import styled from "styled-components";
import Iframe from "react-iframe";

import { Header } from "../../utility/styles";

export const NotificationContainer = styled.div`
  height: calc(100vh - 141px);
  /* width: 100vw; */
  padding: 0px 0px 100px 0px;
  overflow: hidden;
  background-color: #f7f3f3;
  transform: translateY(141px);
`;

export const NotificationHeaderContainer = styled.div`
  left: 5%;
  width: 84%;
  margin: 0px 13%;
  padding-bottom: 15px;
  background-color: #f7f3f3;
`;

export const NotificationHeader = styled(Header)`
  margin-bottom: 10px;
`;

export const NotificationSubHeader = styled.h3`
  color: #333;
  font-size: 1.2rem;
`;

export const NotificationContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 70vh;
`;

export const NotificationIframe = styled(Iframe)`
  width: 100%;
  height: 100vh;
  overflow-y: hidden;
`;
