import * as actionTypes from "../actionTypes/smartWindow";

const initState = {
  manageParkStatOverview: [],
  manageParkStatOverviewTop: [],
  manageParkStatDataLoading: "",
  err: "",
};

//智慧櫥窗
export const startGetSmartWindowtData = (state) => {
  return {
    ...state,
    manageParkStatDataLoading: true,
  }
};

export const getSmartWindowtDataSuccess = (state, data) => {
  return {
    ...state,
    manageParkStatOverview: data?.ManageParkStat_Overview,
    manageParkStatOverviewTop: data?.ManageParkStat_Overview_Top,
    manageParkStatDataLoading: false
  }
};

export const getSmartWindowtDataFail = (state, err) => {
  return {
    ...state,
    manageParkStatDataLoading: false,
    err: err
  }
};

export default (state = initState, action) => {
  switch (action.type) {
    case actionTypes.START_GET_MANAGE_PARK_STAT_DATA:
      return startGetSmartWindowtData(state);
    case actionTypes.GET_MANAGE_PARK_STAT_DATA_SUCCESS:
      return getSmartWindowtDataSuccess(state, action.data);
    case actionTypes.GET_MANAGE_PARK_STAT_DATA_FAIL:
      return getSmartWindowtDataFail(state, action.err);
    default:
      return state;
  }
};