import * as actionTypes from '../actions/actionTypes';

const initState = {
  refreshWithTypeFour: false,
  productVal: "",
  parks: "",
  sectionId: "",
  patenSelection: "all",
  businessId: "",
  redirect: false,
  code: "1810",
  codeList: '',
  typeFour: "",
  basicInfo: "",
  researchPercent: "",
  industryProductivity: "",
  industryPerformancs: "",
  techInfo: "",
  researchInfo: "",
  researchPercent: "",
  researchAvg: "",
  researchIncome: "",
  ProductVal: "",
  ProductValXais: "",
  FactoryCount: "",
  FactoryCountXais: "",
  ResearchFactoryCount: "",
  ResearchFactoryCountXais: "",
  ResearchAmt: "",
  ResearchAmtXais: "",
  EmpCount: "",
  EmpCountXais: '',
  InvestmentAmt: "",
  InvestmentAmtXais: "",
  TechBuyAmt: "",
  TechBuyAmtXais: '',
  TechSaleAmt: "",
  TechBuyAmtXais: '',
  topTen: "",
  pubinvention: "",
  certificated: "",
  pubinventionPage: [0, 10],
  certificatedPage: [0, 10]
};

export default (state = initState, action) => {
  switch (action.type) {
    default:
      return state;
  }
};
