import React from "react";
import { useHistory, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Card from "@material-ui/core/Card";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import Select from "@material-ui/core/Select";
import Icon from "@material-ui/core/Icon";
import Tooltip from "@material-ui/core/Tooltip";
import Modal from "../../components/CustomizedModal/CustomizedModal";
import { useSnackbar } from "notistack";

import Swal from "sweetalert2";

import Spinner from "../../components/Spinner/Spinner";
import { message, directToNewTabHandler } from "../../utility/common";
import UpdateCompanyContainer from "../../components/UpdateCompanyContainer/UpdateCompanyContainer";
import { photoUrl } from "../../constants/endpoints";
import {
  getVerifiedCodeHandler,
  getCompanyInfoListHandler,
  updateCompanyInfoHandler,
  initUpdateCompanyStateHandler,
  getCompanyUploadVerifyingHandler,
} from "../../store/actions/factory";

import factory from "../../assests/update_company_factory.svg";
import uploadDmImg from "../../assests/upload_photo.svg";

import * as Style from "./styles";
import "./styles.scss";

export default (props) => {
  const initState = {
    companyName: "",
    inputtedCompanyCode: "",
    companyCode: "",
    name: "",
    nameHelperText: "",
    businessNo: "",
    businessNoHelperText: "",
    phone: "",
    phoneHelperText: "",
    fax: "",
    faxHelperText: "",
    employeeCount: "",
    employeeCountHelperText: "",
    introduction: "",
    introductionHelperText: "",
    mainProduct: "",
    mainProductHelperText: "",
    webSite: "",
    webSiteHelperText: "",
    contactPerson: "",
    contactPersonHelperText: "",
    accreditation: [],
    accreditationHelperText: "",
    dmImg: "",
    dmImgHelperText: "",
    img: "",
    imgName: "",
    note: "",
    verifiedCode: "",
    verifiedCodeText: "發送驗證碼至 Email",
    verifiedCodeCounter: 0,
    isShowUploadButton: true, //是否顯示上傳圖片按鈕
    isShowIgBackdrop: false,
    isModalOpen: false,
  };
  const inputFile = React.useRef(null);
  const dmImgRef = React.useRef(null);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const history = useHistory();
  const { verifiedCompanyListData } = useSelector((state) => state.app);
  const {
    getUpdateCompanyInfoData,
    getUpdateCompanyInfoLoading,
    getVerifiedCodeData,
    updateCompanyInfoLoading,
    updateCompanyInfoData,
    error,
    uploadVerifying,
    uploadVerifyingLoading,
  } = useSelector((state) => state.factory);
  const [state, setState] = React.useState({
    companyName: "",
    inputtedCompanyCode: "",
    selectedCompanyCode: "",
    companyCode: "",
    name: "",
    nameHelperText: "",
    businessNo: "",
    businessNoHelperText: "",
    phone: "",
    phoneHelperText: "",
    fax: "",
    faxHelperText: "",
    employeeCount: "",
    employeeCountHelperText: "",
    introduction: "",
    introductionHelperText: "",
    mainProduct: "",
    mainProductHelperText: "",
    webSite: "",
    webSiteHelperText: "",
    contactPerson: "",
    contactPersonHelperText: "",
    accreditation: [],
    accreditationHelperText: "",
    dmImg: "",
    dmImgUrl: "",
    dmImgFromServer: "",
    dmImgHelperText: "",
    img: "",
    imgName: "",
    note: "",
    verifiedCode: "",
    verifiedCodeText: "發送驗證碼至 Email",
    verifiedCodeCounter: 0,
    isShowUploadButton: true, //是否顯示上傳圖片按鈕
    isShowIgBackdrop: false,
    isModalOpen: false,
    isOtherAccreditationOpen: false,
    numberOfAccreditation: 0,
    accreditationOptions: [
      {
        name: "AEO 安全認證優質企業",
        value: "AEO",
      },
      {
        name: "AS9100 航空基礎品質管制系統",
        value: "AS9100",
      },
      {
        name: "BSCI 企業社會責任準則",
        value: "BSCI",
      },
      {
        name: "C-TPAT 美國海關商貿反恐聯盟",
        value: "C-TPAT",
      },

      {
        name: "ISO9001 品質管理系統",
        value: "ISO9001",
      },
      {
        name: "ISO13485 醫療器材品質管理系統",
        value: "ISO13485",
      },
      {
        name: "EICC 電子業企業社會準則",
        value: "EICC",
      },
      {
        name: "ISO/TS16949 汽車業品質管理系統",
        value: "ISO/TS16949",
      },
      {
        name: "TL9000 通訊電子業品質管理系統",
        value: "TL9000",
      },

      {
        name: "IECQ QC 080000 HSPM 有害物質流程管理系統",
        value: "IECQQC080000HSPM",
      },
      {
        name: "IWA1 醫療服務業品質管制系統之應用指南",
        value: "IWA1",
      },
      {
        name: "IWA2 教育品質管制系統之應用指南",
        value: "IWA2",
      },
      {
        name: "ISO22716 化妝品安全管理系統",
        value: "ISO22716",
      },
      {
        name: "SGSQualicert 國際服務驗證",
        value: "SGSQualicert",
      },
      {
        name: "Walmart 美國連鎖百貨沃爾瑪驗廠輔導",
        value: "Walmart",
      },
      {
        name: "ISO22000 食品安全管理系統",
        value: "ISO22000",
      },
      {
        name: "HACCP 危害分析重點管理系統",
        value: "HACCP",
      },
      {
        name: "FSSC22000 食品安全管理系統",
        value: "FSSC22000",
      },
      {
        name: "ISO28000 供應鏈安全管理系統",
        value: "ISO28000",
      },

      {
        name: "ISO14001 環境管理系統",
        value: "ISO14001",
      },
      {
        name: "ISO50001 能源管理系統",
        value: "ISO50001",
      },
      {
        name: "ISO14064-1 溫室氣體盤查作業(G.H.G.)",
        value: "ISO14064-1",
      },
      {
        name: "FSC 森林管理委員會認證",
        value: "FSC",
      },
      {
        name: "SA8000 社會責任國際標準系統",
        value: "SA8000",
      },
      {
        name: "ICTI 玩具業企業社會責任準則",
        value: "ICTI",
      },
      {
        name: "OHSAS18001 職業安全衛生管理系統",
        value: "OHSAS18001",
      },
      {
        name: "ISO27001 資訊安全管理系統",
        value: "ISO27001",
      },
    ],
  });

  const handleReverseToInitState = () => {
    setState((state) => ({ ...state, ...initState }));
  };

  const handleAddTextAccreditation = (e) => {
    const { value } = e.target;

    const updateAccreditation = [...state.accreditation];
    if (state.numberOfAccreditation !== state.accreditation.length) {
      updateAccreditation.pop();
    }
    updateAccreditation.push(value);

    setState((state) => ({ ...state, accreditation: updateAccreditation }));
  };

  const handleUpdateField = (e) => {
    const IS_NUMBER = /^\d+$/;
    const { name, value } = e.target;

    e.stopPropagation();
    if (name === "accreditation" && value[value.length - 1] === "other") {
      setState((state) => ({
        ...state,
        isOtherAccreditationOpen: true,
        numberOfAccreditation: state.accreditation.length,
      }));
    } else {
      setState((state) => ({ ...state, isOtherAccreditationOpen: false }));

      if (name === "employeeCount" && !IS_NUMBER.test(value)) {
        message(enqueueSnackbar, "請輸入數字", "error");
      }
      if (name === "employeeCount" && value === "") {
        message(enqueueSnackbar, "員工數不得為0", "error");
        setState((state) => ({ ...state, employeeCount: 1 }));
      }
      if (name === "inputtedCompanyCode" || name === "selectedCompanyCode") {
        dispatch(initUpdateCompanyStateHandler());
        handleReverseToInitState();
        if (value === "customized") {
          setState((state) => ({ ...state, [name]: value }));
        } else {
          setState((state) => ({ ...state, companyCode: value }));
          if (value.length > 8) {
            return;
          } else {
            setState((state) => ({ ...state, [name]: value }));
          }
        }
      } else {
        setState((state) => ({ ...state, [name]: value }));
      }
    }

    // setState(state =>({ ...state, isShowUploadButton: true }));
  };

  const getVerifiedCode = () => {
    dispatch(getVerifiedCodeHandler());
    verifiedCodeCounter();
  };

  const verifiedCodeCounter = () => {
    setState((state) => ({
      ...state,
      verifiedCodeCounter: 120,
      verifiedCodeText: `120秒後可以再取得驗證碼`,
    }));
  };

  const uploadCompanyVerification = (e) => {
    const file = e.target.files[0];
    setState((state) => ({ ...state, imgName: file.name, img: file }));
  };

  const uploadDmFileHandler = (e) => {
    const file = e.target.files[0];
    setState((state) => ({
      ...state,
      dmImg: file,
      file: URL.createObjectURL(e.target.files[0]),
    }));
  };

  const openUploadFileModal = () => {
    inputFile.current.click();
  };

  const openUploadDmFile = () => {
    dmImgRef.current.click();
  };

  const uploadCompanyInfoHandler = () => {
    const {
      name,
      businessNo,
      phone,
      fax,
      employeeCount,
      introduction,
      mainProduct,
      webSite,
      contactPerson,
      verifiedCode,
    } = state;
    if(webSite.indexOf("http") < 0){
      Swal.fire({
        text: "請輸入公司完整網址，包含 http 或是 https 。",
      })

      return
    }

    if (!phone) {
      setState((state) => ({ ...state, phoneHelperText: "姓名不得為空!" }));
    }
    if (!fax) {
      setState((state) => ({ ...state, faxHelperText: "姓名不得為空!" }));
    }
    if (!employeeCount) {
      setState((state) => ({
        ...state,
        employeeCountHelperText: "姓名不得為空!",
      }));
    }
    if (!introduction) {
      setState((state) => ({
        ...state,
        introductionHelperText: "姓名不得為空!",
      }));
    }
    if (!mainProduct) {
      setState((state) => ({
        ...state,
        mainProductHelperText: "姓名不得為空!",
      }));
    }
    if (!webSite) {
      setState((state) => ({ ...state, webSiteHelperText: "姓名不得為空!" }));
    }
    if (
      contactPerson &&
      businessNo &&
      phone &&
      fax &&
      employeeCount &&
      introduction &&
      mainProduct &&
      webSite &&
      verifiedCode
    ) {
      setState((state) => ({ ...state, uploadVerification: true }));
      Swal.fire({
        text: `確認更新廠商資訊`,
        showCancelButton: true,
        confirmButtonText: "確認",
        cancelButtonText: "取消",
      }).then((res) => {
        if (res.value) {
          const file = new FormData();
          file.append("UserId", localStorage.getItem("uid"));
          file.append("Code", state.verifiedCode);
          file.append("Name", localStorage.getItem("name"));
          file.append("BusinessNo", state.businessNo);
          file.append("Phone", state.phone);
          file.append("Fax", state.fax);
          file.append("EmplyeeCount", state.employeeCount);
          file.append("MainProduct", state.mainProduct);
          file.append("Introduction", state.introduction);
          file.append("WebSite", state.webSite);
          file.append("ContactPerson", state.contactPerson);
          file.append("Accreditation", state.accreditation);
          file.append("Note", state.note);


          if (state.img) {
            file.append("Img", state.img);
          }

          if (state.dmImg) {
            file.append("DMImg", state.dmImg);
          }

          dispatch(updateCompanyInfoHandler(file));
          handleReverseToInitState();
          setState((state) => ({ ...state, selectedCompanyCode: "" })); //因為handleReverseToInitState 沒辦法清空 selectedCompanyCode 得值
          dispatch(initUpdateCompanyStateHandler());
        }
      });
    }
  };

  const handleClose = React.useCallback(() => {
    setState((state) => ({
      ...state,
      isModalOpen: false,
    }));
  }, [state.isModalOpen]);

  const handleModalOpen = React.useCallback(() => {
    setState((state) => ({
      ...state,
      isModalOpen: true,
    }));
  }, [state.isModalOpen]);

  const caseInsensitiveComp = (strA, strB) =>
    strA.toLowerCase().localeCompare(strB.toLowerCase());

  React.useEffect(() => {
    if (state.accreditation[state.accreditation.length - 1] === "") {
      const updateAccreditation = [...state.accreditation];
      updateAccreditation.pop();

      setState((state) => ({
        ...state,
        accreditation: updateAccreditation,
      }));
    }
  }, [state.accreditation]);

  React.useEffect(() => {
    const file = new FormData();
    file.append("UserId", localStorage.getItem("uid"));
    file.append("BusinessNo", state.businessNo);

    if (state.businessNo) {
      dispatch(getCompanyUploadVerifyingHandler(file));
    }
  }, [state.businessNo]);

  React.useEffect(() => {
    if (state.verifiedCodeCounter > 0) {
      setTimeout(() => {
        setState((state) => ({
          ...state,
          verifiedCodeCounter: state.verifiedCodeCounter - 1,
          verifiedCodeText: `${state.verifiedCodeCounter}秒後可以再取得驗證碼`,
        }));
      }, 1000);
    } else {
      setState((state) => ({
        ...state,
        verifiedCodeText: "取得驗證碼",
        verifiedCodeCounter: 0,
      }));
    }
  }, [state.verifiedCodeCounter]);

  React.useEffect(() => {
    if (state.companyCode.length === 8) {
      dispatch(getCompanyInfoListHandler(state.companyCode));
    }
  }, [state.companyCode]);

  React.useEffect(() => {
    if (Object.entries(getUpdateCompanyInfoData).length === 0 && error) {
      // message(enqueueSnackbar, error, "error");
    } else if (Object.entries(getUpdateCompanyInfoData).length !== 0) {
      setState((state) => ({
        ...state,
        companyName: getUpdateCompanyInfoData.CompanyName,
        name: getUpdateCompanyInfoData.Name,
        businessNo: getUpdateCompanyInfoData.BusinessNo,
        phone: getUpdateCompanyInfoData.Phone,
        fax: getUpdateCompanyInfoData.Fax,
        employeeCount: getUpdateCompanyInfoData.EmplyeeCount,
        introduction: getUpdateCompanyInfoData.Introduction,
        mainProduct: getUpdateCompanyInfoData.MainProduct,
        webSite: getUpdateCompanyInfoData.WebSite,
        contactPerson: getUpdateCompanyInfoData.ContactPerson,
        dmImgFromServer: getUpdateCompanyInfoData.DMImg,
        accreditation: getUpdateCompanyInfoData.Accreditation
          ? getUpdateCompanyInfoData.Accreditation.split(",")
          : [],
        note: getUpdateCompanyInfoData.Note
      }));
    }
  }, [getUpdateCompanyInfoData, error]);

  React.useEffect(() => {
    if (state.businessNo) {
      if (verifiedCompanyListData.length > 0) {
        for (let i = 0; i <= verifiedCompanyListData.length - 1; i++) {
          if (verifiedCompanyListData[i].split(",")[1] !== state.businessNo) {
            setState((state) => ({ ...state, isShowUploadButton: true }));
          } else {
            setState((state) => ({ ...state, isShowUploadButton: false }));
            break;
          }
        }
      }
    }
  }, [verifiedCompanyListData, state.businessNo]);

  React.useEffect(() => {
    if (Object.entries(getVerifiedCodeData).length > 0) {
      message(
        enqueueSnackbar,
        getVerifiedCodeData.msg
          ? getVerifiedCodeData.msg + ` (${localStorage.getItem("uid")})`
          : "取得驗證Email發生錯誤，請重新嘗試。",
        getVerifiedCodeData.statusCode === 200 ? "success" : "error"
      );
    }
  }, [getVerifiedCodeData]);

  React.useEffect(() => {
    console.log(updateCompanyInfoData)

    if (Object.entries(updateCompanyInfoData).length > 0) {
      message(
        enqueueSnackbar,
        updateCompanyInfoData.msg
          ? updateCompanyInfoData.msg
          : "更新公司資料發生錯誤，請重新嘗試。",
        updateCompanyInfoData.statusCode === 200 ? "success" : "error"
      );

      if (updateCompanyInfoData.statusCode === 200) {
        dispatch(initUpdateCompanyStateHandler());
        handleReverseToInitState();
        setState((state) => ({ ...state, selectedCompanyCode: "" }));
      }
    }
  }, [updateCompanyInfoData]);

  React.useEffect(() => {
    const { dmImg, dmImgFromServer, dmImgUrl } = state;
    if (dmImg) {
      setState((state) => ({
        ...state,
        dmImgUrl: window.URL.createObjectURL(dmImg),
      }));
    } else if (dmImgFromServer && !dmImg) {
      setState((state) => ({
        ...state,
        dmImgUrl: `${photoUrl}/DMFolder/${dmImgFromServer}`,
      }));
    } else {
      setState((state) => ({ ...state, dmImgUrl: uploadDmImg }));
    }
  }, [state.dmImg, state.dmImgFromServer]);

  return (
    <div className="upload-company">
      <Modal isOpen={state.isModalOpen} handleClose={handleClose}>
        <Style.ModalContainer>
          <Style.ModalContainerHeader>
            <Icon onClick={handleClose}>close</Icon>
          </Style.ModalContainerHeader>
          <UpdateCompanyContainer
            Accreditation={state.accreditation}
            BusinessNo={state.businessNo}
            ContactPerson={state.contactPerson}
            DMImg={state.dmImgFromServer}
            EmplyeeCount={state.employeeCount}
            Fax={state.fax}
            Introduction={state.introduction}
            MainProduct={state.mainProduct}
            Name={localStorage.getItem("name")}
            Phone={state.phone}
            WebSite={state.webSite}
            Note={state.note}
            isPreview={true}
          />
        </Style.ModalContainer>
      </Modal>
      <Card className="upload-company-form-modal">
        <div className="upload-company-form-modal-left">
          <div className="upload-company-form-modal-description">
            <img src={factory} alt="factory" loading={"lazy"} />
            <h3>自訂公司資料</h3>
            <p>
              首次新增公司資訊需上傳公司文件進行公司身份驗證(營業登記影本或大小章影本照片)，惟僅有首次需公司驗證，後續維護公司資訊不需再做公司身份驗證，因資安考量每次更新資訊都需透過後台審核通過後方可發布。
            </p>
            <div style={{ paddingTop: "15px" }}>
              <Icon>looks_one</Icon>
              <p>選取或新增欲修改的公司</p>
            </div>
            <div>
              <Icon>looks_two</Icon>
              <p>填寫公司資料</p>
            </div>
            <div>
              <Icon>looks_3</Icon>
              <p>取得驗證碼</p>
            </div>
            <div>
              <Icon>looks_4</Icon>
              <p>預覽</p>
            </div>
            <div>
              <Icon>looks_5</Icon>
              <p>確認自訂公司資料</p>
            </div>
          </div>
        </div>
        <div className="upload-company-form-modal-right">
          <div className="upload-company-sub-header">
            <div className="upload-company-select-company">
              <div className="upload-company-select-company-header">
                <Icon>looks_one</Icon>
                <h1>選取或新增欲修改的公司</h1>
                {uploadVerifying.LastUploadTime && (
                <p>{`資料正在審核中，上傳時間 : ${
                  uploadVerifying.LastUploadTime.split(".")[0]
                }`}</p>
                )}
              </div>
              <div>
                {verifiedCompanyListData.length === 0 ||
                state.selectedCompanyCode === "customized" ? (
                  <div className="company-inputs">
                    <TextField
                      label={"請填寫公司統編"}
                      value={state.inputtedCompanyCode}
                      name={"inputtedCompanyCode"}
                      onChange={(e) => handleUpdateField(e)}
                    />
                    {verifiedCompanyListData.length > 0 && (
                      <Button
                        onClick={() => {
                          setState((state) => ({
                            ...state,
                            selectedCompanyCode: "",
                          }));
                          dispatch(initUpdateCompanyStateHandler());
                          handleReverseToInitState();
                        }}
                      >
                        切回公司清單
                      </Button>
                    )}
                  </div>
                ) : (
                  <TextField
                    className="company-selection"
                    label={"公司清單"}
                    select
                    onChange={(e) => handleUpdateField(e)}
                    value={state.selectedCompanyCode}
                    name={"selectedCompanyCode"}
                  >
                    {verifiedCompanyListData.map((option) => {
                      try {
                        return (
                          <MenuItem
                            key={option.split(",")[0]}
                            value={option.split(",")[1]}
                          >
                            {option.split(",")[0]}
                          </MenuItem>
                        );
                      } catch (e) {
                        return (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        );
                      }
                    })}
                    <MenuItem key={"customized"} value={"customized"}>
                      自行填寫統編
                    </MenuItem>
                  </TextField>
                )}
              </div>
            </div>
            {state.isShowUploadButton && state.businessNo && (
              <div className="upload-company-container-upload-image">
                <input
                  accept="image/*"
                  className="upload-input"
                  id="contained-button-file"
                  multiple
                  type="file"
                  ref={inputFile}
                  onChange={(e) => {
                    uploadCompanyVerification(e);
                  }}
                />
                <label htmlFor="contained-button-file">
                  <Tooltip title="上傳營利事業登記證明或公司大小章圖檔">
                    <Button
                      className="able-button"
                      helperText="麻煩上傳公司大小章以利身份驗證。各廠驗證只需一次。"
                      onClick={openUploadFileModal}
                    >
                      {state.imgName
                        ? `準備上傳${state.imgName}`
                        : "上傳證明圖檔"}
                    </Button>
                  </Tooltip>
                </label>
                <span></span>
              </div>
            )}
          </div>
          <div className="divider" />
          <div className="upload-company-container">
            {(updateCompanyInfoLoading || getUpdateCompanyInfoLoading) && (
              <div>
                <Spinner />
              </div>
            )}
            {!updateCompanyInfoLoading &&
              !getUpdateCompanyInfoLoading &&
              Object.entries(getUpdateCompanyInfoData).length > 0 && (
                <>
                  <div className="upload-company-container-title">
                    <Icon>looks_two</Icon>
                    <h1>填寫
                    <span
                      onClick={() =>
                        directToNewTabHandler(
                          "factory_info/basic_info",
                          state.companyCode
                        )
                      }
                    >
                      {state.companyName}
                    </span>  
                    資料</h1>
                    {state.name && <p>上次修改紀錄 : {state.name}</p>}
                  </div>
                  <div className="upload-company-container-input-container">
                    <TextField
                      label={"公司聯絡人*"}
                      value={state.contactPerson}
                      name={"contactPerson"}
                      onChange={(e) => handleUpdateField(e)}
                      placeholder="姓名+職稱。eg, 王小明 副理   "
                    />
                    <TextField
                      label={"公司電話*"}
                      value={state.phone}
                      name={"phone"}
                      onChange={(e) => handleUpdateField(e)}
                      placeholder="公司電話。eg, 03-XXXXXX"
                    />
                    <TextField
                      label={"公司傳真*"}
                      value={state.fax}
                      name={"fax"}
                      onChange={(e) => handleUpdateField(e)}
                      placeholder="公司傳真。03-XXXXXX"
                    />
                    <TextField
                      label={"公司員工人數*"}
                      value={state.employeeCount}
                      name={"employeeCount"}
                      onChange={(e) => handleUpdateField(e)}
                      placeholder="請填寫員工總人數。"
                    />
                    <TextField
                      label={"公司網站*"}
                      value={state.webSite}
                      name={"webSite"}
                      onChange={(e) => handleUpdateField(e)}
                      placeholder="請填寫公司網站。eg, https://smartpark.com/"
                    />
                    <TextField
                        multiline
                        label={"公司簡介*"}
                        value={state.introduction}
                        name={"introduction"}
                        onChange={(e) => handleUpdateField(e)}
                      />
                      <TextField
                        multiline
                        label={"公司主要產品*"}
                        value={state.mainProduct}
                        name={"mainProduct"}
                        onChange={(e) => handleUpdateField(e)}
                        className="main-product"
                      />
                    <div className="downer-container">

                    </div>
                    <div
                      className={
                        state.dmImg || state.dmImgFromServer
                          ? "downer-container-with-photo"
                          : "downer-container"
                      }
                    >
                      <div
                        className="upload-dm"
                        onMouseEnter={() => {
                          setState((state) => ({
                            ...state,
                            isShowIgBackdrop: true,
                          }));
                        }}
                        onMouseLeave={() => {
                          setState((state) => ({
                            ...state,
                            isShowIgBackdrop: false,
                          }));
                        }}
                      >
                        <label>公司DM</label>
                        <div
                          className="upload-dm-container"
                          onClick={openUploadDmFile}
                        >
                          {(state.dmImg || state.dmImgFromServer) && (
                            <div
                              className={
                                state.isShowIgBackdrop ? "backdrop" : ""
                              }
                            >
                              <Icon>photo_camera</Icon>
                            </div>
                          )}
                          <img
                            src={state.dmImgUrl}
                            alt="upload_dm_img"
                            loading={"lazy"}
                            // onClick={openUploadDmFile}
                          />
                          <input
                            accept="image/*"
                            className="upload-input"
                            id="contained-button-file"
                            multiple
                            type="file"
                            ref={dmImgRef}
                            onChange={(e) => {
                              uploadDmFileHandler(e);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="accreditation-container">
                        <label>公司認證</label>
                        <Select
                          className="update-multi-select"
                          multiple
                          multiline
                          value={state.accreditation}
                          name={"accreditation"}
                          onChange={(e) => handleUpdateField(e)}
                          renderValue={(selected) => (
                            <div className="accreditation-chips-container">
                              {/* {selected.map(acc => <Chip label={state.accreditationOptions.filter(ac => ac.value === acc)[0].name} />)} */}
                              {selected.map((acc) => (
                                <Chip label={acc} />
                              ))}
                            </div>
                          )}
                        >
                          {state.accreditationOptions
                            .sort((a, b) =>
                              caseInsensitiveComp(
                                a.name.substring(0, 1),
                                b.value.substring(0, 1)
                              )
                            )
                            .map((acc) => (
                              <MenuItem value={acc.name} key={acc.value}>
                                {acc.name}
                              </MenuItem>
                            ))}
                        </Select>
                        {state.isOtherAccreditationOpen && (
                          <TextField
                            name={"accreditation"}
                            onChange={(e) => handleAddTextAccreditation(e)}
                            placeholder="自行輸入公司認證"
                          />
                        )}
                      </div>
                    <TextField
                      multiline
                      label={"備註"}
                      value={state.note}
                      name={"note"}
                      onChange={(e) => handleUpdateField(e)}
                      placeholder="請填寫備註。如：公司認證等。"  
                    />
                  </div>
                  <div className="divider" />
                  <div className="upload-company-container-verified-code">
                    <Icon>looks_3</Icon>
                    <TextField
                      label={"驗證碼"}
                      value={state.verifiedCode}
                      name={"verifiedCode"}
                      onChange={(e) => handleUpdateField(e)}
                      placeholder="請輸入驗證碼"
                    />
                    <Button
                      onClick={
                        state.verifiedCodeCounter > 0
                          ? () => {}
                          : getVerifiedCode
                      }
                      className={
                        state.verifiedCodeCounter > 0
                          ? "disable-button"
                          : "able-button"
                      }
                      disabled={state.verifiedCodeCounter > 0}
                    >
                      {state.verifiedCodeText}
                    </Button>
                  </div>
                  <div className="divider" />
                  <div className="upload-company-container-submit-button-container">
                    <Icon>looks_4</Icon>
                    <Button
                      onClick={handleModalOpen}
                      className="able-button"
                    >
                      預覽
                    </Button>
                  </div>
                  <div className="divider" />
                  <div className="upload-company-container-submit-button-container">
                    <Icon>looks_5</Icon>
                    <Button
                      onClick={
                        !state.verifiedCode ||
                        !state.contactPerson ||
                        !state.phone ||
                        !state.fax ||
                        !state.employeeCount ||
                        !state.introduction ||
                        !state.mainProduct ||
                        !state.webSite
                          ? () => {}
                          : uploadCompanyInfoHandler
                      }
                      className={
                        !state.verifiedCode ||
                        !state.contactPerson ||
                        !state.phone ||
                        !state.fax ||
                        !state.employeeCount ||
                        !state.introduction ||
                        !state.mainProduct ||
                        !state.webSite ||
                        (state.isShowUploadButton && !state.imgName)
                          ? "disable-button"
                          : "able-button"
                      }
                      disabled={
                        !state.verifiedCode ||
                        !state.contactPerson ||
                        !state.phone ||
                        !state.fax ||
                        !state.employeeCount ||
                        !state.introduction ||
                        !state.mainProduct ||
                        !state.webSite ||
                        (state.isShowUploadButton && !state.imgName)
                      }
                    >
                      確認送出
                    </Button>
                  </div>
                </>
              )}
            {!updateCompanyInfoLoading &&
              !getUpdateCompanyInfoLoading &&
              Object.entries(getUpdateCompanyInfoData).length === 0 && (
                <div className="upload-company-container-no-data-descrip">
                  <p>請選取或新增欲修改的公司</p>
                </div>
              )}
          </div>
        </div>
      </Card>
    </div>
  );
};
