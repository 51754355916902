import axios from "axios";
import { baseUrl, loginbaseUrl } from "../constants/endpoints";
import { encryptAES, decryptAES } from "../utility/common";

//新增儀表板
export const createDashboard = (board_name) => {
  const account = localStorage.getItem("uid");
  const data = new FormData();
  data.append("Account", account);
  const encrypt = encryptAES(`Aj9dxd${account}`);
  data.append("Encrypt", encrypt);
  data.append("BoardName", board_name);

  return axios({
    method: "post",
    url: `${loginbaseUrl}/api/UserCustmonized/CreateUserDashboard`,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    data: data,
  })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};

export const copyDashboard = (board_id) => {
  const account = localStorage.getItem("uid");
  const data = new FormData();
  data.append("Account", account);
  const encrypt = encryptAES(`Aj9dxd${account}`);
  data.append("Encrypt", encrypt);
  data.append("BoardID", board_id);

  return axios({
    method: "post",
    url: `${loginbaseUrl}/api/UserCustmonized/CopyUserDashboard`,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    data: data,
  })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};

//取得儀表板清單
export const fetchDashboardList = () => {
  const account = localStorage.getItem("uid");
  const data = new FormData();
  data.append("Account", account);
  const encrypt = encryptAES(`Aj9dxd${account}`);
  data.append("Encrypt", encrypt);
  return axios({
    method: "post",
    url: `${loginbaseUrl}/api/UserCustmonized/GetUserDashboardList`,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    data: data,
  })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};

//刪除儀表板統編公司資料
export const deleteDashboardBusinessNumberList = (board_id) => {
  const account = localStorage.getItem("uid");
  const data = new FormData();
  data.append("Account", account);
  const encrypt = encryptAES(`Aj9dxd${account}`);
  data.append("Encrypt", encrypt);
  data.append("BoardID", board_id);
  return axios({
    method: "post",
    url: `${loginbaseUrl}/api/UserCustmonized/DeleteUserDashboard`,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    data: data,
  })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};

//取得儀表板統編公司資料
export const fetchDashboardBusinessNumberList = (board_id) => {
  const account = localStorage.getItem("uid");
  const data = new FormData();
  data.append("Account", account);
  const encrypt = encryptAES(`Aj9dxd${account}`);
  data.append("Encrypt", encrypt);
  data.append("BoardID", board_id);
  return axios({
    method: "post",
    url: `${loginbaseUrl}/api/UserCustmonized/GetUserDashboardCompanyList`,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    data: data,
  })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};

//新增儀表板統編 (個廠情資、技術快搜 單筆或多筆匯入)
export const addDashboardBusinessNumberList = (board_id, business_no_list) => {
  const account = localStorage.getItem("uid");
  const data = new FormData();
  data.append("Account", account);
  const encrypt = encryptAES(`Aj9dxd${account}`);
  data.append("Encrypt", encrypt);
  data.append("BoardID", board_id);
  data.append("BusinessNoList", business_no_list);
  return axios({
    method: "post",
    url: `${loginbaseUrl}api/UserCustmonized/InsertUserDashboardCompany`,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    data: data,
  })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};

export const deleteCompanyDashboardBusinessNumberList = (board_id, business_no_list) => {
  const account = localStorage.getItem("uid");
  const data = new FormData();
  data.append("Account", account);
  const encrypt = encryptAES(`Aj9dxd${account}`);
  data.append("Encrypt", encrypt);
  data.append("BoardID", board_id);
  data.append("CompanyList", business_no_list);
  return axios({
    method: "post",
    url: `${loginbaseUrl}api/UserCustmonized/DeleteDashboardCompany`,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    data: data,
  })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};

//檢查統編已加入儀表板 (個廠)
export const checkDashboardBusinessNumberList = (business_no) => {
  const account = localStorage.getItem("uid");
  const data = new FormData();
  data.append("Account", account);
  const encrypt = encryptAES(`Aj9dxd${account}`);
  data.append("Encrypt", encrypt);
  data.append("BussinessNo", business_no);
  return axios({
    method: "post",
    url: `${loginbaseUrl}/api/UserCustmonized/CheckCompanyInUserDashBoard`,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    data: data,
  })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};

//更新儀表板名稱
export const updateDashboardName = (
  board_id,
  board_name
) => {
  const account = localStorage.getItem("uid");
  const data = new FormData();
  data.append("Account", account);
  const encrypt = encryptAES(`Aj9dxd${account}`);
  data.append("Encrypt", encrypt);
  data.append("BoardID", board_id);
  data.append("BoardName", board_name);

  return axios({
    method: "post",
    url: `${loginbaseUrl}/api/UserCustmonized/UpdateUserDashboardName`,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    data: data,
  })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};


//更新儀表板統編 (新增、刪除 編輯儀表板統編)
export const updateDashboardBusinessNumberList = (
  board_id,
  business_no_list,
  board_name
) => {
  const account = localStorage.getItem("uid");
  const data = new FormData();
  data.append("Account", account);
  const encrypt = encryptAES(`Aj9dxd${account}`);
  data.append("Encrypt", encrypt);
  data.append("BoardID", board_id);
  data.append("BusinessNoList", business_no_list);
  data.append("BoardName", board_name);

  return axios({
    method: "post",
    url: `${loginbaseUrl}/api/UserCustmonized/UploadUserDashboard`,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    data: data,
  })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};

// 使用公司名稱查詢統編
export const getBusinessNoByCompanyName = (
  company_list
) => {
  const account = localStorage.getItem("uid");
  const data = new FormData();
  data.append("Account", account);
  const encrypt = encryptAES(`Aj9dxd${account}`);
  data.append("Encrypt", encrypt);
  data.append("CompanyNameList", company_list);

  return axios({
    method: "post",
    url: `${loginbaseUrl}/api/UserCustmonized/GetBusinessNoByCompanyName`,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    data: data,
  })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};
