import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import styled from "styled-components";
import Swal from "sweetalert2";

import withReactContent from "sweetalert2-react-content";
import { useHistory, Link } from "react-router-dom";
import { Select, Box, FormControl, InputLabel } from "@mui/material";
import { ArrowForward } from "@material-ui/icons";
import headerBg from "../../assests/images/about-header.png";
import otherWebBg from "../../assests/images/about-footer.png";
import robot from "../../assests/images/about-robot-240402.png";
import text from "../../assests/images/about-text.png";
import link1 from "../../assests/images/about-link-1.png";
import link2 from "../../assests/images/about-link-2.png";
import link3 from "../../assests/images/about-link-3.png";
import link4 from "../../assests/images/about-link-4.png";

import mugaLogo from "../../assests/images/muga.png";
import MIRDC_Logo from "../../assests/images/MIRDC_Logo.png";
import moeaLogo from "../../assests/images/moeaLogo.png";
import IDBLogo from "../../assets/images/IDB.png";
import mdirLogo2023 from "../../assests/2024_IDB_Logo.svg";
import accessibilityLogo from "../../assets/images/accessibilityA.png";

import "./styles.scss";
import * as authActions from "../../store/actions/auth";
import { cityCode } from "../../constants/cities";
import { parksWithUrl } from "../../constants/parks";
import { getTypeFourCode, getNewsInfo } from "../../utility/api";
import {
  getFactoryCount,
  getProductVal,
  getListedCompany,
} from "../../store/actions/IndustryDashboard";
import {
  getTopTenCapitalDataData,
  getStartUpDataHandler,
  getTopTenGovPlanData,
  getTypeTwoChartDataHandler,
  getPublicStockChartData,
  getTaiwanInvestmentDataHandler,
} from "../../store/actions/cityDashbroad";
import {
  getSectionIndexHandler,
  getSectionExportPercentageHandler,
  getSectionTopTenCapitalHandler,
  getSectionTopTenGovernmentPlanHandler,
  getSectionNewsContentHandler,
} from "../../store/actions/parkDashboard_beta";
import IndustryBoard from "./InsustryBoard";
import CityBoard from "./CityBoard";
import ParkBoard from "./ParkBoard";
import FactorySearch from "./FactorySearch";
import MapSearch from "./MapSearch";
import Breadcrumb from "../../components/Breadcrumbs";

const themeColor = createTheme({
  palette: {
    secondary: {
      main: "#25758A",
    },
  },
});

const MySwal = withReactContent(Swal);

const About = (props) => {

  const { toggleSignUp, signInModalOpen, signUpFirst } = props;
  const history = useHistory();
  const dispatch = useDispatch();

  const callTo = () => {
    window.location.href = "tel:07-3513121";
  };

  if (localStorage.getItem("uid") === "idpark" && !signInModalOpen) {
    MySwal.fire({
      html: "<p>提醒您目前使用之測試帳號將於近期內失效。</p><p>請聯繫服務窗口陳小姐:07-3513121分機2415<p></p>申請專屬於您的帳號。</p>",
      showCancelButton: true,
      showCloseButton: true,
      confirmButtonText: "前往註冊",
      cancelButtonText: "前往登入",
    }).then((res) => {
      if (res.value) {
        dispatch(authActions.logoutHandler());
        if (window.innerWidth >= 1024) {
          signUpFirst();
          toggleSignUp();
        } else {
          signUpFirst();
          history.push("/sign-up");
        }
      } else {
        history.push("/sign-in");
      }
    });
  }

  // 中間儀表版區塊的資料
  const [dashboardData, setDashboardData] = React.useState({
    dashboard: "industry_index", // park_dashboard, city_dashboard, industry_index
    code: "1810", // 產業儀表板的選擇
    industryIndexData: {},
  });
  const [typeFourOptions, setTypeFourOptions] = React.useState([]); // 產業儀表板的選項
  const [cityOptions, setCityOptions] = React.useState([]); // 縣市儀表板的選項
  const [parkOptions, setParkOptions] = React.useState([]); // 園區儀表板的選項

  // options
  useEffect(() => {
    if (
      dashboardData.dashboard === "city_dashboard" &&
      cityOptions.length === 0
    ) {
      const updateCityOptions = [];
      cityCode.map((city) =>
        updateCityOptions.push({
          key: city.CityId,
          value: city.CityId,
          name: city.CityName,
        })
      );
      setCityOptions(updateCityOptions);
    }
    if (
      dashboardData.dashboard === "park_dashboard" &&
      parkOptions?.length === 0
    ) {
      const parkList = [];
      parksWithUrl.forEach((item) => {
        const { ParkId } = item;
        parkList.push({
          ...item,
          key: item.ParkId,
          value: item.ParkId,
          name: item.Name,
        });
      });
      console.log("parkList", parkList);
      setParkOptions(parkList);
    }
  }, [dashboardData.dashboard, cityOptions, parkOptions]);

  // 產業儀表板的 options
  useEffect(() => {
    const getData = async () => {
      try {
        const data = await getTypeFourCode();
        setTypeFourOptions(data);
        const formData = new FormData();
        formData.append("RocYear", "112");
        formData.append("IndustryCodes", `${dashboardData.code}`);
        const res = await getNewsInfo(formData);
        if (res !== "error") {
          const fixedBasicInfo = {
            amount: res.product_val,
            employeeAmount: res.factory_count,
            avgWater: res.emp_count,
            avgPower: res.investment_amt,
          };
          const fixedTechInfo = {
            amount: res.research_factory_count,
            employeeAmount: res.research_amt,
            avgWater: res.revenue_income,
            avgPower: res.revenue_pay,
          };
          setDashboardData((prev) => ({
            ...prev,
            industryIndexData: {
              basicInfo: fixedBasicInfo,
              techInfo: fixedTechInfo,
            },
          }));
        } else {
          this.setState({
            basicInfo: "error",
          });
        }
        handleRequests(dashboardData.code);
      } catch (err) {
        console.log("err", err);
      }
    };
    getData();
  }, []);

  // 產業儀表板資料
  const chartYear = React.useMemo(() => {
    const year = [];
    for (let i = 102; i <= 113; i++) {
      year.push(i);
    }
    return year;
  }, []);
  const { factoryCount, productVal, listedCompany } = useSelector(
    (state) => state.industryDashboard
  );
  React.useEffect(() => {
    if (listedCompany.listedCompanyData.length != 0) {
      var Date;
      var listedDate = listedCompany.listedCompanyData[0].date;
      if (listedDate.includes("-03-")) {
        Date = listedDate.split("-")[0] + "Q1";
      } else if (listedDate.includes("-06-")) {
        Date = listedDate.split("-")[0] + "Q2";
      } else if (listedDate.includes("-09-")) {
        Date = listedDate.split("-")[0] + "Q3";
      } else if (listedDate.includes("-12-")) {
        Date = listedDate.split("-")[0] + "Q4";
      }
      setDashboardData((prev) => ({
        ...prev,
        industryIndexData: {
          ...prev.industryIndexData,
          listedCompany: {
            date: Date,
          },
        },
      }));
    }
  }, [listedCompany.listedCompanyData]);

  // 縣市儀表板
  const {
    startUp,
    topTenCapital,
    topTenGovPlan,
    typeTwoChartDataMap,
    publicStock,
    taiwanInvestment,
  } = useSelector((state) => state.cityDashboard);

  // 園區儀表板
  const {
    sectionIndex,
    sectionExportPercentage,
    sectionTopTenCapital,
    sectionTopTenGovPlan,
    sectionNewsContent,
  } = useSelector((state) => state.parkDashboard_beta);

  const getIndustryDashboardData = async (code) => {
    const formData = new FormData();
    formData.append("RocYear", "112");
    formData.append("IndustryCodes", `${code}`);
    const res = await getNewsInfo(formData);
    if (res !== "error") {
      const fixedBasicInfo = {
        amount: res.product_val,
        employeeAmount: res.factory_count,
        avgWater: res.emp_count,
        avgPower: res.investment_amt,
      };
      const fixedTechInfo = {
        amount: res.research_factory_count,
        employeeAmount: res.research_amt,
        avgWater: res.revenue_income,
        avgPower: res.revenue_pay,
      };
      setDashboardData((prev) => ({
        ...prev,
        industryIndexData: {
          ...prev.industryIndexData,
          basicInfo: fixedBasicInfo,
          techInfo: fixedTechInfo,
        },
      }));
    }
  };
  const handleRequests = React.useCallback(
    (code) => {
      Promise.all([
        getIndustryDashboardData(code),
        dispatch(getFactoryCount(code)),
        dispatch(getProductVal(code)),
        dispatch(getListedCompany(code)),
      ]);
    },
    [dispatch]
  );
  const handleCityRequests = React.useCallback(
    (code) => {
      Promise.all([
        dispatch(getTopTenCapitalDataData(code)),
        dispatch(getStartUpDataHandler(code)),
        dispatch(getTopTenGovPlanData(code)),
        dispatch(getTypeTwoChartDataHandler(code)),
        dispatch(getPublicStockChartData(code)),
        dispatch(getTaiwanInvestmentDataHandler(code)),
      ]);
    },
    [dispatch]
  );
  const handleParkRequests = React.useCallback(
    (code) => {
      Promise.all([
        dispatch(getSectionIndexHandler(code)),
        dispatch(getSectionExportPercentageHandler(code)),
        dispatch(getSectionTopTenCapitalHandler(code)),
        dispatch(getSectionTopTenGovernmentPlanHandler(code)),
        dispatch(getSectionNewsContentHandler(code)),
      ]);
    },
    [dispatch]
  );
  useEffect(() => {
    if (dashboardData.dashboard === "industry_index") {
      handleRequests(dashboardData.code);
    }
    if (dashboardData.dashboard === "city_dashboard") {
      handleCityRequests(dashboardData.code);
    }
    if (dashboardData.dashboard === "park_dashboard") {
      handleParkRequests(dashboardData.code);
    }
  }, [dashboardData.dashboard, dashboardData.code]);

  // 個廠情資、產品快搜、產學快搜
  const [searchType, setSearchType] = React.useState("factory_search");

  // Footer
  const currentYear = new Date().getFullYear();
  const { viewCount } = useSelector((state) => state.app);
  const { version, cachedVersion } = useSelector((state) => state.app);


  const [showScrollHint, setShowScrollHint] = React.useState(true);
  useEffect(() => {
    const target = document.querySelector(".about-v2");
    const handleScroll = () => {
      const scrollTop = target.scrollTop;
      if (scrollTop >= 0 && scrollTop < 200) {
        setShowScrollHint(true);
      } else {
        setShowScrollHint(false);
      }
    };
    target.addEventListener("scroll", handleScroll, { passive: true });
    return () => target.removeEventListener("scroll", handleScroll);
  }, []);
  return (
    <ThemeProvider theme={themeColor}>
      <div className="about-v2">
        <div
          id="factory"
          class="top"
          style={{
            backgroundImage: `url(${headerBg})`,
          }}>
          <div class="top-breadcrumb">
            <Breadcrumb
              route={[
                {
                  name: "首頁",
                },
              ]}
              target="index"
            />
          </div>
          <div class="mouse_scroll" style={{
            display: showScrollHint ? "block" : "none",
            top: window.innerHeight - 127
          }}>
            <div>
              <span class="m_scroll_arrows unu"></span>
              <span class="m_scroll_arrows doi"></span>
              <span class="m_scroll_arrows trei"></span>
            </div>
          </div>
          <div className="about-container">
            <div className="search-bar about-container">
              <img src={robot} alt="" className="robot" />
              <img src={text} alt="" className="robot-text" />
              <div className="tab-group">
                {[
                  {
                    name: "個廠情資",
                    value: "factory_search",
                    permission: true,
                  },
                  {
                    name: "產品快搜",
                    value: "productSearch",
                    permission: localStorage.getItem("isLogin") === "true",
                  },
                  {
                    name: "產學快搜",
                    value: "talentSearch",
                    permission: localStorage.getItem("isLogin") === "true",
                  },
                ].map((item) => {
                  if (!item.permission) return null;
                  return (
                    <button
                      role="tab"
                      aria-selected={searchType === item.value}
                      key={item.value}
                      className={
                        searchType === item.value ? "tab active" : "tab"
                      }
                      onClick={() => {
                        setSearchType(item.value);
                      }}>
                      {item.name}
                    </button>
                  );
                })}
              </div>
              {searchType === "factory_search" && <FactorySearch />}
              {searchType === "productSearch" && (
                <MapSearch search={"productSearch"} />
              )}
              {searchType === "talentSearch" && (
                <MapSearch search={"talentSearch"} />
              )}
              {/* <div class="search-content">
                <div class="d-flex algin-center">
                  <div className="button-group">
                    <button>廠商名稱</button>
                    <button>統一編號</button>
                  </div>
                  <div>
                    <input type="text" />
                    <button class="button-contained">搜尋</button>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
        <div id="dashboard" className="about-dashboard about-container">
          <div className="about-dashboard-search-bar">
            <div className="tab-group">
              {[
                {
                  name: "產業儀表板",
                  value: "industry_index",
                  default: "1810",
                },
                {
                  name: "園區儀表板",
                  value: "park_dashboard",
                  default: "HIP015",
                },
                {
                  name: "縣市儀表板",
                  value: "city_dashboard",
                  default: "15",
                },
              ].map((item) => {
                return (
                  <button
                    key={item.value}
                    className={
                      dashboardData.dashboard === item.value
                        ? "tab active"
                        : "tab"
                    }
                    onClick={() => {
                      setDashboardData((prev) => ({
                        ...prev,
                        dashboard: item.value,
                        code: item.default,
                      }));
                    }}>
                    {item.name}
                  </button>
                );
              })}
            </div>
            <SearchDashboard fullWidth>
              <InputLabel for="select_dashboard"></InputLabel>
              <Select
                native
                id="select_dashboard"
                size="small"
                label=""
                value={dashboardData.code}
                onChange={(e) => {
                  setDashboardData((prev) => {
                    return {
                      ...prev,
                      code: e.target.value,
                    };
                  });
                }}
                className="dashboard-header-selection">
                {dashboardData.dashboard === "industry_index" &&
                  typeFourOptions &&
                  typeFourOptions !== "error" &&
                  typeFourOptions.map((item, index) => (
                    <option
                      key={`${item.Key}_${item.Value}`}
                      value={item.Key}
                      selected={item.Key === dashboardData.code}>
                      {item.Key} {item.Value}
                    </option>
                  ))}
                {dashboardData.dashboard === "city_dashboard" &&
                  cityOptions?.length > 0 &&
                  cityOptions.map((item) => (
                    <option
                      key={`${item.name}_${item.key}`}
                      value={item.value}
                      selected={item.value === dashboardData.code}>
                      {item.name}
                    </option>
                  ))}
                {dashboardData.dashboard === "park_dashboard" &&
                  parkOptions?.length > 0 &&
                  parkOptions.map((item) => (
                    <option
                      key={`${item.name}_${item.ParkId}`}
                      value={item.value}
                      selected={item.value === dashboardData.code}>
                      {item.isAccess === 1 && "(示範園區)"} {item.name}
                    </option>
                  ))}
              </Select>
            </SearchDashboard>
          </div>
          {dashboardData.dashboard === "industry_index" && (
            <IndustryBoard
              dashboardData={dashboardData}
              productVal={productVal}
              factoryCount={factoryCount}
              chartYear={chartYear}
              listedCompany={listedCompany}
            />
          )}
          {dashboardData.dashboard === "city_dashboard" && (
            <CityBoard
              dashboardData={dashboardData}
              taiwanInvestment={taiwanInvestment}
              startUp={startUp}
              topTenCapital={topTenCapital}
              topTenGovPlan={topTenGovPlan}
              typeTwoChartDataMap={typeTwoChartDataMap}
              publicStock={publicStock}
            />
          )}
          {dashboardData.dashboard === "park_dashboard" && (
            <ParkBoard
              dashboardData={dashboardData}
              sectionIndex={sectionIndex?.sectionIndexData}
              sectionExportPercentage={sectionExportPercentage}
              sectionTopTenCapital={sectionTopTenCapital}
              sectionTopTenGovPlan={sectionTopTenGovPlan}
              sectionNewsContent={sectionNewsContent}
            />
          )}
        </div>
        <div id="link" className="about-link">
          <div className="link-wrapper">
            {[
              {
                title: "廢棄物快搜",
                content: "廢棄物申報狀況及清除機構",
                link: "/wasted_landing_page",
                img: link1,
              },
              {
                title: "廠商需求",
                content: "填寫需求表單由專人服務",
                link: "/client-response/query",
                img: link2,
              },
              {
                title: "產業鏈快搜",
                content: "國內重點產業廠商分佈",
                link: "/supply-chain",
                img: link3,
              },
              {
                title: "入區投資",
                content: "入區流程不可不知",
                link: "/investment",
                img: link4,
              },
            ].map((item) => {
              return (
                <RedLinkBoxContainer
                  key={item.title}
                  to={item.link}
                  target="_blank"
                  title={`另開新視窗，前往${item.title}`}
                  rel="noopener noreferrer">
                  <img src={item.img} alt="" />
                  <RedLinkBoxContent>
                    <Box display="flex" alignItems="center">
                      <h2>{item.title}</h2>
                      <ArrowForward />
                    </Box>
                    <p>{item.content}</p>
                  </RedLinkBoxContent>
                </RedLinkBoxContainer>
              );
            })}
          </div>
        </div>
        <div
          id="other"
          className="other-web"
          style={{
            backgroundImage: `url(${otherWebBg})`,
          }}>
          <div className="about-container">
            <LinkBoxContainer
              display="flex"
              flexWrap="wrap"
              justifyContent="center">
              <LinkBox
                title={`另開新視窗，前往經濟部網站`}
                href="https://www.moea.gov.tw/Mns/populace/home/Home.aspx"
                target="_blank">
                <div className="content">
                  <img src={moeaLogo} alt="" style={{ padding: "10px" }} />
                  <p>經濟部</p>
                </div>
              </LinkBox>
              <LinkBox title={`另開新視窗，前往經濟部產業園區管理局網站`} href="https://www.bip.gov.tw/index.aspx" target="_blank">
                <div className="content">
                  <img src={IDBLogo} alt="" style={{ paddingTop: "10px" }} />
                  <p>經濟部產業園區管理局</p>
                </div>
              </LinkBox>
              {/* <LinkBox
                href="https://www.moeaidb.gov.tw/iphw/monip/"
                target="_blank">
                <div className="content">
                  <img src={IDBLogo} alt="" style={{ padding: "25px" }} />
                  <p>北區工業區管理處</p>
                </div>
              </LinkBox>
              <LinkBox
                href="https://www.moeaidb.gov.tw/iphw/mocip/"
                target="_blank">
                <div className="content">
                  <img src={IDBLogo} alt="" style={{ padding: "25px" }} />
                  <p>中區工業區管理處</p>
                </div>
              </LinkBox>
              <LinkBox
                href="https://www.moeaidb.gov.tw/iphw/mosip/"
                target="_blank">
                <div className="content">
                  <img src={IDBLogo} alt="" style={{ padding: "25px" }} />
                  <p>南區工業區管理處</p>
                </div>
              </LinkBox> */}
              <LinkBox title={`另開新視窗，前往中華民國工業區廠商聯合總會網站`} href="http://www.muga.org.tw/" target="_blank">
                <div className="content">
                  <img src={mugaLogo} alt="" />
                  <p>中華民國工業區廠商聯合總會</p>
                </div>
              </LinkBox>
              <LinkBox
                title={`另開新視窗，前往金屬工業研究發展中心網站`}
                href="https://www.mirdc.org.tw/index.aspx"
                target="_blank">
                <div className="content">
                  <img src={MIRDC_Logo} alt="" style={{ padding: "10px" }} />
                  <p>金屬工業研究發展中心</p>
                </div>
              </LinkBox>
              {/* <LinkBox href="https://mii.mirdc.org.tw/" target="_blank">
                <div className="content">
                  <img src={mirdcLogo} alt="" style={{ padding: "10px" }} />
                  <p>MII金屬情報網</p>
                </div>
              </LinkBox> */}
            </LinkBoxContainer>
          </div>
          <div id="sitemap" className="about-site-map">
            <div class="about-container">
              <Box
                display={"flex"}
                flexDirection={{
                  xs: "column",
                  sm: "row",
                }}
                flexWrap={"wrap"}
                justifyContent={"center"}>
                <BoxWrapper display={"flex"} flexDirection={"column"}>
                  <h2>
                    <TitleLink
                      to="/portal"
                      title={`另開新視窗，前往首頁`}
                      target="_blank"
                      rel="noopener noreferrer">
                      首頁
                    </TitleLink>
                  </h2>
                  <h2>
                    <StyledLink
                      to="/park_dashboard/HIP015"
                      title={`另開新視窗，前往儀表板`}
                      target="_blank"
                      rel="noopener noreferrer">
                      儀表板
                    </StyledLink>
                  </h2>
                  {[
                    { title: "園區儀表板", link: "/park_dashboard/HIP015" },
                    { title: "分區儀表板", link: "/area_dashboard/North" },
                    { title: "縣市儀表板", link: "/city_dashboard/15" },
                    { title: "產業儀表板", link: "/industry_index/1810" },
                    {
                      title: "自訂關注廠商儀表板",
                      link: "/custom_dashboard",
                    },
                    { title: "受事件影響儀表板", link: "/event_dashboard" },
                    {
                      title: "自訂關注產業儀表板",
                      link: "/custom_industry_dashboard",
                    },
                  ].map((item) => {
                    return (
                      <StyledLink
                        key={item.title}
                        to={item.link}
                        target="_blank"
                        title={`另開新視窗，前往${item.title}`}
                        rel="noopener noreferrer">{`> ${item.title}`}</StyledLink>
                    );
                  })}
                </BoxWrapper>
                <BoxWrapper display={"flex"} flexDirection={"column"}>
                  <h2>
                    <StyledLink
                      to="/map-search"
                      title={`另開新視窗，前往快搜服務`}
                      target="_blank"
                      rel="noopener noreferrer">
                      快搜服務
                    </StyledLink>
                  </h2>
                  {[
                    { title: "技術快搜", link: "/map-search" },
                    { title: "廢棄物快搜", link: "/wasted_landing_page" },
                    { title: "產業鏈快搜", link: "/supply-chain" },
                  ].map((item) => {
                    return (
                      <StyledLink
                        key={item.title}
                        to={item.link}
                        title={`另開新視窗，前往${item.title}`}
                        target="_blank"
                        rel="noopener noreferrer">{`> ${item.title}`}</StyledLink>
                    );
                  })}
                  <h2>
                    <TitleLink
                      to="/factory_search"
                      title={`另開新視窗，前往個廠情資`}
                      target="_blank"
                      rel="noopener noreferrer">
                      個廠情資
                    </TitleLink>
                  </h2>
                  <h2>
                    <TitleLink
                      to="/gov_plan_display"
                      title={`另開新視窗，前往政府資源`}
                      target="_blank"
                      rel="noopener noreferrer">
                      政府資源
                    </TitleLink>
                  </h2>
                  <h2>
                    <TitleLink
                      to="/coporaiton_tec"
                      title={`另開新視窗，前往關注訂閱`}
                      target="_blank"
                      rel="noopener noreferrer">
                      關注訂閱
                    </TitleLink>
                  </h2>
                  <h2>
                    <TitleLink
                      to="/client-response/query"
                      title={`另開新視窗，前往廠商需求`}
                      target="_blank"
                      rel="noopener noreferrer">
                      廠商需求
                    </TitleLink>
                  </h2>
                  {/* <TitleLink
                    to="/questionnaire-management"
                    target="_blank"
                    rel="noopener noreferrer">
                    訪廠問卷
                  </TitleLink> */}
                </BoxWrapper>
                <BoxWrapper display={"flex"} flexDirection={"column"}>
                  {/* <StyledLink
                    to="/about"
                    target="_blank"
                    rel="noopener noreferrer">
                    其他
                  </StyledLink> */}
                  {[
                    { title: "自訂廠商資料", link: "/updated_company" },
                    // { title: "修改使用者資訊", link: "/park_dashboard/QIP003" },
                    {
                      title: "意見回饋",
                      link: "https://docs.google.com/forms/d/e/1FAIpQLScJiG5ROXmHBZnExjxvx5cY5b4tEYz36YkqsaGPiuj1b3TYCw/viewform",
                    },
                    { title: "網站導覽", link: "/sitemap" },
                    // { title: "複製平台網址", link: "/park_dashboard/QIP003" },
                    {
                      title: "操作指南",
                      link: "https://smartpark-guideline.gitbook.io/smartpark_guideline/",
                    },
                  ].map((item) => {
                    return (
                      <StyledLink
                        title={`另開新視窗，前往${item.title}`}
                        key={item.title}
                        to={
                          item.title !== "自訂廠商資料"
                            ? { pathname: item.link }
                            : item.link
                        }
                        target="_blank"
                        rel="noopener noreferrer">{`> ${item.title}`}</StyledLink>
                    );
                  })}
                </BoxWrapper>
              </Box>
            </div>
          </div>
          <div className="footer">
            <div className="about-container">
              <Box
                display={"flex"}
                flexDirection={{
                  xs: "column",
                  md: "row",
                }}
                justifyContent={"space-between"}>
                <Box
                  display={'flex'}
                  flexDirection="column"
                  alignItems={{
                    lg: "flex-start",
                    xs: "center",
                  }}
                >
                  <img
                    src={mdirLogo2023}
                    alt="情資平台 logo"
                    style={{ height: "48px", marginBottom: "16px" }}
                  />
                  <a
                    href="https://accessibility.moda.gov.tw/Applications/Detail?category=20240117153317"
                    title="無障礙網站"
                  >
                    <img
                      src={accessibilityLogo}
                      border="0"
                      width="88"
                      height="31"
                      alt="通過A無障礙網頁檢測"
                    />
                  </a>
                </Box>
                <Box
                  display={"flex"}
                  mt={{
                    xs: 2,
                    md: 0,
                  }}
                  flexDirection={{
                    xs: "column",
                    md: "row",
                  }}>
                  <Box mr={2}>
                    <p>版權所有 © 產業園區廠商情資平台</p>
                    <p>客服窗口：陳小姐 07-3513121 分機 2415 </p>
                    <p>服務時間：週一至週五(非國定假日) 09:00～17:00</p>
                    <p>客服信箱：meer@mail.mirdc.org.tw</p>
                    <StyledAWrapper mt={2} display={"flex"}>
                      <a
                        title={`另開新視窗，前往 Line`}
                        href="https://line.me/R/ti/p/@117uphax?from=page"
                        target="_blank"
                        rel="noopener noreferrer">
                        Line
                      </a>
                      <a
                        title={`另開新視窗，前往 Facebook`}
                        href="https://www.facebook.com/%E7%94%A2%E6%A5%AD%E6%83%85%E8%B3%87%E9%80%9Asmartpark-108158701085956"
                        target="_blank"
                        rel="noopener noreferrer">
                        Facebook
                      </a>
                    </StyledAWrapper>
                  </Box>
                  <Box>
                    <p>
                      經濟部產業園區管理局委辦產業園區價值形塑暨廠商情資加值計畫
                      ©
                    </p>
                    <p>{currentYear} AllRights Reserved </p>
                    <p>
                      v.{cachedVersion}{" "}
                      <span>
                        {version !== cachedVersion &&
                          "(您目前使用的是較舊的版本)"}
                      </span>
                    </p>
                    <p>瀏覽人次 : {viewCount}</p>
                    <StyledAWrapper mt={2} display={"flex"}>
                      <a
                        title={`另開新視窗，前往隱私權政策`}
                        href="/privacy_policy"
                        target="_blank"
                        rel="noopener noreferrer">
                        隱私權政策
                      </a>
                      <a
                        title={`另開新視窗，前往資訊安全政策`}
                        href="/information_security_policy"
                        target="_blank"
                        rel="noopener noreferrer">
                        資訊安全政策
                      </a>
                      <a
                        title={`另開新視窗，前往網路安全保護措拖`}
                        href="/internet_security_protection"
                        target="_blank"
                        rel="noopener noreferrer">
                        網路安全保護措拖
                      </a>
                    </StyledAWrapper>
                  </Box>
                </Box>
              </Box>
            </div>
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
};

const AboutText = styled.p`
  line-height: 25px;
`;

const SearchDashboard = styled(FormControl)`
  margin-top: 0px !important;
  margin-left: 20px !important;
  @media (max-width: 768px) {
    margin-top: 24px !important;
  }
`;

const RedLinkBoxContainer = styled(Link)`
  width: calc(100% / 2 - 30px);
  @media (max-width: 768px) {
    width: 100%;
  }
  margin: 0 auto;
  background: rgb(223, 87, 119);
  background: linear-gradient(180deg, rgb(112 196 185) 0%, rgb(37 117 138) 100%);
  padding: 24px;
  display: flex;
  align-items: center;
  color: white;
  border-radius: 16px;
  margin-bottom: 24px;
  color: #ffffff;
  text-decoration: none;
  &:hover {
    opacity: 0.8;
    color: #ffffff;
  }
  img {
    width: 55%;
  }
  svg {
    color: #ffffff;
    fill: #ffffff;
  }
`;
const RedLinkBoxContent = styled.div`
  margin-left: 12px;
  h2 {
    color: #ffffff;
    font-size: 1.125rem;
    font-weight: 700;
    margin-right: 8px;
    margin-bottom: 4px;
  }
  p {
    color: #ffffff;
  }
`;
const BoxWrapper = styled(Box)`
  margin-right: ${(props) => (props.last ? "0px" : "8%")};
  @media (max-width: 576px) {
    margin-right: 0px;
  }
`;
const TitleLink = styled(Link)`
  margin-bottom: 4px;
  color: #ffffff;
  line-height: 2;
  text-decoration: underline;
  &:hover {
    color: #ffffff;
    text-decoration: underline;
    opacity: 0.8;
  }
`;
const StyledLink = styled(Link)`
  color: #ffffff;
  line-height: 2;
  text-decoration: underline;
  &:hover {
    color: #ffffff;
    text-decoration: underline;
    opacity: 0.8;
  }
`;
const LinkBoxContainer = styled(Box)`
  margin-left: -12px;
  margin-right: -12px;
  @media (max-width: 1195px) {
    margin-left: auto;
    margin-right: auto;
    max-width: 95%;
  }
`;
const LinkBox = styled.a`
  display: block;
  width: calc(25% - 24px);
  padding: 0px 12px;
  margin-left: 12px;
  margin-right: 12px;
  margin-bottom: 24px;
  text-align: center;
  background: white;
  border-radius: 16px;
  overflow: hidden;
  color: #212529;
  @media (max-width: 996px) {
    width: 33%;
  }
  @media (max-width: 768px) {
    width: 49%;
    padding: 0px 8px;
  }
  @media (max-width: 576px) {
    padding: 0px 4px;
  }
  &:hover {
    color: #25758A !important;
    text-decoration: underline;
  }
  .content {
    border: 3px solid #ffffff;
  }
  img {
    display: block;
    width: 100%;
    height: 120px;
    object-fit: contain;
    background: white;
    @media (max-height: 805px) {
      height: 12vh;
    }
  }
  p {
    font-size: 1.125rem;
    margin-top: 8px;
    margin-bottom: 8px;
    @media (max-width: 576px) {
      font-size: 1rem;
    }
  }
`;

const StyledAWrapper = styled(Box)`
  a:first-child,
  a:not(:last-child) {
    padding-right: 12px;
  }
  a:not(:first-child) {
    padding-left: 12px;
    border-left: 1px solid rgba(255, 255, 255, 0.7);
  }
`;


export default About;
