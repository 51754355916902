import React from "react";
import Icon from "@material-ui/core/Icon";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";

import TypeTable from "./TypeTable";
import ImportAndExportTable from "./ImportAndExportTable";
import GovPlanAndAwardTable from "./GovPlanAndAwardTable";
import PublciStockTable from "./PublciStockTable";
import PatentTable from "./PatentTable";
import TopTenGovPlan from "./TopTenGovPlan";
import GoogleTrends from "../WordCloud/GoogleTrends";
import Spinner from "../Spinner/Spinner";
import {
  getTypeTwoListDataHandler,
  getTypeThreeListDataHandler,
  getTypeFourListDataHandler,
  getImportListData,
  getExportListData,
  getAwardListData,
  getGovPlanListData,
  getPublicStockListDataHandler,
  getPatentListDataHandler,
  getTopTenGovPlanListDataHandler,
  getTaxRankListHandler,
} from "../../store/actions/cityDashbroad";

import "./styles.scss";

import * as theme from "../../assets/theme";

export default (props) => {
  const dispatch = useDispatch();
  const {
    modalType,
    referenceCode,
    cityId,
    handleClose,
    title,
    selectedCity,
    persistModalOpenHandler,
  } = props;
  const {
    typeTwoList,
    typeThreeList,
    typeFourList,
    importList,
    exportList,
    govPlanList,
    awardList,
    publicStockList,
    patentList,
    topTenGovPlan,
    taxRankList,
  } = useSelector((state) => state.cityDashboard);

  const [state, setState] = React.useState({
    title: "",
    content: "",
    listContent: "",
    header: "",
    loading: false,
    data: [],
    page: 0,
    rowsPerPage: 5,
  });

  const url = window.location.origin;

  const openNewTabs = (link) => window.open(link, "_blank");

  React.useEffect(() => {
    if (modalType) {
      switch (modalType) {
        case "typeTwo":
          dispatch(getTypeTwoListDataHandler(cityId, referenceCode));
          break;
        case "typeThree":
          dispatch(getTypeThreeListDataHandler(cityId, referenceCode));
          break;
        case "typeFour":
          dispatch(getTypeFourListDataHandler(cityId, referenceCode));
          break;
        case "export":
          dispatch(getExportListData(cityId, referenceCode));
          break;
        case "import":
          dispatch(getImportListData(cityId, referenceCode));
          break;
        case "govPlan":
          dispatch(getGovPlanListData(cityId, referenceCode));
          break;
        case "award":
          dispatch(getAwardListData(cityId, referenceCode));
          break;
        case "patent":
          dispatch(getPatentListDataHandler(referenceCode, 3));
          break;
        case "publicStock":
          dispatch(getPublicStockListDataHandler(cityId, referenceCode));
          break;
        case "topTenGovPlan":
          dispatch(getTopTenGovPlanListDataHandler(referenceCode));
          break;
        case "taxRank":
          dispatch(getTaxRankListHandler(cityId, referenceCode));
          break;
        default:
          return "取得 modal 時發生問題。";
      }
    }
  }, [modalType, referenceCode, cityId]);

  React.useEffect(() => {
    const { typeTwoListDataLoading } = typeTwoList;
    const { typeThreeListDataLoading } = typeThreeList;
    const { typeFourListDataLoading } = typeFourList;
    const { importListDataLoading } = importList;
    const { exportListDataLoading } = exportList;
    const { awardListDataLoading } = awardList;
    const { govPlanListDataLoading } = govPlanList;
    const { patentListDataLoading } = patentList;
    const { publicStockListDataLoading } = publicStockList;
    const { topTenGovPlanListDataLoading } = topTenGovPlan;
    const { taxRankListDataLoading } = taxRankList;

    if (
      !typeTwoListDataLoading &&
      !typeThreeListDataLoading &&
      !typeFourListDataLoading &&
      !importListDataLoading &&
      !exportListDataLoading &&
      !awardListDataLoading &&
      !govPlanListDataLoading &&
      !patentListDataLoading &&
      !publicStockListDataLoading &&
      !topTenGovPlanListDataLoading &&
      !taxRankListDataLoading
    ) {
      setState((state) => ({ ...state, loading: false }));
    } else {
      setState((state) => ({ ...state, loading: true }));
    }
  }, [
    typeTwoList.typeTwoListDataLoading,
    typeThreeList.typeThreeListDataLoading,
    typeFourList.typeFourListDataLoading,
    importList.importListDataLoading,
    exportList.exportListDataLoading,
    awardList.awardListDataLoading,
    govPlanList.govPlanListDataLoading,
    patentList.patentListDataLoading,
    publicStockList.publicStockListDataLoading,
    topTenGovPlan.topTenGovPlanListDataLoading,
    taxRankList.taxRankListDataLoading,
  ]);

  React.useEffect(() => {
    persistModalOpenHandler(state.loading); //當資料在 load 的時候不要讓 modal 關掉
  }, [state]);

  return (
    <div className="dashboard-modal-content-container">
      {state.loading ? (
        <Spinner />
      ) : (
        <>
          <div className="dashboard-modal-content-container-head">
            {(modalType === "typeTwo" ||
              modalType === "typeThree" ||
              modalType === "taxRank" ||
              modalType === "award" ||
              modalType === "govPlan") && <h2>{title} 廠商資料</h2>}
            {modalType === "typeFour" && (
              <h2>
                <span
                  onClick={() =>
                    openNewTabs(
                      `${url}/industry_index/${title.substring(0, 4)}`
                    )
                  }
                >
                  {title.substring(0, 4)}
                </span>
                {title.substring(4, title.length)}廠商資料
              </h2>
            )}
            {modalType === "import" && <h2>{title} 進口級距廠商資料</h2>}
            {modalType === "export" && <h2>{title} 出口級距廠商資料</h2>}
            {modalType === "patent" && (
              <h2>
                <span
                  onClick={() =>
                    openNewTabs(
                      `${url}/factory_info/basic_info/${referenceCode}`
                    )
                  }
                >
                  {title}
                </span>
                專利資料
              </h2>
            )}
            {modalType === "topTenGovPlan" && (
              <ClickableTitle
                onClick={() =>
                  openNewTabs(`${url}/factory_info/basic_info/${referenceCode}`)
                }
              >
                <span>{title}</span>政府計畫資料
              </ClickableTitle>
            )}
            {modalType === "publicStock" && <h2>{title} 公司列表</h2>}
            {modalType === "sectionNewsTag" && <h2>{title} Google搜尋趨勢</h2>}
            <Icon className="icon" onClick={handleClose}>
              close
            </Icon>
          </div>
          {modalType === "typeTwo" && (
            <TypeTable data={typeTwoList.typeTwoListData} />
          )}
          {modalType === "typeThree" && (
            <TypeTable data={typeThreeList.typeThreeListData} />
          )}
          {modalType === "typeFour" && (
            <TypeTable data={typeFourList.typeFourListData} />
          )}
          {modalType === "taxRank" && (
            <TypeTable data={taxRankList.taxRankListData} />
          )}
          {modalType === "import" && (
            <ImportAndExportTable
              data={importList.importListData}
              selectedCity={selectedCity}
            />
          )}
          {modalType === "export" && (
            <ImportAndExportTable
              data={exportList.exportListData}
              selectedCity={selectedCity}
            />
          )}
          {modalType === "award" && (
            <GovPlanAndAwardTable data={awardList.awardListData} />
          )}
          {modalType === "govPlan" && (
            <GovPlanAndAwardTable data={govPlanList.govPlanListData} />
          )}
          {modalType === "patent" && (
            <PatentTable data={patentList.patentListData} />
          )}
          {modalType === "publicStock" && (
            <PublciStockTable data={publicStockList.publicStockListData} />
          )}
          {modalType === "topTenGovPlan" && (
            <TopTenGovPlan data={topTenGovPlan.topTenGovPlanListData} />
          )}
          {modalType === "sectionNewsTag" && <GoogleTrends keyword={title} />}
        </>
      )}
    </div>
  );
};

const ClickableTitle = styled.h2`
  > span {
    text-decoration: underline;
    color: ${theme.linkBlue};
    transition: 2s;
    cursor: pointer;

    &:hover {
      transition: 2s;
      color: ${theme.linkHoveredBlue};
    }
  }
`;
