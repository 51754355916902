export const typeTwo = [
  //  { Key: "01", Value: "農、牧業 " },
  //  { Key: "02", Value: "林業 " },
  //  { Key: "03", Value: "漁業 " },
  //  { Key: "05", Value: "石油及天然氣礦業 " },
  //  { Key: "06", Value: "砂、石採取及其他礦業 " },
  { Key: "08", Value: "食品及飼品製造業 " },
  { Key: "09", Value: "飲料製造業 " },
  //  { Key: "10", Value: "菸草製造業 " },
  { Key: "11", Value: "紡織業 " },
  { Key: "12", Value: "成衣及服飾品製造業 " },
  { Key: "13", Value: "皮革、毛皮及其製品製造業 " },
  { Key: "14", Value: "木竹製品製造業 " },
  { Key: "15", Value: "紙漿、紙及紙製品製造業 " },
  { Key: "16", Value: "印刷及資料儲存媒體複製業 " },
  { Key: "17", Value: "石油及煤製品製造業" },
  {
    Key: "18",
    Value: "化學原材料、肥料、氮化合物、塑橡膠原料及人造纖維 ",
  },
  { Key: "19", Value: "其他化學製品製造業 " },
  { Key: "20", Value: "藥品及醫用化學製品製造業 " },
  { Key: "21", Value: "橡膠製品製造業 " },
  { Key: "22", Value: "塑膠製品製造業 " },
  { Key: "23", Value: "非金屬礦物製品製造業 " },
  { Key: "24", Value: "基本金屬製造業 " },
  { Key: "25", Value: "金屬製品製造業 " },
  { Key: "26", Value: "電子零組件製造業 " },
  { Key: "27", Value: "電腦、電子產品及光學製品製造業 " },
  { Key: "28", Value: "電力設備及配備製造業 " },
  { Key: "29", Value: "機械設備製造業 " },
  { Key: "30", Value: "汽車及其零件製造業 " },
  { Key: "31", Value: "其他運輸工具及其零件製造業 " },
  { Key: "32", Value: "家具製造業 " },
  { Key: "33", Value: "其他製造業 " },
  { Key: "34", Value: "產業用機械設備維修及安裝業 " },
  //  { Key: "35", Value: "電力及燃氣供應業" },
  //  { Key: "36", Value: "用水供應業 " },
  //  { Key: "37", Value: "廢水及污水處理業 " },
  //  { Key: "38", Value: "廢棄物清除、處理及資源回收處理業 " },
  //  { Key: "39", Value: "污染整治業 " },
  //  { Key: "41", Value: "建築工程業 " },
  //  { Key: "42", Value: "土木工程業 " },
  //  { Key: "43", Value: "專門營造業 " },
  //  { Key: "45", Value: "批發業 " },
  //  { Key: "46", Value: "批發業 " },
  //  { Key: "47", Value: "零售業 " },
  //  { Key: "48", Value: "零售業 " },
  //  { Key: "49", Value: "陸上運輸業 " },
  //  { Key: "50", Value: "水上運輸業 " },
  //  { Key: "51", Value: "航空運輸業 " },
  //  { Key: "52", Value: "運輸輔助業 " },
  //  { Key: "53", Value: "倉儲業 " },
  //  { Key: "54", Value: "郵政及快遞業 " },
  //  { Key: "55", Value: "住宿業 " },
  //  { Key: "56", Value: "餐飲業 " },
  //  { Key: "58", Value: "出版業 " },
  //  { Key: "59", Value: "影片及電視節目業；聲音錄製及音樂發行業 " },
  //  { Key: "60", Value: "廣播、電視節目編排及傳播業 " },
  //  { Key: "61", Value: "電信業 " },
  //  { Key: "62", Value: "電腦程式設計、諮詢及相關服務業 " },
  //  { Key: "63", Value: "資訊服務業 " },
  //  { Key: "64", Value: "金融服務業 " },
  //  { Key: "65", Value: "保險業 " },
  //  { Key: "66", Value: "證券期貨及金融輔助業 " },
  //  { Key: "67", Value: "不動產開發業 " },
  //  { Key: "68", Value: "不動產經營及相關服務業 " },
  //  { Key: "69", Value: "法律及會計服務業 " },
  //  { Key: "70", Value: "企業總管理機構及管理顧問業" },
  //  { Key: "71", Value: "建築、工程服務及技術檢測、分析服務業 " },
  //  { Key: "72", Value: "研究發展服務業 " },
  //  { Key: "73", Value: "廣告業及市場研究業 " },
  //  { Key: "74", Value: "專門設計業 " },
  //  { Key: "75", Value: "獸醫業 " },
  //  { Key: "76", Value: "其他專業、科學及技術服務業 " },
  //  { Key: "77", Value: "租賃業 " },
  //  { Key: "78", Value: "人力仲介及供應業 " },
  //  { Key: "79", Value: "旅行及相關服務業 " },
  //  { Key: "80", Value: "保全及偵探業 " },
  //  { Key: "81", Value: "建築物及綠化服務業 " },
  //  { Key: "82", Value: "行政支援服務業 " },
  //  { Key: "83", Value: "公共行政及國防；強制性社會安全 " },
  //  { Key: "84", Value: "國際組織及外國機構 " },
  //  { Key: "85", Value: "教育業 " },
  //  { Key: "86", Value: "醫療保健業 " },
  //  { Key: "87", Value: "居住型照顧服務業 " },
  //  { Key: "88", Value: "其他社會工作服務業 " },
  //  { Key: "90", Value: "創作及藝術表演業 " },
  //  { Key: "91", Value: "圖書館、檔案保存、博物館及類似機構 " },
  //  { Key: "92", Value: "博弈業 " },
  //  { Key: "93", Value: "運動、娛樂及休閒服務業 " },
  //  { Key: "94", Value: "宗教、職業及類似組織 " },
  //  { Key: "95", Value: "個人及家庭用品維修業 " },
  //  { Key: "96", Value: "未分類其他服務業 " },
];

export const typeFour = [
  // { Key: "0500", Value: "石油及天然氣礦業", Cnt: 2 },
  // { Key: "0600", Value: "砂、石採取及其他礦業", Cnt: 6 },
  { Key: "0811", Value: "屠宰業", Cnt: 2 },
  { Key: "0812", Value: "肉類其他加工及保藏業", Cnt: 2 },
  { Key: "0820", Value: "水產加工及保藏業", Cnt: 2 },
  { Key: "0830", Value: "蔬果加工及保藏業", Cnt: 3 },
  { Key: "0840", Value: "動植物油脂製造業", Cnt: 3 },
  { Key: "0850", Value: "乳品製造業", Cnt: 6 },
  { Key: "0861", Value: "碾榖業", Cnt: 1 },
  { Key: "0862", Value: "磨粉製品製造業", Cnt: 1 },
  { Key: "0870", Value: "動物飼品製造業", Cnt: 1 },
  { Key: "0891", Value: "烘焙炊蒸食品製造業", Cnt: 2 },
  { Key: "0892", Value: "麵條及粉條類食品製造業", Cnt: 2 },
  { Key: "0893", Value: "製糖業", Cnt: 0 },
  { Key: "0894", Value: "巧克力及糖果製造業", Cnt: 1 },
  { Key: "0895", Value: "製茶業", Cnt: 1 },
  { Key: "0896", Value: "調味品製造業", Cnt: 4 },
  { Key: "0897", Value: "膳食及菜餚製造業", Cnt: 2 },
  { Key: "0898", Value: "保健營養品製造業", Cnt: 3 },
  { Key: "0899", Value: "未分類其他食品製造業", Cnt: 3 },
  { Key: "0911", Value: "啤酒製造業", Cnt: 1 },
  { Key: "0919", Value: "其他酒精飲料製造業", Cnt: 1 },
  { Key: "0920", Value: "非酒精飲料製造業", Cnt: 7 },
  { Key: "1000", Value: "菸草製造業", Cnt: 1 },
  { Key: "1111", Value: "棉毛紡紗業", Cnt: 3 },
  { Key: "1112", Value: "人造纖維紡紗業", Cnt: 3 },
  { Key: "1113", Value: "人造纖維加工絲業", Cnt: 4 },
  { Key: "1121", Value: "棉毛梭織布業", Cnt: 1 },
  { Key: "1122", Value: "人造纖維梭織布業", Cnt: 4 },
  { Key: "1123", Value: "玻璃纖維梭織布業", Cnt: 1 },
  { Key: "1124", Value: "針織布業", Cnt: 2 },
  { Key: "1130", Value: "不織布業", Cnt: 1 },
  { Key: "1140", Value: "染整業", Cnt: 3 },
  { Key: "1151", Value: "紡織製成品製造業", Cnt: 3 },
  { Key: "1152", Value: "繩、纜及網製造業", Cnt: 1 },
  { Key: "1159", Value: "其他紡織品製造業", Cnt: 2 },
  { Key: "1210", Value: "成衣製造業", Cnt: 2 },
  { Key: "1230", Value: "服飾品製造業", Cnt: 5 },
  { Key: "1301", Value: "皮革及毛皮整製業", Cnt: 3 },
  { Key: "1302", Value: "鞋類製造業", Cnt: 3 },
  { Key: "1303", Value: "行李箱及手提袋製造業", Cnt: 2 },
  { Key: "1309", Value: "其他皮革及毛皮製品製造業", Cnt: 1 },
  { Key: "1401", Value: "製材業", Cnt: 1 },
  { Key: "1402", Value: "合板及組合木材製造業", Cnt: 2 },
  { Key: "1403", Value: "建築用木製品製造業", Cnt: 1 },
  { Key: "1404", Value: "木質容器製造業", Cnt: 1 },
  { Key: "1409", Value: "其他木竹製品製造業", Cnt: 1 },
  { Key: "1511", Value: "紙漿製造業", Cnt: 0 },
  { Key: "1512", Value: "紙張製造業", Cnt: 3 },
  { Key: "1513", Value: "紙板製造業", Cnt: 1 },
  { Key: "1520", Value: "瓦楞紙板及紙容器製造業", Cnt: 3 },
  { Key: "1591", Value: "家庭及衛生用紙製造業", Cnt: 3 },
  { Key: "1599", Value: "未分類其他紙製品製造業", Cnt: 2 },
  { Key: "1601", Value: "印刷業", Cnt: 1 },
  { Key: "1602", Value: "印刷輔助業", Cnt: 2 },
  { Key: "1700", Value: "石油及煤製品製造業", Cnt: 3 },
  { Key: "1810", Value: "化學原材料製造業", Cnt: 12 },
  { Key: "1830", Value: "肥料及氮化合物製造業", Cnt: 2 },
  { Key: "1841", Value: "塑膠原料製造業", Cnt: 13 },
  { Key: "1842", Value: "合成橡膠原料製造業", Cnt: 3 },
  { Key: "1850", Value: "人造纖維製造業", Cnt: 4 },
  { Key: "1910", Value: "農藥及環境用藥製造業", Cnt: 2 },
  { Key: "1920", Value: "塗料、染料及顏料製造業", Cnt: 4 },
  { Key: "1931", Value: "清潔用品製造業", Cnt: 3 },
  { Key: "1932", Value: "化粧品製造業", Cnt: 3 },
  { Key: "1990", Value: "未分類其他化學製品製造業", Cnt: 7 },
  { Key: "2001", Value: "原料藥製造業", Cnt: 1 },
  { Key: "2002", Value: "西藥製造業", Cnt: 5 },
  { Key: "2003", Value: "醫用生物製品製造業", Cnt: 1 },
  { Key: "2004", Value: "中藥製造業", Cnt: 1 },
  { Key: "2005", Value: "醫用化學製品製造業", Cnt: 3 },
  { Key: "2101", Value: "輪胎製造業", Cnt: 4 },
  { Key: "2102", Value: "工業用橡膠製品製造業", Cnt: 3 },
  { Key: "2109", Value: "其他橡膠製品製造業", Cnt: 3 },
  { Key: "2201", Value: "塑膠皮、板及管材製造業", Cnt: 10 },
  { Key: "2202", Value: "塑膠膜袋製造業", Cnt: 2 },
  { Key: "2203", Value: "塑膠外殼及配件製造業", Cnt: 2 },
  { Key: "2209", Value: "其他塑膠製品製造業", Cnt: 7 },
  { Key: "2311", Value: "平板玻璃及其製品製造業", Cnt: 1 },
  { Key: "2312", Value: "玻璃容器製造業", Cnt: 1 },
  { Key: "2313", Value: "玻璃纖維製造業", Cnt: 1 },
  { Key: "2319", Value: "其他玻璃及其製品製造業", Cnt: 1 },
  { Key: "2321", Value: "耐火材料製造業", Cnt: 3 },
  { Key: "2322", Value: "黏土建築材料製造業", Cnt: 3 },
  { Key: "2329", Value: "其他陶瓷製品製造業", Cnt: 1 },
  { Key: "2331", Value: "水泥製造業", Cnt: 1 },
  { Key: "2332", Value: "預拌混凝土製造業", Cnt: 1 },
  { Key: "2333", Value: "水泥及混凝土製品製造業", Cnt: 4 },
  { Key: "2340", Value: "石材製品製造業", Cnt: 2 },
  { Key: "2391", Value: "研磨材料製造業", Cnt: 1 },
  { Key: "2399", Value: "未分類其他非金屬礦物製品製造業", Cnt: 3 },
  { Key: "2411", Value: "鋼鐵冶鍊業", Cnt: 3 },
  { Key: "2412", Value: "鋼鐵鑄造業", Cnt: 3 },
  { Key: "2413", Value: "鋼鐵軋延及擠型業", Cnt: 8 },
  { Key: "2414", Value: "鋼鐵伸線業", Cnt: 4 },
  { Key: "2421", Value: "鍊鋁業", Cnt: 1 },
  { Key: "2422", Value: "鋁鑄造業", Cnt: 1 },
  { Key: "2423", Value: "鋁材軋延、擠型及伸線業", Cnt: 3 },
  { Key: "2432", Value: "銅鑄造業", Cnt: 0 },
  { Key: "2433", Value: "銅材軋延、擠型及伸線業", Cnt: 4 },
  { Key: "2491", Value: "其他基本金屬鑄造業", Cnt: 1 },
  { Key: "2499", Value: "未分類其他基本金屬製造業", Cnt: 2 },
  { Key: "2511", Value: "金屬刀具及手工具製造業", Cnt: 5 },
  { Key: "2512", Value: "金屬模具製造業", Cnt: 4 },
  { Key: "2521", Value: "金屬結構製造業", Cnt: 1 },
  { Key: "2522", Value: "金屬建築組件製造業", Cnt: 2 },
  { Key: "2531", Value: "鍋爐、金屬貯槽及壓力容器製造業", Cnt: 1 },
  { Key: "2539", Value: "其他金屬容器製造業", Cnt: 1 },
  { Key: "2541", Value: "金屬鍛造業", Cnt: 1 },
  { Key: "2542", Value: "粉末冶金業", Cnt: 0 },
  { Key: "2543", Value: "金屬熱處理業", Cnt: 0 },
  { Key: "2544", Value: "金屬表面處理業", Cnt: 4 },
  { Key: "2549", Value: "其他金屬加工處理業", Cnt: 5 },
  { Key: "2591", Value: "螺絲、螺帽及鉚釘製造業", Cnt: 2 },
  { Key: "2592", Value: "金屬彈簧及線製品製造業", Cnt: 2 },
  { Key: "2599", Value: "未分類其他金屬製品製造業", Cnt: 7 },
  { Key: "2611", Value: "積體電路製造業", Cnt: 4 },
  { Key: "2612", Value: "分離式元件製造業", Cnt: 2 },
  { Key: "2613", Value: "半導體封裝及測試業", Cnt: 2 },
  { Key: "2620", Value: "被動電子元件製造業", Cnt: 5 },
  { Key: "2630", Value: "印刷電路板製造業", Cnt: 4 },
  { Key: "2641", Value: "面板及其組件製造業", Cnt: 8 },
  { Key: "2642", Value: "發光二極體製造業", Cnt: 2 },
  { Key: "2643", Value: "太陽能電池製造業", Cnt: 2 },
  { Key: "2649", Value: "其他光電材料及元件製造業", Cnt: 3 },
  { Key: "2691", Value: "印刷電路板組件製造業", Cnt: 5 },
  { Key: "2699", Value: "未分類其他電子零組件製造業", Cnt: 3 },
  { Key: "2711", Value: "電腦製造業", Cnt: 3 },
  { Key: "2712", Value: "顯示器及終端機製造業", Cnt: 2 },
  { Key: "2719", Value: "其他電腦週邊設備製造業", Cnt: 4 },
  { Key: "2721", Value: "電話及手機製造業", Cnt: 1 },
  { Key: "2729", Value: "其他通訊傳播設備製造業", Cnt: 10 },
  { Key: "2730", Value: "視聽電子產品製造業", Cnt: 5 },
  { Key: "2740", Value: "資料儲存媒體製造業", Cnt: 1 },
  { Key: "2751", Value: "量測、導航及控制設備製造業", Cnt: 10 },
  { Key: "2752", Value: "鐘錶製造業", Cnt: 3 },
  { Key: "2760", Value: "輻射及電子醫學設備製造業", Cnt: 3 },
  { Key: "2771", Value: "照相機製造業", Cnt: 1 },
  { Key: "2779", Value: "其他光學儀器及設備製造業", Cnt: 3 },
  { Key: "2810", Value: "發電、輸電及配電機械製造業", Cnt: 7 },
  { Key: "2820", Value: "電池製造業", Cnt: 2 },
  { Key: "2831", Value: "電線及電纜製造業", Cnt: 7 },
  { Key: "2832", Value: "配線器材製造業", Cnt: 2 },
  { Key: "2841", Value: "電燈泡及燈管製造業", Cnt: 3 },
  { Key: "2842", Value: "照明器具製造業", Cnt: 2 },
  { Key: "2851", Value: "家用空調器具製造業", Cnt: 3 },
  { Key: "2859", Value: "其他家用電器製造業", Cnt: 11 },
  { Key: "2890", Value: "其他電力設備及配備製造業", Cnt: 5 },
  { Key: "2912", Value: "金屬切削工具機製造業", Cnt: 10 },
  { Key: "2919", Value: "其他金屬加工用機械設備製造業", Cnt: 5 },
  { Key: "2921", Value: "農用及林用機械設備製造業", Cnt: 2 },
  { Key: "2922", Value: "採礦及營造用機械設備製造業", Cnt: 1 },
  { Key: "2923", Value: "食品、飲料及菸草製作用機械設備製造業", Cnt: 1 },
  { Key: "2924", Value: "紡織、成衣及皮革生產用機械設備製造業", Cnt: 6 },
  { Key: "2925", Value: "木工機械設備製造業", Cnt: 4 },
  { Key: "2926", Value: "化工機械設備製造業", Cnt: 1 },
  { Key: "2927", Value: "橡膠及塑膠加工用機械設備製造業", Cnt: 3 },
  { Key: "2928", Value: "電子及半導體生產用機械設備製造業", Cnt: 3 },
  { Key: "2929", Value: "未分類其他專用機械設備製造業", Cnt: 5 },
  { Key: "2933", Value: "泵、壓縮機、活栓及活閥製造業", Cnt: 7 },
  { Key: "2934", Value: "機械傳動設備製造業", Cnt: 6 },
  { Key: "2935", Value: "輸送機械設備製造業", Cnt: 5 },
  { Key: "2936", Value: "事務機械設備製造業", Cnt: 1 },
  { Key: "2937", Value: "污染防治設備製造業", Cnt: 1 },
  { Key: "2938", Value: "動力手工具製造業", Cnt: 1 },
  { Key: "2939", Value: "其他通用機械設備製造業", Cnt: 9 },
  { Key: "3010", Value: "汽車製造業", Cnt: 6 },
  { Key: "3020", Value: "車體製造業", Cnt: 2 },
  { Key: "3030", Value: "汽車零件製造業", Cnt: 8 },
  { Key: "3110", Value: "船舶及浮動設施製造業", Cnt: 2 },
  { Key: "3121", Value: "機車製造業", Cnt: 3 },
  { Key: "3122", Value: "機車零件製造業", Cnt: 1 },
  { Key: "3131", Value: "自行車製造業", Cnt: 1 },
  { Key: "3132", Value: "自行車零件製造業", Cnt: 1 },
  { Key: "3190", Value: "未分類其他運輸工具及其零件製造業", Cnt: 2 },
  { Key: "3211", Value: "木製家具製造業", Cnt: 2 },
  { Key: "3219", Value: "其他非金屬家具製造業", Cnt: 2 },
  { Key: "3220", Value: "金屬家具製造業", Cnt: 1 },
  { Key: "3311", Value: "體育用品製造業", Cnt: 5 },
  { Key: "3312", Value: "玩具及遊戲機製造業", Cnt: 1 },
  { Key: "3313", Value: "樂器製造業", Cnt: 3 },
  { Key: "3314", Value: "文具製造業", Cnt: 5 },
  { Key: "3321", Value: "眼鏡製造業", Cnt: 4 },
  { Key: "3329", Value: "其他醫療器材及用品製造業", Cnt: 2 },
  { Key: "3391", Value: "珠寶及金工製品製造業", Cnt: 1 },
  { Key: "3392", Value: "拉鍊及鈕扣製造業", Cnt: 2 },
  { Key: "3399", Value: "其他未分類製造業", Cnt: 5 },
  { Key: "3400", Value: "產業用機械設備維修及安裝業", Cnt: 5 },
  { Key: "9500", Value: "非製造業", Cnt: 5 },
  // { Key: "3510", Value: "電力供應業", Cnt: 5 },
  // { Key: "3520", Value: "氣體燃料供應業", Cnt: 0 },
  // { Key: "3600", Value: "用水供應業", Cnt: 1 },
];
