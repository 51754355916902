import * as actionTypes from "../actionTypes/supplychain";

const initState = {
  searchMapLoading: false,
  getOptionLoading: false,
  mapData: [],
  dataSortedByPark: {},
  dataSortedByCity: {},
  dataSortedByInduFourStr: {},
  dataSortedByArea: {},
  supplyChainOptions: [],
  supplyChainData: [],
  allCompanies: []
};

export const startGetMapDataData = (state) => {
  return {
    ...state,
    searchMapLoading: true,
  };
};

export const getMapDataSuccess = (state, action) => {
  const updateDataSortedByPark = {};
  const updateDataSortedByCity = {};
  const updateDataSortedByInduFourStr = {};
  const updateDataSortedByArea = {};
  const updateAllCompanies = []

  if (action.mapData) {
    action.mapData.map((item) => {
      if (updateDataSortedByPark[item.Park]) {
        const updateDataSortedByParkList = updateDataSortedByPark[item.Park];
        updateDataSortedByParkList.push(item);

        updateDataSortedByPark[item.Park] = updateDataSortedByParkList;
      } else {
        const updateDataSortedByParkList = [];
        updateDataSortedByParkList.push(item);

        updateDataSortedByPark[item.Park] = updateDataSortedByParkList;
      }

      if (updateDataSortedByCity[item.CityCode]) {
        const updateDataSortedByCityList =
          updateDataSortedByCity[item.CityCode];
        updateDataSortedByCityList.push(item);

        updateDataSortedByCity[item.CityCode] = updateDataSortedByCityList;
      } else {
        const updateDataSortedByCityList = [];
        updateDataSortedByCityList.push(item);

        updateDataSortedByCity[item.CityCode] = updateDataSortedByCityList;
      }

      if (updateDataSortedByInduFourStr[item.InduFourStr]) {
        const updateDataSortedByInduFourStrList =
          updateDataSortedByInduFourStr[item.InduFourStr];
        updateDataSortedByInduFourStrList.push(item);

        updateDataSortedByInduFourStr[
          item.InduFourStr
        ] = updateDataSortedByInduFourStrList;
      } else {
        const updateDataSortedByInduFourStrList = [];
        updateDataSortedByInduFourStrList.push(item);

        updateDataSortedByInduFourStr[
          item.InduFourStr
        ] = updateDataSortedByInduFourStrList;
      }

      if (updateDataSortedByArea[item.Area]) {
        const updateDataSortedByAreaList = updateDataSortedByArea[item.Area];
        updateDataSortedByAreaList.push(item);

        updateDataSortedByArea[item.Area] = updateDataSortedByAreaList;
      } else {
        const updateDataSortedByAreaList = [];
        updateDataSortedByAreaList.push(item);

        updateDataSortedByArea[item.Area] = updateDataSortedByAreaList;
      }

      if(updateAllCompanies.filter(i => i.name === item.CompanyName).length === 0){
        updateAllCompanies.push({
          name: item.CompanyName,
          businessNo: item.BusinessNo,
          capital: item.CapitalTotal,
          // isIPO : item.StockType == "未上市" ? false : true
          isIPO: item.StockType == "未上市" ? "false" : "true" ,
          isInIndustry: item.isInIndustry
        })
      }
    });
  }

  const sortable = (list) => {
    const updateObj = {};
    const sortableArray = Object.entries(list).sort(
      (a, b) => b[1].length - a[1].length
    );

    sortableArray.map((item) => {
      updateObj[item[0]] = item[1];
    });

    return sortableArray;
  };

  return {
    ...state,
    mapData: action.mapData,
    dataSortedByPark: sortable(updateDataSortedByPark),
    dataSortedByCity: sortable(updateDataSortedByCity),
    dataSortedByInduFourStr: sortable(updateDataSortedByInduFourStr),
    dataSortedByArea: sortable(updateDataSortedByArea),
    allCompanies: updateAllCompanies.sort((a,b) => a.CapitalTotal - b.CapitalTotal),
    searchMapLoading: false,
  };
};

export const getMapDataFail = (state, err) => {
  return {
    ...state,
    searchMapLoading: false,
    errorMessage: err,
  };
};

export const startGetOption = (state) => {
  return {
    ...state,
    getOptionLoading: true,
  };
};

export const getOptionSuccess = (state, action) => {
  const { data } = action;
  const updateOptions = [];

  data.map(option=>{
    if(updateOptions.filter(i => i === option.Industry).length  === 0){
        updateOptions.push(option.Industry);
    }
  })

  return {
    ...state,
    supplyChainData: action.data, 
    supplyChainOptions: updateOptions,
    getOptionLoading: false,
  };
};

export const getOptionFail = (state, err) => {
  return {
    ...state,
    getOptionLoading: false,
    getOptionErr: err,
  };
};

export default (state = initState, action) => {
  switch (action.type) {
    case actionTypes.START_GET_SUPPLYCHAIN_MAP_DATA:
      return startGetMapDataData(state);
    case actionTypes.GET_SUPPLYCHAIN_MAP_DATA_SUCCESS:
      return getMapDataSuccess(state, action);
    case actionTypes.GET_SUPPLYCHAIN_MAP_DATA_FAIL:
      return getMapDataFail(state, action);
    case actionTypes.START_GET_SUPPLYCHAIN_OPTIONS:
      return startGetOption(state);
    case actionTypes.GET_SUPPLYCHAIN_OPTIONS_SUCCESS:
      return getOptionSuccess(state, action);
    case actionTypes.GET_SUPPLYCHAIN_OPTIONS_FAIL:
      return getOptionFail(state, action);
    default:
      return state;
  }
};
