import * as actionTypes from '../actions/actionTypes'

const initState = {
    parks:'',
    industry:'',
    loading:false,
    error:''
};

export default (state = initState, action) => {
  switch (action.type) {
    case actionTypes.INIT_MAP_SELECTION:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.INIT_MAP_SELECTION_SUCCESS:
      return {
        ...state,
        industry: action.industry,
        parks: action.parks,
        loading: false,
      };
    case actionTypes.INIT_MAP_SELECTION_FAIL:
      return {
        ...state,
        error:action.error,
        loading: false,
      };
    default:
      return state;
  }
};
