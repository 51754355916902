import React, { useState, useEffect } from 'react'
import { Grid, Card } from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'


import Table from '../CustomizedTable/CustomizedTable'
import Spinner from '../Spinner/Spinner'
import * as govPlanActions from '../../store/actions/govplan'

import './styles.scss'



const GovPlanCompanyGroup = props => {
    const { induGroupList, loading, deliveryLog, deliveryLogsLoading, induGroupLoading } = useSelector(state => state.govPlan );
    const [ isLoading, setIsLoading ] = useState(false)
    const dispatch = useDispatch()


    useEffect(() => {
      setIsLoading(loading)
    }, [loading])
    

    useEffect(() => {
      dispatch(govPlanActions.getInduGroupHandler())
    }, [])


    const modalHandler = (type, id) => {
      props.modalHandler(type, id)
    }


    return (
      <div className="gov-plan-group">
          <div className='table-container'>
          <Table
            type='row'
            tableTitle={['群組名稱', '聯絡人電子郵件']}
            content={induGroupList}
            isIcon={true}
            iconConfig={[{
              name:'edit',
            }]}
            widthControl={[45,50,5]}
            title={['群組名稱']}
            loading={induGroupLoading}
            modalHandler={modalHandler}
          />
          <Table
            type='logs'
            title={'發放紀錄'}
            content={deliveryLog}
            isIcon={false}
            loading={deliveryLogsLoading}
            // tableTitle={['計畫名稱', '發放單位', '發放時間']}
          />
        </div>
        <div className='table-container-mobile'>
          <Grid
            container
            spacing={3}
            direction="column"
            justify="flex-start"
            alignItems="center"
          >
            {!isLoading && induGroupList ?
              induGroupList.map(item => (
                <Grid item component={Card} className='item'>
                  <div>
                    <label>名稱 :</label> <p>{item.Name}</p>
                  </div>
                  <div>
                    <label>E-mail :</label> <div>{item.Email ? item.Email.split(';').map(cate => <p>{cate}</p> ): <p></p> }</div>
                  </div>
                  {/* <div>
                    <label>廠商 :</label> <div>{item.CompanyList ? item.CompanyList.map(company => <p>{company.CompanyName}</p>) : <p></p>}</div>
                  </div> */}
                </Grid>
              ))
              : <Spinner />}
              {/* {deliveryLog.map(item=> console.log('DELIVERY LOG', item))} */}
          </Grid>     

        </div>
      </div>
    );
}



export default GovPlanCompanyGroup