import React from "react";
import styled from "styled-components";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import AddIcon from "@mui/icons-material/Add";
import Close from "@mui/icons-material/Close";
import { useSelector, useDispatch } from "react-redux";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Swal from "sweetalert2";
import IconButton from "@mui/material/IconButton";
import { useSnackbar } from "notistack";
import Tooltip from "@mui/material/Tooltip";

import {
  handleFetchCustomDashboardList,
  handleCreateCustomDashboardList,
  handleCopyDashboardList,
  handleDeleteCustomDashboardList,
} from "../store/actions/custom_dashboard";
import CustomUpdateDashboardListModal from "../components/CustomUpdateDashboardListModal";
import CustomDashboardAddListModal from "../components/CustomDashboardAddListModal";
import { message } from "../utility/common";

import SlideModal from "../components/SlideModal";
import Spinner from "../components/Spinner/Spinner";
import CustomDashboardListItem from "../components/CustomDashboardListItem";

import * as theme from "../assets/theme";

const themeColor = createTheme({
  palette: {
    primary: {
      main: "#25758A",
    },
  },
});

const CustomDashboardList = () => {
  const {
    custom_dashboard_list,
    custom_dashboard_list_loading,
    custom_create_dashboard_list_loading,
  } = useSelector((state) => state.custom_dashboard);
  const { innerWidth } = useSelector(state => state.app); 
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [state, setState] = React.useState({
    add_type: "create", // 新增儀表板
    is_add_dashboard_open: false, //新增儀表板
    is_update_dashboard_detail_open: false, //編輯儀表板
    is_add_list_to_dashboard_detail_open: false, //新增儀表板統編
    create_dashboard_name: "",
    opened_dashboard: "",
  });

  React.useEffect(() => {
    dispatch(handleFetchCustomDashboardList());
  }, []);

  React.useEffect(() => {
    dispatch(handleFetchCustomDashboardList());
  }, [custom_create_dashboard_list_loading]);

  const handleToggleModal = (name) => {
    setState((state) => ({ ...state, [name]: !state[name],create_dashboard_name: ""  }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((state) => ({ ...state, [name]: value }));
  };

  const handleCreateCustomDashboard = () => {
    if (state.add_type === "create") {
      // 新增儀表板
      dispatch(handleCreateCustomDashboardList(state.create_dashboard_name));

      setTimeout(() => {
        if (!custom_create_dashboard_list_loading) {
          message(
            enqueueSnackbar,
            `${state.create_dashboard_name}新增成功!`,
            "success"
          );
        }
      }, 1000);
    } else {
      // 複製儀表板功能
      dispatch(handleCopyDashboardList(state.create_dashboard_name)).then(() => {
        message(enqueueSnackbar, "複製儀表板成功", "success");
      }).catch(err => {
        message(enqueueSnackbar, "複製儀表板失敗", "error");
      });
    }
    

    setState((state) => ({
      ...state,
      create_dashboard_name: "",
      is_add_dashboard_open: false,
    }));
  };

  const handle_open_create_board = () => {
    handleToggleModal("is_add_dashboard_open");
  };

  const handle_update_board = (board_id) => {
    setState((state) => ({ ...state, opened_dashboard: board_id }));
    handleToggleModal("is_update_dashboard_detail_open");
  };

  const handle_insert_number_to_board = (board_id) => {
    setState((state) => ({ ...state, opened_dashboard: board_id }));
    handleToggleModal("is_add_list_to_dashboard_detail_open");
  };

  const handle_delete_board = (board_id) => {
    Swal.fire({
      title: `確定要刪除${
        custom_dashboard_list.filter((i) => i.BoardID === board_id)[0].BoardName
      }專案？`,
      text: "專案刪除後將無法復原。",
      showCancelButton: true,
      confirmButtonColor: "#25758A",
      cancelButtonColor: "#bcb9b9",
      confirmButtonText: "確定刪除",
      cancelButtonText: "取消",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(handleDeleteCustomDashboardList(board_id));
      }
    });
  };

  let content; //儀表板內容
  if (custom_dashboard_list_loading || custom_create_dashboard_list_loading) {
    content = (
      <CustomDashboardListContentContainer>
        <Spinner />
      </CustomDashboardListContentContainer>
    );
  } else {
    if (custom_dashboard_list.length === 0) {
      content = (
        <CustomDashboardListContentContainer>
          <CustomDashboardListContentNoDataText>
            目前無自訂關注廠商儀表板資料，您可以點選右上角按鈕來新增自訂關注廠商儀表板。
          </CustomDashboardListContentNoDataText>
        </CustomDashboardListContentContainer>
      );
    } else {
      content = (
        <CustomDashboardListContentContainer is_grid>
          {custom_dashboard_list.sort((a,b) => new Date(b.UpdateDate) - new Date(a.UpdateDate) ).map((i) => (
            <CustomDashboardListItem
              key={i.BoardID}
              board_id={i.BoardID}
              handle_delete_board={handle_delete_board}
              handle_update_board={handle_update_board}
              handle_insert_number_to_board={handle_insert_number_to_board}
              name={i.BoardName}
              company_count={i.CompanyCnts}
              update_time={i.UpdateDate.split("T")[0]}
            />
          ))}
        </CustomDashboardListContentContainer>
      );
    }
  };

  let create_dashboard_btn;
  if(custom_dashboard_list.length === 3){
    create_dashboard_btn = (
      <Tooltip title={"儀表板數量限制3個"}>
      <StyledButton
        onClick={handle_open_create_board}
        startIcon={<AddIcon />}
        disabled
      >
        新增儀表板
      </StyledButton>
    </Tooltip>
    )
  }else{
    create_dashboard_btn = (
      <StyledButton
      onClick={handle_open_create_board}
      startIcon={<AddIcon />}
    >
      新增儀表板
    </StyledButton>
    )
  }

  return (
    <CustomDashboardListWrapper>
      {/* 新增統編到儀表板 */}
      <SlideModal
        handleClose={() =>
          handleToggleModal("is_add_list_to_dashboard_detail_open")
        }
        is_open={state.is_add_list_to_dashboard_detail_open}
      >
        <CustomDashboardAddListModal
          handleClose={() => {
            setState((state) => ({ ...state, opened_dashboard: "" }));
            handleToggleModal("is_add_list_to_dashboard_detail_open");
          }}
          handle_open_update_board={(board_id)=> {
            setState((state) => ({ ...state, opened_dashboard: board_id }));
            handleToggleModal("is_add_list_to_dashboard_detail_open");
            setTimeout(()=>{
              handleToggleModal("is_update_dashboard_detail_open");
            }, 2000)
          }}
          should_api_call={state.is_add_list_to_dashboard_detail_open}
          id={state.opened_dashboard}
        />
      </SlideModal>
      {/* 編輯儀表板 */}
      <SlideModal
        handleClose={() => handleToggleModal("is_update_dashboard_detail_open")}
        is_open={state.is_update_dashboard_detail_open}
      >
        <CustomUpdateDashboardListModal
          handleClose={() => {
            setState((state) => ({ ...state, opened_dashboard: "" }));
            handleToggleModal("is_update_dashboard_detail_open");
          }}
          should_api_call={state.is_update_dashboard_detail_open}
          id={state.opened_dashboard}
        />
      </SlideModal>
      {/* 新增儀表板 */}
      <SlideModal
        handleClose={() => handleToggleModal("is_add_dashboard_open")}
        is_open={state.is_add_dashboard_open}
      >
        <CustomDashboardListDetailModalWrapper>
          <CustomDashboardListDetailModalCloseContainer>
            <IconButton
              onClick={() => {
                handleToggleModal("is_add_dashboard_open");
              }}
            >
              <Close />
            </IconButton>
          </CustomDashboardListDetailModalCloseContainer>
          <CustomDashboardListDetailModalHeadContainer>
            <CustomDashboardListDetailModalHeadText>
              新增儀表板
            </CustomDashboardListDetailModalHeadText>
          </CustomDashboardListDetailModalHeadContainer>
          <CustomDashboardListDetailModalSubHeadContainer>
            <CustomDashboardListDetailModalHeadSubText>
              提供輸入公司名稱關鍵字，平台將輔助您選擇公司，確認公司後可以點選 + 新增公司進儀表板。 或是透過逗號（,）將統編分隔，批次新增多筆統編(公司) (注意：統編需為半形數字)。
            </CustomDashboardListDetailModalHeadSubText>
          </CustomDashboardListDetailModalSubHeadContainer>
          <FormControl>
            <RadioGroup
              row
              name="row-radio-buttons-group"
              value={state.add_type}
              onChange={(e) => {
                setState(state => ({ ...state, add_type: e.target.value}))
              }}
            >
              <FormControlLabel value="create" control={<Radio size="small" />} label="新增新儀表板" />
              <FormControlLabel value="copy" control={<Radio size="small" />} label="加入其他人的儀表板編號" />
            </RadioGroup>
          </FormControl>
          <CustomDashboardListDetailModalInputContainer>
            <TextField
              label={state.add_type === "create"? "儀表板名稱" : "欲複製的儀表板編號"}
              variant="outlined"
              size="small"
              sx={innerWidth < 750 ? {width: "60%"} : { width: "70%" }}
              value={state.create_dashboard_name}
              name={"create_dashboard_name"}
              onChange={handleChange}
            />
            <StyledButton
              disabled={!state.create_dashboard_name}
              onClick={handleCreateCustomDashboard}
              sx={{ marginLeft: "20px" }}
            >
              新增儀表板
            </StyledButton>
          </CustomDashboardListDetailModalInputContainer>
        </CustomDashboardListDetailModalWrapper>
      </SlideModal>
      <CustomDashboardListHeadContainer>
        <CustomDashboardListHeadTextContainer>
          <CustomDashboardListHeadText>自訂關注廠商儀表板</CustomDashboardListHeadText>
          {create_dashboard_btn}
        </CustomDashboardListHeadTextContainer>
        <CustomDashboardListSubHeadTextContainer>
          <CustomDashboardListSubHeadText>
          提供您可以將欲關注的廠商群體加入自訂關注廠商儀表板中，且管理儀表板，每位使用者上限有<span>3個</span>專屬的儀表板。
          </CustomDashboardListSubHeadText>
        </CustomDashboardListSubHeadTextContainer>
      </CustomDashboardListHeadContainer>
      {content}
    </CustomDashboardListWrapper>
  );
};

const CreateDashboardModal = styled.div`
  background-color: ${theme.white};
  width: 30%;
  border-radius: 20px;
  padding: 20px;
  display: flex;
  flex-direction: column;
`;

const CustomDashboardListWrapper = styled.div`
  width: 100vw;
  height: calc(100vh - 114px);
  overflow: hidden;
  transform: translateY(114px);
  padding: 20px 40px;
  background-color: #f7f3f3;
  @media (max-width: 1195px) {
    padding: 20px 20px;
  }

  @media (max-width: 850px) {
    transform: translateY(0px);
    height: 100%;
    position: fixed;
    top: 70px;
    bottom: 0;
  }
`;

const CustomDashboardListContentContainer = styled.div`
  display: ${(p) => (p.is_grid ? "grid" : "flex")};
  justify-content: ${(p) => (p.is_grid ? "flex-start" : "center")};
  align-items: ${(p) => (p.is_grid ? "flex-start" : "center")};
  height: 60vh;
  width: 100%;
  padding: ${(p) => (p.is_grid ? "20px 0px 0px 0px" : "0px")};
  
  grid-template-columns: repeat(auto-fill, 300px);
  column-gap: 20px;

  @media (max-width: 750px) {
    grid-template-columns: 1fr;
    height: 55vh;
    overflow-y: scroll;
    row-gap: 20px;
    padding-bottom: 40px;
  }
`;

const CustomDashboardListContentNoDataText = styled.p`
  font-size: 1rem;
  font-weight: 400;
  line-height: 25px;
  color: ${theme.grayBorderColor};
`;

const CustomDashboardListHeadContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-start;
`;

const CustomDashboardListHeadTextContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0px;
`;

const CustomDashboardListSubHeadTextContainer = styled.div`
  padding: 5px 0px 20px 0px;
  border-bottom: 1px solid ${theme.grayBorderColor};
  width: 100%;
`;

const CustomDashboardListHeadText = styled.h1`
  font-size: 2rem;
  font-weight: 600;
  @media (max-width: 439px) {
    margin-bottom: 16px;
  }
`;

const CustomDashboardListSubHeadText = styled.h3`
  font-size: 1rem;
  font-weight: 400;
  line-height: 25px;
  >span{
    color:var(--main-color);
    padding: 0px 2px;
    font-weight: 600;
  }
  @media (max-width: 750px) {
    font-size: 0.875rem;
    line-height: 20px;
  }
`;

const StyledButton = styled(Button)`
  background-color: ${(p) =>
    p.disabled ? theme.grayBorderColor : theme.linkBlue} !important;
  color: ${theme.white} !important;

  &:hover {
    background-color: ${(p) =>
      p.disabled ? theme.grayBorderColor : theme.linkBlue} !important;
  }
`;

const CustomDashboardListDetailModalWrapper = styled.div`
  width: 45%;
  background-color: ${theme.white};
  border-radius: 20px;
  padding: 20px 30px;

  @media (max-width: 750px) {
    width: 100%;
  }
`;

const CustomDashboardListDetailModalCloseContainer = styled.div`
  width: calc(100%-60px);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  z-index: 100;
`;

const CustomDashboardListDetailModalHeadContainer = styled.div`
  width: calc(100%-60px);
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  transform: translateY(-20px);
`;

const CustomDashboardListDetailModalHeadText = styled.h2`
  font-size: 2rem;
  font-weight: 600;
  letter-spacing: 2px;
`;

const CustomDashboardListDetailModalSubHeadContainer = styled.div`
  width: calc(100%-60px);
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const CustomDashboardListDetailModalHeadSubText = styled.h2`
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 2px;
  transform: translateY(-10px);
  line-height: 20px;

  > span {
    font-weight: 600;
    color: ${theme.primaryColor};
  }
`;

const CustomDashboardListDetailModalInputContainer = styled.div`
  width: calc(100%-60px);
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  padding: 10px 0px;
  position: relative;
`;

const CustomDashboardListWithTheme = () => {
  return (
    <ThemeProvider theme={themeColor}>
      <CustomDashboardList />
    </ThemeProvider>
  );
};

export default CustomDashboardListWithTheme;
