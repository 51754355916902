import React from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Chip from "@material-ui/core/Chip";
import Avatar from "@material-ui/core/Avatar";
import Icon from "@material-ui/core/Icon";
import Button from "@material-ui/core/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBuilding,
  faCity,
  faHammer,
} from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";

import ItemContent from "./ItemContent";
import Spinner from "../Spinner/Spinner";

import "./styles.scss";

export default (props) => {
  const {
    isStreamOpen,
    selectedItems,
    handleDelete,
    isGetDataLoading,
    listData,
    industryType,
    updateSelectedItemsHandler,
    startSearchHandler,
    updateStreamHandler,
    updateProducthandler,
    updateTagHandler,
    handleDeleteProductOrTagList,
    updateStreamParmsHandler,
    stream,
    clearAllHandler,
    initSearchHandler,
    toggleInfoContent,
    isInfoOpen,
    streamValue,
    openExplainationDialog
  } = props;
  const [state, setState] = React.useState({
    value: 3,
    isContentOpen: true,
  });

  React.useEffect(() => {
    switch (state.value) {
      case 0:
        updateStreamHandler("上游");
        break;
      case 1:
        updateStreamHandler("中游");
        break;
      case 2:
        updateStreamHandler("下游");
        break;
      case 3:
        updateStreamHandler("全部");
        break;
      default:
        return "Something went wrong!";
    }
  }, [state.value]);

  React.useEffect(()=>{
    if(streamValue){
      if(streamValue === "上游"){
        setState((state) => ({ ...state, value: 0 }));
      }else if(streamValue === "中游"){
        setState((state) => ({ ...state, value: 1 }));
      }else if(streamValue === "下游"){
        setState((state) => ({ ...state, value: 2 }));
      }
    }
  }, [streamValue])

  const handleChange = (event, newValue) => {
    if (state.value === newValue) {
      setState((state) => ({ ...state, value: 3 }));
    } else {
      setState((state) => ({ ...state, value: newValue }));
    }
  };

  const updateStramHandler = (stream) => {
    // const isExistStream =
    //   selectedItems.filter((item) => item === stream).length > 0; //確認有沒有重複的值存在
    // const updateSelectedItems = [];

    // if (isExistStream) {
    //   selectedItems.map((item) => {
    //     if (item !== stream) {
    //       updateSelectedItems.push(item);
    //     }
    //   });
    //   setState((state) => ({ ...state, value: 3 })); //假如把上中下游清掉，tab control 的值回歸到 3
    // } else {
    //   if (stream === "上游") {
    //     selectedItems.map((item) => {
    //       if (item !== "中游" && item !== "下游") {
    //         updateSelectedItems.push(item);
    //       }
    //     });
    //   } else if (stream === "中游") {
    //     selectedItems.map((item) => {
    //       if (item !== "上游" && item !== "下游") {
    //         updateSelectedItems.push(item);
    //       }
    //     });
    //   } else if (stream === "下游") {
    //     selectedItems.map((item) => {
    //       if (item !== "中游" && item !== "上游") {
    //         updateSelectedItems.push(item);
    //       }
    //     });
    //   }
    //   updateSelectedItems.push(stream);
    // }
    updateStreamParmsHandler(stream);
    updateSelectedItemsHandler(stream);
  };

  const selectedItemDeleteHandler = (item) => {
    if (item === industryType) {
      Swal.fire({
        title: "提醒",
        text: "主要項目不得刪除。",
      });
    } else {
      if (selectedItems.filter((i) => i === item).length > 0) {
        const updateSelectedItem = [];
        selectedItems.map((i) => {
          if (i !== item) {
            updateSelectedItem.push(i);
          }
        });
        updateSelectedItemsHandler(updateSelectedItem);
        handleDeleteProductOrTagList(item);
      } else {
        return;
      }
    }
  };

  const toggleContent = () => {
    setState(state=> ({ ...state, isContentOpen: !state.isContentOpen }))
  }

  if (isGetDataLoading) {
    return <Spinner />;
  } else {
    if (isStreamOpen) {
      return (
        <div className={state.isContentOpen ? "supply-chain-content" : "supply-chain-content mini-content"}>    
          <div className="supply-chain-content-header">
            <div className="supply-chain-content-header-left">
              <h4 onClick={initSearchHandler}>{industryType}</h4>
              <p onClick={clearAllHandler}>清除選項</p>
              {window.innerWidth > 1023 && <p onClick={toggleInfoContent}>{!isInfoOpen ? "打開廠商列表" : "關閉廠商列表"}</p> }
              {window.innerWidth < 1023 && <p onClick={openExplainationDialog}>說明</p> }
            </div>
            {window.innerWidth > 1023 && 
            <div className="supply-chain-content-header-right">
              <Icon onClick={toggleContent}>{state.isContentOpen ? "chevron_left" : "chevron_right"}</Icon>
            </div>
            }
          </div>
          {state.isContentOpen &&
          <div className="supply-chain-content-selected-content">
            <div className="supply-chain-content-selected-content-header">
              <h4>搜尋條件</h4>
            </div>
            <div className="supply-chain-content-selected-content-content">
              {selectedItems &&
                selectedItems.length > 0 &&
                selectedItems.filter(i => i !== "all").map((item) => (
                  <Chip
                    avatar={<Avatar>{item.substring(0, 1)}</Avatar>}
                    label={item}
                    clickable
                    color="primary"
                    // onDelete={() => selectedItemDeleteHandler(item)}
                  />
                ))}
            </div>
          </div>
          }
          <div className="supply-chain-content-wrapper">
            <div className="supply-chain-content-main-content">
              <div className="supply-chain-content-main-content-tab-header">
                {/* <div className="supply-chain-content-main-content-tab-header-line-left" /> */}
                {/* <div className="supply-chain-content-main-content-tab-header-line-right" /> */}
                <Tabs
                  value={state.value}
                  onChange={handleChange}
                  orientation={window.innerWidth > 1023 ? "vertical" : "horizontal"}
                >
                  <Tab
                    className="upstream-tab"
                    icon={<FontAwesomeIcon icon={faCity} />}
                    label="上游"
                  />
                  <Tab
                    className="middlestream-tab"
                    icon={<FontAwesomeIcon icon={faBuilding} />}
                    label="中游"
                  />
                  <Tab
                    className="downstream-tab"
                    icon={<FontAwesomeIcon icon={faHammer} />}
                    label="下游"
                  />
                  <Tab
                    icon={<FontAwesomeIcon icon={faHammer} />}
                    style={{ display: "none" }}
                  />
                </Tabs>
              </div>
              {state.isContentOpen && (
                <div className="supply-chain-content-main-content-message-box">
                  <ItemContent
                    selectedItems={selectedItems}
                    type="上游"
                    updateSelectedItemsHandler={updateSelectedItemsHandler}
                    data={listData.length > 0 ? listData[0].categoryFour : []}
                    updateTagHandler={updateTagHandler}
                    updateProducthandler={updateProducthandler}
                    style={
                      state.value === 0 || state.value === 3
                        ? { top: "10px" }
                        : { top: "10px", opacity: "0.3" }
                    }
                    handleChange={() => updateStramHandler("上游")}
                    stream={stream}
                    value={state.value}
                    referenceValue={0}
                  />
                  <ItemContent
                    selectedItems={selectedItems}
                    updateSelectedItemsHandler={updateSelectedItemsHandler}
                    type="中游"
                    data={listData.length > 0 ? listData[0].categoryFour : []}
                    updateTagHandler={updateTagHandler}
                    updateProducthandler={updateProducthandler}
                    style={
                      state.value === 1 || state.value === 3
                        ? { top: "200px" }
                        : { top: "200px", opacity: "0.3" }
                    }
                    handleChange={() => updateStramHandler("中游")}
                    stream={stream}
                    value={state.value}
                    referenceValue={1}
                  />
                  <ItemContent
                    selectedItems={selectedItems}
                    updateSelectedItemsHandler={updateSelectedItemsHandler}
                    type="下游"
                    data={listData.length > 0 ? listData[0].categoryFour : []}
                    updateTagHandler={updateTagHandler}
                    updateProducthandler={updateProducthandler}
                    style={
                      state.value === 2 || state.value === 3
                        ? { top: "400px", marginBottom: "50px" }
                        : { top: "400px", opacity: "0.3", marginBottom: "50px" }
                    }
                    handleChange={() => updateStramHandler("下游")}
                    stream={stream}
                    value={state.value}
                    referenceValue={2}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className={state.isContentOpen ? "supply-chain-content" : "supply-chain-content mini-content"}>    
          <div className="supply-chain-content-header">
            <div className="supply-chain-content-header-left">
              <h4 onClick={initSearchHandler}>{industryType}</h4>
              <p onClick={clearAllHandler}>清除選項</p>
              {window.innerWidth > 1023 && <p onClick={toggleInfoContent}>{!isInfoOpen ? "打開廠商列表" : "關閉廠商列表"}</p> }
              {window.innerWidth < 1023 && <p onClick={openExplainationDialog}>說明</p> }
            </div>
            {window.innerWidth > 1023 && 
            <div className="supply-chain-content-header-right">
              <Icon onClick={toggleContent}>{state.isContentOpen ? "chevron_left" : "chevron_right"}</Icon>
            </div>
            }
          </div>
          {state.isContentOpen &&
          <div className="supply-chain-content-selected-content">
            <div className="supply-chain-content-selected-content-header">
              <h4>搜尋條件</h4>
            </div>
            <div className="supply-chain-content-selected-content-content">
              {selectedItems &&
                selectedItems.length > 0 &&
                selectedItems.filter(i => i !== "all").map((item) => (
                  <Chip
                    avatar={<Avatar>{item.substring(0, 1)}</Avatar>}
                    label={item}
                    clickable
                    color="primary"
                    // onDelete={() => selectedItemDeleteHandler(item)}
                  />
                ))}
            </div>
          </div>
          }
          <div className="supply-chain-content-wrapper">
            <div className="supply-chain-content-main-content">
              {state.isContentOpen && (
                <div className="supply-chain-content-main-content-message-box">
                  <ItemContent
                    selectedItems={selectedItems}
                    type="上游"
                    updateSelectedItemsHandler={updateSelectedItemsHandler}
                    data={listData.length > 0 ? listData[0].categoryFour : []}
                    updateTagHandler={updateTagHandler}
                    updateProducthandler={updateProducthandler}
                    style={
                      state.value === 0 || state.value === 3
                        ? { top: "10px" }
                        : { top: "10px", opacity: "0.3" }
                    }
                    handleChange={() => updateStramHandler("上游")}
                    stream={stream}
                    value={state.value}
                    referenceValue={0}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      );
    }
  }
};
