import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";

import parkConverter from "../../../utility/parkIdToNameConvertor";

import "./styles.scss";

const FactoryServiceHistory = ({ data, headerList }) => {
  const [state, setState] = React.useState({
    endIndex: 0,
    page: 0,
    rowsPerPage: 5,
  });

  let content;

  React.useEffect(() => {
    if (Math.ceil(data.length) === state.page) {
      if (data.length % 5 === 0) {
        setState((state) => ({
          ...state,
          endIndex: state.page * state.rowsPerPage + state.rowsPerPage,
        }));
      } else {
        setState((state) => ({
          ...state,
          endIndex: state.page * state.rowsPerPage + (data.length % 5),
        }));
      }
    } else {
      setState((state) => ({
        ...state,
        endIndex: state.page * state.rowsPerPage + state.rowsPerPage,
      }));
    }
  }, [data.length, state.page]);

  if (data.length > 5) {
    content = data
      .slice(state.page * 5, state.page * 5 + 5)
      .map((row, index) => (
        <TableRow
          key={row.AssistDate + index}
          hover
          className="table-body-row"
          style={index % 2 !== 0 ? { backgroundColor: "#ececec" } : {}}
        >
          <TableCell align="left" style={{ width: "15%" }}>
            {row.AssistDate.split(" ")[0]}
          </TableCell>
          <TableCell align="left" style={{ width: "15%" }}>
            {row.TechCon ? "技術諮詢" : "訪視紀錄"}
          </TableCell>
          <TableCell align="left" style={{ width: "15%" }}>
            {parkConverter(row.ParkId)}
          </TableCell>
          <TableCell align="left" style={{ width: "40%" }}>
            {row.TechCon ? row.TechCon : row.VisitRecord}
          </TableCell>
          <TableCell align="left" style={{ width: "15%" }}>
            <p>{row.School}</p>
            <p>{row.Teacher}</p>
          </TableCell>
        </TableRow>
      ));
  } else {
    content = data.map((row, index) => (
      <TableRow
        key={row.PatentNo + index}
        hover
        className="table-body-row"
        style={index % 2 !== 0 ? { backgroundColor: "#ececec" } : {}}
      >
        <TableCell align="left" style={{ width: "15%" }}>
          {row.AssistDate.split(" ")[0]}
        </TableCell>
        <TableCell align="left" style={{ width: "15%" }}>
          {row.TechCon ? "技術諮詢" : "訪視紀錄"}
        </TableCell>
        <TableCell align="left" style={{ width: "15%" }}>
          {parkConverter(row.ParkId)}
        </TableCell>
        <TableCell align="left" style={{ width: "40%" }}>
          {row.TechCon ? row.TechCon : row.VisitRecord}
        </TableCell>
        <TableCell align="left" style={{ width: "15%" }}>
          <p>{row.School}</p>
          <p>{row.Teacher}</p>
        </TableCell>
      </TableRow>
    ));
  }

  const handleChangePage = (event, newPage) => {
    setState((state) => ({ ...state, page: newPage }));
  };

  const openNewTabs = (link) => window.open(link, "_blank");

  return (
    <TableContainer className="table-container">
      <Table className="table">
        <TableHead className="table-header">
          {headerList && (
            <TableRow className="table-row">
              {headerList.map((headerItem) => (
                <TableCell
                  key={headerItem}
                  style={{
                    fontWeight: 600,
                  }}
                  className="table-cell"
                  align="left"
                >
                  {headerItem}
                </TableCell>
              ))}
            </TableRow>
          )}
        </TableHead>
        <TableBody className="table-body">
          {data.length !== 0 && content}
        </TableBody>
      </Table>
      {data.length === 0 && (
        <Table>
          <TableBody className="table-body-no-data">
            <TableRow hover className="table-body-row">
              <TableCell align="center" className="no-data">
                本項目無資料
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      )}
      {data.length > 5 && (
        <TablePagination
          rowsPerPageOptions={[]}
          component="div"
          count={data.length}
          rowsPerPage={state.rowsPerPage}
          page={state.page}
          onChangePage={handleChangePage}
          // onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      )}
    </TableContainer>
  );
};

export default React.memo(FactoryServiceHistory);
