import React from "react";
import axios from "axios";
import Swal from "sweetalert2";

import { fetchUserInfo } from "../../utility/api";
import { encryptAES, decryptAES } from "../../utility/common";

export default (props) => {
  const { children } = props;
  const url = window.location.origin;
  const uid = localStorage.getItem("uid");
  const timeStamp = new Date().valueOf();
  const urlParams = new URLSearchParams(window.location.search);

  const handleGetRedirection = async () => {
    return axios({
      method: "get",
      url: `https://mirdc-system-default-rtdb.firebaseio.com/redirectionUrl.json`,
    })
      .then((res) => {
        const { fromUrl, toUrl } = res.data;
        provider(url, fromUrl, toUrl);
        reciever(fromUrl, toUrl);
        return res;
      })
      .catch((err) => {
        return err;
      });
  };

  const provider = (url, fromUrl, toUrl) => {
    const provideParma = encryptAES(`${uid}&&${timeStamp}`);
    if (url === fromUrl) {
      console.log(url);
      console.log(fromUrl);

      if (toUrl) {
        console.log("成功導出");
        window.location.href = `${toUrl}?u=${provideParma}`;
      }
    }
  };

  const reciever = (url, toUrl) => {
    console.log(url);
    console.log(toUrl);
    const timeStamp = new Date().valueOf();
    const u = urlParams.get("u");
    const recieverParam = decryptAES(u);
    const passedTime = recieverParam.split("&&")[1];
    const uid = recieverParam.split("&&")[0];

    console.log(+passedTime + 60 * 1000000);
    console.log(timeStamp);

    if (+passedTime + 60 * 1000000 > timeStamp && uid) {
      //5分鐘內
      fetchUserInfo(btoa(uid)).then((res) => {
        console.log("成功導出 :", res);
        const { data, PassPermissions } = res;
        localStorage.setItem("isLogin", true);
        localStorage.setItem("uid", uid);
        localStorage.setItem("name", data.Name);
        localStorage.setItem("ParkId", data.ParkId);
        PassPermissions.map((res) => {
          localStorage.setItem(res.PermissionId, res.PermissionId);
        });

        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 1000,
          timerProgressBar: true,
        });

        Toast.fire({
          icon: "success",
          title: "登入成功",
        }).then(() => {
          window.location.assign(window.location.origin + "/portal");
        });
      });
    }
  };

  React.useEffect(() => {
    handleGetRedirection();
  }, []);

  return <div>{children}</div>;
};
