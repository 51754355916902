import styled from "styled-components";

export const CloudContainer = styled.div`
    display: flex;
    height: 100%;
    flex-direction: row;
    align-items: flex-end;
    justify-content: center;
`;

export const CloudIcon = styled.div`
    height: 100px;
    width: 10%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;  
    background-image: url(${require("../../assets/images/pic01.png")}); 
`;

export const CloudMain = styled.div`
    height: ${(p) => (p.nodata ? "1px" : "320px")};
    width: ${(p) => (p.nodata ? "1px" : "80%")};

    @media(max-width:1023px){
        width: ${(p) => (p.nodata ? "1px" : "100%")};
    }
`;

export const TrendsMain = styled.div`
    margin-top: 20px;
`;

export const TrendsText = styled.p`
    margin: 15px 0px 10px;
`;