import * as actionType from "../actionTypes/taxInfo";

const initState = {
  //搜尋稅籍廠商基本資料
  getTaxInfoSearchData: [],
  getTaxInfoSearchSummaryData: [],
  getTaxInfoSearchDataLoading: false,

  //出口產品四碼清單
  getExportProductFourCodeList: [],
  getExportProductFourCodeListLoading: false,

  //稅籍產業選單
  getTaxIndustryList: [],
  getTaxIndustryListLoading: false,

  //工廠二碼選單
  getTypeTwoList: [],
  getTypeTwoListLoading: false,

  //工廠三碼選單
  getTypeThreeList: [],
  getTypeThreeListLoading: false,
};

//搜尋稅籍廠商基本資料
const startTaxInfoSearch = (state) => {
  return {
    ...state,
    getTaxInfoSearchDataLoading: true,
  };
};

const fetchTaxInfoSearchSuccess = (state, action) => {
  return {
    ...state,
    getTaxInfoSearchData: action.data,
    getTaxInfoSearchSummaryData: action.data_statistic,
    getTaxInfoSearchDataLoading: false,
  };
};

const fetchTaxInfoSearchFail = (state) => {
  return {
    ...state,
    getTaxInfoSearchDataLoading: false,
  };
};

//出口產品四碼清單
const startExportProductFourCodeList = (state) => {
  return {
    ...state,
    getExportProductFourCodeListLoading: true,
  };
};

const fetchExportProductFourCodeListSuccess = (state, action) => {
  return {
    ...state,
    getExportProductFourCodeList: action.data,
    getExportProductFourCodeListLoading: false,
  };
};

const fetchExportProductFourCodeListFail = (state) => {
  return {
    ...state,
    getExportProductFourCodeListLoading: false,
  };
};

//稅籍產業選單
const startTaxIndustryList = (state) => {
  return {
    ...state,
    getTaxIndustryListLoading: true,
  };
};

const fetchTaxIndustryListSuccess = (state, action) => {
  const tempData = action.data?.filter((i) => ((i.IndustryName !== "") && (i.IndustryName !== "nan")));
  return {
    ...state,
    getTaxIndustryList: tempData,
    getTaxIndustryListLoading: false,
  };
};

const fetchTaxIndustryListFail = (state) => {
  return {
    ...state,
    getTaxIndustryListLoading: false,
  };
};

//工廠二碼選單
const startTypeTwoList = (state) => {
  return {
    ...state,
    getTypeTwoListLoading: true,
  };
};

const fetchTypeTwoListSuccess = (state, action) => {
  return {
    ...state,
    getTypeTwoList: action,
    getTypeTwoListLoading: false,
  };
};

const fetchTypeTwoListFail = (state) => {
  return {
    ...state,
    getTypeTwoListLoading: false,
  };
};

//工廠三碼選單
const startTypeThreeList = (state) => {
  return {
    ...state,
    getTypeThreeListLoading: true,
  };
};

const fetchTypeThreeListSuccess = (state, action) => {
  return {
    ...state,
    getTypeThreeList: action,
    getTypeThreeListLoading: false,
  };
};

const fetchTypeThreeListFail = (state) => {
  return {
    ...state,
    getTypeThreeListLoading: false,
  };
};


export default (state = initState, action) => {
  switch (action.type) {
    //搜尋稅籍廠商基本資料
    case actionType.START_TAX_INFO_SEARCH:
      return startTaxInfoSearch(state);
    case actionType.FETCH_TAX_INFO_SEARCH_SUCCESS:
      return fetchTaxInfoSearchSuccess(state, action.data);
    case actionType.FETCH_TAX_INFO_SEARCH_FAIL:
      return fetchTaxInfoSearchFail(state);

    //出口產品四碼清單
    case actionType.START_EXPORT_PRODUCT_FOUR_CODE_LIST:
      return startExportProductFourCodeList(state);
    case actionType.FETCH_EXPORT_PRODUCT_FOUR_CODE_LIST_SUCCESS:
      return fetchExportProductFourCodeListSuccess(state, action);
    case actionType.FETCH_EXPORT_PRODUCT_FOUR_CODE_LIST_FAIL:
      return fetchExportProductFourCodeListFail(state);

    //稅籍產業選單
    case actionType.START_TAX_INDUSTRY_LIST:
      return startTaxIndustryList(state);
    case actionType.FETCH_TAX_INDUSTRY_LIST_SUCCESS:
      return fetchTaxIndustryListSuccess(state, action.data);
    case actionType.FETCH_TAX_INDUSTRY_LIST_FAIL:
      return fetchTaxIndustryListFail(state);
    
    //工廠二碼選單
    case actionType.START_TYPE_TWO_LIST:
      return startTypeTwoList(state);
    case actionType.FETCH_TYPE_TWO_LIST_SUCCESS:
      return fetchTypeTwoListSuccess(state, action.data);
    case actionType.FETCH_TYPE_TWO_LIST_FAIL:
      return fetchTypeTwoListFail(state);
    
    //工廠三碼選單
    case actionType.START_TYPE_THREE_LIST:
      return startTypeThreeList(state);
    case actionType.FETCH_TYPE_THREE_LIST_SUCCESS:
      return fetchTypeThreeListSuccess(state, action.data);
    case actionType.FETCH_TYPE_THREE_LIST_FAIL:
      return fetchTypeThreeListFail(state);

    default:
      return state;
  }
};
