import React, { useState, useEffect } from "react";

import { Login } from "../utility/api";

export const AuthContext = React.createContext({
  isAuth: true,
  GroupInfo: "",
  SuperUser: "",
  UserInfo: "",
  PassPermission: "",
  AccessToken: "",
  login: () => {},
  logout: () => {},
});

const AuthContextProvider = (props) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [AccessToken, setAccessToken] = useState("");
  const [uuuu, setUUUU] = useState();
  const [groupInfo, setGroupInfo] = useState("");
  const [userInfo, setUserInfo] = useState("");
  const [superuser, setSuperuser] = useState("");
  const [PassPermission, setPassPermission] = useState("");
  const url = window.location.href.split("/");

  useEffect(() => {
    const data = localStorage.getItem("Login");
    const encTicket = localStorage.getItem("EncTicket");
    const uid = localStorage.getItem("uid");

    if (data || encTicket) {
      setIsAuthenticated(true);

      const now = new Date().getTime();
      const expiration = localStorage.getItem("expiration");

      // ditch the content if too old
      if (now > expiration) {
        localStorage.removeItem("Login");
        localStorage.removeItem("now");
        localStorage.removeItem("expiration");
        // localStorage.removeItem('uid');
      }
    } else {
      setIsAuthenticated(false);
    }
  }, []);

  const loginHandler = (account, password) => {
    var sha1 = require("sha1");

    const fixedPass = sha1(password);

    //api calling here
    //make a form data
    const data = new FormData();
    data.append("a", account);
    data.append("p", fixedPass);

    Login(data).then((res) => {
      if (res.Login === true) {
        // console.log(res)
        // debugger

        localStorage.setItem("EncTicket", res.EncTicket);
        localStorage.setItem("Login", res.Login);
        localStorage.setItem("uid", res.UserInfo.UserId);
        localStorage.setItem("name", res.UserInfo.Name);
        localStorage.setItem("acToken", res.OpenDataService.AccessToken);
        res.PassPermissions.map((res) => {
          localStorage.setItem(res.PermissionId, res.PermissionId);
        });

        const now = new Date();
        const expiration = now.getTime() + 60000 * 60;

        localStorage.setItem("now", now.getTime());
        localStorage.setItem("expiration", expiration);
        localStorage.setItem("RefreshToken", res.InduParkWeb.RefreshToken);
        localStorage.setItem("InduParkWebAcToken", res.InduParkWeb.AccessToken);

        setUUUU(res.EncTicket);
        setUserInfo(res.UserInfo);
        setIsAuthenticated(res.Login);
        setAccessToken(res.OpenDataService.AccessToken);

        // window.location.replace(url[0]+'//'+url[2]+'/section/QIP003')
      } else {
        alert("您輸入的帳號密碼有誤！");
        window.location.reload();
      }
    });
  };

  const loginOutHandler = () => {
    setIsAuthenticated(false);
    localStorage.removeItem("Login");
    localStorage.removeItem("EncTicket");
    localStorage.removeItem("acToken");
    localStorage.removeItem("1");
    localStorage.removeItem("2");
    localStorage.removeItem("3");
    localStorage.removeItem("4");
    localStorage.removeItem("5");
    localStorage.removeItem("6");
    localStorage.removeItem("7");
    localStorage.removeItem("8");
    localStorage.removeItem("RefreshToken");
    localStorage.removeItem("InduParkWebAcToken");

    window.location.replace(url[0] + "//" + url[2] + "/sign-in");
  };

  return (
    <AuthContext.Provider
      value={{
        login: loginHandler,
        logout: loginOutHandler,
        isAuth: isAuthenticated,
        UserInfo: userInfo,
        uid: uuuu,
        AccessToken: AccessToken,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
