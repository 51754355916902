import React from "react";
import styled, { keyframes } from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Icon from "@material-ui/core/Icon";
import Button from "@material-ui/core/Button";
import Close from "@material-ui/icons/Close";

import { logoutHandler } from "../../store/actions/auth";
import * as theme from "../../assests/styles/theme";
// import logo from "../../assests/logo-temp-01@3x.png";
import logo from "../../assests/2024_IDB_Logo.svg";
import Swal from "sweetalert2";

const MobileMenu = ({
  toggle,
  menuHandler,
  auth_menu_handler,
  openSignInModal,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { ParkId } = useSelector((state) => state.user.userInfo);
  const { version, cachedVersion } = useSelector((state) => state.app);
  const currentPage = window.location.pathname.split("/")[1];

  const [state, setState] = React.useState({
    isInit: true,
  });

  const tempParkId = !ParkId || ParkId === "other" ? "HIP015" : ParkId;

  const redirect = (e) => {
    if (localStorage.getItem("isLogin") === "true") {
      e.preventDefault();
      Swal.fire({
        title: "提醒",
        text: "您無操作此功能權限，請聯絡管理員。",
        confirmButtonText: "確認",
      }).then((res) => {
        if (res.value) {
          return;
        } else {
          return;
        }
      });
    } else {
      menuHandler();
    }

    // menuHandler(); //之後要改成用 LoginV2 控制就好。
    // MySwal.fire({
    //   title: <p>提醒</p>,
    //   text: "尚未登入，請登入或註冊帳號。",
    //   showCancelButton: true,
    //   confirmButtonText: '前往註冊',
    //   cancelButtonText: '關閉'
    // }).then((res)=>{
    //   if(res.value){
    //     history.push('/sign-up')
    //     menuHandler()
    //   }else{
    //     return
    //   }
    // })
  };

  const handleOpenGitbook = () =>
    window.open(
      "https://smartpark-guideline.gitbook.io/smartpark_guideline/",
      "_blank"
    );

  const linkItems = [
    {
      name: ["首頁"],
      link: `/portal`,
      icon: "home",
      authLevel: "",
      func: () => {
        menuHandler();
      },
    },
    {
      name: ["園區儀表板"],
      link: `/park_dashboard/${tempParkId}`,
      icon: "apartment",
      authLevel: "",
      func: localStorage.getItem("2")
        ? () => {
          menuHandler();
        }
        : (e) => {
          redirect(e);
        },
    },
    {
      name: ["技術快搜"],
      link: "/map-search",
      icon: "search",
      authLevel: "",
      func: localStorage.getItem("9")
        ? () => {
          menuHandler();
        }
        : (e) => {
          redirect(e);
        },
    },
    {
      name: ["複合條件快搜"],
      link: "/tax_info",
      icon: "join_full",
      authLevel: "",
      func: localStorage.getItem("9")
        ? () => {
          menuHandler();
        }
        : (e) => {
          redirect(e);
        },
    },
    {
      name: ["產業營運動態儀表板"],
      link: "/operations_dashboard",
      icon: "auto_graph",
      authLevel: "",
      func: localStorage.getItem("9")
        ? () => {
          menuHandler();
        }
        : (e) => {
          redirect(e);
        },
    },
    // {
    //     name: '進階搜尋',
    //     link: '/advanced_search',
    //     icon: 'search',
    //     authLevel: ''
    // },
    {
      name: ["個廠情資"],
      link: "/factory_search",
      icon: "build",
      authLevel: "",
      func: localStorage.getItem("4")
        ? () => {
          menuHandler();
        }
        : (e) => {
          redirect(e);
        },
    },
    {
      name: ["產業儀表板"],
      link: `/industry_index/1810`,
      icon: "apps",
      authLevel: "",
      func: localStorage.getItem("5")
        ? () => {
          menuHandler();
        }
        : (e) => {
          redirect(e);
        },
    },
    {
      name: ["分區儀表板"],
      link: `/area_dashboard`,
      icon: "add_location_alt",
      authLevel: "",
      func: localStorage.getItem("2")
        ? () => {
          menuHandler();
        }
        : (e) => {
          redirect(e);
        },
    },
    {
      name: ["縣市儀表板"],
      link: `/city_dashboard`,
      icon: "assistant_photo",
      authLevel: "",
      func: localStorage.getItem("2")
        ? () => {
          menuHandler();
        }
        : (e) => {
          redirect(e);
        },
    },
    // {
    //     name: ['新聞訂閱'],
    //     link: `/coporaiton_tec/`,
    //     icon: 'announcement',
    //     authLevel: '',
    //   func: localStorage.getItem('6') ? () => { menuHandler() } : ()=> {redirect()}
    // },
    // {
    //     name: '上傳資料',
    //     link: `/geographic_system`,
    //     icon: 'description',
    //     authLevel: ''
    // },
    // {
    //   name: ["受事件影響", "儀表板"],
    //   link: `/effected_event_dashboard`,
    //   icon: "theater_comedy_icon",
    //   authLevel: "",
    //   func: localStorage.getItem("20")
    //     ? () => {
    //       menuHandler();
    //       }
    //     : (e) => {
    //         redirect(e);
    //       },
    // },
    {
      name: ["政府資源"],
      link: `/gov_plan_display`,
      icon: "gavel",
      authLevel: "",
      func: () => {
        menuHandler();
      },
    },
    {
      name: ["產業鏈快搜"],
      link: `/supply-chain`,
      icon: "assignment",
      authLevel: "",
      func: () => {
        menuHandler();
      },
    },
    {
      name: ["分享平台"],
      link: `/sharable-page`,
      icon: "share",
      authLevel: "",
      func: () => {
        menuHandler();
      },
    },
    {
      name: ["操作指南"],
      // link: `/sharable-page`,
      icon: "menu_book",
      authLevel: "",
      func: () => {
        handleOpenGitbook();
        menuHandler();
      },
    },
    // {
    //   name: ["受事件影響","儀表板"],
    //   link: `/event_dashboard`,
    //   icon: "feed",
    //   authLevel: "",
    //   func: () => {
    //     menuHandler();
    //   },
    // },
    {
      name: ["訪廠問卷"],
      link: `/questionnaire-management`,
      icon: "description",
      authLevel: "",
      func: localStorage.getItem("17")
        ? () => {
          auth_menu_handler();
        }
        : (e) => {
          redirect(e);
        },
      isHidden: "17",
    },

    // {
    //     name: ['政府資源','管理'],
    //     link: `/gov_plan_management/gov_plan`,
    //     icon: 'account_tree',
    //     authLevel: ''
    // },
    // {
    //     name: ['政府資源','推播設定'],
    //     link: `/gov_plan_management/delivery`,
    //     icon: 'all_inbox',
    //     authLevel: ''
    // },
  ];

  const handleAuth = () => {
    if (localStorage.getItem("isLogin")) {
      dispatch(logoutHandler());
    } else {
      history.push("/portal");
      openSignInModal();
    }
    menuHandler();
  };

  React.useEffect(() => {
    setTimeout(() => {
      setState((state) => ({ ...state, isInit: false }));
    }, 1000);
  }, []);

  return (
    <MobileMenuWrapper open={toggle} isInit={state.isInit}>
      <MobileMenuCloseContainer>
        <Close onClick={menuHandler} />
      </MobileMenuCloseContainer>
      <MobileMenuLogoContainer>
        <MobileMenuLogo src={logo} alt={"LOGO 圖示"} loading={"lazy"} />
      </MobileMenuLogoContainer>
      <GridContainer>
        <GridWrapper container spacing={3}>
          {linkItems.map((link) => {
            let isHidden = false;
            if (link.isHidden && !localStorage.getItem(link.isHidden)) {
              isHidden = true;
            }

            return (
              <CustomGridItem xs={6} item isHidden={isHidden}>
                <GridInnerBlock
                  onClick={link.func}
                  component={(props) => <Link to={link.link} {...props} />}
                  elevation={1}>
                  <Icon>{link.icon}</Icon>
                  {link.name.map((name) => (
                    <p>{name}</p>
                  ))}
                </GridInnerBlock>
              </CustomGridItem>
            );
          })}
        </GridWrapper>
        <ButtonContainer>
          <AuthButton onClick={handleAuth}>
            {localStorage.getItem("isLogin") ? "登出" : "登入/註冊"}
          </AuthButton>
        </ButtonContainer>
      </GridContainer>
      <VersionControlBlock>
        <VersionControlBlockText>v.{cachedVersion}</VersionControlBlockText>
        <VersionControlBlockAlertText isShow={version !== cachedVersion}>
          您目前使用的是較舊的版本
        </VersionControlBlockAlertText>
      </VersionControlBlock>
    </MobileMenuWrapper>
  );
};

const MobileMenuWrapper = styled.div`
  width: 100vw;
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: ${(p) => (p.open ? "0px" : "-100vw")};
  opacity: ${(p) => p.isInit && "0"};
  z-index: 2000;
  background-image: linear-gradient(var(--main-color), #8e9eab);
  animation: 0.5s ${(p) => (p.open ? slideOpen : slideClose)} linear;
`;

const slideOpen = keyframes`
  from {
    left: -100vw;
  }

  to {
    left: 0vw;
  }
`;

const slideClose = keyframes`
  from {
    left: 0vw;
  }

  to {
    left: -100vw;
  }
`;

const MobileMenuLogoContainer = styled.div`
  display: flex;
  width: 90%;
  margin: 0px 5%;
  padding: 5px 0px 10px 0px;
`;

const MobileMenuLogo = styled.img`
  width: 100%;
`;

const MobileMenuCloseContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 90%;
  margin: 0px 5%;
  padding: 15px 0px;

  .MuiSvgIcon-root {
    color: ${theme.white};
  }
`;
const GridContainer = styled.div`
  width: 90%;
  margin: 0px 5%;
  max-height: 68vh;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 10px 0px;
`;

const GridWrapper = styled(Grid)``;

const GridInnerBlock = styled(Paper)`
  width: 100%;
  /* padding: 25px 20px; */
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  border-radius: 25px !important;
  font-weight: 500;

  .material-icons {
    font-size: 150%;
    color: #373636;
  }

  > span {
    margin: 5% 0px;
  }

  > p {
    text-align: center;
    font-weight: 500;
    margin: 5% 0px;
    color: #373636;
    font-size: 1.375rem;
  }
`;

const ButtonContainer = styled.div`
  width: 90%;
  margin: 0px 5%;
  padding: 20px 0px 10px 0px;
`;

const AuthButton = styled(Button)`
  font-size: 1.25rem !important;
  width: 100%;
  padding: 5px 0px !important;
  color: ${theme.white} !important;
  background-color: rgba(2, 2, 2, 0.1) !important;
  border-radius: 15px !important;
  text-align: center;
  box-shadow: 0 0 30px 0 #999;
`;

const VersionControlBlock = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const VersionControlBlockText = styled.p`
  color: #fff;
  font-size: 0.625rem;
`;

const VersionControlBlockAlertText = styled(VersionControlBlockText)`
  display: ${(p) => (p.isShow ? "block" : "none")};
  font-size: 0.3125rem;
  padding-left: 5px;
`;

const GridItem = (props) => <Grid item {...props} />;

const CustomGridItem = styled(GridItem)`
  display: ${(p) => (p.isHidden ? "none" : "")};
`;

export default MobileMenu;
