import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

import ResultList from "./ResultList";

import { thousandDivider } from "../../utility/common";


const EffectedEventResultList = ({
  data,
  is_search_console_open,
  is_clicked,
  loading,
  handle_change_result_type,
  handle_change_result_sub_type,
  result_sub_type,
  is_declined
}) => {
  const { innerWidth } = useSelector((state) => state.app);
  const { effected_events_result_list_loading, effected_events_result_list } =
    useSelector((state) => state.effected_events);
  const [state, setState] = React.useState({
    option_list: [],
    result_type: "all_companies",
    sub_result_type_option: [],
  });

  const remove_duplicates = (array, use_ref) => {
    let uniq = {};
    let result = {};
    array
      .filter((obj) => !uniq[obj[use_ref]] && (uniq[obj[use_ref]] = true))
      .forEach((i) => {
        result[i[use_ref]] = [];
      });
    array.forEach((i) => {
      result[i[use_ref]].push(i);
    });

    return result;
  };

  const remove_duplicates_decline = (array, use_ref) => {
    let uniq = {};
    let result = {};
    array
      .filter((obj) => !uniq[obj[use_ref]] && (uniq[obj[use_ref]] = true))
      .forEach((i) => {
        result[i[use_ref]] = [];
      })
    array.forEach((i) => {
      if (i.isDecline) {
        result[i[use_ref]].push(i);
      }
    });
    return result;
  };

  const sort_by_area = React.useMemo(
    () => remove_duplicates(effected_events_result_list, "area"),
    [effected_events_result_list]
  );
  const sort_by_city = React.useMemo(
    () => remove_duplicates(effected_events_result_list, "city"),
    [effected_events_result_list]
  );
  const sort_by_indu_park = React.useMemo(
    () => remove_duplicates(effected_events_result_list, "induPark"),
    [effected_events_result_list]
  );

  const sort_by_area_decline = React.useMemo(
    () => remove_duplicates_decline(effected_events_result_list, "area"),
    [effected_events_result_list]
  );
  const sort_by_city_decline = React.useMemo(
    () => remove_duplicates_decline(effected_events_result_list, "city"),
    [effected_events_result_list]
  );
  const sort_by_indu_park_decline = React.useMemo(
    () => remove_duplicates_decline(effected_events_result_list, "induPark"),
    [effected_events_result_list]
  );

  const handle_change = (e) => {
    const { name, value } = e.target;
    handle_change_result_type(e);
    setState((state) => ({ ...state, [name]: value }));
  };

  React.useEffect(()=>{
    setState({ ...state, result_type: "all_companies" })
  }, [effected_events_result_list_loading])

  React.useEffect(() => {
    if (is_declined) {
      const update_option = [];
      switch (state.result_type) {
        case "all_companies":
          setState((state) => ({ ...state, sub_result_type_option: [] }));
          break;
        case "sort_by_area":
          Object.entries(sort_by_area_decline)
            .sort((a, b) => b[1].length - a[1].length)
            .forEach((i) => {
              let name;
              if (i[0] === "North") {
                name = "北部";
              } else if (i[0] === "East") {
                name = "東部";
              } else if (i[0] === "Center") {
                name = "中部";
              } else if (i[0] === "South") {
                name = "南部";
              }

              if(i[1].length > 0){
                update_option.push({
                  name: `${name}(${thousandDivider(i[1].length)}家)`,
                  value: i[0],
                });
              }

            });
          setState((state) => ({
            ...state,
            sub_result_type_option: update_option,
          }));
          break;
        case "sort_by_city":
          Object.entries(sort_by_city_decline)
            .sort((a, b) => b[1].length - a[1].length)
            .forEach((i) => {
              if(i[1].length > 0){
                update_option.push({
                  name: `${i[0]}(${thousandDivider(i[1].length)}家)`,
                  value: i[0],
                });
              }
            });

          setState((state) => ({
            ...state,
            sub_result_type_option: update_option,
          }));
          break;
        case "sort_by_park":
          Object.entries(sort_by_indu_park_decline)
            .sort((a, b) => b[1].length - a[1].length)
            .forEach((i) => {
              let name;
              if (i[0] === "") {
                name = "區外";
              } else {
                name = i[0];
              }

              if(i[1].length > 0){
                update_option.push({
                  name: `${name}(${thousandDivider(i[1].length)}家)`,
                  value: i[0],
                });
              }
            });

          setState((state) => ({
            ...state,
            sub_result_type_option: update_option,
          }));
          break;
        default:
          setState((state) => ({ ...state, sub_result_type_option: [] }));
      }
    } else {
      const update_option = [];

      switch (state.result_type) {
        case "all_companies":
          setState((state) => ({ ...state, sub_result_type_option: [] }));
          break;
        case "sort_by_area":
          Object.entries(sort_by_area)
            .sort((a, b) => b[1].length - a[1].length)
            .forEach((i) => {
              let name;
              if (i[0] === "North") {
                name = "北部";
              } else if (i[0] === "East") {
                name = "東部";
              } else if (i[0] === "Center") {
                name = "中部";
              } else if (i[0] === "South") {
                name = "南部";
              }

              update_option.push({
                name: `${name}(${thousandDivider(i[1].length)}家)`,
                value: i[0],
              });
            });
          setState((state) => ({
            ...state,
            sub_result_type_option: update_option,
          }));
          break;
        case "sort_by_city":
          Object.entries(sort_by_city)
            .sort((a, b) => b[1].length - a[1].length)
            .forEach((i) => {
              update_option.push({
                name: `${i[0]}(${thousandDivider(i[1].length)}家)`,
                value: i[0],
              });
            });

          setState((state) => ({
            ...state,
            sub_result_type_option: update_option,
          }));
          break;
        case "sort_by_park":
          Object.entries(sort_by_indu_park)
            .sort((a, b) => b[1].length - a[1].length)
            .forEach((i) => {
              let name;
              if (i[0] === "") {
                name = "區外";
              } else {
                name = i[0];
              }

              update_option.push({
                name: `${name}(${thousandDivider(i[1].length)}家)`,
                value: i[0],
              });
            });

          setState((state) => ({
            ...state,
            sub_result_type_option: update_option,
          }));
          break;
        default:
          setState((state) => ({ ...state, sub_result_type_option: [] }));
      }
    }
  }, [state.result_type, is_declined, effected_events_result_list]);

  return (
    <EffectedEventResultListWrapper>
      <EffectedEventResultListHead>
        <div style={innerWidth > 750 ? {display: "flex", justifyContent: "flex-start", alignItems: "flex-end"} : {display: "flex", flexDirection: "column" ,justifyContent: "flex-start", alignItems: "flex-start"}}>
          <EffectedEventResultListHeadText>
            搜尋結果
          </EffectedEventResultListHeadText>
          <EffectedEventResultLisText is_show={data.length !== 0}>共 {thousandDivider(data.length)} 筆資料</EffectedEventResultLisText>
        </div>
        <SelectionContainer>
          <StyledSelect
            value={state.result_type}
            onChange={handle_change}
            // disabled={!is_clicked}
            placeholder={"依條件分佈"}
            name={"result_type"}
          >
            <MenuItem value={"all_companies"}>所有廠商</MenuItem>
            <MenuItem value={"sort_by_area"}>依地區分佈</MenuItem>
            <MenuItem value={"sort_by_city"}>依縣市分佈</MenuItem>
            <MenuItem value={"sort_by_park"}>依園區分佈</MenuItem>
          </StyledSelect>
          <StyledSelect
            value={result_sub_type}
            onChange={handle_change_result_sub_type}
            disabled={state.result_type === "all_companies"}
            placeholder={"條件細節選項"}
            displayEmpty
          >
            <MenuItem value={"no_result_sub_type"}>欲查詢選項</MenuItem>
            {state.sub_result_type_option.map((option) => (
              <MenuItem key={option.name} value={option.value}>
                {option.name}
              </MenuItem>
            ))}
          </StyledSelect>
        </SelectionContainer>
      </EffectedEventResultListHead>
      <ResultList
        loading={loading}
        is_search_console_open={is_search_console_open}
        data={data}
        is_clicked={is_clicked}
      />
    </EffectedEventResultListWrapper>
  );
};

const StyledSelect = styled(Select)`
  max-height: 35px;
  width: 95%;
  margin: 10px 2.5%;
  > fieldset {
  }
`;

const EffectedEventResultListWrapper = styled.div`
  width: 100%;
  padding: 10px 0px;
`;

const EffectedEventResultListHead = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-bottom: 5px;
  transform: translateY(-5px);
  @media (max-width: 750px) {
    margin: 0px 10px;
  }
`;

const EffectedEventResultListHeadText = styled.h2`
  font-size: 1.75rem;
  font-weight: 600;
  padding: 5px 0px;
  @media (max-width: 750px) {
    font-size: 1.25rem;
  }
`;

const SelectionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  transform: translate(-20px, 10px);
`;

const EffectedEventResultLisText = styled.p`
  display: ${(p) => !p.is_show && "none"};
  padding: 5px 0px 5px 5px;

  @media (max-width: 750px) {
    padding: 2.5px;
    font-size: 0.875rem;
  }
`;

export default React.memo(EffectedEventResultList);
