import React from "react";
import { useSelector } from "react-redux";
import VerdictTable from "../../../components/CustomizedTable/VerdictTable";
import CustomizedCard from "../../../components/CustomizedCard/CustomizedCardV3";
import "./styles.scss";
import MobileVerdict from "../../../components/MobileGrid/FactoryInfo/Verdict/Verdict";


export default (props) => {
  const { innerWidth } = useSelector((state) => state.app);
  const { getVerdictData, getVerdictDataLoading } = useSelector(
    (state) => state.factory
  );

  const [state, setState] = React.useState({
    dataTranslate: []
  });

  const url = window.location.origin;
  const openNewTabs = (link) => window.open(link, "_blank");

  React.useEffect(() => {
    getVerdictData.map((row, index) => {
      const VerdictIdList = row.VerdictId.split(",");
      const reVerdictId = row.Court + " " + VerdictIdList[1] + "年度 " + VerdictIdList[2] + "字 第" + VerdictIdList[3] + "號 " + row.RefereeCategory
      state.dataTranslate.push(reVerdictId);
    })
  }, [getVerdictData])
  return (
    <React.Fragment>
      {innerWidth > 1024 && !getVerdictDataLoading ? (
        <CustomizedCard
          title="智財權相關判決書"
          referenceType={"factoryInfoVerdict"}
        >
          <VerdictTable
            headerList={[
              "裁判字號",
              "裁判日期",
              "裁判案由",
            ]}
            data={getVerdictData}
            reVerdictId={state.dataTranslate}
          />
        </CustomizedCard>
      ) : (
        (state.dataTranslate.length != 0) ? (
          <MobileVerdict data={getVerdictData} reVerdictId={state.dataTranslate} referenceType={"factoryInfoVerdict"}/>
        ) : (
          <MobileVerdict data={[]} reVerdictId={[]} referenceType={"factoryInfoVerdict"}/>
        )
      )}
    </React.Fragment>
  );
};
