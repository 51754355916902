import React from "react";
import Spinner from "../Spinner/Spinner";
import Icon from "@material-ui/core/Icon";
import { IconButton } from "@mui/material";
import StockCompanyAbstract from "./StockCompanyAbstract";
import "./styles.scss";

export default (props) => {
  const {
    modalType,
    handleClose,
    title,
    persistModalOpenHandler,
    data
  } = props;

  const [state, setState] = React.useState({
    title: "",
    content: "",
    listContent: "",
    header: "",
    loading: false,
    data: [],
    page: 0,
    rowsPerPage: 5,
  });

  React.useEffect(() => {
    persistModalOpenHandler(state.loading); //當資料在 load 的時候不要讓 modal 關掉
  }, [state]);
  return (
    <div className="dashboard-modal-content-container">
      {state.loading ? (
        <Spinner />
      ) : (
        <>
          <div className="dashboard-modal-content-container-head">
            {((modalType === "stockCompanyTidy") || (modalType === "exportCompanyTidy")) && (
              <h2 id="modal-title">
                {title}廠商資料
              </h2>
            )}
            <IconButton aria-label="關閉彈出視窗" sx={{ p: 0 }} onClick={handleClose}>
              <Icon>
                close
              </Icon>
            </IconButton>
          </div>
          {((modalType === "stockCompanyTidy") || (modalType === "exportCompanyTidy")) && (
            <StockCompanyAbstract data={data} source={modalType == "stockCompanyTidy" ? "stock" : "export"} />
          )}
        </>
      )}
    </div>
  );
};
