import React from "react";
import { useSelector } from "react-redux";
import { Card } from "react-bootstrap";

import DashboardMap from "../../../components/DashboradMap/DashboradMap";
import CustomizedCard from "../../../components/CustomizedCard/CustomizedCardV3";
import RegisteredFactories from "../../../components/MobileGrid/FactoryInfo/RegisteredFactories/RegisteredFactories";
import FactoryRegistedInfo from "../../../components/CustomizedTable/FactoryInfo/FactoryRegisteredInfo";

const Register = () => {
  const { innerWidth } = useSelector((state) => state.app);
  const { getBusinessInfoData, getBusinessInfoDataLoading } = useSelector((state) => state.factory);

  const [state, setState] = React.useState({
    registeredCompanyPositionList: [],
  });

  React.useEffect(() => {
    const updateRegisteredCompanyPositionList = [];
    if (getBusinessInfoData) {
      getBusinessInfoData.map((item) => {
        updateRegisteredCompanyPositionList.push({
          key: item.FactoryNo,
          owner: item.FactoryBoss,
          name: item.FactoryName,
          address: item.FactoryAddress,
          parks: item.InduParkStr ? item.InduParkStr : "區外",
          typeFour: item.InduTypeFour ? item.InduTypeFour : "無產業別四碼登記",
          position: [item.latitude, item.longitude],
        });
      });
    }

    setState((state) => ({
      ...state,
      registeredCompanyPositionList: updateRegisteredCompanyPositionList,
    }));
  }, [getBusinessInfoData]);

  if(innerWidth > 1024){
    return (
      <React.Fragment>
          <Card>
            <CustomizedCard
              title="工廠登記資料"
              referenceType={"factoryInfoRegisterInfo"}
              loading={getBusinessInfoDataLoading}
            >
              <FactoryRegistedInfo
                data={getBusinessInfoData}
                headerList={[
                  "名稱",
                  "工廠登記證號",
                  "地址",
                  "負責人",
                  "產業二碼",
                  "產業四碼",
                  "主要產品",
                ]}
              />
            </CustomizedCard>
          </Card>
        {state.registeredCompanyPositionList.length > 0 && (
          <Card>
            <CustomizedCard title="工廠分佈地圖" referenceType={"factoryInfoMap"}>
              <DashboardMap
                type={"factory"}
                registeredCompanyPositionList={
                  state.registeredCompanyPositionList
                }
              />
            </CustomizedCard>
          </Card>
        )}
      </React.Fragment>
    );
  }else{
    return (
      <React.Fragment>
        <RegisteredFactories data={getBusinessInfoData} referenceType={"factoryInfoRegisterInfo"}/>
        {state.registeredCompanyPositionList.length > 0 && (
          <Card>
            <CustomizedCard title="工廠分佈地圖" referenceType={"factoryInfoMap"}>
              <DashboardMap
                type={"factory"}
                registeredCompanyPositionList={
                  state.registeredCompanyPositionList
                }
              />
            </CustomizedCard>
          </Card>
        )}
      </React.Fragment>
    );
  }
};

export default React.memo(Register);
