import React, { useState, useEffect, useReducer } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Input,
  Icon,
  Checkbox,
  FormControlLabel,
  Collapse,
  Backdrop,
  Slide,
  Dialog,
  Box,
  IconButton,
  ListItemText,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { Autocomplete } from "@material-ui/lab/";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import {
  cities,
  northCities,
  centerCities,
  southCities,
  eastCities,
} from "../../constants/cities";
import {
  parks,
  northParks,
  centerParks,
  southParks,
  eastParks,
} from "../../constants/parks";
import { talents } from "../../constants/talentMockData";
import { typeTwo, typeFour } from "../../constants/types";

import UseCurrentLocation from "./UseCurrentLocation";
import CustomizedMap from "../../components/CustomizedMap/CustomizedMap";
import MapListContainer from "../../components/MapListContainer/MapListContainer";
import Spinner from "../../components/Spinner/Spinner";
import InBoxTab from "../../components/InBoxTab/InBoxTab";
import * as mapActions from "../../store/actions/map";
import { thousandDivider } from "../../utility/common";
import AddNumberToCustomDashboard from "../../components/AddNumberToCustomDashboard";
import ApplyFile from "../../components/ApplyFile/ApplyFile";

import {
  MapSearchHandler,
  EducationSearchHandler,
  keyWordLogHandler,
  CustomizedSearchHandler,
} from "../../utility/api";

import { get_popular_keyword, getRecentPopularKeyword } from "../../utility/fast_search_api";

import "./styles.scss";
import { SettingsInputAntenna } from "@material-ui/icons";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const MySwal = withReactContent(Swal);

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}
const initState = {
  status: "productSearch",
  popularKeyWord: "",
  isAdvance: false,
  isFilterOpen: true,
  isMobileMenuOpen: true,
  mobileResultOpen: false,
  mobileDetailOpen: false,
  isTalentContentOpen: false,
  isTalentDetailOpen: false,
  isDesktopFilterDetailOpen: true,
  isTalentListMenuOpen: false,
  isTalentListOpen: true,
  isDialogOpen: false,
  Operator: "or",
  keywordHolder: "",
  filterCondition: {
    filterKeyWord: [],
    Area: "All",
    Cities: [],
    ParksList: [],
    TypeList: [],
    MinCapital: "",
    MaxCapital: "",
    isIPO: false,
    isInduParks: false,
    isAward: false,
    isPlan: false,
    isPatent: false,
    isIncome: false,
    isOutcome: false,
    numbers: "",
  },
  keyword: [],
  Area: "All",
  Cities: [],
  ParksList: [],
  TypeList: [],
  MinCapital: "",
  MaxCapital: "",
  isArea: true,
  isTypeTwo: true,
  isIPO: false,
  isInduParks: false,
  isAward: false,
  isPlan: false,
  isPatent: false,
  isIncome: false,
  isOutcome: false,
  SpecialConditionCounts: 0,
  SpecialConditions: [],
  filterContainerClass: "map-desktop-filter-open",
  mobileFilterClass: "map-filter-container-mobile",
  isLoading: false,
  cities,
  isMobileFilterContainerOpen: false,
  isFilterDetailOpen: false,
  height: 0,
  tabData: [
    {
      item: <><Icon>gps_fixed</Icon><p>找產品</p></>,
      key: "productSearch",
      isActive: true,
    },
    {
      item: <><Icon>people</Icon><p>找產學</p></>,
      key: "talentSearch",
      isActive: false,
    },
  ],
};

const reducer = (state, action) => {
  const SpecialConditionCountCompute = (status) => {
    if (!status == true) {
      state.SpecialConditionCounts = state.SpecialConditionCounts + 1;
    } else if (state.SpecialConditionCounts != 0) {
      state.SpecialConditionCounts = state.SpecialConditionCounts - 1;
    }
  };

  switch (action.type) {
    case "INIT_STATE":
      return {
        ...initState,
        keyword: [],
        isAdvance: state.isAdvance,
        status: state.status,
        filterCondition: state.filterCondition,
      };
    case "TOGGLE_ADVANCE_SEARCH":
      return {
        ...initState,
        isAdvance: !state.isAdvance,
        filterCondition: state.filterCondition,
      };
    case "UPDATE_KEYWORD_LIST":
      const updateArr = state.keyword;
      updateArr.push(action.keyword);
      return {
        ...state,
        keyword: updateArr,
        // filterCondition:state.filterCondition
      };
    case "INSERT_KEYWORD_LIST":
      const insertArr = action.keyword;
      return {
        ...state,
        keyword: insertArr,
        // filterCondition:state.filterCondition
      };
    case "updateKeyWordHolder":
      return {
        ...state,
        keywordHolder: action.keyword,
      };
    case "POP_KEYWORD_LIST":
      state.keyword.pop();
      return {
        ...state,
        // keyword: []
      };
    case "CLEAR_KEYWORD_HOLDERS":
      return {
        ...state,
        keywordHolder: "",
      };
    case "TOGGLE_FILTER_CONTAINER":
      return {
        ...state,
        filterContainerClass:
          state.filterContainerClass === "map-desktop-filter-open"
            ? "map-desktop-filter-close"
            : "map-desktop-filter-open",
      };
    case "TOGGLE_IS_AREA":
      return {
        ...state,
        Cities: [],
        ParksList: [],
        isArea: !state.isArea,
      };
    case "TOGGLE_TYPE_TWO":
      return {
        ...state,
        TypeList: [],
        isTypeTwo: !state.isTypeTwo,
      };
    case "UPDATE_AREA":
      return {
        ...state,
        Area: action.area,
        Cities: [],
        ParksList: [],
        // cities: cityList,
      };
    case "UPDATE_CITIES":
      if (action.city.length > 3) {
        MySwal.fire({
          text: "最多選擇三個縣市",
        }).then(() => {});

        return state;
      } else {
        return {
          ...state,
          Cities: action.city,
        };
      }
    case "UPDATE_PARKS":
      if (action.park.length > 3) {
        MySwal.fire({
          text: "最多選擇三個園區",
        }).then(() => {});
        return state;
      } else {
        return {
          ...state,
          ParksList: action.park,
        };
      }
    case "UPDATE_TYPES":
      if (action.types.length > 3) {
        MySwal.fire({
          text: "最多選擇三個產業別",
        }).then(() => {});
        return state;
      } else {
        return {
          ...state,
          TypeList: action.types,
        };
      }
    case "UPDATE_MAX_CAPITAL":
      if (action.maxCapital < 0) {
        MySwal.fire({
          text: "最高資本須大於0",
        }).then(() => {});

        return {
          ...state,
          MaxCapital: "",
        };
      } else {
        return {
          ...state,
          MaxCapital: action.maxCapital,
        };
      }
    case "UPDATE_MIN_CAPITAL":
      if (state.MinCapital < 0) {
        MySwal.fire({
          text: "最低資本須大於0",
        }).then(() => {});
        return {
          ...state,
          MinCapital: "",
        };
      } else {
        return {
          ...state,
          MinCapital: action.minCapital,
        };
      }
    case "TOGGLE_IS_IPO":
      return {
        ...state,
        isIPO: !state.isIPO,
      };
    case "TOGGLE_IS_INDUPARKS":
      return {
        ...state,
        isInduParks: !state.isInduParks,
      };
    case "TOGGLE_IS_AWARD":
      SpecialConditionCountCompute(state.isAward);
      return {
        ...state,
        isAward: !state.isAward,
      };
    case "TOGGLE_IS_PLAN":
      SpecialConditionCountCompute(state.isPlan);
      return {
        ...state,
        isPlan: !state.isPlan,
      };
    case "TOGGLE_IS_PATENT":
      SpecialConditionCountCompute(state.isPatent);
      return {
        ...state,
        isPatent: !state.isPatent,
      };
    case "TOGGLE_IS_INCOME":
      SpecialConditionCountCompute(state.isIncome);
      return {
        ...state,
        isIncome: !state.isIncome,
      };
    case "TOGGLE_IS_OUTCOME":
      SpecialConditionCountCompute(state.isOutcome);
      return {
        ...state,
        isOutcome: !state.isOutcome,
      };
    case "START_LOADING":
      return {
        ...state,
        isLoading: true,
      };
    case "STOP_LOADING":
      return {
        ...state,
        isLoading: false,
      };
    case "TOGGLE_MOBILE_FILTER":
      return {
        ...state,
        isMobileFilterContainerOpen: !state.isMobileFilterContainerOpen,
      };
    case "TOGGLE_OPERATOR":
      return {
        ...state,
        Operator: state.Operator === "or" ? "and" : "or",
      };
    case "INSERT_OPERATOR":
      return {
        ...state,
        Operator: action.operator,
      };
    case "INSERT_ADVANCE_OPTIONS":
      return {
        ...state,
        [action.data.name]: action.data.value,
      };
    case "OPEN_ADVANCE_SEARCH":
      return {
        ...state,
        isAdvance: !state.isAdvance,
        filterCondition: state.filterCondition,
      };
    case "ADD_HEIGHT":
      return {
        ...state,
        height: state.height + 1,
      };
    case "TOGGLE_STATUS":
      const updateTabData = [];
      state.tabData.map((data) => {
        if (data.key === action.status) {
          const newObject = { ...data };
          newObject.isActive = true;

          updateTabData.push(newObject);
        } else {
          const falseObject = { ...data };
          falseObject.isActive = false;

          updateTabData.push(falseObject);
        }
      });

      return {
        ...state,
        status: action.status,
        isAdvance: false,
        isMobileFilterContainerOpen: false,
        tabData: updateTabData,
      };
    case "TOGGLE_FILTER_CONDITION":
      return {
        ...state,
        isFilterDetailOpen: !state.isFilterDetailOpen,
      };
    case "TOGGLE_MOBILE_RESULT":
      return {
        ...state,
        mobileResultOpen: !state.mobileResultOpen,
      };
    case "TOGGLE_MOBILE_DETAIL":
      return {
        ...state,
        mobileDetailOpen: !state.mobileDetailOpen,
      };
    case "TOGGLE_MOBILE_FILTER_CONTAINER":
      return {
        ...state,
        isMobileMenuOpen: !state.isMobileMenuOpen,
        mobileFilterClass:
          state.mobileFilterClass === "map-filter-container-mobile"
            ? "map-filter-container-mobile-close"
            : "map-filter-container-mobile",
      };
    case "CLOSE_MOBILE_MENU":
      return {
        ...state,
        mobileFilterClass: "map-filter-container-mobile-close",
      };
    case "CLOSE_MOBILE_RESULT":
      return {
        ...state,
        mobileResultOpen: false,
      };
    case "CLOSE_MOBILE_DETAIL":
      return {
        ...state,
        mobileDetailOpen: false,
      };
    case "CLOSE_MOBILE_FILTER_CONDITION":
      return {
        ...state,
        isFilterDetailOpen: false,
      };
    case "OPEN_TALENT_CONTENT":
      return {
        ...state,
        isTalentContent: true,
        filterContainerClass:
          state.filterContainerClass === "map-desktop-filter-open"
            ? "map-desktop-filter-close"
            : "map-desktop-filter-open",
      };
    case "CLOSE_TALENT_CONTENT":
      return {
        ...state,
        isTalentContent: false,
        status: "talentSearch",
        filterContainerClass:
          state.filterContainerClass === "map-desktop-filter-open"
            ? "map-desktop-filter-close"
            : "map-desktop-filter-open",
        keyword: [],
      };
    case "TOGGLE_TALENT_DETAIL":
      return {
        ...state,
        isTalentDetailOpen: !state.isTalentDetailOpen,
      };
    case "CLOSE_TALENT_DETAIL":
      return {
        ...state,
        isTalentDetailOpen: false,
      };
    case "SET_FILTER_CONDITION":
      return {
        ...state,
        filterCondition: action.filterCondition,
      };
    case "INIT_KEYWORD":
      return {
        ...state,
        keyword: [],
      };
    case "TOGGLE_DESKTOP_DETAIL":
      // 控制桌面版的廠商詳細資訊列表是否開啟
      return {
        ...state,
        isDesktopFilterDetailOpen: !state.isDesktopFilterDetailOpen,
      };
    case "TOGGLE_TALENT_LIST_MENU":
      //控制學界搜尋列表裡面的 menu，桌面版
      return {
        ...state,
        isTalentListMenuOpen: !state.isTalentListMenuOpen,
      };
    case "TOGGLE_TALENT_LIST":
      //控制學界列表，桌面版
      return {
        ...state,
        isTalentListOpen: !state.isTalentListOpen,
      };
    case "TOGGLE_DIALOG_OPEN":
      return {
        ...state,
        isDialogOpen: !state.isDialogOpen,
      };
    default:
      return state;
  }
};

const MapSearch = (props) => {
  const { industry, loading, error } = useSelector((state) => state.map);
  const { innerWidth } = useSelector((state) => state.app);
  const { applyFileInfo, getApplyFileLoading } = useSelector((state) => state.applyFile);
  const dispatch = useDispatch();
  const [params, setParams] = useState({
    keywordObj: "",
    cityObj: "",
    parksObj: "",
    typeObj: "",
  });
  const [data, setData] = useState();
  const [selectedDataList, setSelectedDataList] = useState(data);
  const [selectedLocation, setSelectedLocation] = useState();
  const [mobileFilterClass, setMobileFilterClass] = useState(
    "map-filter-mobile-filter-closed"
  );
  const [state, stateDispatch] = useReducer(reducer, initState);
  const [talentId, setTalentId] = useState();
  const [selectedSchool, setSelectedSchool] = useState();
  const [popularList, setPopularList] = useState([]);
  const [popularEDUList, setPopularEDUList] = useState([]);
  const inputRef = React.useRef();
  const baseUrl = window.location.origin;
  const param = useParams();
  const [rawDataCnts, setRawDataCnts] = useState(0);
  const [location, setLocation] = useState({
    latitude: "",
    longitude: "",
  });
  const [isUseLocation, setIsUseLocation] = useState(false);
  const [range, setRange] = useState(1);
  const [isCustomDashboardModalOpen, setIsCustomDashboardModalOpen] =
    useState(false);
  const [updateCustomDashboardData, setUpdateCustomDashboardData] = useState(
    []
  );
  const [
    isCheckAllCompanyForCustomDashboard,
    setIsCheckAllCompanyForCustomDashboard,
  ] = useState(false);
  const [applyFileModalOpen, setApplyFileModalOpen] = useState(false);

  const basicSelectSettings = {
    anchorOrigin: {
      vertical: "top",
      horizontal: "left",
    },
    transformOrigin: {
      vertical: "bottom",
      horizontal: "left",
    },
    getContentAnchorEl: null,
  }
  const DesktopSelect = {
    PaperProps: {
      style: {
        maxHeight: "300px",
      },
    },
    ...basicSelectSettings,
  };
  const MobileSelect = {
    PaperProps: {
      style: {
        maxHeight: "200px",
      },
    },
    ...basicSelectSettings,
  };

  const handleToggleCheckAllCompanyForCustomDashboard = () => {
    if(!isCheckAllCompanyForCustomDashboard){
      const update_data = data.map( i => i.content.businessNo )
      setUpdateCustomDashboardData(update_data)
    }else{
      setUpdateCustomDashboardData([])
    }
    
    setIsCheckAllCompanyForCustomDashboard(
      !isCheckAllCompanyForCustomDashboard
    );
  }
  const resetCustomDashboardData = () => {
    setUpdateCustomDashboardData([]);
    setIsCheckAllCompanyForCustomDashboard(false)
  }
  const handleUpdateCustomDashboardData = (id) => {
    if (updateCustomDashboardData.indexOf(id) === -1) {
      let update_date = [...updateCustomDashboardData];
      update_date.push(id);
      setUpdateCustomDashboardData(update_date);
    } else {
      setUpdateCustomDashboardData(
        updateCustomDashboardData.filter((i) => i !== id)
      );
    }
  };

  const handleOpenCustomDashboardModalOpen = () => {
    if(updateCustomDashboardData.length === 0){
      Swal.fire({
        text: "至少選擇一間公司。",
        confirmButtonText: "了解"
      })
    }else{
      setIsCustomDashboardModalOpen(true);
    }
  }

  const handleCloseCustomDashboardModalOpen = () =>
    setIsCustomDashboardModalOpen(false);

  const handle_check_is_use_location = (e) => {
    e.stopPropagation();
    if (!isUseLocation) {
      if (!location.latitude || !location.longitude) {
        Swal.fire({
          text: `使用定位功能，須先分享您的位置。`,
        });
      } else {
        setIsUseLocation(true);
      }
    } else {
      setIsUseLocation(false);
    }
  };

  const handle_select_range = (e) => setRange(e.target.value);

  //取得使用者位置
  const handle_get_user_current_location = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        success,
        handle_get_location_error
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  };

  const success = (position) => {
    const longitude = position.coords.longitude;
    const latitude = position.coords.latitude;
    setLocation({
      latitude: latitude,
      longitude: longitude,
    });
  };

  const handle_get_location_error = (error) => {
    console.log(`Couldn't access your location \n Reason: ${error.message}`);
  };

  // const handle_location_permission = () => {
  //   navigator.permissions.query({name:'geolocation'}).then((result) => {
  //     if(result.state === "granted"){
  //       navigator.permissions.revoke({name:'geolocation'}).then(function(result) {
  //         console.log(result)
  //       });
  //     }
  //   })
  // }

  useEffect(() => {
    handle_get_user_current_location();
  }, []);

  const history = useHistory();
  useEffect(async () => {
    const defaultKeyword = await get_popular_keyword();
    const pulularProducts = await getRecentPopularKeyword('Product');
    const pulularTalent = await getRecentPopularKeyword('Talent');
    if (defaultKeyword.status === 200) {
      if (pulularProducts.status === 200) {
        if (pulularProducts.data.data.length >= 3){
          setPopularList(pulularProducts.data.data);
        } else {
          setPopularList(defaultKeyword.data.data);
        }
      } else {
        setPopularList([]);
      }
      if (pulularTalent.status === 200) {
        if (pulularTalent.data.data.length >= 3){
          setPopularEDUList(pulularTalent.data.data);
        } else {
          setPopularEDUList(defaultKeyword.data.dtData_EDU);
        }
      } else {
        setPopularEDUList([]);
      }
    }
    else{
      if (pulularProducts.status === 200) {
        if (pulularProducts.data.data.length >= 3){
          setPopularList(pulularProducts.data.data);
        } else {
          setPopularEDUList([]);
        }
      } else {
        setPopularList([]);
      }
      if (pulularTalent.status === 200) {
        if (pulularTalent.data.data.length >= 3){
          setPopularEDUList(pulularTalent.data.data);
        } else {
          setPopularEDUList([]);
        }
      } else {
        setPopularEDUList([]);
      }
    }
    // 需要先登入才能使用此畫面
    if (localStorage.getItem("isLogin") !== "true") {
      Swal.fire({
        title: "提醒",
        text: "尚未登入，請登入或註冊帳號。",
        showDenyButton: true,
        denyButtonText: `前往登入`,
        denyButtonColor: "#a8a8a8",
        showCloseButton: true,
        confirmButtonText: "前往註冊",
        confirmButtonColor: "#25758A",
        showCancelButton: false,
      }).then((res) => {
        const { isConfirmed, isDenied, isDismissed } = res;
        if (isConfirmed) history.push("/sign-up");
        if (isDenied) history.push("/sign-in");
        if (isDismissed) history.push("/portal");
      });
    }
  }, []);

  useEffect(() => {
    if (selectedSchool && data) {
      const updateDataList = data.filter(
        (data) => data.content.schoolName === selectedSchool
      );
      setSelectedDataList(updateDataList);
    } else {
      setSelectedDataList(data);
    }
  }, [selectedSchool]);

  useEffect(() => {
    setSelectedSchool();
  }, [data]);

  useEffect(() => {
    dispatch(mapActions.initMapSelection());
  }, []);

  useEffect(() => {
    setTalentId(talentId);
  }, [talentId]);

  // 手機版的頁面，控制 modal 哪些該出來哪些不該出來。
  useEffect(() => {
    if (innerWidth >= 1024) {
      stateDispatch({ type: "CLOSE_MOBILE_MENU" });
      stateDispatch({ type: "CLOSE_MOBILE_DETAIL" });
      stateDispatch({ type: "CLOSE_MOBILE_RESULT" });
      stateDispatch({ type: "CLOSE_MOBILE_FILTER_CONDITION_CONTAINER" });
    } else {
      //
      //
      // if (state.mobileFilterClass === "map-filter-container-mobile"){
      //   stateDispatch({ type: "CLOSE_MOBILE_DETAIL" })
      //   stateDispatch({ type: "CLOSE_MOBILE_FILTER_CONDITION_CONTAINER" })
      // }
      // if(state.mobileDetailOpen){
      //   stateDispatch({ type: "CLOSE_MOBILE_MENU" })
      //   stateDispatch({ type: "CLOSE_MOBILE_FILTER_CONDITION_CONTAINER" })
      // }
      // if (state.isFilterDetailOpen) {
      //   stateDispatch({ type: "CLOSE_MOBILE_MENU" })
      //   stateDispatch({ type: "CLOSE_MOBILE_DETAIL" })
      // }
    }
  }, [
    innerWidth,
    state.mobileFilterClass,
    state.mobileResultOpen,
    state.mobileDetailOpen,
    state.isFilterDetailOpen,
  ]);

  React.useEffect(() => {
    if (rawDataCnts > 2000) {
      Swal.fire({
        text: `結果有${thousandDivider(
          rawDataCnts
        )}筆，超過上限(2000筆)，建議您增加搜尋條件後再搜尋`,
      });
    }
  }, [rawDataCnts]);

  const openTalentDetailFromPopUpHandler = (uid) => {
    setTalentId(uid);
    if (innerWidth >= 1024) {
      stateDispatch({ type: "TOGGLE_TALENT_DETAIL" });
    } else {
      stateDispatch({ type: "TOGGLE_DIALOG_OPEN" });
    }
  };

  let mapContent;
  if (loading) {
    mapContent = (
      <div className="spinner">
        <Spinner />
      </div>
    );
  } else {
    mapContent = (
      <CustomizedMap
        data={data}
        cluster
        selectedLocation={selectedLocation}
        closePopUp={() => {
          // stateDispatch({ type: "TOGGLE_MOBILE_FILTER_CONTAINER" })
        }}
        status={state.status}
        openTalentDetailFromPopUp={openTalentDetailFromPopUpHandler}
        onInitState={() => {
          stateDispatch({ type: "INIT_STATE" });
        }}
        current={
          location.latitude && location.longitude
            ? [location.latitude, location.longitude]
            : null
        }
        updateCustomDashboardData={updateCustomDashboardData}
        handleUpdateCustomDashboardData={handleUpdateCustomDashboardData}
        handleOpenCustomDashboardModalOpen={
          handleOpenCustomDashboardModalOpen
        }
      />
    );
  }

  const keywordChangeHandler = (event) => {
    const { value } = event.target;
    stateDispatch({ type: "updateKeyWordHolder", keyword: value });
  };

  const keywordHolderHandler = (event) => {
    if (event.keyCode === 13 || event.keyCode === 32) {
      updateKeyWord();
    } else if (
      event.keyCode === 8 &&
      state.keyword.length > 0 &&
      !state.keywordHolder
    ) {
      stateDispatch({ type: "POP_KEYWORD_LIST" });
    }
  };

  const updateKeyWord = () => {
    if (!state.keywordHolder) {
      return;
    } else if (state.keyword.length >= 3) {
      // TODO:也要改成用 sweet alert
      alert("關鍵字不得大於3個");
      return;
    } else {
      stateDispatch({
        type: "UPDATE_KEYWORD_LIST",
        keyword: state.keywordHolder,
      });
      stateDispatch({ type: "CLEAR_KEYWORD_HOLDERS" });
    }
  };

  const setSelectedLocationHandler = (uid) => {
    stateDispatch({ type: "CLOSE_MOBILE_DETAIL" });
    if (state.status === "productSearch") {
      setSelectedLocation(uid);
    } else {
      setSelectedLocation(uid);
      setTalentId(uid[1]);
      stateDispatch({ type: "TOGGLE_DIALOG_OPEN" });
    }
  };

  const searchHandler = async () => {
    if (state.status === "productSearch") {
      const {
        keyword,
        keywordHolder,
        Area,
        Cities,
        ParksList,
        TypeList,
        MinCapital,
        MaxCapital,
        isIPO,
        isInduParks,
        isAward,
        isPlan,
        isPatent,
        isIncome,
        isOutcome,
        Operator,
      } = state;
      if (
        !keywordHolder &&
        keyword.length === 0 &&
        Area === "All" &&
        Cities.length === 0 &&
        ParksList.length === 0 &&
        TypeList.length === 0 &&
        !MinCapital &&
        !MaxCapital &&
        !isIPO &&
        !isInduParks &&
        !isAward &&
        !isPlan &&
        !isPatent &&
        !isIncome &&
        !isOutcome &&
        !isUseLocation
      ) {
        MySwal.fire({
          text: "請至少輸入一個條件",
        }).then(() => {});
        return;
      } else if (
        !keywordHolder &&
        keyword.length === 0 &&
        Area !== "All" &&
        Cities.length === 0 &&
        ParksList.length === 0 &&
        TypeList.length === 0 &&
        !MinCapital &&
        !MaxCapital &&
        !isIPO &&
        !isInduParks &&
        !isAward &&
        !isPlan &&
        !isPatent &&
        !isIncome &&
        !isOutcome
      ) {
        MySwal.fire({
          text: "因結果資料筆數過多，建議再多設定其他條件",
        }).then(() => {});
        return;
      } else if (
        !keywordHolder &&
        keyword.length === 0 &&
        Area === "All" &&
        Cities.length !== 0 &&
        ParksList.length === 0 &&
        TypeList.length === 0 &&
        !MinCapital &&
        !MaxCapital &&
        !isIPO &&
        !isInduParks &&
        !isAward &&
        !isPlan &&
        !isPatent &&
        !isIncome &&
        !isOutcome
      ) {
        MySwal.fire({
          text: "因結果資料筆數過多，建議再多設定其他條件",
        }).then(() => {});
        return;
      } else if (
        !keywordHolder &&
        keyword.length === 0 &&
        Area === "All" &&
        Cities.length === 0 &&
        ParksList.length === 0 &&
        TypeList.length === 0 &&
        MinCapital &&
        MaxCapital &&
        !isIPO &&
        !isInduParks &&
        !isAward &&
        !isPlan &&
        !isPatent &&
        !isIncome &&
        !isOutcome
      ) {
        MySwal.fire({
          text: "因結果資料筆數過多，建議再多設定其他條件",
        }).then(() => {});
        return;
      } else if (
        !keywordHolder &&
        keyword.length === 0 &&
        Area === "All" &&
        Cities.length === 0 &&
        ParksList.length === 0 &&
        TypeList.length === 0 &&
        MinCapital &&
        !MaxCapital &&
        !isIPO &&
        !isInduParks &&
        !isAward &&
        !isPlan &&
        !isPatent &&
        !isIncome &&
        !isOutcome
      ) {
        MySwal.fire({
          text: "因結果資料筆數過多，建議再多設定其他條件",
        }).then(() => {});
        return;
      } else if (
        !keywordHolder &&
        keyword.length === 0 &&
        Area === "All" &&
        Cities.length === 0 &&
        ParksList.length === 0 &&
        TypeList.length === 0 &&
        !MinCapital &&
        MaxCapital &&
        !isIPO &&
        !isInduParks &&
        !isAward &&
        !isPlan &&
        !isPatent &&
        !isIncome &&
        !isOutcome
      ) {
        MySwal.fire({
          text: "因結果資料筆數過多，建議再多設定其他條件",
        }).then(() => {});
        return;
      } else if (
        !keywordHolder &&
        keyword.length === 0 &&
        Area === "All" &&
        Cities.length === 0 &&
        ParksList.length === 0 &&
        TypeList.length === 0 &&
        !MinCapital &&
        !MaxCapital &&
        !isIPO &&
        isInduParks &&
        !isAward &&
        !isPlan &&
        !isPatent &&
        !isIncome &&
        !isOutcome
      ) {
        MySwal.fire({
          text: "因結果資料筆數過多，建議再多設定其他條件",
        }).then(() => {});
        return;
      } else if (
        !keywordHolder &&
        keyword.length === 0 &&
        Area === "All" &&
        Cities.length === 0 &&
        ParksList.length === 0 &&
        TypeList.length === 0 &&
        !MinCapital &&
        MaxCapital &&
        !isIPO &&
        isInduParks &&
        !isAward &&
        !isPlan &&
        !isPatent &&
        !isIncome &&
        !isOutcome
      ) {
        MySwal.fire({
          text: "因結果資料筆數過多，建議再多設定其他條件",
        }).then(() => {});
        return;
      } else {
        if (keywordHolder && keyword.length === 0) {
          await updateKeyWord();
          productSearch();
        } else if (keywordHolder) {
          await updateKeyWord();
          productSearch();
        } else {
          productSearch();
        }

        stateDispatch({ type: "CLOSE_MOBILE_DETAIL" });
        stateDispatch({ type: "CLOSE_MOBILE_FILTER_CONDITION" });
        stateDispatch({ type: "TOGGLE_MOBILE_FILTER_CONTAINER" });
      }
    } else if (state.status === "talentSearch") {
      if (state.keywordHolder && state.keyword.length === 0) {
        await updateKeyWord();
        stateDispatch({ type: "START_LOADING" });
        const data = new FormData();

        data.append("KeyWordList", state.keyword);
        data.append("Operator", state.Operator);

        const logForm = new FormData();
        logForm.append("UserId", localStorage.getItem("uid"));
        logForm.append("Type", "Talent");
        logForm.append("Keywords", state.keyword);
        keyWordLogHandler(logForm);
        let formDataObj = {};
        data.forEach((value, key) => (
          formDataObj[key] = value
        ))
        localStorage.setItem("mapSearchEdu", JSON.stringify(formDataObj));
        localStorage.removeItem("mapSearch");
        localStorage.removeItem("taxInfo");
        EducationSearchHandler(data).then((res) => {
          const markerList = [];
          if (res.data.data.length === 0) {
            setData();
            MySwal.fire({
              text: "此搜尋條件無結果",
            }).then(() => {});
            stateDispatch({ type: "STOP_LOADING" });
          } else {
            if (localStorage.getItem("isLogin") !== "true") {
              res.data.data.slice(0, 10).map((item) => {
                if (item.lat !== 0 || item.lon !== 0) {
                  markerList.push({
                    position: [item.lat, item.lon],
                    content: item,
                  });
                }
              });
            } else {
              res.data.data.map((item) => {
                if (item.lat !== 0 || item.lon !== 0) {
                  markerList.push({
                    position: [item.lat, item.lon],
                    content: item,
                  });
                }
              });
            }

            setSelectedDataList(markerList);
            setData(markerList);
            stateDispatch({ type: "STOP_LOADING" });
            stateDispatch({ type: "CLOSE_MOBILE_DETAIL" });
            stateDispatch({ type: "CLOSE_MOBILE_FILTER_CONDITION" });
            stateDispatch({ type: "TOGGLE_MOBILE_FILTER_CONTAINER" });
          }
        });

        stateDispatch({
          type: "OPEN_TALENT_CONTENT",
        });
      } else if (!state.keywordHolder && state.keyword.length === 0) {
        MySwal.fire({
          text: "請輸入關鍵字",
        }).then(() => {});
      } else {
        stateDispatch({ type: "START_LOADING" });
        const data = new FormData();

        data.append("KeyWordList", state.keyword);
        data.append("Operator", state.Operator);

        const logForm = new FormData();

        logForm.append("UserId", localStorage.getItem("uid"));
        logForm.append("Type", "Talent");
        logForm.append("Keywords", state.keyword);

        keyWordLogHandler(logForm);
        let formDataObj = {};
        data.forEach((value, key) => (
          formDataObj[key] = value
        ))
        localStorage.setItem("mapSearchEdu", JSON.stringify(formDataObj));
        localStorage.removeItem("mapSearch");
        localStorage.removeItem("taxInfo");
        EducationSearchHandler(data).then((res) => {
          const markerList = [];

          if (res.data.data.length === 0) {
            setData();
            MySwal.fire({
              text: "此搜尋條件無結果",
            }).then(() => {});
            stateDispatch({ type: "STOP_LOADING" });

            // return
          } else {
            if (localStorage.getItem("isLogin") !== "true") {
              res.data.data.slice(0, 10).map((item) => {
                if (item.lat !== 0 || item.lon !== 0) {
                  markerList.push({
                    position: [item.lat, item.lon],
                    content: item,
                  });
                }
              });
            } else {
              res.data.data.map((item) => {
                if (item.lat !== 0 || item.lon !== 0) {
                  markerList.push({
                    position: [item.lat, item.lon],
                    content: item,
                  });
                }
              });
            }

            setSelectedDataList(markerList);
            setData(markerList);
            stateDispatch({ type: "STOP_LOADING" });
            stateDispatch({ type: "CLOSE_MOBILE_DETAIL" });
            stateDispatch({ type: "CLOSE_MOBILE_FILTER_CONDITION" });
            stateDispatch({ type: "TOGGLE_MOBILE_FILTER_CONTAINER" });
          }
        });

        stateDispatch({
          type: "OPEN_TALENT_CONTENT",
        });
      }
    }
  };

  const dataOrderHandler = (type) => {
    setData();
    stateDispatch({ type: "TOGGLE_STATUS", status: type });
  };

  // type: popular, search
  const popularSearch = (type, keyword, status, options) => {
    setIsUseLocation(false);
    if (state.keyword.length > 0) {
      stateDispatch({ type: "INIT_KEYWORD" });
    }
    if (localStorage.getItem("isLogin") !== "true") {
      Swal.fire({
        html:
          "<div>" +
          "<h4>提醒您，未登入將無法取得所有公司清單，</h4>" +
          `請<a href='/sign-up'>登入</a>以獲得更好的體驗。 ` +
          "</div>",
        confirmButtonText: "了解且繼續查詢",
      }).then(() => {
        let form = new FormData();
        form.append("KeyWordList", keyword ? keyword : "");
        form.append("Operator", state.Operator);
        stateDispatch({ type: "START_LOADING" });

        if (status === "EDU") {
          let formDataObj = {};
          form.forEach((value, key) => (
            formDataObj[key] = value
          ))
          localStorage.setItem("mapSearchEdu", JSON.stringify(formDataObj));
          localStorage.removeItem("mapSearch");
          localStorage.removeItem("taxInfo");
          EducationSearchHandler(form)
            .then((res) => {
              const markerList = [];
              if (res.data.data.length === 0) {
                const updateKeyWord = new Array();
                updateKeyWord.push(keyword);

                setData();

                MySwal.fire({
                  text: "此搜尋條件無結果",
                }).then(() => {});

                stateDispatch({ type: "STOP_LOADING" });
              } else {
                const updateKeyWord = new Array();
                updateKeyWord.push(keyword);
                res.data.data.map((item) => {
                  if (item.lat !== 0 || item.lon !== 0) {
                    markerList.push({
                      position: [item.lat, item.lon],
                      content: item,
                    });
                  }
                });

                const update_makers = markerList.slice(0, 10);
                setSelectedDataList(update_makers);
                setData(update_makers);
                stateDispatch({ type: "STOP_LOADING" });

                stateDispatch({ type: "TOGGLE_DESKTOP_DETAIL" });
                stateDispatch({ type: "TOGGLE_FILTER_CONTAINER" });
                
                // 手機版
                stateDispatch({ type: "CLOSE_MOBILE_DETAIL" });
                stateDispatch({ type: "CLOSE_MOBILE_FILTER_CONDITION" });
                stateDispatch({ type: "TOGGLE_MOBILE_FILTER_CONTAINER" });
              }
            })
            .catch((err) => {
              MySwal.fire({
                text: "地圖搜尋發生錯誤",
              }).then(() => {});

              stateDispatch({ type: "STOP_LOADING" });
            });
        } else {
          if (options?.isAdvance) {
            form.append("Area", options?.Area || "All");
            form.append("CitiesList", options?.Cities || "");
            form.append("InduParksList", options?.ParksList || "");
            form.append("MinCapital", options?.MinCapital || "");
            form.append("MaxCapital", options?.MaxCapital || "");
            form.append("isIPO",  options?.isIPO || false);
            form.append("isInduParks",  options?.isInduParks || false);
            form.append("isAward",  options?.isAward || false);
            form.append("isPlan",  options?.isPlan || false);
            form.append("isPatent",  options?.isPatent || false);
            form.append("isIncome",  options?.isIncome || false);
            form.append("isOutcome",  options?.isOutcome || false);
          }
          let formDataObj = {};
          form.forEach((value, key) => (
            formDataObj[key] = value
          ))
          localStorage.setItem("mapSearch", JSON.stringify(formDataObj));
          localStorage.removeItem("mapSearchEdu");
          localStorage.removeItem("taxInfo");
          MapSearchHandler(form)
            .then((res) => {
              setIsUseLocation(false);
              setRawDataCnts(res.data.RawTotalCnts);
              const markerList = [];
              if (res.data.data.length === 0) {
                const updateKeyWord = new Array();
                updateKeyWord.push(keyword);
                const filterCondition = {
                  filterKeyWord: updateKeyWord,
                  Area: state.Area,
                  Cities: state.Cities,
                  ParksList: state.ParksList,
                  TypeList: state.TypeList,
                  MinCapital: state.MinCapital,
                  MaxCapital: state.MaxCapital,
                  isIPO: state.isIPO,
                  isInduParks: state.isInduParks,
                  isAward: state.isAward,
                  isPlan: state.isPlan,
                  isPatent: state.isPatent,
                  isIncome: state.isIncome,
                  isOutcome: state.isOutcome,
                  numbers: 0,
                };
                stateDispatch({
                  type: "SET_FILTER_CONDITION",
                  filterCondition: filterCondition,
                });

                setData();
                MySwal.fire({
                  text: "此搜尋條件無結果",
                }).then(() => {});

                stateDispatch({ type: "STOP_LOADING" });

                // return
              } else {
                const numbers = res.data.data.length;

                const updateKeyWord = new Array();

                updateKeyWord.push(keyword);
                const filterCondition = {
                  filterKeyWord: updateKeyWord,
                  Area: options?.Area || state.Area,
                  Cities: options?.Cities || state.Cities,
                  ParksList: options?.ParksList || state.ParksList,
                  TypeList: options?.TypeList || state.TypeList,
                  MinCapital: options?.MinCapital || state.MinCapital,
                  MaxCapital: options?.MaxCapital || state.MaxCapital,
                  isIPO: options?.isIPO || state.isIPO,
                  isInduParks: options?.isInduParks || state.isInduParks,
                  isAward: options?.isAward || state.isAward,
                  isPlan: options?.isPlan || state.isPlan,
                  isPatent: options?.isPatent || state.isPatent,
                  isIncome: options?.isIncome || state.isIncome,
                  isOutcome: options?.isOutcome || state.isOutcome,
                  numbers: numbers,
                };
                stateDispatch({
                  type: "SET_FILTER_CONDITION",
                  filterCondition: filterCondition,
                });

                res.data.data.map((item) => {
                  if (item.latitude !== 0 || item.longitude !== 0) {
                    markerList.push({
                      position: [item.latitude, item.longitude],
                      content: item,
                    });
                  }
                });

                if (localStorage.getItem("isLogin") !== "true") {
                  const update_makers = markerList.slice(0, 10);
                  setData(update_makers);
                  stateDispatch({ type: "STOP_LOADING" });
                } else {
                  setData(markerList);
                  stateDispatch({ type: "STOP_LOADING" });
                }
              }

              stateDispatch({ type: "TOGGLE_DESKTOP_DETAIL" });
              stateDispatch({ type: "TOGGLE_FILTER_CONTAINER" });
              
              // 手機版
              stateDispatch({ type: "CLOSE_MOBILE_DETAIL" });
              stateDispatch({ type: "CLOSE_MOBILE_FILTER_CONDITION" });
              stateDispatch({ type: "TOGGLE_MOBILE_FILTER_CONTAINER" });
            })
            .catch((err) => {
              MySwal.fire({
                text: "地圖搜尋發生錯誤",
              }).then(() => {});

              stateDispatch({ type: "STOP_LOADING" });
            });
        }
      });
    } else {
      let form = new FormData();

      form.append("KeyWordList", keyword ? keyword : "");
      form.append("Operator", options?.Operator || "or");

      stateDispatch({ type: "START_LOADING" });
      if (status === "EDU") {
        let formDataObj = {};
        form.forEach((value, key) => (
          formDataObj[key] = value
        ))
        localStorage.setItem("mapSearchEdu", JSON.stringify(formDataObj));
        localStorage.removeItem("mapSearch");
        localStorage.removeItem("taxInfo");
        EducationSearchHandler(form)
          .then((res) => {
            const markerList = [];
            if (res.data.data.length === 0) {
              const updateKeyWord = new Array();
              updateKeyWord.push(keyword);

              const filterCondition = {
                filterKeyWord: updateKeyWord,
                Area: state.Area,
                Cities: state.Cities,
                ParksList: state.ParksList,
                TypeList: state.TypeList,
                MinCapital: state.MinCapital,
                MaxCapital: state.MaxCapital,
                isIPO: state.isIPO,
                isInduParks: state.isInduParks,
                isAward: state.isAward,
                isPlan: state.isPlan,
                isPatent: state.isPatent,
                isIncome: state.isIncome,
                isOutcome: state.isOutcome,
                numbers: 0,
              };

              stateDispatch({
                type: "SET_FILTER_CONDITION",
                filterCondition: filterCondition,
              });

              setData();

              MySwal.fire({
                text: "此搜尋條件無結果",
              }).then(() => {});

              stateDispatch({ type: "STOP_LOADING" });
            } else {
              const updateKeyWord = new Array();

              updateKeyWord.push(keyword);

              res.data.data.map((item) => {
                if (item.lat !== 0 || item.lon !== 0) {
                  markerList.push({
                    position: [item.lat, item.lon],
                    content: item,
                  });
                }
              });

              setSelectedDataList(markerList);
              setData(markerList);
              stateDispatch({ type: "STOP_LOADING" });
              stateDispatch({ type: "TOGGLE_DESKTOP_DETAIL" });
              stateDispatch({ type: "TOGGLE_FILTER_CONTAINER" });
              // 手機版
              stateDispatch({ type: "CLOSE_MOBILE_DETAIL" });
              stateDispatch({ type: "CLOSE_MOBILE_FILTER_CONDITION" });
              stateDispatch({ type: "TOGGLE_MOBILE_FILTER_CONTAINER" });
            }
          })
          .catch((err) => {
            MySwal.fire({
              text: "地圖搜尋發生錯誤",
            }).then(() => {});

            stateDispatch({ type: "STOP_LOADING" });
          });
      } else {
        if (options?.isAdvance) {
          form.append("Area", options?.Area || "All");
          form.append("CitiesList", options?.Cities || "");
          form.append("InduParksList", options?.ParksList || "");

          // if (TypeList.length !== 0) {
          //   if (TypeList[0].length === 2) {
          //     form.append("TypeTwoList", TypeList);
          //     form.append("TypeFourList", "");
          //   } else if (TypeList[0].length === 4) {
          //     form.append("TypeTwoList", "");
          //     form.append("TypeFourList", TypeList);
          //   }
          // } else {
          //   form.append("TypeTwoList", "");
          //   form.append("TypeFourList", "");
          // }

          form.append("MinCapital", options?.MinCapital || "");
          form.append("MaxCapital", options?.MaxCapital || "");
          form.append("isIPO",  options?.isIPO || false);
          form.append("isInduParks",  options?.isInduParks || false);
          form.append("isAward",  options?.isAward || false);
          form.append("isPlan",  options?.isPlan || false);
          form.append("isPatent",  options?.isPatent || false);
          form.append("isIncome",  options?.isIncome || false);
          form.append("isOutcome",  options?.isOutcome || false);
        }
        
        let formDataObj = {};
        form.forEach((value, key) => (
          formDataObj[key] = value
        ))
        localStorage.setItem("mapSearch", JSON.stringify(formDataObj));
        localStorage.removeItem("mapSearchEdu");
        localStorage.removeItem("taxInfo");
        MapSearchHandler(form)
          .then((res) => {
            setRawDataCnts(res.data.RawTotalCnts);
            const markerList = [];

            if (res.data.data.length === 0) {
              const updateKeyWord = new Array();

              updateKeyWord.push(keyword);
            
              const filterCondition = {
                filterKeyWord: updateKeyWord,
                Area: state.Area,
                Cities: state.Cities,
                ParksList: state.ParksList,
                TypeList: state.TypeList,
                MinCapital: state.MinCapital,
                MaxCapital: state.MaxCapital,
                isIPO: state.isIPO,
                isInduParks: state.isInduParks,
                isAward: state.isAward,
                isPlan: state.isPlan,
                isPatent: state.isPatent,
                isIncome: state.isIncome,
                isOutcome: state.isOutcome,
                numbers: 0,
              };

              stateDispatch({
                type: "SET_FILTER_CONDITION",
                filterCondition: filterCondition,
              });

              setData();
              MySwal.fire({
                text: "此搜尋條件無結果",
              }).then(() => {});

              stateDispatch({ type: "STOP_LOADING" });

              // return
            } else {
              const numbers = res.data.data.length;

              const updateKeyWord = new Array();

              updateKeyWord.push(keyword);

              const filterCondition = {
                filterKeyWord: updateKeyWord,
                Area: options?.Area || "All",
                Cities: options?.Cities || [],
                ParksList: options?.ParksList || [],
                TypeList: options?.TypeList || [],
                MinCapital: options?.MinCapital || "",
                MaxCapital: options?.MaxCapital || "",
                isIPO: options?.isIPO || false,
                isInduParks: options?.isInduParks || false,
                isAward: options?.isAward || false,
                isPlan: options?.isPlan || false,
                isPatent: options?.isPatent || false,
                isIncome: options?.isIncome || false,
                isOutcome: options?.isOutcome || false,
                numbers: numbers,
              };

              stateDispatch({
                type: "SET_FILTER_CONDITION",
                filterCondition: filterCondition,
              });

              res.data.data.map((item) => {
                if (item.latitude !== 0 || item.longitude !== 0) {
                  markerList.push({
                    position: [item.latitude, item.longitude],
                    content: item,
                  });
                }
              });
              if (localStorage.getItem("isLogin") !== "true") {
                const update_makers = markerList.slice(0, 10);
                setData(update_makers);
                stateDispatch({ type: "STOP_LOADING" });
              } else {
                setData(markerList);
                stateDispatch({ type: "STOP_LOADING" });
              }
            }

            stateDispatch({ type: "TOGGLE_DESKTOP_DETAIL" });
            stateDispatch({ type: "TOGGLE_FILTER_CONTAINER" });

            // 手機版
            stateDispatch({ type: "CLOSE_MOBILE_DETAIL" });
            stateDispatch({ type: "CLOSE_MOBILE_FILTER_CONDITION" });
            stateDispatch({ type: "TOGGLE_MOBILE_FILTER_CONTAINER" });
          })
          .catch((err) => {
            MySwal.fire({
              text: "地圖搜尋發生錯誤",
            }).then(() => {});

            stateDispatch({ type: "STOP_LOADING" });
          });
      }
    }
    if (type === "popular") {
      const array = [];
      array.push(keyword);
      stateDispatch({
        type: "UPDATE_KEYWORD_LIST",
        keyword: array,
      });
    }
  };

  const getQueryVariable = (string, variable) => {
    const query = string.substring(1);
    const vars = query.split('&');
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split('=');
        if (decodeURIComponent(pair[0]) == variable) {
            return decodeURIComponent(pair[1]);
        }
    } 
  }

  const { search } = useLocation(); 
  const queryParam = useQuery();
  
  useEffect(() => {
    const { slug } = param;
    let filterObj = {
      isAdvance: false,
    };
    const keyword = queryParam.get("keyword");
    const operator = queryParam.get("operator");
    const type = queryParam.get("type"); // talentSearch, productSearch 
    // 進階的 params
    const isIPO = queryParam.get("isIPO");
    const isInduParks = queryParam.get("isInduParks");
    const isAward = queryParam.get("isAward");
    const isPatent = queryParam.get("isPatent");
    const isPlan = queryParam.get("isPlan");
    const isIncome = queryParam.get("isIncome");
    const isOutcome = queryParam.get("isOutcome");
    const Area = queryParam.get("Area");
    const Cities = queryParam.get("Cities");
    const ParksList = queryParam.get("ParksList");
    const isArea = queryParam.get("isArea");
    // Area: "All",
    // Cities: [],
    // ParksList: [],
    // TypeList: [],
    // MinCapital: "",
    // MaxCapital: "",
    // isIPO: false,
    // isInduParks: false,
    // isAward: false,
    // isPlan: false,
    // isPatent: false,
    // isIncome: false,
    // isOutcome: false,
    const MinCapital = queryParam.get("min");
    const MaxCapital = queryParam.get("max");
    if (keyword) {
      stateDispatch({
        type: "INSERT_KEYWORD_LIST",
        keyword: keyword.split(","),
      });
      if (operator) {
        filterObj.Operator = operator;
        stateDispatch({
          type: "INSERT_OPERATOR",
          operator: operator,
        });
      }
      if (type) {
        stateDispatch({
          type: "TOGGLE_STATUS",
          status: type,
        });
      }
      // 進階
      if (type === "productSearch" && (MinCapital || MaxCapital || isIPO || isInduParks || isAward || isPlan || isPatent || isIncome || isOutcome || Area || Cities || ParksList || isArea)) {
        filterObj.isAdvance = true;
        stateDispatch({ type: "OPEN_ADVANCE_SEARCH" });
        if (isArea === "false") {
          filterObj.isArea = false;
          stateDispatch({ type: "TOGGLE_IS_AREA" })
          if (ParksList) {
            filterObj.ParksList = JSON.parse(ParksList);
            stateDispatch({
              type: "INSERT_ADVANCE_OPTIONS",
              data: {
                name: "ParksList",
                value: JSON.parse(ParksList)
              }
            });
          }
        } else {
          if (Cities) {
            filterObj.Cities = JSON.parse(Cities);
            stateDispatch({
              type: "INSERT_ADVANCE_OPTIONS",
              data: {
                name: "Cities",
                value: JSON.parse(Cities)
              }
            });
          }
        }
        if (Area) {
          filterObj.Area = Area;
          stateDispatch({
            type: "INSERT_ADVANCE_OPTIONS",
            data: {
              name: "Area",
              value: Area,
            }
          });
        }
        if (MinCapital) {
          filterObj.MinCapital = MinCapital;
          stateDispatch({
            type: "INSERT_ADVANCE_OPTIONS",
            data: {
              name: "MinCapital",
              value: MinCapital,
            }
          });
        }
        if (MaxCapital) {
          filterObj.MaxCapital = MaxCapital;
          stateDispatch({
            type: "INSERT_ADVANCE_OPTIONS",
            data: {
              name: "MaxCapital",
              value: MaxCapital,
            }
          });
        }
        ["isIPO", "isInduParks", "isAward", "isPlan", "isPatent", "isIncome", "isOutcome"].forEach((item) => {
          if (queryParam.get(item) && queryParam.get(item) === "true") {
            filterObj[item] = true;
            stateDispatch({
              type: "INSERT_ADVANCE_OPTIONS",
              data: {
                name: item,
                value: true,
              }
            });
          }
        })
      }
      
      // 查詢
      if (type === "talentSearch") {
        setTimeout(() => {
          popularSearch("search", keyword, "EDU", filterObj); // 用在新 About 的產學搜尋
        }, 1000)
      } else {
        setTimeout(() => {
          popularSearch("search", keyword, "ORG", filterObj);
        }, 1000)
      }
    }
    // if (slug) {
    //   if (getQueryVariable(search, "type") === "talentSearch") {
    //     console.log("slug", slug);
    //     setData();
    //     stateDispatch({
    //       type: "TOGGLE_STATUS",
    //       status: "talentSearch",
    //     });
    //     setTimeout(() => {
    //       popularSearch("popular", slug, "EDU"); // 用在新 About 的產學搜尋
    //     }, 1000)
 
    //   } else {
    //     popularSearch("popular",slug);
    //   }
    // }
  }, []);

  const customizedSearch = (OrgId) => {
    if (state.keyword.length > 0) {
      stateDispatch({ type: "INIT_KEYWORD" });
    }

    let form = new FormData();

    form.append("OrgId", OrgId ? OrgId : "");

    stateDispatch({ type: "START_LOADING" });

    CustomizedSearchHandler(form)
      .then((res) => {
        const markerList = [];

        if (res.data.data.length === 0) {
          const updateKeyWord = new Array();

          updateKeyWord.push(OrgId);
          const filterCondition = {
            filterKeyWord: updateKeyWord,
            Area: state.Area,
            Cities: state.Cities,
            ParksList: state.ParksList,
            TypeList: state.TypeList,
            MinCapital: state.MinCapital,
            MaxCapital: state.MaxCapital,
            isIPO: state.isIPO,
            isInduParks: state.isInduParks,
            isAward: state.isAward,
            isPlan: state.isPlan,
            isPatent: state.isPatent,
            isIncome: state.isIncome,
            isOutcome: state.isOutcome,
            numbers: 0,
          };
          stateDispatch({
            type: "SET_FILTER_CONDITION",
            filterCondition: filterCondition,
          });

          setData();
          MySwal.fire({
            text: "此搜尋條件無結果",
          }).then(() => {});

          stateDispatch({ type: "STOP_LOADING" });

          // return
        } else {
          const numbers = res.data.data.length;

          const updateKeyWord = new Array();

          updateKeyWord.push(OrgId);

          const filterCondition = {
            filterKeyWord: updateKeyWord,
            Area: state.Area,
            Cities: state.Cities,
            ParksList: state.ParksList,
            TypeList: state.TypeList,
            MinCapital: state.MinCapital,
            MaxCapital: state.MaxCapital,
            isIPO: state.isIPO,
            isInduParks: state.isInduParks,
            isAward: state.isAward,
            isPlan: state.isPlan,
            isPatent: state.isPatent,
            isIncome: state.isIncome,
            isOutcome: state.isOutcome,
            numbers: numbers,
          };
          stateDispatch({
            type: "SET_FILTER_CONDITION",
            filterCondition: filterCondition,
          });

          res.data.data.map((item) => {
            if (item.latitude !== 0 || item.longitude !== 0) {
              markerList.push({
                position: [item.latitude, item.longitude],
                content: item,
              });
            }
          });

          if (localStorage.getItem("isLogin") !== "true") {
            const update_makers = markerList.slice(0, 10);
            setData(update_makers);
            stateDispatch({ type: "STOP_LOADING" });
          } else {
            setData(markerList);
            stateDispatch({ type: "STOP_LOADING" });
          }

          setData(markerList);
          stateDispatch({ type: "STOP_LOADING" });
        }

        stateDispatch({ type: "TOGGLE_DESKTOP_DETAIL" });
        stateDispatch({ type: "TOGGLE_FILTER_CONTAINER" });
        stateDispatch({ type: "TOGGLE_MOBILE_FILTER" });
      })
      .catch((err) => {
        MySwal.fire({
          text: "地圖搜尋發生錯誤",
        }).then(() => {});

        stateDispatch({ type: "STOP_LOADING" });
      });
  };

  const productSearch = () => {
    const {
      keyword,
      popularKeyWord,
      Area,
      Cities,
      ParksList,
      TypeList,
      MinCapital,
      MaxCapital,
      isIPO,
      isInduParks,
      isAward,
      isPlan,
      isPatent,
      isIncome,
      isOutcome,
      Operator,
    } = state;

    let data = new FormData();

    data.append("KeyWordList", keyword ? keyword : "");
    data.append("Area", Area ? Area : "");
    data.append("CitiesList", Cities.length !== 0 ? Cities : "");
    data.append("InduParksList", ParksList.length !== 0 ? ParksList : "");

    if (TypeList.length !== 0) {
      if (TypeList[0].length === 2) {
        data.append("TypeTwoList", TypeList);
        data.append("TypeFourList", "");
      } else if (TypeList[0].length === 4) {
        data.append("TypeTwoList", "");
        data.append("TypeFourList", TypeList);
      }
    } else {
      data.append("TypeTwoList", "");
      data.append("TypeFourList", "");
    }

    data.append("MinCapital", MinCapital ? MinCapital : "");
    data.append("MaxCapital", MaxCapital ? MaxCapital : "");
    data.append("isIPO", isIPO);
    data.append("isInduParks", isInduParks);
    data.append("isAward", isAward);
    data.append("isPlan", isPlan);
    data.append("isPatent", isPatent);
    data.append("isIncome", isIncome);
    data.append("isOutcome", isOutcome);
    data.append("Operator", Operator);
    if (isUseLocation) {
      data.append("Position", `${location.latitude},${location.longitude}`);
      data.append("Distance", range);
    }
    stateDispatch({ type: "START_LOADING" });

    const logForm = new FormData();
    logForm.append("UserId", localStorage.getItem("uid"));
    logForm.append("Type", "Product");
    logForm.append("Keywords", keyword);

    keyWordLogHandler(logForm);
    let formDataObj = {};
    data.forEach((value, key) => (
      formDataObj[key] = value
    ))
    localStorage.setItem("mapSearch", JSON.stringify(formDataObj));
    localStorage.removeItem("mapSearchEdu");
    localStorage.removeItem("taxInfo");
    MapSearchHandler(data)
      .then((res) => {
        setRawDataCnts(res.data.RawTotalCnts);
        const markerList = [];

        if (res.data.data.length === 0) {
          const filterKeyWord = [];
          keyword.map((key) => {
            filterKeyWord.push(key);
          });

          const filterCondition = {
            filterKeyWord: filterKeyWord,
            Area: state.Area,
            Cities: state.Cities,
            ParksList: state.ParksList,
            TypeList: state.TypeList,
            MinCapital: state.MinCapital,
            MaxCapital: state.MaxCapital,
            isIPO: state.isIPO,
            isInduParks: state.isInduParks,
            isAward: state.isAward,
            isPlan: state.isPlan,
            isPatent: state.isPatent,
            isIncome: state.isIncome,
            isOutcome: state.isOutcome,
            numbers: 0,
          };
          stateDispatch({
            type: "SET_FILTER_CONDITION",
            filterCondition: filterCondition,
          });

          setData();

          MySwal.fire({
            text: "此搜尋條件無結果",
          }).then(() => {});

          stateDispatch({ type: "STOP_LOADING" });
        } else {
          const numbers = res.data.data.length;
          const filterKeyWord = [];
          keyword.map((key) => {
            filterKeyWord.push(key);
          });

          const filterCondition = {
            filterKeyWord: filterKeyWord,
            Area: Area,
            Cities: Cities,
            ParksList: ParksList,
            TypeList: TypeList,
            MinCapital: MinCapital,
            MaxCapital: MaxCapital,
            isIPO: isIPO,
            isInduParks: isInduParks,
            isAward: isAward,
            isPlan: isPlan,
            isPatent: isPatent,
            isIncome: isIncome,
            isOutcome: isOutcome,
            numbers: numbers,
          };
          stateDispatch({
            type: "SET_FILTER_CONDITION",
            filterCondition: filterCondition,
          });

          res.data.data.map((item) => {
            if (item.latitude !== 0 || item.longitude !== 0) {
              markerList.push({
                position: [item.latitude, item.longitude],
                content: item,
              });
            }
          });

          if (localStorage.getItem("isLogin") !== "true") {
            const update_makers = markerList.slice(0, 10);
            setData(update_makers);
            stateDispatch({ type: "STOP_LOADING" });
          } else {
            setData(markerList);
            stateDispatch({ type: "STOP_LOADING" });
          }
        }

        stateDispatch({ type: "TOGGLE_DESKTOP_DETAIL" });
        stateDispatch({ type: "TOGGLE_FILTER_CONTAINER" });
        stateDispatch({ type: "TOGGLE_MOBILE_FILTER" });
      })
      .catch((err) => {
        MySwal.fire({
          text: "地圖搜尋發生錯誤",
        }).then(() => {});

        stateDispatch({ type: "STOP_LOADING" });
      });
  };

  const AlertNoSignUp = () => {
    Swal.fire({
      html:
        "<div>" +
        "<h4>提醒您，未登入將無法取得所有公司清單，</h4>" +
        `請<a href='/sign-up'>登入</a>以獲得更好的體驗。 ` +
        "</div>",
      confirmButtonText: "了解且繼續查詢",
    }).then(() => {
      searchHandler();
    });
  };

  let content;

  let filterContent;

  if (state.filterContainerClass === "map-desktop-filter-open") {
    filterContent = (
      <div>
        <div className="desktop-filter-container-title">
          {data && (
            <button title="回到搜尋結果" onClick={
              state.status === "productSearch"
                ? () => {
                    stateDispatch({ type: "TOGGLE_FILTER_CONTAINER" });
                  }
                : () => {
                    stateDispatch({ type: "OPEN_TALENT_CONTENT" });
                  }
            }>
              <Icon>
                arrow_back_ios
              </Icon>
            </button>
          )}
          <div className="filter-title">
            <div className="icon">
              <Icon>
                {state.status === "productSearch" ? "gps_fixed" : "people"}
              </Icon>
            </div>
            <div>
              <h2>{state.status === "productSearch" ? "找產品" : "找產學"}</h2>
            </div>
          </div>
          <div></div>
        </div>
        <div className="map-filter-input-container-header">
          <div className="map-filter-input-keyword-container">
            <div className="map-filter-key-word">
              <div className="map-filter-key-word-container">
                {state.keyword.map((item) => (
                  <div className="map-filter-key-word-item">{item}</div>
                ))}
              </div>
              {/* <Autocomplete
                  className="map-filter-key-word-input-wrapper"
                  freeSolo
                  disableClearable
                  defaultValue={state.keywordHolder}
                  ref={inputRef}
                  disableOpenOnFocus
                  renderOption={(option) => {
                    return (
                      <React.Fragment>
                        <span>
                          {option.BusinessNo}, {option.Name}, {option.ParkName}
                        </span>
                      </React.Fragment>
                    );
                  }}
                  renderInput={(params) => (
                    <input
                      placeholder={state.status === "productSearch" ? "產品或廠商關鍵字(至多3個)" : "技術關鍵字(至多3個)"}
                      className="map-filter-key-word-input"
                      onChange={keywordChangeHandler}
                      onKeyDown={(event) => keywordHolderHandler(event)}
                      value={state.keywordHolder}
                      name="KeyWord"
                      {...params}
                    />
                  )}
                /> */}
              <div className="map-filter-key-word-input-wrapper">
                <input
                  placeholder={
                    state.status === "productSearch"
                      ? "產品或廠商關鍵字(至多3個)"
                      : "技術關鍵字(至多3個)"
                  }
                  className="map-filter-key-word-input"
                  onChange={keywordChangeHandler}
                  onKeyDown={(event) => keywordHolderHandler(event)}
                  value={state.keywordHolder}
                  name="KeyWord"
                  {...params}
                />
              </div>
            </div>
            <div className="add-keyword">
              <Icon onClick={updateKeyWord} className="icon">
                add
              </Icon>
            </div>
          </div>
          <div className="map-filter-input-container">
            <div className="map-filter-check-box">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={state.Operator === "or"}
                    onChange={() => stateDispatch({ type: "TOGGLE_OPERATOR" })}
                    name="Operator"
                    // color="primary"
                    onKeyDown={(event) => {
                      if (event.keyCode === 13) {
                        stateDispatch({ type: "TOGGLE_OPERATOR" })
                      }
                    }}
                  />
                }
                label="OR"
              />
            </div>
            <div className="map-filter-check-box">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={state.Operator === "and"}
                    onChange={() => stateDispatch({ type: "TOGGLE_OPERATOR" })}
                    name="Operator"
                    // color="primary"
                    onKeyDown={(event) => {
                      if (event.keyCode === 13) {
                        stateDispatch({ type: "TOGGLE_OPERATOR" })
                      }
                    }}
                  />
                }
                label="AND"
              />
            </div>
          </div>
        </div>
        <Collapse in={state.isAdvance}>
          <div className="search-condition">
            <div className="divider" />
            <div className="map-filter-input-container">
              <FormControl className="map-filter-industry" variant="outlined">
                <InputLabel className="select-label">地區</InputLabel>
                <Select
                  onChange={(event) => {
                    stateDispatch({
                      type: "UPDATE_AREA",
                      area: event.target.value,
                    });
                  }}
                  value={state.Area}
                  className="select"
                  displayEmpty
                  input={<Input name="Area" />}
                >
                  <MenuItem key="all" value="All">
                    台灣所有地區
                  </MenuItem>
                  <MenuItem key="north" value="North">
                    台灣北部地區
                  </MenuItem>
                  <MenuItem key="center" value="Center">
                    台灣中部地區
                  </MenuItem>
                  <MenuItem key="south" value="South">
                    台灣南部地區
                  </MenuItem>
                  <MenuItem key="south" value="East">
                    台灣東部地區
                  </MenuItem>
                </Select>
              </FormControl>
              <div className="tabs-switch-container">
                <div className="tab-switcher">
                  <button
                    onClick={() => stateDispatch({ type: "TOGGLE_IS_AREA" })}
                    className="left-switcher"
                    style={
                      state.isArea
                        ? {
                            backgroundColor: "#888888",
                            color: "#FFFFFF",
                            ": hover": {
                              backgroundColor: "#666666",
                              color: "#ECECEC",
                            },
                          }
                        : { backgroundColor: "#ECECEC", color: "#888888" }
                    }
                  >
                    縣市
                  </button>
                  <button
                    className="right-switcher"
                    onClick={() => stateDispatch({ type: "TOGGLE_IS_AREA" })}
                    style={
                      !state.isArea
                        ? { backgroundColor: "#888888", color: "#FFFFFF" }
                        : { backgroundColor: "#ECECEC", color: "#888888" }
                    }
                  >
                    園區
                  </button>
                </div>
                {state.isArea && state.Area === "All" && (
                  <FormControl
                    className="map-filter-industry"
                    variant="outlined"
                  >
                    <InputLabel className="select-label">縣市</InputLabel>
                    <Select
                      MenuProps={DesktopSelect}
                      onChange={(event) => {
                        stateDispatch({
                          type: "UPDATE_CITIES",
                          city: event.target.value,
                        });
                      }}
                      multiple
                      value={state.Cities}
                      className="select"
                      name="Cities"
                      input={<Input />}
                    >
                      {/* <MenuItem key="" value="">
                            請選擇地區
                          </MenuItem> */}
                      {cities.map((city) => (
                        <MenuItem key={encodeURI(city)} value={city}>
                          {city}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
                {state.isArea && state.Area === "North" && (
                  <FormControl
                    className="map-filter-industry"
                    variant="outlined"
                  >
                    <InputLabel className="select-label">縣市</InputLabel>
                    <Select
                      MenuProps={DesktopSelect}
                      onChange={(event) => {
                        stateDispatch({
                          type: "UPDATE_CITIES",
                          city: event.target.value,
                        });
                      }}
                      multiple
                      value={state.Cities}
                      className="select"
                      name="Cities"
                      input={<Input />}
                    >
                      {/* <MenuItem key="" value="">
                            請選擇地區
                          </MenuItem> */}
                      {northCities.map((city) => (
                        <MenuItem key={encodeURI(city)} value={city}>
                          {city}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
                {state.isArea && state.Area === "Center" && (
                  <FormControl
                    className="map-filter-industry"
                    variant="outlined"
                  >
                    <InputLabel className="select-label">縣市</InputLabel>
                    <Select
                      MenuProps={DesktopSelect}
                      onChange={(event) => {
                        stateDispatch({
                          type: "UPDATE_CITIES",
                          city: event.target.value,
                        });
                      }}
                      multiple
                      value={state.Cities}
                      className="select"
                      name="Cities"
                      input={<Input />}
                    >
                      {/* <MenuItem key="" value="">
                            請選擇地區
                          </MenuItem> */}
                      {centerCities.map((city) => (
                        <MenuItem key={encodeURI(city)} value={city}>
                          {city}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
                {state.isArea && state.Area === "South" && (
                  <FormControl
                    className="map-filter-industry"
                    variant="outlined"
                  >
                    <InputLabel className="select-label">縣市</InputLabel>
                    <Select
                      MenuProps={DesktopSelect}
                      onChange={(event) => {
                        stateDispatch({
                          type: "UPDATE_CITIES",
                          city: event.target.value,
                        });
                      }}
                      multiple
                      value={state.Cities}
                      className="select"
                      name="Cities"
                      input={<Input />}
                    >
                      {/* <MenuItem key="" value="">
                            請選擇地區
                          </MenuItem> */}
                      {southCities.map((city) => (
                        <MenuItem key={encodeURI(city)} value={city}>
                          {city}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
                {state.isArea && state.Area === "East" && (
                  <FormControl
                    className="map-filter-industry"
                    variant="outlined"
                  >
                    <InputLabel className="select-label">縣市</InputLabel>
                    <Select
                      MenuProps={DesktopSelect}
                      onChange={(event) => {
                        stateDispatch({
                          type: "UPDATE_CITIES",
                          city: event.target.value,
                        });
                      }}
                      multiple
                      value={state.Cities}
                      className="select"
                      name="Cities"
                      input={<Input />}
                    >
                      {/* <MenuItem key="" value="">
                            請選擇地區
                          </MenuItem> */}
                      {eastCities.map((city) => (
                        <MenuItem key={encodeURI(city)} value={city}>
                          {city}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
                {!state.isArea && state.Area === "All" && (
                  <FormControl
                    className="map-filter-industry"
                    variant="outlined"
                  >
                    <InputLabel className="select-label">園區</InputLabel>
                    <Select
                      MenuProps={DesktopSelect}
                      onChange={(event) => {
                        stateDispatch({
                          type: "UPDATE_PARKS",
                          park: event.target.value,
                        });
                      }}
                      multiple
                      value={state.ParksList}
                      className="select"
                      name="ParkList"
                      input={<Input />}
                    >
                      {/* <MenuItem key="" value="">
                            請選擇園區
                          </MenuItem> */}
                      {parks.map((indu) => (
                        <MenuItem key={indu.ParkId} value={indu.Name_CH}>
                          {indu.Name_CH}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
                {!state.isArea && state.Area === "North" && (
                  <FormControl
                    className="map-filter-industry"
                    variant="outlined"
                  >
                    <InputLabel className="select-label">園區</InputLabel>
                    <Select
                      MenuProps={DesktopSelect}
                      onChange={(event) => {
                        stateDispatch({
                          type: "UPDATE_PARKS",
                          park: event.target.value,
                        });
                      }}
                      multiple
                      value={state.ParksList}
                      className="select"
                      name="ParkList"
                      input={<Input />}
                    >
                      {/* <MenuItem key="" value="">
                            請選擇園區
                          </MenuItem> */}
                      {northParks.map((indu) => (
                        <MenuItem key={indu.ParkId} value={indu.Name_CH}>
                          {indu.Name_CH}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
                {!state.isArea && state.Area === "Center" && (
                  <FormControl
                    className="map-filter-industry"
                    variant="outlined"
                  >
                    <InputLabel className="select-label">園區</InputLabel>
                    <Select
                      MenuProps={DesktopSelect}
                      onChange={(event) => {
                        stateDispatch({
                          type: "UPDATE_PARKS",
                          park: event.target.value,
                        });
                      }}
                      multiple
                      value={state.ParksList}
                      className="select"
                      name="ParkList"
                      input={<Input />}
                    >
                      {/* <MenuItem key="" value="">
                            請選擇園區
                          </MenuItem> */}
                      {centerParks.map((indu) => (
                        <MenuItem key={indu.ParkId} value={indu.Name_CH}>
                          {indu.Name_CH}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
                {!state.isArea && state.Area === "South" && (
                  <FormControl
                    className="map-filter-industry"
                    variant="outlined"
                  >
                    <InputLabel className="select-label">園區</InputLabel>
                    <Select
                      MenuProps={DesktopSelect}
                      onChange={(event) => {
                        stateDispatch({
                          type: "UPDATE_PARKS",
                          park: event.target.value,
                        });
                      }}
                      multiple
                      value={state.ParksList}
                      className="select"
                      name="ParkList"
                      input={<Input />}
                    >
                      {/* <MenuItem key="" value="">
                            請選擇園區
                          </MenuItem> */}
                      {southParks.map((indu) => (
                        <MenuItem key={indu.ParkId} value={indu.Name_CH}>
                          {indu.Name_CH}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
                {!state.isArea && state.Area === "East" && (
                  <FormControl
                    className="map-filter-industry"
                    variant="outlined"
                  >
                    <InputLabel className="select-label">園區</InputLabel>
                    <Select
                      MenuProps={DesktopSelect}
                      onChange={(event) => {
                        stateDispatch({
                          type: "UPDATE_PARKS",
                          park: event.target.value,
                        });
                      }}
                      multiple
                      value={state.ParksList}
                      className="select"
                      name="ParkList"
                      input={<Input />}
                    >
                      {/* <MenuItem key="" value="">
                            請選擇園區
                          </MenuItem> */}
                      {eastParks.map((indu) => (
                        <MenuItem key={indu.ParkId} value={indu.Name}>
                          {indu.Name_CH}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </div>
            </div>
            <div className="map-filter-input-container">
              <div className="tabs-switch-container">
                <div className="tab-switcher">
                  <button
                    onClick={() => stateDispatch({ type: "TOGGLE_TYPE_TWO" })}
                    className="left-switcher"
                    style={
                      state.isTypeTwo
                        ? { backgroundColor: "#888888", color: "#FFFFFF" }
                        : { backgroundColor: "#ECECEC", color: "#888888" }
                    }
                  >
                    2碼
                  </button>
                  <button
                    onClick={() => stateDispatch({ type: "TOGGLE_TYPE_TWO" })}
                    className="right-switcher"
                    style={
                      !state.isTypeTwo
                        ? { backgroundColor: "#888888", color: "#FFFFFF" }
                        : { backgroundColor: "#ECECEC", color: "#888888" }
                    }
                  >
                    4碼
                  </button>
                </div>
                {state.isTypeTwo && (
                  <FormControl
                    className="map-filter-industry"
                    variant="outlined"
                  >
                    <InputLabel className="select-label">產業別</InputLabel>
                    <Select
                      MenuProps={DesktopSelect}
                      onChange={(event) => {
                        stateDispatch({
                          type: "UPDATE_TYPES",
                          types: event.target.value,
                        });
                      }}
                      value={state.TypeList}
                      multiple
                      className="select"
                      name="TypeList"
                      input={<Input />}
                    >
                      {/* <MenuItem key="" value="">
                          請選擇產業別
                        </MenuItem> */}
                      {typeTwo.map((indu) => (
                        <MenuItem key={indu.Key} value={indu.Key}>
                          {indu.Key + " " + indu.Value}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
                {!state.isTypeTwo && (
                  <FormControl
                    className="map-filter-industry"
                    variant="outlined"
                  >
                    <InputLabel className="select-label">產業別</InputLabel>
                    <Select
                      MenuProps={DesktopSelect}
                      onChange={(event) => {
                        stateDispatch({
                          type: "UPDATE_TYPES",
                          types: event.target.value,
                        });
                      }}
                      value={state.TypeList}
                      multiple
                      className="select"
                      name="TypeList"
                      input={<Input />}
                    >
                      {/* <MenuItem key="" value="">
                          請選擇產業別
                        </MenuItem> */}
                      {typeFour.map((indu) => (
                        <MenuItem key={indu.Key} value={indu.Key}>
                          {indu.Key + " " + indu.Value}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </div>
              <div className="capital-container">
                <input
                  onChange={(event) => {
                    stateDispatch({
                      type: "UPDATE_MIN_CAPITAL",
                      minCapital: event.target.value,
                    });
                  }}
                  type="number"
                  value={state.MinCapital}
                  placeholder="最低資本額"
                ></input>
                <input
                  onChange={(event) => {
                    stateDispatch({
                      type: "UPDATE_MAX_CAPITAL",
                      maxCapital: event.target.value,
                    });
                  }}
                  type="number"
                  value={state.MaxCapital}
                  placeholder="最高資本額"
                ></input>
              </div>
            </div>
            <div className="map-filter-input-container">
              <FormControl className="map-filter-industry" variant="outlined">
                <InputLabel className="select-label">
                  特殊條件快搜(你已勾選{state.SpecialConditionCounts}項)
                </InputLabel>
                <Select
                  multiple
                  value={state.SpecialConditions}
                  className="select"
                  //displayEmpty
                  input={<Input />}
                  onChange={(event) => {
                    const value = event.target.value;
                    if (value.includes("isAward")) {
                      stateDispatch({ type: "TOGGLE_IS_AWARD" })
                    }
                    if (value.includes("isPlan")) {
                      stateDispatch({ type: "TOGGLE_IS_PLAN" })
                    }
                    if (value.includes("isPatent")) {
                      stateDispatch({ type: "TOGGLE_IS_PATENT" })
                    }
                    if (value.includes("isIncome")) {
                      stateDispatch({ type: "TOGGLE_IS_INCOME" })
                    }
                    if (value.includes("isOutcome")) {
                      stateDispatch({ type: "TOGGLE_IS_OUTCOME" })
                    }
                  }}
                >
                  <MenuItem value={"isAward"}>
                    <Checkbox name="isAward" checked={state.isAward} />
                    <ListItemText primary={"近五年得過獎的廠商"} />
                  </MenuItem>
                  <MenuItem value={"isPlan"}>
                    <Checkbox name="isPlan" checked={state.isPlan} />
                    <ListItemText primary={"近五年執行過政府計畫的廠商"} />
                  </MenuItem>
                  <MenuItem value={"isPatent"}>
                    <Checkbox name="isPatent" checked={state.isPatent} />
                    <ListItemText primary={"近五年有專利公告的廠商"} />
                  </MenuItem>
                  <MenuItem value={"isIncome"}>
                    <Checkbox name="isIncome" checked={state.isIncome} />
                    <ListItemText primary={"上一年度進口級距為C以上的廠商"} />
                  </MenuItem>
                  <MenuItem value={"isOutcome"}>
                    <Checkbox name="isOutcome" checked={state.isOutcome} />
                    <ListItemText primary={"上一年度出口級距為C以上的廠商"} />
                  </MenuItem>
                </Select>
              </FormControl>

              <div
                className="map-filter-check-box"
                style={{
                  position: "relative",
                  top: "50%",
                  transform: "translateY(-30%)",
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={state.isIPO}
                      onChange={() => stateDispatch({ type: "TOGGLE_IS_IPO" })}
                      name="isIPO"
                      onKeyDown={(e) => {
                        if (e.keyCode === 13) {
                          stateDispatch({ type: "TOGGLE_IS_IPO" });
                        }
                      }}
                      // color="primary"
                    />
                  }
                  label="股票上市櫃"
                />
              </div>
              <div
                className="map-filter-check-box"
                style={{
                  position: "relative",
                  top: "50%",
                  transform: "translateY(-30%)",
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={state.isInduParks}
                      onChange={() =>
                        stateDispatch({ type: "TOGGLE_IS_INDUPARKS" })
                      }
                      name="isInduParks"
                      onKeyDown={(e) => {
                        if (e.keyCode === 13) {
                          stateDispatch({ type: "TOGGLE_IS_INDUPARKS" });
                        }
                      }}
                      // color="primary"
                    />
                  }
                  label="產業園區"
                />
              </div>
            </div>
            <UseCurrentLocation
              is_use_location={isUseLocation}
              range={range}
              handle_check_is_use_location={handle_check_is_use_location}
              handle_select_range={handle_select_range}
            />
          </div>
        </Collapse>

        <div className="map-filter-input-container">
          <div className="map-filter-input-btn-container">
            <button
              className="map-search"
              onClick={
                localStorage.getItem("isLogin") !== "true"
                  ? () => AlertNoSignUp()
                  : () => searchHandler()
              }
            >
              查詢
            </button>
            {state.status === "productSearch" && (
              <button
                className="more-btn"
                onClick={() => {
                  stateDispatch({ type: "TOGGLE_ADVANCE_SEARCH" });
                }}
              >
                {state.isAdvance ? "簡易搜尋" : "進階搜尋"}
              </button>
            )}
          </div>
          <button
            className="clean-data"
            onClick={() => {
              stateDispatch({ type: "INIT_STATE" });
            }}
          >
            清除資料
          </button>
        </div>
        <Collapse in={!state.isAdvance}>
          <div className="divider" />

          {state.status === "productSearch" ? (
            <div className="popular-keyword">
              <h3>熱門搜尋</h3>
              <div className="popular-keyword-container">
                {popularList.slice(0, 3).map((p, i) => {
                  const color_array = ["#417e86", "#b54f97", "#9c692b"];
                  return (
                    <button
                      onClick={() => {
                        popularSearch("popular", p.Keyword ? p.Keyword : p);
                      }}
                      className="popular-keyword-item"
                      style={{ backgroundColor: color_array[i] }}
                    >
                      {p.Keyword ? p.Keyword : p}
                    </button>
                  );
                })}
              </div>
            </div>
          ) : (
            <div className="popular-keyword">
              <h3>熱門搜尋</h3>
              <div className="popular-keyword-container">
                {popularEDUList.slice(0, 3).map((p, i) => {
                  const color_array = ["#417e86", "#b54f97", "#9c692b"];
                  return (
                    <button
                      onClick={() => {
                        popularSearch("popular", p.Keyword ? p.Keyword : p, "EDU");
                      }}
                      className="popular-keyword-item"
                      style={{ backgroundColor: color_array[i] }}
                    >
                      {p.Keyword ? p.Keyword : p}
                    </button>
                  );
                })}
              </div>
            </div>
          )}
        </Collapse>
      </div>
    );
  } else {
    if (state.status === "talentSearch" && innerWidth >= 1024) {
      const school = [];
      let uniqSchool;

      if (data) {
        data.map((item) => {
          school.push(item.content.schoolName);
        });
      }

      if (school.length !== 0) {
        uniqSchool = [...new Set(school)];
      }

      filterContent = (
        <div className="map-filter-input-list" style={{ width: "40vw" }}>
          {/* <div> */}
          <div className="map-desktop-filter-close-content">
            <div className="talent-result-abstract">
              <div className="talent-result-abstract-title">
                <div className="name">姓名</div>
                <div className="school">服務機關</div>
                <div className="skill">專長</div>
                <div className="hits">命中次數</div>
                <div className="info">詳細資訊</div>
                <div
                  className="more-close"
                  style={
                    state.isTalentListMenuOpen
                      ? { width: "12vw", marginRight: "-7vw" }
                      : { width: "4vw" }
                  }
                >
                <IconButton
                  className="more-icon"
                  title={state.isTalentListMenuOpen? "關閉控制項目清單" : "開啟控制項目清單"}
                  onClick={() => {
                    stateDispatch({ type: "TOGGLE_TALENT_LIST_MENU" });
                  }}> 
                  <Icon>
                    {state.isTalentListMenuOpen
                      ? "keyboard_arrow_left"
                      : "keyboard_arrow_right"}
                  </Icon>
                </IconButton>
                {state.isTalentListMenuOpen && (
                  <div className="icon-container">
                    <IconButton 
                      className="more-icon"
                      onClick={() => {
                        stateDispatch({ type: "TOGGLE_TALENT_LIST" });
                      }}
                      title={state.isTalentListOpen? "折疊列表" : "展開列表"}
                    >
                      <Icon>
                        {state.isTalentListOpen
                          ? "keyboard_arrow_up"
                          : "keyboard_arrow_down"}
                      </Icon>
                    </IconButton>
                    <IconButton 
                      className="more-icon"
                      title={"回到搜尋視窗"}
                      onClick={() => {
                        stateDispatch({ type: "TOGGLE_TALENT_LIST_MENU" });
                        stateDispatch({ type: "CLOSE_TALENT_CONTENT" });
                        stateDispatch({ type: "CLOSE_TALENT_DETAIL" });
                      }}>
                      <Icon>
                        search
                      </Icon>
                    </IconButton>
                  </div>
                  )}
                </div>
              </div>
              <div className="talent-result-abstract-content" />
              <Collapse in={state.isTalentListOpen}>
                <Box display="flex" justifyContent="space-between" alignItems="center"> 
                  <div className="school-selection">
                    <FormControl
                      className="school-selection-form"
                      variant="outlined"
                    >
                      <InputLabel className="select-label">學校</InputLabel>
                      <Select
                        value={selectedSchool}
                        onChange={(event) => {
                          setSelectedSchool(event.target.value);
                        }}
                        input={<Input type="select" />}
                        name="selectedSchool"
                      >
                        <MenuItem value={""} key={"all"}>
                          所有學校
                        </MenuItem>
                        {uniqSchool &&
                          uniqSchool.map((school, index) => (
                            <MenuItem value={school} key={index}>
                              {school}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </div>
                  <Box style={{ backgroundColor: "#ececec", borderRadius: "16px", whiteSpace: "nowrap", padding: "14px"}}> 
                  <p>關鍵字：{state.keyword.map((item, index) => (
                  <span>{`${item} ${index !== state.keyword.length - 1? "," : ""}`}</span>
                ))}</p>
                  <p>{`筆數： ${selectedDataList && selectedDataList.length}`}</p>
                  </Box>
                </Box>
                {data
                  ? selectedDataList.map((content, index) => {
                      return (
                        <button
                          className={
                            (index + 1) % 2 === 0
                              ? "talent-result-abstract-content-odd"
                              : "talent-result-abstract-content-even"
                          }
                          onClick={() => {
                            if (state.isTalentDetailOpen) {
                              stateDispatch({ type: "TOGGLE_TALENT_DETAIL" });
                            }
                            setSelectedLocation([
                              content.position,
                              content.content.researcherNo,
                            ]);
                            setTalentId(content.content.researcherNo);
                            stateDispatch({ type: "TOGGLE_TALENT_DETAIL" });
                          }}
                          key={content.content.uid}
                        >
                          <div className="name">{content.content.name}</div>
                          <div className="school">
                            {content.content.schoolName +
                              " " +
                              content.content.deptName}
                          </div>
                          <div className="skill">{content.content.skill}</div>
                          <div className="hits">
                            {content.content.hits === 0
                              ? 1
                              : content.content.hits}
                          </div>
                          <div className="info">
                            <Icon>more_vert</Icon>
                          </div>
                        </button>
                      );
                    })
                  : null}
              </Collapse>
            </div>
          </div>
          {/* <div >
              <Icon onClick={()=>{stateDispatch({type:"CLOSE_TALENT_CONTENT"})}}>
                arrow_forward_ios
              </Icon>
            </div> */}
        </div>
      );
    } else {
      filterContent = (
        <div className="map-filter-input-list">
          {/* <div> */}
          <div className="map-desktop-filter-close-content">
            <h3>搜尋條件</h3>
            {state.filterCondition.numbers !== 0 && (
              <div className="filtered-item">
                <p>
                  總共{" "}
                  {localStorage.getItem("isLogin") !== "true" &&
                  state.filterCondition.numbers > 10
                    ? 10
                    : state.filterCondition.numbers}{" "}
                  筆資料
                </p>
              </div>
            )}
            {state.filterCondition.numbers === 0 && (
              <div className="filtered-item">
                <p>總共 0 筆資料</p>
              </div>
            )}
            {state.filterCondition.filterKeyWord.length !== 0 && (
              <div className="filtered-item">
                <p>關鍵字:</p>
                {state.filterCondition.filterKeyWord.map((keyword) => (
                  <p>{keyword}</p>
                ))}
              </div>
            )}
            <div className="filtered-item">
              <p>地區:</p>
              <p>
                {state.filterCondition.Area === "All"
                  ? "台灣所有地區"
                  : state.filterCondition.Area === "North"
                  ? "台灣北部地區"
                  : state.filterCondition.Area === "Center"
                  ? "台灣中部地區"
                  : state.filterCondition.Area === "East"
                  ? "台灣東部地區"
                  : "台灣南部地區"}
              </p>
            </div>
            {state.filterCondition.Cities.length !== 0 && (
              <div className="filtered-item">
                <p>縣市:</p>
                {state.filterCondition.Cities.map((city) => (
                  <p>{city}</p>
                ))}
              </div>
            )}
            {/* <p>{state.filterCondition.Cities}</p> */}
            {state.filterCondition.ParksList.length !== 0 && (
              <div className="filtered-item">
                <p>園區:</p>
                {state.filterCondition.ParksList.map((park) => (
                  <p>{park}</p>
                ))}
              </div>
            )}
            {state.filterCondition.TypeList.length !== 0 && (
              <div className="filtered-item">
                <p>產業別:</p>
                {state.filterCondition.TypeList.map((type) => {
                  if (type.length === 2) {
                    const updateTypeTwo = typeTwo.filter(
                      (types) => types.Key === type.toString()
                    );
                    return <p>{updateTypeTwo[0].Value}</p>;
                  } else if (type.length === 4) {
                    const updateTypeFour = typeFour.filter(
                      (types) => types.Key === type.toString()
                    );
                    return <p>{updateTypeFour[0].Value}</p>;
                  }
                })}
              </div>
            )}
            {state.filterCondition.MinCapital && (
              <div className="filtered-item">
                <p>最低資本額:</p>
                <p>{state.filterCondition.MinCapital}</p>
              </div>
            )}
            {state.filterCondition.MaxCapital && (
              <div className="filtered-item">
                <p>最高資本額:</p>
                <p>{state.filterCondition.MaxCapital}</p>
              </div>
            )}
            {state.filterCondition.isIPO && (
              <div className="filtered-item">
                <p>股票上市櫃廠商</p>
              </div>
            )}{" "}
            {state.filterCondition.isInduParks && (
              <div className="filtered-item">
                <p>產業園區內廠商</p>
              </div>
            )}{" "}
            {state.filterCondition.isAward && (
              <div className="filtered-item">
                <p>近五年得過獎的廠商</p>
              </div>
            )}{" "}
            {state.filterCondition.isPlan && (
              <div className="filtered-item">
                <p>近五年執行過政府計畫的廠商</p>
              </div>
            )}{" "}
            {state.filterCondition.isPatent && (
              <div className="filtered-item">
                <p>近五年有專利公告的廠商</p>
              </div>
            )}{" "}
            {state.filterCondition.isOutcome && (
              <div className="filtered-item">
                <p>上一年度出口級距為C以上的廠商</p>
              </div>
            )}{" "}
            {state.filterCondition.isIncome && (
              <div className="filtered-item">
                <p>上一年度進口級距為C以上的廠商</p>
              </div>
            )}{" "}
            {isUseLocation && (
              <div className="filtered-item">
                <p>以你所在位置範圍{range}公里內</p>
              </div>
            )}{" "}
          </div>
          <div
            className="forward"
          >
          <button title="回到搜尋視窗" onClick={() => {
              stateDispatch({ type: "TOGGLE_FILTER_CONTAINER" });
            }}>
              <Icon>arrow_forward_ios</Icon>
            </button>
          </div>
        </div>
      );
    }
  }

  //Desktop Mode
  if (innerWidth >= 1024) {
    content = (
      <div className="map-wrapper">
        <div className={state.filterContainerClass}>
          {state.filterContainerClass === "map-desktop-filter-open" && (
            <div className="in-box-tab">
              <InBoxTab
                data={state.tabData}
                dataOrderHandler={dataOrderHandler}
                filterContainer
                top={"-1vh"}
              />
            </div>
          )}
          {filterContent}
        </div>
        <div className="map-container">{mapContent}</div>
      </div>
    );
  } else {
    //Mobile Mode
    const { isMobileFilterContainerOpen, isMobileMenuOpen, mobileFilterClass } =
      state;

    let filterContent;

    if (isMobileMenuOpen) {
      filterContent = (
        <div className="open-filter">
          <div className="status-container-mobile">
            <div
              className={
                state.status === "productSearch"
                  ? "active-status"
                  : "inactive-status"
              }
              onClick={() => {
                setData();
                stateDispatch({
                  type: "TOGGLE_STATUS",
                  status: "productSearch",
                });
              }}
            >
              <p>找產品</p>
            </div>
            <div
              className={
                state.status === "talentSearch"
                  ? "active-status"
                  : "inactive-status"
              }
              onClick={() => {
                setData();
                stateDispatch({
                  type: "TOGGLE_STATUS",
                  status: "talentSearch",
                });
              }}
            >
              <p>找產學</p>
            </div>
          </div>
          <div className="map-filter-container-mobile-input-group">
            <div className="map-filter-container-mobile-input-container">
              <div className="map-filter-input-list">
                {state.keyword.length > 0 && (
                  <div className="map-filter-key-word-container">
                    {state.keyword.map((item) => (
                      <div className="map-filter-key-word-item" key={item}>
                        {item}
                      </div>
                    ))}
                  </div>
                )}
                <Autocomplete
                  className="map-filter-key-word-container"
                  freeSolo
                  disableClearable
                  // defaultValue={option[0]}
                  // onKey={event => {
                  //   if (event.charCode === 13) {
                  //     event.preventDefault()
                  //     return false
                  //   }
                  // }}
                  // ref={(input) => { setBusinessId(input) }}
                  disableOpenOnFocus
                  renderOption={(option) => {
                    return (
                      <React.Fragment>
                        <span>
                          {option.BusinessNo}, {option.Name}, {option.ParkName}
                        </span>
                      </React.Fragment>
                    );
                  }}
                  // getOptionLabel={option => {
                  //   if (!option.BusinessNo) {
                  //     return
                  //   } else {
                  //     setBusinessId(option.BusinessNo)
                  //     return option.BusinessNo + ', ' + option.Name + ', ' + option.ParkName
                  //   }
                  // }}
                  // onChange={() => { return  }}
                  renderInput={(params) => (
                    <input
                      placeholder={
                        state.status === "productSearch"
                          ? "產品或廠商關鍵字(至多3個)"
                          : "技術關鍵字(至多3個)"
                      }
                      className="map-filter-key-word"
                      onChange={keywordChangeHandler}
                      onKeyDown={(event) => keywordHolderHandler(event)}
                      value={state.keywordHolder}
                      name="KeyWord"
                      {...params}
                    />
                  )}
                />
              </div>
              {state.status === "productSearch" && (
                <Icon
                  className={
                    isMobileFilterContainerOpen ? "filter-up" : "filter-down"
                  }
                  onClick={() => {
                    stateDispatch({ type: "TOGGLE_MOBILE_FILTER" });
                  }}
                >
                  filter_list
                </Icon>
              )}
            </div>
          </div>
          <Collapse
            in={isMobileFilterContainerOpen}
            className="advance-content"
          >
            <div className="divider" />

            <div className="search-condition">
              <div className="map-filter-input-container">
                <div className="map-filter-check-box-mobile">
                  <div className="map-filter-check-box">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={state.Operator === "or"}
                          onChange={() =>
                            stateDispatch({ type: "TOGGLE_OPERATOR" })
                          }
                          name="Operator"
                          // color="primary"
                        />
                      }
                      label="OR"
                    />
                  </div>
                  <div className="map-filter-check-box">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={state.Operator === "and"}
                          onChange={() =>
                            stateDispatch({ type: "TOGGLE_OPERATOR" })
                          }
                          name="Operator"
                          // color="primary"
                        />
                      }
                      label="AND"
                    />
                  </div>
                </div>
              </div>
              <div className="map-filter-input-container">
                <FormControl className="map-filter-industry" variant="outlined">
                  <InputLabel className="select-label">地區</InputLabel>
                  <Select
                    onChange={(event) => {
                      stateDispatch({
                        type: "UPDATE_AREA",
                        area: event.target.value,
                      });
                    }}
                    value={state.Area}
                    className="select"
                    displayEmpty
                    input={<Input name="Area" />}
                  >
                    <MenuItem key="all" value="All">
                      台灣所有地區
                    </MenuItem>
                    <MenuItem key="north" value="North">
                      台灣北部地區
                    </MenuItem>
                    <MenuItem key="center" value="Center">
                      台灣中部地區
                    </MenuItem>
                    <MenuItem key="south" value="South">
                      台灣南部地區
                    </MenuItem>
                    <MenuItem key="south" value="East">
                      台灣東部地區
                    </MenuItem>
                  </Select>
                </FormControl>
                <div className="tabs-switch-container">
                  <div className="tab-switcher">
                    <div
                      onClick={() => stateDispatch({ type: "TOGGLE_IS_AREA" })}
                      className="left-switcher"
                      style={
                        state.isArea
                          ? {
                              backgroundColor: "#888888",
                              color: "#FFFFFF",
                              ": hover": {
                                backgroundColor: "#666666",
                                color: "#ECECEC",
                              },
                            }
                          : { backgroundColor: "#ECECEC", color: "#888888" }
                      }
                    >
                      縣市
                    </div>
                    <div
                      className="right-switcher"
                      onClick={() => stateDispatch({ type: "TOGGLE_IS_AREA" })}
                      style={
                        !state.isArea
                          ? { backgroundColor: "#888888", color: "#FFFFFF" }
                          : { backgroundColor: "#ECECEC", color: "#888888" }
                      }
                    >
                      園區
                    </div>
                  </div>
                  {state.isArea && state.Area === "All" && (
                    <FormControl
                      className="map-filter-industry"
                      variant="outlined"
                    >
                      <InputLabel className="select-label">縣市</InputLabel>
                      <Select
                        MenuProps={MobileSelect}
                        onChange={(event) => {
                          stateDispatch({
                            type: "UPDATE_CITIES",
                            city: event.target.value,
                          });
                        }}
                        multiple
                        value={state.Cities}
                        className="select"
                        name="Cities"
                        input={<Input />}
                      >
                        {/* <MenuItem key="" value="">
                            請選擇地區
                          </MenuItem> */}
                        {cities.map((city) => (
                          <MenuItem key={encodeURI(city)} value={city}>
                            {city}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                  {state.isArea && state.Area === "North" && (
                    <FormControl
                      className="map-filter-industry"
                      variant="outlined"
                    >
                      <InputLabel className="select-label">縣市</InputLabel>
                      <Select
                        MenuProps={MobileSelect}
                        onChange={(event) => {
                          stateDispatch({
                            type: "UPDATE_CITIES",
                            city: event.target.value,
                          });
                        }}
                        multiple
                        value={state.Cities}
                        className="select"
                        name="Cities"
                        input={<Input />}
                      >
                        {/* <MenuItem key="" value="">
                            請選擇地區
                          </MenuItem> */}
                        {northCities.map((city) => (
                          <MenuItem key={encodeURI(city)} value={city}>
                            {city}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                  {state.isArea && state.Area === "Center" && (
                    <FormControl
                      className="map-filter-industry"
                      variant="outlined"
                    >
                      <InputLabel className="select-label">縣市</InputLabel>
                      <Select
                        MenuProps={MobileSelect}
                        onChange={(event) => {
                          stateDispatch({
                            type: "UPDATE_CITIES",
                            city: event.target.value,
                          });
                        }}
                        multiple
                        value={state.Cities}
                        className="select"
                        name="Cities"
                        input={<Input />}
                      >
                        {/* <MenuItem key="" value="">
                            請選擇地區
                          </MenuItem> */}
                        {centerCities.map((city) => (
                          <MenuItem key={encodeURI(city)} value={city}>
                            {city}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                  {state.isArea && state.Area === "South" && (
                    <FormControl
                      className="map-filter-industry"
                      variant="outlined"
                    >
                      <InputLabel className="select-label">縣市</InputLabel>
                      <Select
                        MenuProps={MobileSelect}
                        onChange={(event) => {
                          stateDispatch({
                            type: "UPDATE_CITIES",
                            city: event.target.value,
                          });
                        }}
                        multiple
                        value={state.Cities}
                        className="select"
                        name="Cities"
                        input={<Input />}
                      >
                        {/* <MenuItem key="" value="">
                            請選擇地區
                          </MenuItem> */}
                        {southCities.map((city) => (
                          <MenuItem key={encodeURI(city)} value={city}>
                            {city}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                  {state.isArea && state.Area === "East" && (
                    <FormControl
                      className="map-filter-industry"
                      variant="outlined"
                    >
                      <InputLabel className="select-label">縣市</InputLabel>
                      <Select
                        MenuProps={MobileSelect}
                        onChange={(event) => {
                          stateDispatch({
                            type: "UPDATE_PARKS",
                            park: event.target.value,
                          });
                        }}
                        multiple
                        value={state.ParksList}
                        className="select"
                        name="ParkList"
                        input={<Input />}
                      >
                        {/* <MenuItem key="" value="">
                            請選擇地區
                          </MenuItem> */}
                        {eastCities.map((city) => (
                          <MenuItem key={encodeURI(city)} value={city}>
                            {city}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                  {!state.isArea && state.Area === "All" && (
                    <FormControl
                      className="map-filter-industry"
                      variant="outlined"
                    >
                      <InputLabel className="select-label">園區</InputLabel>
                      <Select
                        MenuProps={MobileSelect}
                        onChange={(event) => {
                          stateDispatch({
                            type: "UPDATE_PARKS",
                            park: event.target.value,
                          });
                        }}
                        multiple
                        value={state.ParksList}
                        className="select"
                        name="ParkList"
                        input={<Input />}
                      >
                        {/* <MenuItem key="" value="">
                            請選擇園區
                          </MenuItem> */}
                        {parks.map((indu) => (
                          <MenuItem key={indu.ParkId} value={indu.Name_CH}>
                            {indu.Name_CH}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                  {!state.isArea && state.Area === "North" && (
                    <FormControl
                      className="map-filter-industry"
                      variant="outlined"
                    >
                      <InputLabel className="select-label">園區</InputLabel>
                      <Select
                        MenuProps={MobileSelect}
                        onChange={(event) => {
                          stateDispatch({
                            type: "UPDATE_PARKS",
                            park: event.target.value,
                          });
                        }}
                        multiple
                        value={state.ParksList}
                        className="select"
                        name="ParkList"
                        input={<Input />}
                      >
                        {/* <MenuItem key="" value="">
                            請選擇園區
                          </MenuItem> */}
                        {northParks.map((indu) => (
                          <MenuItem key={indu.ParkId} value={indu.Name_CH}>
                            {indu.Name_CH}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                  {!state.isArea && state.Area === "Center" && (
                    <FormControl
                      className="map-filter-industry"
                      variant="outlined"
                    >
                      <InputLabel className="select-label">園區</InputLabel>
                      <Select
                        MenuProps={MobileSelect}
                        onChange={(event) => {
                          stateDispatch({
                            type: "UPDATE_PARKS",
                            park: event.target.value,
                          });
                        }}
                        multiple
                        value={state.ParksList}
                        className="select"
                        name="ParkList"
                        input={<Input />}
                      >
                        {/* <MenuItem key="" value="">
                            請選擇園區
                          </MenuItem> */}
                        {centerParks.map((indu) => (
                          <MenuItem key={indu.ParkId} value={indu.Name_CH}>
                            {indu.Name_CH}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                  {!state.isArea && state.Area === "South" && (
                    <FormControl
                      className="map-filter-industry"
                      variant="outlined"
                    >
                      <InputLabel className="select-label">園區</InputLabel>
                      <Select
                        MenuProps={MobileSelect}
                        onChange={(event) => {
                          stateDispatch({
                            type: "UPDATE_PARKS",
                            park: event.target.value,
                          });
                        }}
                        multiple
                        value={state.ParksList}
                        className="select"
                        name="ParkList"
                        input={<Input />}
                      >
                        {/* <MenuItem key="" value="">
                            請選擇園區
                          </MenuItem> */}
                        {southParks.map((indu) => (
                          <MenuItem key={indu.ParkId} value={indu.Name_CH}>
                            {indu.Name_CH}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                  {!state.isArea && state.Area === "East" && (
                    <FormControl
                      className="map-filter-industry"
                      variant="outlined"
                    >
                      <InputLabel className="select-label">園區</InputLabel>
                      <Select
                        MenuProps={MobileSelect}
                        onChange={(event) => {
                          stateDispatch({
                            type: "UPDATE_PARKS",
                            park: event.target.value,
                          });
                        }}
                        multiple
                        value={state.ParksList}
                        className="select"
                        name="ParkList"
                        input={<Input />}
                      >
                        {/* <MenuItem key="" value="">
                            請選擇園區
                          </MenuItem> */}
                        {eastParks.map((indu) => (
                          <MenuItem key={indu.ParkId} value={indu.Name_CH}>
                            {indu.Name_CH}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                </div>
              </div>
              <div className="map-filter-input-container">
                <div className="tabs-switch-container">
                  <div className="tab-switcher">
                    <div
                      onClick={() => stateDispatch({ type: "TOGGLE_TYPE_TWO" })}
                      className="left-switcher"
                      style={
                        state.isTypeTwo
                          ? { backgroundColor: "#888888", color: "#FFFFFF" }
                          : { backgroundColor: "#ECECEC", color: "#888888" }
                      }
                    >
                      2碼
                    </div>
                    <div
                      onClick={() => stateDispatch({ type: "TOGGLE_TYPE_TWO" })}
                      className="right-switcher"
                      style={
                        !state.isTypeTwo
                          ? { backgroundColor: "#888888", color: "#FFFFFF" }
                          : { backgroundColor: "#ECECEC", color: "#888888" }
                      }
                    >
                      4碼
                    </div>
                  </div>
                  {state.isTypeTwo && (
                    <FormControl
                      className="map-filter-industry"
                      variant="outlined"
                    >
                      <InputLabel className="select-label">產業別</InputLabel>
                      <Select
                        MenuProps={MobileSelect}
                        onChange={(event) => {
                          stateDispatch({
                            type: "UPDATE_TYPES",
                            types: event.target.value,
                          });
                        }}
                        value={state.TypeList}
                        multiple
                        className="select"
                        name="TypeList"
                        input={<Input />}
                      >
                        {/* <MenuItem key="" value="">
                            請選擇產業別
                          </MenuItem> */}
                        {typeTwo.map((indu) => (
                          <MenuItem key={indu.Key} value={indu.Key}>
                            {indu.Key + " " + indu.Value}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                  {!state.isTypeTwo && (
                    <FormControl
                      className="map-filter-industry"
                      variant="outlined"
                    >
                      <InputLabel className="select-label">產業別</InputLabel>
                      <Select
                        MenuProps={MobileSelect}
                        onChange={(event) => {
                          stateDispatch({
                            type: "UPDATE_TYPES",
                            types: event.target.value,
                          });
                        }}
                        value={state.TypeList}
                        multiple
                        className="select"
                        name="TypeList"
                        input={<Input />}
                      >
                        {/* <MenuItem key="" value="">
                            請選擇產業別
                          </MenuItem> */}
                        {typeFour.map((indu) => (
                          <MenuItem key={indu.Key} value={indu.Key}>
                            {indu.Key + " " + indu.Value}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                </div>
                <div className="capital-container">
                  <input
                    onChange={(event) => {
                      stateDispatch({
                        type: "UPDATE_MIN_CAPITAL",
                        minCapital: event.target.value,
                      });
                    }}
                    type="number"
                    value={state.MinCapital}
                    placeholder="最低資本額(元)"
                  ></input>
                  <input
                    onChange={(event) => {
                      stateDispatch({
                        type: "UPDATE_MAX_CAPITAL",
                        maxCapital: event.target.value,
                      });
                    }}
                    type="number"
                    value={state.MaxCapital}
                    placeholder="最高資本額(元)"
                  ></input>
                </div>
              </div>
              <div className="map-filter-input-container">
                <FormControl className="map-filter-industry" variant="outlined">
                  <InputLabel className="select-label">
                    特殊條件快搜(你已勾選{state.SpecialConditionCounts}項)
                  </InputLabel>
                  <Select
                    multiple
                    value={state.SpecialConditions}
                    className="select"
                    //displayEmpty
                    input={<Input />}
                  >
                    <MenuItem>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={state.isAward}
                            onChange={() =>
                              stateDispatch({ type: "TOGGLE_IS_AWARD" })
                            }
                            name="isAward"
                          />
                        }
                        label="近五年得過獎的廠商"
                      />
                    </MenuItem>
                    <MenuItem>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={state.isPlan}
                            onChange={() =>
                              stateDispatch({ type: "TOGGLE_IS_PLAN" })
                            }
                            name="isPlan"
                          />
                        }
                        label="近五年執行過政府計畫的廠商"
                      />
                    </MenuItem>
                    <MenuItem>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={state.isPatent}
                            onChange={() =>
                              stateDispatch({ type: "TOGGLE_IS_PATENT" })
                            }
                            name="isPatent"
                          />
                        }
                        label="近五年有專利公告的廠商"
                      />
                    </MenuItem>
                    <MenuItem>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={state.isIncome}
                            onChange={() =>
                              stateDispatch({ type: "TOGGLE_IS_INCOME" })
                            }
                            name="isIncome"
                          />
                        }
                        label="上一年度進口級距為C以上的廠商"
                      />
                    </MenuItem>
                    <MenuItem>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={state.isOutcome}
                            onChange={() =>
                              stateDispatch({ type: "TOGGLE_IS_OUTCOME" })
                            }
                            name="isOutcome"
                          />
                        }
                        label="上一年度出口級距為C以上的廠商"
                      />
                    </MenuItem>
                  </Select>
                </FormControl>
                <div className="map-filter-check-box-mobile">
                  <div className="map-filter-check-box">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={state.isIPO}
                          onChange={() =>
                            stateDispatch({ type: "TOGGLE_IS_IPO" })
                          }
                          name="isIPO"
                          // color="primary"
                        />
                      }
                      label="股票IPO"
                    />
                  </div>
                  <div className="map-filter-check-box">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={state.isInduParks}
                          onChange={() =>
                            stateDispatch({ type: "TOGGLE_IS_INDUPARKS" })
                          }
                          name="isInduParks"
                          // color="primary"
                        />
                      }
                      label="產業園區"
                    />
                  </div>
                </div>
                <div className="map-filter-check-box-mobile">
                  <UseCurrentLocation
                    is_use_location={isUseLocation}
                    range={range}
                    handle_check_is_use_location={handle_check_is_use_location}
                    handle_select_range={handle_select_range}
                  />
                </div>
              </div>
            </div>
          </Collapse>
          {/* <div className="map-filter-input-container">
              <FormControl className="map-filter-industry" variant="outlined">
                <InputLabel className="select-label">行業</InputLabel>
                <Select
                  // onChange={(event) => onChangeParams(event)}
                  value={params.industry}
                  className="select"
                  name="industry"
                  input={<Input />}
                >
                  <MenuItem key="" value="">
                    請選擇行業別
                  </MenuItem>
                  {industry
                    ? industry.map((indu) => (
                        <MenuItem key={indu.Key} value={indu.Key + indu.Value}>
                          {indu.Value}
                        </MenuItem>
                      ))
                    : null}
                </Select>
              </FormControl>
            </div>
            <div className="map-filter-input-container">
              <FormControl className="map-filter-cities" variant="outlined">
                <InputLabel className="select-label">縣市</InputLabel>
                <Select
                  // onChange={event => generateCompanyListByParkId(event)}
                  // value={selectedParkId}
                  className="select"
                  input={<Input />}
                >
                  <MenuItem key="" value="">
                    請選擇產業園區
                  </MenuItem>
                  {}
                </Select>
              </FormControl>
            </div>
            <div className="map-filter-input-container">
              <FormControl className="map-filter-parks" variant="outlined">
                <InputLabel className="select-label">園區</InputLabel>
                <Select
                  // onChange={(event) => onChangeParams(event)}
                  value={params.parks}
                  className="select"
                  name="parks"
                  input={<Input />}
                >
                  <MenuItem key="" value="">
                    請選擇產業園區
                  </MenuItem>
                  {parks
                    ? parks.map((park) => (
                        <MenuItem key={park.ParkId} value={park.Name}>
                          {park.Name}
                        </MenuItem>
                      ))
                    : null}
                </Select>
              </FormControl>
            </div> */}
          <div className="map-filter-input-container">
            <button
              className="map-search"
              onClick={
                localStorage.getItem("isLogin") !== "true"
                  ? () => AlertNoSignUp()
                  : () => searchHandler()
              }
            >
              查詢
            </button>
            {/* {data &&
              <Icon className="more-in-mobile"
                onClick={() => {
                  if (state.isFilterDetailOpen) {
                    stateDispatch({ type: "TOGGLE_FILTER_CONDITION" })
                  }

                }}
              >view_list</Icon>
              }
              {data &&
              <Icon className="more-in-mobile"
                onClick={() => {
                  stateDispatch({ type: "TOGGLE_FILTER_CONDITION" })

                }}
              >tune</Icon>
              } */}
            <Icon
              className="more-in-mobile"
              onClick={() => {
                let isDelete = window.confirm("確定要清除所有條件？");
                if (isDelete) {
                  stateDispatch({ type: "INIT_STATE" });
                  stateDispatch({ type: "CLEAR_KEYWORD_HOLDERS" });
                } else {
                  return;
                }
              }}
            >
              delete_sweep
            </Icon>
            {data && (
              <Icon
                className="more-in-mobile"
                onClick={() => {
                  stateDispatch({ type: "TOGGLE_MOBILE_FILTER_CONTAINER" });
                  // stateDispatch({ type:"OPEN_MOBILE_MENU_WITH_CLOSE_ADVANCE" })
                }}
              >
                search
              </Icon>
            )}
          </div>
        </div>
      );
    } else {
      filterContent = (
        <div className="closed-filter">
          {data && state.status === "productSearch" && (
            <Icon
              className="more-in-mobile"
              onClick={() => {
                stateDispatch({ type: "TOGGLE_FILTER_CONDITION" });
                stateDispatch({ type: "CLOSE_MOBILE_DETAIL" });
              }}
            >
              tune
            </Icon>
          )}
          {data && state.status === "talentSearch" && (
            <Icon
              className="more-in-mobile"
              onClick={() => {
                stateDispatch({ type: "TOGGLE_MOBILE_RESULT" });
                stateDispatch({ type: "CLOSE_MOBILE_DETAIL" });
                stateDispatch({ type: "CLOSE_MOBILE_FILTER_CONDITION" });
              }}
            >
              tune
            </Icon>
          )}
          {data && (
            <Icon
              className="more-in-mobile"
              onClick={() => {
                stateDispatch({ type: "CLOSE_MOBILE_RESULT" });
                stateDispatch({ type: "TOGGLE_MOBILE_DETAIL" });
                stateDispatch({ type: "CLOSE_MOBILE_FILTER_CONDITION" });
              }}
            >
              view_list
            </Icon>
          )}
          <Icon
            className="more-in-mobile"
            onClick={() => {
              stateDispatch({ type: "CLOSE_MOBILE_RESULT" });
              stateDispatch({ type: "TOGGLE_MOBILE_FILTER_CONTAINER" });
              stateDispatch({ type: "CLOSE_MOBILE_DETAIL" });
              stateDispatch({ type: "CLOSE_MOBILE_FILTER_CONDITION" });
            }}
          >
            search
          </Icon>
        </div>
      );
    }

    content = (
      <div>
        <div className="map-container-mobile">{mapContent}</div>
        <div
          className={mobileFilterClass}
          style={
            mobileFilterClass === "map-filter-container-mobile-close" &&
            window.innerWidth < 600
              ? {
                  left: `${window.innerWidth - 180}px`,
                  top: `${window.innerHeight - 150}px`,
                }
              : null
          }
        >
          {filterContent}
        </div>
      </div>
    );
  }

  return (
    <div className="map-search">
      <AddNumberToCustomDashboard
        is_open={isCustomDashboardModalOpen}
        handleClose={handleCloseCustomDashboardModalOpen}
        business_no={updateCustomDashboardData}
        is_map_search
        custom_style={
          innerWidth < 750
            ? { transform: "translate(0px, 7px)" }
            : { transform: "translate(0px, 67px)" }
        }
        custom_modal_content_style={
          innerWidth < 750
            ? { transform: "translateY(50px)" }
            : { transform: "translateY(150px)" }
        }
        is_show_default_add
        type="MapSearch"
      />
      <ApplyFile
        isOpen={applyFileModalOpen}
        closeModal={() => {
          setApplyFileModalOpen(false);
        }}
        data={data}
        source="2"
        dataLimit={applyFileInfo}
      />
      {content}
      {state.filterContainerClass !== "map-desktop-filter-open" &&
        innerWidth >= 1024 && (
          <MapListContainer
            is_check_all_custom_dashboard={isCheckAllCompanyForCustomDashboard}
            handleToggleCheckAllCompanyForCustomDashboard={
              handleToggleCheckAllCompanyForCustomDashboard
            }
            updateCustomDashboardData={updateCustomDashboardData}
            handleUpdateCustomDashboardData={handleUpdateCustomDashboardData}
            resetCustomDashboardData={resetCustomDashboardData}
            data={data}
            left={state.isDesktopFilterDetailOpen ? "73%" : "83%"}
            top={"17%"}
            width={state.isDesktopFilterDetailOpen ? "25vw" : "14vw"}
            listType={"map-search-result"}
            setSelectedLocationHandler={setSelectedLocationHandler}
            isDetailOpen={
              state.status === "productSearch"
                ? state.isDesktopFilterDetailOpen
                : true
            }
            toggleFilterDetail={() => {
              stateDispatch({ type: "TOGGLE_DESKTOP_DETAIL" });
            }}
            hiddenBackground={state.isDesktopFilterDetailOpen ? false : true}
            status={state.status}
            isUseLocation={isUseLocation}
            range={range}
            handleOpenCustomDashboardModalOpen={
              handleOpenCustomDashboardModalOpen
            }
            openApplyFile={() => {
              setApplyFileModalOpen(true);
            }}
            dataCount={state.filterCondition.numbers}
            applyFileInfo={applyFileInfo}
          />
        )}
      {innerWidth < 1024 && state.mobileResultOpen && (
         <MapListContainer
         is_check_all_custom_dashboard={isCheckAllCompanyForCustomDashboard}
         handleToggleCheckAllCompanyForCustomDashboard={
           handleToggleCheckAllCompanyForCustomDashboard
         }
         updateCustomDashboardData={updateCustomDashboardData}
         handleUpdateCustomDashboardData={handleUpdateCustomDashboardData}
         resetCustomDashboardData={resetCustomDashboardData}
         data={data}
         left={"2.5vw"}
         top={"1%"}
         listType={"map-search-result-length"}
         width={"90vw"}
         isDetailOpen={true}
         status={state.status}
         setSelectedLocationHandler={setSelectedLocationHandler}
         isUseLocation={isUseLocation}
         range={range}
         handleOpenCustomDashboardModalOpen={
           handleOpenCustomDashboardModalOpen
         }
         openApplyFile={() => {
           setApplyFileModalOpen(true);
         }}
         keyword={state.keyword}
         dataCount={state.filterCondition.numbers}
         applyFileInfo={applyFileInfo}
       />
      )}
      {innerWidth < 1024 && state.mobileDetailOpen && (
        <MapListContainer
          is_check_all_custom_dashboard={isCheckAllCompanyForCustomDashboard}
          handleToggleCheckAllCompanyForCustomDashboard={
            handleToggleCheckAllCompanyForCustomDashboard
          }
          updateCustomDashboardData={updateCustomDashboardData}
          handleUpdateCustomDashboardData={handleUpdateCustomDashboardData}
          resetCustomDashboardData={resetCustomDashboardData}
          data={data}
          left={"2.5vw"}
          top={"1%"}
          listType={"map-search-result"}
          width={"90vw"}
          isDetailOpen={true}
          status={state.status}
          setSelectedLocationHandler={setSelectedLocationHandler}
          isUseLocation={isUseLocation}
          range={range}
          handleOpenCustomDashboardModalOpen={
            handleOpenCustomDashboardModalOpen
          }
          openApplyFile={() => {
            setApplyFileModalOpen(true);
          }}
          dataCount={state.filterCondition.numbers}
          applyFileInfo={applyFileInfo}
        />
      )}
      {innerWidth < 1024 && state.isFilterDetailOpen && (
        <MapListContainer
          is_check_all_custom_dashboard={isCheckAllCompanyForCustomDashboard}
          handleToggleCheckAllCompanyForCustomDashboard={
            handleToggleCheckAllCompanyForCustomDashboard
          }
          updateCustomDashboardData={updateCustomDashboardData}
          handleUpdateCustomDashboardData={handleUpdateCustomDashboardData}
          resetCustomDashboardData={resetCustomDashboardData}
          data={[
            state.filterCondition.filterKeyWord,
            state.filterCondition.Area,
            state.filterCondition.Cities,
            state.filterCondition.ParksList,
            state.filterCondition.TypeList,
            state.filterCondition.isIPO,
            state.filterCondition.isInduParks,
            state.filterCondition.MinCapital,
            state.filterCondition.MaxCapital,
            state.filterCondition.isAward,
            state.filterCondition.isPlan,
            state.filterCondition.isPatent,
            state.filterCondition.isIncome,
            state.filterCondition.isOutcome,
            // state.data.length
          ]}
          left={"2.5vw"}
          top={"1%"}
          width={"90vw"}
          listType={"filter-condition-mobile"}
          lengthOfData={state.filterCondition.numbers}
          setSelectedLocationHandler={setSelectedLocationHandler}
          isUseLocation={isUseLocation}
          range={range}
          handleOpenCustomDashboardModalOpen={
            handleOpenCustomDashboardModalOpen
          }
          openApplyFile={() => {
            setApplyFileModalOpen(true);
          }}
          dataCount={state.filterCondition.numbers}
          applyFileInfo={applyFileInfo}
        />
      )}
      {/* {state.filterContainerClass !== "map-desktop-filter-open" && innerWidth < 1024 && state.isFilterDetailOpen && (
          <MapListContainer
            data={[
              state.keyword,
              state.Area,
              state.Cities,
              state.ParksList,
              state.TypeList,
              state.isIPO,
              state.isInduParks
            ]}
            left={"2.5vw"}
            top={"1%"}
            listType={"filter-condition-mobile"}
            lengthOfData={data.length}
            setSelectedLocationHandler={setSelectedLocationHandler}
          />
        )} */}
      {state.isTalentDetailOpen && innerWidth > 1024 && (
        <MapListContainer
          is_check_all_custom_dashboard={isCheckAllCompanyForCustomDashboard}
          handleToggleCheckAllCompanyForCustomDashboard={
            handleToggleCheckAllCompanyForCustomDashboard
          }
          updateCustomDashboardData={updateCustomDashboardData}
          handleUpdateCustomDashboardData={handleUpdateCustomDashboardData}
          resetCustomDashboardData={resetCustomDashboardData}
          data={talents}
          left={"59vw"}
          top={"17%"}
          width={"40vw"}
          listType={"isTalentDetail"}
          // lengthOfData={data.length}
          closeDetail={() => {
            stateDispatch({ type: "CLOSE_TALENT_DETAIL" });
          }}
          setSelectedLocationHandler={setSelectedLocationHandler}
          researchNo={talentId}
          isUseLocation={isUseLocation}
          range={range}
          handleOpenCustomDashboardModalOpen={
            handleOpenCustomDashboardModalOpen
          }
          openApplyFile={() => {
            setApplyFileModalOpen(true);
          }}
          dataCount={state.filterCondition.numbers}
          applyFileInfo={applyFileInfo}
        />
      )}
      {/* {innerWidth < 1024 && (
          <Icon className="more-in-mobile">more_horiz</Icon>
        )} */}
      <Backdrop className="back-drop" open={state.isLoading}>
        <Spinner />
      </Backdrop>
      <Dialog
        open={state.isDialogOpen}
        TransitionComponent={Transition}
        className="dialog"
      >
        <div className="dialog-container">
          <MapListContainer
            is_check_all_custom_dashboard={isCheckAllCompanyForCustomDashboard}
            handleToggleCheckAllCompanyForCustomDashboard={
              handleToggleCheckAllCompanyForCustomDashboard
            }
            updateCustomDashboardData={updateCustomDashboardData}
            handleUpdateCustomDashboardData={handleUpdateCustomDashboardData}
            resetCustomDashboardData={resetCustomDashboardData}
            data={talents}
            left={"1vw"}
            top={"1vw"}
            width={"98vw"}
            listType={"isTalentDetail"}
            // lengthOfData={data.length}
            closeDetail={() => {
              stateDispatch({ type: "CLOSE_TALENT_DETAIL" });
            }}
            setSelectedLocationHandler={setSelectedLocationHandler}
            researchNo={talentId}
            closeDialog={() => {
              stateDispatch({ type: "TOGGLE_DIALOG_OPEN" });
            }}
            isUseLocation={isUseLocation}
            range={range}
            handleOpenCustomDashboardModalOpen={
              handleOpenCustomDashboardModalOpen
            }
            openApplyFile={() => {
              setApplyFileModalOpen(true);
            }}
            dataCount={state.filterCondition.numbers}
            applyFileInfo={applyFileInfo}
          />
        </div>
      </Dialog>
    </div>
  );
};

export default MapSearch;
