import styled, { keyframes } from "styled-components";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import RadioGroup from "@material-ui/core/RadioGroup";

export const QuestionContainerTitleContainer = styled.div`
  width: 80%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 50px;
`;

export const QuestionContainerTitle = styled.p`
  font-size: 2rem;
  font-weight: 600;
`;

export const BasicQuestionNextContainer = styled.div`
  width: 5%;
  display: flex;
  align-items: center;
  justify-content: center;

  .material-icons {
    cursor: ${(p) => (p.disabled ? "not-allowed" : "cursor")};
    transition: 0.1s;
    color: ${(p) => (p.disabled ? "#666666" : "#000")};

    &:hover {
      transition: 0.1s;
      color: #757575;
    }
  }
`;

export const BasicQuestionLeftContainer = styled.div`
  width: 25%;
  padding: 0px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  > img {
    width: 225px;
    height: 225px;
  }
`;

export const BasicQuestionRightContainer = styled.div`
  width: 90%;
  /* height: 100%; */
  margin: 30px 5% 0px 0px;
  @media (max-width: 1024px) {
    width: 100%;
    margin: 0px 0px 0px 0px;
  }
`;

export const BasicQuestionRightContainerPerRow = styled.div`
  display: flex;
  justify-content: space-around;
  position: relative;
  margin: 5px 0px;
`;

export const BasicTextFiled = styled(TextField)`
  width: 100% !important;
  margin: 0px 10px !important;
  padding: ${(p) => (p.visit ? "17px 0px" : "8px 0px")}  !important;

  .MuiInput-underline:after {
    border-bottom: 2px solid #ff746f !important;
  }

  .MuiFormLabel-root.Mui-focused {
    color: #ff746f !important;
  }

  .MuiInputBase-input {
    padding: 6px 0px 8px !important;
  }
`;

export const BasicFormControl = styled(FormControl)`
  width: 100% !important;
  margin: 0px 10px !important;
  padding: 8px 0px !important;

  &:after {
    border-bottom: 2px solid #ff746f !important;
  }

  .MuiFormLabel-root.Mui-focused {
    color: #ff746f !important;
  }
`;

export const BasicInputLabel = styled(InputLabel)`
  width: 100%;
  padding: 5px 0px;

  &:after {
    border-bottom: 2px solid #ff746f !important;
  }

  .MuiFormLabel-root.Mui-focused {
    color: #ff746f !important;
  }
`;

export const BasicSelect = styled(Select)`
  width: 100%;
  padding: 5px 0px;
  
  &:after {
    border-bottom: 2px solid #ff746f !important;
  }

  .MuiFormLabel-root.Mui-focused {
    color: #ff746f !important;
  }
`;

export const BasicSelectOption = styled(MenuItem)`
  width: 100%;
  margin: 0px 10px;
  padding: 5px 0px;
  color: ${(p) => (p.disabled ? "#757575" : "#000")};
`;

export const QuestionGridContainer = styled.div`
  display: flex;
  flex-direction: column;
  
  width: 100%;
  max-height: ${(p) =>
    p.isFourCol && "50vh"};

  @media (max-width: 750px) {
    max-height: ${(p) =>
    p.isFourCol && "80vh"};
  }
`;

export const QuestionGridView = styled.div`
  width: 100%;
  display: grid;
  flex-direction: row;
  justify-content: center;
  max-height: ${(p) =>
    p.isFourCol && p.isTwoCol && p.isThreeCol && "60vh"};

  grid-auto-rows: ${(p) =>
    p.isFourCol
      ? "15vh !important" : p.column
        ? "min-content" : "auto"};

  grid-template-columns: ${(p) =>
    p.isFourCol
      ? "1fr 1fr 1fr 1fr"
      : p.isThreeCol
        ? "1fr 1fr 1fr"
        : p.column
          ? "1fr"
          : "1fr 1fr"};

  @media (max-width: 750px) {
    grid-auto-rows: ${(p) =>
      p.isFourCol
        ? "14vh !important" : p.column
          ? "min-content" : "15vh !important"};
    max-height: ${(p) =>
    p.isFourCol && p.isThreeCol && p.isTwoCol && "60vh"};
    grid-template-columns: ${(p) => (p.column ? "1fr" : "1fr 1fr")};
  }
`;

export const QuestionGridItem = styled.div`
  display: flex;
  height: ${(p) =>
    p.isTwoCol
      ? "15vh" : "10vh"};
  margin: ${(p) =>
    p.isFourCol
      ? "20px 15px"
      : p.isThreeCol
        ? "0px 30px 30px"
        : "30px 50px"} !important;
  padding: ${(p) =>
    p.isFourCol
      ? "20px 0px"
      : p.isThreeCol
        ? "40px 0px"
        : "60px 0px"} !important;
  align-items: center;
  justify-content: center;
  background: #f4d58d;
  color: #fff;
  border-radius: 15px;
  box-shadow: 0px 0px 10px -5px rgba(0, 0, 0, 0.5);
  opacity: 0.7;
  transition: 0.2s;
  cursor: pointer;
  outline: ${(p) => (p.isActive ? "5px solid #ff746f" : "")};

  @media (max-width: 750px) {
    padding: ${(p) =>
    p.isFourCol
      ? "10px 0px"
      : p.isThreeCol
        ? "20px 0px"
        : "40px 0px"} !important;
    margin: 0px 10px 10px !important;
  }

  > p {
    font-weight: ${(p) => (p.isActive ? "600" : "500")};
  }
`;

export const QuestionGridItemText = styled.p`
  font-size: ${(p) => (p.fontSize ? `${p.fontSize}` : "1.5rem")};
  color: #280505;
  text-align: center;
  margin: ${(p) =>
    p.isFourCol
      ? "0px 25px"
      : p.isThreeCol
        ? "0px 10px"
        : "0px"};
  
  @media (max-width: 750px) {
    font-size: 1.2rem;
  }
`;

export const QuestionBasicButtonContainer = styled.div`
  width: 90%;
  height: ${(p) => (p.preview ? "5%" : "10%")};
  margin: 20px 5%;
  display: flex;
  justify-content: flex-end;
  padding-right:  ${(p) => (p.preview && "10px")};

  @media (max-width: 1024px) {
    width: 100%;
    margin: 10px 0%;
  }
`;

export const SignPadButtonContainer = styled.div`
  width: 90%;
  height: 7%;
  margin: 0px 5px 10px 0px;
  display: flex;
  justify-content: flex-end;
`;

export const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
`;


export const QuestionBasicButton = styled(Button)`
  height: 40px !important;
  background: ${(p) =>
    p.backgroundColor ? `${p.backgroundColor}` : "#ff746f"} !important;
  color: #fff !important;
  margin: ${(p) =>
    p.clearButton ? "20px 20px 0px 15px" : "10px 0px 0px 15px"} !important;
  cursor: pointer;

  &:hover {
    background: ${(p) => p.backgroundColor} !important;
  }

  @media (max-width: 1024px) {
    height: 35px !important;
  }
`;

export const DisabledQuestionBasicButton = styled(QuestionBasicButton)`
  height: 40px !important;
  background: #ececec !important;
  color: #fff !important;
  margin-top: 10px !important;
  margin-left: 15px !important;
  cursor: not-allowed;

  &:hover {
    background: ${(p) => p.backgroundColor} !important;
  }

  @media (max-width: 1024px) {
    height: 35px !important;
  }
`;

const menuSlideDown = keyframes`
  from {
    max-height: 0px;
  }

  to {
    max-height: 250px;
  }
`;

const menuSlideUp = keyframes`
  from {
    max-height: 250px;
  }

  to {
    max-height: 0px;
  }
`;

export const QuestionContainer = styled.div`
  margin: 10px;
`;

export const QuestionContainerText = styled.h2`
  color: #ff746f;
  font-weight: 600;
  font-size: 1.5rem;

  @media (max-width: 1024px) {
    font-size: 1.2rem;
  }
`;

export const PhonInterviewContainer = styled.div`
  margin: 0px 5%;
`;

export const QuestionCompanySuggestionListContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 97.5%;
  position: absolute;
  top: 53px;
  left: 10px;
  background: #ffff;
  z-index: 2000;
  max-height: ${(p) => (p.isOpen ? "250px" : "0px")};
  overflow-y: scroll;
  box-shadow: ${(p) => (p.isOpen ? "0px 0px 5px 2px #ececec;" : "none")};
  animation-name: ${(p) => (p.isOpen ? menuSlideDown : menuSlideUp)};
  animation-duration: 0.6s;
`;

export const QuestionCompanySuggestionListItem = styled.div`
  background-color: ${(p) => (p.index % 2 === 0 ? "#ececec" : "#fff")};
  display: flex;
  padding: 15px;
  cursor: pointer;
`;

export const QuestionCompanySuggestionListItemText = styled.p`
  display: ${(p) => (p.isOpen ? "block" : "none")};
  color: ${(p) => (p.noData ? "#ececec" : "#000")};
`;

export const SignaturePadModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 15px;
  border-radius: 15px;
  justify-content: center;
  overflow-y: scroll;
  overflow-x: hidden;
  width: ${(p) => p.width || "80%"};
  height: ${(p) => p.height || "auto"};
  margin: ${(p) => p.margin || "30px 10%"};

  @media (max-width: 1024px) {
    width: 90%;
    padding-right: 0px;
    margin: 30px 5%;
  }
`;

export const SignaturePadModalContainerHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
  .material-icons {
    cursor: pointer;
    transition: 0.1s;

    &:hover {
      transition: 0.1s;
      color: #757575;
    }
  }
`;

export const SignaturePadContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 90%;
  max-height: 75vh;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: ${(p) => p.color || "#fff"};
  border-radius: ${(p) => p.borderRadius || "0px"};

  @media (max-width: 750px) {
    margin: "0px 10px";
  }
`;

export const SignaturePadItem = styled.div`
  display: flex;
  height: ${(p) => p.height || "20%"};
  align-items: center;
  justify-content: ${(p) => p.justifyContent || "center"};
  flex-direction: ${(p) => p.flexDirection || "row"};

  > img {
    padding: 2%;
    background-color: white;
    width: 30vh;
    height: 30vh;
    border-radius: 10px;
  }

  > p {
    color: #888888;
    font-size: 0.875rem;
    margin: 20px 0px 10px 0px;
  }
`;

export const SignaturePadInnerContainer = styled.div`
  width: 100%;
  margin: 20px 0px 20px;
  display: flex;
  flex-direction: column;
`;

export const SignatureFeedbackContainer = styled.div`
  margin-left: 20px;

  @media (max-width: 750px) {
    margin: 0px 10px;
  }
`;

export const SignaturePadContainerTitle = styled.p`
  font-size: 1.15rem;
  font-weight: 600;
`;

export const SignatureFeedback = styled(TextField)`
  font-size: 1.15rem;
  font-weight: 600;
  width: 100%;
  margin-top: 20px !important;

  @media (max-width: 750px) {
    width: 90%;
  }
`;

export const SignaturePadInnerText = styled.p`
  font-size: 1.15rem;
  font-weight: 600;
  margin: 0px 20px;

  @media (max-width: 750px) {
    margin: 0px 10px;
  }
`;

export const SignaturePadInnerPad = styled.div`
  align-items: start;
  display: flex;
  flex-direction: row;
  margin: 0px 10px;

  @media (max-width: 750px) {
    flex-direction: column;
  }
`;

export const SignaturePadWrapper = styled.div`
  margin: 20px 0px 0px;
  border: 3px solid #ececec;
`;

export const EachQuestionContainer = styled.div`
  padding: 20px 0px;
`;

export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: 425px) {
    flex-direction: column;
  }
`;

export const EachQuestionContainerTitle = styled.p`
  font-size: 1.15rem;
  font-weight: 600;
  padding: 0px 0px 10px 0px;
`;

export const EachQuestionList = styled.div`
  margin: 0px 10px 15px;
`;

export const EachQuestionListItem = styled.div`
  display: flex;
  flex-wrap: wrap;
  >ul li {
    padding: 10px 5px;
    word-break : break-all;
    word-wrap: break-word;
    line-height: 30px;

    @media (max-width: 425px) {
      font-size: 1rem;
    }
  }
`;

export const EachQuestionContentContainer = styled.div`
  border-radius: 2px;
`;

export const ControlItems = styled.div`
  display: flex;
  flex-direction: row;
  margin: 2% 0% 0%;

  @media (max-width: 1024px) {
    flex-direction: column;
    margin: 5px 0px;
  }
`;

export const ControlRadioGroup = styled(RadioGroup)`
  margin-left: 30px;

`;

export const ControlTextArea = styled.div`
  display: flex;
  flex-direction: row;

  > p {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 3;
    margin-right: 5px;
  }
`;

export const ControlTextFiled = styled(TextField)`
  margin-left: 10px;
  width: 400px;
  
  @media (max-width: 1024px) {
    flex-direction: column;
    width: 70%;
  }
`;

export const NoData = styled.p`
  color: #757575;
  padding: 10px;
`;

// Preview Page

export const PreviewContainer = styled.div`
  max-height: 90vh;
  background-color: #f7f3f3;
  padding: 20px 5%;
  margin-top: 40px;
  overflow-y: scroll;
`;

export const PreviewSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;

  @media (max-width: 1024px) {
    padding: 5px;
  }
`;

export const PreviewSection = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;

  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

export const PreviewSectionImgContainer = styled.img`
  width: 200px;
  height: 200px;
  margin-right: 50px;
  border: 1px solid #000;
`;

export const PreviewCard = styled.div`
  max-height: 75vh;
  overflow-x: hidden;
  overflow-y: scroll;

  @media (max-width: 750px) {
    padding: 0px;
    padding-right: 10px;
  }
`;

export const PreviewSectionTitleContainer = styled.div`
  padding: ${(p) => (p.shouldPadding ? "20px 20px 0px 20px" : "0px 20px")};

  @media (max-width: 1024px) {
    padding: ${(p) => (p.shouldPadding ? "20px 20px 0px 5px" : "0px 20px")};
  }
`;

export const PreviewSectionTitle = styled.p`
  font-size: 2rem;
  font-weight: 600;
  text-align: center;
  margin: 20px 0px 50px;

  @media (max-width: 750px) {
    margin: 20px 0px;
  }
`;

export const PreviewSectionSubTitle = styled.p`
  font-size: 1.2rem;
  font-weight: 600;
`;

export const PreviewPerRow = styled.div`
  display: flex;
  width: ${(p) => p.width || "100%"};
  justify-content: flex-start;
  border: 1px solid #000;
  background-color: white;
  border-radius: 10px;
  margin: 5px 10px;

  @media (max-width: 1024px) {
    margin: 5px 0px;
  }

  @media (max-width: 750px) {
    flex-direction: column;
  }
`;

export const PreviewEachTitle = styled.span`
  font-size: 1.1rem;
  font-weight: 600;
  padding: 15px;
  background-color: #ffbaba;
  text-align: center;
  justify-content: center;
  line-height: 40px;

  @media (min-width: 1024px) {
    min-width: 180px;
    border-right: 1px solid #000;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  @media (max-width: 1024px) {
    width: 20%;
    border-right: 1px solid #000;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  @media (max-width: 750px) {
    width: 100%;
    border-right: 0px;
    border-bottom: 1px solid #000;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 0px;
  }
`;

export const PreviewEachAnswer = styled.span`
  font-size: 1.15rem;
  display: ${(p) => p.center && "flex"};
  width: 100%;
  justify-content: ${(p) => p.center && "center"};

  > p {
    padding: 10px;
    line-height: 30px;
    display:block;
    float:left;
    white-space:normal;
    word-break : break-all;
    word-wrap: break-word;
  }

  > img {
    height: 250px;
    object-fit: contain;
    max-width: 100%;
  }
`;

export const Preview = styled.div`
  border-radius: 10px;
  margin: 20px 0px;
  background-color: #f0eaea;
`;

export const QuestionCard = styled.div`
  display: flex;
  margin: 25px 5%;
  background: #fff;
  min-height: 80vh;
  box-shadow: 0px 0px 15px -5px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  flex-direction: column;

  @media (max-width: 1024px) {
    margin: 20px 5%;
  padding-bottom: 20px;

  }
`;

export const QuestionForm = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 2% 2% 0%;
  justify-content: flex-end;

  > label {
    font-size: 90%;
    font-weight: 400;
    margin-bottom: 15px;
    color: #645959;
    min-width: 20%;
  }

  > input {
    font-size: 90%;
    height: 55px;
    border-radius: 5px;
    padding: 1%;
    border: 1px solid #eee;
    background-color: #eee;
    width: 98%;
  }
`;

export const SearchContainer = styled.div`
  display: flex;
  flexdirection: row;

  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 20%;
  justify-content: flex-end;

  @media (max-width: 1024px) {
    width: 100%;
    justify-content: space-between;
  }
`;

export const SearchBotton = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin: 0% 5%;

  @media (max-width: 1024px) {
    width: 100%;
    margin-top: 8%;
    margin-left: 0%;
    margin-right: 0%;
  }
`;

export const QuestionRecordCardTop = styled.div`
  display: flex;
  flex-direction: row;
  width: 90%;
  margin: 0% 5%;
  height: 20%;

  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 0%;
  }
`;

export const QuestionRecordCardTopLeft = styled.div`
  display: flex;
  flex-direction: column;
  width: 20%;
  justify-content: space-evenly;
  align-items: center;

  @media (max-width: 1024px) {
    width: 80%;
    margin: 5% 0% 3% 0%;
  }

  > img {
    width: 65%;
    height: 60%;

    @media (max-width: 1024px) {
      width: 30%;
      height: 30%;
      margin-top: 5%;
    }

    @media (max-width: 750px) {
      width: 50%;
      height: 50%;
      margin-top: 8%;
    }
  }
`;

export const QuestionRecordCardTopRight = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;
  margin-top: 2%;

  @media (max-width: 1024px) {
    width: 85%;
  }
`;

export const QuestionRecordCardBottom = styled.div`
  width: 95%;
  margin: 0% 2.5%;
  height: 80%;

  > h6 {
    text-align: end;
    margin: 2% 0%;
    color: grey;

    @media (max-width: 750px) {
      font-size: 80%;
    }
  }
`;

export const QuestionResultContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: 30px 5%;
`;
