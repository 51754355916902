import React from "react";
import styled from "styled-components";
import { Box, Icon } from "@material-ui/core";
import Swal from "sweetalert2";

const openReferenceInfoAlert = async (dataFrom) => {
  Swal.fire({
    title: "引用資料來源",
    html: dataFrom? dataFrom : "資料正在建置中 ... ",
  });
};

const DataInfoModal = ({ dataFrom }) => {
  return <Box mt={"auto"} display="flex" justifyContent="flex-end">
  <InfoBox display="flex" alignItems="center" onClick={() => openReferenceInfoAlert(dataFrom)}>
    <Icon className="icon"> info </Icon>
    <p> 資料說明 </p>
  </InfoBox>
</Box>
}

const InfoBox = styled(Box)`
  cursor: pointer;
  &:hover {
    opacity: 0.75;
  }
`;
export default DataInfoModal;