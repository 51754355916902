import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";

import "./styles.scss";

const FactoryImportExportRating = () => {
  const rating = [
    { A: "A", B: "10百萬美元(含以上)", C: "G", D: "4-5百萬美元" },
    { A: "B", B: "9-10百萬美元", C: "H", D: "3-4百萬美元" },
    { A: "C", B: "8-9百萬美元", C: "I", D: "2-3百萬美元" },
    { A: "D", B: "7-8百萬美元", C: "J", D: "1-2百萬美元" },
    { A: "E", B: "6-7百萬美元", C: "K", D: "0.5-1百萬美元" },
    { A: "F", B: "5-6百萬美元", C: "L", D: "0-0.5百萬美元" },
  ];
  return (
    <TableContainer className="table-container">
      <Table className="table">
        <TableBody
          className="table-body"
          style={{ backgroundColor: "#ececec" }}
        >
          <TableRow className="table-body-row">
            <TableCell align="left" nowrap="nowrap" style={{ fontWeight: 600 }}>
              備註
            </TableCell>
            <TableCell align="left" style={{ color: "#ececec" }}>
              備註
            </TableCell>
            <TableCell align="left" style={{ color: "#ececec" }}>
              備註
            </TableCell>
            <TableCell align="left" style={{ color: "#ececec" }}>
              備註
            </TableCell>
          </TableRow>
        </TableBody>

        <TableBody className="table-body">
          {rating.map((row, index) => (
            <TableRow
              key={row.A}
              className="table-body-row"
              style={index % 2 !== 0 ? { backgroundColor: "#ececec" } : {}}
            >
              <TableCell align="left">{row.A}</TableCell>
              <TableCell align="left">{row.B}</TableCell>
              <TableCell align="left">{row.C}</TableCell>
              <TableCell align="left">{row.D}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default React.memo(FactoryImportExportRating);
