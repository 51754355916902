import React, { useEffect } from 'react';
import styled from "styled-components";
import Box from "@material-ui/core/Box";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Breadcrumb from "../../components/Breadcrumbs";
import data from "./investData.json"
import investmentBg from "../../assests/images/investment-bg.png";
import "./style.scss";

const Investment = () => {
  const [investData, setInvestData] = React.useState({
    target: "prev",
  });
  const handleInvestData = (target) => {
    setInvestData({
      target,
    })
    // scroll into view
    const wrapper = document.getElementById("investment");
    const element = document.getElementById(target);
    const y = element.offsetTop - 105;
    wrapper.scrollTo({
      top: y,
      behavior: "smooth"
    });
  }
  // 當滑到對應區塊時 active 
  useEffect(() => {
    const wrapper = document.getElementById("investment");
    const medium = document.getElementById("medium");
    const finallyEl = document.getElementById("finally");
    let scrollCallBack;
    scrollCallBack = wrapper.addEventListener("scroll", () => {
      if (wrapper.scrollTop < medium.offsetTop - 105) {
        setInvestData({
          target: "prev",
        })
      } else if (wrapper.scrollTop < finallyEl.offsetTop - 105) {
        setInvestData({
          target: "medium",
        })
      } else {
        setInvestData({
          target: "finally",
        })
      }
    });
    return () => {
      window.removeEventListener("scroll", scrollCallBack);
    };
  }, [])
  // sticky sidebar
  useEffect(() => {
    const wrapper = document.getElementById("investment");
    const sidebar = document.getElementById("investment-sidebar");
    const investmentContent = document.getElementById("investment-content");
    const sticky = sidebar.offsetTop;
    let scrollCallBack;
    if (window.innerWidth > 959) {
      scrollCallBack = wrapper.addEventListener("scroll", () => {
        if (wrapper.scrollTop > sticky) {
          sidebar.classList.add("sticky");
          investmentContent.style.paddingLeft = `${sidebar.offsetWidth + 40}px`;
        } else {
          sidebar.classList.remove("sticky");
          investmentContent.style.paddingLeft = `0px`;
        }
      });
    }
    return () => {
      window.removeEventListener("scroll", scrollCallBack);
    };
  }, [])
  return <div className="investment" id="investment">
    <div class="top-breadcrumb">
      <Breadcrumb route={[{
        name: "入區投資",
      }]} />
    </div>
    <div className="investment-bg" style={{
      backgroundImage: `url(${investmentBg})`
    }}>
      <div className="invest-container">
        <Box display="flex">
          <Box display="flex" flexDirection={{
            xs: "row",
            md: "column",
          }} alignItems="center" mr={{
            xs: 3,
            md: 5,
          }} id="investment-sidebar">
            <button className={
              investData.target === "prev" ? "investment-sidebar-item active" : "investment-sidebar-item"
            } onClick={() => { handleInvestData("prev") }}>
              事前<br />準備
            </button>
            <Box mb={1}>
              <KeyboardArrowDownIcon />
            </Box>
            <button className={
              investData.target === "medium" ? "investment-sidebar-item active" : "investment-sidebar-item"
            } onClick={() => { handleInvestData("medium") }}>
              入區<br />投資
            </button>
            <Box mb={1}>
              <KeyboardArrowDownIcon />
            </Box>
            <button className={
              investData.target === "finally" ? "investment-sidebar-item active" : "investment-sidebar-item"
            } onClick={() => { handleInvestData("finally") }}>
              申請<br />完成
            </button>
          </Box>
          <div id="investment-content" className="investment-content">
            {
              [{
                target: "prev",
                data: data.prev,
                title: "事前準備",
              }, {
                target: "medium",
                data: data.medium,
                title: "入區投資",
              }, {
                target: "finally",
                data: data.finally,
                title: "申請完成",
              }].map(item => {
                return <InvestmentBox className="investment-box" id={item.target} active={investData.target === item.target}>
                  <h2>
                    {item.title}
                  </h2>
                  {
                    [...item.data].map((q, index) => {
                      return <div className="investment-card" key={index}>
                        <div className="investment-card-title">
                          <span>{q.question}</span>
                        </div>
                        <div className="investment-card-body">
                          <p dangerouslySetInnerHTML={{ __html: q.answer }} />
                        </div>
                      </div>
                    })
                  }
                </InvestmentBox>
              })
            }
          </div>
        </Box>
      </div>
    </div>
  </div>
}

const InvestmentBox = styled(Box)`
  h2 {
    font-size: 1.25rem;
    font-weight: 700;
    margin-bottom: 16px;
    color: ${props => props.active ? "#25758A" : "#3E5A75"};
    border-bottom: 1px solid ${props => props.active ? "#25758A" : "#3E5A75"};
  }
  .investment-card {
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 16px;
    box-shadow: 0px 2px 1px -1px #00000033;
  }
  .investment-card-title {
    background-color: ${props => props.active ? "#25758A" : "#3E5A75"};
    padding: 18px 24px;
    color: white;
    span {
      font-size: 1.125rem;
      font-weight: 700;
      border-bottom: 1px solid white;
    }
  }
  .investment-card-body {
    background-color: white;
    padding: 18px 24px;
    color: #11304A;
  }
`;
export default Investment;