import React from "react";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import CircularProgress from "@material-ui/core/CircularProgress";
import Slide from "@material-ui/core/Slide";
import BackDrop from "@material-ui/core/Backdrop";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { useSelector, useDispatch } from "react-redux";
import Swal from "sweetalert2";

import SupplychainContent from "../../components/SupplychainContent/SupplychainContent";
import SupplyChainInfo from "../../components/SupplyChainInfoBox/SupplyChainInfoBox";
import Spinner from "../../components/Spinner/Spinner";
import Map from "../../components/Maps/Maps";
import { getMapDataHandler } from "../../store/actions/supplychain";

import "./styles.scss";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});


export default (props) => {
  const dispatch = useDispatch();
  const { mapData, supplyChainOptions, supplyChainData, getOptionLoading, searchMapLoading } = useSelector(state=> state.supplychain);
  const { referenceInfo } = useSelector((state) => state.app);
  const [state, setState] = React.useState({
    industry: "all",
    id: 1,
    stream: "全部",
    streamList:"",
    tagList: [],
    productList: [],
    codeList: [],
    selectedItems: [],
    listData: [],
    value: 0,
    isStreamOpen: false,
    isGetDataLoading: false,
    isStartSearch: false,
    isSupplyChainInfoOpen: false,
    isSupplyContentDialogOpen: false,
    isInfoOpen: true
  }, );

  //判斷有沒有上中下游
  React.useEffect(()=>{
    const { listData, tagList, products } = state;
    if(listData.length > 0 && listData[0].categoryFour.length > 0){
        for(let i=0; i <= listData[0].categoryFour.length; i++){
            if(listData[0].categoryFour[i].stream) { setState(state=>({ ...state, isStreamOpen: true })) }
            break;
        }
    }
 }, [state.listData]);

//  React.useEffect(()=>{
//   const { tagList, productList, industry } = state;
//   const updateSelectedList = [];
//   updateSelectedList.push(industry);
//   tagList.map(tag => {
//     updateSelectedList.push(tag);
//   });
//   productList.map(prod => {
//     updateSelectedList.push(prod);
//   });


//   setState(state=>({ ...state, selectedItems: updateSelectedList }))

//  }, [state]);

//  React.useEffect(()=>{
//   const { industry } = state;
//   if(industry){
//     const updateListItem = state.selectedItems;
//     updateListItem.push(industry)
//     setState(state=>({ ...state, selectedItems: updateListItem }))
//   }
//  },[state.industry]);

 React.useEffect(()=>{
  const updateSelectedList = [];
  supplyChainOptions.map(option=>{

    const updateCategoryFour = [];
    supplyChainData.map(data=>{
      

      if(option === data.Industry){
        updateCategoryFour.push({
          stream: data.Stream,
          industry: data.industry,
          tag: data.Tag,
          products: data.Product,
        })
      }
    })

    updateSelectedList.push({
      industry: option,
      categoryFour: updateCategoryFour
    })
  })

  setState(state=>({
    ...state,
    rawOptionList: updateSelectedList,
    listData : [],
}))
 }, [supplyChainData, supplyChainOptions]);

  const handleUpdateField = (e) => {
    const { value, name } = e.target;
    const updateListData = state.rawOptionList.filter(option => option.industry === value)
    setState((state) => ({ 
      ...state, 
      [name]: value, 
      industryType: value, 
      listData: updateListData,
      tagList: [],
      productList: [],
      codeList: [],
      selectedItems: [value],
    }));

    const form = new FormData();
    form.append('Id', 1);
    form.append('Industry', value);
    form.append('Stream', "全部");

    startSearchHandler(form)
  };

  const updateSelectedItemsHandler = (list) => {    
    // const updateSelectedItem = state.selectedItems;
    // if(updateSelectedItem.length === 1){
    //   updateSelectedItem.push(list);
    // }else{
    //   updateSelectedItem.pop();
    //   updateSelectedItem.push(list);
    // }

    // setState(state=>({ ...state, selectedItems: updateSelectedItem }))
    // startSearchHandler()
  };

  const startSearchHandler = (form) => {
    dispatch(getMapDataHandler(form));
    setState(state=>({ ...state, isStartSearch: true, isSupplyChainInfoOpen: true }))
  };

  const updateStreamHandler = (value) => {

    setState(state=>({ ...state, stream: value}))
  }

  const initSearchHandler = () => {
    const form = new FormData();
    form.append('Id', 1);
    form.append('Industry', state.industry);
    form.append('Stream', "全部");

    startSearchHandler(form)
    setState(state=>({ ...state, tagList: [], productList:[], streamList: "", selectedItems: [state.industry]}))

  }


  const updateStreamParmsHandler = (value) => {
    const updateSelectedItems = [];
    let updateStream;
    if(value === state.streamList || !value){
      updateStream = "全部"
    }else{
      updateStream = value
    }
    updateSelectedItems.push(state.industry);
    updateSelectedItems.push(value);
    setState(state=>({ ...state, tagList: [], productList:[], stream: updateStream,streamList: updateStream, selectedItems: updateSelectedItems}))
    
    const form = new FormData();
    form.append('Id', 1);
    form.append('Industry', state.industry);
    form.append('Stream', value);

    startSearchHandler(form)
  }

  const updateTagHandler = (value) => {
    const { id, streamList, industry, tagList, productList } = state;

    // if(state.tagList.filter(i => i === value).length > 0){
    //   return
    // }else{
    //   const updateTagList = state.tagList;
    //   updateTagList.push(value)
    //   setState(state=>({ ...state, tagList: updateTagList }))
    // }
    let selectedStream;

    for(let i=0; i <= (supplyChainData.length-1); i++){
      try{
        if(supplyChainData[i].Tag === value){
          selectedStream = supplyChainData[i].Stream; 
          break;
        }
      }catch(err){
        console.log(err)
      }
    } 

    const updateTagList = state.tagList;
    if(updateTagList.length === 0){
      updateTagList.push(value);
    }else{
      updateTagList.pop();
      updateTagList.push(value);
    };

    const updateSelectedItems = [];
    updateSelectedItems.push(state.industry);

    state.tagList.map(tag=> updateSelectedItems.push(tag) );

    setState(state=>({ ...state, tagList: updateTagList, productList: [], selectedItems: updateSelectedItems, streamList:"", stream: selectedStream, value: 1 }))
    const form = new FormData();
    if(id) form.append('Id', 2);
    if(industry) form.append('Industry', industry);
    if(tagList) form.append('TagList', tagList);

    startSearchHandler(form)
  }

  const updateProducthandler = (value) => {
    const { id, industry, tagList, productList } = state;

    // if(state.productList.filter(i => i === value).length > 0){
    //   return
    // }else{
    //   const updateProductList = state.productList;
    //   updateProductList.push(value)
    //   setState(state=>({ ...state, productList: updateProductList }))
    // }
    const updateProductList = state.productList;
    if(updateProductList.length === 0){
      updateProductList.push(value);
    }else{
      updateProductList.pop();
      updateProductList.push(value);
    }

    let selectedTag;
    let selectedStream;

    for(let i=0; i <= (supplyChainData.length-1); i++){
      try{
        for(let j=0; j <= (supplyChainData[i].Product.split(",").length -1); j++){
          if(supplyChainData[i].Product.split(",")[j] === value && supplyChainData[i].Industry === industry){
            selectedStream = supplyChainData[i].Stream; 
            selectedTag = supplyChainData[i].Tag;
            break;
          }
        }
          supplyChainData[i].Product.split(",")
      }catch(err){
        console.log(err)
      }
    }

    const updateSelectedItems = [];
    updateSelectedItems.push(state.industry);
    updateSelectedItems.push(selectedTag);

    state.productList.map(prod=> updateSelectedItems.push(prod) );
    

    setState(state=>({ ...state, tagList : [selectedTag] ,productList: updateProductList, selectedItems: updateSelectedItems, streamList:"", stream: selectedStream, value: 1  }))
    const form = new FormData();
    if(id) form.append('Id', 3);
    if(industry) form.append('Industry', industry);
    if(tagList) form.append('TagList', selectedTag);
    if(productList) form.append('ProductList', productList);

    startSearchHandler(form)
  }

  const handleDeleteProductOrTagList = (item) => {
    const { tagList, productList } = state;
    if(tagList.filter(i => i === item).length > 0){
      const updateTagList = [];
      tagList.map( i =>{
        if( i !== item){
          updateTagList.push(i)
        }
        setState(state=>({ ...state, tagList: updateTagList }))
      })
    }

    if(productList.filter(i => i === item).length > 0){
      const updateProductList = [];
      productList.map( i =>{
        if( i !== item){
          updateProductList.push(i)
        }
        setState(state=>({ ...state, productList: updateProductList }))
      })
    }
  }

  const toggleSupplyChainContentDialog = () => {
    setState(state => ({ ...state, isSupplyContentDialogOpen: !state.isSupplyContentDialogOpen }))
  }

  const onCloseInfo = () => {
    setState(state=> ({ ...state, isInfoOpen: false }))
  }

  const toggleInfoContent = () => {
    setState(state=> ({ ...state, isInfoOpen: !state.isInfoOpen }))
  }

  const clearAllHandler = () => {
    setState(state => ({ ...state, 
    streamList:"",
    tagList: [],
    productList: [],
    selectedItems: [ state.industry ]
   }))
  }

  const handleChange = (e, value) => {
    setState(state => ({ ...state, value: value }))
  } 

  const openExplainationDialog = () => {
    Swal.fire({
      text: referenceInfo.supplyChainManagementDescrip,
    })
  }

  return (
    <div className="supply-chain">
      <div className="supply-chain-search-bar">
        <TextField
          className="supply-chain-search-bar-input"
          size="small"
          select
          InputProps={ 
            {
              startAdornment: window.innerWidth < 1023 && ( getOptionLoading ?  (
                <InputAdornment position="start">
                  <CircularProgress className="loading"/>
                </InputAdornment>
              ) : (
                <InputAdornment position="start">
                  <Icon 
                    onClick={state.industryType ? toggleSupplyChainContentDialog: ()=> { Swal.fire({text: "請先選擇欲查詢產業"})}}
                    style={state.industryType ? {} : {color: "#ececec"} }
                  >menu</Icon>
                </InputAdornment>
              )),
            }
          }
          value={state.industry}
          onChange={(e) => handleUpdateField(e)}
          name="industry"
        >
           <MenuItem key={"all"} value="all">請選擇產業</MenuItem>
          {supplyChainOptions.map((option, index) => (
            <MenuItem key={index} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>
        <div className="description">
        <p onClick={openExplainationDialog}>
          說明
        </p>
        <Icon className="icon">help</Icon>
      </div>
      </div>
      { state.industryType && window.innerWidth > 1023 &&        
      <SupplychainContent 
          isGetDataLoading={state.isGetDataLoading}
          selectedItems={state.selectedItems} 
          isStreamOpen={state.isStreamOpen}
          industryType={state.industryType}
          codeList={state.codeList}
          productList={state.productList}
          listData={state.listData}
          updateSelectedItemsHandler={updateSelectedItemsHandler}
          startSearchHandler={startSearchHandler} 
          updateStreamHandler={updateStreamHandler}
          updateTagHandler={updateTagHandler}
          updateProducthandler={updateProducthandler}
          handleDeleteProductOrTagList={handleDeleteProductOrTagList}
          updateStreamParmsHandler={updateStreamParmsHandler}
          stream={state.streamList}
          streamValue={state.stream}
          clearAllHandler={clearAllHandler}
          tagList={state.tagList}
          productList={state.productList}
          initSearchHandler={initSearchHandler}
          toggleInfoContent={toggleInfoContent}
          isInfoOpen={state.isInfoOpen}
          openExplainationDialog={openExplainationDialog}
      />
      }
      {(state.isStartSearch && !searchMapLoading) && state.isInfoOpen && window.innerWidth > 1023 &&
        <SupplyChainInfo 
          onCloseInfo={onCloseInfo}
        />    
      }
      {<BackDrop open={searchMapLoading} className="supplychain-backdrop">
        <Spinner />
      </BackDrop>}
      <Map data={mapData} mapType={"supplyChain"}/>
      {window.innerWidth < 1023 &&
      <div className={!state.isSupplyContentDialogOpen ? "supply-chain-content-dialog close-dialog" : "supply-chain-content-dialog"}>
          <div className="supply-chain-close-dialog">
            <div style={{opacity: "0"}}>blank</div>
            <div>
              <Tabs
                className="supply-chain-mobile-tabs"
                value={state.value}
                onChange={(e, newValue) => handleChange(e, newValue)}
              >
                  <Tab label="總覽" />
                  <Tab label="廠商列表" />
              </Tabs>
            </div>
            <div><Icon onClick={toggleSupplyChainContentDialog}>close</Icon></div>
          </div>
          {state.value === 0 &&
          <SupplychainContent 
            isGetDataLoading={state.isGetDataLoading}
            selectedItems={state.selectedItems} 
            isStreamOpen={state.isStreamOpen}
            industryType={state.industryType}
            codeList={state.codeList}
            productList={state.productList}
            listData={state.listData}
            updateSelectedItemsHandler={updateSelectedItemsHandler}
            startSearchHandler={startSearchHandler} 
            updateStreamHandler={updateStreamHandler}
            updateTagHandler={updateTagHandler}
            updateProducthandler={updateProducthandler}
            handleDeleteProductOrTagList={handleDeleteProductOrTagList}
            updateStreamParmsHandler={updateStreamParmsHandler}
            stream={state.streamList}
            streamValue={state.stream}
            clearAllHandler={clearAllHandler}
            tagList={state.tagList}
            productList={state.productList}
            initSearchHandler={initSearchHandler}
            toggleInfoContent={toggleInfoContent}
            isInfoOpen={state.isInfoOpen}
            openExplainationDialog={openExplainationDialog}
          />
          }
          {state.value === 1 && 
          <SupplyChainInfo 
            onCloseInfo={onCloseInfo}
          />    
           }
      </div>
      }
    </div>
  );
};
