import React from "react";
import Box from "@material-ui/core/Box";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import Tooltip from "@material-ui/core/Tooltip";
import google_patents_logo from "../../../assets/images/google_patents_logo.png";
import {
  paginationListHandler,
  thousandDivider,
  parseUrl,
} from "../../../utility/common";

import "./styles.scss";

const FactoryPatentInfo = (props) => {
  const { data, headerList } = props;
  const [state, setState] = React.useState({
    endIndex: 0,
    page: 0,
    rowsPerPage: 5,
  });

  let content;

  React.useEffect(() => {
    if (Math.ceil(data.length) === state.page) {
      if (data.length % 5 === 0) {
        setState((state) => ({
          ...state,
          endIndex: state.page * state.rowsPerPage + state.rowsPerPage,
        }));
      } else {
        setState((state) => ({
          ...state,
          endIndex: state.page * state.rowsPerPage + (data.length % 5),
        }));
      }
    } else {
      setState((state) => ({
        ...state,
        endIndex: state.page * state.rowsPerPage + state.rowsPerPage,
      }));
    }
  }, [data.length, state.page]);

  if (data.length > 5) {
    content = data
      .slice(state.page * 5, state.page * 5 + 5)
      .map((row, index) => (
        <TableRow
          key={row.PatentNo + index}
          hover
          className="table-body-row"
          style={index % 2 !== 0 ? { backgroundColor: "#ececec" } : {}}
        >
          <TableCell align="left">{row.PatentNo}</TableCell>
          <TableCell align="left">
            <Box style={{ display: "flex", alignItems: "center"}}>
              {row.ChineseName}
              <Tooltip title="點擊觀看專利資訊詳細頁面">
                <a href={parseUrl(row.PatentNo)} target="_blank" rel="noopener noreferrer" className="googlePatentsLogo">
                  <div style={{ width: "80px", padding: "4px" }}>
                    <img alt="專利資料" src={google_patents_logo} style={{ maxWidth: "100%"}} />
                  </div>
                </a>
              </Tooltip>
            </Box>
          </TableCell>
          <TableCell align="left">{row.Publication}</TableCell>
          <TableCell align="left">{row.Date}</TableCell>
        </TableRow>
      ));
  } else {
    content = data.map((row, index) => (
      <TableRow
        key={row.PatentNo + index}
        hover
        className="table-body-row"
        style={index % 2 !== 0 ? { backgroundColor: "#ececec" } : {}}
      >
        <TableCell align="left">{row.PatentNo}</TableCell>
        <TableCell align="left">
          <Box style={{ display: "flex", alignItems: "center"}}>
            {row.ChineseName}
            <Tooltip title="點擊觀看專利資訊詳細頁面">
              <a href={parseUrl(row.PatentNo)} target="_blank" rel="noopener noreferrer" className="googlePatentsLogo">
                <div style={{ width: "80px", padding: "4px" }}>
                  <img alt="專利資料" src={google_patents_logo} style={{ maxWidth: "100%"}} />
                </div>
              </a>
            </Tooltip>
          </Box>
        </TableCell>
        <TableCell align="left">{row.Publication}</TableCell>
        <TableCell align="left">{row.Date}</TableCell>
      </TableRow>
    ));
  }

  const handleChangePage = (event, newPage) => {
    setState((state) => ({ ...state, page: newPage }));
  };

  const openNewTabs = (link) => window.open(link, "_blank");

  return (
    <TableContainer className="table-container">
      <Table className="table">
        <TableHead className="table-header">
          {headerList && (
            <TableRow className="table-row">
              {headerList.map((headerItem) => (
                <TableCell
                  key={headerItem}
                  style={{
                    fontWeight: 600,
                  }}
                  className="table-cell"
                  align="left"
                >
                  {headerItem}
                </TableCell>
              ))}
            </TableRow>
          )}
        </TableHead>
        <TableBody className="table-body">
          {data.length !== 0 && content}
        </TableBody>
      </Table>
      {data.length === 0 && (
        <Table>
          <TableBody className="table-body-no-data">
            <TableRow hover className="table-body-row">
              <TableCell align="center" className="no-data">
                本項目無資料
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      )}
      {data.length > 5 && (
        <TablePagination
          rowsPerPageOptions={[]}
          component="div"
          count={data.length}
          rowsPerPage={state.rowsPerPage}
          page={state.page}
          onChangePage={handleChangePage}
          // onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      )}
    </TableContainer>
  );
};

export default React.memo(FactoryPatentInfo);
