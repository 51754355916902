import React from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import IconButton from "@mui/material/IconButton";
import Close from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import TaxSelect from "../CustomIndustryDashboard/TaxSelect";
import {
  handleGetDashboardCond,
  handleGetALLFactoryIndustry
} from "../../store/actions/custom_industry_dashboard";

import * as theme from "../../assets/theme";

const SetTargetIndustryModal = (props) => {
  const { IndustryCode, handleModalChange, handle_close_modal, handleOpenGoodsModal, select_option_data } = props;

  const dispatch = useDispatch();
  const { getALLFactoryIndustry, getALLFactoryIndustry_loading } = useSelector(
    (state) => state.custom_industry_dashboard
  );

  React.useEffect(() => {
    dispatch(handleGetDashboardCond());
    dispatch(handleGetALLFactoryIndustry());
  }, []);

  return (
    <ModalWrapper>
      <ModalCloseContainer>
        <IconButton onClick={handle_close_modal}>
          <Close />
        </IconButton>
      </ModalCloseContainer>
      <ModalHeadContainer>
        <ModalHeadText>
          設定目標產業
        </ModalHeadText>
      </ModalHeadContainer>
      <ModalSubHeadContainer>
        <ModalHeadSubText>
          [ 輸入產業名稱關鍵字，平台將輔助您選擇目標產業；亦可選擇熱門產業，幫助您快速網羅該產業的產業細項 ]
        </ModalHeadSubText>
      </ModalSubHeadContainer>
      <hr />
      {/* <ModalInputContainer>
        <div style={{
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'wrap',
          margin: '0px 10px 10px'
        }}>
          <LocalFireDepartmentTwoToneIcon style={{ color: 'red' }} />
          <span>熱門產業：</span>
        </div>
      </ModalInputContainer> */}
      <SelectContainer>
        <TaxSelect
          label={"搜尋目標產業"}
          type={"IndustryCode"}
          dataList={getALLFactoryIndustry}
          loading={getALLFactoryIndustry_loading}
          handleChange={handleModalChange}
          selectedGoods={IndustryCode}
          defaultOption={select_option_data?.IndustryStr}
        />
      </SelectContainer>
      <AddButtonContainer>
        <StyledButton
          onClick={handleOpenGoodsModal}
        >
          下一步
        </StyledButton>
      </AddButtonContainer>
    </ModalWrapper>
  );
};

const ModalWrapper = styled.div`
    width: 50%;
    // height: 66%;
    background-color: ${theme.white};
    border-radius: 20px;
    padding: 20px 30px;
    @media (max-width: 750px) {
      width: 100%;
    }
    
    @media screen and (max-width: 1280px),
            screen and (max-height: 675px)
    {
      width: 78%;
      height: fit-content;
    }
  `;

const ModalCloseContainer = styled.div`
    width: calc(100%-60px);
    display: flex;
    justify-content: flex-end;
    align-items: center;
  `;

const ModalHeadContainer = styled.div`
    width: calc(100%-60px);
    display: flex;
    justify-content: flex-start;
    align-items: center;
  `;

const ModalHeadText = styled.h2`
    font-size: 1.5rem;
    font-weight: 600;
    letter-spacing: 2px;
    transform: translateY(-20px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    @media (max-width: 750px) {
      width: 100%;
    }
  `;

const ModalSubHeadContainer = styled.div`
    width: calc(100%-60px);
    display: flex;
    justify-content: flex-start;
    align-items: center;
  `;

const ModalHeadSubText = styled.h2`
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 2px;
  transform: translateY(-5px);
  line-height: 20px;
`;

const StyledButton = styled(Button)`
background-color: ${(p) =>
    p.disabled ? theme.grayBorderColor : theme.linkBlue} !important;
color: ${theme.white} !important;

&:hover {
  background-color: ${(p) =>
    p.disabled ? theme.grayBorderColor : theme.linkBlue} !important;
}
`;

const SelectContainer = styled.div`
    display: flex;
  `;

const AddButtonContainer = styled.div`
  width: 100%;
  padding: 20px 0px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  @media (max-width: 750px) {
    padding: 16px 0px 16px 0px;
  }
`;

export default SetTargetIndustryModal;