import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

import CityDashboardHeader from "../../components/CityDashboardHeader/CityDashboardHeader";
import CustomizedCard from "../../components/CustomizedCard/CustomizedCardV3";
import CustomizedGraphic from "../../components/CustomizedGraphic/CustomizedGraphicV2";
import CustomizedTable from "../../components/CustomizedTable/CustomizedTableV2";
import CustomizedPublicStock from "../../components/CustomizedPublicStock/CustomizedPublicStock";
import NewsInfoTable from "../../components/CustomizedTable/NewsInfoTable";
import WasteDisposalTable from "../../components/CustomizedTable/WasteDisposalTable";
import FactoryAreaRentTable from "../../components/CustomizedTable/FactoryAreaRentTable";
import ParkMainIndexListTable from "../../components/CustomizedTable/ParkMainIndexListTable";
import MainIndex from "../../components/MainIndex/MainIndex";
import Introduction from "../../components/Introducrtion/Introducrtion";
import BackToTopButton from "../../components/BackToTopButton/BackToTopButtonV2";
import Wordcloud from "../../components/WordCloud/WordCloud";
import { cityCode, cityCodeMap } from "../../constants/areas";
import {
  getParkMainIndexListDataHandler,
  getTypeTwoChartDataHandler,
  getTypeThreeChartDataHandler,
  getTypeFourChartDataHandler,
  getPublicStockChartData,
  // getNotPublicStockChartData,
  getStockRankingData,
  getTopTenCapitalDataData,
  getTopTenGovPlanData,
  getTopTenPatentData,
  getImportPercentageData,
  getExportPercentageData,
  getAwardPercentageData,
  getGovPlanPercentageData,
  getPublicStockPercentageData,
  getNewsInfoData,
  getEmployeeData,
  getNewsInfoTagData,
  getGreenFactoryData,
  getMainIndex,
  getFactoryAreaRentDataHandler,
  getTopFiveWasteDisposalDataHandler,
  getMetalPriceVal,
  getMetalOption,
} from "../../store/actions/areaDashbroad";
import Modal from "../../components/CustomizedModal/CustomizedModal";
import CityDashboradModal from "../../components/AreaDashboradModal/CityDashboradModal";
import { upperFirstCase } from "../../utility/common";

import { northParks, centerParks, southParks } from "../../constants/parks";

import "./styles.scss";
import { ScrollContainer, InnerContainer } from "../../utility/styles";
import Select from "@material-ui/core/Select";

export default (props) => {
  const dispatch = useDispatch();
  const containerRef = React.useRef(null);
  const history = useHistory();
  const location = useLocation();
  const [state, setState] = React.useState({
    selectedCity: "North",
    selectedCityName: "",
    citiesOptions: [],
    newsListPage: 1,
    perPageCount: 5,
    modalType: "",
    referenceCode: "",
    isModalOpen: false,
    title: "",
    persistModalOpen: false,
    metalList: [],
    metalsOptions: [],
    isModalOpen: false,
    metal: "",
    selectedMetalTw: "近月倫敦熱軋鋼捲(中國離岸價)",
    selectedMetalEn: "HCDCON",
    metalUnit: "美元/公噸",
  });
  const {
    mainIndex,
    parkMainIndexList,
    typeTwoChartData,
    typeTwoChartDataLabel,
    typeTwoChartDataMap,
    typeTwoChartDataLoading,
    typeThreeChartData,
    typeThreeChartDataLabel,
    typeThreeChartDataMap,
    typeThreeChartDataLoading,
    typeFourChartData,
    typeFourChartDataLabel,
    typeFourChartDataMap,
    typeFourChartDataLoading,
    stockRanking,
    topTenCapital,
    topTenGovPlan,
    topTenPatent,
    importPercentage,
    exportPercentage,
    awardPercentage,
    govPlanPercentage,
    publicStockPercentage,
    newsInfo,
    employeeInfo,
    newsInfoTag,
    greenFactory,
    publicStock,
    factoryAreaRent,
    topFiveWasteDisposal,
    metalPriceValue,
    metalOptions,
  } = useSelector((state) => state.areaDashbroad);

  const areaCode = props.match.params.cityId;

  const handleRequests = React.useCallback(
    (city) => {
      Promise.all([
        dispatch(getParkMainIndexListDataHandler(city)),
        dispatch(getTypeTwoChartDataHandler(city)),
        dispatch(getTypeThreeChartDataHandler(city)),
        dispatch(getTypeFourChartDataHandler(city)),
        dispatch(getPublicStockChartData(city)),
        dispatch(getTopTenCapitalDataData(city)),
        dispatch(getTopTenGovPlanData(city)),
        dispatch(getTopTenPatentData(city)),
        dispatch(getImportPercentageData(city)),
        dispatch(getExportPercentageData(city)),
        dispatch(getAwardPercentageData(city)),
        dispatch(getEmployeeData(city)),
        dispatch(getNewsInfoTagData(city)),
        dispatch(getGreenFactoryData(city)),
        dispatch(getGovPlanPercentageData(city)),
        dispatch(getMainIndex(city)),
        dispatch(getNewsInfoData(city)),
        dispatch(getFactoryAreaRentDataHandler(city)),
        dispatch(getTopFiveWasteDisposalDataHandler(city)),
        dispatch(getMetalPriceVal()),
        dispatch(getMetalOption()),
      ]);
    },
    [dispatch]
  );

  const handleRequestStockData = (city) => {
    const parkList = [];
    if (city === "North") {
      northParks.filter(i => i.Name_CH.indexOf("加工出口區") < 0 ).map((park) => parkList.push(park.ParkId));
    } else if (city === "Center") {
      centerParks.filter(i => i.Name_CH.indexOf("加工出口區") < 0 ).map((park) => parkList.push(park.ParkId));
    } else if (city === "South") {
      southParks.filter(i => i.Name_CH.indexOf("加工出口區") < 0 ).map((park) => parkList.push(park.ParkId));
    }

    dispatch(getStockRankingData(parkList));
  }

  React.useEffect(() => {
    if (Object.keys(props.match.params).length === 0) {

      if (location.pathname.slice(-1) === "/") {
        history.push(`${location.pathname}North`);
      }
      else {
        history.push(`${location.pathname}/North`);
      }
      handleRequests("North");
      handleRequestStockData("North");
      setState((state) => ({ ...state, selectedCity: "North", selectedCityName: "臺北分局" }));
    } else {
      if (!cityCodeMap[upperFirstCase(areaCode)]) {
        history.push(`North`);
        handleRequestStockData("North");
        handleRequests("North");
      } else {
        handleRequestStockData(upperFirstCase(areaCode));
        handleRequests(upperFirstCase(areaCode));
        setState((state) => ({
          ...state,
          selectedCity: upperFirstCase(areaCode),
          selectedCityName: cityCodeMap[upperFirstCase(areaCode)]
        }));
      }
    }


  }, [areaCode]);

  React.useEffect(() => {
    const updateCityOptions = [];
    cityCode.map((city) =>
      updateCityOptions.push({
        key: city.CityId,
        value: city.CityId,
        name: city.CityName,
      })
    );
    setState((state) => ({ ...state, citiesOptions: updateCityOptions }));
  }, []);

  React.useEffect(() => {
    const updateMetalOptions = [];
    metalOptions.map((metalItem) =>
      updateMetalOptions.push({
        key: metalItem.nameEN,
        value: metalItem.nameEN,
        name: metalItem.nameTW,
      })
    );
    setState((state) => ({ ...state, metalsOptions: updateMetalOptions }));
  }, [metalOptions]);

  React.useEffect(() => {
    if (metalOptions) {
      for (var i = 0; i < metalOptions.length; i++) {
        if (state.selectedMetalEn == metalOptions[i].nameEN) {
          var tempUnit = metalOptions[i].unit;
          var tempTw = metalOptions[i].nameTW;
          setState((state) => ({ ...state, metalUnit: tempUnit }));
          setState((state) => ({ ...state, selectedMetalTw: tempTw }));
        }
      }
    }
  }, [state.selectedMetalEn]);

  const handleChangeMetal = (e) => {
    const { value, name } = e.target;
    setState((state) => ({ ...state, [name]: value }));
    onChangeHandlerMetal(value, name);
  };

  const onChangeHandlerMetal = (value) => {
    setState((state) => ({ ...state, selectedMetalEn: value }));
    dispatch(getMetalPriceVal(value));
  };

  const onChangeHandler = (value) => {
    setState((state) => ({ ...state, selectedCity: value }));
    history.push(`${value}`);
  };

  const handleNewsPage = (page) => {
    setState((state) => ({ ...state, newsListPage: page }));
  };

  const handleClose = React.useCallback(() => {
    if (!state.persistModalOpen) {
      setState((state) => ({
        ...state,
        isModalOpen: !state.isModalOpen,
        modalType: "",
        referenceCode: "",
      }));
    }
  }, [state.persistModalOpen]);

  const handleModalOpen = (modalType, referenceCode, title, wasteNumber) => {
    if (
      modalType &&
      ((referenceCode &&
        referenceCode !== "其他") ||
        referenceCode === Number(0))
      //&& referenceCode !== 5
    ) {
      setState((state) => ({
        ...state,
        isModalOpen: true,
        modalType: modalType,
        referenceCode: referenceCode,
        title: title,
        wasteNumber,
      }));
    } else {
      return;
    }
  };

  const openNewTab = (businessNo, type) => {
    if (!businessNo) {
      return;
    } else {
      const url = `${window.location.origin}`;
      window.open(
        `${url}/factory_info/basic_info/${businessNo}`,
        "_blank"
      );
    }
  };

  const persistModalOpenHandler = (value) => {
    setState((state) => ({ ...state, persistModalOpen: value }));
  };

  const scrollToTop = () => {
    containerRef.current.scrollTo({ top: 0, behavior: "smooth" })
  };

  return (
    <ScrollContainer className="area-dashboard" ref={containerRef}>
      <BackToTopButton scrollToTop={scrollToTop}/>
      <Modal isOpen={state.isModalOpen} handleClose={handleClose}>
        <CityDashboradModal
          modalType={state.modalType}
          referenceCode={state.referenceCode}
          handleClose={handleClose}
          cityId={state.selectedCity}
          title={state.title}
          wasteNumber={state.wasteNumber}
          selectedCity={state.selectedCityName}
          persistModalOpenHandler={persistModalOpenHandler}
        />
      </Modal>
      <InnerContainer>
      <div className="area-dashboard-overview">
        <CityDashboardHeader
          isArea
          title="總覽"
          subTitle={`${state.selectedCityName}產業園區基本資料`}
          onChangeHandler={onChangeHandler}
          selectionOption={state.citiesOptions}
          selectedCity={state.selectedCity}
          referenceType={"areaOverview"}
        />
        <div className="area-dashboard-overview-container">
          {
            state.selectedCity !== "All" && 
            <CustomizedCard title="簡介" isLoading={false} >
              <Introduction
                selectedCity={state.selectedCity}
                totalAreaSize={mainIndex.mainIndexData.TotalAreaSize}
              />
            </CustomizedCard>
          }
          <CustomizedCard
            title="金屬價格資訊"
            referenceType={"areaDashboradMetalPrice"}
          >
            <div style={{ width: "100%", textAlign: "right", marginTop: "10px", display: "inline-flex", justifyContent: "right", marginRight: "15px", marginBottom:"5px" }}>
              <div style={{ fontSize: "20px", marginTop: "5px" }}>{"切換金屬："}</div>
              <Select
                native
                value={state.metal}
                onChange={(e) => handleChangeMetal(e)}
                name="metal"
                style={{ display: "inline-block", width: "fill-available", background: "#eececea3", borderRadius: "10px", fontWeight: "bold", marginRight: "15px" }}
                className="dashboard-header-selection"
              >
                {state.metalsOptions.map((option) => {
                  return (
                    <option key={option.key} value={option.value} >
                      {option.name}
                    </option>
                  );
                })}
              </Select>
            </div>
            <div style={{ whiteSpace: "break-spaces", fontSize: "20px", textAlign: "right", marginRight: "15px" }}>
              <span>{"最新數值：" }</span><span style={{fontSize:"30px"}}>{metalPriceValue.metalPriceNew ? metalPriceValue.metalPriceNew : null}</span><span style={{ fontSize: "15px" }}>{state.metalUnit ? state.metalUnit : null}</span>
              <br />
              <div style={{marginTop: "15px"}}>{metalPriceValue.metalDateNew ? "最新日期：" + metalPriceValue.metalDateNew : null}</div>
            </div>
            <div style={{ width: "100%", textAlign: "left", marginLeft: "15px" }}>{"單位：" + state.metalUnit}</div>
            <CustomizedGraphic
              data={metalPriceValue.metalPriceValData}
              labels={metalPriceValue.metalPriceValLabel}
              chartType="curve-chart"
              skip="1"
            />
          </CustomizedCard>
          {
            state.selectedCity !== "All" && 
            <CustomizedCard
              title="概況"
              loading={mainIndex.mainIndexDataLoading}
            >
              <MainIndex data={mainIndex.mainIndexData} isArea/>
            </CustomizedCard>
          }

          {window.innerWidth < 1024 && (
            <CustomizedCard
              title={`${state.selectedCityName}轄下園區列表`}
              shouldCardOpen={true}
              loading={parkMainIndexList.parkMainIndexListLoading}
              referenceType={"areaDashboradMainIndexTable"}
            >
              <ParkMainIndexListTable
                headerList={["園區名", "廠商數(家)", "產值(億)", "員工數"]}
                data={parkMainIndexList.parkMainIndexListData}
              />
            </CustomizedCard>
          )}

          <CustomizedCard
            title={`${state.selectedCityName}土地廠房租售資訊`}
            shouldCardOpen={true}
            loading={factoryAreaRent.factoryAreaRentLoading}
            referenceType={"areaDashboradAreaRentingTable"}
          >
            <FactoryAreaRentTable
              headerList={["園區", "面積", "類別", "單價", "網址"]}
              data={factoryAreaRent.factoryAreaRentData}
            />
          </CustomizedCard>

          {window.innerWidth > 1024 && (
            <CustomizedCard
              title={`${state.selectedCityName}轄下園區列表`}
              shouldCardOpen={true}
              loading={parkMainIndexList.parkMainIndexListLoading}
              referenceType={"areaDashboradMainIndexTable"}
            >
              <ParkMainIndexListTable
                headerList={["園區名", "廠商數(家)", "產值(億)", "員工數"]}
                data={parkMainIndexList.parkMainIndexListData}
              />
            </CustomizedCard>
          )}
        </div>
      </div>
      <div className="area-dashboard-popular-topics">
        <CityDashboardHeader
          title="熱門議題"
          subTitle="以園區與區內廠商名稱關鍵字分析新聞熱門議題"
          referenceType={"areaPopularTopics"}
        />
        <div className="area-dashboard-overview-news-container">
          <CustomizedCard
            title="新聞標籤雲"
            loading={newsInfoTag.newsInfoTagDataLoading}
            referenceType={"areaDashboradNewsInfoTagTable"}
          >
              <Wordcloud
                tag={newsInfoTag.newsInfoTagData}
                eventHandler={(val, title) =>
                  handleModalOpen("sectionNewsTag", val, title)
                }
              />
          </CustomizedCard>
          <CustomizedCard
            title="相關新聞"
            loading={newsInfo.newsInfoDataLoading}
            referenceType={"areaDashboradNewsInfoTable"}
            withMinHeight={newsInfo.newsInfoTotalLength > 5 ? 350 : ""}
          >
            <NewsInfoTable
              headerList={["新聞標題", "日期/來源", "相關廠商"]}
              data={newsInfo.newsInfoData}
              newsInfoTotalLength={newsInfo.newsInfoTotalLength}
              newsInfoRowNum={newsInfo.newsInfoRowNum}
              newsInfoPagingNum={newsInfo.newsInfoPagingNum}
              getNewsListByNewPage={handleNewsPage}
              selectedCity=""
            />
          </CustomizedCard>
        </div>
      </div>
      <div className="area-dashboard-industry-view">
        <CityDashboardHeader
          title="產業形貌"
          subTitle="以主計總處產業類別2/3/4碼呈現園區廠商產業分佈"
          referenceType={"areaIndustryOverview"}
        />
        <div className="area-dashboard-industry-view-container">
          <CustomizedCard
            title={`${state.selectedCityName}廠商產業別四碼家數佔比`}
            shouldCardOpen={true}
            loading={typeTwoChartDataLoading}
            rankingList={typeFourChartDataMap}
            referenceType={"areaDashboradFourChartDataTable"}
            eventHandler={(val, title) =>
              handleModalOpen("typeFour", val, title)
            }
          >
            <CustomizedGraphic
              chartType={"donut-chart"}
              data={typeFourChartData}
              circle={"70"}
              labels={typeFourChartDataLabel}
              mapData={typeFourChartDataMap}
              dataLabels={{
                enabled: true,
                formatter: (val, { seriesIndex, dataPointIndex, w }) =>
                  w.config.labels[seriesIndex],
              }}
              eventHandler={(val, title) =>
                handleModalOpen("typeFour", val, title)
              }
            />
          </CustomizedCard>
          <CustomizedCard
            title={`${state.selectedCityName}廠商產業別三碼家數佔比`}
            shouldCardOpen={true}
            loading={typeThreeChartDataLoading}
            rankingList={typeThreeChartDataMap}
            referenceType={"areaDashboradThreeChartDataTable"}
            eventHandler={(val, title) =>
              handleModalOpen("typeThree", val, title)
            }
          >
            <CustomizedGraphic
              chartType={"donut-chart"}
              data={typeThreeChartData}
              circle={"70"}
              labels={typeThreeChartDataLabel}
              mapData={typeThreeChartDataMap}
              dataLabels={{
                enabled: true,
                formatter: (val, { seriesIndex, dataPointIndex, w }) =>
                  w.config.labels[seriesIndex],
              }}
              eventHandler={(val, title) =>
                handleModalOpen("typeThree", val, title)
              }
            />
          </CustomizedCard>
          <CustomizedCard
            title={`${state.selectedCityName}廠商產業別二碼家數佔比`}
            shouldCardOpen={true}
            loading={typeFourChartDataLoading}
            rankingList={typeTwoChartDataMap}
            referenceType={"areaDashboradTwoChartDataTable"}
            eventHandler={(val, title) =>
              handleModalOpen("typeTwo", val, title)
            }
          >
            <CustomizedGraphic
              chartType={"donut-chart"}
              data={typeTwoChartData}
              circle={"70"}
              labels={typeTwoChartDataLabel}
              mapData={typeTwoChartDataMap}
              dataLabels={{
                enabled: true,
                formatter: (val, { seriesIndex, dataPointIndex, w }) =>
                  w.config.labels[seriesIndex],
              }}
              eventHandler={(val, title) =>
                handleModalOpen("typeTwo", val, title)
              }
            />
          </CustomizedCard>
        </div>
      </div>
      <div className="area-dashboard-index-rating">
        <CityDashboardHeader
          title="指標排行"
          subTitle="呈現區內廠商股票公開發行、園區廠商進出口級距佔比與政府計畫排行等圖表"
          referenceType={"areaIndexRanking"}
        />
        <div className="area-dashboard-index-rating-container">
          <CustomizedCard
            title={`${state.selectedCityName}股票上市櫃廠商推薦`}
            shouldCardOpen={true}
            loading={stockRanking.stockRankingLoading}
            referenceType={"areaDashboradStockRankingTable"}
          >
            <CustomizedTable
              headerList={["統一編號", "廠商名稱", "設廠園區"]}
              data={stockRanking.publicStockRankingData}
              isArea
            />
          </CustomizedCard>
          <CustomizedCard
            title={`${state.selectedCityName}股票非上市櫃廠商推薦`}
            shouldCardOpen={true}
            loading={stockRanking.stockRankingLoading}
            referenceType={"areaDashboradUnStockRankingTable"}
          >
            <CustomizedTable
              headerList={["統一編號", "廠商名稱", "設廠園區"]}
              data={stockRanking.notPublicStockRankingData}
              isArea
            />
          </CustomizedCard>
          {/* <CustomizedCard title={`${state.selectedCityName}綠色工廠`} shouldCardOpen={true} isLoading={greenFactory.greenFactoryLoading} >
            <GreenFactoryTable headerList={["統一編號","廠商名稱"]} data={greenFactory.greenFactoryData}/>
          </CustomizedCard> */}
          <CustomizedCard
            title={`${state.selectedCityName}股票上市櫃廠商家數`}
            shouldCardOpen={true}
            loading={publicStock.notPublicStockLoading}
            referenceType={"areaDashboradPublicStockTable"}
          >
            <CustomizedPublicStock
              data={publicStock}
              eventHandler={(val, title) =>
                handleModalOpen("publicStock", val, title)
              }
            />
          </CustomizedCard>
          <CustomizedCard
            title={`${state.selectedCityName}出口級距廠商家數佔比`}
            shouldCardOpen={true}
            loading={exportPercentage.exportPercentageDataLoading}
            rankingList={exportPercentage.exportPercentageDataMap}
            referenceType={"areaDashboradExportPercentageTable"}
            eventHandler={(val, title) =>
              handleModalOpen("export", val, title)
            }
          >
            <CustomizedGraphic
              chartType={"donut-chart"}
              data={exportPercentage.exportPercentageData}
              circle={"70"}
              labels={exportPercentage.exportPercentageDataLabels}
              mapData={exportPercentage.exportPercentageDataMap}
              dataLabels={{
                enabled: true,
                formatter: (val, { seriesIndex, dataPointIndex, w }) =>
                  w.config.labels[seriesIndex],
              }}
              eventHandler={(val, title) =>
                handleModalOpen("export", val, title)
              }
            />
          </CustomizedCard>
          <CustomizedCard
            title={`${state.selectedCityName}進口級距廠商家數佔比`}
            shouldCardOpen={true}
            loading={importPercentage.importPercentageDataLoading}
            rankingList={importPercentage.importPercentageDataMap}
            referenceType={"areaDashboradImportPercentageTable"}
            eventHandler={(val, title) =>
              handleModalOpen("import", val, title)
            }
          >
            <CustomizedGraphic
              chartType={"donut-chart"}
              data={importPercentage.importPercentageData}
              circle={"70"}
              labels={importPercentage.importPercentageDataLabels}
              mapData={importPercentage.importPercentageDataMap}
              dataLabels={{
                enabled: true,
                formatter: (val, { seriesIndex, dataPointIndex, w }) =>
                  w.config.labels[seriesIndex],
              }}
              eventHandler={(val, title) =>
                handleModalOpen("import", val, title)
              }
            />
          </CustomizedCard>
          <CustomizedCard
            title={`${state.selectedCityName}園區廠商員工數前十大排行`}
            shouldCardOpen={true}
            loading={employeeInfo.employeeInfoDataLoading}
            referenceType="areaDashboradEmployeeRanking"
          >
            <CustomizedGraphic
              chartType={"horizontal-bar-chart"}
              data={employeeInfo.employeeInfoData}
              labels={employeeInfo.employeeInfoDataLabels}
              mapData={employeeInfo.employeeInfoDataMap}
              unit={"人"}
              formatter={(val) => val}
              color={["#6bcf00"]}
              type={"employee"}
              eventHandler={(val) => openNewTab(val, "employee")}
            />
          </CustomizedCard>
          <CustomizedCard
            title={`${state.selectedCityName}園區廠商資本額前十大排行`}
            shouldCardOpen={true}
            loading={topTenCapital.topTenCapitalLoading}
            rankingList={topTenCapital.topTenCapitalDataMap}
            referenceType={"areaDashboradTopTenCapitalTable"}
            eventHandler={(val) => openNewTab(val, "capital")}
          >
            <CustomizedGraphic
              chartType={"vertical-bar-chart"}
              data={topTenCapital.topTenCapitalData}
              labels={topTenCapital.topTenCapitalLabels}
              mapData={topTenCapital.topTenCapitalDataMap}
              unit={"元"}
              formatter={(val) => (val === 0 ? 0 : val / 100000000 + "億")}
              eventHandler={(val) => openNewTab(val, "capital")}
            />
          </CustomizedCard>
          <CustomizedCard
            title={`${state.selectedCityName}園區廠商政府計畫類型佔比`}
            shouldCardOpen={true}
            loading={govPlanPercentage.govPlanPercentageDataLoading}
            rankingList={govPlanPercentage.govPlanPercentageDataMap}
            referenceType={"areaDashboradGovPlanPercentageTable"}
            eventHandler={(val, title) =>
              handleModalOpen("govPlan", val, title)
            }
          >
            <CustomizedGraphic
              chartType={"donut-chart"}
              data={govPlanPercentage.govPlanPercentageData}
              circle={"70"}
              labels={govPlanPercentage.govPlanPercentageDataLabels}
              mapData={govPlanPercentage.govPlanPercentageDataMap}
              isGovPlanOrAwardPercentage
              dataLabels={{
                enabled: true,
                formatter: (val, { seriesIndex, dataPointIndex, w }) =>
                  w.config.labels[seriesIndex],
              }}
              eventHandler={(val, title) =>
                handleModalOpen("govPlan", val, title)
              }
            />
          </CustomizedCard>
          <CustomizedCard
            title={`${state.selectedCityName}園區廠商政府計畫數前十大排行`}
            shouldCardOpen={true}
            loading={topTenGovPlan.topTenGovPlanDataLoading}
            rankingList={topTenGovPlan.topTenGovPlanDataMap}
            referenceType={"areaDashboradTopTenGovPlanTable"}
            eventHandler={(val, title) =>
              handleModalOpen("topTenGovPlan", val, title)
            }
          >
            <CustomizedGraphic
              chartType={"horizontal-bar-chart"}
              data={topTenGovPlan.topTenGovPlanData}
              labels={topTenGovPlan.topTenGovPlanDataLabels}
              mapData={topTenGovPlan.topTenGovPlanDataMap}
              unit={"件"}
              formatter={(val) => val}
              eventHandler={(val, title) =>
                handleModalOpen("topTenGovPlan", val, title)
              }
            />
          </CustomizedCard>
          <CustomizedCard
            title={`${state.selectedCityName}園區廠商獲獎類型佔比`}
            shouldCardOpen={true}
            loading={awardPercentage.awardPercentageDataLoading}
            rankingList={awardPercentage.awardPercentageDataMap}
            referenceType={"areaDashboradAwardPercentageTable"}
            eventHandler={(val, title) =>
              handleModalOpen("award", val, title)
            }
          >
            <CustomizedGraphic
              chartType={"donut-chart"}
              data={awardPercentage.awardPercentageData}
              circle={"70"}
              labels={awardPercentage.awardPercentageDataLabels}
              mapData={awardPercentage.awardPercentageDataMap}
              isGovPlanOrAwardPercentage
              dataLabels={{
                enabled: true,
                formatter: (val, { seriesIndex, dataPointIndex, w }) =>
                  w.config.labels[seriesIndex],
              }}
              eventHandler={(val, title) =>
                handleModalOpen("award", val, title)
              }
            />
          </CustomizedCard>
          <CustomizedCard
            title={`${state.selectedCityName}園區廠商近年專利公告數前十大排行`}
            shouldCardOpen={true}
            loading={topTenPatent.topTenPatentDataLoading}
            rankingList={topTenPatent.topTenPatentDataMap}
            referenceType={"areaDashboradTopTenPatentTable"}
            eventHandler={(val, title) =>
              handleModalOpen("patent", val, title)
            }
          >
            <CustomizedGraphic
              chartType={"horizontal-bar-chart"}
              data={topTenPatent.topTenPatentData}
              labels={topTenPatent.topTenPatentDataLabels}
              mapData={topTenPatent.topTenPatentDataMap}
              unit={"件"}
              formatter={(val) => val}
              eventHandler={(val, title) =>
                handleModalOpen("patent", val, title)
              }
            />
          </CustomizedCard>
          <CustomizedCard
            title={`${state.selectedCityName}循環廢棄物申報量排行`}
            shouldCardOpen={true}
            loading={topFiveWasteDisposal.topFiveWasteDisposalLoading}
            referenceType="wasteDisposal"
          >
            <WasteDisposalTable
              headerList={["廢棄物種類", "工廠家數", "總量(公噸)"]}
              data={topFiveWasteDisposal.topFiveWasteDisposalData}
              eventHandler={(val, title, wasteNumber) =>
                handleModalOpen("topFiveWasteDisposal", val, title, wasteNumber)
              }
            />
          </CustomizedCard>
        </div>
      </div>
      </InnerContainer>
      {/* <Footer /> */}
    </ScrollContainer>
  );
};