import React from "react";

import Spinner from "../Spinner/Spinner";

import {
  northStaticData,
  centerStaticData,
  southStaticData,
  kPStaticData,
} from "../../constants/areaSaticData";
import { parksWithUrl } from "../../constants/parks";

import { thousandDivider } from "../../utility/common";

import "./styles.scss";

export default (props) => {
  const { selectedCity, totalAreaSize, sectionData } = props;
  const [state, setState] = React.useState({
    data: "",
  });

  React.useEffect(() => {
    if (selectedCity === "North") {
      setState((state) => ({ ...state, data: northStaticData }));
    } else if (selectedCity === "Center") {
      setState((state) => ({ ...state, data: centerStaticData }));
    } else if (selectedCity === "South") {
      setState((state) => ({ ...state, data: southStaticData }));
    } else if (selectedCity === "KP") {
      setState((state) => ({ ...state, data: kPStaticData }));
    }
  }, [selectedCity]);

  let size;
  if (!Array.isArray(sectionData)) {
    if (sectionData?.InduPark.Area) {
      size =
        thousandDivider(Math.floor(sectionData.InduPark.Area / 10000)) + "萬";
    } else if (totalAreaSize) {
      size = thousandDivider(Math.floor(totalAreaSize / 10000)) + "萬";
    } else {
      size = "目前暫無資料";
    }
  } else {
    size = "目前暫無資料";
  }

  if (!state.data && !sectionData) {
    return <Spinner />;
  } else {
    return (
      <div className="introduction-container">
        <div className="introduction-container-left">
          <div className="introduction-container-left-name">
            <p className="introduction-container-left-name-CH">
              {state.data.Name || sectionData?.InduPark?.Name}
            </p>
            <p className="introduction-container-left-name-EN">
              {state.data.EnglishName || sectionData?.InduPark?.Name_EN}
            </p>
          </div>
          <div className="introduction-container-left-section">
            <div style={{ borderRight: "solid 1px #e4e6e5" }}>
              <p className="introduction-container-left-section-header">
                園區主管
              </p>
              {state.data.Manager ? (
                <>
                  <p className="manager">{state.data.Manager[0]}</p>
                  <p className="manager">{state.data.Manager[1]}</p>
                </>
              ) : !Array.isArray(sectionData) &&
                sectionData.ServiceCenter.Director ? (
                <p className="manager">
                  {sectionData?.ServiceCenter?.Director}
                </p>
              ) : (
                <p className="manager">目前暫無資料</p>
              )}
            </div>
            {sectionData ? (
              <div>
                <div className="subtitle">園區屬性</div>
                <div className="maintitle">
                  {sectionData?.InduPark?.Property || "目前暫無資料"}
                </div>
              </div>
            ) : (
              <div>
                <p className="introduction-container-left-section-header">
                  轄管園區
                </p>
                <p>{state.data.Type} 個</p>
              </div>
            )}
          </div>
        </div>
        <div className="introduction-container-right">
          <div className="introduction-container-right-items">
            <p className="introduction-container-right-items-header">
              園區總面積(平方公尺)
            </p>
            <p>{size || "目前暫無資料"}</p>
          </div>
          <div className="introduction-container-right-items">
            <p className="introduction-container-right-items-header">
              服務中心地址
            </p>
            <p>{state.data.Address || sectionData?.ServiceCenter?.Address}</p>
          </div>
          {(sectionData?.InduPark?.Url || state.data.link) && (
            <div
              className="introduction-container-right-items"
              style={window.innerWidth < 1024 ? { width: "90%" } : {}}>
              <p className="introduction-container-right-items-header">網站</p>
              {sectionData ? (
                <button
                  className="link"
                  title="另開新視窗，前往官網"
                  onClick={() => {
                    window.open(sectionData?.InduPark?.Url, "_blank");
                  }}>
                  前往官網
                </button>
              ) : (
                <button
                  title="另開新視窗，前往官網"
                  className="link"
                  onClick={() => {
                    window.open(state.data.link, "_blank");
                  }}>
                  前往官網
                </button>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
};
