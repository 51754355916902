import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import Icon from "@material-ui/core/Icon";
import { useSelector } from "react-redux";

import { thousandDivider, stopTypeSwitcher } from "../../../utility/common";

import "./styles.scss";

const FactoryBasicInfoTable = (props) => {
  const { data, loading, getCompanyAbstractData } = props;
  const { innerWidth } = useSelector((state) => state.app);
  const [state, setState] = React.useState({
    endIndex: 0,
    page: 0,
    rowsPerPage: 5,
    styleKey: {},
    styleValue: {},
  });
  const url = window.location.origin;

  const openNewTabs = (link) => window.open(link, "_blank");

  // const styleKey = innerWidth < 1024 ? {width:"40%", fontWeight: 600} : {width:"30%", fontWeight: 600};
  // const styleValue = innerWidth < 1024 ? {width:"60%", borderBottom: "none"} : {display: "flex"};

  React.useEffect(() => {
    if (innerWidth < 1024) {
      setState((state) => ({
        ...state,
        styleKey: { width: "40%", fontWeight: 600 },
        styleValue: { width: "60%", borderBottom: "none" },
      }));
    } else {
      setState((state) => ({
        ...state,
        styleKey: { width: "30%", fontWeight: 600 },
        styleValue: { display: "flex" },
      }));
    }
  }, [innerWidth]);

  if (data && getCompanyAbstractData) {
    return (
      <TableContainer className="table-container">
        {data.length > 0 ? (
          <Table className="table" style={{ tableLayout: "fixed" }}>
            <TableBody className="table-body">
              <React.Fragment>
                {data.map((type, index) => {
                  return (
                    <TableRow
                      hover
                      className="table-body-row"
                      key={type.substring(0, 4) + index}
                    >
                      <TableCell
                        align="left"
                        style={{
                          ...state.styleKey,
                        }}
                      >
                        <button
                          style={{
                            textDecoration: "underline",
                            color: "rgb(0, 106, 180)",
                            cursor: "pointer",
                          }}
                          title="另開新視窗"
                          onClick={() =>
                            openNewTabs(
                              `${url}/industry_index/${type.substring(0, 4)}`
                            )
                          }
                        >
                          {type.substring(0, 4)}
                        </button>
                      </TableCell>
                      <TableCell align="left" style={state.styleValue}>
                        {getCompanyAbstractData?.map((item, index) => {
                          var rankTitle = item.Index.replace("排行", "");
                          if (rankTitle === type) {
                            return (
                              <p>
                                {type.substring(4, type.length)}{" "}
                                <p style={{ fontSize: "14px" }}>
                                  (同產業資本額排名：
                                  {thousandDivider(item.Rank)})
                                </p>
                              </p>
                            );
                          }
                        })}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </React.Fragment>
            </TableBody>
          </Table>
        ) : (
          <Table>
            <TableBody className="table-body-no-data">
              <TableRow hover className="table-body-row">
                <TableCell align="center" className="no-data">
                  本項目無資料
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        )}
      </TableContainer>
    );
  } else {
    return <div />;
  }
};

export default React.memo(FactoryBasicInfoTable);
