const base = `INDUSTRY_DASHBOARD_`;

//產業儀錶板營運動態列表
export const START_GET_OPERATION_MAIN_INDEX_LIST_DATA = `${base}START_GET_OPERATION_MAIN_INDEX_LIST_DATA`;
export const GET_OPERATION_MAIN_INDEX_LIST_DATA_SUCCESS = `${base}GET_OPERATION_MAIN_INDEX_LIST_DATA_SUCCESS`;
export const GET_OPERATION_MAIN_INDEX_LIST_DATA_FAIL = `${base}GET_OPERATION_MAIN_INDEX_LIST_DATA_FAIL`;

//產業上市櫃公司營收漲跌家數
export const START_GET_STOCK_COMPANY_REVENUE = "START_GET_STOCK_COMPANY_REVENUE";
export const GET_STOCK_COMPANY_REVENUE_SUCCESS = "GET_STOCK_COMPANY_REVENUE_SUCCESS";
export const GET_STOCK_COMPANY_REVENUE_FAIL = "GET_STOCK_COMPANY_REVENUE_FAIL";

//產業出口級距漲跌家數
export const START_GET_EXPORT_COMPANY_REVENUE = "START_GET_EXPORT_COMPANY_REVENUE";
export const GET_EXPORT_COMPANY_REVENUE_SUCCESS = "GET_EXPORT_COMPANY_REVENUE_SUCCESS";
export const GET_EXPORT_COMPANY_REVENUE_FAIL = "GET_EXPORT_COMPANY_REVENUE_FAIL";

//產業上市櫃公司營收漲跌前五大排行
export const START_GET_TOP_TEN_CAPITAL_DATA = "START_GET_TOP_TEN_CAPITAL_DATA";
export const GET_TOP_TEN_CAPITAL_DATA_SUCCESS = "GET_TOP_TEN_CAPITAL_DATA_SUCCESS";
export const GET_TOP_TEN_CAPITAL_DATA_FAIL = "GET_TOP_TEN_CAPITAL_DATA_FAIL";

//取得最新數據
export const START_GET_INFO = `${base}START_GET_INFO`;
export const GET_INFO_SUCCESS = `${base}GET_INFO_SUCCESS`;
export const GET_INFO_FAIL = `${base}GET_INFO_FAIL`;

//產業上市櫃公司營收趨勢圖
export const START_GET_STOCK_COMPANY_REVENUE_TREND = `${base}START_GET_STOCK_COMPANY_REVENUE_TREND`;
export const GET_STOCK_COMPANY_REVENUE_TREND_SUCCESS = `${base}GET_STOCK_COMPANY_REVENUE_TREND_SUCCESS`;
export const GET_STOCK_COMPANY_REVENUE_TREND_FAIL = `${base}GET_STOCK_COMPANY_REVENUE_TREND_FAIL`;

//產業出口級距公司營收趨勢圖
export const START_GET_EXPORT_COMPANY_REVENUE_TREND = `${base}START_GET_EXPORT_COMPANY_REVENUE_TREND`;
export const GET_EXPORT_COMPANY_REVENUE_TREND_SUCCESS = `${base}GET_EXPORT_COMPANY_REVENUE_TREND_SUCCESS`;
export const GET_EXPORT_COMPANY_REVENUE_TREND_FAIL = `${base}GET_EXPORT_COMPANY_REVENUE_TREND_FAIL`;

//產業產值趨勢(千元)
export const START_GET_PRODUCT_VAL = `${base}START_GET_PRODUCT_VAL`;
export const GET_PRODUCT_VAL_SUCCESS = `${base}GET_PRODUCT_VAL_SUCCESS`;
export const GET_PRODUCT_VAL_FAIL = `${base}GET_PRODUCT_VAL_FAIL`;

//全年固定資產投資金額趨勢(千元)
export const START_GET_INVESTMENT_AMT = `${base}START_GET_INVESTMENT_AMT`;
export const GET_INVESTMENT_AMT_SUCCESS = `${base}GET_INVESTMENT_AMT_SUCCESS`;
export const GET_INVESTMENT_AMT_FAIL = `${base}GET_INVESTMENT_AMT_FAIL`;

//營運中工廠家數趨勢
export const START_GET_FACTORY_COUNT = `${base}START_GET_FACTORY_COUNT`;
export const GET_FACTORY_COUNT_SUCCESS = `${base}GET_FACTORY_COUNT_SUCCESS`;
export const GET_FACTORY_COUNT_FAIL = `${base}GET_FACTORY_COUNT_FAIL`;

//年度從業員工人數趨勢
export const START_GET_EMP_COUNT = `${base}START_GET_EMP_COUNT`;
export const GET_EMP_COUNT_SUCCESS = `${base}GET_EMP_COUNT_SUCCESS`;
export const GET_EMP_COUNT_FAIL = `${base}GET_EMP_COUNT_FAIL`;

//研究發展工廠家數
export const START_GET_RESEARCH_FACTORY_COUNT = `${base}START_GET_RESEARCH_FACTORY_COUNT`;
export const GET_RESEARCH_FACTORY_COUNT_SUCCESS = `${base}GET_RESEARCH_FACTORY_COUNT_SUCCESS`;
export const GET_RESEARCH_FACTORY_COUNT_FAIL = `${base}GET_RESEARCH_FACTORY_COUNT_FAIL`;

//技術購買金額趨勢(千元)
export const START_GET_TECH_BUY_AMT = `${base}START_GET_TECH_BUY_AMT`;
export const GET_TECH_BUY_AMT_SUCCESS = `${base}GET_TECH_BUY_AMT_SUCCESS`;
export const GET_TECH_BUY_AMT_FAIL = `${base}GET_TECH_BUY_AMT_FAIL`;

//研究發展經費(千元)
export const START_GET_RESEARCH_AMT = `${base}START_GET_RESEARCH_AMT`;
export const GET_RESEARCH_AMT_SUCCESS = `${base}GET_RESEARCH_AMT_SUCCESS`;
export const GET_RESEARCH_AMT_FAIL = `${base}GET_RESEARCH_AMT_FAIL`;

//技術銷售金額趨勢(千元)
export const START_GET_TECH_SALE_AMT = `${base}START_GET_TECH_SALE_AMT`;
export const GET_TECH_SALE_AMT_SUCCESS = `${base}GET_TECH_SALE_AMT_SUCCESS`;
export const GET_TECH_SALE_AMT_FAIL = `${base}GET_TECH_SALE_AMT_FAIL`;

//營業收入(千元)
export const START_GET_REVENUE_INCOME = `${base}START_GET_REVENUE_INCOME`;
export const GET_REVENUE_INCOME_SUCCESS = `${base}GET_REVENUE_INCOME_SUCCESS`;
export const GET_REVENUE_INCOME_FAIL = `${base}GET_REVENUE_INCOME_FAIL`;

//營業支出(千元)
export const START_GET_REVENUE_PAY = `${base}START_GET_REVENUE_PAY`;
export const GET_REVENUE_PAY_SUCCESS = `${base}GET_REVENUE_PAY_SUCCESS`;
export const GET_REVENUE_PAY_FAIL = `${base}GET_REVENUE_PAY_FAIL`;

//產業前三十大資本額廠商清單
export const START_GET_TOP30_COMPANY = `${base}START_GET_TOP30_COMPANY`;
export const GET_TOP30_COMPANY_SUCCESS = `${base}GET_TOP30_COMPANY_SUCCESS`;
export const GET_TOP30_COMPANY_FAIL = `${base}GET_TOP30_COMPANY_FAIL`;

//產業上市櫃廠商
export const START_GET_LISTED_COMPANY = `${base}START_GET_LISTED_COMPANY`;
export const GET_LISTED_COMPANY_SUCCESS = `${base}GET_LISTED_COMPANY_SUCCESS`;
export const GET_LISTED_COMPANY_FAIL = `${base}GET_LISTED_COMPANY_FAIL`;

//發明公開(5年內)
export const START_GET_PUBINVENTION = `${base}START_GET_PUBINVENTION`;
export const GET_PUBINVENTION_SUCCESS = `${base}GET_PUBINVENTION_SUCCESS`;
export const GET_PUBINVENTION_FAIL = `${base}GET_PUBINVENTION_FAIL`;

//專利公告(5年內)
export const START_GET_PATENT_FINISHED = `${base}START_GET_PATENT_FINISHED`;
export const GET_PATENT_FINISHED_SUCCESS = `${base}GET_PATENT_FINISHED_SUCCESS`;
export const GET_PATENT_FINISHED_FAIL = `${base}GET_PATENT_FINISHED_FAIL`;

//產業四碼
export const START_GET_FOUR_CODE = `${base}START_GET_FOUR_CODE`;
export const GET_FOUR_CODE_SUCCESS = `${base}GET_FOUR_CODE_SUCCESS`;
export const GET_FOUR_CODE_FAIL = `${base}GET_FOUR_CODE_FAIL`;