import * as actionTypes from "./actionTypes";
import * as actionType from "../actionTypes/factory";

import { fetchCompanyAssociation } from "../../utility/parkDashboradApi";
import {
  fetchVerifiedCode,
  fetchUpdatedCompanyList,
  fetchEnterpriseQualified,
  fetchCompanyAbstract,
  fetchCompanyAbstractOrigin,
  updatedCompanyInfo,
  getCompanyBasicInfo,
  getForeignTradesCompare,
  getGovProBidding,
  getVerdict,
  getTrademark,
  getBusinessInfo,
  getTaxCompanyIndustryInfo,
  getCompanyInvestBackTW,
  getForeignTrades4CodeProduct,
  handleGetCompanyUploadVerifying,
  getBoardMemberInfo,
  getOperationItems,
  getBranchInfo,
  getTradeAmount,
  getPatentInfo,
  getGovPlan,
  getAwardPlan,
  getMITMarkInfo,
  getNewsContents,
  getNewsTags,
  getServiceHistory,
  fetchManagerInfo,
  getGovPlanSubsidy,
  getNegative,
} from "../../utility/factoryApi";

export const startBasicInfo = () => {
  return {
    type: actionType.START_GET_BASIC_INFO,
  };
};

export const getBasicInfoSuccess = (data) => {
  return {
    type: actionType.GET_BASIC_INFO_SUCCESS,
    data: data,
  };
};

export const getBasicInfoFail = (err) => {
  return {
    type: actionType.GET_BASIC_INFO_FAIL,
    error: err,
  };
};

export const handleStartBasicInfo = (city) => {
  return (dispatch) => {
    dispatch(startBasicInfo());
    getCompanyBasicInfo(city)
      .then((res) => {
        if (res) {
          dispatch(getBasicInfoSuccess(res));
        } else {
          dispatch(getBasicInfoFail("取得廠商資料時發生問題。"));
        }
      })
      .catch((err) => {
        dispatch(getBasicInfoSuccess(err));
      });
  };
};

export const startGetCardControl = () => {
  return actionTypes.START_GET_CARDCONTROL;
};

export const getCardControlSuccess = () => {
  return actionTypes.GET_CARDCONTROL_SUCCESS;
};

export const getCardControlFail = () => {
  return actionTypes.GET_CARDCONTROL_FAIL;
};

export const startUpdateCardControl = () => {
  return actionTypes.START_UPDATE_CARDCONTROL;
};

export const UpdateCardControlSuccess = () => {
  return actionTypes.UPDATE_CARDCONTROL_SUCCESS;
};

export const UpdateCardControlFail = () => {
  return actionTypes.UPDATE_GROUP_FAIL;
};

export const cardControlHandler = (userId) => {};

export const startGetCompanyAssociationData = () => {
  return {
    type: actionTypes.START_GET_COMPANY_ASSOCIATION_DATA,
  };
};

export const getCompanyAssociationDataSuccess = (data) => {
  return {
    type: actionTypes.GET_COMPANY_ASSOCIATION_DATA_SUCCESS,
    data: data,
  };
};

export const getCompanyAssociationDataFail = (err) => {
  return {
    type: actionTypes.GET_COMPANY_ASSOCIATION_DATA_FAIL,
    err: err,
  };
};

export const getCompanyAssociationHandler = (city) => {
  return (dispatch) => {
    dispatch(startGetCompanyAssociationData());
    fetchCompanyAssociation(city)
      .then((res) => {
        if (res) {
          dispatch(getCompanyAssociationDataSuccess(res));
        } else {
          dispatch(getCompanyAssociationDataFail("取得關係企業時發生問題。"));
        }
      })
      .catch((err) => {
        dispatch(getCompanyAssociationDataFail(err));
      });
  };
};

export const startGetVerifiedCode = () => {
  return {
    type: actionType.START_VERIFIED_CODE,
  };
};

export const getVerifiedCodeSuccess = (data) => {
  return {
    type: actionType.GET_VERIFIED_CODE_SUCCESS,
    data: data,
  };
};

export const getVerifiedCodeFail = () => {
  return {
    type: actionType.GET_VERIFIED_CODE_FAIL,
  };
};

export const getVerifiedCodeHandler = (businessNo) => {
  return (dispatch) => {
    dispatch(startGetVerifiedCode());
    fetchVerifiedCode(businessNo)
      .then((res) => {
        if (res) {
          dispatch(getVerifiedCodeSuccess(res));
        } else {
          dispatch(getVerifiedCodeFail("取得認證碼發生問題。"));
        }
      })
      .catch((err) => {
        dispatch(getVerifiedCodeFail(err));
      });
  };
};

export const startGetCompanyInfoList = () => {
  return {
    type: actionType.START_GET_UPDATE_COMPANY_INFO,
  };
};

export const getCompanyInfoListSuccess = (data) => {
  return {
    type: actionType.GET_UPDATE_COMPANY_INFO_SUCCESS,
    data: data,
  };
};

export const getCompanyInfoListFail = (err) => {
  return {
    type: actionType.GET_UPDATE_COMPANY_INFO_FAIL,
    err: err,
  };
};

export const getCompanyInfoListHandler = (businessNo) => {
  return (dispatch) => {
    dispatch(startGetCompanyInfoList());
    fetchUpdatedCompanyList(businessNo)
      .then((res) => {
        if (res) {
          dispatch(getCompanyInfoListSuccess(res));
        } else {
          dispatch(getCompanyInfoListFail("取得公司資訊發生問題。"));
        }
      })
      .catch((err) => {
        dispatch(getCompanyInfoListFail(err));
      });
  };
};

// 產業競爭力發展中心飛雷神
export const startGetEnterpriseQualified = () => {
  return {
    type: actionType.START_GET_ENTERPRISE_QUALIFIED,
  };
};

export const getEnterpriseQualifiedSuccess = (data) => {
  return {
    type: actionType.GET_ENTERPRISE_QUALIFIED_SUCCESS,
    data: data.data,
  };
};

export const getEnterpriseQualifiedFail = (err) => {
  return {
    type: actionType.GET_ENTERPRISE_QUALIFIED_FAIL,
    err: err,
  };
};

export const getEnterpriseQualifiedHandler = (businessNo) => {
  return (dispatch) => {
    dispatch(startGetEnterpriseQualified());
    fetchEnterpriseQualified(businessNo)
      .then((res) => {
        if (res) {
          dispatch(getEnterpriseQualifiedSuccess(res));
        } else {
          dispatch(getEnterpriseQualifiedFail("取得公司資訊發生問題。"));
        }
      })
      .catch((err) => {
        dispatch(getEnterpriseQualifiedFail(err));
      });
  };
};

export const startGetCompanyAbstract = () => {
  return {
    type: actionType.START_GET_COMPANY_ABSTRACT,
  };
};

export const getCompanyAbstractSuccess = (data) => {
  return {
    type: actionType.GET_COMPANY_ABSTRACT_SUCCESS,
    data: data,
  };
};

export const getCompanyAbstractFail = (err) => {
  return {
    type: actionType.GET_COMPANY_ABSTRACT_FAIL,
    err: err,
  };
};

export const getCompanyAbstractHandler = (businessNo) => {
  return (dispatch) => {
    dispatch(startGetCompanyAbstract());
    fetchCompanyAbstract(businessNo)
      .then((res) => {
        if (res) {
          dispatch(getCompanyAbstractSuccess(res));
        } else {
          dispatch(getCompanyAbstractFail("取得公司摘要資料發生問題。"));
        }
      })
      .catch((err) => {
        dispatch(getCompanyAbstractFail(err));
      });
  };
};

export const startUpdateCompanyInfo = () => {
  return {
    type: actionType.START_UPDATE_COMPANY_INFO,
  };
};

export const updateCompanyInfoSuccess = (data) => {
  return {
    type: actionType.UPDATE_COMPANY_INFO_SUCCESS,
    data: data,
  };
};

export const updateCompanyInfoFail = (err) => {
  return {
    type: actionType.UPDATE_COMPANY_INFO_FAIL,
    err: err,
  };
};

export const updateCompanyInfoHandler = (data) => {
  return (dispatch) => {
    dispatch(startUpdateCompanyInfo());
    updatedCompanyInfo(data)
      .then((res) => {
        console.log(res)
        if (res.status === 200) {
          dispatch(updateCompanyInfoSuccess(res.data));
        } else {
          dispatch(updateCompanyInfoFail("上傳公司資訊發生問題。"));
        }
      })
      .catch((err) => {
        dispatch(updateCompanyInfoFail(err));
      });
  };
};

export const startInitUpdateCompanyState = () => {
  return {
    type: actionType.START_INIT_UPDATE_COMPANY_INFO_STATE,
  };
};

export const initUpdateCompanyStateSuccess = () => {
  return {
    type: actionType.INIT_UPDATE_COMPANY_INFO_STATE_SUCCESS,
  };
};

export const initUpdateCompanyStateFail = (err) => {
  return {
    type: actionType.INIT_UPDATE_COMPANY_INFO_STATE_FAIL,
    err: err,
  };
};

export const initUpdateCompanyStateHandler = (data) => {
  return (dispatch) => {
    dispatch(startInitUpdateCompanyState());
    try {
      dispatch(initUpdateCompanyStateSuccess());
    } catch (err) {
      dispatch(initUpdateCompanyStateFail("重置廠商諮詢時方生問題。"));
    }
  };
};

export const startGetForeignTradesCompare = () => {
  return {
    type: actionType.START_GET_FOREIGN_TRADES_COMPARE,
  };
};

export const getForeignTradesCompareSuccess = (data) => {
  return {
    type: actionType.GET_FOREIGN_TRADES_COMPARE_SUCCESS,
    data: data,
  };
};

export const getForeignTradesCompareFail = (err) => {
  return {
    type: actionType.GET_FOREIGN_TRADES_COMPARE_FAIL,
    error: err,
  };
};

export const handleGetForeignTradesCompare = (businessId) => {
  return (dispatch) => {
    dispatch(startGetForeignTradesCompare());
    getForeignTradesCompare(businessId)
      .then((res) => {
        if (res) {
          dispatch(getForeignTradesCompareSuccess(res));
        } else {
          dispatch(
            getForeignTradesCompareFail("取得比較同期比較資料時發生問題。")
          );
        }
      })
      .catch((err) => {
        dispatch(getForeignTradesCompareFail(err));
      });
  };
};

export const startGetGovProBidding = () => {
  return {
    type: actionType.START_GET_GOV_PRO_BIDDING,
  };
};

export const getGovProBiddingSuccess = (data) => {
  return {
    type: actionType.GET_GOV_PRO_BIDDING_SUCCESS,
    data: data,
  };
};

export const getGovProBiddingFail = (err) => {
  return {
    type: actionType.GET_GOV_PRO_BIDDING_FAIL,
  };
};

export const handleGetGovProBidding = (businessId) => {
  return (dispatch) => {
    dispatch(startGetGovProBidding());
    getGovProBidding(businessId)
      .then((res) => {
        if (res) {
          dispatch(getGovProBiddingSuccess(res));
        } else {
          dispatch(getGovProBiddingFail("取得比較同期比較資料時發生問題。"));
        }
      })
      .catch((err) => {
        dispatch(getGovProBiddingFail(err));
      });
  };
};

// 智財權相關判決書
export const startGetVerdict = () => {
  return {
    type: actionType.START_GET_VERDICT,
  };
};

export const getVerdictSuccess = (data) => {
  return {
    type: actionType.GET_VERDICT_SUCCESS,
    data: data.data ? data.data : [],
  };
};

export const getVerdictFail = (err) => {
  return {
    type: actionType.GET_VERDICT_FAIL,
  };
};

export const handleGetVerdict = (businessId) => {
  return (dispatch) => {
    dispatch(startGetVerdict());
    getVerdict(businessId)
      .then((res) => {
        if (res) {
          dispatch(getVerdictSuccess(res));
        } else {
          dispatch(getVerdictFail("取得比較同期比較資料時發生問題。"));
        }
      })
      .catch((err) => {
        dispatch(getVerdictFail(err));
      });
  };
};

// 商標
export const startGetTrademark = () => {
  return {
    type: actionType.START_GET_TRADEMARK,
  };
};

export const getTrademarkSuccess = (data) => {
  data.map((row, index) => {
    row.SDate = (parseInt(row.SDate.split(".")[0]) + 1911).toString() + "." + row.SDate.split(".")[1] + "." + row.SDate.split(".")[2]
    row.EDate = (parseInt(row.EDate.split(".")[0]) + 1911).toString() + "." + row.EDate.split(".")[1] + "." + row.EDate.split(".")[2]
    row.Filing_Date = (parseInt(row.Filing_Date.split(".")[0]) + 1911).toString() + "." + row.Filing_Date.split(".")[1] + "." + row.Filing_Date.split(".")[2]
  })
  data = [...data].sort((a, b) => {
    if (a.EDate > b.EDate) return -1;
    if (a.EDate < b.EDate) return 1;
  });
  return {
    type: actionType.GET_TRADEMARK_SUCCESS,
    data: data,
  };
};

export const getTrademarkFail = (err) => {
  return {
    type: actionType.GET_TRADEMARK_FAIL,
  };
};

export const handleGetTrademark = (businessId) => {
  return (dispatch) => {
    dispatch(startGetTrademark());
    getTrademark(businessId)
      .then((res) => {
        if (res) {
          dispatch(getTrademarkSuccess(res));
        } else {
          dispatch(getTrademarkFail("取得比較同期比較資料時發生問題。"));
        }
      })
      .catch((err) => {
        dispatch(getTrademarkFail(err));
      });
  };
};

export const startGetBusinessInfo = () => {
  return {
    type: actionType.START_GET_BUSINESS_INFO,
  };
};

export const getBusinessInfoSuccess = (data) => {
  return {
    type: actionType.GET_BUSINESS_INFO_SUCCESS,
    data: data,
  };
};

export const getBusinessInfoFail = (err) => {
  return {
    type: actionType.GET_BUSINESS_INFO_FAIL,
    error: err,
  };
};

export const handleGetBusinessInfo = (businessId) => {
  return (dispatch) => {
    dispatch(startGetBusinessInfo());
    getBusinessInfo(businessId)
      .then((res) => {
        if (res) {
          dispatch(getBusinessInfoSuccess(res));
        } else {
          dispatch(getBusinessInfoFail("取得比較同期比較資料時發生問題。"));
        }
      })
      .catch((err) => {
        dispatch(getGovProBiddingFail(err));
      });
  };
};

export const startGetCompanyUploadVerifying = () => {
  return {
    type: actionType.START_GET_COMPANY_UPLOAD_VERIFYING,
  };
};

export const getCompanyUploadVerifyingSuccess = (lastUploadTime) => {
  return {
    type: actionType.GET_COMPANY_UPLOAD_VERIFYING_SUCCESS,
    lastUploadTime: lastUploadTime,
  };
};

export const getCompanyUploadVerifyingFail = (err) => {
  return {
    type: actionType.GET_COMPANY_UPLOAD_VERIFYING_FAIL,
    err: err,
  };
};

export const getCompanyUploadVerifyingHandler = (data) => {
  return (dispatch) => {
    dispatch(startGetCompanyUploadVerifying());
    handleGetCompanyUploadVerifying(data)
      .then((res) => {
        if (res) {
          dispatch(getCompanyUploadVerifyingSuccess(res));
        } else {
          dispatch(getCompanyUploadVerifyingFail("驗證公司發生問題。"));
        }
      })
      .catch((err) => {
        dispatch(getCompanyUploadVerifyingFail(err));
      });
  };
};

export const startGetFactoryTaxInfo = () => {
  return {
    type: actionType.START_GET_TAX_COMPANY_INFO,
  };
};


export const getFactoryInfoTaxSuccess = (data) => {
  return {
    type: actionType.GET_TAX_COMPANY_INFO_SUCCESS,
    data: data,
  };
};

export const getFactoryInfoTaxFail = (err) => {
  return {
    type: actionType.GET_TAX_COMPANY_INFO_FAIL,
    err: err,
  };
};

export const getFactoryTaxInfoHandler = (data) => {
  return (dispatch) => {
    dispatch(startGetFactoryTaxInfo());
    getTaxCompanyIndustryInfo(data)
      .then((res) => {
        if (res) {
          dispatch(getFactoryInfoTaxSuccess(res));
        } else {
          dispatch(getFactoryInfoTaxFail("驗證公司發生問題。"));
        }
      })
      .catch((err) => {
        dispatch(getFactoryInfoTaxFail(err));
      });
  };
};

export const startGetCompanyInvestBackTW = () => {
  return {
    type: actionType.START_GET_COMPANY_INVEST_BACK_TW,
  };
};


export const getCompanyInvestBackTWSuccess = (data) => {
  return {
    type: actionType.GET_COMPANY_INVEST_BACK_TW_SUCCESS,
    data: data,
  };
};

export const getCompanyInvestBackTWFail = (err) => {
  return {
    type: actionType.GET_COMPANY_INVEST_BACK_TW_FAIL,
    err: err,
  };
};

export const getCompanyInvestBackTWHandler = (data) => {
  return (dispatch) => {
    dispatch(startGetCompanyInvestBackTW());
    getCompanyInvestBackTW(data)
      .then((res) => {
        if (res) {
          dispatch(getCompanyInvestBackTWSuccess(res));
        } else {
          dispatch(getCompanyInvestBackTWFail("取得投資台灣資料發生問題。"));
        }
      })
      .catch((err) => {
        dispatch(getCompanyInvestBackTWFail(err));
      });
  };
};

export const startGetForeignTrades4CodeProduct = () => {
  return {
    type: actionType.START_GET_FOREIGN_TRADE_4CODE_PRODUCT,
  };
};

export const getForeignTrades4CodeProductSuccess = (data) => {
  return {
    type: actionType.GET_FOREIGN_TRADE_4CODE_PRODUCT_SUCCESS,
    data: data,
  };
};

export const getForeignTrades4CodeProductFail = (err) => {
  return {
    type: actionType.GET_FOREIGN_TRADE_4CODE_PRODUCT_FAIL,
    err: err,
  };
};


export const getForeignTrades4CodeProductHandler = (data) => {
  return (dispatch) => {
    dispatch(startGetForeignTrades4CodeProduct());
    getForeignTrades4CodeProduct(data)
      .then((res) => {
        if (res) {
          dispatch(getForeignTrades4CodeProductSuccess(res));
        } else {
          dispatch(getForeignTrades4CodeProductFail("驗證公司發生問題。"));
        }
      })
      .catch((err) => {
        console.log(err)
        dispatch(getForeignTrades4CodeProductFail(err));
      });
  };
};

export const startGetBoardMemberInfo = () => {
  return {
    type: actionType.START_GET_BOARD_MEMBER,
  };
};

export const getBoardMemberInfoSuccess = (data) => {
  return {
    type: actionType.GET_BOARD_MEMBER_SUCCESS,
    data: data,
  };
};

export const getBoardMemberInfoFail = (err) => {
  return {
    type: actionType.GET_BOARD_MEMBER_FAIL,
    err: err,
  };
};

export const handleGetBoardMember = (data) => {
  return (dispatch) => {
    dispatch(startGetBoardMemberInfo());
    getBoardMemberInfo(data)
      .then((res) => {
        if (res) {
          dispatch(getBoardMemberInfoSuccess(res));
        } else {
          dispatch(getBoardMemberInfoFail("驗證公司發生問題。"));
        }
      })
      .catch((err) => {
        dispatch(getBoardMemberInfoFail(err));
      });
  };
};


export const startGetRegisteredOperationItems = () => {
  return {
    type: actionType.START_GET_REGISTERED_OPERATION_ITEM,
  };
};

export const getRegisteredOperationItemsSuccess = (data) => {
  return {
    type: actionType.GET_REGISTERED_OPERATION_ITEM_SUCCESS,
    data: data,
  };
};

export const getRegisteredOperationItemsFail = (err) => {
  return {
    type: actionType.GET_REGISTERED_OPERATION_ITEM_FAIL,
    err: err,
  };
};

export const handleGetRegisteredOperationItems = (data) => {
  return (dispatch) => {
    dispatch(startGetRegisteredOperationItems());
    getOperationItems(data)
      .then((res) => {
        if (res) {
          dispatch(getRegisteredOperationItemsSuccess(res));
        } else {
          dispatch(getRegisteredOperationItemsFail("驗證公司發生問題。"));
        }
      })
      .catch((err) => {
        dispatch(getRegisteredOperationItemsFail(err));
      });
  };
};


export const startGetBranchCompanyInfo = () => {
  return {
    type: actionType.START_GET_BRANCH_INFO,
  };
};

export const getBranchCompanyInfoSuccess = (data) => {
  return {
    type: actionType.GET_BRANCH_INFO_SUCCESS,
    data: data,
  };
};

export const getBranchCompanyInfoFail = (err) => {
  return {
    type: actionType.GET_BRANCH_INFO_FAIL,
    err: err,
  };
};

export const handleGetBranchCompanyInfo = (data) => {
  return (dispatch) => {
    dispatch(startGetBranchCompanyInfo());
    getBranchInfo(data)
      .then((res) => {
        if (res) {
          dispatch(getBranchCompanyInfoSuccess(res));
        } else {
          dispatch(getBranchCompanyInfoFail("驗證公司發生問題。"));
        }
      })
      .catch((err) => {
        dispatch(getBranchCompanyInfoFail(err));
      });
  };
};

export const startGetHistoryTradeInfo = () => {
  return {
    type: actionType.START_GET_TRADE_INFO,
  };
};

export const getHistoryTradeInfoSuccess = (data) => {
  return {
    type: actionType.GET_TRADE_INFO_SUCCESS,
    data: data,
  };
};

export const getHistoryTradeInfoFail = (err) => {
  return {
    type: actionType.GET_TRADE_INFO_FAIL,
    err: err,
  };
};

export const handleGetHistoryTradeInfo = (data) => {
  return (dispatch) => {
    dispatch(startGetHistoryTradeInfo());
    getTradeAmount(data)
      .then((res) => {
        if (res) {
          dispatch(getHistoryTradeInfoSuccess(res));
        } else {
          dispatch(getHistoryTradeInfoFail("驗證公司發生問題。"));
        }
      })
      .catch((err) => {
        dispatch(getHistoryTradeInfoFail(err));
      });
  };
};

export const startGetPatentInfo = () => {
  return {
    type: actionType.START_GET_PATENT_INFO,
  };
};

export const getPatentInfoSuccess = (data) => {
  return {
    type: actionType.GET_PATENT_INFO_SUCCESS,
    data: data,
  };
};

export const getPatentInfoFail = (err) => {
  return {
    type: actionType.GET_PATENT_INFO_FAIL,
    err: err,
  };
};

export const handleGetPatentInfo = (data) => {
  return (dispatch) => {
    dispatch(startGetPatentInfo());
    getPatentInfo(data, 8)
      .then((res) => {
        if (res) {
          dispatch(getPatentInfoSuccess(res));
        } else {
          dispatch(getPatentInfoFail("驗證公司發生問題。"));
        }
      })
      .catch((err) => {
        dispatch(getPatentInfoFail(err));
      });
  };
};

export const startGetGovernmentPlansInfo = () => {
  return {
    type: actionType.START_GET_GOV_PLAN_INFO,
  };
};

export const getGovernmentPlansInfoSuccess = (data) => {
  return {
    type: actionType.GET_GOV_PLAN_INFO_SUCCESS,
    data: data,
  };
};

export const getGovernmentPlansInfoFail = (err) => {
  return {
    type: actionType.GET_GOV_PLAN_INFO_FAIL,
    err: err,
  };
};

export const handleGovernmentPlansInfo = (data) => {
  return (dispatch) => {
    dispatch(startGetGovernmentPlansInfo());
    getGovPlan(data)
      .then((res) => {
        if (res) {
          dispatch(getGovernmentPlansInfoSuccess(res));
        } else {
          dispatch(getGovernmentPlansInfoFail("驗證公司發生問題。"));
        }
      })
      .catch((err) => {
        dispatch(getGovernmentPlansInfoFail(err));
      });
  };
};

export const startGetAwardInfo = () => {
  return {
    type: actionType.START_GET_AWARD_INFO,
  };
};

export const getAwardInfoSuccess = (data) => {
  return {
    type: actionType.GET_AWARD_INFO_SUCCESS,
    data: data,
  };
};

export const getAwardInfoFail = (err) => {
  return {
    type: actionType.GET_AWARD_INFO_FAIL,
    err: err,
  };
};

export const handleGetAwardInfo = (data) => {
  return (dispatch) => {
    dispatch(startGetAwardInfo());
    getAwardPlan(data)
      .then((res) => {
        if (res) {
          dispatch(getAwardInfoSuccess(res));
        } else {
          dispatch(getAwardInfoFail("驗證公司發生問題。"));
        }
      })
      .catch((err) => {
        dispatch(getAwardInfoFail(err));
      });
  };
};

// MIT標章
export const startGetMITMarkInfo = () => {
  return {
    type: actionType.START_GET_MIT_INFO,
  };
};

export const getMITMarkInfoSuccess = (data) => {
  return {
    type: actionType.GET_MIT_INFO_SUCCESS,
    data: data.data,
  };
};

export const getMITMarkInfoFail = (err) => {
  return {
    type: actionType.GET_MIT_INFO_FAIL,
    err: err,
  };
};

export const handleGetMITMarkInfo = (data) => {
  return (dispatch) => {
    dispatch(startGetMITMarkInfo());
    getMITMarkInfo(data)
      .then((res) => {
        if (res) {
          dispatch(getMITMarkInfoSuccess(res));
        } else {
          dispatch(getMITMarkInfoFail("驗證公司發生問題。"));
        }
      })
      .catch((err) => {
        dispatch(getMITMarkInfoFail(err));
      });
  };
};

export const startGetNewsInfo = () => {
  return {
    type: actionType.START_GET_NEWS_CONTENT_INFO,
  };
};

export const getNewsInfoSuccess = (data) => {
  return {
    type: actionType.GET_NEWS_CONTENT_INFO_SUCCESS,
    data: data,
  };
};

export const getNewsInfoFail = (err) => {
  return {
    type: actionType.GET_NEWS_CONTENT_INFO_FAIL,
    err: err,
  };
};

export const handleGetNewsInfo = (data) => {
  return (dispatch) => {
    dispatch(startGetNewsInfo());
    getNewsContents(data)
      .then((res) => {
        if (res) {
          dispatch(getNewsInfoSuccess(res));
        } else {
          dispatch(getNewsInfoFail("驗證公司發生問題。"));
        }
      })
      .catch((err) => {
        dispatch(getNewsInfoFail(err));
      });
  };
};

export const startGetNewsTag = () => {
  return {
    type: actionType.START_GET_NEWS_TAG_INFO,
  };
};

export const getNewsTagSuccess = (data) => {
  return {
    type: actionType.GET_NEWS_TAG_INFO_SUCCESS,
    data: data,
  };
};

export const getNewsTagFail = (err) => {
  return {
    type: actionType.GET_NEWS_TAG_INFO_FAIL,
    err: err,
  };
};

export const handleGetNewsTag = (data) => {
  return (dispatch) => {
    dispatch(startGetNewsTag());
    getNewsTags(data)
      .then((res) => {
        if (res) {
          dispatch(getNewsTagSuccess(res));
        } else {
          dispatch(getNewsTagFail("驗證公司發生問題。"));
        }
      })
      .catch((err) => {
        dispatch(getNewsTagFail(err));
      });
  };
};

export const startGetServiceHistory = () => {
  return {
    type: actionType.START_GET_SERVICE_HISTORY,
  };
};

export const getServiceHistorySuccess = (data) => {
  return {
    type: actionType.GET_SERVICE_HISTORY_SUCCESS,
    data: data,
  };
};

export const getServiceHistoryFail = (err) => {
  return {
    type: actionType.GET_SERVICE_HISTORY_FAIL,
    err: err,
  };
};

export const handleGetServiceHistory = (busiNo, UserID) => {
  return (dispatch) => {
    dispatch(startGetServiceHistory());
    getServiceHistory(busiNo, UserID)
      .then((res) => {
        if (res) {
          dispatch(getServiceHistorySuccess(res));
        } else {
          dispatch(getServiceHistoryFail("驗證公司發生問題。"));
        }
      })
      .catch((err) => {
        dispatch(getServiceHistoryFail(err));
      });
  };
};

const startManagerInfo = () => {
  return {
    type: actionType.START_GET_MANAGER_INFO,
  };
};

const getManagerInfoSuccess = (data) => {
  return {
    type: actionType.GET_MANAGER_INFO_SUCCESS,
    data: data,
  };
};

const getManagerInfoFail = (err) => {
  return {
    type: actionType.GET_MANAGER_INFO_FAIL,
    err: err,
  };
};

export const handleGetManagerInfo = (busiNo) => {
  return (dispatch) => {
    dispatch(startManagerInfo());
    fetchManagerInfo(busiNo)
      .then((res) => {
        if (res) {
          dispatch(getManagerInfoSuccess(res.data));
        } else {
          dispatch(getManagerInfoFail("取得經理人資訊發生問題。"));
        }
      })
      .catch((err) => {
        dispatch(getManagerInfoFail(err));
      });
  };
};

//取得工業局補捐助資料
export const startGetGovernmentPlansInfoSubsidy = () => {
  return {
    type: actionType.START_GET_GOV_PLAN_INFO_SUBSIDY,
  };
};

export const getGovernmentPlansInfoSubsidySuccess = (data) => {
  return {
    type: actionType.GET_GOV_PLAN_INFO_SUBSIDY_SUCCESS,
    data: data,
  };
};

export const getGovernmentPlansInfoSubsidyFail = (err) => {
  return {
    type: actionType.GET_GOV_PLAN_INFO_SUBSIDY_FAIL,
    err: err,
  };
};

export const handleGovernmentPlansInfoSubsidy = (data) => {
  return (dispatch) => {
    dispatch(startGetGovernmentPlansInfoSubsidy());
    getGovPlanSubsidy(data)
      .then((res) => {
        if (res) {
          dispatch(getGovernmentPlansInfoSubsidySuccess(res));
        } else {
          dispatch(getGovernmentPlansInfoSubsidyFail("驗證公司發生問題。"));
        }
      })
      .catch((err) => {
        dispatch(getGovernmentPlansInfoSubsidyFail(err));
      });
  };
};

//取得裁罰資料(負面訊息)
export const startGetNegative = () => {
  return {
    type: actionType.START_GET_NEGATIVE,
  };
};

export const getNegativeSuccess = (data) => {
  return {
    type: actionType.GET_NEGATIVE_SUCCESS,
    data: data,
  };
};

export const getNegativeFail = (err) => {
  return {
    type: actionType.GET_NEGATIVE_FAIL,
  };
};

export const handleGetNegative = (businessId) => {
  return (dispatch) => {
    dispatch(startGetNegative());
    {((localStorage.getItem("auL") == 1) || (localStorage.getItem("auL") == 2) || (localStorage.getItem("auL") == 3)) 
      ?(getNegative(businessId)
        .then((res) => {
          if (res) {
            dispatch(getNegativeSuccess(res));
          } else {
            dispatch(getNegativeFail("取得比較同期比較資料時發生問題。"));
          }
        })
        .catch((err) => {
          dispatch(getNegativeFail(err));
        }))
      :dispatch(getNegativeFail("取得裁罰資料失敗。"));
    }
  };
};