import React, { useState, useEffect } from 'react';
import { NavLink, useRouteMatch } from 'react-router-dom'
import { Tabs, Tab ,Card } from '@material-ui/core'

import './styles.scss'


const InnerNavBar = props => {
    const [ listInfo, setListInfo ] = useState()
    const [ businessNoInfo, setBusinessNoInfo ] = useState("")
    const [ value, setValue ] = useState(0)
    const { listData, businessNo } = props

    useEffect(()=>{
        setListInfo(listData)
        setBusinessNoInfo(businessNo)
    },[listData, businessNo])

    const handleChange = (event, newValue) => {
        props.getNavValue(newValue)
        setValue(newValue)
    };

    let nav

    if(listInfo){
        nav = listInfo.map((li, index) =>{
                 return (
                   <Tab
                     className='inner-bar-tab'
                     key={li.url}
                     label={li.name}
                     value={index}
                     id={index}
                    // aria-controls={`nav-tabpanel-${index}`}
                   />
                 );   
            } 
        );
    }
    



    return (
      <Tabs
        value={value}
        className="inner-nav-bar"
        // component={Card}
        onChange={handleChange}
      >
        {nav}
      </Tabs>
    );
      


}



export default InnerNavBar