import React, { useEffect } from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { useSnackbar } from "notistack";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import Radio from "@material-ui/core/Radio";
import Box from "@material-ui/core/Box";
import Select from "@material-ui/core/Select";
import Swal from "sweetalert2";

import { handleGoogleSignInCheckRegistered } from "../../utility/authApi";
import { parksWithOutExportZone as parks } from "../../constants/parks";
import Verification from "./Vertification";
import * as theme from "../../assests/styles/theme";
import { message } from "../../utility/common";
import { singUp } from "../../store/actions/auth";

const SignUp = ({
  isHidden,
  isModalOpen,
  directToSignIn,
  handleCloseModal,
  status,
}) => {
  const IS_NUMBER = /^\d+$/;
  const IS_EMAIL = /^\S+@\S+\.\S+$/;
  const IS_PASS_PHRASE = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[a-zA-Z\d!@#$%^&*]{12,20}$/;
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [state, setState] = React.useState({
    account: "",
    pass_string: "",
    confirmedPhrase: "",
    name: "",
    induParkId: "",
    businessNo: "",
    businessName: "",
    isPasswordVisible: false,
    remember: false,
    isInputBusinessNumber: true,
    isAgreePrivacy: false,
    clicked: false,
    isActive: false,
    is_company_info_pass: false,
  });

  const handleChange = (e) => {
    const { name, value, type } = e.target;

    if (type === "checkbox" || type === "radio") {
      setState((state) => ({ ...state, [name]: !state[name] }));
    } else {
      setState((state) => ({ ...state, [name]: value }));
    }
  };

  const handleVisiblePassword = () => {
    setState((state) => ({
      ...state,
      isPasswordVisible: !state.isPasswordVisible,
    }));
  };

  const handleSingUp = () => {
    setState((state) => ({ ...state, clicked: true }));
    if (!state.isAgreePrivacy) {
      Swal.fire({
        text: `請同意並瀏覽個資聲明`,
      });
    } else if (state.is_company_info_pass) {
      return;
    } else if (!IS_EMAIL.test(state.account)) {
      return;
    } else if (!IS_PASS_PHRASE.test(state.pass_string)) {
      return;
    } else if (state.pass_string !== state.confirmedPhrase) {
      return;
    } else if (
      !state.name ||
      !state.account ||
      !state.pass_string ||
      !state.confirmedPhrase
      // (!state.businessNo && !state.businessName)
    ) {
      return;
    } else if (!state.businessNo && !state.businessName) {
      return;
    } else {
      const params = {};
      let adjusted_business_no;
      if (state.businessNo) {
        if (state.businessNo.length !== 8 || !IS_NUMBER.test(state.businessNo)) {
          setState((state) => ({ ...state, businessNo: "" }));
        }else{
          adjusted_business_no = state.businessNo
        }
      }

      params.name = state.name;
      params.account = state.account;
      params.parkId = state.induParkId || "other";
      params.company = state.businessName;
      params.password = state.pass_string;
      params.businessId = adjusted_business_no || "";

      Swal.fire({
        text: `確認送出?`,
        confirmButtonText: "確認",
      }).then((res) => {
        if (res.value) {
          dispatch(singUp(params));
          history.push("/");
        }
      });
    }
  };
  useEffect(() => {
    if (state.isInputBusinessNumber) {
      setState((state) => ({ ...state, businessName: "" }));
    } else {
      setState((state) => ({ ...state, businessNo: "" }));
    }
  }, [state.isInputBusinessNumber])

  const resetState = () => {
    setState((state) => ({
      ...state,
      account: "",
      pass_string: "",
      confirmedPhrase: "",
      name: "",
      induParkId: "",
      businessNo: "",
      businessName: "",
      isPasswordVisible: false,
      remember: false,
      isInputBusinessNumber: true,
      isAgreePrivacy: false,
      clicked: false,
      isActive: false,
    }));
  };

  React.useEffect(() => {
    if (!state.businessName && state.businessNo) {
      if (state.businessNo.length !== 8 || !IS_NUMBER.test(state.businessNo)) {
        setState((state) => ({ ...state, is_company_info_pass: true }));
      }else {
        setState((state) => ({ ...state, is_company_info_pass: false }));
      }
    } else if (state.businessName && !state.businessNo) {
      setState((state) => ({ ...state, is_company_info_pass: false }));
    } else if (
      state.businessName &&
      state.businessNo
    ) {
      setState((state) => ({ ...state, is_company_info_pass: false }));
    } else {
      setState((state) => ({ ...state, is_company_info_pass: true }));
    }
  }, [state.businessName, state.businessNo]);

  React.useEffect(() => {
    const {
      account,
      pass_string,
      confirmedPhrase,
      name,
      businessNo,
      businessName,
      isAgreePrivacy,
    } = state;
    if (
      account &&
      pass_string &&
      confirmedPhrase &&
      name &&
      (businessNo || businessName) &&
      isAgreePrivacy
    ) {
      setState((state) => ({ ...state, isActive: true }));
    } else {
      setState((state) => ({ ...state, isActive: false }));
    }
  }, [
    state.account,
    state.pass_string,
    state.confirmedPhrase,
    state.name,
    state.induParkId,
    state.businessNo,
    state.businessName,
    state.isAgreePrivacy,
  ]);

  const inputRef = React.useRef(null);
  React.useEffect(()=>{
      if(!isModalOpen) {
        resetState();
        if (status === 'sign-up') {
          setTimeout(() => {
            document.getElementById("signup").focus();
        } , 500)
        }
      }
      if (isModalOpen && inputRef.current) {
          inputRef.current.focus();
      }
  }, [isModalOpen])

  if (isHidden) {
    return <div />;
  }

  return (
    <SignUpWrapper>
      <SignUpTitleContainer>
        <SignUpWrapperTitleHeadText>註冊</SignUpWrapperTitleHeadText>
        <SignUpWrapperText>
          已經註冊，
          <button onClick={directToSignIn}>登入</button>
        </SignUpWrapperText>
      </SignUpTitleContainer>
      <SignUpInputContainer>
        <Inputs
          label="E-mail(帳號，必填)"
          variant="outlined"
          size="small"
          placeholder="請輸入E-mail，例:example@mail.com。"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Icon>email</Icon>
              </InputAdornment>
            ),
          }}
          name="account"
          value={state.account}
          inputRef={inputRef}
          onChange={handleChange}
        />
        <Verification
          isOpen={
            state.clicked && !IS_EMAIL.test(state.account) && state.account
          }
          text="E-mail格式錯誤"
        />
        <Verification
          isOpen={state.clicked && !state.account}
          text="請輸入E-mail"
        />
        <Inputs
          label="密碼(必填)"
          variant="outlined"
          size="small"
          type={state.isPasswordVisible ? "text" : "password"}
          placeholder="請輸入密碼。"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">  
                <Icon>vpn_key</Icon>
              </InputAdornment>
            ),
            endAdornment: (
              <button title={`${state.isPasswordVisible? "關閉" : "開啟"}顯示密碼文字`} onClick={handleVisiblePassword}>
                <span class="material-icons" style={{
                    verticalAlign: "middle" 
                }} title={`${state.isPasswordVisible? "關閉" : "開啟"}顯示密碼文字`}>visibility</span>
              </button>
            ),
          }}
          name="pass_string"
          value={state.pass_string}
          onChange={handleChange}
        />
        <PasswordHint>密碼長度必須在 12 到 20 之間，需包含一個小寫字母、一個大寫字母、一個數字和一個符號(!@#$%^&*)</PasswordHint>
        <Verification
          isOpen={
            state.clicked && !IS_PASS_PHRASE.test(state.pass_string) && state.pass_string
          }
          text="密碼不符合格式"
        />
        <Verification
          isOpen={state.clicked && !state.pass_string}
          text="請輸入密碼"
        />
        <Inputs
          label="密碼確認(必填)"
          variant="outlined"
          size="small"
          type={state.isPasswordVisible ? "text" : "password"}
          placeholder="請再次輸入密碼。"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Icon>vpn_key</Icon>
              </InputAdornment>
            ),
            endAdornment: (
              <button title={`${state.isPasswordVisible? "關閉" : "開啟"}顯示密碼文字`} onClick={handleVisiblePassword}>
                <span class="material-icons" style={{
                    verticalAlign: "middle" 
                }} title={`${state.isPasswordVisible? "關閉" : "開啟"}顯示密碼文字`}>visibility</span>
              </button>
            ),
          }}
          name="confirmedPhrase"
          value={state.confirmedPhrase}
          onChange={handleChange}
        />
        <Verification
          isOpen={state.clicked && state.pass_string !== state.confirmedPhrase}
          text="與密碼不符"
        />
        <Verification
          isOpen={state.clicked && !state.confirmedPhrase}
          text="請輸入密碼確認"
        />
        <Inputs
          label="姓名(必填)"
          variant="outlined"
          size="small"
          type={"text"}
          placeholder="請輸入姓名，例:王大明。"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Icon>person_pin</Icon>
              </InputAdornment>
            ),
          }}
          name="name"
          value={state.name}
          onChange={handleChange}
        />
        <Verification isOpen={state.clicked && !state.name} text="請輸入姓名" />
        <Inputs
          select
          label="所屬產業園區"
          variant="outlined"
          size="small"
          placeholder="請輸入姓名，例:王大明。"
          displayEmpty
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Icon>construction</Icon>
              </InputAdornment>
            ),
          }}
          name="induParkId"
          value={state.induParkId ? state.induParkId : "no_value"}
          onChange={handleChange}
        >
          <SignUpMenuItem value="no_value">請選擇</SignUpMenuItem>
          {parks.map((park) => (
            <SignUpMenuItem key={park.ParkId} value={park.ParkId}>
              {park.Name_CH}
            </SignUpMenuItem>
          ))}
          <SignUpMenuItem value="other">其他</SignUpMenuItem>
        </Inputs>
        <CompanyInfoTypeContainer>
          <CompanyInfoType
            name={"isInputBusinessNumber"}
            isChecked={state.isInputBusinessNumber}
            text={"單位統編"}
            handleCheck={handleChange}
          />

          <CompanyInfoType
            name={"isInputBusinessNumber"}
            isChecked={!state.isInputBusinessNumber}
            text={"單位名稱"}
            handleCheck={handleChange}
          />
        </CompanyInfoTypeContainer>
        <Inputs
          isHidden={!state.isInputBusinessNumber}
          label="單位統編"
          variant="outlined"
          size="small"
          type={"text"}
          placeholder="請輸入服務單位統編，例:00011203"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Icon>apartment</Icon>
              </InputAdornment>
            ),
          }}
          name="businessNo"
          value={state.businessNo}
          onChange={handleChange}
        />
        <Inputs
          isHidden={state.isInputBusinessNumber}
          label="單位名稱"
          variant="outlined"
          size="small"
          type={"text"}
          placeholder="請輸入服務單位全名，例:中國鋼鐵股份有限公司。"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Icon>apartment</Icon>
              </InputAdornment>
            ),
          }}
          name="businessName"
          value={state.businessName}
          onChange={handleChange}
        />
        <Verification
          isOpen={state.clicked && state.isInputBusinessNumber && !state.businessNo}
          text="請輸入單位統編"
        />
        <Verification
          isOpen={state.clicked && state.isInputBusinessNumber && !IS_NUMBER.test(state.businessNo)}
          text="統一編號只能為數字"
        />
        <Verification
          isOpen={state.clicked && state.isInputBusinessNumber && state.businessNo.length !== 8}
          text="統一編號必需為8位數字"
        />
        <Verification
          isOpen={state.clicked && !state.isInputBusinessNumber && !state.businessName}
          text="請輸入單位名稱"
        />
        <RemindContainer>
          <RemindContainerText>
            [提示] 單位統編與名稱至少填寫一項。
          </RemindContainerText>
        </RemindContainer>
      </SignUpInputContainer>
      <AgreePrivacyContainer>
        <FormControlLabel
          className="remember-me"
          label={
            <label for="isAgreePrivacy">
              確認已瀏覽並同意
              <button
                title="另開新視窗"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  const origin = window.location.origin;
                  window.open(origin + "/terms", "_blank");
                }}
              >
                個資聲明
              </button>
            </label>
          }
          control={
            <Checkboxs
              id="isAgreePrivacy"
              checked={state.isAgreePrivacy}
              onClick={handleChange}
              name="isAgreePrivacy"
            />
          }
        />
      </AgreePrivacyContainer>
      <ButtonContainer>
        <Buttons
          active={state.isActive}
          onClick={state.isActive ? handleSingUp : () => {}}
        >
          註冊
        </Buttons>
      </ButtonContainer>
      <HintBox mt={2}>
        <p>註冊時遇到問題？請聯絡<a href="mailto:meer@mail.mirdc.org.tw" target="_blank" rel="noopener noreferrer">客服信箱</a></p>
      </HintBox>
    </SignUpWrapper>
  );
};

const CompanyInfoType = ({ name, text, isChecked, handleCheck }) => {
  return (
    <FormControlLabel
      name={name}
      label={<label>{text}</label>}
      control={
        <Radios onChange={handleCheck} checked={isChecked} name={name} />
      }
    />
  );
};

const SignUpWrapper = styled.div``;

const SignUpTitleContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

const SignUpWrapperTitleHeadText = styled.h2`
  color: ${theme.secondaryBlack};
  font-size: 2.25rem;
  font-family: ${theme.primaryFont} !important;
  font-weight: 600;
`;

const SignUpWrapperText = styled.p`
  color: ${theme.secondaryBlack};
  font-family: ${theme.primaryFont} !important;
  > button {
    color: var(--main-color);
    transition: 0.5s;
    &:hover {
      transition: 0.5s;
      cursor: pointer;
      color: #757575;
    }
  }
`;

const SignUpInputContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin: 10px 0px;
  overflow-y: scroll;
  height: auto;
`;

const InputsSelect = styled(Select)`
  display: ${(p) => (p.isHidden ? "none" : "")} !important;
  margin: 10px 0px !important;
  height: 40px !important;
  .MuiSelect-root {
    color: var(--main-color);
  }

  .MuiOutlinedInput-notchedOutline {
    .MuiOutlinedInput-root.Mui-focused {
      border-color: var(--main-color) !important;
    }
  }
`;

const Inputs = styled(TextField)`
  display: ${(p) => (p.isHidden ? "none" : "")} !important;
  margin: 10px 0px !important;
  &:has(input:focus){ 
    background-color: #edf0f6;
  }
  .MuiFormLabel-root.Mui-focused {
    color: var(--main-color);
  }
  .MuiInputBase-root.Mui-focused {
    .MuiOutlinedInput-notchedOutline {
      border-color: var(--main-color);
    }
  }
`;
const PasswordHint = styled.span`
  color: ${theme.secondaryBlack};
  font-size: 0.75rem;
  margin-bottom: 12px;
`;

const Checkboxs = styled(Checkbox)`
  .MuiIconButton-label {
    color: var(--main-color);
  }
`;

const Buttons = styled(Button)`
  width: 100%;
  height: 40px !important;
  border-radius: 10px !important;
  background-color: ${(p) =>
    p.active ? theme.linkBlue : theme.disabledGray} !important;
  font-size: 1rem !important;
  font-weight: 500 !important;
  color: ${theme.white} !important;
  transition: 0.5s;

  &:hover {
    cursor: ${(p) => (p.active ? "pointer" : "not-allowed")};
    transition: 0.5s;
    background-color: ${(p) => (p.active ? "pointer" : "not-allowed")};
  }
`;

const ButtonContainer = styled.div``;

const ViewIcon = styled(Icon)`
  cursor: pointer;
`;

const SignUpMenuItem = styled(MenuItem)`
  z-index: 10020;
`;

const CompanyInfoTypeContainer = styled.div``;

const Radios = styled(Radio)`
  .MuiIconButton-label {
    color: var(--main-color);
  }
`;

const RemindContainer = styled.div`
  padding: 5px 0px;
`;

const RemindContainerText = styled.p`
  color: ${theme.secondaryBlack};
  font-family: ${theme.primaryFont} !important;
`;

const AgreePrivacyContainer = styled.div`
  padding: 5px 0px;
  .MuiFormControlLabel-root {
    .MuiTypography-root {
      > label {
        color: #757575;
        > button {
          color: ${theme.linkBlue};

          &:hover {
            cursor: pointer;
            color: ${theme.linkHoveredBlue};
          }
        }
      }
    }
  }
`;

const HintBox = styled(Box)`
  color: ${theme.secondaryBlack};
  font-size: 0.875rem;
  text-align: center;
  a {
    color: ${theme.primaryColor};
    text-decoration: underline;
    &:hover {
      opacity: 0.8;
    }
  }
`

export default SignUp;
