import React from "react";
import styled from "styled-components";
import Collapse from "@material-ui/core/Collapse";

const Verification = ({isOpen, text}) => {
    return (
        <VerificationCollapse in={isOpen}>
            <VerificationTextContainer>
                <VerificationTex>{text}</VerificationTex>
            </VerificationTextContainer>
        </VerificationCollapse>
    )
};

const VerificationCollapse = styled(Collapse)`
    min-height: auto !important;
`;

const VerificationTextContainer = styled.div`
    padding: 5px 0px;
`;

const VerificationTex = styled.p`
    font-size: 0.625rem;
    color:red;
`;

export default Verification;