import React from "react";
import { Card } from "react-bootstrap";
import { useSelector } from "react-redux";

import CustomizedCard from "../../../components/CustomizedCard/CustomizedCardV3";
import ForeignTrades4CodeProductInfo from "../../../components/CustomizedTable/FactoryInfo/ForeignTrades4CodeProduct";
import FactoryImportExportRating from "../../../components/CustomizedTable/FactoryInfo/FactoryImportExportRating";
import FactoryImportExport from "../../../components/CustomizedTable/FactoryInfo/FactoryImportExport";

const ImportExportInfo = () => {
  const {
    getForeignTradesCompareData,
    getForeignTradesCompareDataLoading,
    historyTradeInfo,
    historyTradeInfoLoading,
    ForeignTrades4CodeProduct,
    ForeignTrades4CodeProductLoading
  } = useSelector((state) => state.factory);

  return (
    <React.Fragment>
      <Card >
        <CustomizedCard
            title="出口貨品號別"
            referenceType={"foreignTrades4CodeProduct"}
            loading={ForeignTrades4CodeProductLoading}
        >
          <ForeignTrades4CodeProductInfo
            headerList={["貨品號", "描述"]}
            data={ForeignTrades4CodeProduct.filter(i=> i.TradeType === "Export")}
          />
        </CustomizedCard>
      </Card>
      <Card >
        <CustomizedCard
            title="進口貨品號別"
            referenceType={"foreignTrades4CodeProduct"}
            loading={ForeignTrades4CodeProductLoading}
        >
          <ForeignTrades4CodeProductInfo
            headerList={["貨品號", "描述"]}
            data={ForeignTrades4CodeProduct.filter(i=> i.TradeType === "Import")}
          />
        </CustomizedCard>
      </Card>
      <Card>
        <CustomizedCard
          title="進出口級距資料"
          referenceType={"factoryInfoTradings"}
          loading={
            getForeignTradesCompareDataLoading || historyTradeInfoLoading
          }
        >
          <FactoryImportExport
            data={historyTradeInfo}
            getForeignTradesCompareData={getForeignTradesCompareData}
            headerList={["年度", "進口級距", "出口級距"]}
          />
          <FactoryImportExportRating />
        </CustomizedCard>
      </Card>
    </React.Fragment>
  );
};

export default React.memo(ImportExportInfo);
