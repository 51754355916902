import * as actionTypes from "../actionTypes/supplychain";

import { fetchSupplyChainMapData, fetchSupplyChainItem } from "../../utility/supplyChianApi";

export const startGetMapDataData = () => {
    return {
        type:actionTypes.START_GET_SUPPLYCHAIN_MAP_DATA
    }
  };
  
  export const getMapDataSuccess = data => {
    return {
        type:actionTypes.GET_SUPPLYCHAIN_MAP_DATA_SUCCESS,
        mapData: data,
    }
  };
  
  export const getMapDataFail = err => {
    return {
        type:actionTypes.GET_SUPPLYCHAIN_MAP_DATA_FAIL,
        err:err
    }
};

export const getMapDataHandler = (params) => {
    return dispatch => {
        dispatch(startGetMapDataData());
        fetchSupplyChainMapData(params)
            .then(res=>{
                if(res){
                    dispatch(getMapDataSuccess(res))
                }else{
                    dispatch(getMapDataFail("取得概況全國與地區排名時發生問題。"))
                }
            })
            .catch(err=>{
                dispatch(getMapDataFail(err))
            })
    }  
}

export const startGetOptions = () => {
    return {
        type:actionTypes.START_GET_SUPPLYCHAIN_OPTIONS
    }
  };
  
  export const getOptionsSuccess = data => {
    return {
        type:actionTypes.GET_SUPPLYCHAIN_OPTIONS_SUCCESS,
        data: data.data,
    }
  };
  
  export const getOptionsFail = err => {
    return {
        type:actionTypes.GET_SUPPLYCHAIN_OPTIONS_FAIL,
        err:err
    }
};

export const getOptionsHandler = () => {
    return dispatch => {
        dispatch(startGetOptions());
        fetchSupplyChainItem()
            .then(res=>{
                if(res){
                    dispatch(getOptionsSuccess(res))
                }else{
                    dispatch(getOptionsFail("取得概況全國與地區排名時發生問題。"))
                }
            })
            .catch(err=>{
                dispatch(getOptionsFail(err))
            })
    }  
}
  