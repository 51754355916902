const base = `APP_`;

export const START_GET_REFERENCE_INFO = `${base}START_GET_REFERENCE_INFO`;
export const GET_REFERENCE_INFO_SUCCESS = `${base}GET_REFERENCE_INFO_SUCCESS`;
export const GET_REFERENCE_INFO_FAIL = `${base}GET_REFERENCE_INFO_FAIL`;

export const START_GET_SEARCH_HISTORY = `${base}START_GET_SEARCH_HISTORY`;
export const GET_SEARCH_HISTORY_SUCCESS = `${base}GET_SEARCH_HISTORY_SUCCESS`;
export const GET_SEARCH_HISTORY_FAIL = `${base}GET_SEARCH_HISTORY_FAIL`;

export const START_GET_VERIFIED_COMPANY_LIST = `${base}START_GET_VERIFIED_COMPANY_LIST`;
export const GET_VERIFIED_COMPANY_LIST_SUCCESS = `${base}GET_VERIFIED_COMPANY_LIST_SUCCESS`;
export const GET_VERIFIED_COMPANY_LIST_FAIL = `${base}GET_VERIFIED_COMPANY_LIST_FAIL`;

export const START_FETCH_CURRENT_VERSION = `START_FETCH_CURRENT_VERSION`;
export const FETCH_CURRENT_VERSION_SUCCESS = `FETCH_CURRENT_VERSION_SUCCESS`;
export const FETCH_CURRENT_VERSION_FAIL = `FETCH_CURRENT_VERSION_FAIL`;