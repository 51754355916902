const base = `OPERATIONS_DASHBOARD_`;

//出口級距
export const START_GET_OPERATION_EXPORT_DATA = `${base}START_GET_OPERATION_EXPORT_DATA`;
export const GET_OPERATION_EXPORT_DATA_SUCCESS = `${base}GET_OPERATION_EXPORT_DATA_SUCCESS`;
export const GET_OPERATION_EXPORT_DATA_FAIL = `${base}GET_OPERATION_EXPORT_DATA_FAIL`;

//每月營收
export const START_GET_OPERATION_MONTH_DATA = `${base}START_GET_OPERATION_MONTH_DATA`;
export const GET_OPERATION_MONTH_DATA_SUCCESS = `${base}GET_OPERATION_MONTH_DATA_SUCCESS`;
export const GET_OPERATION_MONTH_DATA_FAIL = `${base}GET_OPERATION_MONTH_DATA_FAIL`;