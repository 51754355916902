import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import Link from '@material-ui/core/Link'
import Skeleton from "@material-ui/lab/Skeleton";
import { CellMeasurer } from "react-virtualized";

import * as theme from "../../assets/theme";
import { thousandDivider } from "../../utility/common";

const Items = ({
  address,
  area,
  businessNo,
  capital,
  city,
  induFourStr,
  induPark,
  name,
  owner,
  product,
  stockType,
  loading,
  key,
  index,
  style,
  cache,
  parent
}) => {
  const area_str_parse = (area) => {
    switch (area) {
      case "North":
        return "北部";
      case "Center":
        return "中部";
      case "South":
        return "南部";
      case "East":
        return "東部";
      default:
        return "";
    }
  };

  const handleOpenFactoryPage = () => {
    const url = window.location.origin;
    window.open(`${url}/factory_info/basic_info/${businessNo}`, "_blank");
  };

  const handleOpenIndustryPage = () => {
    const url = window.location.origin;
    if (induFourStr) {
      window.open(
        `${url}/industry_index/${induFourStr.substring(0, 4)}`,
        "_blank"
      );
    }
  };

  const handleOpenGoogleMapPage = () => {
    window.open(`https://maps.google.com/?q=${address}`, "_blank");
  };

  return (
    <CellMeasurer key={key}  parent={parent} cache={cache} columnIndex={0} rowIndex={index}>
     <div style={style}>
     <ItemWrapper>
         <React.Fragment>
           <TagContainer className="effected_dashboard_list_item">
             <CityTag is_show={city}>{city}</CityTag>
             <AreaTag is_show={area_str_parse(area)}>
               {area_str_parse(area)}
             </AreaTag>
             <InduParkTag is_show={induPark}>
               {induPark ? induPark : "區外"}
             </InduParkTag>
           </TagContainer>
           <CompanyNameContainer>
             <CompanyNameContainerText target="_blank" href={`/factory_info/basic_info/${businessNo}`}>{name}</CompanyNameContainerText>
           </CompanyNameContainer>
           <ContentContainer>
             {/* <ContentContainerRowItem title={"負責人"} val={owner} /> */}
             <ContentContainerRowItem
               title={"工廠地址"}
               val={address}
               handler={handleOpenGoogleMapPage}
             />
             {/* <ContentContainerRowItem
               title={"公司統編"}
               val={businessNo}
               handler={handleOpenFactoryPage}
             /> */}
             <ContentContainerRowItem
               title={"產業別四碼"}
               val={induFourStr}
               handler={handleOpenIndustryPage}
             />
             <ContentContainerRowItem title={"工廠主要產品"} val={product} />
             <ContentContainerRowItem
               title={"公司股票狀態"}
               val={stockType}
             />
           </ContentContainer>
           <CapitalContainer>
             <CapitalText>
               公司資本額：<span>{thousandDivider(capital)}</span>
             </CapitalText>
           </CapitalContainer>
         </React.Fragment>
     </ItemWrapper>
   </div>
    </CellMeasurer>
  );
};

const ContentContainerRowItem = ({ title, val, handler }) => {
  const { innerWidth } = useSelector((state) => state.app);


  return (
    <ContentContainerRow>
      <ContentContainerText width={innerWidth > 750 ? "20%" : "40%"}>{title}</ContentContainerText>：
      <ContentContainerText
        is_clickable={handler}
        onClick={handler ? handler : () => {}}
        width={innerWidth > 750 ? "80%" : "60%"}
      >
        {val?.length > 30 ? val.substring(0, 30) + "..." : val}
      </ContentContainerText>
    </ContentContainerRow>
  );
};

const ItemWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: ${theme.white};
  border-radius: 20px;
  margin-bottom: 14px;
  padding: 15px;
  padding-bottom: 28px;
  box-shadow: 0px 0px 20px -5px rgba(0, 0, 0, 0.2);
  @media (max-width: 750px) {
    padding-bottom: 70px;
  }
`;

const ContentContainer = styled.div``;

const ContentContainerRow = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 4px;
`;

const ContentContainerText = styled.p`
  text-decoration: ${(p) => (p.is_clickable ? "underline" : "none")};
  width: ${(p) => p.width};
  cursor: ${(p) => (p.is_clickable ? "pointer" : "none")};
  line-height: 20px;
`;

const TagContainer = styled.div`
  display: flex;
  padding: 5px 0px;
`;

const CompanyNameContainer = styled.div`
  padding-bottom: 10px;
`;

const Tag = styled.div`
  padding: 8px;
  color: ${theme.white};
  margin: 0px 5px;
  border-radius: 10px;
  display: ${(p) => !p.is_show && "none"};
  font-size: 0.875rem;

  @media (max-width: 750px) {
    padding: 5px;
    margin: 0px 2.5px;
    border-radius: 5px;
    font-size: 1rem;
  }
`;

const CityTag = styled(Tag)`
  background-color: #eadc13;
`;

const InduParkTag = styled(Tag)`
  background-color: #d345c3;
`;

const AreaTag = styled(Tag)`
  background-color: #1373ea;
`;

const CompanyNameContainerText = styled(Link)`
  font-size: 1.75rem;
  font-weight: 600;
  text-decoration: underline !important;
  color: black !important;
  &:hover {
    color: #384757 !important;
  }
`;

const CapitalContainer = styled.div`
  position: absolute;
  right: 10px;
  bottom: 10px;
`;

const CapitalText = styled.h3`
  > span {
    font-size: 1.75rem;
    font-weight: 600;
  }
`;

export default React.memo(Items);
