import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
// import TableHead from "@material-ui/core/TableHead";
import TableRow from '@material-ui/core/TableRow';
// import TablePagination from "@material-ui/core/TablePagination";
// import Icon from "@material-ui/core/Icon";
import { useSelector } from 'react-redux';

import { thousandDivider, stopTypeSwitcher } from '../../../utility/common';

import './styles.scss';

const FactoryBasicInfoTable = (props) => {
  const { data, loading, getCompanyAbstractData } = props;
  const { innerWidth } = useSelector((state) => state.app);
  const [state, setState] = React.useState({
    endIndex: 0,
    page: 0,
    rowsPerPage: 5,
    styleKey: {},
    styleValue: {},
  });
  const url = window.location.origin;

  const openNewTabs = (link) => window.open(link, '_blank');

  // const styleKey = innerWidth < 1024 ? {width:"40%", fontWeight: 600} : {width:"30%", fontWeight: 600};
  // const styleValue = innerWidth < 1024 ? {width:"60%", borderBottom: "none"} : {display: "flex"};

  React.useEffect(() => {
    if (innerWidth < 1024) {
      setState((state) => ({
        ...state,
        styleKey: { width: '40%', fontWeight: 400 },
        styleValue: { width: '60%', borderBottom: 'none' },
      }));
    } else {
      setState((state) => ({
        ...state,
        styleKey: { width: '30%', fontWeight: 400 },
        styleValue: { display: 'flex', flexWrap: 'wrap' },
      }));
    }
  }, [innerWidth]);

  if (data && getCompanyAbstractData) {
    return (
      <TableContainer className="table-container">
        {data ? (
          <Table className="table" style={{ tableLayout: 'fixed' }}>
            <TableBody className="table-body">
              <React.Fragment>
                <TableRow hover className="table-body-row">
                  <TableCell
                    align="left"
                    style={{
                      ...state.styleKey,
                    }}
                  >
                    中類
                  </TableCell>
                  <TableCell align="left" style={state.styleValue}>
                    {data.IndustryTwoTypes && data.IndustryTwoTypes.length > 0
                      ? data.IndustryTwoTypes.map((item, index) => <p>{item}{index !== data.IndustryTwoTypes.length - 1 ? "、" : ""}</p>)
                      : <span
                      style={{
                        color: '#757575',
                      }}
                    >
                      本項目無資料
                    </span>}
                  </TableCell>
                </TableRow>
                <TableRow hover className="table-body-row">
                  <TableCell
                    align="left"
                    style={{
                      ...state.styleKey,
                    }}
                  >
                    小類
                  </TableCell>
                  <TableCell align="left" style={state.styleValue}>
                    {data.IndustryThreeTypes && data.IndustryThreeTypes.length > 0
                      ? data.IndustryThreeTypes.map((item, index) => <p>{item}{index !== data.IndustryThreeTypes.length - 1 ? "、" : ""}</p>)
                      : (
                      <span
                        style={{
                          color: '#757575',
                        }}
                      >
                        本項目無資料
                      </span>
                    )}
                  </TableCell>
                </TableRow>
              </React.Fragment>
            </TableBody>
          </Table>
        ) : (
          <Table>
            <TableBody className="table-body-no-data">
              <TableRow hover className="table-body-row">
                <TableCell align="center" className="no-data">
                  本項目無資料
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        )}
      </TableContainer>
    );
  } else {
    return <div />;
  }
};

export default React.memo(FactoryBasicInfoTable);
