import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Map,
  TileLayer,
  Marker,
  Popup,
  Viewport,
  LayersControl,
  useLeaflet,
} from "react-leaflet";
import L from "leaflet";
import MarkerClusterGroup from "react-leaflet-markercluster";
import { useLocation } from "react-router-dom";

import * as mapActions from "../../store/actions/map";
import MaterialSearchPopUp from "./MaterialSearchPopUp";
import SupplyChainPopUp from "./SupplyChianPopUp";
import Spinner from "../Spinner/Spinner";
import GeoJson from "../GeoJson/GeoJson";
import * as Style from "./styles";
import "./styles.scss";

export default (props) => {
  const {
    data,
    selectedCompany,
    passShowedCompany,
    toggleDetailCard,
    closeDetialCard,
    selectedUid,
    mapType,
    clearSelectedCompany,
  } = props;
  const mapRef = useRef(null);
  // const groupRef = useRef(null)
  const [state, setState] = React.useState({
    markerList: [],
    bound: [],
    showedCompany: "",
    // selectedCompany
    popRefs: {},
  });
  const [bond, setBond] = React.useState();
  const [position, setPosition] = useState([23.6978, 120.9605]);
  const [MarkerList, setMarkerList] = useState(null);
  const { innerWidth } = useSelector((state) => state.app);
  const { loading } = useSelector((state) => state.map);
  const mapContext = useLeaflet();
  const { map } = mapContext; //get map instance
  const [markersRef, setMarkersRef] = React.useState({});
  const url = useLocation().pathname.split("/")[1];

  const dispatch = useDispatch();

  const markerOpen = (lat, lng) => {
    const markers = new L.MarkerClusterGroup();
    // 搜尋 markers 圖層下的子圖層
    markers.eachLayer((layer) => {
      // 抓取圖層的 經緯度
      // eslint-disable-next-line no-underscore-dangle
      const eachLat = layer._latlng.lat;
      // eslint-disable-next-line no-underscore-dangle
      const eachLng = layer._latlng.lng;
      // 如果與參數的經緯度相同，就抓取那個 layer
      if (eachLat === lat && eachLng === lng) {
        // zoomToShowLayer 這個是 MarkerClusterGroup 給的函式
        // 方法是調用 MarkerClusterGroup 下的子圖層
        // 打開 bindPopup 的 HTML
        markers.zoomToShowLayer(layer, () => layer.openPopup());
      }
    });
  };

  React.useEffect(() => {
    if (data.length > 0) {
      //set up markers && bounds
      if (mapType === "supplyChain") {
        const updatedMarkers = new Array();
        const updatedBounds = new Array();
        data.map((marker) => {
          if (marker.lat !== 0 && marker.lng !== 0) {
            const markerPoints = (
              <SupplyChainPopUp
                Latitude={marker.lat}
                Longitude={marker.lng}
                Uid={marker.BusinessNo}
                data={marker}
              />
            );
            updatedMarkers.push(markerPoints);
            updatedBounds.push([marker.lat, marker.lng]);

            setState((state) => ({
              ...state,
              markerList: updatedMarkers,
              bond: updatedBounds,
            }));
          }
        });
      } else {
        const updatedMarkers = new Array();
        const updatedBounds = new Array();
        data.map((marker) => {
          if (marker.Latitude !== 0 && marker.Longitude !== 0) {
            const showedCompanyHandler = (value) => {
              if (value !== state.showedCompany) {
                const selectedLocation = data.filter(
                  (maker) => maker.Uid === value
                );
                markerOpen(
                  selectedLocation[0].Latitude,
                  selectedLocation[0].Longitude
                );
                setState((state) => ({ ...state, showedCompany: value }));
              }
            };

            if (selectedCompany) {
              if (selectedCompany[0] !== 0 && selectedCompany[1] !== 0) {
                mapRef.current.leafletElement.closePopup();
                mapRef.current.leafletElement.flyTo(selectedCompany, 18);
              }
              clearSelectedCompany();
            }

            const markerPoints = (
              <MaterialSearchPopUp
                showedCompanyHandler={showedCompanyHandler}
                data={marker}
                updateMarkersRef={updateMarkersRef}
                toggleDetailCard={toggleDetailCard}
                closeDetialCard={closeDetialCard}
                savePopUpRef={(uid, ref) => {
                  savePopUpsHandler(uid, ref);
                }}
              />
            );

            updatedMarkers.push(markerPoints);
            updatedBounds.push([marker.Latitude, marker.Longitude]);

            setState((state) => ({
              ...state,
              markerList: updatedMarkers,
              bond: updatedBounds,
            }));
          }
        });
      }
    } else {
      setState((state) => ({
        ...state,
        markerList: [],
        bond: [
          [25.268576, 121.611722],
          [23.879299, 120.294881],
          [23.762836, 121.54409],
          [21.257621, 120.740482],
          [21.8998, 120.837252],
        ],
      }));
    }
  }, [data, selectedCompany, markersRef]);

  useEffect(() => {
    dispatch(mapActions.initMapSelection());
  }, []);

  React.useEffect(() => {
    if (state.markerList.length === 0) {
      setState((state) => ({
        ...state,
        bond: [
          [25.268576, 121.611722],
          [23.879299, 120.294881],
          [23.762836, 121.54409],
          [21.257621, 120.740482],
          [21.8998, 120.837252],
        ],
      }));
    }
  }, [state.markerList]);

  React.useEffect(() => {
    if (state.showedCompany) passShowedCompany(state.showedCompany);
  }, [state.showedCompany]);

  const updateMarkersRef = (uid, ref) => {
    setMarkersRef((markersRef) => ({ ...markersRef, [uid]: ref }));
  };

  const centerMapView = (e) => {
    const { leafletElement } = mapRef.current;
    leafletElement.setView(e.popup._latlng);
    const point = leafletElement.project(e.target._popup._latlng);

    if (innerWidth > 1024) {
      if (e) {
        //把原本預設的 y 軸 - 200 ， 這樣 popup 才會完整出現在畫面
        const updatePoint = {
          ...point,
          y: point.y - 200,
        };
        leafletElement.panTo(leafletElement.unproject(updatePoint), {
          animate: true,
        });
      }
    } else {
      if (e) {
        //把原本預設的 y 軸 - 200 ， 這樣 popup 才會完整出現在畫面
        const updatePoint = {
          ...point,
          y: point.y - 80,
          // x: point.x - 40,
        };
        leafletElement.panTo(leafletElement.unproject(updatePoint), {
          animate: true,
        });
      }
    }
  };

  const savePopUpsHandler = (uid, ref) => {
    const updatePopUpRef = state.popRefs;
    updatePopUpRef[uid] = ref;

    setState((state) => ({ ...state, popRefs: updatePopUpRef }));
  };

  // React.useEffect(()=>{
  //   state.popRefs[selectedUid].current.leafletElement.options.leaflet.map.closePopup()
  // }, [selectedUid])

  if (loading) {
    return (
      <div className="spinner">
        <Spinner />
      </div>
    );
  } else {
    return (
      <Style.Maps
        isChemical={url === "cmhleialmaricate"}
        ref={mapRef}
        bounds={
          state.markerList.length === 0
            ? [
                [25.268576, 121.611722],
                [23.879299, 120.294881],
                [23.762836, 121.54409],
                [21.257621, 120.740482],
                [21.8998, 120.837252],
              ]
            : state.bond
        }
        zoomControl={false}
        onPopupopen={centerMapView}
        className="map-background"
        animate={true}
        zoom={8}
      >
        <TileLayer
          attribution='&amp;copy <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <MarkerClusterGroup>{state.markerList}</MarkerClusterGroup>
        <GeoJson />
      </Style.Maps>
    );
  }
};
