import React from "react";
import styled from "styled-components";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import Tooltip from "@material-ui/core/Tooltip";
import judicial_logo from "../../assets/images/judicial_logo.png";

import "./styles.scss";

const VerdictTable = (props) => {
  const { data, reVerdictId, headerList } = props;
  const [state, setState] = React.useState({
    endIndex: 0,
    page: 0,
    rowsPerPage: 5,
    dataTranslate : []
  });

  const handleChangePage = (event, newPage) => {
    setState((state) => ({ ...state, page: newPage }));
  };
  
  const openNewTabs = (link) => window.open(link, "_blank");

  return (
    <TableContainer className="table-container">
      <Table className="table">
        <TableHead className="table-header">
          {headerList && (
            <TableRow className="table-row">
              {headerList.map((headerItem) => (
                <TableCell className="table-cell" align="left">
                  {headerItem}
                </TableCell>
              ))}
            </TableRow>
          )}
        </TableHead>
        <TableBody className="table-body">
          {data
            .slice(
              state.page * state.rowsPerPage,
              state.page * state.rowsPerPage + state.rowsPerPage
            )
            .map((row, index) => (
              <TableRow
                key={row.VerdictId + row.VerdictDate + index}
                hover
                className="table-body-row"
                style={index % 2 !== 0 ? { backgroundColor: "#ececec" } : {}}
              >
                <CustomTableCell align="left" onClick={() =>openNewTabs( row.VerdictLink )}>
                    {reVerdictId[index] ? reVerdictId[index]  : "無相關資料"}
                    <Tooltip title="點擊觀看判決資訊詳細頁面">
                      <a href={row.VerdictLink} target="_blank" rel="noopener noreferrer">
                        <div style={{ width: "80px", marginLeft: "12px" }}>
                          <img alt="判決資訊" src={judicial_logo} style={{ maxWidth: "100%"}} />
                        </div>
                      </a>
                    </Tooltip>
                </CustomTableCell>
                <TableCell align="left" style={{ width: "15%" }}>
                  {row.VerdictDate ? row.VerdictDate.replaceAll("T00:00:00", "") : "無相關資料"}
                </TableCell>
                <TableCell align="left" style={{ width: "40%" }}>
                  {row.VerdictTitle ? row.VerdictTitle : "無相關資料"}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      {data.length === 0 && (
        <Table>
          <TableBody className="table-body-no-data">
            <TableRow hover className="table-body-row">
              <TableCell align="center" className="no-data">
                本項目無資料
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      )}
      {data.length > 5 && (
        <TablePagination
          rowsPerPageOptions={[]}
          component="div"
          count={data.length}
          rowsPerPage={state.rowsPerPage}
          page={state.page}
          onChangePage={handleChangePage}
        />
      )}
    </TableContainer>
  );
};

const CustomTableCell = styled(TableCell)`
  display: flex !important;
`;

export default React.memo(VerdictTable);
