import Firebase from "firebase/app";
import "firebase/auth";

const FirebaseCredentials = {
  apiKey: "AIzaSyCAl41DyaKyc6q2ZcsTqy5_RB28TRIfW4w",
  authDomain: "mirdc-system.firebaseapp.com",
  projectId: "mirdc-system",
};
// if a Firebase instance doesn't exist, create one
if (!Firebase.apps.length) {
  Firebase.initializeApp(FirebaseCredentials);
}

export default Firebase;