import axios from "axios";
import { baseUrl, loginbaseUrl } from "../constants/endpoints";

const headers = {
  "Access-Control-Allow-Origin": "*",
  "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
  "Access-Control-Allow-Headers":
    "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers",
  "Content-Type": "application/json",
  Authorization: localStorage.getItem("acToken"),
};

export const fetchVerifiedCompanyList = (userId) => {
  const data = new FormData(); 
  data.append("UserId", userId);


  return axios({
    proxy: true,
    method: "post",
    url: `${loginbaseUrl}/api/CompanyUpload/CheckUpLoadCompanyStatus`,
    data: data,
  })
    .then((res) => {
      try {
        return res.data;
      } catch (e) {
        return [];
      }
    })
    .catch((err) => {
      return "error";
    });
};

export const fetchCurrentVersion = () => {
  const data = new FormData(); 
  data.append("Site", "smartpark");

  return axios({
    proxy: true,
    method: "post",
    url: `https://indupark.smartpark.com.tw/api/Version/GetDeployVersion`,
    data: data,
  })
    .then((res) => {
      try {
        return res.data;
      } catch (e) {
        return [];
      }
    })
    .catch((err) => {
      return "error";
    });
}

