import React, { useState, useEffect } from "react";
import { TextField, withStyles } from "@material-ui/core";
import { Autocomplete } from '@material-ui/lab/';

import variables from "../../assests/styles/_variables.scss";

const CssAutoComplete = withStyles({
    root: {
        '& .MuiFormControl-root':{
           '& label':{
               width: '100%',
           }
        },
        '& .MuiInputBase-root':{
            backgroundColor:'#ECECEC',
            height:'20vh',
            width: window.innerWidth > 1023 ? '900px' : '100%',
            display:'flex',
            justifyContent:'flex-start',
            alignItems:'center',
            '& .MuiOutlinedInput-notchedOutline':{
                border: 'none',
            }    
            // width:'50%',


        },
        '& .MuiAutocomplete-paper': {
            fontFamily: 'Microsoft JhengHei !important',
            backgroundColor:'#ECECEF !important'
        },
        '& label.Mui-focused': {
            color: variables["main-color"],
        },
        '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
            border: 'none !important',
        }
    }
})(Autocomplete)


const CustomizedAutoComplete = props => {
    const { placeholder, onChange, disabled, value, option } = props
    const [autoInput, setAutoInput] = useState()
    const [completeValue, setCompleteValue] = useState()


    useEffect(()=>{
        setCompleteValue(value)
    },[value])


    const inputHandler = event => {
        const { value } = event.target
        onChange(value)
    }

    useEffect(()=>{
        if(autoInput){
            onChange(autoInput)
        }
    }, [autoInput])
    

    return(
        <CssAutoComplete
            className={props.className}
            id={placeholder}
            freeSolo
            disableClearable
            options={option}
            disabled={disabled}
            value={completeValue}
            inputValue={completeValue}
            renderOption={option => {
                return (
                    <React.Fragment>
                        <span>{option.name}</span>
                    </React.Fragment>
                )
            }}
            onKeyDown={e=>{ console.log(e.target) }}
            getOptionLabel={(option) => { 
                if (option.name) {
                    if(option.name === "您的需求已轉介相關窗口，後續將由該窗口為您服務。窗口聯絡資訊：" || option.name === "您的案件正在積極聯繫與辦理中，若有任何疑問請致電："){
                        setAutoInput(option.name)
                        return option.name
                    }else{
                        return null
                    }
                } else {
                    return option
                }
            } }

            // onInputChange={}
            renderInput={params => (
                <TextField
                    value={completeValue}
                    style={{ fontFamily: 'Microsoft JhengHei !important', width:"100%" }}
                    onChange={inputHandler}
                    disabled={disabled}
                    {...params}
                    label={placeholder}
                    margin="normal"
                    variant="outlined"
                    multiline
                />
            )}
        />
    )
}

export default CustomizedAutoComplete