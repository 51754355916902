import React from "react";
import Icon from "@material-ui/core/Icon";
import Select from "@material-ui/core/Select";

import { cityCode as areaOptions } from "../../constants/areas";

export default props => {
    const { handleClose, handlePassValue } = props;
    const [state, setState] = React.useState({
        typeList: [
            { value: "area", name: "地區" },
            { value: "induPark", name: "單一園區" },
        ],
        type: "area", //area, induPark
        childrenOptions: [],
        passValue: "",
        passName: ""
    })

    React.useEffect(() => {
        const { type } = state;
        if (type === "area") {
            setState(state => ({ ...state, childrenOptions: areaOptions }))
        } else if (type === "induPark") {
            setState(state => ({ ...state, childrenOptions: [] }))
        }
    }, [state.type])

    const handleTypeChange = (e) => {
        const { value, name } = e.target;
        setState(state => ({ ...state, [name]: value }))
    }

    const handleChangeChildren = (e) => {
        const { value, name } = e.target;
        setState(state => ({ ...state, [name]: value, passName: name }))
    }


    const passValueHandler = () => {
        const { passValue, passName } = state;
        handlePassValue({
            name: "city",
            value: passValue
        })
        handleClose();
    }


    return (
        <div className="nesting-selection">
            <div className="nesting-selection-header"><Icon className="icon" onClick={handleClose}>close</Icon></div>
            <div className="nesting-selection-content">
                <Select
                    native
                    value={state.type}
                    onChange={(e) => handleTypeChange(e)}
                    name="type"
                    // style={{width:"200px"}}
                    className="dashboard-header-selection"
                >
                    {state.typeList.map(option => <option key={option.value} value={option.value}>{option.name}</option>)}
                </Select>
                <Select
                    native
                    value={state.passValue}
                    onChange={(e) => handleChangeChildren(e)}
                    name="passValue"
                    // style={{width:"200px"}}
                    className="dashboard-header-selection"
                >
                    {state.childrenOptions.map(option => <option key={option.CityId} value={option.CityId}>{option.CityName}</option>)}
                </Select>
            </div>
            <div className="nesting-selection-buttons">
                <p onClick={passValueHandler}>確定</p>
                <p onClick={handleClose}>取消</p>
            </div>
        </div>
    )
}