import React from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { Map, TileLayer } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-markercluster";
import Collapse from "@mui/material/Collapse";
import Button from "@mui/material/Button";
import MoreHoriz from "@mui/icons-material/MoreHoriz";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import DnsIcon from "@mui/icons-material/Dns";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Swal from "sweetalert2";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import SwiperCore, { A11y, Navigation, Autoplay } from "swiper";

import EffectedEventResultList from "../../components/EffectedEventResultList";
import Spinner from "../../components/Spinner/Spinner";
import PopUp from "./CustomPopUp";
import SearchContainer from "./SearchContainer";
import MapView from "./MapView";
import OverAllView from "./OverallView";
import {
  handleFetchEffectedEventsData,
  handleSearchEffectedEventsData,
  handleResetState,
  resetState,
} from "../../store/actions/effected_events";
import * as theme from "../../assets/theme";
import "swiper/swiper.scss";
import "../../assests/styles/_swiper-navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/scrollbar/scrollbar.scss";
import effected_dashboard_descrip_img from "../../assets/images/effected_dashboard_descrip_img.png";

SwiperCore.use([A11y, Navigation, Autoplay]);

const themeColor = createTheme({
  palette: {
    primary: {
      main: "#25758A",
    },
  },
});

const EffectedEventDashboard = () => {
  const mapRef = React.createRef(null);
  const { innerWidth } = useSelector((state) => state.app);
  const dispatch = useDispatch();
  const {
    effected_events_list_loading,
    effected_events_list,
    effected_events_result_list_loading,
    effected_events_result_list,
  } = useSelector((state) => state.effected_events);
  const [state, setState] = React.useState({
    loading: true,
    marker_list: [],
    bond: [],
    event_id_option: "",
    industry_code_option: [],
    product_code_option: [],
    event_id: "",
    event_name: "",
    industry_code: [],
    product_import_code: [],
    product_export_code: [],
    filtered_event_list: [],
    is_search_console_open: true,
    is_clicked: false,
    is_mobile_console_open: true,
    is_declined: false,
    selected_tag_list: [],
    result_type: "all_companies",
    result_options: [],
    result_sub_type: "no_result_sub_type",
    display_tab: 0,
    is_head_descrip_open: false,
    mobile_result_list_view: "mobile_list_view",
    should_open_description: false,
    is_show_description: false,
  });

  const handle_toggle_mobile_list_view = (e, val) =>
    setState((state) => ({ ...state, mobile_result_list_view: val }));

  // const handle_toggle_is_head_descrip_open = () =>
  //   setState((state) => ({
  //     ...state,
  //     is_head_descrip_open: !state.is_head_descrip_open,
  //   }));

  const handle_change_display_tab = (e, val) =>
    setState((state) => ({ ...state, display_tab: val }));

  const handle_change_result_sub_type = (e) => {
    const { value } = e.target;
    setState((state) => ({ ...state, result_sub_type: value, display_tab: 0 })); //查詢完要切換回地圖
  };

  const handle_change_result_type = (e) => {
    const { value } = e.target;

    setState((state) => ({
      ...state,
      result_type: value,
      result_sub_type: "no_result_sub_type",
      display_tab: 0,
    }));
  };

  const handle_show_is_decline = () =>
    setState((state) => ({
      ...state,
      is_declined: !state.is_declined,
      display_tab: 0,
    })); //查詢完要切換回地圖

  const handle_change = (e) => {
    const { name, value } = e.target;
    //重新選擇受影響事件的時候要把手影響產業和受影響事件產品清空
    if (name === "event_id") {
      setState((state) => ({
        ...state,
        should_open_description: true,
        product_import_code: [],
        product_export_code: [],
        industry_code: [],
        // result_type: "all_companies",
        // result_sub_type: "no_result_sub_type",
      }));
    }

    if (Array.isArray(value) && value.length > 3) {
      Swal.fire({
        text: "選項不得大於3個",
      });
    } else {
      setState((state) => ({ ...state, [name]: value }));
    }
  };

  const handle_open_description = () => {
    setState((state) => ({ ...state, is_show_description: true }));
  };

  const handle_close_description = () => {
    setState((state) => ({ ...state, is_show_description: false }));
  };

  const handleFetchData = async () => {
    dispatch(resetState());
    setState((state) => ({
      ...state,
      is_clicked: true,
      result_type: "all_companies",
      result_sub_type: "no_result_sub_type",
      filtered_event_list: [],
      bond: [],
      marker_list: [],
    }));
    const { event_id, industry_code, product_import_code, product_export_code } = state;
    const file = new FormData();
    const update_industry_code = [];
    const update_product_export_code= [];
    const update_product_import_code = [];


    industry_code.forEach((i) => update_industry_code.push(i.split(" ")[0]));
    product_import_code.forEach((i) => update_product_import_code.push(i.split(" ")[0]));
    product_export_code.forEach((i) => update_product_export_code.push(i.split(" ")[0]));


    file.append("EventId", event_id);
    file.append("IndustryCode", update_industry_code.join(","));
    file.append("ImportProductFourCode", update_product_import_code.join(","));
    file.append("ProductFourCode", update_product_export_code.join(","));


    handleToggleMobileConsole();
    handleToggleSearchConsole();
    setState((state) => ({ ...state, display_tab: 0, 
      event_name:effected_events_list.filter(
        (i) => i.EventId === event_id
      )[0].EventName 
    }));
    await dispatch(handleSearchEffectedEventsData(file));
    if (state.should_open_description) {
      handle_open_description();
      setState((state) => ({ ...state, should_open_description: false }));
    }
  };

  const handleToggleSearchConsole = () =>
    setState((state) => ({
      ...state,
      is_search_console_open: !state.is_search_console_open,
    }));

  const handleRestAllStates = () => {
    dispatch(handleResetState());
    setState((state) => ({
      ...state,
      marker_list: [],
      bond: [],
      event_id_option: "",
      industry_code_option: [],
      product_code_option: [],
      event_id: "",
      industry_code: [],
      product_import_code: [],
      product_export_code: [],
    }));
  };

  const handleToggleMobileConsole = () =>
    setState((state) => ({
      ...state,
      is_mobile_console_open: !state.is_mobile_console_open,
    }));

  const handleOpenDescrip = () => {
    Swal.fire({
      text: "平台自動搜集產業關鍵指標數據，如各廠、園區、海關等資料。發展受重要事件衝擊之產業歸納分析引擎。並根據國際/內事件發生提供受事件儀表板服務。協助園區及廠商即時掌握國內產業鍊影響範圍，及早規劃相關因應對策、減緩產業衝擊。",
    });
  };

  const handleAdjustPopUpPosition = (e) => {
    let adjustYaxis;
    adjustYaxis = 200;
    const { leafletElement } = mapRef.current;
    if (e) {
      leafletElement.setView(e.popup._latlng);
      const point = leafletElement.project(e.target._popup._latlng);
      //把原本預設的 y 軸 - 200 ， 這樣 popup 才會完整出現在畫面
      const updatePoint = {
        ...point,
        y: point.y - adjustYaxis,
        // x: point.x - 1000,
      };
      leafletElement.panTo(leafletElement.unproject(updatePoint), {
        animate: true,
      });
    }
  };

  React.useEffect(() => {
    const el = document.querySelector(".effected_dashboard_list_item");
    if (effected_events_result_list_loading && !el) {
      setState((state) => ({ ...state, loading: true }));
    } else {
      setState((state) => ({ ...state, loading: false }));
    }
  }, [effected_events_result_list_loading]);

  React.useEffect(() => {
    //取得受事件影響
    dispatch(handleFetchEffectedEventsData());
  }, []);

  const { search } = useLocation();
  const query = React.useMemo(() => new URLSearchParams(search), [search]);
  React.useEffect(() => {
    //initially set some value;
    const handle_init = async () => {
      if (effected_events_list.length > 0) {
        let EventId = effected_events_list[0].EventId
        if (query.get("event") && 
          effected_events_list.some(ele => ele.EventId === parseInt(query.get("event")))) {
          EventId = parseInt(query.get("event"))
        }

        await setState((state) => ({
          ...state,
          event_id: EventId,
          event_name: effected_events_list.filter(
            (i) => i.EventId === EventId
          )[0].EventName,
        }));

        const file = new FormData();

        file.append("EventId", EventId);
        file.append("IndustryCode", "");
        file.append("ProductFourCode", "");
    
        handleToggleMobileConsole();
        handleToggleSearchConsole();
        await dispatch(handleSearchEffectedEventsData(file));
        handle_open_description();
      }
    };
    handle_init();
  }, [effected_events_list]);

  React.useEffect(() => {
    const { product_import_code, product_export_code, industry_code, event_id } = state;

    const update_selected_tag = [];

    if (product_import_code.length > 0) {
      product_import_code.forEach((i) => update_selected_tag.push(i));
    }

    if (product_export_code.length > 0) {
      product_export_code.forEach((i) => update_selected_tag.push(i));
    }

    if (industry_code.length > 0) {
      industry_code.forEach((i) => update_selected_tag.push(i));
    }

    if (event_id) {
      const event_name = effected_events_list.filter(
        (i) => i.EventId === event_id
      )[0].EventName;
      update_selected_tag.push(event_name);
    }

    setState((state) => ({ ...state, selected_tag_list: update_selected_tag }));
  }, [
    state.product_import_code,
    state.product_export_code,
    state.industry_code,
    state.event_id,
    effected_events_list,
  ]);

  React.useEffect(() => {
    if (effected_events_result_list.length !== 0) {
      const update_marker_list = [];
      const update_bonds = [];
      let result_list = effected_events_result_list;
      if (state.is_declined) {
        if (state.result_type === "sort_by_park" && state.result_sub_type !== "no_result_sub_type") {
          result_list = effected_events_result_list
            .filter((i) => i.induPark === state.result_sub_type)
            .filter((i) => i.isDecline);
        } else if (state.result_type === "sort_by_city"  && state.result_sub_type !== "no_result_sub_type") {
          result_list = effected_events_result_list
            .filter((i) => i.city === state.result_sub_type)
            .filter((i) => i.isDecline);
        } else if (state.result_type === "sort_by_area"  && state.result_sub_type !== "no_result_sub_type") {
          result_list = effected_events_result_list
            .filter((i) => i.area === state.result_sub_type)
            .filter((i) => i.isDecline);
        } else {
          result_list = effected_events_result_list.filter((i) => i.isDecline);
        }
      } else {
        if (state.result_type === "sort_by_park"  && state.result_sub_type !== "no_result_sub_type") {
          result_list = effected_events_result_list.filter(
            (i) => i.induPark === state.result_sub_type
          );
        } else if (state.result_type === "sort_by_city"  && state.result_sub_type !== "no_result_sub_type") {
          result_list = effected_events_result_list.filter(
            (i) => i.city === state.result_sub_type
          );
        } else if (state.result_type === "sort_by_area"  && state.result_sub_type !== "no_result_sub_type") {
          result_list = effected_events_result_list.filter(
            (i) => i.area === state.result_sub_type
          );
        } else {
          result_list = effected_events_result_list;
        }
      }

      result_list.forEach((i) => {
        update_bonds.push([i.latitude, i.longitude]);
        update_marker_list.push({
          latitude: i.latitude,
          longitude: i.longitude,
          company_name: i.name,
          owner: i.owner,
          address: i.address,
          business_no: i.businessNo,
          indu_four_str: i.induFourStr,
          product: i.product,
          stock_type: i.stockType,
        });
      });

      setState((state) => ({
        ...state,
        filtered_event_list: result_list,
        bond: update_bonds,
        marker_list: update_marker_list,
      }));
    } else {
      setState((state) => ({
        ...state,
        bond: [],
        marker_list: [],
      }));
    }
  }, [
    effected_events_result_list_loading,
    effected_events_result_list,
    // state.is_declined,
    state.result_type,
    state.result_sub_type,
    state.sort_by_indu_park,
    state.sort_by_city,
    state.sort_by_area,
  ]);

  if (innerWidth < 750) {
    return (
      <MobileDashboardWrapper>
        <DescriptionModal
          is_show={state.is_show_description}
          event={
            effected_events_list.filter(
              (i) => i.EventId === state.event_id
            )[0] || "請選擇受影響事件"
          }
          handle_close_description={handle_close_description}
          is_loading={state.loading}
        />
        <MapContainers>
          {effected_events_list_loading ? (
            <Loading />
          ) : (
            <Maps
              ref={mapRef}
              bounds={
                state.marker_list.length === 0
                  ? [
                      [25.268576, 121.611722],
                      [23.879299, 120.294881],
                      [23.762836, 121.54409],
                      [21.257621, 120.740482],
                      [21.8998, 120.837252],
                    ]
                  : state.bond
              }
              zoomControl={false}
              animate={true}
              zoom={8}
              onPopupopen={handleAdjustPopUpPosition}
            >
              <TileLayer
                attribution='&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              <MarkerClusterGroup>
                {state.marker_list.map((i) => (
                  <PopUp
                    latitude={i.latitude}
                    longitude={i.longitude}
                    company_name={i.company_name}
                    owner={i.owner}
                    address={i.address}
                    business_no={i.business_no}
                    indu_four_str={i.indu_four_str}
                    product={i.product}
                    stock_type={i.stock_type}
                  />
                ))}
              </MarkerClusterGroup>
            </Maps>
          )}
        </MapContainers>
        <MobileConsole is_open={state.is_mobile_console_open}>
          <MobileMoreHorizonTab>
            <MoreHoriz onClick={handleToggleMobileConsole} />
          </MobileMoreHorizonTab>
          <InputsAndResultHead>
            <InputsAndResultHeadLeftContainer>
              <InputsAndResultHeadText>
                {state.event_name}{state.event_name !== "" && "詳細資料"}
              </InputsAndResultHeadText>
              <PriorityHighIcons onClick={handleOpenDescrip} />
            </InputsAndResultHeadLeftContainer>
            <ToggleButtonGroup
              exclusive
              value={state.mobile_result_list_view}
              size="small"
              onChange={handle_toggle_mobile_list_view}
            >
              <ToggleButton value="mobile_list_view">
                <LocationCityIcon />
              </ToggleButton>
              ,
              <ToggleButton value="mobile_overview">
                <DnsIcon />
              </ToggleButton>
            </ToggleButtonGroup>
          </InputsAndResultHead>
          <SearchContainer
            handle_change={handle_change}
            event_id={state.event_id}
            industry_code={state.industry_code}
            product_export_code={state.product_export_code}
            product_import_code={state.product_import_code}
            is_declined={state.is_declined}
            handle_show_is_decline={handle_show_is_decline}
            handleFetchData={handleFetchData}
            handleRestAllStates={handleRestAllStates}
            selected_tag_list={state.selected_tag_list}
            handle_toggle_console={handleToggleSearchConsole}
            parent_is_open_console={state.is_search_console_open}
          />
          {state.mobile_result_list_view === "mobile_list_view" ? (
            <EffectedEventResultList
              is_search_console_open={state.is_search_console_open}
              data={state.filtered_event_list.sort((a,b) => b.capital - a.capital)}
              is_clicked={state.is_clicked}
              loading={effected_events_result_list_loading}
              result_type={state.result_type}
              result_sub_type={state.result_sub_type}
              result_options={state.result_options}
              handle_change_result_type={handle_change_result_type}
              handle_change_result_sub_type={handle_change_result_sub_type}
            />
          ) : (
            <OverAllView
              is_show_key={state.display_tab}
              effected_events_result_list={effected_events_result_list}
              is_search_console_open={state.is_search_console_open}
              description={
                effected_events_list.filter(
                  (i) => i.EventId === state.event_id
                )[0]?.Description || "請選擇受影響事件"
              }
            />
          )}
        </MobileConsole>
      </MobileDashboardWrapper>
    );
  }

  return (
    <DashboardWrapper>
      <DescriptionModal
        is_show={state.is_show_description}
        event={
          effected_events_list.filter((i) => i.EventId === state.event_id)[0] ||
          "請選擇受影響事件"
        }
        handle_close_description={handle_close_description}
        is_loading={state.loading}
      />
      <LoadingView loading={state.loading}>
        <Spinner />
      </LoadingView>
      <InputsAndResultContainer>
        <InputsAndResultHead>
          <InputsAndResultHeadContainer>
            <InputsAndResultHeadText> {state.event_name}{state.event_name !== "" && "詳細資料"}</InputsAndResultHeadText>
            {/* {state.is_head_descrip_open ? (
              <InputsAndResultSubHeadText
                onClick={handle_toggle_is_head_descrip_open}
              >
                較少內容
              </InputsAndResultSubHeadText>
            ) : (
              <InputsAndResultSubHeadText
                onClick={handle_toggle_is_head_descrip_open}
              >
                更多內容
              </InputsAndResultSubHeadText>
            )} */}
          </InputsAndResultHeadContainer>
          {/* <Collapse in={state.is_head_descrip_open}>
            <InputsAndResultText>
              平台自動搜集產業關鍵指標數據，如各廠、
              園區、海關等資料。發展受重要事件衝擊之產業歸納分析引擎。並根據國際/內事件發生提供受事件儀表板服務。協助園區及廠商即時掌握國內產業鍊影響範圍，及早規劃相關因應對策、減緩產業衝擊。
            </InputsAndResultText>
          </Collapse> */}
        </InputsAndResultHead>
        <SearchContainer
          handle_change={handle_change}
          event_id={state.event_id}
          industry_code={state.industry_code}
          product_export_code={state.product_export_code}
          product_import_code={state.product_import_code}
          is_declined={state.is_declined}
          handle_show_is_decline={handle_show_is_decline}
          handleFetchData={handleFetchData}
          handleRestAllStates={handleRestAllStates}
          selected_tag_list={state.selected_tag_list}
          handle_toggle_console={handleToggleSearchConsole}
          parent_is_open_console={state.is_search_console_open}
        />
        <EffectedEventResultList
          is_declined={state.is_declined}
          effected_events_result_list={effected_events_result_list}
          is_search_console_open={state.is_search_console_open}
          data={state.filtered_event_list.sort((a,b) => b.capital - a.capital)}
          is_clicked={state.is_clicked}
          loading={effected_events_result_list_loading}
          result_type={state.result_type}
          result_sub_type={state.result_sub_type}
          result_options={state.result_options}
          handle_change_result_type={handle_change_result_type}
          handle_change_result_sub_type={handle_change_result_sub_type}
        />
      </InputsAndResultContainer>
      <Box>
        <Box>
          <Tabs
            value={state.display_tab}
            onChange={handle_change_display_tab}
            sx={{ maxWidth: "100%" }}
          >
            <StyledTab label="地圖" />
            <StyledTab label="總覽" />
          </Tabs>
        </Box>
        <MapView
          is_show_key={state.display_tab}
          effected_events_list_loading={effected_events_list_loading}
          mapRef={mapRef}
          marker_list={state.marker_list}
          bond={state.bond}
        />
        <OverAllView
          is_show_key={state.display_tab}
          effected_events_result_list={effected_events_result_list}
          is_search_console_open={state.is_search_console_open}
          description={
            effected_events_list.filter((i) => i.EventId === state.event_id)[0]
              ?.Description || "請選擇受影響事件"
          }
        />
      </Box>
    </DashboardWrapper>
  );
};

const DescriptionModal = ({
  is_show,
  event,
  handle_close_description,
  is_loading,
}) => {
  return (
    <DescriptionModalWrapper is_show={is_show}>
      <DescriptionModalContent is_show={is_show}>
        <DescriptionModalContentImg
          src={effected_dashboard_descrip_img}
          alt={"描述圖片"}
        />
        <DescriptionCloseRow></DescriptionCloseRow>
        <DescriptionHeadRow>
          <h1>{event.EventName}</h1>
        </DescriptionHeadRow>
        <DescriptionContentRow>
          <p>{event.Description}</p>
        </DescriptionContentRow>
        <DescriptionButtonRow>
          <DescriptionLoadingButton onClick={handle_close_description}>
            {is_loading ? "資料讀取中 ..." : "了解"}
          </DescriptionLoadingButton>
        </DescriptionButtonRow>
      </DescriptionModalContent>
    </DescriptionModalWrapper>
  );
};

const DescriptionModalWrapper = styled.div`
  position: absolute;
  left: 0px;
  top: ${(p) => (p.is_show ? "0px" : "-150vh")};
  width: 100vw;
  height: calc(100vh - 114px);
  background-color: rgba(0, 0, 0, 0);
  z-index: 50000;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: top 1s ease-in;

  @media (max-width: 750px) {
    height: calc(100vh - 70px);
  }
`;

const DescriptionModalContent = styled.div`
  width: 600px;
  min-height: 250px;
  border-radius: 10px;
  background-color: #ffffff;
  transform: translateY(-110px);
  overflow: hidden;
  box-shadow: 0px 0px 20px -5px rgba(0, 0, 0, 0.2);

  @media (max-width: 750px) {
    transform: translateY(-50px);
    width: 95%;
    margin: 0px 2.5%;
  }
`;

const DescriptionModalContentImg = styled.img`
  position: absolute;
  left: -10px;
  bottom: -50px;
  opacity: 0.2;
  width: 300px;
  @media (max-width: 750px) {
    left: -50px;
    bottom: -70px;
  }
`;

const DescriptionCloseRow = styled.div`
  width: 80%;
  margin: 0px 10%;
  padding: 10px 0px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const DescriptionHeadRow = styled(DescriptionCloseRow)`
  justify-content: center;
  > h1 {
    font-size: 2rem;
    font-weight: 600;
    
  }
`;

const DescriptionContentRow = styled(DescriptionCloseRow)`
  justify-content: flex-start;
  > p {
    font-size: 1.15rem;
    font-weight: 400;
    line-height: 30px;
    @media (max-width: 750px) {
      font-size: 1rem;
      font-weight: 400;
      line-height: 30px;
    }
  }
`;

const DescriptionButtonRow = styled(DescriptionCloseRow)`
  justify-content: center;
  padding-bottom: 40px;
`;

const DescriptionLoadingButton = styled(Button)`
  background-color: #25758A !important;
  color: #ffffff !important;
`;

const Loading = () => (
  <LoadingWrapper>
    <Spinner />
  </LoadingWrapper>
);

const LoadingWrapper = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 40px;
  @media (max-width: 750px) {
    border-radius: 0px;
  }
`;

const PriorityHighIcons = styled(PriorityHighIcon)`
  color: ${theme.white};
  font-size: 0.125rem;
  border: 1px solid #ececec;
  background-color: #000000;
  border-radius: 20px;
  transform: translateX(5px);
`;

const MobileDashboardWrapper = styled.div`
  width: 100vw;
  height: calc(100vh - 70px);
  position: fixed;
  top: 70px;
  background-color: ${theme.background_color};
`;

const MobileMoreHorizonTab = styled.div`
  height: 5vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const MobileConsole = styled.div`
  width: 100vw;
  height: 85vh;
  position: absolute;
  bottom: ${(p) => (p.is_open ? "0px" : "-67vh")};
  z-index: 10;
  background-color: ${theme.background_color};
  transition: 0.3s bottom ease-in;
`;

const DashboardWrapper = styled.div`
  width: 100vw;
  height: calc(100vh - 114px);
  position: fixed;
  top: 114px;
  display: grid;
  grid-template-columns: 55% 1fr;
  background-color: ${theme.background_color};
`;

const LoadingView = styled.div`
  display: ${(p) => (p.loading ? "flex" : "none")};
  width: 100vw;
  height: 100vh;
  position: absolute;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 10000;
`;

const InputsAndResultContainer = styled.div`
  padding: 10px 10px 30px 30px;
`;

const InputsAndResultHead = styled.div`
  padding: 0px 0px 20px 0px;
  margin: 0px;

  @media (max-width: 750px) {
    margin: 0px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

const InputsAndResultHeadLeftContainer = styled.div`
  margin: 0px 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const InputsAndResultHeadContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
`;

const InputsAndResultHeadText = styled.h2`
  font-size: 1.75rem;
  font-weight: 600;
  padding: 5px 0px;

  @media (max-width: 750px) {
    font-size: 1.35rem;
  }
`;

const InputsAndResultSubHeadText = styled.h2`
  font-size: 1rem;
  padding: 5px 0px 5px 10px;
  text-decoration: underline;
  cursor: pointer;

  @media (max-width: 750px) {
    font-size: 0.75rem;
  }
`;

const InputsAndResultText = styled.h2`
  font-size: 1rem;
  padding: 5px 0px;
  line-height: 20px; ;
`;

const MapContainers = styled.div`
  padding: 10px 30px 30px 10px;
  overflow: hidden;
  height: 85vh;

  @media (max-width: 750px) {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: calc(100vh - 70px);
    padding: 0px;
    z-index: 0;
  }
`;

const Maps = styled(Map)`
  width: 100%;
  height: 100%;
  border-radius: 40px;
  @media (max-width: 750px) {
    width: 100vw;
    height: calc(100vh - 70px);
    padding: 0px;
    border-radius: 0px;
  }
`;

const EffectedEventDashboardWithTheme = () => {
  return (
    <ThemeProvider theme={themeColor}>
      <EffectedEventDashboard />
    </ThemeProvider>
  );
};

const StyledTab = styled(Tab)`
  padding: 0px !important;
`;

export default EffectedEventDashboardWithTheme;
