import React from "react";

import Footer from "../../components/Footer/Footer";

import * as Style from "./styles";

const FollowIndustry = () => {
  const uid = localStorage.getItem("uid");
  return (
    <Style.NotificationContainer>
      <Style.NotificationHeaderContainer>
        <Style.NotificationHeader>關注產業訂閱</Style.NotificationHeader>
        <Style.NotificationSubHeader>
          設定欲關注的產業，平台定期自動推播產業動態至email信箱
        </Style.NotificationSubHeader>
      </Style.NotificationHeaderContainer>
      <Style.NotificationContentContainer>
        <Style.NotificationIframe
          url={`https://indupark.smartpark.com.tw/pushnotify/SubscribeNews/Industry/${uid}`}
        />
        {/* <Footer /> */}
      </Style.NotificationContentContainer>
    </Style.NotificationContainer>
  );
};

export default FollowIndustry;
