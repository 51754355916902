import React from "react";
import { useSelector } from "react-redux";
import MobileGrid from "../../MobileGridV2";
import * as Style from "../../styles";
import { Card } from "react-bootstrap";
import { thousandDivider } from "../../../../utility/common";
import Swal from "sweetalert2";
import styled from "styled-components";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const GovProBidding = ({ data, referenceType }) => {
  const { referenceInfo } = useSelector((state) => state.app);
  const toggleCard = {};
  const openReferenceInfoAlert = (type) => {
    let content = "";
    if (referenceInfo[type] === "") {
      console.log("I was called!");
      Swal.fire({
        title: "引用資料來源",
        text: "資料正在建置中 ... ",
      });
    } else {
      try {
        referenceInfo[type]
          .split("。")
          .map(
            (referenceItem) =>
              (content +=
                "<p style='text-align:left'>" + referenceItem + "</p>")
          );
        Swal.fire({
          title: "引用資料來源",
          html: content,
        });
      } catch (err) {
        Swal.fire({
          title: "引用資料來源",
          text: "資料正在建置中 ... ",
        });
      }
    }
  };

  return (
    <div>
      <Card.Header className="card-title">
        <TradeMarker>
            {"政府標案"}
            {toggleCard && referenceType && ( // 如果有 toggleCard 就顯示（位置差異）
              <button
                title={`開啟${"政府標案"}引用資料來源彈出視窗`}
                style={{ color: "white", borderRadius: "100%", marginLeft: "4px" }}
                onClick={() => openReferenceInfoAlert(referenceType)}  
              > 
                <FontAwesomeIcon
                  className="icon-reference"
                  icon={faInfoCircle}
                />
              </button>
            )}
        </TradeMarker>
      </Card.Header>
      <MobileGrid>
        {data.map((item, index) => (
          <Style.MobileGridContainerItem
            item
            component={Style.MobileGridItem}
            key={item.BusinessNo + index}
          >
            <Style.MobileGridItemList>
              <Style.MobileGridItemListLabel>招標廠商 </Style.MobileGridItemListLabel>
              <Style.MobileGridItemListText>
                {item.Org_Name ? item.Org_Name : "無相關資料"}
              </Style.MobileGridItemListText>
            </Style.MobileGridItemList>
            <Style.MobileGridItemList className="patent-name">
              <Style.MobileGridItemListLabel>採購類型 </Style.MobileGridItemListLabel>
              <Style.MobileGridItemListText>
                {item.ProcurementType
                  ? item.ProcurementType.replaceAll("T00:00:00", "")
                  : "無相關資料"}
              </Style.MobileGridItemListText>
            </Style.MobileGridItemList>
            <Style.MobileGridItemList>
              <Style.MobileGridItemListLabel>招標方式 </Style.MobileGridItemListLabel>
              <Style.MobileGridItemListText>
                {item.TenderAwardWay
                  ? item.TenderAwardWay
                  : "無相關資料"}
              </Style.MobileGridItemListText>
            </Style.MobileGridItemList>
            <Style.MobileGridItemList>
              <Style.MobileGridItemListLabel>標案價格 </Style.MobileGridItemListLabel>
              <Style.MobileGridItemListText>

                {item.TenderAwardPrice !== 0
                  ? thousandDivider(item.TenderAwardPrice)
                  : "無相關資料"}
              </Style.MobileGridItemListText>
            </Style.MobileGridItemList>
            <Style.MobileGridItemList>
              <Style.MobileGridItemListLabel>決標公開時間 </Style.MobileGridItemListLabel>
              <Style.MobileGridItemListText>
                {item.AwardNoticeDate
                  ? item.AwardNoticeDate.split("T")[0]
                  : "無相關資料"}
              </Style.MobileGridItemListText>
            </Style.MobileGridItemList>
            <Style.MobileGridItemList>
              <Style.MobileGridItemListLabel>標案類型 </Style.MobileGridItemListLabel>
              <Style.MobileGridItemListText>
                {item.TenderName
                  ? item.TenderName
                  : "無相關資料"}
              </Style.MobileGridItemListText>
            </Style.MobileGridItemList>
          </Style.MobileGridContainerItem>
        ))}
      </MobileGrid>
    </div>
  );
};

const TradeMarker = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
`;

export default React.memo(GovProBidding);
