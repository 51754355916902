const base = `PARK_DASHBOARD_`;

//取得儀表板資訊
export const START_GET_SECTION_MAP = "START_GET_SECTION_MAP";
export const GET_SECTION_MAP_SUCCESS = "GET_SECTION_MAP_SUCCESS";
export const GET_SECTION_MAP_FAIL = "GET_SECTION_MAP_FAIL";

//修改儀表板資訊
export const START_UPDATE_SECTION_MAP = "START_UPDATE_SECTION_MAP";
export const UPDATE_SECTION_MAP_SUCCESS = "UPDATE_SECTION_MAP_SUCCESS";
export const UPDATE_SECTION_MAP_FAIL = "UPDATE_SECTION_MAP_FAIL";

// 取得同服務中心的園區
export const START_GET_SERVICE_CENTER_PARK_LINK_DATA = `${base}START_GET_SERVICE_CENTER_PARK_LINK_DATA`;
export const GET_SERVICE_CENTER_PARK_LINK_DATA_SUCCESS = `${base}GET_SERVICE_CENTER_PARK_LINK_DATA_SUCCESS`;
export const GET_SERVICE_CENTER_PARK_LINK_DATA_FAIL = `${base}GET_SERVICE_CENTER_PARK_LINK_DATA_FAIL`;

// 取得概況全國與地區排名
export const START_GET_BRIEF_AND_RANKING_DATA = `${base}START_GET_BRIEF_AND_RANKING_DATA`;
export const GET_BRIEF_AND_RANKING_DATA_SUCCESS = `${base}GET_BRIEF_AND_RANKING_DATA_SUCCESS`;
export const GET_BRIEF_AND_RANKING_DATA_FAIL = `${base}GET_BRIEF_AND_RANKING_DATA_FAIL`;

// 取得縣市租用土地資料
export const START_GET_FACTORY_AREA_RENT_DATA = `${base}START_GET_FACTORY_AREA_RENT_DATA`;
export const GET_FACTORY_AREA_RENT_DATA_SUCCESS = `${base}GET_FACTORY_AREA_RENT_DATA_SUCCESS`;
export const GET_FACTORY_AREA_RENT_DATA_FAIL = `${base}GET_FACTORY_AREA_RENT_DATA_FAIL`;

//取得縣市廠商產業別二碼家數佔比
export const START_GET_MAIN_INDEX_DATA = `${base}START_GET_MAIN_INDEX_DATA`;
export const GET_MAIN_INDEX_DATA_SUCCESS = `${base}GET_MAIN_INDEX_DATA_SUCCESS`;
export const GET_MAIN_INDEX_DATA_FAIL = `${base}GET_MAIN_INDEX_DATA_FAIL`;

//取得單一園區 主要指標
export const START_GET_SECTION_INDEX_DATA = `${base}START_GET_SECTION_INDEX_DATA`;
export const GET_SECTION_INDEX_DATA_SUCCESS = `${base}GET_SECTION_INDEX_DATA_SUCCESS`;
export const GET_SECTION_INDEX_DATA_FAIL = `${base}GET_SECTION_INDEX_DATA_FAIL`;

//取得單一園區 新聞標籤
export const START_GET_SECTION_NEWS_TAG_DATA = `${base}START_GET_SECTION_NEWS_TAG_DATA`;
export const GET_SECTION_NEWS_TAG_DATA_SUCCESS = `${base}GET_SECTION_NEWS_TAG_DATA_SUCCESS`;
export const GET_SECTION_NEWS_TAG_DATA_FAIL = `${base}GET_SECTION_NEWS_TAG_DATA_FAIL`;

//取得單一園區 新聞內容
export const START_GET_SECTION_NEWS_CONTENT_DATA = `${base}START_GET_SECTION_NEWS_CONTENT_DATA`;
export const GET_SECTION_NEWS_CONTENT_SUCCESS = `${base}GET_SECTION_NEWS_CONTENT_SUCCESS`;
export const GET_SECTION_NEWS_CONTENT_FAIL = `${base}GET_SECTION_NEWS_CONTENT_FAIL`;

//取得單一園區 公開發行占比
export const START_GET_SECTION_PUBLIC_SHARING_DATA = `${base}START_GET_SECTION_PUBLIC_SHARING_DATA`;
export const GET_SECTION_PUBLIC_SHARING_SUCCESS = `${base}GET_SECTION_PUBLIC_SHARING_SUCCESS`;
export const GET_SECTION_PUBLIC_SHARING_FAIL = `${base}GET_SECTION_PUBLIC_SHARING_FAIL`;

//取得單一園區 股票公開發行前十大廠商清單
export const START_GET_SECTION_PUBLIC_DATA = `${base}START_GET_SECTION_PUBLIC_DATA`;
export const GET_SECTION_PUBLIC_SUCCESS = `${base}GET_SECTION_PUBLIC_SUCCESS`;
export const GET_SECTION_PUBLIC_FAIL = `${base}GET_SECTION_PUBLIC_FAIL`;

//取得單一園區 股票非公開發行前十大廠商清單
export const START_GET_SECTION_NON_PUBLIC_DATA = `${base}START_GET_SECTION_NON_PUBLIC_DATA`;
export const GET_SECTION_NON_PUBLIC_SUCCESS = `${base}GET_SECTION_NON_PUBLIC_SUCCESS`;
export const GET_SECTION_NON_PUBLIC_FAIL = `${base}GET_SECTION_NON_PUBLIC_FAIL`;

//取得單一園區 綠色工廠前十大廠商
export const START_GET_SECTION_PUBLIC_TOP_TEN_GREEN_DATA = `${base}START_GET_SECTION_PUBLIC_TOP_TEN_GREEN_DATA`;
export const GET_SECTION_PUBLIC_TOP_TEN_GREEN_SUCCESS = `${base}GET_SECTION_PUBLIC_TOP_TEN_GREEN_SUCCESS`;
export const GET_SECTION_PUBLIC_TOP_TEN_GREEN_FAIL = `${base}GET_SECTION_PUBLIC_TOP_TEN_GREEN_FAIL`;

//取得單一園區 進口級距佔比-圓餅圖
export const START_GET_SECTION_IMPORT_PERCENTAGE_DATA = `${base}START_GET_SECTION_IMPORT_PERCENTAGE_DATA`;
export const GET_SECTION_IMPORT_PERCENTAGE_SUCCESS = `${base}GET_SECTION_IMPORT_PERCENTAGE_SUCCESS`;
export const GET_SECTION_IMPORT_PERCENTAGE_FAIL = `${base}GET_SECTION_IMPORT_PERCENTAGE_FAIL`;

//取得單一園區 出口級距佔比-圓餅圖
export const START_GET_SECTION_EXPORT_PERCENTAGE_DATA = `${base}START_GET_SECTION_EXPORT_PERCENTAGE_DATA`;
export const GET_SECTION_EXPORT_PERCENTAGE_SUCCESS = `${base}GET_SECTION_EXPORT_PERCENTAGE_SUCCESS`;
export const GET_SECTION_EXPORT_PERCENTAGE_FAIL = `${base}GET_SECTION_EXPORT_PERCENTAGE_FAIL`;


//取得單一園區 產業別2碼家數佔比-圓餅圖
export const START_GET_TWO_CODE_PERCENTAGE_DATA = `${base}START_GET_TWO_CODE_PERCENTAGE_DATA`;
export const GET_TWO_CODE_PERCENTAGE_SUCCESS = `${base}GET_TWO_CODE_PERCENTAGE_SUCCESS`;
export const GET_TWO_CODE_PERCENTAGE_FAIL = `${base}GET_TWO_CODE_PERCENTAGE_FAIL`;

//取得單一園區 產業別3碼家數佔比-圓餅圖
export const START_GET_THREE_CODE_PERCENTAGE_DATA = `${base}START_GET_THREE_CODE_PERCENTAGE_DATA`;
export const GET_THREE_CODE_PERCENTAGE_SUCCESS = `${base}GET_THREE_CODE_PERCENTAGE_SUCCESS`;
export const GET_THREE_CODE_PERCENTAGE_FAIL = `${base}GET_THREE_CODE_PERCENTAGE_FAIL`;

//取得單一園區 產業別4碼家數佔比-圓餅圖
export const START_GET_FOUR_CODE_PERCENTAGE_DATA = `${base}START_GET_FOUR_CODE_PERCENTAGE_DATA`;
export const GET_FOUR_CODE_PERCENTAGE_SUCCESS = `${base}GET_FOUR_CODE_PERCENTAGE_SUCCESS`;
export const GET_FOUR_CODE_PERCENTAGE_FAIL = `${base}GET_FOUR_CODE_PERCENTAGE_FAIL`;

//取得單一園區 資本額前十大廠商排行-長條圖
export const START_GET_SECTION_TOP_TEN_CAPITAL_DATA = `${base}START_GET_SECTION_TOP_TEN_CAPITAL_DATA`;
export const GET_SECTION_TOP_TEN_CAPITAL_SUCCESS = `${base}GET_SECTION_TOP_TEN_CAPITAL_SUCCESS`;
export const GET_SECTION_TOP_TEN_CAPITAL_FAIL = `${base}GET_SECTION_TOP_TEN_CAPITAL_FAIL`;

//取得單一園區 政府計劃前十大廠商
export const START_GET_SECTION_TOP_TEN_GOVERNMENT_PLAN_DATA = `${base}START_GET_SECTION_TOP_TEN_GOVERNMENT_PLAN_DATA`;
export const GET_SECTION_TOP_TEN_GOVERNMENT_PLAN_SUCCESS = `${base}GET_SECTION_TOP_TEN_GOVERNMENT_PLAN_SUCCESS`;
export const GET_SECTION_TOP_TEN_GOVERNMENT_PLAN_FAIL = `${base}GET_SECTION_TOP_TEN_GOVERNMENT_PLAN_FAIL`;

//取得單一園區 專利數前十大-長條圖
export const START_GET_SECTION_TOP_TEN_PATENT_DATA = `${base}START_GET_SECTION_TOP_TEN_PATENT_DATA`;
export const GET_SECTION_TOP_TEN_PATENT_SUCCESS = `${base}GET_SECTION_TOP_TEN_PATENT_SUCCESS`;
export const GET_SECTION_TOP_TEN_PATENT_FAIL = `${base}GET_SECTION_TOP_TEN_PATENT_FAIL`;

//取得單一園區 員工數前十大
export const START_GET_SECTION_TOP_TEN_EMP_DATA = `${base}START_GET_SECTION_TOP_TEN_EMP_DATA`;
export const GET_SECTION_TOP_TEN_EMP_SUCCESS = `${base}GET_SECTION_TOP_TEN_EMP_SUCCESS`;
export const GET_SECTION_TOP_TEN_EMP_FAIL = `${base}GET_SECTION_TOP_TEN_EMP_FAIL`;

//取得單一園區 政府計畫數類型佔比
export const START_GET_SECTION_GOV_PLAN_PERCENT_DATA = `${base}START_GET_SECTION_GOV_PLAN_PERCENT_DATA`;
export const GET_SECTION_GOV_PLAN_PERCENT_SUCCESS = `${base}GET_SECTION_GOV_PLAN_PERCENT_SUCCESS`;
export const GET_SECTION_GOV_PLAN_PERCENT_FAIL = `${base}GET_SECTION_GOV_PLAN_PERCENT_FAIL`;

//取得單一園區 獲獎數類型佔比
export const START_GET_SECTION_AWARD_PERCENT_DATA = `${base}START_GET_SECTION_AWARD_PERCENT_DATA`;
export const GET_SECTION_AWARD_PERCENT_SUCCESS = `${base}GET_SECTION_AWARD_PERCENT_SUCCESS`;
export const GET_SECTION_AWARD_PERCENT_FAIL = `${base}GET_SECTION_AWARD_PERCENT_FAIL`;

//取得單一園區 專利數占比-圓餅圖
export const START_GET_SECTION_PATENT_PERCENT_DATA = `${base}START_GET_SECTION_PATENT_PERCENT_DATA`;
export const GET_SECTION_PATENT_PERCENT_SUCCESS = `${base}GET_SECTION_PATENT_PERCENT_SUCCESS`;
export const GET_SECTION_PATENT_PERCENT_FAIL = `${base}GET_SECTION_PATENT_PERCENT_FAIL`;

//依據新聞標籤取得新聞資料
export const START_GET_CONTENT_VIA_NEWS_TAG_DATA = `${base}START_GET_CONTENT_VIA_NEWS_TAG_DATA`;
export const GET_CONTENT_VIA_NEWS_TAG_SUCCESS = `${base}GET_CONTENT_VIA_NEWS_TAG_SUCCESS`;
export const GET_CONTENT_VIA_NEWS_TAG_FAIL = `${base}GET_CONTENT_VIA_NEWS_TAG_FAIL`;

//產品搜尋地圖
export const START_GET_MAP_SEARCH_DATA = `${base}START_GET_MAP_SEARCH_DATA`;
export const GET_MAP_SEARCH_SUCCESS = `${base}GET_MAP_SEARCH_SUCCESS`;
export const GET_MAP_SEARCH_FAIL = `${base}GET_MAP_SEARCH_FAIL`;

//取得廢棄物前5大排行
export const START_GET_TOP_FIVE_WASTE_DISPOSAL_DATA = `${base}START_GET_TOP_FIVE_WASTE_DISPOSAL_DATA`;
export const GET_TOP_FIVE_WASTE_DISPOSAL_SUCCESS = `${base}GET_TOP_FIVE_WASTE_DISPOSAL_SUCCESS`;
export const GET_TOP_FIVE_WASTE_DISPOSAL_FAIL = `${base}GET_TOP_FIVE_WASTE_DISPOSAL_FAIL`;


// ==========================================================================================================
// ==========================================================================================================
// ==========================================================================================================

//單一園區 產業別2碼家數佔比-Modal
export const START_GET_SECTION_TWO_CODE_PERCENTAGE_LIST_DATA = `${base}START_GET_SECTION_TWO_CODE_PERCENTAGE_LIST_DATA`;
export const GET_SECTION_TWO_CODE_PERCENTAGE_LIST_SUCCESS = `${base}GET_SECTION_TWO_CODE_PERCENTAGE_LIST_SUCCESS`;
export const GET_SECTION_TWO_CODE_PERCENTAGE_LIST_FAIL = `${base}GET_SECTION_TWO_CODE_PERCENTAGE_LIST_FAIL`;

//單一園區 產業別3碼家數佔比-Modal
export const START_GET_SECTION_THREE_CODE_PERCENTAGE_LIST_DATA = `${base}START_GET_SECTION_THREE_CODE_PERCENTAGE_LIST_DATA`;
export const GET_SECTION_THREE_CODE_PERCENTAGE_LIST_SUCCESS = `${base}GET_SECTION_THREE_CODE_PERCENTAGE_LIST_SUCCESS`;
export const GET_SECTION_THREE_CODE_PERCENTAGE_LIST_FAIL = `${base}GET_SECTION_THREE_CODE_PERCENTAGE_LIST_FAIL`;

//單一園區 產業別4碼家數佔比-Modal
export const START_GET_SECTION_FOUR_CODE_PERCENTAGE_LIST_DATA = `${base}START_GET_SECTION_FOUR_CODE_PERCENTAGE_LIST_DATA`;
export const GET_SECTION_FOUR_CODE_PERCENTAGE_LIST_SUCCESS = `${base}GET_SECTION_FOUR_CODE_PERCENTAGE_LIST_SUCCESS`;
export const GET_SECTION_FOUR_CODE_PERCENTAGE_LIST_FAIL = `${base}GET_SECTION_FOUR_CODE_PERCENTAGE_LIST_FAIL`;

//單一園區 進口級距佔比-Modal
export const START_GET_SECTION_IMPORT_LIST_DATA = `${base}START_GET_SECTION_IMPORT_LIST_DATA`;
export const GET_SECTION_IMPORT_LIST_SUCCESS = `${base}GET_SECTION_IMPORT_LIST_SUCCESS`;
export const GET_SECTION_IMPORT_LIST_FAIL = `${base}GET_SECTION_IMPORT_LIST_FAIL`;

//單一園區 出口級距佔比-Modal
export const START_GET_SECTION_EXPORT_LIST_DATA = `${base}START_GET_SECTION_EXPORT_LIST_DATA`;
export const GET_SECTION_EXPORT_LIST_SUCCESS = `${base}GET_SECTION_EXPORT_LIST_SUCCESS`;
export const GET_SECTION_EXPORT_LIST_FAIL = `${base}GET_SECTION_EXPORT_LIST_FAIL`;

//單一園區 專利資料-Modal
export const START_GET_SECTION_PATENT_INFO_LIST_DATA = `${base}START_GET_SECTION_PATENT_INFO_LIST_DATA`;
export const GET_SECTION_PATENT_INFO_LIST_SUCCESS = `${base}GET_SECTION_PATENT_INFO_LIST_SUCCESS`;
export const GET_SECTION_PATENT_INFO_LIST_FAIL = `${base}GET_SECTION_PATENT_INFO_LIST_FAIL`;

//單一園區 政府計畫-Modal
export const START_GET_SECTION_GOV_PLAN_LIST_DATA = `${base}START_GET_SECTION_GOV_PLAN_LIST_DATA`;
export const GET_SECTION_GOV_PLAN_LIST_SUCCESS = `${base}GET_SECTION_GOV_PLAN_LIST_SUCCESS`;
export const GET_SECTION_GOV_PLAN_LIST_FAIL = `${base}GET_SECTION_GOV_PLAN_LIST_FAIL`;

//單一園區 取得園區廠商政府計畫類型佔比-Modal
export const START_GET_SECTION_PARK_DETAIL_INFO_LIST_DATA = `${base}START_GET_SECTION_PARK_DETAIL_INFO_LIST_DATA`;
export const GET_SECTION_PARK_DETAIL_INFO_LIST_SUCCESS = `${base}GET_SECTION_PARK_DETAIL_INFO_LIST_SUCCESS`;
export const GET_SECTION_PARK_DETAIL_INFO_LIST_FAIL = `${base}GET_SECTION_PARK_DETAIL_INFO_LIST_FAIL`;

//單一園區 取得園區廠商獲獎類型佔比資料-Modal
export const START_GET_SECTION_AWARD_LIST_DATA = `${base}START_GET_SECTION_AWARD_LIST_DATA`;
export const GET_SECTION_AWARD_LIST_SUCCESS = `${base}GET_SECTION_AWARD_LIST_SUCCESS`;
export const GET_SECTION_AWARD_LIST_FAIL = `${base}GET_SECTION_AWARD_LIST_FAIL`;

//單一園區 取得廢棄物廠商追溯表-Modal
export const START_GET_WASTE_DISPOSAL_LIST_DATA = `${base}START_GET_WASTE_DISPOSAL_LIST_DATA`;
export const GET_SECTION_WASTE_DISPOSAL_LIST_SUCCESS = `${base}GET_SECTION_WASTE_DISPOSAL_LIST_SUCCESS`;
export const GET_SECTION_WASTE_DISPOSAL_LIST_FAIL = `${base}GET_SECTION_WASTE_DISPOSAL_LIST_FAIL`;

//單一園區 公開發行狀況佔比-Modal
export const START_GET_SECTION_STOCK_LIST_DATA = `${base}START_GET_SECTION_STOCK_LIST_DATA`;
export const GET_SECTION_STOCK_LIST_SUCCESS = `${base}GET_SECTION_STOCK_LIST_SUCCESS`;
export const GET_SECTION_STOCK_LIST_FAIL = `${base}GET_SECTION_STOCK_LIST_FAIL`;