const base = `AREA_DASHBOARD_`;

//金屬價格
export const START_GET_METAL_PRICE_VAL = `${base}START_GET_METAL_PRICE_VAL`;
export const GET_METAL_PRICE_VAL_SUCCESS = `${base}GET_METAL_PRICE_VAL_SUCCESS`;
export const GET_METAL_PRICE_VAL_FAIL = `${base}GET_METAL_PRICE_VAL_FAIL`;

//金屬列表
export const START_GET_METAL_OPTION = `${base}START_GET_METAL_OPTION`;
export const GET_METAL_OPTION_SUCCESS = `${base}GET_METAL_OPTION_SUCCESS`;
export const GET_METAL_OPTION_FAIL = `${base}GET_METAL_OPTION_FAIL`;

export const START_GET_MAIN_INDEX_DATA = `${base}START_GET_MAIN_INDEX_DATA`;
export const GET_MAIN_INDEX_DATA_SUCCESS = `${base}GET_MAIN_INDEX_DATA_SUCCESS`;
export const GET_MAIN_INDEX_DATA_FAIL = `${base}GET_MAIN_INDEX_DATA_FAIL`;

export const START_GET_PARK_MAIN_INDEX_LIST_DATA = `${base}START_GET_PARK_MAIN_INDEX_LIST_DATA`;
export const GET_PARK_MAIN_INDEX_LIST_SUCCESS = `${base}GET_PARK_MAIN_INDEX_LIST_DATA_SUCCESS`;
export const GET_PARK_MAIN_INDEX_LIST_FAIL = `${base}GET_PARK_MAIN_INDEX_LIST_DATA_FAIL`;

export const START_GET_TYPE_TWO_CHART_DATA = `${base}START_GET_TYPE_TWO_CHART_DATA`;
export const GET_TYPE_TWO_CHART_DATA_SUCCESS = `${base}GET_TYPE_TWO_CHART_DATA_SUCCESS`;
export const GET_TYPE_TWO_CHART_DATA_FAIL = `${base}GET_TYPE_TWO_CHART_DATA_FAIL`;

export const START_GET_TYPE_THREE_CHART_DATA = `${base}START_GET_TYPE_THREE_CHART_DATA`;
export const GET_TYPE_THREE_CHART_DATA_SUCCESS = `${base}GET_TYPE_THREE_CHART_DATA_SUCCESS`;
export const GET_TYPE_THREE_CHART_DATA_FAIL = `${base}GET_TYPE_THREE_CHART_DATA_FAIL`;

export const START_GET_TYPE_FOUR_CHART_DATA = `${base}START_GET_TYPE_FOUR_CHART_DATA`;
export const GET_TYPE_FOUR_CHART_DATA_SUCCESS = `${base}GET_TYPE_FOUR_CHART_DATA_SUCCESS`;
export const GET_TYPE_FOUR_CHART_DATA_FAIL = `${base}GET_TYPE_FOUR_CHART_DATA_FAIL`;

export const START_GET_PUBLIC_STOCK_CHART_DATA = `${base}START_GET_PUBLIC_STOCK_CHART_DATA`;
export const GET_PUBLIC_STOCK_CHART_DATA_SUCCESS = `${base}GET_PUBLIC_STOCK_CHART_DATA_SUCCESS`;
export const GET_PUBLIC_STOCK_CHART_DATA_FAIL = `${base}GET_PUBLIC_STOCK_CHART_DATA_FAIL`;

export const START_GET_NOT_PUBLIC_STOCK_CHART_DATA = `${base}START_GET_NOT_PUBLIC_STOCK_CHART_DATA`;
export const GET_NOT_PUBLIC_STOCK_CHART_DATA_SUCCESS = `${base}GET_NOT_PUBLIC_STOCK_CHART_DATA_SUCCESS`;
export const GET_NOT_PUBLIC_STOCK_CHART_DATA_FAIL = `${base}GET_NOT_PUBLIC_STOCK_CHART_DATA_FAIL`;

export const START_GET_STOCK_RANKING_DATA = `${base}START_GET_PUBLIC_STOCK_RANKING_DATA`;
export const GET_STOCK_RANKING_DATA_SUCCESS = `${base}GET_PUBLIC_STOCK_RANKING_DATA_SUCCESS`;
export const GET_STOCK_RANKING_DATA_FAIL = `${base}GET_PUBLIC_STOCK_RANKING_DATA_FAIL`;

// export const START_GET_NOT_PUBLIC_STOCK_RANKING_DATA = `START_GET_NOT_PUBLIC_STOCK_RANKING_DATA`;
// export const GET_NOT_PUBLIC_STOCK_RANKING_DATA_SUCCESS = `GET_NOT_PUBLIC_STOCK_RANKING_DATA_SUCCESS`;
// export const GET_NOT_PUBLIC_STOCK_RANKING_DATA_FAIL = `GET_NOT_PUBLIC_STOCK_RANKING_DATA_FAIL`;

export const START_GET_TOP_TEN_CAPITAL_DATA = `${base}START_GET_TOP_TEN_CAPITAL_DATA`;
export const GET_TOP_TEN_CAPITAL_DATA_SUCCESS = `${base}GET_TOP_TEN_CAPITAL_DATA_SUCCESS`;
export const GET_TOP_TEN_CAPITAL_DATA_FAIL = `${base}GET_TOP_TEN_CAPITAL_DATA_FAIL`;

export const START_GET_TOP_TEN_GOVPLAN_DATA = `${base}START_GET_TOP_TEN_GOVPLAN_DATA`;
export const GET_TOP_TEN_GOVPLAN_DATA_SUCCESS = `${base}GET_TOP_TEN_GOVPLAN_DATA_SUCCESS`;
export const GET_TOP_TEN_GOVPLAN_DATA_FAIL = `${base}GET_TOP_TEN_GOVPLAN_DATA_FAIL`;

export const START_GET_TOP_TEN_PATENT_DATA = `${base}START_GET_TOP_TEN_PATENT_DATA`;
export const GET_TOP_TEN_PATENT_DATA_SUCCESS = `${base}GET_TOP_TEN_PATENT_DATA_SUCCESS`;
export const GET_TOP_TEN_PATENT_DATA_FAIL = `${base}GET_TOP_TEN_PATENT_DATA_FAIL`;

export const START_GET_IMPORT_PERCENTAGE_DATA = `${base}START_GET_IMPORT_PERCENTAGE_DATA`;
export const GET_IMPORT_PERCENTAGE_DATA_SUCCESS = `${base}GET_IMPORT_PERCENTAGE_DATA_SUCCESS`;
export const GET_IMPORT_PERCENTAGE_DATA_FAIL = `${base}GET_IMPORT_PERCENTAGE_DATA_FAIL`;

export const START_GET_EXPORT_PERCENTAGE_DATA = `${base}START_GET_EXPORT_PERCENTAGE_DATA`;
export const GET_EXPORT_PERCENTAGE_DATA_SUCCESS = `${base}GET_EXPORT_PERCENTAGE_DATA_SUCCESS`;
export const GET_EXPORT_PERCENTAGE_DATA_FAIL = `${base}GET_EXPORT_PERCENTAGE_DATA_FAIL`;

export const START_GET_PUBLIC_STOCK_PERCENTAGE_DATA = `${base}START_GET_PUBLIC_STOCK_PERCENTAGE_DATA`;
export const GET_PUBLIC_STOCK_PERCENTAGE_DATA_SUCCESS = `${base}GET_PUBLIC_STOCK_PERCENTAGE_DATA_SUCCESS`;
export const GET_PUBLIC_STOCK_PERCENTAGE_DATA_FAIL = `${base}GET_PUBLIC_STOCK_PERCENTAGE_DATA_FAIL`;

export const START_GET_GOV_PLAN_PERCENTAGE_DATA = `${base}START_GET_GOV_PLAN_PERCENTAGE_DATA`;
export const GET_GOV_PLAN_PERCENTAGE_DATA_SUCCESS = `${base}GET_GOV_PLAN_PERCENTAGE_DATA_SUCCESS`;
export const GET_GOV_PLAN_PERCENTAGE_DATA_FAIL = `${base}GET_GOV_PLAN_PERCENTAGE_DATA_FAIL`;

export const START_GET_AWARD_PERCENTAGE_DATA = `${base}START_GET_AWARD_PERCENTAGE_DATA`;
export const GET_AWARD_PERCENTAGE_DATA_SUCCESS = `${base}GET_AWARD_PERCENTAGE_DATA_SUCCESS`;
export const GET_AWARD_PERCENTAGE_DATA_FAIL = `${base}GET_AWARD_PERCENTAGE_DATA_FAIL`;

export const START_GET_NEWS_INFO_DATA = `${base}START_GET_NEWS_INFO_DATA`;
export const GET_NEWS_INFO_DATA_SUCCESS = `${base}GET_NEWS_INFO_DATA_SUCCESS`;
export const GET_NEWS_INFO_DATA_FAIL = `${base}GET_NEWS_INFO_DATA_FAIL`;

export const START_GET_NEWS_TAGS_DATA = `${base}START_GET_NEWS_TAGS_DATA`;
export const GET_NEWS_TAGS_DATA_SUCCESS = `${base}GET_NEWS_TAGS_DATA_SUCCESS`;
export const GET_NEWS_TAGS_DATA_FAIL = `${base}GET_NEWS_TAGS_DATA_FAIL`;

export const START_GET_EMPLOYEE_DATA = `${base}START_GET_EMPLOYEE_DATA`;
export const GET_EMPLOYEE_DATA_SUCCESS = `${base}GET_EMPLOYEE_DATA_SUCCESS`;
export const GET_EMPLOYEE_DATA_FAIL = `${base}GET_EMPLOYEE_DATA_FAIL`;

export const START_GET_NEWS_INFO_TAG_DATA = `${base}START_GET_NEWS_INFO_TAG_DATA`;
export const GET_NEWS_INFO_TAG_DATA_SUCCESS = `${base}GET_NEWS_INFO_TAG_DATA_SUCCESS`;
export const GET_NEWS_INFO_TAG_DATA_FAIL = `${base}GET_NEWS_INFO_TAG_DATA_FAIL`;

export const START_GET_GREEN_FACTORY_DATA = `${base}START_GET_GREEN_FACTORY_DATA`;
export const GET_GREEN_FACTORY_DATA_SUCCESS = `${base}GET_GREEN_FACTORY_DATA_SUCCESS`;
export const GET_GREEN_FACTORY_DATA_FAIL = `${base}GET_GREEN_FACTORY_DATA_FAIL`;

export const START_GET_COMPANY_ASSOCIATION_DATA = `${base}START_GET_COMPANY_ASSOCIATION_DATA`;
export const GET_COMPANY_ASSOCIATION_DATA_SUCCESS = `${base}GET_COMPANY_ASSOCIATION_DATA_SUCCESS`;
export const GET_COMPANY_ASSOCIATION_DATA_FAIL = `${base}GET_COMPANY_ASSOCIATION_DATA_FAIL`;

export const START_GET_BRIEF_AND_RANKING_DATA = `${base}START_GET_BRIEF_AND_RANKING_DATA`;
export const GET_BRIEF_AND_RANKING_DATA_SUCCESS = `${base}GET_BRIEF_AND_RANKING_DATA_SUCCESS`;
export const GET_BRIEF_AND_RANKING_DATA_FAIL = `${base}GET_BRIEF_AND_RANKING_DATA_FAIL`;

export const START_GET_TYPE_TWO_LIST_DATA = `${base}START_GET_TYPE_TWO_LIST_DATA`;
export const GET_TYPE_TWO_LIST_DATA_SUCCESS = `${base}GET_TYPE_TWO_LIST_DATA_SUCCESS`;
export const GET_TYPE_TWO_LIST_DATA_FAIL = `${base}GET_TYPE_TWO_LIST_DATA_FAIL`;

export const START_GET_TYPE_THREE_LIST_DATA = `${base}START_GET_TYPE_THREE_LIST_DATA`;
export const GET_TYPE_THREE_LIST_DATA_SUCCESS = `${base}GET_TYPE_THREE_LIST_DATA_SUCCESS`;
export const GET_TYPE_THREE_LIST_DATA_FAIL = `${base}GET_TYPE_THREE_LIST_DATA_FAIL`;

export const START_GET_TYPE_FOUR_LIST_DATA = `${base}START_GET_TYPE_FOUR_LIST_DATA`;
export const GET_TYPE_FOUR_LIST_DATA_SUCCESS = `${base}GET_TYPE_FOUR_LIST_DATA_SUCCESS`;
export const GET_TYPE_FOUR_LIST_DATA_FAIL = `${base}GET_TYPE_FOUR_LIST_DATA_FAIL`;

export const START_GET_IMPORT_LIST_DATA = `${base}START_GET_IMPORT_LIST_DATA`;
export const GET_IMPORT_LIST_DATA_SUCCESS = `${base}GET_IMPORT_LIST_DATA_SUCCESS`;
export const GET_IMPORT_LIST_DATA_FAIL = `${base}GET_IMPORT_LIST_DATA_FAIL`;

export const START_GET_EXPORT_LIST_DATA = `${base}START_GET_EXPORT_LIST_DATA`;
export const GET_EXPORT_LIST_DATA_SUCCESS = `${base}GET_EXPORT_LIST_DATA_SUCCESS`;
export const GET_EXPORT_LIST_DATA_FAIL = `${base}GET_EXPORT_LIST_DATA_FAIL`;

export const START_GET_GOV_PLAN_LIST_DATA = `${base}START_GET_GOV_PLAN_LIST_DATA`;
export const GET_GOV_PLAN_LIST_DATA_SUCCESS = `${base}GET_GOV_PLAN_LIST_DATA_SUCCESS`;
export const GET_GOV_PLAN_LIST_DATA_FAIL = `${base}GET_GOV_PLAN_LIST_DATA_FAIL`;

export const START_GET_AWARD_LIST_DATA = `${base}START_GET_AWARD_LIST_DATA`;
export const GET_AWARD_LIST_DATA_SUCCESS = `${base}GET_AWARD_LIST_DATA_SUCCESS`;
export const GET_AWARD_LIST_DATA_FAIL = `${base}GET_AWARD_LIST_DATA_FAIL`;

export const START_GET_PATENT_LIST_DATA = `${base}START_GET_PATENT_LIST_DATA`;
export const GET_PATENT_LIST_DATA_SUCCESS = `${base}GET_PATENT_LIST_DATA_SUCCESS`;
export const GET_PATENT_LIST_DATA_FAIL = `${base}GET_PATENT_LIST_DATA_FAIL`;

export const START_GET_PUBLIC_STOCK_LIST_DATA = `${base}START_GET_PUBLIC_STOCK_LIST_DATA`;
export const GET_PUBLIC_STOCK_LIST_DATA_SUCCESS = `${base}GET_PUBLIC_STOCK_LIST_DATA_SUCCESS`;
export const GET_PUBLIC_STOCK_LIST_DATA_FAIL = `${base}GET_PUBLIC_STOCK_LIST_DATA_FAIL`;

export const START_GET_TOP_TEN_GOVPLAN_LIST_DATA = `${base}START_GET_TOP_TEN_GOVPLAN_LIST_DATA`;
export const GET_TOP_TEN_GOVPLAN_LIST_DATA_SUCCESS = `${base}GET_TOP_TEN_GOVPLAN_LIST_DATA_SUCCESS`;
export const GET_TOP_TEN_GOVPLAN_LIST_DATA_FAIL = `${base}GET_TOP_TEN_GOVPLAN_LIST_DATA_FAIL`;

export const START_GET_FACTORY_AREA_RENT_DATA = `${base}START_GET_FACTORY_AREA_RENT_DATA`;
export const GET_FACTORY_AREA_RENT_DATA_SUCCESS = `${base}GET_FACTORY_AREA_RENT_DATA_SUCCESS`;
export const GET_FACTORY_AREA_RENT_DATA_FAIL = `${base}GET_FACTORY_AREA_RENT_DATA_FAIL`;

export const START_GET_TOP_FIVE_WASTE_DISPOSAL_DATA = `${base}START_GET_TOP_FIVE_WASTE_DISPOSAL_DATA`;
export const GET_TOP_FIVE_WASTE_DISPOSAL_DATA_SUCCESS = `${base}GET_TOP_FIVE_WASTE_DISPOSAL_DATA_SUCCESS`;
export const GET_TOP_FIVE_WASTE_DISPOSAL_DATA_FAIL = `${base}GET_TOP_FIVE_WASTE_DISPOSAL_DATA_FAIL`;

export const START_GET_TOP_FIVE_WASTE_DISPOSAL_LIST_DATA = `${base}START_GET_TOP_FIVE_WASTE_DISPOSAL_LIST_DATA`;
export const GET_TOP_FIVE_WASTE_DISPOSAL_LIST_DATA_SUCCESS = `${base}GET_TOP_FIVE_WASTE_DISPOSAL_LIST_DATA_SUCCESS`;
export const GET_TOP_FIVE_WASTE_DISPOSAL_LIST_DATA_FAIL = `${base}GET_TOP_FIVE_WASTE_DISPOSAL_LIST_DATA_FAIL`;