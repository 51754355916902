import axios from "axios";
import { Base64 } from "js-base64";
import { baseUrl, loginbaseUrl } from "../constants/endpoints";

const headers = {
  "Access-Control-Allow-Origin": "*",
  "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
  "Access-Control-Allow-Headers":
    "Access-Control-Allow-Headers, Origin, Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers",
  "Content-Type": "application/json",
  Authorization: localStorage.getItem("acToken"),
};

//產業儀錶板營運動態列表
export const fetchOperationMainIndexListData = () => {
  return axios({
    method: "get",
    url: `${baseUrl}/api/MoeaDos/GetStockCompanyOperations?IndustryCode_Two=`,
    headers: headers,
  })
    .then((res) => {
      return { "data": res.data.data  };
    })
    .catch((err) => {
      return [];
    });
};

//產業上市櫃公司營收漲跌家數
export const fetchStockCompanyRevenue = (IndustryCode) => {
  return axios({
    method: "get",
    url: `${baseUrl}/api/MoeaDos/GetStockCompanyRevenueVariety?IndustryCode=${IndustryCode}`,
    headers: headers,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

//產業出口級距漲跌家數
export const fetchExportCompanyRevenue = (IndustryCode) => {
  return axios({
    method: "get",
    url: `${baseUrl}/api/MoeaDos/GetCompanyForgeinTradeVariety?IndustryCode=${IndustryCode}&IsIncome=false`,
    headers: headers,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

//取得最新數據
export const getIndustryInfo = (industryCodes) => {
  const form = new FormData();
  form.append("IndustryCodes", industryCodes);
  form.append("RocYear", 112);
  return axios({
    method: "post",
    url: `${baseUrl}/api/MoeaDos/GetLastDatas_MoeaDos_Industry`,
    headers: headers,
    data: form,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return [];
    });
};

//產業上市櫃公司營收趨勢圖
export const getStockCompanyRevenueTrendApi = (IndustryCode) => {
  return axios({
    // proxy: true,
    method: "get",
    url: `${baseUrl}/api/MoeaDos/GetStockCompanyRevenueTrend?IndustryCode=${IndustryCode}&IsIncome=false`,
    headers: headers,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return [];
    });
};

//產業出口級距趨勢圖
export const getExportCompanyRevenueTrendApi = (IndustryCode) => {
  return axios({
    // proxy: true,
    method: "get",
    url: `${baseUrl}/api/MoeaDos/GetCompanyForgeinTradeTrend?IndustryCode=${IndustryCode}`,
    headers: headers,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return [];
    });
};

//取得資料趨勢資料
export const getTradesInfo = (typeName, industryCodes) => {
  const form = new FormData();
  form.append("TypeName", typeName);
  form.append("RocYear", 112);
  form.append("IndustryCodes", industryCodes);

  return axios({
    // proxy: true,
    method: "post",
    url: `${baseUrl}/api/MoeaDos/GetChart_Trend_MoeaDos_Industry`,
    headers: headers,
    data: form,
  })
    .then((res) => {
      return res.data.data[0].dataPoints;
    })
    .catch((err) => {
      return [];
    });
};

//取得產業前三十大資本額廠商清單
export const getTopCompanyIndustry = (industryCodes) => {
  const form = new FormData();
  form.append("IndustryCodes", industryCodes);

  return axios({
    // proxy: true,
    method: "post",
    url: `${baseUrl}/api/MoeaDos/GetTopCompany_Industry`,
    headers: headers,
    data: form,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return [];
    });
};

//取得產業上市櫃廠商
export const getListedIndustryCompany = (industryCodes) => {
  const form = new FormData();
  form.append("IndustryCodes", industryCodes);

  return axios({
    method: "post",
    url: `${baseUrl}/api/MoeaDos/GetListedCompany_Industry/`,
    headers: headers,
    data: form,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return [];
    });
};

//發明公開(5年內)-全國
export const getPubinvention = (industryCodes, parkId) => {
  var url = "";
  const form = new FormData();
  form.append("IndustryCodes", industryCodes);
  if (parkId) {

    //園區
    url = `${baseUrl}/api/InduParkSingle/GetPatentDatas_Pubinvention/${parkId}`;
  } else {
    //全國
    form.append("RocYear", 110);
    url = `${baseUrl}/api/Patent/GetPatentDatas_Pubinvention/`;
  }

  return axios({
    method: "post",
    url: url,
    headers: headers,
    data: form,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return [];
    });
};

//專利研發情形-申請情形
export const getPatentDatas = (industryCodes, parkId) => {
  var url = "";
  const form = new FormData();
  form.append("IndustryCodes", industryCodes);
  if (parkId) {
    url = `${baseUrl}/api/InduParkSIngle/GetPatentDatas_Certified/${parkId}`;
  } else {
    url = `${baseUrl}/api/Patent/GetPatentDatas_Certified`;
  }

  return axios({
    method: "post",
    url: url,
    headers: headers,
    data: form,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return [];
    });
};

//產業四碼
export const getTypeFourCode = () => {
  return axios({
    proxy: true,
    method: "get",
    url: `${baseUrl}/api/InduType/GetIndustryTypes_Four_Stat/`,
    headers: headers,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return [];
    });
};