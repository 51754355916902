import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ShareIcon from "@mui/icons-material/Share";
import WebIcon from "@mui/icons-material/Web";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { useSnackbar } from "notistack";
import { message } from "../../utility/common";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Swal from "sweetalert2";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import TextField from "@mui/material/TextField";
import doll from "../../assets/images/ug_doll.png";
import {
  handleUpdateUserDashboardName,
  handleGetDashboardList
} from "../../store/actions/custom_industry_dashboard";

const DashboardListItem = ({
  board_id,
  board_name,
  description,
  update_time,
  handle_delete_board,
  handle_update_board,
  industry_count,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [state, setState] = React.useState({
    company_name: "",
    is_company_name_edit: false,
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((state) => ({
      ...state,
      [name]: value,
    }));
  };
  const handle_is_company_name_edit = () => {
    setState((state) => ({
      ...state,
      is_company_name_edit: !state.is_company_name_edit,
      company_name: board_name
    }));
  };
  const handle_submit_company_name_edit = async () => {
    dispatch(
      handleUpdateUserDashboardName(
        board_id,
        state.company_name
      )
    ).then(() => {
      dispatch(handleGetDashboardList())
      message(enqueueSnackbar, "更新儀表板名稱成功!", "success");
    }).catch(err => {
      message(enqueueSnackbar, err?.description, "error");
    });
    setState(state => ({ ...state, is_company_name_edit: false }))
  }

  // 分享 id
  const isCopied = () => {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 1000,
      timerProgressBar: true,
    });

    Toast.fire({
      icon: "success",
      title: "複製成功",
    });
  };

  return (
    <CustomDashboardListItemWrapper>
      {
        industry_count > 0? <Link to={`/customized_industry_dashboard/${board_id}`} target="_blank">
        <CustomDashboardListImageContainer cursor="pointer">
          <CustomDashboardListImage
            src={doll}
          />
        </CustomDashboardListImageContainer>
      </Link> : <CustomDashboardListImageContainer cursor="initial">
          <CustomDashboardListImage
            src={doll}
          />
        </CustomDashboardListImageContainer>
      }
      <CustomDashboardListItemDescription>
        <CustomDashboardListItemDescriptionNameContainer>
          <CustomDashboardListItemDescriptionNameText>
            {state.is_company_name_edit ?
              <TextField
                variant="standard"
                onChange={handleChange}
                value={state.company_name}
                name={"company_name"}
                sx={{ fontSize: "1.25rem" }}
              /> : board_name}
          </CustomDashboardListItemDescriptionNameText>
          <IconButton
            onClick={handle_is_company_name_edit}
          >
            {
              state.is_company_name_edit ?
                <CloseIcon sx={{ width: "20px", height: "20px" }} /> :
                <EditIcon sx={{ width: "20px", height: "20px" }} />
            }

          </IconButton>
          {
            state.is_company_name_edit &&
            <IconButton
              onClick={handle_submit_company_name_edit}
            >
              <CheckIcon sx={{ width: "20px", height: "20px" }} />
            </IconButton>
          }
        </CustomDashboardListItemDescriptionNameContainer>
        <CustomDashboardListItemDescriptionDateContainer>
          <p>上次更新時間：{update_time}</p>
        </CustomDashboardListItemDescriptionDateContainer>
        {/* <CustomDashboardListItemDescriptionDateContainer>
          <p>已新增產業數：{company_count}</p>
        </CustomDashboardListItemDescriptionDateContainer> */}
      </CustomDashboardListItemDescription>
      <CustomDashboardListItemButtonGroup>
        <CopyToClipboard text={board_id} onCopy={industry_count > 0&&isCopied}>
          <Tooltip title="複製欲分享的儀表板編號">
          {
            industry_count > 0? (
            <IconButton>
              <ShareIcon />
            </IconButton>
            ):( 
            <span>
              <IconButton disabled>
                <ShareIcon />
              </IconButton>
            </span>)
          }
          </Tooltip>
        </CopyToClipboard>
        <Tooltip title={industry_count > 0?`前往${board_name}儀表板`: "請先加入廠商到儀表板"}>
          {
            industry_count > 0? (
            <Link to={`/customized_industry_dashboard/${board_id}`} target="_blank">
              <IconButton>
                <WebIcon />
              </IconButton>
            </Link>):( 
            <span>
              <IconButton disabled>
                <WebIcon />
              </IconButton>
            </span>)
          }
        </Tooltip>
        <Tooltip title="設定目標產業">
          <IconButton onClick={() => handle_update_board(board_id, board_name, description)}>
            <EditIcon sx={industry_count == 0&&{color:"black"}}/>
          </IconButton>
        </Tooltip>
        <Tooltip title="刪除儀表板">
          {
            <IconButton onClick={() => handle_delete_board(board_id)}>
              <DeleteIcon sx={industry_count == 0&&{color:"#bdbdbd"}}/>
            </IconButton>
          }
        </Tooltip>
      </CustomDashboardListItemButtonGroup>
    </CustomDashboardListItemWrapper>
  );
};

const CustomDashboardListItemWrapper = styled.div`
  width: 300px;
  height: 350px;
  box-shadow: 0 3px 5px 0 #efefef;
  border-radius: 20px;
  background-color: white;
  overflow: hidden;
  position: relative;
  margin-bottom: 16px;

  &:hover {
    box-shadow: 0 6px 10px 0 #efefef;
  }
`;

const CustomDashboardListImageContainer = styled.div`
  width: 300px;
  height: 180px;
  background-color: rgba(69, 127, 139, 0.3);
  cursor: ${(p) => p.cursor};
`;

const CustomDashboardListImage = styled.img`
  width: 150px;
  height: 170px;
  object-fit: contain;
  transform: translate(70%, 20px) rotate(5deg);
`;

const CustomDashboardListItemDescription = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

const CustomDashboardListItemDescriptionNameContainer = styled.div`
  display: flex;
  align-items: center;
`;

const CustomDashboardListItemDescriptionDateContainer = styled.div`
  padding: 5px 0px;
`;

const CustomDashboardListItemDescriptionNameText = styled.h4`
  font-size: 1.5rem;
  font-weight: 600;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const CustomDashboardListItemButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  right: 5px;
  bottom: 10px;
`;

export default DashboardListItem;
