import React from 'react';
import styled from 'styled-components';
import Chart from 'react-apexcharts';
import NoData from '../Nodata/NoData';
import { thousandDivider } from '../../utility/common';

const CustomizedGraphic = (props) => {
  const {
    chartType,
    labels,
    tags,
    data,
    unit,
    formatter,
    mapData,
    isGovPlanOrAwardPercentage,
    type,
    title,
    thead,
    isCompanyCompare,
    skip,
    custWidth,
    custHeight,
    custColor,
    seriesLabel
  } = props;
  const defualtFontSize = {
    fontSize: '16px',
    fontFamily: 'Noto Sans TC',
  };

  let sum = 999;
  let content = null;
  if (title === "上漲家數") {
    sum = 0;
    data.forEach(function (item) {
      sum += item;
    });
  }
  if ((data && data.length === 0) || sum === 0) {
    content = <NoData />;
  } else {
    switch (chartType) {
      case 'donut-chart':
        const options = {
          id: 'donut',
          labels: labels ? labels : false,
          series: data,
          chart: {
            type: 'pie',
            events: props.eventHandler
              ? {
                click: (event, chartContext, config) => {
                  try {
                    if (isGovPlanOrAwardPercentage) {
                      props.eventHandler(
                        config.globals.labels[
                        event.target.attributes['j'].value
                        ],
                        config.globals.labels[
                        event.target.attributes['j'].value
                        ]
                      );
                    } else {
                      const updateValue = mapData.filter(
                        (data) =>
                          data.label ===
                          config.globals.labels[
                          event.target.attributes['j'].value
                          ]
                      );
                      props.eventHandler(
                        updateValue[0].tag,
                        config.globals.labels[
                        event.target.attributes['j'].value
                        ]
                      );
                    }
                  } catch (err) {
                    props.eventHandler('');
                  }
                },
              }
              : {
                click: () => {
                  return;
                },
              },
          },
          tooltip: {
            style: {
              fontSize: '16px',
              fontFamily: 'Noto Sans TC',
            },
          },
          dataLabels: props.dataLabels
            ? {
              ...props.dataLabels,
              style: {
                fontSize: '200px',
                fontFamily: 'Noto Sans TC',
              },
              dropShadow: {
                enabled: false,
              },
            }
            : { enabled: false },
          plotOptions: {
            pie: {
              expandOnClick: true,
              offsetY: props.offSetY ? props.offSetY : 0,
              customScale: 0.9,
              dataLabels: {},
              donut: {
                size: props.circle ? `${props.circle}%` : '0%',
                labels: {
                  total: {
                    showAlways: false,
                    label: 'Total',
                  },
                },
              },
            },
          },
          fill: {
            opacity: 0.8,
          },
          xaxis: {
            labels: {
              style: {
                fontSize: '18px',
                fontFamily: 'Noto Sans TC',
              },
            },
          },
          yaxis: {
            labels: {
              style: {
                fontSize: '18px',
                fontFamily: 'Noto Sans TC',
              },
            },
          },
          legend: {
            show: props.isLegend ? true : false,
          },
        };

        content = (
          <Chart
            options={options}
            series={data}
            labels={labels ? labels : false}
            type="donut"
          />
        );
        break;

      case 'vertical-bar-chart':
        const verticalBarOptions = {
          dataLabels: {
            enabled: false,
          },
          chart: {
            toolbar: {
              show: true,
            },
            events: props.eventHandler
              ? {
                click: (event, chartContext, config) => {
                  try {
                    if (tags) {
                      const updateValue = mapData.filter(
                        (data) =>
                          data.tag.slice(0, 6) ===
                          config.globals.labels[
                          event.target.attributes['j'].value
                          ]
                      );
                      props.eventHandler(
                        updateValue[0].tag,
                        updateValue[0].label
                      );
                    } else {
                      const updateValue = mapData.filter(
                        (data) =>
                          data.label.slice(0, 4) ===
                          config.globals.labels[
                          event.target.attributes['j'].value
                          ]
                      );
                      props.eventHandler(updateValue[0].tag);
                    }
                  } catch (err) {
                    props.eventHandler('');
                  }
                },
              }
              : {
                click: () => {
                  return;
                },
              },
          },
          tooltip: {
            custom: function ({ series, seriesIndex, dataPointIndex, w }) {
              return (
                '<div class="barTooltip">' +
                '<span>' +
                labels[dataPointIndex] +
                ' ' +
                (tags ? tags[dataPointIndex] : '') +
                ' : ' +
                thousandDivider(series[seriesIndex][dataPointIndex]) +
                unit +
                '</span>' +
                '</div>'
              );
            },
          },
          xaxis: {
            categories: labels.map((item) => item.slice(0, tags ? 6 : 4)),
            labels: {
              style: defualtFontSize,
            },
          },
          yaxis: {
            labels: {
              style: defualtFontSize,
              formatter: formatter,
            },
          },
          fill: { colors: custColor ? custColor : props.color },
          plotOptions: {
            bar: {
              endingShape: 'flat',
              columnWidth: props.width ? props.width : '70%',
              distributed: false,
            },
          },
          annotations: isCompanyCompare ? {
            yaxis: [{
              y: (isCompanyCompare[0]?.Variety * 100).toFixed(2),
              borderColor: (isCompanyCompare[0]?.Variety > 0 ? "#FF0000" : "green"),
              borderWidth: "3px",
              label: {
                borderColor: "#ADD4DE",
                style: {
                  fontWeight: 900,
                  fontSize: "1.2rem",
                  color: (isCompanyCompare[0]?.Variety > 0 ? "#FF0000" : "green"),
                  background: "#ADD4DE"
                },
                text: `平均值：${(isCompanyCompare[0]?.Variety * 100).toFixed(2)}%`
              }
            }],
          } : {}
        };

        content = <Chart
          options={verticalBarOptions}
          series={[{ data: data, name: unit }]}
          type="bar"
          width={custWidth && "100%"}
          height={custHeight && "100%"}
        />
        break;

      case 'horizontal-bar-chart':
        const horizontalBarOptions = {
          dataLabels: {
            enabled: false,
          },
          chart: {
            toolbar: {
              show: false,
            },
            events: props.eventHandler
              ? {
                click: (event, chartContext, config) => {
                  try {
                    const updateValue = mapData.filter(
                      (data) =>
                        data.label.slice(0, 4) ===
                        config.globals.labels[
                        event.target.attributes['j'].value
                        ]
                    );
                    if (type === 'employee') {
                      props.eventHandler(updateValue[0].tag);
                    } else {
                      props.eventHandler(
                        updateValue[0].tag,
                        config.globals.labels[
                        event.target.attributes['j'].value
                        ]
                      );
                    }
                  } catch (err) {
                    props.eventHandler('');
                  }
                },
              }
              : {
                click: () => {
                  return;
                },
              },
          },
          tooltip: {
            custom: function ({ series, seriesIndex, dataPointIndex, w }) {
              return (
                '<div class="barTooltip">' +
                '<span>' +
                labels[dataPointIndex] +
                ' : ' +
                thousandDivider(series[seriesIndex][dataPointIndex]) +
                unit +
                '</span>' +
                '</div>'
              );
            },
          },
          xaxis: {
            categories: labels?.map((item) => item.slice(0, 4)),
            labels: {
              style: defualtFontSize,
            },
          },
          yaxis: {
            labels: {
              align: 'left',
              style: defualtFontSize,
              formatter: formatter,
            },
          },
          fill: { colors: props.color },
          plotOptions: {
            bar: {
              horizontal: true,
              endingShape: 'flat',
              columnWidth: props.width ? props.width : '70%',
              distributed: false,
            },
          },
        };

        content = <Chart
          options={horizontalBarOptions}
          series={[{ data: data, name: unit }]}
          type="bar"
        />
        break;

      case 'pie-chart-with-legend':
        const pieChartWithLegendOptions = {
          id: 'donut',
          labels: labels ? labels : false,
          series: data,
          chart: {
            type: 'pie',
            events: props.eventHandler
              ? {
                click: (event, chartContext, config) => {
                  try {
                    props.eventHandler(
                      config.globals.labels[
                      event.target.attributes['j'].value
                      ]
                    );
                  } catch (err) {
                    props.eventHandler('');
                  }
                },
              }
              : {
                click: () => {
                  return;
                },
              },
          },
          dataLabels: props.dataLabels ? props.dataLabels : { enabled: false },
          plotOptions: {
            pie: {
              expandOnClick: true,
              offsetY: props.offSetY ? props.offSetY : 0,
              customScale: 0.9,
              donut: {
                size: props.circle ? `${props.circle}%` : '0%',
                labels: {
                  total: {
                    showAlways: false,
                    label: 'Total',
                  },
                },
              },
            },
          },
          xaxis: {
            labels: {
              style: {
                fontSize: '18px',
                fontFamily: 'Noto Sans TC',
              },
            },
          },
          yaxis: {
            labels: {
              style: {
                fontSize: '18px',
                fontFamily: 'Noto Sans TC',
              },
            },
          },
          legend: {
            show: true,
            position: 'bottom',
          },
        };


        content = <Chart
          options={pieChartWithLegendOptions}
          series={data}
          labels={labels ? labels : false}
          type="pie"
        />
        break;

      case 'curve-chart':
        const average = (arr) => arr.reduce((a, b) => a + b, 0) / arr.length;

        const curveChartOptoins = {
          chart: {
            toolbar: {
              show: true,
            },
            zoom: false,
            showAlways: true,
          },
          dataLabels: {
            enabled: window.innerWidth > 790 && skip != "1" ? true : false,
            formatter: (value) => thousandDivider(value),
          },
          tooltip: {
            enabled: true,
            style: defualtFontSize,
          },
          xaxis: {
            type: 'category',
            categories: labels,
            labels: {
              style: {
                ...defualtFontSize,
                fontSize: window.innerWidth > 790 ? '18px' : '14px',
              },
            },
          },
          yaxis: {
            labels: {
              style: {
                ...defualtFontSize,
                fontSize: window.innerWidth > 790 ? '18px' : '10px',
              },
              maxWidth:
                average(data) > 100000000 && window.innerWidth < 790 ? 10 : 150,
              offsetX:
                average(data) > 100000000 && window.innerWidth < 790 ? 50 : 0,
              formatter: (value) => thousandDivider(value),
            },
          },
        };

        content = <Chart
          options={curveChartOptoins}
          series={[{ name: '', data: data }]}
          type="area"
        />
        break;

      case 'bar-with-bar-chart':
        const barWithBarptoins = {
          dataLabels: {
            enabled: false,
          },
          chart: {
            toolbar: {
              show: true,
            },
          },
          xaxis: {
            categories: labels,
            labels: {
              style: defualtFontSize,
            },
          },
          yaxis: {
            labels: {
              style: defualtFontSize,
              formatter: formatter,
              formatter: (value) => value ? value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "",
            },
          },
          fill: { colors: custColor ? custColor : props.color },
          plotOptions: {
            bar: {
              endingShape: 'flat',
              columnWidth: props.width ? props.width : '70%',
              distributed: false,
            },
          }
        };

        content = <Chart
          options={barWithBarptoins}
          series={[{
            name: seriesLabel && seriesLabel[1],
            type: 'bar',
            data: data[1]
          }, {
            name: seriesLabel && seriesLabel[0],
            type: 'bar',
            data: data[0]
          }]}
          type="bar"
        />
        break;

      default:
        return 'Something went wrong when generating the charts';
    }
  }

  return (
    <div
      tabIndex={'0'}
      title={`${title}圖表資料`}
      style={{
        position: 'relative',
      }}
    >
      <div aria-hidden="true">{content}</div>
      {thead && (
        <StyledTable>
          <thead>
            <tr>
              {thead?.map((label, index) => (
                <th key={index}>{label}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {mapData?.map((value, index) => (
              <tr key={title + index}>
                <td>{value.label}</td>
                <td>{value.y}</td>
              </tr>
            ))}
          </tbody>
        </StyledTable>
      )}
    </div>
  );
};

const StyledTable = styled.table`
  position: absolute;
  width: 100%;
  height: 80%;
  top: 0;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
`;
export default React.memo(CustomizedGraphic);
