import styled from "styled-components";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";

export const MobileGridItemContainer = styled.div`
  margin-top: 15px;
`;

export const MobileGridItemNoData = styled.div`
  font-size: 110%;
  margin-bottom: 10px;
`;

export const MobileGridContainerItem = styled(Grid)`
  margin-bottom: 5% !important;

`

export const MobileGridItem = styled(Card)`
  width: 100%;
  height: auto;
  border-bottom: 2px solid var(--main-color);
  border-radius: 20px;
`;

export const MobileGridItemForAward = styled(Card)`
  width: 100%;
  height: auto;
  border-bottom: 2px solid var(--main-color);
  border-radius: 20px;
  .mobile-no-data {
    font-size: 110%;
    margin-bottom: 10px;
  }
`;

export const MobileGridItemList = styled.div`
  border-bottom: 1px solid #8888;
  padding: 3% 2%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .icon {
    margin: 3%;
  }
  &.patent-name {
    padding: 2% 2%;
  }
  .link {
    text-decoration: underline;
    color: #2c83bd;
    padding-right: 1%;
  }
`;

export const MobileGridItemListLabel = styled.p`
  width: 30%;
  border-right: 1px solid #8888;
  text-align: center;
`;
export const MobileGridItemListText = styled.p`
  width: 70%;
  word-wrap: break-word;
  text-align: left;
  padding: 0px 2.5%;
`;
