import React from "react";

import Spinner from "../Spinner/Spinner"
import "./styles.scss";

const CustomizedDashboardBriefInfo = props => {
    const { selectedOrgName, typeTwo, topTenCapital, publicStock, govPlanPercentage, topTenPatent, award } = props;
    const [state, setState] = React.useState({
        numberOfPublic: 0,
        numberOfNotPublic: 0,
        percentage: 0,
        govPlanPercentageCount: 0
    })

    React.useEffect(() => {
        if (govPlanPercentage.length > 0) {
            let total = 0;
            let part = 0;
            govPlanPercentage.map((plan, index) => {
                if (index === 0 || index === 1) {
                    part += plan.y
                }

                total += plan.y
            })
            setState(state => ({ ...state, govPlanPercentageCount: parseInt((part / total) * 100) }))
        }
    }, [govPlanPercentage])

    React.useEffect(() => {
        let total = 0;
        let PublicTotal = 0;
        let NotPublicTotal = 0;
        if (publicStock.publicStockDataMap.length > 0) {
            publicStock.publicStockDataMap.map(item => {
                total += item.y
                switch (item.label) {
                    case "上市":
                        PublicTotal += item.y;
                        break;
                    case "上櫃":
                        PublicTotal += item.y;
                        break;
                    case "興櫃":
                        PublicTotal += item.y;
                        break;
                    case "公開發行":
                        PublicTotal += item.y;
                        break;
                    case "未上市":
                        NotPublicTotal += item.y
                        break;
                    default:
                        return "";
                }
            })
        }
        setState(state => ({ ...state, numberOfPublic: PublicTotal, numberOfNotPublic: NotPublicTotal, percentage: parseInt((PublicTotal / total) * 100) }))
    }, [publicStock])
    if (typeTwo.length > 0 || 
        (Object.entries(govPlanPercentage)[1] && govPlanPercentage.govPlanPercentageDataLabels.length > 0) || 
        (Object.entries(topTenPatent)[1] && topTenPatent.topTenPatentDataLabels.length > 0)) {
        return (
            <div className="Org-dashboard-brief-info">
                <p >
                    {
                        typeTwo.length > 0 && <span>{selectedOrgName} 製造業廠商分佈前五大產業以 {typeTwo.slice(0, 5).map((d, i) => `${typeTwo[i].label.slice(0, -1)} ${i !== (typeTwo.length> 4? 4 : typeTwo.length - 1)? "、": ""}`)}為主，其中有上市櫃公司{state.numberOfPublic}家(佔總體{state.percentage}%)，未上市櫃公司{state.numberOfNotPublic}家；資本額前三大設廠公司有{topTenCapital.slice(0, 3).map((_, i) => `${topTenCapital[i]} ${i !== (topTenCapital.length > 2? 2 : topTenCapital.length - 1)? "、": ""}`)} ... 等。</span>
                    }
                </p>
                <p>
                    {((Object.entries(govPlanPercentage)[1] && govPlanPercentage.govPlanPercentageDataLabels.length > 0) || (Object.entries(topTenPatent)[1] && topTenPatent.topTenPatentDataLabels.length > 0)) && <span> {selectedOrgName}廠商</span>}
                    {(Object.entries(govPlanPercentage)[1] && govPlanPercentage.govPlanPercentageDataLabels.length > 0) && <span>主要執行過的政府計畫為{govPlanPercentage.govPlanPercentageDataLabels[0]}({govPlanPercentage.govPlanPercentageData[0]}件/次)、{govPlanPercentage.govPlanPercentageDataLabels[1]}({govPlanPercentage.govPlanPercentageData[1]}件/次)、{govPlanPercentage.govPlanPercentageDataLabels[2]}({govPlanPercentage.govPlanPercentageData[2]}件/次) ... 等。</span> }
                {(Object.entries(topTenPatent)[1] && topTenPatent.topTenPatentDataLabels.length > 0) && <span>而近三年專利數量前三大公司為{topTenPatent.topTenPatentDataLabels[0]}({topTenPatent.topTenPatentData[0]}件)、{topTenPatent.topTenPatentDataLabels[1]}({topTenPatent.topTenPatentData[1]}件) 、{topTenPatent.topTenPatentDataLabels[2]}({topTenPatent.topTenPatentData[2]}件) 
                    {award.length > 0? String(`，且近十年內廠商曾獲得` + award.map((_, i) => award[i]) + `... 等。`).replace(/,/g, "、"): ""}</span> }
                        
                </p>
            </div>
        )
    } else {
        return (
            <div className="Org-dashboard-brief-info">
                <p> 無相關簡介 </p>
            </div>
        )
    }
}

export default React.memo(CustomizedDashboardBriefInfo);
