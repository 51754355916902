import React from 'react'
import { Card, Grid, Divider, Icon } from '@material-ui/core'

import './styles.scss'

import water from '../../assests/power.svg'
import { thousandDivider } from "../../utility/common";

const ImgGrid = props => {
    const { getProjectId, plan, edited } = props

    return(
        <Grid item xs lg={3} md={4} key={plan.ProjectId}>
            <Card className={edited ? 'img-grid-edited-mode' : 'img-grid'} onClick={() => getProjectId(plan.ProjectId)}>
                <div className="img-container">
                    {!plan.Img && <Icon className="icon">perm_media</Icon>}
                    {plan.Img && <img alt='' src={`https://indupark.smartpark.com.tw/${plan.Img}`} loading={"lazy"}/> }
                </div>
                <div className="info">
                    <p className="info-title">{plan.Name}</p>
                    <Divider className="divider" />
                    <div>
                        <p className="inner-title">類別</p>
                        <p>{plan.Category.map(cate => cate + ' ')}</p>
                    </div>
                    <div>
                        <p className="inner-title">計畫時間</p>
                        <p>{plan.StartDate} ~ {plan.EndDate}</p>
                    </div>
                    <div>
                        <p className="inner-title">補助金額</p>
                        <p>{plan.Amount === 0 ? '依計畫核定' : thousandDivider(plan.Amount)}</p>
                    </div>
                </div>
            </Card>  
        </Grid>
 
    )
}


export default ImgGrid