import React from "react";
import Box from "@material-ui/core/Box";
import "./style.scss";

const PrivacyPolicy = () => {
  return (
    <div class="policy">
      <div class="policy-container">
        <h1>隱私權政策 │</h1>
        <div>
          <Box mt={4}>
            <h2>適用範圍</h2>
            <p>
              經濟部產業園區管理局委辦產業園區價值形塑暨廠商情資加值計畫，由財團法人金屬工業研究中心建置之「產業園區廠商情資平台」（以下簡稱本網站），為保障網際網路使用者，能安心瀏覽本網站所提供的各項服務，及協助使用者瞭解本網站如何提供安全措施與如何蒐集、運用及保護使用者所提供的個人資料。因此，建議您使用本網站時請確實詳讀下列內容，以幫助您安心使用本網站的各項服務。
            </p>
          </Box>

          <Box mt={4}>
            <h2>隱私保護措施</h2>
            <p>
              本網站遵守「個人資料保護法」之規範，保障用戶隱私權益，保證不對外公開。但依據有關法律規章規定、應司法機關調查要求、為維護社會公眾利益、事先獲得用戶明確授權及為維護本網站合法權益之情形，不在此限。
            </p>

            <p>
              使用者進入本網站時，伺服器將自動產生相關紀錄(Log)，包括如使用者的IP位址、使用時間、使用者的瀏覽器、及點選資料記錄等。這些資料將僅供本網站流量分析與網路行為調查參考。
            </p>

            <p>
              再次提醒您，在網際網路上傳輸資料，本中心不能保證百分之百的安全，雖然本中心積極努力採取各種保護使用者的個人資料安全措施。但由於網際網路資料傳輸過程涉及網路環境安全設計等級之良窳，致使本網站無法確信或保證使用者接收或傳送資料時之安全，使用者必須瞭解及承擔網路資料傳輸之風險。
            </p>
          </Box>

          <Box mt={4}>
            <h2>個人資料之蒐集</h2>
            <p>
              本網站有義務保護各申請人隱私，非經您本人同意不會自行修改或刪除任何個人資料及檔案。除非經過您事先同意或符合以下情況始得為之：
            </p>
            <p>
              經由合法的途徑。
              <br />
              違反本網站規定事項，如在交流園地中出現謾罵或做人身攻擊的言論時。
              <br />
              保護或防衛相關網路民眾的權利或所有權。
              <br />
              為保護本網站各相關單位之權益。
              <br />
              本網站絕不會任意出售、交換、或出租任何您的個人資料給其他團體、個人或私人企業。但有下列情形者除外：
              <br />
              配合司法單位合法的調查。
              <br />
              配合相關職權機關依職務需要之調查或使用。
              <br />
              基於善意相信揭露為法律需要，或為維護和改進網站服務而用於管理。
            </p>
          </Box>

          <Box mt={4}>
            <h2>隱私權政策之修改</h2>
            <p>
              本網站有義務保護各申請人隱私，非經您本人同意不會自行修改或刪除任何個人資料及檔案。除非經過您事先同意或符合以下情況始得為之：
            </p>
            <p>
              金屬工業研究發展中心積極努力使隱私權保護更趨完善，本中心將視情況或事實需要隨時修訂相關條文。當本
              隱私權保護有任何之修改時，我們會刊登於本網站，並提醒使用者來詳細閱讀相關之事項。
            </p>
          </Box>
        </div>
      </div>
    </div>
  );
};
export default PrivacyPolicy;
