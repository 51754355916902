import * as actionTypes from "../actionTypes/wasted_clean";

import { fetchWastedPollutionList, fetchWastedPollutionData, fetchWastedPollutionDetailData } from "../../utility/wasted_clean";

const startFetchWastedPollutionList = () => {
  return {
    type: actionTypes.START_FETCH_CLEAN_POLLUTION_LIST_DATA,
  };
};

const fetchWastedPollutionListSuccess = (data) => {
  return {
    type: actionTypes.FETCH_CLEAN_POLLUTION_LIST_DATA_SUCCESS,
    data: data.data,
  };
};

const fetchWastedPollutionListFail = (err) => {
  return {
    type: actionTypes.FETCH_CLEAN_POLLUTION_LIST_DATA_FAIL,
    err: err,
  };
};

export const handleFetchWastedPollutionList = () => {
  return (dispatch) => {
    dispatch(startFetchWastedPollutionList());
    fetchWastedPollutionList()
      .then((res) => {
        if (res && res.status === 200) {
          dispatch(fetchWastedPollutionListSuccess(res));
        } else {
          dispatch(
            fetchWastedPollutionListFail(
              "取得廢棄物清理服務種類清單時發生問題。"
            )
          );
        }
      })
      .catch((err) => {
        dispatch(fetchWastedPollutionListFail(err));
      });
  };
};


const startFetchWastedPollutionData = () => {
  return {
    type: actionTypes.START_FETCH_CLEAN_POLLUTION_DATA,
  };
};

const fetchWastedPollutionDataSuccess = (data) => {
  return {
    type: actionTypes.FETCH_CLEAN_POLLUTION_DATA_SUCCESS,
    data: data.data,
  };
};

const fetchWastedPollutionDataFail = (err) => {
  return {
    type: actionTypes.FETCH_CLEAN_POLLUTION_DATA_FAIL,
    err: err,
  };
};

const handleRestErrText = () => {
  return {
    type: actionTypes.HANDLE_RESET_ERR,
  };
}

export const handleFetchWastedPollutionData = (param) => {
  return (dispatch) => {
    dispatch(startFetchWastedPollutionData());
    fetchWastedPollutionData(param)
      .then((res) => {
        if (res && res.status === 200) {
          dispatch(fetchWastedPollutionDataSuccess(res));
        } else {
          dispatch(
            fetchWastedPollutionDataFail(res.discription)
          );
          setTimeout(()=>{
            dispatch(handleRestErrText())
          }, 3000)
        }
      })
      .catch((err) => {
        dispatch(fetchWastedPollutionDataFail(err));
        setTimeout(()=>{
          dispatch(handleRestErrText())
        }, 3000);
      });
  };
};

const startFetchWastedDetailList = (ctrl_id) => {
  return {
    type: actionTypes.START_FETCH_CLEAN_DETAIL_LIST,
    ctrl_id: ctrl_id
  };
};

const fetchWastedDetailListSuccess = (data, ctrl_id) => {
  return {
    type: actionTypes.FETCH_CLEAN_DETAIL_LIST_SUCCESS,
    data: data.data,
  };
};

const fetchWastedDetailListFail = (err, ctrl_id) => {
  return {
    type: actionTypes.FETCH_CLEAN_DETAIL_LIST_FAIL,
    err: err,
    ctrl_id: ctrl_id
  };
};

export const handleFetchWastedDetailList = (ctrl_id, org_id) => {
  return (dispatch) => {
    dispatch(startFetchWastedDetailList(ctrl_id));
    fetchWastedPollutionDetailData(ctrl_id, org_id)
      .then((res) => {
        if (res && res.status === 200) {
          dispatch(fetchWastedDetailListSuccess(res, ctrl_id));
        } else {
          dispatch(
            fetchWastedDetailListFail(
              "依照列管編號取得清理種類時發生問題。"
            )
          );
        }
      })
      .catch((err) => {
        dispatch(fetchWastedDetailListFail(err, ctrl_id));
      });
  };
};