import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import LockIcon from '@mui/icons-material/Lock';
import { useSelector } from "react-redux";
import * as theme from "../../../assets/theme";
import blurred from "../../../assets/images/blurred.png";
import "./styles.scss";
import InfoTwoToneIcon from '@mui/icons-material/InfoTwoTone';


const FactoryMoreInfo = (props) => {
  const { data, UpdateCompany, loading, enterpriseQualifiedData } = props;
  const { innerWidth } = useSelector((state) => state.app);
  const [state, setState] = React.useState({
    endIndex: 0,
    page: 0,
    rowsPerPage: 5,
    styleKey: {},
    styleValue: {},
  });
  const url = window.location.origin;
  const history = useHistory();
  
  const openNewTabs = (link) => window.open(link, "_blank");

  const handleOpenFactoryPage = () => {
    history.push("/sign-up")
  };
  // const styleKey = innerWidth < 1024 ? {width:"40%", fontWeight: 600} : {width:"30%", fontWeight: 600};
  // const styleValue = innerWidth < 1024 ? {width:"60%", borderBottom: "none"} : {display: "flex"};
  React.useEffect(() => {
    if (innerWidth < 1024) {
      setState((state) => ({
        ...state,
        styleKey: { width: "40%", fontWeight: 600 },
        styleValue: { width: "60%", borderBottom: "none" },
      }));
    } else {
      setState((state) => ({
        ...state,
        styleKey: { width: "30%", fontWeight: 600 },
        styleValue: { display: "flex" },
      }));
    }
  }, [innerWidth]);

  return(
      <TableContainers className="table-container">
        <Table className="table" style={{ tableLayout: "fixed", visibility: localStorage.getItem("isLogin") === "true"? "visible" : "hidden"}}>
          <TableBody className="table-body">
            <React.Fragment>
              <TableRow hover className="table-body-row">
                <TableCell align="left" style={state.styleKey}>
                  <p>公司簡介</p>
                </TableCell>
                <TableCell align="left" style={state.styleValue}>
                  <p style={{whiteSpace: 'pre-wrap'}}>{data.Introduction ? data.Introduction : "本項目無資料"}</p>
                  {UpdateCompany && innerWidth>1024 && !data.Introduction &&
                    (UpdateCompany.Name === "" ? <a style={{color:"#006EB6"}} href= {url+"/updated_company"}>(自訂廠商資料)</a> : "" )
                  }
                </TableCell>
              </TableRow>
              <TableRow hover className="table-body-row">
                <TableCell align="left" style={state.styleKey}>
                  <p>主要產品</p>
                </TableCell>
                <TableCell align="left" style={state.styleValue}>
                  <p>{data.MainProductDesc ? data.MainProductDesc : "本項目無資料"}</p>
                  {UpdateCompany && innerWidth>1024 && !data.MainProductDesc &&
                    (UpdateCompany.Name === "" ? <a style={{color:"#006EB6"}} href= {url+"/updated_company"}>(自訂廠商資料)</a> : "" )
                  }
                </TableCell>
              </TableRow>
              <TableRow hover className="table-body-row" style={data.JobsWeb !== "無相關資料" ? {} : {display:"none"}}>
                <TableCell align="left" style={state.styleKey}>
                  <p>徵才網址</p>
                </TableCell>
                <TableCell align="left" style={state.styleValue}>
                  {
                    data.JobsWeb !== "無相關資料"? 
                    <a style={{color:"#006ab4", textDecoration: "underline", cursor: "pointer"}} href={data.JobsWeb} title={`另開新視窗，前往${data?.DataSourceType}網站`} target="_blank" rel="noopener noreferrer">{`${data.JobsWeb}`}</a>
                    : 
                    <p>{`${data.JobsWeb}`}</p>
                  }
                  <p
                    style={data.DataSourceType === null ? {display: "none"} : {}}
                  >{" "}{` (${data?.DataSourceType})`}</p>
                </TableCell>
              </TableRow>
            </React.Fragment>
          </TableBody>
        </Table>
        <NoAuthCover login={localStorage.getItem("isLogin") === "true"}>
          <TextContainer>
            <LockIcon /><Text>請 <button onClick={handleOpenFactoryPage}>註冊</button> 解鎖此功能。</Text>
          </TextContainer>
        </NoAuthCover>
        <Blurred 
           login={localStorage.getItem("isLogin") === "true"}
           src={blurred}
        />
      </TableContainers>
    );
};

const TableContainers = styled(TableContainer)`
  position: relative;
`

const NoAuthCover = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
  /* background-color: ${theme.background_color}; */
  display: ${p => p.login ? "none" : "flex"};
  justify-content: center;
  align-items: center;
  z-index: 20;
`;

const Blurred = styled.img`
  width: 100%;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
  filter: blur(10px);
  object-fit: cover;
  z-index: 10;
  display: ${p => p.login ? "none" : "block"};
`;

const TextContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Text = styled.p`
  padding-left: 5px;
  >button{
    color: ${theme.linkBlue};
    text-decoration: underline;
    cursor: pointer;
    transition: 0.2s;
    &:hover{
      transition: 0.2s;
      color: ${theme.linkHoveredBlue};
    }
  }
`;

export default React.memo(FactoryMoreInfo);
