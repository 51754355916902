import React from "react";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Icon from "@material-ui/core/Icon"
import { useSelector } from "react-redux";

import ContentInfoList from "./ContentInfoList";
import ContentInfoOverviweList from "./ContentInfoOverviweList";

import "./styles.scss";

export default (props) => {
  const { onCloseInfo } = props;
  const [state, setState] = React.useState({
     value: 0
  })
  const {
    dataSortedByPark,
    dataSortedByCity,
    dataSortedByInduFourStr,
    dataSortedByArea,
    allCompanies
  } = useSelector((state) => state.supplychain);
  const { innerWidth } = useSelector((state) => state.app);

  const handleChange = (e, newValue) => {
     setState(state => ({ ...state, value: newValue }))
  }

    return (
      <div className="supply-chain-info">
        <div  className="supply-chain-info-close">
           <Icon onClick={onCloseInfo}>close</Icon>
        </div>
        <div className="supply-chain-info-content">
          <Tabs
              className="supply-chain-info-content-head-tabs"
              value={state.value}
              onChange={(e, newValue) => handleChange(e, newValue)}
            >
                <Tab label="總覽" />
                <Tab label={window.innerWidth > 1023 ? "依地區分布" : "依地區"}/>
                <Tab label={window.innerWidth > 1023 ? "依縣市分布" : "依縣市"}/>
                <Tab label={window.innerWidth > 1023 ? "依園區分布" : "依園區"}/>
                <Tab label="所有廠商" />
            </Tabs>
          <div>
            {state.value === 0 && 
              <ContentInfoOverviweList 
                ContentInfoOverviweList 
                title="總覽" 
                dataSortedByArea={dataSortedByArea}  
                dataSortedByCity={dataSortedByCity}  
                dataSortedByPark={dataSortedByPark}  
                allCompany={allCompanies}
                changeToAreaTab={(e, newValue) => handleChange(e, 1)}
                changeToCityTab={(e, newValue) => handleChange(e, 2)}
                changeToParkTab={(e, newValue) => handleChange(e, 3)}
                changeToCompanies={(e, newValue) => handleChange(e, 4)}

              />}
            {state.value === 1 && <ContentInfoList title="依地區分布" data={dataSortedByArea} type="area"/>}
            {state.value === 2 && <ContentInfoList title="依縣市分布" data={dataSortedByCity} type="cities"/>}
            {state.value === 3 && <ContentInfoList title="依園區分布" data={dataSortedByPark} type="parks"/>}
            {state.value === 4 && <ContentInfoList title="所有廠商" allCompany={allCompanies} type="all"/>}
          </div>
        </div>
      </div>
    )
};
