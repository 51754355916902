import React from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import PublicIcon from "@mui/icons-material/Public";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

import air_pollution from "../assets/images/air_pollution.png";
import cloudy from "../assets/images/cloudy.png";
import raining from "../assets/images/raining.png";
import sunny from "../assets/images/sunny.png";
import * as theme from "../assets/theme";

import {
  handleGetWeatherBoxInfo,
  handleGetWeatherInfo,
  handleGetAirQuality,
} from "../store/actions/weather";

const WeatherBox = ({ park_id, sectionIndex }) => {
  const { innerWidth } = useSelector((state) => state.app);
  const { get_weather_box, get_weather_info, get_air_quality } = useSelector(
    (state) => state.weather
  );
  const dispatch = useDispatch();
  const [state, setState] = React.useState({
    weather_image: cloudy,
    weather_info_time: "",
    weather_info: "",
    location: "",
  });

  React.useEffect(() => {
    if (park_id) {
      dispatch(handleGetWeatherBoxInfo(park_id));
    }
  }, [park_id]);

  React.useEffect(() => {
    if (get_weather_box.length > 0) {
      dispatch(handleGetAirQuality(get_weather_box[0].AirboxID));
    }
  }, [get_weather_box]);

  React.useEffect(() => {
    if (sectionIndex.sectionIndexData.ServiceCenter) {
      let location;
      if (
        sectionIndex.sectionIndexData.ServiceCenter.Address.slice(0, 3).indexOf(
          "台"
        ) > -1
      ) {
        location = sectionIndex.sectionIndexData.ServiceCenter.Address.slice(
          0,
          3
        ).replace("台", "臺");
      } else {
        location = sectionIndex.sectionIndexData.ServiceCenter.Address.slice(
          0,
          3
        );
      }
      dispatch(handleGetWeatherInfo(location));
      setState((state) => ({ ...state, location: location }));
    }
  }, [sectionIndex.sectionIndexData]);

  React.useEffect(() => {
    if (get_weather_info) {
      const info =
        get_weather_info.length === 0
          ? "暫無資料"
          : get_weather_info.weatherElement.filter(
              (el) => el.elementName === "Wx"
            )[0]?.time[0];
      handleDisplayWeatherImage(info.parameter.parameterName);
      setState((state) => ({
        ...state,
        weather_info: info.parameter.parameterName,
        weather_info_time: info.startTime,
      }));
    } else {
      setState((state) => ({ ...state, weather_info: "暫無資料" }));
    }
  }, [get_weather_info]);

  const handleDisplayWeatherImage = (weather) => {
    if (weather) {
      if (weather.indexOf("晴天") > -1) {
        setState((state) => ({ ...state, weather_image: sunny }));
      } else if (weather.indexOf("雨") > -1) {
        setState((state) => ({ ...state, weather_image: raining }));
      } else {
        setState((state) => ({ ...state, weather_image: cloudy }));
      }
    }
  };

  const handleFetchAirQualityData = (data) => {
    if (data.length === 0) {
      return {
        pm2_5: "暫無資料",
        time: "暫無資料",
      };
    } else {
      for (let i = 0; i <= data.length; i++) {
        if (data[i].pm2_5 !== 0) {
          return data[i];
        }
      }
    }
  };

  const handleOpenGoogleMap = (lat, long) => {
    if (get_weather_box.length > 0) {
      const selected_box = get_weather_box[0];
      window.open(
        `https://maps.google.com/?q=${selected_box.Latitude},${selected_box.Longitude}`,
        "_blank"
      );
    }
  };

  return (
    <WeatherBoxWrapper>
      <WeatherInnerBoxWrapper>
        <WeatherBoxLeftContainer>
          <WeatherBoxTitle>
            <WeatherBoxTitleText>pm2.5濃度：</WeatherBoxTitleText>
            <WeatherBoxText>濃度若高於54則不適進行戶外活動。</WeatherBoxText>
          </WeatherBoxTitle>
          <ImageContainer>
            <Images src={air_pollution} alt={""} loading={"lazy"} />
            <DisplayContainer
              text={handleFetchAirQualityData(get_air_quality).pm2_5}
              pm2={handleFetchAirQualityData(get_air_quality).pm2_5}
            />
          </ImageContainer>
          <DateDateContainer>
            <WeatherBoxText text={0.5}>
              {innerWidth > 550 && "發佈時間:"}
              {get_air_quality.length !== 0
                ? handleFetchAirQualityData(get_air_quality)
                    ?.time.replace("T", " ")
                    .replace("Z", "")
                : "暫無資料"}
            </WeatherBoxText>
          </DateDateContainer>
        </WeatherBoxLeftContainer>
        <WeatherBoxRightContainer>
          <WeatherBoxTitle>
            <WeatherBoxTitleText>天氣資訊：</WeatherBoxTitleText>
            <WeatherBoxText>
              {state.location}近6小時內預測資料(固定)。
            </WeatherBoxText>
          </WeatherBoxTitle>
          <ImageContainer>
            <Images src={state.weather_image} alt={""} loading={"lazy"} />
            <DisplayContainer text={state.weather_info} />
          </ImageContainer>
          <DateDateContainer>
            <WeatherBoxText text={0.5}>
              {innerWidth > 550 && "發佈時間:"}
              {state.weather_info_time}
            </WeatherBoxText>
          </DateDateContainer>
        </WeatherBoxRightContainer>
      </WeatherInnerBoxWrapper>
      <LocationContainer>
        <Tooltip title={"另開新視窗，查看 Google Map 上空氣盒子位置。"}>
          <IconButton onClick={handleOpenGoogleMap}>
            <PublicIcon />
          </IconButton>
        </Tooltip>
      </LocationContainer>
    </WeatherBoxWrapper>
  );
};

const DisplayContainer = ({ text, pm2 }) => {
  const handleGetAirPollutionIndex = (pm2) => {
    switch (typeof pm2 === "number") {
      case pm2 <= 10.9:
        return "#9cff9c";
      case 11 <= pm2 && pm2 <= 23.9:
        return "#31ff00";
      case 24 <= pm2 && pm2 <= 35.9:
        return "#31cf00";
      case 36 <= pm2 && pm2 <= 41.9:
        return "#ffff00";
      case 42 <= pm2 && pm2 <= 47.9:
        return "#ffcf00";
      case 48 <= pm2 && pm2 <= 53.9:
        return "#ff9a00";
      case 54 <= pm2 && pm2 <= 58.9:
        return "#ff6464";
      case 59 <= pm2 && pm2 <= 64.9:
        return "#ff0000";
      case 65 <= pm2 && pm2 <= 70.9:
        return "#990000";
      default:
        return "#ce30ff";
    }
  };

  return (
    <GrayBoxContainer>
      <GrayBox>
        <Text>{text}</Text>
      </GrayBox>
      {pm2 && text !== "暫無資料" && (
        <AirPollutionIndexBox color={handleGetAirPollutionIndex(pm2)} />
      )}
    </GrayBoxContainer>
  );
};

const LocationContainer = styled.div`
  display: flex;
  justify-content: flex-start;
`;

const WeatherBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const WeatherInnerBoxWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 10vh;
  width: 100%;
  padding: 10px 0px;
`;

const WeatherBoxContainer = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  width: 50%;
  padding: 5px 10px;
`;

const WeatherBoxTitle = styled.div`
  padding: 0px 0px 10px 0px;
  @media (max-width: 550px) {
    min-height: 100px;
  }
`;

const WeatherBoxTitleText = styled.h4`
  font-size: 1.2rem;
  font-weight: 600;
`;

const WeatherBoxText = styled.p`
  padding: 4px 0px;
  font-size: ${(p) => `${p.text}rem`};
`;

const DateDateContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const WeatherBoxLeftContainer = styled(WeatherBoxContainer)`
  border-right: 0.5px solid ${theme.grayBorderColor};
`;

const WeatherBoxRightContainer = styled(WeatherBoxContainer)`
  /* border-left: 0.25px solid ${theme.grayBorderColor}; */
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  min-height: 170px;
  padding: 10px 0px;
  margin: 20px 0px;
  @media (max-width: 550px) {
    min-height: 150px;
  }
`;

const Images = styled.img`
  width: 150px;
  position: absolute;
  object-fit: cover;
  left: 40px;
  z-index: 10;
  @media (max-width: 550px) {
    width: 130px;
    left: 0px;
    bottom: 30px;
  }
`;

const GrayBoxContainer = styled.div`
  display: block;
`;

const GrayBox = styled.div`
  background-color: ${theme.disabledGray};
  width: 160px;
  position: absolute;
  padding: 20px 10px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 15;
  left: 140px;
  top: 20px;
  @media (max-width: 550px) {
    left: 0px;
    top: 65px;
    width: 100%;
  }
`;

const AirPollutionIndexBox = styled(GrayBox)`
  z-index: 10;
  left: 140px;
  top: 50px;
  background-color: ${(p) => p.color};
  display: ${(p) => (p.color ? "block" : "none")};
  @media (max-width: 550px) {
    left: 0px;
    top: 100px;
  }
`;

const Text = styled.p`
  font-size: 1.25rem;
  font-weight: 500;
`;

export default React.memo(WeatherBox);
