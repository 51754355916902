import React from "react";
import MenuItem from "@material-ui/core/MenuItem";
import QuestionContainerCard from "./QuestionContainerCard";
import QuestionCompanySuggestionList from "./QuestionCompanySuggestionList";
import labor from "../../assests/industrialFactory01.png";
import factory from "../../assests/factory.png";
import { useLocation } from "react-router-dom";
import { typeTwo } from "../../constants/types";
import { parksWithUrl } from "../../constants/parks";
import { thousandDivider } from "../../utility/common";
import { handleGetUserCompany } from "../../store/actions/question";
import { handleStartBasicInfo } from "../../store/actions/factory";
import { useSelector, useDispatch } from "react-redux";

import * as Style from "./styles";

export default (props) => {
  const { nextSlide, result, UploadQuestionData } = props;
  const { basicInfo } = useSelector((state) => state.factory);
  const { getVisitQuestionDetail } = useSelector((state) => state.question);
  const dispatch = useDispatch();
  const companyNameRef = React.useRef(null);
  const emailRule =
    /^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z]+$/;
  const location = useLocation();

  const [state, setState] = React.useState({
    companyName: "",
    companyNumber: "",
    industryPark: "",
    companyCapital: "",
    companyTypeTwo: [],
    companyMainProduct: "",
    userName: "",
    userDivision: "",
    userEmail: "",
    userPhone: "",
    note: "",
    visitTime: new Date().toISOString().slice(0, 10),
    isClicked: false,
    isFinished: false,
    parks: [],
    typeTwo: [],
  });

  //讀取編輯資料
  React.useEffect(async () => {
    if (getVisitQuestionDetail.Qid && location.state) {
      const temp = {};
      var tempCode = [];
      var tempTypeTwo = [];
      [getVisitQuestionDetail].map((item) => {
        temp.companyName = item.CompanyName;
        temp.companyNumber = item.BusinessNo;
        temp.industryPark = item.Park;
        temp.companyMainProduct = item.MainProduct;
        temp.companyCapital = thousandDivider(item.CapitalTotal);
        tempCode = item.IndustryCode?.split(",");
        temp.companyTypeTwo = [];
        state.typeTwo.map((i) => {
          tempCode.map((item) => {
            if (i.Value === item) {
              temp.companyTypeTwo.push(i.Key);
              tempTypeTwo.push(i.Value);
            }
          });
        });
        temp.userName = item.RespondName;
        temp.userDivision = item.RespondDept;
        temp.userEmail = item.RespondMail;
        temp.userPhone = item.RespondPhone;
        temp.visitTime = item.VisitTime;
        temp.note = item.Note;
        temp.isClicked = true;
        temp.isFinished = true;
        temp.parks = [];
      });

      setState(temp);
      result[0].companyTypeTwo = tempTypeTwo;
    }
  }, [getVisitQuestionDetail]);

  React.useEffect(() => {
    const TypeTwoData = [];

    state.typeTwo.map((i) => {
      state.companyTypeTwo.map((item) => {
        if (i.Key === item) {
          TypeTwoData.push(i.Value);
        }
      });
    });

    const temp = {
      companyName: state.companyName,
      companyNumber: state.companyNumber,
      industryPark: state.industryPark,
      companyMainProduct: state.companyMainProduct,
      companyCapital: thousandDivider(state.companyCapital),
      companyTypeTwo: TypeTwoData,
      visitTime: state.visitTime,
      userName: state.userName,
      userDivision: state.userDivision,
      userEmail: state.userEmail,
      userPhone: state.userPhone,
      note: state.note,
    };

    result[0] = temp;

    if (
      state.companyName &&
      state.companyNumber.length === 8 &&
      state.industryPark &&
      state.companyMainProduct &&
      state.visitTime &&
      state.companyCapital &&
      state.companyTypeTwo &&
      state.userName &&
      state.userDivision
    ) {
      result[0].isFinished = true;

      setState((state) => ({
        ...state,
        isFinished: true,
      }));
    } else {
      result[0].isFinished = false;

      setState((state) => ({
        ...state,
        isFinished: false,
      }));
    }
  }, [
    state.companyName,
    state.companyNumber,
    state.industryPark,
    state.companyMainProduct,
    state.companyCapital,
    state.companyTypeTwo,
    state.userName,
    state.visitTime,
    state.userDivision,
    state.userEmail,
    state.userPhone,
    state.note,
  ]);

  React.useEffect(() => {
    if (state.companyName && basicInfo.Addr) {
      let typeTwo = [];

      basicInfo.IndustryTypes.map((i) => {
        typeTwo.push(i.substring(0, 2));
      });

      setState((state) => ({
        ...state,
        companyCapital: thousandDivider(basicInfo.CapitalTotal),
        companyMainProduct: basicInfo.MainProductDesc,
        companyTypeTwo: [...new Set(typeTwo)],
        // industryPark,
      }));
    }
  }, [basicInfo]);

  React.useEffect(() => {
    const REGEX_PINYIN =
      /[ㄅ]|[ㄆ]|[ㄇ]|[ㄈ]|[ㄉ]|[ㄊ]|[ㄋ]|[ㄌ]|[ㄍ]|[ㄎ]|[ㄏ]|[ㄐ]|[ㄑ]|[ㄒ]|[ㄓ]|[ㄘ]|[ㄕ]|[ㄖ]|[ㄚ]|[ㄛ]|[ㄝ]|[ㄞ]|[ㄟ]|[ㄠ]|[ㄡ]|[ㄢ]|[ㄣ]|[ㄤ]|[ㄥ]|[ㄦ]/u;
    const REGEX_COMMA = /,/u;
    const hasPinyin = (str) => REGEX_PINYIN.test(str);
    const hasComma = (str) => REGEX_COMMA.test(str);
    const { companyName } = state;

    if (companyName) {
      setTimeout(() => {
        if (!hasPinyin(companyName) && !hasComma(companyName)) {
          if (companyName === companyNameRef.current.value) {
            if (
              typeof companyName === "string" &&
              companyName.length >= 2 &&
              !state.isClicked
            ) {
              dispatch(handleGetUserCompany(companyName));
            }
          }
        }
      }, 1000);
    }
  }, [state.companyName, companyNameRef]);

  const handleUpdateFiled = (e) => {
    const { name, value } = e.target;
    setState((state) => ({ ...state, [name]: value }));
    if (name === "companyName") {
      setState((state) => ({ ...state, isClicked: false }));
    }
  };

  const handleUpdateCompanyInfo = (companyNo, companyName) => {
    dispatch(handleStartBasicInfo(companyNo));
    setState((state) => ({
      ...state,
      isClicked: true,
      companyName: companyName,
      companyNumber: companyNo,
    }));
    ///fetchBasicInfo
  };

  React.useEffect(() => {
    const temp = [...parksWithUrl];
    const tempTypeTwo = [...typeTwo];
    temp.push({
      ParkId: "others",
      Name: "其他",
    });
    tempTypeTwo.push({
      Key: "97",
      Value: "其他",
    });
    setState((state) => ({
      ...state,
      parks: temp,
      typeTwo: tempTypeTwo,
    }));
  }, [parksWithUrl, typeTwo, getVisitQuestionDetail]);

  return (
    <QuestionContainerCard
      title={"基本資料"}
      src={state.isClicked ? factory : labor}
      companyNumber={state.companyNumber}
      userEmail={state.userEmail}
      nextSlide={nextSlide}
      isIndexZero={true}
      result={result}
      isFinished={state.isFinished}
      UploadQuestionData={UploadQuestionData}
      isCompanyExist={state.isClicked}
    >
      <Style.BasicQuestionRightContainer>
        <Style.BasicQuestionRightContainerPerRow>
          <Style.BasicTextFiled
            name={"companyName"}
            value={state.companyName}
            label="*公司名稱"
            onChange={handleUpdateFiled}
            inputRef={companyNameRef}
          />
          <QuestionCompanySuggestionList
            isOpen={state.companyName && !state.isClicked}
            handleUpdateCompanyInfo={handleUpdateCompanyInfo}
          />
        </Style.BasicQuestionRightContainerPerRow>
        <Style.BasicQuestionRightContainerPerRow>
          <Style.BasicTextFiled
            error={
              state.companyNumber.length === 0 ||
              state.companyNumber.length === 8
                ? false
                : true
            }
            name={"companyNumber"}
            value={state.companyNumber}
            label="*統一編號"
            onChange={handleUpdateFiled}
            helperText={
              state.companyNumber.length > 0 && state.companyNumber.length !== 8
                ? "統編格式應為8碼"
                : ""
            }
          />
          <Style.BasicTextFiled
            name={"companyCapital"}
            value={state.companyCapital}
            label="*資本額"
            onChange={handleUpdateFiled}
            disabled
          />
        </Style.BasicQuestionRightContainerPerRow>
        <Style.BasicQuestionRightContainerPerRow>
          <Style.BasicFormControl>
            <Style.BasicInputLabel>*產業別</Style.BasicInputLabel>
            <Style.BasicSelect
              name={"companyTypeTwo"}
              value={state.companyTypeTwo}
              label=""
              onChange={handleUpdateFiled}
              multiple
            >
              {state.typeTwo.map((i) => (
                <MenuItem key={i.Key} value={i.Key}>
                  {i.Value}
                </MenuItem>
              ))}
            </Style.BasicSelect>
          </Style.BasicFormControl>

        </Style.BasicQuestionRightContainerPerRow>
        <Style.BasicQuestionRightContainerPerRow>
          <Style.BasicTextFiled
            name={"companyMainProduct"}
            value={state.companyMainProduct}
            label="*主要產品"
            onChange={handleUpdateFiled}
          />
        </Style.BasicQuestionRightContainerPerRow>
        <Style.BasicQuestionRightContainerPerRow>
          <Style.BasicFormControl>
            <Style.BasicInputLabel>*產業園區</Style.BasicInputLabel>
            <Style.BasicSelect
              name={"industryPark"}
              value={state.industryPark}
              label="產業園區"
              onChange={handleUpdateFiled}
            >
              {state.parks.map((i, index) => (
                <Style.BasicSelectOption
                  disabled={i.ParkId === "divided"}
                  key={i.ParkId + index}
                  value={i.Name}
                >
                  {i.Name}
                </Style.BasicSelectOption>
              ))}
            </Style.BasicSelect>
          </Style.BasicFormControl>
          <Style.BasicTextFiled
            name={"visitTime"}
            value={state.visitTime}
            label="*訪廠時間"
            type="date"
            InputProps={{inputProps: { max: new Date().toISOString().slice(0, 10)} }}
            onChange={handleUpdateFiled}
            visit={true}
          />
        </Style.BasicQuestionRightContainerPerRow>
        <Style.BasicQuestionRightContainerPerRow>
          <Style.BasicTextFiled
            name={"note"}
            value={state.note}
            label="備註"
            onChange={handleUpdateFiled}
          />
        </Style.BasicQuestionRightContainerPerRow>
        <Style.BasicQuestionRightContainerPerRow>
          <Style.BasicTextFiled
            name={"userName"}
            value={state.userName}
            label="*受訪者名稱"
            onChange={handleUpdateFiled}
          />
          <Style.BasicTextFiled
            name={"userDivision"}
            value={state.userDivision}
            label="*受訪者部門"
            onChange={handleUpdateFiled}
          />
        </Style.BasicQuestionRightContainerPerRow>
        <Style.BasicQuestionRightContainerPerRow>
          <Style.BasicTextFiled
            error={
              state.userEmail.length === 0 ||
              state.userEmail.search(emailRule) != -1
                ? false
                : true
            }
            name={"userEmail"}
            value={state.userEmail}
            label="受訪者E-mail"
            onChange={handleUpdateFiled}
            helperText={
              state.userEmail.length > 0 &&
              state.userEmail.search(emailRule) === -1
                ? "Email格式有誤！"
                : ""
            }
          />
          <Style.BasicTextFiled
            name={"userPhone"}
            value={state.userPhone}
            label="受訪者電話"
            onChange={handleUpdateFiled}
          />
        </Style.BasicQuestionRightContainerPerRow>
      </Style.BasicQuestionRightContainer>
    </QuestionContainerCard>
  );
};
