import React from "react";
import Collapse from "@material-ui/core/Collapse";

import { cityCode } from "../../constants/cities";
import taiwan from "../../assests/taiwan.png";
import city from "../../assests/supplycghain-city.svg";
import factory from "../../assests/supplychain-factory.svg";
import building from "../../assests/building.svg";

import "./styles.scss";

export default (props) => {
  const {
    dataSortedByArea,
    dataSortedByCity,
    dataSortedByPark,
    title,
    changeToAreaTab,
    changeToCityTab,
    changeToParkTab,
    changeToCompanies,
    allCompany
  } = props;
  const [state, setState] = React.useState({
    showListLength: 3,
    dataSortedByAreaList: {},
    dataSortedByCityList: {},
    dataSortedByParkList: {},
  });
  
  const openCompanyPage = (e, businessNo) => {
    e.stopPropagation();
    const url = window.location.origin;

    window.open(`${url}/factory_info/basic_info/${businessNo}`, "_blank")
  }

  const toggleItem = (id, list, listName) => {
    if (list && Object.entries(list).length > 0) {
      const obj = {};
      Object.entries(list).map((i) => {
        if (i[0] === id) {
          obj[i[0]] = {
            isOpen: !i[1].isOpen,
            num: i[1].length,
            data: i[1].data,
          };
        } else {
          obj[i[0]] = {
            isOpen: false,
            num: i[1].length,
            data: i[1].data,
          };
        }
      });

      setState((state) => ({ ...state, [listName]: obj }));
    }
  };

  const areaTransform = (area) => {
    switch (area) {
      case "North":
        return "北部";
      case "East":
        return "東部";
      case "Center":
        return "中部";
      case "South":
        return "南部";
      default:
        return "其他";
    }
  };

  const updateList = (list, name) => {
    if (list && list.length > 0) {
      const obj = {};
      list.map((i) => {
        obj[i[0]] = {
          isOpen: false,
          num: i[1].length,
          data: i[1],
        };
      });

      setState((state) => ({ ...state, [name]: obj }));
    }
  };

  React.useEffect(() => {
    updateList(dataSortedByArea, "dataSortedByAreaList");
    updateList(dataSortedByCity, "dataSortedByCityList");
    updateList(dataSortedByPark, "dataSortedByParkList");
  }, [dataSortedByArea, dataSortedByCity, dataSortedByPark]);

  return (
    <div className="content-info">
      <div className="content-info-title">
        <h4>{title}</h4>
      </div>
      <div className="content-info-overview-container">
        <div className="area-container">
          <p className="north">
            北部：
            <span>
              {state.dataSortedByAreaList.North
                ? state.dataSortedByAreaList.North.num
                : 0}
            </span>{" "}
            家
          </p>
          <p className="center">
            中部：
            <span>
              {state.dataSortedByAreaList.Center
                ? state.dataSortedByAreaList.Center.num
                : 0}
            </span>{" "}
            家
          </p>
          <p className="south">
            南部：
            <span>
              {state.dataSortedByAreaList.South
                ? state.dataSortedByAreaList.South.num
                : 0}
            </span>{" "}
            家
          </p>
          <p className="east">
            東部：
            <span>
              {state.dataSortedByAreaList.East
                ? state.dataSortedByAreaList.East.num
                : 0}
            </span>{" "}
            家
          </p>
          <div className="taiwan">
            <img  loading={"lazy"} alt="taiwan" src={taiwan} />
          </div>
        </div>
        <div className="more">
          <p onClick={changeToAreaTab}>更多資訊</p>
        </div>
      </div>
      <div  className="content-info-overview-container">
        <div>
            <h3>依廠商分類</h3>
        </div>
        <div className="content-info-overview-container-row">

          <div className="content-info-overview-container-row-img">
            <img  loading={"lazy"} src={building} alt="building" />
          </div>
          <div className="content-info-overview-container-row-content">
            {allCompany.length > 0 &&
                allCompany.slice(0, 5)
                .map((item) => (
                  <div
                    className="content-info-overview-container-items"
                    key={item.businessNo}
                  >
                    <div className="content-info-overview-container-items-container">
                      <p className="content-info-overview-container-items-header" onClick={(e)=>{openCompanyPage(e, item.businessNo)}}>
                        {
                          item.name.indexOf("股份") > -1 ?  item.name.substring(0,item.name.length - 6) : item.name
                        }
                      </p>
                    </div>
                  </div>
                ))}
          </div>
        </div>
        <div className="more">
          <p onClick={changeToCompanies}>更多資訊</p>
        </div>
      </div>  
      <div  className="content-info-overview-container">
        <div>
            <h3>依縣市分類</h3>
        </div>
        <div className="content-info-overview-container-row">

          <div className="content-info-overview-container-row-img">
            <img  loading={"lazy"} src={city} alt="city" />
          </div>
          <div className="content-info-overview-container-row-content">
            {state.dataSortedByCityList &&
              Object.entries(state.dataSortedByCityList).length > 0 &&
              Object.entries(state.dataSortedByCityList)
                .sort((a, b) => b[1].num - a[1].num)
                .slice(0, 3)
                .map((item) => (
                  <div
                    className="content-info-overview-container-items"
                    key={item[0]}
                  >
                    <div className="content-info-overview-container-items-container">
                      <p className="content-info-overview-container-items-header" >
                        {
                          cityCode.filter(
                            (city) =>
                              city.CityId.toString() === item[0].toString()
                          )[0].CityName
                        }
                      </p>{" "}
                      :{" "}
                      <p className="content-info-overview-container-items-values">
                        {item[1].data.length}
                      </p>
                    </div>
                  </div>
                ))}
          </div>
        </div>
        <div className="more">
          <p onClick={changeToCityTab}>更多資訊</p>
        </div>
      </div>
      <div  className="content-info-overview-container">
      <div>
            <h3>依園區分類</h3>
      </div>
      <div className="content-info-overview-container-row">
        <div className="content-info-overview-container-row-img">
          <img  loading={"lazy"} src={factory} alt="factory" />
        </div>
        <div className="content-info-overview-container-row-content">
          {state.dataSortedByParkList &&
            Object.entries(state.dataSortedByParkList).length > 0 &&
            Object.entries(state.dataSortedByParkList)
              .filter(i => i[0] !== "Other")
              .sort((a, b) => b[1].num - a[1].num)
              .slice(0, 3)
              .map((item) => (
                <div
                  className="content-info-overview-container-items"
                  key={item[0]}
                >
                  <div className="content-info-overview-container-items-container">
                    <p className="content-info-parks-container-items-header">
                      {item[0]}
                    </p>{" "}
                    :{" "}
                    <p className="content-info-overview-container-items-values">
                      {item[1].data.length}
                    </p>
                  </div>
                </div>
              ))}
        </div>
      </div>
      <div className="more">
        <p onClick={changeToParkTab}>更多資訊</p>
      </div>
      </div>
    </div>
  );
};
