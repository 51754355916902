import React, { useMemo } from 'react';
import styled from "styled-components";
import { ResponsiveLineCanvas } from '@nivo/line';
import { thousandDivider } from "../../utility/common";

const LineChart = ({data, Unit}) => {
  const min = useMemo(() => {
    return Math.min(...data[0].data.map(item => item.y))
  }, [data]);
  const max = useMemo(() => {
    return Math.max(...data[0].data.map(item => item.y)) || 0
  }, [data]);
  return (
    <div style={{height: 200}}>
      <ResponsiveLineCanvas
        colors={['#25758A']}
        data={data}
        tooltip={(point) => {
          return (
            <DataTooltip>
              {`${point.point.data.x} 年：${(Unit === "千元" && ((point.point.data.y / 100000) >= 1)) ? thousandDivider((point.point.data.y / 100000).toFixed(2)) : thousandDivider(point.point.data.y)} ${Unit !== "千元" ? Unit : (point.point.data.y / 100000) >= 1 ? '億元' : '千元'}`}
            </DataTooltip>
          )
        }}
        axisBottom={{
          legendPosition: "middle",
          legendOffset: 30,
          legend: "年",
        }}
        axisLeft={{
          tickValues: 6,
          legendPosition: "middle",
          legendOffset: -60,
          legend: Unit !== "千元" ? Unit : (min / 100000) >= 1 ? '億元' : '千元',
          format: function(value){ 
            return (min / 100000) >= 1 ? thousandDivider(value / 100000) : thousandDivider(value);
          }
        }}
        margin={{
          top: 20,
          right: 20,
          left: 70,
          bottom: 50,
        }}
        yScale={{
          type: 'linear',
          stacked: false,
          max: max + 100,
          min: min - 100,
        }}
        enableGridX={false}
        curve="monotoneX"
      />
    </div>
  )
};

const DataTooltip = styled.div`
  background: white;
  padding: 8px 12px;
  border: 1px solid #ccc;
`;

export default LineChart;
