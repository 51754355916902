import * as actionTypes from './actionTypes'
import {
    getTypeFourCode,
    getAllPark
} from '../../utility/api'


export const initMapSelection = () => async dispatch => {
    dispatch({type:actionTypes.INIT_MAP_SELECTION})

    const parks = await getAllPark()
    const fourCode = await getTypeFourCode()

    if(Array.isArray(parks) && Array.isArray(fourCode)){
        dispatch({
          type: actionTypes.INIT_MAP_SELECTION_SUCCESS,
          industry: fourCode,
          parks: parks
        });
    }else if(!Array.isArray(parks)){
        dispatch({
            type:actionTypes.INIT_MAP_SELECTION_FAIL,
            error:'獲取產業園區資料時產生問題'
        })
    }else if(!Array.isArray(fourCode)){
        dispatch({
          type: actionTypes.INIT_MAP_SELECTION_FAIL,
          error: "獲取產業資料時產生問題",
        });
    }else{
        dispatch({
          type: actionTypes.INIT_MAP_SELECTION_FAIL,
          error: "發生問題",
        });
    }


}