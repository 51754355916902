import React from "react";
import { useSelector } from "react-redux";
import MobileGrid from "../../MobileGridV2";
import * as Style from "../../styles";
import { Card } from "react-bootstrap";
import { thousandDivider } from "../../../../utility/common";
import Swal from "sweetalert2";
import styled from "styled-components";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const NegativeNews = ({ data, negTitle, referenceType }) => {
  const { referenceInfo } = useSelector((state) => state.app);
  const toggleCard = {};
  const openReferenceInfoAlert = (type) => {
    let content = "";
    if (referenceInfo[type] === "") {
      console.log("I was called!");
      Swal.fire({
        title: "引用資料來源",
        text: "資料正在建置中 ... ",
      });
    } else {
      try {
        referenceInfo[type]
          .split("。")
          .map(
            (referenceItem) =>
              (content +=
                "<p style='text-align:left'>" + referenceItem + "</p>")
          );
        Swal.fire({
          title: "引用資料來源",
          html: content,
        });
      } catch (err) {
        Swal.fire({
          title: "引用資料來源",
          text: "資料正在建置中 ... ",
        });
      }
    }
  };

  return (
    <div>
      <Card.Header className="card-title">
        <TradeMarker>
            {negTitle}
            {toggleCard && referenceType && ( // 如果有 toggleCard 就顯示（位置差異）
              <button
                title={`開啟${negTitle}引用資料來源彈出視窗`}
                style={{ color: "white", borderRadius: "100%", marginLeft: "4px" }}
                onClick={() => openReferenceInfoAlert(referenceType)}  
              > 
                <FontAwesomeIcon
                  className="icon-reference"
                  icon={faInfoCircle}
                />
              </button>
            )}
        </TradeMarker>
      </Card.Header>
      {negTitle === '違反勞基法' &&
        <MobileGrid>
          {data.map((item, index) => (
            <Style.MobileGridContainerItem
              item
              component={Style.MobileGridItem}
              key={item.BusinessNo + index}
            >
              <Style.MobileGridItemList>
                <Style.MobileGridItemListLabel>公告日期 </Style.MobileGridItemListLabel>
                <Style.MobileGridItemListText>
                  {item.announcement_date ? item.announcement_date : "無相關資料"}
                </Style.MobileGridItemListText>
              </Style.MobileGridItemList>
              <Style.MobileGridItemList className="patent-name">
                <Style.MobileGridItemListLabel>處分日期 </Style.MobileGridItemListLabel>
                <Style.MobileGridItemListText>
                  {item.penalty_date
                    ? item.penalty_date.replaceAll("T00:00:00", "")
                    : "無相關資料"}
                </Style.MobileGridItemListText>
              </Style.MobileGridItemList>
              <Style.MobileGridItemList>
                <Style.MobileGridItemListLabel>違反法規條款 </Style.MobileGridItemListLabel>
                <Style.MobileGridItemListText>
                  {item.violation_clause
                    ? item.violation_clause
                    : "無相關資料"}
                </Style.MobileGridItemListText>
              </Style.MobileGridItemList>
              <Style.MobileGridItemList>
                <Style.MobileGridItemListLabel>法條敘述 </Style.MobileGridItemListLabel>
                <Style.MobileGridItemListText>
                  {item.law_description
                    ? item.law_description
                    : "無相關資料"}
                </Style.MobileGridItemListText>
              </Style.MobileGridItemList>
              <Style.MobileGridItemList>
                <Style.MobileGridItemListLabel>罰鍰金額(新臺幣) </Style.MobileGridItemListLabel>
                <Style.MobileGridItemListText>
                  {((item.penalty_amount !== 0) && (item.penalty_amount))
                    ? thousandDivider(item.penalty_amount)
                    : "無相關資料"}
                </Style.MobileGridItemListText>
              </Style.MobileGridItemList>
            </Style.MobileGridContainerItem>
          ))}
        </MobileGrid>
      }
      {negTitle === '違反性平法、職安法、就服法、中高齡就促法' &&
        <MobileGrid>
          {data.map((item, index) => (
            <Style.MobileGridContainerItem
              item
              component={Style.MobileGridItem}
              key={item.BusinessNo + index}
            >
              <Style.MobileGridItemList>
                <Style.MobileGridItemListLabel>公告日期 </Style.MobileGridItemListLabel>
                <Style.MobileGridItemListText>
                  {item.announcement_date ? item.announcement_date : "無相關資料"}
                </Style.MobileGridItemListText>
              </Style.MobileGridItemList>
              <Style.MobileGridItemList className="patent-name">
                <Style.MobileGridItemListLabel>處分日期 </Style.MobileGridItemListLabel>
                <Style.MobileGridItemListText>
                  {item.penalty_date
                    ? item.penalty_date.replaceAll("T00:00:00", "")
                    : "無相關資料"}
                </Style.MobileGridItemListText>
              </Style.MobileGridItemList>
              <Style.MobileGridItemList>
                <Style.MobileGridItemListLabel>違反法規條款 </Style.MobileGridItemListLabel>
                <Style.MobileGridItemListText>
                  {item.violation_clause
                    ? item.violation_clause
                    : "無相關資料"}
                </Style.MobileGridItemListText>
              </Style.MobileGridItemList>
              <Style.MobileGridItemList>
                <Style.MobileGridItemListLabel>法條敘述 </Style.MobileGridItemListLabel>
                <Style.MobileGridItemListText>

                  {item.law_description
                    ? item.law_description
                    : "無相關資料"}
                </Style.MobileGridItemListText>
              </Style.MobileGridItemList>
            </Style.MobileGridContainerItem>
          ))}
        </MobileGrid>
      }
      {negTitle === '違反勞退條例、災保法' &&
        <MobileGrid>
          {data.map((item, index) => (
            <Style.MobileGridContainerItem
              item
              component={Style.MobileGridItem}
              key={item.BusinessNo + index}
            >
              <Style.MobileGridItemList>
                <Style.MobileGridItemListLabel>公告日期 </Style.MobileGridItemListLabel>
                <Style.MobileGridItemListText>
                  {item.announcement_date ? item.announcement_date : "無相關資料"}
                </Style.MobileGridItemListText>
              </Style.MobileGridItemList>
              <Style.MobileGridItemList className="patent-name">
                <Style.MobileGridItemListLabel>處分日期 </Style.MobileGridItemListLabel>
                <Style.MobileGridItemListText>
                  {item.penalty_date
                    ? item.penalty_date.replaceAll("T00:00:00", "")
                    : "無相關資料"}
                </Style.MobileGridItemListText>
              </Style.MobileGridItemList>
              <Style.MobileGridItemList>
                <Style.MobileGridItemListLabel>違反法規條款 </Style.MobileGridItemListLabel>
                <Style.MobileGridItemListText>
                  {item.violation_clause
                    ? item.violation_clause
                    : "無相關資料"}
                </Style.MobileGridItemListText>
              </Style.MobileGridItemList>
              <Style.MobileGridItemList>
                <Style.MobileGridItemListLabel>法條敘述 </Style.MobileGridItemListLabel>
                <Style.MobileGridItemListText>

                  {item.law_description
                    ? item.law_description
                    : "無相關資料"}
                </Style.MobileGridItemListText>
              </Style.MobileGridItemList>
              <Style.MobileGridItemList>
                <Style.MobileGridItemListLabel>處分金額／滯納金(臺幣) </Style.MobileGridItemListLabel>
                <Style.MobileGridItemListText>
                  {item.fine_amount !== 0
                    ? thousandDivider(item.fine_amount)
                    : "無相關資料"}
                </Style.MobileGridItemListText>
              </Style.MobileGridItemList>
            </Style.MobileGridContainerItem>
          ))}
        </MobileGrid>
      }
    </div>
  );
};

const TradeMarker = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
`;

export default React.memo(NegativeNews);
