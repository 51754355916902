import styled from "styled-components";

export const DashBoardContainer = styled.div`
  // display: grid;
  // grid-template-columns: 1fr;
  // width: 100%;
`;

export const CardContainer = styled.div`
  display: grid;
  grid-template-columns: ${(p) => (p.column ? "1fr 1fr 1fr" : "1fr 1fr")};
  column-gap: 20px;
  row-gap: 20px;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;
