export const START_GOOGLE_SIGN_IN = "START_GOOGLE_SIGN_IN";
export const GOOGLE_SIGN_IN_SUCCESS = "GOOGLE_SIGN_IN_SUCCESS";
export const GOOGLE_SIGN_IN_FAIL = "GOOGLE_SIGN_IN_FAIL";

export const START_CHECK_IS_ACCOUNT_EXIST = "START_CHECK_IS_ACCOUNT_EXIST";
export const CHECK_IS_ACCOUNT_EXIST_SUCCESS = "CHECK_IS_ACCOUNT_EXIST_SUCCESS";
export const CHECK_IS_ACCOUNT_EXIST_FAIL = "CHECK_IS_ACCOUNT_EXIST_FAIL";

export const START_GOOGLE_ACCOUNT_DELETE = "START_GOOGLE_ACCOUNT_DELETE";
export const GOOGLE_ACCOUNT_DELETE_SUCCESS = "GOOGLE_ACCOUNT_DELETE_SUCCESS";
export const GOOGLE_ACCOUNT_DELETE_FAIL = "GOOGLE_SIGN_IN_FAIL";

export const START_FETCH_RESTRICTED_NAME = `START_FETCH_RESTRICTED_NAME`;
export const FETCH_RESTRICTED_NAME_SUCCESS = `FETCH_RESTRICTED_NAME_SUCCESS`;
export const FETCH_RESTRICTED_NAME_FAIL = `FETCH_RESTRICTED_NAME_FAIL`;