import React from "react";
import styled from "styled-components";
import { Popup, Marker } from "react-leaflet";

import * as theme from "../../assets/theme"

const CustomPopUp = ({
  latitude, 
  longitude,
  company_name,
  owner,
  address,
  business_no,
  indu_four_str,
  product,
  stock_type,
}) => {
  const markerRef = React.useRef(null);
  const popUpRef = React.useRef(null);
  const url = window.location.origin;

  const handleOpenFactoryPage = () => {
    const url = window.location.origin;
    window.open(`${url}/factory_info/basic_info/${business_no}`);
  };

  // const openDetailCard = (uid) => {
  //   toggleDetailCard();
  // };

  // React.useEffect(() => {
  //   updateMarkersRef(Uid, markerRef);
  // }, [markerRef]);

  // React.useEffect(() => {
  //   if (popUpRef) savePopUpRef(Uid, popUpRef);
  // }, [popUpRef]);

  return (
    <Marker
      position={[latitude, longitude]}
      key={latitude+longitude+business_no}
      // onClick={() => showedCompanyHandler(Uid)}
    >
      <PopUps autoPan={false}>
        <PopUpsWrapper>
          <PopUpsRow>
            <PopUpsRowTitle>工廠名稱</PopUpsRowTitle>
            <PopUpsRowText>{company_name}</PopUpsRowText>
          </PopUpsRow>
          <PopUpsRow>
            <PopUpsRowTitle>公司地址</PopUpsRowTitle>
            <PopUpsRowText>{address}</PopUpsRowText>
          </PopUpsRow>
          <PopUpsRow>
            <PopUpsRowTitle>產業別四碼</PopUpsRowTitle>
            <PopUpsRowText>{indu_four_str}</PopUpsRowText>
          </PopUpsRow>
          <PopUpsRow>
            <PopUpsRowTitle>工廠主要產品</PopUpsRowTitle>
            <PopUpsRowText>{product}</PopUpsRowText>
          </PopUpsRow>
          <PopUpsRowsFlexEnd>
            <PopUpsRowMoreText onClick={handleOpenFactoryPage}>公司資訊</PopUpsRowMoreText>
          </PopUpsRowsFlexEnd>
        </PopUpsWrapper>
      </PopUps>
    </Marker>
  );
};

const PopUps = styled(Popup)``;

const PopUpsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow-wrap: break-word;
  left: -10px;
  padding: 10px;


  @media(max-width: 550px) {
    width: 90% !important;
    margin: 0px !important;
    padding: 0px !important;
  } 
`;

const PopUpsRow = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 5px 0px 1px 0px;
  margin: 5px 0px;
`

const PopUpsRowTitle = styled.h4`
  font-size: 1rem;
  font-weight: 600;
  overflow-wrap: break-word;
  width: 100px;
`
const PopUpsRowText = styled.p`
  font-size: 1rem;
  margin: 0px !important;
  @media(max-width: 550px) {
    font-size: 1.25rem;
  } 
`

const PopUpsRowsFlexEnd = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 5px 0px 1px 0px;
  margin: 5px 0px;
  justify-content: flex-end;
`

const PopUpsRowMoreText = styled(PopUpsRowText)`
  cursor: pointer;
  font-size: 0.5rem;
  color: ${theme.linkBlue};
  transition: 0.2s;
  transform: translateX(-10px);

  &:hover {
    transition: 0.2s;
    color: ${theme.linkHoveredBlue};
  }

  @media(max-width: 750px) {
    font-size: 1.125rem !important;
  } 
`

export default React.memo(CustomPopUp);
