import React, { useMemo } from 'react';
import styled from "styled-components";
import { Box } from "@material-ui/core";
import { ResponsiveBar } from '@nivo/bar';
import { thousandDivider } from "../../utility/common";

const BarChart = ({chartData, keyTarget, indexBy, innerWidth}) => {
  const colorArray = ["#013a63", "#014f86", "#2c7da0", "#61a5c2"];
  const parseIndex = {
    Name: "產業別",
    Code: "貨品碼",
    Country: "國家"
  }
  const min = useMemo(() => {
    return Math.min(...chartData.map(item => item[keyTarget]))
  }, [keyTarget, chartData]);
  const maxString = useMemo(() => {
    return Math.max(...chartData.map(item => item[indexBy].length ))
  }, [indexBy, chartData]);
  return (<div style={{ height: 350 }}>
    <ResponsiveBar
      data={chartData}
      groupMode="grouped"
      keys={keyTarget}
      indexBy={indexBy}
      padding={0.4}
      margin={{
        top: 10,
        bottom:  (50 + maxString * 3),
        left: 50,
        right: 40
      }}
      valueScale={{ type: "linear" }}
      colors={colorArray}
      colorBy="index"
      animate={true}
      enableLabel={false}
      axisTop={null}
      axisRight={null}
      tooltip={(point) => {
        return (
          <DataTooltip>
            {`${indexBy !=="Code"? point.data[indexBy] : point.data.Name} - ${thousandDivider((point.data[keyTarget] / 100000).toFixed(3))} ${(min / 100000) > 1 ? '億元' : '千元'}`}
          </DataTooltip>
        )
      }} 
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 32,
        legend: parseIndex[indexBy],
        legendPosition: "middle",
        legendOffset:  (30 + maxString * 4)
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        tickValues: 6,
        legend: (min / 100000) > 1 ? '億元' : '千元',
        legendPosition: "middle",
        legendOffset: -40,
        format: function(value){ 
          return (min / 100000) > 1 ? thousandDivider(value / 100000) : thousandDivider(value);
        }
      }}
    />
  </div>)
};

const ColumnBox = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  box-shadow: 2px 2px 6px 2px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  height: 100%;
  margin: ${props => props.marginCustom || "0px"};
  background: ${props => props.bgColor || "none"};
  color: ${props => props.colorCustom || "none"};
  .subTitle {
    font-weight: bold !important;
  }
  p {
    line-height: 1.5;
  }
  .unit {
    margin-left: 8px;
  }
`;
const DataTooltip = styled.div`
  background: white;
  padding: 8px 12px;
  border: 1px solid #ccc;
`
const InfoBox = styled(Box)`
  cursor: pointer;
  &:hover {
    opacity: 0.75;
  }
`;

export default BarChart;
