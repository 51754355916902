import React from "react";
import { useSelector } from "react-redux";
import { Card } from "react-bootstrap";
import CustomizedCard from "../../../components/CustomizedCard/CustomizedCardV3";
import FactoryBasicInfoTable from "../../../components/CustomizedTable/FactoryBasicInfoTable";
import FactoryIndustryType from "../../../components/CustomizedTable/FactoryInfo/FactoryIndustryType";
import FactoryIndustryTypes from "../../../components/CustomizedTable/FactoryInfo/FactoryIndustryTypes";
import FactoryIndustryTax from "../../../components/CustomizedTable/FactoryInfo/FactoryIndustryTax";
import FactoryMoreInfo from "../../../components/CustomizedTable/FactoryInfo/FactoryMoreInfo";
import FactoryIntroduction from "../../../components/CustomizedTable/FactoryInfo/FactoryIntroduction";
import FactoryMainProduction from "../../../components/CustomizedTable/FactoryInfo/FactoryMainProduction";
import FactoryCompanyInvestBackTW from "../../../components/CustomizedTable/FactoryInfo/FactoryCompanyInvestBackTW";
import FactoryRecruitInfo from "../../../components/CustomizedTable/FactoryInfo/FactoryRecruitInfo";

const FactoryBasicInfo = () => {
  const { getUpdateCompanyInfoData, getCompanyAbstractData, getCompanyAbstractLoading, basicLoading, basicInfo, factoryTaxInfo, factoryTaxInfoLoading , companyInvestBackTW, companyInvestBackTWLoading, ForeignTrades4CodeProduct, ForeignTrades4CodeProductLoading, getEnterpriseQualifiedData, getEnterpriseQualifiedLoading, getTrademarkData, getTrademarkDataLoading} =
    useSelector((state) => state.factory);
  const { innerWidth } = useSelector((state) => state.app);

    return (
    <React.Fragment>
      <Card >
        <CustomizedCard
          title="基本資料"
          referenceType={"factoryInoBasic"}
          loading={getCompanyAbstractLoading}
        >
          <FactoryBasicInfoTable 
            data={basicInfo}
            getCompanyAbstractData={getCompanyAbstractData}
            UpdateCompany = {getUpdateCompanyInfoData}
            EnterpriseQualifiedData = {getEnterpriseQualifiedData}
            trademarker={getTrademarkData} />
        </CustomizedCard>
      </Card>
      <Card >
        <CustomizedCard
          title="行業標準分類"
          referenceType={"factoryInfoIndustryType"}
          loading={getCompanyAbstractLoading}
        >
          <FactoryIndustryType
            data={basicInfo}
            getCompanyAbstractData={getCompanyAbstractData}
          />
        </CustomizedCard>
      </Card>
      <Card >
        <CustomizedCard
          title="行業標準分類 (細類)"
          referenceType={"factoryInfoIndustryCategory"}
          loading={getCompanyAbstractLoading}
        >
          <FactoryIndustryTypes
            data={basicInfo.IndustryTypes ? basicInfo.IndustryTypes : []}
            getCompanyAbstractData={getCompanyAbstractData}
          />
        </CustomizedCard>
      </Card>
      <Card >
        <CustomizedCard
          title="稅務行業分類"
          referenceType={"factoryInfoTaxCategory"}
          loading={factoryTaxInfoLoading}
        >
          <FactoryIndustryTax data={factoryTaxInfo} />
        </CustomizedCard>
      </Card>
      {companyInvestBackTW.length > 0 && (
        <Card >
          <CustomizedCard
            title="投資臺灣三大方案資料"
            referenceType={"CompanyInvestBackTW"}
            loading={companyInvestBackTWLoading}
          >
            <FactoryCompanyInvestBackTW
              data={companyInvestBackTW}
              headerList={
                innerWidth > 1024
                  ? ["分類", "日期", "投資內容", "連結"]
                  : ["分類", "日期", "投資內容"]
              }
            />
          </CustomizedCard>
        </Card>
      )}
      {window.innerWidth > 1024 ? (
        <Card >
          <CustomizedCard
            title="更多資料"
            referenceType={"factoryInfoMore"}
            loading={basicLoading}
          >
            <FactoryMoreInfo 
              data={basicInfo}
              UpdateCompany={getUpdateCompanyInfoData} 
              enterpriseQualifiedData={getEnterpriseQualifiedData}
            />
          </CustomizedCard>
        </Card>
      ) : (
        <React.Fragment>
          <Card >
            <CustomizedCard
              title="公司簡介"
              referenceType={"factoryInfoCompanyBrief"}
              loading={basicLoading}
            >
              <FactoryIntroduction data={basicInfo} />
            </CustomizedCard>
          </Card>
          <Card >
            <CustomizedCard
              title="產品內容"
              referenceType={"factoryInfoCompanyProduct"}
              loading={basicLoading}
            >
              <FactoryMainProduction data={basicInfo} />
            </CustomizedCard>
          </Card>
          <Card style={(basicInfo && basicInfo.JobsWeb !== "無相關資料") ? {} : {display: "none"}}>
            <CustomizedCard
              title="徵才網址"
              referenceType={"factoryInfoCompanyProduct"}
              loading={basicLoading}
            >
              <FactoryRecruitInfo data={basicInfo} />
            </CustomizedCard>
          </Card>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default React.memo(FactoryBasicInfo);