import React from "react";
import styled from "styled-components";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import AddCommentIcon from '@mui/icons-material/AddComment';
import FormControlLabel from "@mui/material/FormControlLabel";
import AddIcon from "@mui/icons-material/Add";
import Close from "@mui/icons-material/Close";
import { useSelector, useDispatch } from "react-redux";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Swal from "sweetalert2";
import IconButton from "@mui/material/IconButton";
import { useSnackbar } from "notistack";
import Tooltip from "@mui/material/Tooltip";

import {
  handleGetDashboardList,
  handleGetDashboardCond,
  handleCreateDashboardList,
  handleCopyDashboard,
  handleDeleteDashboard,
  handleUploadDashboardCond
} from "../../store/actions/custom_industry_dashboard";
import SetTargetIndustryModal from "../../components/CustomIndustryDashboard/SetTargetIndustryModal";
import SetGoodsModal from "../../components/CustomIndustryDashboard/SetGoodsModal";
import { message } from "../../utility/common";

import SlideModal from "../../components/SlideModal";
import Spinner from "../../components/Spinner/Spinner";
import DashboardListItem from "../../components/CustomIndustryDashboard/DashboardListItem";

import * as theme from "../../assets/theme";

const themeColor = createTheme({
  palette: {
    primary: {
      main: "#25758A",
    },
  },
});

const CustomDashboardList = () => {
  const {
    getDashboardCond,
    getDashboardCond_loading,
    getDashboardList,
    getDashboardList_loading,
    createDashboard_status,
    createDashboard_loading,
    uploadDashboardCond_status,
    uploadDashboardCond_loading,
  } = useSelector((state) => state.custom_industry_dashboard);

  const { innerWidth } = useSelector(state => state.app);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [state, setState] = React.useState({
    add_type: "create", // 新增儀表板
    is_add_dashboard_open: false, //新增儀表板Modal
    set_industry_modal_open: false, //設定目標產業Modal
    set_goods_modal_import_open: false, //設定進口Modal
    set_goods_modal_export_open: false, //設定出口Modal
    create_dashboard_name: "",
    opened_dashboard: "",
    opened_dashboard_name: "",
    Info: "",
    Importcountry: "美國", // 進口國別
    Exportcountry: "美國", // 出口國別
    ImportSelected: [],
    ExportSelected: [],
    IndustryCode: [],
    IndustryList: [],
  });

  React.useEffect(() => {
    dispatch(handleGetDashboardList());
  }, []);

  React.useEffect(() => {
    setState((state) => ({ ...state, IndustryList: getDashboardList }));
  }, [getDashboardList]);

  React.useEffect(() => {
    dispatch(handleGetDashboardCond(state.opened_dashboard));
  }, [state.opened_dashboard]);

  React.useEffect(() => {
    setState((state) => ({
      ...state,
      ImportSelected: getDashboardCond.Import4Code || [],
      ExportSelected: getDashboardCond.Export4Code || [],
      IndustryCode: getDashboardCond.IndustryStr || [],
    }));
  }, [getDashboardCond]);

  {/* 開啟Modal */ }
  const handleToggleModal = (name) => {
    setState((state) => ({ ...state, [name]: !state[name], create_dashboard_name: "" }));
  };

  {/* 新增儀表板Modal文字 */ }
  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((state) => ({ ...state, [name]: value }));
  };

  {/* 新增儀表板 */ }
  const handleCreateCustomDashboard = () => {
    let currentYear = new Date().getFullYear();
    if (state.add_type === "create") {
      // 新增儀表板
      dispatch(handleCreateDashboardList(state.create_dashboard_name, currentYear - 2, "預設文字"));

      setTimeout(() => {
        if (!createDashboard_loading && createDashboard_status !== "") {
          message(
            enqueueSnackbar,
            `${createDashboard_status}`,
            "error"
          );
        } else {
          dispatch(handleGetDashboardList())
          message(
            enqueueSnackbar,
            `${state.create_dashboard_name}新增成功!`,
            "success"
          );
        }
      }, 1000);
    } else {
      // 複製儀表板功能
      dispatch(handleCopyDashboard(state.create_dashboard_name)).then(() => {
        dispatch(handleGetDashboardList())
        message(enqueueSnackbar, "複製儀表板成功", "success");
      }).catch(err => {
        message(enqueueSnackbar, "複製儀表板失敗", "error");
      });
    }
    setState((state) => ({
      ...state,
      create_dashboard_name: "",
      Info: "",
      is_add_dashboard_open: false,
    }));
  };

  {/* 刪除儀表板 */ }
  const handle_delete_board = (board_id) => {
    var board_name = state.IndustryList.filter((i) => i.BoardID === board_id)[0].BoardName;
    Swal.fire({
      title: `確定要刪除${board_name}專案？`,
      text: "專案刪除後將無法復原。",
      showCancelButton: true,
      confirmButtonColor: "#25758A",
      cancelButtonColor: "#bcb9b9",
      confirmButtonText: "確定刪除",
      cancelButtonText: "取消",
    }).then(async (result) => {
      if (result.isConfirmed) {
        dispatch(handleDeleteDashboard(board_id));
        message(enqueueSnackbar, `刪除${board_name}成功!`, "success");
        dispatch(handleGetDashboardList());
      }
    }).catch(err => {
      message(enqueueSnackbar, "刪除儀表板失敗", "error");
    });
  };

  {/* 開啟新增Modal */ }
  const handle_open_create_board = () => {
    handleToggleModal("is_add_dashboard_open");
  };

  {/* 開啟更新Modal */ }
  const handle_update_board = (board_id, board_name, description) => {
    setState((state) => ({ ...state, opened_dashboard: board_id, opened_dashboard_name: board_name, Info: description }));
    handleToggleModal("set_industry_modal_open");
  };

  {/* 關閉Modal */ }
  const handle_close_modal = (name) => {
    dispatch(handleGetDashboardList());
    setState((state) => ({ ...state, [name]: false }));

    if (name === "set_industry_modal_open") {
      setState((state) => ({
        ...state, IndustryCode: [],
        opened_dashboard: "",
        opened_dashboard_name: "",
        Info: "",
      }));
    }

    if (name === "set_goods_modal_import_open") {
      setState((state) => ({
        ...state,
        Importcountry: "墨西哥",
        Exportcountry: "中華民國",
        ImportSelected: [],
        ExportSelected: []
      }));
    }

    if (name === "set_goods_modal_export_open") {
      setState((state) => ({
        ...state,
        Importcountry: "墨西哥",
        Exportcountry: "中華民國",
        ImportSelected: [],
        ExportSelected: []
      }));
    }
  }

  const handleModalChange = (event, value, type) => {
    if (type === "Importcountry" || type === "Exportcountry") {
      setState((state) => ({ ...state, [type]: event.target.value }));
    } else {
      if (value.length <= 5) {
        setState((state) => ({ ...state, [type]: value }));
      }
      else if (value.length > 5){
        alert("最多選擇5筆");
        return;
        
      }
    }
  };

  const handleUpload = (event, value, type) => {
    dispatch(handleUploadDashboardCond(state.opened_dashboard, state.opened_dashboard_name, state.Info, state.IndustryCode, state.ExportSelected, state.ImportSelected));
    setTimeout(() => {
      if (!uploadDashboardCond_loading && uploadDashboardCond_status !== "") {
        message(
          enqueueSnackbar,
          `${uploadDashboardCond_status}`,
          "error"
        );
      } else {
        dispatch(handleGetDashboardList())
        message(
          enqueueSnackbar,
          `${state.opened_dashboard_name}產業設定成功!`,
          "success"
        );
      }
    }, 1000);
    setState((state) => ({
      ...state,
      opened_dashboard: "",
      opened_dashboard_name: "",
      Info: "",
      Importcountry: "墨西哥",
      Exportcountry: "中華民國",
      ImportSelected: [],
      ExportSelected: [],
      IndustryCode: [],
      set_industry_modal_open: false,
      set_goods_modal_import_open: false,
      set_goods_modal_export_open: false,
    }));
  };

  {/* 儀表板內容 */ }
  let content;
  if (getDashboardList_loading || createDashboard_loading) {
    content = (
      <CustomDashboardListContentContainer>
        <Spinner />
      </CustomDashboardListContentContainer>
    );
  } else {
    if (state.IndustryList.length === 0) {
      content = (
        <CustomDashboardListContentContainer>
          <CustomDashboardListContentNoDataText>
            目前無自訂關注產業儀表板資料，您可以點選右上角按鈕來新增自訂關注產業儀表板。
          </CustomDashboardListContentNoDataText>
        </CustomDashboardListContentContainer>
      );
    } else {
      content = (
        <CustomDashboardListContentContainer is_grid>
          {state.IndustryList.sort((a, b) => new Date(b.UpdateDate) - new Date(a.UpdateDate)).map((i) => (
            <DashboardListItem
              key={i.BoardID}
              board_id={i.BoardID}
              board_name={i.BoardName}
              description={i.Indu_Description}
              handle_delete_board={handle_delete_board}
              handle_update_board={handle_update_board}
              industry_count={i.IndustryCnts}
              update_time={i.UpdateDate.split("T")[0]}
            />
          ))}
        </CustomDashboardListContentContainer>
      );
    }
  };

  {/* 新增儀表板按鈕設定 */ }
  let create_dashboard_btn;
  if (state.IndustryList.length === 3) {
    create_dashboard_btn = (
      <Tooltip title={"儀表板數量限制3個"}>
        <StyledButton
          onClick={handle_open_create_board}
          startIcon={<AddIcon />}
          disabled
        >
          新增儀表板
        </StyledButton>
      </Tooltip>
    )
  } else {
    create_dashboard_btn = (
      <StyledButton
        onClick={handle_open_create_board}
        startIcon={<AddIcon />}
      >
        新增儀表板
      </StyledButton>
    )
  }

  return (
    <CustomDashboardListWrapper>
      {/* 設定目標產業 */}
      <SlideModal
        is_open={state.set_industry_modal_open}
      >
        <SetTargetIndustryModal
          IndustryCode={state.IndustryCode}
          setState={setState}
          handleModalChange={handleModalChange}
          handleOpenGoodsModal={() =>
            handleToggleModal("set_goods_modal_import_open")
          }
          handle_close_modal={() => handle_close_modal("set_industry_modal_open")}
          select_option_data={getDashboardCond}
        />
      </SlideModal>
      {window.innerWidth >= 1024&&(
        <SlideModal
          is_open={state.set_goods_modal_import_open}
        >
          <SetGoodsModal
            handleModalChange={handleModalChange}
            handle_close_modal={() => handle_close_modal("set_goods_modal_import_open")}
            handleUpload={handleUpload}
            state={state}
          />
        </SlideModal>
      )}
      {window.innerWidth < 1024&&(
      <>
        <SlideModal
          is_open={state.set_goods_modal_import_open}
        >
          <SetGoodsModal
            handleModalChange={handleModalChange}
            handleOpenExportModal={() =>
              handleToggleModal("set_goods_modal_export_open")
            }
            handle_close_modal={() => handle_close_modal("set_goods_modal_import_open")}
            state={state}
            modalName={"import"}
          />
        </SlideModal>
        <SlideModal
          is_open={state.set_goods_modal_export_open}
        >
          <SetGoodsModal
            handleModalChange={handleModalChange}
            handle_close_modal={() => handle_close_modal("set_goods_modal_export_open")}
            handleUpload={handleUpload}
            state={state}
            modalName={"export"}
          />
        </SlideModal>
      </>
      )}

      {/* 新增儀表板 */}
      <StyledSlideModal is_open={state.is_add_dashboard_open}>
        <CustomDashboardListDetailModalWrapper>
          <CustomDashboardListDetailModalCloseContainer>
            <IconButton
              onClick={() => {
                handleToggleModal("is_add_dashboard_open");
              }}
            >
              <Close />
            </IconButton>
          </CustomDashboardListDetailModalCloseContainer>
          <CustomDashboardListDetailModalHeadContainer>
            <CustomDashboardListDetailModalHeadText>
              新增儀表板
            </CustomDashboardListDetailModalHeadText>
          </CustomDashboardListDetailModalHeadContainer>
          <CustomDashboardListDetailModalSubHeadContainer>
            <CustomDashboardListDetailModalHeadSubText>
              [請輸入儀表板名稱及欲關注的產業說明，新增完成後請至儀表板選單中新增欲關注的產業。]
            </CustomDashboardListDetailModalHeadSubText>
          </CustomDashboardListDetailModalSubHeadContainer>
          <FormControl>
            <RadioGroup
              row
              name="row-radio-buttons-group"
              value={state.add_type}
              onChange={(e) => {
                setState(state => ({ ...state, add_type: e.target.value }))
              }}
            >
              <FormControlLabel value="create" control={<Radio size="small" />} label="新增新儀表板" />
              <FormControlLabel value="copy" control={<Radio size="small" />} label="加入其他人的儀表板編號" />
            </RadioGroup>
          </FormControl>
          <CustomDashboardListDetailModalInputContainer>
            <TextField
              label={state.add_type === "create" ? "儀表板名稱" : "欲複製的儀表板編號"}
              variant="outlined"
              size="small"
              sx={innerWidth < 750 ? { width: "60%" } : { width: "70%" }}
              value={state.create_dashboard_name}
              name={"create_dashboard_name"}
              onChange={handleChange}
            />
            <StyledButton
              disabled={state.add_type === "create" && !state.create_dashboard_name}
              onClick={handleCreateCustomDashboard}
              sx={{ marginLeft: "20px" }}
            >
              新增儀表板
            </StyledButton>
          </CustomDashboardListDetailModalInputContainer>
        </CustomDashboardListDetailModalWrapper>
      </StyledSlideModal>


      {/* 儀表板主板 */}
      <CustomDashboardListHeadContainer>
        <CustomDashboardListHeadTextContainer>
          <CustomDashboardListHeadText>自訂關注產業儀表板</CustomDashboardListHeadText>
          {create_dashboard_btn}
        </CustomDashboardListHeadTextContainer>
        <CustomDashboardListSubHeadTextContainer>
          <CustomDashboardListSubHeadText>
            提供您可以將欲關注的產業加入儀表板中，每位使用者上限有<span>3個</span>專屬的儀表板。
          </CustomDashboardListSubHeadText>
        </CustomDashboardListSubHeadTextContainer>
      </CustomDashboardListHeadContainer>
      {content}
    </CustomDashboardListWrapper>
  );
};

const StyledSlideModal = styled(SlideModal)`
  height: fit-content !important;
`;

const CustomDashboardListWrapper = styled.div`
  width: 100vw;
  height: calc(100vh - 40px);
  overflow: hidden;
  transform: translateY(40px);
  padding: 20px 40px;
  background-color: #f7f3f3;
  @media (max-width: 1195px) {
    padding: 20px 20px;
  }

  @media (max-width: 850px) {
    transform: translateY(0px);
    height: 100%;
    position: fixed;
    top: 70px;
    bottom: 0;
  }
`;

const CustomDashboardListContentContainer = styled.div`
  display: ${(p) => (p.is_grid ? "grid" : "flex")};
  justify-content: ${(p) => (p.is_grid ? "flex-start" : "center")};
  align-items: ${(p) => (p.is_grid ? "flex-start" : "center")};
  height: 60vh;
  width: 100%;
  padding: ${(p) => (p.is_grid ? "20px 0px 0px 0px" : "0px")};
  
  grid-template-columns: repeat(auto-fill, 300px);
  column-gap: 20px;

  @media (max-width: 750px) {
    grid-template-columns: 1fr;
    height: 55vh;
    overflow-y: scroll;
    row-gap: 20px;
    padding-bottom: 40px;
  }
`;

const CustomDashboardListContentNoDataText = styled.p`
  font-size: 1rem;
  font-weight: 400;
  line-height: 25px;
  color: ${theme.grayBorderColor};
`;

const CustomDashboardListHeadContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-start;
`;

const CustomDashboardListHeadTextContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0px;
`;

const CustomDashboardListSubHeadTextContainer = styled.div`
  padding: 5px 0px 20px 0px;
  border-bottom: 1px solid ${theme.grayBorderColor};
  width: 100%;
`;

const CustomDashboardListHeadText = styled.h1`
  font-size: 2rem;
  font-weight: 600;
  @media (max-width: 439px) {
    margin-bottom: 16px;
  }
`;

const CustomDashboardListSubHeadText = styled.h3`
  font-size: 1rem;
  font-weight: 400;
  line-height: 25px;
  >span{
    color:var(--main-color);
    padding: 0px 2px;
    font-weight: 600;
  }
  @media (max-width: 750px) {
    font-size: 0.875rem;
    line-height: 20px;
  }
`;

const StyledButton = styled(Button)`
  background-color: ${(p) =>
    p.disabled ? theme.grayBorderColor : theme.linkBlue} !important;
  color: ${theme.white} !important;

  &:hover {
    background-color: ${(p) =>
    p.disabled ? theme.grayBorderColor : theme.linkBlue} !important;
  }
`;

const CustomDashboardListDetailModalWrapper = styled.div`
  width: 45%;
  background-color: ${theme.white};
  border-radius: 20px;
  padding: 20px 30px;

  @media (max-width: 750px) {
    width: 100%;
  }
`;

const CustomDashboardListDetailModalCloseContainer = styled.div`
  width: calc(100%-60px);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  z-index: 100;
`;

const CustomDashboardListDetailModalHeadContainer = styled.div`
  width: calc(100%-60px);
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  transform: translateY(-20px);
`;

const CustomDashboardListDetailModalHeadText = styled.h2`
  font-size: 2rem;
  font-weight: 600;
  letter-spacing: 2px;
`;

const CustomDashboardListDetailModalSubHeadContainer = styled.div`
  width: calc(100%-60px);
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const CustomDashboardListDetailModalHeadSubText = styled.h2`
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 2px;
  transform: translateY(-10px);
  line-height: 20px;

  > span {
    font-weight: 600;
    color: ${theme.primaryColor};
  }
`;

const SubTitle = styled.h1`
  font-size: 1.25rem;
  font-weight: bold;
`;

const CustomDashboardListDetailModalInputContainer = styled.div`
  width: calc(100%-60px);
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  padding: 10px 0px;
  position: relative;
`;

const CustomDashboardListWithTheme = () => {
  return (
    <ThemeProvider theme={themeColor}>
      <CustomDashboardList />
    </ThemeProvider>
  );
};

export default CustomDashboardListWithTheme;
