import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import Icon from "@material-ui/core/Icon";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faLink } from "@fortawesome/free-solid-svg-icons";

import "./styles.scss";

const ParkMainIndexListTable = (props) => {
  const { data, headerList } = props;
  const [state, setState] = React.useState({
    endIndex: 0,
    page: 0,
    rowsPerPage: 13,
  });
  const [toggleOrder, setToggleOrder] = React.useState({
    FactoryNum: false,
    ProductVal: false,
    EmpCount: false
  });
  const url = window.location.origin + "/park_dashboard/";

  let content;

  React.useEffect(() => {
    if (Math.ceil(data.length) === state.page) {
      if (data.length % 13 === 0) {
        setState((state) => ({
          ...state,
          endIndex: state.page * state.rowsPerPage + state.rowsPerPage,
        }));
      } else {
        setState((state) => ({
          ...state,
          endIndex: state.page * state.rowsPerPage + (data.length % 13),
        }));
      }
    } else {
      setState((state) => ({
        ...state,
        endIndex: state.page * state.rowsPerPage + state.rowsPerPage,
      }));
    }
  }, [state.page]);

  if (data.length > 13) {
    content = data
      .slice(state.page * 13, state.page * 13 + 13)
      .map((row, index) => {
        return (
          <TableRow
            key={url + row.ParkId}
            hover
            className="table-body-row"
            style={index % 2 !== 0 ? { backgroundColor: "#ececec" } : {}}
          >
            <TableCell
              align="left"
              className="table-body-header"
              onClick={() => openNewTabs(url + row.ParkId)}
            >
              {row.Name}
            </TableCell>
            <TableCell align="right">
              {row.FactoryNum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </TableCell>
            <TableCell align="right">
              {row.ProductVal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </TableCell>
            <TableCell align="right">
              {row.EmpCount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </TableCell>
          </TableRow>
        );
      });
  } else {
    content = data.map((row, index) => {
      return (
        <TableRow
          key={url + row.ParkId}
          hover
          className="table-body-row"
          style={index % 2 !== 0 ? { backgroundColor: "#ececec" } : {}}
        >
          <TableCell
            align="left"
            className="table-body-header"
            onClick={() => openNewTabs(url + row.ParkId)}
          >
            {row.Name}
          </TableCell>
          <TableCell align="right">
            {row.FactoryNum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
          </TableCell>
          <TableCell align="right">
            {row.ProductVal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
          </TableCell>
          <TableCell align="right">
            {row.EmpCount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
          </TableCell>
        </TableRow>
      );
    });
  }

  const handleChangePage = (event, newPage) => {
    setState((state) => ({ ...state, page: newPage }));
  };

  const openNewTabs = (link) => window.open(link, "_blank");

  const parseColumnKey = (column) => {
    const parseColumn = {
      "廠商數(家)": "FactoryNum",
      "產值(億)":"ProductVal",
      "員工數":"EmpCount"
    }
    return  parseColumn[column]
  }
  const handleOrder = (column) => {
    let key = parseColumnKey(column)
    if (data[0]?.[key]) {
      data.sort((a, b) => toggleOrder[key]? a[key] - b[key] : b[key] - a[key])
    }
    const updateToggle = { ...toggleOrder };
    updateToggle[key] = !toggleOrder[key];
    setToggleOrder(updateToggle);
  }
  return (
    <TableContainer className="table-container">
      <Table className="table">
        <TableHead className="table-header">
          {headerList && (
            <TableRow className="table-row">
              {headerList.map((headerItem, i) => (
                <TableCell className="table-cell" align={i === 0 ? "left" : "right"}>
                  {headerItem}
                  {
                    i > 0 &&
                    <Icon
                      style={{ verticalAlign: "bottom" }}
                      className="icon"
                      onClick={() => handleOrder(headerItem)}
                    >
                      {toggleOrder[parseColumnKey(headerItem)] ? "arrow_drop_up" : "arrow_drop_down"}
                    </Icon>
                  }
                </TableCell>
              ))}
            </TableRow>
          )}
        </TableHead>
        <TableBody className="table-body">
          {data.length !== 0 && content}
        </TableBody>
      </Table>
      {data.length === 0 && (
        <Table>
          <TableBody className="table-body-no-data">
            <TableRow hover className="table-body-row">
              <TableCell align="center" className="no-data">
                本項目無資料
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      )}
      {data.length > 13 && (
        <TablePagination
          rowsPerPageOptions={[]}
          component="div"
          count={data.length}
          rowsPerPage={state.rowsPerPage}
          page={state.page}
          onChangePage={handleChangePage}
          // onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      )}
    </TableContainer>
  );
};

export default React.memo(ParkMainIndexListTable);
