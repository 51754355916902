import axios from "axios";
import { baseUrl, loginbaseUrl } from "../constants/endpoints";
import axiosInstance from "./axiosWithRefreshToken";

const headers = {
  "Access-Control-Allow-Origin": "*",
  "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
  "Access-Control-Allow-Headers":
    "Access-Control-Allow-Headers, Origin, Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers",
  "Content-Type": "application/json",
  Authorization: localStorage.getItem("InduParkWebAcToken"),
};
// 訂閱推播

// 訂閱廠商：新聞
export const fetchAccountSubscribeGetNews = (userId) => {
  return axiosInstance({
    proxy: true,
    method: "get",
    url: `${loginbaseUrl}api/AccountSubscribe/Company/GetNews/?UserId=${userId}`,
    headers: headers,
  }).then((res) => {
    try {
      return res.data;
    } catch (e) {
      return res.data;
    }
  })
  .catch((err) => {
    return "error";
  });
};
// 訂閱廠商：進出口級距
export const fetchAccountSubscribeGetCompanyForeignTrade = (userId) => {
  return axiosInstance({
    proxy: true,
    method: "get",
    url: `${loginbaseUrl}api/AccountSubscribe/Company/GetCompanyForeignTrade?UserId=${userId}`,
    headers: headers,
  }).then((res) => {
    try {
      return res.data;
    } catch (e) {
      return res.data;
    }
  })
  .catch((err) => {
    return "error";
  });
};
// 訂閱廠商：上市櫃公司營收
export const fetchAccountSubscribeGetCompanyStockRevenue = (userId) => {
  return axiosInstance({
    proxy: true,
    method: "get",
    url: `${loginbaseUrl}api/AccountSubscribe/Company/GetCompanyStockRevenue_Net?UserId=${userId}`,
    headers: headers,
  }).then((res) => {
    try {
      return res.data;
    } catch (e) {
      return res.data;
    }
  })
  .catch((err) => {
    return "error";
  });
};
// 訂閱廠商：專利
export const fetchAccountSubscribeGetCompanyPatent= (userId) => {
  return axiosInstance({
    proxy: true,
    method: "get",
    url: `${loginbaseUrl}api/AccountSubscribe/Company/GetCompanyPatent?UserId=${userId}`,
    headers: headers,
  }).then((res) => {
    try {
      return res.data;
    } catch (e) {
      return res.data;
    }
  })
  .catch((err) => {
    return "error";
  });
};
// 訂閱廠商：政府計畫
export const fetchAccountSubscribeGetCompanyGovProject= (userId) => {
  return axiosInstance({
    proxy: true,
    method: "get",
    url: `${loginbaseUrl}api/AccountSubscribe/Company/GetCompanyGovProject?UserId=${userId}`,
    headers: headers,
  }).then((res) => {
    try {
      return res.data;
    } catch (e) {
      return res.data;
    }
  })
  .catch((err) => {
    return "error";
  });
};

// 訂閱產業：取得帳號訂閱產業
export const fetchAccountSubscribeIndustryList = (userId) => {
  return axiosInstance({
    proxy: true,
    method: "get",
    url: `${loginbaseUrl}api/AccountSubscribe/Industry/GetAccountIndustryList?UserId=${userId}`,
    headers: headers,
  }).then((res) => {
    try {
      return res.data;
    } catch (e) {
      return res.data;
    }
  })
  .catch((err) => {
    return "error";
  });
};
// 訂閱產業：新聞
export const fetchAccountSubscribeNews = (userId) => {
  return axiosInstance({
    proxy: true,
    method: "get",
    url: `${loginbaseUrl}api/AccountSubscribe/Industry/GetNews?UserId=${userId}`,
    headers: headers,
  }).then((res) => {
    try {
      return res.data;
    } catch (e) {
      return res.data;
    }
  })
  .catch((err) => {
    return "error";
  });
};
// 訂閱產業：進出口級距
export const fetchAccountSubscribeForeignTrade = (userId) => {
  return axiosInstance({
    proxy: true,
    method: "get",
    url: `${loginbaseUrl}api/AccountSubscribe/Industry/GetIndustryForeignTrade?UserId=${userId}`,
    headers: headers,
  }).then((res) => {
    try {
      return res.data;
    } catch (e) {
      return res.data;
    }
  })
  .catch((err) => {
    return "error";
  });
};
// 訂閱產業：上市櫃公司營收
export const fetchAccountStockRevenue = (userId) => {
  return axiosInstance({
    proxy: true,
    method: "get",
    url: `${loginbaseUrl}api/AccountSubscribe/Industry/GetIndustryStockRevenue_Net?UserId=${userId}`,
    headers: headers,
  }).then((res) => {
    try {
      return res.data;
    } catch (e) {
      return res.data;
    }
  })
  .catch((err) => {
    return "error";
  });
};
// 訂閱產業：專利
export const fetchAccountSubscribePatent = (userId) => {
  return axiosInstance({
    proxy: true,
    method: "get",
    url: `${loginbaseUrl}api/AccountSubscribe/Industry/GetIndustryPatent?UserId=${userId}`,
    headers: headers,
  }).then((res) => {
    try {
      return res.data;
    } catch (e) {
      return res.data;
    }
  })
  .catch((err) => {
    return "error";
  });
};
// 訂閱產業：政府計畫
export const fetchAccountSubscribeGovProject = (userId) => {
  return axiosInstance({
    proxy: true,
    method: "get",
    url: `${loginbaseUrl}api/AccountSubscribe/Industry/GetIndustryGovProject?UserId=${userId}`,
    headers: headers,
  }).then((res) => {
    try {
      return res.data;
    } catch (e) {
      return res.data;
    }
  })
  .catch((err) => {
    return "error";
  });
}