import React, { useEffect } from "react";
import SignaturePad from "react-signature-canvas";
import Icon from "@material-ui/core/Icon";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Modals from "../../components/CustomizedModal/CustomizedModal";
import QuestionPreview from "../Questionnaire/QuestionPreview";
import { photoUrl } from "../../constants/endpoints";
import { handleUploadVisitQuestionImage, handleGetVisitQuestionImage } from "../../store/actions/question";
import { useSelector, useDispatch } from "react-redux";

import * as Style from "./styles";

const SignaturePadContainer = (props) => {
  const { handleClose, result, UploadQuestionData, handleOpenQRcode, tempStorage } = props;
  const {
    getVisitQuestionImage,
  } = useSelector((state) => state.question);
  const dispatch = useDispatch();
  const signSupervisorPad = React.useRef();
  const signSupervisorPad_2 = React.useRef();
  const signSupervisorPad_3 = React.useRef();
  const signParticipantsPad = React.useRef();
  const signParticipantsPad_2 = React.useRef();
  const signParticipantsPad_3 = React.useRef();
  const [state, setState] = React.useState({
    conclusion: "",
    isPreviewOpen: false,
    Img1: [],
  });

  React.useEffect(() => {
    setState((state) => ({
      ...state,
      conclusion: result[4].conclusion,
    }));
  }, []);

  const SignaturePadProps =
    window.innerWidth < 750
      ? { width: window.innerWidth / 1.35, height: 200 }
      : window.innerWidth < 1024
        ? { width: window.innerWidth / 2, height: 200 }
        : { width: window.innerWidth / 3, height: 200 };

  // 簽名板的 clear
  const padClear = (target) => {
    target.current.clear();
  };

  const generateCanvasSignature = () => {
    const padArray = [signSupervisorPad, signSupervisorPad_2, signSupervisorPad_3, signParticipantsPad, signParticipantsPad_2, signParticipantsPad_3];
      const padArrayString = ['signSupervisorPad', 'signSupervisorPad_2', 'signSupervisorPad_3', 'signParticipantsPad', 'signParticipantsPad_2', 'signParticipantsPad_3'];
      padArray.forEach((pad, index) => {
        const str = padArrayString[index];
        if (!result[4]?.[`${str}`]) {
          const padURL = pad.current
            .getCanvas()
            .toDataURL("image/png");
          result[4][`${str}`] = padURL;
        }
      })
    // if (!result[4].signSupervisorPad) {
    //   const supervisorPadURL = signSupervisorPad.current
    //     .getCanvas()
    //     .toDataURL("image/png");
    //   result[4].signSupervisorPad = supervisorPadURL;
    // }
    // if (!result[4].signParticipantsPad) {
    //   const participantsPadURL = signParticipantsPad.current
    //     .getCanvas()
    //     .toDataURL("image/png");
    //   result[4].signParticipantsPad = participantsPadURL;
    // }
  }

  const handleOpenPreview = (isFinished, e) => {
    if (!result[5].phoneInterview) {
      const padArray = [signSupervisorPad, signSupervisorPad_2, signSupervisorPad_3, signParticipantsPad, signParticipantsPad_2, signParticipantsPad_3];
      const padArrayString = ['signSupervisorPad', 'signSupervisorPad_2', 'signSupervisorPad_3', 'signParticipantsPad', 'signParticipantsPad_2', 'signParticipantsPad_3'];
      padArray.forEach((pad, index) => {
        const str = padArrayString[index];
        const previewConst = str.replace(/^./, str[0].toUpperCase()); 
        if (!result[4]?.[`${str}`]) {
          const padURL = pad.current
            .getCanvas()
            .toDataURL("image/png");
          result[4][`preview${previewConst}`] = padURL;
        }
      })
    }

    if (!isFinished) {
      // dispatch(handleGetVisitQuestionImage(result[4].Qid));
      
      setState((state) => ({
        ...state,
        isPreviewOpen: true,
      }));
      if (state.Img1.length > 0) {
        [0, 1, 2].forEach((item) => {
          const param = `questionVisitImg${item + 1}`;
          if (state.Img1[item]?.preview?.includes("VisitImage")) {
            result[4][param] = state.Img1[item]?.preview;
          } else {
            result[4][param] = state.Img1[item]?.preview? state.Img1[item].preview : "";
          }
        });
      } else {
        result[4].questionVisitImg1 = "";
        result[4].questionVisitImg2 = "";
        result[4].questionVisitImg3 = "";
      }
    }
  };

  const handleClosePreview = () => {
    setState((state) => ({ ...state, isPreviewOpen: false }));
  };
  const handleUpdateFiled = (type, e) => {
    switch (type) {
      case "selectImg":
        const files = e.target.files; // 從事件中獲取選擇的文件
        // 多選，最多可以選到 3 張
        if ((state.Img1.length + files.length) > 3) {
          alert("最多只能選擇三張照片")
          return;
        }
        let imgArr = [...state.Img1];
        for (var i = 0; i < files.length; i++) {
          const preview = URL.createObjectURL(
            new Blob([files[i]], { type: "image/png" })
          );
          imgArr.push({
            files: files[i],
            preview: preview,
          });
        }
        setState((state) => ({ ...state, Img1: imgArr }));
        break;
      case "uploadImg":
        let fileArr = [];
        let submitImageArr = state.Img1.filter((item) => item.files !== "");
        submitImageArr.forEach((item) => {
          fileArr.push(item.files);
        });
        if (fileArr.length !== 0) {
          dispatch(handleUploadVisitQuestionImage(result[5].uuid, fileArr));
        }
        break;
      default:
        result[4].conclusion = e.target.value;
        setState((state) => ({ ...state, conclusion: e.target.value }));
        break;
    }
  };
  useEffect(() => {
    let arr = [];
    if (getVisitQuestionImage && getVisitQuestionImage.length > 0) {
      getVisitQuestionImage.forEach((item) => {
        arr.push({
          files: "",
          preview: `${photoUrl}/VisitImage/${item?.FileName}`,
        });
      });
      setState((state) => ({ ...state, Img1: arr }));
    } else {
      setState((state) => ({ ...state, Img1: [] }));
    }
  }, [getVisitQuestionImage])
  useEffect(() => {
    const pathnameParts = window.location.pathname.split('/');
    const editPart = pathnameParts[pathnameParts.length - 2];
    const lastPart = pathnameParts[pathnameParts.length - 1];
    if (editPart === "edit" && lastPart) {
      dispatch(handleGetVisitQuestionImage(lastPart));
    }
    if (result[5].uuid) {
      dispatch(handleGetVisitQuestionImage(result[5].uuid));
    }
  }, []);
  return (
    <Style.SignaturePadModalContainer
      width={"50%"}
      height={"90%"}
      margin={"30px 25%"}
    >
      <Style.SignaturePadModalContainerHeader>
        <Icon onClick={handleClose}>close</Icon>
      </Style.SignaturePadModalContainerHeader>

      <Style.SignaturePadContainer>
        {!tempStorage && (
          <Style.SignatureFeedbackContainer
            style={
              window.innerWidth < 750
                ? { width: window.innerWidth / 1.2 }
                : window.innerWidth < 1024
                  ? { width: window.innerWidth / 2 }
                  : { width: window.innerWidth / 3 }
            }
          >
            <Style.SignaturePadContainerTitle>
              訪談照片上傳 (上限3張)
            </Style.SignaturePadContainerTitle>
            <p style={{ margin: "12px 0%", lineHeight: "1.3" }}>
            圖檔類型必須是 jpg , jpeg , png, heic，單一檔案不得超過 20MB。
            </p>
            <div style={{ margin: "5% 0%" }}>
              <Button>
                <input
                  type="file"
                  multiple
                  max={3}
                  accept=".heic, .png, .jpg, .jpeg"
                  onChange={(e) => handleUpdateFiled("selectImg", e)}
                />
              </Button>
            </div>
            <Box display="flex" mb={2}>
              {
                state.Img1.map((item, index) => {
                  return (
                    <div style={{ marginRight: "8px", width: "30%", position: "relative" }}>
                      {
                        !item.preview.includes("VisitImage") && <IconButton variant="contained" color="error" aria-label="delete" size="small" style={{
                          position: "absolute",
                          top: 0,
                          right: 0,
                        }} onClick={() => {
                          let imgArr = [...state.Img1];
                          imgArr.splice(index, 1);
                          setState((state) => ({ ...state, Img1: imgArr }));
                        }}>
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      }
                      
                      <img
                        src={item.preview}
                        style={{ width: "100%" }}
                        alt="preview"
                      />
                    </div>
                  );
                })
              }
            </Box>
          </Style.SignatureFeedbackContainer>
        )}
        <Style.SignatureFeedbackContainer
          style={
            window.innerWidth < 750
              ? { width: window.innerWidth / 1.2 }
              : window.innerWidth < 1024
                ? { width: window.innerWidth / 2 }
                : { width: window.innerWidth / 3 }
          }
        >
          <Style.SignaturePadContainerTitle>
            訪談總結
          </Style.SignaturePadContainerTitle>
          <Style.SignatureFeedback
            multiline
            rows={6}
            id="outlined-basic"
            variant="outlined"
            value={state.conclusion}
            onChange={(e) => handleUpdateFiled(null, e)}
          />
        </Style.SignatureFeedbackContainer>

        {!result[5].phoneInterview && (
          <>
            <Style.SignaturePadInnerContainer>
              <Style.SignaturePadInnerText>
                受訪人簽名
              </Style.SignaturePadInnerText>
              <Style.SignaturePadInnerPad>
                {result[4]?.signSupervisorPad ? (
                  <>
                    {!result[4]?.signSupervisorPad?.includes("RespondSign")
                      ? (<img src={result[4]?.previewSignSupervisorPad} loading="lazy" alt="" />)
                      : (<img src={`${photoUrl}/VisitSign/${result[4]?.signSupervisorPad}`} loading="lazy" alt="" />)}
                  </>
                ) : (
                  <>
                    <Style.SignaturePadWrapper style={SignaturePadProps}>
                      <SignaturePad
                        canvasProps={SignaturePadProps}
                        ref={signSupervisorPad}
                      />

                    </Style.SignaturePadWrapper>
                    <Style.QuestionBasicButton
                      onClick={() => {
                        padClear(signSupervisorPad);
                      }}
                      backgroundColor={"#81C0C0"}
                      clearButton
                    >
                      clear
                    </Style.QuestionBasicButton>
                  </>
                )}
              </Style.SignaturePadInnerPad>
              <Style.SignaturePadInnerPad>
                {result[4]?.signSupervisorPad_2 ? (
                  <>
                    {!result[4]?.signSupervisorPad_2?.includes("RespondSign_2")
                      ? (<img src={result[4]?.previewSignSupervisorPad_2} loading="lazy" alt="" />)
                      : (<img src={`${photoUrl}/VisitSign/${result[4]?.signSupervisorPad_2}`} loading="lazy" alt="" />)}
                  </>
                ) : (
                  <>
                    <Style.SignaturePadWrapper style={SignaturePadProps}>
                      <SignaturePad
                        canvasProps={SignaturePadProps}
                        ref={signSupervisorPad_2}
                      />

                    </Style.SignaturePadWrapper>
                    <Style.QuestionBasicButton
                      onClick={() => {
                        padClear(signSupervisorPad_2);
                      }}
                      backgroundColor={"#81C0C0"}
                      clearButton
                    >
                      clear
                    </Style.QuestionBasicButton>
                  </>
                )}
              </Style.SignaturePadInnerPad>
              <Style.SignaturePadInnerPad>
                {result[4]?.signSupervisorPad_3 ? (
                  <>
                    {!result[4]?.signSupervisorPad_3?.includes("RespondSign_3")
                      ? (<img src={result[4]?.previewSignSupervisorPad_3} loading="lazy" alt="" />)
                      : (<img src={`${photoUrl}/VisitSign/${result[4]?.signSupervisorPad_3}`} loading="lazy" alt="" />)}
                  </>
                ) : (
                  <>
                    <Style.SignaturePadWrapper style={SignaturePadProps}>
                      <SignaturePad
                        canvasProps={SignaturePadProps}
                        ref={signSupervisorPad_3}
                      />

                    </Style.SignaturePadWrapper>
                    <Style.QuestionBasicButton
                      onClick={() => {
                        padClear(signSupervisorPad_3);
                      }}
                      backgroundColor={"#81C0C0"}
                      clearButton
                    >
                      clear
                    </Style.QuestionBasicButton>
                  </>
                )}
              </Style.SignaturePadInnerPad>
            </Style.SignaturePadInnerContainer>
            <Style.SignaturePadInnerContainer>
              <Style.SignaturePadInnerText>
                訪談人員簽名
              </Style.SignaturePadInnerText>
              <Style.SignaturePadInnerPad>
                {result[4]?.signParticipantsPad ? (
                  <>
                    {!result[4]?.signParticipantsPad?.includes("VisitorSign")
                      ? (<img src={result[4]?.previewSignParticipantsPad} loading="lazy" alt="" />)
                      : (<img src={`${photoUrl}/VisitSign/${result[4]?.signParticipantsPad}`} loading="lazy" alt="" />)}
                  </>
                ) : (
                  <>
                    <Style.SignaturePadWrapper style={SignaturePadProps}>
                      <SignaturePad
                        canvasProps={SignaturePadProps}
                        ref={signParticipantsPad}
                      />
                    </Style.SignaturePadWrapper>
                    <Style.QuestionBasicButton
                      onClick={() => {
                        padClear(signParticipantsPad)
                      }}
                      backgroundColor={"#81C0C0"}
                      clearButton
                    >
                      clear
                    </Style.QuestionBasicButton>
                  </>
                )}
              </Style.SignaturePadInnerPad>
              <Style.SignaturePadInnerPad>
                {result[4]?.signParticipantsPad_2 ? (
                  <>
                    {!result[4]?.signParticipantsPad_2?.includes("VisitorSign")
                      ? (<img src={result[4]?.previewSignParticipantsPad_2} loading="lazy" alt="" />)
                      : (<img src={`${photoUrl}/VisitSign/${result[4]?.signParticipantsPad_2}`} loading="lazy" alt="" />)}
                  </>
                ) : (
                  <>
                    <Style.SignaturePadWrapper style={SignaturePadProps}>
                      <SignaturePad
                        canvasProps={SignaturePadProps}
                        ref={signParticipantsPad_2}
                      />
                    </Style.SignaturePadWrapper>
                    <Style.QuestionBasicButton
                      onClick={() => {
                        padClear(signParticipantsPad_2)
                      }}
                      backgroundColor={"#81C0C0"}
                      clearButton
                    >
                      clear
                    </Style.QuestionBasicButton>
                  </>
                )}
              </Style.SignaturePadInnerPad>
              <Style.SignaturePadInnerPad>
                {result[4]?.signParticipantsPad_3 ? (
                  <>
                    {!result[4]?.signParticipantsPad_3?.includes("VisitorSign")
                      ? (<img src={result[4]?.previewSignParticipantsPad_3} loading="lazy" alt="" />)
                      : (<img src={`${photoUrl}/VisitSign/${result[4]?.signParticipantsPad_3}`} loading="lazy" alt="" />)}
                  </>
                ) : (
                  <>
                    <Style.SignaturePadWrapper style={SignaturePadProps}>
                      <SignaturePad
                        canvasProps={SignaturePadProps}
                        ref={signParticipantsPad_3}
                      />
                    </Style.SignaturePadWrapper>
                    <Style.QuestionBasicButton
                      onClick={() => {
                        padClear(signParticipantsPad_3)
                      }}
                      backgroundColor={"#81C0C0"}
                      clearButton
                    >
                      clear
                    </Style.QuestionBasicButton>
                  </>
                )}
              </Style.SignaturePadInnerPad>
            </Style.SignaturePadInnerContainer>
          </>
        )}
      </Style.SignaturePadContainer>

      <Style.SignPadButtonContainer>
        {
          !tempStorage && <Style.QuestionBasicButton
          backgroundColor={"#2a7ac6"}
          onClick={async (e) => {
            var r = await window.confirm("儲存後簽名與圖片將無法修改，確定要儲存？");
            if (r === true) {
              handleOpenPreview(true);
              if (!result[5].phoneInterview) {
                generateCanvasSignature();
              }
              await UploadQuestionData(false);
              await handleUpdateFiled("uploadImg", e);
              await alert("暫存成功!");
              handleClose();
            } else {
              await alert("取消暫存");
            }
          }}
        >
          暫存
        </Style.QuestionBasicButton>
        }
        {!tempStorage && (
          <Style.QuestionBasicButton onClick={(e) => handleOpenPreview(null, e)}>
            預覽
          </Style.QuestionBasicButton>
        )}
        <Style.QuestionBasicButton
          backgroundColor={"#2a7ac6"}
          onClick={async (e) => {
            var r = await window.confirm(tempStorage ? "儲存後簽名將無法修改，確定要儲存?" : "確定要送出?");
            if (r === true) {
              handleOpenPreview(true);
              if (tempStorage) {
                if (!result[5].phoneInterview) {
                  generateCanvasSignature();
                }
                UploadQuestionData(false);
                await alert("暫存成功!");
              } else {
                if (!result[5].phoneInterview) {
                  await generateCanvasSignature();
                }
                await UploadQuestionData(true);
                await handleUpdateFiled("uploadImg", e);
                await handleOpenQRcode();
              }
              handleClose();
            } else {
              await alert("取消暫存");
            }
          }}
        >
          {tempStorage ? "暫存" : "完成送出"}
        </Style.QuestionBasicButton>
      </Style.SignPadButtonContainer>
      <Modals isOpen={state.isPreviewOpen} handleClose={handleClosePreview}>
        <QuestionPreview handleClose={handleClosePreview} result={result} />
      </Modals>
    </Style.SignaturePadModalContainer>
  );
};

export default SignaturePadContainer;
