export const baseUrl =
  process.env.NODE_ENV === "development" ||
  process.env.NODE_ENV === "localhost" ||
  process.env.REACT_APP_ENV === "development" ||
  process.env.REACT_APP_ENV === "localhost"
    ? "https://opendata-test.ddns.net"
    : "https://opendataservice.smartpark.com.tw";

export const loginbaseUrl =
  process.env.NODE_ENV === "development" ||
  process.env.NODE_ENV === "localhost" ||
  process.env.REACT_APP_ENV === "development" ||
  process.env.REACT_APP_ENV === "localhost"
    ? "https://induparkweb-test.ddns.net/"
    : "https://indupark.smartpark.com.tw/";
// export const loginbaseUrl ="https://induparkweb-stage.ddns.net:8068/"
export const photoUrl =
  process.env.NODE_ENV === "development" ||
  process.env.NODE_ENV === "localhost" ||
  process.env.REACT_APP_ENV === "development" ||
  process.env.REACT_APP_ENV === "localhost"
    ? "https://opendata-test.ddns.net"
    : "https://indupark.smartpark.com.tw/";
