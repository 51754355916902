import React, { useState, useEffect } from "react";
import {
  Icon,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Input,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";

import CustomizedTable from "../../components/CustomizedTable/CustomizedTable";
import CustomizedModal from "../../components/CustomizedModal/CustomizedModal";
import ClientRequestDetail from "../../components/ClientRequestDetail/ClientRequestDetail";
import {
  AuthQueryHandler,
  ClientRequestHandler,
  NoAuthQueryHandler,
  GetProjectHandleStatus,
  UpdateProjectHandleStatus,
  CaseClosedHandleStatus,
  getCasesByExpertId,
} from "../../utility/api";

import "./styles.scss";

const TalentHandler = (props) => {
  const history = useHistory();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [data, setData] = useState([]);
  const [rawData, setRawData] = useState();
  const [status, setStatus] = useState("fill");
  const [selectedCase, setSelectedCase] = useState([]);
  const [caseHandle, setCaseHandle] = useState([]);
  const [params, setParams] = useState({
    KeyWord: "",
    StartDate: "",
    EndDate: "",
    HandleType: "",
  });
  const { expertId, caseId } = props.match.params;

  const getCaseHandler = async (expertId, data) => {
    const caseList = await getCasesByExpertId(expertId, data);

    return caseList;
  };

  const getProjectContent = async (caseId) => {
    const projectHandleStatus = await GetProjectHandleStatus(caseId);

    if (projectHandleStatus.status === 200) {
      setCaseHandle(projectHandleStatus.data.data);
    } else {
      alert("取得案件狀態發生問題。");
    }
  };

  useEffect(() => {
    if (!expertId) {
      history.push("/portal");
    } else {
      setStatus("loading");
      getCaseHandler(expertId)
        .then((res) => {
          getProjectContent(caseId);

          const data = [];
          res.data.data.map((item) => {
            data.push({
              CompanyName: item.Contact.CompanyName,
              DisplayName: item.Contact.DisplayName,
              Phone: item.Contact.Phone,
              CaseId: item.Case.CaseId,
              HandleType: item.Case.HandleType,
              Date: item.Case.ApplyTime.split(" ")[0],
            });
          });
          setData(data);

          if (caseId) {
            const updated = res.data.data.filter(
              (data) => data.Case.CaseId === caseId
            )[0];

            if (!updated) {
              history.push("/portal");
            } else {
              setSelectedCase(updated);
              setIsModalOpen(true);
            }
          }
          setRawData(res.data.data);
          setStatus("finished");
        })
        .catch((err) => setStatus("error"));
    }
  }, [expertId, caseId]);

  const reload = () => {
    getCaseHandler(expertId).then((res) => {
      getProjectContent(caseId);

      const data = [];
      res.data.data.map((item) => {
        data.push({
          CompanyName: item.Contact.CompanyName,
          DisplayName: item.Contact.DisplayName,
          Phone: item.Contact.Phone,
          CaseId: item.Case.CaseId,
          HandleType: item.Case.HandleType,
          Date: item.Case.ApplyTime.split(" ")[0],
        });
      });
    });
  };

  const updateSearchParams = (event) => {
    const { value, name } = event.target;
    const updateParams = { ...params };

    updateParams[name] = value;

    setParams(updateParams);
  };

  const getFilteredData = (expertId) => {
    getCaseHandler(expertId, params).then((res) => {
      const data = [];
      res.data.data.map((item) => {
        data.push({
          CompanyName: item.Contact.CompanyName,
          DisplayName: item.Contact.DisplayName,
          Phone: item.Contact.Phone,
          CaseId: item.Case.CaseId,
          HandleType: item.Case.HandleType,
          Date: item.Case.ApplyTime.split(" ")[0],
        });
      });

      setData(data);
    });
  };

  console.log(rawData);

  // useEffect(()=>{
  //     const form = new FormData()
  //     form.append('KeyWord', "")
  //     form.append('isTech', "")
  //     form.append('IsProduct', "")
  //     form.append('IsTalent', "")
  //     form.append('IsMoney', "")
  //     form.append('IsLand', "")
  //     form.append('IsEnergy', "")
  //     form.append('StartDate', "")
  //     form.append('EndDate', "")
  //     form.append('HandleType', "")
  //     form.append('Email', "")
  //     form.append('ParkIds',  "")

  //     AuthQueryHandler(form)
  //         .then(res => {
  //             const data = []
  //             res.data.data.map(item => {
  //                 data.push({
  //                     CompanyName: item.Contact.CompanyName,
  //                     DisplayName: item.Contact.DisplayName,
  //                     Phone: item.Contact.Phone,
  //                     CaseId: item.Case.CaseId,
  //                     HandleType: item.Case.HandleType,
  //                     Date: item.Case.ApplyTime.split(" ")[0]
  //                 })
  //             })

  //             setData(data);
  //             setRawData(res.data.data);
  //         })
  //         .catch(err => {
  //             alert(`系統發生錯誤，請聯絡管理員。\n ${err}`)
  //         })
  // },[])

  const filteredSelectedProjectHandler = async (caseId) => {
    const updatedSelectedCase = rawData.filter(
      (data) => data.Case.CaseId === caseId
    )[0];
    setSelectedCase(updatedSelectedCase);

    const projectHandleStatus = await GetProjectHandleStatus(caseId);

    if (projectHandleStatus.status === 200) {
      await setCaseHandle(projectHandleStatus.data.data);
      setIsModalOpen(true);
    } else {
      alert("取得案件狀態發生問題。");
    }
  };

  return (
    <div className="client-response" style={{ marginTop: "5vh" }}>
      <div className="client-response-container">
        <div className="client-response-container-left">
          <div className="client-response-option-container">
            <div
              className="client-response-option-container-query"
              style={{
                fontSize: "250%",
                fontWeight: "600",
                borderBottom: "2px solid #FFFFFF",
              }}>
              專家分案管理
            </div>
            <div className="talent-description">
              <p>
                感謝您協助處理廠商需求案件，您可以透過點選右邊表格中的案件，填寫最新辦理情形。
              </p>
              <p>若有任何問題，可以聯繫平台窗口：</p>
              <p>金屬中心 陳小姐 07-3513121分機2415</p>
            </div>
          </div>
        </div>
        <div className="client-response-container-middle">
          <div
            className="client-response-container-middle-up"
            style={{ borderWidth: "57vh 0 0 10vw" }}
          />
          <div
            className="client-response-container-middle-down"
            style={{ borderWidth: "0 0 25vh 10vw" }}
          />
        </div>
        <div className="client-response-container-right">
          <div className="query-container-auth">
            <div className="query-container-auth-search">
              <div className="query-container-auth-search-left">
                <div className="query-container-auth-search-left-head">
                  <h3>需求管理</h3>
                </div>
                <div className="query-container-auth-search-left-keyword">
                  <label>關鍵字</label>
                  <input
                    onChange={(event) => updateSearchParams(event)}
                    value={params.KeyWord}
                    name={"KeyWord"}
                    placeholder="請輸入廠商名稱、案件編號或案件內容"
                  />
                </div>
                <div className="query-container-auth-search-left-start-date">
                  <label>查詢案件建立時間大於:</label>
                  <input
                    type="date"
                    onChange={(event) => updateSearchParams(event)}
                    value={params.StartDate}
                    name={"StartDate"}
                  />
                </div>
              </div>
              {window.innerWidth > 1024 && (
                <div className="query-container-auth-search-md">
                  <FormControl
                    className="query-container-auth-search-md-case-status"
                    variant="outlined">
                    <InputLabel className="select-label">案件狀態</InputLabel>
                    <Select
                      onChange={(event) => updateSearchParams(event)}
                      value={params.HandleType}
                      name={"HandleType"}
                      input={<Input type="select" />}>
                      <MenuItem value="" key="all">
                        {" "}
                        所有案件{" "}
                      </MenuItem>
                      <MenuItem value="辦理中" key="processing">
                        辦理中
                      </MenuItem>
                      <MenuItem value="結案" key="close">
                        結案
                      </MenuItem>
                    </Select>
                  </FormControl>
                  <div className="query-container-auth-search-md-end-date">
                    <label>查詢案件建立時間小於:</label>
                    <input
                      type="date"
                      onChange={(event) => updateSearchParams(event)}
                      value={params.EndDate}
                      name={"EndDate"}
                    />
                  </div>
                </div>
              )}
              {window.innerWidth < 1024 && (
                <div className="query-container-auth-search-md">
                  <div className="query-container-auth-search-md-end-date">
                    <label>結案時間</label>
                    <input
                      type="date"
                      onChange={(event) => updateSearchParams(event)}
                      value={params.EndDate}
                      name={"EndDate"}
                    />
                  </div>
                  <FormControl
                    className="query-container-auth-search-md-case-status"
                    variant="outlined">
                    <InputLabel className="select-label">案件狀態</InputLabel>
                    <Select
                      onChange={(event) => updateSearchParams(event)}
                      value={params.HandleType}
                      name={"HandleType"}
                      input={<Input type="select" />}>
                      <MenuItem value="all" key="all">
                        所有案件
                      </MenuItem>
                      <MenuItem value="辦理中" key="processing">
                        {" "}
                        辦理中
                      </MenuItem>
                      <MenuItem value="結案" key="close">
                        {" "}
                        結案
                      </MenuItem>
                    </Select>
                  </FormControl>
                </div>
              )}

              <div className="query-container-auth-search-right">
                <button
                  onClick={() => {
                    getFilteredData(expertId);
                  }}>
                  查詢
                </button>
              </div>
            </div>
            <div className="query-container-auth-table">
              <CustomizedTable
                type="client-request-table"
                loading={false}
                content={data}
                getProjectId={filteredSelectedProjectHandler}
                hover
              />
            </div>
            <div className="query-container-auth-mobile">
              {data.map((content) => (
                <div
                  className="query-container-auth-mobile-container"
                  onClick={() =>
                    filteredSelectedProjectHandler(content.CaseId)
                  }>
                  <div className="item">
                    <div className="item-title">案件時間 :</div>
                    <div className="item-content">{content.Date}</div>
                  </div>
                  <div className="item">
                    <div className="item-title">廠商名稱 :</div>
                    <div className="item-content">{content.CompanyName}</div>
                  </div>
                  <div className="item">
                    <div className="item-title">申請窗口 :</div>
                    <div className="item-content">{content.DisplayName}</div>
                  </div>
                  <div className="item">
                    <div className="item-title">聯絡電話 :</div>
                    <div className="item-content">{content.Phone}</div>
                  </div>
                  <div className="item">
                    <div className="item-title">案件狀態 :</div>
                    <div className="item-content">{content.HandleType}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* <div className="query-container-auth-table" style={{width:"90%", marginLeft:"5%"}}>
                        <CustomizedTable
                        type="client-request-table"
                        loading={false}
                        content={data}
                        getProjectId={filteredSelectedProjectHandler}
                        hover
                    />
                    </div> */}
        </div>
      </div>
      <CustomizedModal isOpen={isModalOpen}>
        <div className="form-modal">
          <ClientRequestDetail
            data={selectedCase}
            handleClose={() => {
              setIsModalOpen(false);
            }}
            handleStatus={caseHandle}
            reload={reload}
          />
        </div>
      </CustomizedModal>
    </div>
  );
};

export default TalentHandler;
