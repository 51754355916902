import styled, { keyframes } from "styled-components";
import { NavLink } from "react-router-dom";
import Card from "@material-ui/core/Card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const FactoryInfoPage = styled.div`
  position: absolute;
  top: 114px;
  display: flex;
  flex-direction: column;
  height: 1vh;
  margin: 10px 2.5% 0px 2.5%;
  width: 95%;

  @media (max-width: 1024px) {
    margin-top: 75px;
    top: 0px;
  }
`;

export const FactoryPage = styled.div`
  width: 100%;
  .factory-info-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .factory-info-header-company-name {
      display: flex;
      align-items: flex-end;
      justify-content: flex-start;

      .navigation-icon {
        font-size: 2rem;
        cursor: pointer;
        transition: 0.2s;

        &:hover {
          transition: 0.2s;
          color: var(--main-color);
        }
      }

      & > h1 {
        @include header-h1;
        font-size: 3rem;
        font-weight: 600;
      }
    }

    .export-buttons-container {
      width: 160px;

      .export-excel-btn {
        margin: 2px 0px;
        width: 100%;
        font-size: 0.5rem;
        background-color: var(--main-color);
        color: #fff;
      }

      .export-pdf-btn {
        margin: 2px 0px;
        width: 100%;
        font-size: 0.5rem;
        background-color: var(--main-color);
        color: #fff;
      }
    }
  }

  .factory-info-menu {
    > div {
      display: flex;
      height: 48px;
      justify-content: space-around;

      > div {
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0px;

        > a {
          line-height: 48px;
        }
      }
    }
  }

  @media (max-width: 1024px) {
    .factory-info-header {
      flex-direction: column;
      align-items: flex-start;
      .factory-info-header-company-name {
        margin: 5px;
        > h1 {
          font-size: 1.8rem;
        }
      }

      .export-buttons-container {
        display: none;
      }
    }
  }
`;

export const FactoryPageHeaderText = styled.h2`
  font-size: 3rem;
  font-weight: 700;
  letter-spacing: 2px;
  
  @media (max-width: 1024px) {
    font-size: 2.3rem;
    letter-spacing: 0px;
  }
`;

export const FactoryContainer = styled.div`
  /* padding: 20px 0px;
    flex-grow: 1;  
    display: flex;
    flex-direction: column; */
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  min-height: 61vh;
  gap: 20px;
  @media (max-width: 1024px) {
    min-height: 60vh;
  }
`;

const opcaityControl = keyframes`
  10% {
    transition: opacity 5s;  
    opacity: 0.1;
  }

  50% {
    transition: opacity 5s;  
    opacity: 0.7;
  }


  90% {
    transition: opacity 5s;  
    opacity: 0.1;
  }
`;

export const ArrowContainer = styled.div`
  display: none;
  @media (max-width: 750px) {
    display: ${(p) => (p.isDisplay ? "block" : "none")};
    position: sticky;
    left: ${(p) => p.left};
  }
`;

export const ArrowIcon = styled.img`
  width: 15px;
  height: 15px;
  animation: ${opcaityControl} 5s linear infinite;
`;

export const MenuContainer = styled.div`
  margin: 15px 0px;
  display: flex;
  align-items: center;
  width: auto;
  overflow-x: scroll;
  justify-content: space-between;
  background: #fff;
  position: relative;
  > a {
    text-decoration: none;
    /* position: absolute; */
  }
  .selected {
    font-weight: 900;
    color: #006ab4;
    border-bottom: 4px solid #006ab4;
  }

  @media (max-width: 1024px) {
    margin: 7px 0px;
  }
`;

export const CustomLink = styled(NavLink)`
  display: inline-block;
  word-break: keep-all;
  padding: 15px 30px;
  font-size: 1rem;
  color: #757575;
  opacity: ${(p) => (p.iswithdata === true ? "1" : "0.3")};
  @media (max-width: 1024px) {
    padding: 10px 20px;
  }
`;

export const MobileExportPDFContainer = styled.div`
  display: none;
  @media (max-width: 1024px) {
    width: 60px;
    height: 60px;
    background-color: rgba(46, 182, 170, 0.5);
    border-radius: 30px;
    position: fixed;
    display: flex !important;
    justify-content: center;
    align-items: center;
    bottom: 50px;
    left: 95px;
    z-index: 1000;
  }
`;

export const MobileExportPDFIcon = styled(FontAwesomeIcon)`
  color: #ffffff;
  font-size: 150%;
  cursor: pointer;
`;

export const NoAuthCard = styled(Card)`
  padding: 20px 10px;
`

export const NoAuthCardText = styled.p`
  color: #888888;
  padding: 10px;

  >button{
    color: #25758A;
    cursor: pointer;
    padding: 0px 5px;
    font-weight: 700;
  }
`