import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import DataLabourLawTable from "../../../components/CustomizedTable/DataLabourLawTable";
import DataGenderLawTable from "../../../components/CustomizedTable/DataGenderLawTable";
import DataRetirementTable from "../../../components/CustomizedTable/DataRetirementTable";
import CustomizedCard from "../../../components/CustomizedCard/CustomizedCardV3";
import MobileNegativeNews from "../../../components/MobileGrid/FactoryInfo/NegativeNews/NegativeNews";
import "./styles.scss";

export default (props) => {
  const { innerWidth } = useSelector((state) => state.app);
  const { getNegativeData, getNegativeDataLoading } = useSelector(
    (state) => state.factory
  );
  const url = window.location.origin;

  const openNewTabs = (link) => window.open(link, "_blank");

  var data_labour_law;
  var data_gender_law;
  var data_retirement;

  if (getNegativeData?.data_labour_law) {
    data_labour_law = [...getNegativeData.data_labour_law].sort((a, b) => {
      if (a.announcement_date > b.announcement_date) return -1;
      if (a.announcement_date < b.announcement_date) return 1;
    });
  }
  if (getNegativeData?.data_gender_law) {
    data_gender_law = [...getNegativeData.data_gender_law].sort((a, b) => {
      if (a.announcement_date > b.announcement_date) return -1;
      if (a.announcement_date < b.announcement_date) return 1;
    });
  }
  if (getNegativeData?.data_retirement) {
    data_retirement = [...getNegativeData.data_retirement].sort((a, b) => {
      if (a.announcement_date > b.announcement_date) return -1;
      if (a.announcement_date < b.announcement_date) return 1;
    });
  }
  return (
    <React.Fragment>
      {innerWidth > 1024 && !getNegativeDataLoading ? (
        <CustomizedCard
          title="違反勞基法"
          referenceType={"factoryInfoNegativeNews"}
        >
          <DataLabourLawTable
            headerList={[
              "公告日期",
              "處分日期",
              "違反法規條款",
              "法條敘述",
              "罰鍰金額(新臺幣)",
            ]}
            data={data_labour_law}
          />
        </CustomizedCard>
      ) : (
        (!getNegativeDataLoading && data_labour_law) && <MobileNegativeNews data={data_labour_law} negTitle={'違反勞基法'} referenceType={"factoryInfoNegativeNews"}/>
      )}
      {innerWidth > 1024 && !getNegativeDataLoading ? (
        <CustomizedCard
          title="違反性平法、職安法、就服法、中高齡就促法"
          referenceType={"factoryInfoNegativeNews"}
        >
          <DataGenderLawTable
            headerList={[
              "公告日期",
              "處分日期",
              "違反法規條款",
              "法條敘述",
            ]}
            data={data_gender_law}
          />
        </CustomizedCard>
      ) : (
        (!getNegativeDataLoading && data_gender_law) && <MobileNegativeNews data={data_gender_law} negTitle={'違反性平法、職安法、就服法、中高齡就促法'} referenceType={"factoryInfoNegativeNews"}/>
      )}
      {innerWidth > 1024 && !getNegativeDataLoading ? (
        <CustomizedCard
          title="違反勞退條例、災保法"
          referenceType={"factoryInfoNegativeNews"}
        >
          <DataRetirementTable
            headerList={[
              "公告日期",
              "處分日期",
              "違反法規條款",
              "法條敘述",
              "處分金額／滯納金(臺幣)",
            ]}
            data={data_retirement}
          />
        </CustomizedCard>
      ) : (
        (!getNegativeDataLoading && data_retirement) && <MobileNegativeNews data={data_retirement} negTitle={'違反勞退條例、災保法'} referenceType={"factoryInfoNegativeNews"}/>
      )}
    </React.Fragment>
  );
};