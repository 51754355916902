import React, { useState, useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  TextField,
} from "@material-ui/core";
import Swal from "sweetalert2";
import FullPage from "../../components/FullPage/FullPage";

import * as authAction from "../../store/actions/auth";

import loginPhoto from "../../assests/login-page.jpg";

import "./styles.scss";

const ForgetPassWord = (props) => {
  const [params, setParams] = useState({
    oldPass: "",
    newPass: "",
    newPassConfirm: "",
  });
  const dispatch = useDispatch();
  const IS_PASS_PHRASE = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[a-zA-Z\d!@#$%^&*]{12,20}$/;

  const setNewPassHandler = () => {
    const { oldPass, newPass, newPassConfirm } = params;
    const enc = localStorage.getItem("EncTicket");

    if (oldPass === newPass) {
      Swal.fire({
        text: `新密碼不得與舊密碼相同。`,
      })
      return;
    }

    if (!IS_PASS_PHRASE.test(newPass)) {
      Swal.fire({
        text: `密碼長度必須在 12 到 20 之間，需包含一個小寫字母、一個大寫字母、一個數字和一個符號(!@#$%^&*)`,
      })
      return;
    }

    if (newPass !== newPassConfirm) {
      Swal.fire({
        text: `新密碼與新密碼確認不符。`,
      })
      return;
    }

    dispatch(authAction.changePassWordHandler(oldPass, newPass));
  };

  const onChangeHandler = (event) => {
    const { value, name } = event.target;
    const updateParams = { ...params };

    updateParams[name] = value;

    setParams(updateParams);
  };
  return (
    <div className="forget-password">
      <FullPage bgPhoto={loginPhoto}>
        <>
          <div className="change-pass-title">
            <h2>修改密碼</h2>
          </div>
          <div className="change-pass-container">
            <TextField
              type="password"
              className="input"
              label="原密碼"
              variant="outlined"
              size="small"
              placeholder="請輸入原有密碼。"
              value={params.oldPass}
              onChange={onChangeHandler}
              name="oldPass"
            />
            <TextField
              type="password"
              className="input"
              label="新密碼"
              variant="outlined"
              size="small"
              placeholder="請輸入新密碼。"
              value={params.newPass}
              onChange={onChangeHandler}
              name="newPass"
            />
            <TextField
              type="password"
              className="input"
              label="新密碼確認"
              variant="outlined"
              size="small"
              placeholder="請輸入新密碼確認。"
              value={params.newPassConfirm}
              onChange={onChangeHandler}
              name="newPassConfirm"
            />
            <button
              className={
                !params.oldPass || !params.newPass || !params.newPassConfirm
                  ? "inactive-button"
                  : "active-button"
              }
              disabled={
                !params.oldPass || !params.newPass || !params.newPassConfirm
              }
              onClick={setNewPassHandler}
            >
              修改密碼
            </button>
          </div>
        </>
      </FullPage>
    </div>
  );
};

export default ForgetPassWord;