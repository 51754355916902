import React from "react";
import ReactDOM from "react-dom";
import Script from "react-load-script";
import * as Style from "./styles";

export default ({ keyword }) => {
  const handleScriptLoad = (_) => {
    window.trends.embed.renderExploreWidgetTo(
      document.getElementById("widget"),
      "TIMESERIES",
      {
        comparisonItem: [{ keyword, geo: "TW", time: "today 1-m" }],
        category: 3,
        property: "",
      },
      {
        exploreQuery: `cat=3&date=today%201-m&geo=TW&q=${encodeURI(keyword)}`,
        guestPath: "https://trends.google.com:443/trends/embed/",
      }
    );
  };

  const openNewTabs = (link) => window.open(link, "_blank");
  
  return (
    <React.Fragment>
      <Style.TrendsMain  id="widget">
        <Script
          url={"https://ssl.gstatic.com/trends_nrtr/2884_RC01/embed_loader.js"}
          onLoad={handleScriptLoad}
        />
      </Style.TrendsMain>
      <Style.TrendsText>
        若無法查看相關結果，請參考
        <a
          href="https://smartpark-guideline.gitbook.io/smartpark_guideline/chang-jian-wen-ti/jie-jue-wu-fa-cheng-xian-google-cu-shi-tu"
          target="_blank"
          rel="noopener noreferrer"
          title="另開新視窗，產業園區廠商情資平台操作指南"
        >
          這裡
        </a>
        說明
      </Style.TrendsText>
    </React.Fragment>
  );
};
