import * as actionType from "../actionTypes/question";
import {
  fetchQuestionItemHandler,
  fetchUploadVisitQuestionDataHandler,
  fetchLimitedVisitQuestionDataHandler,
  fetchVisitQuestionListHandler,
  fetchVisitQuestionDetailHandler,
  fetchDeleteVisitQuestionHandler,
  fetchUploadVisitQuestionImageHandler,
  fetchGetVisitQuestionImageHandler,
} from "../../utility/quesitonApi";
import { getFacNo } from "../../utility/api";

//拿取問卷題目
const startFetchDetailQuestion = () => {
  return {
    type: actionType.START_FETCH_DETAIL_QUESTION,
  };
};

const fetchDetailQuestionSuccess = (data) => {
  return {
    type: actionType.FETCH_DETAIL_QUESTION_SUCCESS,
    data: data,
  };
};

const fetchDetailQuestionFail = (state) => {
  return {
    type: actionType.FETCH_DETAIL_QUESTION_FAIL,
  };
};

export const handleFetchDetailQuestion = (GroupId) => {
  return (dispatch) => {
    dispatch(startFetchDetailQuestion());
    fetchQuestionItemHandler(GroupId)
      .then((res) => {
        if (res !== "error") {
          dispatch(fetchDetailQuestionSuccess(res.data));
        } else {
          dispatch(fetchDetailQuestionFail("發生錯誤。"));
        }
      })
      .catch((err) => {
        dispatch(fetchDetailQuestionFail(err));
      });
  };
};

//讀取廠商資料
const startGetUserCompanyInfo = () => {
  return {
    type: actionType.START_GET_USER_COMPANY_INFO,
  };
};

const getUserCompanyInfoSuccess = (companyInfoList) => {
  return {
    type: actionType.GET_USER_INFO_COMPANY_SUCCESS,
    companyInfoList: companyInfoList,
  };
};

const getUserCompanyInfoFail = (err) => {
  return {
    type: actionType.GET_USER_INFO_COMPANY_FAIL,
    err: err,
  };
};

export const handleGetUserCompany = (keyWord) => {
  return (dispatch) => {
    dispatch(startGetUserCompanyInfo());
    getFacNo(keyWord)
      .then((res) => {
        if (res !== "error") {
          dispatch(getUserCompanyInfoSuccess(res));
        } else {
          dispatch(getUserCompanyInfoFail("發生錯誤。"));
        }
      })
      .catch((err) => {
        dispatch(getUserCompanyInfoFail(err));
      });
  };
};

//暫存===false & 上傳問卷===true
const startUploadVisitQuestionData = () => {
  return {
    type: actionType.START_GET_UPLOAD_VISIT_QUESTION_DATA,
  };
};

const getUploadVisitQuestionDataSuccess = (data) => {
  return {
    type: actionType.GET_UPLOAD_VISIT_QUESTION_DATA_SUCCESS,
    data: data,
  };
};

const getUploadVisitQuestionDataFail = (err) => {
  return {
    type: actionType.GET_UPLOAD_VISIT_QUESTION_DATA_FAIL,
    err: err,
  };
};

export const handleUploadVisitQuestionData = (result, uid, release) => {
  return (dispatch) => {
    dispatch(startUploadVisitQuestionData());
    return fetchUploadVisitQuestionDataHandler(result, uid, release)
      .then((res) => {
        if (res.status === 200) {
          dispatch(getUploadVisitQuestionDataSuccess(res.data));
        } else {
          dispatch(getUploadVisitQuestionDataFail("上傳問卷資料失敗。"));
        }
      })
      .catch((err) => {
        dispatch(getUploadVisitQuestionDataFail(err));
      });
  };
};

//取得限時訪廠問卷資料
const startGetLimitedVisitQuestionData = () => {
  return {
    type: actionType.START_GET_LIMITED_VISIT_QUESTION_DATA,
  };
};

const getLimitedVisitQuestionDataSuccess = (data) => {
  return {
    type: actionType.GET_LIMITED_VISIT_QUESTION_DATA_SUCCESS,
    data: data,
  };
};

const getLimitedVisitQuestionDataFail = (err) => {
  return {
    type: actionType.GET_LIMITED_VISIT_QUESTION_DATA_FAIL,
    err: err,
  };
};

export const handleLimitedVisitQuestionData = (release) => {
  return (dispatch) => {
    dispatch(startGetLimitedVisitQuestionData());
    fetchLimitedVisitQuestionDataHandler(release)
      .then((res) => {
        if (res.data.status === 200) {
          dispatch(getLimitedVisitQuestionDataSuccess(res.data));
        } else {
          dispatch(
            getLimitedVisitQuestionDataFail(res.data)
          );
        }
      })
      .catch((err) => {
        dispatch(getLimitedVisitQuestionDataFail(err));
      });
  };
};

//取得帳號問卷清單
const startGetVisitQuestionList = () => {
  return {
    type: actionType.START_GET_VISIT_QUESTION_LIST,
  };
};

const getVisitQuestionListSuccess = (data) => {
  return {
    type: actionType.GET_VISIT_QUESTION_LIST_SUCCESS,
    data: data,
  };
};

const getVisitQuestionListFail = (err) => {
  return {
    type: actionType.GET_VISIT_QUESTION_LIST_FAIL,
    err: err,
  };
};

export const handleGetVisitQuestionList = (
  uid,
  keyword,
  startTime,
  endTime,
  traceBack,
  visitReason,
  group
) => {
  return (dispatch) => {
    dispatch(startGetVisitQuestionList());
    fetchVisitQuestionListHandler(uid, keyword, startTime, endTime, traceBack, visitReason, group)
      .then((res) => {
        if (res.status === 200) {
          dispatch(getVisitQuestionListSuccess(res.data));
        } else {
          dispatch(getVisitQuestionListFail("取得帳號問卷清單失敗。"));
        }
      })
      .catch((err) => {
        dispatch(getVisitQuestionListFail(err));
      });
  };
};

//取得問卷詳細資料
const startGetVisitQuestionDetail = () => {
  return {
    type: actionType.START_GET_VISIT_QUESTION_DETAIL,
  };
};

const getVisitQuestionDetailSuccess = (data) => {
  return {
    type: actionType.GET_VISIT_QUESTION_DETAIL_SUCCESS,
    data: data,
  };
};

const getVisitQuestionDetailFail = (err) => {
  return {
    type: actionType.GET_VISIT_QUESTION_DETAIL_FAIL,
    err: err,
  };
};

export const handleGetVisitQuestionDetail = (uid, Qid) => {
  return (dispatch) => {
    dispatch(startGetVisitQuestionDetail());
    fetchVisitQuestionDetailHandler(uid, Qid)
      .then((res) => {
        if (res.status === 200) {
          dispatch(getVisitQuestionDetailSuccess(res.data));
        } else {
          dispatch(getVisitQuestionDetailFail("取得問卷詳細資料。"));
        }
      })
      .catch((err) => {
        dispatch(getVisitQuestionDetailFail(err));
      });
  };
};

//刪除問卷
const startDeleteVisitQuestion = () => {
  return {
    type: actionType.START_DELETE_VISIT_QUESTION,
  };
};

const deleteVisitQuestionSuccess = (data) => {
  return {
    type: actionType.DELETE_VISIT_QUESTION_SUCCESS,
    data: data,
  };
};

const deleteVisitQuestionFail = (err) => {
  return {
    type: actionType.DELETE_VISIT_QUESTION_FAIL,
    err: err,
  };
};

export const handleDeleteVisitQuestion = (Qid) => {
  return (dispatch) => {
    dispatch(startDeleteVisitQuestion());
    fetchDeleteVisitQuestionHandler(Qid)
      .then((res) => {
        if (res.status === 200) {
          dispatch(deleteVisitQuestionSuccess(res.data));
        } else {
          dispatch(deleteVisitQuestionFail("刪除問卷失敗。"));
        }
      })
      .catch((err) => {
        dispatch(deleteVisitQuestionFail(err));
      });
  };
};

//上傳圖片
const startUploadVisitQuestionImage = () => {
  return {
    type: actionType.START_UPLOAD_VISIT_QUESTION_IMAGE,
  };
};

const uploadVisitQuestionImageSuccess = (data) => {
  return {
    type: actionType.UPLOAD_VISIT_QUESTION_IMAGE_SUCCESS,
    data: data,
  };
};

const uploadVisitQuestionImageFail = (err) => {
  return {
    type: actionType.UPLOAD_VISIT_QUESTION_IMAGE_FAIL,
    err: err,
  };
};

export const handleUploadVisitQuestionImage = (Qid, MultiImage) => {
  return (dispatch) => {
    dispatch(startUploadVisitQuestionImage());
    fetchUploadVisitQuestionImageHandler(Qid, MultiImage)
      .then((res) => {
        if (res.data?.status === 200) {
          dispatch(uploadVisitQuestionImageSuccess(res.data));
        } else {
          if (res?.data) {
            dispatch(uploadVisitQuestionImageFail(res.data));
          } else {
            dispatch(uploadVisitQuestionImageFail({
              status: 400,
              description: "請確認檔案格式和大小是否符合規定。",
            }));
          }
        }
      })
      .catch((err) => {
        dispatch(uploadVisitQuestionImageFail(err));
      });
  };
};

//取得問卷照片
const startGetVisitQuestionImage = () => {
  return {
    type: actionType.START_GET_VISIT_QUESTION_IMAGE,
  };
};

const uploadGetVisitQuestionImageSuccess = (data) => {
  return {
    type: actionType.GET_VISIT_QUESTION_IMAGE_SUCCESS,
    data: data,
  };
};

const uploadGetVisitQuestionImageFail = (err) => {
  return {
    type: actionType.GET_VISIT_QUESTION_IMAGE_FAIL,
    err: err,
  };
};

export const handleGetVisitQuestionImage = (Qid) => {
  return (dispatch) => {
    dispatch(startGetVisitQuestionImage());
    fetchGetVisitQuestionImageHandler(Qid)
      .then((res) => {
        if (res.status === 200) {
          dispatch(uploadGetVisitQuestionImageSuccess(res.data));
        } else {
          dispatch(uploadGetVisitQuestionImageFail("刪除問卷失敗。"));
        }
      })
      .catch((err) => {
        dispatch(uploadGetVisitQuestionImageFail(err));
      });
  };
};