import axios from "axios";
import { Base64 } from "js-base64";
import { baseUrl, loginbaseUrl } from "../../../constants/endpoints";

const headers = {
  "Access-Control-Allow-Origin": "*",
  "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
  "Access-Control-Allow-Headers":
    "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers",
  "Content-Type": "application/json",
  Authorization: localStorage.getItem("acToken"),
};

export const getPdfHandler = (businessNo) => {
  let token;

  const dateObj = new Date();
  const month = dateObj.getUTCMonth() + 1; //months from 1-12
  const date = dateObj.getUTCDate();
  const year = dateObj.getUTCFullYear();

  token = Base64.encode(`${businessNo}6d3be97d${year}-${month}-${date}`);

  window.open(`${baseUrl}/api/Company/ExportPDF/${token}`, "_blank");

  // return axios({
  //   proxy: true,
  //   method: "get",
  //   url:`${baseUrl}/api/Company/ExportPDF/${token}`,
  //   headers: headers
  // })
  //   .then(res => {
  //     return res.data;
  //   })
  //   .catch(err => {
  //     return "error";
  //   });
};
