import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  Modal,
  Backdrop,
  TextField,
  Collapse,
  InputAdornment,
  Icon,
} from "@material-ui/core";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import About from "../About";

import * as authActions from "../../store/actions/auth";

import "./styles.scss";

const MySwal = withReactContent(Swal);

export default (props) => {
  const encoded = props.match.params.uid;
  const [p, setP] = useState();
  const [pc, setPc] = useState();
  const [isClicked, setIsClicked] = useState();
  const IS_PASS_PHRASE =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[a-zA-Z\d!@#$%^&*]{12,20}$/;
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (content.createDate < new Date()) {
      MySwal.fire({
        text: "您的重設密碼通知信已經過期，請重新申請。",
      }).then(() => {
        history.push("/portal");
      });
    }
  }, []);

  const content = JSON.parse(
    new Buffer(encoded.split(".")[1], "base64").toString()
  );

  console.log(content);

  const handleUpdateField = (e, callback) => {
    callback(e.target.value);
  };

  const changePassWordHandler = () => {
    setIsClicked(true);
    if (p !== pc) {
      return;
    }
    if (!IS_PASS_PHRASE.test(p)) {
      return;
    }

    dispatch(authActions.forgetPassWordHandler(content.a, content.b, p));

    history.push("/portal");
  };

  return (
    <>
      <About />
      <Modal
        className="forget-pass-modal"
        open={true}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}>
        <div className="forget-pass">
          <div className="forget-pass-title">
            <h2>重設密碼</h2>
          </div>
          <div className="note">
            <p>請輸入欲變更的密碼與密碼確認。</p>
          </div>
          <div className="forget-pass-input-box">
            <TextField
              type="password"
              className="input"
              label="新密碼"
              variant="outlined"
              size="small"
              value={p}
              placeholder="請輸入至少八個字元，至少一個數字與一個英文字。"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Icon>vpn_key</Icon>
                  </InputAdornment>
                ),
              }}
              onChange={(e) => handleUpdateField(e, setP)}
            />
            <Collapse in={isClicked && !IS_PASS_PHRASE.test(p) && p}>
              <div className="vertification">
                <p>
                  密碼長度必須在 12 到 20
                  之間，需包含一個小寫字母、一個大寫字母、一個數字和一個符號(!@#$%^&*)
                </p>
              </div>
            </Collapse>
            <Collapse in={isClicked && !p}>
              <div className="vertification">
                <p>請輸入密碼</p>
              </div>
            </Collapse>
            <TextField
              type="password"
              className="input"
              label="新密碼確認"
              variant="outlined"
              placeholder="請再次輸入密碼。"
              size="small"
              value={pc}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Icon>vpn_key</Icon>
                  </InputAdornment>
                ),
              }}
              onChange={(e) => handleUpdateField(e, setPc)}
            />
            <Collapse in={isClicked && pc !== p && pc}>
              <div className="vertification">
                <p>新密碼與確認新密碼不符</p>
              </div>
            </Collapse>
            <Collapse in={isClicked && !pc}>
              <div className="vertification">
                <p>請輸入新密碼</p>
              </div>
            </Collapse>
          </div>
          <div className="sign-up-button">
            <button
              className={!p || !pc ? "inactive-button" : "active-button"}
              onClick={!p || !pc ? () => {} : changePassWordHandler}>
              修改新密碼
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};
