export const northParks = [
  {
    ParkId: "CIP001",
    Name_CH: "大武崙工業區",
    Name_EN: "Dawulun Industrial Park",
  },
  {
    ParkId: "FIP076",
    Name_CH: "瑞芳工業區",
    Name_EN: "Reifang Industrial Park",
  },
  {
    ParkId: "FIP074",
    Name_CH: "樹林工業區",
    Name_EN: "Shulin Industrial Park",
  },
  {
    ParkId: "FIP075",
    Name_CH: "土城工業區",
    Name_EN: "Tucheng Industrial Park",
  },
  {
    ParkId: "FIP078",
    Name_CH: "新北產業園區",
    Name_EN: "New Taipei Industrial Park",
  },
  {
    ParkId: "FIP077",
    Name_CH: "林口工二工業區",
    Name_EN: "Linkou 2nd Industrial Park",
  },
  {
    ParkId: "HIP014",
    Name_CH: "林口工三工業區",
    Name_EN: "Linkou 3rd Industrial Park",
  },
  {
    ParkId: "HIP008",
    Name_CH: "龜山工業區",
    Name_EN: "Guishan Industrial Park",
  },
  {
    ParkId: "HIP013",
    Name_CH: "中壢工業區",
    Name_EN: "Jhongli Industrial Park",
  },
  {
    ParkId: "HIP009",
    Name_CH: "桃園幼獅工業區",
    Name_EN: "Taoyuan Youth Industrial Park",
  },
  {
    ParkId: "HIP010",
    Name_CH: "平鎮工業區",
    Name_EN: "Pingjhen Industrial Park",
  },
  {
    ParkId: "HIP015",
    Name_CH: "大園工業區",
    Name_EN: "Dayuan Industrial Park",
  },
  {
    ParkId: "HIP019",
    Name_CH: "觀音工業區",
    Name_EN: "Guanyin Industrial Park",
  },
  {
    ParkId: "JIP004",
    Name_CH: "新竹工業區",
    Name_EN: "Hsinchu Industrial Park",
  },
  {
    ParkId: "GIP003",
    Name_CH: "龍德工業區",
    Name_EN: "Loung Te Industrial Park",
  },
  {
    ParkId: "GIP004",
    Name_CH: "利澤工業區",
    Name_EN: "Letzer Industrial Park",
  },
  {
    ParkId: "UIP007",
    Name_CH: "美崙工業區",
    Name_EN: "Meilun Industrial Park",
  },
  {
    ParkId: "UIP010",
    Name_CH: "光華樂活創意園區",
    Name_EN: "Guanghua Industrial Park",
  },
];

export const centerParks = [
  {
    ParkId: "KIP005",
    Name_CH: "頭份工業區",
    Name_EN: "Toufen Industrial Park",
  },
  {
    ParkId: "KIP009",
    Name_CH: "竹南工業區",
    Name_EN: "Jhunan Industrial Park",
  },
  {
    ParkId: "KIP007",
    Name_CH: "銅鑼工業區",
    Name_EN: "Tongluo Industrial Park",
  },
  {
    ParkId: "BIP002",
    Name_CH: "臺中工業區",
    Name_EN: "Taichung Industrial Park",
  },
  {
    ParkId: "BIP006",
    Name_CH: "大里工業區",
    Name_EN: "Dali Industrial Park",
  },
  {
    ParkId: "BIP003",
    Name_CH: "大甲幼獅工業區",
    Name_EN: "Dajia Youth Industrial Park",
  },
  {
    ParkId: "BIP004",
    Name_CH: "臺中港關連工業區",
    Name_EN: "Taichung Harbor Related Industrial Park",
  },
  {
    ParkId: "EPZ001", //export processing zone
    Name_CH: "臺中港科技產業園區",
    Name_EN: "",
  },
  {
    ParkId: "EPZ003", //export processing zone
    Name_CH: "潭子科技產業園區",
    Name_EN: "",
  },
  {
    ParkId: "NIP005",
    Name_CH: "福興工業區",
    Name_EN: "Fusing Industrial Park",
  },
  {
    ParkId: "NIP006",
    Name_CH: "埤頭工業區",
    Name_EN: "Bitou Industrial Park",
  },
  {
    ParkId: "NIP008",
    Name_CH: "田中工業區",
    Name_EN: "Tianjhong Industrial Park",
  },
  {
    ParkId: "NIP007",
    Name_CH: "芳苑工業區",
    Name_EN: "Fangyuan Industrial Park",
  },
  {
    ParkId: "NIP010",
    Name_CH: "全興工業區",
    Name_EN: "Chuansing Industrial Park",
  },
  {
    ParkId: "NIP011",
    Name_CH: "彰化濱海工業區",
    Name_EN: "Changhua Coastal Park",
  },
  {
    ParkId: "NIP013",
    Name_CH: "社頭織襪產業園區",
    Name_EN: "Shetou Hosiery Industrial Park",
  },
  {
    ParkId: "MIP003",
    Name_CH: "南崗工業區",
    Name_EN: "Nangang Industrial Park",
  },
  {
    ParkId: "MIP002",
    Name_CH: "竹山工業區",
    Name_EN: "Jhushan Industrial Park",
  }
];

export const southParks = [
  {
    ParkId: "PIP007",
    Name_CH: "豐田工業區",
    Name_EN: "Fongtian Industrial Park",
  },
  {
    ParkId: "PIP006",
    Name_CH: "元長工業區",
    Name_EN: "Yuanchang Industrial Park",
  },
  {
    ParkId: "PIP009",
    Name_CH: "斗六工業區",
    Name_EN: "Douliu Industrial Park",
  },
  {
    ParkId: "PIP010",
    Name_CH: "雲林離島基礎工業區",
    Name_EN: "Yunlin Technology-based Industrial Park",
  },
  {
    ParkId: "PIP011",
    Name_CH: "雲林科技工業區",
    Name_EN: "Yunlin Technology-based Industrial Park",
  },
  {
    ParkId: "QIP004",
    Name_CH: "民雄工業區",
    Name_EN: "Minsyong Industrial Park",
  },
  {
    ParkId: "QIP001",
    Name_CH: "頭橋工業區",
    Name_EN: "Touciao Industrial Park",
  },
  {
    ParkId: "QIP003",
    Name_CH: "嘉太工業區",
    Name_EN: "Jiatai Industrial Park",
  },
  {
    ParkId: "QIP005",
    Name_CH: "朴子工業區",
    Name_EN: "Puzih Industrial Park",
  },
  {
    ParkId: "QIP002",
    Name_CH: "義竹工業區",
    Name_EN: "Yi-jhu Industrial Park",
  },
  {
    ParkId: "DPM001", //da pu may
    Name_CH: "嘉義大埔美精密機械園區",
    Name_EN: "",
  },
  {
    ParkId: "DIP015",
    Name_CH: "新營工業區",
    Name_EN: "Sinying Industrial Park",
  },
  {
    ParkId: "DIP012",
    Name_CH: "官田工業區",
    Name_EN: "Guantian Industrial Park",
  },
  {
    ParkId: "DIP013",
    Name_CH: "永康工業區",
    Name_EN: "Yongkang Industrial Park",
  },
  {
    ParkId: "DIP011",
    Name_CH: "安平工業區",
    Name_EN: "An Ping Industrial Park",
  },
  {
    ParkId: "DIP022",
    Name_CH: "臺南科技工業區",
    Name_EN: "Tainan Technology Industrial Park",
  },
  {
    ParkId: "HSA001", //ho shuing area
    Name_CH: "和順工業區",
    Name_EN: "",
  },
  {
    ParkId: "SSP001",
    Name_CH: "南部科學園區(台南園區)",
    Name_EN: "",
  },
  {
    ParkId: "SSP002", //southern scientistic park
    Name_CH: "南部科學園區(高雄園區)",
    Name_EN: "",
  }
];

export const eastParks = [
  {
    ParkId: "EIP007",
    Name_CH: "永安工業區",
    Name_EN: "Yongan Industrial Park",
  },
  {
    ParkId: "EIP005",
    Name_CH: "仁武工業區",
    Name_EN: "Renwu Industrial Park",
  },
  {
    ParkId: "EIP009",
    Name_CH: "大社工業區",
    Name_EN: "Dashe Industrial Park",
  },
  {
    ParkId: "EIP011",
    Name_CH: "大發工業區",
    Name_EN: "Dafa Industrial Park",
  },
  {
    ParkId: "EIP006",
    Name_CH: "鳳山工業區",
    Name_EN: "Fongshan Industrial Park",
  },
  {
    ParkId: "EIP008",
    Name_CH: "林園工業區",
    Name_EN: "Linyuan Industrial Park",
  },
  {
    ParkId: "EPZ005", //export processing zone
    Name_CH: "前鎮及臨廣科技產業園區",
    Name_EN: "",
  },
  {
    ParkId: "KBZ001",
    Name_CH: "岡山本洲產業園區",
    Name_EN: "",
  },
  {
    ParkId: "EPZ004", //export processing zone
    Name_CH: "楠梓科技產業園區",
    Name_EN: "",
  },
  {
    ParkId: "UIP011",
    Name_CH: "和平工業區",
    Name_EN: "Hoping Industrial Park",
  },
  {
    ParkId: "VIP001",
    Name_CH: "豐樂工業區",
    Name_EN: "Fongle Industrial Park",
  },
  {
    ParkId: "TIP005",
    Name_CH: "屏東工業區",
    Name_EN: "Pingtung Industrial Park",
  },
  {
    ParkId: "TIP006",
    Name_CH: "內埔工業區",
    Name_EN: "Neipu Industrial Park",
  },
  {
    ParkId: "TIP007",
    Name_CH: "屏南工業區",
    Name_EN: "Pingnan Industrial Park",
  },
  {
    ParkId: "EPZ009", //export processing zone
    Name_CH: "屏東科技產業園區",
    Name_EN: "",
  },
  {
    ParkId: "PABP01",
    Name_CH: "農業生物科技園區",
    Name_EN: "",
  }
];

export const parks = [
  {
    ParkId: "GIP003",
    Name_CH: "龍德工業區",
    Name_EN: "Loung Te Industrial Park",
  },
  {
    ParkId: "GIP004",
    Name_CH: "利澤工業區",
    Name_EN: "Letzer Industrial Park",
  },
  {
    ParkId: "CIP001",
    Name_CH: "大武崙工業區",
    Name_EN: "Dawulun Industrial Park",
  },
  {
    ParkId: "FIP076",
    Name_CH: "瑞芳工業區",
    Name_EN: "Reifang Industrial Park",
  },
  {
    ParkId: "FIP078",
    Name_CH: "新北產業園區",
    Name_EN: "New Taipei Industrial Park",
  },
  {
    ParkId: "FIP074",
    Name_CH: "樹林工業區",
    Name_EN: "Shulin Industrial Park",
  },
  {
    ParkId: "FIP075",
    Name_CH: "土城工業區",
    Name_EN: "Tucheng Industrial Park",
  },
  {
    ParkId: "FIP077",
    Name_CH: "林口工二工業區",
    Name_EN: "Linkou 2nd Industrial Park",
  },
  {
    ParkId: "HIP014",
    Name_CH: "林口工三工業區",
    Name_EN: "Linkou 3rd Industrial Park",
  },
  {
    ParkId: "HIP009",
    Name_CH: "桃園幼獅工業區",
    Name_EN: "Taoyuan Youth Industrial Park",
  },
  {
    ParkId: "HIP008",
    Name_CH: "龜山工業區",
    Name_EN: "Guishan Industrial Park",
  },
  {
    ParkId: "HIP013",
    Name_CH: "中壢工業區",
    Name_EN: "Jhongli Industrial Park",
  },
  {
    ParkId: "HIP010",
    Name_CH: "平鎮工業區",
    Name_EN: "Pingjhen Industrial Park",
  },
  {
    ParkId: "HIP015",
    Name_CH: "大園工業區",
    Name_EN: "Dayuan Industrial Park",
  },
  {
    ParkId: "HIP019",
    Name_CH: "觀音工業區",
    Name_EN: "Guanyin Industrial Park",
  },
  {
    ParkId: "JIP004",
    Name_CH: "新竹工業區",
    Name_EN: "Hsinchu Industrial Park",
  },
  {
    ParkId: "KIP009",
    Name_CH: "竹南工業區",
    Name_EN: "Jhunan Industrial Park",
  },
  {
    ParkId: "KIP007",
    Name_CH: "銅鑼工業區",
    Name_EN: "Tongluo Industrial Park",
  },
  {
    ParkId: "KIP005",
    Name_CH: "頭份工業區",
    Name_EN: "Toufen Industrial Park",
  },
  {
    ParkId: "BIP003",
    Name_CH: "大甲幼獅工業區",
    Name_EN: "Dajia Youth Industrial Park",
  },
  {
    ParkId: "BIP002",
    Name_CH: "臺中工業區",
    Name_EN: "Taichung Industrial Park",
  },
  {
    ParkId: "BIP004",
    Name_CH: "臺中港關連工業區",
    Name_EN: "Taichung Harbor Related Industrial Park",
  },
  {
    ParkId: "BIP006",
    Name_CH: "大里工業區",
    Name_EN: "Dali Industrial Park",
  },
  {
    ParkId: "EPZ001", //export processing zone
    Name_CH: "臺中港科技產業園區",
    Name_EN: "",
  },
  {
    ParkId: "EPZ003", //export processing zone
    Name_CH: "潭子科技產業園區",
    Name_EN: "",
  },
  {
    ParkId: "NIP005",
    Name_CH: "福興工業區",
    Name_EN: "Fusing Industrial Park",
  },
  {
    ParkId: "NIP006",
    Name_CH: "埤頭工業區",
    Name_EN: "Bitou Industrial Park",
  },
  {
    ParkId: "NIP008",
    Name_CH: "田中工業區",
    Name_EN: "Tianjhong Industrial Park",
  },
  {
    ParkId: "NIP007",
    Name_CH: "芳苑工業區",
    Name_EN: "Fangyuan Industrial Park",
  },
  {
    ParkId: "NIP010",
    Name_CH: "全興工業區",
    Name_EN: "Chuansing Industrial Park",
  },
  {
    ParkId: "NIP011",
    Name_CH: "彰化濱海工業區",
    Name_EN: "Changhua Coastal Park",
  },
  {
    ParkId: "NIP013",
    Name_CH: "社頭織襪產業園區",
    Name_EN: "Shetou Hosiery Industrial Park",
  },
  {
    ParkId: "MIP002",
    Name_CH: "竹山工業區",
    Name_EN: "Jhushan Industrial Park",
  },
  {
    ParkId: "MIP003",
    Name_CH: "南崗工業區",
    Name_EN: "Nangang Industrial Park",
  },
  {
    ParkId: "PIP007",
    Name_CH: "豐田工業區",
    Name_EN: "Fongtian Industrial Park",
  },
  {
    ParkId: "PIP009",
    Name_CH: "斗六工業區",
    Name_EN: "Douliu Industrial Park",
  },
  {
    ParkId: "PIP006",
    Name_CH: "元長工業區",
    Name_EN: "Yuanchang Industrial Park",
  },
  {
    ParkId: "PIP010",
    Name_CH: "雲林離島基礎工業區",
    Name_EN: "Yunlin Technology-based Industrial Park",
  },
  {
    ParkId: "PIP011",
    Name_CH: "雲林科技工業區",
    Name_EN: "Yunlin Technology-based Industrial Park",
  },
  {
    ParkId: "QIP004",
    Name_CH: "民雄工業區",
    Name_EN: "Minsyong Industrial Park",
  },
  {
    ParkId: "QIP003",
    Name_CH: "嘉太工業區",
    Name_EN: "Jiatai Industrial Park",
  },
  {
    ParkId: "QIP001",
    Name_CH: "頭橋工業區",
    Name_EN: "Touciao Industrial Park",
  },
  {
    ParkId: "QIP005",
    Name_CH: "朴子工業區",
    Name_EN: "Puzih Industrial Park",
  },
  {
    ParkId: "QIP002",
    Name_CH: "義竹工業區",
    Name_EN: "Yi-jhu Industrial Park",
  },
  {
    ParkId: "DPM001", //da pu may
    Name_CH: "嘉義大埔美精密機械園區",
    Name_EN: "",
  },
  {
    ParkId: "DIP012",
    Name_CH: "官田工業區",
    Name_EN: "Guantian Industrial Park",
  },

  {
    ParkId: "DIP013",
    Name_CH: "永康工業區",
    Name_EN: "Yongkang Industrial Park",
  },
  {
    ParkId: "DIP015",
    Name_CH: "新營工業區",
    Name_EN: "Sinying Industrial Park",
  },
  {
    ParkId: "DIP011",
    Name_CH: "安平工業區",
    Name_EN: "An Ping Industrial Park",
  },
  {
    ParkId: "DIP022",
    Name_CH: "臺南科技工業區",
    Name_EN: "Tainan Technology Industrial Park",
  },
  {
    ParkId: "HSA001", //ho shuing area
    Name_CH: "和順工業區",
    Name_EN: "",
  },
  {
    ParkId: "SSP001",
    Name_CH: "南部科學園區(台南園區)",
    Name_EN: "",
  },
  {
    ParkId: "EIP010",
    Name_CH: "高雄臨海工業區",
    Name_EN: "Kaohsiung Linhai Industrial Park",
  },
  {
    ParkId: "EIP007",
    Name_CH: "永安工業區",
    Name_EN: "Yongan Industrial Park",
  },
  {
    ParkId: "EIP009",
    Name_CH: "大社工業區",
    Name_EN: "Dashe Industrial Park",
  },
  {
    ParkId: "EIP005",
    Name_CH: "仁武工業區",
    Name_EN: "Renwu Industrial Park",
  },
  {
    ParkId: "EIP006",
    Name_CH: "鳳山工業區",
    Name_EN: "Fongshan Industrial Park",
  },
  {
    ParkId: "EIP008",
    Name_CH: "林園工業區",
    Name_EN: "Linyuan Industrial Park",
  },
  {
    ParkId: "EIP011",
    Name_CH: "大發工業區",
    Name_EN: "Dafa Industrial Park",
  },
  {
    ParkId: "KBZ001",
    Name_CH: "岡山本洲產業園區",
    Name_EN: "",
  },
  {
    ParkId: "EPZ004", //export processing zone
    Name_CH: "楠梓科技產業園區",
    Name_EN: "",
  },
  {
    ParkId: "EPZ005", //export processing zone
    Name_CH: "前鎮及臨廣科技產業園區",
    Name_EN: "",
  },
  {
    ParkId: "SSP002", //southern scientistic park
    Name_CH: "南部科學園區(高雄園區)",
    Name_EN: "",
  },
  {
    ParkId: "TIP005",
    Name_CH: "屏東工業區",
    Name_EN: "Pingtung Industrial Park",
  },
  {
    ParkId: "TIP007",
    Name_CH: "屏南工業區",
    Name_EN: "Pingnan Industrial Park",
  },
  {
    ParkId: "TIP006",
    Name_CH: "內埔工業區",
    Name_EN: "Neipu Industrial Park",
  },
  {
    ParkId: "PABP01",
    Name_CH: "農業生物科技園區",
    Name_EN: "",
  },
  {
    ParkId: "EPZ009", //export processing zone
    Name_CH: "屏東科技產業園區",
    Name_EN: "",
  },
  {
    ParkId: "UIP011",
    Name_CH: "和平工業區",
    Name_EN: "Hoping Industrial Park",
  },
  {
    ParkId: "UIP007",
    Name_CH: "美崙工業區",
    Name_EN: "Meilun Industrial Park",
  },
  {
    ParkId: "UIP010",
    Name_CH: "光華樂活創意園區",
    Name_EN: "Guanghua Industrial Park",
  },
  {
    ParkId: "VIP001",
    Name_CH: "豐樂工業區",
    Name_EN: "Fongle Industrial Park",
  },
];

export const parksWithOutExportZone = [
  {
    ParkId: "GIP003",
    Name_CH: "龍德產業園區",
    Name_EN: "Loung Te Industrial Park",
  },
  {
    ParkId: "GIP004",
    Name_CH: "利澤產業園區",
    Name_EN: "Letzer Industrial Park",
  },
  {
    ParkId: "CIP001",
    Name_CH: "大武崙產業園區",
    Name_EN: "Dawulun Industrial Park",
  },
  {
    ParkId: "FIP076",
    Name_CH: "瑞芳產業園區",
    Name_EN: "Reifang Industrial Park",
  },
  {
    ParkId: "FIP078",
    Name_CH: "新北產業園區",
    Name_EN: "New Taipei Industrial Park",
  },
  {
    ParkId: "FIP074",
    Name_CH: "樹林產業園區",
    Name_EN: "Shulin Industrial Park",
  },
  {
    ParkId: "FIP075",
    Name_CH: "土城產業園區",
    Name_EN: "Tucheng Industrial Park",
  },
  {
    ParkId: "FIP077",
    Name_CH: "林口工二產業園區",
    Name_EN: "Linkou 2nd Industrial Park",
  },
  {
    ParkId: "HIP014",
    Name_CH: "林口工三產業園區",
    Name_EN: "Linkou 3rd Industrial Park",
  },
  {
    ParkId: "HIP009",
    Name_CH: "桃園幼獅產業園區",
    Name_EN: "Taoyuan Youth Industrial Park",
  },
  {
    ParkId: "HIP008",
    Name_CH: "龜山產業園區",
    Name_EN: "Guishan Industrial Park",
  },
  {
    ParkId: "HIP013",
    Name_CH: "中壢產業園區",
    Name_EN: "Jhongli Industrial Park",
  },
  {
    ParkId: "HIP010",
    Name_CH: "平鎮產業園區",
    Name_EN: "Pingjhen Industrial Park",
  },
  {
    ParkId: "HIP015",
    Name_CH: "大園產業園區",
    Name_EN: "Dayuan Industrial Park",
  },
  {
    ParkId: "HIP019",
    Name_CH: "觀音產業園區",
    Name_EN: "Guanyin Industrial Park",
  },
  {
    ParkId: "JIP004",
    Name_CH: "新竹產業園區",
    Name_EN: "Hsinchu Industrial Park",
  },
  {
    ParkId: "KIP009",
    Name_CH: "竹南產業園區",
    Name_EN: "Jhunan Industrial Park",
  },
  {
    ParkId: "KIP007",
    Name_CH: "銅鑼產業園區",
    Name_EN: "Tongluo Industrial Park",
  },
  {
    ParkId: "KIP005",
    Name_CH: "頭份產業園區",
    Name_EN: "Toufen Industrial Park",
  },
  {
    ParkId: "BIP003",
    Name_CH: "大甲幼獅產業園區",
    Name_EN: "Dajia Youth Industrial Park",
  },
  {
    ParkId: "BIP002",
    Name_CH: "臺中產業園區",
    Name_EN: "Taichung Industrial Park",
  },
  {
    ParkId: "BIP004",
    Name_CH: "臺中港關連產業園區",
    Name_EN: "Taichung Harbor Related Industrial Park",
  },
  {
    ParkId: "BIP006",
    Name_CH: "大里產業園區",
    Name_EN: "Dali Industrial Park",
  },
  {
    ParkId: "NIP005",
    Name_CH: "福興產業園區",
    Name_EN: "Fusing Industrial Park",
  },
  {
    ParkId: "NIP006",
    Name_CH: "埤頭產業園區",
    Name_EN: "Bitou Industrial Park",
  },
  {
    ParkId: "NIP008",
    Name_CH: "田中產業園區",
    Name_EN: "Tianjhong Industrial Park",
  },
  {
    ParkId: "NIP007",
    Name_CH: "芳苑產業園區",
    Name_EN: "Fangyuan Industrial Park",
  },
  {
    ParkId: "NIP010",
    Name_CH: "全興產業園區",
    Name_EN: "Chuansing Industrial Park",
  },
  {
    ParkId: "NIP011",
    Name_CH: "彰化濱海產業園區",
    Name_EN: "Changhua Coastal Park",
  },
  {
    ParkId: "NIP013",
    Name_CH: "社頭織襪產業園區",
    Name_EN: "Shetou Hosiery Industrial Park",
  },
  {
    ParkId: "MIP002",
    Name_CH: "竹山產業園區",
    Name_EN: "Jhushan Industrial Park",
  },
  {
    ParkId: "MIP003",
    Name_CH: "南崗產業園區",
    Name_EN: "Nangang Industrial Park",
  },
  {
    ParkId: "PIP007",
    Name_CH: "豐田產業園區",
    Name_EN: "Fongtian Industrial Park",
  },
  {
    ParkId: "PIP009",
    Name_CH: "斗六產業園區",
    Name_EN: "Douliu Industrial Park",
  },
  {
    ParkId: "PIP006",
    Name_CH: "元長產業園區",
    Name_EN: "Yuanchang Industrial Park",
  },
  {
    ParkId: "PIP010",
    Name_CH: "雲林離島基礎產業園區",
    Name_EN: "Yunlin Technology-based Industrial Park",
  },
  {
    ParkId: "PIP011",
    Name_CH: "雲林產業園區",
    Name_EN: "Yunlin Technology-based Industrial Park",
  },
  {
    ParkId: "QIP004",
    Name_CH: "民雄產業園區",
    Name_EN: "Minsyong Industrial Park",
  },
  {
    ParkId: "QIP003",
    Name_CH: "嘉太產業園區",
    Name_EN: "Jiatai Industrial Park",
  },
  {
    ParkId: "QIP001",
    Name_CH: "頭橋產業園區",
    Name_EN: "Touciao Industrial Park",
  },
  {
    ParkId: "QIP005",
    Name_CH: "朴子產業園區",
    Name_EN: "Puzih Industrial Park",
  },
  {
    ParkId: "QIP002",
    Name_CH: "義竹產業園區",
    Name_EN: "Yi-jhu Industrial Park",
  },
  {
    ParkId: "DIP012",
    Name_CH: "官田產業園區",
    Name_EN: "Guantian Industrial Park",
  },

  {
    ParkId: "DIP013",
    Name_CH: "永康產業園區",
    Name_EN: "Yongkang Industrial Park",
  },
  {
    ParkId: "DIP015",
    Name_CH: "新營產業園區",
    Name_EN: "Sinying Industrial Park",
  },
  {
    ParkId: "DIP011",
    Name_CH: "安平產業園區",
    Name_EN: "An Ping Industrial Park",
  },
  {
    ParkId: "DIP022",
    Name_CH: "臺南產業園區",
    Name_EN: "Tainan Technology Industrial Park",
  },
  {
    ParkId: "EIP010",
    Name_CH: "高雄臨海產業園區",
    Name_EN: "Kaohsiung Linhai Industrial Park",
  },
  {
    ParkId: "EIP007",
    Name_CH: "永安產業園區",
    Name_EN: "Yongan Industrial Park",
  },
  {
    ParkId: "EIP009",
    Name_CH: "大社產業園區",
    Name_EN: "Dashe Industrial Park",
  },
  {
    ParkId: "EIP005",
    Name_CH: "仁武產業園區",
    Name_EN: "Renwu Industrial Park",
  },
  {
    ParkId: "EIP006",
    Name_CH: "鳳山產業園區",
    Name_EN: "Fongshan Industrial Park",
  },
  {
    ParkId: "EIP008",
    Name_CH: "林園產業園區",
    Name_EN: "Linyuan Industrial Park",
  },
  {
    ParkId: "EIP011",
    Name_CH: "大發產業園區",
    Name_EN: "Dafa Industrial Park",
  },
  // {
  //   ParkId: "KBZ001",
  //   Name_CH: "岡山本洲產業園區",
  //   Name_EN: "",
  // },
  {
    ParkId: "TIP005",
    Name_CH: "屏東產業園區",
    Name_EN: "Pingtung Industrial Park",
  },
  {
    ParkId: "TIP007",
    Name_CH: "屏南產業園區",
    Name_EN: "Pingnan Industrial Park",
  },
  {
    ParkId: "TIP006",
    Name_CH: "內埔產業園區",
    Name_EN: "Neipu Industrial Park",
  },
  {
    ParkId: "UIP011",
    Name_CH: "和平產業園區",
    Name_EN: "Hoping Industrial Park",
  },
  {
    ParkId: "UIP007",
    Name_CH: "美崙產業園區",
    Name_EN: "Meilun Industrial Park",
  },
  {
    ParkId: "UIP010",
    Name_CH: "光華樂活創意園區",
    Name_EN: "Guanghua Industrial Park",
  },
  {
    ParkId: "VIP001",
    Name_CH: "豐樂產業園區",
    Name_EN: "Fongle Industrial Park",
  },
];

export const parksWithUrl = [
  {
    ParkId: "CIP001",
    Name: "大武崙產業園區",
    Name_EN: "Dawulun Industrial Park",
    AgencyId: "313022100G",
    Area: 290000,
    Property: "綜合性",
    Url: "https://www.bip.gov.tw/iphw/dawulun/",
    HaveWTP: true,
    HaveRecyclingPlant: false,
    location: "臺北分局",
  },
  {
    ParkId: "FIP076",
    Name: "瑞芳產業園區",
    Name_EN: "Reifang Industrial Park",
    AgencyId: "313022100G",
    Area: 387100,
    Property: "綜合性",
    Url: "https://www.bip.gov.tw/iphw/dawulun/",
    HaveWTP: false,
    HaveRecyclingPlant: false,
    location: "臺北分局",
  },
  // {
  //   ParkId: "AIP008",
  //   Name: "南港軟體工業園區",
  //   Name_EN: "Nankang Software Park",
  //   AgencyId: "313029053L",
  //   Area: 82000,
  //   Property: "軟體業",
  //   Url: "http://www.nkzone.com.tw/",
  //   HaveWTP: false,
  //   HaveRecyclingPlant: false
  // },
  {
    ParkId: "FIP078",
    Name: "新北產業園區",
    Name_EN: "New Taipei Industrial Park",
    AgencyId: "313022300G",
    Area: 1405500,
    Property: "綜合性",
    Url: "https://www.bip.gov.tw/iphw/wuku/",
    HaveWTP: true,
    HaveRecyclingPlant: false,
    location: "臺北分局",
  },
  {
    ParkId: "FIP074",
    Name: "樹林產業園區",
    Name_EN: "Shulin Industrial Park",
    AgencyId: "313022500G",
    Area: 217478,
    Property: "綜合性",
    Url: "https://www.bip.gov.tw/iphw/shulin/",
    HaveWTP: false,
    HaveRecyclingPlant: false,
    location: "臺北分局",
  },
  {
    ParkId: "FIP075",
    Name: "土城產業園區",
    Name_EN: "Tucheng Industrial Park",
    AgencyId: "313022400G",
    Area: 1070000,
    Property: "綜合性",
    Url: "https://www.bip.gov.tw/iphw/tucheng/",
    HaveWTP: true,
    HaveRecyclingPlant: false,
    location: "臺北分局",
  },

  {
    ParkId: "FIP077",
    Name: "林口工二產業園區",
    Name_EN: "Linkou 2nd Industrial Park",
    AgencyId: "313022700G",
    Area: 524400,
    Property: "乙種產業園區",
    Url: "https://www.bip.gov.tw/iphw/linkou/",
    HaveWTP: false,
    HaveRecyclingPlant: false,
    location: "臺北分局",
  },
  {
    ParkId: "HIP014",
    Name: "林口工三產業園區",
    Name_EN: "Linkou 3rd Industrial Park",
    AgencyId: "313022700G",
    Area: 1193080,
    Property: "綜合性",
    Url: "https://www.bip.gov.tw/iphw/linkou/",
    HaveWTP: false,
    HaveRecyclingPlant: false,
    location: "臺北分局",
  },
  {
    ParkId: "HIP009",
    Name: "桃園幼獅產業園區",
    Name_EN: "Taoyuan Youth Industrial Park",
    AgencyId: "313023000G",
    Area: 615700,
    Property: "青年創業",
    Url: "https://www.bip.gov.tw/iphw/taoyuanyouth/",
    HaveWTP: true,
    HaveRecyclingPlant: false,
    location: "臺北分局",
  },
  {
    ParkId: "HIP008",
    Name: "龜山產業園區",
    Name_EN: "Guishan Industrial Park",
    AgencyId: "313022800G",
    Area: 1310000,
    Property: "綜合性",
    Url: "https://www.bip.gov.tw/iphw/gueishan/",
    HaveWTP: false,
    HaveRecyclingPlant: false,
    location: "臺北分局",
  },
  {
    ParkId: "HIP013",
    Name: "中壢產業園區",
    Name_EN: "Jhongli Industrial Park",
    AgencyId: "313023200G",
    Area: 4329000,
    Property: "綜合性",
    Url: "https://www.bip.gov.tw/iphw/jhongli/",
    HaveWTP: true,
    HaveRecyclingPlant: false,
    location: "臺北分局",
  },
  {
    ParkId: "HIP010",
    Name: "平鎮產業園區",
    Name_EN: "Pingjhen Industrial Park",
    AgencyId: "313022900G",
    Area: 1040000,
    Property: "綜合性",
    Url: "https://www.bip.gov.tw/iphw/pingjhen/",
    HaveWTP: true,
    HaveRecyclingPlant: false,
    location: "臺北分局",
  },
  {
    ParkId: "HIP015",
    Name: "大園產業園區",
    Name_EN: "Dayuan Industrial Park",
    AgencyId: "313023100G",
    Area: 2043800,
    Property: "綜合性",
    Url: "https://www.bip.gov.tw/iphw/dayuan/",
    HaveWTP: true,
    HaveRecyclingPlant: false,
    location: "臺北分局",
  },
  {
    ParkId: "HIP019",
    Name: "觀音產業園區",
    Name_EN: "Guanyin Industrial Park",
    AgencyId: "313022600G",
    Area: 6321200,
    Property: "綜合性",
    Url: "https://www.bip.gov.tw/iphw/kuangin/",
    HaveWTP: true,
    HaveRecyclingPlant: false,
    location: "臺北分局",
  },
  {
    ParkId: "JIP004",
    Name: "新竹產業園區",
    Name_EN: "Hsinchu Industrial Park",
    AgencyId: "313023300G",
    Area: 5170000,
    Property: "綜合性",
    Url: "https://www.bip.gov.tw/iphw/hsinchu/",
    HaveWTP: true,
    HaveRecyclingPlant: false,
    location: "臺北分局",
  },
  {
    ParkId: "GIP003",
    Name: "龍德產業園區",
    Name_EN: "Loung Te Industrial Park",
    AgencyId: "313022000G",
    Area: 2360900,
    Property: "綜合性",
    Url: "https://www.bip.gov.tw/iphw/lungte/",
    HaveWTP: false,
    HaveRecyclingPlant: true,
    location: "臺北分局",
  },
  {
    ParkId: "GIP004",
    Name: "利澤產業園區",
    Name_EN: "Letzer Industrial Park",
    AgencyId: "313022000G",
    Area: 3290500,
    Property: "綜合型",
    Url: "https://www.bip.gov.tw/iphw/lungte/",
    HaveWTP: true,
    HaveRecyclingPlant: true,
    location: "臺北分局",
  },
  {
    ParkId: "KIP009",
    Name: "竹南產業園區",
    Name_EN: "Jhunan Industrial Park",
    AgencyId: "313023400G",
    Area: 736000,
    Property: "綜合性",
    Url: "https://www.bip.gov.tw/iphw/toufen/",
    HaveWTP: false,
    HaveRecyclingPlant: false,
    location: "臺中分局",
  },
  {
    ParkId: "KIP007",
    Name: "銅鑼產業園區",
    Name_EN: "Tongluo Industrial Park",
    AgencyId: "313023400G",
    Area: 508400,
    Property: "綜合性",
    Url: "https://www.bip.gov.tw/iphw/toufen/",
    HaveWTP: false,
    HaveRecyclingPlant: false,
    location: "臺中分局",
  },
  {
    ParkId: "KIP005",
    Name: "頭份產業園區",
    Name_EN: "Toufen Industrial Park",
    AgencyId: "313023400G",
    Area: 946496,
    Property: "綜合性",
    Url: "https://www.bip.gov.tw/iphw/toufen/",
    HaveWTP: false,
    HaveRecyclingPlant: false,
    location: "臺中分局",
  },
  {
    ParkId: "BIP003",
    Name: "大甲幼獅產業園區",
    Name_EN: "Dajia Youth Industrial Park",
    AgencyId: "313023600G",
    Area: 2184700,
    Property: "綜合性",
    Url: "https://www.bip.gov.tw/iphw/taichungyouth/",
    HaveWTP: true,
    HaveRecyclingPlant: false,
    location: "臺中分局",
  },
  {
    ParkId: "BIP002",
    Name: "臺中產業園區",
    Name_EN: "Taichung Industrial Park",
    AgencyId: "313023800G",
    Area: 5800000,
    Property: "綜合性",
    Url: "https://www.bip.gov.tw/iphw/taichung/",
    HaveWTP: true,
    HaveRecyclingPlant: false,
    location: "臺中分局",
  },
  {
    ParkId: "BIP004",
    Name: "臺中港關連產業園區",
    Name_EN: "Taichung Harbor Related Industrial Park",
    AgencyId: "313023700G",
    Area: 1428300,
    Property: "綜合性",
    Url: "https://www.bip.gov.tw/iphw/taichunghr/",
    HaveWTP: false,
    HaveRecyclingPlant: false,
    location: "臺中分局",
  },
  {
    ParkId: "BIP006",
    Name: "大里產業園區",
    Name_EN: "Dali Industrial Park",
    AgencyId: "313023900G",
    Area: 772135,
    Property: "綜合性",
    Url: "https://www.bip.gov.tw/iphw/dali/",
    HaveWTP: true,
    HaveRecyclingPlant: false,
    location: "臺中分局",
  },
  {
    ParkId: "MIP002",
    Name: "竹山產業園區",
    Name_EN: "Jhushan Industrial Park",
    AgencyId: "313024500G",
    Area: 220500,
    Property: "綜合性",
    Url: "https://www.bip.gov.tw/iphw/nangang/",
    HaveWTP: false,
    HaveRecyclingPlant: false,
    location: "臺中分局",
  },
  {
    ParkId: "MIP003",
    Name: "南崗產業園區",
    Name_EN: "Nangang Industrial Park",
    AgencyId: "313024500G",
    Area: 4120000,
    Property: "綜合性",
    Url: "https://www.bip.gov.tw/iphw/nangang/",
    HaveWTP: true,
    HaveRecyclingPlant: false,
    location: "臺中分局",
  },
  {
    ParkId: "NIP005",
    Name: "福興產業園區",
    Name_EN: "Fusing Industrial Park",
    AgencyId: "313024200G",
    Area: 183500,
    Property: "綜合性",
    Url: "https://www.bip.gov.tw/iphw/fusing/",
    HaveWTP: false,
    HaveRecyclingPlant: false,
    location: "臺中分局",
  },
  {
    ParkId: "NIP006",
    Name: "埤頭產業園區",
    Name_EN: "Bitou Industrial Park",
    AgencyId: "313024200G",
    Area: 183455,
    Property: "綜合性",
    Url: "https://www.bip.gov.tw/iphw/fusing/",
    HaveWTP: false,
    HaveRecyclingPlant: false,
    location: "臺中分局",
  },
  {
    ParkId: "NIP007",
    Name: "芳苑產業園區",
    Name_EN: "Fangyuan Industrial Park",
    AgencyId: "313024300G",
    Area: 1600000,
    Property: "綜合性",
    Url: "https://www.bip.gov.tw/iphw/fangyuan/",
    HaveWTP: true,
    HaveRecyclingPlant: false,
    location: "臺中分局",
  },
  {
    ParkId: "NIP008",
    Name: "田中產業園區",
    Name_EN: "Tianjhong Industrial Park",
    AgencyId: "313024200G",
    Area: 279369,
    Property: "綜合性",
    Url: "https://www.bip.gov.tw/iphw/fusing/",
    HaveWTP: false,
    HaveRecyclingPlant: false,
    location: "臺中分局",
  },
  {
    ParkId: "NIP010",
    Name: "全興產業園區",
    Name_EN: "Chuansing Industrial Park",
    AgencyId: "313024000G",
    Area: 2468000,
    Property: "綜合性",
    Url: "https://www.bip.gov.tw/iphw/chuansing/",
    HaveWTP: true,
    HaveRecyclingPlant: false,
    location: "臺中分局",
  },
  {
    ParkId: "NIP011",
    Name: "彰化濱海產業園區",
    Name_EN: "Changhua Coastal Park",
    AgencyId: "313024100G",
    Area: 36430000,
    Property: "綜合性",
    Url: "https://www.bip.gov.tw/iphw/changpin/",
    HaveWTP: true,
    HaveRecyclingPlant: false,
    location: "臺中分局",
  },
  {
    ParkId: "NIP013",
    Name: "社頭織襪產業園區",
    Name_EN: "Shetou Hosiery Industrial Park",
    AgencyId: "313024300G",
    Area: 76520,
    Property: "非都市土地產業園區",
    Url: "https://www.bip.gov.tw/iphw/fangyuan/",
    HaveWTP: true,
    HaveRecyclingPlant: false,
    location: "臺中分局",
  },
  {
    ParkId: "EPZ001",
    Name: "臺中港科技產業園區",
    Name_EN: "-",
    AgencyId: "0",
    Area: 0,
    Property: "綜合性",
    Url: "#",
    HaveWTP: true,
    HaveRecyclingPlant: false,
    location: "臺中分局",
  },
  {
    ParkId: "EPZ003",
    Name: "潭子科技產業園區",
    Name_EN: "-",
    AgencyId: "0",
    Area: 0,
    Property: "綜合性",
    Url: "#",
    HaveWTP: true,
    HaveRecyclingPlant: false,
    location: "臺中分局",
  },
  {
    ParkId: "PIP006",
    Name: "元長產業園區",
    Name_EN: "Yuanchang Industrial Park",
    AgencyId: "313024900G",
    Area: 161997,
    Property: "綜合性",
    Url: "https://www.bip.gov.tw/iphw/fongtian/",
    HaveWTP: false,
    HaveRecyclingPlant: false,
    location: "臺南分局",
  },
  {
    ParkId: "PIP007",
    Name: "豐田產業園區",
    Name_EN: "Fongtian Industrial Park",
    AgencyId: "313024900G",
    Area: 397560,
    Property: "綜合性",
    Url: "https://www.bip.gov.tw/iphw/fongtian/",
    HaveWTP: false,
    HaveRecyclingPlant: false,
    location: "臺南分局",
  },
  {
    ParkId: "PIP009",
    Name: "斗六產業園區",
    Name_EN: "Douliu Industrial Park",
    AgencyId: "313024700G",
    Area: 2030000,
    Property: "綜合性",
    Url: "https://www.bip.gov.tw/iphw/douliu/",
    HaveWTP: true,
    HaveRecyclingPlant: false,
    location: "臺南分局",
  },
  {
    ParkId: "PIP010",
    Name: "雲林離島基礎產業園區",
    Name_EN: "Yunlin Offshore Industrial Park",
    AgencyId: "313029900G",
    Area: 172030000,
    Property: "綜合性",
    Url: "https://www.bip.gov.tw/iphw/yloip/",
    HaveWTP: false,
    HaveRecyclingPlant: false,
    location: "臺南分局",
  },
  {
    ParkId: "PIP011",
    Name: "雲林產業園區",
    Name_EN: "Yunlin Technology-based Industrial Park",
    AgencyId: "313024800G",
    Area: 2683600,
    Property: "綜合性",
    Url: "https://www.bip.gov.tw/iphw/ytipc/",
    HaveWTP: true,
    HaveRecyclingPlant: false,
    location: "臺南分局",
  },
  {
    ParkId: "QIP001",
    Name: "頭橋產業園區",
    Name_EN: "Touciao Industrial Park",
    AgencyId: "313025000G",
    Area: 860000,
    Property: "綜合性",
    Url: "https://www.bip.gov.tw/iphw/minsyong/",
    HaveWTP: true,
    HaveRecyclingPlant: false,
    location: "臺南分局",
  },
  {
    ParkId: "QIP002",
    Name: "義竹產業園區",
    Name_EN: "Yi-jhu Industrial Park",
    AgencyId: "313025200G",
    Area: 156500,
    Property: "綜合性",
    Url: "https://www.bip.gov.tw/iphw/puzih/",
    HaveWTP: false,
    HaveRecyclingPlant: false,
    location: "臺南分局",
  },
  {
    ParkId: "QIP003",
    Name: "嘉太產業園區",
    Name_EN: "Jiatai Industrial Park",
    AgencyId: "313025100G",
    Area: 594200,
    Property: "甲種工業用地",
    Url: "https://www.bip.gov.tw/iphw/jiatai/",
    HaveWTP: true,
    HaveRecyclingPlant: false,
    location: "臺南分局",
  },
  {
    ParkId: "QIP004",
    Name: "民雄產業園區",
    Name_EN: "Minsyong Industrial Park",
    AgencyId: "313025000G",
    Area: 2440000,
    Property: "綜合性",
    Url: "https://www.bip.gov.tw/iphw/minsyong/",
    HaveWTP: true,
    HaveRecyclingPlant: false,
    location: "臺南分局",
  },
  {
    ParkId: "QIP005",
    Name: "朴子產業園區",
    Name_EN: "Puzih Industrial Park",
    AgencyId: "313025200G",
    Area: 215200,
    Property: "綜合性",
    Url: "https://www.bip.gov.tw/iphw/puzih/",
    HaveWTP: false,
    HaveRecyclingPlant: false,
    location: "臺南分局",
  },
  {
    ParkId: "DIP012",
    Name: "官田產業園區",
    Name_EN: "Guantian Industrial Park",
    AgencyId: "313025400G",
    Area: 2270000,
    Property: "綜合性",
    Url: "https://www.bip.gov.tw/iphw/guantian/",
    HaveWTP: true,
    HaveRecyclingPlant: false,
    location: "臺南分局",
  },
  {
    ParkId: "DIP013",
    Name: "永康產業園區",
    Name_EN: "Yongkang Industrial Park",
    AgencyId: "313025500G",
    Area: 746743,
    Property: "綜合性",
    Url: "https://www.bip.gov.tw/iphw/yongkang/",
    HaveWTP: true,
    HaveRecyclingPlant: false,
    location: "臺南分局",
  },
  {
    ParkId: "DIP015",
    Name: "新營產業園區",
    Name_EN: "Sinying Industrial Park",
    AgencyId: "313025300G",
    Area: 1240000,
    Property: "綜合性",
    Url: "https://www.bip.gov.tw/iphw/sinying/",
    HaveWTP: true,
    HaveRecyclingPlant: false,
    location: "臺南分局",
  },
  {
    ParkId: "DIP011",
    Name: "安平產業園區",
    Name_EN: "An Ping Industrial Park",
    AgencyId: "313025700G",
    Area: 2000000,
    Property: "綜合性",
    Url: "https://www.bip.gov.tw/iphw/anping/",
    HaveWTP: true,
    HaveRecyclingPlant: false,
    location: "臺南分局",
  },
  {
    ParkId: "DIP022",
    Name: "臺南產業園區",
    Name_EN: "Tainan Technology Industrial Park",
    AgencyId: "313025600G",
    Area: 7091500,
    Property: "綜合性",
    Url: "https://www.bip.gov.tw/iphw/ttip/",
    HaveWTP: true,
    HaveRecyclingPlant: false,
    location: "臺南分局",
  },
  {
    ParkId: "EIP007",
    Name: "永安產業園區",
    Name_EN: "Yongan Industrial Park",
    AgencyId: "313025800G",
    Area: 643700,
    Property: "綜合性",
    Url: "https://www.bip.gov.tw/iphw/yongan/",
    HaveWTP: true,
    HaveRecyclingPlant: false,
    location: "高屏分局",
  },
  {
    ParkId: "EIP005",
    Name: "仁武產業園區",
    Name_EN: "Renwu Industrial Park",
    AgencyId: "313026100G",
    Area: 210000,
    Property: "甲種產業園區",
    Url: "https://www.bip.gov.tw/iphw/renda/",
    HaveWTP: false,
    HaveRecyclingPlant: false,
    location: "高屏分局",
  },
  {
    ParkId: "EIP009",
    Name: "大社產業園區",
    Name_EN: "Dashe Industrial Park",
    AgencyId: "313026100G",
    Area: 1099450,
    Property: "特種產業園區(石化為主)",
    Url: "https://www.bip.gov.tw/iphw/renda/",
    HaveWTP: true,
    HaveRecyclingPlant: false,
    location: "高屏分局",
  },
  {
    ParkId: "EIP006",
    Name: "鳳山產業園區",
    Name_EN: "Fongshan Industrial Park",
    AgencyId: "313026000G",
    Area: 110300,
    Property: "綜合性",
    Url: "https://www.bip.gov.tw/iphw/tafa/",
    HaveWTP: true,
    HaveRecyclingPlant: false,
    location: "高屏分局",
  },
  {
    ParkId: "EIP008",
    Name: "林園產業園區",
    Name_EN: "Linyuan Industrial Park",
    AgencyId: "313026200G",
    Area: 4032430,
    Property: "特種產業園區(石化專業區)",
    Url: "https://www.bip.gov.tw/iphw/linyuan/",
    HaveWTP: true,
    HaveRecyclingPlant: false,
    location: "高屏分局",
  },
  {
    ParkId: "EIP010",
    Name: "高雄臨海產業園區",
    Name_EN: "Kaohsiung Linhai Industrial Park",
    AgencyId: "313025900G",
    Area: 15600000,
    Property: "綜合性",
    Url: "https://www.bip.gov.tw/iphw/linhai/",
    HaveWTP: false,
    HaveRecyclingPlant: true,
    location: "高屏分局",
  },
  {
    ParkId: "EIP011",
    Name: "大發產業園區",
    Name_EN: "Dafa Industrial Park",
    AgencyId: "313026000G",
    Area: 3741920,
    Property: "綜合性",
    Url: "https://www.bip.gov.tw/iphw/tafa/",
    HaveWTP: false,
    HaveRecyclingPlant: true,
    location: "高屏分局",
  },
  {
    ParkId: "TIP005",
    Name: "屏東產業園區",
    Name_EN: "Pingtung Industrial Park",
    AgencyId: "313026400G",
    Area: 1131850,
    Property: "綜合性",
    Url: "https://www.bip.gov.tw/iphw/pingtung/",
    HaveWTP: false,
    HaveRecyclingPlant: false,
    location: "高屏分局",
  },
  {
    ParkId: "TIP006",
    Name: "內埔產業園區",
    Name_EN: "Neipu Industrial Park",
    AgencyId: "313026600G",
    Area: 1032920,
    Property: "綜合性",
    Url: "https://www.bip.gov.tw/iphw/neipo/",
    HaveWTP: false,
    HaveRecyclingPlant: false,
    location: "高屏分局",
  },
  {
    ParkId: "TIP007",
    Name: "屏南產業園區",
    Name_EN: "Pingnan Industrial Park",
    AgencyId: "313026500G",
    Area: 2810000,
    Property: "綜合性",
    Url: "https://www.bip.gov.tw/iphw/pingnan/",
    HaveWTP: true,
    HaveRecyclingPlant: false,
    location: "高屏分局",
  },
  {
    ParkId: "EPZ004",
    Name: "楠梓科技產業園區",
    Name_EN: "-",
    AgencyId: "0",
    Area: 0,
    Property: "綜合性",
    Url: "#",
    HaveWTP: true,
    HaveRecyclingPlant: false,
    location: "高屏分局",
  },
  {
    ParkId: "EPZ005",
    Name: "前鎮及臨廣科技產業園區",
    Name_EN: "-",
    AgencyId: "0",
    Area: 0,
    Property: "綜合性",
    Url: "#",
    HaveWTP: true,
    HaveRecyclingPlant: false,
    location: "高屏分局",
  },
  {
    ParkId: "EPZ009",
    Name: "屏東科技產業園區",
    Name_EN: "-",
    AgencyId: "0",
    Area: 0,
    Property: "綜合性",
    Url: "#",
    HaveWTP: true,
    HaveRecyclingPlant: false,
    location: "高屏分局",
  },
  {
    ParkId: "UIP007",
    Name: "美崙產業園區",
    Name_EN: "Meilun Industrial Park",
    AgencyId: "313026700G",
    Area: 1362700,
    Property: "綜合性",
    Url: "https://www.bip.gov.tw/iphw/meeluen/",
    HaveWTP: false,
    HaveRecyclingPlant: false,
    location: "臺北分局",
  },
  {
    ParkId: "UIP010",
    Name: "光華樂活創意園區",
    Name_EN: "Guanghua Industrial Park",
    AgencyId: "313026700G",
    Area: 343600,
    Property: "石材加工專業區",
    Url: "https://www.bip.gov.tw/iphw/meeluen/",
    HaveWTP: true,
    HaveRecyclingPlant: false,
    location: "臺北分局",
  },
  {
    ParkId: "UIP011",
    Name: "和平產業園區",
    Name_EN: "Hoping Industrial Park",
    AgencyId: "313026700G",
    Area: 4867200,
    Property: "砂石礦業",
    Url: "https://www.bip.gov.tw/iphw/meeluen/",
    HaveWTP: true,
    HaveRecyclingPlant: false,
    location: "臺北分局",
  },
  {
    ParkId: "VIP001",
    Name: "豐樂產業園區",
    Name_EN: "Fongle Industrial Park",
    AgencyId: "313026900G",
    Area: 188857,
    Property: "綜合性",
    Url: "https://www.bip.gov.tw/iphw/fongle/",
    HaveWTP: false,
    HaveRecyclingPlant: false,
    location: "高屏分局",
  },
  // {
  //   ParkId: "divided",
  //   Name: "地方型產業園區",
  // },
  // {
  //   ParkId: "VIP001",
  //   Name: "豐樂產業園區",
  //   Name_EN: "Fongle Industrial Park",
  //   AgencyId: "313026900G",
  //   Area: 188857,
  //   Property: "綜合性",
  //   Url: "https://www.bip.gov.tw/iphw/fongle/",
  //   HaveWTP: false,
  //   HaveRecyclingPlant: false
  // },
  // {
  //   ParkId: "VIP001",
  //   Name: "豐樂產業園區",
  //   Name_EN: "Fongle Industrial Park",
  //   AgencyId: "313026900G",
  //   Area: 188857,
  //   Property: "綜合性",
  //   Url: "https://www.bip.gov.tw/iphw/fongle/",
  //   HaveWTP: false,
  //   HaveRecyclingPlant: false
  // },
  // {
  //   ParkId: "VIP001",
  //   Name: "豐樂產業園區",
  //   Name_EN: "Fongle Industrial Park",
  //   AgencyId: "313026900G",
  //   Area: 188857,
  //   Property: "綜合性",
  //   Url: "https://www.bip.gov.tw/iphw/fongle/",
  //   HaveWTP: false,
  //   HaveRecyclingPlant: false
  // },
  // {
  //   ParkId: "VIP001",
  //   Name: "豐樂產業園區",
  //   Name_EN: "Fongle Industrial Park",
  //   AgencyId: "313026900G",
  //   Area: 188857,
  //   Property: "綜合性",
  //   Url: "https://www.bip.gov.tw/iphw/fongle/",
  //   HaveWTP: false,
  //   HaveRecyclingPlant: false
  // },
  // {
  //   ParkId: "VIP001",
  //   Name: "豐樂產業園區",
  //   Name_EN: "Fongle Industrial Park",
  //   AgencyId: "313026900G",
  //   Area: 188857,
  //   Property: "綜合性",
  //   Url: "https://www.bip.gov.tw/iphw/fongle/",
  //   HaveWTP: false,
  //   HaveRecyclingPlant: false
  // },
];

export const AreaAndCity = {
  CIP001: "臺北分局",
  FIP076: "臺北分局",
  FIP074: "臺北分局",
  FIP075: "臺北分局",
  FIP078: "臺北分局",
  FIP077: "臺北分局",
  HIP014: "臺北分局",
  HIP008: "臺北分局",
  HIP013: "臺北分局",
  HIP009: "臺北分局",
  HIP010: "臺北分局",
  HIP015: "臺北分局",
  HIP019: "臺北分局",
  JIP004: "臺北分局",
  GIP003: "臺北分局",
  GIP004: "臺北分局",
  KIP005: "臺中分局",
  KIP009: "臺中分局",
  KIP007: "臺中分局",
  BIP002: "臺中分局",
  BIP006: "臺中分局",
  BIP003: "臺中分局",
  BIP004: "臺中分局",
  EPZ001: "臺中分局",
  EPZ003: "臺中分局",
  NIP005: "臺中分局",
  NIP006: "臺中分局",
  NIP008: "臺中分局",
  NIP007: "臺中分局",
  NIP010: "臺中分局",
  NIP011: "臺中分局",
  NIP013: "臺中分局",
  MIP003: "臺中分局",
  MIP002: "臺中分局",
  PIP007: "臺南分局",
  PIP006: "臺南分局",
  PIP009: "臺南分局",
  PIP010: "臺南分局",
  PIP011: "臺南分局",
  QIP004: "臺南分局",
  QIP001: "臺南分局",
  QIP003: "臺南分局",
  QIP005: "臺南分局",
  QIP002: "臺南分局",
  DPM001: "臺南分局",
  DIP015: "臺南分局",
  DIP012: "臺南分局",
  DIP013: "臺南分局",
  DIP011: "臺南分局",
  DIP022: "臺南分局",
  HSA001: "臺南分局",
  SSP001: "臺南分局",
  EIP010: "高屏分局",
  EIP007: "高屏分局",
  EIP005: "高屏分局",
  EIP009: "高屏分局",
  EIP011: "高屏分局",
  EIP006: "高屏分局",
  EIP008: "高屏分局",
  EPZ005: "高屏分局",
  // "KBZ001":"高屏分局",
  EPZ004: "高屏分局",
  UIP011: "高屏分局",
  VIP001: "高屏分局",
  TIP005: "高屏分局",
  TIP006: "高屏分局",
  TIP007: "高屏分局",
  EPZ009: "高屏分局",
  PABP01: "高屏分局",
  SSP002: "高屏分局",
  UIP007: "臺北分局",
  UIP010: "臺北分局",
};

export const parkDashboardList = [
  {
    ParkId: "KLL001",
    Name_CH: "六堵科技園區",
    Name_EN: "Ho Fa Industrial Park",
  },
  {
    ParkId: "TYD001",
    Name_CH: "大潭濱海特定工業區",
    Name_EN: "Ho Fa Industrial Park",
  },
  {
    ParkId: "TYH001",
    Name_CH: "環保科技園區",
    Name_EN: "Ho Fa Industrial Park",
  },
  {
    ParkId: "TYH002",
    Name_CH: "蘆竹海湖坑口工業用地",
    Name_EN: "Ho Fa Industrial Park",
  },
  {
    ParkId: "TYL001",
    Name_CH: "龍潭烏樹林工業用地",
    Name_EN: "Ho Fa Industrial Park",
  },
  {
    ParkId: "TYT001",
    Name_CH: "桃園科技工業園區",
    Name_EN: "Ho Fa Industrial Park",
  },
  {
    ParkId: "TYY001",
    Name_CH: "楊梅幼獅擴大工業用地",
    Name_EN: "Ho Fa Industrial Park",
  },
  {
    ParkId: "TYY002",
    Name_CH: "新屋永安工業用地",
    Name_EN: "Ho Fa Industrial Park",
  },
  {
    ParkId: "TCF001",
    Name_CH: "神岡豐洲科技工業園區",
    Name_EN: "Ho Fa Industrial Park",
  },
  {
    ParkId: "TCJ001",
    Name_CH: "臺中市精密機械科技創新園區(第一期)",
    Name_EN: "Ho Fa Industrial Park",
  },
  {
    ParkId: "CID001",
    Name_CH: "大埔美精密機械園區",
    Name_EN: "",
  },
  {
    ParkId: "CIM001",
    Name_CH: "馬稠後產業園區",
    Name_EN: "",
  },
  {
    ParkId: "TNH001",
    Name_CH: "和順工業區",
    Name_EN: "",
  },
  {
    ParkId: "TNY001",
    Name_CH: "永康科技工業區",
    Name_EN: "",
  },
  {
    ParkId: "TNS001",
    Name_CH: "新吉工業區",
    Name_EN: "Ho Fa Industrial Park",
  },
  {
    ParkId: "TNZ001",
    Name_CH: "總頭寮工業區",
    Name_EN: "Ho Fa Industrial Park",
  },
  {
    ParkId: "TNB001",
    Name_CH: "保安工業區",
    Name_EN: "Ho Fa Industrial Park",
  },
  {
    ParkId: "KHB001",
    Name_CH: "岡山本洲產業園區",
    Name_EN: "Benjhou Industrial Park",
  },
  {
    ParkId: "KHH001",
    Name_CH: "和發產業園區",
    Name_EN: "Ho Fa Industrial Park",
  },

  {
    ParkId: "KHW001",
    Name_CH: "萬大工業區",
    Name_EN: "Ho Fa Industrial Park",
  },
];

export const southArea = [
  {
    ParkId: "KLL001",
    Name_CH: "六堵科技園區",
    Name_EN: "Ho Fa Industrial Park",
  },
  {
    ParkId: "TYD001",
    Name_CH: "桃園大潭濱海特定工業區",
    Name_EN: "Ho Fa Industrial Park",
  },
  {
    ParkId: "TYH001",
    Name_CH: "桃園環保科技園區",
    Name_EN: "Ho Fa Industrial Park",
  },
  {
    ParkId: "TYH002",
    Name_CH: "桃園蘆竹海湖坑口工業區",
    Name_EN: "Ho Fa Industrial Park",
  },
  {
    ParkId: "TYL001",
    Name_CH: "桃園龍潭烏樹林工業區",
    Name_EN: "Ho Fa Industrial Park",
  },
  {
    ParkId: "TYT001",
    Name_CH: "桃園科技工業園區",
    Name_EN: "Ho Fa Industrial Park",
  },
  {
    ParkId: "TYY001",
    Name_CH: "桃園楊梅幼獅擴大產業園區",
    Name_EN: "Ho Fa Industrial Park",
  },
  {
    ParkId: "TYY002",
    Name_CH: "桃園永安工業區",
    Name_EN: "Ho Fa Industrial Park",
  },
  {
    ParkId: "TCF001",
    Name_CH: "豐洲科技工業園區",
    Name_EN: "Ho Fa Industrial Park",
  },
  {
    ParkId: "TCJ001",
    Name_CH: "臺中精密機械科技創新園區",
    Name_EN: "Ho Fa Industrial Park",
  },
  {
    ParkId: "CID001",
    Name_CH: "大埔美精密機械園區",
    Name_EN: "",
  },
  {
    ParkId: "CIM001",
    Name_CH: "馬稠後產業園區",
    Name_EN: "",
  },
  {
    ParkId: "TNH001",
    Name_CH: "和順工業區",
    Name_EN: "",
  },
  {
    ParkId: "TNY001",
    Name_CH: "永康科技工業區",
    Name_EN: "",
  },
  {
    ParkId: "TNS001",
    Name_CH: "新吉工業區",
    Name_EN: "Ho Fa Industrial Park",
  },
  {
    ParkId: "TNZ001",
    Name_CH: "總頭寮工業區",
    Name_EN: "Ho Fa Industrial Park",
  },
  {
    ParkId: "TNB001",
    Name_CH: "保安工業區",
    Name_EN: "Ho Fa Industrial Park",
  },
  {
    ParkId: "KHB001",
    Name_CH: "岡山本洲產業園區",
    Name_EN: "Benjhou Industrial Park",
  },
  {
    ParkId: "KHH001",
    Name_CH: "和發產業園區",
    Name_EN: "Ho Fa Industrial Park",
  },

  {
    ParkId: "KHW001",
    Name_CH: "萬大工業區",
    Name_EN: "Ho Fa Industrial Park",
  },
];

