import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { Icon, Grid, Modal, Card, Slide, Dialog, Tooltip, Button, Box } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import Footer from "../../components/Footer/Footer";
import Spinner from "../../components/Spinner/Spinner";
import ImgGrid from "../../components/ImgGrid/ImgGrid";
import CustomizedTable from "../../components/CustomizedTable/CustomizedTable";
import GovDetail from "../../components/GovPlanDetail/GovPlanDetail";
import * as govPlanActions from "../../store/actions/govplan";
import BackToTopButton from "../../components/BackToTopButton/BackToTopButtonV2";
import { thousandDivider } from "../../utility/common";
import * as theme from "../../assets/theme/index";

import "./styles.scss";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const MySwal = withReactContent(Swal);

const GovPlanDisplay = (props) => {
  const tableRef = React.useRef(null);
  const mobileContainerRef = React.useRef(null);

  const { activePlanList, loading } = useSelector((state) => state.govPlan);
  const { isAuth } = useSelector((state) => state.auth);
  const { innerWidth } = useSelector((state) => state.app);
  const dispatch = useDispatch();
  const [rollBackToPageOne, setRollBackToPageOne] = useState(false);

  const [tabValue, setTabValue] = useState("list");
  const [data, setData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [plan, setPlan] = useState();
  const [selectedCategory, setSelectedCategory] = useState();
  const [searchKeyword, setSearchKeyword] = useState();
  const { project_id } = props.match.params;


  useEffect(()=>{
    if(activePlanList){
      if(window.location.search){
        const key_word = window.location.search.split("=")[1];
        const updateData = [];
        activePlanList.map((content) => {
          if (Object.values(content).toString().indexOf(decodeURI(key_word)) !== -1) {
            updateData.push(content);
          }
        });
        setSearchKeyword(decodeURI(key_word));
        setData(updateData);
      }
    }
  }, [activePlanList]);

  useEffect(() => {
    if (activePlanList) {
      if (project_id) {
        const isProjectId = activePlanList.filter(
          (plan) => plan.ProjectId === project_id
        );
        if (isProjectId.length === 0) {
          MySwal.fire({
            text: "查無此專案編號",
          }).then(() => {});
        } else {
          if (innerWidth > 1024) {
            setPlan(isProjectId[0]);
            setIsModalOpen(true);
          } else {
            setPlan(isProjectId[0]);
            setIsDialogOpen(true);
          }
        }
      }
    }
  }, [project_id, activePlanList]);

  useEffect(() => {
    if (activePlanList && activePlanList.length > 0) {
      setData([ ...activePlanList]);
    }
  }, [activePlanList]);

  useEffect(() => {
    dispatch(govPlanActions.getAllGovPlans());
  }, [dispatch]);

  useEffect(() => {
    if (selectedCategory) {
      if (data) {
        const updatePlans = [];

        activePlanList.map((plan) => {
          if (plan.Category.toString().indexOf(selectedCategory) !== -1) {
            updatePlans.push(plan);
          }
        });

        setData(updatePlans);
      }
    }
  }, [selectedCategory]);

  useEffect(() => {
    if (searchKeyword) {
      const updateData = [];

      if (data) {
        activePlanList.map((content) => {
          if (Object.values(content).toString().indexOf(searchKeyword) !== -1) {
            updateData.push(content);
          }
        });
      }
      setData(updateData);
    } else if (!searchKeyword) {
      setData(activePlanList);
    }
  }, [searchKeyword]);

  const handleOrder = (value, type) => {
    let orderData;

    switch (type) {
      case "displayName":
        if (value) {
          orderData = data.sort((a, b) => ("" + a.Name).localeCompare(b.Name));
        } else {
          orderData = data.sort((a, b) => ("" + b.Name).localeCompare(a.Name));
        }

        setData(orderData);
        break;
      case "displayCategory":
        if (value) {
          orderData = data.sort((a, b) => ("" + a.Name).localeCompare(b.Name));
        } else {
          orderData = data.sort((a, b) => ("" + b.Name).localeCompare(a.Name));
        }

        setData(orderData);
        break;
      case "displayAmount":
        if (value) {
          orderData = data.sort((a, b) => a.Amount - b.Amount);
        } else {
          orderData = data.sort((a, b) => b.Amount - a.Amount);
        }

        setData(orderData);
        break;
      case "displayDate":
        if (value) {
          orderData = data.sort((a, b) => ("" + a.Name).localeCompare(b.Name));
        } else {
          orderData = data.sort((a, b) => ("" + b.Name).localeCompare(a.Name));
        }

        setData(orderData);
        break;
      case "displayHost":
        if (value) {
          orderData = data.sort((a, b) => ("" + a.Name).localeCompare(b.Name));
        } else {
          orderData = data.sort((a, b) => ("" + b.Name).localeCompare(a.Name));
        }

        setData(orderData);
        break;

      default:
        return null;
    }
  };

  const modalHandler = (projectId) => {
    const plan = activePlanList.filter((plan) => plan.ProjectId === projectId);
    setPlan(plan[0]);
    setIsModalOpen(true);
  };

  const dialogHandler = (projectId) => {
    const plan = activePlanList.filter((plan) => plan.ProjectId === projectId);
    setPlan(plan[0]);
    setIsDialogOpen(true);
  };

  const contentHandler = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleClose = () => {
    setIsModalOpen(false);
    setIsDialogOpen(false);
  };

  const categorySelectHandler = (category) => {
    tableRef.current.scrollTo({ top: 0, behavior: "smooth" });
    if(innerWidth < 750){
      mobileContainerRef.current.scrollTo({ top: 250, behavior: "smooth" });
    }

    console.log(mobileContainerRef)

    if (category === selectedCategory) {
      setSelectedCategory();
      setData(activePlanList);
    } else {
      setSelectedCategory(category);
    }
  };

  const searchHandler = (event) => {
    const value = event.target.value;
    setSearchKeyword(value);
    // setRollBackToPageOne(true)
  };

  const resetHandler = () => {
    setSearchKeyword("");
    setSelectedCategory();
    setData(activePlanList);
  };

  let content;

  const scrollToTop = () => {
    mobileContainerRef.current.scrollTo({ top: 0, behavior: "smooth" })
  };

  // 我有興趣: 發信到免費諮詢服務 mail
  const email = localStorage.getItem('uid');
  const sendMail = (id) => {
    Swal.fire({
      title: '確定要發送 Email 嗎？',
      text: '我們提供免費諮詢服務 (Email)，若您對此計畫有興趣，可點『發送 Email』按鈕，將有專人為您服務！',
      showCancelButton: true,
      confirmButtonColor: "#006ab4",
      cancelButtonText: '取消',
      confirmButtonText: '發送 Email',
    }).then(async (result) => {
      if (result.isConfirmed) {
        const form = new FormData();
        form.append("UserId", email);
        form.append("ProjectId", id);
        await dispatch(govPlanActions.sendGovPlanInterestNotifyHandler(form))
      }
    })
    
  }

  const checkApplyDate = (startDate, endDate) => {
    // 資源的結束申請時間大於當前時間 —> 名稱 + (申請中)
    // 資源的開始申請時間大於當前時間 —> 名稱 + (未開放)
    const now = new Date().getTime();
    const start = new Date(startDate).getTime();
    const end = new Date(endDate).getTime();
    let status = {
      name: "",
      bgColor: "",
    };
    if (now > start && now < end) {
      status = {
        name: "申請中",
        bgColor: "rgb(0, 106, 180)",
      }
    } else if (now < start) {
      status = {
        name: "未開放",
        bgColor: "#666666",
      }
    }
    return status;

  }

  if (loading) {
    content = <Spinner />;
  } else if (tabValue === "list" && !loading) {
    if (innerWidth > 1023) {
      content = (
        <div>
          <CustomizedTable
            rollBackToPageOne={rollBackToPageOne}
            type="main-table"
            displayMode
            content={data}
            getProjectId={modalHandler}
            handleOrder={(value, type) => handleOrder(value, type)}
            hover
            tableRef={tableRef}
            sendMail={(id) => {
              sendMail(id);
            }}
          />
        </div>
      );
    } else {
      content = (
        <div className="mobile-table">
          <Grid
            container
            spacing={3}
            direction="column"
            justify="flex-start"
            alignItems="center"
          >
            {data ? (
              data.map((item) => (
                <Grid
                  item
                  component={Card}
                  className="item"
                  onClick={() => dialogHandler(item.ProjectId)}
                >
                  <div>
                    <label>名稱 :</label> <p>{item.Name} {` (${checkApplyDate(item.StartDate, item.EndDate)?.name})`}</p>
                  </div>
                  <div>
                    <label>時間 :</label>{" "}
                    <p>
                      {item.StartDate} ~ {item.EndDate}
                    </p>
                  </div>
                  {/* <div>
                    <label>補助上限 :</label>{" "}
                    <p>{item.Amount === 0 ? "依計畫核定" : thousandDivider(item.Amount)}</p>
                  </div> */}
                  <div>
                    <label>計畫連結 :</label>{" "}
                    <p
                      className="link"
                      onClick={() => window.open(item.Link, "_blank")}
                    >
                      {item.Link}
                    </p>
                  </div>
                  <div>
                    <label>類型 :</label>{" "}
                    {item.Category
                      ? item.Category.map((catItem) => {
                          switch (catItem) {
                            case "人力資源相關":
                              return <Icon className="icon">face</Icon>;
                            case "研究發展相關":
                              return <Icon className="icon">developer_mode</Icon>;
                            case "市場行銷相關":
                              return <Icon className="icon">storefront</Icon>;
                              case "資金相關":
                                return <Icon className='icon'>storefront</Icon>;
                              case "計畫廣宣":
                                return <Icon className='icon'>featured_video</Icon>;
                              case "企業獎項":
                                return <Icon className='icon'>emoji_events</Icon>;
                            case "淨零減碳":
                              return <Icon className="icon">co2</Icon>;
                            case "資金貸款":
                              return <Icon className="icon">money</Icon>;
                            case "數位轉型":
                              return <Icon className="icon">electric_bolt</Icon>;
                            default:
                              return <Icon className="icon">monetization_on</Icon>;
                          }
                        })
                      : null}
                  </div>
                  <div>
                    <Box width="100%" display="flex" justifyContent="flex-end">
                      <Tooltip title={`點我發送免費諮詢服務(email)，將有專人為您服務！`}>
                        <StyledInterestButton
                          variant="contained"
                          onClick={(e) => {
                            e.stopPropagation();
                            sendMail(item.ProjectId)
                          }}
                        >
                          我有興趣
                        </StyledInterestButton>
                      </Tooltip>
                    </Box>
                  </div>
                </Grid>
              ))
            ) : (
              <Grid>暫時無資料</Grid>
            )}
          </Grid>
        </div>
      );
    }
  } else if (tabValue === "grid" && !loading) {
    content = (
      <div className="display-grid">
        <Grid
          container
          spacing={2}
          direction="row"
          justify="flex-start"
          alignItems="center"
        >
          {data.length !== 0 ? (
            data.map((plan) => (
              <ImgGrid
                plan={plan}
                getProjectId={innerWidth > 1024 ? modalHandler : dialogHandler}
              />
            ))
          ) : (
            <p>暫時沒有資料</p>
          )}
        </Grid>
      </div>
    );
  }

  return (
    <div className="gov-plan-display"  ref={mobileContainerRef}>
      <BackToTopButton scrollToTop={scrollToTop} />
      <div className="gov-plan-display-title">
        <div className="gov-plan-display-title-left">
          <h1>政府資源</h1>
          <p>蒐集政府各部會補助或輔導資源資訊</p>
        </div>
        <div>
          <ToggleButtonGroup
            value={tabValue}
            onChange={contentHandler}
            exclusive
          >
            <ToggleButton title="以列表排版檢視" value="list" disabled={tabValue === "list"}>
              <Icon>list</Icon>
            </ToggleButton>
            <ToggleButton title="以卡片排版檢視" value="grid" disabled={tabValue === "grid"}>
              <Icon>view_module</Icon>
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
      </div>

      <div className="category-container">
        <div className="category-item-container">
          <button
            className={
              selectedCategory === "淨零減碳"
                ? "category-item-selected"
                : "category-item"
            }
            style={{ backgroundColor: "#12736e" }}
            onClick={() => categorySelectHandler("淨零減碳")}
          >
            <Icon className="icon">co2</Icon>
            <p>淨零減碳</p>
          </button>
          <button
            className={
              selectedCategory === "數位轉型"
                ? "category-item-selected"
                : "category-item"
            }
            style={{ backgroundColor: "#103778" }}
            onClick={() => categorySelectHandler("數位轉型")}
          >
            <Icon className="icon">electric_bolt</Icon>
            <p>數位轉型</p>
          </button>
          <button
            className={
              selectedCategory === "研究發展相關"
                ? "category-item-selected"
                : "category-item"
            }
            style={{ backgroundColor: "#1a7a3d" }}
            onClick={() => categorySelectHandler("研究發展相關")}
          >
            <Icon className="icon">developer_mode</Icon>
            <p>研究發展</p>
          </button>
          <button
            className={
              selectedCategory === "市場行銷相關"
                ? "category-item-selected"
                : "category-item"
            }
            style={{ backgroundColor: "#1085C9" }}
            onClick={() => categorySelectHandler("市場行銷相關")}
          >
            <Icon className="icon">storefront</Icon>
            <p>市場行銷</p>
          </button>
          <button
            className={
              selectedCategory === "人力資源相關"
                ? "category-item-selected"
                : "category-item"
            }
            style={{ backgroundColor: "#8b6132" }}
            onClick={() => categorySelectHandler("人力資源相關")}
          >
            <Icon className="icon">face</Icon>
            <p>人力資源</p>
          </button>
          {/* <button
            className={
              selectedCategory === "計畫廣宣"
                ? "category-item-selected"
                : "category-item"
            }
            style={{ backgroundColor: "#E34928" }}
            onClick={() => categorySelectHandler("計畫廣宣")}
          >
            <Icon className="icon">featured_video</Icon>
            <p>課程訊息</p>
          </button> */}
          <button
            className={
              selectedCategory === "企業獎項"
                ? "category-item-selected"
                : "category-item"
            }
            style={{ backgroundColor: "#4F1FB0" }}
            onClick={() => categorySelectHandler("企業獎項")}
          >
            <Icon className="icon">emoji_events</Icon>
            <p>企業獎項</p>
          </button>
      
          <button
            className={
              selectedCategory === "資金貸款"
                ? "category-item-selected"
                : "category-item"
            }
            style={{ backgroundColor: "#E3371E" }}
            onClick={() => categorySelectHandler("資金貸款")}
          >
            <Icon className="icon">money</Icon>
            <p>資金貸款</p>
          </button>
        </div>

        <div className="search-bar-container">
          <div className="search-bar">
            <Icon className="icon">search</Icon>
            <input
              placeholder="請輸入欲查詢的關鍵字"
              onChange={(event) => searchHandler(event)}
              onClick={resetHandler}
              value={searchKeyword}
            />
          </div>
        </div>
      </div>

      <div className="content" ref={tableRef}>{content}</div>

      <Modal
        open={isModalOpen}
        // open={true}
        // onClose={handleClose}
      >
        <div>
          {/* <div className="modal-container-close-lg" onClick={handleClose}><Icon>close</Icon></div> */}
          <div className="modal-container-gov-plan-display">
            <GovDetail displayMode plan={plan} closeModal={handleClose} />
          </div>
        </div>
      </Modal>
      <Dialog open={isDialogOpen} TransitionComponent={Transition}>
        <div>
          {/* <div className="modal-container-close-lg" onClick={handleClose}><Icon>close</Icon></div> */}
          <div className="modal-container-gov-plan-display">
            <GovDetail displayMode plan={plan} closeModal={handleClose} />
          </div>
        </div>
      </Dialog>
      {/* <Footer style={{ width: "135%", marginLeft: "-20%", marginTop: "20px" }}/>  */}
    </div>
  );
};

const StyledInterestButton = styled(Button)`
  background-color:  ${theme.linkBlue} !important;
  color: ${theme.white} !important;
  white-space: nowrap;

  &:hover {
    opacity: 0.8;
  }
`


export default GovPlanDisplay;
