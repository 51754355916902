import React, { useEffect, useState } from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Icon from "@material-ui/core/Icon";
import CircularProgress from "@material-ui/core/CircularProgress";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBiohazard, faRecycle, faWater, faWind, faMapMarkedAlt } from "@fortawesome/free-solid-svg-icons";
import { Collapse } from "@material-ui/core";
import SwiperCore, { A11y, Navigation } from 'swiper'


// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import parkConverter from "../../utility/parkIdToNameConvertor";
import { getControlledMaterialDetail } from "./api";
import 'swiper/swiper.scss';
import "../../assests/styles/_swiper-navigation.scss";
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';
import "./styles.scss";


SwiperCore.use([A11y, Navigation])

export default (props) => {
  const swiperRef = React.useRef(null);
  const { 
    uid, 
    name, 
    address, 
    parkId, 
    busineesNo, 
    CtrlId, 
    MonitoringType, 
    onClick, 
    tabData,
    IsToxicPoll,
    IsWastePoll,
    isFlyTo,
    passIsContentOpenHandler,
    noFlyTo
   } = props;
  const [state, setState] = useState({
    activeContent: "wastedPollution",
    value: 0,
    content: "",
    WastePollList: "",
    ToxicPollList: "",
    isContentOpen: false,
    loading: false,
    isAirPollution: false,
    isWastedPollution: false,
    isToxicPollution: false,
    isWaterPollution: false,
    currentSide:0
  });
  const url = window.location.href.split('/')
  

  const prevSlide = React.useCallback((e) => {
    e.stopPropagation();
    swiperRef.current.swiper.slidePrev();
  }, [swiperRef]);

  const nextSlide = React.useCallback((e) => {
    e.stopPropagation();
    swiperRef.current.swiper.slideNext();
  }, [swiperRef]);

  React.useEffect(()=>{
    passIsContentOpenHandler(state.isContentOpen);
  }, [state.isContentOpen]);

  useEffect(()=>{
    if(tabData){
      setState((state) => ({
        ...state,
        isContentOpen:true,
        WastePollList: tabData.WastePollList,
        ToxicPollList: tabData.ToxicPollList,
      }));
    }
  },[tabData])

  useEffect(() => {
    const { WastePollList, ToxicPollList } = state;
    let listContent;

    if (state.activeContent === "wastedPollution") {
      if (WastePollList[0]) {
        listContent = WastePollList.map(content =>(
          <SwiperSlide index={1}>
            <ul>
            <li>
              <span>清理方式:</span> {content.CleanType}
            </li>
            <li>
              <span>名稱:</span> 
              {content.WasteName.length > 25 ? 
               content.WasteName.substring(25) + " ... " :
               content.WasteName
              }
            </li>
            <li>
              <span>申報量:</span>{" "}
              {Number(content.ReportAmt.toFixed(3)) + " " + content.Unit}
            </li>
            <li>
              <span>申報時段:</span> {content.ReportYearMonth}
            </li>
            </ul>
            </SwiperSlide>
        ));
        
        setState(state=>({
          ...state,
          content: listContent,
        }));
      } else {
        setState(state=>({
          ...state,
          content: <p index={0}>{"尚無申報資料"}</p>,
        }));
      }
    } else {
      if (ToxicPollList[0]) {
        listContent = ToxicPollList.map(content =>(
          <SwiperSlide index={0}>
            <ul>
            <li>
              <span>毒學物質中文名稱:</span> {content.ToxicNameCh}
            </li>
            {/* <li>
              <span>毒化物英文質名稱:</span> {content.ToxicNameEn}
            </li> */}
            <li>
              <span>列管物總噸數:</span> {content.ReportTon}
            </li>
            <li>
              <span>時間:</span> {content.ReportYearSeason}
            </li>
            <li>
              <span>使用狀態:</span> {content.ReportType}
            </li>
            </ul>
            </SwiperSlide>
        ));

        setState(state=>({
          ...state,
          content: listContent,
        }));
      } else {
        setState(state=>({
          ...state,
          content: <p index={0}>{"尚無申報資料"}</p>,
        }));
      }
    }
  }, [state.activeContent, state.WastePollList, state.ToxicPollList, state.isContentOpen, tabData]);

  useEffect(() => {
    tabsGenerator();
  }, [MonitoringType]);

  //處理哪些 tabs 要出現哪些不用。
  const tabsGenerator =  React.useCallback(() => {
    if (MonitoringType) {
      const monitorTypeArr = MonitoringType.split(";");
      monitorTypeArr.map(type => typeDetermine(type));
    } else {
      //什麼類型都沒有的時候
      return
    }
  },[]);

  const typeDetermine =  React.useCallback((type) => {
    switch (type) {
      case "空氣污染":
        setState((state) => ({ ...state, isAirPollution: true }));
        break;
      case "廢棄物":
        setState((state) => ({ ...state, isWastedPollution: true }));
        break;
      case "毒性化學物質":
        setState((state) => ({ ...state, isToxicPollution: true }));
        break;
      case "水污染":
        setState((state) => ({ ...state, isWaterPollution: true }));
        break;
      default:
        return "在判斷列管物分類時發生錯誤。";
    }
  },[]);

  const handleChange = (e, newValue) => {
    e.stopPropagation();
    const { isWastedPollution,isToxicPollution } = state;
    if(isWastedPollution && !isToxicPollution){
      setState(state=>({
        ...state,
        value: 0,
        activeContent: "wastedPollution",
        // content: <p index={0}>{state.WastedPollution}</p>,
      }));
    }else if(!isWastedPollution && isToxicPollution){
      setState(state=>({
        ...state,
        value: 1,
        activeContent: "toxicPollution",
        // content: <p index={1}>{state.}</p>,
      }));
    }else{
      if (newValue === 0) {
        setState(state=>({
          ...state,
          value: 0,
          activeContent: "toxicPollution",
          // content: <p index={0}>{state.WastedPollution}</p>,
        }));
      } else {
        setState(state=>({
          ...state,
          value: 1,
          activeContent: "wastedPollution",
          // content: <p index={1}>{state.}</p>,
        }));
      }
    }
  };

  const fetchDetailHandler = async (e) => {
    e.stopPropagation()
    setState((state) => ({ ...state, isContentOpen: true, loading: true }));
    const data = await getControlledMaterialDetail(CtrlId);

    setState((state) => ({
      ...state,
      loading: false,
      WastePollList: data.WastePollList,
      ToxicPollList: data.ToxicPollList,
    }));
  };

  const handleFlyTo = e => {
    e.stopPropagation();
    onClick()
  };

  const chineseCount = (word) => {
    return word.toString().split(/[\u4e00-\u9a05]/).length -1;
  };

  return (
    <div className="result-item" key={uid} { ...props } onClick={(e)=>{e.stopPropagation()}}>
      <div className="result-item-head">
        <h4 
          onClick={busineesNo ? (e) => { 
              e.stopPropagation();
              window.open(url[0] + "//" + url[2] + "/factory_info/basic_info/" + busineesNo, "_blank") 
            }:(e)=>{e.stopPropagation()}}
          className={busineesNo ? "with-business-no" : "with-no-business-no"}
          >
          {name}
          {!noFlyTo && <FontAwesomeIcon className="icon" icon={faMapMarkedAlt} onClick={isFlyTo ? e => handleFlyTo(e) : (e)=>{e.stopPropagation()}}/>}
          </h4>
        <div className="tags-container">
          {IsWastePoll && <p className="tags">廢棄物申報</p>}
          {IsToxicPoll && <p className="tags">毒化物申報</p>}
        </div> 
        <p onClick={parkId ? (e)=>{ 
          e.stopPropagation()
          window.open(url[0] + "//" + url[2] + "/park_dashboard/" + parkId, '_blank') }: ()=>{}}>{parkConverter(parkId)}</p>
        <p onClick={(e) => { 
          e.stopPropagation()
          window.open(`https://maps.google.com/?q=${address}`, "_blank") }}>
          {chineseCount(address) > 20 ? address.substring(20) + " ... " : address}
        </p>
      </div>
      <Tabs
        className="result-item-tabs"
        value={state.value}
        onChange={(e, newValue) => handleChange(e, newValue)}
      >
        {state.isToxicPollution && <Tab
          // disabled={!state.isToxicPollution}
          icon={<FontAwesomeIcon icon={faBiohazard} />}
          onClick={(e) => fetchDetailHandler(e)}
        />}
        {state.isWastedPollution && <Tab
          // disabled={!state.isWastedPollution}
          // style={state.isWastedPollution ? {}:{width:'0px'}}
          icon={<FontAwesomeIcon icon={faRecycle} />}
          onClick={(e) => fetchDetailHandler(e)}
        />}
        {false && <Tab
          disabled={!state.isWaterPollution}
          // style={state.isWaterPollution ? {}:{width:'0px'}}
          icon={<FontAwesomeIcon icon={faWater} />}
          onClick={(e) => fetchDetailHandler(e)}
        />}
        {false && <Tab
          disabled={!state.isAirPollution}
          // style={state.isAirPollution ? {}:{width:'0px'}}
          icon={<FontAwesomeIcon icon={faWind} />}
          onClick={(e) => fetchDetailHandler(e)}
        />}
      </Tabs>
      {state.isContentOpen  && 
            <div>
            <div role="tabpanel" className="result-item-content">
              <div style={{display:"flex", alignItems:"center"}}>
                <Icon 
                  onClick={state.currentSide === 0 ? (e)=>{e.stopPropagation()} : (e) => prevSlide(e)}
                  style={state.currentSide === 0 ? {opacity:"0.2", cursor:"not-allowed"} : {}}  
                  >keyboard_arrow_left</Icon>
                  <Swiper
                    ref={swiperRef}
                    spaceBetween={0}
                    slidesPerView={1}
                    onSwiper={(swiper) => {  setState(state=>({ ...state, currentSide: swiper.activeIndex}))}}
                    onSlideChange={(swiper) => {  setState(state=>({ ...state, currentSide: swiper.activeIndex}))}}
                    pagination
                    initialSlide={0}
                  >
                    {state.loading ? <CircularProgress /> : state.content}
                    </Swiper>
                    <Icon 
                      onClick={state.currentSide === state.content.length - 1 ? (e)=>{e.stopPropagation()} : (e) => nextSlide(e)}
                      style={state.currentSide === state.content.length - 1 ? {opacity:"0.2", cursor:"not-allowed"} : {}}  
                    
                    >keyboard_arrow_right</Icon>
              </div>
            </div>
          </div>
      }
    </div>
  );
};
