import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Chip from "@mui/material/Chip";
import Button from "@mui/material/Button";
import Select from "@mui/material/Select";
import Collapse from "@mui/material/Collapse";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUp from "@mui/icons-material/KeyboardArrowUp";
import { Swiper, SwiperSlide } from "swiper/react";
import Swal from "sweetalert2";

import * as theme from "../../assets/theme";

const SearchContainer = ({
  handle_change,
  event_id,
  industry_code,
  product_import_code,
  product_export_code,
  is_declined,
  handle_show_is_decline,
  handleFetchData,
  handleRestAllStates,
  selected_tag_list,
  handle_toggle_console,
  parent_is_open_console
}) => {
  const basicSelectSettings = {
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "left",
    },
    getContentAnchorEl: null,
  }
  const DesktopSelect = {
    PaperProps: {
      style: {
        maxHeight: "300px",
      },
    },
    ...basicSelectSettings,
  };
  const MobileSelect = {
    PaperProps: {
      style: {
        maxHeight: "200px",
      },
    },
    ...basicSelectSettings,
  };
  const { effected_events_list } = useSelector(
    (state) => state.effected_events
  );
  const { innerWidth } = useSelector((state) => state.app);

  const [state, setState] = React.useState({
    is_search_console_open: true,
    selected_tag_list: [],
  });

  const handleToggleSearchConsole = () => {
    handle_toggle_console()
    setState((state) => ({
      ...state,
      is_search_console_open: !state.is_search_console_open,
    }));
  };

  const options = React.useMemo(() => {
    if (effected_events_list.length > 0) {
      const option = effected_events_list.filter(
        (i) => event_id === i.EventId
      )[0];
      if (option) {
        return {
          industry_code_option: option.EventIndustryStr || [],
          product_import_code_option: option.EventImport4Code || [],
          product_export_code_option: option.EventExport4Code || [],

        };
      } else {
        return {
          industry_code_option: [],
          product_import_code_option: [],
          product_export_code_option:  [],
        };
      }
    }
  }, [event_id, effected_events_list]);

  const handleOpenTags = () => {
    Swal.fire({
      text:
        selected_tag_list.length < 1
          ? "請選擇條件。"
          : selected_tag_list.concat(""),
    });
  };

  React.useEffect(()=>{
    setState(state =>({ ...state, is_search_console_open: parent_is_open_console }))
  }, [parent_is_open_console])

  if (innerWidth < 750) {
    return (
      <InputsAndResultSelectContainer>
        <InputsAndResultSelectHead>
          <InputsAndResultSelectHeadTextContainer>
            <InputsAndResultSelectHeadText onClick={handleOpenTags}>
              搜尋條件
            </InputsAndResultSelectHeadText>
            <Swiper
              spaceBetween={20}
              slidesPerView={1}
              pagination={false}
              initialSlide={0}
              style={{ width: "60%" }}
              loop={true}
              autoplay={{
                delay: 3000,
                disableOnInteraction: true,
              }}
            >
              {selected_tag_list.map((i) => (
                <SwiperSlide>
                  <Chip
                    label={i.length > 10 ? `${i.substring(0, 10)}...` : i}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </InputsAndResultSelectHeadTextContainer>
          <InputsAndResultSelectHeadIconContainer>
            {state.is_search_console_open ? (
              <KeyboardArrowDown onClick={handleToggleSearchConsole} />
            ) : (
              <KeyboardArrowUp onClick={handleToggleSearchConsole} />
            )}
          </InputsAndResultSelectHeadIconContainer>
        </InputsAndResultSelectHead>
        <Collapses in={state.is_search_console_open}>
          <SelectionContainer>
          <FormControls>
              <InputLabel>事件</InputLabel>
              <Selects
                label="事件"
                onChange={handle_change}
                name={"event_id"}
                value={event_id}
              >
                {(effected_events_list || []).map((option, index) => (
                  <MenuItem
                    key={option.EventName + index}
                    value={option.EventId}
                  >
                    {option.EventName}
                  </MenuItem>
                ))}
              </Selects>
            </FormControls>
            <FormControls>
              <InputLabel>受影響產業</InputLabel>
              <Selects
                label="受影響產業"
                onChange={handle_change}
                name={"industry_code"}
                value={industry_code}
                multiple
                disabled={!event_id}
              >
                {(options?.industry_code_option || []).map((option, index) => (
                  <MenuItem key={option + index} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Selects>
            </FormControls>
            <FormControls>
              <InputLabel>受影響進口產品</InputLabel>
              <Selects
                MenuProps={MobileSelect}
                label="受影響進口產品"
                onChange={handle_change}
                name={"product_import_code"}
                value={product_import_code}
                multiple
                disabled={!event_id}
              >
                {(options?.product_import_code_option || []).map((option, index) => (
                  <MenuItem key={option + index} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Selects>
            </FormControls>
            <FormControls>
              <InputLabel>受影響出口產品</InputLabel>
              <Selects
                MenuProps={MobileSelect}
                label="受影響出口產品"
                onChange={handle_change}
                name={"product_export_code"}
                value={product_export_code}
                multiple
                disabled={!event_id}
              >
                {(options?.product_export_code_option || []).map((option, index) => (
                  <MenuItem key={option + index} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Selects>
            </FormControls>
          </SelectionContainer>
          <IsDeclineContainer>
            <FormControlLabel
              control={
                <StyledCheckbox
                  color="primary"
                  checked={state.is_declined}
                  onClick={handle_show_is_decline}
                />
              }
              label="查看關懷廠商建議清單。"
            />
          </IsDeclineContainer>
          <ButtonContainer>
            <StyledButton
              variant="contained"
              onClick={handleFetchData}
              disabled={!event_id}
            >
              查詢
            </StyledButton>
            <HelperText onClick={handleRestAllStates}>清除資料</HelperText>
          </ButtonContainer>
        </Collapses>
      </InputsAndResultSelectContainer>
    );
  }

  return (
    <InputsAndResultSelectContainer>
      <InputsAndResultSelectHead>
        <InputsAndResultSelectHeadTextContainer>
          <InputsAndResultSelectHeadText>
            搜尋條件
          </InputsAndResultSelectHeadText>
          <Tooltip 
            // title={selected_tag_list.concat(", ")}
            title={
              <React.Fragment>
                <p style={{lineHeight: "20px"}}>{selected_tag_list.join()}</p>
              </React.Fragment>
            }
            >
            <TagContainer direction="row" spacing={1}>
              {selected_tag_list.map((i) => (
                <Chip label={i.length > 10 ? `${i.substring(0, 10)}...` : i} />
              ))}
            </TagContainer>
          </Tooltip>
        </InputsAndResultSelectHeadTextContainer>
        <InputsAndResultSelectHeadIconContainer>
          {state.is_search_console_open ? (
            <KeyboardArrowDown onClick={handleToggleSearchConsole} />
          ) : (
            <KeyboardArrowUp onClick={handleToggleSearchConsole} />
          )}
        </InputsAndResultSelectHeadIconContainer>
      </InputsAndResultSelectHead>
      <Collapses in={state.is_search_console_open}>
        <SelectionContainer>
          <CoveredContainer>
            <FormControls>
              <InputLabel>事件</InputLabel>
              <Selects
                label="事件"
                onChange={handle_change}
                name={"event_id"}
                value={event_id}
              >
                {effected_events_list.map((option, index) => (
                  <MenuItem
                    key={option.EventName + index}
                    value={option.EventId}
                  >
                    {option.EventName}
                  </MenuItem>
                ))}
              </Selects>
            </FormControls>
          </CoveredContainer>
          <MoveableContainer is_selected_event_id={event_id}>
            <FormControls>
              <InputLabel>受影響產業</InputLabel>
              <Selects
                label="受影響產業"
                onChange={handle_change}
                name={"industry_code"}
                value={industry_code}
                multiple
                disabled={!event_id}
              >
                {options?.industry_code_option.map((option, index) => (
                  <MenuItem key={option + index} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Selects>
            </FormControls>
            <FormControls>
              <InputLabel>受影響進口產品</InputLabel>
              <Selects
                MenuProps={DesktopSelect}
                label="受影響進口產品"
                onChange={handle_change}
                name={"product_import_code"}
                value={product_import_code}
                multiple
                disabled={!event_id}
              >
                {(options?.product_import_code_option || []).map((option, index) => (
                  <MenuItem key={option + index} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Selects>
            </FormControls>
            <FormControls>
              <InputLabel>受影響出口產品</InputLabel>
              <Selects
                MenuProps={DesktopSelect}
                label="受影響出口產品"
                onChange={handle_change}
                name={"product_export_code"}
                value={product_export_code}
                multiple
                disabled={!event_id}
              >
                {(options?.product_export_code_option || []).map((option, index) => (
                  <MenuItem key={option + index} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Selects>
            </FormControls>
          </MoveableContainer>
        </SelectionContainer>
        <IsDeclineContainer>
          <FormControlLabel
            control={
              <StyledCheckbox
                color="primary"
                checked={is_declined}
                onClick={handle_show_is_decline}
              />
            }
            label="查看關懷廠商建議清單。"
          />
        </IsDeclineContainer>
        <ButtonContainer>
          <StyledButton
            variant="contained"
            onClick={handleFetchData}
            disabled={!event_id}
          >
            查詢
          </StyledButton>
          <HelperText onClick={handleRestAllStates}>清除資料</HelperText>
        </ButtonContainer>
      </Collapses>
    </InputsAndResultSelectContainer>
  );
};

const InputsAndResultSelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  background-color: #ececec;
  border-radius: 20px;
  /* box-shadow: 0px 0px 20px -5px rgba(0, 0, 0, 0.2); */

  @media (max-width: 750px) {
    box-shadow: none;
    border-radius: 0px;
    border-top: 1px solid ${theme.grayBorderColor};
    border-bottom: 1px solid ${theme.grayBorderColor};
  }
`;

const InputsAndResultSelectHead = styled.div`
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const InputsAndResultSelectHeadTextContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
  height: 20px;
`;

const TagContainer = styled(Stack)`
  padding-left: 5px;
  overflow-x: scroll;
  transform: translateY(4px);
  width: 75%;
`;

const InputsAndResultSelectHeadText = styled.h3`
  font-size: 1.25rem;
  font-weight: 600;
  width: 140px;
  @media (max-width: 750px) {
    text-decoration: underline;
  }
`;

const InputsAndResultSelectHeadIconContainer = styled.div``;

const ButtonContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding: 10px;
`;

const StyledButton = styled(Button)`
  width: 80px;
  font-size: 1.125rem !important;
  transform: translateY(-1px);
`;

const SelectionContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: relative;
  overflow: hidden;
  height: 75px;

  @media (max-width: 750px) {
    flex-direction: column;
    align-items: space-between;
    height: 205px;
    margin-bottom: 10px;
    overflow: visible;
  }
`;

const CoveredContainer = styled.div`
  position: absolute;
  left: 0px;
  width: 30%;
  background-color: #ececec;
  z-index: 5;
  padding-bottom: 20px;
`;

const Selects = styled(Select)`
  max-height: 40px;
  width: 95%;
  margin: 10px 2.5%;
  > fieldset {
  }
`;

const FormControls = styled(FormControl)`
  width: 100%;
  height: 40px;
  margin: 10px 2.5%;
  > label {
    transform: translate(15px, 20px);
  }
  .MuiFormLabel-root.Mui-focused {
    color: ${theme.primaryColor} !important;
  }
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${theme.primaryColor} !important;
  }
  @media (max-width: 750px) {
    margin: 6px 2.5% !important;
  }
`;

const Collapses = styled(Collapse)`
  .MuiCollapse-wrapper {
    .MuiCollapse-wrapperInner {
      /* display:flex; */
    }
  }
`;

const MoveableContainer = styled.div`
  position: absolute;
  width: 70%;
  left: ${(p) => (p.is_selected_event_id ? "30%" : "-70%")};
  background-color: #ececec;
  display: flex;
  justify-content: space-between;
  z-index: 1;
  padding-bottom: 20px;
  transition: 0.8s left ease-in;
`;

const IsDeclineContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0px 10px;
  width: calc(100% - 20px);
`;

const StyledCheckbox = styled(Checkbox)`
  padding: 0px 9px 2px 9px !important;
`;

const HelperText = styled.p`
  font-size: 1rem;
  padding: 5px 10px;
  transform: translateX(-2px);
  text-decoration: underline;
  cursor: pointer;
`;

export default React.memo(SearchContainer);
