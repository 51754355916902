const base = `CUSTOM_INDUSTRY_DASHBOARD_`;

//新增儀表板
export const START_CREATE_DASHBOARD= `${base}START_CREATE_DASHBOARD`;
export const CREATE_DASHBOARD_SUCCESS = `${base}CREATE_DASHBOARD_SUCCESS`;
export const CREATE_DASHBOARD_FAIL = `${base}CREATE_DASHBOARD_FAIL`;

//取得儀表板清單
export const START_GET_DASHBOARD_LIST= `${base}START_GET_DASHBOARD_LIST`;
export const GET_DASHBOARD_LIST_SUCCESS = `${base}GET_DASHBOARD_LIST_SUCCESS`;
export const GET_DASHBOARD_LIST_FAIL = `${base}GET_DASHBOARD_LIST_FAIL`;

//取得儀表板當前條件
export const START_GET_DASHBOARD_COND= `${base}START_GET_DASHBOARD_COND`;
export const GET_DASHBOARD_COND_SUCCESS = `${base}GET_DASHBOARD_COND_SUCCESS`;
export const GET_DASHBOARD_COND_FAIL = `${base}GET_DASHBOARD_COND_FAIL`;

//更新儀表板名稱
export const START_UPDATE_USER_DASHBOARD_NAME= `${base}START_UPDATE_USER_DASHBOARD_NAME`;
export const UPDATE_USER_DASHBOARD_NAME_SUCCESS = `${base}UPDATE_USER_DASHBOARD_NAME_SUCCESS`;
export const UPDATE_USER_DASHBOARD_NAME_FAIL = `${base}UPDATE_USER_DASHBOARD_NAME_FAIL`;

//更新儀表板條件
export const START_UPLOAD_DASHBOARD_COND= `${base}START_UPLOAD_DASHBOARD_COND`;
export const UPLOAD_DASHBOARD_COND_SUCCESS = `${base}UPLOAD_DASHBOARD_COND_SUCCESS`;
export const UPLOAD_DASHBOARD_COND_FAIL = `${base}UPLOAD_DASHBOARD_COND_FAIL`;

//複製儀表板
export const START_COPY_DASHBOARD= `${base}START_COPY_DASHBOARD`;
export const COPY_DASHBOARD_SUCCESS = `${base}COPY_DASHBOARD_SUCCESS`;
export const COPY_DASHBOARD_FAIL = `${base}COPY_DASHBOARD_FAIL`;

//刪除儀表板
export const START_DELETE_DASHBOARD= `${base}START_DELETE_DASHBOARD`;
export const DELETE_DASHBOARD_SUCCESS = `${base}DELETE_DASHBOARD_SUCCESS`;
export const DELETE_DASHBOARD_FAIL = `${base}DELETE_DASHBOARD_FAIL`;

//取得工廠四碼
export const START_GET_ALL_FACTORY_INDUSTRY= `${base}START_GET_ALL_FACTORY_INDUSTRY`;
export const GET_ALL_FACTORY_INDUSTRY_SUCCESS = `${base}GET_ALL_FACTORY_INDUSTRY_SUCCESS`;
export const GET_ALL_FACTORY_INDUSTRY_FAIL = `${base}GET_ALL_FACTORY_INDUSTRY_FAIL`;

//國別清單
export const START_GET_COUNTRY_LIST= `${base}START_GET_COUNTRY_LIST`;
export const GET_COUNTRY_LIST_SUCCESS = `${base}GET_COUNTRY_LIST_SUCCESS`;
export const GET_COUNTRY_LIST_FAIL = `${base}GET_COUNTRY_LIST_FAIL`;

//進口清單排名 (參數：國別、年分)
export const START_GET_IMPORT_COUNTRY_TRADE_PRODUCT= `${base}START_GET_IMPORT_COUNTRY_TRADE_PRODUCT`;
export const GET_IMPORT_COUNTRY_TRADE_PRODUCT_SUCCESS = `${base}GET_IMPORT_COUNTRY_TRADE_PRODUCT_SUCCESS`;
export const GET_IMPORT_COUNTRY_TRADE_PRODUCT_FAIL = `${base}GET_IMPORT_COUNTRY_TRADE_PRODUCT_FAIL`;

//出口清單排名 (參數：國別、年分)
export const START_GET_EXPORT_COUNTRY_TRADE_PRODUCT= `${base}START_GET_EXPORT_COUNTRY_TRADE_PRODUCT`;
export const GET_EXPORT_COUNTRY_TRADE_PRODUCT_SUCCESS = `${base}GET_EXPORT_COUNTRY_TRADE_PRODUCT_SUCCESS`;
export const GET_EXPORT_COUNTRY_TRADE_PRODUCT_FAIL = `${base}GET_EXPORT_COUNTRY_TRADE_PRODUCT_FAIL`;