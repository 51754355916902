import React from "react";
import styled from "styled-components";
import Collapse from "@material-ui/core/Collapse";
import Icon from "@material-ui/core/Icon";
import { useHistory } from "react-router-dom";
import Checkbox from "@mui/material/Checkbox";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import { IconButton } from "@material-ui/core";

export default (props) => {
  const { name, count, companies } = props.data;
  const [state, setState] = React.useState({
    isBoxOpen: false,
    iconText: "keyboard_arrow_down",
  });
  const history = useHistory();

  const handleBoxOpen = () => {
    setState(state=>({ 
        ...state, 
        isBoxOpen:!state.isBoxOpen, 
        iconText: state.iconText === "keyboard_arrow_up" ? "keyboard_arrow_down" : "keyboard_arrow_up"
    }))
  };

  const handleOpenNewTab = (businessNo) => {
    const origin = window.location.origin;
    //   if(window.innerWidth > 768){
    //     window.open(origin + "/factory_info/basic_info/" + businessNo,"_blank")
    //   }else{
    //     history.push(`/factory_info/basic_info/${businessNo}`)ㄤ;
    //   }
      window.open(origin + "/factory_info/basic_info/" + businessNo,"_blank")
  };

  return (
      <div className="map-content-item" key={name}>
        <div className="map-content-item-header">
          <div><span>{name}:</span> {count}</div>
          <IconButton onClick={handleBoxOpen} style={{
            padding:"0px",
            color: "white"
          }}><Icon >{state.iconText}</Icon></IconButton>
        </div>
        <Collapse style={!state.isBoxOpen ? {paddingBottom:"0px"}:{}} in={state.isBoxOpen}>{companies.map(company=> 
          <Box display="flex" alignItems="center">
            {/* 先關閉手機自定義關注儀表板 */}
            {
            props.innerWidth > 1023 &&
              <CheckBoxContainer
                updateCustomDashboardData={
                  props.updateCustomDashboardData
                }
                handleUpdateCustomDashboardData={
                  props.handleUpdateCustomDashboardData
                }
                business_id={company.businessNo}
                is_company_selected={
                  props.updateCustomDashboardData.indexOf(
                    company.businessNo
                  ) !== -1
                }
              />}
            <ButtonLink title="另開新視窗，前往個廠情資頁面" className="map-content-item-list" onClick={()=>handleOpenNewTab(company.businessNo)}>{company.name}</ButtonLink>
        </Box>)}
        </Collapse>
      </div>
  );
};

const ButtonLink = styled.button`
  color: white;
  &:hover {
    color: #25758A;
  }
`;

const CheckBoxContainer = ({
  handleUpdateCustomDashboardData,
  updateCustomDashboardData,
  business_id,
  is_company_selected,
}) => {
  const handleClick = (e) => {
    e.stopPropagation();
    if (localStorage.getItem("isLogin") !== "true") {
      alert("請登入平台新增你的自訂關注廠商儀表板");
    } else {
      handleUpdateCustomDashboardData(business_id);
    }
  };

  if(localStorage.getItem("isLogin") !== "true"){
    return null
  }

  return (
    <Tooltip title="加入自訂儀表板清單">
      <Checkbox
        onClick={handleClick}
        checked={is_company_selected}
        size="small"
        sx={{
          color: "#ffffff",
          padding: "0px",
          transition: "translateY(-2px)",
          "&.Mui-checked": {
            color: "#25758A",
          },
        }}
      />
    </Tooltip>
  );
};