import React from "react";

import { useSelector } from "react-redux";
import { CircularProgress } from "@material-ui/core";
import IconButton from "@mui/material/IconButton";
import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";
import { useLocation } from "react-router-dom";
import Popover from "@mui/material/Popover";
import labor from "../../assests/industrialFactory03.png";
import QuestionContainerCard from "./QuestionContainerCard";
import Typography from "@mui/material/Typography";
import * as Style from "./styles";

export default (props) => {
  const {
    result,
    prevSlide,
    nextSlide,
    selected,
    stepThreeTitle,
    updateTypeList,
    UploadQuestionData,
  } = props;
  const { detailQuestions, getVisitQuestionDetail } = useSelector(
    (state) => state.question
  );
  const location = useLocation();

  const [state, setState] = React.useState({
    isFinished: false,
    anchorEl: null,
    openIndex: null,
  });

  const handleClick = (event, index) => {
    setState((state) => ({
      ...state,
      anchorEl: event.currentTarget,
      openIndex: index,
    }));
  };

  const handleClose = () => {
    setState((state) => ({
      ...state,
      anchorEl: false,
      openIndex: null,
    }));
  };

  const open = Boolean(state.anchorEl);
  const id = open ? "simple-popover" : undefined;

  //讀取編輯資料
  React.useEffect(async () => {
    if (getVisitQuestionDetail.Qid && location.state) {
      var temp = [];
      var tempSelected = [];
      var tempResult = [];
      var tempChild = [];
      var tempData = [];

      [getVisitQuestionDetail].map((item) => {
        temp = item.Step3Record?.split(",")?.filter((i) => i !== "");
      });

      if (temp) {
        tempSelected = temp?.map((i) => parseInt(i));

        stepThreeTitle.map((title) => {
          tempSelected.map((selected, index) => {
            if (title.TopicId === selected) {
              tempResult.push(title.VisitTypeName);
            }
          });
        });

        detailQuestions.map((item) => {
          tempResult.map((result, index) => {
            if (
              item.VisitTypeName === result &&
              tempChild.indexOf(item.TypeChildName) === -1
            ) {
              tempChild.push(item.TypeChildName);
              tempData.push(item);
            }
          });
        });
      }

      result[2].stepThreeSelected = tempSelected;
      result[2].stepThreeResult = tempResult;
      result[2].stepThreeData = tempData;
    }
  }, [getVisitQuestionDetail, stepThreeTitle]);

  //判斷此step是否填完資料
  React.useEffect(() => {
    if (selected?.length > 0) {
      setState((state) => ({
        ...state,
        isFinished: true,
      }));
    } else {
      setState((state) => ({
        ...state,
        isFinished: false,
      }));
    }
  }, [selected?.length]);

  return !stepThreeTitle ? (
    <CircularProgress />
  ) : (
    <QuestionContainerCard
      title={"拜訪類型"}
      src={labor}
      prevSlide={prevSlide}
      nextSlide={nextSlide}
      isIndexTwo={true}
      isFinished={state.isFinished}
      UploadQuestionData={UploadQuestionData}
    >
      <Style.BasicQuestionRightContainer>
        <Style.QuestionGridContainer>
          <Style.QuestionGridView isThreeCol={true}>
            {stepThreeTitle.map((item, index) => (
              <div>
                <IconButton
                  aria-describedby={id}
                  onClick={(e) => handleClick(e, index)}
                >
                  <ErrorRoundedIcon />
                </IconButton>
                <Style.QuestionGridItem
                  key={"stepThree" + index.toString()}
                  onClick={() =>
                    updateTypeList(item.VisitTypeName, item.TopicId)
                  }
                  isActive={
                    selected?.filter((i) => i === item.TopicId).length > 0
                  }
                  isThreeCol={true}
                  style={{ marginTop: "0px"}}
                >
                  <Style.QuestionGridItemText isThreeCol={true} fontSize={"1.4rem"}>
                    {item.VisitTypeName}
                  </Style.QuestionGridItemText>
                </Style.QuestionGridItem>
                <Popover
                  id={id}
                  open={index === state.openIndex ? true : false}
                  anchorEl={state.anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                >
                  {result[2].stepThreeDetail.map((detail, index) => {
                    if (item.VisitTypeName === detail.VisitTypeName) {
                      return (
                        <Typography sx={{ p: 2 }}>
                          {detail.TypeChildName}
                        </Typography>
                      );
                    }
                  })}
                </Popover>
              </div>
            ))}
          </Style.QuestionGridView>
        </Style.QuestionGridContainer>
      </Style.BasicQuestionRightContainer>
    </QuestionContainerCard>
  );
};
