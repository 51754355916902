import React from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { useSnackbar } from "notistack";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import LoadingButton from '@mui/lab/LoadingButton';
import firebase from "firebase/app";

import google_sign_in from "../../assests/images/btn_google_signin.png";
import Verification from "./Vertification";
import { handleGoogleSignIn } from "../../store/actions/auth";
import { handleGoogleSignInCheckRegistered } from "../../utility/authApi";
import { message } from "../../utility/common";
import * as theme from "../../assests/styles/theme";
import { signIn } from "../../store/actions/auth";

const SignIn = ({isHidden, isModalOpen, directToForgetPass, directToSignUp, status, loginHandler}) => {
    const history = useHistory();
    const { singInLoading } = useSelector(state => state.auth);
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const [state, setState] = React.useState({
        account: "",
        pass_string: "",
        isPasswordVisible: false,
        remember: true,
        isClicked: false,
    });

    const googleSignIn = () => {
        const provider = new firebase.auth.GoogleAuthProvider();
        firebase.auth()
        .signInWithPopup(provider)
        .then((result) => {
          const { email, displayName } = result.user;
          //test if registered
          handleGoogleSignInCheckRegistered(email)
            .then(res=>{
              if(res.description === "帳號(Email)已於平台註冊"){
                message(enqueueSnackbar, res.description, "error");
                firebase.auth().currentUser.delete();
                history.push("/")
              }else{
                dispatch(handleGoogleSignIn(email, displayName))
              }
            })
        }).catch((error) => {
          message(enqueueSnackbar, error.message, "error");
        });
      
      };


    const handleSignIn = () => {
        const { account, pass_string } = state;
        if(!account || !pass_string){
            setState(state=>({ ...state, isClicked: true }))
        }else{
            // setState(state=>({ ...state, loading: true }));
            dispatch(signIn(account, pass_string))
        }
    }

    const handleChange = (e) => {
        const { name, value, type } = e.target;

        if(type === "checkbox"){
            setState(state => ({ ...state, [name]: !state[name] }))
        }else{
            setState(state => ({ ...state, [name]: value }))
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            const { account, pass_string } = state;
            if(!account || !pass_string){
                setState(state=>({ ...state, isClicked: true }))
            }else{
                // setState(state=>({ ...state, loading: true }));
                dispatch(signIn(account, pass_string))
            }
        }
    };

    const handleVisiblePassword = () => {
        setState(state => ({ ...state, isPasswordVisible: !state.isPasswordVisible }))
    };

    const resetState = () => {
        setState(state=>({
            ...state,
            account: "",
            pass_string: "",
            isPasswordVisible: false,
            remember: true,
            isClicked: false,
        }))
    }

    const inputRef = React.useRef(null);
    React.useEffect(()=> {
        if(!isModalOpen) {
            if (status === "sign-in") {
                const signInEle = document.getElementById("signin");
                if (signInEle) {
                    signInEle.focus();
                }
            }
            resetState();
        }
        if (isModalOpen && inputRef.current) {
            inputRef.current.focus();
        }
    }, [isModalOpen])

    if(isHidden){
        return <div />
    }

    return (
        <SingInWrapper>
            <SingInTitleContainer>
            <SingInWrapperTitleHeadText>登入</SingInWrapperTitleHeadText>
                <SingInWrapperText>
                    沒有帳號，
                    <button
                        onClick={directToSignUp}
                    >
                        快速註冊
                    </button>
                </SingInWrapperText>
            </SingInTitleContainer>
            <SingInputContainer>
                <Inputs
                    label="E-mail(帳號)"
                    variant="outlined"
                    size="small"
                    placeholder="請輸入帳號。"
                    InputProps={{
                        startAdornment: (
                        <InputAdornment position="start">
                            <Icon>email</Icon>
                        </InputAdornment>
                        ),
                    }}
                    inputRef={inputRef}
                    name="account"
                    value={state.account}
                    onChange={handleChange}
                />
                <Verification isOpen={state.isClicked && !state.account} text={"請輸入帳號。"} />
                <Inputs
                    label="密碼"
                    variant="outlined"
                    size="small"
                    type={state.isPasswordVisible ? "text" : "password"}
                    placeholder="請輸入密碼。"
                    InputProps={{
                        startAdornment: (
                        <InputAdornment position="start">
                            <Icon>vpn_key</Icon>
                        </InputAdornment>
                        ),
                        endAdornment: (
                            <button title={`${state.isPasswordVisible? "關閉" : "開啟"}顯示密碼文字`}  onClick={handleVisiblePassword}>
                                <span class="material-icons" style={{
                                    verticalAlign: "middle" 
                                }} title={`${state.isPasswordVisible? "關閉" : "開啟"}顯示密碼文字`}>visibility</span>
                            </button>
                        )
                    }}
                    name="pass_string"
                    value={state.pass_string}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                />
                <Verification isOpen={state.isClicked && !state.account} text={"請輸入密碼。"} />
                <FormControlLabel
                    label="記住我"
                    for="remember"
                    control={
                        <Checkboxs
                            checked={state.remember}
                            onClick={handleChange}
                            name="remember"
                            id="remember"
                        />
                    }
                />
            </SingInputContainer>
            <ButtonContainer>
                <Buttons
                    loading={singInLoading}
                    active={!singInLoading && state.pass_string && state.account}
                    onClick={(state.pass_string && state.account) && handleSignIn}
                >
                登入
                </Buttons>
                <GoogleSignInButtonContainer 
                onClick={googleSignIn}
                >
                <GoogleSignInButton src={google_sign_in}  loading={"lazy"}/>
                <GoogleSignInText>Sign in with Google</GoogleSignInText>
                </GoogleSignInButtonContainer>
            </ButtonContainer>
            <DirectToForgetPassContainer>
                <DirectToForgetPassText><button onClick={directToForgetPass}>忘記密碼</button></DirectToForgetPassText>
            </DirectToForgetPassContainer>
            <HintBox mt={2}>
                <p>登入時遇到問題？請聯絡<a href="mailto:meer@mail.mirdc.org.tw" target="_blank" rel="noopener noreferrer">客服信箱</a></p>
            </HintBox>
        </SingInWrapper>
    )
};

const SingInWrapper = styled.div`
`
const SingInTitleContainer = styled.div`
    width : 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
`
const SingInWrapperTitleHeadText = styled.h2`
    color: ${theme.secondaryBlack};
    font-size: 2.25rem;
    font-family: ${theme.primaryFont} !important;
    font-weight: 600;
`
const SingInWrapperText = styled.p`
    color: ${theme.secondaryBlack};
    font-family: ${theme.primaryFont} !important;
    >button{
        color:var(--main-color);
        transition: 0.5s;
        &:hover{
            transition: 0.5s;
            cursor: pointer;
            color: #757575;
        }
    }
`

const SingInputContainer = styled.div`
    width : 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin: 10px 0px;
`

const Inputs = styled(TextField)`
    margin: 10px 0px !important;
    &:has(input:focus){ 
        background-color: #edf0f6;
    }
    .MuiFormLabel-root.Mui-focused{
        color: var(--main-color);
    }
    .MuiInputBase-root.Mui-focused{
        .MuiOutlinedInput-notchedOutline{
            border-color: var(--main-color);
        }
    }
`

const Checkboxs = styled(Checkbox)`
    .MuiIconButton-label{
        color:var(--main-color);
    }
`

const Buttons = styled.button`
    width: 100%;
    height: 40px !important;
    border-radius: 10px !important;
    background-color: ${p => p.active ? theme.linkBlue : theme.disabledGray} !important;
    font-size: 1rem !important;
    font-weight: 500 !important;
    color: ${theme.white} !important;
    transition: 0.5s;

    &:hover{
        cursor: ${p => p.active ? "pointer" : "not-allowed"};
        transition: 0.5s;
        background-color: ${p => p.active ? "pointer" : "not-allowed"};
    }
`

export const GoogleSignInButtonContainer = styled.button`
    cursor: pointer;
    width: 100%;
    height: 40px;
    margin-top: 10px;
    border: 1px solid #757575;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
`

export const GoogleSignInButton = styled.img`
    width: calc(51.2px * 0.5 );
    height: calc(51.2px * 0.5 );
`

export const GoogleSignInText = styled.p`
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 0.875rem;
    color: rgba(0, 0, 0, 0.54);
    font-weight: 600;
    padding-left: 10px;
`

const ButtonContainer = styled.div`

`

const DirectToForgetPassContainer = styled.div`
    text-align: center;
    padding: 3% 0px 1% 0px;
`

const DirectToForgetPassText = styled(SingInWrapperText)`
    > button {
        font-size: 1rem;
        transition: 0.5s;
        cursor: pointer;
        color: ${theme.secondaryBlack};

        &:hover{
            transition: 0.5s;
            color:#757575;
            cursor: pointer;
        }
    }
`

const ViewIcon = styled(Icon)`
    cursor: pointer;
`


const HintBox = styled(Box)`
  color: ${theme.secondaryBlack};
  font-size: 0.875rem;
  text-align: center;
  a {
    color: ${theme.primaryColor};
    text-decoration: underline;
    &:hover {
      opacity: 0.8;
    }
  }
`

export default SignIn