import React from 'react'
import { Card } from 'react-bootstrap'

import './FullPage.css';
import "./styles.scss"

const FullPage = props => {

    const clickHandler = () => {        
        props.buttonFunc()
    }


    return(
        <div className='full-page'>
            <img loading={"lazy"} alt={props.alt ? props.alt : ''} src={props.bgPhoto}/>
            <div className='input-container'>
                <Card className='input-box'>
                    <h1>{props.title}</h1>
                    {props.children}
                    {props.buttonContent ? 
                     <button onClick={clickHandler}>{props.buttonContent}</button>:
                     null}
                    <div className='input-box-note'>{props.note}</div>
                </Card>
            </div>

        </div>
    )
}


export default FullPage