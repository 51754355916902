import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { useSelector } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";

import { getControlledMaterialResult } from "./api";
import "./styles.scss";
import variables from "../../assests/styles/_variables.scss";
import * as theme from "../../assets/theme";

export default props => {
  const { toggleDrawer, controlledSearch, updateData, noHelperText, passDataHandler, openForceFilterGroup, KeyWords, updateKeyWord, keyWord, toggleDialogHandler } = props;
  const { innerWidth } = useSelector((state) => state.app);
  const history = useHistory();

  const [state, setState] = React.useState({
    isDrawerOpen: false,
    KeyWord: "",
    isHelperText: noHelperText ? null : "最多三間，可透過 ' , ' 隔開。",
    data:""
  });

  const fetchControlledMaterialDataHandler = async () => {
    setState(state=>({ ...state, loading: true, isClicked:true }));

    const { KeyWord } = state;
    const form = new FormData();
    form.append("KeyWord", KeyWord);
    form.append("controlledMaterialType", "");
    form.append("location", "");
    form.append("city", "");
    form.append("parkId", "");
    form.append("typeTwo", "");
    form.append("typeFour", "");

    const data = await getControlledMaterialResult(form);
    setState(state=>({ ...state, loading: false, data: data.data }));
};


  const toggleDrawerHandler = () => {
    const { isDrawerOpen } = state;

    setState(state => ({ ...state, isDrawerOpen: !isDrawerOpen }));

    toggleDrawer();
  }

  const handleUpdateField = (event) => {
    const { KeyWord } = state;
    const { name, value } = event.target;
    const keyCode = event.keyStroke;
    setState(state => ({
      ...state,
      [name]: value
    }))
    updateData(name, value);
    // updateKeyWord(value)
  };
  const controlledSearchHandler = () => {
    const { KeyWord } = state;
    if(KeyWord){
      fetchControlledMaterialDataHandler();
    }
    controlledSearch();
  }

  const handlePushToWastedClean = () => {
    history.push("/wasted_clean")
}
  

  React.useEffect(()=>{
    passDataHandler(state.data);
},[state.data]);

// React.useEffect(()=>{
//   if(!KeyWords){
//     setState(state => ({
//       ...state,
//       KeyWord: ""
//     }))
//   }
// },[KeyWords])

React.useEffect(()=>{
  setState(state=>({ ...state, KeyWord: keyWord }))
},[keyWord])


if(innerWidth < 750){
  return (
    <MobileInputSearchWrapper>
        <HeadContainer>
            <h2>廢棄物申報概況</h2>
            <p onClick={handlePushToWastedClean}>廢棄物清運機構</p>
        </HeadContainer>
        <p>透過條件搜尋各廠列管污染源相關資料。</p>      
          <TextFields
            {...props}
            variant="outlined"
            size="small"
            placeholder="請輸入廠商名稱"
            helperText={state.isHelperText}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                <Icon onClick={window.innerWidth < 1023 ? toggleDialogHandler : toggleDrawerHandler}>menu</Icon>
              </InputAdornment>
              ),
              endAdornment: noHelperText ? (
                <InputAdornment position="end">
                  {state.loading ? <CircularProgress className="loading"/> : <Icon onClick={controlledSearchHandler} style={state.KeyWord ? {color: variables["main-color"]} : {cursor: "not-allowed"}}>search</Icon>}
                </InputAdornment>
              ) : null
            }}
            value={state.KeyWord}
            onChange={(e) => handleUpdateField(e)}
            name="KeyWord"
            onFocus={()=>{}}
            // onMouseLeave={()=>openForceFilterGroup(false)}
            onKeyPress={(e) => { 
              if(e.key === "Enter" && state.KeyWord){
                fetchControlledMaterialDataHandler();
                controlledSearch();
              }
            }}
          />
    </MobileInputSearchWrapper>
  )
}


  return (
    <TextField
      {...props}
      className="controlled-material-auto-input"
      variant="outlined"
      size="small"
      placeholder="請輸入廠商名稱"
      helperText={state.isHelperText}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
          <Icon onClick={window.innerWidth < 1023 ? toggleDialogHandler : toggleDrawerHandler}>menu</Icon>
        </InputAdornment>
        ),
        endAdornment: noHelperText ? (
          <InputAdornment position="end">
            {state.loading ? <CircularProgress className="loading"/> : <Icon onClick={controlledSearchHandler} style={state.KeyWord ? {color: variables["main-color"]} : {cursor: "not-allowed"}}>search</Icon>}
          </InputAdornment>
        ) : null
      }}
      value={state.KeyWord}
      onChange={(e) => handleUpdateField(e)}
      name="KeyWord"
      onFocus={()=>{}}
      // onMouseLeave={()=>openForceFilterGroup(false)}
      onKeyPress={(e) => { 
        if(e.key === "Enter" && state.KeyWord){
          fetchControlledMaterialDataHandler();
          controlledSearch();
        }
      }}
    />
  )
};

const MobileInputSearchWrapper = styled.div`
    background-color: #fff;
    min-height: 10vh;
    z-index: 1000;
    position: relative;
    display: flex;
    flex-direction: column;
    top: 0px;
    >p{
      padding: 5px 15px;
    }
`;

const TextFields = styled(TextField)`
    width: 100% !important;
    top: 60px !important;
`

const HeadContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    > h2 {
        padding: 5px 15px;
        font-size: 1.5rem;
        font-weight: 600;
    }


    >p{
        margin-left: 2.5px;
        text-decoration: underline;
        color: ${theme.linkBlue};
        transition: 0.2s;
        cursor: pointer;

        &:hover{
            transition: 0.2s;
            color: ${theme.linkHoveredBlue}
        }
    }
`;