export const OrgCode = [{ "OrgId": "MIRDC_Member", "Org_Ch": "金屬中心會員" },
                        { "OrgId": "Electric_Cars", "Org_Ch": "電動車產業" },
                        { "OrgId": "CBAM", "Org_Ch": "CBAM" },
]


export const OrgCodeMap = {
    "MIRDC_Member": "金屬中心會員",
    "Electric_Cars": "電動車產業",
    "CBAM": "CBAM",
}