import * as actionTypes from "../actions/actionTypes";
import * as actionType from "../actionTypes/customizedDashboard";

const initState = {
    typeTwoChartData: [],
    typeTwoChartDataLabel: [],
    typeTwoChartDataMap: [],
    typeTwoChartDataLoading: false,
    typeThreeChartData: [],
    typeThreeChartDataLabel: [],
    typeThreeChartDataMap: [],
    typeThreeChartDataLoading: false,
    typeFourChartData: [],
    typeFourChartDataLabel: [],
    typeFourChartDataMap: [],
    typeFourChartDataLoading: false,
    publicStock: {
        publicStockData: [],
        publicStockDataLabels: [],
        publicStockDataMap: [],
        publicStockLoading: false,
    },
    notPublicStock: {
        notPublicStockData: [],
        notPublicStockDataLabels: [],
        notPublicStockDataMap: [],
        notPublicStockLoading: false,
    },
    stockRanking: {
        publicStockRankingData: [],
        notPublicStockRankingData: [],
        stockRankingLoading: false,
    },
    // notPublicStockRanking: {
    //   notPublicStockRankingData: [],
    //   notPublicStockRankingLoading: false,
    // },
    topTenCapital: {
        topTenCapitalData: [],
        topTenCapitalLabels: [],
        topTenCapitalDataTags: [],
        topTenCapitalDataMap: [],
        topTenCapitalLoading: false,
    },
    topTenGovPlan: {
        topTenGovPlanData: [],
        topTenGovPlanDataLabels: [],
        topTenGovPlanDataTags: [],
        topTenGovPlanDataMap: [],
        topTenGovPlanDataLoading: false,
    },
    topTenPatent: {
        topTenPatentData: [],
        topTenPatentDataLabels: [],
        topTenPatentDataTags: [],
        topTenPatentDataMap: [],
        topTenPatentDataLoading: false,
    },
    importPercentage: {
        importPercentageData: [],
        importPercentageDataLabels: [],
        importPercentageDataTags: [],
        importPercentageDataMap: [],
        importPercentageDataLoading: false,
    },
    exportPercentage: {
        exportPercentageData: [],
        exportPercentageDataLabels: [],
        exportPercentageDataTags: [],
        exportPercentageDataMap: [],
        exportPercentageDataLoading: false,
    },
    awardPercentage: {
        awardPercentageData: [],
        awardPercentageDataLabels: [],
        awardPercentageDataTags: [],
        awardPercentageDataMap: [],
        awardPercentageDataLoading: false,
    },
    govPlanPercentage: {
        govPlanPercentageData: [],
        govPlanPercentageDataLabels: [],
        govPlanPercentageDataTags: [],
        govPlanPercentageDataMap: [],
        govPlanPercentageDataLoading: false,
    },
    publicStockPercentage: {
        publicStockPercentageData: [],
        publicStockPercentageDataLabels: [],
        publicStockPercentageDataTags: [],
        publicStockPercentageDataMap: [],
        publicStockPercentageDataLoading: false,
    },
    newsInfo: {
        newsInfoData: [],
        newsInfoMaxRowNum: "",
        newsInfoMinRowNum: "",
        newsInfoPagingNum: "",
        newsInfoRowNum: "",
        newsInfoTotalLength: "",
        newsInfoDataLoading: false,
    },
    newsInfoTag: {
        newsInfoTagData: [],
        newsInfoTagDataLoading: false,
    },
    greenFactory: {
        greenFactoryData: [],
        greenFactoryLoading: false,
    },
    employeeInfo: {
        employeeInfoData: [],
        employeeInfoDataLabels: [],
        employeeInfoDataTags: [],
        employeeInfoDataMap: [],
        employeeInfoDataLoading: false,
    },
    typeTwoList: {
        typeTwoListData: [],
        typeTwoListDataLoading: false,
    },
    typeThreeList: {
        typeThreeListData: [],
        typeThreeListDataLoading: false,
    },
    typeFourList: {
        typeFourListData: [],
        typeFourListDataLoading: false,
    },
    importList: {
        importListData: [],
        importListDataLoading: false,
    },
    exportList: {
        exportListData: [],
        exportListDataLoading: false,
    },
    awardList: {
        awardListData: [],
        awardListDataLoading: false,
    },
    govPlanList: {
        govPlanListData: [],
        govPlanListDataLoading: false,
    },
    patentList: {
        patentListData: [],
        patentListDataLoading: false,
    },
    publicStockList: {
        publicStockListData: [],
        publicStockListDataLoading: false,
    },
    topTenGovPlan: {
        topTenGovPlanListData: [],
        topTenGovPlanListDataLoading: false,
    },
    taiwanInvestment: {
        taiwanInvestmentData: [],
        taiwanInvestmentLoading: false
    },
    factoryAreaRent: {
        factoryAreaRentData: [],
        factoryAreaRentLoading: false,
    },
    errorMessage: "",
};

export const startGetTypeTwoCharData = (state) => {
    return {
        ...state,
        typeTwoChartDataLoading: true,
    };
};

export const getTypeTwoCharDataSuccess = (state, data) => {
    const updateTypeTwoChartDataLabel = [];
    const updateTypeTwoChartData = [];
    data.map((type) => {
        updateTypeTwoChartData.push(type.y);
        updateTypeTwoChartDataLabel.push(type.label);
    });
    return {
        ...state,
        typeTwoChartData: updateTypeTwoChartData,
        typeTwoChartDataMap: data,
        typeTwoChartDataLabel: updateTypeTwoChartDataLabel,
        typeTwoChartDataLoading: false,
    };
};

export const getTypeTwoCharDataFail = (state, err) => {
    return {
        ...state,
        typeTwoChartDataLoading: false,
        errorMessage: err,
    };
};

export const startGetTypeThreeCharData = (state) => {
    return {
        ...state,
        typeThreeChartDataLoading: true,
    };
};

export const getTypeThreeCharDataSuccess = (state, data) => {
    const updateTypeThreeChartDataLabel = [];
    const updateTypeThreeChartData = [];
    data.map((type) => {
        updateTypeThreeChartData.push(type.y);
        updateTypeThreeChartDataLabel.push(type.label);
    });
    return {
        ...state,
        typeThreeChartData: updateTypeThreeChartData,
        typeThreeChartDataLabel: updateTypeThreeChartDataLabel,
        typeThreeChartDataMap: data,
        typeThreeChartDataLoading: false,
    };
};

export const getTypeThreeCharDataFail = (state, err) => {
    return {
        ...state,
        typeThreeChartDataLoading: false,
        errorMessage: err,
    };
};

export const startGetTypeFourCharData = (state) => {
    return {
        ...state,
        typeFourChartDataLoading: true,
    };
};

export const getTypeFourCharDataSuccess = (state, data) => {
    const updateTypeFourChartDataLabel = [];
    const updateTypeFourChartData = [];
    data.map((type) => {
        updateTypeFourChartData.push(type.y);
        updateTypeFourChartDataLabel.push(type.label);
    });
    return {
        ...state,
        typeFourChartData: updateTypeFourChartData,
        typeFourChartDataLabel: updateTypeFourChartDataLabel,
        typeFourChartDataMap: data,
        typeFourChartDataLoading: false,
    };
};

export const getTypeFourCharDataFail = (state, err) => {
    return {
        ...state,
        typeFourChartDataLoading: false,
        errorMessage: err,
    };
};

export const startGetPublicStockChartData = (state) => {
    return {
        ...state,
        publicStock: {
            ...state.publicStock,
            publicStockLoading: true,
        },
    };
};

export const getPublicStockChartDataSuccess = (state, data) => {
    const updateGetPublicStockDataLabel = [];
    const updateGetPublicStockData = [];
    data.map((type) => {
        updateGetPublicStockDataLabel.push(type.y);
        updateGetPublicStockData.push(type.label);
    });
    return {
        ...state,
        publicStock: {
            ...state.publicStock,
            publicStockData: updateGetPublicStockData,
            publicStockDataLabels: updateGetPublicStockDataLabel,
            publicStockDataMap: data,
            publicStockLoading: false,
        },
    };
};

export const getPublicStockChartDataFail = (state, err) => {
    return {
        ...state,
        publicStock: {
            ...state.publicStock,
            publicStockLoading: false,
        },
        errorMessage: err,
    };
};

export const startGetNotPublicStockChartData = (state) => {
    return {
        ...state,
        notPublicStock: {
            ...state.notPublicStock,
            notPublicStockLoading: true,
        },
    };
};

export const getNotPublicStockChartDataSuccess = (state, data) => {
    const updateGetNotPublicStockDataLabel = [];
    const updateGetNotPublicStockData = [];
    data.map((type) => {
        updateGetNotPublicStockData.push(type.y);
        updateGetNotPublicStockDataLabel.push(type.label);
    });
    return {
        ...state,
        notPublicStock: {
            ...state.notPublicStock,
            notPublicStockData: updateGetNotPublicStockData,
            notPublicStockDataLabels: updateGetNotPublicStockDataLabel,
            notPublicStockDataMap: data,
            notPublicStockLoading: false,
        },
    };
};

export const getNotPublicStockChartDataFail = (state, err) => {
    return {
        ...state,
        notPublicStock: {
            ...state.notPublicStock,
            notPublicStockLoading: true,
        },
        errorMessage: err,
    };
};

export const startGetStockRankingData = (state) => {
    return {
        ...state,
        stockRanking: {
            ...state.stockRanking,
            stockRankingLoading: true,
        },
    };
};

export const getStockRankingDataSuccess = (state, data) => {
    const updatePublicStockRankingData = [];
    const updateNotPublicStockRankingData = [];

    data.map((company) => {
        if (company.StockType === "未上市") {
            updateNotPublicStockRankingData.push(company);
        } else {
            updatePublicStockRankingData.push(company);
        }
    });

    return {
        ...state,
        stockRanking: {
            ...state.stockRanking,
            publicStockRankingData: updatePublicStockRankingData,
            notPublicStockRankingData: updateNotPublicStockRankingData,
            stockRankingLoading: false,
        },
    };
};

export const getStockRankingDataFail = (state, err) => {
    return {
        ...state,
        stockRanking: {
            ...state.stockRanking,
            stockRankingLoading: false,
        },
        errorMessage: err,
    };
};

export const startGetNotPublicStockRankingData = (state) => {
    return {
        ...state,
        notPublicStockRanking: {
            ...state.notPublicStockRanking,
            notPublicStockRankingLoading: true,
        },
    };
};

export const getNotPublicStockRankingDataSuccess = (state, data) => {
    return {
        ...state,
        notPublicStockRanking: {
            ...state.notPublicStock,
            notPublicStockRankingData: data,
            notPublicStockRankingLoading: false,
        },
    };
};

export const getNotPublicStockRankingDataFail = (state, err) => {
    return {
        ...state,
        notPublicStockRanking: {
            ...state.notPublicStockRanking,
            notPublicStockRankingLoading: false,
        },
        errorMessage: err,
    };
};

export const startGetTopTenCapitalData = (state) => {
    return {
        ...state,
        topTenCapital: {
            ...state.topTenCapital,
            topTenCapitalLoading: true,
        },
    };
};

export const getTopTenCapitalDataSuccess = (state, data) => {
    const updateTopTenCapitalDataLabels = [];
    const updateTopTenCapitalData = [];
    const updateTopTenCapitalDataTags = [];

    data.map((type) => {
        updateTopTenCapitalData.push(type.y);
        updateTopTenCapitalDataLabels.push(type.label);
        updateTopTenCapitalDataTags.push(type.tag);
    });
    return {
        ...state,
        topTenCapital: {
            ...state.topTenCapital,
            topTenCapitalData: updateTopTenCapitalData,
            topTenCapitalLabels: updateTopTenCapitalDataLabels,
            topTenCapitalDataTags: updateTopTenCapitalDataTags,
            topTenCapitalDataMap: data,
            topTenCapitalLoading: false,
        },
    };
};

export const getTopTenCapitalDataFail = (state, err) => {
    return {
        ...state,
        topTenCapital: {
            ...state.topTenCapital,
            topTenCapitalLoading: false,
        },
        errorMessage: err,
    };
};

export const startGetTopTenGovPlanData = (state) => {
    return {
        ...state,
        topTenGovPlan: {
            ...state.topTenGovPlan,
            topTenGovPlanDataLoading: true,
        },
    };
};

export const getTopTenGovPlanDataSuccess = (state, data) => {
    const updateTopTenGovPlanDataLabels = [];
    const updateTopTenGovPlanData = [];
    const updateTopTenGovPlanDataTags = [];

    data.map((type) => {
        updateTopTenGovPlanData.push(type.y);
        updateTopTenGovPlanDataLabels.push(type.label);
        updateTopTenGovPlanDataTags.push(type.tag);
    });
    return {
        ...state,
        topTenGovPlan: {
            ...state.topTenGovPlan,
            topTenGovPlanData: updateTopTenGovPlanData,
            topTenGovPlanDataLabels: updateTopTenGovPlanDataLabels,
            topTenGovPlanDataTags: updateTopTenGovPlanDataTags,
            topTenGovPlanDataMap: data,
            topTenGovPlanDataLoading: false,
        },
    };
};

export const getTopTenGovPlanDataFail = (state, err) => {
    return {
        ...state,
        topTenGovPlan: {
            ...state.topTenGovPlan,
            topTenGovPlanDataLoading: true,
        },
        errorMessage: err,
    };
};

export const startGeTopTenPatentData = (state) => {
    return {
        ...state,
        topTenPatent: {
            ...state.topTenPatent,
            topTenPatentDataLoading: true,
        },
    };
};

export const geTopTenPatentDataSuccess = (state, data) => {
    const updateTopTenPatentDataLabels = [];
    const updateTopTenPatentData = [];
    const updateTopTenPatentDataTags = [];

    data.map((type) => {
        updateTopTenPatentData.push(type.y);
        updateTopTenPatentDataLabels.push(type.label);
        updateTopTenPatentDataTags.push(type.tag);
    });
    return {
        ...state,
        topTenPatent: {
            ...state.topTenPatent,
            topTenPatentData: updateTopTenPatentData,
            topTenPatentDataLabels: updateTopTenPatentDataLabels,
            topTenPatentDataTags: updateTopTenPatentDataTags,
            topTenPatentDataMap: data,
            topTenPatentDataLoading: false,
        },
    };
};

export const geTopTenPatentDataFail = (state, err) => {
    return {
        ...state,
        topTenPatent: {
            ...state.topTenPatent,
            topTenPatentDataLoading: true,
        },
        errorMessage: err,
    };
};

export const startGeImportPercentageData = (state) => {
    return {
        ...state,
        importPercentage: {
            ...state.importPercentage,
            importPercentageDataLoading: true,
        },
    };
};

export const getImportPercentageDataSuccess = (state, data) => {
    const updateImportPercentageDataLabels = [];
    const updateImportPercentageData = [];
    const updateImportPercentageDataTags = [];

    data.map((type) => {
        updateImportPercentageData.push(type.y);
        updateImportPercentageDataLabels.push(type.label);
        updateImportPercentageDataTags.push(type.tag);
    });
    return {
        ...state,
        importPercentage: {
            ...state.importPercentage,
            importPercentageData: updateImportPercentageData,
            importPercentageDataLabels: updateImportPercentageDataLabels,
            importPercentageDataTags: updateImportPercentageDataTags,
            importPercentageDataMap: data,
            importPercentageDataLoading: false,
        },
    };
};

export const getImportPercentageDataFail = (state, err) => {
    return {
        ...state,
        importPercentage: {
            ...state.importPercentage,
            importPercentageDataLoading: true,
        },
        errorMessage: err,
    };
};

export const startGetExportPercentageData = (state) => {
    return {
        ...state,
        exportPercentage: {
            ...state.exportPercentage,
            exportPercentageDataLoading: true,
        },
    };
};

export const getExportPercentageDataSuccess = (state, data) => {
    const updateExportPercentageDataLabels = [];
    const updateExportPercentageData = [];
    const updateExportPercentageDataTags = [];

    data.map((type) => {
        updateExportPercentageData.push(type.y);
        updateExportPercentageDataLabels.push(type.label);
        updateExportPercentageDataTags.push(type.tag);
    });
    return {
        ...state,
        exportPercentage: {
            ...state.exportPercentage,
            exportPercentageData: updateExportPercentageData,
            exportPercentageDataLabels: updateExportPercentageDataLabels,
            exportPercentageDataTags: updateExportPercentageDataTags,
            exportPercentageDataMap: data,
            exportPercentageDataLoading: false,
        },
    };
};

export const getExportPercentageDataFail = (state, err) => {
    return {
        ...state,
        exportPercentage: {
            ...state.exportPercentage,
            exportPercentageDataLoading: true,
        },
        errorMessage: err,
    };
};

export const startAwardPercentageData = (state) => {
    return {
        ...state,
        awardPercentage: {
            ...state.awardPercentage,
            awardPercentageDataLoading: true,
        },
    };
};

export const getAwardPercentageDataSuccess = (state, data) => {
    const updateAwardPercentageDataLabels = [];
    const updateAwardPercentageData = [];
    const updateAwardPercentageDataTags = [];

    data.map((type) => {
        updateAwardPercentageData.push(type.y);
        updateAwardPercentageDataLabels.push(type.label);
        updateAwardPercentageDataTags.push(type.tag);
    });
    return {
        ...state,
        awardPercentage: {
            ...state.awardPercentage,
            awardPercentageData: updateAwardPercentageData,
            awardPercentageDataLabels: updateAwardPercentageDataLabels,
            awardPercentageDataTags: updateAwardPercentageDataTags,
            awardPercentageDataMap: data,
            awardPercentageDataLoading: false,
        },
    };
};

export const getAwardPercentageDataFail = (state, err) => {
    return {
        ...state,
        awardPercentage: {
            ...state.awardPercentage,
            awardPercentageDataLoading: true,
        },
        errorMessage: err,
    };
};

export const startGovPlanPercentageData = (state) => {
    return {
        ...state,
        govPlanPercentage: {
            ...state.govPlanPercentage,
            govPlanPercentageDataLoading: true,
        },
    };
};

export const getGovPlanPercentageDataSuccess = (state, data) => {
    const updateGovPlanPercentageDataLabels = [];
    const updateGovPlanPercentageData = [];
    const updateGovPlanPercentageDataTags = [];

    data.map((type) => {
        updateGovPlanPercentageData.push(type.y);
        updateGovPlanPercentageDataLabels.push(type.label);
        updateGovPlanPercentageDataTags.push(type.tag);
    });
    return {
        ...state,
        govPlanPercentage: {
            ...state.govPlanPercentage,
            govPlanPercentageData: updateGovPlanPercentageData,
            govPlanPercentageDataLabels: updateGovPlanPercentageDataLabels,
            govPlanPercentageDataTags: updateGovPlanPercentageDataTags,
            govPlanPercentageDataMap: data,
            govPlanPercentageDataLoading: false,
        },
    };
};

export const getGovPlanPercentageDataFail = (state, err) => {
    return {
        ...state,
        govPlanPercentage: {
            ...state.govPlanPercentage,
            govPlanPercentageDataLoading: true,
        },
        errorMessage: err,
    };
};

export const startPublicStockPercentageData = (state) => {
    return {
        ...state,
        publicStockPercentage: {
            ...state.publicStockPercentage,
            publicStockPercentageDataLoading: true,
        },
    };
};

export const getPublicStockPercentageDataSuccess = (state, data) => {
    const updatePublicStockPercentageDataLabels = [];
    const updatePublicStockPercentageData = [];
    const updatePublicStockPercentageDataTags = [];

    data.map((type) => {
        updatePublicStockPercentageData.push(type.y);
        updatePublicStockPercentageDataLabels.push(type.label);
        updatePublicStockPercentageDataTags.push(type.tag);
    });
    return {
        ...state,
        publicStockPercentage: {
            ...state.publicStockPercentage,
            publicStockPercentageData: updatePublicStockPercentageData,
            publicStockPercentageDataLabels: updatePublicStockPercentageDataLabels,
            publicStockPercentageDataTags: updatePublicStockPercentageDataTags,
            publicStockPercentageDataMap: data,
            publicStockPercentageDataLoading: false,
        },
    };
};

export const getPublicStockPercentageDataFail = (state, err) => {
    return {
        ...state,
        publicStockPercentage: {
            ...state.publicStockPercentage,
            publicStockPercentageDataLoading: true,
        },
        errorMessage: err,
    };
};

export const startGetNewsInfoData = (state) => {
    return {
        ...state,
        newsInfo: {
            ...state.newsInfo,
            newsInfoDataLoading: true,
        },
    };
};

export const getNewsInfoDataSuccess = (state, data) => {
    return {
        ...state,
        newsInfo: {
            ...state.newsInfo,
            newsInfoData: data.Datas,
            newsInfoMaxRowNum: data.MaxRowNum,
            newsInfoMinRowNum: data.MinRowNum,
            newsInfoPagingNum: data.PagingNum,
            newsInfoRowNum: data.RowNum,
            newsInfoTotalLength: data.TotalLength,
            newsInfoDataLoading: false,
        },
    };
};

export const getNewsInfoDataFail = (state, err) => {
    return {
        ...state,
        newsInfo: {
            ...state.newsInfo,
            newsInfoDataLoading: true,
        },
        errorMessage: err,
    };
};

export const startGetEmployeeInfoData = (state) => {
    return {
        ...state,
        employeeInfo: {
            ...state.employeeInfo,
            employeeInfoDataLoading: true,
        },
    };
};

export const getEmployeeInfoDataSuccess = (state, data) => {
    const updateEmployeeInfoDataLabels = [];
    const updateEmployeeInfoData = [];
    const updateEmployeeInfoDataTags = [];

    data.map((type) => {
        updateEmployeeInfoData.push(type.y);
        updateEmployeeInfoDataLabels.push(type.label);
        updateEmployeeInfoDataTags.push(type.tag);
    });
    return {
        ...state,
        employeeInfo: {
            ...state.employeeInfo,
            employeeInfoData: updateEmployeeInfoData,
            employeeInfoDataLabels: updateEmployeeInfoDataLabels,
            employeeInfoDataTags: updateEmployeeInfoDataTags,
            employeeInfoDataMap: data,
            employeeInfoDataLoading: false,
        },
    };
};

export const getEmployeeInfoDataFail = (state, err) => {
    return {
        ...state,
        employeeInfo: {
            ...state.employeeInfo,
            employeeInfoDataLoading: true,
        },
        errorMessage: err,
    };
};

export const startGetNewsInfoTagData = (state) => {
    return {
        ...state,
        newsInfoTag: {
            ...state.newsInfoTag,
            newsInfoTagDataLoading: true,
        },
    };
};

export const getNewsInfoTagDataSuccess = (state, data) => {
    const updateNewsInfoDataTag = [];
    data.map((tagItem) => {
        updateNewsInfoDataTag.push({
            value: tagItem.Keywords,
            count: tagItem.TermCount,
        });
    });

    return {
        ...state,
        newsInfoTag: {
            ...state.newsInfoTag,
            newsInfoTagData: updateNewsInfoDataTag,
            newsInfoTagDataLoading: false,
        },
    };
};

export const getNewsInfoTagDataFail = (state, err) => {
    return {
        ...state,
        newsInfoTag: {
            ...state.newsInfoTag,
            newsInfoTagDataLoading: true,
        },
        errorMessage: err,
    };
};

export const startGetGreenFactoryData = (state) => {
    return {
        ...state,
        greenFactory: {
            ...state.greenFactory,
            greenFactoryLoading: true,
        },
    };
};

export const getGreenFactoryDataSuccess = (state, data) => {
    return {
        ...state,
        greenFactory: {
            ...state.greenFactory,
            greenFactoryData: data,
            greenFactoryLoading: false,
        },
    };
};

export const getGreenFactoryDataFail = (state, err) => {
    return {
        ...state,
        greenFactory: {
            ...state.greenFactory,
            greenFactoryLoading: true,
        },
        errorMessage: err,
    };
};

export const startGetTypeTwoListData = (state) => {
    return {
        ...state,
        typeTwoList: {
            ...state.typeTwoList,
            typeTwoListDataLoading: true,
        },
    };
};

export const getTypeTwoListDataSuccess = (state, data) => {
    return {
        ...state,
        typeTwoList: {
            ...state.typeTwoList,
            typeTwoListData: data,
            typeTwoListDataLoading: false,
        },
    };
};

export const getTypeTwoListDataFail = (state, err) => {
    return {
        ...state,
        typeTwoList: {
            ...state.typeTwoList,
            typeTwoListDataLoading: false,
        },
        errorMessage: err,
    };
};

export const startGetTypeThreeListData = (state) => {
    return {
        ...state,
        typeThreeList: {
            ...state.typeThreeList,
            typeThreeListDataLoading: true,
        },
    };
};

export const getTypeThreeListDataSuccess = (state, data) => {
    return {
        ...state,
        typeThreeList: {
            ...state.typeThreeList,
            typeThreeListData: data,
            typeThreeListDataLoading: false,
        },
    };
};

export const getTypeThreeListDataFail = (state, err) => {
    return {
        ...state,
        typeThreeListDataLoading: false,
        errorMessage: err,
    };
};

export const startGetTypeFourListData = (state) => {
    return {
        ...state,
        typeFourList: {
            ...state.typeFourList,
            typeFourListDataLoading: true,
        },
    };
};

export const getTypeFourListDataSuccess = (state, data) => {
    return {
        ...state,
        typeFourList: {
            ...state.typeFourList,
            typeFourListData: data,
            typeFourListDataLoading: false,
        },
    };
};

export const getTypeFourListDataFail = (state, err) => {
    return {
        ...state,
        typeFourList: {
            ...state.typeFourList,
            typeFourListDataLoading: false,
        },
        errorMessage: err,
    };
};

export const startImportListData = (state) => {
    return {
        ...state,
        importList: {
            ...state.importList,
            importListDataLoading: true,
        },
    };
};

export const getImportListDataSuccess = (state, data) => {
    return {
        ...state,
        importList: {
            ...state.importList,
            importListData: data,
            importListDataLoading: false,
        },
    };
};

export const getImportListDataFail = (state, err) => {
    return {
        ...state,
        importList: {
            ...state.importList,
            importListDataLoading: false,
        },
        errorMessage: err,
    };
};

export const startExportListData = (state) => {
    return {
        ...state,
        exportList: {
            ...state.exportList,
            exportListDataLoading: true,
        },
    };
};

export const getExportListDataSuccess = (state, data) => {
    return {
        ...state,
        exportList: {
            ...state.exportList,
            exportListData: data,
            exportListDataLoading: false,
        },
    };
};

export const getExportListDataFail = (state, err) => {
    return {
        ...state,
        exportList: {
            ...state.exportList,
            exportListDataLoading: false,
        },
        errorMessage: err,
    };
};

export const startAwardListData = (state) => {
    return {
        ...state,
        awardList: {
            ...state.awardList,
            awardListDataLoading: true,
        },
    };
};

export const getAwardListDataSuccess = (state, data) => {
    return {
        ...state,
        awardList: {
            ...state.awardList,
            awardListData: data,
            awardListDataLoading: false,
        },
    };
};

export const getAwardListDataFail = (state, err) => {
    return {
        ...state,
        awardList: {
            ...state.awardList,
            awardListDataLoading: false,
        },
        errorMessage: err,
    };
};

export const startGovPlanListData = (state) => {
    return {
        ...state,
        govPlanList: {
            ...state.govPlanList,
            govPlanListDataLoading: true,
        },
    };
};

export const getGovPlanListDataSuccess = (state, data) => {
    return {
        ...state,
        govPlanList: {
            ...state.govPlanList,
            govPlanListData: data,
            govPlanListDataLoading: false,
        },
    };
};

export const getGovPlanListDataFail = (state, err) => {
    return {
        ...state,
        govPlanList: {
            ...state.govPlanList,
            govPlanListDataLoading: false,
        },
        errorMessage: err,
    };
};

export const startPatentListData = (state) => {
    return {
        ...state,
        patentList: {
            ...state.patentList,
            patentListDataLoading: true,
        },
    };
};

export const getPatentListDataSuccess = (state, data) => {
    return {
        ...state,
        patentList: {
            ...state.patentList,
            patentListData: data,
            patentListDataLoading: false,
        },
    };
};

export const getPatentListDataFail = (state, err) => {
    return {
        ...state,
        patentList: {
            ...state.patentList,
            patentListDataLoading: false,
        },
        errorMessage: err,
    };
};

export const startPublicStockListData = (state) => {
    return {
        ...state,
        publicStockList: {
            ...state.publicStockList,
            publicStockListDataLoading: true,
        },
    };
};

export const getPublicStockListDataSuccess = (state, data) => {
    return {
        ...state,
        publicStockList: {
            ...state.publicStockList,
            publicStockListData: data,
            publicStockListDataLoading: false,
        },
    };
};

export const getPublicStockListDataFail = (state, err) => {
    return {
        ...state,
        publicStockList: {
            ...state.publicStockList,
            publicStockListDataLoading: false,
        },
        errorMessage: err,
    };
};

export const startTopTenGovPlanListData = (state) => {
    return {
        ...state,
        topTenGovPlan: {
            ...state.topTenGovPlan,
            topTenGovPlanListDataLoading: true,
        },
    };
};

export const getTopTenGovPlanListDataSuccess = (state, data) => {
    return {
        ...state,
        topTenGovPlan: {
            ...state.topTenGovPlan,
            topTenGovPlanListData: data,
            topTenGovPlanListDataLoading: false,
        },
    };
};

export const getTopTenGovPlanListDataFail = (state, err) => {
    return {
        ...state,
        topTenGovPlan: {
            ...state.topTenGovPlan,
            topTenGovPlanListDataLoading: false,
        },
        errorMessage: err,
    };
};

export const starGetTaiwanInvestmentData = (state) => {
    return {
        ...state,
        taiwanInvestment: {
            ...state.taiwanInvestment,
            taiwanInvestmentLoading: true
        },
    };
};

export const getTaiwanInvestmentDataSuccess = (state, data) => {
    return {
        ...state,
        taiwanInvestment: {
            ...state.taiwanInvestment,
            taiwanInvestmentData: data,
            taiwanInvestmentLoading: false
        },
    };
};

export const getTaiwanInvestmentDataFail = (state, err) => {
    return {
        ...state,
        taiwanInvestment: {
            ...state.taiwanInvestment,
            taiwanInvestmentLoading: false
        },
        errorMessage: err,
    };
};

export const startFactoryAreaRentData = (state) => {
    return {
        ...state,
        factoryAreaRent: {
            ...state.factoryAreaRent,
            factoryAreaRentLoading: true
        },
    };
};

export const getFactoryAreaRentDataSuccess = (state, data) => {
    return {
        ...state,
        factoryAreaRent: {
            ...state.factoryAreaRent,
            factoryAreaRentData: data,
            factoryAreaRentLoading: false
        },
    };
};

export const getFactoryAreaRentDataFail = (state, err) => {
    return {
        ...state,
        factoryAreaRent: {
            ...state.factoryAreaRent,
            factoryAreaRentLoading: false
        },
        errorMessage: err,
    };
};

export default (state = initState, action) => {
    switch (action.type) {
        case actionTypes.START_GET_TYPE_TWO_CHART_DATA:
            return startGetTypeTwoCharData(state);
        case actionTypes.GET_TYPE_TWO_CHART_DATA_SUCCESS:
            return getTypeTwoCharDataSuccess(state, action.data);
        case actionTypes.GET_TYPE_TWO_CHART_DATA_FAIL:
            return getTypeTwoCharDataFail(state, action.err);
        case actionTypes.START_GET_TYPE_THREE_CHART_DATA:
            return startGetTypeThreeCharData(state);
        case actionTypes.GET_TYPE_THREE_CHART_DATA_SUCCESS:
            return getTypeThreeCharDataSuccess(state, action.data);
        case actionTypes.GET_TYPE_THREE_CHART_DATA_FAIL:
            return getTypeThreeCharDataFail(state, action.err);
        case actionTypes.START_GET_TYPE_FOUR_CHART_DATA:
            return startGetTypeFourCharData(state);
        case actionTypes.GET_TYPE_FOUR_CHART_DATA_SUCCESS:
            return getTypeFourCharDataSuccess(state, action.data);
        case actionTypes.GET_TYPE_FOUR_CHART_DATA_FAIL:
            return getTypeFourCharDataFail(state, action.err);
        case actionTypes.START_GET_PUBLIC_STOCK_CHART_DATA:
            return startGetPublicStockChartData(state);
        case actionTypes.GET_PUBLIC_STOCK_CHART_DATA_SUCCESS:
            return getPublicStockChartDataSuccess(state, action.data);
        case actionTypes.GET_PUBLIC_STOCK_CHART_DATA_FAIL:
            return getPublicStockChartDataFail(state, action.err);
        case actionTypes.START_GET_NOT_PUBLIC_STOCK_CHART_DATA:
            return startGetNotPublicStockChartData(state);
        case actionTypes.GET_NOT_PUBLIC_STOCK_CHART_DATA_SUCCESS:
            return getNotPublicStockChartDataSuccess(state, action.data);
        case actionTypes.GET_NOT_PUBLIC_STOCK_CHART_DATA_FAIL:
            return getNotPublicStockChartDataFail(state, action.err);
        case actionTypes.START_GET_STOCK_RANKING_DATA:
            return startGetStockRankingData(state);
        case actionTypes.GET_STOCK_RANKING_DATA_SUCCESS:
            return getStockRankingDataSuccess(state, action.data);
        case actionTypes.GET_STOCK_RANKING_DATA_FAIL:
            return getStockRankingDataFail(state, action.err);
        // case actionTypes.START_GET_NOT_PUBLIC_STOCK_RANKING_DATA:
        //   return startGetNotPublicStockRankingData(state);
        // case actionTypes.GET_NOT_PUBLIC_STOCK_RANKING_DATA_SUCCESS:
        //   return getNotPublicStockRankingDataSuccess(state, action.data);
        // case actionTypes.GET_NOT_PUBLIC_STOCK_RANKING_DATA_FAIL:
        //   return getNotPublicStockRankingDataFail(state, action.err);
        case actionTypes.START_GET_TOP_TEN_CAPITAL_DATA:
            return startGetTopTenCapitalData(state);
        case actionTypes.GET_TOP_TEN_CAPITAL_DATA_SUCCESS:
            return getTopTenCapitalDataSuccess(state, action.data);
        case actionTypes.GET_TOP_TEN_CAPITAL_DATA_FAIL:
            return getTopTenCapitalDataFail(state, action.err);
        case actionTypes.START_GET_TOP_TEN_GOVPLAN_DATA:
            return startGetTopTenGovPlanData(state);
        case actionTypes.GET_TOP_TEN_GOVPLAN_DATA_SUCCESS:
            return getTopTenGovPlanDataSuccess(state, action.data);
        case actionTypes.GET_TOP_TEN_GOVPLAN_DATA_FAIL:
            return getTopTenGovPlanDataFail(state, action.err);
        case actionTypes.START_GET_TOP_TEN_PATENT_DATA:
            return startGeTopTenPatentData(state);
        case actionTypes.GET_TOP_TEN_PATENT_DATA_SUCCESS:
            return geTopTenPatentDataSuccess(state, action.data);
        case actionTypes.GET_TOP_TEN_PATENT_DATA_FAIL:
            return geTopTenPatentDataFail(state, action.err);
        case actionTypes.START_GET_IMPORT_PERCENTAGE_DATA:
            return startGeImportPercentageData(state);
        case actionTypes.GET_IMPORT_PERCENTAGE_DATA_SUCCESS:
            return getImportPercentageDataSuccess(state, action.data);
        case actionTypes.GET_IMPORT_PERCENTAGE_DATA_FAIL:
            return getImportPercentageDataFail(state, action.err);
        case actionTypes.START_GET_EXPORT_PERCENTAGE_DATA:
            return startGetExportPercentageData(state);
        case actionTypes.GET_EXPORT_PERCENTAGE_DATA_SUCCESS:
            return getExportPercentageDataSuccess(state, action.data);
        case actionTypes.GET_EXPORT_PERCENTAGE_DATA_FAIL:
            return getExportPercentageDataFail(state, action.err);
        case actionTypes.START_GET_AWARD_PERCENTAGE_DATA:
            return startAwardPercentageData(state);
        case actionTypes.GET_AWARD_PERCENTAGE_DATA_SUCCESS:
            return getAwardPercentageDataSuccess(state, action.data);
        case actionTypes.GET_AWARD_PERCENTAGE_DATA_FAIL:
            return getAwardPercentageDataFail(state, action.err);
        case actionTypes.START_GET_GOV_PLAN_PERCENTAGE_DATA:
            return startGovPlanPercentageData(state);
        case actionTypes.GET_GOV_PLAN_PERCENTAGE_DATA_SUCCESS:
            return getGovPlanPercentageDataSuccess(state, action.data);
        case actionTypes.GET_GOV_PLAN_PERCENTAGE_DATA_FAIL:
            return getGovPlanPercentageDataFail(state, action.err);
        case actionTypes.START_GET_PUBLIC_STOCK_PERCENTAGE_DATA:
            return startPublicStockPercentageData(state);
        case actionTypes.GET_PUBLIC_STOCK_PERCENTAGE_DATA_SUCCESS:
            return getPublicStockPercentageDataSuccess(state, action.data);
        case actionTypes.GET_PUBLIC_STOCK_PERCENTAGE_DATA_FAIL:
            return getPublicStockPercentageDataFail(state, action.err);
        case actionTypes.START_GET_NEWS_INFO_DATA:
            return startGetNewsInfoData(state);
        case actionTypes.GET_NEWS_INFO_DATA_SUCCESS:
            return getNewsInfoDataSuccess(state, action.data);
        case actionTypes.GET_NEWS_INFO_DATA_FAIL:
            return getNewsInfoDataFail(state, action.err);
        case actionTypes.START_GET_EMPLOYEE_DATA:
            return startGetEmployeeInfoData(state);
        case actionTypes.GET_EMPLOYEE_DATA_SUCCESS:
            return getEmployeeInfoDataSuccess(state, action.data);
        case actionTypes.GET_EMPLOYEE_DATA_FAIL:
            return getEmployeeInfoDataFail(state, action.err);
        case actionTypes.START_GET_NEWS_INFO_TAG_DATA:
            return startGetNewsInfoTagData(state);
        case actionTypes.GET_NEWS_INFO_TAG_DATA_SUCCESS:
            return getNewsInfoTagDataSuccess(state, action.data);
        case actionTypes.GET_NEWS_INFO_TAG_DATA_FAIL:
            return getNewsInfoTagDataFail(state, action.err);
        case actionTypes.START_GET_TYPE_TWO_LIST_DATA:
            return startGetTypeTwoListData(state);
        case actionTypes.GET_TYPE_TWO_LIST_DATA_SUCCESS:
            return getTypeTwoListDataSuccess(state, action.data);
        case actionTypes.GET_TYPE_TWO_LIST_DATA_FAIL:
            return getTypeTwoListDataFail(state, action.err);
        case actionTypes.START_GET_TYPE_THREE_LIST_DATA:
            return startGetTypeThreeListData(state);
        case actionTypes.GET_TYPE_THREE_LIST_DATA_SUCCESS:
            return getTypeThreeListDataSuccess(state, action.data);
        case actionTypes.GET_TYPE_THREE_LIST_DATA_FAIL:
            return getTypeThreeListDataFail(state, action.err);
        case actionTypes.START_GET_TYPE_FOUR_LIST_DATA:
            return startGetTypeFourListData(state);
        case actionTypes.GET_TYPE_FOUR_LIST_DATA_SUCCESS:
            return getTypeFourListDataSuccess(state, action.data);
        case actionTypes.GET_TYPE_FOUR_LIST_DATA_FAIL:
            return getTypeFourListDataFail(state, action.err);
        case actionTypes.START_GET_IMPORT_LIST_DATA:
            return startImportListData(state);
        case actionTypes.GET_IMPORT_LIST_DATA_SUCCESS:
            return getImportListDataSuccess(state, action.data);
        case actionTypes.GET_IMPORT_LIST_DATA_FAIL:
            return getImportListDataFail(state, action.err);
        case actionTypes.START_GET_EXPORT_LIST_DATA:
            return startExportListData(state);
        case actionTypes.GET_EXPORT_LIST_DATA_SUCCESS:
            return getExportListDataSuccess(state, action.data);
        case actionTypes.GET_EXPORT_LIST_DATA_FAIL:
            return getExportListDataFail(state, action.err);
        case actionTypes.START_GET_GOV_PLAN_LIST_DATA:
            return startGovPlanListData(state);
        case actionTypes.GET_GOV_PLAN_LIST_DATA_SUCCESS:
            return getGovPlanListDataSuccess(state, action.data);
        case actionTypes.GET_GOV_PLAN_LIST_DATA_FAIL:
            return getGovPlanListDataFail(state, action.err);
        case actionTypes.START_GET_AWARD_LIST_DATA:
            return startAwardListData(state);
        case actionTypes.GET_AWARD_LIST_DATA_SUCCESS:
            return getAwardListDataSuccess(state, action.data);
        case actionTypes.GET_AWARD_LIST_DATA_FAIL:
            return startGovPlanListData(state, action.err);
        case actionTypes.START_GET_PATENT_LIST_DATA:
            return startPatentListData(state);
        case actionTypes.GET_PATENT_LIST_DATA_SUCCESS:
            return getPatentListDataSuccess(state, action.data);
        case actionTypes.GET_PATENT_LIST_DATA_FAIL:
            return getPatentListDataFail(state, action.err);
        case actionTypes.START_GET_PUBLIC_STOCK_LIST_DATA:
            return startPublicStockListData(state);
        case actionTypes.GET_PUBLIC_STOCK_LIST_DATA_SUCCESS:
            return getPublicStockListDataSuccess(state, action.data);
        case actionTypes.GET_PUBLIC_STOCK_LIST_DATA_FAIL:
            return getPublicStockListDataFail(state, action.err);
        case actionTypes.START_GET_TOP_TEN_GOVPLAN_LIST_DATA:
            return startTopTenGovPlanListData(state);
        case actionTypes.GET_TOP_TEN_GOVPLAN_LIST_DATA_SUCCESS:
            return getTopTenGovPlanListDataSuccess(state, action.data);
        case actionTypes.GET_TOP_TEN_GOVPLAN_LIST_DATA_FAIL:
            return getTopTenGovPlanListDataFail(state, action.err);
        case actionTypes.START_GET_GREEN_FACTORY_DATA:
            return startGetGreenFactoryData(state);
        case actionTypes.GET_GREEN_FACTORY_DATA_SUCCESS:
            return getGreenFactoryDataSuccess(state, action.data);
        case actionTypes.GET_GREEN_FACTORY_DATA_FAIL:
            return getGreenFactoryDataFail(state, action.err);
        case actionType.START_GET_TAIWAN_INVESTMENT_DATA:
            return starGetTaiwanInvestmentData(state);
        case actionType.GET_TAIWAN_INVESTMENT_DATA_SUCCESS:
            return getTaiwanInvestmentDataSuccess(state, action.data);
        case actionType.GET_TAIWAN_INVESTMENT_DATA_FAIL:
            return getTaiwanInvestmentDataFail(state, action.err);
        case actionType.START_GET_FACTORY_AREA_RENT_DATA:
            return startFactoryAreaRentData(state);
        case actionType.GET_FACTORY_AREA_RENT_DATA_SUCCESS:
            return getFactoryAreaRentDataSuccess(state, action.data);
        case actionType.GET_FACTORY_AREA_RENT_DATA_FAIL:
            return getFactoryAreaRentDataFail(state, action.err);
        default:
            return state;
    }
};
