import React from "react";
import { Helmet } from "react-helmet";

const WithHelmet = (props) => {
    const { head, children } = props;

    return(
        <React.Fragment>
            <Helmet>
                <title>{`${head}-產業園區廠商情資平台`}</title>
            </Helmet>
            {children}
        </React.Fragment>
    )
};



export default WithHelmet;