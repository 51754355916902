import React from "react";
import ReactExport from "react-data-export";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export default props => {
    const { data, source, dataLimit } = props;
    const [state, setState] = React.useState({
        filedData:[]
    });

    if(source === '2'){
        React.useEffect(()=>{
            try{
                const excelList = [];
                if(data.length !== 0){
                    data.map(company=>{      
                        excelList.push({
                            廠商名稱:company.content.name,
                            地址:company.content.address,
                            縣市別:company.content.city,
                            產業別四碼:company.content.induFourStr,
                            主要產品:company.content.product,
                            登記資本額:company.content.capital,
                            所屬產業園區:company.content.induPark ? company.content.induPark : "區外",
                            股票狀態:company.content.stockType,
                            公司電話:company.content.Tel ? company.content.Tel : "無資料" ,
                        })           
                    })
                    setState(state=>({
                        ...state,
                        filedData: excelList
                    }))
                }else{
                    setState(state=>({
                        ...state,
                        filedData: []
                    }))
                }
            }catch(err){
                setState(state=>({
                    ...state,
                    filedData: []
                }))
            }
        },[data])
        return(
            <ExcelFile filename="技術快搜廠商清單_產業園區廠商情資平台" element={<Button variant="contained" color="primary" startIcon={<FontAwesomeIcon className="export-excel-icon" icon={faFileExcel} />}>匯出 Excel 報表</Button>}>
                {state.filedData.length !== 0 && 
                    <ExcelSheet data={state.filedData.slice(0, dataLimit.Single_DataCnts_Limit)} name="清單">
                        <ExcelColumn label="廠商名稱" value="廠商名稱"/>
                        <ExcelColumn label="地址" value="地址"/>
                        <ExcelColumn label="縣市別" value="縣市別"/>
                        <ExcelColumn label="產業別四碼" value="產業別四碼"/>
                        <ExcelColumn label="主要產品" value="主要產品"/>
                        <ExcelColumn label="登記資本額（元）" value="登記資本額"/>
                        <ExcelColumn label="所屬產業園區" value="所屬產業園區"/>
                        <ExcelColumn label="股票狀態" value="股票狀態"/>
                        <ExcelColumn label="公司電話" value="公司電話"/>
                    </ExcelSheet>
                }
            </ExcelFile>
        ) 
    }
    else{
        return(
        <ExcelFile
            filename={`複合條件快搜結果清單_產業園區廠商情資平台`}
            element={ <Button variant="contained" color="primary" startIcon={<Icon>download</Icon>} style={{ marginTop: "10px", height: "35px", width: "200px", }}>匯出 Excel 報表</Button>}
        >
            <ExcelSheet
                data={data.slice(0, dataLimit.Single_DataCnts_Limit)}
                name={`複合條件快搜結果清單_產業園區廠商情資平台`}
            >
                <ExcelColumn label="統一編號" value="BusinessNo" />
                <ExcelColumn label="廠商名稱" value="CompanyName" />
                <ExcelColumn label="廠商電話" value="Tel" />
                <ExcelColumn label="資本額" value="CapitalTotal" />
                <ExcelColumn label="公司登記縣市" value="CityName" />
                <ExcelColumn label="稅務行業分類" value="TaxIndustry" />
            </ExcelSheet>
        </ExcelFile>
        )
    }
}
