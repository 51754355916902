import React from "react";

import Footer from "../../components/Footer/Footer";

import * as Style from "./styles";

const News = () => {
  const uid = localStorage.getItem("uid");

  return (
    <Style.NotificationContainer>
      <Style.NotificationHeaderContainer>
        <Style.NotificationHeader>新聞訂閱</Style.NotificationHeader>
        <Style.NotificationSubHeader>
          設定議題關鍵字，平台定期自動推播新聞至email信箱
        </Style.NotificationSubHeader>
      </Style.NotificationHeaderContainer>
      <Style.NotificationContentContainer>
        <Style.NotificationIframe
          url={`https://indupark.smartpark.com.tw/NewsSubscribe/Index/${btoa(uid)}`}
        />
        {/* <Footer /> */}
      </Style.NotificationContentContainer>
    </Style.NotificationContainer>
  );
};

export default News;
