import React from "react";
import styled from "styled-components";

import * as theme from "../assets/theme"

const SlideModal = ({ children, is_open, handleClose, custom_style }) => {



  return (
    <React.Fragment>
      <SlideModalBackdrop onClick={handleClose} is_open={is_open} style={custom_style}/>
      <SlideModalWrapper is_open={is_open}>{children}</SlideModalWrapper>
    </React.Fragment>
  );
};

const SlideModalWrapper = styled.div`
    width: 91%;
    height: 75%;
    display: ${ p => p.is_open ? "flex" : "none" };
    justify-content: center;
    position: absolute;
    top: ${p => p.is_open ? "20px" : "-150vh"};
    transition: 0.7s top linear;
    z-index: 30000;
    
    @media screen and (max-height: 1930px)
    {
      height: 55%;
    }
    
    @media screen and (max-height: 1300px)
    {
      height: 60%;
    }
    
    @media screen and (max-height: 975px)
    {
      height: 67%;
    }
    
    @media screen and (max-height: 955px),
           screen and (max-height: 805px)
    {
      height: 80%;
    }

    @media screen and (max-height: 675px),
           screen and (max-height: 715px)
    {
      height: 77vh;
      overflow-y: auto;
      -ms-overflow-style: none;
      ::-webkit-scrollbar {
        display: none;
      }
    }

    @media (max-width: 1023px) {
      height: 80%;
    }

    @media (max-width: 750px) {
      width: 90%;
      left: 5%;
      top: ${p => p.is_open ? "0px" : "-150vh"};
    }
`;

const SlideModalBackdrop = styled.div`
    position: absolute;
    width: 100vw;
    background-color: ${theme.black};
    transform: translate(-0px, -20px);
    left: 0px;
    right: 0px;
    z-index: 20000;
    opacity: 0.3;
    height: ${ p => p.is_open ? "100vh" : "0vh" };
    display: ${ p => p.is_open ? "block" : "none" };
    transition: height 0.5s linear;
`;

export default SlideModal;
