export const START_FETCH_CLEAN_POLLUTION_LIST_DATA = `START_FETCH_CLEAN_POLLUTION_LIST_DATA`;
export const FETCH_CLEAN_POLLUTION_LIST_DATA_SUCCESS = `FETCH_CLEAN_POLLUTION_LIST_DATA_SUCCESS`;
export const FETCH_CLEAN_POLLUTION_LIST_DATA_FAIL = `FETCH_CLEAN_POLLUTION_LIST_DATA_FAIL`;

export const START_FETCH_CLEAN_POLLUTION_DATA = `START_FETCH_CLEAN_POLLUTION_DATA`;
export const FETCH_CLEAN_POLLUTION_DATA_SUCCESS = `FETCH_CLEAN_POLLUTION_DATA_SUCCESS`;
export const FETCH_CLEAN_POLLUTION_DATA_FAIL = `FETCH_CLEAN_POLLUTION_DATA_FAIL`;

export const START_FETCH_CLEAN_DETAIL_LIST = `START_FETCH_CLEAN_DETAIL_LIST`;
export const FETCH_CLEAN_DETAIL_LIST_SUCCESS = `FETCH_CLEAN_DETAIL_LIST_SUCCESS`;
export const FETCH_CLEAN_DETAIL_LIST_FAIL = `FETCH_CLEAN_DETAIL_LIST_FAIL`;

export const HANDLE_RESET_ERR = `HANDLE_RESET_ERR`;
