import React from "react";
import Box from "@material-ui/core/Box";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import { thousandDivider, parseUrl } from "../../utility/common";
import google_patents_logo from "../../assets/images/google_patents_logo.png";

import "./styles.scss";

export default (props) => {
  const {
    data,
    headerList,
    isArea,
    isStock,
    selectedMenu,
    isListedCompany,
    isPubInvention,
    isPatent, // 是專利（會有 Google Patents Logo）
    isInfo,
    rowsPerPage,
    isTop30,
    GHGNum,
    isGHG,
  } = props;

  const [state, setState] = React.useState({
    endIndex: 0,
    page: 0,
    rowsPerPage: rowsPerPage || 5,
  });
  const url = window.location.origin;

  const handleChangePage = (event, newPage) => {
    setState((state) => ({ ...state, page: newPage }));
  };

  const openNewTabs = (link) => window.open(link, "_blank");

  let content;

  if (isArea) {
    if (data.length > state.rowsPerPage) {
      content = data
        .slice(
          state.page * state.rowsPerPage,
          state.page * state.rowsPerPage + state.rowsPerPage
        )
        .map((row, index) => {
          if (isTop30) {
            return <TableRow
              key={row.BusinessNo || row.Key}
              hover
              className="table-body-row"
              style={index % 2 !== 0 ? { backgroundColor: "#ececec" } : {}}
            >
              <TableCell
                align="left"
                className="table-body-header"
                onClick={() =>
                  openNewTabs(
                    url +
                    "/factory_info/basic_info/" +
                    (row.BusinessNo || row.Key)
                  )
                }
              >
                <button title="另開新視窗" onClick={(e) => {
                  e.stopPropagation();
                  openNewTabs(
                    url +
                    "/factory_info/basic_info/" +
                    (row.BusinessNo || row.Key)
                  )
                }}>
                  {row.CompanyName || row.Value}
                </button>
              </TableCell>
            </TableRow>
          } else {
            return <TableRow
              key={row.BusinessNo || row.Key}
              hover
              className="table-body-row"
              style={index % 2 !== 0 ? { backgroundColor: "#ececec" } : {}}
            >
              <TableCell
                align="left"
                className="table-body-header"
                onClick={() =>
                  openNewTabs(
                    url +
                    "/factory_info/basic_info/" +
                    (row.BusinessNo || row.Key)
                  )
                }
              >
                <button title="另開新視窗" onClick={(e) => {
                  e.stopPropagation();
                  openNewTabs(
                    url +
                    "/factory_info/basic_info/" +
                    (row.BusinessNo || row.Key)
                  )
                }}>
                  {row.BusinessNo || row.Key}
                </button>
              </TableCell>
              <TableCell align="left">{row.CompanyName || row.Value}</TableCell>
              {row.InduParkStr && (
                <TableCell align="left" width={isTop30 && "55%"}>
                  {row.InduParkStr}
                </TableCell>
              )}
            </TableRow>
          }
        });
    } else {
      content = data.map((row, index) => {
        if (isTop30) {
          return <TableRow
            key={row.BusinessNo || row.Key}
            hover
            className="table-body-row"
            style={index % 2 !== 0 ? { backgroundColor: "#ececec" } : {}}
          >
            <TableCell
              align="left"
              className="table-body-header"
              onClick={() =>
                openNewTabs(
                  url +
                  "/factory_info/basic_info/" +
                  (row.BusinessNo || row.Key)
                )
              }
            >
              <button title="另開新視窗" onClick={(e) => {
                e.stopPropagation();
                openNewTabs(
                  url +
                  "/factory_info/basic_info/" +
                  (row.BusinessNo || row.Key)
                )
              }}>
                {row.CompanyName || row.Value}
              </button>
            </TableCell>
          </TableRow>
        } else {
          return <TableRow
            key={row.BusinessNo || row.Key}
            hover
            className="table-body-row"
            style={index % 2 !== 0 ? { backgroundColor: "#ececec" } : {}}
          >
            <TableCell
              align="left"
              className="table-body-header"
              onClick={() =>
                openNewTabs(
                  url + "/factory_info/basic_info/" + (row.BusinessNo || row.Key)
                )
              }
            >
              <button title="另開新視窗" onClick={(e) => {
                e.stopPropagation();
                openNewTabs(
                  url + "/factory_info/basic_info/" + (row.BusinessNo || row.Key)
                )
              }}>
                {row.BusinessNo || row.Key}
              </button>
            </TableCell>
            <TableCell align="left">{row.CompanyName || row.Value}</TableCell>
            {row.InduParkStr && (
              <TableCell align="left">{row.InduParkStr}</TableCell>
            )}
          </TableRow>
        }
      });
    }
  }
  if (isStock) {
    let UpRateCheck = false;
    let Compare = 0;
    let CompareResult = "上升";
    let Compare1 = 0;
    let CompareResult1 = "上升";
    let LastYeartemp = [];
    //篩選出去年同期資料

    if (selectedMenu === '營業額') {
      LastYeartemp = data.filter(function (item, index, array) {
        return item.Month === data[0].Month && item.YY === data[0].YY - 1;
      });
      if (LastYeartemp[0] === undefined || data[0] === undefined) {
        UpRateCheck = false;
      } else if (LastYeartemp[0].Month === data[0].Month) {
        Compare = ((data[0].VALUE - LastYeartemp[0].VALUE) / Math.abs(LastYeartemp[0].VALUE)) * 100;
        if (Compare > 0) {
          CompareResult = "上升";
        } else if (Compare < 0) {
          CompareResult = "下降";
        } else {
          CompareResult = "相同";
        }
        if (Compare === Infinity || isNaN(Compare)) {
          //沒有上一季可以比 或者 上季資料為0
          UpRateCheck = false;
        } else {
          UpRateCheck = true;
        }
      }
      if (LastYeartemp[0] === undefined || data[0] === undefined) {
        UpRateCheck = false;
      } else if (LastYeartemp[0].Month === data[0].Month) {
        Compare1 = ((data[0].TotalVALUE - LastYeartemp[0].TotalVALUE) / Math.abs(LastYeartemp[0].TotalVALUE)) * 100;
        if (Compare1 > 0) {
          CompareResult1 = "上升";
        } else if (Compare1 < 0) {
          CompareResult1 = "下降";
        } else {
          CompareResult1 = "相同";
        }

        if (Compare1 === Infinity || isNaN(Compare1)) {
          //沒有上一季可以比 或者 上季資料為0
          UpRateCheck = false;
        } else {
          UpRateCheck = true;
        }
      }
      headerList[0] = '年月';
      headerList[1] = '當月營收(單位：仟元)';
      headerList[2] = '累計營收(單位：仟元)';
      content = data.map((row, index) => (
        <TableRow
          key={row.BusinessNo}
          hover
          className="table-body-row"
          style={row.Month == 12 ? { backgroundColor: "#ececec" } : {}}
        >
          {!isGHG ?
            <>
              <TableCell className="noBorderBottom" align="center" style={{ display: 'flex', flexDirection: 'column' }}>
                <div>{row.YY + "/" + row.Month} </div>
              </TableCell>
              {selectedMenu === "股利政策" && row.VALUE === 0 ? (
                <TableCell align="center">
                  {index === 0 && <p>無資料或未公開</p>}
                </TableCell>
              ) : (
                <>
                  <TableCell align="center">
                    {row.VALUE.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    {index === 0 && UpRateCheck && (
                      <p>
                        {" "}
                        (相比去年同期{" "}
                        <a
                          style={
                            CompareResult == "上升"
                              ? { color: "red", "font-weight": "bold" }
                              : { color: "green", "font-weight": "bold" }
                          }
                        >
                          {CompareResult}{" "}
                        </a>
                        {Math.abs(Compare)
                          .toFixed(2)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        %)
                      </p>
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {row.TotalVALUE.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    {index === 0 && UpRateCheck && (
                      <p>
                        {" "}
                        (相比去年同期{" "}
                        <a
                          style={
                            CompareResult1 == "上升"
                              ? { color: "red", "font-weight": "bold" }
                              : { color: "green", "font-weight": "bold" }
                          }
                        >
                          {CompareResult1}{" "}
                        </a>
                        {Math.abs(Compare1)
                          .toFixed(2)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        %)
                      </p>
                    )}
                  </TableCell>
                </>
              )}
            </>
            :
            (GHGNum ?
              <>
                {GHGNum === 1 && (
                  <>
                    <TableCell align={"left"}>{row.Year}</TableCell>
                    <TableCell align="left">{row.Category1_DataBoundary}</TableCell>
                    <TableCell align="left">{row.Category1_Verify === "N" ? "否" : row.Category1_Verify}</TableCell>
                    <TableCell align="left">{parseFloat(row.Category1_Emission.replaceAll(",", "")).toFixed(3).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</TableCell>
                  </>)
                }
                {GHGNum === 2 && (
                  <>
                    <TableCell align={"left"}>{row.Year}</TableCell>
                    <TableCell align="left">{row.Category2_DataBoundary}</TableCell>
                    <TableCell align="left">{row.Category2_Verify === "N" ? "否" : row.Category2_Verify}</TableCell>
                    <TableCell align="left">{parseFloat(row.Category2_Emission.replaceAll(",", "")).toFixed(3).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</TableCell>
                  </>)
                }
                {GHGNum === 3 && (
                  <>
                    <TableCell align={"left"}>{row.Year}</TableCell>
                    <TableCell align="left">{row.Category1_DataBoundary}</TableCell>
                    <TableCell align="left">{row.Category1_Verify === "N" ? "否" : row.Category1_Verify}</TableCell>
                    <TableCell align="left">{parseFloat(row.Category3_Emission.replaceAll(",", "")).toFixed(3).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</TableCell>
                  </>)
                }
                {GHGNum === 4 && (
                  <>
                    <TableCell align={"left"}>{row.Year}</TableCell>
                    <TableCell align="left">{row.Density}</TableCell>
                    <TableCell align="left">{row.Unit}</TableCell>
                  </>)
                }
              </>
              : null)
          }
        </TableRow>
      ));
    }
    else {
      LastYeartemp = data.filter(function (item, index, array) {
        return item.Month === data[0].Month && item.YY === data[0].YY - 1;
      });
      if (LastYeartemp[0] === undefined || data[0] === undefined) {
        UpRateCheck = false;
      } else if (LastYeartemp[0].Month === data[0].Month) {
        Compare =
          ((data[0].VALUE - LastYeartemp[0].VALUE) /
            Math.abs(LastYeartemp[0].VALUE)) *
          100;
        if (Compare > 0) {
          CompareResult = "上升";
        } else if (Compare < 0) {
          CompareResult = "下降";
        } else {
          CompareResult = "相同";
        }

        if (Compare === Infinity || isNaN(Compare)) {
          //沒有上一季可以比 或者 上季資料為0
          UpRateCheck = false;
        } else {
          UpRateCheck = true;
        }
      }

      // let temp = []
      // if(selectmenu == "股利政策"){
      //   temp = data.filter(function(item, index, array){
      //     return item.Month == 12;       // 股利政策只顯示年
      //   });
      // }
      // else{temp = data}

      content = data.map((row, index) => (
        <TableRow
          key={row.BusinessNo}
          hover
          className="table-body-row"
          style={row.Month == 12 ? { backgroundColor: "#ececec" } : {}}
        >
          {!isGHG ?
            <>
              <TableCell align="center">{row.YY}</TableCell>
              <TableCell align="center">
                {row.Month.toString()
                  .replace("12", "四")
                  .replace("9", "三")
                  .replace("6", "二")
                  .replace("3", "一")}
              </TableCell>
              {selectedMenu === "股利政策" && row.VALUE === 0 ? (
                <TableCell align="center">
                  {index === 0 && <p>無資料或未公開</p>}
                </TableCell>
              ) : (
                <TableCell align="center">
                  {row.VALUE.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  {index === 0 && UpRateCheck && (
                    <p>
                      {" "}
                      (相比去年同期{" "}
                      <a
                        style={
                          CompareResult == "上升"
                            ? { color: "red", "font-weight": "bold" }
                            : { color: "green", "font-weight": "bold" }
                        }
                      >
                        {CompareResult}{" "}
                      </a>
                      {Math.abs(Compare)
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      %)
                    </p>
                  )}
                </TableCell>
              )}
            </>
            :
            (GHGNum ?
              <>
                {GHGNum === 1 && (
                  <>
                    <TableCell align={"left"}>{row.Year}</TableCell>
                    <TableCell align="left">{row.Category1_DataBoundary}</TableCell>
                    <TableCell align="left">{row.Category1_Verify === "N" ? "否" : row.Category1_Verify}</TableCell>
                    <TableCell align="left">{parseFloat(row.Category1_Emission.replaceAll(",", "")).toFixed(3).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</TableCell>
                  </>)
                }
                {GHGNum === 2 && (
                  <>
                    <TableCell align={"left"}>{row.Year}</TableCell>
                    <TableCell align="left">{row.Category2_DataBoundary}</TableCell>
                    <TableCell align="left">{row.Category2_Verify === "N" ? "否" : row.Category2_Verify}</TableCell>
                    <TableCell align="left">{parseFloat(row.Category2_Emission.replaceAll(",", "")).toFixed(3).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</TableCell>
                  </>)
                }
                {GHGNum === 3 && (
                  <>
                    <TableCell align={"left"}>{row.Year}</TableCell>
                    <TableCell align="left">{row.Category1_DataBoundary}</TableCell>
                    <TableCell align="left">{row.Category1_Verify === "N" ? "否" : row.Category1_Verify}</TableCell>
                    <TableCell align="left">{parseFloat(row.Category3_Emission.replaceAll(",", "")).toFixed(3).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</TableCell>
                  </>)
                }
                {GHGNum === 4 && (
                  <>
                    <TableCell align={"left"}>{row.Year}</TableCell>
                    <TableCell align="left">{row.Density}</TableCell>
                    <TableCell align="left">{row.Unit}</TableCell>
                  </>)
                }
              </>
              : null)
          }
        </TableRow>
      ));
    }
  }
  if (isListedCompany || isPubInvention) {
    if (data.length > state.rowsPerPage) {
      content = data
        .slice(
          state.page * state.rowsPerPage,
          state.page * state.rowsPerPage + state.rowsPerPage
        )
        .map((row, index) => (
          <TableRow
            key={
              row.Key + row.Type ||
              row.CompanyName + row.PatentNo + row.ChineseName
            }
            hover
            className="table-body-row"
            style={index % 2 !== 0 ? { backgroundColor: "#ececec" } : {}}
          >
            <TableCell
              align="left"
              className="table-body-header"
              width={isListedCompany ? "30%" : "35%"}
              onClick={() =>
                openNewTabs(
                  url +
                  "/factory_info/basic_info/" +
                  (row.Key || row.BusinessNo)
                )
              }
            >
              {row.Value || row.CompanyName}
            </TableCell>
            <TableCell align="left" width={isListedCompany ? "30%" : "15%"}>
              {row.Type || row.PatentNo}
            </TableCell>
            <TableCell
              align="left"
              width={isListedCompany ? "30%" : "50%"}
              style={{
                color:
                  row.period === "上升"
                    ? "red"
                    : row.period === "下降"
                      ? "green"
                      : "#000000",
              }}
            >
              { }
              {
                isPatent ? <Box style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                  {row.ChineseName}
                  <a href={parseUrl(row.PatentNo)} target="_blank" rel="noopener noreferrer">
                    <div style={{ width: "80px", marginLeft: "12px" }}>
                      <img alt="專利資料" src={google_patents_logo} style={{ maxWidth: "100%" }} />
                    </div>
                  </a>
                </Box> : isPubInvention
                  ? row.ChineseName
                  : thousandDivider(row.Revenue_Net)
              }
            </TableCell>
          </TableRow>
        ));
    } else {
      content = data.map((row, index) => (
        <TableRow
          key={
            row.Key + row.Type ||
            row.CompanyName + row.PatentNo + row.ChineseName
          }
          hover
          className="table-body-row"
          style={index % 2 !== 0 ? { backgroundColor: "#ececec" } : {}}
        >
          <TableCell
            align="left"
            className="table-body-header"
            width={"35%"}
            onClick={() =>
              openNewTabs(
                url + "/factory_info/basic_info/" + (row.Key || row.BusinessNo)
              )
            }
          >
            {row.Value || row.CompanyName}
          </TableCell>
          <TableCell align="left" width={"15%"}>
            {row.Type || row.PatentNo}
          </TableCell>
          <TableCell
            align="left"
            width={"50%"}
            style={{
              color:
                row.period === "上升"
                  ? "red"
                  : row.period === "下降"
                    ? "green"
                    : "#000000",
            }}
          >
            {
              isPatent ? <Box style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                {row.ChineseName}
                <a href={parseUrl(row.PatentNo)} target="_blank" rel="noopener noreferrer">
                  <div style={{ width: "80px", marginLeft: "12px" }}>
                    <img alt="專利資料" src={google_patents_logo} style={{ maxWidth: "100%" }} />
                  </div>
                </a>
              </Box> : isPubInvention
                ? row.ChineseName
                : thousandDivider(row.Revenue_Net)
            }
          </TableCell>
        </TableRow>
      ));
    }
  }
  if (isInfo) {
    content = (
      <TableRow hover className="table-body-row">
        <TableCell align="center">
          <strong style={{ fontSize: 28, fontWeight: "bold" }}>
            {thousandDivider(data[0])}
          </strong>
        </TableCell>
      </TableRow>
    );
  }

  return (
    <TableContainer className="table-container">
      <Table className="table">
        <TableHead className="table-header">
          {headerList && (
            <TableRow className="table-row">
              {headerList.map((headerItem, index) => (
                <TableCell
                  className="table-cell"
                  align={isStock ? !isGHG ? "center" : "left" : "left"}
                  key={headerItem.toString() + index}
                >
                  {headerItem}
                </TableCell>
              ))}
            </TableRow>
          )}
        </TableHead>
        <TableBody className="table-body">
          {data.length !== 0 && content}
        </TableBody>
      </Table>
      {data.length === 0 && (
        <Table>
          <TableBody className="table-body-no-data">
            <TableRow hover className="table-body-row">
              <TableCell align="center" className="no-data">
                本項目無資料
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      )}
      {data.length > state.rowsPerPage && !isStock && (
        <TablePagination
          rowsPerPageOptions={[]}
          component="div"
          count={data.length}
          rowsPerPage={state.rowsPerPage}
          page={state.page}
          onChangePage={handleChangePage}
        />
      )}
    </TableContainer>
  );
};