export const START_FETCH_EFFECTED_EVENTS_LIST_DATA = `START_FETCH_EFFECTED_EVENTS_LIST_DATA`;
export const FETCH_EFFECTED_EVENTS_LIST_DATA_SUCCESS = `FETCH_EFFECTED_EVENTS_LIST_DATA_SUCCESS`;
export const FETCH_EFFECTED_EVENTS_LIST_DATA_FAIL = `FETCH_EFFECTED_EVENTS_LIST_DATA_FAIL`;

export const START_FETCH_INDUSTRY_4_CODE_DATA = `START_FETCH_INDUSTRY_4_CODE_DATA`;
export const FETCH_INDUSTRY_4_CODE_DATA_SUCCESS = `FETCH_INDUSTRY_4_CODE_DATA_SUCCESS`;
export const FETCH_INDUSTRY_4_CODE_DATA_FAIL = `FETCH_INDUSTRY_4_CODE_DATA_FAIL`;

export const START_FETCH_FOREIGN_TRADE_DATA = `START_FETCH_FOREIGN_TRADE_DATA`;
export const FETCH_FOREIGN_TRADE_EXPORT_DATA_SUCCESS = `FETCH_FOREIGN_TRADE_EXPORT_DATA_SUCCESS`;
export const FETCH_FOREIGN_TRADE_IMPORT_DATA_SUCCESS = `FETCH_FOREIGN_TRADE_IMPORT_DATA_SUCCESS`;
export const FETCH_FOREIGN_TRADE_DATA_FAIL = `FETCH_FOREIGN_TRADE_DATA_FAIL`;

export const START_FETCH_COUNTRY_DATA = `START_FETCH_COUNTRY_DATA`;
export const FETCH_COUNTRY_EXPORT_DATA_SUCCESS = `FETCH_COUNTRY_EXPORT_DATA_SUCCESS`;
export const FETCH_COUNTRY_IMPORT_DATA_SUCCESS = `FETCH_COUNTRY_IMPORT_DATA_SUCCESS`;
export const FETCH_COUNTRY_DATA_FAIL = `FETCH_COUNTRY_DATA_FAIL`;

export const START_FETCH_NEWS_DATA = `START_FETCH_NEWS_DATA`;
export const FETCH_NEWS_DATA_SUCCESS = `FETCH_NEWS_DATA_SUCCESS`;
export const FETCH_NEWS_DATA_FAIL = `FETCH_NEWS_DATA_FAIL`;

export const START_SEARCH_EFFECTED_EVENTS_RESULT = `START_SEARCH_EFFECTED_EVENTS_RESULT`;
export const SEARCH_EFFECTED_EVENTS_RESULT_SUCCESS = `SEARCH_EFFECTED_EVENTS_RESULT_SUCCESS`;
export const SEARCH_EFFECTED_EVENTS_RESULT_FAIL = `SEARCH_EFFECTED_EVENTS_RESULT_FAIL`;

export const HANDLE_RESET_ERR = `HANDLE_RESET_ERR`;

export const RESET_STATE = `RESET_STATE`;
