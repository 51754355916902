import React, { useState, useMemo } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import SwiperCore, { A11y, Navigation, Autoplay, EffectCoverflow } from "swiper";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Link from '@mui/material/Link';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.scss";
import "../../assests/styles/_swiper-navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/scrollbar/scrollbar.scss";
import Swal from "sweetalert2";

import { Grid, Typography, Box, Icon, Button, IconButton, TablePagination, FormControl, InputLabel, MenuItem, Select, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper } from "@material-ui/core";
import * as theme from "../../assets/theme";
import Spinner from "../../components/Spinner/Spinner";
import { thousandDivider } from "../../utility/common";

import apple from "../../assests/news_images/apple.jpg";
import china from "../../assests/news_images/china.jpg";
import cna from "../../assests/news_images/cna.jpg";
import CteeLogo from "../../assests/news_images/CteeLogo.jpg";
import ettoday from "../../assests/news_images/ettoday.jpg";
import free from "../../assests/news_images/free.png";
import udn from "../../assests/news_images/udn_logo.gif";
import yahoo from "../../assests/news_images/yahoo.png";
import white from "../../assests/news_images/white.jpg";
import pts from "../../assests/news_images/pts.png";
import ttv from "../../assests/news_images/ttv.jpg";
import eventProComment from "../../assests/images/event_pro_comment.svg";
import eventProCommentModal from "../../assests/images/event_pro_comment_modal.svg";

import {
  handleFetchEffectedEventsData,
  handleFetchIndustry4CodeStatData,
  handleFetchForeignTradeStatData,
  handleFetchCountryStatData,
  handleFetchNewsData,
} from "../../store/actions/effected_events";
import { handleGetDashboardList } from "../../store/actions/custom_industry_dashboard";
import BarChart from "../EventDashboard/BarChart";
import HorizontalBarChart from "../EventDashboard/HorizontalBarChart";
import LineChart from "../EventDashboard/LineChart";
import DataInfoModal from "../EventDashboard/DataInfoModal";

SwiperCore.use([Navigation, EffectCoverflow]);
const themeColor = createTheme({
  palette: {
    swiper: {
      main: "gray",
    },
  },
});

const Loading = () => (
  <LoadingWrapper>
    <Spinner />
  </LoadingWrapper>
);

const CustomizedIndustryDashboard = (props) => {
  const { innerWidth } = useSelector((state) => state.app);

  const handleNewsIcon = (logo) => {
    let imageSrc = white;
    switch (logo) {
      case "apple.jpg":
        imageSrc = apple;
        break;
      case "china.jpg":
        imageSrc = china;
        break;
      case "cna.jpg":
        imageSrc = cna;
        break;
      case "CteeLogo.jpg":
        imageSrc = CteeLogo;
        break;
      case "ettoday.jpg":
        imageSrc = ettoday;
        break;
      case "free.png":
        imageSrc = free;
        break;
      case "udn_logo.gif":
        imageSrc = udn;
        break;
      case "yahoo.png":
        imageSrc = yahoo;
        break;
      case "ttv.jpg":
        imageSrc = ttv;
        break;
      case "pts.png":
        imageSrc = pts;
        break;
      default:
        return (imageSrc = white);
    }

    return <img className="news-image" loading={"lazy"} src={imageSrc} alt="news-logo" />;
  };
  // swiper - 專家建議
  const swiperRef = React.useRef(null);
  const prevSlide = React.useCallback((e) => {
    e.stopPropagation();
    swiperRef.current.swiper.slidePrev();
  }, [swiperRef]);
  const nextSlide = React.useCallback((e) => {
    e.stopPropagation();
    swiperRef.current.swiper.slideNext();
  }, [swiperRef]);

  const pros_suggestion = [
    {
      id: 1,
      title: "遠見雜誌",
      description: `企業若要達成淨零碳排的目標，<strong style="font-weight:600" >必先做碳盤查，了解產品生命週期產生的碳量</strong>；之後再進行相關的碳定價，把碳排從計算量體轉為成本數字，成為生產成本的一環；最後，再來思考如何透過製程更新、技術改善、材料替換，進行碳中和。`,
      link: "https://www.gvm.com.tw/article/88376"
    },
    {
      id: 2,
      title: "科技新報",
      description: `<strong style="font-weight:600">大型企業應加緊制訂碳定價，中小企業應加速內部碳盤查</strong><br />彭啟明指出，大型企業在這個階段應該要完成碳盤查，並且進行至碳定價的階段。中小企業先從數位轉型和碳盤查做起。未來客戶也會要求提供產品中含碳量，中小企業必須一步步建構盤查資料。但中小企業若沒有數位化，甚至是數位轉型，就如同要進行減重，卻連體重機都沒準備，要進行碳盤查的難度就會相當高，因此兩者併行對中小企業而言無疑是當務之急。`,
      link: "https://technews.tw/2022/05/18/how-net-zero-emissions-will-impact-businesses/"
    },
  ]


  // swiper - 中間指標
  const swiperRefSecond = React.useRef(null);
  const prevSlideSecond = React.useCallback((e) => {
    e.stopPropagation();
    swiperRefSecond.current.swiper.slidePrev();
  }, [swiperRefSecond]);

  const nextSlideSecond = React.useCallback((e) => {
    e.stopPropagation();
    swiperRefSecond.current.swiper.slideNext();
  }, [swiperRefSecond]);

  // dispatch
  const dispatch = useDispatch();
  const {
    effected_industry_4_code_loading,
    effected_industry_4_code,
    effected_foreign_trade_loading,
    effected_foreign_trade_export,
    effected_foreign_trade_import,
    effected_country_loading,
    effected_country_export,
    effected_country_import,
    effected_news_loading,
    effected_news,
  } = useSelector((state) => state.effected_events)

  const {
    getDashboardList,
    getDashboardList_loading,
  } = useSelector((state) => state.custom_industry_dashboard);

  React.useEffect(() => {
    //取得自訂產業儀表版清單
    dispatch(handleGetDashboardList());
  }, []);

  // init value
  const [state, setState] = useState({
    BoardID: "",
    BoardName: "",
    Indu_Description: "",
    Industry4CodeValueTrend: "",
    Industry4CodeCompanyRank: "",
    Industry4CodeFactoryCityRank: "",
    Industry4CodeFactoryParkRank: "",
    ExportValueTrend: "",
    ImportValueTrend: "",
    CountryExportValueTrend: "",
    CountryExportValueTrendCountryList: [],
    CountryExportValueTrendCountry: "",
    CountryImportValueTrend: "",
    CountryImportValueTrendCountryList: [],
    CountryImportValueTrendCountry: "",
    CountryRankExport: "",
    CountryRankImport: "",
    page: 0,
    rowsPerPage: 5
  })

  React.useEffect(() => {
    //initially set some value;
    const handle_init = async () => {
      await getDashboardList.map((data, index) => {
        if (data.BoardID === props.match.params.OrgId) {
          setState((state) => ({
            ...state,
            BoardID: props.match.params.OrgId,
            BoardName: data.BoardName,
            Indu_Description: data.Indu_Description,
          }));
        }
      })
    };
    handle_init();
  }, [getDashboardList]);

  React.useEffect(() => {
    const handle_init = async () => {
      await dispatch(handleFetchIndustry4CodeStatData(state.BoardID));
      await dispatch(handleFetchForeignTradeStatData(state.BoardID, "Export"));
      await dispatch(handleFetchForeignTradeStatData(state.BoardID, "Import"));
      await dispatch(handleFetchCountryStatData(state.BoardID, "Export"));
      await dispatch(handleFetchCountryStatData(state.BoardID, "Import"));
      await dispatch(handleFetchNewsData(state.BoardID));
     };
    if(state.BoardID){
      handle_init();
    }
  }, [state.BoardID])

  React.useEffect(() => {
    if (effected_industry_4_code) {
      if (effected_industry_4_code.ValueTrend && effected_industry_4_code.ValueTrend.length > 0) {
        setState((state) => ({
          ...state,
          Industry4CodeValueTrend: "all",
        }));
      }
      if (effected_industry_4_code.CompanyRank && effected_industry_4_code.CompanyRank.length > 0) {
        setState((state) => ({
          ...state,
          Industry4CodeCompanyRank: "all",
        }));
      }
      if (effected_industry_4_code.FactoryParkRank && effected_industry_4_code.FactoryParkRank.length > 0) {
        setState((state) => ({
          ...state,
          Industry4CodeFactoryParkRank: "all",
        }));
      }

      if (effected_industry_4_code.FactoryCityRank && effected_industry_4_code.FactoryCityRank.length > 0) {
        setState((state) => ({
          ...state,
          Industry4CodeFactoryCityRank: "all",
        }));
      }
    }
  }, [effected_industry_4_code]);

  React.useEffect(() => {
    if (effected_foreign_trade_export) {
      if (effected_foreign_trade_export.ValueTrend && effected_foreign_trade_export.ValueTrend.length > 0) {
        setState((state) => ({
          ...state,
          ExportValueTrend: "all",
        }));
      }
    }
  }, [effected_foreign_trade_export]);

  React.useEffect(() => {
    if (effected_foreign_trade_import) {
      if (effected_foreign_trade_import.ValueTrend && effected_foreign_trade_import.ValueTrend.length > 0) {
        setState((state) => ({
          ...state,
          ImportValueTrend: "all",
        }));
      }
    }
  }, [effected_foreign_trade_import]);

  React.useEffect(() => {
    if (effected_country_export) {
      if (effected_country_export.ValueTrade && effected_country_export.ValueTrade.length > 0) {
        setState((state) => ({
          ...state,
          CountryExportValueTrend: "all",
        }));
      }
      if (effected_country_export.CountryRank && effected_country_export.CountryRank.length > 0) {
        setState((state) => ({
          ...state,
          CountryRankExport: "all",
        }));
      }
    }
  }, [effected_country_export]);

  React.useEffect(() => {
    if (effected_country_import) {
      if (effected_country_import.ValueTrade && effected_country_import.ValueTrade.length > 0) {
        setState((state) => ({
          ...state,
          CountryImportValueTrend: "all",
        }));
      }
      if (effected_country_import.CountryRank && effected_country_import.CountryRank.length > 0) {
        setState((state) => ({
          ...state,
          CountryRankImport: "all",
        }));
      }
    }
  }, [effected_country_import]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const filterData = getDashboardList.filter(item => item.BoardID === value);
    setState((state) => ({
      ...state,
      [name]: value,
      BoardName: filterData[0].BoardName,
      Indu_Description: filterData[0].Indu_Description
    }));
  };

  const handleValueChange = (e) => {
    const { name, value } = e.target;
    setState((state) => ({
      ...state,
      [name]: value,
    }));
  };

  // 圖表 - 全部 - 有加總邏輯，例如：家數、出口額
  function getChartData(data, selectTarget, category, targetKey, countKey) {
    // 家數(加總)
    const originData = JSON.parse(JSON.stringify(data));
    const allData = [];
    if (selectTarget === "all") {
      originData.forEach((item) => {
        item[category].forEach(ele => {
          allData.push(ele)
        })
      })
      const result = allData.reduce((a, v) => {
        if (a[v[targetKey]]) {
          a[v[targetKey]][countKey] = a[v[targetKey]][countKey] + v[countKey]
        } else {
          a[v[targetKey]] = v
        }
        return a
      }, {})
      const sortTotal = JSON.parse(JSON.stringify(Object.values(result))).sort((a, b) => b[countKey] - a[countKey])
      let CountTotal = sortTotal.slice(0, 10).sort((a, b) => a[countKey] - b[countKey])
      return CountTotal
    } else {
      const filterData = originData.filter(ele => ele.Code === selectTarget)
      const spliceData = filterData.length > 0 ? [...filterData[0][category]].slice(0, 10).sort((a, b) => a[countKey] - b[countKey]) : []
      return spliceData
    }
  }
  // 圖表 - 全部 - 沒有加總邏輯，例如：資本額排行
  function getRankChartData(data, selectTarget, category, targetKey, countKey) {
    // 資本額排行（不加總）
    const originData = JSON.parse(JSON.stringify(data));
    const allData = [];
    if (selectTarget === "all") {
      originData.forEach((item) => {
        item[category].forEach(ele => {
          allData.push(ele)
        })
      })
      const result = allData.reduce((a, v) => {
        if (!a[v[targetKey]]) {
          a[v[targetKey]] = v
        }
        return a
      }, {})

      const arrayReverse = Object.values(result).sort((a, b) => b[countKey] - a[countKey]);
      let CountTotal = JSON.parse(JSON.stringify(arrayReverse)).slice(0, 10).sort((a, b) => a[countKey] - b[countKey]);
      return CountTotal
    } else {
      const filterData = originData.filter(ele => ele.Code === selectTarget)
      const spliceData = filterData.length > 0 ? [...filterData[0][category]].slice(0, 10).sort((a, b) => a[countKey] - b[countKey]) : []
      return spliceData
    }
  }
  // 趨勢圖 - 全部 - 加總，例如：出口額趨勢
  function getTrendChartData(data, selectTarget, category, targetKey, countKey, filterKey) {
    const originData = JSON.parse(JSON.stringify(data));
    const allData = [];
    if (selectTarget === "all") {
      originData.forEach((item) => {
        item[category].forEach(ele => {
          allData.push(ele)
        })
      })
      const result = allData.reduce((a, v) => {
        if (a[v[targetKey]]) {
          a[v[targetKey]][countKey] = a[v[targetKey]][countKey] + v[countKey]
        } else {
          a[v[targetKey]] = v
        }
        return a
      }, {})
      let CountTotal = JSON.parse(JSON.stringify(Object.values(result))).sort((a, b) => b[targetKey] - a[targetKey]).slice(0, 10).sort((a, b) => a[targetKey] - b[targetKey]);
      return [
        {
          id: "all",
          data: CountTotal.map((item, i) => ({
            x: item[targetKey],
            y: item[countKey],
          })),
        }
      ];
    } else {
      const filterData = originData.filter(ele => ele[filterKey] === selectTarget);
      return [
        {
          id: filterData.length > 0 ? filterData[0][filterKey] : [],
          data: (filterData.length > 0 ? filterData[0][category] : []).slice(0, 10).sort((a, b) => a.Year - b.Year).map((item, i) => ({
            x: item[targetKey],
            y: item[countKey],
          })),
        }
      ];
    }
  }
  const TrendChartData = useMemo(() => {
    if (effected_industry_4_code && effected_industry_4_code.ValueTrend && effected_industry_4_code.ValueTrend.length > 0) {
      return getTrendChartData(effected_industry_4_code.ValueTrend, state.Industry4CodeValueTrend, "Trend", "Year", "Value", "Code")
    }
  }, [state.Industry4CodeValueTrend, effected_industry_4_code]);
  const ExportTrendChartData = useMemo(() => {
    if (effected_foreign_trade_export && effected_foreign_trade_export.ValueTrend && effected_foreign_trade_export.ValueTrend.length > 0) {
      return getTrendChartData(effected_foreign_trade_export.ValueTrend, state.ExportValueTrend, "Trend", "Year", "TotalValue", "Code");
    }
  }, [state.ExportValueTrend, effected_foreign_trade_export]);
  const ImportTrendChartData = useMemo(() => {
    if (effected_foreign_trade_import && effected_foreign_trade_import.ValueTrend && effected_foreign_trade_import.ValueTrend.length > 0) {
      return getTrendChartData(effected_foreign_trade_import.ValueTrend, state.ImportValueTrend, "Trend", "Year", "TotalValue", "Code");
    }
  }, [state.ImportValueTrend, effected_foreign_trade_import]);
  const CompanyRankChartData = useMemo(() => {
    if (effected_industry_4_code && effected_industry_4_code.CompanyRank && effected_industry_4_code.CompanyRank.length > 0) {
      return getRankChartData(effected_industry_4_code.CompanyRank, state.Industry4CodeCompanyRank, "Rank", "CompanyName", "CapitalTotal");
    }
  }, [state.Industry4CodeCompanyRank, effected_industry_4_code]);
  const FactoryCityRankChartData = useMemo(() => {
    if (effected_industry_4_code && effected_industry_4_code.FactoryCityRank && effected_industry_4_code.FactoryCityRank.length > 0) {
      return getChartData(effected_industry_4_code.FactoryCityRank, state.Industry4CodeFactoryCityRank, "Rank", "CityName", "Cnts");
    }
  }, [state.Industry4CodeFactoryCityRank, effected_industry_4_code]);
  const FactoryParkRankChartData = useMemo(() => {
    if (effected_industry_4_code && effected_industry_4_code.FactoryParkRank && effected_industry_4_code.FactoryParkRank.length > 0) {
      return getChartData(effected_industry_4_code.FactoryParkRank, state.Industry4CodeFactoryParkRank, "Rank", "IndustryArea", "Cnts")
    }
  }, [state.Industry4CodeFactoryParkRank, effected_industry_4_code]);
  const CountryRankExportChartData = useMemo(() => {
    if (effected_country_export && effected_country_export.CountryRank && effected_country_export.CountryRank.length > 0) {
      return getChartData(effected_country_export.CountryRank, state.CountryRankExport, "Rank", "Country", "TotalValue");
    }
  }, [state.CountryRankExport, effected_country_export]);
  const CountryRankImportChartData = useMemo(() => {
    if (effected_country_import && effected_country_import.CountryRank && effected_country_import.CountryRank.length > 0) {
      return getChartData(effected_country_import.CountryRank, state.CountryRankImport, "Rank", "Country", "TotalValue");
    }
  }, [state.CountryRankImport, effected_country_import]);

  // 國別出口額趨勢 - 貨品、國家選單
  const CountryExportTrendCountryList = useMemo(() => {
    if (effected_country_export && effected_country_export.ValueTrade && effected_country_export.ValueTrade.length > 0) {
      const originData = JSON.parse(JSON.stringify(effected_country_export.ValueTrade));
      const allData = [];
      const allCountryData = [];
      if (state.CountryExportValueTrend === "all") {
        setState(state => ({
          ...state,
          CountryExportValueTrendCountry: "all",
          CountryExportValueTrendCountryList: [],
        }))
        originData.forEach((item) => {
          item.CountryList.forEach(ele => {
            allCountryData.push(ele);
            ele.Trend.forEach(trend => {
              allData.push(trend)
            })
          })
        })
        const uniqueCountry = [...new Set(allCountryData.map(item => item.Country))];
        const uniqueCountryList = uniqueCountry.map(c => ({ Country: c }))
        return uniqueCountryList
      } else {
        const filterData = originData.filter(ele => ele.Code === state.CountryExportValueTrend);
        if (filterData.length > 0) {
          setState(state => ({
            ...state,
            CountryExportValueTrendCountry: "all",
            CountryExportValueTrendCountryList: filterData[0].CountryList,
          }))
        }
        return filterData[0]?.CountryList || []
      }
    }
  }, [state.CountryExportValueTrend, effected_country_export]);
  const CountryExportTrendChartData = useMemo(() => {
    if (effected_country_export && effected_country_export.ValueTrade && effected_country_export.ValueTrade.length > 0) {
      // 1. 全部 vs 全部
      // 2. 全部 vs 國家
      if (state.CountryExportValueTrend === "all") {
        const originData = JSON.parse(JSON.stringify(effected_country_export.ValueTrade));
        let targetData = [];
        if (state.CountryExportValueTrendCountry === "all") {
          const allData = [];
          originData.forEach((item) => {
            item.CountryList.forEach(ele => {
              ele.Trend.forEach(trend => {
                allData.push(trend)
              })
            })
          })
          targetData = JSON.parse(JSON.stringify(allData));
        } else {
          const countryData = [];
          originData.forEach((item) => {
            item.CountryList.forEach(ele => {
              if (ele.Country === state.CountryExportValueTrendCountry) {
                ele.Trend.forEach(trend => {
                  countryData.push(trend)
                })
              }
            })
          })
          targetData = JSON.parse(JSON.stringify(countryData));
        }
        const result = targetData.reduce((a, v) => {
          if (a[v.Year]) {
            a[v.Year].TotalValue = a[v.Year].TotalValue + v.TotalValue
          } else {
            a[v.Year] = v
          }
          return a
        }, {})
        let CountTotal = JSON.parse(JSON.stringify(Object.values(result))).sort((a, b) => b.Year - a.Year).slice(0, 10).sort((a, b) => a.Year - b.Year);
        return [
          {
            id: "all",
            data: CountTotal.map((item, i) => ({
              x: item.Year,
              y: item.TotalValue,
            })),
          }
        ];
      } else {
        // 3. 品項 vs.全部
        // 3. 品項 vs.國家
        return getTrendChartData(state.CountryExportValueTrendCountryList, state.CountryExportValueTrendCountry, "Trend", "Year", "TotalValue", "Country");
      }
    }
  }, [state.CountryExportValueTrend, state.CountryExportValueTrendCountry, state.CountryExportValueTrendCountryList, effected_country_export]);

  // 國別進口額趨勢 - 貨品、國家選單
  const CountryImportTrendCountryList = useMemo(() => {
    if (effected_country_import && effected_country_import.ValueTrade && effected_country_import.ValueTrade.length > 0) {
      const originData = JSON.parse(JSON.stringify(effected_country_import.ValueTrade));
      const allData = [];
      const allCountryData = [];
      if (state.CountryImportValueTrend === "all") {
        setState(state => ({
          ...state,
          CountryImportValueTrendCountry: "all",
          CountryImportValueTrendCountryList: [],
        }))
        originData.forEach((item) => {
          item.CountryList.forEach(ele => {
            allCountryData.push(ele);
            ele.Trend.forEach(trend => {
              allData.push(trend)
            })
          })
        })
        const uniqueCountry = [...new Set(allCountryData.map(item => item.Country))];
        const uniqueCountryList = uniqueCountry.map(c => ({ Country: c }))
        return uniqueCountryList
      } else {
        const filterData = originData.filter(ele => ele.Code === state.CountryImportValueTrend);
        if (filterData.length > 0) {
          setState(state => ({
            ...state,
            CountryImportValueTrendCountry: "all",
            CountryImportValueTrendCountryList: filterData[0].CountryList,
          }))
        }
        return filterData[0]?.CountryList || []
      }
    }
  }, [state.CountryImportValueTrend, effected_country_import]);
  const CountryImportTrendChartData = useMemo(() => {
    if (effected_country_import && effected_country_import.ValueTrade && effected_country_import.ValueTrade.length > 0) {
      // 1. 全部 vs 全部
      // 2. 全部 vs 國家
      if (state.CountryImportValueTrend === "all") {
        const originData = JSON.parse(JSON.stringify(effected_country_import.ValueTrade));
        let targetData = [];
        if (state.CountryImportValueTrendCountry === "all") {
          const allData = [];
          originData.forEach((item) => {
            item.CountryList.forEach(ele => {
              ele.Trend.forEach(trend => {
                allData.push(trend)
              })
            })
          })
          targetData = JSON.parse(JSON.stringify(allData));
        } else {
          const countryData = [];
          originData.forEach((item) => {
            item.CountryList.forEach(ele => {
              if (ele.Country === state.CountryImportValueTrendCountry) {
                ele.Trend.forEach(trend => {
                  countryData.push(trend)
                })
              }
            })
          })
          targetData = JSON.parse(JSON.stringify(countryData));
        }
        const result = targetData.reduce((a, v) => {
          if (a[v.Year]) {
            a[v.Year].TotalValue = a[v.Year].TotalValue + v.TotalValue
          } else {
            a[v.Year] = v
          }
          return a
        }, {})
        let CountTotal = JSON.parse(JSON.stringify(Object.values(result))).sort((a, b) => b.Year - a.Year).slice(0, 10).sort((a, b) => a.Year - b.Year);
        return [
          {
            id: "all",
            data: CountTotal.map((item, i) => ({
              x: item.Year,
              y: item.TotalValue,
            })),
          }
        ];
      } else {
        // 3. 品項 vs.全部
        // 3. 品項 vs.國家
        return getTrendChartData(state.CountryImportValueTrendCountryList, state.CountryImportValueTrendCountry, "Trend", "Year", "TotalValue", "Country");
      }
    }
  }, [state.CountryImportValueTrend, state.CountryImportValueTrendCountry, state.CountryImportValueTrendCountryList, effected_country_import]);

  const handleChangePage = (event, newPage) => {
    setState(state => ({ ...state, page: newPage }))
  }

  return (
    <ThemeProvider theme={themeColor}>
      {effected_industry_4_code_loading || effected_foreign_trade_loading ? (
        <Loading />
      ) :
        <InnerContainer>
          <div style={{ marginTop: "60px" }}>
            <Link href="/custom_industry_dashboard" underline="hover" sx={{ display: "flex", flexDirection: "row", color: "#666666", }}>
              <Icon className="icon" >arrow_back_ios_new</Icon>
              <h3 style={{
                display: "flex", alignItems: "center",

              }}>返回自訂關注產業儀表板後台</h3>
            </Link></div>
          <TitleContainer>
            <Title>{state.BoardName}儀表板</Title>
            <FormControls variant="outlined" className="title-select">
              <InputLabel id="demo-simple-select-label">儀表板選單</InputLabel>
              <MainStyledSelect
                size="small"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="BoardID"
                value={state.BoardID}
                label="儀表板選單"
                onChange={handleChange}
              >
                {(getDashboardList || []).map((option, index) => (
                  <MenuItem
                    key={option.BoardName + index}
                    value={option.BoardID}
                  >
                    {`${option.BoardName}`}
                  </MenuItem>
                ))}
              </MainStyledSelect>
            </FormControls>
          </TitleContainer>
          <Grid my={3} container spacing={3}>
            <Grid item xs={12} md={12}>
              <RowBox>
                <Grid my={3} container spacing={3} style={{ width: window.innerWidth>1285?"90%":"100%" }}>
                  <SwiperContainer my={3} >
                    <IconButton className="arrow-left" size="small" color="swiper" component="span" onClick={prevSlideSecond}>
                      <Icon className="icon" fontSize="large">arrow_left</Icon>
                    </IconButton>
                    <Swiper
                      autoplay={true}
                      observer={true}
                      observeParents={true}
                      pagination={true}
                      ref={swiperRefSecond}
                      initialSlide={0}
                      spaceBetween={0}
                      slidesPerView={1}
                      breakpoints={{
                        640: {
                          slidesPerView: 2,
                          spaceBetween: 10
                        },
                        1024: {
                          slidesPerView: 3,
                          spaceBetween: 10
                        }
                      }}
                      loop={true}
                      allowTouchMove={false}
                      // onSlideChange={() => console.log('slide change')}
                    >
                      {
                        effected_industry_4_code && effected_industry_4_code.SumValue > 0
                        && effected_industry_4_code.TotalValue > 0
                        && effected_industry_4_code.EmployeeCnts > 0
                        && [{ title: "產業總產值", content: (effected_industry_4_code["SumValue"] / 100000) > 1 ? '(億元)' : '(千元)', data: (effected_industry_4_code["SumValue"] / 100000) > 1 ? thousandDivider((effected_industry_4_code["SumValue"] / 100000).toFixed(3)) : thousandDivider(effected_industry_4_code["SumValue"]), unit: (effected_industry_4_code["SumValue"] / 100000) > 1 ? '億元' : '千元', bgColor: "linear-gradient(to right, #88505D, #824855, #8F5D68);" }, { title: "產業產值佔比", content: "產值與全產業總產值佔比", data: ((effected_industry_4_code["SumValue"] / effected_industry_4_code["TotalValue"]) * 100).toFixed(3), unit: "%", bgColor: "linear-gradient(to right, #3DA5F6, #6ABEFF, #86C8FC)" }, { title: "產業從業員工數", content: "所有次產業員工數加總", data: thousandDivider(effected_industry_4_code["EmployeeCnts"]), unit: "人", bgColor: "linear-gradient(to right, #D3BEC0, #D1C0C2, #CAC0C1)" }].map(code => {
                          return (
                            <SwiperSlide>
                              <ColumnBox marginCustom="12px 8px" bgColor={code.bgColor} colorCustom="white">
                                <Typography className="subTitle" variant="h5" component="div">{code.title}</Typography>
                                <p>{code.content}</p>
                                <Box display="flex" justifyContent="flex-end" alignItems="baseline">
                                  <CountNumber customLength={3} variant="h1" component="h2">{code.data} </CountNumber>
                                  <Typography className="unit" variant="h5" component="h5">{code.unit}</Typography>
                                </Box>
                              </ColumnBox>
                            </SwiperSlide>
                          )
                        })
                      }
                      {
                        effected_foreign_trade_export && effected_foreign_trade_export.SumValue > 0
                        && effected_foreign_trade_export.TotalValue > 0
                        && [{ title: "出口總值", content: (effected_foreign_trade_export["SumValue"] / 100000) > 1 ? '(億元)' : '(千元)', data: (effected_foreign_trade_export["SumValue"] / 100000) > 1 ? thousandDivider((effected_foreign_trade_export["SumValue"] / 100000).toFixed(3)) : thousandDivider(effected_foreign_trade_export["SumValue"]), unit: (effected_foreign_trade_export["SumValue"] / 100000) > 1 ? '億元' : '千元', bgColor: "linear-gradient(to right, #88505D, #824855, #8F5D68);" }, { title: "出口值佔比", content: "出口值與全產業總出口值佔比", data: ((effected_foreign_trade_export["SumValue"] / effected_foreign_trade_export["TotalValue"]) * 100).toFixed(3), unit: "％", bgColor: "linear-gradient(to right, #3DA5F6, #6ABEFF, #86C8FC)" }].map(code => {
                          return (
                            <SwiperSlide>
                              <ColumnBox marginCustom="12px 8px" bgColor={code.bgColor} colorCustom="white">
                                <Typography className="subTitle" variant="h5" component="div">{code.title}</Typography>
                                <p>{code.content}</p>
                                <Box display="flex" justifyContent="flex-end" alignItems="baseline">
                                  <CountNumber customLength={3} variant="h1" component="h2">{code.data} </CountNumber>
                                  <Typography className="unit" variant="h5" component="h5">{code.unit}</Typography>
                                </Box>
                              </ColumnBox>
                            </SwiperSlide>
                          )
                        })
                      }
                      {
                        effected_foreign_trade_import && effected_foreign_trade_import.SumValue > 0
                        && effected_foreign_trade_import.TotalValue > 0
                        && [{ title: "進口總值", content: (effected_foreign_trade_import["SumValue"] / 100000) > 1 ? '(億元)' : '(千元)', data: (effected_foreign_trade_import["SumValue"] / 100000) > 1 ? thousandDivider((effected_foreign_trade_import["SumValue"] / 100000).toFixed(3)) : thousandDivider(effected_foreign_trade_import["SumValue"]), unit: (effected_foreign_trade_import["SumValue"] / 100000) > 1 ? '億元' : '千元', bgColor: "linear-gradient(to right, #88505D, #824855, #8F5D68);" }, { title: "進口值佔比", content: "進口值與全產業總進口值佔比", data: ((effected_foreign_trade_import["SumValue"] / effected_foreign_trade_import["TotalValue"]) * 100).toFixed(3), unit: "元", bgColor: "linear-gradient(to right, #3DA5F6, #6ABEFF, #86C8FC)" }].map(code => {
                          return (
                            <SwiperSlide>
                              <ColumnBox marginCustom="12px 8px" bgColor={code.bgColor} colorCustom="white">
                                <Typography className="subTitle" variant="h5" component="div">{code.title}</Typography>
                                <p>{code.content}</p>
                                <Box display="flex" justifyContent="flex-end" alignItems="baseline">
                                  <CountNumber customLength={3} variant="h1" component="h2">{code.data} </CountNumber>
                                  <Typography className="unit" variant="h5" component="h5">{code.unit}</Typography>
                                </Box>
                              </ColumnBox>
                            </SwiperSlide>
                          )
                        })
                      }
                    </Swiper>
                    <IconButton className="arrow-right" size="small" color="swiper" component="span" onClick={nextSlideSecond}>
                      <Icon className="icon" fontSize="large">arrow_right</Icon>
                    </IconButton>
                  </SwiperContainer>
                </Grid>
                <ImgBox>
                  <img src={eventProComment} height="200px" alt="專家圖片" />
                </ImgBox>
              </RowBox>
            </Grid>
          </Grid>
          <Grid my={3} container spacing={3}>
            {/* 產業產值排行 */}
            {
              effected_industry_4_code && effected_industry_4_code.ValueRank && effected_industry_4_code.ValueRank.length > 0 &&
              <Grid item xs={12} md={6} lg={4}>
                <ColumnBox colorCustom="#25758A">
                  <Typography className="subTitle" variant="h5" gutterBottom component="div">產業產值排行</Typography>
                  <BarChart chartData={effected_industry_4_code.ValueRank} keyTarget={["Value"]} indexBy="Name" innerWidth={innerWidth} Uint="元"/>
                  <DataInfoModal dataFrom="經濟部統計處工業產銷存－業別統計資料庫<br />單位：新台幣" />
                </ColumnBox>
              </Grid>
            }
            {/* 產業產值趨勢 */}
            {
              effected_industry_4_code && effected_industry_4_code.ValueTrend && effected_industry_4_code.ValueTrend.length > 0 &&
              <Grid item xs={12} md={6} lg={4}>
                <ColumnBox colorCustom="#25758A">
                  <Box display="flex" flexWrap="wrap" justifyContent="space-between" flexDirection="column">
                    <Typography className="subTitle" variant="h5" gutterBottom component="div">產業產值趨勢</Typography>
                    <FormControls variant="outlined">
                      <StyledSelect
                        size="small"
                        id="demo-simple-trend"
                        name="Industry4CodeValueTrend"
                        value={state.Industry4CodeValueTrend}
                        onChange={handleValueChange}
                      >
                        <MenuItem value="all">全部</MenuItem>
                        {(effected_industry_4_code.ValueTrend || []).map((option, index) => (
                          <MenuItem
                            key={`${option.Name + index}`}
                            value={option.Code}
                          >
                            {option.Name} ( {option.Code} )
                          </MenuItem>
                        ))}
                      </StyledSelect>
                    </FormControls>
                  </Box>
                  <LineChart data={TrendChartData} Unit="千元" />
                  <DataInfoModal dataFrom="經濟部統計處工業產銷存－業別統計資料庫<br />單位：新台幣" />
                </ColumnBox>
              </Grid>
            }
            {/* 產業公司資本額排行 */}
            {
              effected_industry_4_code && effected_industry_4_code.CompanyRank && effected_industry_4_code.CompanyRank.length > 0 &&
              <Grid item xs={12} md={6} lg={4}>
                <ColumnBox colorCustom="#25758A">
                  <Box display="flex" flexWrap="wrap" justifyContent="space-between" flexDirection="column">
                    <Typography className="subTitle" variant="h5" gutterBottom component="div">產業公司資本額排行</Typography>
                    <FormControls variant="outlined">
                      <StyledSelect
                        size="small"
                        id="demo-simple-trend"
                        name="Industry4CodeCompanyRank"
                        value={state.Industry4CodeCompanyRank}
                        onChange={handleValueChange}
                      >
                        <MenuItem value="all">全部</MenuItem>
                        {(effected_industry_4_code.CompanyRank || []).map((option, index) => (
                          <MenuItem
                            key={`${option.Name + index}`}
                            value={option.Code}
                          >
                            {option.Name} ( {option.Code} )
                          </MenuItem>
                        ))}
                      </StyledSelect>
                    </FormControls>
                  </Box>
                  <HorizontalBarChart data={CompanyRankChartData} keyTarget="CapitalTotal" indexBy="CompanyName" />
                  <DataInfoModal dataFrom="經濟部統計處工廠名錄資料庫 <br />經濟部商業司公司設立登記清冊<br />單位：新台幣" />
                </ColumnBox>
              </Grid>
            }
            {/*  產業工廠家數縣市別排行 */}
            {
              effected_industry_4_code && effected_industry_4_code.FactoryCityRank && effected_industry_4_code.FactoryCityRank.length > 0 &&
              <Grid item xs={12} md={6} lg={4}>
                <ColumnBox colorCustom="#25758A">
                  <Box display="flex" flexWrap="wrap" justifyContent="space-between" flexDirection="column">
                    <Typography className="subTitle" variant="h5" gutterBottom component="div">產業工廠家數縣市別排行</Typography>
                    <FormControls variant="outlined">
                      <StyledSelect
                        size="small"
                        id="demo-simple-trend"
                        name="Industry4CodeFactoryCityRank"
                        value={state.Industry4CodeFactoryCityRank}
                        onChange={handleValueChange}
                      >
                        <MenuItem value="all">全部</MenuItem>
                        {(effected_industry_4_code.FactoryCityRank || []).map((option, index) => (
                          <MenuItem
                            key={`${option.Name + index}`}
                            value={option.Code}
                          >
                            {option.Name} ( {option.Code} )
                          </MenuItem>
                        ))}
                      </StyledSelect>
                    </FormControls>
                  </Box>
                  <HorizontalBarChart data={FactoryCityRankChartData} keyTarget="Cnts" indexBy="CityName" />
                  <DataInfoModal dataFrom="經濟部統計處工廠名錄資料庫" />
                </ColumnBox>
              </Grid>
            }
            {/* 產業工廠園區別排行 */}
            {
              effected_industry_4_code && effected_industry_4_code.FactoryParkRank && effected_industry_4_code.FactoryParkRank.length > 0 &&
              <Grid item xs={12} md={6} lg={4}>
                <ColumnBox colorCustom="#25758A">
                  <Box display="flex" flexWrap="wrap" justifyContent="space-between" flexDirection="column">
                    <Typography className="subTitle" variant="h5" gutterBottom component="div">產業工廠園區別排行</Typography>
                    <FormControls variant="outlined">
                      <StyledSelect
                        size="small"
                        id="demo-simple-trend"
                        name="Industry4CodeFactoryParkRank"
                        value={state.Industry4CodeFactoryParkRank}
                        onChange={handleValueChange}
                      >
                        <MenuItem value="all">全部</MenuItem>
                        {(effected_industry_4_code.FactoryParkRank || []).map((option, index) => (
                          <MenuItem
                            key={`${option.Name + index}`}
                            value={option.Code}
                          >
                            {option.Name} ( {option.Code} )
                          </MenuItem>
                        ))}
                      </StyledSelect>
                    </FormControls>
                  </Box>
                  <HorizontalBarChart data={FactoryParkRankChartData} keyTarget="Cnts" indexBy="IndustryArea" />
                  <DataInfoModal dataFrom="經濟部統計處工廠名錄資料庫" />
                </ColumnBox>
              </Grid>
            }
            {/* 貨品出口額排行 */}
            {
              effected_foreign_trade_export && effected_foreign_trade_export.ValueRank && effected_foreign_trade_export.ValueRank.length > 0 &&
              <Grid item xs={12} md={6} lg={4}>
                <ColumnBox colorCustom="#25758A">
                  <Typography className="subTitle" variant="h5" gutterBottom component="div">貨品出口額排行</Typography>
                  <BarChart chartData={JSON.parse(JSON.stringify(effected_foreign_trade_export.ValueRank)).splice(0, 10)} keyTarget={["TotalValue"]} indexBy="Code" innerWidth={innerWidth}  Uint="元"/>
                  <DataInfoModal dataFrom="財政部關務署海關進出口統計資料庫<br />單位：新台幣" />
                </ColumnBox>
              </Grid>
            }
            {/* 貨品出口額趨勢 */}
            {
              effected_foreign_trade_export && effected_foreign_trade_export.ValueTrend && effected_foreign_trade_export.ValueTrend.length > 0 &&
              <Grid item xs={12} md={6} lg={4}>
                <ColumnBox colorCustom="#25758A">
                  <Box display="flex" flexWrap="wrap" justifyContent="space-between" flexDirection="column">
                    <Typography className="subTitle" variant="h5" gutterBottom component="div">貨品出口額趨勢</Typography>
                    <FormControls variant="outlined">
                      <StyledSelect
                        size="small"
                        id="demo-simple-trend"
                        name="ExportValueTrend"
                        value={state.ExportValueTrend}
                        onChange={handleValueChange}
                      >
                        <MenuItem value="all">全部</MenuItem>
                        {(effected_foreign_trade_export.ValueTrend || []).map((option, index) => (
                          <MenuItem
                            key={`${option.Name + index}`}
                            value={option.Code}
                          >
                            {option.Name} ( {option.Code} )
                          </MenuItem>
                        ))}
                      </StyledSelect>
                    </FormControls>
                  </Box>
                  <LineChart data={ExportTrendChartData} Unit="千元" />
                  <DataInfoModal dataFrom="財政部關務署海關進出口統計資料庫<br />單位：新台幣" />
                </ColumnBox>
              </Grid>
            }
            {/* 所有貨品出口額國別排行 */}
            {
              effected_country_export && effected_country_export.ValueRank && effected_country_export.ValueRank.length > 0 &&
              <Grid item xs={12} md={6} lg={4}>
                <ColumnBox colorCustom="#25758A">
                  <Typography className="subTitle" variant="h5" gutterBottom component="div">所有貨品出口額國別排行</Typography>
                  <BarChart title="" chartData={JSON.parse(JSON.stringify(effected_country_export.ValueRank)).splice(0, 10)} keyTarget={["TotalValue"]} indexBy="Country" innerWidth={innerWidth}  Uint="元"/>
                  <DataInfoModal dataFrom="財政部關務署海關進出口統計資料庫<br />單位：新台幣" />
                </ColumnBox>
              </Grid>
            }
            {/* 貨品出口額國別趨勢  */}
            {
              effected_country_export && effected_country_export.ValueTrade && effected_country_export.ValueTrade.length > 0 &&
              <Grid item xs={12} md={6} lg={4}>
                <ColumnBox colorCustom="#25758A">
                  <Typography className="subTitle" variant="h5" gutterBottom component="div">貨品出口額國別趨勢</Typography>
                  <Box display="flex" flexWrap="wrap" justifyContent="flex-start">

                    <FormControls variant="outlined">
                      <StyledSelect
                        size="small"
                        id="demo-simple-trend"
                        name="CountryExportValueTrend"
                        value={state.CountryExportValueTrend}
                        onChange={handleValueChange}
                      >
                        <MenuItem value="all">全部</MenuItem>
                        {(effected_country_export.ValueTrade || []).map((option, index) => (
                          <MenuItem
                            key={`${option.Name + index}`}
                            value={option.Code}
                          >
                            {option.Name} ( {option.Code} )
                          </MenuItem>
                        ))}
                      </StyledSelect>
                    </FormControls>
                    <FormControls variant="outlined">
                      <StyledSelect
                        size="small"
                        id="demo-simple-trend"
                        name="CountryExportValueTrendCountry"
                        value={state.CountryExportValueTrendCountry}
                        onChange={handleValueChange}
                      >
                        <MenuItem value="all">全部</MenuItem>
                        {(CountryExportTrendCountryList || []).map((option, index) => (
                          <MenuItem
                            key={`${option.Country + index}`}
                            value={option.Country}
                          >{option.Country}
                          </MenuItem>
                        ))}
                      </StyledSelect>
                    </FormControls>
                  </Box>
                  <LineChart data={CountryExportTrendChartData} Unit="千元" />
                  <DataInfoModal dataFrom="財政部關務署海關進出口統計資料庫<br />單位：新台幣" />
                </ColumnBox>
              </Grid>
            }
            {/* 單一貨品出口額國別排行  */}
            {
              effected_country_export && effected_country_export.CountryRank && effected_country_export.CountryRank.length > 0 &&
              <Grid item xs={12} md={6} lg={4}>
                <ColumnBox colorCustom="#25758A">
                  <Box display="flex" flexWrap="wrap" justifyContent="space-between" flexDirection="column">
                    <Typography className="subTitle" variant="h5" gutterBottom component="div">單一貨品出口額國別排行</Typography>
                    <FormControls variant="outlined">
                      <StyledSelect
                        size="small"
                        id="demo-simple-trend"
                        name="CountryRankExport"
                        value={state.CountryRankExport}
                        onChange={handleValueChange}
                      >
                        <MenuItem value="all">全部</MenuItem>
                        {(effected_country_export.CountryRank || []).map((option, index) => (
                          <MenuItem
                            key={`${option.Name + index}`}
                            value={option.Code}
                          >
                            {option.Name} ( {option.Code} )
                          </MenuItem>
                        ))}
                      </StyledSelect>
                    </FormControls>
                  </Box>
                  <HorizontalBarChart data={CountryRankExportChartData} keyTarget="TotalValue" indexBy="Country" />
                  <DataInfoModal dataFrom="財政部關務署海關進出口統計資料庫<br />單位：新台幣" />
                </ColumnBox>
              </Grid>
            }
            {/* 貨品進口額排行 */}
            {
              effected_foreign_trade_import && effected_foreign_trade_import.ValueRank && effected_foreign_trade_import.ValueRank.length > 0 &&
              <Grid item xs={12} md={6} lg={4}>
                <ColumnBox colorCustom="#25758A">
                  <Typography className="subTitle" variant="h5" gutterBottom component="div">貨品進口額排行</Typography>
                  <BarChart chartData={JSON.parse(JSON.stringify(effected_foreign_trade_import.ValueRank)).splice(0, 10)} keyTarget={["TotalValue"]} indexBy="Code" innerWidth={innerWidth}  Uint="元"/>
                  <DataInfoModal dataFrom="財政部關務署海關進出口統計資料庫<br />單位：新台幣" />
                </ColumnBox>
              </Grid>
            }
            {/* 貨品進口額趨勢 */}
            {
              effected_foreign_trade_import && effected_foreign_trade_import.ValueTrend && effected_foreign_trade_import.ValueTrend.length > 0 &&
              <Grid item xs={12} md={6} lg={4}>
                <ColumnBox colorCustom="#25758A">
                  <Box display="flex" flexWrap="wrap" justifyContent="flex-start" flexDirection="column">
                    <Typography className="subTitle" variant="h5" gutterBottom component="div">貨品進口額趨勢</Typography>
                    <FormControls variant="outlined">
                      <StyledSelect
                        size="small"
                        id="demo-simple-trend"
                        name="ImportValueTrend"
                        value={state.ImportValueTrend}
                        onChange={handleValueChange}
                      >
                        <MenuItem value="all">全部</MenuItem>
                        {(effected_foreign_trade_import.ValueTrend || []).map((option, index) => (
                          <MenuItem
                            key={`${option.Name + index}`}
                            value={option.Code}
                          >
                            {option.Name} ( {option.Code} )
                          </MenuItem>
                        ))}
                      </StyledSelect>
                    </FormControls>
                  </Box>
                  <LineChart data={ImportTrendChartData} Unit="千元" />
                  <DataInfoModal dataFrom="財政部關務署海關進出口統計資料庫<br />單位：新台幣" />
                </ColumnBox>
              </Grid>
            }
            {/* 所有貨品進口額國別排行 */}
            {
              effected_country_import && effected_country_import.ValueRank && effected_country_import.ValueRank.length > 0 &&
              <Grid item xs={12} md={6} lg={4}>
                <ColumnBox colorCustom="#25758A">
                  <Typography className="subTitle" variant="h5" gutterBottom component="div">所有貨品進口額國別排行</Typography>
                  <BarChart chartData={JSON.parse(JSON.stringify(effected_country_import.ValueRank)).splice(0, 10)} keyTarget={["TotalValue"]} indexBy="Country" innerWidth={innerWidth}  Uint="元"/>
                  <DataInfoModal dataFrom="財政部關務署海關進出口統計資料庫<br />單位：新台幣" />
                </ColumnBox>
              </Grid>
            }
            {/* 貨品進口額國別趨勢 */}
            {
              effected_country_import && effected_country_import.ValueTrade && effected_country_import.ValueTrade.length > 0 &&
              <Grid item xs={12} md={6} lg={4}>
                <ColumnBox colorCustom="#25758A">
                  <Typography className="subTitle" variant="h5" gutterBottom component="div">貨品進口額國別趨勢</Typography>
                  <Box display="flex" flexWrap="wrap" justifyContent="flex-start">
                    <FormControls variant="outlined">
                      <StyledSelect
                        size="small"
                        id="demo-simple-trend"
                        name="CountryImportValueTrend"
                        value={state.CountryImportValueTrend}
                        onChange={handleValueChange}
                      >
                        <MenuItem value="all">全部</MenuItem>
                        {(effected_country_import.ValueTrade || []).map((option, index) => (
                          <MenuItem
                            key={`${option.Name + index}`}
                            value={option.Code}
                          >
                            {option.Name} ( {option.Code} )
                          </MenuItem>
                        ))}
                      </StyledSelect>
                    </FormControls>
                    <FormControls variant="outlined">
                      <StyledSelect
                        size="small"
                        id="demo-simple-trend"
                        name="CountryImportValueTrendCountry"
                        value={state.CountryImportValueTrendCountry}
                        onChange={handleValueChange}
                      >
                        <MenuItem value="all">全部</MenuItem>
                        {(CountryImportTrendCountryList || []).map((option, index) => (
                          <MenuItem
                            key={`${option.Country + index}`}
                            value={option.Country}
                          >{option.Country}
                          </MenuItem>
                        ))}
                      </StyledSelect>
                    </FormControls>
                  </Box>
                  <LineChart data={CountryImportTrendChartData} Unit="千元" />
                  <DataInfoModal dataFrom="財政部關務署海關進出口統計資料庫<br />單位：新台幣" />
                </ColumnBox>
              </Grid>
            }
            {/* 單一貨品進口額國別排行  */}
            {
              effected_country_import && effected_country_import.CountryRank && effected_country_import.CountryRank.length > 0 &&
              <Grid item xs={12} md={6} lg={4}>
                <ColumnBox colorCustom="#25758A">
                  <Box display="flex" flexWrap="wrap" justifyContent="flex-start" flexDirection="column">
                    <Typography className="subTitle" variant="h5" gutterBottom component="div">單一貨品進口額國別排行</Typography>
                    <FormControls variant="outlined">
                      <StyledSelect
                        size="small"
                        id="demo-simple-trend"
                        name="CountryRankImport"
                        value={state.CountryRankImport}
                        onChange={handleValueChange}
                      >
                        <MenuItem value="all">全部</MenuItem>
                        {(effected_country_import.CountryRank || []).map((option, index) => (
                          <MenuItem
                            key={`${option.Name + index}`}
                            value={option.Code}
                          >
                            {option.Name} ( {option.Code} )
                          </MenuItem>
                        ))}
                      </StyledSelect>
                    </FormControls>
                  </Box>
                  <HorizontalBarChart data={CountryRankImportChartData} keyTarget="TotalValue" indexBy="Country" />
                  <DataInfoModal dataFrom="財政部關務署海關進出口統計資料庫<br />單位：新台幣" />
                </ColumnBox>
              </Grid>
            }
            {/* 相關新聞 */}
            {
              effected_news && effected_news.Datas && effected_news.Datas.length > 0 &&
              <Grid item xs={12} md={6} lg={4}>
                <ColumnBox colorCustom="#25758A" noSpace>
                  <NewsTitle className="subTitle" variant="h5" gutterBottom component="div">相關新聞</NewsTitle>
                  <div>
                    <StyledTableContainer component={Paper}>
                      <Table stickyHeader aria-label="simple table">
                        <TableHead>
                          <StyledTableRowHead>
                            <StyledTableCellHead>新聞標題</StyledTableCellHead>
                            <StyledTableCellHead align="right">時間 /來源</StyledTableCellHead>
                          </StyledTableRowHead>
                        </TableHead>
                        <TableBody>
                          {effected_news.Datas.slice(state.page * state.rowsPerPage, state.page * state.rowsPerPage + state.rowsPerPage).map((row) => (
                            <TableRow key={row.Id}>
                              <StyledTableCellBody component="th" scope="row">
                                <a href={row.Link} target="_blank" rel="noopener noreferrer">{handleNewsIcon(row.Logo)} {row.Title}</a>
                              </StyledTableCellBody>
                              <StyledTableCellBody date align="right">{row.PubDate.split(" ")[0]}</StyledTableCellBody>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </StyledTableContainer>
                    <TablePagination
                      rowsPerPageOptions={[]}
                      component="div"
                      count={effected_news.Datas.length}
                      rowsPerPage={state.rowsPerPage}
                      page={state.page}
                      onChangePage={handleChangePage}
                    />
                  </div>
                  <Box mx={2}>
                    <DataInfoModal dataFrom="聯合新聞網、Yahoo財經新聞、自由電子報、東森新聞電子報、中央社、公視新聞、台視新聞" />
                  </Box>
                </ColumnBox>
              </Grid>
            }
          </Grid>
        </InnerContainer>
      }
    </ThemeProvider>
  )
};

const InnerContainer = styled.div`
  padding: 0 2.5%;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  padding-bottom: 3rem;
  @media (max-width: 575px) {
    padding: 0 16px;
    padding-bottom: 10rem;
  }
`;
const TitleContainer = styled.div`
  margin-top: 36px;
  margin-bottom: 24px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  @media (max-width: 1023px) {
    margin-top: 20px;
  }
`

const TContainer = styled(Link)`
display: flex;
align-items: center;
margin-top: 20px;
margin-bottom: 4px;
color: #666666;
`

const Title = styled.h2`
  font-size: 2.25rem;
  font-weight: 500;
`
const RowBox = styled.div`
  display: flex;
  flex-direction: row;
  // padding: 16px;
  box-shadow: 2px 2px 6px 2px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  height: 200px;
  margin: ${props => props.marginCustom || "0px"};
  background: ${props => props.bgColor || "none"};
  color: ${props => props.colorCustom || "none"};
  padding-left: ${props => props.noSpace ? "0px" : "16px"};
  padding-right: ${props => props.noSpace ? "0px" : "16px"};
  .subTitle {
    font-weight: bold !important;
  }
  p {
    line-height: 1.5;
  }
  .unit {
    margin-left: 8px;
  }
`;
const ColumnBox = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  box-shadow: 2px 2px 6px 2px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  height: 100%;
  margin: ${props => props.marginCustom || "0px"};
  background: ${props => props.bgColor || "none"};
  color: ${props => props.colorCustom || "none"};
  padding-left: ${props => props.noSpace ? "0px" : "16px"};
  padding-right: ${props => props.noSpace ? "0px" : "16px"};
  .subTitle {
    font-weight: bold !important;
  }
  p {
    line-height: 1.5;
  }
  .unit {
    margin-left: 8px;
  }
`;
const NewsTitle = styled(Typography)`
  font-weight: bold !important;
  padding-left: 16px;
  padding-right: 16px;
`
const CommentBox = styled.div`
  width: 70%;
  margin-left: 12px;
  margin-top: -24px;
  height: calc(100% + 24px);
  @media (max-width: 1285px) {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
`;
const ImgBox = styled.div`
  width: 20%;
  height: 250px;
  display: block;
  margin-left: 12px;
  margin-top: -36px;
  height: calc(100% + 24px);
  transform: translateX(16px);
  img {
    width: 100%;
    display: block;
    max-width: 100%;
  }
  @media (max-width: 1285px) {
    display: none;
  }
`;
const CountNumber = styled.h2`
  font-size: 2rem;
  font-weight: 700;
  @media (max-width: 1285px) {
    font-size: 2rem;
  }
`
const SwiperContainer = styled(Box)`
  width: 90%;
  margin: 0 auto;
  position: relative;
  display: flex;
  align-Items: center;
  .arrow-left {
    position: absolute;
    left: 0px;
    top: 55%;
    transform: translate(-100%, -100%);
  }
  .arrow-right {
    position: absolute;
    right: 0px;
    top: 55%;
    transform: translate(100%, -100%);
  }
  &.h-100, .h-100 {
    height: 100%;
  }
`;
const FormControls = styled(FormControl)`
  height: 40px;
  margin-left: 4px !important;
  > label {
    transform: translate(15px, 12px);
  }
  .MuiSelect-outlined.MuiSelect-outlined {
    padding: 4px 8px;
  }
  .MuiFormLabel-root.Mui-focused {
    color: ${theme.primaryColor} !important;
  }
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${theme.primaryColor} !important;
  }
  @media (max-width: 576px) {
    &.title-select {
      margin-top: 18px !important;
    }
  }
`;
const MainStyledSelect = styled(Select)`
  width: 240px;
  max-height: 40px;
  padding: 8px;
`;
const StyledSelect = styled(Select)`
  width: 160px;
  max-height: 40px;
  padding: 8px;
`;

const LoadingWrapper = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 40px;
  @media (max-width: 750px) {
    border-radius: 0px;
  }
`;

const StyledTableContainer = styled(TableContainer)`
  border-radius: 0px !important
`;
const StyledTableRowHead = styled(TableRow)`
`;
const StyledTableCellHead = styled(TableCell)`
  color: white !important;
  background: #25758A !important;
  padding: 12px !important;
  white-space: nowrap;
`;
const StyledTableCellBody = styled(TableCell)`
  padding: 12px !important;
  background: #f7f3f3 !important;
  white-space: ${props => props.date ? "nowrap" : "initial"};
  a {
    color: black;
    display: flex;
    align-items: center;
    &:hover {
      color: #25758A;
    }
  }
  .news-image {
    width: 28px;
    padding-right: 4px;
  }
`;

const InfoBox = styled(Box)`
  cursor: pointer;
  &:hover {
    opacity: 0.75;
  }
`;

const DetailButton = styled(Button)`
  align-self: flex-end;
  color: white !important;
  margin-top: auto !important;
  background-color: ${theme.primaryColor} !important;
  &:hover {
    opacity: 0.85;
  }
`;

const ProSuggestionDescription = styled.p`
  >strong{
    font-weight: 600;
  }
`

export default CustomizedIndustryDashboard;
