import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import Icon from "@material-ui/core/Icon";

import "./styles.scss";

const FactoryRegisteredInfo = ({ data, headerList }) => {
  const [state, setState] = React.useState({
    endIndex: 0,
    page: 0,
    rowsPerPage: 5,
  });
  const url = window.location.origin;
  let content;
  React.useEffect(() => {
    if (Math.ceil(data.length) === state.page) {
      if (data.length % 5 === 0) {
        setState((state) => ({
          ...state,
          endIndex: state.page * state.rowsPerPage + state.rowsPerPage,
        }));
      } else {
        setState((state) => ({
          ...state,
          endIndex: state.page * state.rowsPerPage + (data.length % 5),
        }));
      }
    } else {
      setState((state) => ({
        ...state,
        endIndex: state.page * state.rowsPerPage + state.rowsPerPage,
      }));
    }
  }, [data.length, state.page]);

  if (data.length > 5) {
    content = data
      .slice(state.page * 5, state.page * 5 + 5)
      .map((row, index) => (
        <TableRow
          key={row.BusinessNo+ index}
          hover
          className="table-body-row factory-registered-table"
          style={index % 2 !== 0 ? { backgroundColor: "#ececec" } : {}}
        >
          <TableCell align="left" style={{ width: "10%" }}>
            {row.FactoryName}
          </TableCell>
          <TableCell align="left" style={{ width: "10%" }}>
            {row.FactoryNo}
          </TableCell>
          <TableCell align="left" style={{ width: "20%" }}>
            <p style={{ display: "flex", alignItems: "center" }}>
              {row.FactoryAddress ? row.FactoryAddress : "無相關資料"}
              {row.InduParkStr ? "(" + row.InduParkStr + ")" : ""}
              <Icon
                onClick={() => { row.latitude && row.longitude ? 
                  window.open(
                    `https://maps.google.com/?q=${row.latitude},${row.longitude}`,
                    "_blank"
                  ) :
                  window.open(
                    `https://maps.google.com/?q=${row.FactoryAddress}`,
                    "_blank"
                  );
                }}
                style={{ cursor: "pointer", marginLeft: "20px" }}
              >
                directions
              </Icon>
            </p>
          </TableCell>
          <TableCell align="left" style={{ width: "5%" }}>
            {row.FactoryBoss ? row.FactoryBoss : "無相關資料"}
          </TableCell>
          <TableCell align="left" style={{ width: "15%" }}>
            {row.InduTypeTwo ? row.InduTypeTwo : "無產業別二碼登記"}
          </TableCell>
          <TableCell align="left" style={{ width: "15%" }}>
            {row.InduTypeFour ? row.InduTypeFour : "無產業別四碼登記"}
          </TableCell>
          <TableCell align="left" style={{ width: "15%" }}>
            {row.FactoryMainProduct ? row.FactoryMainProduct : "無相關資料"}
          </TableCell>
        </TableRow>
      ));
  } else {
    content = data.map((row, index) => (
      <TableRow
        key={row.BusinessNo + index}
        hover
        className="table-body-row factory-registered-table"
        style={index % 2 !== 0 ? { backgroundColor: "#ececec" } : {}}
      >
        <TableCell align="left" style={{ width: "10%" }}>
          {row.FactoryName}
        </TableCell>
        <TableCell align="left" style={{ width: "10%" }}>
          {row.FactoryNo}
        </TableCell>
        <TableCell align="left" style={{ width: "20%" }}>
          <p style={{ display: "flex", alignItems: "center" }}>
            {row.FactoryAddress ? row.FactoryAddress : "無相關資料"}
            {row.InduParkStr ? "(" + row.InduParkStr + ")" : ""}
            <Icon
                onClick={() => { row.latitude && row.longitude ? 
                  window.open(
                    `https://maps.google.com/?q=${row.latitude},${row.longitude}`,
                    "_blank"
                  ) :
                  window.open(
                    `https://maps.google.com/?q=${row.FactoryAddress}`,
                    "_blank"
                  );
                }}
              style={{ cursor: "pointer", marginLeft: "20px" }}
            >
              directions
            </Icon>
          </p>
        </TableCell>
        <TableCell align="left" style={{ width: "5%" }}>
          {row.FactoryBoss ? row.FactoryBoss : "無相關資料"}
        </TableCell>
        <TableCell align="left" style={{ width: "15%" }}>
          {row.InduTypeTwo ? row.InduTypeTwo : "無產業別二碼登記"}
        </TableCell>
        <TableCell align="left" style={{ width: "15%" }}>
          {row.InduTypeFour ? row.InduTypeFour : "無產業別四碼登記"}
        </TableCell>
        <TableCell align="left" style={{ width: "15%" }}>
          {row.FactoryMainProduct ? row.FactoryMainProduct : "無相關資料"}
        </TableCell>
      </TableRow>
    ));
  }

  const handleChangePage = (event, newPage) => {
    setState((state) => ({ ...state, page: newPage }));
  };

  const openNewTabs = (link) => window.open(link, "_blank");

  return (
    <TableContainer className="table-container">
      <Table className="table">
        <TableHead className="table-header">
          {headerList && (
            <TableRow className="table-row">
              {headerList.map((headerItem) => (
                <TableCell
                  key={headerItem}
                  className="table-cell"
                  align="left"
                  style={{ fontWeight: 600 }}
                >
                  {headerItem}
                </TableCell>
              ))}
            </TableRow>
          )}
        </TableHead>
        <TableBody className="table-body">
          {data.length !== 0 && content}
        </TableBody>
      </Table>
      {data.length === 0 && (
        <Table>
          <TableBody className="table-body-no-data">
            <TableRow hover className="table-body-row">
              <TableCell align="center" className="no-data">
                本項目無資料
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      )}
      {data.length > 5 && (
        <TablePagination
          rowsPerPageOptions={[]}
          component="div"
          count={data.length}
          rowsPerPage={state.rowsPerPage}
          page={state.page}
          onChangePage={handleChangePage}
          // onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      )}
    </TableContainer>
  );
};

export default React.memo(FactoryRegisteredInfo);
