import React from "react";
import { Popup, Marker } from "react-leaflet";

export default (props) => {
  const markerRef = React.useRef(null);
  const popUpRef = React.useRef(null);

  const {
    FactoryName,
    FactoryAddr,
    MonitoringType,
    BusineesNo,
    Latitude,
    Longitude,
    Uid,
  } = props.data;
  const {
    updateMarkersRef,
    showedCompanyHandler,
    toggleDetailCard,
    closeDetialCard,
    savePopUpRef,
  } = props;
  const url = window.location.origin;

  const goToFactorySearch = () => {
    if (BusineesNo) {
      window.open(`${url}/factory_info/basic_info/${BusineesNo}`);
    } else {
      return;
    }
  };

  const openDetailCard = (uid) => {
    toggleDetailCard();
  };

  React.useEffect(() => {
    updateMarkersRef(Uid, markerRef);
  }, [markerRef]);

  React.useEffect(() => {
    if (popUpRef) savePopUpRef(Uid, popUpRef);
  }, [popUpRef]);

  return (
    <Marker
      position={[Latitude, Longitude]}
      key={Uid}
      onClick={() => showedCompanyHandler(Uid)}
    >
      <Popup onClose={closeDetialCard} autoPan={false}>
        <div className="material-search-popup">
          <h4>
            工廠名稱:
            <span
              onClick={goToFactorySearch}
              className={
                BusineesNo ? "with-business-no" : "with-out-business-no"
              }
            >
              {" " + FactoryName}
            </span>
          </h4>
          <p>
            <span>工廠地址:</span> {" " + FactoryAddr}
          </p>
          <p>
            <span>列管物分類:</span> {" " + MonitoringType}
          </p>
          <div>
            <p className="more-info" onClick={() => openDetailCard(Uid)}>
              更多資訊
            </p>
          </div>
        </div>
      </Popup>
    </Marker>
  );
};
