import React from "react";

import Spinner from "../Spinner/Spinner";
import "./styles.scss";

const CiyDashboardBriefInfo = (props) => {
  const {
    selectedCityName,
    tpyeTwo,
    topTenCapital,
    publicStock,
    govPlanPercentage,
    topTenPatent,
    award,
  } = props;
  const [state, setState] = React.useState({
    numberOfPublic: 0,
    numberOfNotPublic: 0,
    percentage: 0,
    govPlanPercentageCount: 0,
  });

  React.useEffect(() => {
    if (govPlanPercentage.length > 0) {
      let total = 0;
      let part = 0;
      govPlanPercentage.map((plan, index) => {
        if (index === 0 || index === 1) {
          part += plan.y;
        }

        total += plan.y;
      });
      setState((state) => ({
        ...state,
        govPlanPercentageCount: parseInt((part / total) * 100),
      }));
    }
  }, [govPlanPercentage]);

  console.log(props);

  React.useEffect(() => {
    let total = 0;
    let PublicTotal = 0;
    let NotPublicTotal = 0;
    if (publicStock.publicStockDataMap.length > 0) {
      publicStock.publicStockDataMap.map((item) => {
        total += item.y;
        switch (item.label) {
          case "上市":
            PublicTotal += item.y;
            break;
          case "上櫃":
            PublicTotal += item.y;
            break;
          case "興櫃":
            PublicTotal += item.y;
            break;
          case "公開發行":
            PublicTotal += item.y;
            break;
          case "未上市":
            NotPublicTotal += item.y;
            break;
          default:
            return "";
        }
      });
    }
    setState((state) => ({
      ...state,
      numberOfPublic: PublicTotal,
      numberOfNotPublic: NotPublicTotal,
      percentage: parseInt((PublicTotal / total) * 100),
    }));
  }, [publicStock]);

  if (
    Object.entries(tpyeTwo)[1] &&
    Object.entries(govPlanPercentage)[1] &&
    Object.entries(topTenPatent)[1]
  ) {
    return (
      <div className="city-dashboard-brief-info">
        <p>
          {selectedCityName}製造業廠商分佈前五大產業以
          {tpyeTwo[0]?.label.slice(0, -1)}、{tpyeTwo[1]?.label.slice(0, -1)}、
          {tpyeTwo[2]?.label.slice(0, -1)}、{tpyeTwo[3]?.label.slice(0, -1)}、
          {tpyeTwo[4]?.label.slice(0, -1)}為主，其中有上市櫃公司
          {state.numberOfPublic}家(佔總體{state.percentage}%)，未上市櫃公司
          {state.numberOfNotPublic}家；資本額前三大設廠公司有{topTenCapital[0]}
          、{topTenCapital[1]}、{topTenCapital[2]} ... 等。
        </p>
        <p>
          {selectedCityName}廠商主要執行過的政府計畫為
          {govPlanPercentage.govPlanPercentageDataLabels[0]}(
          {govPlanPercentage.govPlanPercentageData[0]}件/次)、
          {govPlanPercentage.govPlanPercentageDataLabels[1]}(
          {govPlanPercentage.govPlanPercentageData[1]}件/次)、
          {govPlanPercentage.govPlanPercentageDataLabels[2]}(
          {govPlanPercentage.govPlanPercentageData[2]}件/次) ... 等。
          而近三年專利數量前三大公司為{topTenPatent[0]?.label}(
          {topTenPatent[0]?.y}件)、
          {topTenPatent[1]?.label}(
          {topTenPatent[1]?.y}件) 、
          {topTenPatent[2]?.label}(
          {topTenPatent[2]?.y}件) ，
          且近十年內廠商曾獲得{award[0]}
          、{award[1]}、{award[2]} ... 等。
        </p>
      </div>
    );
  } else {
    return <Spinner />;
  }
};

export default React.memo(CiyDashboardBriefInfo);
