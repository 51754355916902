import React from 'react';
import styled from "styled-components";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import Box from '@material-ui/core/Box';
import { thousandDivider, parseUrl } from '../../utility/common';
import google_patents_logo from "../../assets/images/google_patents_logo.png";

// columns=[{name: "公司", key: "CompanyName"}]
// mapKey: map key, ex: BusinessNo
const TableComponent = ({ title, data, columns, mapKey }) => {
  if (data?.length === 0) {
    return <Box display="flex" justifyContent="center">
      <NoDataBadge>近期無變動</NoDataBadge>
    </Box>
  }
  return (
    <TableContainer component={Paper} style={{
      boxShadow: "none",
    }}>
      <Table aria-label="simple table" size="small" >
        <TableHead>
          <TableRow>
            {
              columns.map((column, index) => (
                <StyledTableCell th="true" key={`${title}_${column.key}_${index}`}>{column.name}</StyledTableCell>
              ))
            }
          </TableRow>
        </TableHead>
        <TableBody>
          {data && data?.map((row, index) => {
            return (
              <TableRow key={`${title}_${row?.[mapKey]}_${index}`}>
                {
                  columns.map((column) => {
                    if (column.key === "CompanyName") {
                      return (
                        <StyledTableCell td="true">
                          <a href={`/factory_info/basic_info/${row[mapKey]}`} target="_blank" rel="noopener noreferrer">
                            {row[column.key]}
                          </a>
                        </StyledTableCell>
                      )
                    } else if (column.key === "Date") {
                      return (
                        <StyledTableCell td="true">
                          {new Date(row[column.key]).toLocaleDateString("sv-SE")}
                        </StyledTableCell>
                      )
                    } else if (column.key === "ChineseName") {
                      return (
                        <StyledTableCell td="true">
                          <Box display={"flex"}
                          alignItems={{
                            xs: "flex-start",
                            md: "center",
                          }}
                          flexDirection={{
                            xs: "column",
                            md: "row",
                          }}>
                            <span style={{ paddingRight: "8px"}}>{row[column.key]}</span>
                            <Tooltip title="點擊觀看專利資訊詳細頁面">
                              <a href={parseUrl(row.DocNo)} target="_blank" rel="noopener noreferrer">
                                <div style={{ width: "60px" }}>
                                  <img alt="專利資料" src={google_patents_logo} style={{ maxWidth: "100%"}} />
                                </div>
                              </a>
                            </Tooltip>
                          </Box>
                        </StyledTableCell>
                      )
                    } else if (column.key === "Revenue_Net") {
                      return (
                        <StyledTableCell td="true">
                          { thousandDivider(row[column.key]) }
                        </StyledTableCell>
                      )
                    } else if (column.key === "Year_ROC") {
                      return (
                        <StyledTableCell td="true">
                          { row[column.key] }/{row["Month"]} 
                        </StyledTableCell>
                      )
                    } else {
                      return (
                        <StyledTableCell td="true" style={{
                          maxWidth: 150,
                        }}>{row[column.key]}</StyledTableCell>
                      )
                    }
                  })
                }
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

const StyledTableCell = styled(TableCell)`
  && {
    padding: 6px 8px;
    border-bottom: 0px;
    color: ${props => props.th === "true"? "#00000099" : "#3E5A75"};
    font-size: ${props => props.th === "true"? "0.75rem" : "1rem"};
    white-space: ${props => props.th === "true"? "nowrap" : "initial"};
    a {
      text-decoration: underline;
      color: ${props => props.th === "true"? "#00000099" : "#3E5A75"};
      &:hover {
        opacity: 0.8;
      }
    }
  }
`

const NoDataBadge = styled.p`
  background: #E6EDF6;
  padding: 4px 8px;
  display: inline-block;
  border-radius: 24px;
  font-size: 0.875rem;
  color: #033a63;
  margin-bottom: 8px;
  marin-top: 24px;
`
export default TableComponent;