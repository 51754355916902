import React from "react";
import { Link } from "react-router-dom";
import { Box, Grid } from "@material-ui/core";
import styled from "styled-components";
import Breadcrumb from "../../components/Breadcrumbs";
import sitemapBg from "../../assests/images/subscribe-bg.png";
import * as theme from "../../assets/theme";

const SitemapPush = () => {
  return (
    <SiteMapWrapper id="sitemap">
      <div class="top-breadcrumb">
        <Breadcrumb
          route={[
            {
              name: "網站導覽",
            },
          ]}
        />
      </div>
      <div
        className="sitemap-bg"
        style={{
          backgroundImage: `url(${sitemapBg})`,
        }}
      >
        <SiteMapContainer
          style={{
            marginTop: "24px",
          }}
        >
          <StyledTitle>網站導覽</StyledTitle>
          <Grid container spacing={3}>
            <CardWrapper item xs={12} sm={6} md={4}>
              <CardBox>
                <h2>
                  <TitleLink to="/portal">關於平台</TitleLink>
                </h2>
              </CardBox>
              <CardBox>
                <h2>
                  <TitleLink to="/park_dashboard/HIP015">儀表板</TitleLink>
                </h2>
                <Box display={"flex"} flexDirection={"column"}>
                  {[
                    { title: "園區儀表板", link: "/park_dashboard/HIP015" },
                    { title: "分區儀表板", link: "/area_dashboard/North" },
                    { title: "縣市儀表板", link: "/city_dashboard/15" },
                    { title: "產業儀表板", link: "/industry_index/1810" },
                    {
                      title: "自訂關注廠商儀表板",
                      link: "/custom_dashboard",
                    },
                    { title: "受事件影響儀表板", link: "/event_dashboard" },
                    {
                      title: "自訂關注產業儀表板",
                      link: "/custom_industry_dashboard",
                    },
                  ].map((item) => {
                    return (
                      <StyledLink
                        key={item.title}
                        to={item.link}
                        title={`另開新視窗，前往${item.title}頁面`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >{`> ${item.title}`}</StyledLink>
                    );
                  })}
                </Box>
              </CardBox>
              <CardBox>
                <h2>
                  <TitleLink to="/map-search">技術快搜</TitleLink>
                </h2>
                <Box display={"flex"} flexDirection={"column"}>
                  {[
                    { title: "技術快搜", link: "/map-search" },
                    { title: "廢棄物快搜", link: "/wasted_landing_page" },
                    { title: "產業鏈快搜", link: "/supply-chain" },
                  ].map((item) => {
                    return (
                      <StyledLink
                        key={item.title}
                        to={item.link}
                        title={`另開新視窗，前往${item.title}頁面`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >{`> ${item.title}`}</StyledLink>
                    );
                  })}
                </Box>
              </CardBox>
            </CardWrapper>
            <CardWrapper item xs={12} sm={6} md={4}>
              <CardBox>
                <h2>
                  <TitleLink to="/factory_search">個廠情資</TitleLink>
                </h2>
              </CardBox>
              <CardBox>
                <h2>
                  <TitleLink to="/gov_plan_display">政府資源</TitleLink>
                </h2>
              </CardBox>
              <CardBox flexGrow={2}>
                <h2>
                  <TitleLink to="/coporaiton_tec">關注訂閱</TitleLink>
                </h2>
                <Box display={"flex"} flexDirection={"column"}>
                  {[
                    { title: "新聞訂閱", link: "/coporaiton_tec" },
                    { title: "關注廠商 / 產業訂閱", link: "/subscribe_push" },
                  ].map((item) => {
                    return (
                      <StyledLink
                        key={item.title}
                        to={item.link}
                        target="_blank"
                        title={`另開新視窗，前往${item.title}頁面`}
                        rel="noopener noreferrer"
                      >{`> ${item.title}`}</StyledLink>
                    );
                  })}
                </Box>
              </CardBox>
            </CardWrapper>
            <CardWrapper item xs={12} sm={6} md={4}>
              <CardBox>
                <h2>
                  <TitleLink to="/client-response/query">廠商需求</TitleLink>
                </h2>
              </CardBox>
              {/* <CardBox>
              <TitleLink to="/questionnaire-management">訪廠問卷</TitleLink>
            </CardBox> */}
              <CardBox flexGrow={2}>
                <Title>其他功能</Title>
                <Box display={"flex"} flexDirection={"column"}>
                  {[
                    { title: "自訂廠商資料", link: "/updated_company" },
                    {
                      title: "意見回饋",
                      link: "https://docs.google.com/forms/d/e/1FAIpQLScJiG5ROXmHBZnExjxvx5cY5b4tEYz36YkqsaGPiuj1b3TYCw/viewform",
                    },
                    {
                      title: "操作指南",
                      link: "https://smartpark-guideline.gitbook.io/smartpark_guideline/",
                    },
                  ].map((item) => {
                    return (
                      <StyledLink
                        key={item.title}
                        to={
                          item.title !== "自訂廠商資料"
                            ? { pathname: item.link }
                            : item.link
                        }
                        title={`另開新視窗，前往${item.title}頁面`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >{`> ${item.title}`}</StyledLink>
                    );
                  })}
                </Box>
              </CardBox>
            </CardWrapper>
          </Grid>
        </SiteMapContainer>
      </div>
    </SiteMapWrapper>
  );
};

const StyledTitle = styled.h1`
  font-size: 1.75rem;
  font-weight: 700;
  color: #11304a;
  margin-bottom: 24px;
`;

const CardWrapper = styled(Grid)`
  display: flex;
  flex-direction: column;
`;

const CardBox = styled(Box)`
  background-color: white;
  border-radius: 8px;
  margin-bottom: 24px;
  padding: 16px 24px;
  flex-grow: ${(props) => props.flexGrow || 0};
`;
const Title = styled.h2`
  color: ${theme.lightPrimaryColor};
  font-size: 1.125rem;
  font-weight: 700;
`;
const TitleLink = styled(Link)`
  color: ${theme.lightPrimaryColor};
  font-size: 1.125rem;
  font-weight: 700;
  text-decoration: underline;
  &:hover {
    color: ${theme.primaryColor};
  }
`;
const StyledLink = styled(Link)`
  color: ${theme.BlueV2};
  text-decoration: underline;
  margin-bottom: 8px;
  &:first-child {
    margin-top: 8px;
  }
  &:hover {
    color: ${theme.BlueV2};
    text-decoration: underline;
    opacity: 0.8;
  }
`;

const SiteMapWrapper = styled.div`
  position: relative;
  line-height: 1.5;
  height: 100vh;
  min-height: 50vh;
  overflow-y: scroll;
  overflow-x: hidden;
  width: 100%;
  .top-breadcrumb {
    position: absolute;
    width: 100%;
    top: 40px;
    z-index: 2;
    @media (max-width: 1024px) {
      top: 0px;
    }
  }
  .sitemap-bg {
    min-height: 100vh;
    position: relative;
    padding-top: 96px;
    @media (max-width: 959px) {
      padding-top: 32px;
      padding-bottom: 220px;
    }
    padding-bottom: 96px;
    position: relative;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
`;

const SiteMapContainer = styled.div`
  max-width: 540px;
  margin: 0 auto;
  padding: 0px 12px;
  @media (min-width: 576px) {
    max-width: 540px;
    margin: 0 auto;
  }
  @media (min-width: 768px) {
    max-width: 720px !important;
  }
  @media (min-width: 992px) {
    max-width: 960px !important;
  }
  @media (min-width: 1200px) {
    max-width: 1140px !important;
  }
  @media (min-width: 1400px) {
    max-width: 1320px !important;
  }
`;

export default SitemapPush;
