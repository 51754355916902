import axios from "axios";
import { baseUrl, loginbaseUrl } from "../../constants/endpoints";

const headers =  {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,HEAD,OPTIONS,POST,PUT',
    'Access-Control-Allow-Headers': 'Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers',
    'Content-Type': 'application/json',
    'Authorization': localStorage.getItem('acToken')
  }

export const getControlledMaterialDetail = CrlTd => {
    return axios({
      proxy: true,
      method: "get",
      url:`${baseUrl}/api/Waste/GetPollutions/${CrlTd}`,
      headers: headers
    })
      .then(res => {
        return res.data.data;
      })
      .catch(err => {
        return "error";
      });
};

export const getControlledMaterialOptions = CrlType => {
  return axios({
    proxy: true,
    method: "get",
    url:`${baseUrl}/api/Waste/GetPollutionsList/${CrlType}`,
    headers: headers
  })
    .then(res => {
      return res.data.data;
    })
    .catch(err => {
      return "error";
    });
};