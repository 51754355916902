export const START_FETCH_WEATHER_BOX_DATA = `START_FETCH_WEATHER_BOX_DATA`;
export const FETCH_WEATHER_BOX_DATA_SUCCESS = `FETCH_WEATHER_BOX_DATA_SUCCESS`;
export const FETCH_WEATHER_BOX_DATA_FAIL = `FETCH_WEATHER_BOX_DATA_FAIL`;

export const START_FETCH_WEATHER_INFO = `START_FETCH_WEATHER_INFO`;
export const FETCH_WEATHER_INFO_SUCCESS = `FETCH_WEATHER_INFO_SUCCESS`;
export const FETCH_WEATHER_INFO_FAIL = `FETCH_WEATHER_INFO_FAIL`;

export const START_FETCH_AIR_QUALITY = `START_FETCH_AIR_QUALITY`;
export const FETCH_AIR_QUALITY_SUCCESS = `FETCH_AIR_QUALITY_SUCCESS`;
export const FETCH_AIR_QUALITY_FAIL = `FETCH_AIR_QUALITY_FAIL`;

