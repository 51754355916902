import React from 'react';
import styled from "styled-components";
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';

export default function SimpleBreadcrumbs({ route }) {
  return (
    <BgBox>
      <Breadcrumbs  separator=">" aria-label="breadcrumb">
        <StyledLink color="inherit" href="/portal">
          產業園區廠商情資平台
        </StyledLink>
        {
          route.map((item, index) => {
            if (item?.url) {
              return <StyledLink color="inherit" href={item.url}>{
                item.name
              }</StyledLink>
            }
            return <Typography color="textPrimary">{item.name}</Typography>
          })
        }
      </Breadcrumbs>
    </BgBox>
  );
}

const BgBox = styled.div`
  background-color: rgba(255, 255, 255, 0.3);
  padding: 8px 16px;
  width: 100%;
`;

const StyledLink = styled(Link)`
  :hover {
    color: #25758A !important;
  }
`;
