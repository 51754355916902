import axios from "axios";
import { baseUrl, loginbaseUrl } from "../../constants/endpoints";

const headers =  {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,HEAD,OPTIONS,POST,PUT',
    'Access-Control-Allow-Headers': 'Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers',
    'Content-Type': 'application/json',
    'Authorization': localStorage.getItem('acToken')
  }

export const getControlledMaterialResult = (data) => {
  return axios({
    proxy: true,
    method: "post",
    url:`${baseUrl}/api/Waste/ControlledMaterialResult`,
    headers: headers,
    data:data
  })
    .then(res => {
      return res.data; 
    })
    .catch(err => {
      return "error";
    });
};