import * as actionTypes from "./actionTypes";
import * as actionType from "../actionTypes/customizedDashboard";


import {
    fetchTypeTwoData,
    fetchTypeThreeData,
    fetchTypeFourData,
    fetchPublicStockData,
    fetchNotPublicStockData,
    fetchPublicStockRatingData,
    fetchNoPublicStockRatingData,
    fetchTopTenCapitalData,
    fetchTopGovPlanData,
    fetchTopPatentData,
    fetchImportPercentageData,
    fetchExportPercentageData,
    fetchAwardPercentageData,
    fetchGovPlanListPercentageData,
    fetchPublicStockPercentageData,
    fetchNewsTagsInfo,
    fetchNewsInfo,
    fetchEmployeeData,
    fetchNewsInfoTagData,
    //fetchGreenFactoryData,
    fetchFactoryRanking,
    fetchTypeTwoList,
    fetchTypeThreeList,
    fetchTypeFourList,
    fetchImportList,
    fetchExportList,
    fetchGovPlanList,
    fetchAwardList,
    fetchPatentList,
    fetchPublicDataList,
    fetchTopTenGovPlanDataList,
    fetchBackTaiwanInvestment,
    fetchFactoryAreaRentData
} from "../../utility/customizedDashboardApi";

export const startGetTypeTwoCharData = () => {
    return {
        type: actionTypes.START_GET_TYPE_TWO_CHART_DATA
    }
};

export const getTypeTwoCharDataSuccess = data => {
    return {
        type: actionTypes.GET_TYPE_TWO_CHART_DATA_SUCCESS,
        data: data
    }
};

export const getTypeTwoCharDataFail = err => {
    return {
        type: actionTypes.GET_TYPE_TWO_CHART_DATA_FAIL,
        err: err
    }
};

export const getTypeTwoChartDataHandler = (city) => {
    return dispatch => {
        dispatch(startGetTypeTwoCharData());
        fetchTypeTwoData(city)
            .then(res => {
                if (res.data[0].dataPoints) {
                    dispatch(getTypeTwoCharDataSuccess(res.data[0].dataPoints))
                } else {
                    dispatch(getTypeTwoCharDataFail("取得縣市廠商產業別二碼家數佔比時發生問題。"))
                }
            })
            .catch(err => {
                dispatch(getTypeTwoCharDataFail(err))
            })
    }
}


export const startGetTypeThreeCharData = () => {
    return {
        type: actionTypes.START_GET_TYPE_THREE_CHART_DATA
    }
};

export const getTypeThreeCharDataSuccess = data => {
    return {
        type: actionTypes.GET_TYPE_THREE_CHART_DATA_SUCCESS,
        data: data
    }
};

export const getTypeThreeCharDataFail = err => {
    return {
        type: actionTypes.GET_TYPE_THREE_CHART_DATA_FAIL,
        err: err
    }
};

export const getTypeThreeChartDataHandler = (city) => {
    return dispatch => {
        dispatch(startGetTypeThreeCharData());
        fetchTypeThreeData(city)
            .then(res => {
                if (res.data[0].dataPoints) {
                    dispatch(getTypeThreeCharDataSuccess(res.data[0].dataPoints))
                } else {
                    dispatch(getTypeThreeCharDataFail("取得縣市廠商產業別三碼家數佔比時發生問題。"))
                }
            })
            .catch(err => {
                dispatch(getTypeThreeCharDataFail(err))
            })
    }
}


export const startGetTypeFourCharData = () => {
    return {
        type: actionTypes.START_GET_TYPE_FOUR_CHART_DATA
    }
};

export const getTypeFourCharDataSuccess = data => {
    return {
        type: actionTypes.GET_TYPE_FOUR_CHART_DATA_SUCCESS,
        data: data
    }
};

export const getTypeFourCharDataFail = err => {
    return {
        type: actionTypes.GET_TYPE_FOUR_CHART_DATA_FAIL,
        err: err
    }
};

export const getTypeFourChartDataHandler = (city) => {
    return dispatch => {
        dispatch(startGetTypeFourCharData());
        fetchTypeFourData(city)
            .then(res => {
                if (res.data[0].dataPoints) {
                    dispatch(getTypeFourCharDataSuccess(res.data[0].dataPoints))
                } else {
                    dispatch(getTypeFourCharDataFail("取得縣市廠商產四碼家數佔比時發生問題。"))
                }
            })
            .catch(err => {
                dispatch(getTypeFourCharDataFail(err))
            })
    }
}


export const startGetPublicStockChartData = () => {
    return {
        type: actionTypes.START_GET_PUBLIC_STOCK_CHART_DATA
    }
};

export const getPublicStockChartDataSuccess = data => {
    return {
        type: actionTypes.GET_PUBLIC_STOCK_CHART_DATA_SUCCESS,
        data: data
    }
};

export const getPublicStockChartDataFail = err => {
    return {
        type: actionTypes.GET_PUBLIC_STOCK_CHART_DATA_FAIL,
        err: err
    }
};

export const startGetNotPublicStockChartData = () => {
    return {
        type: actionTypes.START_GET_NOT_PUBLIC_STOCK_CHART_DATA
    }
};

export const getNotPublicStockChartDataSuccess = data => {
    return {
        type: actionTypes.GET_NOT_PUBLIC_STOCK_CHART_DATA_SUCCESS,
        data: data
    }
};

export const getNotPublicStockChartDataFail = err => {
    return {
        type: actionTypes.GET_NOT_PUBLIC_STOCK_CHART_DATA_FAIL,
        err: err
    }
};

export const getPublicStockChartData = (city) => {
    return dispatch => {
        dispatch(startGetPublicStockChartData());
        fetchPublicStockData(city)
            .then(res => {
                if (res.data[0].dataPoints) {
                    dispatch(getPublicStockChartDataSuccess(res.data[0].dataPoints))
                } else {
                    dispatch(getPublicStockChartDataFail("取得縣市股票公開發行類廠商排名時發生問題。"))
                }
            })
            .catch(err => {
                dispatch(getPublicStockChartDataFail(err))
            })
    }
}



export const getNotPublicStockChartData = (city) => {
    return dispatch => {
        dispatch(startGetNotPublicStockChartData());
        fetchNotPublicStockData(city)
            .then(res => {
                if (res.data[0].dataPoints) {
                    dispatch(getNotPublicStockChartDataSuccess(res.data[0].dataPoints))
                } else {
                    dispatch(getPublicStockChartDataFail("取得縣市股票非公開發行類廠商排名時發生問題。"))
                }
            })
            .catch(err => {
                dispatch(getNotPublicStockChartDataFail(err))
            })
    }
}


export const startGetStockRankingData = () => {
    return {
        type: actionTypes.START_GET_STOCK_RANKING_DATA
    }
};

export const getStockRankingDataSuccess = data => {
    return {
        type: actionTypes.GET_STOCK_RANKING_DATA_SUCCESS,
        data: data
    }
};

export const getStockRankingDataFail = err => {
    return {
        type: actionTypes.GET_STOCK_RANKING_DATA_FAIL,
        err: err
    }
};

// export const startGetNotPublicStockRankingData = () => {
//     return {
//         type:actionTypes.START_GET_NOT_PUBLIC_STOCK_RANKING_DATA
//     }
//   };

// export const getNotPublicStockRankingDataSuccess = data => {
//     return {
//         type:actionTypes.GET_NOT_PUBLIC_STOCK_RANKING_DATA_SUCCESS,
//         data:data
//     }
//   };

//   export const getNotPublicStockRankingDataFail = err => {
//     return {
//         type:actionTypes.GET_NOT_PUBLIC_STOCK_RANKING_DATA_FAIL,
//         err:err
//     }
// };

export const getStockRankingData = (city) => {
    return dispatch => {
        dispatch(startGetStockRankingData());
        fetchFactoryRanking(city)
            .then(res => {
                dispatch(getStockRankingDataSuccess(res))
            })
            .catch(err => {
                dispatch(getStockRankingDataFail(err))
            })
    }
}


// export const getNotPublicStockRankingData = (city) => {
//     return dispatch => {
//         dispatch(startGetNotPublicStockRankingData());
//         fetchNoPublicStockRatingData(city)
//             .then(res=>{
//                 dispatch(getNotPublicStockRankingDataSuccess(res))
//             })
//             .catch(err=>{
//                 dispatch(getNotPublicStockRankingDataFail(err))
//             })
//     }
// }  


export const startGetTopTenCapitalData = () => {
    return {
        type: actionTypes.START_GET_TOP_TEN_CAPITAL_DATA
    }
};

export const getTopTenCapitalDataSuccess = data => {
    return {
        type: actionTypes.GET_TOP_TEN_CAPITAL_DATA_SUCCESS,
        data: data
    }
};

export const getTopTenCapitalDataFail = err => {
    return {
        type: actionTypes.GET_TOP_TEN_CAPITAL_DATA_FAIL,
        err: err
    }
};

export const getTopTenCapitalDataData = (city) => {
    return dispatch => {
        dispatch(startGetTopTenCapitalData());
        fetchTopTenCapitalData(city)
            .then(res => {
                if (res.data[0].dataPoints) {
                    dispatch(getTopTenCapitalDataSuccess(res.data[0].dataPoints))
                } else {
                    dispatch(getTopTenCapitalDataFail("取得縣市園區廠商資本額前十大排行時發生問題。"))
                }
            })
            .catch(err => {
                dispatch(getTopTenCapitalDataFail(err))
            })
    }
}


export const startGetTopTenGovPlanData = () => {
    return {
        type: actionTypes.START_GET_TOP_TEN_GOVPLAN_DATA
    }
};

export const getTopTenGovPlanDataSuccess = data => {
    return {
        type: actionTypes.GET_TOP_TEN_GOVPLAN_DATA_SUCCESS,
        data: data
    }
};

export const getTopTenGovPlanDataFail = err => {
    return {
        type: actionTypes.GET_TOP_TEN_GOVPLAN_DATA_FAIL,
        err: err
    }
};

export const getTopTenGovPlanData = (city) => {
    return dispatch => {
        dispatch(startGetTopTenGovPlanData());
        fetchTopGovPlanData(city)
            .then(res => {
                if (res.data[0].dataPoints) {
                    dispatch(getTopTenGovPlanDataSuccess(res.data[0].dataPoints))
                } else {
                    dispatch(getTopTenGovPlanDataFail("取得縣市園區廠商政府計畫類型佔比時發生問題。"))
                }
            })
            .catch(err => {
                dispatch(getTopTenGovPlanDataFail(err))
            })
    }
}


export const startGetTopTenPatentData = () => {
    return {
        type: actionTypes.START_GET_TOP_TEN_PATENT_DATA
    }
};

export const getTopTenPatentDataSuccess = data => {
    return {
        type: actionTypes.GET_TOP_TEN_PATENT_DATA_SUCCESS,
        data: data
    }
};

export const getTopTenPatentDataFail = err => {
    return {
        type: actionTypes.GET_TOP_TEN_PATENT_DATA_FAIL,
        err: err
    }
};

export const getTopTenPatentData = (city) => {
    return dispatch => {
        dispatch(startGetTopTenPatentData());
        fetchTopPatentData(city)
            .then(res => {
                if (res.data[0].dataPoints) {
                    dispatch(getTopTenPatentDataSuccess(res.data[0].dataPoints))
                } else {
                    dispatch(getTopTenPatentDataFail("取得縣市園區廠商近年專利公告數前十大排行時發生問題。"))
                }
            })
            .catch(err => {
                dispatch(getTopTenPatentDataFail(err))
            })
    }
}


export const startGetImportPercentageData = () => {
    return {
        type: actionTypes.START_GET_IMPORT_PERCENTAGE_DATA
    }
};

export const getImportPercentageDataSuccess = data => {
    return {
        type: actionTypes.GET_IMPORT_PERCENTAGE_DATA_SUCCESS,
        data: data
    }
};

export const getImportPercentageDataFail = err => {
    return {
        type: actionTypes.GET_IMPORT_PERCENTAGE_DATA_FAIL,
        err: err
    }
};

export const getImportPercentageData = (city) => {
    return dispatch => {
        dispatch(startGetImportPercentageData());
        fetchImportPercentageData(city)
            .then(res => {
                if (res.data[0].dataPoints) {
                    dispatch(getImportPercentageDataSuccess(res.data[0].dataPoints))
                } else {
                    dispatch(getImportPercentageDataFail("取得縣市園區廠商近年專利公告數前十大排行時發生問題。"))
                }
            })
            .catch(err => {
                dispatch(getImportPercentageDataFail(err))
            })
    }
}



export const startGetExportPercentageData = () => {
    return {
        type: actionTypes.START_GET_EXPORT_PERCENTAGE_DATA
    }
};

export const getExportPercentageDataSuccess = data => {
    return {
        type: actionTypes.GET_EXPORT_PERCENTAGE_DATA_SUCCESS,
        data: data
    }
};

export const getExportPercentageDataFail = err => {
    return {
        type: actionTypes.GET_EXPORT_PERCENTAGE_DATA_FAIL,
        err: err
    }
};

export const getExportPercentageData = (city) => {
    return dispatch => {
        dispatch(startGetExportPercentageData());
        fetchExportPercentageData(city)
            .then(res => {
                if (res.data[0].dataPoints) {
                    dispatch(getExportPercentageDataSuccess(res.data[0].dataPoints))
                } else {
                    dispatch(getExportPercentageDataFail("取得縣市園區廠商近年專利公告數前十大排行時發生問題。"))
                }
            })
            .catch(err => {
                dispatch(getExportPercentageDataFail(err))
            })
    }
}


export const startGetAwardPercentageData = () => {
    return {
        type: actionTypes.START_GET_AWARD_PERCENTAGE_DATA
    }
};

export const getAwardPercentageDataSuccess = data => {
    return {
        type: actionTypes.GET_AWARD_PERCENTAGE_DATA_SUCCESS,
        data: data
    }
};

export const getAwardPercentageDataFail = err => {
    return {
        type: actionTypes.GET_AWARD_PERCENTAGE_DATA_FAIL,
        err: err
    }
};


export const getAwardPercentageData = (city) => {
    return dispatch => {
        dispatch(startGetAwardPercentageData());
        fetchAwardPercentageData(city)
            .then(res => {
                if (res.data[0].dataPoints) {
                    dispatch(getAwardPercentageDataSuccess(res.data[0].dataPoints))
                } else {
                    dispatch(getAwardPercentageDataFail("取得縣市園區廠商獲獎類型佔比時發生問題。"))
                }
            })
            .catch(err => {
                dispatch(getAwardPercentageDataFail(err))
            })
    }
}


export const startGetGovPlanPercentageData = () => {
    return {
        type: actionTypes.START_GET_GOV_PLAN_PERCENTAGE_DATA
    }
};

export const getGovPlanPercentageDataSuccess = data => {
    return {
        type: actionTypes.GET_GOV_PLAN_PERCENTAGE_DATA_SUCCESS,
        data: data
    }
};

export const getGovPlanPercentageDataFail = err => {
    return {
        type: actionTypes.GET_GOV_PLAN_PERCENTAGE_DATA_FAIL,
        err: err
    }
};


export const getGovPlanPercentageData = (city) => {
    return dispatch => {
        dispatch(startGetGovPlanPercentageData());
        fetchGovPlanListPercentageData(city)
            .then(res => {
                if (res.data[0].dataPoints) {
                    dispatch(getGovPlanPercentageDataSuccess(res.data[0].dataPoints))
                } else {
                    dispatch(getGovPlanPercentageDataFail("取得縣市園區廠商獲獎類型佔比時發生問題。"))
                }
            })
            .catch(err => {
                dispatch(getGovPlanPercentageDataFail(err))
            })
    }
}

export const startGetPublicStockPercentageData = () => {
    return {
        type: actionTypes.START_GET_PUBLIC_STOCK_PERCENTAGE_DATA
    }
};

export const getPublicStockPercentageDataSuccess = data => {
    return {
        type: actionTypes.GET_PUBLIC_STOCK_PERCENTAGE_DATA_SUCCESS,
        data: data
    }
};

export const getPublicStockPercentageDataFail = err => {
    return {
        type: actionTypes.GET_PUBLIC_STOCK_PERCENTAGE_DATA_FAIL,
        err: err
    }
};

export const getPublicStockPercentageData = (city) => {
    return dispatch => {
        dispatch(startGetPublicStockPercentageData());
        fetchPublicStockPercentageData(city)
            .then(res => {
                if (res.data[0].dataPoints) {
                    dispatch(getPublicStockPercentageDataSuccess(res.data[0].dataPoints))
                } else {
                    dispatch(getPublicStockPercentageDataFail("取得縣市園區廠商政府計畫類型佔比時發生問題。"))
                }
            })
            .catch(err => {
                dispatch(getPublicStockPercentageDataFail(err))
            })
    }
}

export const startGetNewsInfoData = () => {
    return {
        type: actionTypes.START_GET_NEWS_INFO_DATA
    }
};

export const getNewsInfoDataSuccess = data => {
    return {
        type: actionTypes.GET_NEWS_INFO_DATA_SUCCESS,
        data: data
    }
};

export const getNewsInfoDataFail = err => {
    return {
        type: actionTypes.GET_NEWS_INFO_DATA_FAIL,
        err: err
    }
};


export const startGetNewsTagsData = () => {
    return {
        type: actionTypes.START_GET_NEWS_TAGS_DATA
    }
};

export const getNewsTagsDataSuccess = data => {
    return {
        type: actionTypes.GET_NEWS_TAGS_DATA_SUCCESS,
        data: data
    }
};

export const getNewsTagsDataFail = err => {
    return {
        type: actionTypes.GET_NEWS_TAGS_DATA_FAIL,
        err: err
    }
};

export const getNewsInfoData = (city, page, perPage) => {
    return dispatch => {
        dispatch(startGetNewsInfoData());
        fetchNewsInfo(city, page, perPage)
            .then(res => {


                if (res) {
                    dispatch(getNewsInfoDataSuccess(res))
                } else {
                    dispatch(getNewsInfoDataFail("取得相關新聞時發生問題。"))
                }
            })
            .catch(err => {
                dispatch(getNewsInfoDataFail(err))
            })
    }
}


export const startGetEmployeeData = () => {
    return {
        type: actionTypes.START_GET_EMPLOYEE_DATA
    }
};

export const getEmployeeDataSuccess = data => {
    return {
        type: actionTypes.GET_EMPLOYEE_DATA_SUCCESS,
        data: data
    }
};

export const getEmployeeDataFail = err => {
    return {
        type: actionTypes.GET_EMPLOYEE_DATA_FAIL,
        err: err
    }
};


export const getEmployeeData = (city) => {
    return dispatch => {
        dispatch(startGetEmployeeData());
        fetchEmployeeData(city)
            .then(res => {
                if (res.data[0].dataPoints) {
                    dispatch(getEmployeeDataSuccess(res.data[0].dataPoints))
                } else {
                    dispatch(getEmployeeDataFail("取得員工數料資料時發生問題。"))
                }
            })
            .catch(err => {
                dispatch(getEmployeeDataFail(err))
            })
    }
}

export const startGetNewsInfoTag = () => {
    return {
        type: actionTypes.START_GET_NEWS_INFO_TAG_DATA
    }
};

export const getNewsInfoTagDataSuccess = data => {
    return {
        type: actionTypes.GET_NEWS_INFO_TAG_DATA_SUCCESS,
        data: data
    }
};

export const getNewsInfoTagDataFail = err => {
    return {
        type: actionTypes.GET_NEWS_INFO_TAG_DATA_FAIL,
        err: err
    }
};

export const getNewsInfoTagData = (city) => {
    return dispatch => {
        dispatch(startGetNewsInfoTag());
        fetchNewsInfoTagData(city)
            .then(res => {
                if (res) {
                    dispatch(getNewsInfoTagDataSuccess(res))
                } else {
                    dispatch(getNewsInfoTagDataFail("取得新聞標籤雲時發生問題。"))
                }
            })
            .catch(err => {
                dispatch(getNewsInfoTagDataFail(err))
            })
    }
}


export const startGetGreenFactory = () => {
    return {
        type: actionTypes.START_GET_GREEN_FACTORY_DATA
    }
};

export const getGreenFactoryDataSuccess = data => {
    return {
        type: actionTypes.GET_GREEN_FACTORY_DATA_SUCCESS,
        data: data
    }
};

export const getGreenFactoryDataFail = err => {
    return {
        type: actionTypes.GET_GREEN_FACTORY_DATA_FAIL,
        err: err
    }
};

// export const getGreenFactoryData = (city) => {
//     return dispatch => {
//         dispatch(startGetGreenFactory());
//         fetchGreenFactoryData(city)
//             .then(res => {
//                 if (res) {
//                     dispatch(getGreenFactoryDataSuccess(res))
//                 } else {
//                     dispatch(getGreenFactoryDataFail("取得綠色工廠時發生問題。"))
//                 }
//             })
//             .catch(err => {
//                 dispatch(getGreenFactoryDataFail(err))
//             })
//     }
// }

export const startGetTypeTwoListData = () => {
    return {
        type: actionTypes.START_GET_TYPE_TWO_LIST_DATA
    }
};

export const getTypeTwoListDataSuccess = data => {
    return {
        type: actionTypes.GET_TYPE_TWO_LIST_DATA_SUCCESS,
        data: data
    }
};

export const getTypeTwoListDataFail = err => {
    return {
        type: actionTypes.GET_TYPE_TWO_LIST_DATA_FAIL,
        err: err
    }
};

export const getTypeTwoListDataHandler = (city, code) => {
    return dispatch => {
        dispatch(startGetTypeTwoListData());
        fetchTypeTwoList(city, code)
            .then(res => {
                if (res) {
                    dispatch(getTypeTwoListDataSuccess(res))
                } else {
                    dispatch(getTypeTwoListDataFail("取得縣市廠商產業別二碼家數佔比時發生問題。"))
                }
            })
            .catch(err => {
                dispatch(getTypeTwoListDataFail(err))
            })
    }
}


export const startGetTypeThreeListData = () => {
    return {
        type: actionTypes.START_GET_TYPE_THREE_LIST_DATA
    }
};

export const getTypeThreeListDataSuccess = data => {
    return {
        type: actionTypes.GET_TYPE_THREE_LIST_DATA_SUCCESS,
        data: data
    }
};

export const getTypeThreeListDataFail = err => {
    return {
        type: actionTypes.GET_TYPE_THREE_LIST_DATA_FAIL,
        err: err
    }
};

export const getTypeThreeListDataHandler = (city, code) => {
    return dispatch => {
        dispatch(startGetTypeThreeListData());
        fetchTypeThreeList(city, code)
            .then(res => {
                if (res) {
                    dispatch(getTypeThreeListDataSuccess(res))
                } else {
                    dispatch(getTypeThreeListDataFail("取得縣市廠商產業別三碼家數佔比時發生問題。"))
                }
            })
            .catch(err => {
                dispatch(getTypeThreeListDataFail(err))
            })
    }
}


export const startGetTypeFourListData = () => {
    return {
        type: actionTypes.START_GET_TYPE_FOUR_LIST_DATA
    }
};

export const getTypeFourListDataSuccess = data => {
    return {
        type: actionTypes.GET_TYPE_FOUR_LIST_DATA_SUCCESS,
        data: data
    }
};

export const getTypeFourListDataFail = err => {
    return {
        type: actionTypes.GET_TYPE_FOUR_LIST_DATA_FAIL,
        err: err
    }
};

export const getTypeFourListDataHandler = (city, code) => {
    return dispatch => {
        dispatch(startGetTypeFourListData());
        fetchTypeFourList(city, code)
            .then(res => {
                if (res) {
                    dispatch(getTypeFourListDataSuccess(res))
                } else {
                    dispatch(getTypeFourListDataFail("取得縣市廠商產四碼家數佔比時發生問題。"))
                }
            })
            .catch(err => {
                dispatch(getTypeFourListDataFail(err))
            })
    }
}

export const startGetImportListData = () => {
    return {
        type: actionTypes.START_GET_IMPORT_LIST_DATA
    }
};

export const getImportListDataSuccess = data => {
    return {
        type: actionTypes.GET_IMPORT_LIST_DATA_SUCCESS,
        data: data
    }
};

export const getImportListDataFail = err => {
    return {
        type: actionTypes.GET_IMPORT_LIST_DATA_FAIL,
        err: err
    }
};

export const getImportListData = (city, code) => {
    return dispatch => {
        dispatch(startGetImportListData());
        fetchImportList(city, code)
            .then(res => {
                if (res) {
                    dispatch(getImportListDataSuccess(res))
                } else {
                    dispatch(getImportListDataFail("取得縣市園區廠商近年專利公告數前十大排行時發生問題。"))
                }
            })
            .catch(err => {
                dispatch(getImportListDataFail(err))
            })
    }
}



export const startGetExportListData = () => {
    return {
        type: actionTypes.START_GET_EXPORT_LIST_DATA
    }
};

export const getExportListDataSuccess = data => {
    return {
        type: actionTypes.GET_EXPORT_LIST_DATA_SUCCESS,
        data: data
    }
};

export const getExportListDataFail = err => {
    return {
        type: actionTypes.GET_EXPORT_LIST_DATA_FAIL,
        err: err
    }
};

export const getExportListData = (city, code) => {
    return dispatch => {
        dispatch(startGetExportListData());
        fetchExportList(city, code)
            .then(res => {
                if (res) {
                    dispatch(getExportListDataSuccess(res))
                } else {
                    dispatch(getExportListDataFail("取得縣市園區廠商近年專利公告數前十大排行時發生問題。"))
                }
            })
            .catch(err => {
                dispatch(getExportListDataFail(err))
            })
    }
}


export const startGetAwardListData = () => {
    return {
        type: actionTypes.START_GET_AWARD_LIST_DATA
    }
};

export const getAwardListDataSuccess = data => {
    return {
        type: actionTypes.GET_AWARD_LIST_DATA_SUCCESS,
        data: data
    }
};

export const getAwardListDataFail = err => {
    return {
        type: actionTypes.GET_AWARD_LIST_DATA_FAIL,
        err: err
    }
};


export const getAwardListData = (city, code) => {
    return dispatch => {
        dispatch(startGetAwardListData());
        fetchAwardList(city, code)
            .then(res => {
                if (res) {
                    dispatch(getAwardListDataSuccess(res))
                } else {
                    dispatch(getAwardListDataFail("取得縣市園區廠商獲獎類型佔比時發生問題。"))
                }
            })
            .catch(err => {
                dispatch(getAwardListDataFail(err))
            })
    }
}


export const startGetGovPlanListData = () => {
    return {
        type: actionTypes.START_GET_GOV_PLAN_LIST_DATA
    }
};

export const getGovPlanListDataSuccess = data => {
    return {
        type: actionTypes.GET_GOV_PLAN_LIST_DATA_SUCCESS,
        data: data
    }
};

export const getGovPlanListDataFail = err => {
    return {
        type: actionTypes.GET_GOV_PLAN_LIST_DATA_FAIL,
        err: err
    }
};


export const getGovPlanListData = (city, code) => {
    return dispatch => {
        dispatch(startGetGovPlanListData());
        fetchGovPlanList(city, code)
            .then(res => {
                if (res) {
                    dispatch(getGovPlanListDataSuccess(res))
                } else {
                    dispatch(getGovPlanListDataFail("取得縣市園區廠商獲獎類型佔比時發生問題。"))
                }
            })
            .catch(err => {
                dispatch(getGovPlanListDataFail(err))
            })
    }
}


export const startGetPatentListData = () => {
    return {
        type: actionTypes.START_GET_PATENT_LIST_DATA
    }
};

export const getPatentListDataSuccess = data => {
    return {
        type: actionTypes.GET_PATENT_LIST_DATA_SUCCESS,
        data: data
    }
};

export const getPatentListDataFail = err => {
    return {
        type: actionTypes.GET_PATENT_LIST_DATA_FAIL,
        err: err
    }
};

export const getPatentListDataHandler = (businessId, year) => {
    return dispatch => {
        dispatch(startGetPatentListData());
        fetchPatentList(businessId, year)
            .then(res => {
                if (res) {
                    dispatch(getPatentListDataSuccess(res))
                } else {
                    dispatch(getPatentListDataFail("取得縣市廠商產業別二碼家數佔比時發生問題。"))
                }
            })
            .catch(err => {
                dispatch(getPatentListDataFail(err))
            })
    }
}


export const startGetPublicStockListData = () => {
    return {
        type: actionTypes.START_GET_PUBLIC_STOCK_LIST_DATA
    }
};

export const getPublicStockListDataSuccess = data => {
    return {
        type: actionTypes.GET_PUBLIC_STOCK_LIST_DATA_SUCCESS,
        data: data
    }
};

export const getPublicStockListDataFail = err => {
    return {
        type: actionTypes.GET_PUBLIC_STOCK_LIST_DATA_FAIL,
        err: err
    }
};

export const getPublicStockListDataHandler = (city, stockType) => {
    return dispatch => {
        dispatch(startGetPublicStockListData());
        fetchPublicDataList(city, stockType)
            .then(res => {
                if (res) {
                    dispatch(getPublicStockListDataSuccess(res))
                } else {
                    dispatch(getPublicStockListDataFail("取得縣市廠商產業別二碼家數佔比時發生問題。"))
                }
            })
            .catch(err => {
                dispatch(getPublicStockListDataFail(err))
            })
    }
}

export const startGetTopTenGovPlanListData = () => {
    return {
        type: actionTypes.START_GET_TOP_TEN_GOVPLAN_LIST_DATA
    }
};

export const getTopTenGovPlanListDataSuccess = data => {
    return {
        type: actionTypes.GET_TOP_TEN_GOVPLAN_LIST_DATA_SUCCESS,
        data: data
    }
};

export const getTopTenGovPlanListDataFail = err => {
    return {
        type: actionTypes.GET_TOP_TEN_GOVPLAN_LIST_DATA_FAIL,
        err: err
    }
};

export const getTopTenGovPlanListDataHandler = (businessNo) => {
    return dispatch => {
        dispatch(startGetTopTenGovPlanListData());
        fetchTopTenGovPlanDataList(businessNo)
            .then(res => {
                if (res) {
                    dispatch(getTopTenGovPlanListDataSuccess(res))
                } else {
                    dispatch(getTopTenGovPlanListDataFail("取得縣市廠商產業別二碼家數佔比時發生問題。"))
                }
            })
            .catch(err => {
                dispatch(getTopTenGovPlanListDataFail(err))
            })
    }
}

export const startGetTaiwanInvestmentData = () => {
    return {
        type: actionType.START_GET_TAIWAN_INVESTMENT_DATA
    }
};

export const getTaiwanInvestmentDataSuccess = data => {
    return {
        type: actionType.GET_TAIWAN_INVESTMENT_DATA_SUCCESS,
        data: data
    }
};

export const getTaiwanInvestmentDataSuccessFail = err => {
    return {
        type: actionType.GET_TAIWAN_INVESTMENT_DATA_FAIL,
        err: err
    }
};

export const getTaiwanInvestmentDataHandler = (city) => {
    return dispatch => {
        dispatch(startGetTaiwanInvestmentData());
        fetchBackTaiwanInvestment(city)
            .then(res => {
                if (res) {
                    dispatch(getTaiwanInvestmentDataSuccess(res))
                } else {
                    dispatch(getTaiwanInvestmentDataSuccessFail("取得縣回台投資時發生問題。"))
                }
            })
            .catch(err => {
                dispatch(getTaiwanInvestmentDataSuccessFail(err))
            })
    }
}

export const startFactoryAreaRentData = () => {
    return {
        type: actionType.START_GET_FACTORY_AREA_RENT_DATA
    }
};

export const getFactoryAreaRentDataSuccess = data => {
    return {
        type: actionType.GET_FACTORY_AREA_RENT_DATA_SUCCESS,
        data: data
    }
};

export const getFactoryAreaRentDataFail = err => {
    return {
        type: actionType.GET_FACTORY_AREA_RENT_DATA_FAIL,
        err: err
    }
};

export const getFactoryAreaRentDataHandler = (area) => {
    return dispatch => {
        dispatch(startFactoryAreaRentData());
        fetchFactoryAreaRentData(area)
            .then(res => {
                if (res) {
                    dispatch(getFactoryAreaRentDataSuccess(res))
                } else {
                    dispatch(getFactoryAreaRentDataFail("取得縣市租用土地資料時時發生問題。"))
                }
            })
            .catch(err => {
                dispatch(getFactoryAreaRentDataFail(err))
            })
    }
}