import React from "react";
import { useDispatch, useSelector } from "react-redux";
import "./OperationsDashboard.scss"
import Tab from '@mui/material/Tab';
import styled from "styled-components";
import { Select, FormControl } from "@mui/material";
import Pagination from '@material-ui/lab/Pagination';
import Breadcrumb from "../../components/Breadcrumbs";
import { thousandDivider } from "../../utility/common";
import { TypeTwoListHandler } from "../../utility/taxInfoApi";
import subscribeBg from "../../assests/images/subscribe-bg.png";
import ascendingIcon from "../../assets/images/sort-ascending.png";
import descendingIcon from "../../assets/images/sort-descending.png";
import { getOperationData, getOperationExportData } from "../../utility/api";
import CustomizedGraphic from "../../components/CustomizedGraphic/CustomizedGraphicV2";
import CityDashboardHeader from "../../components/CityDashboardHeader/CityDashboardHeader";
import { handleOperationMonthData, handleOperationExportData } from "../../store/actions/operationsDashboard";

export default (props) => {
  const dispatch = useDispatch();
  const { operationExportData, operationExportAccumulatedAbstructData, operationExportDataLoading, operationMonthData, operationMonthAbstructData, operationMonthAccumulatedAbstructData, operationMonthDataLoading } = useSelector((state) => state.operationsDashboard);
  React.useEffect(() => {
    dispatch(handleOperationMonthData(""));
    dispatch(handleOperationExportData(""));
  }, [dispatch]);
  const [value, setValue] = React.useState(0);
  const [sortType, setSortType] = React.useState(true);
  const [state, setState] = React.useState({
    endIndex: 0,
    page: 0,
    rowsPerPage: 8,
  });
  const [typeTwoOptions, setTypeTwoOptions] = React.useState([]); // 產業二碼的選項
  const [industryDashboardCoed, setIndustryDashboardCoed] = React.useState(['0811', '0600', '0812', '0820', '0830', '0840', '0850', '0861', '0862', '0870', '0891', '0892', '0893', '0894', '0895', '0896', '0897', '0899', '0911', '0919', '0920', '1000', '1111', '1112', '1113', '1121', '1122', '1123', '1124', '1130', '1140', '1151', '1152', '1159', '1210', '1230', '1301', '1302', '1303', '1309', '1401', '1402', '1403', '1404', '1409', '1511', '1512', '1513', '1520', '1591', '1599', '1601', '1602', '1700', '1810', '1830', '1841', '1842', '1850', '1910', '1920', '1930', '1990', '2001', '2002', '2003', '2004', '2005', '2101', '2102', '2109', '2201', '2202', '2203', '2209', '2311', '2312', '2313', '2319', '2321', '2322', '2329', '2331', '2332', '2333', '2340', '2391', '2399', '2411', '2412', '2413', '2414', '2421', '2422', '2423', '2433', '2491', '2499', '2511', '2512', '2521', '2522', '2531', '2539', '2541', '2543', '2544', '2549', '2591', '2592', '2599', '2611', '2612', '2613', '2620', '2630', '2641', '2642', '2643', '2649', '2691', '2699', '2711', '2712', '2719', '2721', '2729', '2730', '2740', '2751', '2752', '2760', '2771', '2779', '2810', '2820', '2831', '2832', '2841', '2842', '2851', '2859', '2890', '2912', '2919', '2921', '2922', '2923', '2924', '2925', '2926', '2927', '2928', '2929', '2933', '2934', '2935', '2936', '2937', '2938', '2939', '3010', '3020', '3030', '3110', '3121', '3122', '3131', '3132', '3190', '3211', '3219', '3220', '3311', '3312', '3313', '3314', '3321', '3329', '3391', '3392', '3399', '3400', '3510', '3520', '3600']);
  const [dashboardData, setDashboardData] = React.useState({
    dashboard: "industry_index",
    code: "", // 產業二碼的選擇
    data: [],
    lastDate: "",
    calcAbstract: {},
    calcAccumulatedAbstract: {},
  });
  const [dashboardType, setDashboardType] = React.useState(0);
  const url = window.location.origin;
  const openNewTabs = (link) => window.open(link, "_blank");
  // 產業二碼的 options
  React.useEffect(() => {
    const getData = async () => {
      if (operationMonthData && dashboardType == 0) {
        try {
          const defaultData = [];
          const tempTwoCodeDataList = await TypeTwoListHandler("");
          const seriesData = { three: [], six: [] };
          operationMonthData.data.map((item, index) => {
            const tempSeriesData = { data: [[], []], label: [], Year: [], Code: '', Name: '', status: '' };
            let upCompare = 0;
            let downCompare = 0;
            if (industryDashboardCoed.includes(item.Code)) {
              defaultData.push(item);
              tempSeriesData['Code'] = item.Code;
              tempSeriesData['Name'] = item.Name;
              tempSeriesData['data'][0].push(parseInt(item.Avg_Recent_Revenue));
              tempSeriesData['data'][1].push(parseInt(item.Avg_Period_Revenue));
              tempSeriesData['label'].push(operationMonthData.date.substr(5, 2));
              tempSeriesData['Year'].push(operationMonthData.date.substr(0, 4));
              tempSeriesData['Year'].push(parseInt(operationMonthData.date.substr(0, 4)) - 1);
              item.RecentMonth.map((subItem, subIndex) => {
                tempSeriesData['data'][0].push(parseInt(subItem.Avg_Recent_Revenue));
                tempSeriesData['data'][1].push(parseInt(subItem.Avg_Period_Revenue));
                if (tempSeriesData['data'][0][subIndex] > tempSeriesData['data'][0][subIndex + 1]) {
                  upCompare += 1;
                }
                if (tempSeriesData['data'][0][subIndex] < tempSeriesData['data'][0][subIndex + 1]) {
                  downCompare -= 1;
                }

                tempSeriesData['label'].push(subItem.RecentDate.substr(5, 2));
                if (subIndex == 1) {
                  tempSeriesData['data'][0] = tempSeriesData['data'][0].reverse()
                  tempSeriesData['data'][1] = tempSeriesData['data'][1].reverse()
                  tempSeriesData['label'] = tempSeriesData['label'].reverse()
                  if (upCompare == 2) {
                    tempSeriesData['status'] = 1
                  } else if (downCompare == -2) {
                    tempSeriesData['status'] = -1
                  } else {
                    tempSeriesData['status'] = 0
                  }
                  seriesData.three.push(JSON.parse(JSON.stringify(tempSeriesData)));
                  tempSeriesData['data'][0] = tempSeriesData['data'][0].reverse()
                  tempSeriesData['data'][1] = tempSeriesData['data'][1].reverse()
                  tempSeriesData['label'] = tempSeriesData['label'].reverse()
                } else if (subIndex == 4) {
                  tempSeriesData['data'][0] = tempSeriesData['data'][0].reverse()
                  tempSeriesData['data'][1] = tempSeriesData['data'][1].reverse()
                  tempSeriesData['label'] = tempSeriesData['label'].reverse()
                  if (upCompare == 5) {
                    tempSeriesData['status'] = 1
                  } else if (downCompare == -5) {
                    tempSeriesData['status'] = -1
                  } else {
                    tempSeriesData['status'] = 0
                  }
                  seriesData.six.push(tempSeriesData);
                }
              })
            }
          })
          let twoCodeDataList = [];
          for (let twoCodeIndex = 0; twoCodeIndex < tempTwoCodeDataList.data.length; twoCodeIndex++) {
            twoCodeDataList.push({
              'Key': tempTwoCodeDataList.data[twoCodeIndex].Code,
              'Value': tempTwoCodeDataList.data[twoCodeIndex].Name
            });
          }
          setTypeTwoOptions(twoCodeDataList);
          setDashboardData((prev) => ({
            ...prev,
            data: operationMonthData.data,
            seriesData: seriesData,
            lastDate: operationMonthData.date,
            calcAbstract: operationMonthAbstructData,
            calcAccumulatedAbstract: operationMonthAccumulatedAbstructData
          }));
        } catch (err) {
          console.log("err", err);
        }
      }
    };
    getData();
  }, [operationMonthDataLoading, dashboardType]);
  const refreshData = async (e) => {
    if (dashboardType === 0) {
      const tempData = await getOperationData(e.target.value);
      const defaultData = [];
      const seriesData = { three: [], six: [] };
      tempData.data.data.map((item, index) => {
        const tempSeriesData = { data: [[], []], label: [], Year: [], Code: '', Name: '', status: '' };
        let upCompare = 0;
        let downCompare = 0;
        if (industryDashboardCoed.includes(item.Code)) {
          defaultData.push(item);
          tempSeriesData['Code'] = item.Code;
          tempSeriesData['Name'] = item.Name;
          tempSeriesData['data'][0].push(parseInt(item.Avg_Recent_Revenue));
          tempSeriesData['data'][1].push(parseInt(item.Avg_Period_Revenue));
          tempSeriesData['label'].push(tempData.data.date.substr(5, 2));
          tempSeriesData['Year'].push(tempData.data.date.substr(0, 4));
          tempSeriesData['Year'].push(parseInt(tempData.data.date.substr(0, 4)) - 1);
          item.RecentMonth.map((subItem, subIndex) => {
            if (tempSeriesData['data'][0][subIndex] > tempSeriesData['data'][0][subIndex + 1]) {
              upCompare += 1;
            }
            if (tempSeriesData['data'][0][subIndex] < tempSeriesData['data'][0][subIndex + 1]) {
              downCompare -= 1;
            }
            tempSeriesData['data'][0].push(parseInt(subItem.Avg_Recent_Revenue));
            tempSeriesData['data'][1].push(parseInt(subItem.Avg_Period_Revenue));
            tempSeriesData['label'].push(subItem.RecentDate.substr(5, 2));
            if (subIndex == 1) {
              if (upCompare == 2) {
                tempSeriesData['status'] = 1
              } else if (downCompare == -2) {
                tempSeriesData['status'] = -1
              } else {
                tempSeriesData['status'] = 0
              }
              seriesData.three.push(tempSeriesData);
            } else if (subIndex == 4) {
              if (upCompare == 5) {
                tempSeriesData['status'] = 1
              } else if (downCompare == -5) {
                tempSeriesData['status'] = -1
              } else {
                tempSeriesData['status'] = 0
              }
              seriesData.six.push(tempSeriesData);
            }
          })
        }
      })
      setDashboardData((prev) => ({
        ...prev,
        data: defaultData,
        code: e.target.value,
        seriesData: seriesData,
        lastDate: tempData.data.date,
        calcAbstract: tempData.calcAbstract,
        calcAccumulatedAbstract: tempData.calcAccumulatedAbstract
      }));
    } else {
      const data = await getOperationExportData(e.target.value);
      setValue(1);
      setDashboardData((prev) => ({
        ...prev,
        code: e.target.value,
        data: data.data.data,
        lastDate: data.data.date,
        calcAbstract: data.calcAbstract,
        calcAccumulatedAbstract: data.calcAccumulatedAbstract
      }));
    }
  };
  const refreshType = (e) => {
    const getExprtData = async () => {
      setDashboardType(1);
      setValue(1);
      setDashboardData((prev) => ({
        ...prev,
        data: operationExportData.data,
        lastDate: operationExportData.date,
        calcAbstract: {},
        calcAccumulatedAbstract: operationExportAccumulatedAbstructData
      }));
    }
    const getMonthData = async () => {
      setValue(0)
      setDashboardType(0);
      const defaultData = [];
      const seriesData = { three: [], six: [] };
      operationMonthData.data.map((item, index) => {
        const tempSeriesData = { data: [[], []], label: [], Year: [], Code: '', Name: '', status: '' };
        let upCompare = 0;
        let downCompare = 0;
        if (industryDashboardCoed.includes(item.Code)) {
          defaultData.push(item);
          tempSeriesData['Code'] = item.Code;
          tempSeriesData['Name'] = item.Name;
          tempSeriesData['data'][0].push(parseInt(item.Avg_Recent_Revenue));
          tempSeriesData['data'][1].push(parseInt(item.Avg_Period_Revenue));
          tempSeriesData['label'].push(operationMonthData.date.substr(5, 2));
          tempSeriesData['Year'].push(operationMonthData.date.substr(0, 4));
          tempSeriesData['Year'].push(parseInt(operationMonthData.date.substr(0, 4)) - 1);
          item.RecentMonth.map((subItem, subIndex) => {
            if (tempSeriesData['data'][0][subIndex] > tempSeriesData['data'][0][subIndex + 1]) {
              upCompare += 1;
            }
            if (tempSeriesData['data'][0][subIndex] < tempSeriesData['data'][0][subIndex + 1]) {
              downCompare -= 1;
            }
            tempSeriesData['data'][0].push(parseInt(subItem.Avg_Recent_Revenue));
            tempSeriesData['data'][1].push(parseInt(subItem.Avg_Period_Revenue));
            tempSeriesData['label'].push(subItem.RecentDate.substr(5, 2));
            if (subIndex == 1) {
              if (upCompare == 2) {
                tempSeriesData['status'] = 1
              } else if (downCompare == -2) {
                tempSeriesData['status'] = -1
              } else {
                tempSeriesData['status'] = 0
              }
              seriesData.three.push(tempSeriesData);
            } else if (subIndex == 4) {
              if (upCompare == 5) {
                tempSeriesData['status'] = 1
              } else if (downCompare == -5) {
                tempSeriesData['status'] = -1
              } else {
                tempSeriesData['status'] = 0
              }
              seriesData.six.push(tempSeriesData);
            }
          })
        }
      })
      setDashboardData((prev) => ({
        ...prev,
        data: operationMonthData.data,
        seriesData: seriesData,
        lastDate: operationMonthData.date,
        calcAbstract: operationMonthAbstructData,
        calcAccumulatedAbstract: operationMonthAccumulatedAbstructData
      }));
    }
    if (dashboardType == 0) {
      getExprtData();
    } else {
      getMonthData();
    }
  };
  const updateSearchParams = (event, newPage) => {
    if ((!event.currentTarget.parentNode.parentNode.parentNode.id.includes("topPage")) && (!event.currentTarget.id.includes("topPage"))) {
      document.getElementById("taxInfoContent").scrollTo({
        top: 0,
        behavior: "smooth"
      })
    }
    if (event.nativeEvent.type == 'input') {
      const previousPage = state.page + 1;
      newPage = isNaN(event.currentTarget.value) ? previousPage : parseInt(event.currentTarget.value);
      if (newPage > Math.ceil(dashboardData?.data?.length / state.rowsPerPage)) {
        newPage = Math.ceil(dashboardData?.data?.length / state.rowsPerPage);
      }
      if (newPage <= 0) {
        newPage = 1;
      }
      if (!isNaN(newPage)) {
        setState((state) => ({
          ...state,
          page: newPage - 1,
        }));
      }
      else {
        setState((state) => ({
          ...state,
          page: previousPage - 1,
        }));
      }
    }
    else {
      if (newPage !== undefined) {
        setState((state) => ({
          ...state,
          page: newPage - 1,
        }));
      }
    }
  };
  const openTab = (evt, tab) => {
    if (tab === "tab-1") {
      setValue(0)
      let sortData;
      if (sortType == 0) {
        sortData = [...dashboardData.data].sort((a, b) => {
          return a.Variety - b.Variety;
        });
      } else if (sortType == 1) {
        sortData = [...dashboardData.data].sort((a, b) => {
          return b.Variety - a.Variety;
        });
      }
      setDashboardData((prev) => ({
        ...prev,
        data: sortData,
      }));
    } else if (tab === "tab-2") {
      setValue(1)
      let sortData;
      if (sortType == 0) {
        sortData = [...dashboardData.data].sort((a, b) => {
          return a.Accumulated_Variety - b.Accumulated_Variety;
        });
      } else if (sortType == 1) {
        sortData = [...dashboardData.data].sort((a, b) => {
          return b.Accumulated_Variety - a.Accumulated_Variety;
        });
      }
      setDashboardData((prev) => ({
        ...prev,
        data: sortData,
      }));
    } else if (tab === "tab-4") {
      setValue(4)
    } else if (tab === "tab-5") {
      setValue(5)
    }
    var i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("content__inner");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = "none";
    }
    tablinks = document.getElementsByClassName("operationsTab");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(" active", "");
    }
    document.getElementById(tab).style.display = "block";
    evt.currentTarget.className += " active";
  }
  const changeSort = (evt, tab) => {
    setSortType(!sortType)
    if (sortType) {
      const sortData = [...dashboardData.data].sort((a, b) => {
        if (value == 0) {
          return a.Variety - b.Variety;
        } else if (value == 1) {
          return a.Accumulated_Variety - b.Accumulated_Variety;
        }
      });
      setDashboardData((prev) => ({
        ...prev,
        data: sortData,
      }));
    } else {
      const sortData = [...dashboardData.data].sort((a, b) => {
        if (value == 0) {
          return b.Variety - a.Variety;
        } else if (value == 1) {
          return b.Accumulated_Variety - a.Accumulated_Variety;
        }
      });
      setDashboardData((prev) => ({
        ...prev,
        data: sortData,
      }));
    }
    setState((prev) => ({
      ...prev,
      page: 0
    }));
  }
  React.useEffect(() => {
    if (window.innerWidth > 800) {
      const scrollContainer = document.querySelector(".operationsTabs");
      scrollContainer.addEventListener("wheel", (evt) => {
        evt.preventDefault();
        scrollContainer.scrollLeft += evt.deltaY;
      });
    }
  }, [value]);
  return (
    <>
      <BreadcrumbContainer>
        <Breadcrumb route={[{
          name: "營運動態儀表板",
        }]} />
      </BreadcrumbContainer>
      <ScrollContainer>
        <CityDashboardHeader
          isOperation
          referenceType={"OperationsDashboard"}
          title="產業營運動態總覽"
          subTitle={`產業營運概況`}
          dataAbstract={value === 0 ? dashboardData.calcAbstract : dashboardData.calcAccumulatedAbstract}
          lastDate={`最新資料時間：${dashboardData.lastDate}`}
          skipOperationCalc={(value == 0 || value == 1) ? true : false}
        />
        <SelectContainer>
          <SearchDashboard>
            <CustSlect
              native
              id="select_type"
              size="small"
              label=""
              value={dashboardType}
              onChange={(e) => { refreshType(e) }}
              className="dashboard-header-selection"
            >
              <option
                key={0}
                value={0}
                selected={""}>
                {"每月營業額"}
              </option>
              <option
                key={1}
                value={1}
                selected={""}>
                {"進出口級距"}
              </option>
            </CustSlect>
          </SearchDashboard>
          <SearchDashboard>
            <CustSlect
              native
              id="select_dashboard"
              size="small"
              label=""
              onChange={(e) => { refreshData(e) }}
              className="dashboard-header-selection"
            >
              <option
                key={`All`}
                value={""}>
                {"全部產業"}
              </option>
              {typeTwoOptions.map((item, index) => (
                item.Key != 10 && (
                  <option
                    key={`${item.Key}_${item.Value}`}
                    value={item.Key}
                    selected={item.Key == dashboardData.code}>
                    {item.Key} {item.Value}
                  </option>
                )
              ))}
            </CustSlect>
          </SearchDashboard>
        </SelectContainer>
        <div className="folder">
          <div className="operationsTabs">
            <div>
              {dashboardType == 0 && (
                <>
                  <button className="operationsTab active" onClick={(event) => openTab(event, 'tab-1')}>
                    <div>
                      <span>單月</span>
                    </div>
                  </button>
                  <button className="operationsTab" onClick={(event) => openTab(event, 'tab-2')}>
                    <div>
                      <span>累計</span>
                    </div>
                  </button>
                  <button className="operationsTab" onClick={(event) => openTab(event, 'tab-4')}>
                    <div>
                      <span>連續3個月</span>
                    </div>
                  </button>
                  <button className="operationsTab" onClick={(event) => openTab(event, 'tab-5')}>
                    <div>
                      <span>連續6個月</span>
                    </div>
                  </button>
                </>
              )
              }
            </div>
            {(value == 0 || value == 1) && (
              <div>
                <button className="oppositeTab active" onClick={(event) => changeSort(event, 'tab-3')}>
                  <SortContainer>
                    <img src={sortType == true ? descendingIcon : ascendingIcon} width="35" height="35" />
                    <span>{sortType == true ? "遞減" : "遞增"}</span>
                  </SortContainer>
                </button>
              </div>
            )}
          </div>
          <div className="content">
            {dashboardType == 0 ? (
              <>
                <div className="content__inner" id="tab-1">
                  <div className="page">
                    <WaterBallGrid>
                      {dashboardData?.data?.slice(
                        state.page * state.rowsPerPage,
                        state.page * state.rowsPerPage + state.rowsPerPage
                      ).map((item, index) => (
                        <ItemContainer key={`tab1_${index}`}>
                          <IndustryTitle>
                            {item.Code + " " + item.Name}
                          </IndustryTitle>
                          <IllustrateText>
                            <a>相較去年同期<span style={{ color: item.Variety > 0 ? "#970032" : "#009726", fontWeight: "bold" }}>{item.Variety > 0 ? "上漲" : "下降"}{Math.abs(parseFloat(item.Variety) * 100).toFixed(2)}%</span></a>
                          </IllustrateText>
                          <CustomizedGraphic
                            title={item.Code}
                            custWidth={"100%"}
                            custHeight={"100%"}
                            custColor={item.Variety > 0 ? "#970032" : "#009726"}
                            chartType={"vertical-bar-chart"}
                            data={[item.Avg_Period_Revenue.toFixed(2), item.Avg_Recent_Revenue.toFixed(2)]}
                            labels={["去年同期", "最新平均營收"]}
                            mapData={[
                              { "label": "去年同期", "y": item.Avg_Recent_Revenue.toFixed(2), "tag": item.Code }, { "label": "最新平均營收", "y": item.Avg_Recent_Revenue.toFixed(2), "tag": item.Code }
                            ]}
                            unit={"仟元"}
                            formatter={(val) => (val === 0 ? 0 : val)}
                          />
                          <IllustrateText>
                            {typeof (item.Avg_Recent_Revenue) == 'number' && <a>{`平均營業收入(仟元)：${thousandDivider(item.Avg_Recent_Revenue.toFixed(2))}`}</a>}
                          </IllustrateText>
                          <MoreIndustryButton
                            onClick={() =>
                              openNewTabs(`${url}/industry_index/${item.Code}`)
                            }>
                            瀏覽產業詳細資料
                          </MoreIndustryButton>
                        </ItemContainer>
                      ))}
                    </WaterBallGrid>
                  </div>
                </div>
                <div className="content__inner" id="tab-2">
                  <div className="page">
                    <WaterBallGrid>
                      {dashboardData?.data?.slice(
                        state.page * state.rowsPerPage,
                        state.page * state.rowsPerPage + state.rowsPerPage
                      ).map((item, index) => (
                        item.Accumulated_Variety != 'Infinity' && (
                          <ItemContainer key={`tab2_${index}`}>
                            <IndustryTitle>
                              {item.Code + " " + item.Name}
                            </IndustryTitle>
                            <IllustrateText>
                              <a>相較去年同期<span style={{ color: item.Accumulated_Variety > 0 ? "#970032" : "#009726", fontWeight: "bold" }}>{item.Accumulated_Variety > 0 ? "上漲" : "下降"}{Math.abs(parseFloat(item.Accumulated_Variety) * 100).toFixed(2)}%</span></a>
                            </IllustrateText>
                            <CustomizedGraphic
                              title={item.Code}
                              custWidth={"100%"}
                              custHeight={"100%"}
                              custColor={item.Accumulated_Variety > 0 ? "#970032" : "#009726"}
                              chartType={"vertical-bar-chart"}
                              data={[item.Avg_Accumulated_Period_Revenue.toFixed(2), item.Avg_Accumulated_Revenue.toFixed(2)]}
                              labels={["去年同期", "最新平均營收"]}
                              mapData={[
                                { "label": "去年同期", "y": item.Avg_Recent_Revenue.toFixed(2), "tag": item.Code }, { "label": "最新平均營收", "y": item.Avg_Recent_Revenue.toFixed(2), "tag": item.Code }
                              ]}
                              unit={"仟元"}
                              formatter={(val) => (val === 0 ? 0 : val)}
                            />
                            <IllustrateText>
                              {typeof (item.Avg_Accumulated_Revenue) == 'number' && <a>{`平均營業收入(仟元)：${thousandDivider(item.Avg_Accumulated_Revenue.toFixed(2))}`}</a>}
                            </IllustrateText>
                            <MoreIndustryButton
                              onClick={() =>
                                openNewTabs(`${url}/industry_index/${item.Code}`)
                              }>
                              瀏覽產業詳細資料
                            </MoreIndustryButton>
                          </ItemContainer>
                        )
                      ))}
                    </WaterBallGrid>
                  </div>
                </div>
                <div className="content__inner" id="tab-4">
                  <div className="page">
                    <WaterBallGrid>
                      {dashboardData?.seriesData?.three?.slice(
                        state.page * state.rowsPerPage,
                        state.page * state.rowsPerPage + state.rowsPerPage
                      ).map((item, index) => (
                        <ItemContainer status={item.status} key={`tab4_${index}`}>
                          <IndustryTitle>
                            {item.Code + " " + item.Name}
                          </IndustryTitle>
                          <CustomizedGraphic
                            title={item.Code}
                            data={item.data}
                            labels={item.label}
                            seriesLabel={item.Year}
                            chartType="bar-with-bar-chart"
                            skip="1"
                          />
                          <MoreIndustryButton
                            onClick={() =>
                              openNewTabs(`${url}/industry_index/${item.Code}`)
                            }>
                            瀏覽產業詳細資料
                          </MoreIndustryButton>
                        </ItemContainer>
                      ))}
                    </WaterBallGrid>
                  </div>
                </div>
                <div className="content__inner" id="tab-5">
                  <div className="page">
                    <WaterBallGrid>
                      {dashboardData?.seriesData?.six?.slice(
                        state.page * state.rowsPerPage,
                        state.page * state.rowsPerPage + state.rowsPerPage
                      ).map((item, index) => (
                        item.Accumulated_Variety != 'Infinity' && (
                          <ItemContainer status={item.status} key={`tab5_${index}`}>
                            <IndustryTitle>
                              {item.Code + " " + item.Name}
                            </IndustryTitle>
                            <CustomizedGraphic
                              title={item.Code}
                              data={item.data}
                              labels={item.label}
                              seriesLabel={item.Year}
                              chartType="bar-with-bar-chart"
                            />
                            <MoreIndustryButton
                              onClick={() =>
                                openNewTabs(`${url}/industry_index/${item.Code}`)
                              }>
                              瀏覽產業詳細資料
                            </MoreIndustryButton>
                          </ItemContainer>
                        )
                      ))}
                    </WaterBallGrid>
                  </div>
                </div>
              </>
            ) : (
              <div className="content__inner" style={{ display: "block" }}>
                <div className="page">
                  <WaterBallGrid>
                    {dashboardData?.data?.slice(
                      state.page * state.rowsPerPage,
                      state.page * state.rowsPerPage + state.rowsPerPage
                    ).map((item, index) => (
                      (item.Accumulated_Variety != 'Infinity' && item.Avg_Accumulated_Period_Value && item.Avg_Accumulated_Value) && (
                        <ItemContainer key={`tab0_${index}`}>
                          <IndustryTitle>
                            {item.Code + " " + item.Name}
                          </IndustryTitle>
                          <IllustrateText>
                            <a>相較去年同期<span style={{ color: item.Accumulated_Variety > 0 ? "#970032" : "#009726", fontWeight: "bold" }}>{item.Accumulated_Variety > 0 ? "上漲" : "下降"}{Math.abs(parseFloat(item.Accumulated_Variety) * 100).toFixed(2)}%</span></a>
                          </IllustrateText>
                          <CustomizedGraphic
                            custWidth={"100%"}
                            custHeight={"100%"}
                            custColor={item.Accumulated_Variety > 0 ? "#970032" : "#009726"}
                            chartType={"vertical-bar-chart"}
                            data={[item.Avg_Accumulated_Period_Value.toFixed(2), item.Avg_Accumulated_Value.toFixed(2)]}
                            labels={["去年同期", "最新平均出口額"]}
                            mapData={[
                              { "label": "去年同期", "y": item.Avg_Accumulated_Period_Value.toFixed(2), "tag": item.Code }, { "label": "最新平均出口額", "y": item.Avg_Accumulated_Value.toFixed(2), "tag": item.Code }
                            ]}
                            unit={"百萬美元"}
                            formatter={(val) => (val === 0 ? 0 : val)}
                          />
                          <IllustrateText>
                            {typeof (item.Avg_Accumulated_Value) == 'number' && <a>{`平均出口額(百萬美元)：${thousandDivider(item.Avg_Accumulated_Value.toFixed(2))}`}</a>}
                          </IllustrateText>
                          <MoreIndustryButton
                            onClick={() =>
                              openNewTabs(`${url}/industry_index/${item.Code}`)
                            }>
                            瀏覽產業詳細資料
                          </MoreIndustryButton>
                        </ItemContainer>
                      )
                    ))}
                  </WaterBallGrid>
                </div>
              </div>
            )}
            <PaginationContainer>
              <PaginationModify
                count={Math.ceil(dashboardData?.data?.length / state.rowsPerPage)}
                shape="primary"
                page={state.page + 1}
                onChange={updateSearchParams}
                siblingCount={1}
                style={{ marginTop: "15px" }}
                size={window.innerWidth > 1024 ? "large" : "small"}
                color="primary"
                id="topPageControl"
              />
            </PaginationContainer>
          </div>
        </div>
      </ScrollContainer>
    </>
  );
};
const PaginationModify = styled(Pagination)`
  .MuiPaginationItem-sizeLarge{
    font-size: 18px !important;
  }
  .MuiPaginationItem-textPrimary.Mui-selected {
    font-size: 18px !important;
    height: 38px !important;
    border-radius: 38px !important;
  }
  .MuiPagination-ul{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
  }
`;
const PaginationContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 99999;
  background: #ffffffac;
  border: #000 1px solid;
  border-radius: 20px;
  top: 35vh;
  ${((window.innerWidth / window.outerWidth) * 100) >= 100
    ? "left: 93.5vw;"
    : ((window.innerWidth / window.outerWidth) * 100) >= 90
      ? "left: 93vw;"
      : ((window.innerWidth / window.outerWidth) * 100) >= 80
        ? "left: 92vw;"
        : "left: 91vw;"
  }
  @media (max-width: 768px) {
    left: 0vw;
  }
  @media (max-height: 400px) {
    top: 28vh;
  }
`;
const ScrollContainer = styled.div`
  height: calc(100vh - 115px);
  min-height: 50vh;
  overflow-y: scroll;
  overflow-x: hidden;
  width: 100%;
  margin: 40px 0px;
  padding: 30px 20px;
  background-image: url(${subscribeBg});
  background-position: center;
  background-size: cover;

  @media (max-width: 1024px) {
    height: 100%;
    overflow-y: auto;
    margin: 0px;
  }
`;
const ItemContainer = styled.div`
  border: ${(p) => (p.status == 1 ? "6px solid #970032" : p.status == -1 ? "6px solid #009726" : "")};
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  background: #FFFFFFE0;
  border-radius: 20px;
  padding: 20px;
  :hover {
    background: #9eccc7;
  }
  max-width: 20vw;
  min-width: 20vw;
  max-height: fit-content;
  min-height: fit-content;
  @media (max-width: 1024px) {
    max-width: 30vw;
    min-width: 30vw;
    max-height: fit-content;
    min-height: fit-content;
  }
  @media (max-width: 768px) {
    max-width: 80vw;
    min-width: 80vw;
  }
`;
const BreadcrumbContainer = styled.div`
  width: 100%;
  top: 115px;
  z-index: 2;
  position: absolute;
  @media (max-width: 1024px) {
    top: 0px;
  }
}
`;
const WaterBallGrid = styled.div`
  margin: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  align-content: center;
  justify-content: center;
  align-items: center;
  justify-items: center;
  grid-row-gap: 100px;
  background-size: cover;
  @media (max-width: 1024px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;
const IndustryTitle = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  font-size: 1.8rem;
  font-weight: bold;
  margin-bottom: 10px;
  @media (max-width: 1024px) {
    font-size: 1.1rem;
    height: 1.1rem;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  width: 100%;
  height: 1.8rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
const MoreIndustryButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: nowrap;
  align-items: center;
  margin: 20px 20px 0px 20px;
  border-radius: 15px;
  width: 90%;
  background: #25758A;
  line-height: 3rem;
  font-weight: bold;
  color: #FFFFFF;
`;
const WaterBallItem = styled.div`
  background: #0000;
  width: 15vw;
  border:${p => p.isNeg == 1 ? "5px solid #970032" : "5px solid #009726"};
  border-radius: 50%;
  height: 15vw;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  @media (max-width: 1024px) {
    height: 30vw;
    width: 30vw;
  }
`;
const IllustrateText = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  padding-top: 20px;
  text-align: center;
`;
const SearchDashboard = styled(FormControl)`
  display: flex !important;
  flex-direction: row !important;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: flex-end;
  width: 50%;
  margin: 0px 0px 50px 0px !important;
`;
const BorderTab = styled(Tab)`
  border: 3px #25758A !important;
  border-radius: 15px !important;
  font-size: x-large !important;
  font-weight: 900 !important;
  color: black !important;
  height: 0px !important;
  background: ${(p) => (p.isSelected == 1 ? "#25758aa1 !important" : "#1111 !important")};
}
`;
const SelectContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  alignitems: cente;
}
`;
const CustSlect = styled(Select)`
  padding-right: 15px;
  font-size: 1.3rem;
}
`;
const SortContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  text-align: center;
}
`;