import React from "react";

import "./styles.scss";
import * as Style from "./styles";

export default (props) => {
  const { width, title, VisitTypeName, data, isForm, isPic } = props;
  const [state, setState] = React.useState({
    title: [],
  });

  React.useEffect(() => {
    const temp = [];

    if (isForm && data && VisitTypeName) {
      VisitTypeName.map((visit) => {
        temp.push({
          VisitTypeName: visit,
          TypeChildName: [],
        });
      });

      data.map((i) => {
        temp.map((temp) => {
          if (
            i.VisitTypeName === temp.VisitTypeName &&
            temp.TypeChildName.indexOf(i.TypeChildName) === -1
          ) {
            temp.TypeChildName.push(i.TypeChildName);
          }
        });
      });
    }
    setState((state) => ({
      ...state,
      title: temp.filter((i) => i.TypeChildName.length !== 0),
    }));
  }, [VisitTypeName]);

  return (
    <Style.PreviewPerRow width={width}>
      {title && <Style.PreviewEachTitle>{title}</Style.PreviewEachTitle>}
      {isForm ? (
        <Style.PreviewEachAnswer>
          {state.title.map((title, index) => {
            return (
              <Style.EachQuestionContainer key={"title" + index.toString()}>
                {title.TypeChildName.map((childName, index) => {
                  return (
                    <Style.EachQuestionList
                      key={"childName" + index.toString()}
                    >
                      <Style.EachQuestionContainerTitle>
                        {`${title.VisitTypeName} > ${childName}`}
                      </Style.EachQuestionContainerTitle>
                      <Style.EachQuestionListItem>
                        <ul>
                          {data.map((item, index) => {
                            if (
                              item.VisitTypeName === title.VisitTypeName &&
                              item.TypeChildName === childName &&
                              item.QuestionTypeName === "checkbox"
                            ) {
                              if (item.VisitTypeName === "汙水(環保組)") {
                                return (
                                  <li key={"item" + index.toString()}>
                                    {item.CheckAns === true
                                      ? index === 3
                                      ? `	• ${item.Content}： 無。`
                                      : `	• ${item.Content}： 是。`
                                      : index === 2 || index === 3
                                      ? `	• ${item.Content}： 異常 ${item.TextArea && `，${item.TextArea}`}。`
                                      : `	• ${item.Content}： 否 ${item.TextArea && `，${item.TextArea}`}。`
                                    }
                                  </li>
                                );
                              }
                              if (item.CheckAns === true) {
                                return (
                                  <li key={"item" + index.toString()}>
                                    {item.TextArea
                                      ? `	• ${item.Content}： ${item.TextArea}`
                                      : `	• ${item.Content}`}
                                  </li>
                                );
                              }
                            } else if (
                              item.VisitTypeName === title.VisitTypeName &&
                              item.TypeChildName === childName &&
                              item.QuestionTypeName === "textarea"
                            ) {
                              return (
                                <li key={"item" + index.toString()}>
                                  {item.TextArea
                                    ? ` • ${item.Content}： ${item.TextArea}`
                                    : `	• ${item.Content}`}
                                </li>
                              );
                            }
                          })}
                        </ul>
                      </Style.EachQuestionListItem>
                    </Style.EachQuestionList>
                  );
                })}
              </Style.EachQuestionContainer>
            );
          })}
        </Style.PreviewEachAnswer>
      ) : Array.isArray(isPic) ? (
        <Style.PreviewEachAnswer>
          <div style={{
            display: "flex",
            flexWrap: "wrap"
          }}>
          {
            isPic.map((item, index) => {
              return (
                <>
                  <img src={item} style={{ maxWidth: "350px" }} key={`item_${index}`} loading="lazy" alt="" />
                </>
              );
            })
          } 
          </div>
        </Style.PreviewEachAnswer>
      ) : (
        isPic?
        <Style.PreviewEachAnswer center>
          <img src={isPic} loading="lazy" alt="" />
        </Style.PreviewEachAnswer>
        : data !== "" && data !== undefined ? (
          <Style.PreviewEachAnswer>
            <p>{data + " "}</p>
          </Style.PreviewEachAnswer>
        ) : (
          <Style.PreviewEachAnswer>
            <p style={{ color: "#888888", fontSize: "80%" }}>{"無資料"}</p>
          </Style.PreviewEachAnswer>
        )
      )}
    </Style.PreviewPerRow>
  );
};
