export const cities = [
  "宜蘭縣",
  "基隆市",
  "臺北市",
  "新北市",
  // "釣魚臺",
  "桃園市",
  "新竹市",
  "新竹縣",
  "苗栗縣",
  "臺中市",
  "彰化縣",
  "南投縣",
  "雲林縣",
  "嘉義市",
  "嘉義縣",
  "臺南市",
  "高雄市",
  "屏東縣",
  "澎湖縣",
  "金門縣",
  "連江縣",
  "花蓮縣",
  "臺東縣",
];


export const northCities = [
  "宜蘭縣",
  "基隆市",
  "臺北市",
  "新北市",
  "桃園市",
  "新竹市",
  "新竹縣",
];

export const centerCities = [
  "苗栗縣",
  "臺中市",
  "彰化縣",
  "南投縣",
  "雲林縣",
];

export const southCities = [
  "嘉義市",
  "嘉義縣",
  "臺南市",
  "高雄市",
  "屏東縣",
  "澎湖縣",
  "金門縣",
  "連江縣",
];

export const eastCities = [
  "花蓮縣",
  "臺東縣",
];

export const surroundIslands = [
  "連江縣",
  // "釣魚臺",
  "南海島",
  "金門縣",
];

export const cityCode = [{"CityId":1,"CityName":"基隆市"},
            {"CityId":2,"CityName":"臺北市"},
            {"CityId":3,"CityName":"新北市"},
            {"CityId":4,"CityName":"桃園市"},
            {"CityId":5,"CityName":"新竹縣"},
            {"CityId":6,"CityName":"新竹市"},
            {"CityId":7,"CityName":"苗栗縣"},
            {"CityId":8,"CityName":"臺中市"},
            {"CityId":9,"CityName":"彰化縣"},
            {"CityId":10,"CityName":"南投縣"},
            {"CityId":11,"CityName":"雲林縣"},
            {"CityId":12,"CityName":"嘉義縣"},
            {"CityId":13,"CityName":"嘉義市"},
            {"CityId":14,"CityName":"臺南市"},
            {"CityId":15,"CityName":"高雄市"},
            {"CityId":16,"CityName":"屏東縣"},
            {"CityId":17,"CityName":"宜蘭縣"},
            {"CityId":18,"CityName":"花蓮縣"},
            {"CityId":19,"CityName":"臺東縣"},
            {"CityId":20,"CityName":"澎湖縣"},
            {"CityId":21,"CityName":"金門縣"}
            //{"CityId":22,"CityName":"連江縣"}
]

export const cityCodeMap = {
  "1":"基隆市",
  "2":"臺北市",
  "3":"新北市",
  "4":"桃園市",
  "5":"新竹縣",
  "6":"新竹市",
  "7":"苗栗縣",
  "8":"臺中市",
  "9":"彰化縣",
  "10":"南投縣",
  "11":"雲林縣",
  "12":"嘉義縣",
  "13":"嘉義市",
  "14":"臺南市",
  "15":"高雄市",
  "16":"屏東縣",
  "17":"宜蘭縣",
  "18":"花蓮縣",
  "19":"臺東縣",
  "20":"澎湖縣",
  "21":"金門縣"
  // ,"22":"連江縣"
}