export const northStaticData = {
  Name: "園管局臺北分局",
  EnglishName: "",
  Manager: ["梁又文", "分局長"],
  Type: 19,
  Size: "API加總",
  Address: "臺北市南港區園區街3-1號",
  link: "",
};

export const centerStaticData = {
  Name: "園管局臺中分局",
  EnglishName: "",
  Manager: ["紀世宗", "分局長"],
  Type: 16,
  Size: "API加總",
  Address: "臺中市潭子區建國路1號",
  link: "",
};

export const southStaticData = {
  Name: "園管局臺南分局",
  EnglishName: "",
  Manager: ["曹家文", "分局長"],
  Type: 15,
  Size: "API加總",
  Address: "臺南市安南區科技公園大道1號",
  link: "",
};

export const kPStaticData = {
  Name: "園管局高屏分局",
  EnglishName: "",
  Manager: ["游淑惠", "分局長"],
  Type: 11,
  Size: "API加總",
  Address: "高雄市前鎮區中一路2號",
  link: "",
};
