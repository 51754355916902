import React, { useState, useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import { NavLink, useHistory } from "react-router-dom";
import { NavHashLink as Link } from "react-router-hash-link";
import clear from "../../assests/clear.svg";
import { AuthContext } from "../../context/auth-context";
import { Authenticated } from "../../utility/api";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import "../navigationBar/navigationBar.css";
import "./styles.scss";
import { randomMath } from "../../utility/common";

const MySwal = withReactContent(Swal);

const NavigationBar = (props) => {
  const { origin } = window.location;
  const [isShow, setIsShow] = useState(localStorage.getItem("isLogin"));
  const [state, setState] = React.useState({
    menuList: [],
  });
  const { ParkId } = useSelector((state) => state.user.userInfo);

  const history = useHistory();

  const url = window.location.href.split("/");

  useEffect(() => {
    setIsShow(localStorage.getItem("isLogin"));
  }, [localStorage.getItem("isLogin")]);

  const closeMenu = () => {
    props.menuHandler();
  };

  const scrollWithOffset = (el, offset) => {
    const elementPosition = el.offsetTop - 100;
    el.scrollIntoView({
      top: elementPosition,
      left: 0,
      behavior: "smooth",
      block: "center",
    });
  };

  const redirect = (e) => {
    if (localStorage.getItem("isLogin") !== "true") {
      MySwal.fire({
        title: <p>提醒</p>,
        text: "尚未登入，請登入或註冊帳號。",
        showCancelButton: true,
        confirmButtonText: "前往註冊",
        cancelButtonText: "關閉",
      }).then((res) => {
        if (res.value) {
          history.push("/sign-up");
          closeMenu();
        } else {
          return;
        }
      });
    } else {
      e.preventDefault();
      MySwal.fire({
        title: <p>提醒</p>,
        text: "您無操作此功能權限，請聯絡管理員。",
        confirmButtonText: "確認",
      }).then((res) => {
        if (res.value) {
          return;
        } else {
          return;
        }
      });
    }
  };

  React.useEffect(() => {
    let tempParkId = "HIP015";
    if (ParkId && ParkId !== "other") {
      tempParkId = ParkId;
    }

    const updateMenuList = [
      {
        name: "首頁",
        url: "/portal",
        func: () => {},
        activeClassName: "selected",
        scroll: () => {},
        subItems: [],
      },
      {
        key: "",
        name: "儀表板",
        url: `/park_dashboard/${tempParkId}`,
        func: () => {},
        activeClassName: "selected",
        scroll: () => {},
        subItems: [
          {
            key: "section",
            name: "園區儀表板",
            url: `/park_dashboard/${tempParkId}`,
            func: () => {},
            activeClassName: "",
            scroll: () => {},
            isSmooth: false,
          },
          {
            key: "dashboard",
            name: "分區儀表板",
            url: `/area_dashboard`,
            func: localStorage.getItem("2")
              ? () => {}
              : (e) => {
                  redirect(e);
                },
            activeClassName: "",
            scroll: () => {},
            isSmooth: false,
          },
          {
            key: "dashboard",
            name: "縣市儀表板",
            url: `/city_dashboard`,
            func: localStorage.getItem("2")
              ? () => {}
              : (e) => {
                  redirect(e);
                },
            activeClassName: "",
            scroll: () => {},
            isSmooth: false,
          },
          {
            key: "company_search",
            name: "產業儀表板",
            url: `/industry_index/1810`,
            func: localStorage.getItem("5")
              ? () => {}
              : (e) => {
                  redirect(e);
                },
            activeClassName: "",
            scroll: () => {},
            isSmooth: false,
          },
          {
            key: "dashboard",
            name: "自訂關注廠商儀表板",
            url: `/custom_dashboard`,
            func: () => {},
            activeClassName: "",
            scroll: () => {},
            isSmooth: false,
          },
          {
            key: "event_dashboard",
            name: "受事件影響儀表板",
            url: `/event_dashboard`,
            func: () => {},
            activeClassName: "",
            scroll: () => {},
            isSmooth: false,
          },
          {
            key: "custom_industry_dashboard",
            name: "自訂關注產業儀表板",
            url: `/custom_industry_dashboard`,
            func: () => {},
            activeClassName: "",
            scroll: () => {},
            isSmooth: false
          },
          {
            key: "operations_dashboard",
            name: "產業營運動態儀表板",
            url: `/operations_dashboard/`,
            func: () => {},
            activeClassName: "",
            scroll: () => {},
            isSmooth: false,
          },
          // {
          //   key: "company_search",
          //   name: "受事件影響儀表板",
          //   url: `/effected_event_dashboard`,
          //   func: localStorage.getItem("20") ? () => {} : (e) => {redirect(e);},
          //   activeClassName: "",
          //   scroll: () => {},
          //   isSmooth: false
          // },
        ],
      },
      {
        key: "",
        name: "快搜服務",
        url: `/map-search`,
        func: () => {},
        activeClassName: "selected",
        scroll: () => {},
        subItems: [
          {
            key: "company_search",
            name: "技術快搜",
            url: "/map-search",
            func: localStorage.getItem("9")
              ? () => {}
              : (e) => {
                  redirect(e);
                },
            activeClassName: "",
            scroll: () => {},
            isSmooth: false,
          },
          {
            key: "controlled-search",
            name: "廢棄物快搜",
            url: `/wasted_landing_page`,
            func: () => {},
            activeClassName: "",
            scroll: () => {},
            isSmooth: false,
          },
          {
            key: "supplychain-search",
            name: "產業鏈快搜",
            url: `/supply-chain`,
            func: () => {},
            activeClassName: "",
            scroll: () => {},
            isSmooth: false,
          },
          {
            key: "tax_info",
            name: "複合條件快搜",
            url: `/tax_info`,
            func: () => {},
            activeClassName: "",
            scroll: () => {},
            isSmooth: false,
          },
        ],
      },
      {
        key: "company_search",
        name: "個廠情資",
        url: "/factory_search",
        func: () => {},
        activeClassName: "selected",
        scroll: () => {},
        subItems: [],
      },

      // {
      //   key: "company_search",
      //   name: "產業儀表板",
      //   url: `/industry_index/1810`,
      //   func: localStorage.getItem("5") ? () => {} : (e) => {redirect(e);},
      //   activeClassName: "selected",
      //   scroll: () => {},
      //   subItems: [
      //     {
      //       key: "about",
      //       name: "總覽",
      //       url: `/industry_index/1810#over-view`,
      //       func: ()=>{},
      //       activeClassName: "",
      //       scroll: (el) => scrollWithOffset(el, 0),
      //       isSmooth: true,
      //     },
      //     {
      //       key: "dashboard",
      //       name: "產業趨勢",
      //       url: `/industry_index/1810#trending`,
      //       func: () => {},
      //       activeClassName: "",
      //       scroll: (el) => scrollWithOffset(el, 100),
      //       isSmooth: true
      //     },
      //     {
      //       key: "dashboard_advance",
      //       name: "標竿廠商",
      //       url: `/industry_index/1810#role-model`,
      //       func: () => {},
      //       activeClassName: "",
      //       scroll: (el) => scrollWithOffset(el, 150),
      //       isSmooth: true
      //     },
      //     {
      //       key: "company_search",
      //       name: "專利研發",
      //       url: `/industry_index/1810#patent`,
      //       func: () => {},
      //       activeClassName: "",
      //       scroll: (el) => scrollWithOffset(el, 150),
      //       isSmooth: true
      //     },
      //   ],
      // },
      {
        key: "company_search",
        name: "政府資源",
        url: "/gov_plan_display",
        func: () => {},
        activeClassName: "selected",
        scroll: () => {},
        subItems: [],
      },
      {
        key: "company_search",
        name: "關注訂閱",
        url: "/coporaiton_tec/",
        func: localStorage.getItem("6")
          ? () => {}
          : (e) => {
              redirect(e);
            },
        activeClassName: "selected",
        scroll: () => {},
        subItems: [
          {
            key: "settings-gov-plan-management",
            name: "新聞訂閱",
            url: `/coporaiton_tec/`,
            func: localStorage.getItem("12")
              ? () => {}
              : (e) => {
                  redirect(e);
                },
            activeClassName: "",
            // scroll: (el) => scrollWithOffset(el, 0),
            isSmooth: false,
          },
          {
            key: "settings-gov-plan-management",
            name: "關注廠商/產業訂閱",
            url: `/subscribe_push/`,
            func: () => {},
            // scroll: (el) => scrollWithOffset(el, 0),
            isSmooth: false,
          },
          // {
          //   key: "settings-gov-plan-management",
          //   name: "關注產業訂閱",
          //   url: `/industry_subscribe/`,
          //   func: localStorage.getItem("11") ? () => {} : (e) => {redirect(e);},
          //   activeClassName: "",
          //   // scroll: (el) => scrollWithOffset(el, 0),
          //   isSmooth: false,
          // },
        ],
      },
      {
        key: "company_search",
        name: "廠商需求",
        url: "/client-response/query",
        func: () => {},
        activeClassName: "selected",
        scroll: () => {},
        subItems: [],
      },
    ];

    if (localStorage.getItem("17")) {
      updateMenuList.push({
        key: "questionnaire",
        name: "訪廠問卷",
        url: "/questionnaire-management",
        func: localStorage.getItem("17")
          ? () => {}
          : (e) => {
              redirect(e);
            },
        activeClassName: "selected",
        scroll: () => {},
        subItems: [],
      });
    }
    updateMenuList.push({
      key: "sitemap",
      name: "網站導覽",
      url: "/sitemap",
      func: () => {},
      activeClassName: "selected",
      scroll: () => {},
      subItems: [],
    });

    if (
      origin === "http://localhost:3000" ||
      origin === "https://idpark-stage.azurewebsites.net"
    ) {
      updateMenuList.push({
        key: "settings",
        name: "後台設定",
        url: "/gov_plan_management",
        func: () => {},
        activeClassName: "selected",
        scroll: () => {},
        subItems: [
          {
            key: "settings-gov-plan-management",
            name: "政府資源管理",
            url: `/gov_plan_management/gov_plan`,
            func: localStorage.getItem("14")
              ? () => {}
              : (e) => {
                  MySwal.fire({
                    title: <p>提醒</p>,
                    text: "尚未登入，請登入或註冊帳號。",
                    showCancelButton: true,
                    confirmButtonText: "前往註冊",
                    cancelButtonText: "關閉",
                  });
                  return e.preventDefault();
                },
            activeClassName: "",
            // scroll: (el) => scrollWithOffset(el, 0),
            isSmooth: true,
          },
          {
            key: "settings-notification-settings",
            name: "政府資源推播設定",
            url: `/gov_plan_management/delivery`,
            func: localStorage.getItem("15")
              ? () => {}
              : (e) => {
                  MySwal.fire({
                    title: <p>提醒</p>,
                    text: "尚未登入，請登入或註冊帳號。",
                    showCancelButton: true,
                    confirmButtonText: "前往註冊",
                    cancelButtonText: "關閉",
                  });
                  return e.preventDefault();
                },
            activeClassName: "",
            // scroll: (el) => scrollWithOffset(el, 100),
            isSmooth: true,
          },
        ],
      });
    }

    setState((state) => ({ ...state, menuList: updateMenuList }));
  }, [origin, ParkId]);

  return (
    <div className="nav-bar">
      <nav className="navigationBar">
        <ul>
          {state.menuList.map((item) => (
            <li key={randomMath()}>
              <NavLink
                to={item.url}
                activeClassName={item.activeClassName}
                onClick={item.func}>
                {item.name}
                <ul className="subItem">
                  {item.subItems.length > 0 &&
                    isShow &&
                    item.subItems.map((sub) => (
                      <li key={`subItem_${randomMath()}`}>
                        <Link
                          smooth={sub.isSmooth}
                          scroll={sub.scroll}
                          to={sub.url}
                          onClick={sub.func}>
                          {sub.name}
                        </Link>
                      </li>
                    ))}
                </ul>
              </NavLink>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  );
};

export default NavigationBar;
