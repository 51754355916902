import React from "react";
import ReactExport from "react-data-export";
import Card from "@material-ui/core/Card";
import Icon from "@material-ui/core/Icon";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import Pagination from '@material-ui/lab/Pagination';
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import Spinner from "../../components/Spinner/Spinner";
import { ScrollContainer } from "../../utility/styles";
import { thousandDivider, directToNewTabHandler } from "../../utility/common";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import CustomizedCardV2 from "../../components/CustomizedCard/CustomizedCardV2";
import MainIndex from "../../components/MainIndex/MainIndex";

import {
  handleTaxInfoSearch,
  handleExportProductFourCodeList,
  handleTaxIndustryList,
  handleTypeTwoList,
  handleTypeThreeList,
} from "../../store/actions/taxInfo";
import { useSelector, useDispatch } from "react-redux";
import BackToTopButton from "../../components/BackToTopButton/BackToTopButtonV2";

import { cityCode } from "../../constants/cities";
import { typeFour } from "../../constants/types";

import * as Style from "./styles";
import TaxSelect from "./TaxSelect";
import styled from "styled-components";
import ApplyFile from "../../components/ApplyFile/ApplyFile";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { applyFileParameter } from "../../constants/applyFileParameter";

export default (props) => {
  const containerRef = React.useRef(null);
  const dispatch = useDispatch();
  const {
    getTaxInfoSearchData,
    getTaxInfoSearchSummaryData,
    getTaxInfoSearchDataLoading,
    getExportProductFourCodeList,
    getExportProductFourCodeListLoading,
    getTaxIndustryList,
    getTaxIndustryListLoading,
    getTypeTwoList,
    getTypeTwoListLoading,
    getTypeThreeList,
    getTypeThreeListLoading,
  } = useSelector((state) => state.taxInfo);

  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


  const MySwal = withReactContent(Swal);
  const { applyFileInfo, getApplyFileLoading } = useSelector((state) => state.applyFile);

  const date = new Date();

  let day = date.getDate();
  let month = ("0" + (date.getMonth() + 1)).slice(-2)
  let year = date.getFullYear();

  let currentDate = `${year}${month}${day}`;

  const [state, setState] = React.useState({
    keyWordList: "",
    operator: "OR",
    productFourCode: [],
    taxCode: [],
    industryTwoCode: [],
    industryThreeCode: [],
    industryCode: [],
    cityCode: [],
    minCapital: 0,
    maxCapital: 0,
    page: 0,
    rowsPerPage: 20,
    isAdvance: false,
    restartDropdown: 1,
  });

  const [applyFileModalOpen, setApplyFileModalOpen] = React.useState(false);
  const [sweetAlertMessage, setSweetAlertMessage] = React.useState("");
  const [nextMonth, setNextMonth] = React.useState("");
  React.useEffect(() => {
    var today = new Date();
    today.setDate(1);
    today.setMonth(today.getMonth() + 1);
    const usegeStr = applyFileInfo.Usege_Monthly ? `(已匯出${applyFileInfo.Usege_Monthly}次)` : ''
    setNextMonth(new Date(today));
    setSweetAlertMessage(
      getTaxInfoSearchData.length > 500
        ? `僅匯出搜尋結果前500筆(按資本額排行)，是否要繼續？<br/>備註：1個月內僅限匯出2次EXCEL` + usegeStr
        : '1個月內僅限匯出2次EXCEL' + usegeStr
    );
  }, [getTaxInfoSearchData.length]);

  React.useEffect(() => {
    dispatch(handleExportProductFourCodeList());
    dispatch(handleTaxIndustryList());
    dispatch(handleTypeTwoList());
    dispatch(handleTypeThreeList());
  }, []);
  const [value, setValue] = React.useState(0);
  const uid = localStorage.getItem("uid");


  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  const updateSearchParams = (event, newPage) => {
    if ((!event.currentTarget.parentNode.parentNode.parentNode.id.includes("topPage")) && (!event.currentTarget.id.includes("topPage"))) {
      document.getElementById("taxInfoContent").scrollTo({
        top: 0,
        behavior: "smooth"
      })
    }
    if (event.nativeEvent.type == 'input') {
      const previousPage = state.page + 1;
      newPage = isNaN(event.currentTarget.value) ? previousPage : parseInt(event.currentTarget.value);
      if (newPage > Math.ceil(getTaxInfoSearchData.length / state.rowsPerPage)) {
        newPage = Math.ceil(getTaxInfoSearchData.length / state.rowsPerPage);
      }
      if (newPage <= 0) {
        newPage = 1;
      }
      if (!isNaN(newPage)) {
        setState((state) => ({
          ...state,
          page: newPage - 1,
        }));
      }
      else {
        setState((state) => ({
          ...state,
          page: previousPage - 1,
        }));
      }
    }
    else {
      if (newPage !== undefined) {
        setState((state) => ({
          ...state,
          page: newPage - 1,
        }));
      }
    }
  };

  const NoDataTableRow = (props) => {
    return (
      <TableRow className="table-row-no-data">
        <TableCell>{props.children}</TableCell>
      </TableRow>
    );
  };

  const CompanySearch = () => {
    localStorage.setItem("taxInfo", JSON.stringify(state));
    localStorage.removeItem("mapSearchEdu");
    localStorage.removeItem("mapSearch");
    dispatch(handleTaxInfoSearch(state));

    setState((state) => ({
      ...state,
      page: 0,
    }));
  };

  React.useEffect(() => {
    setState((state) => ({
      ...state,
      restartDropdown: 1,
    }))
  }, [state.restartDropdown]);
  const RestartCondition = () => {
    setState((state) => ({
      ...state,
      keyWordList: "",
      operator: "OR",
      productFourCode: [],
      taxCode: [],
      industryTwoCode: [],
      industryThreeCode: [],
      industryCode: [],
      cityCode: [],
      minCapital: 0,
      maxCapital: 0,
      page: 0,
      rowsPerPage: 20,
      isAdvance: false,
      restartDropdown: 0,
    }));
  };

  const toggleIsAdvance = () => {
    setState((state) => ({
      ...state,
      isAdvance: !state.isAdvance,
    }));
  };

  const scrollToTop = () => {
    containerRef.current.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleChange = (event, values, type) => {
    if (type === "operator" || type === "keyWordList" || type === "minCapital" || type === "maxCapital") {
      setState((state) => ({
        ...state,
        [type]: values,
      }));
    } else {
      const tempCode = [];
      if (values.length > 0) {
        values.map((item) => {
          tempCode.push(item.Code || item.CityId || item.Key);
        });
      }

      if (values.length > 3) {
        alert("最多選擇3筆");
        values.pop();
        return;
      }

      if (type === "productFourCode") {
        setState((state) => ({
          ...state,
          productFourCode: tempCode,
        }));
      }
      if (type === "taxCode") {
        setState((state) => ({
          ...state,
          taxCode: tempCode,
        }));
      }
      if (type === "industryTwoCode") {
        setState((state) => ({
          ...state,
          industryTwoCode: tempCode,
        }));
      }
      if (type === "industryThreeCode") {
        setState((state) => ({
          ...state,
          industryThreeCode: tempCode,
        }));
      }
      if (type === "industryCode") {
        setState((state) => ({
          ...state,
          industryCode: tempCode,
        }));
      }
      if (type === "cityCode") {
        setState((state) => ({
          ...state,
          cityCode: tempCode,
        }));
      }
    }
  };


  const openApplyFile = () => {
    setApplyFileModalOpen(true);
  }

  const openApplyFileHandler = () => {
    if (applyFileInfo.Usege_Monthly < applyFileInfo.Usege_Limit) {
      MySwal.fire({
        title: "提示",
        showCancelButton: true,
        html: sweetAlertMessage,
        confirmButtonText: '確認',
        cancelButtonText: '取消'
      }).then((res) => {
        if (res.isConfirmed) {
          openApplyFile();
        }
      });
    }
    else {
      MySwal.fire({
        title: "提示",
        html: `超出本月使用量<br>下次可匯出EXCEL日期：${nextMonth.toISOString().split("T")[0] + "  00:00"}<br/>如果你有任何需求與問題，請撥打${applyFileParameter.phone} ${applyFileParameter.nickNmae}`,
        confirmButtonText: '確認',
      })
    }
  };

  return (
    <ScrollContainer ref={containerRef} id="taxInfoContent">
      <ApplyFile
        isOpen={applyFileModalOpen}
        closeModal={() => {
          setApplyFileModalOpen(false);
        }}
        data={getTaxInfoSearchData}
        source="1"
        dataLimit={applyFileInfo}
      />
      {window.innerWidth < 1024 && (
        <BackToTopButton scrollToTop={scrollToTop} />
      )}
      <Style.Card>
        <Style.TopContainer>
          <Style.TopLeftContainer>
            <Style.ContainerText>複合條件快搜</Style.ContainerText>
          </Style.TopLeftContainer>
          <Style.TopRightContainer>
            <Style.SearchField>
              <Style.SearchTopContainer>
                <Style.SearchTextField
                  id="outlined-basic"
                  label={
                    window.innerWidth < 1024
                      ? "請輸入關鍵字並使用逗號分隔"
                      : "請輸入關鍵字並使用逗號分隔（最多五個），範例: 五金, 鋼鐵"
                  }
                  variant="outlined"
                  name={"keyWordList"}
                  large
                  style={{
                    marginRight: "1%",
                    marginBottom: window.innerWidth < 1024 && "5%",
                  }}
                  onChange={(event) =>
                    handleChange(event, event.target.value, "keyWordList")
                  }
                  value={state.keyWordList}
                />
                <Style.FormControlInherit>
                  <FormLabel id="demo-radio-buttons-group-label">
                    產品關鍵字查詢條件
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="OR"
                    name={"operator"}
                    onChange={(event, values) =>
                      handleChange(event, values, "operator")
                    }
                    value={state.operator}
                  >
                    <FormControlLabel value="OR" control={<Radio />} label="OR" />
                    <FormControlLabel
                      value="AND"
                      control={<Radio />}
                      label="AND"
                    />
                  </RadioGroup>
                </Style.FormControlInherit>
                <Style.RestartButton>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{
                      height: "35px",
                      width: "90%",
                    }}
                    startIcon={<Icon>restart_alt</Icon>}
                    onClick={RestartCondition}
                  >
                    重設條件
                  </Button>
                </Style.RestartButton>
              </Style.SearchTopContainer>
            </Style.SearchField>
            <Style.SearchContainer>
              <Style.SearchTopContainer>
                <TaxSelect
                  label={"行業標準分類(中類)"}
                  name={"industryTwoCode"}
                  loading={getTypeTwoListLoading}
                  dataList={getTypeTwoList}
                  handleChange={(event, values) =>
                    handleChange(event, values, "industryTwoCode")
                  }
                  state={state.industryTwoCode}
                />
                <TaxSelect
                  label={"行業標準分類(小類)"}
                  name={"industryThreeCode"}
                  loading={getTypeThreeListLoading}
                  dataList={getTypeThreeList}
                  handleChange={(event, values) =>
                    handleChange(event, values, "industryThreeCode")
                  }
                  state={state.industryThreeCode}
                />
                <TaxSelect
                  label={"行業標準分類(細類)"}
                  name={"industryCode"}
                  loading={getTaxIndustryListLoading}
                  dataList={typeFour}
                  handleChange={(event, values) =>
                  (console.log(event, values),
                    handleChange(event, values, "industryCode"))
                  }
                  state={state.industryCode}
                />
                <TaxSelect
                  label={"稅務行業分類"}
                  name={"taxCode"}
                  dataList={getTaxIndustryList}
                  loading={getTaxIndustryListLoading}
                  handleChange={(event, values) =>
                    handleChange(event, values, "taxCode")
                  }
                  value={state.taxCode}
                />
              </Style.SearchTopContainer>
            </Style.SearchContainer>
            {state.isAdvance && (
              <Style.SearchContainer>
                <Style.SearchTopContainer>
                  <TaxSelect
                    label={"公司登記縣市"}
                    name={"cityCode"}
                    dataList={cityCode}
                    handleChange={(event, values) =>
                      handleChange(event, values, "cityCode")
                    }
                    value={state.cityCode}
                  />
                  <TaxSelect
                    label={"出口貨品代碼"}
                    name={"productFourCode"}
                    dataList={getExportProductFourCodeList}
                    loading={getExportProductFourCodeListLoading}
                    handleChange={(event, values) =>
                      handleChange(event, values, "productFourCode")
                    }
                    value={state.productFourCode}
                  />
                  <Style.SearchCapitalField
                    id="outlined-basic"
                    label={"資本額下限"}
                    type="number"
                    variant="outlined"
                    name={"minCapital"}
                    inputProps={{ min: 0 }}
                    normal
                    onChange={(event) =>
                      handleChange(event, event.target.value, "minCapital")
                    }
                    value={state.minCapital}
                  />
                  <Style.SearchCapitalField
                    id="outlined-basic"
                    label={"資本額上限"}
                    type="number"
                    variant="outlined"
                    name={"maxCapital"}
                    inputProps={{ min: 0 }}
                    onChange={(event) =>
                      handleChange(event, event.target.value, "maxCapital")
                    }
                    value={state.maxCapital}
                  />
                </Style.SearchTopContainer>
              </Style.SearchContainer>
            )}
            <Style.ButtonContainer>
              <Style.SearchBotton>
                <Button
                  variant="contained"
                  color="primary"
                  style={{
                    height: "35px",
                    width: "90%",
                  }}
                  startIcon={<Icon>{state.isAdvance ? "remove" : "add"}</Icon>}
                  onClick={toggleIsAdvance}
                >
                  {state.isAdvance ? "簡易搜尋" : "進階搜尋"}
                </Button>
              </Style.SearchBotton>
              <Style.SearchBotton>
                <Button
                  variant="contained"
                  color="primary"
                  style={{
                    height: "35px",
                    width: "90%",
                  }}
                  startIcon={<Icon>search</Icon>}
                  onClick={CompanySearch}
                >
                  查詢
                </Button>
              </Style.SearchBotton>
            </Style.ButtonContainer>
          </Style.TopRightContainer>
          {getTaxInfoSearchData.length > 0 && window.innerWidth < 1024 && (
            <div>
              <Button
                variant="contained"
                color="default"
                startIcon={<Icon>download</Icon>}
                style={{
                  marginTop: "10px",
                  height: "35px",
                  width: "200px",
                }}
                onClick={openApplyFileHandler}
              >
                匯出 Excel 報表
              </Button>
            </div>
          )}
        </Style.TopContainer>
        <Style.BottomContainer>
          <Tabs value={value} onChange={handleChangeTab} aria-label="icon tabs example">
            <BorderTab icon={<Icon>description</Icon>} aria-label="description" />
            <BorderTab icon={<Icon>table_view</Icon>} aria-label="table_view" />
          </Tabs>
          {window.innerWidth < 1024 ? (
            <React.Fragment>
              {value == 0 && getTaxInfoSearchSummaryData.length != 0 && (
                <CustomizedCardV2
                  title="概況"
                  isOpen={true}
                  loading={getTaxInfoSearchDataLoading}
                  noSouthArea
                >
                  <MainIndex
                    data={getTaxInfoSearchSummaryData}
                    isTaxInfo
                  />
                </CustomizedCardV2>
              )}
              {value == 1 && (
                getTaxInfoSearchData.slice(0, 10).map((content, index) => {
                  var tempfollow = content.TaxIndustry?.split(",");
                  return (
                    <Style.MobileContainer key={index.toString()}>
                      <Style.MobileItem>
                        <Style.MobileItemTitle>統一編號 </Style.MobileItemTitle>
                        <Style.MobileItemContent>
                          {content.BusinessNo}
                        </Style.MobileItemContent>
                      </Style.MobileItem>
                      <Style.MobileItem>
                        <Style.MobileItemTitle>廠商名稱 </Style.MobileItemTitle>
                        <Style.MobileItemContentLink
                          onClick={() =>
                            directToNewTabHandler(
                              "factory_info/basic_info",
                              content.BusinessNo
                            )
                          }
                        >
                          {content.CompanyName}
                        </Style.MobileItemContentLink>
                      </Style.MobileItem>
                      <Style.MobileItem>
                        <Style.MobileItemTitle>廠商電話 </Style.MobileItemTitle>
                        <Style.MobileItemContent>
                          {content.Tel}
                        </Style.MobileItemContent>
                      </Style.MobileItem>
                      <Style.MobileItem>
                        <Style.MobileItemTitle>資本額 </Style.MobileItemTitle>
                        <Style.MobileItemContent>
                          {thousandDivider(content.CapitalTotal)}
                        </Style.MobileItemContent>
                      </Style.MobileItem>
                      <Style.MobileItem>
                        <Style.MobileItemTitle>公司登記縣市</Style.MobileItemTitle>
                        <Style.MobileItemContent>
                          {content.CityName}
                        </Style.MobileItemContent>
                      </Style.MobileItem>
                      <Style.MobileItem>
                        <Style.MobileItemTitle>
                          稅務行業分類{" "}
                        </Style.MobileItemTitle>
                        <Style.MobileItemContent>
                          {tempfollow?.map((item) => {
                            return <p>{item}</p> || "未填寫";
                          })}
                        </Style.MobileItemContent>
                      </Style.MobileItem>
                    </Style.MobileContainer>
                  );
                })
              )}
            </React.Fragment>
          ) : (
            <>
              {value == 0 && (
                (getTaxInfoSearchData.length === 0 && !getTaxInfoSearchDataLoading) ? (
                  <Table component={Card}>
                    <TableBody>
                      <NoDataTableRow>
                        <TableCell>目前沒有資料</TableCell>
                      </NoDataTableRow>
                    </TableBody>
                  </Table>
                ) : (
                  <CustomizedCardV2
                    title="概況"
                    isOpen={true}
                    loading={getTaxInfoSearchDataLoading}
                    noSouthArea
                  >
                    <MainIndex
                      data={getTaxInfoSearchSummaryData}
                      isTaxInfo
                    />
                  </CustomizedCardV2>
                )
              )}
              {value == 1 && (
                <>
                  <Table
                    component={Card}
                    className="table"
                    style={
                      getTaxInfoSearchData.length === 0
                        ? { marginBottom: "0px" }
                        : { textAlign: "center" }
                    }
                  >
                    <Style.TableHeader>
                      <TableRow>
                        <TableCell width="10%">統一編號</TableCell>
                        <TableCell width="20%">廠商名稱</TableCell>
                        <TableCell width="10%">廠商電話</TableCell>
                        <TableCell width="15%">資本額</TableCell>
                        <TableCell width="10%">公司登記縣市</TableCell>
                        <TableCell width="30%">稅務行業分類</TableCell>
                      </TableRow>
                    </Style.TableHeader>
                    {getTaxInfoSearchData.length > 0 && (
                      <TableHead>
                        <TableRow>
                          {localStorage.getItem("auL") <= 3 ? (
                            <Style.NormalCell colspan={3}>
                              <Button
                                variant="contained"
                                color="primary"
                                startIcon={<Icon>download</Icon>}
                                style={{
                                  height: "35px",
                                  width: "200px",
                                }}
                                onClick={openApplyFileHandler}
                              >
                                匯出 Excel 報表
                              </Button>
                            </Style.NormalCell>
                          ) : (
                            ""
                          )
                          }
                          <Style.NormalCell colspan={3} align={"right"} style={{ verticalAlign: 'middle', fontSize: 'large' }} >
                            <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-end", alignItems: "flex-end" }}>
                              <div style={{ display: "flex", flexDirection: "row", paddingRight: "15px", justifyContent: "flex-end" }}>
                                <Style.ElevatorTextField
                                  value={state.page + 1}
                                  variant="outlined"
                                  large
                                  onChange={updateSearchParams}
                                  id="topPageInputControl"
                                />
                                <span>/{Math.ceil(getTaxInfoSearchData.length / state.rowsPerPage)}頁</span>
                              </div>
                              <Pagination
                                count={Math.ceil(getTaxInfoSearchData.length / state.rowsPerPage)}
                                shape="primary"
                                page={state.page + 1}
                                onChange={updateSearchParams}
                                siblingCount={1}
                                style={{ float: 'right' }}
                                id="topPageControl"
                              />
                            </div>
                          </Style.NormalCell>
                        </TableRow>
                      </TableHead>
                    )}
                    <TableBody>
                      {getTaxInfoSearchData.length !== 0 &&
                        !getTaxInfoSearchDataLoading &&
                        getTaxInfoSearchData
                          .slice(
                            state.page * state.rowsPerPage,
                            state.page * state.rowsPerPage + state.rowsPerPage
                          )
                          .map((item, index) => {
                            var tempfollow = item.TaxIndustry?.split(",");
                            return (
                              <TableRow key={index.toString()} index={index} hover>
                                <Style.SmallCell>{item.BusinessNo}</Style.SmallCell>
                                <Style.LargeCellLink
                                  onClick={() =>
                                    directToNewTabHandler(
                                      "factory_info/basic_info",
                                      item.BusinessNo
                                    )
                                  }
                                >
                                  {item.CompanyName}
                                </Style.LargeCellLink>
                                <Style.NormalCell>{item.Tel}</Style.NormalCell>
                                <Style.NormalCell>
                                  {thousandDivider(item.CapitalTotal)}
                                </Style.NormalCell>
                                <Style.NormalCell>{item.CityName}</Style.NormalCell>
                                <Style.LargeCell>
                                  {tempfollow?.map((item) => {
                                    return <p>{item}</p>;
                                  }) || "無項目"}
                                </Style.LargeCell>
                              </TableRow>
                            );
                          })}
                      {getTaxInfoSearchDataLoading &&
                        <TableRow>
                          <TableCell colspan='6'>
                            <Spinner />
                          </TableCell>
                        </TableRow>
                      }
                    </TableBody>
                    {getTaxInfoSearchData.length > 0 && (
                      <TableFooter>
                        <TableRow>
                          {localStorage.getItem("auL") <= 3 ? (
                            <Style.NormalCell colspan={3}>
                              <Button
                                variant="contained"
                                color="primary"
                                startIcon={<Icon>download</Icon>}
                                style={{
                                  height: "35px",
                                  width: "200px",
                                }}
                                onClick={openApplyFileHandler}
                              >
                                匯出 Excel 報表
                              </Button>
                            </Style.NormalCell>
                          ) : (
                            ""
                          )
                          }
                          <Style.NormalCell colspan={3} align={"right"} style={{ verticalAlign: 'middle', fontSize: 'large' }} >
                            <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-end", alignItems: "flex-end" }}>
                              <div style={{ display: "flex", flexDirection: "row", paddingRight: "15px", justifyContent: "flex-end" }}>
                                <Style.ElevatorTextField
                                  value={state.page + 1}
                                  variant="outlined"
                                  large
                                  onChange={updateSearchParams}
                                />
                                <span>/{Math.ceil(getTaxInfoSearchData.length / state.rowsPerPage)}頁</span>
                              </div>
                              <Pagination
                                count={Math.ceil(getTaxInfoSearchData.length / state.rowsPerPage)}
                                shape="primary"
                                page={state.page + 1}
                                onChange={updateSearchParams}
                                siblingCount={1}
                                style={{ float: 'right' }}
                              />
                            </div>
                          </Style.NormalCell>
                        </TableRow>
                      </TableFooter>
                    )}
                  </Table>
                  {getTaxInfoSearchData.length === 0 && !getTaxInfoSearchDataLoading && (
                    <Table component={Card}>
                      <TableBody>
                        <NoDataTableRow>
                          <TableCell>目前沒有資料</TableCell>
                        </NoDataTableRow>
                      </TableBody>
                    </Table>
                  )}
                </>
              )}
            </>
          )}
        </Style.BottomContainer>
      </Style.Card>
    </ScrollContainer>
  );
};

const BorderTab = styled(Tab)`
  border: 3px dashed #25758A !important;
`
