import React from 'react'

import "./styles.scss";


const Spinner = () => { 
    return(
        <div className='spinner' id="spinner">Loading...</div>
    )
}


export default Spinner