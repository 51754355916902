import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

import CityDashboardHeader from "../../components/CityDashboardHeader/CityDashboardHeader";
import CustomizedCard from "../../components/CustomizedCard/CustomizedCardV3";
import CustomizedGraphic from "../../components/CustomizedGraphic/CustomizedGraphicV2";
import CustomizedTable from "../../components/CustomizedTable/CustomizedTableV2";
import TaiwanInvestmentTable from "../../components/CustomizedTable/TaiwanInvestmentTable";
import FactoryAreaRentTable from "../../components/CustomizedTable/FactoryAreaRentTable";
import CustomizedPublicStock from "../../components/CustomizedPublicStock/CustomizedPublicStock";
import NewsInfoTable from "../../components/CustomizedTable/NewsInfoTable";
import GreenFactoryTable from "../../components/CustomizedTable/GreenFactoryTable";
import CityDashboardBriefInfo from "../../components/CiyDashboardBriefInfo/CiyDashboardBriefInfo";
import StartUpCompanyTable from "../../components/CustomizedTable/StartUpCompany";
import StartUpFactory from "../../components/CustomizedTable/StartUpFactory";
import DashboradMap from "../../components/DashboradMap/DashboradMap";
import Wordcloud from "../../components/WordCloud/WordCloud";
import { cityCode, cityCodeMap } from "../../constants/cities";
import {
  getTypeTwoChartDataHandler,
  getTypeThreeChartDataHandler,
  getTypeFourChartDataHandler,
  getPublicStockChartData,
  // getNotPublicStockChartData,
  getStockRankingData,
  getTopTenCapitalDataData,
  getTopTenGovPlanData,
  getTopTenPatentData,
  getImportPercentageData,
  getExportPercentageData,
  getAwardPercentageData,
  getGovPlanPercentageData,
  getPublicStockPercentageData,
  getNewsInfoData,
  getEmployeeData,
  getNewsInfoTagData,
  getGreenFactoryData,
  getTaiwanInvestmentDataHandler,
  getFactoryAreaRentDataHandler,
  getStartUpDataHandler,
  getTaxRankHandler,
} from "../../store/actions/cityDashbroad";
import Modal from "../../components/CustomizedModal/CustomizedModal";
import CityDashboradModal from "../../components/CityDashboradModal/CityDashboradModal";
import NoData from "../../components/Nodata/NoData";
import Footer from "../../components/Footer/Footer";
import { upperFirstCase } from "../../utility/common";
import BackToTopButton from "../../components/BackToTopButton/BackToTopButtonV2";

import "./styles.scss";
import { ScrollContainer, InnerContainer } from "../../utility/styles";

export default (props) => {
  const containerRef = React.useRef(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [state, setState] = React.useState({
    selectedCity: 15,
    selectedCityName: "高雄市",
    citiesOptions: [],
    newsListPage: 1,
    perPageCount: 5,
    modalType: "",
    referenceCode: "",
    isModalOpen: false,
    title: "",
  });
  const {
    typeTwoChartData,
    typeTwoChartDataLabel,
    typeTwoChartDataMap,
    typeTwoChartDataLoading,
    typeThreeChartData,
    typeThreeChartDataLabel,
    typeThreeChartDataMap,
    typeThreeChartDataLoading,
    typeFourChartData,
    typeFourChartDataLabel,
    typeFourChartDataMap,
    typeFourChartDataLoading,
    stockRanking,
    topTenCapital,
    topTenGovPlan,
    topTenPatent,
    importPercentage,
    exportPercentage,
    awardPercentage,
    govPlanPercentage,
    publicStockPercentage,
    newsInfo,
    employeeInfo,
    newsInfoTag,
    greenFactory,
    publicStock,
    taiwanInvestment,
    factoryAreaRent,
    startUp,
    taxRank,
  } = useSelector((state) => state.cityDashboard);
  const cityIdFromUrl = props.match.params.cityId;

  const handleRequests = React.useCallback(
    (city) => {
      Promise.all([
        dispatch(getTypeTwoChartDataHandler(city)),
        dispatch(getTypeThreeChartDataHandler(city)),
        dispatch(getTypeFourChartDataHandler(city)),
        dispatch(getPublicStockChartData(city)),
        // dispatch(getNotPublicStockChartData(selectedCity))
        dispatch(getStockRankingData(cityCodeMap[city])),
        dispatch(getTopTenCapitalDataData(city)),
        dispatch(getTopTenGovPlanData(city)),
        dispatch(getTopTenPatentData(city)),
        dispatch(getImportPercentageData(city)),
        dispatch(getExportPercentageData(city)),
        dispatch(getAwardPercentageData(city)),
        dispatch(getPublicStockPercentageData(city)),
        dispatch(getEmployeeData(city)),
        dispatch(getNewsInfoTagData(city)),
        dispatch(getGreenFactoryData(city)),
        dispatch(getGovPlanPercentageData(city)),
        dispatch(getNewsInfoData(city)),
        dispatch(getTaiwanInvestmentDataHandler(city)),
        dispatch(getFactoryAreaRentDataHandler(city)),
        dispatch(getStartUpDataHandler(city)),
        dispatch(getTaxRankHandler(city)),
      ]);
    },
    [dispatch]
  );

  React.useEffect(() => {
    if (Object.keys(props.match.params).length === 0) {
      if (location.pathname.slice(-1) === "/") {
        history.push(`${location.pathname}15`);
      } else {
        history.push(`${location.pathname}/15`);
      }
      handleRequests(15);
      setState((state) => ({
        ...state,
        selectedCity: 15,
        selectedCityName: "高雄市",
      }));
    } else {
      if (!cityCodeMap[props.match.params.cityId]) {
        handleRequests(15);
        history.push(`15`);
      } else {
        handleRequests(cityIdFromUrl);
        setState((state) => ({
          ...state,
          selectedCity: upperFirstCase(cityIdFromUrl),
          selectedCityName: cityCodeMap[upperFirstCase(cityIdFromUrl)],
        }));
      }
    }
  }, [cityIdFromUrl]);

  React.useEffect(() => {
    const updateCityOptions = [];
    cityCode.map((city) =>
      updateCityOptions.push({
        key: city.CityId,
        value: city.CityId,
        name: city.CityName,
      })
    );
    setState((state) => ({ ...state, citiesOptions: updateCityOptions }));
  }, []);

  const onChangeHandler = (value) => {
    setState((state) => ({ ...state, selectedCity: value }));
    history.push(`${value}`);
  };

  const handleNewsPage = (page) => {
    setState((state) => ({ ...state, newsListPage: page }));
  };

  const handleClose = React.useCallback(() => {
    if (!state.persistModalOpen) {
      setState((state) => ({
        ...state,
        isModalOpen: !state.isModalOpen,
        modalType: "",
        referenceCode: "",
      }));
    }
  }, [state.persistModalOpen]);

  const handleModalOpen = (modalType, referenceCode, title) => {
    if (
      modalType &&
      ((referenceCode &&
        referenceCode !== "其他" &&
        referenceCode !== 5) ||
        referenceCode === Number(0))
    ) {
      setState((state) => ({
        ...state,
        isModalOpen: true,
        modalType: modalType,
        referenceCode: referenceCode,
        title: title,
      }));
    } else {
      return;
    }
  };

  const openNewTab = (businessNo, type) => {
    if (!businessNo) {
      return;
    } else {
      const url = `${window.location.origin}`;
      window.open(
        `${url}/factory_info/basic_info/${businessNo}`,
        "_blank"
      );
    }
  };

  const persistModalOpenHandler = (value) => {
    setState((state) => ({ ...state, persistModalOpen: value }));
  };

  const scrollToTop = () => {
    containerRef.current.scrollTo({ top: 0, behavior: "smooth" });
  };
// console.log(state.citiesOptions)
  return (
    <ScrollContainer className="city-dashboard" ref={containerRef}>
      <BackToTopButton scrollToTop={scrollToTop} />
      <Modal isOpen={state.isModalOpen} handleClose={handleClose}>
        <CityDashboradModal
          modalType={state.modalType}
          referenceCode={state.referenceCode}
          handleClose={handleClose}
          cityId={state.selectedCity}
          title={state.title}
          selectedCity={state.selectedCityName}
          persistModalOpenHandler={persistModalOpenHandler}
        />
      </Modal>
      <InnerContainer>
        <div className="city-dashboard-overview">
          <CityDashboardHeader
            title="總覽"
            subTitle="縣市基本資料"
            onChangeHandler={onChangeHandler}
            selectionOption={state.citiesOptions}
            selectedCity={state.selectedCity}
            referenceType={"cityOverview"}
          />
          <div className="city-dashboard-overview-container">
            <CustomizedCard
              title="簡介"
              loading={
                typeTwoChartDataLoading ||
                topTenCapital.topTenCapitalLoading ||
                publicStock.notPublicStockLoading ||
                govPlanPercentage.govPlanPercentageDataLoading ||
                topTenPatent.topTenPatentDataLoading ||
                awardPercentage.awardPercentageDataLoading
              }
            >
              <CityDashboardBriefInfo
                selectedCityName={state.selectedCityName}
                tpyeTwo={typeTwoChartDataMap}
                topTenCapital={topTenCapital.topTenCapitalLabels}
                publicStock={publicStock}
                govPlanPercentage={govPlanPercentage}
                topTenPatent={topTenPatent.topTenPatentDataMap}
                award={awardPercentage.awardPercentageDataLabels}
              />
            </CustomizedCard>
            <CustomizedCard
              title={`${state.selectedCityName}產業園區土地廠房租售資訊`}
              shouldCardOpen={true}
              loading={factoryAreaRent.factoryAreaRentLoading}
              style={{ marginBottom: "20px" }}
              referenceType={"areaDashboradAreaRentingTable"}
            >
              <FactoryAreaRentTable
                headerList={["園區", "面積", "類別", "單價", "網址"]}
                data={factoryAreaRent.factoryAreaRentData}
              />
            </CustomizedCard>
          </div>
        </div>
        <div className="city-dashboard-popular-topics">
          <CityDashboardHeader
            title="熱門議題"
            subTitle="以縣市內廠商名稱關鍵字分析新聞熱門議題"
            referenceType={"cityPopularTopics"}
          />
          <div className="city-dashboard-overview-news-container">
            <CustomizedCard
              title="新聞標籤雲"
              referenceType={"CityNewsInfoTag"}
              loading={newsInfoTag.newsInfoTagDataLoading}
            >
              <Wordcloud
                tag={newsInfoTag.newsInfoTagData}
                eventHandler={(val, title) =>
                  handleModalOpen("sectionNewsTag", val, title)
                }
              />
            </CustomizedCard>
            <CustomizedCard
              title="相關新聞"
              loading={newsInfo.newsInfoDataLoading}
              withMinHeight={newsInfo.newsInfoTotalLength > 5 ? 350 : ""}
              referenceType={"CityRelatedNews"}
            >
              <NewsInfoTable
                headerList={["新聞標題", "日期/來源", "相關廠商"]}
                data={newsInfo.newsInfoData}
                newsInfoTotalLength={newsInfo.newsInfoTotalLength}
                newsInfoRowNum={newsInfo.newsInfoRowNum}
                newsInfoPagingNum={newsInfo.newsInfoPagingNum}
                getNewsListByNewPage={handleNewsPage}
                selectedCity=""
              />
            </CustomizedCard>
          </div>
        </div>
        <div className="city-dashboard-industry-view">
          <CityDashboardHeader
            title="產業形貌"
            subTitle="以主計總處產業類別2/3/4碼與稅務行業類別呈現縣市廠商產業分佈"
            referenceType={"cityIndustryOverview"}
          />
          <div className="city-dashboard-industry-view-container">
            <CustomizedCard
              title={`${state.selectedCityName}廠商產業別四碼家數佔比`}
              shouldCardOpen={true}
              loading={typeTwoChartDataLoading}
              rankingList={typeFourChartDataMap}
              referenceType={"CityFourChart"}
              eventHandler={(val, title) =>
                handleModalOpen("typeFour", val, title)
              }
            >
              <CustomizedGraphic
                chartType={"donut-chart"}
                data={typeFourChartData}
                circle={"70"}
                labels={typeFourChartDataLabel}
                mapData={typeFourChartDataMap}
                dataLabels={{
                  enabled: true,
                  formatter: (val, { seriesIndex, dataPointIndex, w }) =>
                    w.config.labels[seriesIndex],
                }}
                eventHandler={(val, title) =>
                  handleModalOpen("typeFour", val, title)
                }
              />
            </CustomizedCard>
            <CustomizedCard
              title={`${state.selectedCityName}廠商產業別三碼家數佔比`}
              shouldCardOpen={true}
              loading={typeThreeChartDataLoading}
              rankingList={typeThreeChartDataMap}
              referenceType={"CityThreeChart"}
              eventHandler={(val, title) =>
                handleModalOpen("typeThree", val, title)
              }
            >
              <CustomizedGraphic
                chartType={"donut-chart"}
                data={typeThreeChartData}
                circle={"70"}
                labels={typeThreeChartDataLabel}
                mapData={typeThreeChartDataMap}
                dataLabels={{
                  enabled: true,
                  formatter: (val, { seriesIndex, dataPointIndex, w }) =>
                    w.config.labels[seriesIndex],
                }}
                eventHandler={(val, title) =>
                  handleModalOpen("typeThree", val, title)
                }
              />
            </CustomizedCard>
            <CustomizedCard
              title={`${state.selectedCityName}廠商產業別二碼家數佔比`}
              shouldCardOpen={true}
              loading={typeFourChartDataLoading}
              rankingList={typeTwoChartDataMap}
              referenceType={"CityTwoChart"}
              eventHandler={(val, title) =>
                handleModalOpen("typeTwo", val, title)
              }
            >
              <CustomizedGraphic
                chartType={"donut-chart"}
                data={typeTwoChartData}
                circle={"70"}
                labels={typeTwoChartDataLabel}
                mapData={typeTwoChartDataMap}
                dataLabels={{
                  enabled: true,
                  formatter: (val, { seriesIndex, dataPointIndex, w }) =>
                    w.config.labels[seriesIndex],
                }}
                eventHandler={(val, title) =>
                  handleModalOpen("typeTwo", val, title)
                }
              />
            </CustomizedCard>
            <CustomizedCard
              title={`${state.selectedCityName}廠商稅務行業別前十大排行`}
              shouldCardOpen={true}
              loading={taxRank.taxRankDataLoading}
              rankingList={taxRank.taxRankDataMap}
              referenceType={"CityTaxRank"}
              eventHandler={(val, title) =>
                handleModalOpen("taxRank", val, title)
              }
            >
              <CustomizedGraphic
                chartType={"vertical-bar-chart"}
                data={taxRank.taxRankData}
                labels={taxRank.taxRankDataLabels}
                tags={taxRank.taxRankDataTags}
                mapData={taxRank.taxRankDataMap}
                unit={"家"}
                formatter={(val) => (val === 0 ? 0 : val + "家")}
                eventHandler={(val, title) =>
                  handleModalOpen("taxRank", val, title)
                }
              />
            </CustomizedCard>
          </div>
        </div>
        <div className="city-dashboard-index-rating">
          <CityDashboardHeader
            title="指標排行"
            subTitle="呈現縣市內廠商股票公開發行、縣市廠商進出口級距佔比與政府計畫排行等圖表"
            referenceType={"cityIndexRanking"}
          />
          <div className="city-dashboard-index-rating-container">
            <CustomizedCard
              title={`${state.selectedCityName}股票上市櫃廠商推薦`}
              shouldCardOpen={true}
              referenceType={"CityStockRank"}
              loading={stockRanking.stockRankingLoading}
            >
              <CustomizedTable
                headerList={["統一編號", "廠商名稱"]}
                data={stockRanking.publicStockRankingData}
                isArea
              />
            </CustomizedCard>
            <CustomizedCard
              title={`${state.selectedCityName}股票非上市櫃廠商推薦`}
              shouldCardOpen={true}
              referenceType={"CityNotPublicStockRank"}
              loading={stockRanking.stockRankingLoading}
            >
              <CustomizedTable
                headerList={["統一編號", "廠商名稱"]}
                data={stockRanking.notPublicStockRankingData}
                isArea
              />
            </CustomizedCard>
            <CustomizedCard
              title={`${state.selectedCityName}新成立公司`}
              shouldCardOpen={true}
              referenceType={"CityStartUpCompany"}
              loading={startUp.startUpDataLoading}
            >
              <StartUpCompanyTable
                headerList={["廠商名稱", "設立時間"]}
                data={startUp.startUpData.Companydata || []}
              />
            </CustomizedCard>
            <CustomizedCard
              title={`${state.selectedCityName}新成立工廠`}
              shouldCardOpen={true}
              referenceType={"CityStartUpFactory"}
              loading={startUp.startUpDataLoading}
            >
              <StartUpFactory
                headerList={["廠商名稱", "產業二碼", "設立時間"]}
                data={startUp.startUpData.Factorydata || []}
              />
            </CustomizedCard>
            {/* <CustomizedCard title={`${state.selectedCityName}綠色工廠`} shouldCardOpen={true} isLoading={greenFactory.greenFactoryLoading} >
            <GreenFactoryTable headerList={["統一編號","廠商名稱"]} data={greenFactory.greenFactoryData}/>
          </CustomizedCard> */}
            <CustomizedCard
              title={`${state.selectedCityName}股票上市櫃廠商家數`}
              shouldCardOpen={true}
              loading={publicStock.notPublicStockLoading}
              referenceType={"CityPublicStock"}
            >
              <CustomizedPublicStock
                data={publicStock}
                eventHandler={(val, title) =>
                  handleModalOpen("publicStock", val, title)
                }
              />
            </CustomizedCard>
            <CustomizedCard
              title={`${state.selectedCityName}在臺投資`}
              shouldCardOpen={true}
              loading={taiwanInvestment.taiwanInvestmentLoading}
              referenceType={"CityTaiwanInvestment"}
            >
              <TaiwanInvestmentTable
                headerList={[
                  "廠商名稱",
                  "投資金額",
                  "工作機會",
                  "參考新聞來源時間",
                ]}
                data={taiwanInvestment.taiwanInvestmentData}
              />
            </CustomizedCard>
            <CustomizedCard
              title={`${state.selectedCityName}出口級距廠商家數佔比`}
              shouldCardOpen={true}
              referenceType={"CityExportPercentage"}
              loading={exportPercentage.exportPercentageDataLoading}
              rankingList={exportPercentage.exportPercentageDataMap}
              eventHandler={(val, title) =>
                handleModalOpen("export", val, title)
              }
            >
              <CustomizedGraphic
                chartType={"donut-chart"}
                data={exportPercentage.exportPercentageData}
                circle={"70"}
                labels={exportPercentage.exportPercentageDataLabels}
                mapData={exportPercentage.exportPercentageDataMap}
                dataLabels={{
                  enabled: true,
                  formatter: (val, { seriesIndex, dataPointIndex, w }) =>
                    w.config.labels[seriesIndex],
                }}
                eventHandler={(val, title) =>
                  handleModalOpen("export", val, title)
                }
              />
            </CustomizedCard>
            <CustomizedCard
              title={`${state.selectedCityName}進口級距廠商家數佔比`}
              shouldCardOpen={true}
              referenceType={"CityImportPercentage"}
              loading={importPercentage.importPercentageDataLoading}
              rankingList={importPercentage.importPercentageDataMap}
              eventHandler={(val, title) =>
                handleModalOpen("import", val, title)
              }
            >
              <CustomizedGraphic
                chartType={"donut-chart"}
                data={importPercentage.importPercentageData}
                circle={"70"}
                labels={importPercentage.importPercentageDataLabels}
                mapData={importPercentage.importPercentageDataMap}
                dataLabels={{
                  enabled: true,
                  formatter: (val, { seriesIndex, dataPointIndex, w }) =>
                    w.config.labels[seriesIndex],
                }}
                eventHandler={(val, title) =>
                  handleModalOpen("import", val, title)
                }
              />
            </CustomizedCard>
            <CustomizedCard
              title={`${state.selectedCityName}廠商員工數前十大排行`}
              shouldCardOpen={true}
              loading={employeeInfo.employeeInfoDataLoading}
              referenceType="cityDashboradEmployeeRanking"
            >
              <CustomizedGraphic
                chartType={"horizontal-bar-chart"}
                data={employeeInfo.employeeInfoData}
                labels={employeeInfo.employeeInfoDataLabels}
                mapData={employeeInfo.employeeInfoDataMap}
                unit={"人"}
                formatter={(val) => val}
                color={["#6bcf00"]}
                type={"employee"}
                eventHandler={(val) => openNewTab(val, "employee")}
              />
            </CustomizedCard>
            <CustomizedCard
              title={`${state.selectedCityName}廠商資本額前十大排行`}
              shouldCardOpen={true}
              referenceType={"CityTopTenCapital"}
              loading={topTenCapital.topTenCapitalLoading}
              rankingList={topTenCapital.topTenCapitalDataMap}
              eventHandler={(val) => openNewTab(val, "capital")}
            >
              <CustomizedGraphic
                chartType={"vertical-bar-chart"}
                data={topTenCapital.topTenCapitalData}
                labels={topTenCapital.topTenCapitalLabels}
                mapData={topTenCapital.topTenCapitalDataMap}
                unit={"元"}
                formatter={(val) => (val === 0 ? 0 : val / 100000000 + "億")}
                eventHandler={(val) => openNewTab(val, "capital")}
              />
            </CustomizedCard>
            <CustomizedCard
              title={`${state.selectedCityName}廠商政府計畫類型佔比`}
              shouldCardOpen={true}
              referenceType={"CityGovPlanPercentage"}
              loading={govPlanPercentage.govPlanPercentageDataLoading}
              rankingList={govPlanPercentage.govPlanPercentageDataMap}
              eventHandler={(val, title) =>
                handleModalOpen("govPlan", val, title)
              }
            >
              <CustomizedGraphic
                chartType={"donut-chart"}
                data={govPlanPercentage.govPlanPercentageData}
                circle={"70"}
                labels={govPlanPercentage.govPlanPercentageDataLabels}
                mapData={govPlanPercentage.govPlanPercentageDataMap}
                isGovPlanOrAwardPercentage
                dataLabels={{
                  enabled: true,
                  formatter: (val, { seriesIndex, dataPointIndex, w }) =>
                    w.config.labels[seriesIndex],
                }}
                eventHandler={(val, title) =>
                  handleModalOpen("govPlan", val, title)
                }
              />
            </CustomizedCard>
            <CustomizedCard
              title={`${state.selectedCityName}廠商政府計畫數前十大排行`}
              shouldCardOpen={true}
              referenceType={"CityTopTenGovPlan"}
              loading={topTenGovPlan.topTenGovPlanDataLoading}
              rankingList={topTenGovPlan.topTenGovPlanDataMap}
              eventHandler={(val, title) =>
                handleModalOpen("topTenGovPlan", val, title)
              }
            >
              <CustomizedGraphic
                chartType={"horizontal-bar-chart"}
                data={topTenGovPlan.topTenGovPlanData}
                labels={topTenGovPlan.topTenGovPlanDataLabels}
                mapData={topTenGovPlan.topTenGovPlanDataMap}
                unit={"件"}
                formatter={(val) => val}
                eventHandler={(val, title) =>
                  handleModalOpen("topTenGovPlan", val, title)
                }
              />
            </CustomizedCard>
            <CustomizedCard
              title={`${state.selectedCityName}廠商獲獎類型佔比`}
              shouldCardOpen={true}
              referenceType={"CityAwardPercentage"}
              loading={awardPercentage.awardPercentageDataLoading}
              rankingList={awardPercentage.awardPercentageDataMap}
              eventHandler={(val, title) =>
                handleModalOpen("award", val, title)
              }
            >
              <CustomizedGraphic
                chartType={"donut-chart"}
                data={awardPercentage.awardPercentageData}
                circle={"70"}
                labels={awardPercentage.awardPercentageDataLabels}
                mapData={awardPercentage.awardPercentageDataMap}
                isGovPlanOrAwardPercentage
                dataLabels={{
                  enabled: true,
                  formatter: (val, { seriesIndex, dataPointIndex, w }) =>
                    w.config.labels[seriesIndex],
                }}
                eventHandler={(val, title) =>
                  handleModalOpen("award", val, title)
                }
              />
            </CustomizedCard>
            <CustomizedCard
              title={`${state.selectedCityName}廠商近年專利公告數前十大排行`}
              shouldCardOpen={true}
              referenceType={"CityTopTenPatent"}
              loading={topTenPatent.topTenPatentDataLoading}
              rankingList={topTenPatent.topTenPatentDataMap}
              eventHandler={(val, title) =>
                handleModalOpen("patent", val, title)
              }
            >
              <CustomizedGraphic
                chartType={"horizontal-bar-chart"}
                data={topTenPatent.topTenPatentData}
                labels={topTenPatent.topTenPatentDataLabels}
                mapData={topTenPatent.topTenPatentDataMap}
                unit={"件"}
                formatter={(val) => val}
                eventHandler={(val, title) =>
                  handleModalOpen("patent", val, title)
                }
              />
            </CustomizedCard>
          </div>
        </div>
      </InnerContainer>
      {/* <Footer /> */}
    </ScrollContainer>
  );
};
