import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuilding, faInfo, faTools } from "@fortawesome/free-solid-svg-icons";

import { thousandDivider } from "../../utility/common";
import { photoUrl } from "../../constants/endpoints";

import * as Style from "./styles";

const UpdatedCompanyInfo = ({
  Accreditation,
  BusinessNo,
  ContactPerson,
  DMImg,
  EmplyeeCount,
  Fax,
  Introduction,
  MainProduct,
  Name,
  Phone,
  WebSite,
  Note,
  isPreview
}) => {
  const [state, setState] = React.useState({
    displayAccreditation: "未提供"
  });

  React.useEffect(()=>{
    if(isPreview){
      if(Accreditation.length !== 0){
        setState(state=>({ ...state, displayAccreditation: Accreditation.join("; ") }))
      }else{
        setState(state=>({ ...state, displayAccreditation: "未提供" }))
      }
    }else{
      if(Accreditation && Accreditation.split(",").length > 0){
        setState(state=>({ ...state, displayAccreditation:  Accreditation.split(",").join("; ") }))
      }else{
        setState(state=>({ ...state, displayAccreditation: "未提供" }))
      }
    }
  }, [Accreditation, isPreview])



  let headerImage;
  if(isPreview){
    headerImage = (
      <Style.UpdateCompanyContainerHeaderImage
      src="data:image/gif;base64,R0lGODlhAQABAPcAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACH5BAEAAP8ALAAAAAABAAEAAAgEAP8FBAA7"
      style={
        DMImg
          ? { backgroundImage: `url(${DMImg})` }
          : {
              backgroundImage:
                "url(https://images.unsplash.com/photo-1554316382-853b8a1696f3?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2534&q=80)",
            }
      }
      alt="dmImg"
    />
    )
  }else{
    headerImage = (
      <Style.UpdateCompanyContainerHeaderImage
      src="data:image/gif;base64,R0lGODlhAQABAPcAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACH5BAEAAP8ALAAAAAABAAEAAAgEAP8FBAA7"
      style={
          DMImg
            ? { backgroundImage: `url(${photoUrl}/DMFolder/${DMImg})` }
            : {
                backgroundImage:
                  "url(https://images.unsplash.com/photo-1554316382-853b8a1696f3?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2534&q=80)",
              }
        }
      alt="dmImg"
    />
    )
  };

  return (
    <Style.UpdateCompanyContainer>
      <Style.UpdateCompanyContainerHeader>
        {DMImg && (
          <Style.UpdateCompanyContainerHeaderContain>
            <Style.UpdateCompanyContainerHeaderText
              onClick={isPreview ? () => {} : 
              () => {
                window.open(`${photoUrl}/DMFolder/${DMImg}`, "_blank");
              }}
            >
              公司DM
            </Style.UpdateCompanyContainerHeaderText>
          </Style.UpdateCompanyContainerHeaderContain>
        )}
        {headerImage}
        <Style.UpdateCompanyContainerHeaderImage
          src="data:image/gif;base64,R0lGODlhAQABAPcAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACH5BAEAAP8ALAAAAAABAAEAAAgEAP8FBAA7"
          style={{
            background: `linear-gradient(to top, rgba(0,0,0,0), rgba(247, 243, 243, 1) 90%)`,
          }}
          alt="dmImg"
        />
      </Style.UpdateCompanyContainerHeader>
      <Style.UpdateCompanyContainerBody>
        <Style.UpdateCompanyContainerInnerCard>
          <Style.UpdateCompanyContainerInnerCardIcons>
            <FontAwesomeIcon icon={faInfo} />
          </Style.UpdateCompanyContainerInnerCardIcons>
          <Style.UpdateCompanyContainerInnerCardHeader>
            <Style.UpdateCompanyContainerInnerCardHeaderText>
              公司基本資料
            </Style.UpdateCompanyContainerInnerCardHeaderText>
          </Style.UpdateCompanyContainerInnerCardHeader>
          <Style.UpdateCompanyContainerInnerCardContent>
            <Style.UpdateCompanyContainerInnerCardContentText>
              統一編號：
              <Style.UpdateCompanyContainerInnerCardContentTextSpan>
                {BusinessNo ? BusinessNo : "未提供"}
              </Style.UpdateCompanyContainerInnerCardContentTextSpan>
            </Style.UpdateCompanyContainerInnerCardContentText>
            <Style.UpdateCompanyContainerInnerCardContentText>
              聯絡人：
              <Style.UpdateCompanyContainerInnerCardContentTextSpan>
                {ContactPerson ? ContactPerson : "未提供"}
              </Style.UpdateCompanyContainerInnerCardContentTextSpan>
            </Style.UpdateCompanyContainerInnerCardContentText>
            <Style.UpdateCompanyContainerInnerCardContentText>
              公司總人數：
              <Style.UpdateCompanyContainerInnerCardContentTextSpan>
                {EmplyeeCount ? thousandDivider(EmplyeeCount) : "未提供"}
              </Style.UpdateCompanyContainerInnerCardContentTextSpan>
            </Style.UpdateCompanyContainerInnerCardContentText>
            <Style.UpdateCompanyContainerInnerCardContentText>
              聯絡電話：
              <Style.UpdateCompanyContainerInnerCardContentTextSpan>
                {Phone ? Phone : "未提供"}
              </Style.UpdateCompanyContainerInnerCardContentTextSpan>
            </Style.UpdateCompanyContainerInnerCardContentText>
            <Style.UpdateCompanyContainerInnerCardContentText>
              傳真電話：
              <Style.UpdateCompanyContainerInnerCardContentTextSpan>
                {Fax ? Fax : "未提供"}
              </Style.UpdateCompanyContainerInnerCardContentTextSpan>
            </Style.UpdateCompanyContainerInnerCardContentText>
            <Style.UpdateCompanyContainerInnerCardContentText>
              公司網站：
              <Style.UpdateCompanyContainerInnerCardContentTextSpan 
                onClick={isPreview ? ()=>{} : ()=> { window.open(WebSite, "_blank") }}
                isLink
              >
                {WebSite ? WebSite : "未提供"}
              </Style.UpdateCompanyContainerInnerCardContentTextSpan>
            </Style.UpdateCompanyContainerInnerCardContentText>
            <Style.UpdateCompanyContainerInnerCardContentText>
              公司認證：
              <Style.UpdateCompanyContainerInnerCardContentTextSpan>
                {state.displayAccreditation}
              </Style.UpdateCompanyContainerInnerCardContentTextSpan>
            </Style.UpdateCompanyContainerInnerCardContentText>
            <Style.UpdateCompanyContainerInnerCardContentText>
              備註：
              <Style.UpdateCompanyContainerInnerCardContentTextSpan>
              </Style.UpdateCompanyContainerInnerCardContentTextSpan>
            </Style.UpdateCompanyContainerInnerCardContentText>
            <Style.UpdateCompanyContainerInnerCardContentText>
              <Style.UpdateCompanyContainerInnerCardContentTextSpan>
                {Note ? Note : "未提供"}
              </Style.UpdateCompanyContainerInnerCardContentTextSpan>
            </Style.UpdateCompanyContainerInnerCardContentText>
          </Style.UpdateCompanyContainerInnerCardContent>
        </Style.UpdateCompanyContainerInnerCard>
        <Style.UpdateCompanyContainerInnerCard>
          <Style.UpdateCompanyContainerInnerCardIcons>
            <FontAwesomeIcon icon={faBuilding} />
          </Style.UpdateCompanyContainerInnerCardIcons>
          <Style.UpdateCompanyContainerInnerCardHeader>
            <Style.UpdateCompanyContainerInnerCardHeaderText>
              公司簡介
            </Style.UpdateCompanyContainerInnerCardHeaderText>
          </Style.UpdateCompanyContainerInnerCardHeader>
          <Style.UpdateCompanyContainerInnerCardContent
            style={{ textAlign: "justify" }}
          >
            <Style.UpdateCompanyContainerInnerCardContentText>
              {Introduction ? Introduction : "未提供"}
            </Style.UpdateCompanyContainerInnerCardContentText>
          </Style.UpdateCompanyContainerInnerCardContent>
        </Style.UpdateCompanyContainerInnerCard>
        <Style.UpdateCompanyContainerInnerCard>
          <Style.UpdateCompanyContainerInnerCardIcons>
            <FontAwesomeIcon icon={faTools} />
          </Style.UpdateCompanyContainerInnerCardIcons>
          <Style.UpdateCompanyContainerInnerCardHeader>
            <Style.UpdateCompanyContainerInnerCardHeaderText>
              公司主要產品
            </Style.UpdateCompanyContainerInnerCardHeaderText>
          </Style.UpdateCompanyContainerInnerCardHeader>
          <Style.UpdateCompanyContainerInnerCardContent
            style={{ textAlign: "justify" }}
          >
            <Style.UpdateCompanyContainerInnerCardContentText>
              {MainProduct ? MainProduct : "未提供"}
            </Style.UpdateCompanyContainerInnerCardContentText>
          </Style.UpdateCompanyContainerInnerCardContent>
        </Style.UpdateCompanyContainerInnerCard>
      </Style.UpdateCompanyContainerBody>
    </Style.UpdateCompanyContainer>
  );
};

export default React.memo(UpdatedCompanyInfo);
