import React from "react";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TablePagination from "@material-ui/core/TablePagination";

import NoData from "./NoData";

export default (props) => {
    const { data } = props;
    const [state, setState] = React.useState({
        page:0,
        rowsPerPage:5
    })
    const url = window.location.origin;

    const openNewTabs = (link) => window.open(link, "_blank");

    const handleChangePage = (event, newPage) => {
      setState(state=>({ ...state, page: newPage }))
    }

    const companyType = (type) => {
        if(type.indexOf("<category>") > -1){
            return type.substring(type.indexOf("<category>")+"<category>".length,type.indexOf("</category>"));
        }else{
            return "無相關資料"
        }
    };

    return(
        <TableContainer className="table-container">
        <Table className="table">
          <TableHead className="table-header">
            <TableRow className="table-row">
                <TableCell className="table-cell" align="left">廠商名稱</TableCell>
                <TableCell className="table-cell" align="left">產業分類</TableCell>
            </TableRow>
          </TableHead>
          <TableBody className="table-body">
            {data.length !== 0 ?data.slice(state.page * state.rowsPerPage, state.page * state.rowsPerPage + state.rowsPerPage).map((row, index)=>{
              return(
                <TableRow key={row.BusiNo} hover  className="table-body-row" style={index%2 !== 0 ? {backgroundColor:"#ececec"}:{}}>
                  <TableCell align="left" className="table-body-header" onClick={()=>openNewTabs(url+"/factory_info/basic_info/"+row.BusiNo)}>{row.Name}</TableCell>
                  <TableCell align="left">{companyType(row.InduTypeStr)}</TableCell>
                </TableRow>
              )
            }): <NoData />}
          </TableBody>
        </Table>
        {data.length > 5 &&        
            <TablePagination
            rowsPerPageOptions={[]}
            component="div"
            count={data.length}
            rowsPerPage={state.rowsPerPage}
            page={state.page}
            onChangePage={handleChangePage}
          />
        }
        </TableContainer>
    )
}