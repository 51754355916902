import React from "react";
import { useSelector, useDispatch } from "react-redux";
import Swal from "sweetalert2";

import { handleFetchVersion } from "../../store/actions/app";

const VersionController = (props) => {
    const dispatch = useDispatch();
    const { version } = useSelector(state => state.app );
    const currentVersion = 666;
    const url = window.location.origin;
    React.useEffect(()=>{
        if(url !== "https://idpark-stage.azurewebsites.net" || url.search("localhost") === -1){
            dispatch(handleFetchVersion())
        }
    }, [url]);

    return <React.Fragment>{props.children}</React.Fragment>
};

export default VersionController;