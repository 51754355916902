import React from "react";
import { useSelector } from "react-redux";
import GovProBiddingTable from "../../../components/CustomizedTable/GovProBiddingTable";
import CustomizedCard from "../../../components/CustomizedCard/CustomizedCardV3";
import "./styles.scss";
import MobileGovProBidding from "../../../components/MobileGrid/FactoryInfo/GovProBidding/GovProBidding";

export default (props) => {
  const { innerWidth } = useSelector((state) => state.app);
  const { getGovProBiddingData, getGovProBiddingDataLoading } = useSelector(
    (state) => state.factory
  );
  const url = window.location.origin;

  const openNewTabs = (link) => window.open(link, "_blank");

  return (
    <React.Fragment>
      {innerWidth > 1024 && !getGovProBiddingDataLoading ? (
        <CustomizedCard
          title="政府標案"
          referenceType={"factoryInfoGovProBidding"}
        >
          <GovProBiddingTable
            headerList={[
              "招標廠商",
              "採購類型",
              "招標方式",
              "標案價格(元)",
              "決標公開時間",
              "標案簡述",
            ]}
            data={getGovProBiddingData}
          />
        </CustomizedCard>
      ) : (
        <MobileGovProBidding data={getGovProBiddingData} referenceType={"factoryInfoGovProBidding"}/>
      )}
    </React.Fragment>
  );
};
