import axios from "axios";
import axiosInstance from "./axiosWithRefreshToken"
import { baseUrl, loginbaseUrl } from "../constants/endpoints";

const headers = {
  "Access-Control-Allow-Origin": "*",
  "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
  "Access-Control-Allow-Headers":
    "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers",
  "Content-Type": "application/json",
  Authorization: localStorage.getItem("acToken"),
};
const InduParkWebAcTokenheaders = {
  "Access-Control-Allow-Origin": "*",
  "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
  "Access-Control-Allow-Headers":
    "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers",
  "Content-Type": "application/json",
  Authorization: localStorage.getItem("InduParkWebAcToken"),
};

export const fetchVerifiedCode = (userId) => {
  const file = new FormData();
  file.append("UserId", localStorage.getItem("uid"));
  return axios({
    proxy: true,
    method: "post",
    url: `${loginbaseUrl}/api/CompanyUpload/SendUploadVERCodeEmail`,
    data: file,
  })
    .then((res) => {
      try {
        return res.data;
      } catch (e) {
        return res.data;
      }
    })
    .catch((err) => {
      return "error";
    });
};

export const fetchUpdatedCompanyList = (userId) => {
  return axios({
    proxy: true,
    method: "get",
    url: `${baseUrl}/api/CompanyBasicInfo/GetNewCompanyData/${userId}`,
    headers: headers,
  })
    .then((res) => {
      try {
        return res.data;
      } catch (e) {
        return res.data;
      }
    })
    .catch((err) => {
      return "error";
    });
};

// 產業競爭力發展中心飛雷神
export const fetchEnterpriseQualified = (userId) => {
  return axios({
    proxy: true,
    method: "get",
    url: `${baseUrl}/api/Company/GetEnterpriseQualifie/${userId}`,
    headers: headers,
  })
    .then((res) => {
      try {
        return res.data;
      } catch (e) {
        return [];
      }
    })
    .catch((err) => {
      return [];
    });
};

// 原始的 GetCompanyAbstract API（用來取得是否為陸資欄位資料）
export const fetchCompanyAbstractOrigin = (businessNo) => {
  return axios({
    proxy: true,
    method: "get",
    url: `${baseUrl}/api/CompanyBasicInfo/GetCompanyAbstract/${businessNo}`,
    headers: headers,
  })
    .then((res) => {
        return res.data;
    })
    .catch((err) => {
      return "error";
    });
};

export const fetchCompanyAbstract = (businessNo) => {
  return axios({
    proxy: true,
    method: "get",
    url: `${baseUrl}/api/CompanyBasicInfo/GetCompanyRanking/${businessNo}`,
    headers: headers,
  })
    .then((res) => {
        return res.data;
    })
    .catch((err) => {
      return "error";
    });
};


export const updatedCompanyInfo = (data) => {
  return axios({
    proxy: true,
    method: "post",
    url: `${loginbaseUrl}/api/CompanyUpload/UploadCompanyData`,
    data: data,
  })
    .then((res) => {
      try {
        return res;
      } catch (e) {
        return e;
      }
    })
    .catch((err) => {
      return "error";
    });
};

export const getCompanyBasicInfo = businessId => {
  return axios({
    proxy: true,
    method: "get",
    url: `${baseUrl}/api/CompanyBasicInfo/GetCompanyBasicInfo/${businessId}`,
    headers: headers,
  })
    .then(res => {
      return res.data
    })
    .catch(err => {
      return "error";
    });
}

export const getForeignTradesCompare = businessId => {
  return axios({
    proxy: true,
    method: "get",
    url: `${baseUrl}/api/ForeignTrade/GetForeignTradesCompare/${businessId}`,
    headers: headers,
  })
    .then(res => {
      return res.data
    })
    .catch(err => {
      return "error";
    });
}

export const getGovProBidding = businessId => {
  return axios({
    proxy: true,
    method: "get",
    url: `${baseUrl}/api/GovPlan/GovProBidding/${businessId}`,
    headers: headers,
  })
    .then(res => {
      return res.data
    })
    .catch(err => {
      return "error";
    });
}

// 智財權相關判決書
export const getVerdict = businessId => {
  return axios({
    proxy: true,
    method: "get",
    url: `${baseUrl}/api/Company/GetVerdict/${businessId}`,
    headers: headers,
  })
    .then(res => {
      return res.data
    })
    .catch(err => {
      return [];
    });
}

// 商標
export const getTrademark = businessId => {
  return axios({
    proxy: true,
    method: "get",
    url: `${baseUrl}/api/TradeMark/GetTradeMarks/${businessId}`,
    headers: headers,
  })
    .then(res => {
      return res.data
    })
    .catch(err => {
      return [];
    });
}

export const getBusinessInfo = businessId => {
  return axios({
    proxy: true,
    method: "get",
    url: `${baseUrl}/api/FactoryInfo/GetFactoryInfos/${businessId}`,
    headers: headers,
  })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return "error";
    });
}



export const handleGetCompanyUploadVerifying = (data) => {
  return axios({
    proxy: true,
    method: "post",
    url: `${baseUrl}/api/CompanyUpload/GetCompanyUploadVerifying`,
    data: data
  })
    .then((res) => {
      try {
        return res.data;
      } catch (e) {
        return e;
      }
    })
    .catch((err) => {
      return err;
    });
};

// 取得稅務行業分類資料
export const getTaxCompanyIndustryInfo = (businessId) => {
  return axios({
    method: "get",
    url: `${baseUrl}/api/Company/GetTaxCompanyIndustry/${businessId}`,
    headers: headers,
  })
  .then((res) => {
    try {
      return res.data;
    } catch (e) {
      return e;
    }
  })
  .catch((err) => {
    return err;
  });
};

// 取得投資台灣資料
export const getCompanyInvestBackTW = (businessId) => {
  return axios({
    method: "get",
    url: `${baseUrl}/api/Company/GetCompanyInvestBackTW/${businessId}`,
    headers: headers,
  })
  .then((res) => {
    try {
      return res.data;
    } catch (e) {
      return e;
    }
  })
  .catch((err) => {
    return err;
  });
};

// 取得進出口貨品號別
export const getForeignTrades4CodeProduct = (businessId) => {
  return axios({
    method: "get",
    url: `${baseUrl}/api/InduParkCompany/GetForeignTrades4CodeProduct/${businessId}`,
    headers: headers,
  })
  .then((res) => {
    try {
      return res.data;
    } catch (e) {
      return e;
    }
  })
  .catch((err) => {
    return err;
  });
};


//獲獎實績
export const getAwardPlan = (businessId) => {
  return axios({
    method: "get",
    url: `${baseUrl}/api/Award/GetAwards/${businessId}`,
    headers: headers,
  })
  .then((res) => {
    try {
      return res.data;
    } catch (e) {
      return e;
    }
  })
  .catch((err) => {
    return err;
  });
};

//MIT標章
export const getMITMarkInfo = (businessId) => {
  return axios({
    method: "get",
    url: `${baseUrl}/api/Company/GetMITProduct/${businessId}`,
    headers: headers,
  })
  .then((res) => {
    try {
      return res.data;
    } catch (e) {
      return [];
    }
  })
  .catch((err) => {
    return [];
  });
};

//進出口額
export const getTradeAmount = (businessId) => {
  return axios({
    method: "get",
    url:`${baseUrl}/api/ForeignTrade/GetForeignTrades/${businessId}`,
    headers: headers,
  })
  .then((res) => {
    try {
      return res.data;
    } catch (e) {
      return e;
    }
  })
  .catch((err) => {
    return err;
  });
};

//股市資訊
export const getStockInfo = (businessId) => {
  return axios({
    method: "get",
    url: `${baseUrl}/api/Stock/GetStockItems/${businessId}`,
    headers: headers,
  })
  .then((res) => {
    try {
      return res.data;
    } catch (e) {
      return e;
    }
  })
  .catch((err) => {
    return err;
  });
};

//董監事資料
export const getBoardMemberInfo = (businessId) => {
  return axios({
    method: "get",
    url:`${baseUrl}/api/BoardMember/GetBoardMembers/${businessId}`,
    headers: headers,
  })
  .then((res) => {
    try {
      return res.data;
    } catch (e) {
      return e;
    }
  })
  .catch((err) => {
    return err;
  });
};

//公司股市基本資料
export const getCompanyStockInfo = (businessId) => {
  return axios({
    method: "get",
    url:`${baseUrl}/api/Stock/GetStockBasicInfo/${businessId}`,
    headers: headers,
  })
  .then((res) => {
    try {
      return res.data;
    } catch (e) {
      return e;
    }
  })
  .catch((err) => {
    return err;
  });
};

//公司股市體質
export const getCompanyStockPhysique = (businessId) => {
  return axios({
    method: "get",
    url: `${baseUrl}/api/Stock/GetStockPhysique/${businessId}`,
    headers: headers,
  })
  .then((res) => {
    try {
      return res.data;
    } catch (e) {
      return e;
    }
  })
  .catch((err) => {
    return err;
  });
};

//股市指標(營業額Revenue,EPS,研發比例Research,毛利率GrossProfitMargin,稅後淨利率NetIncomeMargin,自由現金流量FreeCashFlow,ROE,股利殖利率DividendYield)(chart and List)
export const getStockIndex = (businessId) => {
  return axios({
    method: "get",
    url: `${baseUrl}/api/Stock/GetStockIndex/${businessId}`,
    headers: headers,
  })
  .then((res) => {
    try {
      return res.data;
    } catch (e) {
      return e;
    }
  })
  .catch((err) => {
    return err;
  });
};

//股市趨勢(研發比例)
export const getTradeResearch = (businessId) => {
  return axios({
    method: "get",
    url: `${baseUrl}/api/Stock/GetTrend_Research/${businessId}`,
    headers: headers,
  })
  .then((res) => {
    try {
      return res.data;
    } catch (e) {
      return e;
    }
  })
  .catch((err) => {
    return err;
  });
};

//股市趨勢(EPS)
export const getTradeEPS = (businessId) => {
  return axios({
    method: "get",
    url: `${baseUrl}/api/Stock/GetTrend_EPS/${businessId}`,
    headers: headers,
  })
  .then((res) => {
    try {
      return res.data;
    } catch (e) {
      return e;
    }
  })
  .catch((err) => {
    return err;
  });
};

//股市趨勢(營業額);
export const getTradeRevenue = (businessId) => {
  return axios({
    method: "get",
    url: `${baseUrl}/api/Stock/GetTrend_Revenue/${businessId}`,
    headers: headers,
  })
    .then((res) => {
      try {
        return res.data.data[0].dataPoints;
      } catch (e) {
        return res.data.length;
      }
    })
    .catch((err) => {
      return [];
    });
};

//新聞標籤 -- 動態資訊
export const getNewsTags = (businessId) => {
  return axios({
    method: "get",
    url: `${baseUrl}/api/News/GetNewsTagCloud/${businessId}`,
    headers: headers,
  })
  .then((res) => {
    try {
      return res.data;
    } catch (e) {
      return e;
    }
  })
  .catch((err) => {
    return err;
  });
};

//新聞資料 -- 動態資訊
export const getNewsContents = (businessId) => {
  return axios({
    method: "get",
    url: `${baseUrl}/api/News/GetNewsItems/${businessId}`,
    headers: headers,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return [];
    });
};

//各廠情資-營業登記項目
export const getOperationItems = (businessId) => {
  return axios({
    method: "get",
    url: `${baseUrl}/api/BusiItem/GetBusiItems/${businessId}`,
    headers: headers,
  })
  .then((res) => {
    try {
      return res.data;
    } catch (e) {
      return e;
    }
  })
  .catch((err) => {
    return err;
  });
};

//專利資料
export const getPatentInfo = (businessId, year) => {
  return axios({
    method: "get",
    url: `${baseUrl}/api/Patent/GetPatents/${businessId}/${year}`,
    headers: headers,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return [];
    });
};

//專利資料
export const getBranchInfo = (businessId) => {
  return axios({
    method: "get",
    url:`${baseUrl}/api/Company/GetBranchCompany/${businessId}/true`,
    headers: headers,
  })
  .then((res) => {
    try {
      return res.data;
    } catch (e) {
      return e;
    }
  })
  .catch((err) => {
    return err;
  });
};

//產業指標-產業動態 取得四碼
export const getTypeFourCode = () => {
  return axios({
    method: "get",
    url: `${baseUrl}/api/InduType/GetIndustryTypes_Four_Stat/`,
    headers: headers,
  })
  .then((res) => {
    try {
      return res.data;
    } catch (e) {
      return e;
    }
  })
  .catch((err) => {
    return err;
  });
};

//依據新聞標籤取得新聞資料
export const getNewsContentViaKryWords = (keyword) => {
  return axios({
    method: "get",
    url:`${baseUrl}/api/News/GetNewsByKeywords/${encodeURI(keyword)}/2019-11-09`,
    headers: headers,
  })
  .then((res) => {
    try {
      return res.data;
    } catch (e) {
      return e;
    }
  })
  .catch((err) => {
    return err;
  });
};

export const getGovPlan = (businessId) => {
  return axios({
    method: "get",
    url: `${baseUrl}/api/GovPlan/GetGovPlans/${businessId}`,
    headers: headers,
  })
  .then((res) => {
    try {
      return res.data;
    } catch (e) {
      return e;
    }
  })
  .catch((err) => {
    return err;
  });
};


//取得所有服務歷程
export const getServiceHistory = (BusinessNo, UserID) => {
  return axios({
    method: "get",
    url: `${loginbaseUrl}api/ServiceHistory/Query/${BusinessNo}/${btoa(UserID)}`,
  })
  .then((res) => {
    try {
      return res.data;
    } catch (e) {
      return e;
    }
  })
  .catch((err) => {
    return err;
  });
};

export const fetchManagerInfo = (BusinessNo) => {
  return axios({
    method: "get",
    url: `${baseUrl}/api/Company/GetCompanyManager/${BusinessNo}`,
    headers: headers,
  })
  .then((res) => {
    try {
      return res.data;
    } catch (e) {
      return e;
    }
  })
  .catch((err) => {
    return err;
  });
};

//工業局補捐助資料
export const getGovPlanSubsidy = (businessId) => {
  return axiosInstance({
    method: "get",
    url: `${baseUrl}/api/GovPlan/GetCompanyGovSupple/${businessId}`,
    headers: InduParkWebAcTokenheaders,
  })
  .then((res) => {
    try {
      return res.data;
    } catch (e) {
      return e;
    }
  })
  .catch((err) => {
    return err;
  });
};

//裁罰資料(負面訊息)
export const getNegative = businessId => {
  return axiosInstance({
    proxy: true,
    method: "get",
    url: `${baseUrl}/api/Company/GetCompanyMOL_Illegal/${businessId}`,
    headers: InduParkWebAcTokenheaders,
  })
    .then(res => {
      return res.data
    })
    .catch(err => {
      return "error";
    });
}