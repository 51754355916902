import React, { useState, useEffect, useContext } from "react";
import "./App.css";
import {
  Switch,
  Route,
  Redirect,
  useLocation,
  useParams,
} from "react-router-dom";
import { Backdrop } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import Swal from "sweetalert2";

// import Main from '../src/containers/MainPage/MainPage'

// React Boostrap implementaiton
import "bootstrap/dist/css/bootstrap.min.css";
import UrlParamsRequired from "./components/UrlParamsRequired/UrlParamsRequired";
import LoginRequired from "./components/LoginRequired/LoginRequiredV2";
import Header from "./components/header/header";
import NavigationBar from "./components/navigationBar/navigationBar";
import ParkDashBoard from "./containers/ParkDashboard/ParkDashboard";
import AdvancedSearch from "./containers/AdvancedSearch/AdvancedSearch";
import FactorySearch from "./containers/Factory/FactorySearch/FactorySearch";
import FactoryInfo from "./containers/Factory/FactoryInfo/FactoryInfo";
// import IndustryIndex from "./containers/IndustryIndex/IndustryIndex";
import WastedLandingPage from "./containers/WastedLandingPage";
import IndustryIndex from "./containers/IndustryDashboard/IndustryDashboard";
import OperationsDashboard from "./containers/OperationsDashboard/OperationsDashboard";
import SmartWindow from "./containers/SmartWindow/SmartWindow";

import UploadInfo from "./containers/PushAndNotification/UploadInfo";
import News from "./containers/PushAndNotification/News";
import GovPlanManagement from "./containers/GovPlanManagement/GovPlanManagement";
import GovPlanDisplay from "./containers/GovPlanDisplay/GovPlanDisplay";
import MobileMenu from "./components/MobileMenu/MobileMenuV2";
import MapSearch from "./containers/MapSearch/MapSearch";
import EnMonitor from "./containers/EnMonitor/EnMonitor";
import ClientResponse from "./containers/ClientResponse/ClientResponse";
import About from "./containers/About/About";
import FollowIndustry from "./containers/PushAndNotification/FollowIndustry";
import FollowCompany from "./containers/PushAndNotification/FollowCompany";
import TalentHandler from "./containers/ClientResponse/TalentHandler";
import ChangePass from "./containers/ForgetPassWord/ForgetPassWord";
import Footer from "./components/Footer/Footer";
import SignUpBox from "./components/SignUpBox/SignUpBoxV2";
import Terms from "./containers/Terms/Terms";
import ActiveAccount from "./containers/ActiveAccount/ActiveAccount";
import ForgetPassWordDirection from "./containers/ForgetPassWordDirection/ForgetPassWordDirection";
import UpdateUserInfo from "./components/UpdateUserInfo/UpdateUserInfo";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import SharablePage from "./containers/SharablePage/SharablePage";
import ControlledMaterialMap from "./containers/ControlledMaterialMap/ControlledMaterialMap"; //閹割版的，只有廢棄物
import ChemicalMaterialMap from "./containers/ControlledMaterialMap/ChemicalMaterialMap";
import ControlledMaterialMaps from "./containers/ControlledMaterialMap/ControlledMaterialMaps";
import CityDashboard from "./containers/CityDashboard/CityDashboard";
import AreaDashboard from "./containers/AreaDashboard/AreaDashboard";
import CustomizedDashboard from "./containers/CustomizedDashboard/CustomizedDashboard";
import QuestionContainer from "./containers/Questionnaire/QuestionContainer";
import ECOContainer from "./containers/Questionnaire/ECOContainer";
import QuestionnaireRecord from "./containers/Questionnaire/QuestionnaireRecord";
import QuestionResult from "./containers/Questionnaire/QuestionDetail";
import SupplyChain from "./containers/Supplychain/Supplychain";
import UpdateCompanyInfo from "./containers/UpdateCompanyInfo/UpdateCompanyInfo";
import WithHelmet from "./components/WithHelmet/WithHelmet";
import Redirection from "./components/Redirection/Redirection";
import WastedCleanPage from "./containers/WastedCleanPage";
import EffectedEventDashboard from "./containers/EffectedEventDashboard";
import EventDashboard from "./containers/EventDashboard";
import BetaAbout from "./containers/About";
import AboutV2 from "./containers/AboutV2";
import PrivacyPolicy from "./containers/Policy/PrivacyPolicy";
import InformationSecurity from "./containers/Policy/InformationSecurity";
import InternetSecurityProtection from "./containers/Policy/InternetSecurityProtection";
import Investment from "./containers/Investment";
import SubscribePush from "./containers/SubscribePush";
import SiteMap from "./containers/SiteMap";
import CustomDashboardList from "./containers/CustomDashboardList";
import CustomIndustryDashboardList from "./containers/CustomizedIndustryDashboard/CustomIndustryDashboardList";
import CustomizedIndustryDashboard from "./containers/CustomizedIndustryDashboard/CustomizedIndustryDashboard";

import TaxInfo from "./containers/TaxInfo/TaxInfo";

import { getUserLog, updatePermission } from "./utility/api";
import Spinner from "./components/Spinner/Spinner";

import * as appActions from "./store/actions/app";
import * as authActions from "./store/actions/auth";
import * as userActions from "./store/actions/users";
import * as applyFileInfoActions from "./store/actions/applyFile";
import * as sectionActions from "./store/actions/section";
import { getOptionsHandler } from "./store/actions/supplychain";
import { getCompanyListHandler } from "./store/actions/app";
import { handleStartBasicInfo } from "./store/actions/factory";
import {
  handle_check_is_restricted,
  logoutHandler,
} from "./store/actions/auth";

// import './containers/MainPage/MainPage.css'
import { AuthContext } from "./context/auth-context";
import "./styles.scss";

// import FactoryBasicInfo from './containers/Factory/FactoryBasicInfo/FactoryBasicInfo';

const CustomDashboardListWithHelmet = () => (
  <WithHelmet head={"自訂關注廠商儀表板設定"}>
    <CustomDashboardList />
  </WithHelmet>
);

const SmartWindowWithHelmet = () => (
  <WithHelmet head={"園區櫥窗"}>
    <SmartWindow />
  </WithHelmet>
);

const CustomIndustryDashboardListWithHelmet = () => (
  <WithHelmet head={"自訂關注產業儀表板設定"}>
    <CustomIndustryDashboardList />
  </WithHelmet>
);

const CustomizedIndustryDashboardWithHelmet = (props) => (
  <WithHelmet head={"自訂關注產業儀表板"}>
    <CustomizedIndustryDashboard {...props} />
  </WithHelmet>
);

const BetaAboutWithHelmet = () => (
  <WithHelmet head={"首頁"}>
    <BetaAbout />
    <script defer src="animejsPlugins-ScrollContainer.js"></script>
  </WithHelmet>
);

const BetaAboutV2tWithHelmet = () => (
  <WithHelmet head={"首頁"}>
    <AboutV2 />
    {/* <BetaAbout />
    <script defer src="animejsPlugins-ScrollContainer.js"></script> */}
  </WithHelmet>
);
const PrivacyPolicyWithHelmet = () => (
  <WithHelmet head={"隱私權政策"}>
    <PrivacyPolicy />
  </WithHelmet>
);
const InformationSecurityPolicyWithHelmet = () => (
  <WithHelmet head={"資訊安全政策"}>
    <InformationSecurity />
  </WithHelmet>
);
const InternetSecurityProtectionWithHelmet = () => (
  <WithHelmet head={"網路安全保護措施"}>
    <InternetSecurityProtection />
  </WithHelmet>
);
const InvestmentWithHelmet = () => (
  <WithHelmet head={"入區投資"}>
    <Investment />
  </WithHelmet>
);
const SubscribePushWithHelmet = () => (
  <WithHelmet head={"關注廠商/產業訂閱"}>
    <SubscribePush />
  </WithHelmet>
);
const SiteMapWithHelmet = () => (
  <WithHelmet head={"網站導覽"}>
    <SiteMap />
  </WithHelmet>
);
const EffectedEventDashboardPageWithHelmet = () => (
  <WithHelmet head={"受事件影響儀表板"}>
    <EffectedEventDashboard />
  </WithHelmet>
);
const EventDashboardPage = () => (
  <WithHelmet head={"受事件影響儀表板"}>
    <EventDashboard />
  </WithHelmet>
);

const TaxInfoWithHelmet = () => (
  <WithHelmet head={"複合條件快搜"}>
    <TaxInfo />
  </WithHelmet>
);

const WastedCleanPageWithHelmet = () => (
  <WithHelmet head={"廢棄物清運機構"}>
    <WastedCleanPage />
  </WithHelmet>
);

const QuestionnaireWithHelmet = () => (
  <WithHelmet head={"問卷管理"}>
    <QuestionnaireRecord />
  </WithHelmet>
);

const QuestionResultWithHelmet = (props) => (
  <WithHelmet head={"問卷"}>
    <QuestionResult {...props} />
  </WithHelmet>
);

const QuestionEditWithHelmet = (props) => (
  <WithHelmet head={"問卷編輯"}>
    <QuestionContainer {...props} />
  </WithHelmet>
);

const QuestionContainerSheets = () => (
  <WithHelmet head={"訪廠問卷"}>
    <QuestionContainer />
  </WithHelmet>
);

const ECOContainerSheets = () => (
  <WithHelmet head={"環保問卷"}>
    <ECOContainer />
  </WithHelmet>
);

const AboutWithHelmet = (props) => (
  <WithHelmet head={"首頁"}>
    <About {...props} />
  </WithHelmet>
);
const AreaDashboradWithHelmet = (props) => (
  <WithHelmet head={"分區儀表板"}>
    <AreaDashboard {...props} />
  </WithHelmet>
);
const ParkDashboradWithHelmet = (props) => (
  <WithHelmet head={"園區儀表板"}>
    <ParkDashBoard {...props} />
  </WithHelmet>
);
const CustomizedDashboradWithHelmet = (props) => (
  <WithHelmet head={"自訂關注廠商儀表板"}>
    <CustomizedDashboard {...props} />
  </WithHelmet>
);
const CityDashboradWithHelmet = (props) => (
  <WithHelmet head={"縣市儀表板"}>
    <CityDashboard {...props} />
  </WithHelmet>
);
const IndustryIndexWithHelmet = (props) => (
  <WithHelmet head={"產業儀表板"}>
    <IndustryIndex {...props} />
  </WithHelmet>
);
const OperationsDashboardWithHelmet = (props) => (
  <WithHelmet head={"產業營運動態儀表板"}>
    <OperationsDashboard {...props} />
  </WithHelmet>
);
const MapSearchWithHelmet = (props) => (
  <WithHelmet head={"技術快搜"}>
    <MapSearch {...props} />
  </WithHelmet>
);
const ControlledMaterialMapWithHelmet = (props) => (
  <WithHelmet head={"廢棄物申報概況"}>
    <ControlledMaterialMap {...props} />
  </WithHelmet>
);
const ControlledMaterialsMapWithHelmet = (props) => (
  <WithHelmet head={"列管快搜"}>
    <ControlledMaterialMaps {...props} />
  </WithHelmet>
);
const SupplyChainWithHelmet = (props) => (
  <WithHelmet head={"產業鏈快搜"}>
    <SupplyChain {...props} />
  </WithHelmet>
);
const FactorySearchWithHelmet = (props) => (
  <WithHelmet head={"個廠情資查詢"}>
    <FactorySearch {...props} />
  </WithHelmet>
);
const GovPlanDisplayWithHelmet = (props) => (
  <WithHelmet head={"政府資源"}>
    <GovPlanDisplay {...props} />
  </WithHelmet>
);
const NewsWithHelmet = (props) => (
  <WithHelmet head={"新聞訂閱"}>
    <News {...props} />
  </WithHelmet>
);
const UploadInfoWithHelmet = (props) => (
  <WithHelmet head={"資料上傳"}>
    <UploadInfo {...props} />
  </WithHelmet>
);
const GovPlanManagementWithHelmet = (props) => (
  <WithHelmet head={"後台設定"}>
    <GovPlanManagement {...props} />
  </WithHelmet>
);
const FollowCompanyWithHelmet = (props) => (
  <WithHelmet head={"關注廠商訂閱"}>
    <FollowCompany {...props} />
  </WithHelmet>
);
const FollowIndustryWithHelmet = (props) => (
  <WithHelmet head={"關注產業訂閱"}>
    <FollowIndustry {...props} />
  </WithHelmet>
);
const TalentHandlerWithHelmet = (props) => (
  <WithHelmet head={"分案管理"}>
    <TalentHandler {...props} />
  </WithHelmet>
);
const TalentHandlerContentWithHelmet = (props) => (
  <WithHelmet head={"分案管理內容"}>
    <TalentHandler {...props} />
  </WithHelmet>
);
const TermsWithHelmet = (props) => (
  <WithHelmet head={"個資條款"}>
    <Terms {...props} />
  </WithHelmet>
);
const SharablePageWithHelmet = (props) => (
  <WithHelmet head={"分享平台"}>
    <SharablePage {...props} />
  </WithHelmet>
);
const EnMonitorWithHelmet = (props) => (
  <WithHelmet head={"南科工環境監測儀表板"}>
    <EnMonitor {...props} />
  </WithHelmet>
);
const UpdateCompanyInfoWithHelmet = (props) => (
  <WithHelmet head={"自訂廠商資料"}>
    <UpdateCompanyInfo {...props} />
  </WithHelmet>
);
const ChemicalMaterialMapWithHelmet = (props) => (
  <WithHelmet head={"毒化物快搜"}>
    <ChemicalMaterialMap {...props} />
  </WithHelmet>
);
const WastedLandingPageWithHelmet = (props) => (
  <WithHelmet head={"廢棄物相關頁面"}>
    <WastedLandingPage {...props} />
  </WithHelmet>
);

function App() {
  // console.log(process.env);
  const history = useHistory();
  const location = useLocation();
  const uid = localStorage.getItem("uid");
  const authContext = useContext(AuthContext);
  const { isAuth, singInLoading, restricted_list } = useSelector(
    (state) => state.auth
  );
  const { searchHistory } = useSelector((state) => state.app);
  const { ParkId } = useSelector((state) => state.user.userInfo);
  const { basicInfo, basicLoading } = useSelector((state) => state.factory);

  const [toggleMenu, setToggleMenu] = useState(false);
  const [redirec, setRedirect] = useState(false);
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);
  const dispatch = useDispatch();
  const url = useLocation().pathname.split("/")[1];
  const isFactorySearchSub = useLocation().pathname.split("/")[2];
  const [auth, setAuth] = useState(
    localStorage.getItem("isLogin") === "true" ? true : isAuth
  );
  const [signInModalOpen, setSignInModalOpen] = useState(false);
  const [updateUserModalOpen, setUpdateUserModalOpen] = useState(false);
  const [isSignUpInit, setIsSignUpInit] = useState(false);
  const [state, setState] = React.useState({
    setAuthStatus: "sign-in",
  });
  const chemicalMaterialUrl = "cmhleialmaricate";
  const currentPage = window.location.pathname.split("/")[1];

  //PassPermission
  const isPass = localStorage.getItem("client");

  //背景顏色切換功能
  const bgColor = new URLSearchParams(window.location.search).get("bgColor");
  if (bgColor) {
    document
      .querySelector("body")
      .style.setProperty("--main-color", `#${bgColor}`);
  }

  //透過 url 判斷是否為 black list
  React.useEffect(() => {
    dispatch(handle_check_is_restricted());
  }, [dispatch]);

  //是 black list 的話會回傳 true 就強制把使用者 log out
  React.useEffect(() => {
    console.log(restricted_list);

    if (
      restricted_list.length > 0 &&
      localStorage.getItem("isLogin") === "true"
    ) {
      const is_black_list = restricted_list.filter((i) => i === uid).length > 0;
      if (is_black_list) dispatch(logoutHandler());
    }
  }, [restricted_list, localStorage.getItem("isLogin"), dispatch, uid]);

  React.useEffect(() => {
    if (url === "factory_info") {
      if (location.pathname.indexOf("-") > -1) {
        const businessNo = location.pathname.split("/")[3].split("-")[0];
        dispatch(handleStartBasicInfo(businessNo));
      } else {
        const businessNo = location.pathname.split("/")[3];
        dispatch(handleStartBasicInfo(businessNo));
      }
    }
  }, [dispatch, url]);

  //把舊的 localstorage 移除
  React.useEffect(() => {
    localStorage.removeItem("mirdc_remember_expiry");
  }, []);

  React.useEffect(() => {
    if (!signInModalOpen)
      setState((state) => ({ ...state, setAuthStatus: "sign-in" }));
  }, [signInModalOpen]);

  useEffect(() => {
    if (signInModalOpen === false) {
      setIsSignUpInit(false);
    }
  }, [signInModalOpen, isSignUpInit]);

  useEffect(() => {
    dispatch(appActions.getViewerCountsHandler());
    dispatch(appActions.getReferenceInfoHandler());
    dispatch(getOptionsHandler());
    dispatch(getCompanyListHandler());
  }, [dispatch]);

  React.useEffect(() => {
    if (
      (url === "factory_search" &&
        !isFactorySearchSub &&
        localStorage.getItem("isLogin") === "true") ||
      (url === "portal" &&
        !isFactorySearchSub &&
        localStorage.getItem("isLogin") === "true")
    ) {
      dispatch(appActions.getSearchHistoryHandler());
    }
  }, [dispatch, isFactorySearchSub, url]);

  useEffect(() => {
    dispatch(sectionActions.fetchSectionMapInfoHandler(uid));
  }, [dispatch, uid]);

  // useEffect(() => {
  //   if (localStorage.getItem("mirdc_remember_expiry")) {
  //     if (
  //       new Date().getTime() > +localStorage.getItem("mirdc_remember_expiry")
  //     ) {
  //       dispatch(authActions.logoutHandler());
  //     }
  //   }
  // }, [dispatch]);

  useEffect(() => {
    if (!isPass && isAuth) {
      dispatch(authActions.logoutHandler());
    }
  }, [isPass, isAuth, dispatch]);

  useEffect(() => {
    //fetchUserInfo
    const uid = localStorage.getItem("uid");
    if (uid) {
      dispatch(userActions.getUserInfoHandler(uid));
    }
  }, [dispatch]);

  useEffect(() => {
    //fetchApplyFileInfo
    dispatch(applyFileInfoActions.getApplyFileInfoHandler());
  }, [dispatch]);

  useEffect(() => {
    dispatch(authActions.isLocalSign());
  }, [dispatch]);

  useEffect(() => {
    if (isAuth) {
      setAuth(isAuth);
    } else if (!isAuth) {
      setAuth(false);
    }
  }, [isAuth]);

  const auth_menu_handler = () => {
    if (localStorage.getItem("isLogin") !== "true") {
      Swal.fire({
        title: "提醒",
        text: "尚未登入，請登入或註冊帳號。",
        showDenyButton: true,
        denyButtonText: `前往登入`,
        denyButtonColor: "#a8a8a8",
        showCloseButton: true,
        confirmButtonText: "前往註冊",
        confirmButtonColor: "#25758A",
        showCancelButton: false,
      }).then((res) => {
        const { isConfirmed, isDenied, isDismissed } = res;
        if (isConfirmed) history.push("/sign-up");
        if (isDenied) history.push("/sign-in");
        if (isDismissed && currentPage !== "factory_info")
          history.push("/about");

        setToggleMenu(!toggleMenu);
      });
    } else {
      setToggleMenu(!toggleMenu);
    }
  };

  const menuHandler = () => {
    setToggleMenu(!toggleMenu);
  };

  //監聽螢幕寬度
  useEffect(() => {
    const resizeListener = () => {
      dispatch(appActions.innerWidthChanging(window.innerWidth));
      setInnerWidth(window.innerWidth);
    };
    window.addEventListener("resize", resizeListener);
  }, [dispatch]);

  useEffect(() => {
    const url = window.location.href;
    const data = new FormData();

    data.append("Url", url);
    data.append("UserId", localStorage.getItem("uid"));
    data.append("Name", localStorage.getItem("name"));

    if (process.env.NODE_ENV !== "development") {
      getUserLog(data);
    }
    // updatePermission().then(res=>{
    //   if(res.PassPermissions){
    //     res.PassPermissions.map(res => {
    //       localStorage.setItem(res.PermissionId, res.PermissionId)
    //     })
    //   }

    //   if (res.ParkPermissions){
    //     const { Client, indupark_upload, service_history } = res.ParkPermissions;
    //     localStorage.setItem('ParkPermission', JSON.stringify(Client));
    //     localStorage.setItem('ParkPermission', JSON.stringify(indupark_upload));
    //     localStorage.setItem('ParkPermission', JSON.stringify(service_history));
    //   }

    // })
  }, [url]);

  //確認當螢幕寬大於 1024 的時候 sub-nav-bar 永遠開著
  useEffect(() => {
    if (innerWidth > 1023) {
      setToggleMenu(true);
    }
  }, [innerWidth]);

  useEffect(() => {
    if (
      window.location.toString()[window.location.toString().length - 1] === "/"
    ) {
      setRedirect(true);
    }
  }, [redirec, setRedirect]);

  useEffect(() => {
    if (url === "sign-up") {
      setSignInModalOpen(true);
      setIsSignUpInit(true);
    } else if (url === "sign-in") {
      setSignInModalOpen(true);
    }
  }, [url]);

  // 透過url判斷 height 是不是需要 100vh (換不同cd的 class)
  let mainClass;
  let containClass;

  if (url === "advanced_search" || url === "map-search") {
    mainClass = "main-full";
  } else if (url === "factory_search") {
    mainClass = "main-full";
  } else if (url === "sign-in") {
    mainClass = "main-full";
  } else if (url === "client-response") {
    mainClass = "main-full";
  } else {
    mainClass = "main";
  }

  if (
    (url === "map-search" ||
      url === "client-response" ||
      url === "supply-chain") &&
    innerWidth < 1024
  ) {
    containClass = "small-contain";
  } else if (
    (url === "client-response" || url === "supply-chain") &&
    innerWidth > 1024
  ) {
    containClass = "full-height-contain";
  } else if (
    (url === "factory_search" || url === "sign-in") &&
    innerWidth < 1024
  ) {
    containClass = "not-scroll-contain";
  } else if (url === "factory_info") {
    containClass = "main-container";
  } else if (
    (url === "effected_event_dashboard") | (url === "wasted_landing_page") ||
    url === "updated_company" ||
    url === "" ||
    url === "about" ||
    url === "custom_dashboard" ||
    url === "coporaiton_tec"
  ) {
    containClass = "";
  } else {
    containClass = "contain";
  }

  return (
    <div className="App">
      <Redirection />
      <ScrollToTop />
      {innerWidth < 1024 && (
        <MobileMenu
          toggle={toggleMenu}
          menuHandler={menuHandler}
          auth_menu_handler={auth_menu_handler}
          openSignInModal={() => {
            setSignInModalOpen(true);
          }}
          openSignUpModal={() => {
            setSignInModalOpen(true);
            setState((state) => ({ ...state, setAuthStatus: "sign-up" }));
          }}
        />
      )}
      <div className={mainClass}>
        {innerWidth < 1024 && (
          <div className="main-title">
            <Header
              toggle={toggleMenu}
              innerWidth={innerWidth}
              menuHandler={menuHandler}
            />
          </div>
        )}
        {innerWidth >= 1024 && url !== chemicalMaterialUrl && (
          <div className="main-title">
            <Header
              toggle={toggleMenu}
              innerWidth={innerWidth}
              menuHandler={menuHandler}
              openSignInModal={() => {
                setSignInModalOpen(true);
              }}
              openSignUpModal={() => {
                setSignInModalOpen(true);
                setState((state) => ({ ...state, setAuthStatus: "sign-up" }));
              }}
              openUpdateUserInfo={() => {
                setUpdateUserModalOpen(true);
              }}
            />
            {url !== "talent-handler" && (
              <NavigationBar
                toggle={toggleMenu}
                uid={authContext.uid}
                menuHandler={menuHandler}
                userId={authContext.UserInfo.UserId}
                isAuth={isAuth}
                permission={[1, 2, 3]}
              />
            )}
          </div>
        )}

        <div
          className={containClass}
          id="content"
          style={{
            height: url === "map-search" ? "100vh" : "",
            overflowY: url === "map-search" ? "hidden" : "",
            paddingTop: url === chemicalMaterialUrl ? "0px" : "",
          }}
        >
          <Switch>
            {/* <Route render={() => <Redirect to={{ pathname: "/sign-in" }} />} /> */}
            <Route path="/about_beta" component={BetaAboutWithHelmet} />
            <Route path="/about" exact component={BetaAboutWithHelmet} />
            <Route path="/portal" exact component={BetaAboutV2tWithHelmet} />
            <Route
              path="/privacy_policy"
              exact
              component={PrivacyPolicyWithHelmet}
            />
            <Route
              path="/information_security_policy"
              exact
              component={InformationSecurityPolicyWithHelmet}
            />
            <Route
              path="/internet_security_protection"
              exact
              component={InternetSecurityProtectionWithHelmet}
            />
            <Route
              path="/investment" // 入區投資
              exact
              component={InvestmentWithHelmet}
            />
            <Route
              path="/subscribe_push" // 訂閱推播
              exact
              component={SubscribePushWithHelmet}
            />
            <Route
              path="/sitemap" // 網站導覽
              exact
              component={SiteMapWithHelmet}
            />
            {/* <Route path="/sign-in" exact component={() => <Login signIn />} />
            <Route path="/sign-up" exact component={() => <Login signUp />} />
            <Route path="/forget-pass-word" exact component={() => <Login signUp />} /> */}
            <Route
              path="/map-search/:slug"
              component={MapSearchWithHelmet}
              isLogin={auth}
            />
            <Route
              path="/map-search"
              component={MapSearchWithHelmet}
              isLogin={auth}
            />
            <Route
              path="/"
              exact
              component={BetaAboutWithHelmet}
              isLogin={auth}
            />
            <LoginRequired
              path="/custom_dashboard"
              component={CustomDashboardListWithHelmet}
              isLogin={auth}
            />
            <LoginRequired
              path="/smart_window"
              component={SmartWindowWithHelmet}
              isLogin={auth}
            />
            <LoginRequired
              path="/custom_industry_dashboard"
              component={CustomIndustryDashboardListWithHelmet}
              isLogin={auth}
            />
            <LoginRequired
              path="/wasted_landing_page"
              component={WastedLandingPageWithHelmet}
              isLogin={auth}
            />
            <LoginRequired
              path="/effected_event_dashboard"
              component={EffectedEventDashboardPageWithHelmet}
              isLogin={auth}
            />
            <LoginRequired
              path="/event_dashboard"
              component={EventDashboardPage}
              isLogin={auth}
            />
            <Route
              path="/park_dashboard/:park_id"
              component={ParkDashboradWithHelmet}
              isLogin={auth}
            />
            <LoginRequired
              path="/advanced_search"
              exact
              component={AdvancedSearch}
              isLogin={auth}
            />
            <LoginRequired
              path="/en_monitor"
              exact
              component={EnMonitorWithHelmet}
              isLogin={auth}
            />
            <Route
              path="/factory_search"
              exact
              component={FactorySearchWithHelmet}
              isLogin={auth}
            />
            <Route path="/factory_info/" component={FactoryInfo} />
            <LoginRequired
              path="/industry_index/:code"
              component={IndustryIndexWithHelmet}
              isLogin={auth}
            />
            <Route path="/operations_dashboard/" component={OperationsDashboardWithHelmet} />
            <LoginRequired
              path="/operations_dashboard/"
              component={OperationsDashboardWithHelmet}
              isLogin={auth}
            />
            <LoginRequired
              path="/geographic_system"
              exact
              component={UploadInfoWithHelmet}
              isLogin={auth}
            />
            <LoginRequired
              path="/coporaiton_tec/"
              component={NewsWithHelmet}
              isLogin={auth}
            />

            <Route
              path="/talent-handler/:expertId/:caseId"
              exact
              component={TalentHandlerContentWithHelmet}
            />
            <Route
              path="/talent-handler/:expertId/"
              exact
              component={TalentHandlerWithHelmet}
            />
            <LoginRequired
              path="/city_dashboard"
              exact
              isLogin={auth}
              component={CityDashboradWithHelmet}
            />
            <LoginRequired
              path="/city_dashboard/:cityId"
              exact
              isLogin={auth}
              component={CityDashboradWithHelmet}
            />
            <LoginRequired
              path="/area_dashboard"
              exact
              isLogin={auth}
              component={AreaDashboradWithHelmet}
            />
            <LoginRequired
              path="/area_dashboard/:cityId"
              exact
              isLogin={auth}
              component={AreaDashboradWithHelmet}
            />
            <LoginRequired
              path="/customized_dashboard"
              exact
              isLogin={auth}
              component={CustomizedDashboradWithHelmet}
            />
            <LoginRequired
              path="/customized_dashboard/:OrgId"
              exact
              isLogin={auth}
              component={CustomizedDashboradWithHelmet}
            />
            <LoginRequired
              path="/customized_industry_dashboard"
              exact
              component={CustomizedIndustryDashboardWithHelmet}
              isLogin={auth}
            />
            <LoginRequired
              path="/customized_industry_dashboard/:OrgId"
              exact
              isLogin={auth}
              component={CustomizedIndustryDashboardWithHelmet}
            />
            <LoginRequired
              path="/site-visit-record/"
              exact
              isLogin={auth}
              component={QuestionContainerSheets}
            />
            <LoginRequired
              path="/ECO-questionnaire/"
              exact
              isLogin={auth}
              component={ECOContainerSheets}
            />
            <LoginRequired
              path="/ECO-questionnaire/edit/:QId"
              exact
              isLogin={auth}
              component={ECOContainerSheets}
            />
            <LoginRequired
              path="/questionnaire-management"
              exact
              component={QuestionnaireWithHelmet}
              isLogin={auth}
            />
            <LoginRequired
              path="/site-visit-record/:QId"
              exact
              isLogin={auth}
              component={QuestionResultWithHelmet}
            />
            <LoginRequired
              path="/site-visit-record/edit/:QId"
              exact
              isLogin={auth}
              component={QuestionEditWithHelmet}
            />
            <Route
              path="/limited-visit/:releaseId"
              component={QuestionResultWithHelmet}
            />
            {/* <LoginRequired
              path="/consulting-records/:userId"
              exact
              isLogin={auth}
              component={ConsultingRecordSheets}
            /> */}
            <LoginRequired
              path="/supply-chain"
              exact
              isLogin={auth}
              component={SupplyChainWithHelmet}
            />
            <LoginRequired
              path="/gov_plan_management/"
              component={GovPlanManagementWithHelmet}
              isLogin={auth}
            // exact
            />
            <Route
              path="/gov_plan_display?key_word="
              component={GovPlanDisplayWithHelmet}
            // exact
            />
            <LoginRequired
              path="/gov_plan_display/:project_id"
              component={GovPlanDisplayWithHelmet}
            // exact
            />
            <Route
              path="/client-response/:requestId"
              component={ClientResponse}
              exact={false}
            />
            {/* {auth && */}
            <Route
              path="/gov_plan_display"
              component={GovPlanDisplayWithHelmet}
              exact
            />
            <LoginRequired
              path="/industry_subscribe"
              component={FollowIndustryWithHelmet}
              exact
            />
            <LoginRequired
              path="/company_subscribe"
              component={FollowCompanyWithHelmet}
              exact
            />
            <LoginRequired
              path="/updated_company"
              component={UpdateCompanyInfoWithHelmet}
            />
            <Route path="/terms" component={TermsWithHelmet} />

            <LoginRequired
              path="/change_password"
              exact
              component={ChangePass}
              isLogin={auth}
            />
            <Route
              path="/activeAccount/:params"
              exact
              component={ActiveAccount}
            />
            <Route
              path="/forget_password_redirection/:uid"
              exact
              component={ForgetPassWordDirection}
            />
            <Route path="/sharable-page" component={SharablePageWithHelmet} />
            <LoginRequired
              path="/controlled-material"
              component={ControlledMaterialMapWithHelmet}
            />
            <LoginRequired
              path="/controlled-materials"
              component={ControlledMaterialsMapWithHelmet}
            />
            <LoginRequired
              path="/wasted_clean"
              component={WastedCleanPageWithHelmet}
            />
            <LoginRequired path="/tax_info" component={TaxInfoWithHelmet} />
            {/* <Route
              path={`/${chemicalMaterialUrl}`}
              component={ChemicalMaterialMapWithHelmet}
            /> */}

            <Route component={BetaAboutWithHelmet} />
          </Switch>
          <Backdrop className="back-drop" open={singInLoading}>
            <Spinner />
          </Backdrop>
          <SignUpBox
            isOpen={signInModalOpen}
            isSignUpInit={isSignUpInit}
            closeModal={() => {
              setSignInModalOpen(false);
            }}
            setAuthStatus={state.setAuthStatus}
          />
          <UpdateUserInfo
            isOpen={updateUserModalOpen}
            closeModal={() => {
              setUpdateUserModalOpen(false);
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default App;
