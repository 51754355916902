import * as actionTypes from "../actions/actionTypes";
import * as actionType from "../actionTypes/subscribe";

const initState = {
  companyInfo: [],
  companyError: "",
  companyLoading: false,
  industryInfo: [],
  industryError: "",
  industryLoading: false,
};

export const startGetCompany = (state) => {
  return {
    ...state,
    companyLoading: true,
  };
};

export const getCompanySuccess = (state, action) => {
  return {
    ...state,
    companyLoading: false,
    companyInfo: action.data,
  };
};

export const getCompanyFail = (state, action) => {
  return {
    ...state,
    companyLoading: false,
    companyError: action.error,
  };
};

export const startGetIndustry = (state) => {
  return {
    ...state,
    industryLoading: true,
  };
};

export const getIndustrySuccess = (state, action) => {
  return {
    ...state,
    industryLoading: false,
    industryInfo: action.data,
  };
};

export const getIndustryFail = (state, action) => {
  return {
    ...state,
    industryLoading: false,
    industryError: action.error,
  };
};

export default (state = initState, action) => {
  switch (action.type) {
    case actionType.START_GET_COMPANY_INFO:
      return startGetCompany(state);
    case actionType.GET_COMPANY_INFO_SUCCESS:
      return getCompanySuccess(state, action);
    case actionType.GET_COMPANY_INFO_FAIL:
      return getCompanyFail(state, action);        
    case actionType.START_GET_INDUSTRY_INFO:
      return startGetIndustry(state);
    case actionType.GET_INDUSTRY_INFO_SUCCESS:
      return getIndustrySuccess(state, action);
    case actionType.GET_INDUSTRY_INFO_FAIL:
      return getIndustryFail(state, action);         
    default:
      return state;
  }
};
