import React from "react";
import ReactExport from "react-data-export";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';
import Button from "@material-ui/core/Button";

import * as theme from "../assets/theme"

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const ExportWastedCleanData = ({waste_name}) => {
    const { wasted_pollution_data } = useSelector((state) => state.wasted_clean);
    const [state, setState] = React.useState({
        file_data:[]
    });

    React.useEffect(()=>{
        try{
            const excel_list = [];
            if(wasted_pollution_data.length > 0){
                wasted_pollution_data.forEach(company=>{                    
                    excel_list.push({
                        廠商名稱:company.CompanyName,
                        廠商統編:company.BusinessNo,
                        地址:company.Address,
                        縣市別:company.County,
                        廠商電話:company.Phone,
                        廢棄物種類:waste_name,
                        月處理總量:company.Amount,
                    })
                })
                setState(state=>({
                    ...state,
                    file_data: excel_list
                }))
            }else{
                setState(state=>({
                    ...state,
                    file_data: []
                }))
            }
        }catch(err){
            setState(state=>({
                ...state,
                file_data: []
            }))
        }

    },[wasted_pollution_data])

    return(
        <ExcelFile filename={`${waste_name}搜尋結果清單_產業園區廠商情資平台`} element={<ExportButton startIcon={<FontAwesomeIcon className="export-excel-icon" icon={faFileExcel} />}>匯出 Excel 報表</ExportButton>}>
            {state.file_data.length !== 0 && 
                <ExcelSheet data={state.file_data} name="清單">
                    <ExcelColumn label="廠商名稱" value="廠商名稱"/>
                    <ExcelColumn label="地址" value="地址"/>
                    <ExcelColumn label="縣市別" value="縣市別"/>
                    <ExcelColumn label="廠商電話" value="廠商電話"/>
                    <ExcelColumn label="廢棄物種類" value="廢棄物種類"/>
                    <ExcelColumn label="月處理總量(公噸)" value="月處理總量"/>
                </ExcelSheet>
            }
        </ExcelFile>
    ) 
};


const ExportButton = styled(Button)`
    display: ${() => localStorage.getItem('auL') <= 3 ? "flex" : "none"};
    background-color: ${theme.primaryColor};
    color: ${theme.white};
    padding: 5px 15px; 
`

export default ExportWastedCleanData;