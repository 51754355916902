import React from "react";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import Swal from "sweetalert2";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { parkDashboardList } from "../../constants/parks";
import { handleGetReferenceInfoData } from "../../utility/reference_info";
import redLight from "../../assets/images/redLight.png";
import greenLight from "../../assets/images/greenLight.png";
import balanceStatus from "../../assets/images/minus.png";

import "./styles.scss";

export default (props) => {
  const {
    title,
    subTitle,
    selectionOption,
    onChangeHandler,
    selectedCity,
    isIndustry,
    isArea, // 是分區儀表板
    referenceType,
    isPatent,
    handleKeyWord,
    keyWord,
    remark,
    isOperation,
    dataAbstract,
    lastDate,
    skipOperationCalc,
  } = props;
  const [state, setState] = React.useState({
    city: "",
    sampleArea: [],
    northArea: [],
    westArea: [],
    southArea: [],
    parkDashboardList: [],
    kpArea: [],
    eastArea: [],
    isModalOpen: false,
    patenSelection: "all",
  });

  const openReferenceInfoAlert = async () => {
    const data = await handleGetReferenceInfoData(referenceType);

    let content = "";
    if (!data) {
      Swal.fire({
        title: "引用資料來源",
        html: remark,
        text: remark ? "" : "資料正在建置中 ... ",
      });
    } else {
      try {
        data
          .split("。")
          .map(
            (referenceItem) =>
            (content +=
              "<p style='text-align:left'>" + referenceItem + "</p>")
          );
        Swal.fire({
          title: "引用資料來源",
          html: content,
        });
      } catch (err) {
        Swal.fire({
          title: "引用資料來源",
          text: "資料正在建置中 ... ",
        });
      }
    }
  };

  React.useEffect(() => {
    setState((state) => ({ ...state, city: selectedCity }));
  }, [selectedCity]);

  const handleChangeCity = (e) => {
    const { value, name } = e.target;
    setState((state) => ({ ...state, [name]: value }));
    onChangeHandler(value, name);
  };

  React.useEffect(() => {
    const sampleArea = selectionOption?.filter((data) => data.isAccess === 1);
    const northArea = selectionOption?.filter(
      (data) => data.location === "臺北分局" && data.isAccess !== 1
    );
    const westArea = selectionOption?.filter(
      (data) => data.location === "臺中分局" && data.isAccess !== 1
    );
    const kpArea = selectionOption?.filter(
      (data) => data.location === "高屏分局" && data.isAccess !== 1
    );
    const southArea = selectionOption?.filter(
      (data) => data.location === "臺南分局" && data.isAccess !== 1
    );
    const eastArea = selectionOption?.filter(
      (data) => data.location === "東區" && data.isAccess !== 1
    );

    setState((state) => ({
      ...state,
      sampleArea,
      northArea,
      westArea,
      kpArea,
      parkDashboardList,
      southArea,
      eastArea,
    }));
  }, [selectionOption]);

  return (
    <div className="dashboard-header-container">
      <div className="dashboard-header">
        <div className={!isOperation ? "dashboard-header-title" : "dashboard-header-title-forOperations"}>
          <h2>{title}</h2>
          <button title="開啟引用資料來源彈出視窗" onClick={() => openReferenceInfoAlert(referenceType)}>
            <FontAwesomeIcon
              className="icon-reference"
              icon={faInfoCircle}
            />
          </button>
        </div>
        <div>
          {isPatent && (
            <React.Fragment>
              <TextField
                id="standard-search"
                label="專利研發關鍵字搜尋"
                type="search"
                value={keyWord}
                variant="standard"
                style={{ width: "500px" }}
                className="dashboard-header-selection"
                onChange={(e) => handleKeyWord(e)}
              />
              <Select
                native
                value={state.patenSelection}
                onChange={(e) => handleChangeCity(e)}
                name="patenSelection"
                style={{ width: "100px", margin: "16px 30px" }}
                className="dashboard-header-selection">
                <option value={"all"} selected={state.patenSelection === "all"}>
                  全國
                </option>
                <option
                  value={"section"}
                  selected={state.patenSelection === "section"}>
                  園區
                </option>
              </Select>
            </React.Fragment>
          )}

          {((selectionOption && !isPatent) ||
            (selectionOption &&
              isPatent &&
              state.patenSelection === "section")) && (
              <Select
                native
                value={state.city}
                onChange={(e) => handleChangeCity(e)}
                name="city"
                style={{ width: "200px" }}
                className="dashboard-header-selection">
                {state.sampleArea.map((option) => {
                  return (
                    <option key={option.key} value={option.value}>
                      {`(示範園區) ${option.name}`}
                    </option>
                  );
                })}
                {isArea && <option value="All">全區</option>}
                {selectionOption.map((option) => {
                  if (
                    selectionOption.length === 4 ||
                    selectionOption.length === 21 ||
                    (selectionOption.length === 22 && !isIndustry)
                  ) {
                    return (
                      <option key={option.key} value={option.value}>
                        {option.name}
                      </option>
                    );
                  } else if (isIndustry) {
                    return (
                      <option key={option.Key} value={option.Key}>
                        {option.Key} {option.Value}
                      </option>
                    );
                  }
                })}
                {selectionOption.length !== 4 &&
                  selectionOption.length !== 21 &&
                  selectionOption.length !== 22 &&
                  !isIndustry && (
                    <>
                      <optgroup
                        label="臺北分局"
                        style={{ backgroundColor: "pink" }}>
                        {state.northArea.map((option) => {
                          return (
                            <option key={option.key} value={option.value}>
                              {option.name}
                            </option>
                          );
                        })}
                      </optgroup>
                      <optgroup
                        label="臺中分局"
                        style={{ backgroundColor: "pink" }}>
                        {state.westArea.map((option) => {
                          return (
                            <option key={option.key} value={option.value}>
                              {option.name}
                            </option>
                          );
                        })}
                      </optgroup>
                      <optgroup
                        label="臺南分局"
                        style={{ backgroundColor: "pink" }}>
                        {state.southArea.map((option) => {
                          return (
                            <option key={option.key} value={option.value}>
                              {option.name}
                            </option>
                          );
                        })}
                      </optgroup>
                      <optgroup
                        label="高屏分局"
                        style={{ backgroundColor: "pink" }}>
                        {state.kpArea.map((option) => {
                          return (
                            <option key={option.key} value={option.value}>
                              {option.name}
                            </option>
                          );
                        })}
                        {state.eastArea.map((option) => {
                          return (
                            <option key={option.key} value={option.value}>
                              {option.name}
                            </option>
                          );
                        })}
                      </optgroup>
                      {/* <optgroup
                      label="東區"
                      style={{ backgroundColor: "pink" }}></optgroup> */}
                      <optgroup
                        label="地方型園區"
                        style={{ backgroundColor: "pink" }}>
                        {parkDashboardList.map((option) => {
                          return (
                            <option
                              key={option["ParkId"]}
                              value={option["ParkId"]}>
                              {option["Name_CH"]}
                            </option>
                          );
                        })}
                      </optgroup>
                    </>
                  )}
              </Select>
            )}
        </div>
      </div>
      <div className={!isOperation ? "dashboard-sub-header" : "dashboard-sub-header-forOperations"}>
        {!isOperation ? (<p>{subTitle}</p>)
          : (
            !skipOperationCalc ? (
              <div>
                <a style={{ fontWeight: "bolder" }}>{lastDate}</a>
              </div>
            ) : (
              <>
                <div>
                  <a style={{ fontWeight: "bolder" }}>{lastDate}</a>
                </div>
                <div className="operationsAbstractContainer">
                  <div className="operationsAbstractItem">
                    <img src={redLight} loading={"lazy"} width={35} />
                    <p>{`上漲${dataAbstract?.up}個產業`}</p>
                  </div>
                  <div className="operationsAbstractItem">
                    <img src={greenLight} loading={"lazy"} width={35} />
                    <p>{`下跌${dataAbstract?.down}個產業`}</p>
                  </div>
                  <div className="operationsAbstractItem">
                    <img src={balanceStatus} loading={"lazy"} width={40} style={{ padding: "5px" }} />
                    <p>{`持平${dataAbstract?.balance}個產業`}</p>
                  </div>
                </div>
              </>
            )
          )}
      </div>
    </div>
  );
};
