import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import "./styles.scss";

const FactoryImportExport = (props) => {
  const { data, headerList, getForeignTradesCompareData, loading } = props;
  const [state, setState] = React.useState({
    endIndex: 0,
    page: 0,
    rowsPerPage: 5,
  });
  const url = window.location.origin;

  let content;

  React.useEffect(() => {
    if (Math.ceil(data.length) === state.page) {
      if (data.length % 5 === 0) {
        setState((state) => ({
          ...state,
          endIndex: state.page * state.rowsPerPage + state.rowsPerPage,
        }));
      } else {
        setState((state) => ({
          ...state,
          endIndex: state.page * state.rowsPerPage + (data.length % 5),
        }));
      }
    } else {
      setState((state) => ({
        ...state,
        endIndex: state.page * state.rowsPerPage + state.rowsPerPage,
      }));
    }
  }, [data.length, state.page]);

  content = data.map((row, index) => (
    <TableRow
      key={row.BusiNo}
      hover
      className="table-body-row"
      style={index % 2 !== 0 ? { backgroundColor: "#ececec" } : {}}
    >
      {index === 0 ? (
        <React.Fragment>
          <TableCell align="left">
            {getForeignTradesCompareData[0].Year}/
            {getForeignTradesCompareData[0].Month}月 
            { (getForeignTradesCompareData[0]["Same_Period_Import"] || getForeignTradesCompareData[0]["Same_Period_Export"]) && `(${getForeignTradesCompareData[0].Lastyear}/${getForeignTradesCompareData[0].Month}月)`}
          </TableCell>
          <TableCell align="left">
            {getForeignTradesCompareData[0].Import}
            {getForeignTradesCompareData[0]["Same_Period_Import"]
              ? `(${getForeignTradesCompareData[0]["Same_Period_Import"]})`
              : ""}
          </TableCell>
          <TableCell align="left">
            {getForeignTradesCompareData[0].Export}
            {getForeignTradesCompareData[0]["Same_Period_Export"]
              ? `(${getForeignTradesCompareData[0]["Same_Period_Export"]})`
              : ""}
          </TableCell>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <TableCell align="left">{row.Year}</TableCell>
          <TableCell align="left">{row["Trade_I_Level"]}</TableCell>
          <TableCell align="left">{row["Trade_E_Level"]}</TableCell>
        </React.Fragment>
      )}
    </TableRow>
  ));
  const openNewTabs = (link) => window.open(link, "_blank");

  return (
    <TableContainer className="table-container">
      <Table className="table">
        <TableHead className="table-header">
          {headerList && (
            <TableRow className="table-row">
              {headerList.map((headerItem) => (
                <TableCell
                  style={{
                    fontWeight: 600,
                  }}
                  className="table-cell"
                  align="left"
                >
                  {headerItem}
                </TableCell>
              ))}
            </TableRow>
          )}
        </TableHead>
        <TableBody className="table-body">
          {data.length !== 0 && content}
        </TableBody>
      </Table>
      {data.length === 0 && (
        <Table>
          <TableBody className="table-body-no-data">
            <TableRow hover className="table-body-row">
              <TableCell align="center" className="no-data">
                本項目無資料
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      )}
    </TableContainer>
  );
};

export default React.memo(FactoryImportExport);
