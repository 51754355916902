import React from "react";
import styled from "styled-components";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Swal from "sweetalert2";
import "./styles.scss";

export default (props) => {
  const { data, eventHandler, headerList } = props;

  return (
    <TableContainer className="table-container">
      <Table className="table">
        <TableHead className="table-header">
          {headerList && (
            <TableRow className="table-row">
              {headerList.map((headerItem) => (
                <TableCell className="table-cell" align={"left"}>
                  {headerItem}
                </TableCell>
              ))}
            </TableRow>
          )}
        </TableHead>
        <TableBody className="waste-table-body">
          {data?.map((item, index) => {
            return (
              <TableRow hover className="table-body-row">
                <TableCells align="left" className="pollution" width={60}>
                  {item.Number + " " + item.Pollution}
                </TableCells>
                <TableCells
                 width={20}
                  align="left"
                  className="company-Count"
                >
                  <button onClick={() =>{
                      if(localStorage.getItem("17")){
                        eventHandler(
                          index + 1,
                          `${item.Number + " " + item.Pollution}`,
                          `${item.Number}`
                        )
                      }else{
                        Swal.fire({
                          title: "提醒",
                          text: "您無操作此功能權限，請聯絡管理員。",
                          confirmButtonText: "確認",
                        })
                      }
                    }
                  }>
                    {item.Count}
                  </button>
                </TableCells>
                <TableCells align="left" className="pollution" width={20}>
                  {item.Total.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                </TableCells>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      {data?.length === 0 && (
        <Table>
          <TableBody className="table-body-no-data">
            <TableRow hover className="table-body-row">
              <TableCell align="center" className="no-data">
                本項目無資料
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      )}
    </TableContainer>
  );
};


const TableCells = styled(TableCell)`
  width: ${p => `${p.width}%`} !important
`