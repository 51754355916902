import * as actionTypes from "../actionTypes/operationsDashboard";

const initState = {
  operationExportData: {},
  operationExportAccumulatedAbstructData: {},
  operationExportDataLoading: "",
  operationMonthData: {},
  operationMonthAbstructData: {},
  operationMonthAccumulatedAbstructData: {},
  operationMonthDataLoading: "",
  err: "",
};

//出口級距
export const startGetOperationExportData = (state) => {
  return {
    ...state,
    operationExportDataLoading: true,
  }
};

export const getOperationExportDataSuccess = (state, data) => {
  return {
    ...state,
    operationExportData: data.data,
    operationExportAccumulatedAbstructData: data.calcAccumulatedAbstract,
    operationExportDataLoading: false
  }
};

export const getOperationExportDataFail = (state, err) => {
  return {
    ...state,
    operationExportDataLoading: false,
    err: err
  }
};

//每月營收
export const startGetOperationMonthData = (state) => {
  return {
    ...state,
    operationMonthDataLoading: true,
  }
};

export const getOperationMonthDataSuccess = (state, data) => {
  return {
    ...state,
    operationMonthData: data.data,
    operationMonthAbstructData: data.calcAbstract,
    operationMonthAccumulatedAbstructData: data.calcAccumulatedAbstract,
    operationMonthDataLoading: false
  }
};

export const getOperationMonthDataFail = (state, err) => {
  return {
    ...state,
    operationMonthDataLoading: false,
    err: err
  }
};

export default (state = initState, action) => {
  switch (action.type) {
    case actionTypes.START_GET_OPERATION_EXPORT_DATA:
      return startGetOperationExportData(state);
    case actionTypes.GET_OPERATION_EXPORT_DATA_SUCCESS:
      return getOperationExportDataSuccess(state, action.data);
    case actionTypes.GET_OPERATION_EXPORT_DATA_FAIL:
      return getOperationExportDataFail(state, action.err);
    case actionTypes.START_GET_OPERATION_MONTH_DATA:
      return startGetOperationMonthData(state);
    case actionTypes.GET_OPERATION_MONTH_DATA_SUCCESS:
      return getOperationMonthDataSuccess(state, action.data);
    case actionTypes.GET_OPERATION_MONTH_DATA_FAIL:
      return getOperationMonthDataFail(state, action.err);
    default:
      return state;
  }
};