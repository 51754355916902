import React from "react";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TablePagination from "@material-ui/core/TablePagination";

import NoData from "./NoData";

import { paginationListHandler } from "../../utility/common";

export default (props) => {
    const { data } = props;
    const [state, setState] = React.useState({
        endIndex:0,
        page:0,
        rowsPerPage:5
    })
    const url = window.location.origin;

    const openNewTabs = (link) => window.open(link, "_blank");

    const handleChangePage = (event, newPage) => {
      setState(state=>({ ...state, page: newPage }))
    }

    const companyType = (type) => {
        if(type.indexOf("<category>") > -1){
            return type.substring(type.indexOf("<category>")+"<category>".length,type.indexOf("</category>"));
        }else{
            return "無相關資料"
        }
    };

    React.useEffect(()=>{
      if(data && Math.ceil(data.length) === state.page){
        if(data.length % 5 === 0){
          setState(state=>({ ...state, endIndex: state.page * state.rowsPerPage + state.rowsPerPage }))
        }else{
          setState(state=>({ ...state, endIndex: state.page * state.rowsPerPage + data.length % 5 }))
        }
      }else{
        setState(state=>({ ...state, endIndex: state.page * state.rowsPerPage + state.rowsPerPage }))
      }
    },[state.page])


    return(
        <TableContainer className="table-container">
        <Table className="table">
          <TableHead className="table-header">
            <TableRow className="table-row">
                <TableCell className="table-cell" align="left">計畫名稱</TableCell>
                <TableCell className="table-cell" align="left">計畫別</TableCell>
                <TableCell className="table-cell" align="left">年份</TableCell>
            </TableRow>
          </TableHead>
          <TableBody className="table-body">
            {data && data.length !== 0 ? paginationListHandler(data, state.page * state.rowsPerPage, state.endIndex).map((row, index)=>{
              return(
                <TableRow key={row.BusinessNo} hover  className="table-body-row" style={index%2 !== 0 ? {backgroundColor:"#ececec"}:{}}>
                  <TableCell align="left">{row.PlanName}</TableCell>
                  <TableCell align="left">{row.Type}</TableCell>
                  <TableCell align="left">{row.Year}</TableCell>
                </TableRow>
              )
            }): <NoData />}
          </TableBody>
        </Table>
        {data && data.length > 5 &&
            <TablePagination
            rowsPerPageOptions={[]}
            component="div"
            count={data && data.length}
            rowsPerPage={state.rowsPerPage}
            page={state.page}
            onChangePage={handleChangePage}
          />
        }
        </TableContainer>
    )
}