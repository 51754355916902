import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import * as actionTypes from "../actions/actionTypes";
import { fetchUserInfo, updateUserInfo } from "../../utility/api";

const MySwal = withReactContent(Swal);


export const startGetUserInfo = () => async dispatch => {
   
};

export const getUserInfoSuccess = () => async dispatch => {
    
}
export const getUserInfo = () => async dispatch => {
    
};

export const getUserInfoHandler = uid  => async dispatch => {
    dispatch({ type: actionTypes.START_GET_USER_INFO })

    const userInfo = await fetchUserInfo(btoa(uid));


    if(userInfo.status === 200 && userInfo.data.Email){
        dispatch({ type: actionTypes.GET_USER_INFO_SUCCESS, userInfo:userInfo.data })
    }else{
        dispatch({ type: actionTypes.GET_USER_INFO_FAIL, err: userInfo })

        console.error("取得使用這資訊發生問題")
    }
}

export const updateUserInfoHandler = data => async dispatch => {

    dispatch({ type: actionTypes.START_UPDATE_USER_INFO })
    const file = new FormData();
    file.append('Account', data.account);
    file.append('Name', data.name || "");
    file.append('ParkId', data.parkId);
    file.append('CompanyName', data.company || "");
    file.append('Email', data.email || "");
    file.append('Phone',data.phone || "");
    file.append('Department', data.department || "");
    file.append('JobTitle', data.jobTitle || "");
    file.append('BusinessNo', data.businessNo || "");


    const updated = await updateUserInfo(file);
    if(updated.data === "更新成功"){
        MySwal.fire({
            text: "更新成功",
        }).then(() => {
            dispatch({ type: actionTypes.UPDATE_USER_INFO_SUCCESS });
        });
    }else if(updated.status === 400){
        MySwal.fire({
            text: `更新失敗，${updated.data}`,
        }).then(() => {
            dispatch({ type: actionTypes.UPDATE_USER_INFO_FAIL });
        });
    }else{
        MySwal.fire({
            text: "更新失敗",
        }).then(() => {
            dispatch({ type: actionTypes.UPDATE_USER_INFO_FAIL });
        });
    }

}

export const startupdateUserInfo = () => async dispatch => {
    
} 

// export const updateUserInfo = () => async dispatch => {
    
// } 