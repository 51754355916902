import React from "react";
import { Card } from "react-bootstrap";
import { useSelector } from "react-redux";

import CustomizedCard from "../../../components/CustomizedCard/CustomizedCardV3";
import FactoryServiceHistory from "../../../components/CustomizedTable/FactoryInfo/FactoryServiceHistory";
import MobileServiceHistory from "../../../components/MobileGrid/FactoryInfo/ServiceHistory/ServiceHistory";

import { NoAuthCard, NoAuthCardText } from "../FactoryInfo/styles";

const ServiceHistory = (props) => {
  const { innerWidth } = useSelector((state) => state.app);
  const { serviceHistoryInfo, serviceHistoryInfoLoading } = useSelector(
    (state) => state.factory
  );

  if (serviceHistoryInfo.status !== 200) {
    return (
        <NoAuthCard>
          <NoAuthCardText>您尚無瀏覽該廠商服務歷程權限，若有疑問請聯繫平台管理員，謝謝。</NoAuthCardText>
        </NoAuthCard>
    );
  } else {
    if (innerWidth > 1023) {
      return (
        <React.Fragment>
          <Card>
            <CustomizedCard
              title="服務歷程"
              referenceType={"factoryInfoServiceHistory"}
              loading={serviceHistoryInfoLoading}
            >
              <FactoryServiceHistory
                data={serviceHistoryInfo.data}
                headerList={["時間", "類別", "所屬園區", "內容", "訪視人員"]}
              />
            </CustomizedCard>
          </Card>
        </React.Fragment>
      );
    } else {
      return <MobileServiceHistory data={serviceHistoryInfo.data} referenceType={"factoryInfoServiceHistory"}/>;
    }
  }
};

export default ServiceHistory;
