import * as actionTypes from "../actionTypes/operationsDashboard";
import { getOperationData, getOperationExportData } from "../../utility/operationsDashboard";

//出口級距
export const startGetOperationExportData = () => {
  return {
    type: actionTypes.START_GET_OPERATION_EXPORT_DATA,
  };
};

export const getOperationExportDataSuccess = (data) => {
  return {
    type: actionTypes.GET_OPERATION_EXPORT_DATA_SUCCESS,
    data: data,
  };
};

export const getOperationExportDataFail = (err) => {
  return {
    type: actionTypes.GET_OPERATION_EXPORT_DATA_FAIL,
    err: err,
  };
};

export const handleOperationExportData = (induTwoCode) => {
  return (dispatch) => {
    dispatch(startGetOperationExportData());
    getOperationExportData(induTwoCode)
      .then((res) => {
        if (res) {
          dispatch(getOperationExportDataSuccess(res));
        } else {
          dispatch(getOperationExportDataFail("取得智慧櫥窗時發生問題。"));
        }
      })
      .catch((err) => {
        dispatch(getOperationExportDataFail(err));
      });
  };
};

//每月營收
export const startGetOperationMonthData = () => {
  return {
    type: actionTypes.START_GET_OPERATION_MONTH_DATA,
  };
};

export const getOperationMonthDataSuccess = (data) => {
  return {
    type: actionTypes.GET_OPERATION_MONTH_DATA_SUCCESS,
    data: data,
  };
};

export const getOperationMonthDataFail = (err) => {
  return {
    type: actionTypes.GET_OPERATION_MONTH_DATA_FAIL,
    err: err,
  };
};

export const handleOperationMonthData = (induTwoCode) => {
  return (dispatch) => {
    dispatch(startGetOperationMonthData());
    getOperationData(induTwoCode)
      .then((res) => {
        if (res) {
          dispatch(getOperationMonthDataSuccess(res));
        } else {
          dispatch(getOperationMonthDataFail("取得智慧櫥窗時發生問題。"));
        }
      })
      .catch((err) => {
        dispatch(getOperationExportDataFail(err));
      });
  };
};
