import React from "react";
import Box from "@material-ui/core/Box";
import "./style.scss";

const InformationSecurity = () => {
  return (
    <div class="policy">
      <div class="policy-container">
        <h1>資訊安全政策 │</h1>
        <div>
          <Box mt={4}>
            <h2>資通安全宣告</h2>
            <p>
              為確保網際網路使用者在瀏覽本網站時的之資通安全，故訂定此資通安全宣告。
              藉以協助您瞭解本中心如何保護您在使用本網站各項服務的安全，以及如何蒐集、運用及保護您所提供的各項資訊。
              請您詳讀下列內容，以促進您安心使用本網站的各項貼心服務。
            </p>
          </Box>

          <Box mt={4}>
            <h2>資通安全政策</h2>
            <p>
              為維護本中心資訊業務與資訊處理設施之營運，建立資訊處理、傳送及儲存之安全環境。
              <br />
              ‧強化人員之資通安全認知
              <br />
              ‧確保資訊之機密、完整、可用
              <br />
              ‧落實資通安全管理
              <br />
              以避免當發生人為疏失、蓄意破壞或自然災害時，遭致資訊業務流程相關資產不當使用、洩漏、篡改、毀損、遺失等情事，影響本中心業務運作或損及本中心權益。
            </p>
          </Box>

          <Box mt={4}>
            <h2>資通安全保護措施</h2>
            <p>
              本網站為保護個人資料及網站運作的安全性，採取下列措施來維持網站正常運作：
            </p>
            <ol style={{
              listStyleType: "decimal",
              paddingLeft: "20px",
            }}>
              <li>架設硬體防火牆，來防止網際網路上的入侵或攻擊。</li>
              <li>安裝網路監控系統，監控網路流量。</li>
              <li>架設防毒系統，以防止病毒之入侵。</li>
              <li>定期與不定期更新網站作業系統或應用程式的相關更新程式碼。</li>
              <li>定期與不定期備份網站資料。</li>
            </ol>
          </Box>

          <Box mt={4}>
            <h2>資通安全政策之修改</h2>
            <p>
              金屬工業研究發展中心積極努力使網站資通安全更趨完善，本中心將視情況或事實需要隨時修訂相關條文。當本資通安全政策有任何之修改時，我們會刊登於本網站，並提醒使用者來詳細閱讀相關之事項。
            </p>
          </Box>
        </div>
      </div>
    </div>
  );
};
export default InformationSecurity;
