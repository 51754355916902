import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";

import apple from "../../assests/news_images/apple.jpg";
import china from "../../assests/news_images/china.jpg";
import cna from "../../assests/news_images/cna.jpg";
import CteeLogo from "../../assests/news_images/CteeLogo.jpg";
import ettoday from "../../assests/news_images/ettoday.jpg";
import free from "../../assests/news_images/free.png";
import udn from "../../assests/news_images/udn_logo.gif";
import yahoo from "../../assests/news_images/yahoo.png";
import white from "../../assests/news_images/white.jpg";
import pts from "../../assests/news_images/pts.png";
import ttv from "../../assests/news_images/ttv.jpg";

import "./styles.scss";

const NewsInfoTable = (props) => {
  const {
    data,
    headerList,
    selectedCity,
    newsInfoTotalLength,
    newsInfoRowNum,
    newsInfoPagingNum,
    getNewsListByNewPage,
  } = props;
  const [state, setState] = React.useState({
    pageCount: 0,
    page: 0,
    rowsPerPage: 5,
  });
  let content;


  // React.useEffect(()=>{
  //  //計算 page count
  //  if(newsInfoTotalLength > 5){
  //    if(newsInfoTotalLength % 5 === 0){
  //     setState(state=>({ ...state, pageCount: newsInfoTotalLength/5 }))
  //    }else{
  //     setState(state=>({ ...state, pageCount: parseInt(newsInfoTotalLength/5) + 1 }))
  //    }
  //  }
  // },[newsInfoTotalLength, newsInfoRowNum, newsInfoPagingNum])

  const handleChangePage = (event, newPage) => {
    // getNewsListByNewPage(newPage)
    setState((state) => ({ ...state, page: newPage }));
  };

  const handleNewsIcon = (logo) => {
    let imageSrc = white;
    let imageAlt = "";
    switch (logo) {
      case "apple.jpg":
        imageSrc = apple;
        imageAlt = "蘋果日報"
        break;
      case "china.jpg":
        imageSrc = china;
        imageAlt = "中時電子報"
        break;
      case "cna.jpg":
        imageSrc = cna;
        imageAlt = "中央通訊社"
        break;
      case "CteeLogo.jpg":
        imageSrc = CteeLogo;
        imageAlt = "工商時報"
        break;
      case "ettoday.jpg":
        imageSrc = ettoday;
        imageAlt = "東森新聞"
        break;
      case "free.png":
        imageSrc = free;
        imageAlt = "自由時報"
        break;
      case "udn_logo.gif":
        imageSrc = udn;
        imageAlt = "聯合新聞網"
        break;
      case "yahoo.png":
        imageSrc = yahoo;
        imageAlt = "Yahoo奇摩新聞"
        break;
      case "ttv.jpg":
        imageSrc = ttv;
        imageAlt = "台視新聞"
        break;
      case "pts.png":
        imageSrc = pts;
        imageAlt = "公視新聞"
        break;
      default:
        return (imageSrc = white);
    }

    return <img className="news-image" loading={"lazy"} src={imageSrc} alt={`${imageAlt} Logo`} />;
  };

  const openNewTabs = (link) => window.open(link, "_blank");

  const reOrganizeCompanyHandler = async (list) => {
    const updateArray = [];
    await list.map((company) => {
      console.log(company);
      updateArray.push(company.CompanyName);
    });
    return updateArray;
  };

  // if (data.length === 0) {
  //   content = <div className="no-data">本項目無資料</div>;
  // } else {
  //   content = data.map((row, index) => (
  //     <TableRow
  //       key={encodeURI(row.Title)}
  //       hover
  //       className="table-body-row"
  //       style={index % 2 !== 0 ? { backgroundColor: "#ececec" } : {}}
  //       onClick={()=>openNewTabs(row.Link)}
  //     >
  //       <TableCell align="left" className="table-body-header">
  //         {row.Title}
  //       </TableCell>
  //       <TableCell align="left" className="table-body-date">
  //         {row.PubDate.split(" ")[0]}
  //         {handleNewsIcon(row.Logo)}
  //       </TableCell>
  //       <TableCell align="left" className="table-body-company-name">
  //         {row.Companys.length === 0
  //           ? selectedCity
  //           : row.Companys[0].CompanyName}
  //       </TableCell>
  //     </TableRow>
  //   ));
  // }

  if (data?.length > 5) {
    content = data
      .slice(
        state.page * state.rowsPerPage,
        state.page * state.rowsPerPage + state.rowsPerPage
      )
      .map((row, index) => {
        const updateArray = [];
        if (row.Companys[0].CompanyName === "") {
          updateArray.push(row.Companys[0].InduParks[0].ParkName);
        } else {
          row.Companys.map((company) => {
            updateArray.push(company.CompanyName);
          });
        }

        return (
          <TableRow
            key={encodeURI(row.Title)}
            hover
            className="table-body-row"
            style={index % 2 !== 0 ? { backgroundColor: "#ececec" } : {}}
          >
            <TableCell align="left" className="table-body-header">
              <button onClick={() => openNewTabs(row.Link)} title={`另開新視窗，前往${row.Title}新聞連結`}>{row.Title}</button>
            </TableCell>
            <TableCell align="left" className="table-body-date">
              {row.PubDate.split(" ")[0]}
              {handleNewsIcon(row.Logo)}
            </TableCell>
            <TableCell align="left" className="table-body-company-name">
              {updateArray.map((company) => (
                <p>{company}</p>
              ))}
            </TableCell>
          </TableRow>
        );
      });
  } else {
    content = data?.map((row, index) => {
      const updateArray = [];

      if (row.Companys[0].CompanyName === "") {
        updateArray.push(row.Companys[0].InduParks[0].ParkName);
      } else {
        row.Companys.map((company) => {
          updateArray.push(company.CompanyName);
        });
      }

      return (
        <TableRow
          key={encodeURI(row.Title)}
          hover
          className="table-body-row"
          id="news"
          style={index % 2 === 0 ? { backgroundColor: "#ececec" } : {}}
        >
          <TableCell align="left" className="table-body-header">
            <button onClick={() => openNewTabs(row.Link)} title={`另開新視窗，前往${row.Title}新聞連結`}>{row.Title}</button>
          </TableCell>
          <TableCell align="left" className="table-body-date">
            {row.PubDate.split(" ")[0]}
            {handleNewsIcon(row.Logo)}
          </TableCell>
          <TableCell align="left" className="table-body-company-name">
            {updateArray.map((company) => (
              <p>{company}</p>
            ))}
          </TableCell>
        </TableRow>
      );
    });
  }

  return (
    <TableContainer className="table-container">
      <Table className="table">
        <TableHead className="table-header">
          {headerList && (
            <TableRow className="table-row">
              {headerList.map((headerItem) => (
                <TableCell className="table-cell" align="left">
                  {headerItem}
                </TableCell>
              ))}
            </TableRow>
          )}
        </TableHead>
        <TableBody className="news-table-body">
          {data?.length !== 0 && content}
        </TableBody>
      </Table>
      {data?.length > 5 && (
        <TablePagination
          className="news-table-pagination"
          rowsPerPageOptions={[]}
          component="div"
          count={data.length}
          rowsPerPage={state.rowsPerPage}
          page={state.page}
          onChangePage={handleChangePage}
          // onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      )}

      {data?.length === 0 && (
        <Table>
          <TableBody className="table-body-no-data">
            <TableRow hover className="table-body-row">
              <TableCell align="center" className="no-data">
                本項目無資料
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      )}
    </TableContainer>
  );
};

export default React.memo(NewsInfoTable);
