import * as actionTypes from "../actions/actionTypes";
import * as actionType from "../actionTypes/app";

const initState = {
  innerWidth: window.innerWidth,
  getViewCountLoading: false,
  viewCount: null,
  referenceInfo: {},
  referenceInfoLoading: false,
  getSearchHistoryLoading: false,
  searchHistory: [],
  verifiedCompanyListLoading: false,
  verifiedCompanyListData: [],
  err: "",
  version: 666, //這個不用改，api 會 update
  cachedVersion: 767,
  getVersionLoading: false,
};

export const startGetCompanyList = (state) => {
  return {
    ...state,
    verifiedCompanyListLoading: true,
  };
};

export const getCompanyListSuccess = (state, data) => {
  return {
    ...state,
    verifiedCompanyListData: data,
    verifiedCompanyListLoading: false,
  };
};

export const getCompanyListFail = (state, err) => {
  return {
    ...state,
    verifiedCompanyListLoading: false,
    err: err,
  };
};

const startFetchCurrentVersion = (state) => {
  return {
    ...state,
    getVersionLoading: true,
  };
};

const fetchCurrentVersionSuccess = (state, action) => {
  return {
    ...state,
    version: action.version,
    getVersionLoading: false,
  };
};

const fetchCurrentVersionFail = (state) => {
  return {
    ...state,
    getVersionLoading: false,
  };
};

export default (state = initState, action) => {
  switch (action.type) {
    case actionType.START_GET_REFERENCE_INFO:
      return {
        ...state,
        referenceInfoLoading: true,
      };
    case actionType.GET_REFERENCE_INFO_SUCCESS:
      return {
        ...state,
        referenceInfo: { ...action.data },
        referenceInfoLoading: false,
      };
    case actionType.GET_REFERENCE_INFO_FAIL:
      return {
        ...state,
        err: action.err,
        referenceInfoLoading: false,
      };
    case actionType.START_GET_SEARCH_HISTORY:
      return {
        ...state,
        getSearchHistoryLoading: true,
      };
    case actionType.GET_SEARCH_HISTORY_SUCCESS:
      return {
        ...state,
        searchHistory: action.data,
        getSearchHistoryLoading: false,
      };
    case actionType.GET_SEARCH_HISTORY_FAIL:
      return {
        ...state,
        err: action.err,
        getSearchHistoryLoading: false,
      };
    case actionTypes.INNER_WIDTH_CHANGING:
      return {
        ...state,
        innerWidth: action.innerWidth,
      };
    case actionTypes.START_GET_VIEW_COUNT:
      return {
        ...state,
        getViewCountLoading: true,
      };
    case actionTypes.GET_VIEW_COUNT_SUCCESS:
      return {
        ...state,
        getViewCountLoading: false,
        viewCount: action.viewCount,
      };
    case actionTypes.GET_VIEW_COUNT_FAIL:
      return {
        ...state,
        getViewCountLoading: false,
        viewCount: "網路連線問題。",
      };
    case actionType.START_GET_VERIFIED_COMPANY_LIST:
      return startGetCompanyList(state);
    case actionType.GET_VERIFIED_COMPANY_LIST_SUCCESS:
      return getCompanyListSuccess(state, action.data);
    case actionType.GET_VERIFIED_COMPANY_LIST_FAIL:
      return getCompanyListFail(state, action.err);
    case actionType.START_FETCH_CURRENT_VERSION:
      return startFetchCurrentVersion(state);
    case actionType.FETCH_CURRENT_VERSION_SUCCESS:
      return fetchCurrentVersionSuccess(state, action);
    case actionType.FETCH_CURRENT_VERSION_FAIL:
      return fetchCurrentVersionFail(state);
    default:
      return state;
  }
};
