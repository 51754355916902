import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import moneyIcon from "../../assests/images/about-money.png";
import buildingIcon from "../../assests/images/about-building.png";
import BarChart from "./BarChart";
import PieChart from "./PieChart";
import { Box, Grid } from "@mui/material";
import Loading from "./Loading";

const CityBoard = ({ dashboardData, taiwanInvestment, startUp, topTenCapital, topTenGovPlan, typeTwoChartDataMap, publicStock }) => {
  return (
    <>
      <div class="d-flex about-dashboard-content">
        <div>
          <div class="card">
            <div class="card-header">
              <h3>廠商資本額前十大排行</h3>
            </div>
            <div className="card-body">
              <BarChart
                chartData={topTenCapital.topTenCapitalDataMap.map((item) => ({
                  label: item.label?.replace("股份有限公司", ""),
                  y: item.y,
                }))}
                keyTarget={["y"]} indexBy="label"
                Unit="元"
              />
            </div>
          </div>
          <div class="card">
            <div class="card-header">
              <h3>廠商產業別二碼家數佔比</h3>
            </div>
            <div className="card-body">
              <div>
                {typeTwoChartDataMap?.length > 0? <PieChart data={typeTwoChartDataMap} /> : 
                <Box display="flex" justifyContent={"center"}>
                  <NewsCompanyName>無相關資料</NewsCompanyName>
                </Box>
              }
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="card">
            <div class="card-header">
              <h3>在臺投資</h3>
            </div>
            <div
              className="card-body"
            >
              {
                taiwanInvestment.taiwanInvestmentLoading ? <Loading /> :
                taiwanInvestment?.taiwanInvestmentData?.length === 0 ?
                    <Box display="flex" justifyContent={"center"}>
                      <NewsCompanyName>無相關資料</NewsCompanyName>
                    </Box>
                    :
                    <ListWrapper >
                      <CompanyBox display="flex" justifyContent={"space-between"}>
                        <Box className="text" style={{width:"40%"}}>
                          公司名稱
                        </Box>
                        <Box className="taiwanText" style={{width:"20%"}}>
                          投資金額
                        </Box>
                        <Box className="number" style={{width:"40%"}}>
                          參考新聞來源時間
                        </Box>
                      </CompanyBox>
                      {taiwanInvestment?.taiwanInvestmentData?.slice(0, 5)?.map((row) => (
                        <CompanyBox display="flex" justifyContent={"space-between"}>
                          <Box className="text" style={{width:"40%"}}>
                            <CompanyLink title={`另開新視窗，前往${row.CompanyName}的個廠情資`} to={`/factory_info/basic_info/${row.BusinessNo}`} target="_blank">
                              {row.CompanyName}
                            </CompanyLink>
                          </Box>
                          <Box className="taiwanText" style={{width:"20%"}}>
                            <p>
                              {row.InvestAmount?row.InvestAmount + "億":"未公開"}
                            </p>
                          </Box>
                          <Box className="number" style={{width:"40%"}}>
                            <p>
                              {row.NewsDate.replace("T00:00:00", "").replaceAll("-", "/")}
                            </p>
                          </Box>
                        </CompanyBox>
                      ))}
                    </ListWrapper>
              }
            </div>
          </div>
          <div class="card">
            <div class="card-header">
              <h3>廠商政府計畫數前十大排行</h3>
            </div>
            <div className="card-body">
              <BarChart
                chartData={topTenGovPlan.topTenGovPlanDataMap.map((item) => ({
                  label: item.label?.replace("股份有限公司", ""),
                  y: item.y,
                }))}
                keyTarget={["y"]} indexBy="label"
                Unit="件"
              />
            </div>
          </div>
        </div>
        <div class="card long">
          <div class="card-header">
            <h3>新成立公司</h3>
          </div>
          <div
            className="card-body"
            style={{
              margin: "16px 0px",
            }}
          >
            <ListWrapper>
              <CompanyBox display="flex" justifyContent={"space-between"}>
                <Box className="text">
                  公司名稱
                </Box>
                <Box className="number">
                  設立時間
                </Box>
              </CompanyBox>
              {startUp?.startUpData?.Companydata?.slice(0, 12)?.map((row) => (
                <CompanyBox display="flex" justifyContent={"space-between"}>
                  <Box className="text">
                    <CompanyLink title={`另開新視窗，前往${row.CompanyName}的個廠情資`} to={`/factory_info/basic_info/${row.BusinessNo}`} target="_blank">
                      {row.CompanyName}
                    </CompanyLink>
                  </Box>
                  <Box className="number">
                    <p>
                      {row.SetupDate}
                    </p>
                  </Box>
                </CompanyBox>
              ))}
            </ListWrapper>
            <hr
              style={{
                margin: "14px 0px",
              }}
            />
            <CompanyLink
              style={{
                fontSize: "14px",
              }}
              target="_blank"
              title={`另開新視窗，前往縣市儀表板查看更多`}
              to={`/city_dashboard/${dashboardData.code}`}
            >
              查看更多
            </CompanyLink>
          </div>
        </div>
      </div>
    </>
  );
};

const CompanyLink = styled(Link)`
  display: flex;
  color: #3E5A75;
  text-decoration: underline;
  &:hover {
    color: #3E5A75;
    opacity: 0.8;
    text-decoration: underline;
  }
`;
const CompanyBox = styled(Box)`
  margin-bottom: 4px;
  .text {
    text-align: left;
    span {
      font-size: 0.75rem;
      color: rgba(0, 0, 0, 0.6);
    }
  }
  .taiwanText {
    text-align: right;
    span {
      font-size: 0.75rem;
      color: rgba(0, 0, 0, 0.6);
    }
  }
  .number {
    text-align: right;
    span {
      font-size: 0.75rem;
      color: rgba(0, 0, 0, 0.6);
    }
    p {
      fontSize: 16px;
      color: #25758A;
    }
  }
`;
const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  @media (max-width: 768px) {
    margin-bottom: 0;
  }
`;
const taiwanInvestmentCompany = styled.p`
  background: #E6EDF6;
  padding: 4px 8px;
  display: inline-block;
  border-radius: 24px;
  font-size: 0.875rem;
  color: #033a63;
  margin-bottom: 8px;
`
const NewsCompanyName = styled.p`
  background: #E6EDF6;
  padding: 4px 8px;
  display: inline-block;
  border-radius: 24px;
  font-size: 0.875rem;
  color: #033a63;
  margin-bottom: 8px;
`
export default CityBoard;