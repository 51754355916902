import React from "react";
import { Popup, Marker } from "react-leaflet";

export default (props) => {
  const markerRef = React.useRef(null);
  const popUpRef = React.useRef(null);

  const { Latitude, Longitude, Uid, data } = props;
  const [state, setState] = React.useState({
    selectedUid: "",
  });
  const url = window.location.origin;
  const goToFactorySearch = () => {
    if (data.BusinessNo) {
      window.open(`${url}/factory_info/basic_info/${data.BusinessNo}`);
    } else {
      return;
    }
  }; 


  return (
    <Marker position={[Latitude, Longitude]} key={Uid}>
      <Popup isOpen={Uid === state.selectedUid} autoPan={false}>
      <div className="material-search-popup">
          <h4>
            公司名稱:
            <span
              onClick={goToFactorySearch}
              className="with-business-no"
            >
              {" " + data.CompanyName}
            </span>
          </h4>
          <p>
            <span>公司統編:</span> {" " + data.BusinessNo}
          </p>
          <p>
            <span>工廠名稱:</span> {" " + data.FactoryName}
          </p>
          <p>
            <span>工廠地址:</span> {" " + data.FactoryAddress}
          </p>
          <p>
            <span>資本額:</span> {" " + data.CapitalTotal.toString().replace(/\B(?=(\d{3})+(?!\d))/g ,",")} 
          </p>
          <p>
            <span>主要產品:</span> {" " + data.MainProduct}
          </p>
          <p>
            <span>產業別四碼:</span> {" " + data.InduFourStr}
          </p>
          <p>
            <span>負責人:</span> {" " + data.Principal}
          </p>
        </div>
      </Popup>
    </Marker>
  );
};
