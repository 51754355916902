const base = `FACTORY_`;

export const START_GET_BASIC_INFO = `${base}START_GET_BASIC_INFO`;
export const GET_BASIC_INFO_SUCCESS = `${base}GET_BASIC_INFO_SUCCESS`;
export const GET_BASIC_INFO_FAIL = `${base}GET_BASIC_INFO_FAIL`;

export const START_GET_UPDATE_COMPANY_INFO = `${base}START_GET_UPDATE_COMPANY_INFO`;
export const GET_UPDATE_COMPANY_INFO_SUCCESS = `${base}GET_UPDATE_COMPANY_INFO_SUCCESS`;
export const GET_UPDATE_COMPANY_INFO_FAIL = `${base}GET_UPDATE_COMPANY_INFO_FAIL`;

// 產業競爭力發展中心飛雷神
export const START_GET_ENTERPRISE_QUALIFIED = `${base}START_GET_ENTERPRISE_QUALIFIED`;
export const GET_ENTERPRISE_QUALIFIED_SUCCESS = `${base}GET_ENTERPRISE_QUALIFIED_SUCCESS`;
export const GET_ENTERPRISE_QUALIFIED_FAIL = `${base}GET_ENTERPRISE_QUALIFIED_FAIL`;

export const START_GET_COMPANY_ABSTRACT = `${base}START_GET_COMPANY_ABSTRACT`;
export const GET_COMPANY_ABSTRACT_SUCCESS = `${base}GET_COMPANY_ABSTRACT_SUCCESS`;
export const GET_COMPANY_ABSTRACT_FAIL = `${base}GET_COMPANY_ABSTRACT_FAIL`;

export const START_VERIFIED_CODE = `${base}START_VERIFIED_CODE`;
export const GET_VERIFIED_CODE_SUCCESS = `${base}GET_VERIFIED_CODE_SUCCESS`;
export const GET_VERIFIED_CODE_FAIL = `${base}GET_VERIFIED_CODE_FAIL`;

export const START_UPDATE_COMPANY_INFO = `${base}START_UPDATE_COMPANY_INFO`;
export const UPDATE_COMPANY_INFO_SUCCESS = `${base}UPDATE_COMPANY_INFO_SUCCESS`;
export const UPDATE_COMPANY_INFO_FAIL = `${base}UPDATE_COMPANY_INFO_FAIL`;

export const START_INIT_UPDATE_COMPANY_INFO_STATE = `${base}START_INIT_UPDATE_COMPANY_INFO_STATE`;
export const INIT_UPDATE_COMPANY_INFO_STATE_SUCCESS = `${base}INIT_UPDATE_COMPANY_INFO_STATE_SUCCESS`;
export const INIT_UPDATE_COMPANY_INFO_STATE_FAIL = `${base}INIT_UPDATE_COMPANY_INFO_STATE_FAIL`;

export const START_GET_FOREIGN_TRADES_COMPARE = `${base}START_GET_FOREIGN_TRADES_COMPARE`;
export const GET_FOREIGN_TRADES_COMPARE_SUCCESS = `${base}GET_FOREIGN_TRADES_COMPARE_SUCCESS`;
export const GET_FOREIGN_TRADES_COMPARE_FAIL = `${base}GET_FOREIGN_TRADES_COMPARE_FAIL`;

export const START_GET_GOV_PRO_BIDDING = `${base}START_GET_GOV_PRO_BIDDING`;
export const GET_GOV_PRO_BIDDING_SUCCESS = `${base}GET_GOV_PRO_BIDDING_SUCCESS`;
export const GET_GOV_PRO_BIDDING_FAIL = `${base}GET_GOV_PRO_BIDDING_FAIL`;

// 智財權相關判決書
export const START_GET_VERDICT = `${base}START_GET_VERDICT`;
export const GET_VERDICT_SUCCESS = `${base}GET_VERDICT_SUCCESS`;
export const GET_VERDICT_FAIL = `${base}GET_VERDICT_FAIL`;

// 商標
export const START_GET_TRADEMARK = `${base}START_GET_TRADEMARK`;
export const GET_TRADEMARK_SUCCESS = `${base}GET_TRADEMARK_SUCCESS`;
export const GET_TRADEMARK_FAIL = `${base}GET_TRADEMARK_FAIL`;

export const START_GET_BUSINESS_INFO = `${base}START_GET_BUSINESS_INFO`;
export const GET_BUSINESS_INFO_SUCCESS = `${base}GET_BUSINESS_INFO_SUCCESS`;
export const GET_BUSINESS_INFO_FAIL = `${base}GET_BUSINESS_INFO_FAIL`;

export const START_GET_COMPANY_UPLOAD_VERIFYING = `${base}START_GET_COMPANY_UPLOAD_VERIFYING`;
export const GET_COMPANY_UPLOAD_VERIFYING_SUCCESS = `${base}GET_COMPANY_UPLOAD_VERIFYING_SUCCESS`;
export const GET_COMPANY_UPLOAD_VERIFYING_FAIL = `${base}GET_COMPANY_UPLOAD_VERIFYING_FAIL`;

export const START_GET_AWARD_INFO = `${base}START_GET_AWARD_INFO`;
export const GET_AWARD_INFO_SUCCESS = `${base}GET_AWARD_INFO_SUCCESS`;
export const GET_AWARD_INFO_FAIL = `${base}GET_AWARD_INFO_FAIL`;

// MIT標章
export const START_GET_MIT_INFO = `${base}START_GET_MIT_INFO`;
export const GET_MIT_INFO_SUCCESS = `${base}GET_MIT_INFO_SUCCESS`;
export const GET_MIT_INFO_FAIL = `${base}GET_MIT_INFO_FAIL`;

export const START_GET_BRANCH_INFO = `${base}START_GET_BRANCH_INFO`;
export const GET_BRANCH_INFO_SUCCESS = `${base}GET_BRANCH_INFO_SUCCESS`;
export const GET_BRANCH_INFO_FAIL = `${base}GET_BRANCH_INFO_FAIL`;

export const START_GET_NEWS_TAG_INFO = `${base}START_GET_NEWS_TAG_INFO`;
export const GET_NEWS_TAG_INFO_SUCCESS = `${base}GET_NEWS_TAG_INFO_SUCCESS`;
export const GET_NEWS_TAG_INFO_FAIL = `${base}GET_NEWS_TAG_INFO_FAIL`;

export const START_GET_NEWS_CONTENT_INFO = `${base}START_GET_NEWS_CONTENT_INFO`;
export const GET_NEWS_CONTENT_INFO_SUCCESS = `${base}GET_NEWS_CONTENT_INFO_SUCCESS`;
export const GET_NEWS_CONTENT_INFO_FAIL = `${base}GET_NEWS_CONTENT_INFO_FAIL`;

export const START_GET_TRADE_INFO = `${base}START_GET_TRADE_INFO`;
export const GET_TRADE_INFO_SUCCESS = `${base}GET_TRADE_INFO_SUCCESS`;
export const GET_TRADE_INFO_FAIL = `${base}GET_TRADE_INFO_FAIL`;

export const START_GET_PATENT_INFO = `${base}START_GET_PATENT_INFO`;
export const GET_PATENT_INFO_SUCCESS = `${base}GET_PATENT_INFO_SUCCESS`;
export const GET_PATENT_INFO_FAIL = `${base}GET_PATENT_INFO_FAIL`;

export const START_GET_GOV_PLAN_INFO = `${base}START_GET_GOV_PLAN_INFO`;
export const GET_GOV_PLAN_INFO_SUCCESS = `${base}GET_GOV_PLAN_INFO_SUCCESS`;
export const GET_GOV_PLAN_INFO_FAIL = `${base}GET_GOV_PLAN_INFO_FAIL`;

export const START_GET_COMPANY_STOCK_INFO = `${base}START_GET_COMPANY_STOCK_INFO`;
export const GET_COMPANY_STOCK_INFO_SUCCESS = `${base}GET_COMPANY_STOCK_INFO_SUCCESS`;
export const GET_COMPANY_STOCK_INFO_FAIL = `${base}GET_COMPANY_STOCK_INFO_FAIL`;

export const START_GET_STOCK_INDEX_INFO = `${base}START_GET_STOCK_INDEX_INFO`;
export const GET_STOCK_INDEX_INFO_SUCCESS = `${base}GET_STOCK_INDEX_INFO_SUCCESS`;
export const GET_STOCK_INDEX_INFO_FAIL = `${base}GET_STOCK_INDEX_INFO_FAIL`;

export const START_GET_COMPANY_STOCK_PHYSIQUES = `${base}START_GET_COMPANY_STOCK_PHYSIQUES`;
export const GET_COMPANY_STOCK_PHYSIQUES_SUCCESS = `${base}GET_COMPANY_STOCK_PHYSIQUES_SUCCESS`;
export const GET_COMPANY_STOCK_PHYSIQUES_FAIL = `${base}GET_COMPANY_STOCK_PHYSIQUES_FAIL`;

export const START_GET_STOCK_INFO = `${base}START_GET_STOCK_INFO`;
export const GET_STOCK_INFO_SUCCESS = `${base}GET_STOCK_INFO_SUCCESS`;
export const GET_STOCK_INFO_FAIL = `${base}GET_STOCK_INFO_FAIL`;

export const START_GET_TRADE_RESEARCH = `${base}START_GET_TRADE_RESEARCH`;
export const GET_TRADE_RESEARCH_SUCCESS = `${base}GET_TRADE_RESEARCH_SUCCESS`;
export const GET_TRADE_RESEARCH_FAIL = `${base}GET_TRADE_RESEARCH_FAIL`;

export const START_GET_TRADE_EPS = `${base}START_GET_TRADE_EPS`;
export const GET_TRADE_EPS_SUCCESS = `${base}GET_TRADE_EPS_SUCCESS`;
export const GET_TRADE_EPS_FAIL = `${base}GET_TRADE_EPS_FAIL`;

export const START_GET_TRADE_REVENUE = `${base}START_GET_TRADE_REVENUE`;
export const GET_TRADE_REVENUE_SUCCESS = `${base}GET_TRADE_REVENUE_SUCCESS`;
export const GET_TRADE_REVENUE_FAIL = `${base}GET_TRADE_REVENUE_FAIL`;

export const START_GET_SERVICE_HISTORY = `${base}START_GET_SERVICE_HISTORY`;
export const GET_SERVICE_HISTORY_SUCCESS = `${base}GET_SERVICE_HISTORY_SUCCESS`;
export const GET_SERVICE_HISTORY_FAIL = `${base}GET_SERVICE_HISTORY_FAIL`;

export const START_GET_BOARD_MEMBER = `${base}START_GET_BOARD_MEMBER`;
export const GET_BOARD_MEMBER_SUCCESS = `${base}GET_BOARD_MEMBER_SUCCESS`;
export const GET_BOARD_MEMBER_FAIL = `${base}GET_BOARD_MEMBER_FAIL`;

export const START_GET_TAX_COMPANY_INFO= `${base}START_GET_TAX_COMPANY_INFO`;
export const GET_TAX_COMPANY_INFO_SUCCESS = `${base}GET_TAX_COMPANY_INFO_SUCCESS`;
export const GET_TAX_COMPANY_INFO_FAIL = `${base}GET_TAX_COMPANY_INFO_FAIL`;

export const START_GET_COMPANY_INVEST_BACK_TW= `${base}START_GET_COMPANY_INVEST_BACK_TW`;
export const GET_COMPANY_INVEST_BACK_TW_SUCCESS = `${base}GET_COMPANY_INVEST_BACK_TW_SUCCESS`;
export const GET_COMPANY_INVEST_BACK_TW_FAIL = `${base}GET_COMPANY_INVEST_BACK_TW_FAIL`;

export const START_GET_FOREIGN_TRADE_4CODE_PRODUCT= `${base}START_GET_FOREIGN_TRADE_4CODE_PRODUCT`;
export const GET_FOREIGN_TRADE_4CODE_PRODUCT_SUCCESS = `${base}GET_FOREIGN_TRADE_4CODE_PRODUCT_SUCCESS`;
export const GET_FOREIGN_TRADE_4CODE_PRODUCT_FAIL = `${base}GET_FOREIGN_TRADE_4CODE_PRODUCT_FAIL`;

export const START_GET_REGISTERED_OPERATION_ITEM= `${base}START_GET_REGISTERED_OPERATION_ITEM`;
export const GET_REGISTERED_OPERATION_ITEM_SUCCESS = `${base}GET_REGISTERED_OPERATION_ITEM_SUCCESS`;
export const GET_REGISTERED_OPERATION_ITEM_FAIL = `${base}GET_REGISTERED_OPERATION_ITEM_FAIL`;

export const START_GET_MANAGER_INFO= `${base}START_GET_MANAGER_INFO`;
export const GET_MANAGER_INFO_SUCCESS = `${base}GET_MANAGER_INFO_SUCCESS`;
export const GET_MANAGER_INFO_FAIL = `${base}GET_MANAGER_INFO_FAIL`;

//工業局補捐助資料
export const START_GET_GOV_PLAN_INFO_SUBSIDY = `${base}START_GET_GOV_PLAN_INFO_SUBSIDY`;
export const GET_GOV_PLAN_INFO_SUBSIDY_SUCCESS = `${base}GET_GOV_PLAN_INFO_SUBSIDY_SUCCESS`;
export const GET_GOV_PLAN_INFO_SUBSIDY_FAIL = `${base}GET_GOV_PLAN_INFO_SUBSIDY_FAIL`;

//裁罰資料
export const START_GET_NEGATIVE = `${base}START_GET_NEGATIVE`;
export const GET_NEGATIVE_SUCCESS = `${base}GET_NEGATIVE_SUCCESS`;
export const GET_NEGATIVE_FAIL = `${base}GET_NEGATIVE_FAIL`;