import React, { useState, useEffect } from "react";
import {
  Icon,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Input,
  TextField,
  Box,
  IconButton
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import AddIcon from "@mui/icons-material/Add";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';
import styled from "styled-components";
import Checkbox from "@mui/material/Checkbox";
import Tooltip from "@mui/material/Tooltip";
import FormControlLabel from "@mui/material/FormControlLabel";

import Spinner from "../../components/Spinner/Spinner";
import InBoxTab from "../../components/InBoxTab/InBoxTab";
import { typeTwo, typeFour } from "../../constants/types";
import { EducationDetailHandler } from "../../utility/api";
import IndustrySeperationBox from "./industrySeperationBox";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { applyFileParameter } from "../../constants/applyFileParameter";

import "./styles.scss";
import { useSelector } from "react-redux";

const MapListContainer = (props) => {
  const {
    left,
    top,
    data,
    listType,
    loading,
    setSelectedLocationHandler,
    lengthOfData,
    width,
    closeDetail,
    toggleFilterDetail,
    isDetailOpen,
    hiddenBackground,
    researchNo,
    status,
    closeDialog,
    updateCustomDashboardData,
    handleUpdateCustomDashboardData,
    resetCustomDashboardData,
    is_check_all_custom_dashboard,
    handleToggleCheckAllCompanyForCustomDashboard,
    keyword,
    openApplyFile,
    applyFileInfo,
  } = props;

  const MySwal = withReactContent(Swal);
  const [sweetAlertMessage, setSweetAlertMessage] = React.useState("");
  const [nextMonth, setNextMonth] = React.useState("");
  React.useEffect(() => {
    const today = new Date();
    today.setDate(1);
    today.setMonth(today.getMonth() + 1);
    const usegeStr = applyFileInfo.Usege_Monthly ? `(已匯出${applyFileInfo.Usege_Monthly}次)` : ''
    setNextMonth(new Date(today));
    setSweetAlertMessage(
      props.dataCount > 500
        ? `僅匯出搜尋結果前500筆(按資本額排行)，是否要繼續？<br/>備註：1個月內僅限匯出2次EXCEL` + usegeStr
        : '1個月內僅限匯出2次EXCEL' + usegeStr
    );
  }, [applyFileInfo]);

  const { innerWidth } = useSelector((state) => state.app);
  const [state, setState] = useState({
    dataSortByIndu: [],
    dataSortByPark: [],
    dataSortByCity: [],
  });
  const [dataList, setDataList] = useState(
    data && (listType === "map-search-result" || listType === "map-search-result-length")
      ? data.sort((a, b) => b.content.capital - a.content.capital)
      : null
  );
  const [selectedDataList, setSelectedDataList] = useState(
    data && (listType === "map-search-result" || listType === "map-search-result-length")
      ? data.sort((a, b) => b.content.capital - a.content.capital)
      : null
  );
  const [productDetailType, setProductDetailType] = useState("capitalRanking");
  const containerWidth = innerWidth === 1024 ? "30vw" : width ? width : "25vw";
  const backgroundColor = hiddenBackground ? "rgba(0,0,0,0)" : "#FFFFFF";
  const [talentLoading, setTalentLoading] = useState(true);
  const [basic, setBasic] = useState();
  const [papers, setPapers] = useState();
  const [projects, setProjects] = useState();
  const [localStatus, setLocalStatus] = useState(status);
  const [selectedSchool, setSelectedSchool] = useState();
  const url = window.location.origin;


  const handleClickAll = (e) => {
    e.stopPropagation();
    if (localStorage.getItem("isLogin") !== "true") {
      alert("請登入平台新增你的自訂關注廠商儀表板");
    } else {
      handleToggleCheckAllCompanyForCustomDashboard();
    }
  };

  // React.useEffect(()=>{
  //   if(data && listType === "map-search-result"){
  //     setState(state=>({ ...state,  dataSortByIndu: data.sort((a, b) => b.content.capital - a.content.capital), dataSortByPark: data.sort((a, b) => b.content.capital - a.content.capital)}))
  //   }
  // },[data, listType])

  useEffect(() => {
    if (selectedSchool) {
      const updateDataList = dataList.filter(
        (data) => data.content.schoolName === selectedSchool
      );
      setSelectedDataList(updateDataList);
    } else {
      setSelectedDataList(dataList);
    }
  }, [selectedSchool]);

  useEffect(() => {
    setLocalStatus(status);
  }, [status]);

  //算出現的次數
  const getOccurrence = (array, value) => {
    return array.filter((v) => v === value).length;
  };

  useEffect(() => {
    if (researchNo) {
      setTalentLoading(true);
      EducationDetailHandler(researchNo)
        .then((res) => {
          setBasic(res.data.data.Basic);
          setPapers(res.data.data.Papers);
          setProjects(res.data.data.Projects);
          setTalentLoading(false);
        })
        .catch((err) => {
          console.log("取得學界詳細資料發生錯誤");
          setTalentLoading(false);
        });
    }
  }, [researchNo]);

  React.useEffect(() => {
    if ((listType === "map-search-result" || listType === "map-search-result-length") && localStatus !== "talentSearch") {
      if (data) {
        const capitalRankingData = data.sort(
          (a, b) => b.content.capital - a.content.capital
        );

        setDataList(capitalRankingData);

        const industryKey = [];
        const cityKey = [];
        data.map((item) => {
          industryKey.push(item.content.induFour[0]);
          cityKey.push(item.content.city);
        });

        const uniqCity = [...new Set(cityKey)];
        const cityLength = {};
        const cityList = [];

        uniqCity.map((city) => (cityLength[city] = []));

        data.map((content) => {
          Object.keys(cityLength).map((city) => {
            if (content.content.city === city) {
              cityLength[city].push({
                name: content.content.name,
                businessNo: content.content.businessNo,
              });
            }
          });
        });

        Object.entries(cityLength).map((item) => {
          cityList.push({
            name: item[0],
            count: item[1].length,
            companies: item[1],
          });
        });

        const uniqueKey = [...new Set(industryKey)];

        const parkNameAndKey = {};

        uniqueKey.map((name) => {
          const list = [];
          data.map((company) => {
            if (company.content.induFourStr === name) {
              list.push({
                name: company.content.name,
                businessNo: company.content.businessNo,
              });
            }
          });

          parkNameAndKey[name] = list;
        });

        const otherList = [];

        data.map((company) => {
          if (company.content.induPark === "") {
            otherList.push({
              name: company.content.name,
              businessNo: company.content.businessNo,
            });
          }
        });

        parkNameAndKey["區外"] = otherList;

        const industryRankingData = [];

        uniqueKey.map((item) => {
          industryRankingData.push({
            name: item,
            count: getOccurrence(industryKey, item),
            companies: parkNameAndKey[item],
          });
        });

        const parkKey = [];
        data.map((item) => {
          parkKey.push(item.content.induPark ? item.content.induPark : "區外");
        });

        const uniqueParkKey = [...new Set(parkKey)];

        const parkRankingList = [];

        const parkNameAndKeys = {};

        uniqueParkKey.map((name) => {
          const list = [];
          data.map((company) => {
            if (company.content.induPark === name) {
              list.push({
                name: company.content.name,
                businessNo: company.content.businessNo,
              });
            }
          });

          parkNameAndKeys[name] = list;
        });

        const otherLists = [];

        data.map((company) => {
          if (company.content.induPark === "") {
            otherLists.push({
              name: company.content.name,
              businessNo: company.content.businessNo,
            });
          }
        });

        parkNameAndKeys["區外"] = otherLists;

        uniqueParkKey.map((item) => {
          parkRankingList.push({
            name: item,
            count: getOccurrence(parkKey, item),
            companies: parkNameAndKeys[item],
          });
        });

        // setDataList(industryRankingData.sort((a, b) => b.count - a.count));
        setState((state) => ({
          ...state,
          dataSortByIndu: industryRankingData.sort((a, b) => b.count - a.count),
          dataSortByPark: parkRankingList.sort((a, b) => b.count - a.count),
          dataSortByCity: cityList.sort((a, b) => b.count - a.count),
        }));
      }
    }
  }, [data]);

  const tabData = [
    {
      item: "按資本額排行",
      key: "capitalRanking",
      isActive: true,
    },
    {
      item: "產業別家數排行",
      key: "industryRanking",
      isActive: false,
    },
    {
      item: "園區別家數排行",
      key: "parkRanking",
      isActive: false,
    },
    {
      item: "縣市別家數排行",
      key: "cityRanking",
      isActive: false,
    },
  ];

  const openApplyFileHandler = () => {
    if (applyFileInfo.Usege_Monthly < applyFileInfo.Usege_Limit) {
      MySwal.fire({
        title: "提示",
        showCancelButton: true,
        html: sweetAlertMessage,
        confirmButtonText: '確認',
        cancelButtonText: '取消'
      }).then((res) => {
        if (res.isConfirmed) {
          openApplyFile();
        }
      });
    }
    else {
      MySwal.fire({
        title: "提示",
        html: `超出本月使用量<br>下次可匯出EXCEL日期：${nextMonth.toISOString().split("T")[0] + "  00:00"}<br/>如有任何需求或問題，請聯絡${applyFileParameter.nickNmae}  ${applyFileParameter.phone}`,
        confirmButtonText: '確認',
      })
    }
  };

  const selectedSchoolHandler = (e) => {
    const value = e.target.value;
    setSelectedSchool(value);
  };

  const handleChange = (event) => {
    const { value } = event.target;
    setProductDetailType(value);
    resetCustomDashboardData();
  };

  let content;

  if (loading) {
    content = <Spinner />;
  } else if (!loading && data) {
    if (listType === "map-search-result") {
      if (isDetailOpen) {
        if (localStatus === "productSearch") {
          if (productDetailType === "capitalRanking") {
            content = (
              <div className="content">
                <div className="content-header">
                  <div style={{ position: "absolute", right: "0px" }}>
                    <IconButton aria-label="關閉廠商詳細資料列表" style={{
                      padding: "0",
                    }} onClick={toggleFilterDetail}>
                      <Icon className="close" >
                        close
                      </Icon>
                    </IconButton>
                  </div>
                  <div style={{ paddingTop: "10px" }}>
                    <Button startIcon={<FontAwesomeIcon className="export-excel-icon" icon={faFileExcel} />} onClick={openApplyFileHandler}>匯出 Excel 報表</Button>
                    {/* 先關閉手機自定義關注儀表板 */}
                    {innerWidth > 1023 && localStorage.getItem("uid") && (
                      <Button
                        onClick={props.handleOpenCustomDashboardModalOpen}
                        className="export-pdf-btn"
                        startIcon={<AddIcon />}
                      >
                        自訂關注廠商儀表板
                      </Button>
                    )}
                  </div>
                  {/* <InBoxTab
                    data={tabData}
                    dataOrderHandler={dataOrderHandler}
                    top={innerWidth < 1024 ? "0" : "4vh"}
                  /> */}
                  <TextField
                    className="material-input-select"
                    variant="outlined"
                    size="small"
                    onChange={handleChange}
                    value={productDetailType}
                    select
                  >
                    {tabData.map((tab) => (
                      <MenuItem value={tab.key}>{tab.item}</MenuItem>
                    ))}
                  </TextField>
                </div>
                {/* 先關閉手機自定義關注儀表板 */}
                {
                  innerWidth > 1023 && <CheckAllContainer>
                    <FormControlLabel
                      control={
                        <Checkbox
                          sx={{
                            "&.Mui-checked": {
                              color: "#25758A",
                            },
                            padding: "2px 0px 2px 0px",
                            margin: "0px 0px 0px 10px"
                          }}
                          checked={is_check_all_custom_dashboard}
                          onClick={handleClickAll}
                        />
                      }
                      label="勾選全部"
                    />
                  </CheckAllContainer>
                }

                <div className="content-content">
                  {data
                    ? dataList.sort((a, b) => b?.content?.userUpload - a?.content?.userUpload).map((data) => (
                      <button
                        // className="content-item"
                        className={data.content.userUpload ? "content-item first" : "content-item"}
                        onClick={() =>
                          setSelectedLocationHandler([
                            data.position,
                            data.content.uid,
                          ])
                        }
                      >
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                          {
                            data.content.userUpload && <div class="content-user-upload">平台推薦</div>
                          }
                          <div className="content-item-icons">
                            {/* 先關閉手機自定義關注儀表板 */}

                            {
                              innerWidth > 1023 &&
                              <CheckBoxContainer
                                updateCustomDashboardData={
                                  updateCustomDashboardData
                                }
                                handleUpdateCustomDashboardData={
                                  handleUpdateCustomDashboardData
                                }
                                business_id={data.content.businessNo}
                                is_company_selected={
                                  updateCustomDashboardData.indexOf(
                                    data.content.businessNo
                                  ) !== -1
                                }
                              />
                            }
                            <Tooltip title="另開新視窗，查看公司詳細資訊">
                              <>
                                <IconButton title="另開新視窗，查看公司詳細資訊" size="small" onClick={() => {
                                  window.open(
                                    url +
                                    '/factory_info/basic_info/' +
                                    data.content.businessNo,
                                    '_blank'
                                  );
                                }}>
                                  <Icon style={{ color: "white" }}>
                                    search
                                  </Icon>
                                </IconButton>
                              </>
                            </Tooltip>
                            <Tooltip title="另開新視窗，前往 Google Maps">
                              <>
                                <IconButton title="另開新視窗，前往 Google Maps" size="small"
                                  onClick={() => {
                                    window.open(
                                      `https://maps.google.com/?q=${data.position[0]},${data.position[1]}`,
                                      '_blank'
                                    );
                                  }}>
                                  <Icon style={{ color: "white" }}>
                                    explore
                                  </Icon>
                                </IconButton>
                              </>
                            </Tooltip>
                            {/* <Icon onClick={() => { window.open(`https://www.google.com/search?q=${data.content.name.substr(0, 4)}`, "_blank") }}  >search</Icon> */}
                            <Tooltip title="另開新視窗，查看產業資訊">
                              <>
                                <IconButton title="另開新視窗，查看產業資訊" size="small"
                                  onClick={() => {
                                    window.open(
                                      url +
                                      '/industry_index/' +
                                      data.content.induFourStr.substr(0, 4),
                                      '_blank'
                                    );
                                  }}>
                                  <Icon style={{ color: "white" }}>
                                    home_work
                                  </Icon>
                                </IconButton>
                              </>
                            </Tooltip>
                          </div>
                        </div>
                        <p className="content-item-title">
                          {data.content.name}
                        </p>
                        <p className="content-item-address">
                          {"地址: " + data.content.address}
                        </p>
                        <p className="content-item-type-four">
                          {"產業別: " + data.content.induFour}
                        </p>
                        <p className="content-item-product">
                          {"產品: " + data.content.product}
                        </p>
                        <p className="content-item-capital">
                          {"登記資本額(元): " +
                            data.content.capital
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </p>
                        <p className="content-item-indupark">
                          {data.content.induPark
                            ? ("產業園區: " + data.content.induPark)
                            : ("產業園區: 區外")
                          }
                        </p>
                        <p className="content-item-stock-type">
                          {"股票狀態: " + data.content.stockType}
                        </p>
                        {data.content.Tel && (
                          <p className="content-item-stock-type">
                            {"公司電話: " + data.content.Tel}
                          </p>
                        )}
                      </button>
                    ))
                    : null}
                </div>
              </div>
            );
          } else if (productDetailType === "industryRanking") {
            content = (
              <div className="content">
                <div className="content-header">
                  <div>
                    <IconButton onClick={toggleFilterDetail} style={{
                      padding: "0",
                    }}>
                      <Icon className="close" >
                        close
                      </Icon>
                    </IconButton>
                  </div>
                  <div>
                    <Button startIcon={<FontAwesomeIcon className="export-excel-icon" icon={faFileExcel} />} onClick={openApplyFileHandler}>匯出 Excel 報表</Button>
                    {/* 先關閉手機自定義關注儀表板 */}
                    {
                      innerWidth > 1023 &&
                      <Button
                        onClick={props.handleOpenCustomDashboardModalOpen}
                        className="export-pdf-btn"
                        startIcon={<AddIcon />}
                      >
                        自訂關注廠商儀表板
                      </Button>
                    }
                  </div>
                  <TextField
                    className="material-input-select"
                    variant="outlined"
                    size="small"
                    onChange={handleChange}
                    value={productDetailType}
                    select
                  >
                    {tabData.map((tab) => (
                      <MenuItem value={tab.key}>{tab.item}</MenuItem>
                    ))}
                  </TextField>
                </div>
                {/* 先關閉手機自定義關注儀表板 */}
                {
                  innerWidth > 1023 && <CheckAllContainer>
                    <FormControlLabel
                      control={
                        <Checkbox
                          sx={{
                            "&.Mui-checked": {
                              color: "#25758A",
                            },
                            padding: "2px 0px 2px 0px",
                            margin: "0px 0px 0px 10px"
                          }}
                          checked={is_check_all_custom_dashboard}
                          onClick={handleClickAll}
                        />
                      }
                      label="勾選全部"
                    />
                  </CheckAllContainer>
                }
                <div className="content-content">
                  {data
                    ? state.dataSortByIndu.map((data) => (
                      <IndustrySeperationBox
                        updateCustomDashboardData={
                          updateCustomDashboardData
                        }
                        handleUpdateCustomDashboardData={
                          handleUpdateCustomDashboardData
                        }
                        innerWidth={innerWidth} data={data} key={data.name} />
                    ))
                    : ""}
                </div>
              </div>
            );
          } else if (productDetailType === "parkRanking") {
            content = (
              <div className="content">
                <div className="content-header">
                  <div>
                    <IconButton onClick={toggleFilterDetail} style={{
                      padding: "0",
                    }}>
                      <Icon className="close">
                        close
                      </Icon>
                    </IconButton>
                  </div>
                  <div>
                    <Button startIcon={<FontAwesomeIcon className="export-excel-icon" icon={faFileExcel} />} onClick={openApplyFileHandler}>匯出 Excel 報表</Button>
                    {
                      innerWidth > 1023 &&
                      <Button
                        onClick={props.handleOpenCustomDashboardModalOpen}
                        className="export-pdf-btn"
                        startIcon={<AddIcon />}
                      >
                        自訂關注廠商儀表板
                      </Button>
                    }
                  </div>
                  <TextField
                    className="material-input-select"
                    variant="outlined"
                    size="small"
                    onChange={handleChange}
                    value={productDetailType}
                    select
                  >
                    {tabData.map((tab) => (
                      <MenuItem value={tab.key}>{tab.item}</MenuItem>
                    ))}
                  </TextField>
                </div>
                {/* 先關閉手機自定義關注儀表板 */}
                {
                  innerWidth > 1023 && <CheckAllContainer>
                    <FormControlLabel
                      control={
                        <Checkbox
                          sx={{
                            "&.Mui-checked": {
                              color: "#25758A",
                            },
                            padding: "2px 0px 2px 0px",
                            margin: "0px 0px 0px 10px"
                          }}
                          checked={is_check_all_custom_dashboard}
                          onClick={handleClickAll}
                        />
                      }
                      label="勾選全部"
                    />
                  </CheckAllContainer>
                }
                <div className="content-content">
                  {data
                    ? state.dataSortByPark.map((data) => (
                      <IndustrySeperationBox updateCustomDashboardData={
                        updateCustomDashboardData
                      }
                        handleUpdateCustomDashboardData={
                          handleUpdateCustomDashboardData
                        }
                        innerWidth={innerWidth} data={data} key={data.name} />
                    ))
                    : ""}
                </div>
              </div>
            );
          } else if (productDetailType === "cityRanking") {
            content = (
              <div className="content">
                <div className="content-header">
                  <div>
                    <IconButton onClick={toggleFilterDetail} style={{
                      padding: "0",
                    }}>
                      <Icon className="close">
                        close
                      </Icon>
                    </IconButton>
                  </div>
                  <div>
                    {localStorage.getItem("auL") <= 3 ? (
                      <Button startIcon={<FontAwesomeIcon className="export-excel-icon" icon={faFileExcel} />} onClick={openApplyFileHandler}>匯出 Excel 報表</Button>
                    ) : (
                      ""
                    )}
                    {
                      innerWidth > 1023 &&
                      <Button
                        onClick={props.handleOpenCustomDashboardModalOpen}
                        className="export-pdf-btn"
                        startIcon={<AddIcon />}
                      >
                        自訂關注廠商儀表板
                      </Button>
                    }
                  </div>
                  <TextField
                    className="material-input-select"
                    variant="outlined"
                    size="small"
                    onChange={handleChange}
                    value={productDetailType}
                    select
                  >
                    {tabData.map((tab) => (
                      <MenuItem value={tab.key}>{tab.item}</MenuItem>
                    ))}
                  </TextField>
                </div>
                {/* 先關閉手機自定義關注儀表板 */}
                {
                  innerWidth > 1023 && <CheckAllContainer>
                    <FormControlLabel
                      control={
                        <Checkbox
                          sx={{
                            "&.Mui-checked": {
                              color: "#25758A",
                            },
                            padding: "2px 0px 2px 0px",
                            margin: "0px 0px 0px 10px"
                          }}
                          checked={is_check_all_custom_dashboard}
                          onClick={handleClickAll}
                        />
                      }
                      label="勾選全部"
                    />
                  </CheckAllContainer>
                }
                <div className="content-content">
                  {data
                    ? state.dataSortByCity.map((data) => (
                      <IndustrySeperationBox updateCustomDashboardData={
                        updateCustomDashboardData
                      }
                        handleUpdateCustomDashboardData={
                          handleUpdateCustomDashboardData
                        }
                        innerWidth={innerWidth} data={data} key={data.name} />
                    ))
                    : ""}
                </div>
              </div>
            );
          }
        } else if (localStatus === "talentSearch" && innerWidth < 1024) {
          const school = [];

          data.map((item) => {
            school.push(item.content.schoolName);
          });

          const uniqSchool = [...new Set(school)];

          content = (
            <div className="content">
              <div className="school-selection">
                <FormControl
                  className="school-selection-form"
                  variant="outlined"
                >
                  <InputLabel className="select-label">學校</InputLabel>
                  <Select
                    value={selectedSchool}
                    onChange={(event) => selectedSchoolHandler(event)}
                    input={<Input type="select" />}
                    name="selectedSchool"
                  >
                    <MenuItem value={""} key={"all"}>
                      所有學校
                    </MenuItem>
                    {data &&
                      data.length > 0 &&
                      uniqSchool.map((school, index) => (
                        <MenuItem value={school} key={index}>
                          {school}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </div>
              <div className="content-content">
                {data
                  ? selectedDataList.map((data) => (
                    <div
                      className="content-item"
                      onClick={() => {
                        setSelectedLocationHandler([
                          data.position,
                          data.content.researcherNo,
                        ]);
                      }}
                    >
                      <p className="content-item-title">
                        {data.content.name}
                      </p>
                      <p className="content-item-address">
                        {data.content.schoolName +
                          " " +
                          data.content.deptName}
                      </p>
                      <p className="content-item-hits">
                        共命中 <span>{data.content.hits}</span> 次
                      </p>
                      <p className="content-item-type-four">
                        {data.content.skill}
                      </p>
                    </div>
                  ))
                  : null}
              </div>
            </div>
          );
        }
      } else {
        content = (
          <div className="content">
            <button className="open-content" onClick={toggleFilterDetail}>
              <Icon>view_list</Icon>
              廠商詳細列表
            </button>
          </div>
        );
      }
    } else if (listType === "map-search-result-length") {
      content = <div className="content">
        <Box style={{ paddingLeft: "12px", paddingRight: "12px", width: "100%" }}>
          <h3 style={{ fontSize: "1.5rem", textAlign: "center" }}>搜尋結果</h3>
          <Box style={{ backgroundColor: "#ececec", borderRadius: "13px", whiteSpace: "nowrap", padding: "14px", marginTop: "12px", fontSize: "1.15rem" }}>
            <p>{`筆數： ${selectedDataList && selectedDataList?.length}`}</p>
          </Box>
          <Box style={{ backgroundColor: "#ececec", borderRadius: "13px", whiteSpace: "nowrap", padding: "14px", marginTop: "12px", fontSize: "1.15rem" }}>
            <p>關鍵字：{keyword && keyword.length > 0 && keyword.map((item, index) => (
              <span>{`${item} ${index !== keyword.length - 1 ? "," : ""}`}</span>
            ))}</p>
          </Box>
        </Box>
      </div>
    } else if (listType === "filter-condition-mobile") {
      content = (
        <div className="map-desktop-filter-close-content">
          <h3>搜尋條件</h3>
          <div className="map-desktop-filter-scroallable-content">
            {data && (
              <div className="filtered-item">
                <p>
                  總共{" "}
                  {localStorage.getItem("isLogin") !== "true" &&
                    lengthOfData > 10
                    ? 10
                    : lengthOfData}{" "}
                  筆資料
                </p>
              </div>
            )}
            {/* <Icon>arrow_forward_ios</Icon> */}
            {data[0].length !== 0 && (
              <div className="filtered-item">
                <p>關鍵字:</p>
                {data[0].map((keyword) => (
                  <p>{keyword}</p>
                ))}
              </div>
            )}
            <div className="filtered-item">
              <p>地區:</p>
              <p>
                {data[1] === "All"
                  ? "台灣所有地區"
                  : data[1] === "North"
                    ? "台灣北部地區"
                    : data[1] === "Center"
                      ? "台灣中部地區"
                      : data[1] === "East"
                        ? "台灣南東地區"
                        : "台灣南部地區"}
              </p>
            </div>
            {data[2].length !== 0 && (
              <div className="filtered-item">
                <p>縣市:</p>
                {data[2].map((city) => (
                  <p>{city}</p>
                ))}
              </div>
            )}
            {data[3].length !== 0 && (
              <div className="filtered-item">
                <p>園區:</p>
                {data[3].map((park) => (
                  <p>{park}</p>
                ))}
              </div>
            )}
            {data[4].length !== 0 && (
              <div className="filtered-item">
                <p>產業別:</p>
                {data[4].map((type) => {
                  if (type.length === 2) {
                    const updateTypeTwo = typeTwo.filter(
                      (types) => types.Key === type.toString()
                    );
                    return <p>{updateTypeTwo[0].Value}</p>;
                  } else if (type.length === 4) {
                    const updateTypeFour = typeFour.filter(
                      (types) => types.Key === type.toString()
                    );
                    return <p>{updateTypeFour[0].Value}</p>;
                  }
                })}
              </div>
            )}
            {data[7] && (
              <div className="filtered-item">
                <p>最低資本額</p>
                <p>{data[7]}</p>
              </div>
            )}{" "}
            {data[8] && (
              <div className="filtered-item">
                <p>最高資本額</p>
                <p>{data[8]}</p>
              </div>
            )}{" "}
            {data[5] && (
              <div className="filtered-item">
                <p>股票上市櫃廠商</p>
              </div>
            )}{" "}
            {data[6] && (
              <div className="filtered-item">
                <p>產業園區內廠商</p>
              </div>
            )}{" "}
            {data[9] && (
              <div className="filtered-item">
                <p>近五年得過獎的廠商</p>
              </div>
            )}{" "}
            {data[10] && (
              <div className="filtered-item">
                <p>近五年執行過政府計畫的廠商</p>
              </div>
            )}{" "}
            {data[11] && (
              <div className="filtered-item">
                <p>近五年有專利公告的廠商</p>
              </div>
            )}{" "}
            {data[12] && (
              <div className="filtered-item">
                <p>上一年度進口級距為C以上的廠商</p>
              </div>
            )}{" "}
            {data[13] && (
              <div className="filtered-item">
                <p>上一年度出口級距為C以上的廠商</p>
              </div>
            )}{" "}
            {props.isUseLocation && (
              <div className="filtered-item">
                <p>以你所在位置範圍{props.range}公里內</p>
              </div>
            )}{" "}
          </div>
        </div>
      );
    } else if (listType === "isTalentDetail") {
      if (talentLoading) {
        content = <Spinner />;
      } else if (!talentLoading && innerWidth > 1024) {
        const isEnterprise = new RegExp("產學合作研究計畫");
        const isTechEnterprise = new RegExp("產學技術聯盟合作計畫");
        let count = 0;

        projects.map((project) => {
          if (
            isEnterprise.test(project.category) ||
            isTechEnterprise.test(project.category)
          ) {
            count += 1;
          }
        });

        content = (
          <div className="talent-detail">
            <div className="talent-detail-title">
              <h3>{basic.chineseName} 老師的詳細資料</h3>
              <div className="isEnterprise">
                <p>科技部產學計畫數:</p>
                <span>{count}</span>
              </div>
              <IconButton onClick={closeDetail} style={{
                padding: "0",
                marginLeft: "4px"
              }}>
                <Icon className="close">
                  close
                </Icon>
              </IconButton>
            </div>
            <div className="scrollable-part">
              <div className="talent-detail-content">
                <div className="talent-detail-content-even">
                  <div className="title">姓名 :</div>
                  <div
                    className="content"
                    style={
                      !basic.chineseName || basic.chineseName === "[不公開]"
                        ? { color: "#757575" }
                        : { color: "#000000" }
                    }
                  >
                    {basic.chineseName ? basic.chineseName : "[不公開]"}
                  </div>
                </div>
                <div className="talent-detail-content-odd">
                  <div className="title">性別 :</div>
                  <div
                    className="content"
                    style={
                      !basic.englishName || basic.englishName === "[不公開]"
                        ? { color: "#757575" }
                        : { color: "#000000" }
                    }
                  >
                    {basic.englishName ? basic.englishName : "[不公開]"}
                  </div>
                </div>
                <div className="talent-detail-content-even">
                  <div className="title">英文姓名 :</div>
                  <div
                    className="content"
                    style={
                      !basic.sex || basic.sex === "[不公開]"
                        ? { color: "#757575" }
                        : { color: "#000000" }
                    }
                  >
                    {basic.sex ? basic.sex : "[不公開]"}
                  </div>
                </div>
                <div className="talent-detail-content-odd">
                  <div className="title">聯絡地址 :</div>
                  <div
                    className="content"
                    style={
                      !basic.address || basic.address === "[不公開]"
                        ? { color: "#757575" }
                        : { color: "#000000" }
                    }
                  >
                    {basic.address ? basic.address : "[不公開]"}
                  </div>
                </div>
                <div className="talent-detail-content-even">
                  <div className="title">E-mail :</div>
                  <div
                    className="content"
                    style={
                      !basic.email || basic.email === "[不公開]"
                        ? { color: "#757575" }
                        : { color: "#000000" }
                    }
                  >
                    {basic.email ? basic.email : "[不公開]"}
                  </div>
                </div>
                <div className="talent-detail-content-even">
                  <div className="title">服務機關 :</div>
                  <div
                    className="content"
                    style={
                      !basic.serviceAgencies ||
                        basic.serviceAgencies === "[不公開]"
                        ? { color: "#757575" }
                        : { color: "#000000" }
                    }
                  >
                    {basic.serviceAgencies ? basic.serviceAgencies : "[不公開]"}
                  </div>
                </div>
                <div className="talent-detail-content-odd">
                  <div className="title">職稱 :</div>
                  <div
                    className="content"
                    style={
                      !basic.degree || basic.degree === "[不公開]"
                        ? { color: "#757575" }
                        : { color: "#000000" }
                    }
                  >
                    {basic.degree ? basic.degree : "[不公開]"}
                  </div>
                </div>
                <div className="talent-detail-content-even">
                  <div className="title">聯絡電話 :</div>
                  <div
                    className="content"
                    style={
                      !basic.phone || basic.phone === "[不公開]"
                        ? { color: "#757575" }
                        : { color: "#000000" }
                    }
                  >
                    {basic.phone ? basic.phone : "[不公開]"}
                  </div>
                </div>
                <div className="talent-detail-content-odd">
                  <div className="title">傳真 :</div>
                  <div
                    className="content"
                    style={
                      !basic.fax || basic.fax === "[不公開]"
                        ? { color: "#757575" }
                        : { color: "#000000" }
                    }
                  >
                    {basic.fax ? basic.fax : "[不公開]"}
                  </div>
                </div>
                <div className="talent-detail-content-even">
                  <div className="title">個人網站 :</div>
                  <button
                    className="content-link"
                    onClick={
                      !basic.webSite || basic.webSite === "[不公開]"
                        ? () => { }
                        : () => {
                          let isHttp = new RegExp("^(http|https)");
                          window.open(
                            !isHttp.test(basic.webSite)
                              ? `https://${basic.webSite}`
                              : `${basic.webSite}`,
                            "_blank"
                          );
                        }
                    }
                    style={
                      !basic.webSite || basic.webSite === "[不公開]"
                        ? { color: "#757575", textDecoration: "none" }
                        : { display: "block" }
                    }
                  >
                    {basic.webSite ? `個人網站連結` : "[不公開]"}
                  </button>
                </div>
                <div className="talent-detail-content-odd">
                  <div className="title">專長 :</div>
                  <div
                    className="content"
                    style={
                      !basic.skill || basic.skill === "[不公開]"
                        ? { color: "#757575" }
                        : { color: "#000000" }
                    }
                  >
                    {basic.skill ? basic.skill : "[不公開]"}
                  </div>
                </div>
                {/* <div className='talent-detail-content-column'>
                  <div className='talent-detail-content-row-title'>
                    
                    
                  </div>
                  <div className='talent-detail-content-row-content'>
                  </div>
                </div> */}
                {/* <div className='talent-detail-content-column'>
                  <div className='talent-detail-content-row-title'>
                    =
                  </div>
                  <div className='talent-detail-content-row-content'>
                  </div>
                </div> */}
              </div>
              <div className="talent-detail-project">
                <div className="talent-detail-project-title">
                  <h3>相關計畫</h3>
                </div>
                <div className="talent-detail-project-content">
                  <div className="talent-detail-project-content-title">
                    <div className="year">出版年月</div>
                    <div className="category">補助類別</div>
                    <div className="acdemic_category">計畫名稱</div>
                    <div className="author">計畫角色</div>
                    <div className="reference">計畫金額</div>
                  </div>
                  {projects
                    .sort((a, b) => b.year - a.year)
                    .sort((a, b) => b.month - a.month)
                    .map((project, index) => {
                      return (
                        <div
                          className={
                            (index + 1) % 2 === 0
                              ? "talent-detail-project-content-odd"
                              : "talent-detail-project-content-even"
                          }
                        >
                          <div className="year">{project.year}</div>
                          <div className="category">{project.category}</div>
                          <div className="acdemic_category">
                            {project.projectName}
                          </div>
                          <div className="author">{project.roles}</div>
                          <div className="reference">{project.fund}</div>
                        </div>
                      );
                    })}
                </div>
              </div>
              <div className="talent-detail-publication">
                <div className="talent-detail-publication-title">
                  <h3>相關著作</h3>
                </div>
                <div className="talent-detail-publication-content">
                  <div className="talent-detail-publication-content-title">
                    <div className="year">出版年月</div>
                    <div className="category">著作類別</div>
                    <div className="title">著作名稱</div>
                    <div className="author">作者</div>
                  </div>
                  {papers.map((content, index) => {
                    return (
                      <div
                        className={
                          (index + 1) % 2 === 0
                            ? "talent-detail-publication-content-odd"
                            : "talent-detail-publication-content-even"
                        }
                      >
                        <div className="year">
                          {content.year + "/" + content.month}
                        </div>
                        <div className="category">{content.type}</div>
                        <div className="title">
                          {content.title.substr(0, 50) + " ..."}
                        </div>
                        <div className="author">
                          {content.author.substr(0, 50) + " ..."}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>

              <div></div>
            </div>
          </div>
        );
      } else if (!talentLoading && innerWidth < 1024) {
        const isEnterprise = new RegExp("產學合作研究計畫");
        const isTechEnterprise = new RegExp("產學技術聯盟合作計畫");
        let count = 0;

        projects.map((project) => {
          if (
            isEnterprise.test(project.category) ||
            isTechEnterprise.test(project.category)
          ) {
            count += 1;
          }
        });

        content = (
          <div className="talent-detail">
            <div className="talent-detail-title">
              <h3>{basic.chineseName} 老師的詳細資料</h3>
              <IconButton onClick={closeDialog} style={{
                padding: "0",
              }}>
                <Icon className="close-icon">
                  close
                </Icon>
              </IconButton>
            </div>
            <div className="isEnterprise">
              科技部產學計畫數: <span>{count}</span>
            </div>
            <div className="scrollable-part">
              <div className="talent-detail-content">
                <div className="talent-detail-content-container">
                  <div className="talent-detail-content-container-title">
                    姓名
                  </div>
                  <div
                    className="talent-detail-content-container-content"
                    style={
                      !basic.chineseName || basic.chineseName === "[不公開]"
                        ? { color: "#757575" }
                        : { color: "#000000" }
                    }
                  >
                    {basic.chineseName ? basic.chineseName : "[不公開]"}
                  </div>
                </div>
                <div className="talent-detail-content-container">
                  <div className="talent-detail-content-container-title">
                    英文姓名
                  </div>
                  <div
                    className="talent-detail-content-container-content"
                    style={
                      !basic.englishName || basic.englishName === "[不公開]"
                        ? { color: "#757575" }
                        : { color: "#000000" }
                    }
                  >
                    {basic.englishName ? basic.englishName : "[不公開]"}
                  </div>
                </div>
                <div className="talent-detail-content-container">
                  <div className="talent-detail-content-container-title">
                    性別
                  </div>
                  <div
                    className="talent-detail-content-container-content"
                    style={
                      !basic.sex || basic.sex === "[不公開]"
                        ? { color: "#757575" }
                        : { color: "#000000" }
                    }
                  >
                    {basic.sex ? basic.sex : "[不公開]"}
                  </div>
                </div>
                <div className="talent-detail-content-container">
                  <div className="talent-detail-content-container-title">
                    服務機關
                  </div>
                  <div
                    className="talent-detail-content-container-content"
                    style={
                      !basic.serviceAgencies ||
                        basic.serviceAgencies === "[不公開]"
                        ? { color: "#757575" }
                        : { color: "#000000" }
                    }
                  >
                    {basic.serviceAgencies ? basic.serviceAgencies : "[不公開]"}
                  </div>
                </div>
                <div className="talent-detail-content-container">
                  <div className="talent-detail-content-container-title">
                    職稱
                  </div>
                  <div
                    className="talent-detail-content-container-content"
                    style={
                      !basic.degree || basic.degree === "[不公開]"
                        ? { color: "#757575" }
                        : { color: "#000000" }
                    }
                  >
                    {basic.degree ? basic.degree : "[不公開]"}
                  </div>
                </div>
                <div className="talent-detail-content-container">
                  <div className="talent-detail-content-container-title">
                    聯絡電話
                  </div>
                  <div
                    className="talent-detail-content-container-content"
                    style={
                      !basic.phone || basic.phone === "[不公開]"
                        ? { color: "#757575" }
                        : { color: "#000000" }
                    }
                  >
                    {basic.phone ? basic.phone : "[不公開]"}
                  </div>
                </div>
                <div className="talent-detail-content-container">
                  <div className="talent-detail-content-container-title">
                    傳真
                  </div>
                  <div
                    className="talent-detail-content-container-content"
                    style={
                      !basic.fax || basic.fax === "[不公開]"
                        ? { color: "#757575" }
                        : { color: "#000000" }
                    }
                  >
                    {basic.fax ? basic.fax : "[不公開]"}
                  </div>
                </div>
                <div className="talent-detail-content-container">
                  <div className="talent-detail-content-container-title">
                    個人網站
                  </div>
                  <button
                    className="talent-detail-content-container-content-link"
                    onClick={
                      !basic.webSite || basic.webSite === "[不公開]"
                        ? () => { }
                        : () => {
                          window.open(basic.webSite, "_blank");
                        }
                    }
                    style={
                      !basic.webSite || basic.webSite === "[不公開]"
                        ? { color: "#757575", textDecoration: "none" }
                        : { display: "block" }
                    }
                  >
                    {basic.webSite ? `個人網站連結` : "[不公開]"}
                  </button>
                </div>
                <div className="talent-detail-content-container">
                  <div className="talent-detail-content-container-title">
                    聯絡地址
                  </div>
                  <div
                    className="talent-detail-content-container-content"
                    style={
                      !basic.address || basic.address === "[不公開]"
                        ? { color: "#757575" }
                        : { color: "#000000" }
                    }
                  >
                    {basic.address ? basic.address : "[不公開]"}
                  </div>
                </div>
                <div className="talent-detail-content-container">
                  <div className="talent-detail-content-container-title">
                    E-mail
                  </div>
                  <div
                    className="talent-detail-content-container-content"
                    style={
                      !basic.email || basic.email === "[不公開]"
                        ? { color: "#757575" }
                        : { color: "#000000" }
                    }
                  >
                    {basic.email ? basic.email : "[不公開]"}
                  </div>
                </div>
                <div className="talent-detail-content-container">
                  <div className="talent-detail-content-container-title">
                    專長
                  </div>
                  <div
                    className="talent-detail-content-container-content"
                    style={
                      !basic.skill || basic.skill === "[不公開]"
                        ? { color: "#757575" }
                        : { color: "#000000" }
                    }
                  >
                    {basic.skill ? basic.skill : "[不公開]"}
                  </div>
                </div>
              </div>
              <div className="talent-detail-project">
                <div className="talent-detail-project-title">
                  <h3>相關計畫</h3>
                </div>
                <div className="talent-detail-project-content">
                  {/* <div className='talent-detail-project-content-title' >
                    <div className='year'>出版年月</div>
                    <div className='category'>補助類別</div>
                    <div className='acdemic_category'>學門分類</div>
                    <div className='author'>計畫角色</div>
                    <div className='reference'>計畫金額</div>
                  </div> */}
                  {projects
                    .sort((a, b) => b.year - a.year)
                    .map((project, index) => {
                      return (
                        <div
                          className={
                            (index + 1) % 2 === 0
                              ? "talent-detail-project-content-odd"
                              : "talent-detail-project-content-even"
                          }
                        >
                          <div className="talent-detail-project-content-container">
                            <div className="talent-detail-project-content-title">
                              年份
                            </div>
                            <div className="year">{project.year}</div>
                          </div>
                          <div className="talent-detail-project-content-container">
                            <div className="talent-detail-project-content-title">
                              補助類別
                            </div>
                            <div className="category">{project.category}</div>
                          </div>
                          <div className="talent-detail-project-content-container">
                            <div className="talent-detail-project-content-title">
                              計畫名稱
                            </div>
                            <div className="author">{project.projectName}</div>
                          </div>
                          <div className="talent-detail-project-content-container">
                            <div className="talent-detail-project-content-title">
                              計畫角色
                            </div>
                            <div className="roles">{project.roles}</div>
                          </div>
                          <div className="talent-detail-project-content-container">
                            <div className="talent-detail-project-content-title">
                              補助金額
                            </div>
                            <div className="fund">{project.fund}</div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
              <div className="talent-detail-publication">
                <div className="talent-detail-publication-title">
                  <h3>相關著作</h3>
                </div>
                <div className="talent-detail-publication-content">
                  {papers.map((content, index) => {
                    return (
                      <div
                        className={
                          (index + 1) % 2 === 0
                            ? "talent-detail-publication-content-odd"
                            : "talent-detail-publication-content-even"
                        }
                      >
                        <div className="talent-detail-publication-content-container">
                          <div className="talent-detail-publication-content-container-title">
                            出版年月
                          </div>
                          <div className="year">
                            {content.year + "/" + content.month}
                          </div>
                        </div>
                        <div className="talent-detail-publication-content-container">
                          <div className="talent-detail-publication-content-container-title">
                            著作類別
                          </div>
                          <div className="category">{content.type}</div>
                        </div>
                        <div className="talent-detail-publication-content-container">
                          <div className="talent-detail-publication-content-container-title">
                            作者
                          </div>
                          <div className="author">{content.author}</div>
                        </div>
                        <div className="talent-detail-publication-content-container">
                          <div className="talent-detail-publication-content-container-title">
                            著作名稱
                          </div>
                          <div className="title">{content.title}</div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>

              <div></div>
            </div>
          </div>
        );
      }
    }
  }

  return (
    <div
      className="map-list-container"
      style={{
        // left: left,
        right: innerWidth === 1024 ? "10px" : "5vw",
        top: top,
        width: containerWidth,
        backgroundColor: backgroundColor,
      }}
    >
      {data ? data.length === 0 && <div>此查詢條件無資料。</div> : null}
      {content}
    </div>
  );
};

const CheckBoxContainer = ({
  handleUpdateCustomDashboardData,
  updateCustomDashboardData,
  business_id,
  is_company_selected,
}) => {
  const handleClick = (e) => {
    e.stopPropagation();
    if (localStorage.getItem("isLogin") !== "true") {
      alert("請登入平台新增你的自訂關注廠商儀表板");
    } else {
      handleUpdateCustomDashboardData(business_id);
    }
  };

  if (localStorage.getItem("isLogin") !== "true") {
    return null
  }

  return (
    <Tooltip title="加入自訂儀表板清單">
      <Checkbox
        onClick={handleClick}
        checked={is_company_selected}
        size="small"
        sx={{
          color: "#ffffff",
          padding: "0px",
          transition: "translateY(-2px)",
          "&.Mui-checked": {
            color: "#25758A",
          },
        }}
      />
    </Tooltip>
  );
};

const CheckAllContainer = styled.div`
  display: ${p => localStorage.getItem("isLogin") === "true" ? "flex" : "none"};
  justify-content: flex-start;
  align-items: center;
  width: 90%;
  margin: 0px 5%;
`;

export default MapListContainer;