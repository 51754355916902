import React, { useState, useEffect } from 'react'
import { Chip, Icon, IconButton } from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'

import ImgGrid from '../ImgGrid/ImgGrid'
import * as planActions from '../../store/actions/govplan'
import { thousandDivider } from "../../utility/common";

import './styles.scss'

const GovPlanDetail = props => {
    const dispatch = useDispatch()
    const { typeTwoConvent } = useSelector(state => state.govPlan)
    const { plan, displayMode } = props 

    useEffect(() =>{
        dispatch(planActions.getTypeTwo())
    },[])

    const Display = props => {
      const { link, linkUrl } = props

      let classContent
      let content

      if(link) {
        classContent = 'gov-plan-detail-display-link'
        content = props.content
      }else if (props.content && props.content !== 'undefined') {
        classContent = 'gov-plan-detail-display-content'
        content = props.content
      } else if (!props.content && props.title === '補助金額上限') {
        classContent = 'gov-plan-detail-display-content'
        content = '依計畫核定'
      }else {
        classContent = 'gov-plan-detail-display-content-none'
        content = '目前暫無資料'
      }


      if(typeof props.content === 'object'){
        const { content, title } = props
        if(content.length === 0){
          return(
            <div className='gov-plan-detail-display'>
              <p className='gov-plan-detail-display-title'>{props.title}</p>
              <p className='gov-plan-detail-display-content-none'>目前暫無資料</p>
            </div>  
          )
        }  
        return(
          <div className='gov-plan-detail-display'>
            <p className='gov-plan-detail-display-title'>{props.title}</p>
            {content !== '目前暫無資料' && title !== '相關產業別' &&
              <p className={classContent}>{content.map(item => <Chip label={item} />)}</p>
            }
            {content !== '目前暫無資料' && title === '相關產業別' &&
              <p className={classContent}>
              {(Object.keys(typeTwoConvent).length > 0 && content.length > 0) && content.map(item => <Chip label={item.toString()+' '+typeTwoConvent[item].toString()} /> )}  
              </p>
            }
          </div>
        )
      } else if (props.title === '內容' || props.title === '申請資格' || props.title === '目標'){
        return(
          <div className='gov-plan-detail-display' >
            <p className='gov-plan-detail-display-title'>{props.title}</p>
            {content.split('。').map(p => <p className={classContent}>{p}</p>)}        
          </div>
        )
      } else {
        return(
          <div className='gov-plan-detail-display' >
            <p className='gov-plan-detail-display-title'>{props.title}</p>
            <p className={classContent}>{link && linkUrl? <a href={linkUrl} target="_blank" rel="noopener noreferrer" title={`另開新視窗，前往 ${content} 連結`}>{content}</a>:content}</p>
          </div>
        )
      }

    }

    const closeModalHandler = () => {
      props.closeModal()
    }
    const parseCategory = (category) => {
      const index = category.findIndex(item => item === "計畫廣宣")
      if (index > -1) {
        category[index] = "課程訊息"
        return category
      } else {
        return category
      }
    }
    const parseBgColor = (category) => {
      const key = ["淨零減碳", "數位轉型", "研究發展相關", "市場行銷相關", "人力資源相關", "課程訊息", "企業獎項", "資金貸款"]
      const rightOne = category.find(item => key.indexOf(item) !== -1)
      switch (rightOne) {
        case "淨零減碳":
          return "#12736e"
        case "數位轉型":
          return "rgba(16, 55, 120, 1)"
        case "研究發展相關":
          return "#1a7a3d"
        case "市場行銷相關":
          return "rgba(16, 133, 201, 1)"
        case "人力資源相關":
          return "#8b6132"
        // case "課程訊息":
        //   return "rgba(227, 73, 40, 1)"
        case "企業獎項":
          return "rgba(79, 31, 176, 1)"
        case "資金貸款":
          return "rgba(227, 55, 30, 1)"
        default:
          return "rgba(151, 0, 50, 1)"
      }
    }

    if (displayMode){
      return (
        <div className="gov-plan-display-detail">
          <div className="header-container">
            <h3 style={{
              backgroundColor: parseBgColor(plan.Category),
              color: "white",
              padding: "5px 10px",
            }}>{plan.Name}詳細內容</h3>
            <IconButton size="small" title="關閉詳細內容視窗" onClick={closeModalHandler}>
              <Icon className="icon-close">
                close
              </Icon>
            </IconButton>
          </div>

          <div className="detail-content">
            <ul>
              <li style={{ marginTop: "0px" }}>
                <Display title="名稱" content={plan.Name} />
              </li>
              <li>
                <Display title="內容" content={plan.Description} />
              </li>
              <li>
                <Display title="申請資格" content={plan.Target} />
              </li>
              <li>
                <Display title="適用企業層級" content={plan.CorpLevel} />
              </li>
              <li>
                <Display title="申請期間" content={`${plan.StartDate} ~ ${plan.EndDate}`} />
              </li>
              {/* <li>{plan.description}</li>
            <li>{plan.goal}</li>
            <li>{plan.target}</li>
            <li>{plan.startDate} ~ {plan.endDate}</li>
            <li>{plan.link}</li>
            <li>{plan.name}</li> */}
              {/* <li>{plan.name}</li>                                                                                          */}
            </ul>
            <ul>
              <li>
                <Display title="目標" content={plan.Goal} />
              </li>
              <li>
                <Display link title="連結" content={plan.Name} linkUrl={plan.Link} />
              </li>
              {/* <li>
                <Display title="補助金額上限" content={thousandDivider(plan.Amount)} />
              </li> */}
              <li>
                <Display title="主辦單位" content={plan.Host} />
              </li>
              <li>
                <Display title="分類" content={parseCategory(plan.Category)} />
              </li>
              <li>
                <Display
                  title="相關產業別"
                  content={plan.IndustryCode_Two}
                  twoCode
                />
              </li>
              <li>
                <Display title="相關產業描述" content={plan.IndustryMemo} />
              </li>
              {/* <li>
                <a
                  className="file-download"
                  href={"https://indupark.smartpark.com.tw/" + plan.AttachedFile}
                >
                  下載資料
                </a>
              </li> */}
            </ul>
          </div>

          <div className="detail-content-mobile">
            <ul>
              <li style={{ marginTop: "0px" }}>
                <Display title="名稱" content={plan.Name} />
              </li>
              <li>
                <Display title="內容" content={plan.Description} />
              </li>
              <li>
                <Display title="申請資格" content={plan.Target} />
              </li>
              <li>
                <Display title="適用企業層級" content={plan.CorpLevel} />
              </li>
              <li>
                <Display title="申請期間" content={`${plan.StartDate} ~ ${plan.EndDate}`} />
              </li>
              {/* <li>{plan.description}</li>
            <li>{plan.goal}</li>
            <li>{plan.target}</li>
            <li>{plan.startDate} ~ {plan.endDate}</li>
            <li>{plan.link}</li>
            <li>{plan.name}</li> */}
              {/* <li>{plan.name}</li>                                                                                          */}
              <li>
                <Display title="目標" content={plan.Goal} />
              </li>
              <li>
                <Display link title="連結" content={plan.Name} linkUrl={plan.Link} />
              </li>
              {/* <li>
                <Display title="補助金額上限" content={thousandDivider(plan.Amount)} />
              </li> */}
              <li>
                <Display title="主辦單位" content={plan.Host} />
              </li>
              <li>
                <Display title="分類" content={parseCategory(plan.Category)} />
              </li>
              <li>
                <Display
                  title="相關產業別"
                  content={plan.IndustryCode_Two}
                  twoCode
                />
              </li>
              <li>
                <Display title="相關產業描述" content={plan.IndustryMemo} />
              </li>
              {/* <li>
                <a
                  className="file-download"
                  href={"https://indupark.smartpark.com.tw/" + plan.AttachedFile}
                >
                  下載資料
                </a>
              </li> */}
            </ul>
          </div>
          </div>
          
      );
    }else{
      return (
        <div className='gov-plan-detail'>
          <div className="header-container">
            <h3>{plan.Name}詳細內容</h3>
            <Icon className='icon-close' onClick={closeModalHandler}>close</Icon>
          </div>
          <div className='detail-content'>
            <div className="preview">
              <ImgGrid plan={plan} edited/>
            </div>
            <ul className='detail-content-management'>
              <li><Display
                title='名稱'
                content={plan.Name}
              /></li>
              <li><Display
                title={'內容'}
                content={plan.Description}
              /></li>
              <li><Display
                title='申請資格'
                content={plan.Target}
              /></li>
              <li><Display
                title='適用企業層級'
                content={plan.CorpLevel}
              /></li>
              <li><Display
                title='申請期間'
                content={`${plan.StartDate} ~ ${plan.EndDate}`}
              /></li>
              <li><Display
                title='目標'
                content={plan.Goal}
              /></li>
              <li>
                <Display
                title='連結'
                content={plan.Name}
                link
                linkUrl={plan.Link}
              /></li>
              <li><Display
                title='補助金額上限'
                content={thousandDivider(plan.Amount)}
              /></li>
              <li><Display
                title='主辦單位'
                content={plan.Host}
              /></li>
              <li><Display
                title='分類'
                content={parseCategory(plan.Category)}
              /></li>
              <li><Display
                title='相關產業別'
                content={plan.IndustryCode_Two}
                twoCode
              /></li>
              <li>
                <Display title="相關產業描述" content={plan.IndustryMemo} />
              </li>

              {/* <li>{plan.description}</li>
            <li>{plan.goal}</li>
            <li>{plan.target}</li>
            <li>{plan.startDate} ~ {plan.endDate}</li>
            <li>{plan.link}</li>
            <li>{plan.name}</li> */}
              {/* <li>{plan.name}</li>                                                                                          */}
            </ul>
          </div>
        </div>
      );
    }




}


export default GovPlanDetail