import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import "./styles.scss";
import { randomMath } from "../../../utility/common";

const FactoryBoardMember = (props) => {
  const { data, headerList } = props;
  const { innerWidth } = useSelector((state) => state.app);
  const [state, setState] = React.useState({
    endIndex: 0,
    page: 0,
    rowsPerPage: 5,
  });

  const openNewTabs = (link) => window.open(link, "_blank");

  const handleChangePage = (event, newPage) => {
    setState((state) => ({ ...state, page: newPage }));
  };
  React.useEffect(() => {
    if (Math.ceil(data.length) === state.page) {
      if (data.length % 5 === 0) {
        setState((state) => ({
          ...state,
          endIndex: state.page * state.rowsPerPage + state.rowsPerPage,
        }));
      } else {
        setState((state) => ({
          ...state,
          endIndex: state.page * state.rowsPerPage + (data.length % 5),
        }));
      }
    } else {
      setState((state) => ({
        ...state,
        endIndex: state.page * state.rowsPerPage + state.rowsPerPage,
      }));
    }
  }, [data.length, state.page]);

  return (
    <TableContainer className="table-container">
      <Table className="table">
        <TableHead className="table-header">
          {headerList && (
            <TableRow className="table-row">
              {headerList.map((headerItem, index) => (
                <TableCell
                  key={headerItem}
                  style={{
                    fontWeight: 600,
                  }}
                  className="table-cell"
                  align={index === 3 ? "center" : "left"}
                >
                  {headerItem}
                </TableCell>
              ))}
            </TableRow>
          )}
        </TableHead>

        <TableBody className="table-body">
          {data?.slice(state.page * 5, state.page * 5 + 5).map((row, index) => (
            <TableRow
              key={row.Name + randomMath()}
              hover
              className="table-body-row"
              style={index % 2 !== 0 ? { backgroundColor: "#ececec" } : {}}
            >
              <TableCell style={{ width: innerWidth <= 1024 ? "20%" : "15%" }}>
                {row.Type}
              </TableCell>
              <TableCell style={{ width: innerWidth <= 1024 ? "20%" : "15%" }}>
                {row.NewsDate.split("T")[0]}
              </TableCell>
              {innerWidth <= 1024 ? (
                <TableCell
                  className="invest-table"
                  onClick={() => openNewTabs(row.NewsLink)}
                >
                  {row.InvestDetail}
                </TableCell>
              ) : (
                <React.Fragment>
                  <TableCell style={{ width: "65%" }}>
                    {row.InvestDetail}
                  </TableCell>
                  <TableCell align="center" style={{ width: "10%" }}>
                    <FontAwesomeIcon
                      icon={faLink}
                      onClick={() => openNewTabs(row.NewsLink)}
                    />
                  </TableCell>
                </React.Fragment>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {data.length === 0 && (
        <Table>
          <TableBody className="table-body-no-data">
            <TableRow hover className="table-body-row">
              <TableCell align="center" className="no-data">
                本項目無資料
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      )}
      {data.length > 5 && (
        <TablePagination
          rowsPerPageOptions={[]}
          component="div"
          count={data.length}
          rowsPerPage={state.rowsPerPage}
          page={state.page}
          onChangePage={handleChangePage}
        />
      )}
    </TableContainer>
  );
};

export default React.memo(FactoryBoardMember);
