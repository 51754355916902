//廢棄物清理頁面
import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { Map, TileLayer } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-markercluster";

import MapSideMenu from "../../components/MapSideMenu";
import PopUp from "./CustomPopUp";
import Spinner from "../../components/Spinner/Spinner";
import GeoJson from "../../components/GeoJson/GeoJson";

const WastedCleanPage = () => {
  const mapRef = React.createRef(null);
  const {
    wasted_pollution_data,
    wasted_pollution_data_loading,
    wasted_pollution_list_loading,
  } = useSelector((state) => state.wasted_clean);
  const [state, setState] = React.useState({
    loading: true,
    marker_list: [],
    bond: [],
  });

  React.useEffect(() => {
    //防止出現灰色的地圖
    setTimeout(function () {
      window.dispatchEvent(new Event("resize"));
    }, 1000);
  }, []);

  React.useEffect(() => {
    if (wasted_pollution_data.length !== 0) {
      const update_bonds = [];
      const update_marker_list = [];
      const update_map_marker_list = [];

      wasted_pollution_data.forEach((i) => {
        update_bonds.push([i.Latitude, i.Longitude]);
        update_marker_list.push({
          latitude: i.Latitude,
          longitude: i.Longitude,
          address: i.Address,
          amount: i.Amount,
          business_no: i.BusinessNo,
          company_name: i.CompanyName,
          county: i.County,
          key: i.CtrlId,
          ctrl_id: i.CtrlId,
          phone: i.Phone,
        });
      });

      setState((state) => ({
        ...state,
        bond: update_bonds,
        marker_list: update_marker_list,
        map_marker_list: update_map_marker_list,
      }));
    } else {
      setState((state) => ({ ...state, marker_list: [] }));
    }
  }, [wasted_pollution_data, wasted_pollution_data_loading]);

  return (
    <WastedCleanWrapper>
      <MapSideMenu marker_list={state.marker_list} />
      {wasted_pollution_data_loading || wasted_pollution_list_loading ? (
        <Loading />
      ) : (
        <MapContainers
          ref={mapRef}
          bounds={
            state.marker_list.length === 0
              ? [
                  [25.268576, 121.611722],
                  [23.879299, 120.294881],
                  [23.762836, 121.54409],
                  [21.257621, 120.740482],
                  [21.8998, 120.837252],
                ]
              : state.bond
          }
          zoomControl={false}
          animate={true}
          zoom={8}
        >
          <TileLayer
            attribution='&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <GeoJson/>
          <MarkerClusterGroup>
            {state.marker_list.map((i) => (
              <PopUp
                latitude={i.latitude}
                longitude={i.longitude}
                address={i.address}
                amount={i.amount}
                business_no={i.business_no}
                company_name={i.company_name}
                county={i.county}
                key={i.ctrl_id}
                ctrl_id={i.ctrl_id}
                phone={i.phone}
              />
            ))}
          </MarkerClusterGroup>
        </MapContainers>
      )}
    </WastedCleanWrapper>
  );
};

const Loading = () => (
  <LoadingWrapper>
    <Spinner />
  </LoadingWrapper>
);

const LoadingWrapper = styled.div`
  position: absolute;
  top: 70px;
  left: 0px;
  width: 100vw;
  height: calc(100vh - 44px);
  z-index: 1201;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const WastedCleanWrapper = styled.div`
  width: 100vw;
  overflow: hidden;
`;

const MapContainers = styled(Map)`
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 0;
`;

export default WastedCleanPage;
