import * as actionTypes from "../actions/actionTypes";
import * as actionType from "../actionTypes/factory";

const initState = {
  basicInfo: {
    Addr: "",
    CompanyName_En: "",
    Phone: "",
    Registration_authority: "",
    IndustryArea: "",
    SetupDate: "",
    CapitalTotal: "",
    CapitalReality: "",
    Principal: "",
    StockType: "",
    Status: "",
    LastUpdateDate: "",
    WebSite: "",
    JobsWeb: ""
  },
  boardMember:[],
  boardMemberLoading: false,
  manager: [],
  managerLoading: false,
  branchCompanyInfo: [],
  branchCompanyInfoLoading: false,
  registeredOperationItems: [],
  registeredOperationItemsLoading: false,
  factoryTaxInfo: [],
  factoryTaxInfoLoading: false,
  companyInvestBackTW: [],
  companyInvestBackTWLoading: false,
  ForeignTrades4CodeProduct: [],
  ForeignTrades4CodeProductLoading: false,
  basicLoading: false,
  basicInfoError: "",
  patentInfo:[],
  patentInfoLoading:false,
  cardControl: {},
  cardControlLoading: false,
  governmentPlansInfo: [],
  governmentPlansInfoLoading: false,
  newsInfo: [],
  newsInfoLoading: false,
  newsTags: [],
  newsTagsLoading: false,
  awardInfo: [],
  awardInfoLoading: false,
  MITMarkInfo: [],
  MITMarkInfoLoading: false,
  associationCompany: {
    associationCompanyLoading: false,
    subCompanyList: [],
    relatedCompanyList: [],
  },
  serviceHistoryInfo: {
    data:[],
    status: 200
  },
  serviceHistoryInfoLoading: false,
  historyTradeInfo:[],
  historyTradeInfoLoading:false,
  updateCompanyInfoData: {},
  updateCompanyInfoLoading: false,
  getUpdateCompanyInfoData: {},
  getUpdateCompanyInfoLoading: false,
  getVerifiedCodeData: {},
  getVerifiedCodeLoading: false,
  startInitUpdateCompanyStateLoading: false,
  getForeignTradesCompareData: [],
  getForeignTradesCompareDataLoading: false,
  getGovProBiddingData: [],
  getGovProBiddingDataLoading: false,
  getVerdictData: [],
  getVerdictDataLoading: false,
  getTrademarkData: [],
  getTrademarkDataLoading: false,
  getBusinessInfoData: [],
  getBusinessInfoDataLoading: false,
  uploadVerifying: "",
  uploadVerifyingLoading: false,
  error: "",
  governmentPlansInfoSubsidy: [],
  governmentPlansInfoSubsidyLoading: false,
  getNegativeData: [],
  getNegativeDataLoading: false,
  getEnterpriseQualifiedData: [],
  getEnterpriseQualifiedLoading: false,
};

export const startBasicInfo = (state) => {
  return {
    ...state,
    basicLoading: true,
  };
};

export const getBasicInfoSuccess = (state, action) => {
  return {
    ...state,
    basicLoading: false,
    basicInfo: action.data,
  };
};

export const getBasicInfoFail = (state, action) => {
  return {
    ...state,
    basicLoading: false,
    basicInfoError: action.error,
  };
};

export const startGetCardControl = (state) => {
  return {
    ...state,
    cardControlLoading: true,
  };
};

export const getCardControlSuccess = (state, action) => {
  return {
    ...state,
    cardControlLoading: false,
    cardControl: action.data,
  };
};

export const getCardControlFail = (state, action) => {
  return {
    ...state,
    cardControlLoading: false,
    error: action.error,
  };
};

export const startUpdateCardControl = (state) => {
  return {
    ...state,
    cardControlLoading: true,
  };
};

export const UpdateCardControlSuccess = (state, action) => {
  return {
    ...state,
    cardControlLoading: false,
    cardControl: action.data,
  };
};

export const UpdateCardControlFail = (state, action) => {
  return {
    ...state,
    cardControlLoading: false,
    error: action.error,
  };
};

export const startGetCompanyAssociationData = (state, action) => {
  return {
    ...state,
    associationCompany: {
      ...state.associationCompany,
      associationCompanyLoading: true,
    },
  };
};

export const getCompanyAssociationDataSuccess = (state, action) => {
  const { data } = action;
  return {
    ...state,
    associationCompany: {
      ...state.associationCompany,
      subCompanyList: data.SubComapny,
      relatedCompanyList: data.RelatedComapny,
      associationCompanyLoading: false,
    },
  };
};

export const getCompanyAssociationDataFail = (state, action) => {
  return {
    associationCompany: {
      ...state.associationCompany,
      associationCompanyLoading: false,
    },
    err: action.err,
  };
};

export const startGetUpdateCompanyInfo = (state) => {
  return {
    ...state,
    getUpdateCompanyInfoLoading: true,
  };
};

export const getUpdateCompanyInfoSuccess = (state, action) => {
  return {
    ...state,
    getUpdateCompanyInfoLoading: false,
    getUpdateCompanyInfoData: action.data,
  };
};

export const getUpdateCompanyInfoFail = (state, action) => {
  return {
    ...state,
    getUpdateCompanyInfoLoading: false,
    error: action.err,
  };
};

// 產業競爭力發展中心飛雷神
export const startGetEnterpriseQualified = (state) => {
  return {
    ...state,
    getEnterpriseQualifiedLoading: true,
  };
};

export const getEnterpriseQualifiedSuccess = (state, action) => {
  
  action.data = [...action.data].sort((a, b) => {
    if (a.SubTitle > b.SubTitle) return 1;
    if (a.SubTitle < b.SubTitle) return -1;
  });
  return {
    ...state,
    getEnterpriseQualifiedLoading: false,
    getEnterpriseQualifiedData: action.data,
  };
};

export const getEnterpriseQualifiedFail = (state, action) => {
  return {
    ...state,
    getEnterpriseQualifiedLoading: false,
    error: action.err,
  };
};

export const startGetCompanyAbstract = (state) => {
  return {
    ...state,
    getCompanyAbstractLoading: true,
  };
};

export const getCompanyAbstractSuccess = (state, action) => {
  return {
    ...state,
    getCompanyAbstractLoading: false,
    getCompanyAbstractData: action.data,
  };
};

export const getCompanyAbstractFail = (state, action) => {
  return {
    ...state,
    getCompanyAbstractLoading: false,
    error: action.err,
  };
};

export const startUpdateCompanyInfo = (state) => {
  return {
    ...state,
    updateCompanyInfoLoading: true,
  };
};

export const updateCompanyInfoSuccess = (state, action) => {
  return {
    ...state,
    updateCompanyInfoLoading: false,
    updateCompanyInfoData: {
      msg: action.data.description,
      statusCode: action.data.status,
    },
  };
};

export const updateCompanyInfoFail = (state, action) => {
  return {
    ...state,
    updateCompanyInfoLoading: false,
    error: action.err,
  };
};

export const startGetVerifiedCode = (state) => {
  return {
    ...state,
    getVerifiedCodeLoading: true,
  };
};

export const getVerifiedCodeSuccess = (state, action) => {
  return {
    ...state,
    getVerifiedCodeLoading: false,
    getVerifiedCodeData: {
      msg: action.data.description,
      statusCode: action.data.status,
    },
  };
};

export const getVerifiedCodeFail = (state, action) => {
  return {
    ...state,
    getVerifiedCodeLoading: false,
    error: action.err,
  };
};

export const startInitUpdateCompanyState = (state) => {
  return {
    ...state,
    startInitUpdateCompanyStateLoading: true,
  };
};

export const initUpdateCompanyStateSuccess = (state, action) => {
  return {
    ...state,
    startInitUpdateCompanyStateLoading: false,
    updateCompanyInfoData: {},
    updateCompanyInfoLoading: false,
    getUpdateCompanyInfoData: {},
    getUpdateCompanyInfoLoading: false,
    getVerifiedCodeData: {},
    getVerifiedCodeLoading: false,
    error: "",
  };
};

export const initUpdateCompanyStateFail = (state, action) => {
  return {
    ...state,
    startInitUpdateCompanyStateLoading: true,
    error: action.err,
  };
};

export const startGetForeignTradesCompare = (state) => {
  return {
    ...state,
    getForeignTradesCompareDataLoading: true,
  };
};

export const getForeignTradesCompareSuccess = (state, action) => {
  return {
    ...state,
    getForeignTradesCompareDataLoading: false,
    getForeignTradesCompareData: action.data,
  };
};

export const getForeignTradesCompareFail = (state, action) => {
  return {
    ...state,
    getForeignTradesCompareDataLoading: false,
    error: action.err,
  };
};

export const startGetGovProBidding = (state) => {
  return {
    ...state,
    getGovProBiddingDataLoading: true,
  };
};

export const getGovProBiddingSuccess = (state, action) => {
  return {
    ...state,
    getGovProBiddingData: action.data,
    getGovProBiddingDataLoading: false,
  };
};

export const getGovProBiddingFail = (state, action) => {
  return {
    ...state,
    error: action.err,
    getGovProBiddingDataLoading: false,
  };
};

// 智財權相關判決書
export const startGetVerdict = (state) => {
  return {
    ...state,
    getVerdictDataLoading: true,
  };
};

export const getVerdictSuccess = (state, action) => {
  return {
    ...state,
    getVerdictData: action.data,
    getVerdictDataLoading: false,
  };
};

export const getVerdictFail = (state, action) => {
  return {
    ...state,
    error: action.err,
    getVerdictDataLoading: false,
  };
};

// 智財權相關判決書
export const startGetTrademark = (state) => {
  return {
    ...state,
    getTrademarkDataLoading: true,
  };
};

export const getTrademarkSuccess = (state, action) => {
  return {
    ...state,
    getTrademarkData: action.data,
    getTrademarkDataLoading: false,
  };
};

export const getTrademarkFail = (state, action) => {
  return {
    ...state,
    error: action.err,
    getTrademarkDataLoading: false,
  };
};

export const startGetBusinessInfo = (state) => {
  return {
    ...state,
    getBusinessInfoDataLoading: true,
  };
};

export const getBusinessInfoSuccess = (state, action) => {
  return {
    ...state,
    getBusinessInfoDataLoading: false,
    getBusinessInfoData: action.data,
  };
};

export const getBusinessInfoFail = (state, action) => {
  return {
    ...state,
    getBusinessInfoDataLoading: false,
    error: action.err,
  };
};

export const startGetCompanyUploadVerifying = (state) => {
  return {
    ...state,
    uploadVerifyingLoading: true,
  };
};

export const getCompanyUploadVerifyingSuccess = (state, action) => {
  return {
    ...state,
    uploadVerifying: action.lastUploadTime,
    uploadVerifyingLoading: false,
  };
};

export const getCompanyUploadVerifyingFail = (state, action) => {
  return {
    ...state,
    uploadVerifyingLoading: false,
    error: action.err,
  };
};

export const startGetFactoryTaxInfo = (state) => {
  return {
    ...state,
    factoryTaxInfoLoading: true,
  };
};

export const getFactoryInfoTaxSuccess = (state, action) => {
  return {
    ...state,
    factoryTaxInfo: action.data.data, 
    factoryTaxInfoLoading: false
  };
};

export const getFactoryInfoTaxFail = (state,err) => {
  return {
    ...state,
    factoryTaxInfoLoading: false,
    err: err,
  };
};

export const startGetCompanyInvestBackTW = (state) => {
  return {
    ...state,
    companyInvestBackTWLoading: true,
  };
};

export const getCompanyInvestBackTWSuccess = (state, action) => {
  return {
    ...state,
    companyInvestBackTW: action.data.data, 
    companyInvestBackTWLoading: false
  };
};

export const getCompanyInvestBackTWFail = (state,err) => {
  return {
    ...state,
    companyInvestBackTWLoading: false,
    err: err,
  };
};

export const startGetForeignTrades4CodeProduct = (state) => {
  return {
    ...state,
    ForeignTrades4CodeProductLoading: true,
  };
};

export const getForeignTrades4CodeProductSuccess = (state, action) => {
  return {
    ...state,
    ForeignTrades4CodeProduct: action.data, 
    ForeignTrades4CodeProductLoading: false
  };
};

export const getForeignTrades4CodeProductFail = (state,err) => {
  return {
    ...state,
    ForeignTrades4CodeProductLoading: false,
    err: err,
  };
};


export const startGetBoardMemberInfo = (state) => {
  return {
    ...state,
    boardMemberLoading: true,
  };
};

export const getBoardMemberInfoSuccess = (state, action) => {
  return {
    ...state,
    boardMember: action.data, 
    boardMemberLoading: false
  };
};

export const getBoardMemberInfoFail = (state,err) => {
  return {
    ...state,
    boardMemberLoading: false,
    err: err,
  };
};

export const startGetRegisteredOperationItems = (state) => {
  return {
    ...state,
    registeredOperationItemsLoading: true,
  };
};

export const getRegisteredOperationItemsSuccess = (state, action) => {
  return {
    ...state,
    registeredOperationItems: action.data,
    registeredOperationItemsLoading: false,
  };
};

export const getRegisteredOperationItemsFail = (state, action) => {
  return {
    ...state,
    registeredOperationItemsLoading: false,
    err: action.err,
  };
};

export const startGetBranchCompanyInfo = (state) => {
  return {
    ...state,
    branchCompanyInfoLoading: true,
  };
};

export const getBranchCompanyInfoSuccess = (state, action) => {
  return {
    ...state,
    branchCompanyInfo: action.data,
    branchCompanyInfoLoading: false,
  };
};

export const getBranchCompanyInfoFail = (state, action) => {
  return {
    ...state,
    branchCompanyInfoLoading: false,
  };
};

export const startGetHistoryTradeInfo = (state) => {
  return {
    ...state,
    historyTradeInfoLoading: true,
  };
};

export const getHistoryTradeInfoSuccess = (state, action) => {
  return {
    ...state,
    historyTradeInfo: action.data,
    historyTradeInfoLoading: false,
  };
};

export const getHistoryTradeInfoFail = (state, action) => {
  return {
    ...state,
    historyTradeInfoLoading: false,
  };
};

export const startGetPatentInfo = (state) => {
  return {
    ...state,
    patentInfoLoading: true,
  };
};

export const getPatentInfoSuccess = (state, action) => {
  return {
    ...state,
    patentInfo: action.data,
    patentInfoLoading: false,
  };
};

export const getPatentInfoFail = (state, action) => {
  return {
    ...state,
    patentInfoLoading: false,
  };
};

export const startGetGovernmentPlansInfo = (state) => {
  return {
    ...state,
    governmentPlansInfoLoading: true,
  };
};

export const getGovernmentPlansInfoSuccess = (state, action) => {
  return {
    ...state,
    governmentPlansInfo: action.data,
    governmentPlansInfoLoading: false,
  };
};

export const getGovernmentPlansInfoFail = (state, action) => {
  return {
    ...state,
    governmentPlansInfoLoading: false,
  };
};

export const startGetAwardInfo = (state) => {
  return {
    ...state,
    awardInfoLoading: true,
  };
};

export const getAwardInfoSuccess = (state, action) => {
  return {
    ...state,
    awardInfo: action.data,
    awardInfoLoading: false,
  };
};

export const getAwardInfoFail = (state, action) => {
  return {
    ...state,
    awardInfoLoading: false,
  };
};

// MIT標章
export const startGetMITMarkInfo = (state) => {
  return {
    ...state,
    MITMarkInfoLoading: true,
  };
};

export const getMITMarkInfoSuccess = (state, action) => {
  return {
    ...state,
    MITMarkInfo: action.data,
    MITMarkInfoLoading: false,
  };
};

export const getMITMarkInfoFail = (state, action) => {
  return {
    ...state,
    MITMarkInfoLoading: false,
  };
};


export const startGetNewsTag = (state) => {
  return {
    ...state,
    newsTagsLoading: true,
  };
};

export const getNewsTagSuccess = (state, action) => {
  const updateNewsInfoDataTag = [];
  action.data.map((tagItem) => {
    updateNewsInfoDataTag.push({
      value: tagItem.Keywords,
      count: tagItem.TermCount,
    });
  });

  return {
    ...state,
    newsTags: updateNewsInfoDataTag,
    newsTagsLoading: false,
  };
};

export const getNewsTagFail = (state, action) => {
  return {
    ...state,
    newsTagsLoading: false,
  };
};

export const startGetNewsInfo = (state) => {
  return {
    ...state,
    newsInfoLoading: true,
  };
};

export const getNewsInfoSuccess = (state, action) => {


  return {
    ...state,
    newsInfo: action.data,
    newsInfoLoading: false,
  };
};

export const getNewsInfoFail = (state, action) => {
  return {
    ...state,
    newsInfoLoading: false,
  };
};

export const startGetServiceHistory = (state) => {
  return {
    ...state,
    serviceHistoryInfoLoading: true,
  };
};

export const getServiceHistorySuccess = (state, action) => {


  return {
    ...state,
    serviceHistoryInfo: action.data,
    serviceHistoryInfoLoading: false,
  };
};

export const getServiceHistoryFail = (state, action) => {
  return {
    ...state,
    serviceHistoryInfoLoading: false,
  };
};

//工業局補捐助資料
export const startGetGovernmentPlansInfoSubsidy = (state) => {
  return {
    ...state,
    governmentPlansInfoSubsidyLoading: true,
  };
};

export const getGovernmentPlansInfoSubsidySuccess = (state, action) => {
  return {
    ...state,
    governmentPlansInfoSubsidy: action.data,
    governmentPlansInfoSubsidyLoading: false,
  };
};

export const getGovernmentPlansInfoSubsidyFail = (state, action) => {
  return {
    ...state,
    governmentPlansInfoSubsidyLoading: false,
  };
};

//裁罰資料(負面訊息)
export const startGetNegative = (state) => {
  return {
    ...state,
    getNegativeDataLoading: true,
  };
};

export const getNegativeSuccess = (state, action) => {
  return {
    ...state,
    getNegativeData: action.data,
    getNegativeDataLoading: false,
  };
};

export const getNegativeFail = (state, action) => {
  return {
    ...state,
    error: action.err,
    getNegativeDataLoading: false,
  };
};
const startManagerInfo = (state) => {
  return {
    ...state,
    managerLoading: true,
  };
};

const getManagerInfoSuccess = (state, action) => {
  return {
    ...state,
    manager: action.data,
    managerLoading: false,
  };
};

const getManagerInfoFail = (state, action) => {
  return {
    ...state,
    managerLoading: false,
  };
};


export default (state = initState, action) => {
  switch (action.type) {
    case actionTypes.START_GET_CARDCONTROL:
      return startGetCardControl(state);
    case actionTypes.GET_CARDCONTROL_SUCCESS:
      return getCardControlSuccess(state, action);
    case actionTypes.GET_CARDCONTROL_FAIL:
      return getCardControlFail(state, action);
    case actionTypes.START_UPDATE_CARDCONTROL:
      return startUpdateCardControl(state);
    case actionTypes.UPDATE_CARDCONTROL_SUCCESS:
      return UpdateCardControlSuccess(state, action);
    case actionTypes.UPDATE_CARDCONTROL_FAIL:
      return UpdateCardControlFail(state, action);
    case actionTypes.START_GET_COMPANY_ASSOCIATION_DATA:
      return startGetCompanyAssociationData(state);
    case actionTypes.GET_COMPANY_ASSOCIATION_DATA_SUCCESS:
      return getCompanyAssociationDataSuccess(state, action);
    case actionTypes.GET_COMPANY_ASSOCIATION_DATA_FAIL:
      return getCompanyAssociationDataFail(state, action);
    case actionType.START_GET_UPDATE_COMPANY_INFO:
      return startGetUpdateCompanyInfo(state);
    case actionType.GET_UPDATE_COMPANY_INFO_SUCCESS:
      return getUpdateCompanyInfoSuccess(state, action);
    case actionType.GET_UPDATE_COMPANY_INFO_FAIL:
      return getUpdateCompanyInfoFail(state, action);
    // 產業競爭力發展中心飛雷神
    case actionType.START_GET_ENTERPRISE_QUALIFIED:
      return startGetEnterpriseQualified(state);
    case actionType.GET_ENTERPRISE_QUALIFIED_SUCCESS:
      return getEnterpriseQualifiedSuccess(state, action);
    case actionType.GET_ENTERPRISE_QUALIFIED_FAIL:
      return getEnterpriseQualifiedFail(state, action);

    case actionType.START_GET_COMPANY_ABSTRACT:
      return startGetCompanyAbstract(state);
    case actionType.GET_COMPANY_ABSTRACT_SUCCESS:
      return getCompanyAbstractSuccess(state, action);
    case actionType.GET_COMPANY_ABSTRACT_FAIL:
      return getCompanyAbstractFail(state, action);

    case actionType.START_UPDATE_COMPANY_INFO:
      return startUpdateCompanyInfo(state);
    case actionType.UPDATE_COMPANY_INFO_SUCCESS:
      return updateCompanyInfoSuccess(state, action);
    case actionType.UPDATE_COMPANY_INFO_FAIL:
      return updateCompanyInfoFail(state, action);
    case actionType.START_VERIFIED_CODE:
      return startGetVerifiedCode(state);
    case actionType.GET_VERIFIED_CODE_SUCCESS:
      return getVerifiedCodeSuccess(state, action);
    case actionType.GET_VERIFIED_CODE_FAIL:
      return getVerifiedCodeFail(state, action);
    case actionType.START_INIT_UPDATE_COMPANY_INFO_STATE:
      return startInitUpdateCompanyState(state);
    case actionType.INIT_UPDATE_COMPANY_INFO_STATE_SUCCESS:
      return initUpdateCompanyStateSuccess(state, action);
    case actionType.INIT_UPDATE_COMPANY_INFO_STATE_FAIL:
      return initUpdateCompanyStateFail(state, action);
    case actionType.START_GET_BASIC_INFO:
      return startBasicInfo(state);
    case actionType.GET_BASIC_INFO_SUCCESS:
      return getBasicInfoSuccess(state, action);
    case actionType.GET_BASIC_INFO_FAIL:
      return getBasicInfoFail(state, action);
    case actionType.START_GET_FOREIGN_TRADES_COMPARE:
      return startGetForeignTradesCompare(state);
    case actionType.GET_FOREIGN_TRADES_COMPARE_SUCCESS:
      return getForeignTradesCompareSuccess(state, action);
    case actionType.GET_FOREIGN_TRADES_COMPARE_FAIL:
      return getForeignTradesCompareFail(state, action);
    case actionType.START_GET_GOV_PRO_BIDDING:
      return startGetGovProBidding(state);
    case actionType.GET_GOV_PRO_BIDDING_SUCCESS:
      return getGovProBiddingSuccess(state, action);
    case actionType.GET_GOV_PRO_BIDDING_FAIL:
      return getGovProBiddingFail(state, action);
    // 智財權相關判決書
    case actionType.START_GET_VERDICT:
      return startGetVerdict(state);
    case actionType.GET_VERDICT_SUCCESS:
      return getVerdictSuccess(state, action);
    case actionType.GET_VERDICT_FAIL:
      return getVerdictFail(state, action);
    // 商標
    case actionType.START_GET_TRADEMARK:
      return startGetTrademark(state);
    case actionType.GET_TRADEMARK_SUCCESS:
      return getTrademarkSuccess(state, action);
    case actionType.GET_TRADEMARK_FAIL:
      return getTrademarkFail(state, action);
    case actionType.START_GET_BUSINESS_INFO:
      return startGetBusinessInfo(state);
    case actionType.GET_BUSINESS_INFO_SUCCESS:
      return getBusinessInfoSuccess(state, action);
    case actionType.GET_BUSINESS_INFO_FAIL:
      return getBusinessInfoFail(state, action);
    case actionType.START_GET_COMPANY_UPLOAD_VERIFYING:
      return startGetCompanyUploadVerifying(state);
    case actionType.GET_COMPANY_UPLOAD_VERIFYING_SUCCESS:
      return getCompanyUploadVerifyingSuccess(state, action);
    case actionType.GET_COMPANY_UPLOAD_VERIFYING_FAIL:
      return getCompanyUploadVerifyingFail(state, action);

    case actionType.START_GET_TAX_COMPANY_INFO:
      return startGetFactoryTaxInfo(state);
    case actionType.GET_TAX_COMPANY_INFO_SUCCESS:
      return getFactoryInfoTaxSuccess(state, action);
    case actionType.GET_TAX_COMPANY_INFO_FAIL:
      return getFactoryInfoTaxFail(state, action);

    case actionType.START_GET_COMPANY_INVEST_BACK_TW:
      return startGetCompanyInvestBackTW(state);
    case actionType.GET_COMPANY_INVEST_BACK_TW_SUCCESS:
      return getCompanyInvestBackTWSuccess(state, action);
    case actionType.GET_COMPANY_INVEST_BACK_TW_FAIL:
      return getCompanyInvestBackTWFail(state, action);
    
    case actionType.START_GET_FOREIGN_TRADE_4CODE_PRODUCT:
      return startGetForeignTrades4CodeProduct(state);
    case actionType.GET_FOREIGN_TRADE_4CODE_PRODUCT_SUCCESS:
      return getForeignTrades4CodeProductSuccess(state, action);
    case actionType.GET_FOREIGN_TRADE_4CODE_PRODUCT_FAIL:
      return getForeignTrades4CodeProductFail(state, action);

    case actionType.START_GET_BOARD_MEMBER:
      return startGetBoardMemberInfo(state);
    case actionType.GET_BOARD_MEMBER_SUCCESS:
      return getBoardMemberInfoSuccess(state, action);
    case actionType.GET_BOARD_MEMBER_FAIL:
      return getBoardMemberInfoFail(state, action);
    case actionType.START_GET_REGISTERED_OPERATION_ITEM:
      return startGetRegisteredOperationItems(state);
    case actionType.GET_REGISTERED_OPERATION_ITEM_SUCCESS:
      return getRegisteredOperationItemsSuccess(state, action);
    case actionType.GET_REGISTERED_OPERATION_ITEM_FAIL:
      return getRegisteredOperationItemsFail(state, action);      
    case actionType.START_GET_BRANCH_INFO:
      return startGetBranchCompanyInfo(state);
    case actionType.GET_BRANCH_INFO_SUCCESS:
      return getBranchCompanyInfoSuccess(state, action);
    case actionType.GET_BRANCH_INFO_FAIL:
      return getBranchCompanyInfoFail(state, action);   
    case actionType.START_GET_TRADE_INFO:
      return startGetHistoryTradeInfo(state);
    case actionType.GET_TRADE_INFO_SUCCESS:
      return getHistoryTradeInfoSuccess(state, action);
    case actionType.GET_TRADE_INFO_FAIL:
      return getHistoryTradeInfoFail(state, action);     
    case actionType.START_GET_PATENT_INFO:
      return startGetPatentInfo(state);
    case actionType.GET_PATENT_INFO_SUCCESS:
      return getPatentInfoSuccess(state, action);
    case actionType.GET_PATENT_INFO_FAIL:
      return getPatentInfoFail(state, action);          
    case actionType.START_GET_GOV_PLAN_INFO:
      return startGetGovernmentPlansInfo(state);
    case actionType.GET_GOV_PLAN_INFO_SUCCESS:
      return getGovernmentPlansInfoSuccess(state, action);
    case actionType.GET_GOV_PLAN_INFO_FAIL:
      return getGovernmentPlansInfoFail(state, action);     
    case actionType.START_GET_AWARD_INFO:
      return startGetAwardInfo(state);
    case actionType.GET_AWARD_INFO_SUCCESS:
      return getAwardInfoSuccess(state, action);
    case actionType.GET_AWARD_INFO_FAIL:
      return getAwardInfoFail(state, action);
    // MIT標章
    case actionType.START_GET_MIT_INFO:
      return startGetMITMarkInfo(state);
    case actionType.GET_MIT_INFO_SUCCESS:
      return getMITMarkInfoSuccess(state, action);
    case actionType.GET_MIT_INFO_FAIL:
      return getMITMarkInfoFail(state, action); 
    case actionType.START_GET_NEWS_CONTENT_INFO:
      return startGetNewsInfo(state);
    case actionType.GET_NEWS_CONTENT_INFO_SUCCESS:
      return getNewsInfoSuccess(state, action);
    case actionType.GET_NEWS_CONTENT_INFO_FAIL:
      return getNewsInfoFail(state, action);   
    case actionType.START_GET_NEWS_TAG_INFO:
      return startGetNewsTag(state);
    case actionType.GET_NEWS_TAG_INFO_SUCCESS:
      return getNewsTagSuccess(state, action);
    case actionType.GET_NEWS_TAG_INFO_FAIL:
      return getNewsTagFail(state, action);   
    case actionType.START_GET_SERVICE_HISTORY:
      return startGetServiceHistory(state);
    case actionType.GET_SERVICE_HISTORY_SUCCESS:
      return getServiceHistorySuccess(state, action);
    case actionType.GET_SERVICE_HISTORY_FAIL:
      return getServiceHistoryFail(state, action);  
    case actionType.START_GET_MANAGER_INFO:
      return startManagerInfo(state);
    case actionType.GET_MANAGER_INFO_SUCCESS:
      return getManagerInfoSuccess(state, action);
    case actionType.GET_MANAGER_INFO_FAIL:
      return getManagerInfoFail(state, action);
    //工業局補捐(助)資料
    case actionType.START_GET_GOV_PLAN_INFO_SUBSIDY:
      return startGetGovernmentPlansInfoSubsidy(state);
    case actionType.GET_GOV_PLAN_INFO_SUBSIDY_SUCCESS:
      return getGovernmentPlansInfoSubsidySuccess(state, action);
    case actionType.GET_GOV_PLAN_INFO_SUBSIDY_FAIL:
      return getGovernmentPlansInfoSubsidyFail(state, action);
    //裁罰資料(負面訊息)
    case actionType.START_GET_NEGATIVE:
      return startGetNegative(state);
    case actionType.GET_NEGATIVE_SUCCESS:
      return getNegativeSuccess(state, action);
    case actionType.GET_NEGATIVE_FAIL:
      return getNegativeFail(state, action);        
    default:
      return state;
  }
};