import React, { useState, useEffect } from 'react'

import './styles.scss'


const InBoxTab = props => {
    const { data, dataOrderHandler, filterContainer, top } = props

    const [ tabWidth, setTabWidth ] = useState()
    const [ tabData, setTabData] = useState(data)



    useEffect(() => {
        setTabWidth((100/ tabData.length)+10)
    }, [])
    
    
    const switchActiveHandler = (key) => {
        const updateData = []
        
        tabData.map(item => {
            if(item.key === key){
                updateData.push({
                    ...item,
                    isActive:true
                })
            }else{
                updateData.push({
                    ...item,
                    isActive:false
                })
            }
        })
        setTabData(updateData)
        
        //給上層的 component - mapListContainer 值去整理 data 
        dataOrderHandler(key)
    }


    return(
        <div className='in-box-tabs' style={{top: top ? top : '0'}}>
            {tabData.map((tab, index )=>{

                let leftParam
                if(tab.isActive){
                    if(index === 0){
                        leftParam = 0
                    }else if(index === 1){
                        leftParam = -15
                    }else if(index === 2 ){
                        leftParam = -30
                    }
                }else{
                    if (index === 0) {
                        leftParam = 0
                    } else if (index === 1) {
                        leftParam = -8
                    } else if (index === 2) {
                        leftParam = -10
                    }
                }


                return <button 
                key={tab.key}
                style={filterContainer ? {
                        width: '150px',
                        top: '0',
                        left: 0,
                        zIndex: tab.isActive ? 10000 : index === 0 ? 450 : 500 - index,
                        backgroundColor: tab.isActive ? '#ECECEC' : '#FEF9F9',
                        color: tab.isActive ? '#000000' : '#666666',
                        fontWeight: tab.isActive ? 600 : 400,
                        padding: '3% 5%',

                    } :{
                    // width:`${tabWidth}%`
                    width:'150px',
                    top:'0',
                    left: 0,
                    zIndex: tab.isActive ? 10000 : index === 0 ? 450 : 500 - index,
                    backgroundColor: tab.isActive ? '#ECECEC' : '#FEF9F9',
                    color: tab.isActive ? '#000000' : '#666666',
                    fontWeight: tab.isActive ? 600 : 400,
                    boxShadow: '0px - 10px 10px 5px #FFFFFF'

                }}
                className={tab.key}
                role="tab"
                aria-selected={tab.isActive}
                onClick={() => switchActiveHandler(tab.key)}
                >{tab.item}</button>
            })}
        </div>
    )
}

export default InBoxTab