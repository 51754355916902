import React from "react";
import styled from "styled-components";
import { Popup, Marker } from "react-leaflet";

import { thousandDivider } from "../../utility/common";
import * as theme from "../../assets/theme"

const CustomPopUp = ({
  latitude, 
  longitude,
  address,
  amount,
  business_no,
  company_name,
  county,
  ctrl_id,
  phone,
}) => {
  const markerRef = React.useRef(null);
  const popUpRef = React.useRef(null);
  const url = window.location.origin;

  const handleOpenFactoryPage = () => {
    const url = window.location.origin;
    window.open(`${url}/factory_info/basic_info/${business_no}`);
  };

  // const openDetailCard = (uid) => {
  //   toggleDetailCard();
  // };

  // React.useEffect(() => {
  //   updateMarkersRef(Uid, markerRef);
  // }, [markerRef]);

  // React.useEffect(() => {
  //   if (popUpRef) savePopUpRef(Uid, popUpRef);
  // }, [popUpRef]);

  return (
    <Marker
      position={[latitude, longitude]}
      // onClick={() => showedCompanyHandler(Uid)}
    >
      <PopUps autoPan={false}>
        <PopUpsWrapper>
          <PopUpsRow>
            <PopUpsRowTitle>公司名稱</PopUpsRowTitle>
            <PopUpsRowText>{company_name}</PopUpsRowText>
          </PopUpsRow>
          <PopUpsRow>
            <PopUpsRowTitle>聯絡電話</PopUpsRowTitle>
            <PopUpsRowText>{phone}</PopUpsRowText>
          </PopUpsRow>
          <PopUpsRow>
            <PopUpsRowTitle>月處理總量</PopUpsRowTitle>
            <PopUpsRowText>{thousandDivider(amount)}{" "}公噸</PopUpsRowText>
          </PopUpsRow>
          <PopUpsRowsFlexEnd>
            <PopUpsRowMoreText onClick={handleOpenFactoryPage}>公司資訊</PopUpsRowMoreText>
          </PopUpsRowsFlexEnd>
        </PopUpsWrapper>
      </PopUps>
    </Marker>
  );
};

const PopUps = styled(Popup)`
    .leaflet-popup-pane {
      .leaflet-popup {
        .leaflet-popup-content-wrapper {
          .leaflet-popup-content {
 
          }
        }
      }
    }
  }
`

const PopUpsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow-wrap: break-word;
  left: -10px;
  padding: 10px;


  @media(max-width: 550px) {
    width: 90% !important
  } 
`

const PopUpsRow = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 5px 0px 1px 0px;
  margin: 5px 0px;
`

const PopUpsRowTitle = styled.h4`
  font-size: 1rem;
  font-weight: 600;
  overflow-wrap: break-word;
  width: 100px;
`
const PopUpsRowText = styled.p`
  font-size: 1rem;
  margin: 0px !important;
`

const PopUpsRowsFlexEnd = styled(PopUpsRow)`
  justify-content: flex-end;
`

const PopUpsRowMoreText = styled(PopUpsRowText)`
  cursor: pointer;
  font-size: 0.5rem;
  color: ${theme.linkBlue};
  transition: 0.2s;
  transform: translateX(-10px);

  &:hover {
    transition: 0.2s;
    color: ${theme.linkHoveredBlue};
  }
`

export default CustomPopUp;
