import * as actionTypes from "../actionTypes/custom_industry_dashboard";

import {
  createDashboard,
  getDashboardList,
  getDashboardCond,
  updateUserDashboardName,
  uploadDashboardCond,
  copyDashboard,
  deleteDashboard,
  // getALLFactoryIndustry,
  getFilterFactoryIndustry,
  getCountryList,
  // getCountryTradeProduct,
  getTradeProduct,
} from "../../utility/custom_industry_dashboard_api";

//新增儀表板
const startCreateDashboard = () => {
  return {
    type: actionTypes.START_CREATE_DASHBOARD,
  };
};

const createDashboardSuccess = (data) => {
  return {
    type: actionTypes.CREATE_DASHBOARD_SUCCESS,
    data: data,
  };
};

const createDashboardFail = (err) => {
  return {
    type: actionTypes.CREATE_DASHBOARD_FAIL,
    err: err,
  };
};

export const handleCreateDashboardList = (data, year, description) => {
  return (dispatch) => {
    dispatch(startCreateDashboard());
    createDashboard(data, year, description)
      .then(async (res) => {
        if (res.data.status === "success") {
          dispatch(createDashboardSuccess(res.data.data));
        } else {
          dispatch(createDashboardFail("新增儀表板發生問題。"));
        }
      })
      .catch((err) => {
        dispatch(createDashboardFail(err));
      });
  };
};

//取得儀表板清單
const startGetDashboardList = () => {
  return {
    type: actionTypes.START_GET_DASHBOARD_LIST,
  };
};

const getDashboardListSuccess = (data) => {
  return {
    type: actionTypes.GET_DASHBOARD_LIST_SUCCESS,
    data: data,
  };
};

const getDashboardListFail = (err) => {
  return {
    type: actionTypes.GET_DASHBOARD_LIST_FAIL,
    err: err,
  };
};

export const handleGetDashboardList = () => {
  return (dispatch) => {
    dispatch(startGetDashboardList());
    getDashboardList()
      .then((res) => {
        if (res.data.status === "success") {
          dispatch(getDashboardListSuccess(res.data.data));
        } else {
          dispatch(getDashboardListFail("取得儀表板清單發生問題。"));
        }
      })
      .catch((err) => {
        dispatch(getDashboardListFail(err));
      });
  };
};

//取得儀表板當前條件
const startGetDashboardCond = () => {
  return {
    type: actionTypes.START_GET_DASHBOARD_COND,
  };
};

const getDashboardCondSuccess = (data) => {
  return {
    type: actionTypes.GET_DASHBOARD_COND_SUCCESS,
    data: data,
  };
};

const GetDashboardCondFail = (err) => {
  return {
    type: actionTypes.GET_DASHBOARD_COND_FAIL,
    err: err,
  };
};

export const handleGetDashboardCond = (board_id) => {
  return (dispatch) => {
    dispatch(startGetDashboardCond());
    getDashboardCond(board_id)
      .then((res) => {
        if (res.data.status === "success") {
          dispatch(getDashboardCondSuccess(res.data.data));
        } else {
          dispatch(GetDashboardCondFail("取得儀表板當前條件發生問題。"));
        }
      })
      .catch((err) => {
        dispatch(GetDashboardCondFail(err));
      });
  };
};

//更新儀表板名稱
const startUpdateUserDashboardName = () => {
  return {
    type: actionTypes.START_UPDATE_USER_DASHBOARD_NAME,
  };
};

const updateUserDashboardNameSuccess = (data) => {
  return {
    type: actionTypes.UPDATE_USER_DASHBOARD_NAME_SUCCESS,
    data: data,
  };
};

const UpdateUserDashboardNameFail = (err) => {
  return {
    type: actionTypes.UPDATE_USER_DASHBOARD_NAME_FAIL,
    err: err,
  };
};

export const handleUpdateUserDashboardName = (board_id, board_name) => {
  return (dispatch) => {
    dispatch(startUpdateUserDashboardName());
    return new Promise((resolve, reject) => {
      updateUserDashboardName(board_id, board_name)
        .then(async (res) => {
          if (res.data.status === "success") {
            dispatch(updateUserDashboardNameSuccess(res.data.data));
            resolve(res.data)
          } else {
            reject(res.data)
            dispatch(UpdateUserDashboardNameFail("更新儀表板名稱發生問題。"));
          }
        })
        .catch((err) => {
          reject(err)
          dispatch(UpdateUserDashboardNameFail(err));
        });
    })
  };
};

//更新儀表板條件
const startUploadDashboardCond = () => {
  return {
    type: actionTypes.START_UPLOAD_DASHBOARD_COND,
  };
};

const UploadDashboardCondSuccess = (data) => {
  return {
    type: actionTypes.UPLOAD_DASHBOARD_COND_SUCCESS,
    data: data,
  };
};

const UploadDashboardCondFail = (err) => {
  return {
    type: actionTypes.UPLOAD_DASHBOARD_COND_FAIL,
    err: err,
  };
};

export const handleUploadDashboardCond = (board_id, board_name, description, industry_list, export_4_code, import_4_code) => {
  return (dispatch) => {
    dispatch(startUploadDashboardCond());
    uploadDashboardCond(board_id, board_name, description, industry_list, export_4_code, import_4_code)
      .then((res) => {
        if (res.data.status === "success") {
          dispatch(UploadDashboardCondSuccess(res.data.data));
        } else {
          dispatch(UploadDashboardCondFail("更新儀表板條件發生問題。"));
        }
      })
      .catch((err) => {
        dispatch(UploadDashboardCondFail(err));
      });
  };
};

//刪除儀表板
const startDeleteDashboard = () => {
  return {
    type: actionTypes.START_DELETE_DASHBOARD,
  };
};

const deleteDashboardSuccess = (data) => {
  return {
    type: actionTypes.DELETE_DASHBOARD_SUCCESS,
    data: data,
  };
};

const deleteDashboardFail = (err) => {
  return {
    type: actionTypes.DELETE_DASHBOARD_FAIL,
    err: err,
  };
};

export const handleDeleteDashboard = (board_id) => {
  return (dispatch) => {
    dispatch(startDeleteDashboard());
    deleteDashboard(board_id)
      .then(async (res) => {
        if (res.data.status === "success") {
          dispatch(deleteDashboardSuccess());
          dispatch(handleGetDashboardList());
        } else {
          dispatch(deleteDashboardFail("刪除儀表板發生問題。"));
        }
      })
      .catch((err) => {
        dispatch(deleteDashboardFail(err));
      });
  };
};

//複製儀表板
const startCopyDashboard = () => {
  return {
    type: actionTypes.START_COPY_DASHBOARD,
  };
};

const copyDashboardSuccess = (data) => {
  return {
    type: actionTypes.COPY_DASHBOARD_SUCCESS,
    data: data,
  };
};

const copyDashboardFail = (err) => {
  return {
    type: actionTypes.COPY_DASHBOARD_FAIL,
    err: err,
  };
};

export const handleCopyDashboard = (board_id) => {
  return (dispatch) => {
    dispatch(startCopyDashboard());
    return new Promise((resolve, reject) => {
      copyDashboard(board_id)
        .then(async (res) => {
          if (res.data.status === "success") {
            dispatch(copyDashboardSuccess(res.data.data));
            await handleGetDashboardList();
            resolve(res.data)
          } else {
            dispatch(
              copyDashboardFail(
                "複製儀表板時發生問題。"
              )
            );
            reject(res.data)
          }
        })
        .catch((err) => {
          dispatch(copyDashboardFail(err));
          reject(err)
        });
    })
  };
};

//取得工廠四碼
const startGetALLFactoryIndustry = () => {
  return {
    type: actionTypes.START_GET_ALL_FACTORY_INDUSTRY,
  };
};

const getALLFactoryIndustrySuccess = (data) => {
  return {
    type: actionTypes.GET_ALL_FACTORY_INDUSTRY_SUCCESS,
    data: data,
  };
};

const GetALLFactoryIndustryFail = (err) => {
  return {
    type: actionTypes.GET_ALL_FACTORY_INDUSTRY_FAIL,
    err: err,
  };
};

export const handleGetALLFactoryIndustry = () => {
  return (dispatch) => {
    dispatch(startGetALLFactoryIndustry());
    getFilterFactoryIndustry()
      .then((res) => {
        const newRes = res.data.map(function(i) {
          return {
            Code: i.Key,
            Name: i.Value,
          } 
        });
        if (res.status === 200) {
          dispatch(getALLFactoryIndustrySuccess(newRes));
        } else {
          dispatch(GetALLFactoryIndustryFail("取得工廠四碼發生問題。"));
        }
      })
      .catch((err) => {
        dispatch(GetALLFactoryIndustryFail(err));
      });
  };
};

//國別清單
const startGetCountryList = () => {
  return {
    type: actionTypes.START_GET_COUNTRY_LIST,
  };
};

const getCountryListSuccess = (data) => {
  return {
    type: actionTypes.GET_COUNTRY_LIST_SUCCESS,
    data: data,
  };
};

const getCountryListFail = (err) => {
  return {
    type: actionTypes.GET_COUNTRY_LIST_FAIL,
    err: err,
  };
};

export const handleGetCountryList = () => {
  return (dispatch) => {
    dispatch(startGetCountryList());
    getCountryList()
      .then((res) => {
        if (res.status === 200) {
          dispatch(getCountryListSuccess(res.data));
        } else {
          dispatch(getCountryListFail("取得國別清單發生問題。"));
        }
      })
      .catch((err) => {
        dispatch(getCountryListFail(err));
      });
  };
};

//進口清單排名 (參數：國別、年分)
const startGetImportCountryTradeProduct = () => {
  return {
    type: actionTypes.START_GET_IMPORT_COUNTRY_TRADE_PRODUCT,
  };
};

const getImportCountryTradeProductSuccess = (data) => {
  return {
    type: actionTypes.GET_IMPORT_COUNTRY_TRADE_PRODUCT_SUCCESS,
    data: data,
  };
};

const getImportCountryTradeProductFail = (err) => {
  return {
    type: actionTypes.GET_IMPORT_COUNTRY_TRADE_PRODUCT_FAIL,
    err: err,
  };
};

export const handleGetImportCountryTradeProduct = (country, year) => {
  return (dispatch) => {
    dispatch(startGetImportCountryTradeProduct());
    getTradeProduct()
      .then((res) => {
        if (res.status === 200) {
          dispatch(getImportCountryTradeProductSuccess(res.data));
        } else {
          dispatch(getImportCountryTradeProductFail("取得進口清單排名 (參數：國別、年分)發生問題。"));
        }
      })
      .catch((err) => {
        dispatch(getImportCountryTradeProductFail(err));
      });
  };
};

//出口清單排名 (參數：國別、年分)
const startGetExportCountryTradeProduct = () => {
  return {
    type: actionTypes.START_GET_EXPORT_COUNTRY_TRADE_PRODUCT,
  };
};

const getExportCountryTradeProductSuccess = (data) => {
  return {
    type: actionTypes.GET_EXPORT_COUNTRY_TRADE_PRODUCT_SUCCESS,
    data: data,
  };
};

const getExportCountryTradeProductFail = (err) => {
  return {
    type: actionTypes.GET_EXPORT_COUNTRY_TRADE_PRODUCT_FAIL,
    err: err,
  };
};

export const handleGetExportCountryTradeProduct = (country, year) => {
  return (dispatch) => {
    dispatch(startGetExportCountryTradeProduct());
    getTradeProduct()
      .then((res) => {
        if (res.status === 200) {
          dispatch(getExportCountryTradeProductSuccess(res.data));
        } else {
          dispatch(getExportCountryTradeProductFail("取得進出口清單排名 (參數：國別、年分)發生問題。"));
        }
      })
      .catch((err) => {
        dispatch(getExportCountryTradeProductFail(err));
      });
  };
};