import axios from 'axios';
import { baseUrl, loginbaseUrl } from "../constants/endpoints";


export const handleFetchWeatherBox = (park_id) => {
    return axios({
      proxy: true,
      method: "get",
      url: `${baseUrl}/api/InduParkSingle/GetSingleParkAirboxID/${park_id}`,
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};

export const handleFetchAirQuality = (weather_box_id) => {
  let today_string;
  let three_days_before_string;
  const date = new Date();
  const three_day_ago = new Date(date);
  three_day_ago.setDate(three_day_ago.getDate()-3)

  today_string = `${date.getFullYear()}-${date.getMonth()+1}-${date.getDate()}`;
  three_days_before_string = `${three_day_ago.getFullYear()}-${three_day_ago.getMonth()+1}-${three_day_ago.getDate()}`
  

  return axios({
    method: "get",
    url: `${baseUrl}/api/InduParkSingle/GetAirboxDetail/${weather_box_id}/${three_days_before_string}/${today_string}`,
  })
  .then((res) => {
    return res.data;
  })
  .catch((err) => {
    return err;
  });
} 

export const handleFetchWeatherInfo = (location) => {
  return axios({
    method: "get",
    url: `https://opendata.cwa.gov.tw/api/v1/rest/datastore/F-C0032-001?Authorization=CWB-25082BA2-4172-499D-8B8D-302ABE052FD4&locationName=${location}`,
  })
  .then((res) => {
    return res;
  })
  .catch((err) => {
    return err;
  });
} 


// export const fetchWastedPollutionDetailData = (ctrl_id) => {
//   return axios({
//     proxy: true,
//     method: "get",
//     url: `${baseUrl}/api/Waste/GetCleanPollutions/${ctrl_id}`,
//   })
//   .then((res) => {
//     return res;
//   })
//   .catch((err) => {
//     return err;
//   });
// } 
    