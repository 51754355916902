import React from "react";
import Icon from "@material-ui/core/Icon";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";

import TypeTable from "./TypeTable";
import ImportAndExportTable from "./ImportAndExportTable";
import GovPlanAndAwardTable from "./GovPlanAndAwardTable";
import PublciStockTable from "./PublciStockTable";
import PatentTable from "./PatentTable";
import TopTenGovPlan from "./TopTenGovPlan";
import GoogleTrends from "../WordCloud/GoogleTrends";
import Spinner from "../Spinner/Spinner";
import {
  getTypeTwoListDataHandler,
  getTypeThreeListDataHandler,
  getTypeFourListDataHandler,
  getImportListData,
  getExportListData,
  getAwardListData,
  getGovPlanListData,
  getPublicStockListDataHandler,
  getPatentListDataHandler,
  getTopTenGovPlanListDataHandler,
  getTopFiveWasteDisposalDataListHandler,
} from "../../store/actions/areaDashbroad";

import {
  getSectionTwoCodePercentageListHandler,
  getSectionThreeCodePercentageListHandler,
  getSectionFourCodePercentageListHandler,
  getSectionImportListHandler,
  getSectionExportListHandler,
  getSectionPatentInfoListHandler,
  getSectionGovPlanListHandler,
  getSectionParkDetailInfoListHandler,
  getSectionAwardListHandler,
  getWasteDisposalListHandler,
  getSectionStockListHandler,
} from "../../store/actions/parkDashboard_beta";

import "./styles.scss";

import * as theme from "../../assets/theme";
import { IconButton } from "@mui/material";

export default (props) => {
  const dispatch = useDispatch();
  const {
    modalType,
    referenceCode,
    cityId,
    parkId,
    handleClose,
    title,
    wasteNumber,
    persistModalOpenHandler,
  } = props;
  const {
    typeTwoList,
    typeThreeList,
    typeFourList,
    importList,
    exportList,
    govPlanList,
    awardList,
    publicStockList,
    patentList,
    topTenGovPlan,
    topFiveWasteDisposalList,
  } = useSelector((state) => state.areaDashbroad);

  const {
    sectionTwoCodePercentageList,
    sectionThreeCodePercentageList,
    sectionFourCodePercentageList,
    sectionImportList,
    sectionExportList,
    sectionPatentInfoList,
    sectionGovPlanList,
    sectionParkDetailInfoList,
    sectionAwardList,
    wasteDisposalList,
    sectionStockList,
  } = useSelector((state) => state.parkDashboard_beta);

  const [state, setState] = React.useState({
    title: "",
    content: "",
    listContent: "",
    header: "",
    loading: false,
    data: [],
    page: 0,
    rowsPerPage: 5,
  });
  const url = window.location.origin;

  const openNewTabs = (link) => window.open(link, "_blank");

  React.useEffect(() => {
    if (modalType) {
      switch (modalType) {
        // ------園區------
        case "SectionTypeTwo":
          dispatch(
            getSectionTwoCodePercentageListHandler(parkId, referenceCode)
          );
          break;
        case "SectionTypeThree":
          dispatch(
            getSectionThreeCodePercentageListHandler(parkId, referenceCode)
          );
          break;
        case "SectionTypeFour":
          dispatch(
            getSectionFourCodePercentageListHandler(parkId, referenceCode)
          );
          break;
        case "SectionImport":
          dispatch(getSectionImportListHandler(parkId, referenceCode));
          break;
        case "SectionExport":
          dispatch(getSectionExportListHandler(parkId, referenceCode));
          break;
        case "SectionWasteDisposal":
          dispatch(getWasteDisposalListHandler(wasteNumber, parkId));
          break;
        case "SectionPatent":
          dispatch(getSectionPatentInfoListHandler(referenceCode, 3));
          break;
        case "SectionGovPlan":
          dispatch(getSectionGovPlanListHandler(referenceCode));
          break;
        case "sectionGovPlanPercent":
          dispatch(getSectionParkDetailInfoListHandler(parkId, referenceCode));
          break;
        case "SectionAward":
          dispatch(getSectionAwardListHandler(parkId, referenceCode));
          break;
        case "SectionStock":
          dispatch(getSectionStockListHandler(parkId, referenceCode));
          break;

        // ------分區------
        case "typeTwo":
          dispatch(getTypeTwoListDataHandler(cityId, referenceCode));
          break;
        case "typeThree":
          dispatch(getTypeThreeListDataHandler(cityId, referenceCode));
          break;
        case "typeFour":
          dispatch(getTypeFourListDataHandler(cityId, referenceCode));
          break;
        case "export":
          dispatch(getExportListData(cityId, referenceCode));
          break;
        case "import":
          dispatch(getImportListData(cityId, referenceCode));
          break;
        case "govPlan":
          dispatch(getGovPlanListData(cityId, referenceCode));
          break;
        case "award":
          dispatch(getAwardListData(cityId, referenceCode));
          break;
        case "patent":
          dispatch(getPatentListDataHandler(referenceCode, 3));
          break;
        case "publicStock":
          dispatch(getPublicStockListDataHandler(cityId, referenceCode));
          break;
        case "topTenGovPlan":
          dispatch(getTopTenGovPlanListDataHandler(referenceCode));
          break;
        case "topFiveWasteDisposal":
          dispatch(getTopFiveWasteDisposalDataListHandler(wasteNumber, cityId));
          break;
        default:
          return "取得 modal 時發生問題。";
      }
    }
  }, [modalType, referenceCode, cityId, parkId]);

  React.useEffect(() => {
    const { typeTwoListDataLoading } = typeTwoList;
    const { typeThreeListDataLoading } = typeThreeList;
    const { typeFourListDataLoading } = typeFourList;
    const { importListDataLoading } = importList;
    const { exportListDataLoading } = exportList;
    const { awardListDataLoading } = awardList;
    const { govPlanListDataLoading } = govPlanList;
    const { patentListDataLoading } = patentList;
    const { publicStockListDataLoading } = publicStockList;
    const { topTenGovPlanListDataLoading } = topTenGovPlan;
    const { topFiveWasteDisposalListLoading } = topFiveWasteDisposalList;
    const { wasteDisposalListLoading } = wasteDisposalList;
    const { sectionPatentInfoListloading } = sectionPatentInfoList;
    const { sectionTwoCodePercentageListloading } =
      sectionTwoCodePercentageList;
    const { sectionThreeCodePercentageListloading } =
      sectionThreeCodePercentageList;
    const { sectionFourCodePercentageListloading } =
      sectionFourCodePercentageList;
    const { sectionImportListloading } = sectionImportList;
    const { sectionExportListloading } = sectionExportList;
    const { sectionGovPlanListloading } = sectionGovPlanList;
    const { sectionParkDetailInfoListloading } = sectionParkDetailInfoList;
    const { sectionAwardListloading } = sectionAwardList;
    const { sectionStockListloading } = sectionStockList;

    if (
      !typeTwoListDataLoading &&
      !typeThreeListDataLoading &&
      !typeFourListDataLoading &&
      !importListDataLoading &&
      !exportListDataLoading &&
      !awardListDataLoading &&
      !govPlanListDataLoading &&
      !patentListDataLoading &&
      !publicStockListDataLoading &&
      !topTenGovPlanListDataLoading &&
      !topFiveWasteDisposalListLoading &&
      !wasteDisposalListLoading &&
      !sectionPatentInfoListloading &&
      !sectionTwoCodePercentageListloading &&
      !sectionThreeCodePercentageListloading &&
      !sectionFourCodePercentageListloading &&
      !sectionImportListloading &&
      !sectionExportListloading &&
      !sectionGovPlanListloading &&
      !sectionParkDetailInfoListloading &&
      !sectionAwardListloading &&
      !sectionStockListloading
    ) {
      setState((state) => ({ ...state, loading: false }));
    } else {
      setState((state) => ({ ...state, loading: true }));
    }
  }, [
    typeTwoList.typeTwoListDataLoading,
    typeThreeList.typeThreeListDataLoading,
    typeFourList.typeFourListDataLoading,
    importList.importListDataLoading,
    exportList.exportListDataLoading,
    awardList.awardListDataLoading,
    govPlanList.govPlanListDataLoading,
    patentList.patentListDataLoading,
    publicStockList.publicStockListDataLoading,
    topTenGovPlan.topTenGovPlanListDataLoading,
    topFiveWasteDisposalList.topFiveWasteDisposalListLoading,
    wasteDisposalList.wasteDisposalListLoading,
    sectionPatentInfoList.sectionPatentInfoListloading,
    sectionTwoCodePercentageList.sectionTwoCodePercentageListloading,
    sectionThreeCodePercentageList.sectionThreeCodePercentageListloading,
    sectionFourCodePercentageList.sectionFourCodePercentageListloading,
    sectionImportList.sectionImportListloading,
    sectionExportList.sectionExportListloading,
    sectionGovPlanList.sectionGovPlanListloading,
    sectionParkDetailInfoList.sectionParkDetailInfoListloading,
    sectionAwardList.sectionAwardListloading,
    sectionStockList.sectionStockListloading,
  ]);
  React.useEffect(() => {
    persistModalOpenHandler(state.loading); //當資料在 load 的時候不要讓 modal 關掉
  }, [state]);

  return (
    <div className="dashboard-modal-content-container">
      {state.loading ? (
        <Spinner />
      ) : (
        <>
          <div className="dashboard-modal-content-container-head">
            {(modalType === "typeTwo" ||
              modalType === "typeThree" ||
              modalType === "SectionTypeTwo" ||
              modalType === "SectionTypeThree") && <h2 id="modal-title">{title}廠商資料</h2>}
            {(modalType === "typeFour" || modalType === "SectionTypeFour") && (
              <h2 id="modal-title">
                <button
                  onClick={() =>
                    openNewTabs(
                      `${url}/industry_index/${title.substring(0, 4)}`
                    )
                  }
                >
                  {title.substring(0, 4)}
                </button>
                {title.substring(4, title.length)}廠商資料
              </h2>
            )}
            {(modalType === "import" || modalType === "SectionImport") && (
              <h2 id="modal-title">{title}進口級距廠商資料</h2>
            )}
            {(modalType === "export" || modalType === "SectionExport") && (
              <h2 id="modal-title">{title}出口級距廠商資料</h2>
            )}
            {(modalType === "award" || modalType === "SectionAward") && (
              <h2 id="modal-title">{title}廠商資料</h2>
            )}
            {(modalType === "govPlan" ||
              modalType === "sectionGovPlanPercent") && (
              <h2 id="modal-title">{title}廠商資料</h2>
            )}
            {(modalType === "patent" || modalType === "SectionPatent") && (
              <h2 id="modal-title">
                <button
                  title="另開新視窗，前往廠商基本資料頁面"
                  onClick={() =>
                    openNewTabs(
                      `${url}/factory_info/basic_info/${referenceCode}`
                    )
                  }
                >
                  {title}
                </button>
                專利資料
              </h2>
            )}
            {(modalType === "topTenGovPlan" || modalType === "SectionGovPlan") && (
              <ClickableTitle
                title="另開新視窗"
                onClick={() =>
                  openNewTabs(
                    `${url}/factory_info/basic_info/${referenceCode}`
                  )
                }
              >
                <button>{title}</button>政府計畫資料
              </ClickableTitle>
            )}
            {(modalType === "publicStock" || modalType === "SectionStock") && (
              <h2 id="modal-title">{title}公司列表</h2>
            )}
            {(modalType === "topFiveWasteDisposal" ||
              modalType === "SectionWasteDisposal") && <h2 id="modal-title">{title}追溯</h2>}
            {modalType === "sectionNewsTag" && <h2 id="modal-title">{title} Google搜尋趨勢</h2>}
            <IconButton aria-label="關閉彈出視窗" sx={{ p: 0 }} onClick={handleClose}>
              <Icon>
                close
              </Icon>
            </IconButton>
          </div>

          {/* ------分區------ */}
          {modalType === "typeTwo" && (
            <TypeTable data={typeTwoList.typeTwoListData} />
          )}
          {modalType === "typeThree" && (
            <TypeTable data={typeThreeList.typeThreeListData} />
          )}
          {modalType === "typeFour" && (
            <TypeTable data={typeFourList.typeFourListData} />
          )}
          {modalType === "import" && (
            <ImportAndExportTable data={importList.importListData} />
          )}
          {modalType === "export" && (
            <ImportAndExportTable data={exportList.exportListData} />
          )}
          {modalType === "award" && (
            <GovPlanAndAwardTable data={awardList.awardListData} />
          )}
          {modalType === "govPlan" && (
            <GovPlanAndAwardTable data={govPlanList.govPlanListData} />
          )}
          {modalType === "patent" && (
            <PatentTable data={patentList.patentListData} />
          )}
          {modalType === "publicStock" && (
            <PublciStockTable data={publicStockList.publicStockListData} />
          )}
          {modalType === "topTenGovPlan" && (
            <TopTenGovPlan data={topTenGovPlan.topTenGovPlanListData} />
          )}
          {modalType === "topFiveWasteDisposal" && (
            <TypeTable
              data={topFiveWasteDisposalList.topFiveWasteDisposalDataList}
            />
          )}

          {/* ------園區------ */}

          {modalType === "SectionImport" && (
            <ImportAndExportTable
              data={sectionImportList.sectionImportListData}
            />
          )}
          {modalType === "SectionExport" && (
            <ImportAndExportTable
              data={sectionExportList.sectionExportListData}
            />
          )}

          {modalType === "SectionTypeTwo" && (
            <TypeTable
              data={
                sectionTwoCodePercentageList.sectionTwoCodePercentageListData
              }
            />
          )}
          {modalType === "SectionTypeThree" && (
            <TypeTable
              data={
                sectionThreeCodePercentageList.sectionThreeCodePercentageListData
              }
            />
          )}
          {modalType === "SectionTypeFour" && (
            <TypeTable
              data={
                sectionFourCodePercentageList.sectionFourCodePercentageListData
              }
            />
          )}
          {modalType === "SectionGovPlan" && (
            <TopTenGovPlan data={sectionGovPlanList.sectionGovPlanListData} />
          )}
          {modalType === "SectionWasteDisposal" && (
            <TypeTable data={wasteDisposalList.wasteDisposalListData} />
          )}
          {modalType === "SectionPatent" && (
            <PatentTable
              data={sectionPatentInfoList.sectionPatentInfoListData}
            />
          )}
          {modalType === "SectionAward" && (
            <GovPlanAndAwardTable
              data={sectionAwardList.sectionAwardListData}
            />
          )}
          {modalType === "sectionGovPlanPercent" && (
            <GovPlanAndAwardTable
              data={sectionParkDetailInfoList.sectionParkDetailInfoListData}
            />
          )}
          {modalType === "SectionStock" && (
            <PublciStockTable data={sectionStockList.sectionStockListData} />
          )}
          {modalType === "sectionNewsTag" && (
              <GoogleTrends keyword = {title}/>
          )}
        </>
      )}
    </div>
  );
};

const ClickableTitle = styled.h2`
  > button {
    text-decoration: underline;
    color: ${theme.linkBlue};
    transition: 2s;
    cursor: pointer;

    &:hover {
      transition: 2s;
      color: ${theme.linkHoveredBlue};
    }
  }
`;
