import * as actionTypes from "../actionTypes/smartWindow";
import { getSmartWindowtData } from "../../utility/smartWindowApi";

//智慧櫥窗
export const startGetSmartWindowtData = () => {
  return {
    type: actionTypes.START_GET_MANAGE_PARK_STAT_DATA,
  };
};

export const getSmartWindowtDataSuccess = (data) => {
  return {
    type: actionTypes.GET_MANAGE_PARK_STAT_DATA_SUCCESS,
    data: data,
  };
};

export const getSmartWindowtDataFail = (err) => {
  return {
    type: actionTypes.GET_MANAGE_PARK_STAT_DATA_FAIL,
    err: err,
  };
};

export const handleSmartWindowtData = () => {
  return (dispatch) => {
    dispatch(startGetSmartWindowtData());
    getSmartWindowtData()
      .then((res) => {
        if (res) {
          dispatch(getSmartWindowtDataSuccess(res));
        } else {
          dispatch(getSmartWindowtDataFail("取得智慧櫥窗時發生問題。"));
        }
      })
      .catch((err) => {
        dispatch(getSmartWindowtDataFail(err));
      });
  };
};
