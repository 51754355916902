import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import { useSelector } from "react-redux";

import { thousandDivider, stopTypeSwitcher } from "../../../utility/common";

import "./styles.scss";

const FactoryBasicInfoTable = (props) => {
  const { data, loading } = props;
  const { innerWidth } = useSelector((state) => state.app);
  const [state, setState] = React.useState({
    endIndex: 0,
    page: 0,
    rowsPerPage: 5,
    styleKey: {},
    styleValue: {},
  });
  const url = window.location.origin;

  const openNewTabs = (link) => window.open(link, "_blank");

  // const styleKey = innerWidth < 1024 ? {width:"40%", fontWeight: 600} : {width:"30%", fontWeight: 600};
  // const styleValue = innerWidth < 1024 ? {width:"60%", borderBottom: "none"} : {display: "flex"};

  React.useEffect(() => {
    if (innerWidth < 1024) {
      setState((state) => ({
        ...state,
        styleKey: { width: "40%", fontWeight: 600 },
        styleValue: { width: "60%", borderBottom: "none" },
      }));
    } else {
      setState((state) => ({
        ...state,
        styleKey: { width: "30%", fontWeight: 600 },
        styleValue: { display: "flex" },
      }));
    }
  }, [innerWidth]);

  return (
    <TableContainer className="table-container">
      {data.length > 0 ? 
      <Table className="table" style={{ tableLayout: "fixed" }}>
        <TableBody className="table-body">
          <React.Fragment>
            {data.map((taxType) => {
              return (
                <TableRow hover className="table-body-row" key={taxType.Code}>
                  <TableCell align="left" style={state.styleKey}>
                    <p>{taxType.Code}</p>
                  </TableCell>
                  <TableCell align="left" style={state.styleValue}>
                    <p>{taxType.IndustryName}</p>
                  </TableCell>
                </TableRow>
              );
            })}
          </React.Fragment>
        </TableBody>
      </Table>
      :  
      <Table>
        <TableBody className="table-body-no-data">
          <TableRow hover className="table-body-row">
            <TableCell align="center" className="no-data">
              本項目無資料
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>        
      }
    </TableContainer>
  );
};

export default React.memo(FactoryBasicInfoTable);
