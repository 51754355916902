import React from "react";
import styled from "styled-components";

import taiwan from "../../assests/taiwan.png";
import city from "../../assests/supplycghain-city.svg";
import building from "../../assests/building.svg";
import factory from "../../assests/supplychain-factory.svg";
import { useSelector } from "react-redux";

import { thousandDivider } from "../../utility/common";

const OverAllView = ({
  is_show_key,
  description,
  effected_events_result_list,
  is_search_console_open,
}) => {
  const { innerWidth } = useSelector((state) => state.app);
  const remove_duplicates = (array, use_ref) => {
    let uniq = {};
    let result = {};
    array
      .filter((obj) => !uniq[obj[use_ref]] && (uniq[obj[use_ref]] = true))
      .forEach((i) => {
        result[i[use_ref]] = [];
      });
    array.forEach((i) => {
      result[i[use_ref]].push(i);
    });

    return result;
  };

  const sort_by_area = React.useMemo(
    () => remove_duplicates(effected_events_result_list, "area"),
    [effected_events_result_list]
  );
  const sort_by_city = React.useMemo(
    () => remove_duplicates(effected_events_result_list, "city"),
    [effected_events_result_list]
  );
  const sort_by_indu_park = React.useMemo(
    () => remove_duplicates(effected_events_result_list, "induPark"),
    [effected_events_result_list]
  );
  const sort_by_capital = React.useMemo(
    () => remove_duplicates(effected_events_result_list, "companyName"),
    [effected_events_result_list]
  );

  if (innerWidth < 750) {
    return (
      <IsShow is_show_key={1} cmp_key={1}>
        <OverAllViewOuterContainer
          height={is_search_console_open ? "30vh" : "52vh"}
        >
          {/* <OverAllViewDescriptionContainer>
            <SortContainerText>受影響事件說明：</SortContainerText>
            <DescriptionContainer no_val={description === "請選擇受影響事件"}>
              <p>{description}</p>
            </DescriptionContainer>
          </OverAllViewDescriptionContainer> */}
          <OverAllViewContainer>
            <SortByAreaContainer>
              <TaiwanImage src={taiwan} alt={"台灣圖片"} />
              <SortByAreaTextContainer
                top={"24px"}
                left={"18%"}
                color={"#45c907"}
              >
                <SortByAreaText>
                  北部：
                  {sort_by_area["North"] ? thousandDivider(sort_by_area["North"].length) : 0} 家
                </SortByAreaText>
              </SortByAreaTextContainer>
              <SortByAreaTextContainer
                top={"58px"}
                left={"15%"}
                color={"#c95307"}
              >
                <SortByAreaText>
                  中部：
                  {sort_by_area["Center"]
                    ? thousandDivider(sort_by_area["Center"].length)
                    : 0}{" "}
                  家
                </SortByAreaText>
              </SortByAreaTextContainer>
              <SortByAreaTextContainer
                top={"90px"}
                left={"17.5%"}
                color={"#0777c9"}
              >
                <SortByAreaText>
                  南部：
                  {sort_by_area["South"] ? thousandDivider(sort_by_area["South"].length) : 0} 家
                </SortByAreaText>
              </SortByAreaTextContainer>
              <SortByAreaTextContainer
                top={"59px"}
                left={"55%"}
                color={"#c90730"}
              >
                <SortByAreaText>
                  東部：{sort_by_area["East"] ? thousandDivider(sort_by_area["East"].length) : 0}{" "}
                  家
                </SortByAreaText>
              </SortByAreaTextContainer>
            </SortByAreaContainer>
          </OverAllViewContainer>
          <OverAllViewContainer>
            <SortByCompanyContainer>
              <SortContainerText>前 5 大資本額公司</SortContainerText>
              <SortInnerContainer>
                <SortImageContainer>
                  <SortImage src={building} alt={"前 5 大資本額公司圖片"} />
                </SortImageContainer>
                <SortContentContainer>
                {Object.entries(sort_by_capital).length === 0 ? (
                    <NoDateText>請選擇受影響事件</NoDateText>
                  ) : (
                    Object.entries(sort_by_capital)
                      .sort((a, b) => b[1][0].capital - a[1][0].capital)
                      .slice(0, 5)
                      .map((i) => {
                        return <p>{i[1][0]["companyName"]}</p>;
                      })
                  )}
                </SortContentContainer>
              </SortInnerContainer>
            </SortByCompanyContainer>
          </OverAllViewContainer>
          <OverAllViewContainer>
            <SortByCityContainer>
              <SortContainerText>前 5 大分布地區</SortContainerText>
              <SortInnerContainer>
                <SortImageContainer>
                  <SortImage src={city} alt={"前 5 大分布地區圖片"} />
                </SortImageContainer>
                <SortContentContainer>
                  {Object.entries(sort_by_city).length === 0 ? (
                    <NoDateText>請選擇受影響事件</NoDateText>
                  ) : (
                    Object.entries(sort_by_city)
                      .sort((a, b) => b[1].length - a[1].length)
                      .slice(0, 5)
                      .map((i) => {
                        return (
                          <p>
                            {i[0]} : {thousandDivider(i[1].length)}
                          </p>
                        );
                      })
                  )}
                </SortContentContainer>
              </SortInnerContainer>
            </SortByCityContainer>
          </OverAllViewContainer>
          <OverAllViewContainer>
            <SortByParkContainer>
              <SortContainerText>前 5 大設廠園區</SortContainerText>
              <SortInnerContainer>
                <SortImageContainer>
                  <SortImage src={factory} alt={"前 5 大設廠園區圖片"} />
                </SortImageContainer>
                <SortContentContainer>
                  {Object.entries(sort_by_indu_park).length === 0 ? (
                    <NoDateText>請選擇受影響事件</NoDateText>
                  ) : (
                    Object.entries(sort_by_indu_park)
                      .sort((a, b) => b[1].length - a[1].length)
                      .slice(0, 6)
                      .map((i) => {
                        return (
                          <p>
                            {i[0] && `${i[0]} : ${thousandDivider(i[1].length)}`}
                          </p>
                        );
                      })
                  )}
                </SortContentContainer>
              </SortInnerContainer>
            </SortByParkContainer>
          </OverAllViewContainer>
        </OverAllViewOuterContainer>
      </IsShow>
    );
  }

  return (
    <IsShow is_show_key={is_show_key} cmp_key={1}>
      <OverAllViewOuterContainer
        height={is_search_console_open ? "30vh" : "52vh"}
      >
        {/* <OverAllViewDescriptionContainer>
          <SortContainerText>受影響事件說明：</SortContainerText>
          <DescriptionContainer no_val={description === "請選擇受影響事件"}>
            <p>{description}</p>
          </DescriptionContainer>
        </OverAllViewDescriptionContainer> */}
        <OverAllViewOuterContainerRows>
          <OverAllViewGrid>
            <SortByAreaContainer is_grid>
              <TaiwanImage src={taiwan} alt={"台灣圖片"} />
              <SortByAreaTextContainer
                top={"45px"}
                left={"8%"}
                color={"#45c907"}
              >
                <SortByAreaText>
                  北部：
                  {sort_by_area["North"] ? thousandDivider(sort_by_area["North"].length) : 0} 家
                </SortByAreaText>
              </SortByAreaTextContainer>
              <SortByAreaTextContainer
                top={"86px"}
                left={"7%"}
                color={"#c95307"}
              >
                <SortByAreaText>
                  中部：
                  {sort_by_area["Center"]
                    ? thousandDivider(sort_by_area["Center"].length)
                    : 0}{" "}
                  家
                </SortByAreaText>
              </SortByAreaTextContainer>
              <SortByAreaTextContainer
                top={"132px"}
                left={"8.5%"}
                color={"#0777c9"}
              >
                <SortByAreaText>
                  南部：
                  {sort_by_area["South"] ? thousandDivider(sort_by_area["South"].length) : 0} 家
                </SortByAreaText>
              </SortByAreaTextContainer>
              <SortByAreaTextContainer
                top={"89px"}
                left={"62%"}
                color={"#c90730"}
              >
                <SortByAreaText>
                  東部：{sort_by_area["East"] ? thousandDivider(sort_by_area["East"].length) : 0}{" "}
                  家
                </SortByAreaText>
              </SortByAreaTextContainer>
            </SortByAreaContainer>
          </OverAllViewGrid>
          <OverAllViewGrid>
            <SortByCompanyContainer is_grid>
              <SortContainerText>前 5 大資本額公司</SortContainerText>
              <SortInnerContainer is_grid>
                <SortGridImageContainer>
                  <SortImage src={building} alt={"前 5 大資本額公司圖片"} />
                </SortGridImageContainer>
                <SortGridContentContainer>
                  {Object.entries(sort_by_capital).length === 0 ? (
                    <NoDateText>請選擇受影響事件</NoDateText>
                  ) : (
                    Object.entries(sort_by_capital)
                      .sort((a, b) => b[1][0].capital - a[1][0].capital)
                      .slice(0, 5)
                      .map((i) => {
                        return <p>{i[1][0]["companyName"]}</p>;
                      })
                  )}
                </SortGridContentContainer>
              </SortInnerContainer>
            </SortByCompanyContainer>
          </OverAllViewGrid>
          <OverAllViewGrid>
            <SortByCityContainer is_grid>
              <SortContainerText>前 5 大分布地區</SortContainerText>
              <SortInnerContainer is_grid>
                <SortGridImageContainer>
                  <SortImage src={city} alt={"前 5 大分布地區圖片"} />
                </SortGridImageContainer>
                <SortGridContentContainer>
                  {Object.entries(sort_by_city).length === 0 ? (
                    <NoDateText>請選擇受影響事件</NoDateText>
                  ) : (
                    Object.entries(sort_by_city)
                      .sort((a, b) => b[1].length - a[1].length)
                      .slice(0, 5)
                      .map((i) => {
                        return (
                          <p>
                            {i[0]} : {thousandDivider(i[1].length)}
                          </p>
                        );
                      })
                  )}
                </SortGridContentContainer>
              </SortInnerContainer>
            </SortByCityContainer>
          </OverAllViewGrid>
          <OverAllViewGrid>
            <SortByParkContainer is_grid>
              <SortContainerText>前 5 大設廠園區</SortContainerText>
              <SortInnerContainer is_grid>
                <SortGridImageContainer>
                  <SortImage src={factory} alt={"前 5 大設廠園區圖片"} />
                </SortGridImageContainer>
                <SortGridContentContainer>
                  {Object.entries(sort_by_indu_park).length === 0 ? (
                    <NoDateText>請選擇受影響事件</NoDateText>
                  ) : (
                    Object.entries(sort_by_indu_park)
                      .sort((a, b) => b[1].length - a[1].length)
                      .slice(0, 6)
                      .map((i) => {
                        return (
                          <p>
                            {i[0] && `${i[0]} : ${thousandDivider(i[1].length)}`}
                          </p>
                        );
                      })
                  )}
                </SortGridContentContainer>
              </SortInnerContainer>
            </SortByParkContainer>
          </OverAllViewGrid>
        </OverAllViewOuterContainerRows>
      </OverAllViewOuterContainer>
    </IsShow>
  );
};

const IsShow = ({ is_show_key, cmp_key, children }) => {
  return <IsShowView is_show={is_show_key === cmp_key}>{children}</IsShowView>;
};

const IsShowView = styled.div`
  display: ${(p) => (p.is_show ? "flex" : "none")};
  flex-direction: column;
  transition: display 0.2s ease-in-out;
  width: 100%;
`;

const OverAllViewOuterContainer = styled.div`
  height: 80vh;
  overflow-x: hidden;
  overflow-y: scroll;
  @media (max-width: 750px) {
    height: ${(p) => p.height};
  }
`;

const OverAllViewOuterContainerRows = styled.div`
  display: grid;
  justify-content: space-between;
  align-items: center;
  width: 97.5%;
  margin: 10px 10% 5px 0px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-gap: 10px;
`;

const OverAllViewContainer = styled.div`
  width: 97.5%;
  border-radius: 20px;
  min-height: 20px;
  background-color: #ececec;
  margin: 10px 10% 5px 0px;
`;

const OverAllViewGrid = styled.div`
  width: 100%;
  border-radius: 20px;
  min-height: 225px;
  background-color: #ececec;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

const SortGridImageContainer = styled.div`
  width: 30%;
  position: absolute;
  bottom: -30px;
  right: 0px;
  opacity: 0.3;
`;

const SortGridContentContainer = styled.div`
  /* padding-left: 20px; */
  width: 100%;
  /* display: flex;
  align-items: center;
  justify-content: center; */
  > p {
    font-size: 1.15rem;
    padding: 2.5px 0px;
  };

  @media (max-width: 750px) {
    > p {
      font-size: 1rem;
      padding: 1.5px 0px;
    };
  }
`;

const OverAllViewDescriptionContainer = styled(OverAllViewContainer)`
  padding: 20px;
`;

const SortByAreaContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0px;
  width: ${(p) => p.is_grid && "100%"};
  height: ${(p) => p.is_grid && "200px"};
`;

const SortByAreaTextContainer = styled.div`
  position: absolute;
  color: ${(p) => p.color};
  left: ${(p) => p.left};
  top: ${(p) => p.top};
`;

const SortContainerText = styled.p`
  font-size: 1.35rem;
  padding-left: 15px;
  font-weight: 600;
`;

const SortContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  width: ${(p) => p.is_grid && "100%"};
  height: ${(p) => p.is_grid && "200px"};
`;

const SortByCompanyContainer = styled(SortContainer)``;

const SortByCityContainer = styled(SortContainer)``;

const SortByParkContainer = styled(SortContainer)``;

const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: 40px;
  padding: 10px 15px;
  > p {
    color: ${(p) => p.no_val && "#757575"};
    line-height: 25px;
  }
`;

const SortByAreaText = styled.p`
  font-size: 1.15rem;
`;

const SortInnerContainer = styled.div`
  display: flex;
  padding-top: 15px;
  align-items: center;
  justify-content: ${(p) => (p.is_grid ? "space-around" : "space-between")};
  width: ${(p) => (p.is_grid ? "90%" : "98%")};
  margin: ${(p) => (p.is_grid ? "0px 5%" : "0px 1%")};
`;

const SortImage = styled.img`
  width: 150px;
  height: 150px;
  @media (max-width: 750px) {
    width: 125px;
    height: 125px;
  }
`;

const NoDateText = styled.p`
  color: #757575;
  font-size: 1rem !important;
`;

const TaiwanImage = styled.img`
  width: 100px;
  height: 100px;
  transform: translateX(15px);
`;

const SortImageContainer = styled.div`
  width: 30%;
`;

const SortContentContainer = styled.div`
  padding-left: 20px;
  width: 60%;
  > p {
    font-size: 1rem;
    padding: 1.5px 0px;
  }
`;

export default OverAllView;
