import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { TagCloud } from "react-tagcloud";
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";
import Swal from "sweetalert2";

import CustomizedDashboardHeader from "../../components/CustomizedDashboardHeader/CustomizedDashboardHeader";
import CustomizedCard from "../../components/CustomizedCard/CustomizedCardV3";
import CustomizedGraphic from "../../components/CustomizedGraphic/CustomizedGraphicV2";
import CustomizedTable from "../../components/CustomizedTable/CustomizedTableV2";
import TaiwanInvestmentTable from "../../components/CustomizedTable/TaiwanInvestmentTable";
import FactoryAreaRentTable from "../../components/CustomizedTable/FactoryAreaRentTable";
import CustomizedPublicStock from "../../components/CustomizedPublicStock/CustomizedPublicStock";
import NewsInfoTable from "../../components/CustomizedTable/NewsInfoTable";
import GreenFactoryTable from "../../components/CustomizedTable/GreenFactoryTable";
import CustomizedDashboardBriefInfo from "../../components/CustomizedDashboardBriefInfo/CustomizedDashboardBriefInfo";
import DashboradMap from "../../components/DashboradMap/DashboradMap";
import { OrgCode, OrgCodeMap } from "../../constants/customizedOrg";
import {
  getTypeTwoChartDataHandler,
  getTypeThreeChartDataHandler,
  getTypeFourChartDataHandler,
  getPublicStockChartData,
  // getNotPublicStockChartData,
  getStockRankingData,
  getTopTenCapitalDataData,
  getTopTenGovPlanData,
  getTopTenPatentData,
  getImportPercentageData,
  getExportPercentageData,
  getAwardPercentageData,
  getGovPlanPercentageData,
  getPublicStockPercentageData,
  getNewsInfoData,
  getEmployeeData,
  getNewsInfoTagData,
  //getGreenFactoryData,
  getTaiwanInvestmentDataHandler,
  getFactoryAreaRentDataHandler,
} from "../../store/actions/customizedDashboard";
import Modal from "../../components/CustomizedModal/CustomizedModal";
import CustomizedDashboradModal from "../../components/CustomizedDashboardModal/CustomizedDashboardModal";
import NoData from "../../components/Nodata/NoData";
import Wordcloud from "../../components/WordCloud/WordCloud";
import { upperFirstCase } from "../../utility/common";
import { handleFetchCustomDashboardList } from "../../store/actions/custom_dashboard";

import "./styles.scss";

export default (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [state, setState] = React.useState({
    isFetched: false,
    selectedOrg: "MIRDC_Member",
    selectedOrgName: "金屬中心會員",
    OrgOptions: [],
    newsListPage: 1,
    perPageCount: 5,
    modalType: "",
    referenceCode: "",
    isModalOpen: false,
    title: "",
  });
  const {
    typeTwoChartData,
    typeTwoChartDataLabel,
    typeTwoChartDataMap,
    typeTwoChartDataLoading,
    typeThreeChartData,
    typeThreeChartDataLabel,
    typeThreeChartDataMap,
    typeThreeChartDataLoading,
    typeFourChartData,
    typeFourChartDataLabel,
    typeFourChartDataMap,
    typeFourChartDataLoading,
    stockRanking,
    topTenCapital,
    topTenGovPlan,
    topTenPatent,
    importPercentage,
    exportPercentage,
    awardPercentage,
    govPlanPercentage,
    publicStockPercentage,
    newsInfo,
    employeeInfo,
    newsInfoTag,
    greenFactory,
    publicStock,
    taiwanInvestment,
    factoryAreaRent,
  } = useSelector((state) => state.customizedDashboard);
  const OrgIdFromUrl = props.match.params.OrgId;
  const baseUrl = window.location.origin;
  const handleRequests = React.useCallback(
    (OrgId) => {
      Promise.all([
        dispatch(getTypeTwoChartDataHandler(OrgId)),
        dispatch(getTypeThreeChartDataHandler(OrgId)),
        dispatch(getTypeFourChartDataHandler(OrgId)),
        dispatch(getPublicStockChartData(OrgId)),
        dispatch(getStockRankingData(OrgId)),
        dispatch(getTopTenCapitalDataData(OrgId)),
        dispatch(getTopTenGovPlanData(OrgId)),
        dispatch(getTopTenPatentData(OrgId)),
        dispatch(getImportPercentageData(OrgId)),
        dispatch(getExportPercentageData(OrgId)),
        dispatch(getAwardPercentageData(OrgId)),
        dispatch(getPublicStockPercentageData(OrgId)),
        dispatch(getEmployeeData(OrgId)),
        dispatch(getNewsInfoTagData(OrgId)),
        //ispatch(getGreenFactoryData(OrgId)),
        dispatch(getGovPlanPercentageData(OrgId)),
        dispatch(getNewsInfoData(OrgId)),
        dispatch(getTaiwanInvestmentDataHandler(OrgId)),
        dispatch(getFactoryAreaRentDataHandler(OrgId)),
      ]);
    },
    [dispatch]
  );

  // 取得 Custom_Dashboard_List
  const { custom_dashboard_list, custom_dashboard_list_loading } = useSelector(
    (state) => state.custom_dashboard
  );
  const { innerWidth } = useSelector((state) => state.app);

  const alertMessage = () => {
    if (innerWidth > 576) {
      Swal.fire({
        text: "您尚未設定任何關注廠商清單，請至設定後台新增。",
        confirmButtonText: "確認",
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          history.replace("/custom_dashboard");
        }
      });
    } else {
      Swal.fire({
        text: "您尚未設定任何關注廠商清單，請使用個人電腦連至設定後台新增。",
        confirmButtonText: "確認",
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          history.replace("/portal");
        }
      });
    }
  };

  React.useEffect(() => {
    if (custom_dashboard_list_loading) {
      setState((state) => ({ ...state, isFetched: true }));
    }
    if (!custom_dashboard_list_loading) {
      const hasOrgCode = OrgCode.some((org) => org.OrgId === OrgIdFromUrl);
      if (hasOrgCode) {
        handleRequests(OrgIdFromUrl);
        const updateOrgOptions = [];
        OrgCode.map((Org) =>
          updateOrgOptions.push({
            BoardID: Org.OrgId,
            BoardName: Org.Org_Ch,
          })
        );
        setState((state) => ({
          ...state,
          OrgOptions: updateOrgOptions,
          selectedOrg: OrgIdFromUrl,
          selectedOrgName: OrgCodeMap[upperFirstCase(OrgIdFromUrl)],
        }));
      } else {
        if (state.isFetched) {
          // 如果沒有設定儀表板
          if (custom_dashboard_list.length === 0) {
            alertMessage();
          } else {
            if (OrgIdFromUrl) {
              const board_now = custom_dashboard_list.filter(
                (d) => d.BoardID === OrgIdFromUrl
              );
              if (board_now.length > 0) {
                // 需判斷他是否有設定過廠商，若沒有的話
                if (board_now[0].CompanyCnts > 0) {
                  handleRequests(OrgIdFromUrl);
                  setState((state) => ({
                    ...state,
                    selectedOrg: OrgIdFromUrl,
                    selectedOrgName: board_now[0].BoardName || "",
                  }));
                  setState((state) => ({
                    ...state,
                    OrgOptions: custom_dashboard_list,
                  }));
                } else {
                  alertMessage();
                }
              } else {
                history.replace("/custom_dashboard");
              }
            } else {
              // 如果有設定儀表板，導向該名會員自行設定的儀表板畫面
              const boardHasCompanyCnts = custom_dashboard_list.filter(
                (d) => d.CompanyCnts > 0
              );
              if (boardHasCompanyCnts.length > 0) {
                history.replace(
                  `/customized_dashboard/${boardHasCompanyCnts[0].BoardID}`
                );
              } else {
                alertMessage();
              }
            }
          }
        }
      }
    }
  }, [custom_dashboard_list_loading, OrgIdFromUrl]);

  React.useEffect(() => {
    dispatch(handleFetchCustomDashboardList());
  }, []);

  const onChangeHandler = (value) => {
    setState((state) => ({ ...state, selectedOrg: value }));
    history.push(`${value}`);
  };

  const handleNewsPage = (page) => {
    setState((state) => ({ ...state, newsListPage: page }));
  };

  const handleClose = React.useCallback(() => {
    if (!state.persistModalOpen) {
      setState((state) => ({
        ...state,
        isModalOpen: !state.isModalOpen,
        modalType: "",
        referenceCode: "",
      }));
    }
  }, [state.persistModalOpen]);

  const handleModalOpen = (modalType, referenceCode, title) => {
    if (
      modalType &&
      ((referenceCode && referenceCode !== "其他" && referenceCode !== 5) ||
        referenceCode === Number(0))
    ) {
      setState((state) => ({
        ...state,
        isModalOpen: true,
        modalType: modalType,
        referenceCode: referenceCode,
        title: title,
      }));
    } else {
      return;
    }
  };

  const openNewTab = (businessNo, type) => {
    if (!businessNo) {
      return;
    } else {
      const url = `${window.location.origin}`;
      if (type === "employee") {
        window.open(`${url}/factory_info/basic_info/${businessNo}`, "_blank");
      } else if (type === "capital") {
        window.open(`${url}/factory_info/basic_info/${businessNo}`, "_blank");
      }
    }
  };

  const persistModalOpenHandler = (value) => {
    setState((state) => ({ ...state, persistModalOpen: value }));
  };

  return (
    <div className="Org-dashboard">
      <Modal isOpen={state.isModalOpen} handleClose={handleClose}>
        <CustomizedDashboradModal
          modalType={state.modalType}
          referenceCode={state.referenceCode}
          handleClose={handleClose}
          orgId={state.selectedOrg}
          title={state.title}
          selectedOrg={state.selectedOrgName}
          persistModalOpenHandler={persistModalOpenHandler}
        />
      </Modal>
      <div className="Org-dashboard-overview">
        <Link
          sx={{ display: "flex", alignItems: "center", marginTop: "12px" }}
          href="/custom_dashboard"
          class="Back-link">
          <Icon className="icon">arrow_back_ios_new</Icon>
          返回關注廠商自訂儀表板後台
        </Link>
        <CustomizedDashboardHeader
          title="總覽"
          subTitle={state.selectedOrgName + "基本資料"}
          onChangeHandler={onChangeHandler}
          selectionOption={state.OrgOptions}
          selectedOrg={state.selectedOrg}
          referenceType={"OrgOverview"}
        />
        <div className="Org-dashboard-overview-container">
          <CustomizedCard
            title="簡介"
            loading={
              typeTwoChartDataLoading ||
              topTenCapital.topTenCapitalLoading ||
              publicStock.notPublicStockLoading ||
              govPlanPercentage.govPlanPercentageDataLoading ||
              topTenPatent.topTenPatentDataLoading ||
              awardPercentage.awardPercentageDataLoading
            }>
            <CustomizedDashboardBriefInfo
              selectedOrgName={state.selectedOrgName}
              typeTwo={typeTwoChartDataMap}
              topTenCapital={topTenCapital.topTenCapitalLabels}
              publicStock={publicStock}
              govPlanPercentage={govPlanPercentage}
              // topTenPatent={topTenPatent.topTenPatentDataMap}
              award={awardPercentage.awardPercentageDataLabels}
              topTenPatent={topTenPatent}
            />
          </CustomizedCard>
          {/* <CustomizedCard
                        title={`${state.selectedOrgName}產業園區土地廠房租售資訊`}
                        shouldCardOpen={true}
                        isLoading={factoryAreaRent.factoryAreaRentLoading}
                        style={{ marginBottom: "20px" }}
                        referenceType={"areaDashboradAreaRentingTable"}
                    >
                        <FactoryAreaRentTable
                            headerList={["園區", "面積", "類別", "單價", "網址"]}
                            data={factoryAreaRent.factoryAreaRentData}
                        />
                    </CustomizedCard> */}
        </div>
      </div>
      <div className="Org-dashboard-popular-topics">
        <CustomizedDashboardHeader
          title="熱門議題"
          subTitle="以廠商名稱關鍵字分析新聞熱門議題"
          referenceType={"OrgPopularTopics"}
        />
        <div className="Org-dashboard-overview-news-container">
          <CustomizedCard
            title="標籤雲"
            loading={newsInfoTag.newsInfoTagDataLoading}
            referenceType={"OrgRelatedNewsInfoTag"}>
            <Wordcloud
              tag={newsInfoTag.newsInfoTagData}
              eventHandler={(val, title) =>
                handleModalOpen("sectionNewsTag", val, title)
              }
            />
          </CustomizedCard>
          <CustomizedCard
            title="相關新聞"
            loading={newsInfo.newsInfoDataLoading}
            withMinHeight={newsInfo.newsInfoTotalLength > 5 ? 350 : ""}
            referenceType={"OrgRelatedNews"}>
            <NewsInfoTable
              headerList={["新聞標題", "日期/來源", "相關廠商"]}
              data={newsInfo.newsInfoData}
              newsInfoTotalLength={newsInfo.newsInfoTotalLength}
              newsInfoRowNum={newsInfo.newsInfoRowNum}
              newsInfoPagingNum={newsInfo.newsInfoPagingNum}
              getNewsListByNewPage={handleNewsPage}
              selectedCity=""
            />
          </CustomizedCard>
        </div>
      </div>
      <div className="Org-dashboard-industry-view">
        <CustomizedDashboardHeader
          title="產業形貌"
          subTitle="以主計總處產業類別2/3/4碼呈現廠商產業分佈"
          referenceType={"OrgIndustryOverview"}
        />
        <div className="Org-dashboard-industry-view-container">
          <CustomizedCard
            title={`${state.selectedOrgName}廠商產業別四碼家數佔比`}
            shouldCardOpen={true}
            referenceType={"OrgRelatedFourChart"}
            loading={typeTwoChartDataLoading}
            rankingList={typeFourChartDataMap}
            eventHandler={(val, title) =>
              handleModalOpen("typeFour", val, title)
            }>
            <CustomizedGraphic
              chartType={"donut-chart"}
              data={typeFourChartData}
              circle={"70"}
              labels={typeFourChartDataLabel}
              mapData={typeFourChartDataMap}
              dataLabels={{
                enabled: true,
                formatter: (val, { seriesIndex, dataPointIndex, w }) =>
                  w.config.labels[seriesIndex],
              }}
              eventHandler={(val, title) =>
                handleModalOpen("typeFour", val, title)
              }
            />
          </CustomizedCard>
          <CustomizedCard
            title={`${state.selectedOrgName}廠商產業別三碼家數佔比`}
            shouldCardOpen={true}
            loading={typeThreeChartDataLoading}
            rankingList={typeThreeChartDataMap}
            referenceType={"OrgRelatedThreeChart"}
            eventHandler={(val, title) =>
              handleModalOpen("typeThree", val, title)
            }>
            <CustomizedGraphic
              chartType={"donut-chart"}
              data={typeThreeChartData}
              circle={"70"}
              labels={typeThreeChartDataLabel}
              mapData={typeThreeChartDataMap}
              dataLabels={{
                enabled: true,
                formatter: (val, { seriesIndex, dataPointIndex, w }) =>
                  w.config.labels[seriesIndex],
              }}
              eventHandler={(val, title) =>
                handleModalOpen("typeThree", val, title)
              }
            />
          </CustomizedCard>
          <CustomizedCard
            title={`${state.selectedOrgName}廠商產業別二碼家數佔比`}
            shouldCardOpen={true}
            referenceType={"OrgRelatedTwoChart"}
            loading={typeFourChartDataLoading}
            rankingList={typeTwoChartDataMap}
            eventHandler={(val, title) =>
              handleModalOpen("typeTwo", val, title)
            }>
            <CustomizedGraphic
              chartType={"donut-chart"}
              data={typeTwoChartData}
              circle={"70"}
              labels={typeTwoChartDataLabel}
              mapData={typeTwoChartDataMap}
              dataLabels={{
                enabled: true,
                formatter: (val, { seriesIndex, dataPointIndex, w }) =>
                  w.config.labels[seriesIndex],
              }}
              eventHandler={(val, title) =>
                handleModalOpen("typeTwo", val, title)
              }
            />
          </CustomizedCard>
        </div>
      </div>
      <div className="Org-dashboard-index-rating">
        <CustomizedDashboardHeader
          title="指標排行"
          subTitle="呈現廠商股票公開發行、廠商進出口級距佔比與政府計畫排行等圖表"
          referenceType={"OrgIndexRanking"}
        />
        <div className="Org-dashboard-index-rating-container">
          <CustomizedCard
            title={`${state.selectedOrgName}股票上市櫃廠商推薦`}
            shouldCardOpen={true}
            referenceType={"OrgRelatedStockRank"}
            loading={stockRanking.stockRankingLoading}>
            <CustomizedTable
              headerList={["統一編號", "廠商名稱"]}
              data={stockRanking.publicStockRankingData}
              isArea
            />
          </CustomizedCard>
          <CustomizedCard
            title={`${state.selectedOrgName}股票非上市櫃廠商推薦`}
            shouldCardOpen={true}
            referenceType={"OrgRelatedNotPublicStockRank"}
            loading={stockRanking.stockRankingLoading}>
            <CustomizedTable
              headerList={["統一編號", "廠商名稱"]}
              data={stockRanking.notPublicStockRankingData}
              isArea
            />
          </CustomizedCard>
          {/* <CustomizedCard title={`${state.selectedOrgName}綠色工廠`} shouldCardOpen={true} isLoading={greenFactory.greenFactoryLoading} >
            <GreenFactoryTable headerList={["統一編號","廠商名稱"]} data={greenFactory.greenFactoryData}/>
          </CustomizedCard> */}
          <CustomizedCard
            title={`${state.selectedOrgName}股票上市櫃廠商家數`}
            shouldCardOpen={true}
            referenceType={"OrgRelatedPublicStock"}
            loading={publicStock.notPublicStockLoading}>
            <CustomizedPublicStock
              data={publicStock}
              eventHandler={(val, title) =>
                handleModalOpen("publicStock", val, title)
              }
            />
          </CustomizedCard>
          <CustomizedCard
            title={`${state.selectedOrgName}在臺投資`}
            shouldCardOpen={true}
            referenceType={"OrgRelatedTaiwanInvestment"}
            loading={taiwanInvestment.taiwanInvestmentLoading}>
            <TaiwanInvestmentTable
              headerList={[
                "廠商名稱",
                "投資金額",
                "工作機會",
                "參考新聞來源時間",
              ]}
              data={taiwanInvestment.taiwanInvestmentData}
            />
          </CustomizedCard>
          <CustomizedCard
            title={`${state.selectedOrgName}出口級距廠商家數佔比`}
            shouldCardOpen={true}
            referenceType={"OrgRelatedExportPercentage"}
            loading={exportPercentage.exportPercentageDataLoading}
            rankingList={exportPercentage.exportPercentageDataMap}
            eventHandler={(val, title) =>
              handleModalOpen("export", val, title)
            }>
            <CustomizedGraphic
              chartType={"donut-chart"}
              data={exportPercentage.exportPercentageData}
              circle={"70"}
              labels={exportPercentage.exportPercentageDataLabels}
              mapData={exportPercentage.exportPercentageDataMap}
              dataLabels={{
                enabled: true,
                formatter: (val, { seriesIndex, dataPointIndex, w }) =>
                  w.config.labels[seriesIndex],
              }}
              eventHandler={(val, title) =>
                handleModalOpen("export", val, title)
              }
            />
          </CustomizedCard>
          <CustomizedCard
            title={`${state.selectedOrgName}進口級距廠商家數佔比`}
            shouldCardOpen={true}
            referenceType={"OrgRelatedImportPercentage"}
            loading={importPercentage.importPercentageDataLoading}
            rankingList={importPercentage.importPercentageDataMap}
            eventHandler={(val, title) =>
              handleModalOpen("import", val, title)
            }>
            <CustomizedGraphic
              chartType={"donut-chart"}
              data={importPercentage.importPercentageData}
              circle={"70"}
              labels={importPercentage.importPercentageDataLabels}
              mapData={importPercentage.importPercentageDataMap}
              dataLabels={{
                enabled: true,
                formatter: (val, { seriesIndex, dataPointIndex, w }) =>
                  w.config.labels[seriesIndex],
              }}
              eventHandler={(val, title) =>
                handleModalOpen("import", val, title)
              }
            />
          </CustomizedCard>
          <CustomizedCard
            title={`${state.selectedOrgName}廠商員工數前十大排行`}
            shouldCardOpen={true}
            loading={employeeInfo.employeeInfoDataLoading}
            referenceType="OrgDashboradEmployeeRanking">
            <CustomizedGraphic
              chartType={"horizontal-bar-chart"}
              data={employeeInfo.employeeInfoData}
              labels={employeeInfo.employeeInfoDataLabels}
              mapData={employeeInfo.employeeInfoDataMap}
              unit={"人數"}
              formatter={(val) => val}
              color={["#6bcf00"]}
              type="employee"
              eventHandler={(val) => openNewTab(val, "employee")}
            />
          </CustomizedCard>
          <CustomizedCard
            title={`${state.selectedOrgName}廠商資本額前十大排行`}
            shouldCardOpen={true}
            referenceType={"OrgRelatedTopTenCapital"}
            loading={topTenCapital.topTenCapitalLoading}
            rankingList={topTenCapital.topTenCapitalDataMap}
            eventHandler={(val) => openNewTab(val, "capital")}>
            <CustomizedGraphic
              chartType={"vertical-bar-chart"}
              data={topTenCapital.topTenCapitalData}
              labels={topTenCapital.topTenCapitalLabels}
              mapData={topTenCapital.topTenCapitalDataMap}
              unit={"資本額"}
              formatter={(val) => (val === 0 ? 0 : val / 100000000 + "億")}
              eventHandler={(val) => openNewTab(val, "capital")}
            />
          </CustomizedCard>
          <CustomizedCard
            title={`${state.selectedOrgName}廠商政府計畫類型佔比`}
            shouldCardOpen={true}
            referenceType={"OrgRelatedGovPlanPercentage"}
            loading={govPlanPercentage.govPlanPercentageDataLoading}
            rankingList={govPlanPercentage.govPlanPercentageDataMap}
            eventHandler={(val, title) =>
              handleModalOpen("govPlan", val, title)
            }>
            <CustomizedGraphic
              chartType={"donut-chart"}
              data={govPlanPercentage.govPlanPercentageData}
              circle={"70"}
              labels={govPlanPercentage.govPlanPercentageDataLabels}
              mapData={govPlanPercentage.govPlanPercentageDataMap}
              isGovPlanOrAwardPercentage
              dataLabels={{
                enabled: true,
                formatter: (val, { seriesIndex, dataPointIndex, w }) =>
                  w.config.labels[seriesIndex],
              }}
              eventHandler={(val, title) =>
                handleModalOpen("govPlan", val, title)
              }
            />
          </CustomizedCard>
          <CustomizedCard
            title={`${state.selectedOrgName}廠商政府計畫數前十大排行`}
            shouldCardOpen={true}
            referenceType={"OrgRelatedTopTenGovPlan"}
            loading={topTenGovPlan.topTenGovPlanDataLoading}
            rankingList={topTenGovPlan.topTenGovPlanDataMap}
            eventHandler={(val, title) =>
              handleModalOpen("topTenGovPlan", val, title)
            }>
            <CustomizedGraphic
              chartType={"horizontal-bar-chart"}
              data={topTenGovPlan.topTenGovPlanData}
              labels={topTenGovPlan.topTenGovPlanDataLabels}
              mapData={topTenGovPlan.topTenGovPlanDataMap}
              unit={"件數"}
              formatter={(val) => val}
              eventHandler={(val, title) =>
                handleModalOpen("topTenGovPlan", val, title)
              }
            />
          </CustomizedCard>
          <CustomizedCard
            title={`${state.selectedOrgName}廠商獲獎類型佔比`}
            shouldCardOpen={true}
            referenceType={"OrgRelatedAwardPercentage"}
            loading={awardPercentage.awardPercentageDataLoading}
            rankingList={awardPercentage.awardPercentageDataMap}
            eventHandler={(val, title) => handleModalOpen("award", val, title)}>
            <CustomizedGraphic
              chartType={"donut-chart"}
              data={awardPercentage.awardPercentageData}
              circle={"70"}
              labels={awardPercentage.awardPercentageDataLabels}
              mapData={awardPercentage.awardPercentageDataMap}
              isGovPlanOrAwardPercentage
              dataLabels={{
                enabled: true,
                formatter: (val, { seriesIndex, dataPointIndex, w }) =>
                  w.config.labels[seriesIndex],
              }}
              eventHandler={(val, title) =>
                handleModalOpen("award", val, title)
              }
            />
          </CustomizedCard>
          <CustomizedCard
            title={`${state.selectedOrgName}廠商近年專利公告數前十大排行`}
            shouldCardOpen={true}
            referenceType={"OrgRelatedTopTenPatent"}
            loading={topTenPatent.topTenPatentDataLoading}
            rankingList={topTenPatent.topTenPatentDataMap}
            eventHandler={(val, title) =>
              handleModalOpen("patent", val, title)
            }>
            <CustomizedGraphic
              chartType={"horizontal-bar-chart"}
              data={topTenPatent.topTenPatentData}
              labels={topTenPatent.topTenPatentDataLabels}
              mapData={topTenPatent.topTenPatentDataMap}
              unit={"件數"}
              formatter={(val) => val}
              eventHandler={(val, title) =>
                handleModalOpen("patent", val, title)
              }
            />
          </CustomizedCard>
        </div>
      </div>
    </div>
  );
};
