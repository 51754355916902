import React from "react";
import styled from "styled-components";
import Map from "@mui/icons-material/Map";
import CorporateFare from "@mui/icons-material/CorporateFare";
import Tooltip from '@mui/material/Tooltip';

import { useSelector, useDispatch } from "react-redux";
import { thousandDivider } from "../../utility/common";
import { handleFetchWastedDetailList } from "../../store/actions/wasted_clean";
import * as theme from "../../assets/theme";

const TabsItem = ({
    latitude,
    longitude,
    address,
    amount,
    business_no,
    company_name, 
    ctrl_id, 
    county,
    phone,
    handleToggleDetailModal,
    handleUpdateModalTitle,
    org_id
}) => {
    const dispatch = useDispatch(); 


    const handleFetchDetailData = () => {
        handleUpdateModalTitle(company_name)
        let org_kind;
        switch(org_id){
            case "清除機構":
                org_kind = 0;
                break;
            case "處理同意設置":
                org_kind = 1;
                break;
            case "處理機構":
                org_kind = 2;
                break;
            default:
                org_kind = 0;
        }


        dispatch(handleFetchWastedDetailList(ctrl_id, org_kind));
        handleToggleDetailModal();
    }

    const handleOpenBusiness = () => {
        const url = window.location.origin;
        window.open(`${url}/factory_info/basic_info/${business_no}`, "_blank");
    }

    const handleOpenGoogleMap = () => {
        window.open(`https://maps.google.com/?q=$${address}`, "_blank");
    }

    return (
        <TabItemWrapper>
            <TabRows>
                <TabHeadText>{company_name}</TabHeadText>
                <div>
                    <Tooltip title="查看公司資訊">
                        <CorporateFareIcon onClick={handleOpenBusiness}/>
                    </Tooltip>
                    <Tooltip title="連結至Google Map">
                        <MapIcon onClick={handleOpenGoogleMap}/>
                    </Tooltip>
                    
                </div>
            </TabRows>
            <TabRows><TabHeadText>月處理總量：</TabHeadText><TabText>{thousandDivider(amount)}{" "}公噸</TabText></TabRows>
            <TabRows><TabHeadText>聯絡電話：</TabHeadText><TabText>{phone}</TabText></TabRows>
            <TabRowsFlexEnd><MoreText onClick={handleFetchDetailData}>處理廢棄物清單</MoreText></TabRowsFlexEnd>
        </TabItemWrapper>
    )
};


const MapIcon = styled(Map)`
    transition: 0.2s;
    &:hover{
        transition: 0.2s;
        color: ${theme.disabledGray}
    }
`;

const CorporateFareIcon = styled(CorporateFare)`
    transition: 0.2s;
    &:hover{
        transition: 0.2s;
        color: ${theme.disabledGray}
    }
`;

const TabItemWrapper = styled.div`
    border-top: 0.5px solid ${theme.disabledGray};
    border-bottom: 0.5px solid ${theme.disabledGray};
    width: 100%;
    padding: 5px 10px;
;
`

const TabRows = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 2.5px 0px;
`;

const TabRowsFlexEnd = styled(TabRows)`
    justify-content: flex-end;
    padding-top: 10px;
`

const TabText = styled.p`
    font-size: 1rem;
`;

const TabHeadText = styled.p`
    font-size: 1rem;
    font-weight: 600;
`;

const MoreText = styled.p`
    font-size: 0.8rem;
    cursor: pointer;
    text-decoration: underline;
    color: ${theme.linkBlue};

    &:hover{
        color: ${theme.linkHoveredBlue}
    }
`


export default React.memo(TabsItem)