import React from 'react'
import { Card } from 'react-bootstrap'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCrown } from "@fortawesome/free-solid-svg-icons";

import './CustomizedCard.css';
import "./styles.scss"

const CustomizedCard = props => {
    const { isSimple } = props

    if(isSimple){
        return(
            <div className='simple-card'>
                {props.children}
            </div>
        )
    }else{
        return (
            <div>
                <Card.Header className='card-title'>{props.title}</Card.Header>
                <Card.Body className='card-body'>
                    {props.children}
                </Card.Body>
            </div>
        )
    }


}

export default CustomizedCard