import * as actionTypes from './actionTypes'
import { getSectionMapHandler, updateSectionMapHandler } from "../../utility/api";

export const startFetchSectionMap = state => {
    return {
        type: actionTypes.START_GET_SECTION_MAP,
    }
}

export const fetchSectionMapSuccess = data => {
    const updateData =  { ...data } ;

    return {
        type: actionTypes.GET_SECTION_MAP_SUCCESS,
        data: updateData
    }
}

export const fetchSectionMapFail = err => {
    return {
        type: actionTypes.GET_SECTION_MAP_FAIL,
        err: err
    }
}

export const fetchSectionMapInfoHandler = uid => async(dispatch) => {
    try{
        dispatch(startFetchSectionMap());
        const sectionMap = await getSectionMapHandler(uid);
        if(sectionMap.status === 200){
            dispatch(fetchSectionMapSuccess(sectionMap.data));
        }else{
            dispatch(fetchSectionMapFail(sectionMap));
        }
    } catch (error) {
        console.log(error);
        if (error && error.status === 401) {
          dispatch(fetchSectionMapFail(error));
        }
      }
}

export const startUpdateSectionMap = state => {
    return {
        type: actionTypes.START_UPDATE_SECTION_MAP,
    }
}

export const updateSectionMapSuccess = data => {
    return {
        type: actionTypes.UPDATE_SECTION_MAP_SUCCESS,
    }
}

export const updateSectionMapFail = err => {
    return {
        type: actionTypes.UPDATE_USER_INFO_FAIL,
        err: err
    }
}

export const toggleSectionMapHandler = (data) => async(dispatch) => {
    try{
        dispatch(startUpdateSectionMap());
        const sectionMapUpdate = await updateSectionMapHandler(data);
        if(sectionMapUpdate.status === 200){
            await dispatch(updateSectionMapSuccess());
        }else{
            dispatch(updateSectionMapFail(sectionMapUpdate));
        }

    } catch (error) {
        console.log(error);
        if (error && error.status === 401) {
          dispatch(updateSectionMapFail(error));
        }
      }
}