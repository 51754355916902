import React from "react";
import styled from "styled-components";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Collapse from "@material-ui/core/Collapse";
import MenuItem from "@material-ui/core/MenuItem";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";

import Spinner from "../../components/Spinner/Spinner";
import { applyFileRequestHandler, getUserInfoHandler } from "../../store/actions/applyFile";
import * as theme from "../../assests/styles/theme";
import { Typography } from "@material-ui/core";
import { keyTranslate, areaData, checkboxStatus, operatorStatus } from "../../constants/applyFileParameter";
import "./styles.scss";

export default ({ isOpen, closeModal, data, source, dataLimit }) => {
  const dispatch = useDispatch();
  const { userInfo, getUserInfoLoading } = useSelector((state) => state.user);
  const { applyFileDropdownList, getApplyFileDropdownListLoading } = useSelector((state) => state.applyFile);
  const [state, setState] = React.useState({
    isModalOpen: true,
    isClicked: false,
    account: "",
    name: "",
    email: "",
    department: "",
    phone: "",
    source: source,
    applyReason: "",
    applyMemo: "",
    applyCondition: "",
    showApplyCondition: ""
  });
  const { name, email, department, phone, applyReason, applyMemo, applyCondition } = state;
  const handleUpdate = (e) => {
    const { name, value } = e.target;
    setState((state) => ({ ...state, [name]: value }));
  };

  const [showScrollHint, setShowScrollHint] = React.useState(true);
  React.useEffect(() => {
    const target = document.querySelector("#applyForm");
    const handleScroll = () => {
      const scrollTop = target.scrollTop;
      if (scrollTop >= 0 && scrollTop < 1) {
        setShowScrollHint(true);
      } else {
        setShowScrollHint(false);
      }
    };
    target.addEventListener("scroll", handleScroll, { passive: true });
    return () => target.removeEventListener("scroll", handleScroll);
  }, []);

  React.useEffect(() => {
    setState((state) => ({ ...state, source: source }));
  }, [source]);

  React.useEffect(() => {
    if (isOpen) {
      setState((state) => ({ ...state, isModalOpen: true }));
    } else {
      setState((state) => ({ ...state, isModalOpen: false, isClicked: false }));
    }
  }, [isOpen]);


  React.useEffect(() => {
    //fetchUserInfo
    dispatch(getUserInfoHandler());
  }, [isOpen]);

  React.useEffect(() => {
    //fetch user info from redux;
    let applyCondition = '';
    let showApplyCondition = '';
    if (localStorage.getItem("mapSearch") || localStorage.getItem("mapSearchEdu") || localStorage.getItem("taxInfo")) {
      Object.entries(JSON.parse(localStorage.getItem("mapSearch") || localStorage.getItem("mapSearchEdu") || localStorage.getItem("taxInfo"))).map(([key, val]) => {
        applyCondition += (keyTranslate[key] && val && val.length) ? (`${keyTranslate[key]}：${val}\n`) : "";
        if (!localStorage.getItem("taxInfo")) {
          if (keyTranslate[key] == '地區') {
            showApplyCondition += (keyTranslate[key] && val && val.length) ? (`${keyTranslate[key]}：${areaData[val]}\n`) : "";
          } else if (keyTranslate[key] == '運算子') {
            showApplyCondition += (keyTranslate[key] && val && val.length) ? (`${keyTranslate[key]}：${operatorStatus[val]}\n`) : "";
          } else if ((keyTranslate[key].includes('行業標準分類')) || (keyTranslate[key].includes('關鍵字')) || (keyTranslate[key].includes('資本額')) || (keyTranslate[key] == ('縣市')) || (keyTranslate[key] == ('園區')) || (keyTranslate[key] == ('所在地'))) {
            showApplyCondition += (keyTranslate[key] && val && val.length) ? (`${keyTranslate[key]}：${val}\n`) : "";
          } else if (keyTranslate[key] == ('距離')) {
            showApplyCondition += (keyTranslate[key] && val && val.length) ? (`${keyTranslate[key]}：${val}公里\n`) : "";
          } else {
            showApplyCondition += (keyTranslate[key] && val && val.length) ? (`${keyTranslate[key]}：${checkboxStatus[val]}\n`) : "";
          }
        }
      });
    }
    if (userInfo) {
      const data = {
        account: userInfo.UserId || "",
        name: userInfo.Name || "",
        email: userInfo.Email || "",
        department: userInfo.Department || "",
        phone: userInfo.Phone || "",
        applyReason: userInfo.ApplyReason || "",
        applyMemo: userInfo.ApplyMemo || "",
        applyCondition: applyCondition,
        showApplyCondition: showApplyCondition,
      };

      setState((state) => ({ ...state, ...data }));
    }
  }, [userInfo, localStorage.getItem('mapSearch'), localStorage.getItem('taxInfo')]);

  const handleApplyFileRequests = () => {
    setState((state) => ({ ...state, isClicked: true }));
    const checkList = ['name', 'email', 'department', 'phone', 'applyReason', 'applyMemo'];
    for (let checkIndex = 0; checkIndex < checkList.length; checkIndex++) {
      if (!state[checkList[checkIndex]]) {
        let addElem = document.getElementById(checkList[checkIndex]).parentNode.parentNode
        addElem.classList.add('shake');
        addElem.classList.add('shakeColor');
        setTimeout(() => { addElem.classList.remove('shake') }, 800);
      }
      else {
        let removeElem = document.getElementById(checkList[checkIndex]).parentNode.parentNode
        removeElem.classList.remove('shake');
        removeElem.classList.remove('shakeColor');
      }
    }
    if (state.name && state.account && (state.department) && (state.phone) && (state.applyReason) && (state.applyMemo)) {
      const params = {};
      params.account = state.account;
      params.name = state.name;
      params.email = state.email;
      params.department = state.department;
      params.phone = state.phone;
      params.source = state.source;
      params.applyReason = state.applyReason;
      params.applyMemo = state.applyMemo;
      params.showApplyCondition = state.showApplyCondition;
      params.applyCondition = state.applyCondition;
      params.parkId = state.applyReason || "other"
      Swal.fire({
        html: `確認資訊無誤?<br>備註：(檔案將會自動匯出)`,
        showCancelButton: true,
        confirmButtonText: '確認',
        cancelButtonText: '取消'
      }).then((res) => {
        if (res.value) {
          dispatch(applyFileRequestHandler(params, data, dataLimit));
          closeModalHandler();
        }
      })
    }
  };

  const closeModalHandler = () => {
    closeModal();
    setState((state) => ({ ...state, isModalOpen: false, isClicked: false }));
  };

  return (
    <React.Fragment>
      <Backdrops isModalOpen={state.isModalOpen} />
      <AuthControllerWrapper isModalOpen={state.isModalOpen} id="applyForm">
        <CloseContainer onClick={closeModalHandler}>
          <Icon>close</Icon>
        </CloseContainer>
        <ApplyFileTitleContainer>
          <ApplyFileWrapperTitleHeadText>
            檔案用途說明
          </ApplyFileWrapperTitleHeadText>
        </ApplyFileTitleContainer>
        <UserAccount>帳號：{state.account}</UserAccount>
        {getUserInfoLoading ? (
          <ApplyFileContainer>
            <Spinner />
          </ApplyFileContainer>
        ) : (
          <ApplyFileContainer>
            <div class="mouse_scroll" style={{
              display: showScrollHint ? "block" : "none",
              top: "calc(75vh - 50px)"
            }}>
              <div>
                <span class="m_scroll_arrows unu"></span>
                <span class="m_scroll_arrows doi"></span>
                <span class="m_scroll_arrows trei"></span>
              </div>
            </div>
            <Inputs
              required
              label="姓名(必填)"
              variant="outlined"
              placeholder="請輸入姓名(必填)。"
              size="small"
              value={state.name}
              InputProps={{
                autoComplete: "off",
                startAdornment: (
                  <InputAdornment position="start">
                    <Icon>person_pin</Icon>
                  </InputAdornment>
                ),
              }}
              onChange={handleUpdate}
              name={"name"}
              id={"name"}
            />
            <Verification
              in={state.isClicked && !state.name}
              text={"請填寫姓名(必填)。"}
            />
            <Inputs
              required
              label="E-mail(必填)"
              variant="outlined"
              placeholder="請輸入E-mail(必填)。"
              size="small"
              value={state.email}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Icon>email</Icon>
                  </InputAdornment>
                ),
              }}
              onChange={handleUpdate}
              name={"email"}
              id={"email"}
            />
            <Verification
              in={state.isClicked && !state.email}
              text={"請填寫E-mail(必填)。"}
            />
            <Inputs
              required
              label="單位名稱(必填)"
              variant="outlined"
              placeholder="請輸入所屬單位名稱，例：中國鋼鐵股份有限公司(必填)。"
              size="small"
              value={state.department}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Icon>apartment</Icon>
                  </InputAdornment>
                ),
              }}
              onChange={handleUpdate}
              name={"department"}
              id={"department"}
            />
            <Verification
              in={state.isClicked && !state.department}
              text={"請填寫單位名稱(必填)。"}
            />
            <Inputs
              required
              label="聯絡電話(必填)"
              placeholder="請輸入聯絡電話(必填)。"
              variant="outlined"
              size="small"
              value={state.phone}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Icon>phone</Icon>
                  </InputAdornment>
                ),
              }}
              onInput={(e) => {
                if (isNaN(e.nativeEvent.data)) {
                  e.target.value = e.target.defaultValue;
                }
                else if (e.target.value.length == 10) {
                  e.target.value = e.target.value.toString().replace(/(\d{4})(\d{3})(\d{3})/, '$1-$2-$3').slice(0, 12);
                }
                else if (e.target.value.length > 12) {
                  e.target.value = e.target.value.toString().replace(/(\d{4})(\d{3})(\d{3})/, '$1-$2-$3').slice(0, 12);
                }
              }}
              onChange={handleUpdate}
              name={"phone"}
              id={"phone"}
            />
            <Verification
              in={state.isClicked && !state.phone}
              text={"請填寫聯絡電話(必填)。"}
            />
            <Inputs
              required
              label="用途(必填)"
              variant="outlined"
              size="small"
              value={state.applyReason ? state.applyReason : "請選擇用途"}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Icon>construction</Icon>
                  </InputAdornment>
                ),
              }}
              onChange={handleUpdate}
              select
              name={"applyReason"}
              id={"applyReason"}
            >
              <MenuItem value="請選擇用途" key="defaultSelect" disabled>請選擇用途</MenuItem>
              {applyFileDropdownList != "" && applyFileDropdownList?.map((applyReason) => (
                <MenuItem key={applyReason.id} value={applyReason.id}>
                  {applyReason.Name}
                </MenuItem>
              ))}
            </Inputs>
            <Inputs
              required
              label="備註(必填)"
              placeholder="請簡短說明應用方式(必填)。"
              variant="outlined"
              size="small"
              value={state.applyMemo}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Icon>description</Icon>
                  </InputAdornment>
                ),
              }}
              onChange={handleUpdate}
              name={"applyMemo"}
              id={"applyMemo"}
              inputProps={{
                style: {
                  height: "100px",
                },
              }}
              multiline
              rows={10}
            />
            <Verification
              in={state.isClicked && !state.applyMemo}
              text={"請填寫備註(必填)。"}
            />
            <Inputs
              required
              label="設定條件"
              variant="outlined"
              size="small"
              value={state.showApplyCondition}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Icon>rule</Icon>
                  </InputAdornment>
                ),
              }}
              onChange={handleUpdate}
              // styled={"height: 100px;"}
              name={"showApplyCondition"}
              id={"showApplyCondition"}
              disabled
              inputProps={{
                style: {
                  height: "150px",
                },
              }}
              multiline
              rows={10}
            />
            <ButtonContainer>
              <Buttons
                active={name && email && department && phone && applyReason && applyMemo}
                onClick={handleApplyFileRequests}>確認送出</Buttons>
            </ButtonContainer>
          </ApplyFileContainer>
        )}
      </AuthControllerWrapper>
    </React.Fragment>
  );
};

const Verification = ({ isOpen, text }) => {
  return (
    <Collapse in={isOpen}>
      <VerificationTextContainer>
        <VerificationTex>{text}</VerificationTex>
      </VerificationTextContainer>
    </Collapse>
  );
};

const Backdrops = styled.div`
  width: 100vw;
  height: 100vh;
  position: absolute;
  z-index: 1200;
  background-color: rgba(0, 0, 0, 0.7);
  top: 0px;
  display: ${(p) => (p.isModalOpen ? "block" : "none")};
  transition: 0.2s display ease-in-out;
  
  //手機版沒有修改使用者資料
  @media (max-width: 768px) {
    display: none;
  }
`;

const AuthControllerWrapper = styled.div`
  overflow-y: scroll;
  position: absolute;
  width: 30%;
  margin: 3% 35% 0px 35%;
  min-height: 100px;
  height: 75vh;
  overflow-y: scroll;
  top:${(p) => (p.isModalOpen ? "0px" : "-130vh")};
  opacity:${p => p.isModalOpen ? 1 : 0};
  display:${p => p.isModalOpen ? "block" : "none"};
  z-index: 1201;
  background-color: #fff;
  transition: 0.2s top linear;
  padding: 15px 30px 30px 30px;
  border-radius: 20px;
  transition: 0.5s top linear;

  //手機版沒有修改使用者資料
  @media (max-width: 768px) {
    height: 80vh;
    overflow-y: scroll;
    position: absolute;
    width: 90%;
    margin: 3% 0% 0px 5%;
    min-height: 100px;
    top:${(p) => (p.isModalOpen ? "0px" : "-130vh")};
    opacity:${p => p.isModalOpen ? 1 : 0};
    display:${p => p.isModalOpen ? "block" : "none"};
    z-index: 1201;
    background-color: #fff;
    -webkit-transition: 0.2s top linear;
    transition: 0.2s top linear;
    padding: 15px 30px 30px 30px;
    border-radius: 20px;
    -webkit-transition: 0.5s top linear;
    transition: 0.5s top linear;
  }
`;

const CloseContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;

  .material-icons {
    cursor: pointer;
  }
`;

const ApplyFileTitleContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

const ApplyFileWrapperTitleHeadText = styled.h2`
  color: ${theme.secondaryBlack};
  font-size: 2.25rem;
  font-family: ${theme.primaryFont} !important;
  font-weight: 600;
`;

const ApplyFileContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 0px;
`;

const Inputs = styled(TextField)`
  margin: 10px 0px !important;
  .MuiFormLabel-root.Mui-focused {
    color: var(--main-color);
  }
  .MuiInputBase-root.Mui-focused {
    .MuiOutlinedInput-notchedOutline {
      border-color: var(--main-color);
    }
  }
`;

const Buttons = styled(Button)`
  width: 100%;
  height: 40px !important;
  border-radius: 10px !important;
  background-color: ${(p) =>
    p.active ? theme.linkBlue : theme.disabledGray} !important;
  font-size: 1rem !important;
  font-weight: 500 !important;
  color: ${theme.white} !important;
  transition: 0.5s;

  &:hover {
    cursor: ${(p) => (p.active ? "pointer" : "not-allowed")};
    transition: 0.5s;
    background-color: ${(p) => (p.active ? "pointer" : "not-allowed")};
  }
`;
const ButtonContainer = styled.div``;

const VerificationTextContainer = styled.div`
  padding: 5px 0px;
`;

const VerificationTex = styled.p`
  font-size: 0.625rem;
  color: red;
`;

const UserAccount = styled(Typography)`
  margin-top: 8px !important;
`;
