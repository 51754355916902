import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import moneyIcon from "../../assests/images/about-money.png";
import buildingIcon from "../../assests/images/about-building.png";
// import LineChart from "./LineChart";
import { Box, Grid } from "@mui/material";
import PieChart from "./PieChart";
import BarChart from "./BarChart";
import Loading from "./Loading";

const IndustryBoard = ({ dashboardData, sectionIndex, sectionExportPercentage, sectionTopTenCapital, sectionTopTenGovPlan, sectionNewsContent }) => {
  return (
    <>
      <div class="d-flex about-dashboard-content">
        <div>
          <div class="card">
            <div class="card-header">
              <h3>概況</h3>
            </div>
            <div className="card-body">
              <div
                style={{
                  margin: "32px 0px",
                }}
              >
                {/* {JSON.stringify(sectionIndex)} */}
                <Grid container spacing={2}>
                  {[
                    {
                      logo: moneyIcon,
                      title: "產值",
                      unit: "億元",
                      key: "ProductVal",
                      column: 12,
                    },
                    {
                      logo: buildingIcon,
                      title: "廠商數",
                      unit: "家",
                      key: "FactoryNum",
                      column: 6,
                    },
                    {
                      logo: buildingIcon,
                      title: "員工數",
                      unit: "人",
                      key: "EmpCount",
                      column: 6,
                    },
                    // {
                    //   logo: moneyIcon,
                    //   title: "自來水供應量",
                    //   key: "Facility.WaterSupply",
                    //   column: 6,
                    // },
                    // {
                    //   logo: moneyIcon,
                    //   title: "電力供應量",
                    //   key: "Facility.PowerSupply",
                    //   column: 6,
                    // },
                  ].map((item) => {
                    return (
                      <Grid item xs={12} md={item.column}>
                        <Box display="flex" alignItems={"center"} justifyContent={"center"}>
                          <img src={item.logo} alt="" width="60" />
                          <div
                            style={{
                              textAlign: "left",
                              marginLeft: "10px",
                            }}
                          >
                            <h4
                              style={{
                                fontSize: "14px",
                              }}
                            >
                              {item.title}
                            </h4>
                            <p
                              style={{
                                color: "#25758A",
                                fontSize: "16px",
                              }}
                            >
                              {sectionIndex?.[
                                item.key
                              ]
                                ?.toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                              <span style={{
                                color: "#00000099",
                                paddingLeft: "4px",
                              }}>{item.unit}</span>
                            </p>
                            
                          </div>
                        </Box>
                      </Grid>
                    );
                  })}
                </Grid>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-header">
              <h3>出口級距廠商家數佔比</h3>
            </div>
            <div className="card-body">
              {
                sectionExportPercentage?.sectionExportPercentageLoading ? <Loading />:
                sectionExportPercentage?.sectionExportPercentageMap?.length > 0? 
                <div tabIndex={"0"} title="出口級距廠商家數佔比圖表資料" style={{
                  position: "relative"
                }}>
                  <div aria-hidden="true">
                    <PieChart data={sectionExportPercentage?.sectionExportPercentageMap} /> 
                  </div>
                  <table style={{
                    position: "absolute",
                    width: "100%",
                    height: "80%",
                    top: 0,
                    margin: "-1px",
                    padding: "0",
                    overflow: "hidden",
                    clip: "rect(0, 0, 0, 0)",
                    border: "0"
                  }}>
                    <thead>
                      <tr>
                        <th>出口級距</th>
                        <th>佔比</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        sectionExportPercentage?.sectionExportPercentageMap?.map((item) => (
                          <tr>
                            <td>{item.label}</td>
                            <td>{item.y}%</td>
                          </tr>
                        ))
                      }
                    </tbody>
                  </table>
                </div>: 
                <Box display="flex" justifyContent={"center"}>
                  <NewsCompanyName>無相關資料</NewsCompanyName>
                </Box>
              }
            </div>
          </div>
        </div>
        <div>
          <div class="card">
            <div class="card-header">
              <h3>園區廠商資本額前十大排行</h3>
            </div>
            <div className="card-body">
              {
                sectionTopTenCapital?.sectionExportPercentageLoading ? <p style={{
                  textAlign: "center",
                }}>Loading...</p> :
                sectionTopTenCapital?.sectionTopTenCapitalDataMap?.length > 0?  <BarChart title="園區廠商資本額前十大排行" chartData={sectionTopTenCapital.sectionTopTenCapitalDataMap.map((item) => ({
                  label: item.label?.replace("股份有限公司", ""),
                  y: item.y,
                }))}
                keyTarget={["y"]} indexBy="label"
                Unit="元" /> : 
                <Box display="flex" justifyContent={"center"}>
                  <NewsCompanyName>無相關資料</NewsCompanyName>
                </Box>
              }
            </div>
          </div>
          <div class="card">
            <div class="card-header">
              <h3>園區廠商政府計畫數前十大排行</h3>
            </div>
            <div className="card-body">
            {
                sectionTopTenGovPlan?.sectionTopTenGovPlanLoading ? <p style={{
                  textAlign: "center",
                }}>Loading...</p> :
                sectionTopTenGovPlan?.sectionTopTenGovPlanDataMap?.length > 0?  <BarChart title="園區廠商政府計畫數前十大排行" chartData={sectionTopTenGovPlan.sectionTopTenGovPlanDataMap.map((item) => ({
                  label: item.label?.replace("股份有限公司", ""),
                  y: item.y,
                }))}
                keyTarget={["y"]} indexBy="label"
                Unit="件" /> : 
                <Box display="flex" justifyContent={"center"}>
                  <NewsCompanyName>無相關資料</NewsCompanyName>
                </Box>
              }
              {/* <LineChart
                data={[
                  {
                    id: "產業產值趨勢",
                    data: productVal?.productValData?.map((item, index) => ({
                      x: chartYear[index],
                      y: item,
                    })),
                  },
                ]}
                Unit="千元"
              /> */}
            </div>
          </div>
        </div>
        <div class="card long">
          <div class="card-header">
            <h3>相關新聞</h3>
          </div>
          <div
            className="card-body"
            style={{
              margin: "16px 0px",
            }}
          >
            {
              sectionNewsContent.sectionNewsContentLoading? <Loading /> : 
              sectionNewsContent?.sectionNewsContentData?.Datas?.length === 0 ?
              <Box display="flex" justifyContent={"center"}>
                <NewsCompanyName>無相關資料</NewsCompanyName>
              </Box>
              :
              <ListWrapper>
              {sectionNewsContent?.sectionNewsContentData?.Datas?.slice(0, 5)?.map((row) => (
                <CompanyBox display="flex" justifyContent={"space-between"}>
                  <Box className="text">
                    <span>
                      {new Date(row.PubDate).toLocaleDateString("sv-SE")} ｜ {row.Companys?.[0].CompanyName === ""? row?.Companys?.[0]?.InduParks?.[0].ParkName : row?.Companys?.[0]?.CompanyName}
                    </span>
                    <OuterLink title={`另開新視窗，連結到${row.Title}新聞`} href={row.Link} target="_blank">
                      {row.Title}
                    </OuterLink>
                  </Box>
                </CompanyBox>
              ))}
              </ListWrapper>
            }
            
            <hr
              style={{
                margin: "14px 0px",
              }}
            />
            <CompanyLink
              style={{
                fontSize: "14px",
              }}
              title={`另開新視窗，前往園區儀表板查看更多資訊`}
              target="_blank"
              to={`/park_dashboard/${dashboardData.code}`}
            >
              查看更多
            </CompanyLink>
          </div>
        </div>
      </div>
    </>
  );
};

const OuterLink = styled.a`
  display: flex;
  color: #3E5A75;
  text-decoration: underline;
  &:hover {
    color: #3E5A75;
    opacity: 0.8;
    text-decoration: underline;
  }
`

const CompanyLink = styled(Link)`
  display: flex;
  color: #3E5A75;
  text-decoration: underline;
  &:hover {
    color: #3E5A75;
    opacity: 0.8;
    text-decoration: underline;
  }
`
const CompanyBox = styled(Box)`
  .text {
    text-align: left;
    margin-bottom: 8px;
    span {
      font-size: 0.75rem;
      color: rgba(0, 0, 0, 0.6);
    }
  }
  .number {
    text-align: right;
    span {
      font-size: 0.75rem;
      color: rgba(0, 0, 0, 0.6);
    }
    p {
      fontSize: 16px;
      color: #25758A;
    }
  }
`
const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  @media (max-width: 768px) {
    margin-bottom: 0;
  }
`;

const NewsCompanyName = styled.p`
  background: #E6EDF6;
  padding: 4px 8px;
  display: inline-block;
  border-radius: 24px;
  font-size: 0.875rem;
  color: #033a63;
  margin-bottom: 8px;
`
export default IndustryBoard;