import React from "react";
import firebase from "../../utility/firebase";
import * as actionTypes from "../actions/actionTypes";
import * as actionType from "../actionTypes/auth";
import {
  SignUp,
  Login,
  changePassWord,
  forgetPassWord,
} from "../../utility/api";
import {
  handleGoogleSignInToServer,
  handleGetIsRestricted,
} from "../../utility/authApi";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
const MySwal = withReactContent(Swal);

const provider = new firebase.auth.GoogleAuthProvider();

export const getForgetPassEmail = (account) => async (dispatch) => {
  dispatch({ type: actionTypes.START_GET_FORGET_PASS_EMAIL });
  const forget = await forgetPassWord(account);

  if (forget.data === "更新成功") {
    MySwal.fire({
      text: "重設密碼通知信已經寄送至您的信箱，請至信箱收信。",
    }).then(() => {
      dispatch({ type: actionTypes.FORGET_PASS_FAIL });
    });
  } else {
    MySwal.fire({
      text: "寄送重設密碼通知信時發生錯誤，請聯繫窗口陳小姐 07-3513121 分機 2415。",
    }).then(() => {
      dispatch({ type: actionTypes.FORGET_PASS_FAIL });
    });
  }
};

export const forgetPassWordHandler =
  (uid, oldPass, newPass) => async (dispatch) => {
    var sha1 = require("sha1");
    dispatch({ type: actionTypes.START_FORGET_PASS });
    const fixedNewPass = sha1(newPass);

    //api calling here
    //make a form data
    const data = new FormData();
    data.append("uid", uid);
    data.append("a", oldPass);
    data.append("p", fixedNewPass);

    const changed = await changePassWord(data);

    if (changed.data.data === "舊密碼有誤") {
      MySwal.fire({
        text: "原密碼輸入錯誤。",
      }).then(() => {
        dispatch({ type: actionTypes.FORGET_PASS_FAIL });
      });
    } else if (changed.data.status === 400) {
      MySwal.fire({
        text: "網路連線出現問題。",
      }).then(() => {
        dispatch({ type: actionTypes.FORGET_PASS_FAIL });
      });
    } else {
      MySwal.fire({
        text: "密碼修改成功。",
      }).then(() => {
        dispatch({ type: actionTypes.FORGET_PASS_SUCCESS });
        window.location.replace("/portal");
      });
    }
  };

export const changePassWordHandler = (oldPass, newPass) => async (dispatch) => {
  var sha1 = require("sha1");
  dispatch({ type: actionTypes.START_CHANGE_PASS });
  const fixedOldPass = sha1(oldPass);
  const fixedNewPass = sha1(newPass);

  //api calling here
  //make a form data
  const data = new FormData();
  data.append("uid", localStorage.getItem("uid"));
  data.append("a", fixedOldPass);
  data.append("p", fixedNewPass);

  const changed = await changePassWord(data);

  if (changed.data.data === "舊密碼有誤") {
    MySwal.fire({
      text: "原密碼輸入錯誤。",
    }).then(() => {
      dispatch({ type: actionTypes.CHANGE_PASS_FAIL });
    });
  } else if (changed.data.status === 400) {
    MySwal.fire({
      text: "網路連線出現問題。",
    }).then(() => {
      dispatch({ type: actionTypes.CHANGE_PASS_FAIL });
    });
  } else {
    MySwal.fire({
      text: "密碼修改成功。",
    }).then(() => {
      dispatch({ type: actionTypes.CHANGE_PASS_SUCCESS });
      window.location.replace("/portal");
    });
  }
};

export const signIn = (account, password) => async (dispatch) => {
  dispatch({ type: actionTypes.START_SIGN_IN });
  var sha1 = require("sha1");

  const fixedPass = sha1(password);

  //api calling here
  //make a form data
  const data = new FormData();
  data.append("a", account);
  data.append("p", fixedPass);

  const login = await Login(data);

  if (!login) {
    MySwal.fire({
      title: <p>登入</p>,
      text: "您輸入的帳號密碼有誤或帳號尚未啟用，請至信箱點選啟用連結。",
      clickConfirm: () => alert("test!"),
    }).then(() => {
      dispatch({ type: actionTypes.SING_IN_FAIL });
    });
  } else if (login === "此為OAuth帳號 登入false") {
    MySwal.fire({
      title: <p>登入</p>,
      text: "此帳號已用Google註冊，請透過下方Sign in with Google按鈕進行登入，謝謝！",
      clickConfirm: () => alert("test!"),
    }).then(() => {
      dispatch({ type: actionTypes.SING_IN_FAIL });
    });
  } else {
    if (login.UserInfo.UserId) {
      localStorage.setItem("isLogin", login.Login);
      localStorage.setItem("EncTicket", login.EncTicket);
      localStorage.setItem("uid", login.UserInfo.UserId);
      localStorage.setItem("name", login.UserInfo.Name);
      localStorage.setItem("acToken", login.OpenDataService.AccessToken);
      localStorage.setItem("ParkId", login.UserInfo.ParkId);

      login.PassPermissions.map((res) => {
        localStorage.setItem(res.PermissionId, res.PermissionId);
      });
      localStorage.setItem(
        "client",
        JSON.stringify(login.ParkPermissions.client)
      );
      localStorage.setItem(
        "indupark_upload",
        JSON.stringify(login.ParkPermissions.indupark_upload)
      );
      localStorage.setItem(
        "service_history",
        JSON.stringify(login.ParkPermissions.service_history)
      );
      localStorage.setItem("mir_su", login.SuperUser);
      localStorage.setItem("auL", login.GroupInfos[0].GroupId);

      const now = new Date();
      const expiration = now.getTime() + 60000 * 60;

      localStorage.setItem("now", now.getTime());
      localStorage.setItem("expiration", expiration);
      localStorage.setItem("RefreshToken", login.InduParkWeb.RefreshToken);
      localStorage.setItem("InduParkWebAcToken", login.InduParkWeb.AccessToken);

      dispatch({ type: actionTypes.SIGN_IN_SUCCESS, permission: "" });

      const Toast = MySwal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1000,
        timerProgressBar: true,
      });

      // 密碼超過90天未更新，跳出提示
      const nowDate = new Date().getTime();
      const lastChangePass = login.Change_Pw_Date;
      const lastChangePassExpiration =
        new Date(lastChangePass).getTime() + 1000 * 60 * 60 * 24 * 90;

      if (nowDate > lastChangePassExpiration) {
        MySwal.fire({
          title: "登入成功",
          text: "您的密碼超過 90 天沒有更新，是否更改密碼？",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#25758A",
          cancelButtonColor: "#4f5155",
          confirmButtonText: "修改密碼",
          cancelButtonText: "略過",
          allowOutsideClick: false,
          allowEscapeKey: false,
        }).then((result) => {
          if (result.dismiss === "cancel") {
            window.location.assign(window.location.origin + "/portal");
          }
          if (result.isConfirmed) {
            window.location.assign(window.location.origin + "/change_password");
          }
        });
      } else {
        Toast.fire({
          icon: "success",
          title: "登入成功",
        }).then(() => {
          window.location.assign(window.location.origin + "/portal");
        });
      }
    }
  }
};

export const singUp = (params) => async (dispatch) => {
  dispatch({ type: actionTypes.START_SIGN_UP });
  const file = new FormData();
  file.append("Account", params.account);
  file.append("Password", params.password);
  file.append("Name", params.name);
  file.append("ParkId", params.parkId);
  file.append("CompanyName", params.company);
  file.append("BusinessNo", params.businessId);

  const logUp = await SignUp(file);

  if (logUp.status !== 200) {
    MySwal.fire({
      text: "註冊發生錯誤或帳號已存在，請重新註冊或聯繫窗口陳小姐 07-3513121 分機 2415。",
    }).then(() => {
      dispatch({ type: actionTypes.SING_UP_FAIL });
      window.location.reload();
    });
  } else {
    MySwal.fire({
      text: "註冊成功，請至您的信箱收信並啟用您的帳號。",
    }).then(() => {
      dispatch({ type: actionTypes.SIGN_UP_SUCCESS });
      window.location.reload();
    });
  }
};

export const isLocalSign = () => (dispatch) => {
  dispatch({ type: actionTypes.START_GET_AUTH_FROM_LOCAL });

  const isLogin = localStorage.getItem("isLogin");

  if (isLogin === "true") {
    dispatch({ type: actionTypes.SIGN_IN_SUCCESS });
  } else {
    dispatch({ type: actionTypes.GET_AUTH_FROM_LOCAL_FAIL });
  }
};

export const logoutHandler = () => (dispatch) => {
  window.location.replace("/portal");
  localStorage.removeItem("isLogin");
  localStorage.removeItem("EncTicket");
  localStorage.removeItem("acToken");
  localStorage.removeItem("ParkPermission");
  localStorage.removeItem("uid");
  localStorage.removeItem("name");
  localStorage.removeItem("mir_su");
  localStorage.removeItem("1");
  localStorage.removeItem("2");
  localStorage.removeItem("3");
  localStorage.removeItem("4");
  localStorage.removeItem("5");
  localStorage.removeItem("6");
  localStorage.removeItem("7");
  localStorage.removeItem("8");
  localStorage.removeItem("9");
  localStorage.removeItem("10");
  localStorage.removeItem("11");
  localStorage.removeItem("12");
  localStorage.removeItem("13");
  localStorage.removeItem("14");
  localStorage.removeItem("15");
  localStorage.removeItem("16");
  localStorage.removeItem("17");
  localStorage.removeItem("18");
  localStorage.removeItem("client");
  localStorage.removeItem("ParkId");
  localStorage.removeItem("service_history");
  localStorage.removeItem("indupark_upload");
  localStorage.removeItem("auL");
  localStorage.removeItem("RefreshToken");
  localStorage.removeItem("InduParkWebAcToken");
  dispatch({ type: actionTypes.LOG_OUT });

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 1000,
    timerProgressBar: true,
  });

  Toast.fire({
    icon: "success",
    title: "已登出",
  });
  // setTimeout(()=>{

  // }, 10000)
};

const startGoogleSignIn = () => {
  return {
    type: actionType.START_GOOGLE_SIGN_IN,
  };
};

const googleSignInSuccess = (info) => {
  if (info.UserInfo.UserId) {
    localStorage.setItem("isLogin", info.Login);
    localStorage.setItem("EncTicket", info.EncTicket);
    localStorage.setItem("uid", info.UserInfo.UserId);
    localStorage.setItem("name", info.UserInfo.Name);
    localStorage.setItem("acToken", info.OpenDataService.AccessToken);
    localStorage.setItem("ParkId", info.UserInfo.ParkId);
    info.PassPermissions.map((res) => {
      localStorage.setItem(res.PermissionId, res.PermissionId);
    });
    localStorage.setItem("client", JSON.stringify(info.ParkPermissions.client));
    localStorage.setItem(
      "indupark_upload",
      JSON.stringify(info.ParkPermissions.indupark_upload)
    );
    localStorage.setItem(
      "service_history",
      JSON.stringify(info.ParkPermissions.service_history)
    );
    localStorage.setItem("mir_su", info.SuperUser);
    localStorage.setItem("auL", info.GroupInfos[0].GroupId);

    const now = new Date();
    const expiration = now.getTime() + 60000 * 60;
    localStorage.setItem("RefreshToken", info.InduParkWeb.RefreshToken);
    localStorage.setItem("InduParkWebAcToken", info.InduParkWeb.AccessToken);

    localStorage.setItem("now", now.getTime());
    localStorage.setItem("expiration", expiration);
    const Toast = MySwal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 1000,
      timerProgressBar: true,
    });

    Toast.fire({
      icon: "success",
      title: "登入成功",
    }).then(() => {
      window.location.assign(window.location.origin + "/portal");
    });
    return {
      type: actionType.GOOGLE_SIGN_IN_SUCCESS,
    };
  }
};

const googleSignInFail = (err) => {
  return {
    type: actionType.GOOGLE_SIGN_IN_FAIL,
  };
};

export const handleGoogleSignIn = (account, name) => {
  return (dispatch) => {
    dispatch(startGoogleSignIn());
    handleGoogleSignInToServer(account, name)
      .then((res) => {
        const { Info, description, status } = res;
        if (
          status === 200 &&
          description === "[OAuthSignUpAndSignIn] SignUp and SignIn"
        ) {
          dispatch(googleSignInSuccess(Info[0]));
        } else {
          MySwal.fire({
            title: <p>登入</p>,
            text: "您輸入的帳號密碼有誤或帳號尚未啟用，請至信箱點選啟用連結。",
            clickConfirm: () => alert("test!"),
          }).then(() => {
            dispatch(googleSignInFail());
          });
        }
      })
      .catch((err) => {
        dispatch(googleSignInFail(err));
      });
  };
};

const start_fetch_restricted_list = () => {
  return {
    type: actionType.START_FETCH_RESTRICTED_NAME,
  };
};

const fetch_restricted_success = (data) => {
  return {
    type: actionType.FETCH_RESTRICTED_NAME_SUCCESS,
    data: data,
  };
};

const fetch_restricted_fail = (err) => {
  return {
    type: actionType.FETCH_RESTRICTED_NAME_FAIL,
    err: err,
  };
};

export const handle_check_is_restricted = () => {
  return (dispatch) => {
    dispatch(start_fetch_restricted_list());
    handleGetIsRestricted()
      .then((res) => {
        if (res) {
          dispatch(fetch_restricted_success(res.data));
        } else {
          dispatch(fetch_restricted_fail("fetch restricted list went wrong."));
        }
      })
      .catch((err) => {
        dispatch(fetch_restricted_fail(err));
      });
  };
};
