import React, { useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import Swal from "sweetalert2";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
// import Chip from "@material-ui/core/Chip";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import subscribeBg from "../../assests/images/subscribe-bg.png";
import Spinner from "../../components/Spinner/Spinner";
import "./style.scss";
import TableComponent from "./TableComponent";
import {
  handleGetCompany,
  handleGetIndustry,
} from "../../store/actions/subscribe";
import Breadcrumb from "../../components/Breadcrumbs";

const SubscribePush = () => {

  const dispatch = useDispatch();
  const [state, setState] = React.useState({
    value: "all",
    target: "industry",
  });
  const {
    companyLoading,
    companyInfo,
    industryLoading,
    industryInfo,
  } = useSelector((state) => state.subscribe);
  const handleRequests = React.useCallback(
    (userId) => {
      Promise.all([
        dispatch(handleGetCompany(userId)),
      ]);
    },
    [dispatch]
  );
  const handleIndustryRequests = React.useCallback(
    (userId) => {
      Promise.all([
        dispatch(handleGetIndustry(userId)),
      ]);
    },
    [dispatch]
  );

  const history = useHistory();
  useEffect(() => {
    if (localStorage.getItem("isLogin") !== "true") {
      Swal.fire({
        title: "提醒",
        text: "尚未登入，請登入或註冊帳號。",
        showDenyButton: true,
        denyButtonText: `前往登入`,
        denyButtonColor: "#a8a8a8",
        showCloseButton: true,
        confirmButtonText: "前往註冊",
        confirmButtonColor: "#25758A",
        showCancelButton: false,
      }).then((res) => {
        const { isConfirmed, isDenied, isDismissed } = res;
        if (isConfirmed) history.push("/sign-up");
        if (isDenied) history.push("/sign-in");
        if (isDismissed) history.push("/portal");
      });
    } else {
      const userId = localStorage.getItem("uid");
      if (state.target === "company" && companyInfo.length === 0) {
        handleRequests(userId)
      } else if (state.target === "industry" && industryInfo.length === 0) {
        handleIndustryRequests(userId)
      }
    }
  }, [state.target]);

  const [industryInfoResult, setIndustryInfoResult] = React.useState({
    News: [],
    ForeignTrade: [],
    StockRevenue: [],
    Patent: [],
    GovProject: [],
  });
  useEffect(() => {
    if (industryInfo) {
      if (state.value === "all") {
        let obj = {};
        let keys = ["News", "ForeignTrade", "StockRevenue", "Patent", "GovProject"]
        for (let i = 0; i < keys.length; i++) {
          const name = keys[i];
          if (industryInfo?.[name] && Array.isArray(industryInfo?.[name])) {
            obj[name] = industryInfo?.[name];
          }
        }
        setIndustryInfoResult(obj);
      } else {
        let obj = {};
        let keys = ["News", "ForeignTrade", "StockRevenue", "Patent", "GovProject"]
        for (let i = 0; i < keys.length; i++) {
          const name = keys[i];
          if (industryInfo?.[name] && Array.isArray(industryInfo?.[name])) {
            const filterData = industryInfo?.[name].filter((item) => {
              return item.induFourStr === state.value;
            });
            obj[name] = filterData;
          }
        }
        setIndustryInfoResult(obj);
      }
    }
  }, [state.value, industryInfo]);
  // useEffect(() => {
  //   if(industryInfo?.IndustryList){
  //     setState({
  //       ...state,
  //       value: industryInfo?.IndustryList[0]?.IndustryFourCode
  //     })
  //   }
  // }, [industryInfo?.IndustryList]);

  return <div className="subscribe" id="subscribe">
    <div class="top-breadcrumb">
      <Breadcrumb route={[{
        name: "關注廠商 / 產業訂閱",
      }]} />
    </div>
    <div className="subscribe-bg" style={{
      backgroundImage: `url(${subscribeBg})`
    }}>
      <div class="subscribe-container" style={{
        marginTop: "36px",
      }}>
        {/* <a href="/custom_dashboard" target="_blank">自訂關注廠商儀表板</a> */}
        <CardBox>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div className="tab-group">
                {[
                  {
                    name: "訂閱廠商",
                    value: "company",
                  },
                  {
                    name: "訂閱產業",
                    value: "industry",
                  },
                ].map((item) => {
                  return (
                    <button
                      className={
                        state.target === item.value
                          ? "tab active"
                          : "tab"
                      }
                      onClick={() => {
                        setState((prev) => {
                          return {
                            ...prev,
                            target: item.value,
                          };
                        });
                      }}
                    >
                      {item.name}
                    </button>
                  );
                })}
              </div>
            </Grid>
          </Grid>
        </CardBox>
        {
          (companyLoading || industryLoading) &&
          <div>
            <Spinner />
          </div>
        }
        {
          state.target === "industry" && !industryLoading && <>
            <Box display="flex" justifyContent={"flex-end"} mb={2}>
              <a class="event-btn" href="/custom_industry_dashboard" target="_blank">設定自訂關注產業</a>
            </Box>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Card>
                  <div class="card-list">
                    <SearchDashboard
                      fullWidth
                      variant="outlined"
                      disabled={state.target === "company"}
                    >
                      {/* <InputLabel for="select_dashboard"></InputLabel> */}
                      <Select
                        native
                        id="select_dashboard"
                        size="small"
                        value={state.value}
                        onChange={(e) => {
                          setState((prev) => {
                            return {
                              ...prev,
                              value: e.target.value,
                            };
                          });
                        }}
                        className="dashboard-header-selection"
                      >
                        <option
                          value={"all"}>
                          全部
                        </option>
                        {
                          industryInfo && industryInfo?.IndustryList && Array.isArray(industryInfo?.IndustryList) &&
                          industryInfo?.IndustryList.map((item) => {
                            return <option
                              value={item.IndustryFourCode}>
                              {item.IndustryFourCode}-{item.Name}
                            </option>
                          })
                        }
                      </Select>
                    </SearchDashboard>
                  </div>
                </Card>
              </Grid>
              {/* <Grid item xs={12}>
                <Card>
                  <div class="card-list">
                    <h3>訂閱的產業</h3>
                    <Box>
                      {
                        industryInfo && industryInfo?.IndustryList && Array.isArray(industryInfo?.IndustryList) &&
                          industryInfo?.IndustryList.map((item) => {
                          return <Chip style={{ marginBottom: "8px", marginRight: "8px", border: "1px solid", color: "rgb(51 75 97)", background: "transparent" }} label={`${item.IndustryFourCode}-${item.Name}`} />
                        })
                      }
                    </Box>
                    <Box display="flex" justifyContent={"flex-end"}>
                      <a class="event-btn" href="/" target="_blank"></a>
                    </Box>
                  </div>
                </Card>
              </Grid> */}
              {/* 最新新聞 */}
              <Grid item xs={12} md={6}>
                <Card>
                  <div class="card">
                    <div class="card-header">
                      最新新聞
                    </div>
                    <div class="card-body">
                      <NewsWrapper>
                        {
                          industryInfoResult?.News?.length > 0 ? industryInfoResult.News.map((item) => {
                            return <NewsBox className="news-item">
                              <Box display="flex" justifyContent="space-between">
                                <p> <a href={`/factory_info/basic_info/${item.BusinessNo}`} target="_blank" rel="noopener noreferrer">
                                  {item.CompanyName}
                                </a></p>
                                <p>{new Date(item.Date).toLocaleDateString("sv-SE")}</p>
                              </Box>
                              <div style={{ overflow: "hidden", textOverflow: "ellipsis", width: '100%' }}>
                                <a href={item.Link} target="_blank" rel="noopener noreferrer" className="news-item-title news">
                                  {item.Title}
                                </a>
                              </div>
                            </NewsBox>
                          }) : <Box display="flex" justifyContent="center">
                            <NoDataBadge>近期無相關新聞</NoDataBadge>
                          </Box>
                        }
                      </NewsWrapper>
                    </div>
                  </div>
                </Card>
              </Grid>
              {/* 進出口級距 */}
              <Grid item xs={12} md={6}>
                <Card>
                  <div class="card">
                    <div class="card-header">
                      進出口級距
                    </div>
                    <div class="card-body">
                      <TableComponent
                        title="ForeignTrade"
                        data={industryInfoResult?.ForeignTrade?.length > 0 ? industryInfoResult.ForeignTrade : []}
                        mapKey={"BusinessNo"}
                        columns={[{
                          name: "年度月份",
                          key: "Year_ROC",
                        }, {
                          name: "進口級距",
                          key: "Trade_I_Level",
                        }, {
                          name: "出口級距",
                          key: "Trade_E_Level",
                        }, {
                          name: "公司名稱",
                          key: "CompanyName",
                        }]}
                      />
                    </div>
                  </div>
                </Card>
              </Grid>
              {/* 上市櫃營收 */}
              <Grid item xs={12} md={6}>
                <Card>
                  <div class="card">
                    <div class="card-header">
                      上市櫃營收
                    </div>
                    <div class="card-body">
                      <TableComponent
                        title="StockRevenue"
                        data={industryInfoResult?.StockRevenue?.length > 0 ? industryInfoResult.StockRevenue : []}
                        mapKey={"BusinessNo"}
                        columns={[{
                          name: "季度",
                          key: "Date",
                        }, {
                          name: "營收 (千元)",
                          key: "Revenue_Net",
                        }, {
                          name: "公司名稱",
                          key: "CompanyName",
                        }]}
                      />
                    </div>
                  </div>
                </Card>
              </Grid>
              {/* 政府計畫 */}
              <Grid item xs={12} md={6}>
                <Card>
                  <div class="card">
                    <div class="card-header">
                      政府計畫
                    </div>
                    <div class="card-body">
                      <TableComponent
                        title="GovProject"
                        data={industryInfoResult?.GovProject?.length > 0 ? industryInfoResult.GovProject : []}
                        mapKey={"BusinessNo"}
                        columns={[{
                          name: "年度",
                          key: "Year",
                        }, {
                          name: "計畫別",
                          key: "Type",
                        }, {
                          name: "計畫名稱",
                          key: "PlanName",
                        }, {
                          name: "公司名稱",
                          key: "CompanyName",
                        }]}
                      />
                    </div>
                  </div>
                </Card>
              </Grid>
              {/* 專利 */}
              <Grid item xs={12}>
                <Card>
                  <div class="card">
                    <div class="card-header">
                      專利
                    </div>
                    <div class="card-body">
                      <TableComponent
                        title="Patent"
                        data={industryInfoResult?.Patent?.length > 0 ? industryInfoResult.Patent : []}
                        mapKey={"BusinessNo"}
                        columns={[{
                          name: "專利號",
                          key: "DocNo",
                        }, {
                          name: "專利名稱",
                          key: "ChineseName",
                        }, {
                          name: "獲證日期",
                          key: "PubDate",
                        }, {
                          name: "公司名稱",
                          key: "CompanyName",
                        }]}
                      />
                    </div>
                  </div>
                </Card>
              </Grid>
            </Grid>
          </>
        }
        {
          state.target === "company" && !companyLoading && <>
            <Box display="flex" justifyContent={"flex-end"} mb={2}>
              <a class="event-btn" href="/custom_dashboard" target="_blank">設定自訂關注廠商</a>
            </Box>
            <Grid container spacing={3}>
              {/* 最新新聞 */}
              <Grid item xs={12} md={6}>
                <Card>
                  <div class="card">
                    <div class="card-header">
                      最新新聞
                    </div>
                    <div class="card-body">
                      <NewsWrapper>
                        {
                          companyInfo && companyInfo?.News && Array.isArray(companyInfo?.News) &&
                          companyInfo?.News.map((item) => {
                            return <NewsBox className="news-item">
                              <Box display="flex" justifyContent="space-between">
                                <p> <a href={`/factory_info/basic_info/${item.BusinessNo}`} target="_blank" rel="noopener noreferrer">
                                  {item.CompanyName}
                                </a></p>
                                <p>{new Date(item.Date).toLocaleDateString("sv-SE")}</p>
                              </Box>
                              <div style={{ overflow: "hidden", textOverflow: "ellipsis", width: '100%' }}>
                                <a href={item.Link} target="_blank" rel="noopener noreferrer" className="news-item-title news">
                                  {item.Title}
                                </a>
                              </div>
                            </NewsBox>
                          })
                        }
                      </NewsWrapper>
                    </div>
                  </div>
                </Card>
              </Grid>
              {/* 進出口級距 */}
              <Grid item xs={12} md={6}>
                <Card>
                  <div class="card">
                    <div class="card-header">
                      進出口級距
                    </div>
                    <div class="card-body">
                      <TableComponent
                        title="ForeignTrade"
                        data={companyInfo && Array.isArray(companyInfo?.ForeignTrade) ? companyInfo?.ForeignTrade : []}
                        mapKey={"BusinessNo"}
                        columns={[{
                          name: "年度月份",
                          key: "Year_ROC",
                        }, {
                          name: "進口級距",
                          key: "Trade_I_Level",
                        }, {
                          name: "出口級距",
                          key: "Trade_E_Level",
                        }, {
                          name: "公司名稱",
                          key: "CompanyName",
                        }]}
                      />
                    </div>
                  </div>
                </Card>
              </Grid>
              {/* 上市櫃營收 */}
              <Grid item xs={12} md={6}>
                <Card>
                  <div class="card">
                    <div class="card-header">
                      上市櫃營收
                    </div>
                    <div class="card-body">
                      <TableComponent
                        title="StockRevenue"
                        data={companyInfo && Array.isArray(companyInfo?.StockRevenue) ? companyInfo?.StockRevenue : []}
                        mapKey={"BusinessNo"}
                        columns={[{
                          name: "季度",
                          key: "Date",
                        },
                        {
                          name: "營收 (千元)",
                          key: "Revenue_Net",
                        }, {
                          name: "公司名稱",
                          key: "CompanyName",
                        }]}
                      />
                    </div>
                  </div>
                </Card>
              </Grid>
              {/* 政府計畫 */}
              <Grid item xs={12} md={6}>
                <Card>
                  <div class="card">
                    <div class="card-header">
                      政府計畫
                    </div>
                    <div class="card-body">
                      <TableComponent
                        title="GovProject"
                        data={companyInfo && Array.isArray(companyInfo?.GovProject) ? companyInfo?.GovProject : []}
                        mapKey={"BusinessNo"}
                        columns={[{
                          name: "年度",
                          key: "Year",
                        }, {
                          name: "計畫別",
                          key: "Type",
                        }, {
                          name: "計畫名稱",
                          key: "PlanName",
                        }, {
                          name: "公司名稱",
                          key: "CompanyName",
                        }]}
                      />
                    </div>
                  </div>
                </Card>
              </Grid>
              {/* 專利 */}
              <Grid item xs={12}>
                <Card>
                  <div class="card">
                    <div class="card-header">
                      專利
                    </div>
                    <div class="card-body">
                      <TableComponent
                        title="Patent"
                        data={companyInfo && Array.isArray(companyInfo?.Patent) ? companyInfo?.Patent : []}
                        mapKey={"BusinessNo"}
                        columns={[{
                          name: "專利號",
                          key: "DocNo",
                        }, {
                          name: "專利名稱",
                          key: "ChineseName",
                        }, {
                          name: "獲證日期",
                          key: "PubDate",
                        }, {
                          name: "公司名稱",
                          key: "CompanyName",
                        }]}
                      />
                    </div>
                  </div>
                </Card>
              </Grid>
            </Grid>
          </>
        }

      </div>
    </div>
  </div>
}

const CardBox = styled(Box)`
  background-color: white;
  border-radius: 8px;
  margin-bottom: 24px;
  padding: 16px 24px;
`;
const SearchDashboard = styled(FormControl)`
  margin-top: 0px !important;
  @media (max-width: 768px) {
    margin-top: 24px !important;
  }
`
const Card = styled(Box)`
  .card-list {
    background: white;
    padding: 16px;
    border-radius: 16px;
    h3 {
      text-align: center;
      color: #11304A;
      font-size: 1rem;
      font-weight: 500;
      line-height: 1.2;
      margin-bottom: 8px;
    }
  }
  .card {
    border-radius: 16px;
    box-shadow: 0px 2px 1px -1px #00000033;
    background-color: white;
    overflow: hidden;
    width: 100%;
    height: 300px;
    @media (max-width: 769px) {
      height: auto;
      max-height: 450px;
    }
    display: flex;
    flex-direction: column;
    .card-body {
      padding: 0px 16px !important;
      display: flex;
      flex-direction: column;
      height: calc(100% - 48px);
      overflow: auto;
      hr {
        margin-top: auto !important;
      }
    }
    .card-header {
      padding: 12px 24px;
      color: #11304A;
      border: 0px;
      background-color: transparent;
      text-align: center;
      .card-title {
        font-size: 1rem;
        font-weight: 500;
        line-height: 1.2;
        margin-bottom: 0px;
      }
    }
  }
`;
const NewsWrapper = styled(Box)`
  height: calc(100%);
  overflow: auto;
`
const NewsBox = styled(Box)`
  padding-top: 8px;
  padding-bottom: 16px;
  padding-right: 4px;
  border-bottom: 1px solid #0000001A;
  p a {
    font-size: 0.875rem;
    color: #00000099;
    margin-bottom: 8px;
    text-decoration: none;
    &:hover {
      opacity: 0.8;
    }
  }
  a.news {
    font-size: 1rem;
    color: #11304A;
    text-decoration: underline;
    white-space: nowrap;
    &:hover {
      color: #11304A;
      text-decoration: none;
    }
  }
`
const NoDataBadge = styled.p`
  background: #E6EDF6;
  padding: 4px 8px;
  display: inline-block;
  border-radius: 24px;
  font-size: 0.875rem;
  color: #033a63;
  margin-bottom: 8px;
  marin-top: 24px;
`
export default SubscribePush;