import axios from "axios";
import { Base64 } from "js-base64";
import { baseUrl, loginbaseUrl } from "../constants/endpoints";

const headers = {
  "Access-Control-Allow-Origin": "*",
  "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
  "Access-Control-Allow-Headers":
    "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers",
  "Content-Type": "application/json",
  Authorization: localStorage.getItem("acToken"),
};

export const get_popular_keyword = (data) => {
  return axios({
    proxy: true,
    method: "get",
    url: `${loginbaseUrl}api/ProductSearch/ManualKeyword`,
  })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};

export const getRecentPopularKeyword = (searchType) => {
  let originDate = new Date();
  let endDate = originDate.toJSON().substring(0, 10);
  let startDate = new Date(originDate - 2592000000).toJSON().substring(0, 10);
  return axios({
    proxy: true,
    method: "get",
    // url: `${loginbaseUrl}api/ProductSearch/ManualKeyword`,
    url: `${loginbaseUrl}api/ProductSearch/GetKeywordLogByDate?StartTime=${startDate}&EndTime=${endDate}&Type=${searchType}`,
  })
    .then((res) => {
      res.data.data.map((item, index) => {
        if(item=='' || item.includes("@")){
            res.data.data.splice(index, 1);
        }
      })
      return res;
    })
    .catch((err) => {
      return err;
    });
};
