import React, { useState, useEffect } from "react";
import { Collapse, Icon } from "@material-ui/core";
import { Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCrown, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import Skeleton from '@material-ui/lab/Skeleton';
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import styled from "styled-components";

import { southArea } from "../../constants/parks";

import "./CustomizedCard.css";
import "./styles.scss";

const CustomizedCardV3 = (props) => {
  const {
    isSimple,
    title,
    isOpen, // 卡片的開啟狀態
    toggleCard, // 卡片的開啟狀態切換
    withMinHeight,
    referenceType,
    rankingList,
    loading,
    noSouthArea,
    eventHandler,
    isGHG,
    isMarker,
  } = props;
  const parkId = window.location.pathname.split("/")[window.location.pathname.split("/").length -1];
  const currentPage = window.location.pathname.split("/")[window.location.pathname.split("/").length - 2];

  const { referenceInfo, innerWidth } = useSelector((state) => state.app);

  const openReferenceInfoAlert = (type) => {
    let content = "";
    if (referenceInfo[type] === "") {
      console.log("I was called!");
      Swal.fire({
        title: "引用資料來源",
        text: "資料正在建置中 ... ",
      });
    } else {
      try {
        referenceInfo[type]
          .split("。")
          .map(
            (referenceItem) =>
              (content +=
                "<p style='text-align:left'>" + referenceItem + "</p>")
          );
        Swal.fire({
          title: "引用資料來源",
          html: content,
        });
      } catch (err) {
        Swal.fire({
          title: "引用資料來源",
          text: "資料正在建置中 ... ",
        });
      }
    }
  };

  const [shouldCardOpen, setShouldCardOpen] = useState(false);
  useEffect(()=>{
      if(isOpen){
          setShouldCardOpen(true);
      }else{
          setShouldCardOpen(false)
      }
  },[isOpen])

  const toggleCardHandler = () => {
      toggleCard();
      setShouldCardOpen(prvState=> !prvState);
  };



  if (isSimple) {
    return <div className="simple-card">{props.children}</div>;
  } else if (southArea.filter(park => park.ParkId === parkId).length > 0 && currentPage === "park_dashboard" && noSouthArea) {
    return <div /> 
  }
  else {
    return (
      <div className="customized-card">
        <Card>
          {isGHG ? (
            <Card.Header className="card-title" style={{ borderBottom: "solid 2px #FFF" }}>
              <div>
                {"企業ESG資訊揭露"}
              </div>
            </Card.Header>) : null}
          <Card.Header className="card-title">
            {!isMarker ?
            <div>
              <h3>{title}</h3>
              {toggleCard && referenceType && ( // 如果有 toggleCard 就顯示（位置差異）
                <button
                  title={`開啟${title}引用資料來源彈出視窗`}
                  style={{ color: "white", borderRadius: "100%", marginLeft: "4px" }}
                  onClick={() => openReferenceInfoAlert(referenceType)}  
                > 
                  <FontAwesomeIcon
                    className="icon-reference"
                    icon={faInfoCircle}
                  />
                </button>
              )}
            </div>
            :
            <TradeMarker>
              <h3>{title}</h3>
              <a style={{fontSize: '10px', marginTop:'5px'}}></a>
              {toggleCard && referenceType && ( // 如果有 toggleCard 就顯示（位置差異）
                <button
                  title={`開啟${title}引用資料來源彈出視窗`}
                  style={{ color: "white", borderRadius: "100%", marginLeft: "4px" }}
                  onClick={() => openReferenceInfoAlert(referenceType)}  
                > 
                  <FontAwesomeIcon
                    className="icon-reference"
                    icon={faInfoCircle}
                  />
                </button>
              )}
            </TradeMarker>
            }
            <div>
              {!toggleCard && referenceType && ( // 如果有 toggleCard 就不顯示（位置差異）
                <button
                  title={`開啟${title}引用資料來源彈出視窗`}
                  style={{ color: "white", borderRadius: "100%" }}
                  onClick={() => openReferenceInfoAlert(referenceType)}
                > 
                <FontAwesomeIcon
                  className="icon-reference"
                  icon={faInfoCircle}
                />
                </button>
              )}
              {
                toggleCard && <button title={shouldCardOpen? `點擊可折疊${props.title}卡片`: `點擊可展開${props.title}卡片`} aria-expanded={shouldCardOpen} style={{ color: "white" }} onClick={toggleCardHandler}>
                  <Icon>
                    {shouldCardOpen ? "expand_more" : "expand_less"}
                  </Icon>
                </button>
              }
            </div>
          </Card.Header>
          {/* <Card.Header className='card-title'>{title}<Icon onClick={toggleCardHandler} >{shouldCardOpen ? "expand_more":"expand_less"}</Icon></Card.Header> */}
          <Collapse in={toggleCard? shouldCardOpen : true}>
            <Card.Body
              className="card-body"
              style={withMinHeight ? { minHeight: `${withMinHeight}px` } : {}}
            >
              {loading ? 
                <div className="loading-container">
                    <Skeleton variant="text" height={50}/>
                    <Skeleton variant="text" height={50}/>
                    <Skeleton variant="text" height={50}/>
                    <Skeleton variant="text" height={50}/>
                    <Skeleton variant="text" height={50}/>
                    <Skeleton variant="text" height={50}/>
                </div>
              : props.children}
              {rankingList && rankingList.length > 0 && !loading && (
                <div className="ranking">
                  {rankingList
                    .filter(
                      (item) => item.label !== "其他" && item.label !== "0"
                    )
                    .sort((a, b) => b.y - a.y)
                    .slice(0, 3)
                    .map((item, index) => (
                      <div className="ranking-item" key={item.tag}>
                        <div className="ranking-header">
                          <p className="ranking-number">{index + 1}</p>
                          <FontAwesomeIcon className="icon" icon={faCrown} />
                        </div>
                        {eventHandler ? (<button onClick={() => eventHandler(item.tag || item.label, item.label)}>{item.label}</button>) : ( <p>{item.label}</p>)}                      
                      </div>
                    ))}
                </div>
              )}
            </Card.Body>
          </Collapse>
        </Card>
      </div>
    );

  }
};

const TradeMarker = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start !important;
  flex-direction: column;
`;
export default React.memo(CustomizedCardV3);