import React from "react";
import { useSelector } from "react-redux";

import { directToNewTabHandler } from "../../../utility/common";

import "./styles.scss";

export default () => {
  const { searchHistory } = useSelector((state) => state.app);

  if (searchHistory.length === 0 || !Array.isArray(searchHistory)) {
    return null;
  } else {
    return (
      <div className="search-history-wrapper">
        <p>
          搜尋紀錄：
          {searchHistory?.map((item, index) => (
            <>
              <button
                onClick={() =>
                  directToNewTabHandler(
                    item.isEdited
                      ? "factory_info/updated_companyinfo"
                      : "factory_info/basic_info",
                    item.BusinessNo
                  )
                }>
                {item.CompanyName}
              </button>
            </>
          ))}
        </p>
      </div>
    );
  }
};
