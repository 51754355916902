import React from "react";
import styled from "styled-components";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

import Footer from "../../components/Footer/Footer";
import intro from "../../assests/intro.jpg";
import contact from "../../assests/contact.jpg";
import policy from "../../assests/policy.jpg";
import service from "../../assests/service.jpg";
import rsz_intro from "../../assests/rsz_intro.jpg";
import "./styles.scss";
import * as authActions from "../../store/actions/auth";

const MySwal = withReactContent(Swal);

const About = (props) => {
  const { toggleSignUp, signInModalOpen, signUpFirst } = props;
  const history = useHistory();
  const dispatch = useDispatch();

  const callTo = () => {
    window.location.href = "tel:07-3513121";
  };

  if (localStorage.getItem("uid") === "idpark" && !signInModalOpen) {
    MySwal.fire({
      html: "<p>提醒您目前使用之測試帳號將於近期內失效。</p><p>請聯繫服務窗口陳小姐:07-3513121分機2415<p></p>申請專屬於您的帳號。</p>",
      showCancelButton: true,
      showCloseButton: true,
      confirmButtonText: "前往註冊",
      cancelButtonText: "前往登入",
    }).then((res) => {
      if (res.value) {
        dispatch(authActions.logoutHandler());
        if (window.innerWidth >= 1024) {
          signUpFirst();
          toggleSignUp();
        } else {
          signUpFirst();
          history.push("/sign-up");
        }
      } else {
        history.push("/sign-in");
      }
    });
  }

  return (
    <div className="about">
      <div className="about-header">
        <h1>關於平台</h1>
      </div>
      <div className="first-section">
        <img
          className="first-section-img"
          src={window.innerWidth > 1024 ? rsz_intro : intro}
          alt="main-ing"
          loading={"lazy"}
        />
        <div className="first-section-content">
          <AboutText>
            為了協助產業園區服務中心與相關產業輔導團隊優化管理與產業輔導效率，並活化應用產業與廠商資訊，工業局自民108年起委託財團法人金屬工業研究發展中心建置「產業園區廠商情資平台」。透過平台的加值功能支援，加速回應區內廠商解決面臨之研發、經營、行銷等問題。
          </AboutText>
          <AboutText>備註 : 本平台資料僅供參考，實際以資料來源為準。</AboutText>
        </div>
      </div>
      <div className="second-section">
        <div className="second-section-content">
          <div className="second-section-content-head">
            <h3>服務概述</h3>
          </div>
          <img
            className="second-section-img-mobile"
            src={service}
            alt="second-img"
            loading={"lazy"}
          />
          <div className="second-section-content-content">
            <AboutText>
              本平台介接40類以上產業Open
              Data，資料透過交叉分析歸納，透過視覺化互動式網頁方式提供使用者以個人電腦與行動裝置操作使用。平台內容分為3大類支援園區管理與廠商服務:
            </AboutText>
            <AboutText>
              1.廠商需求類:平台內建產業園區產學計畫廠商需求資料供參閱(需具備權限)
            </AboutText>
            <AboutText>
              2.產輔工具類:協助服務中心找補助(政府計畫)、找產品(供應商)、找產學(學界技術)
            </AboutText>
            <AboutText>
              3.園區動態類:彙集工業局轄管62處產業園區廠商動態資料，以儀表板方式呈現園區廠商型貌，並提供廠商綜合資訊一鍵查詢，快速掌握廠動態
            </AboutText>
          </div>
        </div>
        <img
          className="second-section-img"
          src={service}
          alt="second-img"
          loading={"lazy"}
        />
      </div>
      <div className="third-section">
        <img
          className="third-section-img"
          src={policy}
          alt="second-img"
          loading={"lazy"}
        />
        <div className="third-section-content">
          <div className="third-section-content-head">
            <h3>使用規範</h3>
          </div>
          <img
            className="third-section-img-mobile"
            src={policy}
            alt="second-img"
            loading={"lazy"}
          />
          <div className="third-section-content-content">
            <AboutText>
              為利各界廣為利用網站資料，產業園區廠商情資平台網站上刊載之所有資料與素材，其得受著作權保護之範圍，以無償、非專屬，得再授權之方式提供公眾使用，使用者得不限時間及地域，重製、改作、編輯、公開傳輸或為其他方式之利用，開發各種產品或服務（簡稱加值衍生物），此一授權行為不會嗣後撤回，使用者亦無須取得機關之書面或其他方式授權；然使用時，應註明出處。
            </AboutText>
          </div>
        </div>
      </div>
      <div className="forth-section">
        <div className="forth-section-content">
          <div className="forth-section-content-head">
            <h3>聯絡我們</h3>
          </div>
          <img
            className="forth-section-img-mobile"
            src={contact}
            alt="second-img"
            loading={"lazy"}
          />
          <div className="forth-section-content-content">
            <AboutText>
              維護單位:財團法人金屬工業研究發展中心 知識應用服務組
            </AboutText>
            <AboutText className="phone-call" onClick={callTo}>
              聯絡窗口:陳小姐 07-3513121分機2415{" "}
            </AboutText>
            <a
              className="email-to"
              href="mailto:meer@mail.mirdc.org.tw?subject=廠商平台相關問題"
              target="_blank">
              meer@mail.mirdc.org.tw
            </a>
          </div>
        </div>
        <img
          className="forth-section-img"
          src={contact}
          alt="second-img"
          loading={"lazy"}
        />
      </div>
      <Footer style={{ width: "135%", marginLeft: "-20%" }} />
    </div>
  );
};

const AboutText = styled.p`
  line-height: 25px;
`;

export default About;
