import React from "react";
import { Card } from "react-bootstrap";
import { useSelector } from "react-redux";

import CustomizedCard from "../../../components/CustomizedCard/CustomizedCardV3";
import FactoryGovernmentPlans from "../../../components/CustomizedTable/FactoryInfo/FactoryGovernmentPlans";
import MobileGovernmentPlans from "../../../components/MobileGrid/FactoryInfo/GovernmentPlans/GovernmentPlans";
import FactoryGovernmentPlansSubsidy from "../../../components/CustomizedTable/FactoryInfo/FactoryGovernmentPlansSubsidy";
import MobileGovernmentPlansSubsidy from "../../../components/MobileGrid/FactoryInfo/GovernmentPlans/GovernmentPlansSubsidy";

const GovernmentPlans = () => {
  const { innerWidth } = useSelector((state) => state.app);
  const { governmentPlansInfo, governmentPlansInfoLoading } = useSelector(
    (state) => state.factory
  );
  const { governmentPlansInfoSubsidy, governmentPlansInfoSubsidyLoading } = useSelector(
    (state) => state.factory
  );

  return (
    <React.Fragment>
      {innerWidth > 1024 ? (
        <Card >
          <CustomizedCard
            title="政府計畫資料"
            referenceType={"factoryInfoGovPlan"}
            loading={governmentPlansInfoLoading}
          >
            <FactoryGovernmentPlans
              headerList={["年份", "計畫別", "計畫名稱"]}
              data={governmentPlansInfo}
            />
          </CustomizedCard>
        </Card>
      ) : (
        <MobileGovernmentPlans data={governmentPlansInfo} referenceType={"factoryInfoGovPlan"}/>
      )}
      {innerWidth > 1024 ? (
        <Card >
          <CustomizedCard
            title="工業局補(捐)助資料"
            referenceType={"factoryInfoGovPlanSubsidy"}
            loading={governmentPlansInfoSubsidyLoading}
          >
            <FactoryGovernmentPlansSubsidy
              headerList={["核定日期", "計畫名稱", "補(捐)助金額 (新台幣)"]}
              data={governmentPlansInfoSubsidy}
            />
          </CustomizedCard>
        </Card>
      ) : (
        <MobileGovernmentPlansSubsidy data={governmentPlansInfoSubsidy} referenceType={"factoryInfoGovPlanSubsidy"}/>
      )}
    </React.Fragment>
  );
};

export default React.memo(GovernmentPlans);