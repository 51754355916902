import React from "react";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import Icon from "@material-ui/core/Icon"

import ControlledMaterilaAutoComplete from "../ControlledMaterialMapAutoComplete/ControlledMaterialMapAutoComplete";
import ControlledMaterialFilterGroup from "../ControlledMaterialFilterGroup/ControlledMaterialFilterGroup";
import ControlledMaterialResultList from "../ControlledMaterialResultList/ControlledMaterialResultList";
import { getControlledMaterialResult } from "../ControlledMaterialMapSideMenu/api";


import "./styles.scss";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" timeout={2000} ref={ref} {...props} />;
  });

export default props => {
    const { open, handleClose, onSelectedCompany, passDataHandler, parentsData, clearKeyWord, keyWord, updateData, handleClearParamsInMaterialControl, toggleDialogHandler, wastedPollutionOptions, toxicPollutionOptions, closePopUpHandler } = props;
    const [state, setState] = React.useState({
        KeyWord: "",
        controlledMaterialType: "",
        location: "",
        city: "",
        parkId: "",
        typeTwo: "",
        typeFour: "",
        data: "",
        WasteName: "",
        ToxicName: "",
        loading: false,
        isClicked: true,
        forceOpenFilterGroup:false,
    })

    const updateDataFromFilter = (name, value) => {
        //fetch data from filter group
        if(name === "WasteName"){
            setState((state) => ({
              ...state,
              ToxicName: "",
              [name]: Array.isArray(value) ? value.slice(1, (value.length)) : value,
            }));
          }else if(name === "ToxicName"){
            setState((state) => ({
              ...state,
              WasteName: "",
              [name]: Array.isArray(value) ? value.slice(1, (value.length)) : value,
            }));
          }else{
            setState(state=>({
                ...state,
                [name]: Array.isArray(value) ? value.slice(1, (value.length)) : value
            }))
          }

    };


    const fetchControlledMaterialDataHandler = async () => {
        setState(state=>({ ...state, loading: true, isClicked:true }));

        const { controlledMaterialType, location, city, parkId, typeTwo, typeFour, WasteName, ToxicName } = state;
        const form = new FormData();
        form.append("KeyWord", keyWord);
        form.append("controlledMaterialType", controlledMaterialType);
        form.append("location", location);
        form.append("city", city);
        form.append("parkId", parkId);
        form.append("typeTwo", typeTwo);
        form.append("typeFour", typeFour);
        form.append("WasteName", WasteName);
        form.append("ToxicName", ToxicName);
    
        const data = await getControlledMaterialResult(form);
        setState(state=>({ ...state, loading: false, data: data.data, forceOpenFilterGroup:true }));
    };

    const clearKeyWordHandler= () => {
        setState(state=>({
            ...state,
            KeyWord: ""
        }))
        clearKeyWord()
    };

    const handleClearParams = () => {
        setState(state=>({
            ...state,
            KeyWord: "",
            controlledMaterialType: "",
            location: "",
            city: "",
            parkId: "",
            typeTwo: "",
            typeFour: "",
            WasteName: "",
            ToxicName: "",
        }))
        handleClearParamsInMaterialControl() //清掉 parent component 值
    }

    React.useEffect(()=>{
        passDataHandler(state.data);
    },[state.data]);


    React.useEffect(()=>{
        setState(state=>({ ...state, data: parentsData }));
    },[parentsData]);

    const [searchOpen, setSearchOpen] = React.useState(false);
    return(
        <Dialog
        className="material-dialog"
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        >
            <Icon className="more" onClick={handleClose}>keyboard_arrow_down</Icon>
            <div className="drawer-auto-complete">
                    <ControlledMaterilaAutoComplete 
                        toggleDialogHandler={toggleDialogHandler} 
                        controlledSearch={fetchControlledMaterialDataHandler}
                        style={{position:'initial', width:'90%', margin:'5% 0px 0px 5%'}}
                        passDataHandler={()=>{}}
                        openForceFilterGroup={()=>{}}
                        keyWord={keyWord}
                        updateData={(name, value)=>{updateData("", value)}}
                    />
                </div>
            <ControlledMaterialFilterGroup 
                updateData={(name, value)=> updateDataFromFilter(name, value)}
                // ref={ref}
                controlledSearch={fetchControlledMaterialDataHandler}
                KeyWord={state.KeyWord}
                forceOpen={state.forceOpenFilterGroup}
                clearKeyWord={clearKeyWordHandler}
                handleClearParams={handleClearParams}
                keyWord={keyWord}
                wastedPollutionOptions={wastedPollutionOptions}
                toxicPollutionOptions={toxicPollutionOptions}
                changeSearchOpen={(status) => setSearchOpen(status)}
            />
            <ControlledMaterialResultList 
                totalAmt={0} 
                closePopUpHandler={closePopUpHandler}
                loading={state.loading} 
                clicked={state.isClicked} 
                data={state.data} 
                searchOpen={searchOpen}
                onSelectedCompany={onSelectedCompany}
                forceOpenFilterGroup={state.forceOpenFilterGroup}
            />
        </Dialog>
    )
}