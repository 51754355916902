import React, { useState, useEffect } from 'react'
import { 
    Select, 
    MenuItem, 
    FormControl, 
    InputLabel, 
    TableHead,
    TableContainer, 
    Table, 
    TableCell, 
    TableBody, 
    TableRow,
    Input, 
    Icon
} from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'


import * as govPlanActions from '../../store/actions/govplan'
import './styles.scss'

const GovPlanGroupCreate = props => {
    const { parks, type, existedGroup, isEdit } = props
    const ParkId = existedGroup ? existedGroup[0].ParkId : null
    const dispatch = useDispatch()
    const { companyList, loading, businessDisplay, businessLoading } = useSelector(state => state.govPlan )
    const [ parksOptions, setParksOptions ] = useState([])
    const [ selectedParkId, setSelectedParkId ] = useState()
    const [ name, setName ] = useState()
    const [ businessNoList, setBusinessNoList ] = useState([])
    const [ businessNoHolder, setBusinessNoHolder ] = useState()
    const [ editCompanyList, setEditCompanyList ] = useState()
    const [ emailList, setEmailList ] = useState([])
    const [ emailHolder, setEmailHolder ] = useState()
    const [contactInfoHolder, setContactInfoHolder] = useState({
      Name: "",
      JobTitle: "",
      Email: ""
    });

    const [isClicked, setIsClicked] = useState(false)
    const [emailValidation, setEmailValidation] = useState(false)
    const [contactInfoList, setContactInfoList] = useState([])

    


    useEffect(()=>{
        const options = []
        if(parks){
            parks.map(option => options.push({
                parkId: option.InduPark.ParkId,
                name: option.InduPark.Name
            }))
        }
        setParksOptions(options)
    }, [parks])


    useEffect( ()  => {
      if(type !== 'create'){
        const { ParkId, CompanyList, Contacts } = existedGroup[0]

         if(ParkId){
           setSelectedParkId(ParkId)
         } 

         if(CompanyList){
           const updateBusinessNoList = []
           
           CompanyList.map(company => updateBusinessNoList.push(company.BusinessNo))


           setBusinessNoList(updateBusinessNoList)
         }

         setName(existedGroup[0].Name)
         setContactInfoList(Contacts);
      }

    }, [type, existedGroup])

    useEffect(() => {
      if (existedGroup && ParkId) {
        dispatch(govPlanActions.getCompanyList(existedGroup[0].ParkId))
      }
    }, [existedGroup])

    useEffect(() => {
      if (existedGroup && companyList){
        setEditCompanyList(companyList)
      }
    }, [companyList])


    useEffect(()=>{
      if(businessNoList){
        dispatch(govPlanActions.convertingBusinessNo(businessNoList))
      }
    }, [businessNoList, setBusinessNoList])


    useEffect(()=>{
      if (validateEmail(contactInfoHolder.Email)) {
        setEmailValidation(false);
      } else {
        setEmailValidation(true);
      }
    },[contactInfoHolder.Email])

    // const setParkIdHandler = e => {
    //     const parkId = e.target.value
    //     setSelectedParkId(parkId)
    // }

    // const nameChangeHandler = event => {
    //   const value = event.target.value
    //   setName(value)
    // }

    // const addEmailListHandler = e => {
    //     if (e.keyCode === 13) {
    //         const updateEmailList = emailList.concat(emailHolder)
    //         // updateEmailList
    //         setEmailHolder('')
    //         setEmailList(updateEmailList)
    //     }
    // }


    const contactInfoHolders = (event) => {
      const type = event.target.type

      const keyCode = event.keyCode
      const { Name, Email } = contactInfoHolder

      if (keyCode === 13 && Name && Email) {
        const updateContact = contactInfoList.concat(contactInfoHolder);

        setContactInfoList(updateContact);
        setContactInfoHolder({
          Name: "",
          JobTitle: "",
          Email: ""
        });
      } else if (type === "submit") {
        if(!Name || !Email){
          setIsClicked(true)
        }else{
          const updateContact = contactInfoList.concat(contactInfoHolder);

          setContactInfoList(updateContact);
          setContactInfoHolder({
            Name: "",
            JobTitle: "",
            Email: ""
          });
        }
      }

    }




    const contactInfoHandler = (event, name) => {
      const value = event.target.value
      const updateContactHolder = { ...contactInfoHolder }

      updateContactHolder[name] = value


      setContactInfoHolder(updateContactHolder)
    }


    const businessNoInputHandler = event => {
        const target = event.target
        let value = target.value

        const commaReg = new RegExp('[,]');
        const NextReg = new RegExp('[\n]');
        const spaceReg = new RegExp(' ')

        const len = value.length

        if (len >= 8 && value.match(commaReg)) {
            let updatedValue = value.split(',').slice(0, -1)
            setBusinessNoList(businessNoList.concat(updatedValue))
            target.value = ''
        } else if (len >= 8 && value.match(NextReg)) {
            let updatedValue = value.split('\n').slice(0, -1)
            setBusinessNoList(businessNoList.concat(updatedValue))
            target.value = ''
        } else if (len >= 8 && value.match(spaceReg)) {
          let updatedValue = value.split(' ').slice(0, -1)
          setBusinessNoList(businessNoList.concat(updatedValue))
          target.value = ''
        }else if (len >= 8) {
            setBusinessNoList(businessNoList.concat(value))
            setBusinessNoHolder('')
        }else{
            setBusinessNoHolder(value)
        }



    }

    const createInduGroupHandler = () => {
        const updateContact = [ ]
      
        contactInfoList.map(contact => {
          // const contactlist = []
          // contactlist.push(contact.ContactPerson)
          // contactlist.push(contact.ContactPersonPosition)
          // contactlist.push(contact.Email)

          updateContact.push(contactInfoList)
        })

        const form = new FormData()
        form.append('Name', name)
        form.append('ParkId', selectedParkId ? selectedParkId : "")
        // form.append('BusinessNo', businessNoList)
        form.append('Email', emailList)
        form.append('Contacts', JSON.stringify(contactInfoList))
        form.append('User', 'temp')

        // dispatch(govPlanActions.createInduGroupHandler(form))
        dispatch(govPlanActions.updateGovGroupHandler(existedGroup[0].GroupId, form))
        props.closeModal()


        // if(type === 'create'){
        //   dispatch(govPlanActions.createInduGroupHandler(form))
        //   props.closeModal()
        // }else{
        //   dispatch(govPlanActions.updateGovGroupHandler(existedGroup[0].GroupId, form))
        //   props.closeModal()
        // }
        
    }
 
    const generateCompanyListByParkId = event => {
        const value = event.target.value
    
        dispatch(govPlanActions.getCompanyList(value))

        const selectedPark = parks.filter(park => park.InduPark.ParkId === value)

        setName(selectedPark[0].InduPark.Name)


        if(!loading){
            setSelectedParkId(value)
        }

    }

  const uploadFileHandler = (event) => {
    const files = event.target.files;
    const isExcel = files[0].name.split('.')[1]
    console.log('FILES!')
    if (isExcel !== 'xls' || isExcel !== 'xlsx' || isExcel !== 'csv') {
      alert('請上傳 excel 檔')
      return
    }

    //   const form = new File(files, files[0].name);
  };


    const deleteGroup = () => {
      dispatch(govPlanActions.deleteGovGroupHandler(existedGroup[0].GroupId))
      dispatch(govPlanActions.getInduGroupHandler())
      props.closeModal()
    }


    const deleteContactListHandler = (name, email) => {
      const updateContacts = contactInfoList.filter(contact => contact.Name !== name && contact.Email !== email)
      setContactInfoList(updateContacts);
    }

    const closeModalHandler = () => {
      props.closeModal()
    }

    const validateEmail = (email) =>  {
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    }

    return (
      <div className="gov-plan-group-modal">
        <h3 className="gov-plan-group-modal-title">
          <p>
            {type === "create" ? "新增" : "修改"}政府畫發送群組({name})
          </p>
          <Icon className="icon-close" onClick={closeModalHandler}>
            close
          </Icon>
        </h3>
        <div className="gov-plan-group-modal-content">
          {type === "create" && !isEdit && (
            <div className="gov-plan-group-modal-indupark-select">
              <FormControl className="select-container" variant="outlined">
                <InputLabel className="select-label">
                  直接透過工業區選取
                </InputLabel>
                <Select
                  onChange={event => generateCompanyListByParkId(event)}
                  value={selectedParkId}
                  className="select"
                  input={<Input />}
                >
                  <MenuItem key="" value="">
                    請選擇工業區
                  </MenuItem>
                  {parksOptions.map(option => (
                    <MenuItem
                      key={option.parkId}
                      value={option.parkId}
                      name={option.name}
                    >
                      {option.parkId} {option.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          )}

          <div
            className="gov-plan-group-modal-form"
            onSubmit={event => event.preventDefault()}
          >
            {/* <div>
              <input
                placeholder="群組名稱"
                className="gov-plan-group-input"
                name="groupName"
                value={name}
                onChange={nameChangeHandler}
                disabled={selectedParkId}
              />
            </div> */}

            {/* 只有在新增模式裡面會出現 */}
            {!selectedParkId && (
              <div>
                <input
                  placeholder="廠商統一編號"
                  className="gov-plan-group-business-no"
                  name="businessNoList"
                  value={businessNoHolder}
                  onChange={businessNoInputHandler}
                />
                <TableContainer
                  className={
                    businessNoList.length === 0 ? "table-disabled" : "table"
                  }
                >
                  <Table stickyHeader>
                    <TableHead>
                      <TableCell align="center">統一編號</TableCell>
                      <TableCell align="center">廠商名稱</TableCell>
                    </TableHead>
                    <TableBody>
                      {businessDisplay
                        ? businessDisplay.map(num => (
                            <TableRow>
                              <TableCell align="center">
                                {num.BusinessNo}
                              </TableCell>
                              <TableCell align="center">
                                {num.CompanyName}
                              </TableCell>
                            </TableRow>
                          ))
                        : null}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            )}

            {/* 只有在編輯模式裡面的工業區才會出現 ， 將原本就有的廠商統一編和和名稱顯示出來*/}
            {/* 不能編輯 */}

            {isEdit && ParkId && (
              <div>
                <TableContainer className="table">
                  <Table stickyHeader>
                    <TableHead>
                      <TableCell align="center">統一編號</TableCell>
                      <TableCell align="center">廠商名稱</TableCell>
                    </TableHead>
                    <TableBody>
                      {editCompanyList
                        ? editCompanyList.map(num => (
                            <TableRow>
                              <TableCell align="center">
                                {num.BusinessNo}
                              </TableCell>
                              <TableCell align="center">
                                {num.CompanyName}
                              </TableCell>
                            </TableRow>
                          ))
                        : null}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            )}
            <div className="divider" />
            <div>
              <h5 className="sub-title">建立聯絡人清單</h5>
              <div className="contact-input-container">
                <input
                  placeholder="聯絡人名稱"
                  className="gov-plan-group-email"
                  name="ContactPerson"
                  type="text"
                  value={contactInfoHolder.Name}
                  onKeyDown={event => contactInfoHolders(event)}
                  onChange={event => contactInfoHandler(event, "Name")}
                />
                {isClicked && !contactInfoHolder.Name && <a>此欄位不得空白</a>}
              </div>
              <div className="contact-input-container">
                <input
                  placeholder="聯絡人職稱"
                  className="gov-plan-group-email"
                  name="ContactPersonPosition"
                  type="text"
                  value={contactInfoHolder.JobTitle}
                  onKeyDown={event => contactInfoHolders(event)}
                  onChange={event => contactInfoHandler(event, "JobTitle")}
                />
              </div>
              <div className="contact-input-container-with-button">
                <div>
                  <input
                    placeholder="聯絡人 E-mail"
                    className="gov-plan-group-email"
                    name="Email"
                    type="e-mail"
                    value={contactInfoHolder.Email}
                    onKeyDown={event => contactInfoHolders(event)}
                    onChange={event => contactInfoHandler(event, "Email")}
                  />
                  {isClicked && !contactInfoHolder.Email && (
                    <a>此欄位不得空白</a>
                  )}
                  {emailValidation && contactInfoHolder.Email &&
                    <a>E-mail 格式不符</a>
                  }
                </div>
                <div className="contact-input-container-with-button-container">
                  <button onClick={event => contactInfoHolders(event)}>
                    新增聯絡人
                  </button>
                </div>
              </div>
              <TableContainer>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">聯絡人</TableCell>
                      <TableCell align="center">聯絡人職稱</TableCell>
                      <TableCell align="center">E - mail</TableCell>
                      <TableCell align="center"></TableCell>
                    </TableRow>
                  </TableHead>
                  {/* <TableBody>
                    {contactInfoList.length !== 0 &&
                      contactInfoList.map(contact => (
                        <TableRow hover>
                          <TableCell align="center">{contact.Name}</TableCell>
                          <TableCell align="center">
                            {contact.JobTitle}
                          </TableCell>
                          <TableCell align="center">{contact.Email}</TableCell>
<<<<<<< HEAD
                          <TableCell align="right">
                            <Icon
                              onClick={() =>
                                deleteContactListHandler(
                                  contact.Name,
                                  contact.Email
                                )
                              }
                            >
                              delete
                            </Icon>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
=======
                          <TableCell align="right"><Icon>delete</Icon></TableCell>
                        </TableRow>)}
                  </TableBody> */}
                </Table>
              </TableContainer>
            </div>
            <div className='divider'/>
            {/* 限定EXCEL、CSV檔案、 */}
            <div className='gov-plan-group-modal-content-upload'>
              <label>上傳政府群組</label>
              <input type='file' accept=".csv, .xls, .xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" onChange={event => uploadFileHandler(event)} />
            </div>
          </div>
        </div>
        <div className="btn-container">
          <button onClick={createInduGroupHandler}>
            {type === "create" ? "新增" : "修改"}
          </button>

          {type !== "create" && <button onClick={deleteGroup}>刪除</button>}
        </div>
      </div>
    );
}


export default GovPlanGroupCreate