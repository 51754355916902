import React from "react";
import styled from "styled-components";
// import { FixedSizeList as List } from "react-window";
import { List, AutoSizer, CellMeasurerCache } from "react-virtualized";
// import AutoSizer from "react-virtualized-auto-sizer";
// import InfiniteLoader from "react-window-infinite-loader";

import Spinner from "../Spinner/Spinner";
import Item from "./item";
import * as theme from "../../assets/theme";

const ResultList = ({ loading, is_search_console_open, data, is_clicked, handleSaveRef }) => {
  const cache = new CellMeasurerCache({
    fixedWidth: true,
    defaultHeight: window.innerWidth > 750 ? 330 : 465
  });

  const renderRow = ({ style, index, key, isScrolling, parent }) => {
    return (
      <Item
        style={style}
        address={data[index]["address"]}
        area={data[index]["area"]}
        businessNo={data[index]["businessNo"]}
        capital={data[index]["capital"]}
        city={data[index]["city"]}
        induFourStr={data[index]["induFourStr"]}
        induPark={data[index]["induPark"]}
        name={data[index]["name"]}
        owner={data[index]["owner"]}
        product={data[index]["product"]}
        stockType={data[index]["stockType"]}
        key={key}
        loading={isScrolling}
        index={index}
        parent={parent}
        cache={cache}
      />
    );
  };

  return (
    <ListContainer
      is_search_console_open={is_search_console_open}
      is_with_data={data.length > 0}
    >
      {data.length === 0 ? (
        <ListNoDataContainer is_search_console_open={is_search_console_open}>
          <ListContainerText is_clicked={is_clicked}>
            <span>請透過條件搜尋受事件影響廠商。</span>
          </ListContainerText>
          <ListContainerText is_clicked={!is_clicked}>
            <span>搜尋條件無結果。</span>
          </ListContainerText>
        </ListNoDataContainer>
      ) : (
        <AutoSizer>
          {({ width, height }) => (
            <List
              width={width}
              height={height}
              rowRenderer={renderRow}
              rowCount={data ? data.length : 0}
              itemSize={100}
              overscanRowCount={20}
              deferredMeasurementCache={cache}
              rowHeight={cache.rowHeight} 
              scrollToIndex={0}
            />
          )}
        </AutoSizer>
      )}
    </ListContainer>
  );
};

const ListContainer = styled.div`
  height: ${(p) => (!p.is_search_console_open ? "57vh" : "35vh")};
  @media (max-width: 750px) {
    margin: 0px 10px;
    height: ${(p) => (p.is_search_console_open ? "25vh" : "50vh")};
  }
`;

const ListNoDataContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${(p) => (!p.is_search_console_open ? "64vh" : "47vh")};
  @media (max-width: 750px) {
    margin: 0px 10px;
    height: ${(p) => (p.is_search_console_open ? "25vh" : "50vh")};
  }
`;

const ListContainerText = styled.p`
  color: ${theme.secondaryBlack};
  > span {
    display: ${(p) => p.is_clicked && "none"};
  }
`;

export default ResultList;
