import React, { useState } from "react";
import Icon from "@material-ui/core/Icon";
import Backdrop from "@material-ui/core/Backdrop";
import Card from "@material-ui/core/Card";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import { useSelector } from "react-redux";

import Maps from "../../components/Maps/Maps";
import ControlledMaterialMenu from "../../components/ControlledMaterialMapSideMenu/ControlledMaterialMapSideMenus";
import CustomizedMap from "../../components/CustomizedMap/CustomizedMap";
import ControlledMaterilaAutoComplete from "../../components/ControlledMaterialMapAutoComplete/ControlledMaterialMapAutoComplete";
import ControlledMaterialDialog from "../../components/ControlledMaterialDialog/ControlledMaterialDialog";
import Spinner from "../../components/Spinner/Spinner";
import TabPanel from "../../components/ControlledMaterialResultList/TabPanel";
import {
  getControlledMaterialDetail,
  getControlledMaterialOptions,
} from "./api";

import "./styles.scss";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export default (props) => {
  const { innerWidth } = useSelector((state) => state.app);
  const [state, setState] = useState({
    isDialogOpen: false, // 打開手機版的 dialog
    isDrawerOpen: true, // 打開電腦版的 drawer
    selectedLocation: "",
    data: "",
    isBackDropOpen: false,
    selectedCompany: "",
    keyWord: "",
    showedCompany: "",
    isDetailCardOpen: false,
    isDetailCardOpenLoading: false,
    selectedCompanyList: [],
    tabData: "",
    selectedUid: "",
    wastedPollutionOptions: [],
    toxicPollutionOptions: [],
  });

  const toggleDrawerHandler = () => {
    if (window.innerWidth > 1024) {
      setState((state) => ({ ...state, isDrawerOpen: !state.isDrawerOpen }));
    } else {
      return;
    }
  };

  const toggleDialogHandler = () => {
    setState((state) => ({ ...state, isDialogOpen: !state.isDialogOpen }));
  };

  const handleLoading = (value) => {
    setState((state) => ({ ...state, isBackDropOpen: value }));
  };

  const onSelectedCompany = (value) => {
    setState((state) => ({ ...state, selectedCompany: value }));
  };

  const dialogOnSelectedCompany = (value) => {
    if (window.innerWidth > 1023) {
      return;
    } else {
      setState((state) => ({ ...state, selectedCompany: value }));
    }
  };

  const updateKeyWord = (value) => {
    setState((state) => ({ ...state, keyWord: value }));
  };

  const passShowedCompany = (value) => {
    setState((state) => ({ ...state, showedCompany: value }));
    const selectedCompanyList =
      state.data && state.data.filter((company) => company.Uid === value);
    setState((state) => ({
      ...state,
      selectedCompanyList: selectedCompanyList,
    }));
  };

  const toggleDetailCard = () =>
    setState((state) => ({
      ...state,
      isDetailCardOpen: !state.isDetailCardOpen,
    }));

  const closeDetialCard = () =>
    setState((state) => ({ ...state, isDetailCardOpen: false }));

  const clearParamshandler = () => {
    setState((state) => ({
      ...state,
      keyWord: "",
    }));
  };

  const clearSelectedCompany = () => {
    setState((state) => ({
      ...state,
      selectedCompany: "",
      selectedCompanyList: [],
    }));
  };
  // React.useEffect(()=>{
  //     const { showedCompany, data  } = state;
  //     if(showedCompany){
  //         const showedCompanyInfo = data.filter(company => company.Uid === showedCompany );
  //         const notShowedCompanyInfo = data.filter(company => company.Uid !== showedCompany );

  //         setState(state=>({ ...state, data: showedCompanyInfo.concat(notShowedCompanyInfo) }))
  //     }
  // },[state.showedCompany])

  React.useEffect(() => {
    const { selectedCompanyList } = state;
    if (selectedCompanyList.length !== 0)
      getControlledMaterialDetail(selectedCompanyList[0].CtrlId)
        .then((res) => {
          setState((state) => ({
            ...state,
            tabData: res,
            isDetailCardOpenLoading: false,
          }));
        })
        .catch((err) => {
          console.err("取得列管資料詳細發生錯誤 :", err);
        });
    getControlledMaterialOptions("Toxic")
      .then((res) => {
        setState((state) => ({
          ...state,
          toxicPollutionOptions: res,
          isDetailCardOpenLoading: false,
        }));
      })
      .catch((err) => {
        console.err("取得列管資料詳細發生錯誤 :", err);
      });
    getControlledMaterialOptions("Waste")
      .then((res) => {
        setState((state) => ({
          ...state,
          wastedPollutionOptions: res,
          isDetailCardOpenLoading: false,
        }));
      })
      .catch((err) => {
        console.err("取得列管資料詳細發生錯誤 :", err);
      });
  }, [state.selectedCompanyList]);

  return (
    <div className="controlled-material">
      {state.isDetailCardOpen && (
        <Card className="detail-card">
          <div className="detail-card-close">
            <Icon onClick={closeDetialCard}>close</Icon>
          </div>
          {state.selectedCompanyList.map((item) => (
            <TabPanel
              key={item.Uid}
              CtrlId={item.CtrlId}
              uid={item.Uid}
              busineesNo={item.BusineesNo}
              name={item.FactoryName}
              address={item.FactoryAddr}
              parkId={item.ParkId}
              MonitoringType={item.MonitoringType}
              onClick={() => onSelectedCompany(item.Uid)}
              tabData={state.tabData}
              passIsContentOpenHandler={() => {}}
              noFlyTo
            />
          ))}
        </Card>
      )}
      <Backdrop
        className="controlled-material-back-drop"
        open={state.isBackDropOpen}
      >
        <Spinner />
      </Backdrop>
      {/* {!state.isDrawerOpen && <div style={{marginTop:"20px"}} onClick={()=>{ setIsDrawerOpen(true) }}>TEST!</div>} */}
      <ControlledMaterialMenu
        open={state.isDrawerOpen}
        toggleDrawer={toggleDrawerHandler}
        passDataHandler={(data) => {
          setState((state) => ({ ...state, data }));
        }}
        data={state.data}
        isLoading={handleLoading}
        onSelectedCompany={onSelectedCompany}
        updateKeyWord={updateKeyWord}
        showedCompany={state.showedCompany}
        keyWord={state.keyWord}
        handleClearParamsInMaterialControl={clearParamshandler}
        closePopUpHandler={(uid) =>
          setState((state) => ({ ...state, selectedUid: uid }))
        }
        wastedPollutionOptions={state.wastedPollutionOptions}
        toxicPollutionOptions={state.toxicPollutionOptions}
      />
      {/* <Maps data={state.data} selectedCompany={state.selectedCompany} passShowedCompany={passShowedCompany} toggleDetailCard={toggleDetailCard} closeDetialCard={closeDetialCard} selectedUid={state.selectedUid} passIsContentOpenHandler={()=>{}} clearSelectedCompany={clearSelectedCompany}/> */}
      <ControlledMaterilaAutoComplete
        toggleDrawer={toggleDrawerHandler}
        noHelperText
        style={
          innerWidth > 1023
            ? {
                position: "absolute",
                top: "130px",
                backgroundColor: "#fff",
                height: "auto",
                width: "230px",
              }
            : {
                position: "fixed",
                top: "80px",
                backgroundColor: "#fff",
                height: "auto",
                width: "90%",
              }
        }
        passDataHandler={(data) => {
          setState((state) => ({ ...state, data }));
        }}
        updateData={(name, value) =>
          setState((state) => ({ ...state, keyWord: value }))
        }
        controlledSearch={() => {}}
        openForceFilterGroup={() => {}} //用來控制 autocomplete onFocus 之後的行為
        updateKeyWord={updateKeyWord}
        keyWord={state.keyWord}
        toggleDialogHandler={toggleDialogHandler}
      />
      <>
        <ControlledMaterialDialog
          open={state.isDialogOpen}
          handleClose={toggleDialogHandler}
          onSelectedCompany={dialogOnSelectedCompany}
          passDataHandler={(data) => {
            setState((state) => ({ ...state, data }));
          }}
          parentsData={state.data}
          clearKeyWord={() => {
            setState((state) => ({ ...state, keyWord: "" }));
          }}
          showedCompany={state.showedCompany}
          keyWord={state.keyWord}
          updateData={(name, value) =>
            setState((state) => ({ ...state, keyWord: value }))
          }
          handleClearParamsInMaterialControl={clearParamshandler}
          toggleDialogHandler={toggleDialogHandler}
          wastedPollutionOptions={state.wastedPollutionOptions}
          toxicPollutionOptions={state.toxicPollutionOptions}
          closePopUpHandler={(uid) =>
            setState((state) => ({ ...state, selectedUid: uid }))
          }
        />
        <div onClick={toggleDialogHandler} className="dialog-close">
          <Icon className="more">keyboard_arrow_down</Icon>
        </div>
      </>
      <Dialog
        open={state.isDetailCardOpen && window.innerWidth < 1023}
        TransitionComponent={Transition}
        className="detail-info-dialog"
      >
        <div className="detail-card-close">
          <Icon onClick={toggleDetailCard}>close</Icon>
        </div>
        {state.selectedCompanyList.length !== 0 &&
          state.selectedCompanyList.map((item) => (
            <TabPanel
              isFlyTo
              key={item.Uid}
              CtrlId={item.CtrlId}
              uid={item.Uid}
              busineesNo={item.BusineesNo}
              name={item.FactoryName}
              address={item.FactoryAddr}
              parkId={item.ParkId}
              MonitoringType={item.MonitoringType}
              onClick={() => onSelectedCompany(item.Uid)}
              tabData={state.tabData}
              passIsContentOpenHandler={() => {}}
              noFlyTo
            />
          ))}
      </Dialog>
    </div>
  );
};
