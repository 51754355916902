import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import UpdateCompanyContainer from "../../../components/UpdateCompanyContainer/UpdateCompanyContainer";

import "./styles.scss";

const UpdatedCompanyInfo = ({ businessId }) => {
  const history = useHistory();
  const { 
    Accreditation,
    BusinessNo,
    ContactPerson,
    DMImg,
    EmplyeeCount,
    Fax,
    Introduction,
    MainProduct,
    Name,
    Phone,
    WebSite,
    Note
  } = useSelector((state) => state.factory.getUpdateCompanyInfoData );

  if (!Name) {
    history.push(`/factory_info/basic_info/${businessId}`);
  }

  return <UpdateCompanyContainer
    Accreditation={Accreditation}
    BusinessNo={BusinessNo}
    ContactPerson={ContactPerson}
    DMImg={DMImg}
    EmplyeeCount={EmplyeeCount}
    Fax={Fax}
    Introduction={Introduction}
    MainProduct={MainProduct}
    Name={localStorage.getItem("name")}
    Phone={Phone}
    WebSite={WebSite}
    Note={Note}
  />

  // return (
  //   <div className="updated-company-card">
  //     <div className="updated-company-card-header">
  //       {/* <div><h2>{ContactPerson}</h2></div> */}
  //       {DMImg && (
  //         <div>
  //           <h2
  //             onClick={() => {
  //               window.open(`${baseUrl}/DMFolder/${DMImg}`, "_blank");
  //             }}
  //           >
  //             公司DM
  //           </h2>
  //         </div>
  //       )}
  //       <img
  //         src="data:image/gif;base64,R0lGODlhAQABAPcAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACH5BAEAAP8ALAAAAAABAAEAAAgEAP8FBAA7"
  //         style={
  //           DMImg
  //             ? { backgroundImage: `url(${baseUrl}/DMFolder/${DMImg})` }
  //             : {
  //                 backgroundImage:
  //                   "url(https://images.unsplash.com/photo-1554316382-853b8a1696f3?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2534&q=80)",
  //               }
  //         }
  //         alt="dmImg"
  //       />
  //       <img
  //         src="data:image/gif;base64,R0lGODlhAQABAPcAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACH5BAEAAP8ALAAAAAABAAEAAAgEAP8FBAA7"
  //         style={{
  //           background: `linear-gradient(to top, rgba(0,0,0,0), rgba(247, 243, 243, 1) 90%)`,
  //         }}
  //         alt="dmImg"
  //       />
  //       {/* <img style={{backgroundImage: `url(${baseUrl}/DMFolder/${DMImg})`}} /> */}
  //     </div>
  //     <div className="updated-company-body">
  //       <div className="updated-company-inner-card">
  //         <div className="updated-company-inner-card-icons">
  //           <FontAwesomeIcon icon={faInfo} />
  //         </div>
  //         <div className="updated-company-inner-card-header">
  //           <h2>公司基本資料</h2>
  //         </div>
  //         <div className="updated-company-inner-card-content">
  //           <p>
  //             統一編號：<span>{BusinessNo ? BusinessNo : "未提供"}</span>
  //           </p>
  //           <p>
  //             聯絡人：<span>{ContactPerson ? thousandDivider(ContactPerson) : "未提供"}</span>
  //           </p>
  //           <p>
  //             公司總人數：<span>{EmplyeeCount ? EmplyeeCount : "未提供"}</span>
  //           </p>
  //           <p>
  //             聯絡電話：<span>{Phone ? Phone : "未提供"}</span>
  //           </p>
  //           <p>
  //             傳真電話：<span>{Fax ? Fax : "未提供"}</span>
  //           </p>
  //           <p onClick={()=>{ window.open(WebSite, "_blank") }} style={{textDecoration:"underline", color:"#006ab4"}}>
  //             公司網站：<span>{WebSite ? WebSite : "未提供"}</span>
  //           </p>
  //           <p>
  //             公司認證：<span>{Accreditation ? Accreditation.split(",").join("; ") : "未提供"}</span>
  //           </p>
  //           <p>備註：</p>
  //           <p>
  //           {Note ? Note : "未提供"}
  //           </p>
  //         </div>
  //       </div>
  //       <div className="updated-company-inner-card">
  //         <div className="updated-company-inner-card-icons">
  //           <FontAwesomeIcon icon={faBuilding} />
  //         </div>
  //         <div className="updated-company-inner-card-header">
  //           <h2>公司簡介</h2>
  //         </div>
  //         <div
  //           className="updated-company-inner-card-content"
  //           style={{ textAlign: "justify" }}
  //         >
  //           <p>{Introduction ? Introduction : "未提供"}</p>
  //         </div>
  //       </div>
  //       <div className="updated-company-inner-card">
  //         <div className="updated-company-inner-card-icons">
  //           <FontAwesomeIcon icon={faTools} />
  //         </div>
  //         <div className="updated-company-inner-card-header">
  //           <h2>公司主要產品</h2>
  //         </div>
  //         <div
  //           className="updated-company-inner-card-content"
  //           style={{ textAlign: "justify" }}
  //         >
  //           <p>{MainProduct ? MainProduct : "未提供"}</p>
  //         </div>
  //       </div>
  //     </div>
  //   </div>
  // );
};

export default React.memo(UpdatedCompanyInfo);
