import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from "@fortawesome/free-solid-svg-icons";

import "./styles.scss";

const FactoryAreaRentTable = (props) => {
  const { data, headerList } = props;
  const [state, setState] = React.useState({
    endIndex: 0,
    page: 0,
    rowsPerPage: 5,
  });
  const url = window.location.origin;

  let content;

  React.useEffect(() => {
    if (Math.ceil(data.length) === state.page) {
      if (data.length % 5 === 0) {
        setState((state) => ({
          ...state,
          endIndex: state.page * state.rowsPerPage + state.rowsPerPage,
        }));
      } else {
        setState((state) => ({
          ...state,
          endIndex: state.page * state.rowsPerPage + (data.length % 5),
        }));
      }
    } else {
      setState((state) => ({
        ...state,
        endIndex: state.page * state.rowsPerPage + state.rowsPerPage,
      }));
    }
  }, [state.page]);

  if (data.length > 5) {
    content = data
      .slice(state.page * 5, state.page * 5 + 5)
      .map((row, index) => {
        let dividedTenBillon;
        if (row.TotalPrice === "面議") {
          dividedTenBillon = "面議";
        } else {
          const numberWithNoComma = row.TotalPrice.replace(/,/g, "").replace(
            /元/g,
            ""
          );
          const transformToNum = +numberWithNoComma;
          if (transformToNum < 1000000) {
            dividedTenBillon = "< 1百萬";
          } else {
            dividedTenBillon = (transformToNum / 100000000).toFixed(2);
          }
        }

        let perThousandMeterPrice;
        let perMeterPrice;

        if (row.UnitPric.indexOf("坪,") > -1) {
          perThousandMeterPrice = row.UnitPric.split("坪,")[0] + "坪";
          perMeterPrice = row.UnitPric.split("坪,")[1];
        } else {
          perThousandMeterPrice = row.UnitPric.split("坪")[0] + "坪";
          perMeterPrice = row.UnitPric.split("坪")[1];
        }

        let areaSize;
        let areaSizeSqureMeter;

        if (row.AreaSize.indexOf("坪,") > -1) {
          areaSize = row.AreaSize.split("坪,")[0].split(".")[0] + "坪";
          areaSizeSqureMeter =
            row.AreaSize.split("坪,")[1].split(".")[0] + "平米";
        } else {
          areaSize = row.AreaSize.split("坪")[0].split(".")[0] + "坪";
          areaSizeSqureMeter =
            row.AreaSize.split("坪")[1].split(".")[0] + "平米";
        }

        return (
          <TableRow
            key={row.Link}
            hover
            className="table-body-row"
            style={index % 2 !== 0 ? { backgroundColor: "#ececec" } : {}}
          >
            <TableCell align="left">{row.IndustrialName}</TableCell>
            <TableCell align="left">
              <p>{areaSize}</p>
              <p>{areaSizeSqureMeter}</p>
            </TableCell>
            <TableCell align="left">{row.RentalCategory}</TableCell>
            <TableCell align="left">
              {row.TotalPrice === "面議" ? (
                "面議"
              ) : (
                <>
                  <p>{perThousandMeterPrice}</p>
                  <p>{perMeterPrice}</p>
                </>
              )}
            </TableCell>
            <TableCell align="left">
              <FontAwesomeIcon
                icon={faLink}
                onClick={() => openNewTabs(row.Link)}
              />
            </TableCell>
          </TableRow>
        );
      });
  } else {
    content = data.map((row, index) => {
      const numberWithNoComma = row.TotalPrice.replace(/,/g, "").replace(
        /元/g,
        ""
      );
      const transformToNum = +numberWithNoComma;
      const dividedTenBillon = (transformToNum / 100000000).toFixed(2);

      let perThousandMeterPrice;
      let perMeterPrice;

      if (row.UnitPric.indexOf("坪,") > -1) {
        perThousandMeterPrice = row.UnitPric.split("坪,")[0] + "坪";
        perMeterPrice = row.UnitPric.split("坪,")[1];
      } else {
        perThousandMeterPrice = row.UnitPric.split("坪")[0] + "坪";
        perMeterPrice = row.UnitPric.split("坪")[1];
      }

      let areaSize;
      let areaSizeSqureMeter;

      if (row.AreaSize.indexOf("坪,") > -1) {
        areaSize = row.AreaSize.split("坪,")[0].split(".")[0] + "坪";
        areaSizeSqureMeter =
          row.AreaSize.split("坪,")[1].split(".")[0] + "平米";
      } else {
        areaSize = row.AreaSize.split("坪")[0].split(".")[0] + "坪";
        areaSizeSqureMeter = row.AreaSize.split("坪")[1].split(".")[0] + "平米";
      }

      return (
        <TableRow
          key={row.Link}
          hover
          className="table-body-row"
          style={index % 2 !== 0 ? { backgroundColor: "#ececec" } : {}}
        >
          <TableCell align="left">{row.IndustrialName}</TableCell>
          <TableCell align="left">
            <p>{areaSize}</p>
            <p>{areaSizeSqureMeter}</p>
          </TableCell>
          <TableCell align="left">{row.RentalCategory}</TableCell>
          <TableCell align="left">
            {row.TotalPrice === "面議" ? (
              "面議"
            ) : (
              <>
                <p>{perThousandMeterPrice}</p>
                <p>{perMeterPrice}</p>
              </>
            )}
          </TableCell>
          <TableCell align="left">
            <FontAwesomeIcon
              icon={faLink}
              onClick={() => openNewTabs(row.Link)}
            />
          </TableCell>
        </TableRow>
      );
    });
  }

  const handleChangePage = (event, newPage) => {
    setState((state) => ({ ...state, page: newPage }));
  };

  const openNewTabs = (link) => window.open(link, "_blank", "noreferrer");

  return (
    <TableContainer className="table-container">
      <Table className="table">
        <TableHead className="table-header">
          {headerList && (
            <TableRow className="table-row">
              {headerList.map((headerItem) => (
                <TableCell className="table-cell" align="left">
                  {headerItem}
                </TableCell>
              ))}
            </TableRow>
          )}
        </TableHead>
        <TableBody className="table-body">
          {data.length !== 0 && content}
        </TableBody>
      </Table>
      {data.length === 0 && (
        <Table>
          <TableBody className="table-body-no-data">
            <TableRow hover className="table-body-row">
              <TableCell align="center" className="no-data">
                本項目無資料
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      )}
      {data.length > 5 && (
        <TablePagination
          rowsPerPageOptions={[]}
          component="div"
          count={data.length}
          rowsPerPage={state.rowsPerPage}
          page={state.page}
          onChangePage={handleChangePage}
          // onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      )}
    </TableContainer>
  );
};

export default React.memo(FactoryAreaRentTable);
