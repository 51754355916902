import React from "react";
import Grid from "@material-ui/core/Grid";

import * as Style from "./styles";

const MobileGrid = (props) => {
  let content;

  if (props.children) {
    if (props.children.length === 0) {
      content = <Style.MobileGridItemNoData>目前沒有資料</Style.MobileGridItemNoData>;
    } else {
      content = props.children;
    }
  }

  return (
    <Style.MobileGridItemContainer>
      <Grid
        container
        spacing={3}
        direction="column"
        justify="flex-start"
        alignItems="center"
      >
        {content}
      </Grid>
    </Style.MobileGridItemContainer>
  );
};

export default MobileGrid;
