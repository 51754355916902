import axios from "axios";

export const handleGetReferenceInfoData = (id) => {
    return axios({
      method: "get",
      url: `https://mirdc-system-default-rtdb.firebaseio.com/referenceInfo/${id}.json`,
    })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return [];
      });
  };