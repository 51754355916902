import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter, useHistory } from "react-router-dom";
import { createStore, applyMiddleware, combineReducers } from "redux";
import { Provider } from "react-redux";
import ReduxThunk from "redux-thunk";
import { SnackbarProvider } from "notistack";

import app from "./store/reducers/app";
import auth from "./store/reducers/auth";
import factory from "./store/reducers/factory";
import govPlan from "./store/reducers/govplan";
import industryIndex from "./store/reducers/industryIndex";
import overView from "./store/reducers/overview";
import searchResult from "./store/reducers/searchResult";
import section from "./store/reducers/section";
import map from "./store/reducers/map";
import user from "./store/reducers/users";
import applyFile from "./store/reducers/applyFile";
import parkDashboard_beta from "./store/reducers/parkDashboard_beta";
import customizedDashboard from "./store/reducers/customizedDashboard";
import cityDashboard from "./store/reducers/cityDashbroad";
import areaDashbroad from "./store/reducers/areaDashbroad";
import industryDashboard from "./store/reducers/IndustryDashboard";
import supplychain from "./store/reducers/supplychain";
import question from "./store/reducers/question";
import wasted_clean from "./store/reducers/wasted_clean";
import weather from "./store/reducers/weather";
import effected_events from "./store/reducers/effected_events";
import taxInfo from "./store/reducers/taxInfo";
import smartWindow from "./store/reducers/smartWindow";
import operationsDashboard from "./store/reducers/operationsDashboard";
import custom_dashboard from "./store/reducers/custom_dashboard";
import custom_industry_dashboard from "./store/reducers/custom_industry_dashboard";
import subscribe from "./store/reducers/subscribe";

import AuthContextProvider from "./context/auth-context";
import ErrorBoundary from "./components/ErrorBoundary/ErrorBoundary";
import VersionController from "./components/VersionController/VersionController";

const rootReducer = combineReducers({
  app: app,
  auth: auth,
  factory: factory,
  subscribe: subscribe,
  govPlan: govPlan,
  industryIndex: industryIndex,
  overView: overView,
  searchResult: searchResult,
  section: section,
  map: map,
  user: user,
  applyFile: applyFile,
  customizedDashboard: customizedDashboard,
  cityDashboard: cityDashboard,
  areaDashbroad: areaDashbroad,
  parkDashboard_beta: parkDashboard_beta,
  industryDashboard: industryDashboard,
  supplychain: supplychain,
  question: question,
  wasted_clean: wasted_clean,
  weather: weather,
  effected_events: effected_events,
  taxInfo: taxInfo,
  custom_dashboard: custom_dashboard,
  custom_industry_dashboard: custom_industry_dashboard,
  smartWindow: smartWindow,
  operationsDashboard: operationsDashboard,
});

const store = createStore(rootReducer, applyMiddleware(ReduxThunk));

ReactDOM.render(
  <Provider store={store}>
    <ErrorBoundary>
      <SnackbarProvider maxSnack={3}>
        <AuthContextProvider>
          <BrowserRouter>
            <VersionController>
              <App />
            </VersionController>
          </BrowserRouter>
        </AuthContextProvider>
      </SnackbarProvider>
    </ErrorBoundary>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
