import { parks } from '../constants/parks';


export default (parkId) => {
    if(parkId){
        const parkName = parks.filter(park => parkId.toString() === park.ParkId );
        return parkName[0] ? parkName[0].Name_CH : ""
    }else{
        return ""
    }
}