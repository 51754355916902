import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import moneyIcon from "../../assests/images/about-money.png";
import buildingIcon from "../../assests/images/about-building.png";
import LineChart from "./LineChart";
import Loading from "./Loading";
import { Box, Grid } from "@mui/material";

const IndustryBoard = ({
  dashboardData,
  productVal,
  factoryCount,
  chartYear,
  listedCompany,
}) => {
  const parseThousandMillion = (num) => {
    if (num / 100000 >= 1) return (num / 100000).toFixed(2);
    return num;
  };
  const parseThousandMillionUnit = (num) => {
    if (num / 100000 >= 1) return "億元";
    return "千元";
  };

  return (
    <>
      <div class="d-flex about-dashboard-content">
        <div>
          <div class="card">
            <div class="card-header">
              <h2>產業概況</h2>
            </div>
            <div className="card-body">
              <div
                style={{
                  margin: "32px 0px",
                }}
              >
                <Grid container spacing={2}>
                  {[
                    {
                      logo: moneyIcon,
                      title: "產值",
                      unit: "千元",
                      key: "amount",
                    },
                    {
                      logo: buildingIcon,
                      title: "工廠家數",
                      unit: "家",
                      key: "employeeAmount",
                    },
                    {
                      logo: moneyIcon,
                      title: "投資金額",
                      unit: "千元",
                      key: "avgPower",
                    },
                    {
                      logo: buildingIcon,
                      title: "員工數",
                      unit: "人",
                      key: "avgWater",
                    },
                  ].map((item) => {
                    return (
                      <Grid item xs={12} md={6}>
                        <Box display="flex" alignItems={"center"}>
                          <img src={item.logo} alt="" width="50" />
                          <div
                            style={{
                              textAlign: "left",
                              marginLeft: "8px",
                            }}
                          >
                            <h3
                              style={{
                                fontSize: "14px",
                              }}
                            >
                              {item.title}
                            </h3>
                            <p
                              style={{
                                color: "#25758A",
                                fontSize: "16px",
                              }}
                            >
                              {parseThousandMillion(
                                dashboardData.industryIndexData?.basicInfo?.[
                                item.key
                                ]
                              )
                                ?.toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                              <span
                                style={{
                                  color: "#00000099",
                                  paddingLeft: "4px",
                                }}
                              >
                                {item.unit === "千元"
                                  ? parseThousandMillionUnit(
                                    dashboardData.industryIndexData
                                      ?.basicInfo?.[item.key]
                                  )
                                  : item.unit}
                              </span>
                            </p>
                          </div>
                        </Box>
                      </Grid>
                    );
                  })}
                </Grid>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-header">
              <h2>研發概況</h2>
            </div>
            <div className="card-body">
              <div
                style={{
                  margin: "32px 0px",
                }}
              >
                <Grid container spacing={2}>
                  {[
                    {
                      logo: buildingIcon,
                      title: "研究發展工廠數",
                      unit: "家",
                      key: "amount",
                    },
                    {
                      logo: moneyIcon,
                      title: "研究發展經費",
                      unit: "千元",
                      key: "employeeAmount",
                    },
                    {
                      logo: moneyIcon,
                      title: "營業支出",
                      unit: "千元",
                      key: "avgPower",
                    },
                    {
                      logo: moneyIcon,
                      title: "營業收入",
                      unit: "千元",
                      key: "avgWater",
                    },
                  ].map((item) => {
                    return (
                      <Grid item xs={12} md={6}>
                        <Box display="flex" alignItems={"center"}>
                          <img src={item.logo} alt="" width="50" />
                          <div
                            style={{
                              textAlign: "left",
                              marginLeft: "8px",
                            }}
                          >
                            <h3
                              style={{
                                fontSize: "14px",
                              }}
                            >
                              {item.title}
                            </h3>
                            <p
                              style={{
                                color: "#25758A",
                                fontSize: "16px",
                              }}
                            >
                              {parseThousandMillion(
                                dashboardData.industryIndexData?.techInfo?.[
                                item.key
                                ]
                              )
                                ?.toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                              <span
                                style={{
                                  color: "#00000099",
                                  paddingLeft: "4px",
                                }}
                              >
                                {item.unit === "千元"
                                  ? parseThousandMillionUnit(
                                    dashboardData.industryIndexData
                                      ?.techInfo?.[item.key]
                                  )
                                  : item.unit}
                              </span>
                            </p>
                          </div>
                        </Box>
                      </Grid>
                    );
                  })}
                </Grid>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="card">
            <div class="card-header">
              <h2>營運中工廠家數趨勢</h2>
            </div>
            <div className="card-body">
              <div tabIndex={"0"} title="營運中工廠家數趨勢圖表資料" style={{
                position: "relative"
              }}>
                <div aria-hidden="true">
                  <LineChart
                    data={[
                      {
                        id: "營運中工廠家數趨勢",
                        data: factoryCount?.factoryCountData?.map(
                          (item, index) => ({
                            x: factoryCount?.factoryCountLabel[index],
                            y: item,
                          })
                        ),
                      },
                    ]}
                    Unit="家"
                  />
                </div>
                <table style={{
                  position: "absolute",
                  width: "100%",
                  height: "80%",
                  top: 0,
                  margin: "-1px",
                  padding: "0",
                  overflow: "hidden",
                  clip: "rect(0, 0, 0, 0)",
                  border: "0"
                }}>
                  <thead>
                    <tr>
                      <th>年度</th>
                      <th>營運中工廠家數</th>
                    </tr>
                  </thead>
                  <tbody>
                    {factoryCount?.factoryCountData?.map((item, index) => (
                      <tr>
                        <td>{factoryCount?.factoryCountLabel[index]}</td>
                        <td>{item}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-header">
              <h2>產業產值趨勢</h2>
            </div>
            <div className="card-body">
              <div tabIndex={"0"} title="產業產值趨勢圖表資料" style={{
                position: "relative"
              }}>
                <div aria-hidden="true">
                  <LineChart
                    data={[
                      {
                        id: "產業產值趨勢",
                        data: productVal?.productValData?.map((item, index) => ({
                          x: productVal?.productValLabel[index],
                          y: item,
                        })),
                      },
                    ]}
                    Unit="千元"
                  />
                </div>
                <table style={{
                  position: "absolute",
                  width: "100%",
                  height: "80%",
                  top: 0,
                  margin: "-1px",
                  padding: "0",
                  overflow: "hidden",
                  clip: "rect(0, 0, 0, 0)",
                  border: "0"
                }}>
                  <thead>
                    <tr>
                      <th>年度</th>
                      <th>產業產值(億元)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {productVal?.productValData?.map((item, index) => (
                      <tr>
                        <td>{productVal?.productValLabel[index]}</td>
                        <td>{item}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="card long">
          <div class="card-header">
            <h2>股票上市櫃公司</h2>
          </div>
          <div
            className="card-body"
            style={{
              margin: "16px 0px",
            }}
          >
            {
              listedCompany.listedCompanyLoading ? <Loading /> :
                listedCompany.listedCompanyData?.length === 0 ?
                  <Box display="flex" justifyContent={"center"}>
                    <NewsCompanyName>無相關資料</NewsCompanyName>
                  </Box> :
                  <ListWrapper>
                    {listedCompany.listedCompanyData.slice(0, 8)?.map((row) => (
                      <CompanyBox display="flex" justifyContent={"space-between"}>
                        <Box className="text">
                          <span>{row.Type}</span>
                          <CompanyLink title={`另開新視窗，連結到${row.Value}的個廠情資`} to={`/factory_info/basic_info/${row.Key}`} target="_blank">
                            {row.Value}
                          </CompanyLink>
                        </Box>
                        <Box className="number">
                          <span>
                            {dashboardData?.industryIndexData?.listedCompany?.date}{" "}
                            營業額 (千元)
                          </span>
                          <p style={{
                            color: row.period === "下降" ? "#378f17" : "#c9254b"
                          }}>
                            {row.Revenue_Net?.toString().replace(
                              /\B(?=(\d{3})+(?!\d))/g,
                              ","
                            )}
                          </p>
                        </Box>
                      </CompanyBox>
                    ))}
                  </ListWrapper>
            }
            <hr
              style={{
                margin: "14px 0px",
              }}
            />
            <CompanyLink
              style={{
                fontSize: "14px",
              }}
              target="_blank"
              title={`另開新視窗，前往產業儀表板查看更多`}
              to={`/industry_index/${dashboardData.code}`}
            >
              查看更多
            </CompanyLink>
          </div>
        </div>
      </div>
    </>
  );
};

const CompanyLink = styled(Link)`
  display: flex;
  color: #3e5a75;
  text-decoration: underline;
  &:hover {
    color: #3e5a75;
    opacity: 0.8;
    text-decoration: underline;
  }
`;
const CompanyBox = styled(Box)`
  .text {
    text-align: left;
    span {
      font-size: 0.75rem;
      color: rgba(0, 0, 0, 0.6);
    }
  }
  .number {
    text-align: right;
    span {
      font-size: 0.75rem;
      color: rgba(0, 0, 0, 0.6);
    }
    p {
      font-size: 1rem;
    }
  }
`;
const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  @media (max-width: 768px) {
    margin-bottom: 0;
  }
`;
const NewsCompanyName = styled.p`
  background: #E6EDF6;
  padding: 4px 8px;
  display: inline-block;
  border-radius: 24px;
  font-size: 0.875rem;
  color: #033a63;
  margin-bottom: 8px;
`
export default IndustryBoard;
