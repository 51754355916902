import * as actionTypes from "../actionTypes/parkDashboard_beta";

const initState = {
  sectionMap: {
    sectionMapData: [],
    sectionMapLoading: false,
  },
  updateSectionMap: {
    updateSectionMapLoading: false,
  },
  serviceCenterParkLink: {
    serviceCenterParkLinkData: [],
    serviceCenterParkLinkLoading: false,
  },
  briefAndRanking: {
    briefAndRankingLoading: false,
    briefAndRankingList: [],
  },
  factoryAreaRent: {
    factoryAreaRentData: [],
    factoryAreaRentLoading: false,
  },
  sectionIndex: {
    sectionIndexData: [],
    sectionIndexLoading: false,
  },
  sectionNewsTag: {
    sectionNewsTagData: [],
    sectionNewsTagLoading: false,
  },
  sectionNewsContent: {
    sectionNewsContentData: [],
    sectionNewsContentLength: "",
    sectionNewsContentPagingNum: "",
    sectionNewsContentMaxRowNum: "",
    sectionNewsContentLoading: false,
  },
  sectionPublicSharing: {
    sectionPublicSharingData: [],
    sectionPublicSharingDataLabel: [],
    publicStockDataMap: [],
    sectionPublicSharingLoading: false,
  },
  sectionPublic: {
    sectionPublicData: [],
    sectionNonPublicData: [],
    sectionPublicLoading: false,
  },
  sectionPublicTopTenGreen: {
    sectionPublicTopTenGreenData: [],
    sectionPublicTopTenGreenLoading: false,
  },
  sectionImportPercentage: {
    sectionImportPercentageData: [],
    sectionImportPercentageLabel: [],
    sectionImportPercentageTags: [],
    sectionImportPercentageMap: [],
    sectionImportPercentageLoading: false,
  },
  sectionExportPercentage: {
    sectionExportPercentageData: [],
    sectionExportPercentageLabel: [],
    sectionExportPercentageTags: [],
    sectionExportPercentageMap: [],
    sectionExportPercentageLoading: false,
  },
  twoCodePercentage: {
    twoCodePercentageData: [],
    twoCodePercentageLabel: [],
    twoCodePercentageDataMap: [],
    twoCodePercentageLoading: false,
  },
  threeCodePercentage: {
    threeCodePercentageData: [],
    threeCodePercentageLabel: [],
    threeCodePercentageDataMap: [],
    threeCodePercentageLoading: false,
  },
  fourCodePercentage: {
    fourCodePercentageData: [],
    fourCodePercentageLabel: [],
    fourCodePercentageDataMap: [],
    fourCodePercentageLoading: false,
  },
  sectionTopTenCapital: {
    sectionTopTenCapitalData: [],
    sectionTopTenCapitalDataLabels: [],
    sectionTopTenCapitalDataTags: [],
    sectionTopTenCapitalDataMap: [],
    sectionTopTenCapitalLoading: false,
  },
  sectionTopTenGovPlan: {
    sectionTopTenGovPlanData: [],
    sectionTopTenGovPlanDataLabels: [],
    sectionTopTenGovPlanDataTags: [],
    sectionTopTenGovPlanDataMap: [],
    sectionTopTenGovPlanLoading: false,
  },
  sectionTopTenPatent: {
    sectionTopTenPatentData: [],
    sectionTopTenPatentDataLabels: [],
    sectionTopTenPatentDataTags: [],
    sectionTopTenPatentDataMap: [],
    sectionTopTenPatentLoading: false,
  },
  sectionTopTenEmployee: {
    sectionTopTenEmployeeData: [],
    sectionTopTenEmployeeLabels: [],
    sectionTopTenEmployeeTags: [],
    sectionTopTenEmployeeMap: [],
    sectionTopTenEmployeeloading: false,
  },
  sectionGovPlanPercent: {
    sectionGovPlanPercentData: [],
    sectionGovPlanPercentDataLabels: [],
    sectionGovPlanPercentDataTags: [],
    sectionGovPlanPercentDataMap: [],
    sectionGovPlanPercentloading: false,
  },
  sectionAwardPercent: {
    sectionAwardPercentData: [],
    sectionAwardPercentDataLabels: [],
    sectionAwardPercentDataTags: [],
    sectionAwardPercentDataMap: [],
    sectionAwardPercentloading: false,
  },
  sectionPatentPercent: {
    sectionPatentPercentData: [],
    sectionPatentPercentloading: false,
  },
  mapSearch: {
    mapSearchData: [],
    mapSearchloading: false,
  },
  topFiveWasteDisposal: {
    topFiveWasteDisposalData: [],
    topFiveWasteDisposalLoading: false,
  },

  // ====================== MODAL ======================

  sectionTwoCodePercentageList: {
    sectionTwoCodePercentageListData: [],
    sectionTwoCodePercentageListloading: false,
  },
  sectionThreeCodePercentageList: {
    sectionThreeCodePercentageListData: [],
    sectionThreeCodePercentageListloading: false,
  },
  sectionFourCodePercentageList: {
    sectionFourCodePercentageListData: [],
    sectionFourCodePercentageListloading: false,
  },
  sectionImportList: {
    sectionImportListData: [],
    sectionImportListloading: false,
  },
  sectionExportList: {
    sectionExportListData: [],
    sectionExportListloading: false,
  },
  sectionPatentInfoList: {
    sectionPatentInfoListData: [],
    sectionPatentInfoListloading: false,
  },
  sectionGovPlanList: {
    sectionGovPlanListData: [],
    sectionGovPlanListloading: false,
  },
  sectionParkDetailInfoList: {
    sectionParkDetailInfoListData: [],
    sectionParkDetailInfoListloading: false,
  },
  sectionAwardList: {
    sectionAwardListData: [],
    sectionAwardListloading: false,
  },
  wasteDisposalList: {
    wasteDisposalListData: [],
    wasteDisposalListLoading: false,
  },
  sectionStockList: {
    sectionStockListData: [],
    sectionStockListloading: false,
  },
};

//取得儀表板資訊
export const startSectionMapData = (state) => {
  return {
    ...state,
    sectionMap: {
      ...state.sectionMap,
      sectionMapLoading: true,
    },
  };
};

export const getSectionMapSuccess = (state, data) => {
  return {
    ...state,
    sectionMap: {
      ...state.sectionMap,
      sectionMapData: data,
      sectionMapLoading: false,
    },
  };
};

export const getSectionMapFail = (state, err) => {
  return {
    ...state,
    sectionMap: {
      ...state.sectionMap,
      sectionMapLoading: false,
    },
    errorMessage: err,
  };
};

//修改儀表板資訊
export const startUpdateSectionMapData = (state) => {
  return {
    ...state,
    updateSectionMap: {
      ...state.updateSectionMap,
      updateSectionMapLoading: true,
    },
  };
};

export const getUpdateSectionMapSuccess = (state, data) => {
  return {
    ...state,
    updateSectionMap: {
      ...state.updateSectionMap,
      updateSectionMapLoading: false,
    },
  };
};

export const getUpdateSectionMapFail = (state, err) => {
  return {
    ...state,
    updateSectionMap: {
      ...state.updateSectionMap,
      updateSectionMapLoading: false,
    },
    errorMessage: err,
  };
};

//取得同服務中心的園區
export const startServiceCenterParkLinkData = (state) => {
  return {
    ...state,
    serviceCenterParkLink: {
      ...state.serviceCenterParkLink,
      serviceCenterParkLinkLoading: true,
    },
  };
};

export const getServiceCenterParkLinkDataSuccess = (state, data) => {
  return {
    ...state,
    serviceCenterParkLink: {
      ...state.serviceCenterParkLink,
      serviceCenterParkLinkData: data,
      serviceCenterParkLinkLoading: false,
    },
  };
};

export const getServiceCenterParkLinkDataFail = (state, err) => {
  return {
    ...state,
    serviceCenterParkLink: {
      ...state.serviceCenterParkLink,
      serviceCenterParkLinkLoading: false,
    },
    errorMessage: err,
  };
};

//取得概況全國與地區排名
export const startGetBriefAndRankingData = (state, action) => {
  return {
    ...state,
    briefAndRanking: {
      ...state.briefAndRanking,
      briefAndRankingLoading: true,
    },
  };
};

export const getBriefAndRankingDataSuccess = (state, data) => {
  return {
    ...state,
    briefAndRanking: {
      ...state.briefAndRanking,
      briefAndRankingList: data[0],
      briefAndRankingLoading: false,
    },
  };
};

export const getBriefAndRankingDataFail = (state, action) => {
  return {
    briefAndRanking: {
      ...state.briefAndRanking,
      briefAndRankingLoading: false,
    },
    err: action.err,
  };
};

//取得縣市租用土地資料
export const startFactoryAreaRentData = (state) => {
  return {
    ...state,
    factoryAreaRent: {
      ...state.factoryAreaRent,
      factoryAreaRentLoading: true,
    },
  };
};

export const getFactoryAreaRentDataSuccess = (state, data) => {
  return {
    ...state,
    factoryAreaRent: {
      ...state.factoryAreaRent,
      factoryAreaRentData: data,
      factoryAreaRentLoading: false,
    },
  };
};

export const getFactoryAreaRentDataFail = (state, err) => {
  return {
    ...state,
    factoryAreaRent: {
      ...state.factoryAreaRent,
      factoryAreaRentLoading: false,
    },
    errorMessage: err,
  };
};

//取得單一園區 主要指標
export const startGetSectionIndexData = (state) => {
  return {
    ...state,
    sectionIndex: {
      ...state.sectionIndex,
      sectionIndexLoading: true,
    },
  };
};

export const getSectionIndexDataSuccess = (state, data) => {
  return {
    ...state,
    sectionIndex: {
      ...state.sectionIndex,
      sectionIndexData: { ...data },
      sectionIndexLoading: false,
    },
  };
};

export const getSectionIndexDataFail = (state, err) => {
  return {
    ...state,
    sectionIndex: {
      ...state.sectionIndex,
      sectionIndexLoading: false,
    },
    errorMessage: err,
  };
};

//取得單一園區 新聞標籤
export const startGetSectionNewsTagData = (state) => {
  return {
    ...state,
    sectionNewsTag: {
      ...state.sectionNewsTag,
      sectionNewsTagLoading: true,
    },
  };
};

export const getSectionNewsTagDataSuccess = (state, data) => {
  const updateSectionNewsTag = [];
  data.map((item) => {
    updateSectionNewsTag.push({
      value: item.Keywords,
      count: item.TermCount,
    });
  });
  return {
    ...state,
    sectionNewsTag: {
      ...state.sectionNewsTag,
      sectionNewsTagData: updateSectionNewsTag,
      sectionNewsTagLoading: false,
    },
  };
};

export const getSectionNewsTagDataFail = (state, err) => {
  return {
    ...state,
    sectionNewsTag: {
      ...state.sectionNewsTag,
      sectionNewsTagLoading: false,
    },
    errorMessage: err,
  };
};

//取得單一園區 新聞內容
export const startGetSectionNewsContentData = (state) => {
  return {
    ...state,
    sectionNewsContent: {
      ...state.sectionNewsContent,
      sectionNewsContentLoading: true,
    },
  };
};

export const getSectionNewsContentDataSuccess = (state, data) => {
  return {
    ...state,
    sectionNewsContent: {
      ...state.sectionNewsContent,
      sectionNewsContentData: data,
      sectionNewsContentLength: data.TotalLength,
      sectionNewsContentPagingNum: data.PagingNum,
      sectionNewsContentMaxRowNum: data.MaxRowNum,
      sectionNewsContentLoading: false,
    },
  };
};

export const getSectionNewsContentDataFail = (state, err) => {
  return {
    ...state,
    sectionNewsContent: {
      ...state.sectionNewsContent,
      sectionNewsContentLoading: false,
    },
    errorMessage: err,
  };
};

//取得單一園區 公開發行占比
export const startGetSectionPublicSharingData = (state) => {
  return {
    ...state,
    sectionPublicSharing: {
      ...state.sectionPublicSharing,
      sectionPublicSharingLoading: true,
    },
  };
};

export const getSectioPublicSharingDataSuccess = (state, data) => {
  const updateSectionPublicSharingData = [];
  const updateSectionPublicSharingLabel = [];

  data.map((type) => {
    updateSectionPublicSharingData.push(type.y);
    updateSectionPublicSharingLabel.push(type.label);
  });
  return {
    ...state,
    sectionPublicSharing: {
      ...state.sectionPublicSharing,
      sectionPublicSharingData: updateSectionPublicSharingData,
      sectionPublicSharingDataLabel: updateSectionPublicSharingLabel,
      publicStockDataMap: data,
      sectionPublicSharingLoading: false,
    },
  };
};

export const getSectionPublicSharingDataFail = (state, err) => {
  return {
    ...state,
    sectionPublicSharing: {
      ...state.sectionPublicSharing,
      sectionPublicSharingLoading: false,
    },
    errorMessage: err,
  };
};

//取得單一園區 股票公開發行前十大廠商清單
export const startGetSectionPublicData = (state) => {
  return {
    ...state,
    sectionPublic: {
      ...state.sectionPublic,
      sectionPublicLoading: true,
    },
  };
};

export const getSectionPublicDataSuccess = (state, data) => {
  const updateSectionPublicData = [];
  const updateSectionNonPublicData = [];

  data.map((company) => {
    if (company.StockType === "未上市") {
      updateSectionNonPublicData.push(company);
    } else {
      updateSectionPublicData.push(company);
    }
  });
  return {
    ...state,
    sectionPublic: {
      ...state.sectionPublic,
      sectionPublicData: updateSectionPublicData,
      sectionNonPublicData: updateSectionNonPublicData,
      sectionPublicLoading: false,
    },
  };
};

export const getSectionPublicDataFail = (state, err) => {
  return {
    ...state,
    sectionPublic: {
      ...state.sectionPublic,
      sectionPublicLoading: false,
    },
    errorMessage: err,
  };
};

//取得單一園區 綠色工廠前十大廠商
export const startGetSectionPublicTopTenGreenData = (state) => {
  return {
    ...state,
    sectionPublicTopTenGreen: {
      ...state.sectionPublicTopTenGreen,
      sectionPublicTopTenGreenLoading: true,
    },
  };
};

export const getSectionPublicTopTenGreenDataSuccess = (state, data) => {
  return {
    ...state,
    sectionPublicTopTenGreen: {
      ...state.sectionPublicTopTenGreen,
      sectionPublicTopTenGreenData: data,
      sectionPublicTopTenGreenLoading: false,
    },
  };
};

export const getSectionPublicTopTenGreenDataFail = (state, err) => {
  return {
    ...state,
    sectionPublicTopTenGreen: {
      ...state.sectionPublicTopTenGreen,
      sectionPublicTopTenGreenLoading: false,
    },
    errorMessage: err,
  };
};

//取得單一園區 進口級距佔比-圓餅圖
export const startGetSectionImportPercentageData = (state) => {
  return {
    ...state,
    sectionImportPercentage: {
      ...state.sectionImportPercentage,
      sectionImportPercentageLoading: true,
    },
  };
};

export const getSectionImportPercentageDataSuccess = (state, data) => {
  const updateSectionTradeRateData = [];
  const updateSectionTradeRateLabel = [];
  const updateSectionTradeRateTags = [];

  data.map((type) => {
    updateSectionTradeRateData.push(type.y);
    updateSectionTradeRateLabel.push(type.label);
    updateSectionTradeRateTags.push(type.tag);
  });
  return {
    ...state,
    sectionImportPercentage: {
      ...state.sectionImportPercentage,
      sectionImportPercentageData: updateSectionTradeRateData,
      sectionImportPercentageLabel: updateSectionTradeRateLabel,
      sectionImportPercentageTags: updateSectionTradeRateTags,
      sectionImportPercentageMap: data,
      sectionImportPercentageLoading: false,
    },
  };
};

export const getSectionImportPercentageDataFail = (state, err) => {
  return {
    ...state,
    sectionImportPercentage: {
      ...state.sectionImportPercentage,
      sectionImportPercentageLoading: false,
      errorMessage: err,
    },
  };
};

//取得單一園區 出口級距佔比-圓餅圖
export const startGetSectionExportPercentageData = (state) => {
  return {
    ...state,
    sectionExportPercentage: {
      ...state.sectionExportPercentage,
      sectionExportPercentageLoading: true,
    },
  };
};

export const getSectionExportPercentageDataSuccess = (state, data) => {
  const updateSectionTradeRateData = [];
  const updateSectionTradeRateLabel = [];
  const updateSectionTradeRateTags = [];

  data.map((type) => {
    updateSectionTradeRateData.push(type.y);
    updateSectionTradeRateLabel.push(type.label);
    updateSectionTradeRateTags.push(type.tag);
  });
  return {
    ...state,
    sectionExportPercentage: {
      ...state.sectionExportPercentage,
      sectionExportPercentageData: updateSectionTradeRateData,
      sectionExportPercentageLabel: updateSectionTradeRateLabel,
      sectionExportPercentageTags: updateSectionTradeRateTags,
      sectionExportPercentageMap: data,
      sectionExportPercentageLoading: false,
    },
  };
};

export const getSectionExportPercentageDataFail = (state, err) => {
  return {
    ...state,
    sectionExportPercentage: {
      ...state.sectionExportPercentage,
      sectionExportPercentageLoading: false,
      errorMessage: err,
    },
  };
};

//取得單一園區 產業別2碼家數佔比-圓餅圖
export const startGetTwoCodePercentageData = (state) => {
  return {
    ...state,
    twoCodePercentage: {
      ...state.twoCodePercentage,
      twoCodePercentageLoading: true,
    },
  };
};

export const getTwoCodePercentageDataSuccess = (state, data) => {
  const updateTwoCodePercentageLabel = [];
  const updateTwoCodePercentageData = [];
  data.map((type) => {
    updateTwoCodePercentageData.push(type.y);
    updateTwoCodePercentageLabel.push(type.label);
  });
  return {
    ...state,
    twoCodePercentage: {
      ...state.twoCodePercentage,
      twoCodePercentageData: updateTwoCodePercentageData,
      twoCodePercentageDataMap: data,
      twoCodePercentageLabel: updateTwoCodePercentageLabel,
      twoCodePercentageLoading: false,
    },
  };
};

export const getTwoCodePercentageDataFail = (state, err) => {
  return {
    ...state,
    twoCodePercentage: {
      ...state.twoCodePercentage,
      twoCodePercentageLoading: false,
    },
    errorMessage: err,
  };
};

//取得單一園區 產業別3碼家數佔比-圓餅圖
export const startGetThreeCodePercentageData = (state) => {
  return {
    ...state,
    threeCodePercentage: {
      ...state.threeCodePercentage,
      threeCodePercentageLoading: true,
    },
  };
};

export const getThreeCodePercentageDataSuccess = (state, data) => {
  const updateThreeCodePercentageLabel = [];
  const updateThreeCodePercentageData = [];
  data.map((type) => {
    updateThreeCodePercentageData.push(type.y);
    updateThreeCodePercentageLabel.push(type.label);
  });
  return {
    ...state,
    threeCodePercentage: {
      ...state.threeCodePercentage,
      threeCodePercentageData: updateThreeCodePercentageData,
      threeCodePercentageDataMap: data,
      threeCodePercentageLabel: updateThreeCodePercentageLabel,
      threeCodePercentageLoading: false,
    },
  };
};

export const getThreeCodePercentageDataFail = (state, err) => {
  return {
    ...state,
    threeCodePercentage: {
      ...state.threeCodePercentage,
      threeCodePercentageLoading: false,
    },
    errorMessage: err,
  };
};

//取得單一園區 產業別4碼家數佔比-圓餅圖
export const startGetFourCodePercentageData = (state) => {
  return {
    ...state,
    fourCodePercentage: {
      ...state.fourCodePercentage,
      fourCodePercentageLoading: true,
    },
  };
};

export const getFourCodePercentageDataSuccess = (state, data) => {
  const updateFourCodePercentageLabel = [];
  const updateFourCodePercentageData = [];
  data.map((type) => {
    updateFourCodePercentageData.push(type.y);
    updateFourCodePercentageLabel.push(type.label);
  });
  return {
    ...state,
    fourCodePercentage: {
      ...state.fourCodePercentage,
      fourCodePercentageData: updateFourCodePercentageData,
      fourCodePercentageDataMap: data,
      fourCodePercentageLabel: updateFourCodePercentageLabel,
      fourCodePercentageLoading: false,
    },
  };
};

export const getFourCodePercentageDataFail = (state, err) => {
  return {
    ...state,
    fourCodePercentage: {
      ...state.fourCodePercentage,
      fourCodePercentageLoading: false,
    },
    errorMessage: err,
  };
};

//取得單一園區 資本額前十大廠商排行-長條圖
export const startGetSectionTopTenCapitalData = (state) => {
  return {
    ...state,
    sectionTopTenCapital: {
      ...state.sectionTopTenCapital,
      sectionTopTenCapitalLoading: true,
    },
  };
};

export const getSectionTopTenCapitalDataSuccess = (state, data) => {
  const updateSectionTopTenCapitalData = [];
  const updateSectionTopTenCapitalDataLabels = [];
  const updateSectionTopTenCapitalDataTags = [];

  data.map((type) => {
    updateSectionTopTenCapitalData.push(type.y);
    updateSectionTopTenCapitalDataLabels.push(type.label);
    updateSectionTopTenCapitalDataTags.push(type.tag);
  });
  return {
    ...state,
    sectionTopTenCapital: {
      ...state.sectionTopTenCapital,
      sectionTopTenCapitalData: updateSectionTopTenCapitalData,
      sectionTopTenCapitalDataLabels: updateSectionTopTenCapitalDataLabels,
      sectionTopTenCapitalDataTags: updateSectionTopTenCapitalDataTags,
      sectionTopTenCapitalDataMap: data,
      sectionTopTenCapitalLoading: false,
    },
  };
};

export const getSectionTopTenCapitalDataFail = (state, err) => {
  return {
    ...state,
    sectionTopTenCapital: {
      ...state.sectionTopTenCapital,
      sectionTopTenCapitalLoading: false,
    },
    errorMessage: err,
  };
};

//取得單一園區 政府計劃前十大廠商
export const startGetSectionTopTenGovernmentPlanData = (state) => {
  return {
    ...state,
    sectionTopTenGovPlan: {
      ...state.sectionTopTenGovPlan,
      sectionTopTenGovernmentPlanLoading: true,
    },
  };
};

export const getSectionTopTenGovernmentPlanDataSuccess = (state, data) => {
  const updateSectionTopTenGovPlanData = [];
  const updateSectionTopTenGovPlanDataLabels = [];
  const updateSectionTopTenGovPlanDataTags = [];

  data.map((type) => {
    updateSectionTopTenGovPlanData.push(type.y);
    updateSectionTopTenGovPlanDataLabels.push(type.label);
    updateSectionTopTenGovPlanDataTags.push(type.tag);
  });
  return {
    ...state,
    sectionTopTenGovPlan: {
      ...state.sectionTopTenGovPlan,
      sectionTopTenGovPlanData: updateSectionTopTenGovPlanData,
      sectionTopTenGovPlanDataLabels: updateSectionTopTenGovPlanDataLabels,
      sectionTopTenGovPlanDataTags: updateSectionTopTenGovPlanDataTags,
      sectionTopTenGovPlanDataMap: data,
      sectionTopTenGovPlanLoading: false,
    },
  };
};

export const getSectionTopTenGovernmentPlanDataFail = (state, err) => {
  return {
    ...state,
    sectionTopTenGovPlan: {
      ...state.sectionTopTenGovPlan,
      sectionTopTenGovernmentPlanLoading: false,
    },
    errorMessage: err,
  };
};

//取得單一園區 專利數前十大-長條圖
export const startGetSectionTopTenPatentData = (state) => {
  return {
    ...state,
    sectionTopTenPatent: {
      ...state.sectionTopTenPatent,
      sectionTopTenPatentLoading: true,
    },
  };
};

export const getSectionTopTenPatentDataSuccess = (state, data) => {
  const updateSectionTopTenPatentData = [];
  const updateSectionTopTenPatentDataLabels = [];
  const updateSectionTopTenPatentDataTags = [];

  data.map((type) => {
    updateSectionTopTenPatentData.push(type.y);
    updateSectionTopTenPatentDataLabels.push(type.label);
    updateSectionTopTenPatentDataTags.push(type.tag);
  });
  return {
    ...state,
    sectionTopTenPatent: {
      ...state.sectionTopTenPatent,
      sectionTopTenPatentData: updateSectionTopTenPatentData,
      sectionTopTenPatentDataLabels: updateSectionTopTenPatentDataLabels,
      sectionTopTenPatentDataTags: updateSectionTopTenPatentDataTags,
      sectionTopTenPatentDataMap: data,
      sectionTopTenPatentLoading: false,
    },
  };
};

export const getSectionTopTenPatentDataFail = (state, err) => {
  return {
    ...state,
    sectionTopTenPatent: {
      ...state.sectionTopTenPatent,
      sectionTopTenPatentLoading: false,
    },
    errorMessage: err,
  };
};

//取得單一園區 員工數前十大
export const startGetSectionTopTenEmpData = (state) => {
  return {
    ...state,
    sectionTopTenEmployee: {
      ...state.sectionTopTenEmployee,
      sectionTopTenEmployeeloading: true,
    },
  };
};

export const getSectionTopTenEmpDataSuccess = (state, data) => {
  const updateSectionTopTenEmployeeData = [];
  const updateSectionTopTenEmployeeDataLabels = [];
  const updateSectionTopTenEmployeeDataTags = [];

  data.map((type) => {
    updateSectionTopTenEmployeeData.push(type.y);
    updateSectionTopTenEmployeeDataLabels.push(type.label);
    updateSectionTopTenEmployeeDataTags.push(type.tag);
  });
  return {
    ...state,
    sectionTopTenEmployee: {
      ...state.sectionTopTenEmployee,
      sectionTopTenEmployeeData: updateSectionTopTenEmployeeData,
      sectionTopTenEmployeeLabels: updateSectionTopTenEmployeeDataLabels,
      sectionTopTenEmployeeTags: updateSectionTopTenEmployeeDataTags,
      sectionTopTenEmployeeMap: data,
      sectionTopTenEmployeeloading: false,
    },
  };
};

export const getSectionTopTenEmpDataFail = (state, err) => {
  return {
    ...state,
    sectionTopTenEmployee: {
      ...state.sectionTopTenEmployee,
      sectionTopTenEmployeeloading: false,
    },
    errorMessage: err,
  };
};

//取得單一園區 政府計畫數類型佔比
export const startGetSectionGovPlanPercentData = (state) => {
  return {
    ...state,
    sectionGovPlanPercent: {
      ...state.sectionGovPlanPercent,
      sectionGovPlanPercentloading: true,
    },
  };
};

export const getSectionGovPlanPercentDataSuccess = (state, data) => {
  const updateSectionGovPlanPercentData = [];
  const updateSectionGovPlanPercentLabels = [];
  data.map((type) => {
    updateSectionGovPlanPercentData.push(type.y);
    updateSectionGovPlanPercentLabels.push(type.label);
  });
  return {
    ...state,
    sectionGovPlanPercent: {
      ...state.sectionGovPlanPercent,
      sectionGovPlanPercentData: updateSectionGovPlanPercentData,
      sectionGovPlanPercentDataLabels: updateSectionGovPlanPercentLabels,
      sectionGovPlanPercentDataMap: data,
      sectionGovPlanPercentloading: false,
    },
  };
};

export const getSectionGovPlanPercentDataFail = (state, err) => {
  return {
    ...state,
    sectionGovPlanPercent: {
      ...state.sectionGovPlanPercent,
      sectionGovPlanPercentloading: false,
    },
    errorMessage: err,
  };
};

//取得單一園區 獲獎數類型佔比
export const startGetSectionAwardPercentData = (state) => {
  return {
    ...state,
    sectionAwardPercent: {
      ...state.sectionAwardPercent,
      sectionAwardPercentloading: true,
    },
  };
};

export const getSectionAwardPercentDataSuccess = (state, data) => {
  const updateSectionAwardPercenData = [];
  const updateSectionAwardPercentDataLabels = [];
  const updateSectionAwardPercentDataTags = [];

  data.map((type) => {
    updateSectionAwardPercenData.push(type.y);
    updateSectionAwardPercentDataLabels.push(type.label);
    updateSectionAwardPercentDataTags.push(type.tag);
  });

  return {
    ...state,
    sectionAwardPercent: {
      ...state.sectionAwardPercent,
      sectionAwardPercentData: updateSectionAwardPercenData,
      sectionAwardPercentDataLabels: updateSectionAwardPercentDataLabels,
      sectionAwardPercentDataTags: updateSectionAwardPercentDataTags,
      sectionAwardPercentDataMap: data,
      sectionAwardPercentloading: false,
    },
  };
};

export const getSectionAwardPercentDataFail = (state, err) => {
  return {
    ...state,
    sectionAwardPercent: {
      ...state.sectionAwardPercent,
      sectionAwardPercentloading: false,
    },
    errorMessage: err,
  };
};

//取得單一園區 專利數占比-圓餅圖
export const startGetSectionPatentPercentData = (state) => {
  return {
    ...state,
    sectionPatentPercent: {
      ...state.sectionPatentPercent,
      sectionPatentPercentloading: true,
    },
  };
};

export const getSectionPatentPercentDataSuccess = (state, data) => {
  return {
    ...state,
    sectionPatentPercent: {
      ...state.sectionPatentPercent,
      sectionPatentPercentData: data,
      sectionPatentPercentloading: false,
    },
  };
};

export const getSectionPatentPercentDataFail = (state, err) => {
  return {
    ...state,
    sectionPatentPercent: {
      ...state.sectionPatentPercent,
      sectionPatentPercentloading: false,
    },
    errorMessage: err,
  };
};

//產品搜尋地圖
export const startGetMapSearchData = (state) => {
  return {
    ...state,
    mapSearch: {
      ...state.mapSearch,
      mapSearchloading: true,
    },
  };
};

export const getMapSearchDataSuccess = (state, data) => {
  return {
    ...state,
    mapSearch: {
      ...state.mapSearch,
      mapSearchData: data,
      mapSearchloading: false,
    },
  };
};

export const getMapSearchDataFail = (state, err) => {
  return {
    ...state,
    mapSearch: {
      ...state.mapSearch,
      mapSearchloading: false,
    },
    errorMessage: err,
  };
};

//取得廢棄物前5大排行
export const startTopFiveWasteDisposalData = (state) => {
  return {
    ...state,
    topFiveWasteDisposal: {
      ...state.topFiveWasteDisposal,
      topFiveWasteDisposalLoading: true,
    },
  };
};

export const getTopFiveWasteDisposalDataSuccess = (state, data) => {
  return {
    ...state,
    topFiveWasteDisposal: {
      ...state.topFiveWasteDisposal,
      topFiveWasteDisposalData: data,
      topFiveWasteDisposalLoading: false,
    },
  };
};

export const getTopFiveWasteDisposalDataFail = (state, err) => {
  return {
    ...state,
    topFiveWasteDisposal: {
      ...state.topFiveWasteDisposal,
      topFiveWasteDisposalLoading: false,
    },
    errorMessage: err,
  };
};

// ====================== MODAL ======================

//單一園區 產業別2碼家數佔比-Modal
export const startSectionTwoCodePercentageListData = (state) => {
  return {
    ...state,
    sectionTwoCodePercentageList: {
      ...state.sectionTwoCodePercentageList,
      sectionTwoCodePercentageListloading: true,
    },
  };
};

export const getSectionTwoCodePercentageListSuccess = (state, data) => {
  return {
    ...state,
    sectionTwoCodePercentageList: {
      ...state.sectionTwoCodePercentageList,
      sectionTwoCodePercentageListData: data,
      sectionTwoCodePercentageListloading: false,
    },
  };
};

export const getSectionTwoCodePercentageListFail = (state, err) => {
  return {
    ...state,
    sectionTwoCodePercentageList: {
      ...state.sectionTwoCodePercentageList,
      sectionTwoCodePercentageListloading: false,
    },
    errorMessage: err,
  };
};

//單一園區 產業別3碼家數佔比-Modal
export const startSectionThreeCodePercentageListData = (state) => {
  return {
    ...state,
    sectionThreeCodePercentageList: {
      ...state.sectionThreeCodePercentageList,
      sectionThreeCodePercentageListloading: true,
    },
  };
};

export const getSectionThreeCodePercentageListSuccess = (state, data) => {
  return {
    ...state,
    sectionThreeCodePercentageList: {
      ...state.sectionThreeCodePercentageList,
      sectionThreeCodePercentageListData: data,
      sectionThreeCodePercentageListloading: false,
    },
  };
};

export const getSectionThreeCodePercentageListFail = (state, err) => {
  return {
    ...state,
    sectionThreeCodePercentageList: {
      ...state.sectionThreeCodePercentageList,
      sectionThreeCodePercentageListloading: false,
    },
    errorMessage: err,
  };
};

//單一園區 產業別4碼家數佔比-Modal
export const startSectionFourCodePercentageListData = (state) => {
  return {
    ...state,
    sectionFourCodePercentageList: {
      ...state.sectionFourCodePercentageList,
      sectionFourCodePercentageListloading: true,
    },
  };
};

export const getSectionFourCodePercentageListSuccess = (state, data) => {
  return {
    ...state,
    sectionFourCodePercentageList: {
      ...state.sectionFourCodePercentageList,
      sectionFourCodePercentageListData: data,
      sectionFourCodePercentageListloading: false,
    },
  };
};

export const getSectionFourCodePercentageListFail = (state, err) => {
  return {
    ...state,
    sectionFourCodePercentageList: {
      ...state.sectionFourCodePercentageList,
      sectionFourCodePercentageListloading: false,
    },
    errorMessage: err,
  };
};

//單一園區 進口級距佔比-清單-Modal
export const startSectionImportListData = (state) => {
  return {
    ...state,
    sectionImportList: {
      ...state.sectionImportList,
      sectionImportListloading: true,
    },
  };
};

export const getSectionImportListSuccess = (state, data) => {
  return {
    ...state,
    sectionImportList: {
      ...state.sectionImportList,
      sectionImportListData: data,
      sectionImportListloading: false,
    },
  };
};

export const getSectionImportListFail = (state, err) => {
  return {
    ...state,
    sectionImportList: {
      ...state.sectionImportList,
      sectionImportListloading: false,
    },
    errorMessage: err,
  };
};

//單一園區 出口級距佔比-清單-Modal
export const startSectionExportListData = (state) => {
  return {
    ...state,
    sectionExportList: {
      ...state.sectionExportList,
      sectionExportListloading: true,
    },
  };
};

export const getSectionExportListSuccess = (state, data) => {
  return {
    ...state,
    sectionExportList: {
      ...state.sectionExportList,
      sectionExportListData: data,
      sectionExportListloading: false,
    },
  };
};

export const getSectionExportListFail = (state, err) => {
  return {
    ...state,
    sectionExportList: {
      ...state.sectionExportList,
      sectionExportListloading: false,
    },
    errorMessage: err,
  };
};

//單一園區 專利資料-Modal
export const startSectionPatentInfoListData = (state) => {
  return {
    ...state,
    sectionPatentInfoList: {
      ...state.sectionPatentInfoList,
      sectionPatentInfoListloading: true,
    },
  };
};

export const getSectionPatentInfoListSuccess = (state, data) => {
  return {
    ...state,
    sectionPatentInfoList: {
      ...state.sectionPatentInfoList,
      sectionPatentInfoListData: data,
      sectionPatentInfoListloading: false,
    },
  };
};

export const getSectionPatentInfoListFail = (state, err) => {
  return {
    ...state,
    sectionPatentInfoList: {
      ...state.sectionPatentInfoList,
      sectionPatentInfoListloading: false,
    },
    errorMessage: err,
  };
};

//單一園區 政府計畫-Modal
export const startSectionGovPlanListData = (state) => {
  return {
    ...state,
    sectionGovPlanList: {
      ...state.sectionGovPlanList,
      sectionGovPlanListloading: true,
    },
  };
};

export const getSectionGovPlanListSuccess = (state, data) => {
  return {
    ...state,
    sectionGovPlanList: {
      ...state.sectionGovPlanList,
      sectionGovPlanListData: data,
      sectionGovPlanListloading: false,
    },
  };
};

export const getSectionGovPlanListFail = (state, err) => {
  return {
    ...state,
    sectionGovPlanList: {
      ...state.sectionGovPlanList,
      sectionGovPlanListloading: false,
    },
    errorMessage: err,
  };
};

//單一園區 取得園區廠商政府計畫類型佔比-Modal
export const startSectionParkDetailInfoListData = (state) => {
  return {
    ...state,
    sectionParkDetailInfoList: {
      ...state.sectionParkDetailInfoList,
      sectionParkDetailInfoListloading: true,
    },
  };
};

export const getSectionParkDetailInfoListSuccess = (state, data) => {
  return {
    ...state,
    sectionParkDetailInfoList: {
      ...state.sectionParkDetailInfoList,
      sectionParkDetailInfoListData: data,
      sectionParkDetailInfoListloading: false,
    },
  };
};

export const getSectionParkDetailInfoListFail = (state, err) => {
  return {
    ...state,
    sectionParkDetailInfoList: {
      ...state.sectionParkDetailInfoList,
      sectionParkDetailInfoListloading: false,
    },
    errorMessage: err,
  };
};

//單一園區 取得園區廠商獲獎類型佔比資料-Modal
export const startSectionAwardListData = (state) => {
  return {
    ...state,
    sectionAwardList: {
      ...state.sectionAwardList,
      sectionAwardListloading: true,
    },
  };
};

export const getSectionAwardListSuccess = (state, data) => {
  return {
    ...state,
    sectionAwardList: {
      ...state.sectionAwardList,
      sectionAwardListData: data,
      sectionAwardListloading: false,
    },
  };
};

export const getSectionAwardListFail = (state, err) => {
  return {
    ...state,
    sectionAwardList: {
      ...state.sectionAwardList,
      sectionAwardListloading: false,
    },
    errorMessage: err,
  };
};

//單一園區 取得廢棄物廠商追溯表-Modal
export const startWasteDisposalListData = (state) => {
  return {
    ...state,
    wasteDisposalList: {
      ...state.wasteDisposalList,
      wasteDisposalListLoading: true,
    },
  };
};

export const getWasteDisposalListSuccess = (state, data) => {
  return {
    ...state,
    wasteDisposalList: {
      ...state.wasteDisposalList,
      wasteDisposalListData: data,
      wasteDisposalListLoading: false,
    },
  };
};

export const getWasteDisposalListFail = (state, err) => {
  return {
    ...state,
    wasteDisposalList: {
      ...state.wasteDisposalList,
      wasteDisposalListLoading: false,
    },
    errorMessage: err,
  };
};

//單一園區 公開發行狀況佔比-Modal
export const startSectionStockListData = (state) => {
  return {
    ...state,
    sectionStockList: {
      ...state.sectionStockList,
      sectionStockListloading: true,
    },
  };
};

export const getSectionStockListSuccess = (state, data) => {
  return {
    ...state,
    sectionStockList: {
      ...state.sectionStockList,
      sectionStockListData: data,
      sectionStockListloading: false,
    },
  };
};

export const getSectionStockListFail = (state, err) => {
  return {
    ...state,
    sectionStockList: {
      ...state.sectionStockList,
      sectionStockListloading: false,
    },
    errorMessage: err,
  };
};

export default (state = initState, action) => {
  switch (action.type) {
    //取得儀表板資訊
    case actionTypes.START_GET_SECTION_MAP:
      return startSectionMapData(state);
    case actionTypes.GET_SECTION_MAP_SUCCESS:
      return getSectionMapSuccess(state, action.data);
    case actionTypes.GET_SECTION_MAP_FAIL:
      return getSectionMapFail(state, action.err);

    //修改儀表板資訊
    case actionTypes.START_UPDATE_SECTION_MAP:
      return startUpdateSectionMapData(state);
    case actionTypes.UPDATE_SECTION_MAP_SUCCESS:
      return getUpdateSectionMapSuccess(state);
    case actionTypes.UPDATE_SECTION_MAP_FAIL:
      return getUpdateSectionMapFail(state, action.err);

    //取得同服務中心的園區
    case actionTypes.START_GET_SERVICE_CENTER_PARK_LINK_DATA:
      return startServiceCenterParkLinkData(state);
    case actionTypes.GET_SERVICE_CENTER_PARK_LINK_DATA_SUCCESS:
      return getServiceCenterParkLinkDataSuccess(state, action.data);
    case actionTypes.GET_SERVICE_CENTER_PARK_LINK_DATA_FAIL:
      return getServiceCenterParkLinkDataFail(state, action.err);

    //取得概況全國與地區排名
    case actionTypes.START_GET_BRIEF_AND_RANKING_DATA:
      return startGetBriefAndRankingData(state);
    case actionTypes.GET_BRIEF_AND_RANKING_DATA_SUCCESS:
      return getBriefAndRankingDataSuccess(state, action.data);
    case actionTypes.GET_BRIEF_AND_RANKING_DATA_FAIL:
      return getBriefAndRankingDataFail(state, action);

    //取得縣市租用土地資料
    case actionTypes.START_GET_FACTORY_AREA_RENT_DATA:
      return startFactoryAreaRentData(state);
    case actionTypes.GET_FACTORY_AREA_RENT_DATA_SUCCESS:
      return getFactoryAreaRentDataSuccess(state, action.data);
    case actionTypes.GET_FACTORY_AREA_RENT_DATA_FAIL:
      return getFactoryAreaRentDataFail(state, action.err);

    //取得單一園區 主要指標
    case actionTypes.START_GET_SECTION_INDEX_DATA:
      return startGetSectionIndexData(state);
    case actionTypes.GET_SECTION_INDEX_DATA_SUCCESS:
      return getSectionIndexDataSuccess(state, action.data);
    case actionTypes.GET_SECTION_INDEX_DATA_FAIL:
      return getSectionIndexDataFail(state, action.err);

    //取得單一園區 新聞標籤
    case actionTypes.START_GET_SECTION_NEWS_TAG_DATA:
      return startGetSectionNewsTagData(state);
    case actionTypes.GET_SECTION_NEWS_TAG_DATA_SUCCESS:
      return getSectionNewsTagDataSuccess(state, action.data);
    case actionTypes.GET_SECTION_NEWS_TAG_DATA_FAIL:
      return getSectionNewsTagDataFail(state, action.err);

    //取得單一園區 新聞內容
    case actionTypes.START_GET_SECTION_NEWS_CONTENT_DATA:
      return startGetSectionNewsContentData(state);
    case actionTypes.GET_SECTION_NEWS_CONTENT_SUCCESS:
      return getSectionNewsContentDataSuccess(state, action.data);
    case actionTypes.GET_SECTION_NEWS_CONTENT_FAIL:
      return getSectionNewsContentDataFail(state, action.err);

    //取得單一園區 公開發行占比
    case actionTypes.START_GET_SECTION_PUBLIC_SHARING_DATA:
      return startGetSectionPublicSharingData(state);
    case actionTypes.GET_SECTION_PUBLIC_SHARING_SUCCESS:
      return getSectioPublicSharingDataSuccess(state, action.data);
    case actionTypes.GET_SECTION_PUBLIC_SHARING_FAIL:
      return getSectionPublicSharingDataFail(state, action.err);

    //取得單一園區 潛力廠商清單
    case actionTypes.START_GET_SECTION_PUBLIC_DATA:
      return startGetSectionPublicData(state);
    case actionTypes.GET_SECTION_PUBLIC_SUCCESS:
      return getSectionPublicDataSuccess(state, action.data);
    case actionTypes.GET_SECTION_PUBLIC_FAIL:
      return getSectionPublicDataFail(state, action.err);

    //取得單一園區 綠色工廠前十大廠商
    case actionTypes.START_GET_SECTION_PUBLIC_TOP_TEN_GREEN_DATA:
      return startGetSectionPublicTopTenGreenData(state);
    case actionTypes.GET_SECTION_PUBLIC_TOP_TEN_GREEN_SUCCESS:
      return getSectionPublicTopTenGreenDataSuccess(state, action.data);
    case actionTypes.GET_SECTION_PUBLIC_TOP_TEN_GREEN_FAIL:
      return getSectionPublicTopTenGreenDataFail(state, action.err);

    //取得單一園區 進口級距佔比-圓餅圖
    case actionTypes.START_GET_SECTION_IMPORT_PERCENTAGE_DATA:
      return startGetSectionImportPercentageData(state);
    case actionTypes.GET_SECTION_IMPORT_PERCENTAGE_SUCCESS:
      return getSectionImportPercentageDataSuccess(state, action.data);
    case actionTypes.GET_SECTION_IMPORT_PERCENTAGE_FAIL:
      return getSectionImportPercentageDataFail(state, action.err);

    //取得單一園區 出口級距佔比-圓餅圖
    case actionTypes.START_GET_SECTION_EXPORT_PERCENTAGE_DATA:
      return startGetSectionExportPercentageData(state);
    case actionTypes.GET_SECTION_EXPORT_PERCENTAGE_SUCCESS:
      return getSectionExportPercentageDataSuccess(state, action.data);
    case actionTypes.GET_SECTION_EXPORT_PERCENTAGE_FAIL:
      return getSectionExportPercentageDataFail(state, action.err);

    //取得單一園區 產業別2碼家數佔比-圓餅圖
    case actionTypes.START_GET_TWO_CODE_PERCENTAGE_DATA:
      return startGetTwoCodePercentageData(state);
    case actionTypes.GET_TWO_CODE_PERCENTAGE_SUCCESS:
      return getTwoCodePercentageDataSuccess(state, action.data);
    case actionTypes.GET_TWO_CODE_PERCENTAGE_FAIL:
      return getTwoCodePercentageDataFail(state, action.err);

    //取得單一園區 產業別3碼家數佔比-圓餅圖
    case actionTypes.START_GET_THREE_CODE_PERCENTAGE_DATA:
      return startGetThreeCodePercentageData(state);
    case actionTypes.GET_THREE_CODE_PERCENTAGE_SUCCESS:
      return getThreeCodePercentageDataSuccess(state, action.data);
    case actionTypes.GET_THREE_CODE_PERCENTAGE_FAIL:
      return getThreeCodePercentageDataFail(state, action.err);

    //取得單一園區 產業別4碼家數佔比-圓餅圖
    case actionTypes.START_GET_FOUR_CODE_PERCENTAGE_DATA:
      return startGetFourCodePercentageData(state);
    case actionTypes.GET_FOUR_CODE_PERCENTAGE_SUCCESS:
      return getFourCodePercentageDataSuccess(state, action.data);
    case actionTypes.GET_FOUR_CODE_PERCENTAGE_FAIL:
      return getFourCodePercentageDataFail(state, action.err);

    //取得單一園區 資本額前十大廠商排行-長條圖
    case actionTypes.START_GET_SECTION_TOP_TEN_CAPITAL_DATA:
      return startGetSectionTopTenCapitalData(state);
    case actionTypes.GET_SECTION_TOP_TEN_CAPITAL_SUCCESS:
      return getSectionTopTenCapitalDataSuccess(state, action.data);
    case actionTypes.GET_SECTION_TOP_TEN_CAPITAL_FAIL:
      return getSectionTopTenCapitalDataFail(state, action.err);

    //取得單一園區 政府計劃前十大廠商
    case actionTypes.START_GET_SECTION_TOP_TEN_GOVERNMENT_PLAN_DATA:
      return startGetSectionTopTenGovernmentPlanData(state);
    case actionTypes.GET_SECTION_TOP_TEN_GOVERNMENT_PLAN_SUCCESS:
      return getSectionTopTenGovernmentPlanDataSuccess(state, action.data);
    case actionTypes.GET_SECTION_TOP_TEN_GOVERNMENT_PLAN_FAIL:
      return getSectionTopTenGovernmentPlanDataFail(state, action.err);

    //取得單一園區 專利數前十大-長條圖
    case actionTypes.START_GET_SECTION_TOP_TEN_PATENT_DATA:
      return startGetSectionTopTenPatentData(state);
    case actionTypes.GET_SECTION_TOP_TEN_PATENT_SUCCESS:
      return getSectionTopTenPatentDataSuccess(state, action.data);
    case actionTypes.GET_SECTION_TOP_TEN_PATENT_FAIL:
      return getSectionTopTenPatentDataFail(state, action.err);

    //取得單一園區 員工數前十大
    case actionTypes.START_GET_SECTION_TOP_TEN_EMP_DATA:
      return startGetSectionTopTenEmpData(state);
    case actionTypes.GET_SECTION_TOP_TEN_EMP_SUCCESS:
      return getSectionTopTenEmpDataSuccess(state, action.data);
    case actionTypes.GET_SECTION_TOP_TEN_EMP_FAIL:
      return getSectionTopTenEmpDataFail(state, action.err);

    //取得單一園區 政府計畫數類型佔比
    case actionTypes.START_GET_SECTION_GOV_PLAN_PERCENT_DATA:
      return startGetSectionGovPlanPercentData(state);
    case actionTypes.GET_SECTION_GOV_PLAN_PERCENT_SUCCESS:
      return getSectionGovPlanPercentDataSuccess(state, action.data);
    case actionTypes.GET_SECTION_GOV_PLAN_PERCENT_FAIL:
      return getSectionGovPlanPercentDataFail(state, action.err);

    //取得單一園區 獲獎數類型佔比
    case actionTypes.START_GET_SECTION_AWARD_PERCENT_DATA:
      return startGetSectionAwardPercentData(state);
    case actionTypes.GET_SECTION_AWARD_PERCENT_SUCCESS:
      return getSectionAwardPercentDataSuccess(state, action.data);
    case actionTypes.GET_SECTION_AWARD_PERCENT_FAIL:
      return getSectionAwardPercentDataFail(state, action.err);

    //取得單一園區 專利數占比-圓餅圖
    case actionTypes.START_GET_SECTION_PATENT_PERCENT_DATA:
      return startGetSectionPatentPercentData(state);
    case actionTypes.GET_SECTION_PATENT_PERCENT_SUCCESS:
      return getSectionPatentPercentDataSuccess(state, action.data);
    case actionTypes.GET_SECTION_PATENT_PERCENT_FAIL:
      return getSectionPatentPercentDataFail(state, action.err);

    //取得單一園區 依據新聞標籤取得新聞資料
    // case actionTypes.START_GET_CONTENT_VIA_NEWS_TAG_DATA:
    //   return startGetContentViaNewsTagData(state);
    // case actionTypes.GET_CONTENT_VIA_NEWS_TAG_SUCCESS:
    //   return getContentViaNewsTagDataSuccess(state, action.data);
    // case actionTypes.GET_CONTENT_VIA_NEWS_TAG_FAIL:
    //   return getContentViaNewsTagDataFail(state, action.err);

    //產品搜尋地圖
    case actionTypes.START_GET_MAP_SEARCH_DATA:
      return startGetMapSearchData(state);
    case actionTypes.GET_MAP_SEARCH_SUCCESS:
      return getMapSearchDataSuccess(state, action.data);
    case actionTypes.GET_MAP_SEARCH_FAIL:
      return getMapSearchDataFail(state, action.err);

    //取得廢棄物前5大排行
    case actionTypes.START_GET_TOP_FIVE_WASTE_DISPOSAL_DATA:
      return startTopFiveWasteDisposalData(state);
    case actionTypes.GET_TOP_FIVE_WASTE_DISPOSAL_SUCCESS:
      return getTopFiveWasteDisposalDataSuccess(state, action.data);
    case actionTypes.GET_TOP_FIVE_WASTE_DISPOSAL_FAIL:
      return getTopFiveWasteDisposalDataFail(state, action.err);

    // ====================== MODAL ======================

    //單一園區 產業別2碼家數佔比-Modal
    case actionTypes.START_GET_SECTION_TWO_CODE_PERCENTAGE_LIST_DATA:
      return startSectionTwoCodePercentageListData(state);
    case actionTypes.GET_SECTION_TWO_CODE_PERCENTAGE_LIST_SUCCESS:
      return getSectionTwoCodePercentageListSuccess(state, action.data);
    case actionTypes.GET_SECTION_TWO_CODE_PERCENTAGE_LIST_FAIL:
      return getSectionTwoCodePercentageListFail(state, action.err);

    //單一園區 產業別3碼家數佔比-Modal
    case actionTypes.START_GET_SECTION_THREE_CODE_PERCENTAGE_LIST_DATA:
      return startSectionThreeCodePercentageListData(state);
    case actionTypes.GET_SECTION_THREE_CODE_PERCENTAGE_LIST_SUCCESS:
      return getSectionThreeCodePercentageListSuccess(state, action.data);
    case actionTypes.GET_SECTION_THREE_CODE_PERCENTAGE_LIST_FAIL:
      return getSectionThreeCodePercentageListFail(state, action.err);

    //單一園區 產業別4碼家數佔比-Modal
    case actionTypes.START_GET_SECTION_FOUR_CODE_PERCENTAGE_LIST_DATA:
      return startSectionFourCodePercentageListData(state);
    case actionTypes.GET_SECTION_FOUR_CODE_PERCENTAGE_LIST_SUCCESS:
      return getSectionFourCodePercentageListSuccess(state, action.data);
    case actionTypes.GET_SECTION_FOUR_CODE_PERCENTAGE_LIST_FAIL:
      return getSectionFourCodePercentageListFail(state, action.err);

    //單一園區 進口級距佔比-Modal
    case actionTypes.START_GET_SECTION_IMPORT_LIST_DATA:
      return startSectionImportListData(state);
    case actionTypes.GET_SECTION_IMPORT_LIST_SUCCESS:
      return getSectionImportListSuccess(state, action.data);
    case actionTypes.GET_SECTION_IMPORT_LIST_FAIL:
      return getSectionImportListFail(state, action.err);

    //單一園區 出口級距佔比-Modal
    case actionTypes.START_GET_SECTION_EXPORT_LIST_DATA:
      return startSectionExportListData(state);
    case actionTypes.GET_SECTION_EXPORT_LIST_SUCCESS:
      return getSectionExportListSuccess(state, action.data);
    case actionTypes.GET_SECTION_EXPORT_LIST_FAIL:
      return getSectionExportListFail(state, action.err);

    //單一園區 專利資料-Modal
    case actionTypes.START_GET_SECTION_PATENT_INFO_LIST_DATA:
      return startSectionPatentInfoListData(state);
    case actionTypes.GET_SECTION_PATENT_INFO_LIST_SUCCESS:
      return getSectionPatentInfoListSuccess(state, action.data);
    case actionTypes.GET_SECTION_PATENT_INFO_LIST_FAIL:
      return getSectionPatentInfoListFail(state, action.err);

    //單一園區 政府計畫-Modal
    case actionTypes.START_GET_SECTION_GOV_PLAN_LIST_DATA:
      return startSectionGovPlanListData(state);
    case actionTypes.GET_SECTION_GOV_PLAN_LIST_SUCCESS:
      return getSectionGovPlanListSuccess(state, action.data);
    case actionTypes.GET_SECTION_GOV_PLAN_LIST_FAIL:
      return getSectionGovPlanListFail(state, action.err);

    //單一園區 取得園區廠商政府計畫類型佔比-Modal
    case actionTypes.START_GET_SECTION_PARK_DETAIL_INFO_LIST_DATA:
      return startSectionParkDetailInfoListData(state);
    case actionTypes.GET_SECTION_PARK_DETAIL_INFO_LIST_SUCCESS:
      return getSectionParkDetailInfoListSuccess(state, action.data);
    case actionTypes.GET_SECTION_PARK_DETAIL_INFO_LIST_FAIL:
      return getSectionParkDetailInfoListFail(state, action.err);

    //單一園區 取得園區廠商獲獎類型佔比資料-Modal
    case actionTypes.START_GET_SECTION_AWARD_LIST_DATA:
      return startSectionAwardListData(state);
    case actionTypes.GET_SECTION_AWARD_LIST_SUCCESS:
      return getSectionAwardListSuccess(state, action.data);
    case actionTypes.GET_SECTION_AWARD_LIST_FAIL:
      return getSectionAwardListFail(state, action.err);

    //單一園區 取得廢棄物廠商追溯表-Modal
    case actionTypes.START_GET_WASTE_DISPOSAL_LIST_DATA:
      return startWasteDisposalListData(state);
    case actionTypes.GET_SECTION_WASTE_DISPOSAL_LIST_SUCCESS:
      return getWasteDisposalListSuccess(state, action.data);
    case actionTypes.GET_SECTION_WASTE_DISPOSAL_LIST_FAIL:
      return getWasteDisposalListFail(state, action.err);

    //單一園區 公開發行狀況佔比-Modal
    case actionTypes.START_GET_SECTION_STOCK_LIST_DATA:
      return startSectionStockListData(state);
    case actionTypes.GET_SECTION_STOCK_LIST_SUCCESS:
      return getSectionStockListSuccess(state, action.data);
    case actionTypes.GET_SECTION_STOCK_LIST_FAIL:
      return getSectionStockListFail(state, action.err);

    default:
      return state;
  }
};
