import React from "react";
import { useSelector } from "react-redux";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";

import { thousandDivider } from "../../../utility/common";

import "./styles.scss";

const FactoryAssociatedCompany = (props) => {
  const { data, headerList } = props;
  const { innerWidth } = useSelector((state) => state.app);
  const [state, setState] = React.useState({
    endIndex: 0,
    page: 0,
    rowsPerPage: 5,
  });
  const url = window.location.origin;

  let content;

  React.useEffect(() => {
    if (Math.ceil(data.length) === state.page) {
      if (data.length % 5 === 0) {
        setState((state) => ({
          ...state,
          endIndex: state.page * state.rowsPerPage + state.rowsPerPage,
        }));
      } else {
        setState((state) => ({
          ...state,
          endIndex: state.page * state.rowsPerPage + (data.length % 5),
        }));
      }
    } else {
      setState((state) => ({
        ...state,
        endIndex: state.page * state.rowsPerPage + state.rowsPerPage,
      }));
    }
  }, [data.length, state.page]);

  if (data.length > 5) {
    content = data
      .slice(state.page * 5, state.page * 5 + 5)
      .map((row, index) => (
        <TableRow
          key={row.BusinessNo + index}
          hover
          className="table-body-row"
          style={index % 2 !== 0 ? { backgroundColor: "#ececec" } : {}}
        >
          <TableCell
            align="left"
            onClick={() =>
              openNewTabs(`${url}/factory_info/basic_info/${row.BusinessNo}`)
            }
            style={{
              textDecoration: "underline",
              color: "rgb(0, 106, 180)",
              cursor: "pointer",
              width: innerWidth > 900 ? "20%" : "30%" 
            }}
          >
            <button title={`另開新視窗，前往${row.CompanyName}基本資料`} style={{
              textDecoration: "underline",
              color: "rgb(0, 106, 180)",
            }} onClick={(e) => {
              e.stopPropagation();
              openNewTabs(`${url}/factory_info/basic_info/${row.BusinessNo}`)
            }}>
              {row.BusinessNo}
            </button>
          </TableCell>
          <TableCell
            align="left"
            onClick={() =>
              openNewTabs(`${url}/factory_info/basic_info/${row.BusinessNo}`)
            }
            style={{
              textDecoration: "underline",
              color: "rgb(0, 106, 180)",
              cursor: "pointer",
              width: innerWidth > 900 ? "40%" : "70%" 
            }}
          >
            <button title={`另開新視窗，前往${row.CompanyName}基本資料`} style={{
              textDecoration: "underline",
              color: "rgb(0, 106, 180)",
            }} onClick={(e) => {
              e.stopPropagation();
              openNewTabs(`${url}/factory_info/basic_info/${row.BusinessNo}`)
            }}>
              {row.CompanyName}
            </button>
          </TableCell>
          {innerWidth > 1024 && (
            <TableCell align="left"  style={{ width: "40%" }}>
              {thousandDivider(row.CapitalTotal)}
            </TableCell>
          )}
        </TableRow>
      ));
  } else {
    content = data.map((row, index) => (
      <TableRow
        key={row.BusinessNo + index}
        hover
        className="table-body-row"
        style={index % 2 !== 0 ? { backgroundColor: "#ececec" } : {}}
      >
        <TableCell
          align="left"
          onClick={() =>
            openNewTabs(`${url}/factory_info/basic_info/${row.BusinessNo}`)
          }
          style={{
            textDecoration: "underline",
            color: "rgb(0, 106, 180)",
            cursor: "pointer",
            width: innerWidth > 900 ? "20%" : "30%" 
          }}
        >
          <button title={`另開新視窗，前往${row.CompanyName}基本資料`} style={{
            textDecoration: "underline",
            color: "rgb(0, 106, 180)",
          }} onClick={(e) => {
            e.stopPropagation();
            openNewTabs(`${url}/factory_info/basic_info/${row.BusinessNo}`)
          }}>
            {row.BusinessNo}
          </button>
        </TableCell>
        <TableCell
          align="left"
          onClick={() =>
            openNewTabs(`${url}/factory_info/basic_info/${row.BusinessNo}`)
          }
          style={{
            textDecoration: "underline",
            color: "rgb(0, 106, 180)",
            cursor: "pointer",
            width: innerWidth > 900 ? "40%" : "70%" 
          }}
        >
          <button title={`另開新視窗，前往${row.CompanyName}基本資料`} style={{
            textDecoration: "underline",
            color: "rgb(0, 106, 180)",
          }} onClick={(e) => {
            e.stopPropagation();
            openNewTabs(`${url}/factory_info/basic_info/${row.BusinessNo}`)
          }}>
            {row.CompanyName}
          </button>
        </TableCell>
        {innerWidth > 1024 && (
          <TableCell align="left"  style={{  width: "40%"  }}>
            {thousandDivider(row.CapitalTotal)}
          </TableCell>
        )}
      </TableRow>
    ));
  }

  const handleChangePage = (event, newPage) => {
    setState((state) => ({ ...state, page: newPage }));
  };

  const openNewTabs = (link) => window.open(link, "_blank");

  return (
    <TableContainer className="table-container">
      <Table className="table">
        <TableHead className="table-header">
          {headerList && (
            <TableRow className="table-row">
              {headerList.map((headerItem) => (
                <TableCell
                  key={headerItem}
                  style={{
                    fontWeight: 600,
                  }}
                  className="table-cell"
                  align="left"
                >
                  {headerItem}
                </TableCell>
              ))}
            </TableRow>
          )}
        </TableHead>
        <TableBody className="table-body">
          {data.length !== 0 && content}
        </TableBody>
      </Table>
      {data.length === 0 && (
        <Table>
          <TableBody className="table-body-no-data">
            <TableRow hover className="table-body-row">
              <TableCell align="center" className="no-data">
                本項目無資料
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      )}
      {data.length > 5 && (
        <TablePagination
          rowsPerPageOptions={[]}
          component="div"
          count={data.length}
          rowsPerPage={state.rowsPerPage}
          page={state.page}
          onChangePage={handleChangePage}
          // onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      )}
    </TableContainer>
  );
};

export default React.memo(FactoryAssociatedCompany);
