import * as actionTypes from "../actionTypes/effected_event";

const initState = {
  effected_events_list_loading: false,
  effected_events_list: [],
  effected_events_result_list_loading: false,
  effected_events_result_list: [],
  effected_industry_4_code_loading: false,
  effected_industry_4_code: [],
  effected_foreign_trade_loading: false,
  effected_foreign_trade_export: [],
  effected_foreign_trade_import: [],
  effected_country_loading: false,
  effected_country_export: [],
  effected_country_import: [],
  effected_news_loading: false,
  effected_news: [],
  err:""
};

const startFetchEffectedEventsList = (state) => {
  return {
    ...state,
    effected_events_list_loading: true,
  };
};

const fetchEffectedEventsListSuccess = (state, action) => {
  return {
    ...state,
    effected_events_list: action.data.data,
    effected_events_list_loading: false,
  };
};

const fetchEffectedEventsListFail = (state) => {
  return {
    ...state,
    effected_events_list_loading: false,
  };
};

// 工廠四碼
const startFetchIndustry4Code = (state) => {
  return {
    ...state,
    effected_industry_4_code_loading: true,
  };
};

const fetchIndustry4CodeSuccess = (state, action) => {
  return {
    ...state,
    effected_industry_4_code: action.data,
    effected_industry_4_code_loading: false,
  };
};

const fetchIndustry4CodeFail = (state) => {
  return {
    ...state,
    effected_industry_4_code_loading: false,
  };
};

// 進出口貨品
const startFetchForeignTrade = (state) => {
  return {
    ...state,
    effected_foreign_trade_loading: true,
  };
};

const fetchForeignTradeExportSuccess = (state, action) => {
  return {
    ...state,
    effected_foreign_trade_export: action.data,
    effected_foreign_trade_loading: false,
  };
};
const fetchForeignTradeImportSuccess = (state, action) => {
  return {
    ...state,
    effected_foreign_trade_import: action.data,
    effected_foreign_trade_loading: false,
  };
};

const fetchForeignTradeFail = (state) => {
  return {
    ...state,
    effected_foreign_trade_loading: false,
  };
};

// 國家別
const startFetchCountry = (state) => {
  return {
    ...state,
    effected_country_loading: true,
  };
};

const fetchCountryExportSuccess = (state, action) => {
  return {
    ...state,
    effected_country_export: action.data,
    effected_country_loading: false,
  };
};
const fetchCountryImportSuccess = (state, action) => {
  return {
    ...state,
    effected_country_import: action.data,
    effected_country_loading: false,
  };
};

const fetchCountryFail = (state) => {
  return {
    ...state,
    effected_country_loading: false,
  };
};
// 新聞
const startFetchNews = (state) => {
  return {
    ...state,
    effected_news_loading: true,
  };
};

const fetchNewsSuccess = (state, action) => {
  return {
    ...state,
    effected_news: action.data,
    effected_news_loading: false,
  };
};

const fetchNewsFail = (state) => {
  return {
    ...state,
    effected_news_loading: false,
  };
};

const startSearchEffectedEventsData = (state) => {
  return {
    ...state,
    effected_events_result_list_loading: true,
  };
};

const searchEffectedEventsDataSuccess = (state, action) => {
  return {
    ...state,
    effected_events_result_list: action.data,
    effected_events_result_list_loading: false,
  };
};

const searchEffectedEventsDataFail = (state, action) => {
  return {
    ...state,
    effected_events_result_list_loading: false,
    err: action.err
  };
};

const handleResetErrText = (state) => {
  return {
    ...state,
    err:""
  };
} 


const resetState = (state) => {
  return {
    ...state,
    effected_events_list_loading: false,
    effected_events_result_list_loading: false,
    effected_events_result_list: [],
    err:""
  };
}


export default (state = initState, action) => {
  switch (action.type) {
    case actionTypes.START_FETCH_EFFECTED_EVENTS_LIST_DATA:
      return startFetchEffectedEventsList(state);
    case actionTypes.FETCH_EFFECTED_EVENTS_LIST_DATA_SUCCESS:
      return fetchEffectedEventsListSuccess(state, action);
    case actionTypes.FETCH_EFFECTED_EVENTS_LIST_DATA_FAIL:
      return fetchEffectedEventsListFail(state, action);
    case actionTypes.START_FETCH_INDUSTRY_4_CODE_DATA:
      return startFetchIndustry4Code(state);
    case actionTypes.FETCH_INDUSTRY_4_CODE_DATA_SUCCESS:
      return fetchIndustry4CodeSuccess(state, action);
    case actionTypes.FETCH_INDUSTRY_4_CODE_DATA_FAIL:
      return fetchIndustry4CodeFail(state, action);
    case actionTypes.START_FETCH_NEWS_DATA:
      return startFetchNews(state);
    case actionTypes.FETCH_NEWS_DATA_SUCCESS:
      return fetchNewsSuccess(state, action);
    case actionTypes.FETCH_NEWS_DATA_FAIL:
      return fetchNewsFail(state, action);
    case actionTypes.START_FETCH_FOREIGN_TRADE_DATA:
      return startFetchForeignTrade(state);
    case actionTypes.FETCH_FOREIGN_TRADE_EXPORT_DATA_SUCCESS:
      return fetchForeignTradeExportSuccess(state, action);
    case actionTypes.FETCH_FOREIGN_TRADE_IMPORT_DATA_SUCCESS:
      return fetchForeignTradeImportSuccess(state, action);
    case actionTypes.FETCH_FOREIGN_TRADE_DATA_FAIL:
      return fetchForeignTradeFail(state, action);
    case actionTypes.START_FETCH_COUNTRY_DATA:
      return startFetchCountry(state);
    case actionTypes.FETCH_COUNTRY_EXPORT_DATA_SUCCESS:
      return fetchCountryExportSuccess(state, action);
    case actionTypes.FETCH_COUNTRY_IMPORT_DATA_SUCCESS:
      return fetchCountryImportSuccess(state, action);
    case actionTypes.FETCH_COUNTRY_DATA_FAIL:
      return fetchCountryFail(state, action);
    case actionTypes.START_SEARCH_EFFECTED_EVENTS_RESULT:
      return startSearchEffectedEventsData(state);
    case actionTypes.SEARCH_EFFECTED_EVENTS_RESULT_SUCCESS:
      return searchEffectedEventsDataSuccess(state, action);
    case actionTypes.SEARCH_EFFECTED_EVENTS_RESULT_FAIL:
      return searchEffectedEventsDataFail(state, action);
    case actionTypes.HANDLE_RESET_ERR:
      return handleResetErrText(state)   
    case actionTypes.RESET_STATE:
      return resetState(state)     
    default:
      return state;
  }
};
