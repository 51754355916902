import React from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import * as actionTypes from "../actions/actionTypes";
import { fetchUserInfo, applyFileRequest, fetchApplyFileInfo, fetchApplyFileDropdownList } from "../../utility/api";
import MapDataExcelGenerator from "../../utility/MapDataExcelGenerator";
import { applyFileParameter } from "../../constants/applyFileParameter"
import ExcelJs from "exceljs";

const MySwal = withReactContent(Swal);

export const getApplyFileInfoHandler = ()  => async dispatch => {
    dispatch({ type: actionTypes.START_GET_APPLY_FILE_INFO })
    dispatch({ type: actionTypes.START_GET_APPLY_FILE_DROPDOWN_LIST })
    const applyFileInfo = await fetchApplyFileInfo();
    const applyFileDropdownList= await fetchApplyFileDropdownList();
    if(applyFileInfo){
        dispatch({ type: actionTypes.GET_APPLY_FILE_INFO_SUCCESS, applyFileInfo:applyFileInfo })
    }else{
        dispatch({ type: actionTypes.GET_APPLY_FILE_INFO_FAIL, err: applyFileInfo })
        console.error("取得使用這資訊發生問題")
    }
    if(applyFileDropdownList){
        dispatch({ type: actionTypes.GET_APPLY_FILE_DROPDOWN_LIST_SUCCESS, applyFileDropdownList:applyFileDropdownList.ApplyReason })
    }else{
        dispatch({ type: actionTypes.GET_APPLY_FILE_DROPDOWN_LIST_FAIL, err: applyFileDropdownList })
        console.error("取得使用這資訊發生問題")
    }
}

export const getUserInfoHandler = uid  => async dispatch => {
    dispatch({ type: actionTypes.START_GET_USER_INFO })
    const userInfo = await fetchUserInfo(btoa(uid));
    if(userInfo.status === 200 && userInfo.data.Email){
        dispatch({ type: actionTypes.GET_USER_INFO_SUCCESS, userInfo:userInfo.data })
    }else{
        dispatch({ type: actionTypes.GET_USER_INFO_FAIL, err: userInfo })
        console.error("取得使用這資訊發生問題")
    }
}

export const  exportExcelFile = (data, sheetName, thead, fileName, source, dataLimit) => {
    const workbook = new ExcelJs.Workbook();
    const dataList = [];
    data.forEach((sheetData)=>{
        if(source==="2"){
            dataList.push([
                sheetData.content.name,
                sheetData.content.address,
                sheetData.content.city,
                sheetData.content.induFourStr,
                sheetData.content.product,
                sheetData.content.capital,
                sheetData.content.induPark ? sheetData.content.induPark : "區外",
                sheetData.content.stockType,
                sheetData.content.Tel ? sheetData.content.Tel : "無資料" 
            ]);
        }
        else{
            dataList.push([
                sheetData.BusinessNo,
                sheetData.CompanyName,
                sheetData.Tel,
                sheetData.CapitalTotal,
                sheetData.CityName,
                sheetData.TaxIndustry,
            ]);
        }
    })
    const sheet = workbook.addWorksheet(sheetName);
    sheet.addTable({
        name: sheetName,
        ref: `A1`, // 從A1開始
        headerRow: true,
        columns: thead.map((s)=>{ return {name: s}}),
        rows: dataList.slice(0, dataLimit.Single_DataCnts_Limit)
    });
    workbook.xlsx.writeBuffer().then((content) => {
        const link = document.createElement("a");
        const blob = new Blob([content], {
        type: "application/vnd.ms-excel;charset=utf-8;"
        });
        link.download = `${fileName}.xlsx`;
        link.href = URL.createObjectURL(blob);
        link.click();
    });
};

export const applyFileRequestHandler = (data, excelData, dataLimit) => async dispatch => {
    var today = new Date();
    today.setDate(1);
    today.setMonth(today.getMonth() + 1);
    dispatch({ type: actionTypes.START_APPLY_FILE })
    const file = new FormData();
    file.append('Department', data.department);
    file.append('Phone', data.phone || "");
    file.append('Source', data.source || "");
    file.append('ApplyReason',data.applyReason || "");
    file.append('ApplyMemo', data.applyMemo || "");
    file.append('ApplyCondition', data.applyCondition || "");
    file.append('DataCnts', excelData.length || 0);
    const applyStatus = await applyFileRequest(file);
    if(applyStatus.description === "提交成功"){
        const sheetName = "資料表"
        if(data.source === '2'){
            const thead = ['廠商名稱', '地址', '縣市別', '產業別四碼', '主要產品', '登記資本額', '所屬產業園區', '股票狀態', '公司電話']
            const fileName = "技術快搜廠商清單_產業園區廠商情資平台"
            exportExcelFile(excelData, sheetName, thead, fileName, data.source, dataLimit);
        }
        else{
            const thead = ["統一編號", "廠商名稱", "廠商電話", "資本額", "公司登記縣市",  "稅務行業分類",]
            const fileName = "複合條件快搜結果清單_產業園區廠商情資平台"
            exportExcelFile(excelData, sheetName, thead, fileName, data.source, dataLimit);
        }
        MySwal.fire({
            title: "提交成功",
            html: <MapDataExcelGenerator data={excelData} source={data.source} dataLimit={dataLimit} />,
            didOpen: function () {
                MySwal.showLoading();
                setTimeout(function() {
                  MySwal.hideLoading()
                }, 5000) 
              }
            }).then(() => {
                dispatch({ type: actionTypes.APPLY_FILE_SUCCESS });
            },
        );
    }else if(applyStatus.description === "超出當月使用量"){
        MySwal.fire({
            title:"提示",
            html: `超出本月使用量<br>下次可匯出EXCEL日期：${new Date(today).toISOString().split("T")[0] + "  00:00"}<br/>如有任何需求或問題，請聯絡${applyFileParameter.nickNmae}  ${applyFileParameter.phone}`,
            confirmButtonText: '確認',
        }).then(() => {
            dispatch({ type: actionTypes.APPLY_FILE_FAIL });
        });
    }else if(applyStatus.status === 400){
        MySwal.fire({
            text: `提交失敗，${applyStatus.data}`,
        }).then(() => {
            dispatch({ type: actionTypes.APPLY_FILE_FAIL });
        });
    }

}
