import React from "react";

export default props => {
    const { sectionData } = props;
    return (
        <div className="detial-info">
        <div>
          <div>
            <div className="subtitle">供水來源</div>
            <div className="maintitle">
              {sectionData?.Facility?.WaterSupplySource||"無"}
            </div>
          </div>
          <div className="divider" />
          <div>
            <div className="subtitle">電力來源</div>
            <div className="maintitle">
              {sectionData?.Facility?.PowerSupplySource||"無"}
            </div>
          </div>
          <div className="divider" />
          <div>
            <div className="subtitle">污水處理廠</div>
            <div className="maintitle">
              {sectionData?.InduPark?.HaveWTP ? "有" : "無"}
            </div>
          </div>
          <div className="divider" />
          <div>
            <div className="subtitle">資源回收廠</div>
            <div className="maintitle">
              {sectionData?.InduPark?.HaveRecyclingPlant ? "有" : "無"}
            </div>
          </div>
          <div className="divider" />
          <div>
            <div className="subtitle">轄區警局</div>
            <div className="maintitle">
              {sectionData?.Facility?.PoliceDepartment||"無"}
            </div>
          </div>
          <div className="divider" />
          <div>
            <div className="subtitle">轄區消防隊</div>
            <div className="maintitle">
              {sectionData?.Facility?.FireDepartment||"無"}
            </div>
          </div>
          <div className="divider" />
        </div>
        <div>
          <div>
            <div className="subtitle">道路總長(公尺)</div>
            <div className="maintitle">
              {sectionData?.Facility?.Road
                ?.toString()?.replace(
                    /\B(?=(\d{3})+(?!\d))/g,
                    ","
                  )
                || "無"}
            </div>
          </div>
          <div className="divider" />
          <div>
            <div className="subtitle">
              雨水下水道系統總長(公尺)
            </div>
            <div className="maintitle">
              {sectionData?.Facility?.RainWaterSewerSystem
                ?.toString()?.replace(
                    /\B(?=(\d{3})+(?!\d))/g,
                    ","
                  )
                || "無"}
            </div>
          </div>
          <div className="divider" />
          <div>
            <div className="subtitle">
              汙水下水道系統總長(公尺)
            </div>
            <div className="maintitle">
              {sectionData?.Facility?.SewerageSystem
                ?.toString()?.replace(
                    /\B(?=(\d{3})+(?!\d))/g,
                    ","
                  )
                || "無"}
            </div>
          </div>
          <div className="divider" />
          {/* <div>
            <div className="subtitle">污水量</div>
            <div className="maintitle">
              {sectionData.Wastewater_Volume_YearAvg
                ? sectionData.Wastewater_Volume_YearAvg.toFixed(2)
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                : "無"}
            </div>
          </div> */}
          {/* <div className="divider" /> */}
          <div>
            <div className="subtitle">路燈總數(盞)</div>
            <div className="maintitle">
              {sectionData?.Facility?.StreetLight?.toString()?.replace(
                /\B(?=(\d{3})+(?!\d))/g,
                ","
              )}
            </div>
          </div>
          <div className="divider" />
          <div>
            <div className="subtitle">其他公共設施</div>
            <div className="maintitle">
              {sectionData?.Facility?.OtherPublicFacility
                || "無"}
            </div>
          </div>
          <div className="divider" />
        </div>
      </div>
      )
  };