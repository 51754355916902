import React from "react";
import { useSelector } from "react-redux";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import "./styles.scss";

const WastedCleanDetailTable = (props) => {
  const { innerWidth } = useSelector((state) => state.app);
  const { data, headerList } = props;
  let content;

  if (innerWidth < 750) {
    content =
      data.length !== 0 &&
      data.map((row, index) => (
        <TableRow
          key={row.BusinessNo}
          hover
          className="table-body-row"
          style={index % 2 !== 0 ? { backgroundColor: "#ececec" } : {}}
        >
          <TableCell align="left" style={{ width: "40%" }}>
            {row.Name.split(" ")[0]}
          </TableCell>
          <TableCell align="left" style={{ width: "60%" }}>
            {row.Name.split(" ")[1]} {row.Process && `(${row.Process})`}
          </TableCell>
        </TableRow>
      ));
  } else {
    if (headerList.length === 2) {
      content =
        data.length !== 0 &&
        data.map((row, index) => (
          <TableRow
            key={row.BusinessNo}
            hover
            className="table-body-row"
            style={index % 2 !== 0 ? { backgroundColor: "#ececec" } : {}}
          >
            <TableCell align="left" style={{ width: "40%" }}>
              {row.Name.split(" ")[0]}
            </TableCell>
            <TableCell align="left" style={{ width: "60%" }}>
              {row.Name.split(" ")[1]}
            </TableCell>
          </TableRow>
        ));
    } else {
      content =
        data.length !== 0 &&
        data.map((row, index) => (
          <TableRow
            key={row.BusinessNo}
            hover
            className="table-body-row"
            style={index % 2 !== 0 ? { backgroundColor: "#ececec" } : {}}
          >
            <TableCell align="left" style={{ width: "15%" }}>
              {row.Name.split(" ")[0]}
            </TableCell>
            <TableCell align="left" style={{ width: "55%" }}>
              {row.Name.split(" ")[1]}
            </TableCell>
            <TableCell align="left" style={{ width: "30%" }}>
              {row.Process}
            </TableCell>
          </TableRow>
        ));
    }
  }

  return (
    <TableContainer className="table-container">
      <Table className="table">
        <TableHead className="table-header">
          {headerList && (
            <TableRow className="table-row">
              {headerList.map((headerItem) => (
                <TableCell className="table-cell" align="left">
                  {headerItem}
                </TableCell>
              ))}
            </TableRow>
          )}
        </TableHead>
        <TableBody className="table-body">{content}</TableBody>
      </Table>
      {data.length === 0 && (
        <Table>
          <TableBody className="table-body-no-data">
            <TableRow hover className="table-body-row">
              <TableCell align="center" className="no-data">
                本項目無資料
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      )}
    </TableContainer>
  );
};

export default React.memo(WastedCleanDetailTable);
