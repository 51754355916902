import React from "react";
import { useSelector } from "react-redux";
import MobileGrid from "../../MobileGridV2";
import * as Style from "../../styles";
import { Card } from "react-bootstrap";
import Swal from "sweetalert2";
import styled from "styled-components";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const EnterpriseQualified = ({ data, referenceType }) => {
  const { referenceInfo } = useSelector((state) => state.app);
  const toggleCard = {};
  const openReferenceInfoAlert = (type) => {
    let content = "";
    if (referenceInfo[type] === "") {
      console.log("I was called!");
      Swal.fire({
        title: "引用資料來源",
        text: "資料正在建置中 ... ",
      });
    } else {
      try {
        referenceInfo[type]
          .split("。")
          .map(
            (referenceItem) =>
              (content +=
                "<p style='text-align:left'>" + referenceItem + "</p>")
          );
        Swal.fire({
          title: "引用資料來源",
          html: content,
        });
      } catch (err) {
        Swal.fire({
          title: "引用資料來源",
          text: "資料正在建置中 ... ",
        });
      }
    }
  };

  const openNewTabs = (link) => window.open(link, "_blank");
  return (
    <div>
      <Card.Header className="card-title">
        <TradeMarker>
            {"產發署技術服務機構服務能量"}
            {toggleCard && referenceType && ( // 如果有 toggleCard 就顯示（位置差異）
              <button
                title={`開啟${"產發署技術服務機構服務能量"}引用資料來源彈出視窗`}
                style={{ color: "white", borderRadius: "100%", marginLeft: "4px" }}
                onClick={() => openReferenceInfoAlert(referenceType)}  
              > 
                <FontAwesomeIcon
                  className="icon-reference"
                  icon={faInfoCircle}
                />
              </button>
            )}
        </TradeMarker>
      </Card.Header>
      <MobileGrid>
        {data.map((item, index) => (
          <Style.MobileGridContainerItem
            item
            component={Style.MobileGridItem}
            key={item.SubTitle + index}
            onClick={() => openNewTabs("https://data.gov.tw/dataset/29791")}
          >
            <Style.MobileGridItemList>
              <Style.MobileGridItemListLabel>服務項目 </Style.MobileGridItemListLabel>
              <Style.MobileGridItemListText style={{ display: "flex", alignItems: "center" }}>
                {item.SubTitle ? item.SubTitle : "無相關資料"}
              </Style.MobileGridItemListText>
            </Style.MobileGridItemList>
            <Style.MobileGridItemList>
              <Style.MobileGridItemListLabel>服務分項 </Style.MobileGridItemListLabel>
              <Style.MobileGridItemListText>
                {item.Item ? item.Item : "無相關資料"}
              </Style.MobileGridItemListText>
            </Style.MobileGridItemList>
            <Style.MobileGridItemList>
              <Style.MobileGridItemListLabel>有效日期 </Style.MobileGridItemListLabel>
              <Style.MobileGridItemListText>
                {item.RegisterDateExpiry ? item.RegisterDateExpiry.replaceAll("T00:00:00", "") : "無相關資料"}
              </Style.MobileGridItemListText>
            </Style.MobileGridItemList>
          </Style.MobileGridContainerItem>
        ))}
      </MobileGrid>
    </div>
  );
};

const TradeMarker = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
`;

export default React.memo(EnterpriseQualified);
