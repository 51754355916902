import React, { useState } from "react";
import { Icon, FormControl, InputLabel, Select, MenuItem, Input } from "@material-ui/core";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import CustomizedAutoComplete from "../CustomizedAutoComplete/CustomizedAutoComplete";
import Spinner from "../Spinner/Spinner";
import { UpdateProjectHandleStatus } from "../../utility/api";

import "./styles.scss"
const MySwal = withReactContent(Swal);

const ClientRequestDetail = props => {
    const { data, handleClose, handleStatus, reload } = props;
    const [handleTypeLocal, setHandleTypeLocal] = useState(data.Case.HandleType);
    const [status, setStatus] = useState('fill')
    const [updateHandleContent, setUpdateHandleContent] = useState();

    const updateConfirm = (caseId) => {
        if (!updateHandleContent){
            MySwal.fire({
                text: `請輸入辦理情形。`,
                confirmButtonText: '確認',
            });
            return
        };

        MySwal.fire({
            text: `確認更新辦理情形`,
            showCancelButton: true,
            confirmButtonText: '確認',
            cancelButtonText: '取消'
        }).then((res) => {
            if (res.value) {
                updateStatusHandler(caseId)
            }
        }
        )
    }    

    const updateStatusHandler = async (caseId) => {
        const data = new FormData()
        // const { EndCaseDesc } = data.Case
        if (handleTypeLocal === '辦理中') {
            const user = localStorage.getItem('name')
            if (!updateHandleContent) {
                MySwal.fire({
                    text: "請填寫辦理情形。",
                })
                
                return
            }

            data.append('HandleContent', updateHandleContent)
            data.append('Logger', user)
            data.append('Memo', '')
            setStatus('loading')

            const updated = await UpdateProjectHandleStatus(caseId, data)
            if (updated.data.data) {
                setStatus('finished');
                reload()
            } else {
                MySwal.fire({
                    text: "連線發生問題，請重新嘗試。",
                })
            }

        } else {

            // if (!EndCaseDesc) {
            //     MySwal.fire({
            //         text: "請填寫結案說明。",
            //     })

            //     return
            // }

            // data.append('EndCaseDesc', EndCaseDesc)

            // const closed = await CaseClosedHandleStatus(caseId, data)
            // setSendHandleStatus("sending")


            // if (closed.data.data) {

            //     const reload = await authQueryHandler('init')
            //     setSendHandleStatus("finished")

            // } else {
            //     MySwal.fire({
            //         text: "連線發生問題，請重新嘗試。",
            //     }).then(() => { stateDispatch({ type: "TOGGLE_AUTH_QUERY_MODAL" }) })
            // }

            // stateDispatch({ type: "FINISHED_UPDATE_PROJECT_INFO" })


            // if(closed.)

        }
    }

    const closeFinised = () => {
        setStatus('fill')
        handleClose()
    }
    

    if(status === 'loading'){
        return <Spinner />
    } else if (status === 'fill'){
        return (
            <div className="auth-query-modal-content" id="mobile" >
                <div className="inner-content" >
                    <div className="auth-query-modal-content-btn-header">
                        <div className="auth-query-modal-content-btn-header-box">
                            <div>
                                <h3>
                                    {data.Contact.CompanyName} 的需求案件
                                </h3>
                            </div>
                            <div>
                                <span>[案件編號：{data.Case.ShowCaseId}]</span>
                            </div>
                        </div>
                        <Icon onClick={handleClose}>close</Icon>
                    </div>
                    <div className="auth-query-modal-content-container">
                        <div className="auth-query-modal-content-header">
                            <h3>基本資訊</h3>
                        </div>
                        <div className="auth-query-modal-content-description">
                            <div className="auth-query-modal-content-description-left">
                                <div className="auth-query-modal-content-description-box">
                                    <label>*廠商名稱</label>
                                    <div>{data.Contact.CompanyName}</div>
                                </div>
                                <div className="auth-query-modal-content-description-box">
                                    <label>*統一編號</label>
                                    <div>{data.Contact.BusinessNo}</div>
                                </div>
                                <div className="auth-query-modal-content-description-box">
                                    <label>*聯絡窗口</label>
                                    <div>
                                        {data.Contact.DisplayName}
                                        <span>先生</span>
                                    </div>
                                </div>
                            </div>
                            <div className="auth-query-modal-content-description-right">
                                <div className="auth-query-modal-content-description-box">
                                    <label>*所屬園區</label>
                                    <div>{data.Contact.ParkId}</div>
                                </div>
                                <div className="auth-query-modal-content-description-box">
                                    <label>*聯絡電話</label>
                                    <div>{data.Contact.Phone}</div>
                                </div>
                                <div className="auth-query-modal-content-description-box">
                                    <label>*聯絡信箱</label>
                                    <div>{data.Contact.Email}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="auth-query-modal-content-container">
                        <div className="auth-query-modal-content-header">
                            <h3>案件內容</h3>
                        </div>
                        <div className="auth-query-modal-content-description">
                            <p>{data.Case.Content}</p>
                        </div>
                    </div>
                    <div className="auth-query-modal-content-container select">
                        <div className="auth-query-modal-content-header">
                            <h3>案件狀態</h3>
                            <FormControl className="auth-query-modal-content-header-status" variant="outlined">
                                <InputLabel className="select-label">案件狀態</InputLabel>
                                <Select
                                    value={handleTypeLocal}
                                    onChange={(event) => { setHandleTypeLocal(event.target.value) }}
                                    input={<Input type="select" />}
                                    name="HandleType"
                                    disabled
                                >
                                    <MenuItem value="辦理中" key="processing">辦理中</MenuItem>
                                    <MenuItem value="結案" key="close">結案</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                    <div className="auth-query-modal-content-container">
                        <div className="auth-query-modal-content-header">
                            <h3>辦理情形</h3>
                        </div>
                        <div className="auth-query-modal-content-description">
                            <div className="auth-query-modal-content-description-container">
                                {handleStatus.length !== 0 ? handleStatus.map((content) => {
                                    return (
                                        <div className="auth-query-modal-content-description-box">
                                            <div className="auth-query-modal-content-description-box-date">
                                                {content.RecordTime + " " + "承辦人員 : " + content.Logger}
                                            </div>
                                            <div className="auth-query-modal-content-description-box-content">
                                                {content.HandleContent}
                                            </div>
                                        </div>
                                    );
                                }) : (
                                        <div className="auth-query-modal-content-description-box">
                                            <div className="auth-query-modal-content-description-box-content">
                                                目前無辦理情況。
                                         </div>
                                        </div>
                                    )}
                                {handleTypeLocal !== "結案" && (
                                    <div className="auth-query-modal-content-description-box">
                                        <CustomizedAutoComplete
                                            // className="status-input"
                                            placeholder="請輸入辦理情形"
                                            option={[
                                                {
                                                    name: "您的需求已轉介相關窗口，後續將由該窗口為您服務。窗口聯絡資訊：",
                                                    value: "您的需求已轉介相關窗口，後續將由該窗口為您服務。窗口聯絡資訊："
                                                },
                                                {
                                                    name: "您的案件正在積極聯繫與辦理中，若有任何疑問請致電：",
                                                    value: "您的案件正在積極聯繫與辦理中，若有任何疑問請致電："
                                                },
                                            ]}
                                        value={updateHandleContent}
                                        onChange={value => {setUpdateHandleContent(value)}}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    {data.Case.EndCaseDesc &&
                        <div className="auth-query-modal-content-container">
                            <div className="auth-query-modal-content-header">
                                <h3>結案說明</h3>
                            </div>
                            <div className="auth-query-modal-content-description">
                                <div className="auth-query-modal-content-description-container">
                                    <div className="auth-query-modal-content-description-box">
                                        <div className="auth-query-modal-content-description-box-date">
                                            {"結案時間 : " + data.Case.EndTime}
                                        </div>
                                        <div className="auth-query-modal-content-description-box-content">
                                            {data.Case.EndCaseDesc}
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    }
                    {/* {projectStatusHolder === "結案" && !state.editedProject.EndCaseDesc && (
                    <div className="auth-query-modal-content-container">
                        <div className="auth-query-modal-content-header">
                            <h3>結案說明</h3>
                        </div>
                        <div className="auth-query-modal-content-description">
                            <div className="auth-query-modal-content-description-container">
                                <div className="auth-query-modal-content-description-box">
                                    <CustomizedAutoComplete
                                        disabled={state.editedProject.HandleType === "結案"}
                                        placeholder="*請輸入結案說明"
                                        option={[
                                            {
                                                name: "您的需求已轉介相關窗口，後續將由該窗口為您服務。窗口聯絡資訊：",
                                                value: "您的需求已轉介相關窗口，後續將由該窗口為您服務。窗口聯絡資訊："
                                            },
                                            {
                                                name: "您的案件正在積極聯繫與辦理中，若有任何疑問請致電：",
                                                value: "您的案件正在積極聯繫與辦理中，若有任何疑問請致電："
                                            },
                                        ]}
                                        value={state.editedProject.EndCaseDesc}
                                        onChange={value => {
                                            stateDispatch({ type: "EDITED_CONTENT_HANDLER", value: value, name: "EndCaseDesc" })
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                )} */}
                </div>
                {data.Case.HandleType !== "結案" &&
                    <div className="auth-query-modal-content-btn">
                    <button onClick={() => updateConfirm(data.Case.CaseId)}>
                            更新狀態
                </button>
                    </div>
                }
            </div>
        )
    } else if (status === 'finished'){
        return(
            <div className="auth-query-modal-content-finished">
                <div className="inner-content-finished">
                    <div className="auth-query-modal-content-btn-header">
                        <Icon onClick={closeFinised}>close</Icon>
                    </div>
                    <div>
                        <p>案件 {data.Case.ShowCaseId} 已完成新增辦理情形。</p>
                    </div>
                  </div>  
            </div>
        )
    }

} 


export default ClientRequestDetail;