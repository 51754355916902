import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import DashboardHeader from "../../components/CityDashboardHeader/DashboardHeader";
import CustomizedCard from "../../components/CustomizedCard/CustomizedCardV3";
import CustomizedTable from "../../components/CustomizedTable/CustomizedTableV2";
import CustomizedGraphic from "../../components/CustomizedGraphic/CustomizedGraphicV2";
import Modal from "../../components/CustomizedModal/CustomizedModal";
import IndustryDashboradModal from "../../components/IndustryDashboradModal/IndustryDashboradModal";
import BackToTopButton from "../../components/BackToTopButton/BackToTopButtonV2";
import IndustryMainIndexListTable from "../../components/CustomizedTable/IndustryMainIndexListTable";

import MainIndex from "../../components/MainIndex/MainIndex";
import { parksWithUrl } from "../../constants/parks";
import {
  getInfo,
  getProductVal,
  getStockCompanyRevenueTrend, //產業上市櫃公司營收趨勢圖
  getExportCompanyRevenueTrend, //產業出口級距趨勢圖
  getInvestmentAmt,
  getFactoryCount,
  getEmpCount,
  getResearchFactoryCount,
  getTechBuyAmt,
  getResearchAmt,
  getTechSaleAmt,
  getRevenueIncome,
  getRevenuePay,
  getTop30Company,
  getListedCompany,
  getPubinventionALL,
  getPatentFinished,
  getFourCode,
  getStockCompanyRevenueHandler, //產業上市櫃公司營收漲跌家數
  getExportCompanyRevenueHandler, //產業出口級距漲跌家數
  getOperationMainIndexListDataHandler,
} from "../../store/actions/IndustryDashboard";
import { getOperationData } from "../../utility/api";

import * as Style from "./styles";
import { ScrollContainer, InnerContainer } from "../../utility/styles";

import "./IndustryIndex.css";

export default (props) => {
  const dispatch = useDispatch();
  const containerRef = React.useRef(null);
  const history = useHistory();
  const [state, setState] = React.useState({
    ListedCompanyDate: "",
    selectedCode: "",
    selectedName: "",
    codeList: [],
    codeListReady: false,
    researchPercent: 0,
    industryProductivity: 0,
    industryPerformancs: 0,
    selectedPark: "HIP015",
    parkOptions: [],
    filterInvention: [],
    filterPatent: [],
    keyWord: "",
    patenSelection: "all",
  });
  const [dashboardData, setDashboardData] = React.useState({
    filterData: [],
  });
  const {
    info,
    productVal,
    stockCompanyRevenueTrend, //產業上市櫃公司營收趨勢圖
    exportCompanyRevenueTrend, //產業出口級距趨勢圖
    investmentAmt,
    factoryCount,
    empCount,
    researchFactoryCount,
    techBuyAmt,
    researchAmt,
    techSaleAmt,
    revenueIncome,
    revenuePay,
    top30Company,
    listedCompany,
    pubinvention,
    patentFinished,
    fourCode,
    stockCompanyRevenue, //產業上市櫃公司營收漲跌家數
    stockCompanyRevenueLabel, //產業上市櫃公司營收漲跌家數
    stockCompanyRevenueMap, //產業上市櫃公司營收漲跌家數
    stockCompanyRevenueLoading, //產業上市櫃公司營收漲跌家數
    exportCompanyRevenue, //產業出口級距漲跌家數
    exportCompanyRevenueLabel, //產業出口級距漲跌家數
    exportCompanyRevenueMap, //產業出口級距漲跌家數
    exportCompanyRevenueLoading, //產業出口級距漲跌家數
    topRevenue, //產業上市櫃公司營收漲跌前五大排行
    operationMainIndexList, //產業儀錶板營運動態列表
  } = useSelector((state) => state.industryDashboard);

  const industryCode = props.match.params.code;

  const handleRequests = React.useCallback(
    (code) => {
      Promise.all([
        dispatch(getOperationMainIndexListDataHandler("")),
        dispatch(getInfo(code)),
        dispatch(getProductVal(code)),
        dispatch(getStockCompanyRevenueTrend(code)), //產業上市櫃公司營收趨勢圖
        dispatch(getExportCompanyRevenueTrend(code)), //產業出口級距趨勢圖
        dispatch(getInvestmentAmt(code)),
        dispatch(getFactoryCount(code)),
        dispatch(getEmpCount(code)),
        dispatch(getResearchFactoryCount(code)),
        dispatch(getTechBuyAmt(code)),
        dispatch(getResearchAmt(code)),
        dispatch(getTechSaleAmt(code)),
        dispatch(getRevenueIncome(code)),
        dispatch(getRevenuePay(code)),
        dispatch(getTop30Company(code)),
        dispatch(getListedCompany(code)),
        dispatch(getStockCompanyRevenueHandler(code)), //產業上市櫃公司營收漲跌家數
        dispatch(getExportCompanyRevenueHandler(code)), //產業出口級距漲跌家數
      ]);
    },
    [dispatch]
  );

  const handleInventRequests = React.useCallback(
    (code, parkId) => {
      Promise.all([
        dispatch(getPubinventionALL(code, parkId)),
        dispatch(getPatentFinished(code, parkId)),
      ]);
    },
    [dispatch]
  );

  React.useEffect(() => {
    const parkList = [];
    parksWithUrl.map((item) => {
      const { ParkId } = item;
      parkList.push({
        ...item,
        key: item.ParkId,
        value: item.ParkId,
        name: item.Name,
      });
    });
    setState((state) => ({ ...state, parkOptions: parkList }));
  }, []);

  React.useEffect(() => {
    if (state.codeListReady) {
      if (!state.codeList[industryCode]) {
        handleRequests(industryCode);
        handleInventRequests(industryCode);
        history.push(`1810`);
      } else {
        handleRequests(industryCode);
        handleInventRequests(industryCode);
        setState((state) => ({
          ...state,
          selectedCode: industryCode,
          selectedName: state.codeList[industryCode],
        }));
      }
    }
  }, [state.codeListReady, industryCode]);

  React.useEffect(() => {
    if (fourCode.fourCodeData.length != 0) {
      const tempObj = new Object();
      fourCode.fourCodeData.map((item) => {
        tempObj[item.Key] = item.Value;
      });

      setState((state) => ({
        ...state,
        codeList: tempObj,
        codeListReady: true,
      }));
    }
  }, [fourCode.fourCodeData]);

  React.useEffect(() => {
    dispatch(getFourCode());
  }, [dispatch]);

  React.useEffect(() => {
    if (listedCompany.listedCompanyData.length != 0) {
      var Date;
      var listedDate = listedCompany.listedCompanyData[0].date;
      if (listedDate.includes("-03-")) {
        Date = listedDate.split("-")[0] + "Q1";
      } else if (listedDate.includes("-06-")) {
        Date = listedDate.split("-")[0] + "Q2";
      } else if (listedDate.includes("-09-")) {
        Date = listedDate.split("-")[0] + "Q3";
      } else if (listedDate.includes("-12-")) {
        Date = listedDate.split("-")[0] + "Q4";
      }

      setState((state) => ({
        ...state,
        ListedCompanyDate: Date,
      }));
    }
  }, [listedCompany.listedCompanyData]);

  React.useEffect(() => {
    if (info.infoData.length != 0) {
      const data = info.infoData;
      var researchPercent =
        data.factory_count === 0
          ? 0
          : ((data.research_factory_count / data.factory_count) * 100).toFixed(
            2
          );

      var industryProductivity =
        data.factory_count === 0
          ? 0
          : Math.round(data.product_val / data.factory_count);

      var industryPerformancs = (data.product_val / data.emp_count).toFixed(0);
      setState((state) => ({
        ...state,
        researchPercent,
        industryProductivity,
        industryPerformancs,
      }));
    }
  }, [info.infoData]);

  const onChangeHandler = (value) => {
    setState((state) => ({
      ...state,
      selectedCode: value,
      patenSelection: "all",
    }));

    history.push(`${value}`);
  };

  const handleModalOpen = (modalType, referenceCode, title, data) => {
    if(data){
      setState((state) => ({
        ...state,
        isModalOpen: true,
        modalType: modalType,
        referenceCode: referenceCode,
        title: title,
        data: data
      }));
    }
  };

  const handleClose = React.useCallback(() => {
    if (!state.persistModalOpen) {
      setState((state) => ({
        ...state,
        isModalOpen: !state.isModalOpen,
        modalType: "",
        referenceCode: "",
      }));
    }
  }, [state.persistModalOpen]);

  React.useEffect(() => {
    const getData = async () => {
      try {
        const defaultData = await getOperationData("");
        setDashboardData((prev) => ({
          ...prev,
          filterData: defaultData.data.data,
        }));
      } catch (err) {
        console.log("err", err);
      }
    };
    getData();
  }, []);

  React.useEffect(() => {
    const a = dashboardData;
  }, [dashboardData]);

  const openNewTab = (businessNo) => {
    if (!businessNo) {
      return;
    } else {
      const url = `${window.location.origin}`;
      window.open(
        `${url}/factory_info/basic_info/${businessNo}`,
        "_blank"
      );
    }
  };

  const persistModalOpenHandler = (value) => {
    setState((state) => ({ ...state, persistModalOpen: value }));
  };

  const onChangeAreaHandler = (value, name) => {
    if (value === "all") {
      handleInventRequests(industryCode);
    }
    if (name === "patenSelection") {
      handleInventRequests(industryCode, "HIP015");
    }
    if (name === "city") {
      handleInventRequests(industryCode, value);
    }

    setState((state) => ({
      ...state,
      keyWord: "",
      patenSelection: name === "city" ? "section" : value,
    }));
  };

  const scrollToTop = () => {
    containerRef.current.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleKeyWord = (e) => {
    const { value } = e.target;
    var subject = value.replace(
      /[\-\_\,\.\!\|\~\`\(\)\#\@\%\-\+\=\/\'\$\%\^\&\*\{\}\:\;\"\L\<\>\?\\]/g,
      ""
    );
    const Invention = pubinvention.pubinventionData;
    var filterInvention = [];

    const Patent = patentFinished.patentFinishedData;
    var filterPatent = [];

    if (Invention) {
      Invention.map((i) => {
        if (
          i.CompanyName.search(subject) !== -1 ||
          i.ChineseName.search(subject) !== -1
        ) {
          filterInvention.push(i);
        }
      });
    }

    if (Patent) {
      Patent.map((i) => {
        if (
          i.CompanyName.search(subject) !== -1 ||
          i.ChineseName.search(subject) !== -1
        ) {
          filterPatent.push(i);
        }
      });
    }

    setState((state) => ({
      ...state,
      keyWord: subject,
      filterInvention,
      filterPatent,
    }));
  };

  return (
    <ScrollContainer ref={containerRef}>
      <BackToTopButton scrollToTop={scrollToTop} />
      <Modal isOpen={state.isModalOpen} handleClose={handleClose}>
        <IndustryDashboradModal
          modalType={state.modalType}
          handleClose={handleClose}
          title={state.title}
          selectedCity={state.selectedCityName}
          persistModalOpenHandler={persistModalOpenHandler}
          data={state.data}
        />
      </Modal>
      <InnerContainer>
        <MobileBottomPadding>
          <Style.DashBoardContainer>
            <DashboardHeader
              title={`${state.selectedName}(全國)`}
              onChangeHandler={onChangeHandler}
              selectionOption={fourCode.fourCodeData}
              selectedCity={state.selectedCode}
              referenceType={"industryOverview"}
              patenSelection={state.patenSelection}
              isIndustry
            />
            <Style.CardContainer>
              <CustomizedCard title="產業概況" loading={info.infoLoading}>
                <MainIndex data={info.infoData} isIndustry />
              </CustomizedCard>
              <CustomizedCard title="研發概況" loading={info.infoLoading}>
                <MainIndex data={info.infoData} isIndustry isTech />
              </CustomizedCard>
            </Style.CardContainer>
            <Style.CardContainer column={3}>
              <CustomizedCard
                title="產業投入研發比例(%)"
                loading={info.infoLoading}>
                <CustomizedTable data={[state.researchPercent]} isInfo />
              </CustomizedCard>
              <CustomizedCard
                title="產業平均產值(千元)"
                loading={info.infoLoading}>
                <CustomizedTable data={[state.industryProductivity]} isInfo />
              </CustomizedCard>
              <CustomizedCard
                title="產業人均產值(千元)"
                loading={info.infoLoading}>
                <CustomizedTable data={[state.industryPerformancs]} isInfo />
              </CustomizedCard>
            </Style.CardContainer>
          </Style.DashBoardContainer>
          <Style.DashBoardContainer>
            <DashboardHeader
              title="產業趨勢"
              subTitle="呈現該主計總處行業分類細項(4碼)整體營運指標趨勢。"
              referenceType={"industryTrends"}
            />
            <Style.CardContainer>
              <CustomizedCard /* 產業上市櫃公司營收漲跌家數 */
                title={"上市櫃公司營收漲跌家數"}
                shouldCardOpen={true}
                loading={stockCompanyRevenueLoading}
                referenceType={"CityFourChart"}
              >
                <CustomizedGraphic
                  chartType={"donut-chart"}
                  data={stockCompanyRevenue}
                  title="上漲家數"
                  labels={stockCompanyRevenueLabel}
                  mapData={stockCompanyRevenueMap}
                  dataLabels={{
                    enabled: true,
                    formatter: (val, { seriesIndex, dataPointIndex, w }) =>
                      w.config.labels[seriesIndex],
                  }}
                  eventHandler={(val, title) =>
                    handleModalOpen("stockCompanyTidy", val, title, stockCompanyRevenueMap[val]?.source)
                  }
                />
              </CustomizedCard>
              <CustomizedCard /* 產業出口級距漲跌家數 */
                title={"出口級距漲跌家數"}
                shouldCardOpen={true}
                loading={exportCompanyRevenueLoading}
                referenceType={"CityFourChart"}
              >
                <CustomizedGraphic
                  chartType={"donut-chart"}
                  data={exportCompanyRevenue}
                  title="上漲家數"
                  labels={exportCompanyRevenueLabel}
                  mapData={exportCompanyRevenueMap}
                  dataLabels={{
                    enabled: true,
                    formatter: (val, { seriesIndex, dataPointIndex, w }) =>
                      w.config.labels[seriesIndex],
                  }}
                  eventHandler={(val, title) =>
                    handleModalOpen("exportCompanyTidy", val, title, exportCompanyRevenueMap[val]?.source)
                  }
                />
              </CustomizedCard>
              <CustomizedCard /* 產業上市櫃公司營收趨勢圖 */
                title="產業上市櫃公司營收趨勢圖(千元)"
                referenceType={"industryProductVal"}
                loading={stockCompanyRevenueTrend.stockCompanyRevenueTrendLoading}>
                <CustomizedGraphic
                  data={stockCompanyRevenueTrend.stockCompanyRevenueTrend}
                  labels={stockCompanyRevenueTrend.stockCompanyRevenueTrendLabel}
                  chartType="curve-chart"
                  skip="1"
                />
              </CustomizedCard>
              <CustomizedCard /* 產業出口級距趨勢圖 */
                title="產業出口級距趨勢圖(千元)"
                referenceType={"industryProductVal"}
                loading={exportCompanyRevenueTrend.exportCompanyRevenueTrendLoading}>
                <CustomizedGraphic
                  data={exportCompanyRevenueTrend.exportCompanyRevenueTrend}
                  labels={exportCompanyRevenueTrend.exportCompanyRevenueTrendLabel}
                  chartType="curve-chart"
                />
              </CustomizedCard>
              <CustomizedCard /* 產業上市櫃公司營收漲跌前五大排行 */
                title={`上市櫃公司營收漲跌前五大排行`}
                shouldCardOpen={true}
                referenceType={"CityTopTenCapital"}
                loading={topRevenue.topRevenueLoading}
                eventHandler={(val) => openNewTab(val)}
              >
                <CustomizedGraphic
                  chartType={"vertical-bar-chart"}
                  data={topRevenue.topRevenueData}
                  labels={topRevenue.topRevenueLabels}
                  mapData={topRevenue.topRevenueMap}
                  unit={"%"}
                  formatter={(val) => (val === 0 ? 0 : val)}
                  eventHandler={(val) => openNewTab(val)}
                  isCompanyCompare={
                    dashboardData.filterData.filter(function (item, index, array) {
                      return item.Code === window.location.href.split("/")[window.location.href.split("/").length - 1];
                    })
                  }
                />
              </CustomizedCard>
              <CustomizedCard
                title={`各產業營運動態列表`}
                shouldCardOpen={true}
                loading={operationMainIndexList.operationMainIndexListLoading}
                referenceType={"areaDashboradMainIndexTable"}
              >
                <IndustryMainIndexListTable
                  headerList={["產業名稱", "單月漲跌幅", "累計漲跌幅"]}
                  data={operationMainIndexList.operationMainIndexListData}
                />
              </CustomizedCard>
            </Style.CardContainer>
          </Style.DashBoardContainer>
          <Style.DashBoardContainer>
            <DashboardHeader
              title="產業趨勢"
              subTitle="呈現該主計總處行業分類細項(4碼)整體營運指標趨勢。"
              referenceType={"industryTrends"}
            />
            <Style.CardContainer>
              <CustomizedCard
                title="產業產值趨勢(千元)"
                referenceType={"industryProductVal"}
                loading={productVal.productValLoading}>
                <CustomizedGraphic
                  data={productVal.productValData}
                  labels={productVal.productValLabel}
                  chartType="curve-chart"
                />
              </CustomizedCard>
              <CustomizedCard
                title="全年固定資產投資金額趨勢(千元)"
                referenceType={"industryInvestmentAmt"}
                loading={investmentAmt.investmentAmtLoading}>
                <CustomizedGraphic
                  data={investmentAmt.investmentAmtData}
                  labels={investmentAmt.investmentAmtLabel}
                  chartType="curve-chart"
                />
              </CustomizedCard>
              <CustomizedCard
                title="營運中工廠家數趨勢"
                referenceType={"industryFactoryCount"}
                loading={factoryCount.factoryCountLoading}>
                <CustomizedGraphic
                  data={factoryCount.factoryCountData}
                  labels={factoryCount.factoryCountLabel}
                  chartType="curve-chart"
                />
              </CustomizedCard>
              <CustomizedCard
                title="年度從業員工人數趨勢"
                referenceType={"industryEmpCount"}
                loading={empCount.empCountLoading}>
                <CustomizedGraphic
                  data={empCount.empCountData}
                  labels={empCount.empCountLabel}
                  chartType="curve-chart"
                />
              </CustomizedCard>
              <CustomizedCard
                title="研究發展工廠家數"
                referenceType={"industryResearchFactoryCount"}
                loading={researchFactoryCount.researchFactoryCountLoading}>
                <CustomizedGraphic
                  data={researchFactoryCount.researchFactoryCountData}
                  labels={researchFactoryCount.researchFactoryCountLabel}
                  chartType="curve-chart"
                />
              </CustomizedCard>
              <CustomizedCard
                title="技術購買金額趨勢(千元)"
                referenceType={"industryTechBuyAmt"}
                loading={techBuyAmt.techBuyAmtLoading}>
                <CustomizedGraphic
                  data={techBuyAmt.techBuyAmtData}
                  labels={techBuyAmt.techBuyAmtLabel}
                  chartType="curve-chart"
                />
              </CustomizedCard>
              <CustomizedCard
                title="研究發展經費(千元)"
                loading={researchAmt.researchAmtLoading}
                referenceType={"industryResearchAmt"}>
                <CustomizedGraphic
                  data={researchAmt.researchAmtData}
                  labels={researchAmt.researchAmtLabel}
                  chartType="curve-chart"
                />
              </CustomizedCard>
              <CustomizedCard
                title="技術銷售金額趨勢(千元)"
                referenceType={"industryTechSaleAmt"}
                loading={techSaleAmt.techSaleAmtLoading}>
                <CustomizedGraphic
                  data={techSaleAmt.techSaleAmtData}
                  labels={techSaleAmt.techSaleAmtLabel}
                  chartType="curve-chart"
                />
              </CustomizedCard>
              <CustomizedCard
                title="營業收入(千元)"
                referenceType={"industryRevenueIncome"}
                loading={revenueIncome.revenueIncomeLoading}>
                <CustomizedGraphic
                  data={revenueIncome.revenueIncomeData}
                  labels={revenueIncome.revenueIncomeLabel}
                  chartType="curve-chart"
                />
              </CustomizedCard>
              <CustomizedCard
                title="營業支出(千元)"
                referenceType={"industryRevenuePay"}
                loading={revenuePay.revenuePayLoading}>
                <CustomizedGraphic
                  data={revenuePay.revenuePayData}
                  labels={revenuePay.revenuePayLabel}
                  chartType="curve-chart"
                />
              </CustomizedCard>
            </Style.CardContainer>
          </Style.DashBoardContainer>
          <Style.DashBoardContainer>
            <DashboardHeader
              title="標竿廠商"
              subTitle="產業前三十大資本額廠商清單與股票上市櫃公司。"
              remark={
                "<p style='text-align:left'>1.資本額 : 商業司商業司商工行政資料開放平台。</p><p style='text-align:left'>2.廠商行業別資料來自於經濟部工廠名錄。</p><p style='text-align:left'><a  href='https://dmz26.moea.gov.tw/GMWeb/investigate/InvestigateFactory.aspx'>https://dmz26.moea.gov.tw/GMWeb/investigate/InvestigateFactory.aspx</a></p><p style='text-align:left'>3.股票發行狀態:公開資訊觀測站。</p><p style='text-align:left'>資料說明：</p><p style='text-align:left'>營業額<font color='red'>紅字</font>表示比去年同期成長。</p><p style='text-align:left'>營業額<font color='green'>綠字</font>表示比去年同期衰退。</p>"
              }
            />
            <Style.CardContainer>
              <CustomizedCard
                title={`資本額前三十大公司`}
                referenceType={"industryTop30Company"}
                shouldCardOpen={true}
                loading={top30Company.top30CompanyLoading}>
                <CustomizedTable
                  headerList={["廠商名稱"]}
                  data={top30Company.top30CompanyData}
                  rowsPerPage={10}
                  isArea
                  isTop30
                />
              </CustomizedCard>
              <CustomizedCard
                title={`股票上市櫃公司`}
                shouldCardOpen={true}
                referenceType={"industryListedCompany"}
                loading={listedCompany.listedCompanyLoading}>
                <CustomizedTable
                  headerList={[
                    "公司簡稱",
                    "股票類別",
                    `${state.ListedCompanyDate}營業額(千元)`,
                  ]}
                  data={listedCompany.listedCompanyData}
                  rowsPerPage={10}
                  isListedCompany
                />
              </CustomizedCard>
            </Style.CardContainer>
          </Style.DashBoardContainer>
          <Style.DashBoardContainer>
            <DashboardHeader
              title="專利研發"
              subTitle="掌握全國或工業區主計總處行業分類細項(4碼)之最新發明公開與專利公告。"
              referenceType={"industryPatentResearch"}
              onChangeHandler={onChangeAreaHandler}
              handleKeyWord={handleKeyWord}
              selectionOption={state.parkOptions}
              selectedCity={state.selectedPark}
              keyWord={state.keyWord}
              isPatent
              patenSelection={state.patenSelection}
              loading={pubinvention.pubinventionLoading}
              patentLoading={patentFinished.patentFinishedLoading}
            />
            <Style.CardContainer>
              <CustomizedCard
                title={`發明公開(5年內)`}
                shouldCardOpen={true}
                referenceType={"industryPublicInvention"}
                loading={pubinvention.pubinventionLoading}>
                <CustomizedTable
                  headerList={["公司名稱", "公開號", "專利中文名稱"]}
                  data={
                    state.keyWord !== ""
                      ? state.filterInvention
                      : pubinvention.pubinventionData
                  }
                  isPubInvention
                  rowsPerPage={10}
                />
              </CustomizedCard>
              <CustomizedCard
                title={`專利公告(5年內)`}
                shouldCardOpen={true}
                referenceType={"industryPatentFinished"}
                loading={patentFinished.patentFinishedLoading}>
                <CustomizedTable
                  headerList={["公司名稱", "專利號碼", "專利中文名稱"]}
                  data={
                    state.keyWord !== ""
                      ? state.filterPatent
                      : patentFinished.patentFinishedData
                  }
                  isPubInvention
                  isPatent
                  rowsPerPage={10}
                />
              </CustomizedCard>
            </Style.CardContainer>
          </Style.DashBoardContainer>
        </MobileBottomPadding>
      </InnerContainer>
    </ScrollContainer>
  );
};

const MobileBottomPadding = styled.div`
  @media (max-width: 576px) {
    padding-bottom: 6.5rem;
  }
`;
