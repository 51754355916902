import * as actionTypes from "../actionTypes/IndustryDashboard";

const initState = {
  operationMainIndexList: {
    operationMainIndexListData: [],
    operationMainIndexListLoading: false,
  },
  stockCompanyRevenue: [],
  stockCompanyRevenueLabel: [],
  stockCompanyRevenueMap: [],
  stockCompanyRevenueLoading: false,
  exportCompanyRevenue: [],
  exportCompanyRevenueLabel: [],
  exportCompanyRevenueMap: [],
  exportCompanyRevenueLoading: false,
  topRevenue: {
    topRevenueData: [],
    topRevenueLabels: [],
    topRevenueTags: [],
    topRevenueMap: [],
    topRevenueLoading: false,
  },
  info: {
    infoData: [],
    infoLoading: false,
  },
  productVal: {
    productValData: [],
    productValLabel: [],
    productValLoading: false,
  },
  stockCompanyRevenueTrend: {
    stockCompanyRevenueTrend: [],
    stockCompanyRevenueTrendLabel: [],
    stockCompanyRevenueTrendLoading: false,
  },
  exportCompanyRevenueTrend: {
    exportCompanyRevenueTrend: [],
    exportCompanyRevenueTrendLabel: [],
    exportCompanyRevenueTrendLoading: false,
  },
  investmentAmt: {
    investmentAmtData: [],
    investmentAmtLabel: [],
    investmentAmtLoading: false,
  },
  factoryCount: {
    factoryCountData: [],
    factoryCountLabel: [],
    factoryCountLoading: false,
  },
  empCount: {
    empCountData: [],
    empCountLabel: [],
    empCountLoading: false,
  },
  researchFactoryCount: {
    researchFactoryCountData: [],
    researchFactoryCountLabel: [],
    researchFactoryCountLoading: false,
  },
  techBuyAmt: {
    techBuyAmtData: [],
    techBuyAmtLabel: [],
    techBuyAmtLoading: false,
  },
  researchAmt: {
    researchAmtData: [],
    researchAmtLabel: [],
    researchAmtLoading: false,
  },
  techSaleAmt: {
    techSaleAmtData: [],
    techSaleAmtLabel: [],
    techSaleAmtLoading: false,
  },
  revenueIncome: {
    revenueIncomeData: [],
    revenueIncomeLabel: [],
    revenueIncomeLoading: false,
  },
  revenuePay: {
    revenuePayData: [],
    revenuePayLabel: [],
    revenuePayLoading: false,
  },
  top30Company: {
    top30CompanyData: [],
    top30CompanyLoading: false,
  },
  listedCompany: {
    listedCompanyData: [],
    listedCompanyLoading: false,
  },
  pubinvention: {
    pubinventionData: [],
    pubinventionLoading: false,
  },
  patentFinished: {
    patentFinishedData: [],
    patentFinishedLoading: false,
  },
  fourCode: {
    fourCodeData: [],
    fourCodeLoading: false,
  },
  errorMessage: "",
};

//產業儀錶板營運動態列表
export const startOperationMainIndexListData = (state) => {
  return {
    ...state,
    operationMainIndexList: {
      ...state.operationMainIndexList,
      operationMainIndexListLoading: true,
    },
  };
};

export const getOperationMainIndexListDataSuccess = (state, data) => {
  return {
    ...state,
    operationMainIndexList: {
      ...state.operationMainIndexList,
      operationMainIndexListData: data.data,
      operationMainIndexListLoading: false,
    },
  };
};

export const getOperationMainIndexListDataFail = (state, err) => {
  return {
    ...state,
    operationMainIndexList: {
      ...state.operationMainIndexList,
      operationMainIndexListLoading: false,
    },
    errorMessage: err,
  };
};


// 產業上市櫃公司營收漲跌家數
export const startGetStockCompanyRevenue = (state) => {
  return {
    ...state,
    stockCompanyRevenueLoading: true,
  };
};

export const getStockCompanyRevenueSuccess = (state, data) => {
  //產業上市櫃公司營收漲跌前五大排行
  const updateTopRevenueData = [];
  const updateTopRevenueTags = [];
  const updateTopRevenueLabels = [];
  const tidyTopRevenueData = [];
  data.slice(0, 5).map((type) => {
    updateTopRevenueData.push((type.Variety * 100).toFixed(2));
    updateTopRevenueTags.push(type.BusinessNo);
    updateTopRevenueLabels.push(type.CompanyName);
    tidyTopRevenueData.push(
      {
        'label': type.CompanyName,
        'y': (type.Variety * 100).toFixed(2),
        'tag': type.BusinessNo,
      }
    )
  });
  data.slice(data.length - 5, data.length).map((type) => {
    if(!updateTopRevenueTags.includes(type.BusinessNo)){
      updateTopRevenueData.push((type.Variety * 100).toFixed(2));
      updateTopRevenueTags.push(type.BusinessNo);
      updateTopRevenueLabels.push(type.CompanyName);
      tidyTopRevenueData.push(
        {
          'label': type.CompanyName,
          'y': (type.Variety * 100).toFixed(2),
          'tag': type.BusinessNo,
        }
      )
    }
  });
  //END產業上市櫃公司營收漲跌前五大排行
  let tidyData = [
    { 'label': "上漲", "y": 0, "tag": "0", "source": [] },
    { 'label': "下跌", "y": 0, "tag": "1", "source": [] },
    { 'label': "持平", "y": 0, "tag": "2", "source": [] },
  ]
  for (let i = 0; i < data.length; i++) {
    if (data[i].Variety > 0) {
      tidyData[0].y++;
      tidyData[0].source.push(data[i]);
    } else if (data[i].Variety < 0) {
      tidyData[1].y++;
      tidyData[1].source.push(data[i]);
    } else {
      tidyData[2].y++;
      tidyData[2].source.push(data[i]);
    }
  }
  const updateStockCompanyRevenueLabel = [];
  const updateStockCompanyRevenue = [];
  tidyData.map((type) => {
    updateStockCompanyRevenue.push(type.y);
    updateStockCompanyRevenueLabel.push(type.label);
  });
  return {
    ...state,
    stockCompanyRevenue: updateStockCompanyRevenue,
    stockCompanyRevenueLabel: updateStockCompanyRevenueLabel,
    stockCompanyRevenueMap: tidyData,
    stockCompanyRevenueLoading: false,
    topRevenue: {
      ...state.topRevenue,
      topRevenueData: updateTopRevenueData,
      topRevenueLabels: updateTopRevenueLabels,
      topRevenueTags: updateTopRevenueTags,
      topRevenueMap: tidyTopRevenueData,
      topRevenueLoading: false,
    },
  };
};

export const getStockCompanyRevenueFail = (state, err) => {
  return {
    ...state,
    stockCompanyRevenueLoading: false,
    errorMessage: err,
  };
};

// 產業出口級距漲跌家數
export const startGetExportCompanyRevenue = (state) => {
  return {
    ...state,
    typeFourChartDataLoading2: true,
  };
};

export const getExportCompanyRevenueSuccess = (state, data) => {
  let tidyData = [
    { 'label': "上漲", "y": 0, "tag": "0", "source": [] },
    { 'label': "下跌", "y": 0, "tag": "1", "source": [] },
    { 'label': "持平", "y": 0, "tag": "2", "source": [] },
  ]
  for (let i = 0; i < data.length; i++) {
    if (data[i].Variety > 0) {
      tidyData[0].y++;
      tidyData[0].source.push(data[i]);
    } else if (data[i].Variety < 0) {
      tidyData[1].y++;
      tidyData[1].source.push(data[i]);
    } else {
      tidyData[2].y++;
      tidyData[2].source.push(data[i]);
    }
  }
  const updateExportCompanyRevenueLabel = [];
  const updateExportCompanyRevenue = [];
  tidyData.map((type) => {
    updateExportCompanyRevenue.push(type.y);
    updateExportCompanyRevenueLabel.push(type.label);
  });
  return {
    ...state,
    exportCompanyRevenue: updateExportCompanyRevenue,
    exportCompanyRevenueLabel: updateExportCompanyRevenueLabel,
    exportCompanyRevenueMap: tidyData,
    exportCompanyRevenueLoading: false,
  };
};

export const getExportCompanyRevenueFail = (state, err) => {
  return {
    ...state,
    typeFourChartDataLoading2: false,
    errorMessage: err,
  };
};

//取得最新數據
export const startGetInfo = (state) => {
  return {
    ...state,
    info: {
      ...state.info,
      infoLoading: true,
    },
  };
};

export const getInfoSuccess = (state, data) => {
  return {
    ...state,
    info: {
      ...state.info,
      infoData: data,
      infoLoading: false,
    },
  };
};

export const getInfoFail = (state, err) => {
  return {
    ...state,
    info: {
      ...state.info,
      infoLoading: false,
    },
    errorMessage: err,
  };
};

//產業上市櫃公司營收趨勢圖
export const startGetStockCompanyRevenueTrend = (state) => {
  return {
    ...state,
    stockCompanyRevenueTrend: {
      ...state.stockCompanyRevenueTrend,
      stockCompanyRevenueTrendLoading: true,
    },
  };
};

export const getStockCompanyRevenueTrendSuccess = (state, data) => {
  var dataArr = [];
  var labelArr = [];
  let sortedData = data.sort((p1, p2) => (p2.AnnouncementDate < p1.AnnouncementDate) ? 1 : (p2.AnnouncementDate > p1.AnnouncementDate) ? -1 : 0);
  sortedData.map((i) => {
    dataArr.push(i.SumSaleMonth);
    labelArr.push(i.AnnouncementDate.replace("-01T00:00:00", ""));
  });

  return {
    ...state,
    stockCompanyRevenueTrend: {
      ...state.stockCompanyRevenueTrend,
      stockCompanyRevenueTrend: dataArr,
      stockCompanyRevenueTrendLabel: labelArr,
      stockCompanyRevenueTrendLoading: false,
    },
  };
};

export const getStockCompanyRevenueTrendFail = (state, err) => {
  return {
    ...state,
    stockCompanyRevenueTrend: {
      ...state.stockCompanyRevenueTrend,
      stockCompanyRevenueTrendLoading: false,
    },
    errorMessage: err,
  };
};

//產業上市櫃公司營收趨勢圖
export const startGetExportCompanyRevenueTrend = (state) => {
  return {
    ...state,
    exportCompanyRevenueTrend: {
      ...state.exportCompanyRevenueTrend,
      exportCompanyRevenueTrendLoading: true,
    },
  };
};

export const getExportCompanyRevenueTrendSuccess = (state, data) => {
  var dataArr = [];
  var labelArr = [];
  let sortedData = data.sort((p1, p2) => (p2.Date < p1.Date) ? 1 : (p2.Date > p1.Date) ? -1 : 0);
  sortedData.map((i) => {
    if (i.Date.includes("-12-01")) {
      dataArr.push(i.Value);
      labelArr.push(i.Date.toString().replace("-01", ""));
    }
  });

  return {
    ...state,
    exportCompanyRevenueTrend: {
      ...state.exportCompanyRevenueTrend,
      exportCompanyRevenueTrend: dataArr,
      exportCompanyRevenueTrendLabel: labelArr,
      exportCompanyRevenueTrendLoading: false,
    },
  };
};

export const getExportCompanyRevenueTrendFail = (state, err) => {
  return {
    ...state,
    exportCompanyRevenueTrend: {
      ...state.exportCompanyRevenueTrend,
      exportCompanyRevenueTrendLoading: false,
    },
    errorMessage: err,
  };
};


//產業產值趨勢(千元)
export const startGetProductVal = (state) => {
  return {
    ...state,
    productVal: {
      ...state.productVal,
      productValLoading: true,
    },
  };
};

export const getProductValSuccess = (state, data) => {
  var dataArr = [];
  data.map((i) => {
    dataArr.push(i.y);
  });

  var labelArr = [];
  data.map((i) => {
    labelArr.push(i.label);
  });

  return {
    ...state,
    productVal: {
      ...state.productVal,
      productValData: dataArr,
      productValLabel: labelArr,
      productValLoading: false,
    },
  };
};

export const getProductValFail = (state, err) => {
  return {
    ...state,
    productVal: {
      ...state.productVal,
      productValLoading: false,
    },
    errorMessage: err,
  };
};

//全年固定資產投資金額趨勢(千元)
export const startGetInvestmentAmt = (state) => {
  return {
    ...state,
    investmentAmt: {
      ...state.investmentAmt,
      investmentAmtLoading: true,
    },
  };
};

export const getInvestmentAmtSuccess = (state, data) => {
  var dataArr = [];
  data.map((i) => {
    dataArr.push(i.y);
  });

  var labelArr = [];
  data.map((i) => {
    labelArr.push(i.label);
  });

  return {
    ...state,
    investmentAmt: {
      ...state.investmentAmt,
      investmentAmtData: dataArr,
      investmentAmtLabel: labelArr,
      investmentAmtLoading: false,
    },
  };
};

export const getInvestmentAmtFail = (state, err) => {
  return {
    ...state,
    investmentAmt: {
      ...state.investmentAmt,
      investmentAmtLoading: false,
    },
    errorMessage: err,
  };
};

//營運中工廠家數趨勢
export const startGetFactoryCount = (state) => {
  return {
    ...state,
    factoryCount: {
      ...state.factoryCount,
      factoryCountLoading: true,
    },
  };
};

export const getFactoryCountSuccess = (state, data) => {
  var dataArr = [];
  data.map((i) => {
    dataArr.push(i.y);
  });

  var labelArr = [];
  data.map((i) => {
    labelArr.push(i.label);
  });

  return {
    ...state,
    factoryCount: {
      ...state.factoryCount,
      factoryCountData: dataArr,
      factoryCountLabel: labelArr,
      factoryCountLoading: false,
    },
  };
};

export const getFactoryCountFail = (state, err) => {
  return {
    ...state,
    factoryCount: {
      ...state.factoryCount,
      factoryCountLoading: false,
    },
    errorMessage: err,
  };
};

//年度從業員工人數趨勢
export const startGetEmpCount = (state) => {
  return {
    ...state,
    empCount: {
      ...state.empCount,
      empCountLoading: true,
    },
  };
};

export const getEmpCountSuccess = (state, data) => {
  var dataArr = [];
  data.map((i) => {
    dataArr.push(i.y);
  });

  var labelArr = [];
  data.map((i) => {
    labelArr.push(i.label);
  });

  return {
    ...state,
    empCount: {
      ...state.empCount,
      empCountData: dataArr,
      empCountLabel: labelArr,
      empCountLoading: false,
    },
  };
};

export const getEmpCountFail = (state, err) => {
  return {
    ...state,
    empCount: {
      ...state.empCount,
      empCountLoading: false,
    },
    errorMessage: err,
  };
};

//研究發展工廠家數
export const startGetResearchFactoryCount = (state) => {
  return {
    ...state,
    researchFactoryCount: {
      ...state.researchFactoryCount,
      researchFactoryCountLoading: true,
    },
  };
};

export const getResearchFactoryCountSuccess = (state, data) => {
  var dataArr = [];
  data.map((i) => {
    dataArr.push(i.y);
  });

  var labelArr = [];
  data.map((i) => {
    labelArr.push(i.label);
  });

  return {
    ...state,
    researchFactoryCount: {
      ...state.researchFactoryCount,
      researchFactoryCountData: dataArr,
      researchFactoryCountLabel: labelArr,
      researchFactoryCountLoading: false,
    },
  };
};

export const getResearchFactoryCountFail = (state, err) => {
  return {
    ...state,
    researchFactoryCount: {
      ...state.researchFactoryCount,
      researchFactoryCountLoading: false,
    },
    errorMessage: err,
  };
};

//技術購買金額趨勢(千元)
export const startGetTechBuyAmt = (state) => {
  return {
    ...state,
    techBuyAmt: {
      ...state.techBuyAmt,
      techBuyAmtLoading: true,
    },
  };
};

export const getTechBuyAmtSuccess = (state, data) => {
  var dataArr = [];
  data.map((i) => {
    dataArr.push(i.y);
  });

  var labelArr = [];
  data.map((i) => {
    labelArr.push(i.label);
  });

  return {
    ...state,
    techBuyAmt: {
      ...state.techBuyAmt,
      techBuyAmtData: dataArr,
      techBuyAmtLabel: labelArr,
      techBuyAmtLoading: false,
    },
  };
};

export const getTechBuyAmtFail = (state, err) => {
  return {
    ...state,
    techBuyAmt: {
      ...state.techBuyAmt,
      techBuyAmtLoading: false,
    },
    errorMessage: err,
  };
};

//研究發展經費(千元)
export const startGetResearchAmt = (state) => {
  return {
    ...state,
    researchAmt: {
      ...state.researchAmt,
      researchAmtLoading: true,
    },
  };
};

export const getResearchAmtSuccess = (state, data) => {
  var dataArr = [];
  data.map((i) => {
    dataArr.push(i.y);
  });

  var labelArr = [];
  data.map((i) => {
    labelArr.push(i.label);
  });

  return {
    ...state,
    researchAmt: {
      ...state.researchAmt,
      researchAmtData: dataArr,
      researchAmtLabel: labelArr,
      researchAmtLoading: false,
    },
  };
};

export const getResearchAmtFail = (state, err) => {
  return {
    ...state,
    researchAmt: {
      ...state.researchAmt,
      researchAmtLoading: false,
    },
    errorMessage: err,
  };
};

//技術銷售金額趨勢(千元)
export const startGetTechSaleAmt = (state) => {
  return {
    ...state,
    techSaleAmt: {
      ...state.techSaleAmt,
      techSaleAmtLoading: true,
    },
  };
};

export const getTechSaleAmtSuccess = (state, data) => {
  var dataArr = [];
  data.map((i) => {
    dataArr.push(i.y);
  });

  var labelArr = [];
  data.map((i) => {
    labelArr.push(i.label);
  });

  return {
    ...state,
    techSaleAmt: {
      ...state.techSaleAmt,
      techSaleAmtData: dataArr,
      techSaleAmtLabel: labelArr,
      techSaleAmtLoading: false,
    },
  };
};

export const getTechSaleAmtFail = (state, err) => {
  return {
    ...state,
    techSaleAmt: {
      ...state.techSaleAmt,
      techSaleAmtLoading: false,
    },
    errorMessage: err,
  };
};

//營業收入(千元)
export const startGetRevenueIncome = (state) => {
  return {
    ...state,
    revenueIncome: {
      ...state.revenueIncome,
      revenueIncomeLoading: true,
    },
  };
};

export const getRevenueIncomeSuccess = (state, data) => {
  var dataArr = [];
  data.map((i) => {
    dataArr.push(i.y);
  });

  var labelArr = [];
  data.map((i) => {
    labelArr.push(i.label);
  });

  return {
    ...state,
    revenueIncome: {
      ...state.revenueIncome,
      revenueIncomeData: dataArr,
      revenueIncomeLabel: labelArr,
      revenueIncomeLoading: false,
    },
  };
};

export const getRevenueIncomeFail = (state, err) => {
  return {
    ...state,
    revenueIncome: {
      ...state.revenueIncome,
      revenueIncomeLoading: false,
    },
    errorMessage: err,
  };
};

//營業支出(千元)
export const startGetRevenuePay = (state) => {
  return {
    ...state,
    revenuePay: {
      ...state.revenuePay,
      revenuePayLoading: true,
    },
  };
};

export const getRevenuePaySuccess = (state, data) => {
  var dataArr = [];
  data.map((i) => {
    dataArr.push(i.y);
  });

  var labelArr = [];
  data.map((i) => {
    labelArr.push(i.label);
  });

  return {
    ...state,
    revenuePay: {
      ...state.revenuePay,
      revenuePayData: dataArr,
      revenuePayLabel: labelArr,
      revenuePayLoading: false,
    },
  };
};

export const getRevenuePayFail = (state, err) => {
  return {
    ...state,
    revenuePay: {
      ...state.revenuePay,
      revenuePayLoading: false,
    },
    errorMessage: err,
  };
};

//產業前三十大資本額廠商清單
export const startGetTop30Company = (state) => {
  return {
    ...state,
    top30Company: {
      ...state.top30Company,
      top30CompanyLoading: true,
    },
  };
};

export const getTop30CompanySuccess = (state, data) => {
  return {
    ...state,
    top30Company: {
      ...state.top30Company,
      top30CompanyData: data,
      top30CompanyLoading: false,
    },
  };
};

export const getTop30CompanyFail = (state, err) => {
  return {
    ...state,
    top30Company: {
      ...state.top30Company,
      top30CompanyLoading: false,
    },
    errorMessage: err,
  };
};

//產業上市櫃廠商
export const startGetListedCompany = (state) => {
  return {
    ...state,
    listedCompany: {
      ...state.listedCompany,
      listedCompanyLoading: true,
    },
  };
};

export const getListedCompanySuccess = (state, data) => {
  return {
    ...state,
    listedCompany: {
      ...state.listedCompany,
      listedCompanyData: data,
      listedCompanyLoading: false,
    },
  };
};

export const getListedCompanyFail = (state, err) => {
  return {
    ...state,
    listedCompany: {
      ...state.listedCompany,
      listedCompanyLoading: false,
    },
    errorMessage: err,
  };
};

//發明公開(5年內)
export const startGetPubinvention = (state) => {
  return {
    ...state,
    pubinvention: {
      ...state.pubinvention,
      pubinventionLoading: true,
    },
  };
};

export const getPubinventionSuccess = (state, data) => {
  return {
    ...state,
    pubinvention: {
      ...state.pubinvention,
      pubinventionData: data,
      pubinventionLoading: false,
    },
  };
};

export const getPubinventionFail = (state, err) => {
  return {
    ...state,
    pubinvention: {
      ...state.pubinvention,
      pubinventionLoading: false,
    },
    errorMessage: err,
  };
};

//專利公告(5年內)
export const startGetPatentFinished = (state) => {
  return {
    ...state,
    patentFinished: {
      ...state.patentFinished,
      patentFinishedLoading: true,
    },
  };
};

export const getPatentFinishedSuccess = (state, data) => {
  return {
    ...state,
    patentFinished: {
      ...state.patentFinished,
      patentFinishedData: data,
      patentFinishedLoading: false,
    },
  };
};

export const getPatentFinishedFail = (state, err) => {
  return {
    ...state,
    patentFinished: {
      ...state.patentFinished,
      patentFinishedLoading: false,
    },
    errorMessage: err,
  };
};

//產業四碼
export const startGetFourCode = (state) => {
  return {
    ...state,
    fourCode: {
      ...state.fourCode,
      fourCodeLoading: true,
    },
  };
};

export const getFourCodeSuccess = (state, data) => {
  return {
    ...state,
    fourCode: {
      ...state.fourCode,
      fourCodeData: data,
      fourCodeLoading: false,
    },
  };
};

export const getFourCodeFail = (state, err) => {
  return {
    ...state,
    fourCode: {
      ...state.fourCode,
      fourCodeLoading: false,
    },
    errorMessage: err,
  };
};

export default (state = initState, action) => {
  switch (action.type) {
    //產業儀錶板營運動態列表
    case actionTypes.START_GET_OPERATION_MAIN_INDEX_LIST_DATA:
      return startOperationMainIndexListData(state);
    case actionTypes.GET_OPERATION_MAIN_INDEX_LIST_DATA_SUCCESS:
      return getOperationMainIndexListDataSuccess(state, action.data);
    case actionTypes.GET_OPERATION_MAIN_INDEX_LIST_DATA_FAIL:
      return getOperationMainIndexListDataFail(state, action.err);

    //產業上市櫃公司營收漲跌家數
    case actionTypes.START_GET_STOCK_COMPANY_REVENUE:
      return startGetStockCompanyRevenue(state);
    case actionTypes.GET_STOCK_COMPANY_REVENUE_SUCCESS:
      return getStockCompanyRevenueSuccess(state, action.data);
    case actionTypes.GET_STOCK_COMPANY_REVENUE_FAIL:
      return getStockCompanyRevenueFail(state, action.err);

    //產業出口級距漲跌家數
    case actionTypes.START_GET_EXPORT_COMPANY_REVENUE:
      return startGetExportCompanyRevenue(state);
    case actionTypes.GET_EXPORT_COMPANY_REVENUE_SUCCESS:
      return getExportCompanyRevenueSuccess(state, action.data);
    case actionTypes.GET_EXPORT_COMPANY_REVENUE_FAIL:
      return getExportCompanyRevenueFail(state, action.err);

    //取得最新數據
    case actionTypes.START_GET_INFO:
      return startGetInfo(state);
    case actionTypes.GET_INFO_SUCCESS:
      return getInfoSuccess(state, action.data);
    case actionTypes.GET_INFO_FAIL:
      return getInfoFail(state, action.err);

    //產業產值趨勢(千元)
    case actionTypes.START_GET_PRODUCT_VAL:
      return startGetProductVal(state);
    case actionTypes.GET_PRODUCT_VAL_SUCCESS:
      return getProductValSuccess(state, action.data);
    case actionTypes.GET_PRODUCT_VAL_FAIL:
      return getProductValFail(state, action.err);

    //產業上市櫃公司營收趨勢圖
    case actionTypes.START_GET_STOCK_COMPANY_REVENUE_TREND:
      return startGetStockCompanyRevenueTrend(state);
    case actionTypes.GET_STOCK_COMPANY_REVENUE_TREND_SUCCESS:
      return getStockCompanyRevenueTrendSuccess(state, action.data);
    case actionTypes.GET_STOCK_COMPANY_REVENUE_TREND_FAIL:
      return getStockCompanyRevenueTrendFail(state, action.err);

    //產業出口級距趨勢圖
    case actionTypes.START_GET_EXPORT_COMPANY_REVENUE_TREND:
      return startGetExportCompanyRevenueTrend(state);
    case actionTypes.GET_EXPORT_COMPANY_REVENUE_TREND_SUCCESS:
      return getExportCompanyRevenueTrendSuccess(state, action.data);
    case actionTypes.GET_EXPORT_COMPANY_REVENUE_TREND_FAIL:
      return getExportCompanyRevenueTrendFail(state, action.err);

    //產業產值趨勢(千元)
    case actionTypes.START_GET_INVESTMENT_AMT:
      return startGetInvestmentAmt(state);
    case actionTypes.GET_INVESTMENT_AMT_SUCCESS:
      return getInvestmentAmtSuccess(state, action.data);
    case actionTypes.GET_INVESTMENT_AMT_FAIL:
      return getInvestmentAmtFail(state, action.err);

    //營運中工廠家數趨勢
    case actionTypes.START_GET_FACTORY_COUNT:
      return startGetFactoryCount(state);
    case actionTypes.GET_FACTORY_COUNT_SUCCESS:
      return getFactoryCountSuccess(state, action.data);
    case actionTypes.GET_FACTORY_COUNT_FAIL:
      return getFactoryCountFail(state, action.err);

    //年度從業員工人數趨勢
    case actionTypes.START_GET_EMP_COUNT:
      return startGetEmpCount(state);
    case actionTypes.GET_EMP_COUNT_SUCCESS:
      return getEmpCountSuccess(state, action.data);
    case actionTypes.GET_EMP_COUNT_FAIL:
      return getEmpCountFail(state, action.err);

    //研究發展工廠家數
    case actionTypes.START_GET_RESEARCH_FACTORY_COUNT:
      return startGetResearchFactoryCount(state);
    case actionTypes.GET_RESEARCH_FACTORY_COUNT_SUCCESS:
      return getResearchFactoryCountSuccess(state, action.data);
    case actionTypes.GET_RESEARCH_FACTORY_COUNT_FAIL:
      return getResearchFactoryCountFail(state, action.err);

    //技術購買金額趨勢(千元)
    case actionTypes.START_GET_TECH_BUY_AMT:
      return startGetTechBuyAmt(state);
    case actionTypes.GET_TECH_BUY_AMT_SUCCESS:
      return getTechBuyAmtSuccess(state, action.data);
    case actionTypes.GET_TECH_BUY_AMT_FAIL:
      return getTechBuyAmtFail(state, action.err);

    //研究發展經費(千元)
    case actionTypes.START_GET_RESEARCH_AMT:
      return startGetResearchAmt(state);
    case actionTypes.GET_RESEARCH_AMT_SUCCESS:
      return getResearchAmtSuccess(state, action.data);
    case actionTypes.GET_RESEARCH_AMT_FAIL:
      return getResearchAmtFail(state, action.err);

    //技術銷售金額趨勢(千元)
    case actionTypes.START_GET_TECH_SALE_AMT:
      return startGetTechSaleAmt(state);
    case actionTypes.GET_TECH_SALE_AMT_SUCCESS:
      return getTechSaleAmtSuccess(state, action.data);
    case actionTypes.GET_TECH_SALE_AMT_FAIL:
      return getTechSaleAmtFail(state, action.err);

    //營業收入(千元)
    case actionTypes.START_GET_REVENUE_INCOME:
      return startGetRevenueIncome(state);
    case actionTypes.GET_REVENUE_INCOME_SUCCESS:
      return getRevenueIncomeSuccess(state, action.data);
    case actionTypes.GET_REVENUE_INCOME_FAIL:
      return getRevenueIncomeFail(state, action.err);

    //營業支出(千元)
    case actionTypes.START_GET_REVENUE_PAY:
      return startGetRevenuePay(state);
    case actionTypes.GET_REVENUE_PAY_SUCCESS:
      return getRevenuePaySuccess(state, action.data);
    case actionTypes.GET_REVENUE_PAY_FAIL:
      return getRevenuePayFail(state, action.err);

    //產業前三十大資本額廠商清單
    case actionTypes.START_GET_TOP30_COMPANY:
      return startGetTop30Company(state);
    case actionTypes.GET_TOP30_COMPANY_SUCCESS:
      return getTop30CompanySuccess(state, action.data);
    case actionTypes.GET_TOP30_COMPANY_FAIL:
      return getTop30CompanyFail(state, action.err);

    //產業上市櫃廠商
    case actionTypes.START_GET_LISTED_COMPANY:
      return startGetListedCompany(state);
    case actionTypes.GET_LISTED_COMPANY_SUCCESS:
      return getListedCompanySuccess(state, action.data);
    case actionTypes.GET_LISTED_COMPANY_FAIL:
      return getListedCompanyFail(state, action.err);

    //發明公開(5年內)-全國
    case actionTypes.START_GET_PUBINVENTION:
      return startGetPubinvention(state);
    case actionTypes.GET_PUBINVENTION_SUCCESS:
      return getPubinventionSuccess(state, action.data);
    case actionTypes.GET_PUBINVENTION_FAIL:
      return getPubinventionFail(state, action.err);

    //專利公告(5年內)
    case actionTypes.START_GET_PATENT_FINISHED:
      return startGetPatentFinished(state);
    case actionTypes.GET_PATENT_FINISHED_SUCCESS:
      return getPatentFinishedSuccess(state, action.data);
    case actionTypes.GET_PATENT_FINISHED_FAIL:
      return getPatentFinishedFail(state, action.err);

    //產業四碼
    case actionTypes.START_GET_FOUR_CODE:
      return startGetFourCode(state);
    case actionTypes.GET_FOUR_CODE_SUCCESS:
      return getFourCodeSuccess(state, action.data);
    case actionTypes.GET_FOUR_CODE_FAIL:
      return getFourCodeFail(state, action.err);
    default:
      return state;
  }
};
