import React from "react";
import Icon from "@material-ui/core/Icon";
import { useDispatch, useSelector } from "react-redux";

import TypeTable from "./TypeTable";
import ImportAndExportTable from "./ImportAndExportTable";
import GovPlanAndAwardTable from "./GovPlanAndAwardTable";
import PublciStockTable from "./PublciStockTable";
import PatentTable from "./PatentTable";
import TopTenGovPlan from "./TopTenGovPlan";
import GoogleTrends from "../WordCloud/GoogleTrends";


import Spinner from "../Spinner/Spinner";
import {
  getTypeTwoListDataHandler,
  getTypeThreeListDataHandler,
  getTypeFourListDataHandler,
  getImportListData,
  getExportListData,
  getAwardListData,
  getGovPlanListData,
  getPublicStockListDataHandler,
  getPatentListDataHandler,
  getTopTenGovPlanListDataHandler
} from "../../store/actions/customizedDashboard";

import "./styles.scss";

export default (props) => {
  const dispatch = useDispatch();
  const { modalType, referenceCode, orgId, handleClose, title, selectedOrg, persistModalOpenHandler } = props;
  const {
    typeTwoList,
    typeThreeList,
    typeFourList,
    importList,
    exportList,
    govPlanList,
    awardList,
    publicStockList,
    patentList,
    topTenGovPlan
  } = useSelector(state => state.cityDashboard)

  const [state, setState] = React.useState({
    title: "",
    content: "",
    listContent: "",
    header: "",
    loading: false,
    data: [],
    page: 0,
    rowsPerPage: 5
  });

  const url = window.location.origin;

  const openNewTabs = (link) => window.open(link, "_blank");

  React.useEffect(() => {
    if (modalType) {
      switch (modalType) {
        case "typeTwo":
          dispatch(getTypeTwoListDataHandler(orgId, referenceCode))
          break;
        case "typeThree":
          dispatch(getTypeThreeListDataHandler(orgId, referenceCode))
          break;
        case "typeFour":
          dispatch(getTypeFourListDataHandler(orgId, referenceCode))
          break;
        case "export":
          dispatch(getExportListData(orgId, referenceCode))
          break;
        case "import":
          dispatch(getImportListData(orgId, referenceCode))
          break;
        case "govPlan":
          dispatch(getGovPlanListData(orgId, referenceCode))
          break;
        case "award":
          dispatch(getAwardListData(orgId, referenceCode))
          break;
        case "patent":
          dispatch(getPatentListDataHandler(referenceCode, 3))
          break;
        case "publicStock":
          dispatch(getPublicStockListDataHandler(orgId, referenceCode))
          break;
        case "topTenGovPlan":
          dispatch(getTopTenGovPlanListDataHandler(referenceCode))
          break;
        default:
          return "取得 modal 時發生問題。";
      }
    }
  }, [
    // typeTwoList,
    modalType,
    referenceCode,
    orgId]);

  React.useEffect(() => {
    const { typeTwoListDataLoading } = typeTwoList;
    const { typeThreeListDataLoading } = typeThreeList;
    const { typeFourListDataLoading } = typeFourList;
    const { importListDataLoading } = importList;
    const { exportListDataLoading } = exportList;
    const { awardListDataLoading } = awardList;
    const { govPlanListDataLoading } = govPlanList;
    const { patentListDataLoading } = patentList;
    const { publicStockListDataLoading } = publicStockList;
    const { topTenGovPlanListDataLoading } = topTenGovPlan;

    if (
      !typeTwoListDataLoading &&
      !typeThreeListDataLoading &&
      !typeFourListDataLoading &&
      !importListDataLoading &&
      !exportListDataLoading &&
      !awardListDataLoading &&
      !govPlanListDataLoading &&
      !patentListDataLoading &&
      !publicStockListDataLoading &&
      !topTenGovPlanListDataLoading
    ) {
      setState(state => ({ ...state, loading: false }))
    } else {
      setState(state => ({ ...state, loading: true }))
    }
  }, [
    typeTwoList.typeTwoListDataLoading,
    typeThreeList.typeThreeListDataLoading,
    typeFourList.typeFourListDataLoading,
    importList.importListDataLoading,
    exportList.exportListDataLoading,
    awardList.awardListDataLoading,
    govPlanList.govPlanListDataLoading,
    patentList.patentListDataLoading,
    publicStockList.publicStockListDataLoading,
    topTenGovPlan.topTenGovPlanListDataLoading
  ])

  React.useEffect(() => {
    persistModalOpenHandler(state.loading) //當資料在 load 的時候不要讓 modal 關掉
  }, [state])

  return (
    <div className="dashboard-modal-content-container">
      {state.loading ?
        <Spinner /> :
        <>
          <div className="dashboard-modal-content-container-head">
            {(modalType === "typeTwo" || modalType === "typeThree") && <h2>{title}廠商資料</h2>}
            {modalType === "typeFour" && <h2><span onClick={() => openNewTabs(`${url}/industry_index/${title.substring(0, 4)}`)}>{title.substring(0, 4)}</span>{title.substring(4, title.length)}廠商資料</h2>}
            {modalType === "import" && <h2>{title}進口級距廠商資料</h2>}
            {modalType === "export" && <h2>{title}出口級距廠商資料</h2>}
            {modalType === "award" && <h2>{title}廠商資料</h2>}
            {modalType === "govPlan" && <h2>{title}廠商資料</h2>}
            {modalType === "patent" && <h2><span onClick={() => openNewTabs(`${url}/factory_info/basic_info/${referenceCode}`)}>{title}</span>專利資料</h2>}
            {modalType === "topTenGovPlan" && <h2><span onClick={() => openNewTabs(`${url}/factory_info/basic_info/${referenceCode}`)}>{title}</span>政府計劃資料</h2>}
            {modalType === "publicStock" && <h2>{title}公司列表</h2>}
            {modalType === "sectionNewsTag" && <h2>{title} Google搜尋趨勢</h2>}
            <Icon className="icon" onClick={handleClose}>
              close
            </Icon>
          </div>
          {modalType === "typeTwo" && <TypeTable data={typeTwoList.typeTwoListData} />}
          {modalType === "typeThree" && <TypeTable data={typeThreeList.typeThreeListData} />}
          {modalType === "typeFour" && <TypeTable data={typeFourList.typeFourListData} />}
          {modalType === "import" && <ImportAndExportTable data={importList.importListData} selectedOrg={selectedOrg} />}
          {modalType === "export" && <ImportAndExportTable data={exportList.exportListData} selectedOrg={selectedOrg} />}
          {modalType === "award" && <GovPlanAndAwardTable data={awardList.awardListData} />}
          {modalType === "govPlan" && <GovPlanAndAwardTable data={govPlanList.govPlanListData} />}
          {modalType === "patent" && <PatentTable data={patentList.patentListData} />}
          {modalType === "publicStock" && <PublciStockTable data={publicStockList.publicStockListData} />}
          {modalType === "topTenGovPlan" && <TopTenGovPlan data={topTenGovPlan.topTenGovPlanListData} />}
          {modalType === "sectionNewsTag" && (
              <GoogleTrends keyword = {title}/>
          )}
        </>
      }
    </div>
  );
};
