import React from 'react'

import './Error.css'

const Error = props => {
    

    return(
        <div className='error'>網路連線問題</div>
    )
}


export default  Error