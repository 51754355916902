import styled from "styled-components";


export const Footer = styled.div`
    position: ${ p => p.isFixed ? "fixed" : "static" };
    width: 100%;
    left: 0;
    bottom: 0;
    background-color: var(--main-color);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0px;
    z-index: 100000;

    @media all and (device-width: 1180px) and (device-height: 820px) and (orientation:landscape) {
        padding-bottom: 40px;
    }

    @media only screen and (min-width: 1366px) and (orientation: landscape){
        padding-bottom: 40px;
    }
    
    @media (max-width: 1024px) {
        display: none;
    }

`

export const FooterText = styled.p`
    color:#FFFFFF;
    font-size:  0.875rem;
    >span{
        font-size: 0.5rem;
    }
`