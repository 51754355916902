import React from 'react';
import styled from "styled-components";
import { useSelector } from "react-redux";
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import CircularProgress from '@mui/material/CircularProgress';
import Menu from "@mui/icons-material/Menu";
import Search from "@mui/icons-material/Search";


import * as theme from "../../assets/theme"
//會有三種狀況手機版和電腦版選單開著和選單關著。

const KeywordInput = ({ handleFetchData, toggleDetailMenu, toggleDialogHandler, controlledSearchHandler, noHelperText, loading, handleUpdateProps, propsState }) => {
  const [state, setState] = React.useState({
    KeyWord: ""
  });

  const handleUpdateField = (e) => {
    const { name, value } = e.target;
    handleUpdateProps(name, value);
  }

  return (
    <Inputs
      variant="outlined"
      size="small"
      placeholder="請輸入清理廢棄物名稱或編號"
      helperText={noHelperText ? "" : "最多三間，可透過 ' , ' 隔開。"}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <MenuIcon onClick={toggleDetailMenu} />
          </InputAdornment>
        ),
        endAdornment: noHelperText ? (
          <InputAdornment position="end">
            {loading ? <Loading /> : <SearchIcon notAllowed={!state.KeyWord}/>}
          </InputAdornment>
        ) : null
      }}
      value={propsState["key_word"]}
      onChange={(e) => handleUpdateField(e)}
      name="key_word"
      onKeyPress={(e) => { 
        if(e.key === "Enter"){
          handleFetchData()
        }
      }}
    />
  )
};

const Loading = styled(CircularProgress)`
  width: 30px !important; 
  height: 30px !important;
  color: ${theme.primaryColor} !important 
`

const SearchIcon = styled(Search)`
    cursor: ${p=> p.notAllowed ? "not-allowed" : "pointer"};
    transition: 0.2s;

    &:hover{
        color: ${theme.primaryColor};
        transition: 0.2s;
    }
`

const MenuIcon = styled(Menu)`
    cursor: pointer;
    transition: 0.2s;

    &:hover{
        color: ${theme.black};
        transition: 0.2s;
    }
`

const Inputs = styled(TextField)`
    width: 90%;
    background-color: ${theme.white};
    .MuiOutlinedInput-root{
        .MuiOutlinedInput-notchedOutline{
            >legend{
                display: none;
            }
        }
    }

    .MuiOutlinedInput-root.Mui-focused{
        .MuiOutlinedInput-notchedOutline{
            border-color: ${theme.primaryColor};
        }
    }

    @media(max-width: 790px){
      width: 100%;
    }
`
export default KeywordInput;