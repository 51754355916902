import * as actionType from "../actionTypes/taxInfo";
import { taxInfoSearchHandler, exportProductFourCodeListHandler, TaxIndustryListHandler, TypeTwoListHandler, TypeThreeListHandler } from "../../utility/taxInfoApi";

//搜尋稅籍廠商基本資料
const startTaxInfoSearch = () => {
  return {
    type: actionType.START_TAX_INFO_SEARCH,
  };
};

const fetchTaxInfoSearchSuccess = (data) => {
  return {
    type: actionType.FETCH_TAX_INFO_SEARCH_SUCCESS,
    data: data,
  };
};

const fetchTaxInfoSearchFail = (state) => {
  return {
    type: actionType.FETCH_TAX_INFO_SEARCH_FAIL,
  };
};

export const handleTaxInfoSearch = (state) => {
  return (dispatch) => {
    dispatch(startTaxInfoSearch());
    taxInfoSearchHandler(state.keyWordList, state.minCapital, state.maxCapital, state.operator, state.productFourCode, state.taxCode, state.industryTwoCode, state.industryThreeCode, state.industryCode, state.cityCode)
      .then((res) => {
        if (res !== "error") {
          dispatch(fetchTaxInfoSearchSuccess(res.data));
        } else {
          dispatch(fetchTaxInfoSearchFail("稅籍資料搜尋發生錯誤。"));
        }
      })
      .catch((err) => {
        dispatch(fetchTaxInfoSearchFail(err));
      });
  };
};

//出口產品四碼清單
const startExportProductFourCodeList = () => {
  return {
    type: actionType.START_EXPORT_PRODUCT_FOUR_CODE_LIST,
  };
};

const fetchExportProductFourCodeListSuccess = (data) => {
  return {
    type: actionType.FETCH_EXPORT_PRODUCT_FOUR_CODE_LIST_SUCCESS,
    data: data,
  };
};

const fetchExportProductFourCodeListFail = (state) => {
  return {
    type: actionType.FETCH_EXPORT_PRODUCT_FOUR_CODE_LIST_FAIL,
  };
};

export const handleExportProductFourCodeList = () => {
  return (dispatch) => {
    dispatch(startExportProductFourCodeList());
    exportProductFourCodeListHandler()
      .then((res) => {
        if (res !== "error") {
          dispatch(fetchExportProductFourCodeListSuccess(res.data));
        } else {
          dispatch(fetchExportProductFourCodeListFail("出口產品四碼清單發生錯誤。"));
        }
      })
      .catch((err) => {
        dispatch(fetchExportProductFourCodeListFail(err));
      });
  };
};

//稅籍產業選單
const startTaxIndustryList = () => {
  return {
    type: actionType.START_TAX_INDUSTRY_LIST,
  };
};

const fetchTaxIndustryListSuccess = (data) => {
  return {
    type: actionType.FETCH_TAX_INDUSTRY_LIST_SUCCESS,
    data: data,
  };
};

const fetchTaxIndustryListFail = (state) => {
  return {
    type: actionType.FETCH_TAX_INDUSTRY_LIST_FAIL,
  };
};

export const handleTaxIndustryList = () => {
  return (dispatch) => {
    dispatch(startTaxIndustryList());
    TaxIndustryListHandler()
      .then((res) => {
        if (res !== "error") {
          dispatch(fetchTaxIndustryListSuccess(res.data));
        } else {
          dispatch(fetchTaxIndustryListFail("稅籍產業選單發生錯誤。"));
        }
      })
      .catch((err) => {
        dispatch(fetchTaxIndustryListFail(err));
      });
  };
};

//工廠二碼選單
const startTypeTwoList = () => {
  return {
    type: actionType.START_TYPE_TWO_LIST,
  };
};

const fetchTypeTwoListSuccess = (data) => {
  return {
    type: actionType.FETCH_TYPE_TWO_LIST_SUCCESS,
    data: data,
  };
};

const fetchTypeTwoListFail = (state) => {
  return {
    type: actionType.FETCH_TYPE_TWO_LIST_FAIL,
  };
};

export const handleTypeTwoList = () => {
  return (dispatch) => {
    dispatch(startTypeTwoList());
    TypeTwoListHandler()
      .then((res) => {
        if (res !== "error") {
          dispatch(fetchTypeTwoListSuccess(res.data));
        } else {
          dispatch(fetchTypeTwoListFail("工廠二碼選單發生錯誤。"));
        }
      })
      .catch((err) => {
        dispatch(fetchTypeTwoListFail(err));
      });
  };
};

//工廠三碼選單
const startTypeThreeList = () => {
  return {
    type: actionType.START_TYPE_THREE_LIST,
  };
};

const fetchTypeThreeListSuccess = (data) => {
  return {
    type: actionType.FETCH_TYPE_THREE_LIST_SUCCESS,
    data: data,
  };
};

const fetchTypeThreeListFail = (state) => {
  return {
    type: actionType.FETCH_TYPE_THREE_LIST_FAIL,
  };
};

export const handleTypeThreeList = () => {
  return (dispatch) => {
    dispatch(startTypeThreeList());
    TypeThreeListHandler()
      .then((res) => {
        if (res !== "error") {
          dispatch(fetchTypeThreeListSuccess(res.data));
        } else {
          dispatch(fetchTypeThreeListFail("工廠三碼選單發生錯誤。"));
        }
      })
      .catch((err) => {
        dispatch(fetchTypeThreeListFail(err));
      });
  };
};
