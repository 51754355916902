import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import FullPage from "../../../components/FullPage/FullPage";
import Spinner from "../../../components/Spinner/Spinner";
import SearchHistoryContainer from "./SearchHistoryContainer";

import serachPhoto from "../../../assests/bill-oxford--fGqsewtsJY-unsplash.jpg";

import { getFacNo, getCompanyListByBusinessNo } from "../../../utility/api";

import "./FactorySearch.css";
import "./styles.scss";

const MySwal = withReactContent(Swal);

const FactorySearch = (props) => {
  const url = window.location.href.split("/");
  const [businessId, setBusinessId] = useState("");
  const [option, setOption] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isSearched, setIsSearched] = useState(false);
  const [state, setState] = React.useState({
    searchType: "CompanyName",
    directUrl: "",
    business_name: "",
  });
  const REGEX_PINYIN =
    /[ㄅ]|[ㄆ]|[ㄇ]|[ㄈ]|[ㄉ]|[ㄊ]|[ㄋ]|[ㄌ]|[ㄍ]|[ㄎ]|[ㄏ]|[ㄐ]|[ㄑ]|[ㄒ]|[ㄓ]|[ㄘ]|[ㄕ]|[ㄖ]|[ㄚ]|[ㄛ]|[ㄝ]|[ㄞ]|[ㄟ]|[ㄠ]|[ㄡ]|[ㄢ]|[ㄣ]|[ㄤ]|[ㄥ]|[ㄦ]/u;
  const REGEX_COMMA = /,/u;
  const hasPinyin = (str) => REGEX_PINYIN.test(str);
  const hasComma = (str) => REGEX_COMMA.test(str);
  const inputRef = useRef();

  useEffect(() => {
    setTimeout(() => {
      if (state.searchType === "CompanyName") {
        if (!hasPinyin(inputValue) && !hasComma(inputValue) && inputRef.current) {
          if (inputValue === inputRef.current.value) {
            if (typeof inputValue === "string" && inputValue.length >= 2) {
              setIsLoading(true);
              getFacNo(inputValue).then((res) => {
                setIsSearched(true);
  
                if (res !== "error") {
                  setOption(res);
                  setIsLoading(false);
                } else {
                  setOption([]);
                }
              });
            } else {
              setOption([]);
            }
          }
        }
      } else if (state.searchType === "BusinessNo") {
        if (inputValue === inputRef.current.value) {
          if (!isNaN(inputValue) && inputValue.length >= 2) {
            setIsLoading(true);
            getCompanyListByBusinessNo(inputValue).then((res) => {
              setIsSearched(true);
              if (res !== "error") {
                setOption(res);
                setIsLoading(false);
              } else {
                setOption([]);
              }
            });
          } else {
            setOption([]);
          }
        }
      }
      
    }, 1000);
  }, [inputValue, inputRef, state.searchType]);

  // 更換 searchType 時清空 input
  useEffect(() => {
    setInputValue("");
  }, [state.searchType]);

  useEffect(() => {
    if (inputValue === "") {
      setIsSearched(false);
    }
  }, [inputValue]);

  const searchNext = () => {
    if (!inputValue) {
      MySwal.fire({
        text: "請輸入欲搜尋的公司",
      }).then(() => {});
    } else if (businessId === "") {
      MySwal.fire({
        text: "請選擇至少一間公司",
      }).then(() => {});
    } else {
      if(state.business_name){
        window.open(
          url[0] +
            "//" +
            url[2] +
            `/factory_info/${state.directUrl}/` +
            businessId + "-" + state.business_name,
          "_blank"
        );
      }else{
        window.open(
          url[0] +
            "//" +
            url[2] +
            `/factory_info/${state.directUrl}/` +
            businessId,
          "_blank"
        );
      }

    }
  };



  const inputHandler = (event) => {
    const keyword = event.target.value;
    setInputValue(keyword);
    setBusinessId("");
    setState(state =>({ ...state, business_name: "" }));
  };

  const setInputHandler = (value, businessId, business_name) => {
    setBusinessId(businessId);
    setState(state =>({ ...state, business_name: business_name }));
    setInputValue(value);
    getFacNo(businessId).then((res) => {
      setIsSearched(true);
      if (res !== "error") {
        setOption(res);
      } else {
        setOption([]);
      }
    });
  };

  const clearInput = () => {
    setBusinessId("");
    setOption([]);
    setInputValue("");
    setIsLoading(false);
    setState(state =>({ ...state, business_name: "" }));

    inputRef.current.focus();
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && state.business_name) {
      searchNext()
    }
};

  let selection;

  if (isLoading) {
    selection = (
      <div className="spinner">
        <Spinner />
      </div>
    );
  } else {
    if (isSearched && option.length === 0 && inputValue) {
      selection = (
        <div className="option-item-even">
          <span>查無資料</span>
        </div>
      );
    } else {
      selection = option.map((option, index) => (
        <div
          className={index % 2 === 0 ? "option-item-even" : "option-item-odd"}
          onClick={() => {
            setState((state) => ({
              ...state,
              directUrl:
                option.isEdited === 1 ? "updated_companyinfo" : "basic_info",
            }));
            setInputHandler(
              `${option.BusinessNo}, ${option.Name}`,
              option.BusinessNo,
              option.Name
            );
            selection = null;
            inputRef.current.focus();
          }}
        >
          <span>
            {option.BusinessNo}, {option.Name}
          </span>
        </div>
      ));
    }
  }
  return (
    <div>
      <div className="factory-serach">
        <FullPage
          bgPhoto={serachPhoto}
          title="個廠情資查詢"
          note={
            <div>
              <h2 className="title-header">查詢資料範圍說明:</h2>
              <p>全國具公司登記的廠商</p>
            </div>
          }
        >
          <FormControl>
            <RadioGroup
              row
              name="row-radio-buttons-group"
              value={state.searchType}
              onChange={(e) => {
                setState(state => ({ ...state, searchType: e.target.value}))
              }}
            >
              <FormControlLabel value="CompanyName" control={<Radio size="small" />} label="廠商名稱關鍵字" />
              <FormControlLabel value="BusinessNo" control={<Radio size="small" />} label="統一編號" />
            </RadioGroup>
          </FormControl>
          <TextField
            className="search-input"
            variant="outlined"
            size="small"
            placeholder={state.searchType === "CompanyName" ? "請輸入廠商名稱" : "請輸入廠商統編"}
            InputProps={
              inputValue
                ? {
                    endAdornment: (
                      <InputAdornment position="start" onClick={clearInput}>
                        <Icon>close</Icon>
                      </InputAdornment>
                    ),
                  }
                : null
            }
            value={inputValue}
            inputRef={inputRef}
            onChange={inputHandler}
            onKeyDown={handleKeyDown}
            autoFocus
          />
          <input
            className="search-input"
            value={inputValue}
            // ref={inputRef}
            placeholder="統一編號或廠商名稱"
            onChange={inputHandler}
            style={{ visibility: "hidden", height: "0px" }}
          />
          {!businessId && <div className="selection-content">{selection}</div>}
          <button
            className={businessId ? "active-button" : "inactive-button"}
            onClick={searchNext}
          >
            搜尋
          </button>
          <SearchHistoryContainer />
        </FullPage>
      </div>
    </div>
  );
};

export default FactorySearch;
