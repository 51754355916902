import React from "react";
import * as echarts from "echarts";
import "echarts-wordcloud";
import NoData from "../../components/Nodata/NoData";
import * as Style from "./styles";

export default (props) => {
  const { tag, eventHandler } = props;

  React.useEffect(() => {

    var chart = echarts.init(document.getElementById("cloud_main"));
    var maskImage = new Image();
    maskImage.src = require("../../assets/images/circle.png");

    var tagJson = [];

    if (tag.length > 0) {
      const renameKey = (obj, oldKey, newKey) => {
        obj[newKey] = obj[oldKey];
        delete obj[oldKey];
      };

      tagJson = JSON.parse(JSON.stringify(tag));
      tagJson.forEach((obj) => renameKey(obj, "value", "name"));
      tagJson.forEach((obj) => renameKey(obj, "count", "value"));
    }

    var option = {
      tooltip: {},

      series: [
        {
          type: "wordCloud",
          keepAspect: true,
          sizeRange: [18, 50],
          rotationRange: [0, 0],
          shape: "circle",
          maskImage: maskImage,
          top: 10,
          width: document.getElementById("box__text")?.offsetWidth,
          height: tag.length > 0 ? 300 : 100,
          gridSize: window.innerWidth <= 1024 ? 5 : 8,
          layoutAnimation: true,
          drawOutOfBound: false,
          textStyle: {
            fontFamily: "sans-serif",
            fontWeight: "bold",
            color: function () {
              return (
                "rgb(" +
                [
                  Math.round(parseFloat("0." + crypto.getRandomValues(new Uint32Array(1))[0]) * 300),
                  Math.round(parseFloat("0." + crypto.getRandomValues(new Uint32Array(1))[0]) * 200),
                  Math.round(parseFloat("0." + crypto.getRandomValues(new Uint32Array(1))[0]) * 150),
                ].join(",") +
                ")"
              );
            },
          },
          emphasis: {
            textStyle: {
              shadowBlur: 10,
              shadowColor: "#333",
            },
          },
          data: tagJson,
        },
      ],
    };

    maskImage.onload = function () {
      chart.setOption(option);
    }

    window.onresize = chart.resize;

    chart.on("click", (params) => {
      eventHandler(params.value, params.name);
    });
  }, [tag.length > 0]);

  return (
    <Style.CloudContainer>
      {window.innerWidth > 1024 && tag.length > 0 && <Style.CloudIcon />}
      {tag.length > 0 ? (
        <Style.CloudMain id="cloud_main" />
      ) : (
        <Style.CloudMain id="cloud_main" nodata />
      )}
      {tag.length === 0 && <NoData />}
    </Style.CloudContainer>
  );
};
