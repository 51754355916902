import axios from "axios";
import { baseUrl, loginbaseUrl } from "../constants/endpoints";
import { Base64 } from "js-base64";

const headers = {
  "Access-Control-Allow-Origin": "*",
  "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
  "Access-Control-Allow-Headers":
    "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers",
  "Content-Type": "application/json",
  Authorization: localStorage.getItem("acToken"),
};

//取得儀表板資訊
export const getSectionMap = (uid) => {
  const getSectionData = new FormData();
  getSectionData.append("account", uid);
  return axios({
    proxy: true,
    method: "post",
    url: `${loginbaseUrl}/api/dashboardctrl/getdashboardctrlrec/`,
    data: getSectionData,
    // headers: headers,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return [];
    });
};

//修改儀表板資訊
export const updateSectionMapHandler = (data) => {
  const uid = localStorage.getItem("uid");

  const updateSectionData = new FormData();
  updateSectionData.append("Order", data.Order);
  updateSectionData.append("isOpen", !data.isOpen);
  updateSectionData.append("title", data.title);
  updateSectionData.append("key", data.key);
  updateSectionData.append("type", data.type);
  updateSectionData.append("category", data.category);
  updateSectionData.append("account", uid);

  return axios({
    method: "post",
    url: `${loginbaseUrl}/api/dashboardctrl/updatedashboardctrlrec/`,
    data: updateSectionData,
    //headers: headers,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return [];
    });
};

export const fetchCompanyAssociation = (businessId) => {
  return axios({
    proxy: true,
    method: "get",
    url: `${baseUrl}/api/BoardMember/GetCompanyAssociation/${businessId}`,
    headers: headers,
  })
    .then((res) => {
      try {
        return res.data;
      } catch (e) {
        return res.data;
      }
    })
    .catch((err) => {
      return "error";
    });
};

export const fetchBriefAndRankingData = (parkId) => {
  return axios({
    proxy: true,
    method: "get",
    url: `${baseUrl}/api/InduParkSingle/GetOverviewRank/${parkId}`,
    headers: headers,
  })
    .then((res) => {
      try {
        return res.data;
      } catch (e) {
        return res.data;
      }
    })
    .catch((err) => {
      return "error";
    });
};

export const fetchFactoryAreaRentData = (parkId) => {
  return axios({
    method: "get",
    url: `${baseUrl}/api/InduParkSingle/GetFactoryAreaRent/${parkId}`,
    headers: headers,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return "error";
    });
};

export const fetchServiceCenterParkLinkData = (parkId) => {
  return axios({
    method: "get",
    url: `${baseUrl}/api/InduParkSingle/GetServiceCenterParkLink/${parkId}`,
    headers: headers,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return "error";
    });
};

//單一園區 主要指標
export const fetchSectionIndex = (parkId) => {
  return axios({
    proxy: true,
    method: "get",
    url: `${baseUrl}/api/InduParkSingle/GetMainIndex/${parkId}/`,
    headers: headers,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return [];
    });
};

//單一園區 新聞標籤
export const fetchSectionNewsTag = (parkId) => {
  return axios({
    proxy: true,
    method: "get",
    url: `${baseUrl}/api/InduParkSIngle/GetNewsTagCloud/${parkId}`,
    headers: headers,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return [];
    });
};

//單一園區 新聞內容
export const fetchSectionNewsContent = (parkId, item, pagination) => {
  return axios({
    proxy: true,
    method: "get",
    url: `${baseUrl}/api/InduParkSIngle/GetNewsItems/${parkId}/100/1`, //pagination?????????????
    headers: headers,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return [];
    });
};

//單一園區 股票公開發行廠商家數占比
//
export const fetchSectionPublicSharing = (parkId) => {
  return axios({
    proxy: true,
    method: "get",
    url: `${baseUrl}/api/InduParkSIngle/GetPieChartByStockType_CanvasJS/${parkId}`,
    headers: headers,
  })
    .then((res) => {
      return res.data.data[0].dataPoints;
    })
    .catch((err) => {
      return [];
    });
};

//單一園區 股票公開發行前十大
export const fetchSectionPublic = (parkId) => {
  const form = new FormData();
  form.append("ParkIDList", parkId);
  form.append("MinCapital", 20000000);
  form.append("MaxCapital", 1000000000);
  form.append("MinTradeExport", 4000000);

  return axios({
    method: "post",
    url: `${baseUrl}/api/PotentialSearch/SearchParkRange`,
    headers: headers,
    data: form,
  })
    .then((res) => {
      return res.data.data;
    })
    .catch((err) => {
      return err;
    });
};

//單一園區 綠色工廠前十大廠商
export const fetchSectionPublicTopTenGreen = (parkId) => {
  return axios({
    proxy: true,
    method: "get",
    url: `${baseUrl}/api/InduParkSIngle/GetTopCompany_Green/${parkId}`,
    headers: headers,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return [];
    });
};

//單一園區 進口級距佔比-圓餅圖 (透過 true 跟 false 確認進出口)
export const fetchSectionImportPercentage = (parkId) => {
  return axios({
    proxy: true,
    method: "get",
    url: `${baseUrl}/api/InduParkSIngle/GetPieChartByForeignTrade_CanvasJS/${parkId}/true`,
    headers: headers,
  })
    .then((res) => {
      return res.data.data[0].dataPoints;
    })
    .catch((err) => {
      return [];
    });
};

//單一園區 出口級距佔比-圓餅圖 (透過 true 跟 false 確認進出口)
export const fetchSectionExportPercentage = (parkId) => {
  return axios({
    proxy: true,
    method: "get",
    url: `${baseUrl}/api/InduParkSIngle/GetPieChartByForeignTrade_CanvasJS/${parkId}/false`,
    headers: headers,
  })
    .then((res) => {
      return res.data.data[0].dataPoints;
    })
    .catch((err) => {
      return [];
    });
};

//單一園區 產業別2碼家數佔比-圓餅圖
export const fetchTwoCodePercentage = (parkId) => {
  return axios({
    proxy: true,
    method: "get",
    url: `${baseUrl}/api/InduParkSIngle/GetPieChartByInduTypeTwo_CanvasJS/${parkId}`,
    headers: headers,
  })
    .then((res) => {
      return res.data.data[0].dataPoints;
    })
    .catch((err) => {
      return [];
    });
};

//單一園區 產業別3碼家數佔比-圓餅圖
export const fetchThreeCodePercentage = (parkId) => {
  return axios({
    proxy: true,
    method: "get",
    url: `${baseUrl}/api/InduParkSIngle/GetPieChartByInduTypeThree_CanvasJS/${parkId}`,
    headers: headers,
  })
    .then((res) => {
      return res.data.data[0].dataPoints;
    })
    .catch((err) => {
      return [];
    });
};

//單一園區 產業別4碼家數占比-圓餅圖
export const fetchFourCodePercentage = (parkId) => {
  return axios({
    proxy: true,
    method: "get",
    url: `${baseUrl}/api/InduParkSIngle/GetPieChartByInduTypeFour_CanvasJS/${parkId}`,
    headers: headers,
  })
    .then((res) => {
      return res.data.data[0].dataPoints;
    })
    .catch((err) => {
      return [];
    });
};

//單一園區 資本額前十大廠商排行-長條圖
export const fetchSectionTopTenCapital = (parkId) => {
  return axios({
    proxy: true,
    method: "get",
    url: `${baseUrl}/api/InduParkSIngle/GetColumnChartByCapital_CanvasJS/${parkId}`,
    headers: headers,
  })
    .then((res) => {
      return res.data.data[0].dataPoints;
    })
    .catch((err) => {
      return [];
    });
};

//單一園區 政府計劃前十大廠商
export const fetchSectionTopTenGovernmentPlan = (parkId) => {
  return axios({
    proxy: true,
    method: "get",
    url: `${baseUrl}/api/InduParkSIngle/GetColumnChartByGovPlan_CanvasJS/${parkId}`,
    headers: headers,
  })
    .then((res) => {
      return res.data.data[0].dataPoints;
    })
    .catch((err) => {
      return [];
    });
};

//單一園區 專利數前十大-長條圖
export const fetchSectionTopTenPatent = (parkId) => {
  return axios({
    proxy: true,
    method: "get",
    url: `${baseUrl}/api/InduParkSIngle/GetColumnChartByPatent_CanvasJS/${parkId}`,
    headers: headers,
  })
    .then((res) => {
      return res.data.data[0].dataPoints;
    })
    .catch((err) => {
      return [];
    });
};

//單一園區 員工數前十大

export const fetchSectionTopTenEmp = (parkId) => {
  return axios({
    proxy: true,
    method: "get",
    url: `${baseUrl}/api/InduParkSIngle/GetColumnChartByEmpCount_CanvasJS/${parkId}`,
    headers: headers,
  })
    .then((res) => {
      return res.data.data[0].dataPoints;
    })
    .catch((err) => {
      return [];
    });
};

//單一園區 政府計畫數類型佔比
export const fetchSectionGovPlanPercent = (parkId) => {
  return axios({
    proxy: true,
    method: "get",
    url: `${baseUrl}/api/InduParkSIngle/GetPieChartByGovPlan_CanvasJS/${parkId}`,
    headers: headers,
  })
    .then((res) => {
      return res.data.data[0].dataPoints;
    })
    .catch((err) => {
      return [];
    });
};

//單一園區 獲獎數類型佔比
export const fetchSectionAwardPercent = (parkId) => {
  return axios({
    proxy: true,
    method: "get",
    url: `${baseUrl}/api/InduParkSIngle/GetPieChartByAward_CanvasJS/${parkId}`,
    headers: headers,
  })
    .then((res) => {
      return res.data.data[0].dataPoints;
    })
    .catch((err) => {
      return [];
    });
};

//單一園區 專利數占比-圓餅圖
export const fetchSectionPatentPercent = (parkId) => {
  return axios({
    proxy: true,
    method: "get",
    url: `${baseUrl}/api/InduParkSIngle/GetPieChartByPatent_CanvasJS/${parkId}?`,
    headers: headers,
  })
    .then((res) => {
      return res.data.data[0].dataPoints;
    })
    .catch((err) => {
      return [];
    });
};

//依據新聞標籤取得新聞資料
export const fetchContentViaNewsTag = (tag) => {
  return axios({
    proxy: true,
    method: "get",
    url: `${baseUrl}/api/News/GetNewsByKeywords/${encodeURI(tag)}/2019-11-09`,
    headers: headers,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return [];
    });
};

//產品搜尋地圖
export const fetchMapSearch = (data) => {
  const header = {
    Authorization: localStorage.getItem("acToken"),
    "Content-Type": "application/x-www-form-urlencoded",
  };

  return axios({
    proxy: true,
    method: "post",
    url: `${baseUrl}/api/ProductSearch/Search`,
    data: data,
    headers: header,
  })
    .then((res) => {
      const updateMapMarkerList = [];
      res.data.data.map((marker) =>
        updateMapMarkerList.push({
          position: [marker.latitude, marker.longitude],
          key: marker.businessNo,
          marker: marker,
        })
      );
      return updateMapMarkerList;
    })
    .catch((err) => {
      return "error";
    });
};

//取得廢棄物前5大排行
export const fetchTopFiveWasteDisposal = (parkId) => {
  return axios({
    proxy: true,
    method: "get",
    url: `${baseUrl}/api/InduParkSingle/GetPollutionRank/Waste/${parkId}`,
    headers: headers,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return "error";
    });
};

// ====================== MODAL ======================
// ===================================================
// ===================================================
// ===================================================
// ===================================================
// ===================================================
// ===================================================

//單一園區 產業別2碼家數佔比-Modal
export const fetchSectionTwoCodePercentageList = (parkId, params) => {
  return axios({
    proxy: true,
    method: "get",
    url: `${baseUrl}/api/InduParkSIngle/GetCompanyList_InduTypeTwo/${parkId}/${params}`,
    headers: headers,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return [];
    });
};

//單一園區 產業別3碼家數佔比-Modal
export const fetchSectionThreeCodePercentageList = (parkId, params) => {
  return axios({
    proxy: true,
    method: "get",
    url: `${baseUrl}/api/InduParkSIngle/GetCompanyList_InduTypeThree/${parkId}/${params}`,
    headers: headers,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return [];
    });
};

//單一園區 產業別4碼家數占比-Modal
export const fetchSectionFourCodePercentageList = (parkId, params) => {
  return axios({
    proxy: true,
    method: "get",
    url: `${baseUrl}/api/InduParkSIngle/GetCompanyList_InduTypeFour/${parkId}/${params}`,
    headers: headers,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return [];
    });
};

//單一園區 進口級距佔比-清單-Modal
export const fetchSectionImportList = (parkId, params) => {
  return axios({
    proxy: true,
    method: "get",
    url: `${baseUrl}/api/InduParkSIngle/GetCompanyList_ForeignTrade/${parkId}/true/${params}`,
    headers: headers,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return [];
    });
};

//單一園區 出口級距佔比-清單-Modal
export const fetchSectionExportList = (parkId, params) => {
  return axios({
    proxy: true,
    method: "get",
    url: `${baseUrl}/api/InduParkSIngle/GetCompanyList_ForeignTrade/${parkId}/false/${params}?`,
    headers: headers,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return [];
    });
};

//專利資料-Modal
export const fetchSectionPatentInfoList = (businessId, year) => {
  return axios({
    proxy: true,
    method: "get",
    url: `${baseUrl}/api/Patent/GetPatents/${businessId}/${year}`,
    headers: headers,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return [];
    });
};

//政府計畫-Modal
export const fetchSectionGovPlanList = (businessId) => {
  return axios({
    proxy: true,
    method: "get",
    url: `${baseUrl}/api/GovPlan/GetGovPlans/${businessId}`,
    headers: headers,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return [];
    });
};

//取得園區廠商政府計畫類型佔比-Modal
export const fetchSectionParkDetailInfoList = (parkId, labelName) => {
  const updateLabelName = Base64.encode(labelName)
    .replace(/[+]/g, "-")
    .replace(/[/]/g, "_");

  return axios({
    proxy: true,
    method: "get",
    url: `${baseUrl}/api/InduParkSIngle/GetCompanyListByGovPlanOrAward/${parkId}/${updateLabelName}/false`,
    headers: headers,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return [];
    });
};

//園區廠商獲獎類型佔比資料-Modal
export const fetchSectionAwardList = (parkId, labelName) => {
  const updateLabelName = Base64.encode(labelName)
    .replace(/[+]/g, "-")
    .replace(/[/]/g, "_");

  return axios({
    proxy: true,
    method: "get",
    url: `${baseUrl}/api/InduParkSIngle/GetCompanyListByGovPlanOrAward/${parkId}/${updateLabelName}/true`,
    headers: headers,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return [];
    });
};

//取得廢棄物廠商追溯表-Modal
export const fetchWasteDisposalList = (wasteNumber, parkId) => {
  return axios({
    proxy: true,
    method: "get",
    url: `${baseUrl}/api/InduParkSingle/GetPollutionRankList/Waste/${wasteNumber}/${parkId}`,
    headers: headers,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return "error";
    });
};

//公開發行狀況佔比-Modal
export const fetchSectionStockList = (parkId, stockType) => {
  return axios({
    proxy: true,
    method: "get",
    url: `${baseUrl}/api/InduParkSIngle/GetCompanyList_Stock/${parkId}/${stockType}`,
    headers: headers,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return "error";
    });
};
