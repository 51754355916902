import React, { useState } from "react";
import { FormControl, InputLabel, Select, MenuItem, Input, Icon } from "@material-ui/core";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import CustomizedModal from '../CustomizedModal/CustomizedModal';
import { ForwardProject, UpdateProjectHandleStatus } from "../../utility/api";
import Spinner from '../../components/Spinner/Spinner';

import "./styles.scss"
const MySwal = withReactContent(Swal);



const ForwardProjectInput = props => {
    const { isOpen, handleClose, caseId, handleHistroy, forwardCaseHeader } = props;
    const [name, setName] = useState();
    const [email, setEmail] = useState();
    const [position, setPosition] = useState();
    const [unit, setUnit] = useState();
    const [status, setStatus] = useState("fill");

    const onChangeHandler = (event) => {
        const { value, name } = event.target

        if(name === "name"){
            setName(value)
        } else if(name === "email"){
            setEmail(value)
        } else if (name === "position") {
            setPosition(value)
        } else if (name === "unit") {
            setUnit(value)
        }

    }

    const confirmForward = () => {
      if (!name) {
        MySwal.fire({
          text: "請填寫專家名稱",
        });
        return;
      };
      if (!email) {
        MySwal.fire({
          text: "請填寫 Email。",
        });
        return;
      };
      if (!position) {
        MySwal.fire({
          text: "請填寫職稱。",
        });
        return;
      }
      if (!unit) {
        MySwal.fire({
          text: "請填寫專家服務單位",
        });
        return;
      }


      MySwal.fire({
        text: `確認將案件分給${unit} ${name}`,
        showCancelButton: true,
        confirmButtonText:'確認',
        cancelButtonText:'取消'
      }).then((res)=>{
        if(res.value){
          forwardProjectHandler()
        }
      }
      )
    }

    const forwardProjectHandler = async () => {
      const data = new FormData();
      const user = localStorage.getItem("name");

        setStatus("sending");

        data.append("HandleContent", `將案件指配給${unit} ${name} ${position}, E-mail 為 ${email}`);
        data.append("Logger", user);
        data.append("Memo", "");

        const updated = await UpdateProjectHandleStatus(caseId, data);
        if (updated.data.data) {
            const form = new FormData()

            form.append("name", name);
            form.append("email", email);
            form.append("position", position);
            form.append("unit", unit);
            form.append("userid", localStorage.getItem("uid"));

            const forward = await ForwardProject(form, caseId);

            if (forward.data.status === 200) {
                setStatus("finished");
            } else {
                setStatus("error");
            }


        } else {
            setStatus("error")
        }

    }

    const inputExportHandler = event => {
      const { Name, Position, Email, Unit } = event.target.value;
      setName(Name);
      setPosition(Position);
      setEmail(Email);
      setUnit(Unit);
    }

    const closeModalHandler = e => {
        // setName("");
        // setPosition("");
        // setEmail("");
        // setUnit("");
        setStatus("fill");
        handleClose(e);
    }
    
    let content

    if(status === 'fill'){
        content = (
          <div className="forward-project-form">
            <div className="forward-project-form-close">
              <Icon className="close" onClick={closeModalHandler}>
                close
              </Icon>
            </div>
            <div className="forward-project-head">
              <h1>
                將{forwardCaseHeader.CompanyName}之案件分案給專家
              </h1>
              <h3>[案件編號：{forwardCaseHeader.ShowCaseId}]</h3>
            </div>
            <div className="forward-project-content">
              {handleHistroy && handleHistroy.length !== 0  && (
                <div className="forward-project-selection-container">
                  <FormControl
                    className="forward-project-selection"
                    variant="outlined"
                  >
                    <InputLabel className="select-label">
                      專家清單
                    </InputLabel>
                    <Select
                      value={""}
                      onChange={(event) => {inputExportHandler(event)}}
                      input={<Input type="select" />}
                      name="ParkId"
                    >
                      {(handleHistroy && handleHistroy.length !== 0) ? (handleHistroy || []).map((option) => (
                        <MenuItem value={option} key={option.Name}>
                          {option.Unit+" "+option.Name}
                        </MenuItem>
                      )): null}
                    </Select>
                  </FormControl>
                </div>
              )}
              <div className="forward-project-input">
                <label>專家姓名</label>
                <input
                  onChange={(event) => onChangeHandler(event)}
                  value={name}
                  name={"name"}
                  placeholder="請輸入專家姓名"
                />
              </div>
              <div className="forward-project-input">
                <label>專家E-mail</label>
                <input
                  onChange={(event) => onChangeHandler(event)}
                  value={email}
                  name={"email"}
                  placeholder="請輸入專家E-mail"
                />
              </div>
              <div className="forward-project-input">
                <label>專家職稱</label>
                <input
                  onChange={(event) => onChangeHandler(event)}
                  value={position}
                  name={"position"}
                  placeholder="請輸入專家職稱"
                />
              </div>
              <div className="forward-project-input">
                <label>專家單位</label>
                <input
                  onChange={(event) => onChangeHandler(event)}
                  value={unit}
                  name={"unit"}
                  placeholder="請輸入專家單位"
                />
              </div>
              <div className="forward-project-btn">
                <button className="btn" onClick={confirmForward}>
                  確認分案
                </button>
              </div>
            </div>
          </div>
        );
    }else if(status === 'sending'){
        content = (
          <div className="forward-project-loading">
            <Spinner />
          </div>
        );
    }else if(status === 'finished'){
        content = (
          <div className="forward-project-finished">
            <div className="forward-project-form-close">
              <Icon className="close" onClick={closeModalHandler}>
                close
              </Icon>
            </div>
            <div className="forward-project-form-content">
              <p>您的案件已經完成分案。</p>
            </div>
          </div>
        );
    }else if (status === "error"){
        content = (
          <div className="forward-project-finished">
            <div className="forward-project-form-close">
              <Icon className="close" onClick={closeModalHandler}>
                close
              </Icon>
            </div>
            <div className="forward-project-form-content">
              <p>
                您的案件寄送中發生網路連線問題，請重新嘗試或聯繫系統管理員。
              </p>
            </div>
          </div>
        );
    }
    
    return <CustomizedModal isOpen={isOpen}>{content}</CustomizedModal>;
};


export default ForwardProjectInput;


