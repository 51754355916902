import axios from "axios";
import { baseUrl, loginbaseUrl } from "../constants/endpoints";

export const handleFetchEffectedEvents = () => {
  return axios({
    proxy: true,
    method: "get",
    url: `${baseUrl}/api/EventSearch/EventList`,
  })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};

export const handleSearchEffectedEvents = (data) => {
  return axios({
    proxy: true,
    method: "post",
    url: `${baseUrl}/api/EventSearch/Search`,
    data: data,
  })
    .then((res) => {
      try {
        return res.data;
      } catch (e) {
        return [];
      }
    })
    .catch((err) => {
      return "error";
    });
};

// 工廠四碼 API
export const handleIndustry4CodeStat = (EventId) => {
  return axios({
    proxy: true,
    method: "get",
    url: `${baseUrl}/api/EventSearch/Industry4CodeStat/${EventId}`,
  })
    .then((res) => {
      try {
        return res.data;
      } catch (e) {
        return [];
      }
    })
    .catch((err) => {
      return "error";
    });
};

// 進出口貨品碼 API ｜TradeType：Export, Import
export const handleForeignTradeStat = (EventId, TradeType) => {
  return axios({
    proxy: true,
    method: "get",
    url: `${baseUrl}/api/EventSearch/ForeignTradeStat/${EventId}/${TradeType}`,
  })
    .then((res) => {
      try {
        return res.data;
      } catch (e) {
        return [];
      }
    })
    .catch((err) => {
      return "error";
    });
};
// 國家別 API ｜TradeType：Export, Import
export const handleCountryStat = (EventId, TradeType) => {
  return axios({
    proxy: true,
    method: "get",
    url: `${baseUrl}/api/EventSearch/CountryStat/${EventId}/${TradeType}`,
  })
    .then((res) => {
      try {
        return res.data;
      } catch (e) {
        return [];
      }
    })
    .catch((err) => {
      return "error";
    });
};
// 新聞區 API
export const handleNews = (EventId) => {
  return axios({
    proxy: true,
    method: "get",
    url: `${baseUrl}/api/EventSearch/GetNews/${EventId}`,
  })
    .then((res) => {
      try {
        return res.data;
      } catch (e) {
        return [];
      }
    })
    .catch((err) => {
      return "error";
    });
};
