import React, { useMemo } from 'react';
import styled from "styled-components";
import { Box } from "@material-ui/core";
import { ResponsiveBar } from '@nivo/bar';
import { thousandDivider } from "../../utility/common";

const BarChart = ({ chartData, keyTarget, indexBy, Unit, title }) => {
  const colorArray = ["#25758A", "#197d74", "#358C6B", "#52D9A5"];
  const parseIndex = {
    Name: "產業別",
    Code: "貨品碼",
    Country: "國家"
  }
  // const min = useMemo(() => {
  //   return Math.min(...chartData.map(item => item[keyTarget]))
  // }, [keyTarget, chartData]);
  const max = useMemo(() => {
    return Math.max(...chartData.map(item => item[keyTarget]))
  }, [keyTarget, chartData]);
  const maxString = useMemo(() => {
    return Math.max(...chartData.map(item => item[indexBy].length ))
  }, [indexBy, chartData]);
  const parseNumberValue = (unit, data, decimal) => {
    if (unit === "元" && ((data / 100000000) >= 1)) {
      return (data / 100000000).toFixed(decimal)
    } else if (unit === "元" && ((data / 1000) >= 1)) {
      return (data / 1000).toFixed(decimal)
    } else if (unit === "千元" && ((data / 100000) >= 1)) {
      return (data / 100000).toFixed(decimal)
    }
    return data;
  }
  const parseUnit = (unit, data) => {
    if (unit === "元" && ((data / 100000000) >= 1)) {
      return "億元";
    } else if (unit === "元" && ((data / 1000) >= 1)) {
      return "千元"
    } else if (unit === "千元" && ((data / 100000) >= 1)) {
      return "億元";
    }
    return unit;
  }
  return (<div style={{ height: 200 }}>
    <ResponsiveBar
      data={chartData}
      role="application"
      isFocusable={true}
      ariaLabel={`${title} 長條圖表`}
      barAriaLabel={e => e.indexValue + e.formattedValue + Unit}
      groupMode="grouped"
      keys={keyTarget}
      indexBy={indexBy}
      padding={0.4}
      margin={{
        top: 10,
        bottom:  (50 + maxString * 3),
        left: 50,
        right: 40
      }}
      valueScale={{ type: "linear" }}
      colors={colorArray}
      colorBy="index"
      animate={true}
      enableLabel={false}
      axisTop={null}
      axisRight={null}
      tooltip={(point) => {
        return (
          <DataTooltip>
            {`${indexBy !=="Code"? point.data[indexBy] : point.data.Name} - ${thousandDivider(parseNumberValue(Unit, point.data[keyTarget], 2))} ${Unit === "件"? Unit : parseUnit("元", point.data[keyTarget])}`}
          </DataTooltip>
        )
      }} 
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 32,
        legend: parseIndex[indexBy],
        legendPosition: "middle",
        legendOffset:  (30 + maxString * 4)
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        tickValues: 6,
        legend: Unit === "件" ? Unit : parseUnit(Unit, max),
        legendPosition: "middle",
        legendOffset: -42,
        format: function(value){ 
          return ["元", "千元"].includes(Unit) ? thousandDivider(parseNumberValue(Unit, value, 0)) : thousandDivider(value);
        }
      }}
    />
  </div>)
};

const ColumnBox = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  box-shadow: 2px 2px 6px 2px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  height: 100%;
  margin: ${props => props.marginCustom || "0px"};
  background: ${props => props.bgColor || "none"};
  color: ${props => props.colorCustom || "none"};
  .subTitle {
    font-weight: bold !important;
  }
  p {
    line-height: 1.5;
  }
  .unit {
    margin-left: 8px;
  }
`;
const DataTooltip = styled.div`
  background: white;
  padding: 8px 12px;
  border: 1px solid #ccc;
`
const InfoBox = styled(Box)`
  cursor: pointer;
  &:hover {
    opacity: 0.75;
  }
`;

export default BarChart;
