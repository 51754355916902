import React from "react";
import SwiperCore, { A11y, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { v4 as uuidv4 } from "uuid";
import { useSelector, useDispatch } from "react-redux";
import { ScrollContainer } from "../../utility/styles";
import { useLocation } from "react-router-dom";

// Import Swiper slide
import QuestionBasic from "./QuestionBasicInfo";
import ECOVisitReason from "./ECOVisitReason";
import QuestionVisitSubTypeItem from "./QuestionVisitSubTypeItem";

import {
  handleFetchDetailQuestion,
  handleUploadVisitQuestionData,
  handleGetVisitQuestionDetail,
} from "../../store/actions/question";

// Import Swiper styles
import "swiper/swiper.scss";
import "../../assests/styles/_swiper-navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/scrollbar/scrollbar.scss";
import "./styles.scss";
import { result } from "lodash";
import { useSnackbar } from "notistack";
import { message } from "../../utility/common";

SwiperCore.use([A11y, Navigation]);

export default () => {
  const swiperRef = React.useRef(null);
  const dispatch = useDispatch();
  const location = useLocation();
  const { detailQuestions, uploadVisitQuestionImageMsg } = useSelector((state) => state.question);
  // 上傳圖片錯誤訊息回饋
  const { enqueueSnackbar } = useSnackbar();
  React.useEffect(() => {
    if (uploadVisitQuestionImageMsg && uploadVisitQuestionImageMsg?.status !== 200) {
      message(enqueueSnackbar, `問卷圖片上傳錯誤：${uploadVisitQuestionImageMsg?.description}`, "error");
    }
  }, [uploadVisitQuestionImageMsg]);

  const steps = [
    {
      index: 0,
      label: "基本資料",
    },
    {
      index: 1,
      label: "類型子項",
    },
    {
      index: 2,
      label: "洽商事由",
    },
  ];

  const [state, setState] = React.useState({
    currentSide: 0,
    result: [
      {
        companyName: "",
        companyNumber: "",
        industryPark: "",
        companyMainProduct: "",
        companyCapital: "",
        companyTypeTwo: [],
        userName: "",
        visitTime: "",
        userDivision: "",
        userEmail: "",
        userPhone: "",
        note: "",
        isFinished: false,
      },
      {
        stepTwoSelected: "",
        stepTwoData: "",
      },
      {
        stepThreeDetail: [],
        stepThreeSelected: [],
        stepThreeTitle: [],
        stepThreeResult: [],
        stepThreeData: [],
      },
      {
        stepFourSelected: [],
        stepFourResult: [],
        stepFourData: [],
        followStep4: [],
      },
      {
        conclusion: "",
        signSupervisorPad: null,
        signParticipantsPad: null,
        questionVisitImg1: "",
        questionVisitImg2: "",
        questionVisitImg3: "",
        controlNum: "",
        Inspector: "",
      },
      {
        uuid: "",
        group: "環保組",
        phoneInterview: false,
        allowNext: [true, false, false, false, false],
      },
    ],
  });

  React.useEffect(() => {
    dispatch(handleFetchDetailQuestion(1));

    if (location.state) {
      const uid = localStorage.getItem("uid");
      dispatch(handleGetVisitQuestionDetail(uid, location.state));
      state.result[5].uuid = location.state;
    } else {
      state.result[5].uuid = uuidv4();
    }
  }, []);

  React.useEffect(() => {
    const temp = [];
    const temp2 = [];
    const temp3 = [];
    const tempDetail = [];

    Object.entries(detailQuestions).map((item) => {
      item.map((i) => {
        if (i.VisitTypeName && temp.indexOf(i.VisitTypeName) === -1) {
          temp.push(i.VisitTypeName);
        }
        if (i.TypeChildName && temp2.indexOf(i.TypeChildName) === -1) {
          temp2.push(i.TypeChildName);
          temp3.push(i);

          tempDetail.push({
            VisitTypeName: i.VisitTypeName,
            TypeChildName: i.TypeChildName,
          });
        }
      });
    });

    state.result[1].stepTwoSelected = 0;
    state.result[1].stepTwoData = "主動拜訪";

    state.result[2].stepThreeData = temp3;
    state.result[2].stepThreeDetail = tempDetail;
    state.result[2].stepThreeResult = ["汙水(環保組)"];
    state.result[2].stepThreeSelected = [140];
  }, [detailQuestions]);

  const prevSlide = React.useCallback(
    (e) => {
      e.stopPropagation();
      swiperRef.current.swiper.slidePrev();
    },
    [swiperRef]
  );

  const nextSlide = React.useCallback(
    (e) => {
      e.stopPropagation();
      swiperRef.current.swiper.slideNext();
    },
    [swiperRef]
  );

  const goToStepThree = () => {
    swiperRef.current.swiper.slidePrev();
    setState((state) => ({ ...state, currentSide: 2 }));
  };

  const handleClickTabs = (num) => {
    for (let i = 0; i < Math.abs(num - state.currentSide); i++) {
      if (num - state.currentSide > 0) {
        if (
          (state.currentSide === 0 && state.result[0].isFinished) ||
          (state.currentSide === 1 && state.result[1].stepThreeSelected !== "")
        ) {
          swiperRef.current.swiper.slideNext();
        }
      } else {
        swiperRef.current.swiper.slidePrev();
      }
    }
  };

  const UploadQuestionData = async (release) => {
    const uid = localStorage.getItem("uid");
    await dispatch(handleUploadVisitQuestionData(state.result, uid, release));
  };

  return (
    <ScrollContainer>
      <div className="question-container">
        {/* 上方選單 */}
        <div className="question-container-progress">
          {steps.map((step) => (
            <li
              key={step.index}
              className={`
        progress-step
        ${state.currentSide === step.index ? "active" : "inactive"}
        ${state.currentSide > step.index ? "complete" : "incomplete"}
      `}
            >
              <div
                className="question-container-progress-item"
                onClick={() => {
                  handleClickTabs(step.index);
                }}
              >
                <span className="step-number">{step.index + 1}</span>
                {window.innerWidth > 730 && <h3>{step.label}</h3>}
              </div>
            </li>
          ))}
        </div>

        {/* 下方問卷 */}
        <div className="question-container-card-view">
          <Swiper
            ref={swiperRef}
            spaceBetween={0}
            slidesPerView={1}
            onSwiper={(swiper) => {
              setState((state) => ({
                ...state,
                currentSide: swiper.activeIndex,
              }));
            }}
            onSlideChange={(swiper) => {
              setState((state) => ({
                ...state,
                currentSide: swiper.activeIndex,
              }));
            }}
            initialSlide={0}
            noSwiping={true}
            allowTouchMove={false}
          >
            <SwiperSlide index={0}>
              <QuestionBasic
                result={state.result}
                nextSlide={nextSlide}
                setResult={setState}
                UploadQuestionData={UploadQuestionData}
              />
            </SwiperSlide>
            <SwiperSlide index={1}>
              <ECOVisitReason
                result={state.result}
                prevSlide={prevSlide}
                nextSlide={nextSlide}
                UploadQuestionData={UploadQuestionData}
                stepFour={state.result[2]?.stepThreeData.sort(
                  (x, y) => x.TopicId - y.TopicId
                )}
                selected={state.result[3]?.stepFourSelected}
                updateTypeList={(title, val) => {
                  const updateResult = [...state.result];
                  updateResult[3].stepFourSelected = [val];
                  updateResult[3].stepFourResult = [title];

                  updateResult[3].stepFourData =
                    updateResult[3].stepFourData.filter(
                      (i) => i.TypeChildName === title
                    );

                  detailQuestions.map((item) => {
                    if (item.TypeChildName === title) {
                      updateResult[3].stepFourData.push(item);
                    }
                  });

                  setState((state) => ({
                    ...state,
                    result: updateResult,
                  }));
                }}
              />
            </SwiperSlide>
            <SwiperSlide index={2}>
              <QuestionVisitSubTypeItem
                result={state.result}
                prevSlide={prevSlide}
                nextSlide={nextSlide}
                goToStepThree={goToStepThree}
                UploadQuestionData={UploadQuestionData}
                stepFive={state.result[3]?.stepFourData}
                ECOQuestion={true}
              />
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
      <div style={{ height: "30%" }} />
    </ScrollContainer>
  );
};
