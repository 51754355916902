import axios from "axios";
import axiosInstance from "./axiosWithRefreshToken"
import { Base64 } from "js-base64";
import { baseUrl, loginbaseUrl } from "../constants/endpoints";

const headers = {
  "Access-Control-Allow-Origin": "*",
  "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
  "Access-Control-Allow-Headers":
    "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers",
  "Content-Type": "application/json",
  Authorization: localStorage.getItem("acToken"),
};
const InduParkWebAcTokenheaders = {
  "Access-Control-Allow-Origin": "*",
  "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
  "Access-Control-Allow-Headers":
    "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers",
  "Content-Type": "application/json",
  Authorization: localStorage.getItem("InduParkWebAcToken"),
};
const ChatGptHeaders = {
  'Content-Type': 'application/json',
  'Authorization': 'Bearer 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',
};

// const baseUrl = 'http://13.76.6.206/OpenDataServiceTest2'
// const loginbaseUrl = 'https://indupark.smartpark.com.tw/'
// const loginbaseUrl = 'http://13.76.6.206/'
// const baseUrl = 'https://opendataservice.smartpark.com.tw'

//handling proxy tmeplorary
const proxyUrl = "";

//聊天機器人
export const chatGpt = (train) => {
  return axios({
    proxy: true,
    method: "post",
    url: `https://api.openai.com/v1/chat/completions`,
    headers: ChatGptHeaders,
    data: {        
      'model': 'gpt-4o',
      "messages": train,
      'temperature': 0.4,
      'max_tokens': 1000
    }
  })
    .then((res) => {
    
      const regex1 = /[\[][\u4E00-\u9FFF\_、:0-9A-z]+[\]]\([A-z0-9:/.]+\)/gm;
      const regex2 = /[\[][\u4E00-\u9FFF\_、:0-9A-z]+[\]]/gm;
      const regex3 = /\([A-z0-9:/.]+\)/gm;
      const regex4 = /\*\*[Step0-9\ ]+\:\*\*/gm;
      const regex5 = /\*\*[Step0-9\ ]+\*\*\:/gm;
  
      // Alternative syntax using RegExp constructor
      // const regex = new RegExp('[[\\u4E00-\\u9FFF]+]\\([A-z0-9:\/.]+\\)', 'gm')
      let m1 = "";
      let m2 = "";
      let m3 = "";
      let m4 = "";
      let m5 = "";
      res.data.choices[0].message.content = res.data.choices[0].message.content.replaceAll("\n", "<br/>")
      while ((m4 = regex4.exec(res.data.choices[0].message.content)) !== null) {
        // This is necessary to avoid infinite loops with zero-width matches
        if (m4.index === regex4.lastIndex) {
          regex4.lastIndex++;
        }
        // The result can be accessed through the `m`-variable.
        m4.forEach((match4, groupIndex4) => {
          let sumMatch = "";
          console.log(`Found match, group ${groupIndex4}: ${match4}`);
          if(match4.replaceAll("**", "") != "Step 1"){
          }
          sumMatch += "<a style='font-weight: bold;font-style: oblique;'>" + match4.replaceAll("**", "") + "</a>";
          res.data.choices[0].message.content = res.data.choices[0].message.content.replaceAll(match4, sumMatch);
        });
      }
      while ((m5 = regex5.exec(res.data.choices[0].message.content)) !== null) {
        // This is necessary to avoid infinite loops with zero-width matches
        if (m5.index === regex5.lastIndex) {
          regex5.lastIndex++;
        }
        // The result can be accessed through the `m`-variable.
        m5.forEach((match5, groupIndex5) => {
          let sumMatch = "";
          console.log(`Found match, group ${groupIndex5}: ${match5}`);
          if(match5.replaceAll("**", "") != "Step 1"){
          }
          sumMatch += "<a style='font-weight: bold;font-style: oblique;'>" + match5.replaceAll("**", "") + "</a>";
          res.data.choices[0].message.content = res.data.choices[0].message.content.replaceAll(match5, sumMatch);
        });
      }
      while ((m1 = regex1.exec(res.data.choices[0].message.content)) !== null) {
        // This is necessary to avoid infinite loops with zero-width matches
        if (m1.index === regex1.lastIndex) {
          regex1.lastIndex++;
        }
        // The result can be accessed through the `m`-variable.
        m1.forEach((match1, groupIndex1) => {
          let sumMatch = "";
          while ((m3 = regex3.exec(match1)) !== null) {
            // This is necessary to avoid infinite loops with zero-width matches
            if (m3.index === regex3.lastIndex) {
              regex3.lastIndex++;
            }
            // The result can be accessed through the `m`-variable.
            m3.forEach((match3, groupIndex3) => {
              console.log(`Found match, group ${groupIndex3}: ${match3}`);
              
              sumMatch += "<a target='_blank' href='" + match3.replaceAll("(", "").replaceAll(")", "") + "'>";
            });
          }
          while ((m2 = regex2.exec(match1)) !== null) {
            // This is necessary to avoid infinite loops with zero-width matches
            if (m2.index === regex2.lastIndex) {
              regex2.lastIndex++;
            }
            // The result can be accessed through the `m`-variable.
            m2.forEach((match2, groupIndex2) => {
              console.log(`Found match, group ${groupIndex2}: ${match2}`);
              sumMatch +=  match2.replaceAll("[", "").replaceAll("]", "") + "</a>";
            });
          }
          res.data.choices[0].message.content = res.data.choices[0].message.content.replaceAll(match1, sumMatch);
        });
      }
      return res;
    })
    .catch((err) => {
      return err;
    });
};
//修改密碼
export const changePassWord = (data) => {
  return axios({
    proxy: true,
    method: "post",
    url: `${loginbaseUrl}api/auth/UpdatePassword/`,
    // headers: {
    //   "Access-Control-Allow-Origin": "*",
    //   'Authorization': localStorage.getItem('acToken')
    // },
    data: data,
  })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};

//登入
export const Login = (data) => {
  return axios({
    proxy: true,
    method: "post",
    url: `${loginbaseUrl}api/auth/SignIn/`,
    // headers: headers,
    data: data,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return [];
    });
};

//註冊
export const SignUp = (file) => {
  return axios({
    proxy: true,
    method: "post",
    url: `${loginbaseUrl}api/auth/signup/`,
    // headers: headers,
    data: file,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return [];
    });
};

//取得總瀏覽數
export const getViewerCounts = () => {
  return axios({
    proxy: true,
    method: "get",
    url: `${loginbaseUrl}api/auth/getUserLogCount/`,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return [];
    });
};

//取得使用者資訊
export const fetchUserInfo = (uid) => {
  return axios({
    proxy: true,
    method: "get",
    url: `${loginbaseUrl}api/auth/getUserInfo/${uid}`,
    // headers: headers,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return [];
    });
};

//取得儀表板資訊
export const getSectionMapHandler = (uid) => {
  const getSectionData = new FormData();
  getSectionData.append("account", uid);
  return axios({
    proxy: true,
    method: "post",
    url: `${loginbaseUrl}/api/dashboardctrl/getdashboardctrlrec/`,
    data: getSectionData,
    // headers: headers,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return [];
    });
};

//修改儀表板資訊
export const updateSectionMapHandler = (data) => {
  const uid = localStorage.getItem("uid");

  const updateSectionData = new FormData();
  updateSectionData.append("Order", data.Order);
  updateSectionData.append("isOpen", !data.isOpen);
  updateSectionData.append("title", data.title);
  updateSectionData.append("key", data.key);
  updateSectionData.append("type", data.type);
  updateSectionData.append("category", data.category);
  updateSectionData.append("account", uid);

  return axios({
    proxy: true,
    method: "post",
    url: `${loginbaseUrl}/api/dashboardctrl/updatedashboardctrlrec/`,
    data: updateSectionData,
    // headers: headers,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return [];
    });
};

export const updateUserInfo = (file) => {
  return axios({
    proxy: true,
    method: "post",
    url: `${loginbaseUrl}/api/auth/updateUserInfo`,
    data: file,
    // headers: headers,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return [];
    });
};

// 提出申請檔案需求
export const applyFileRequest = (file) => {
  return axios({
    proxy: true,
    method: "post",
    url: `${loginbaseUrl}/api/ExportCtrl/SubmitExportInfo`,
    data: file,
    headers: InduParkWebAcTokenheaders
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return [];
    });
};

// 取得申請檔案資訊
export const fetchApplyFileInfo = () => {
  return axios({
    proxy: true,
    method: "get",
    url: `${loginbaseUrl}api/ExportCtrl/GetUserExportInfo`,
    headers: InduParkWebAcTokenheaders,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return [];
    });
};

// 取得申請檔案下拉選單列表
export const fetchApplyFileDropdownList = () => {
  return axios({
    proxy: true,
    method: "get",
    url: `${loginbaseUrl}api/ExportCtrl/GetExportOptions`,
    headers: InduParkWebAcTokenheaders,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return [];
    });
};

//啟用帳號
export const activeAccount = (account) => {
  const file = new FormData();
  file.append("Account", account);
  return axios({
    proxy: true,
    method: "post",
    url: `${loginbaseUrl}api/auth/isActiveAccount/`,
    // headers: headers,
    data: file,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return [];
    });
};

//驗證
export const Authenticated = (token, data) => {
  const formData = new FormData();
  formData.append("EncTicket", `${data}`);

  return axios({
    // proxy: true,
    method: "post",
    url: `${loginbaseUrl}InduParkWeb/api/auth/IsPassPermission/${token}`,
    data: formData,
    // header:headers
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return [];
    });
};

//忘記密碼
export const forgetPassWord = (email) => {
  const file = new FormData();
  file.append("Account", email);
  return axios({
    proxy: true,
    method: "post",
    url: `${loginbaseUrl}api/auth/forgetPassWord/`,
    // headers: headers,
    data: file,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return [];
    });
};

//新增log
export const getUserLog = (data) => {
  return axios({
    method: "post",
    url: `${loginbaseUrl}api/auth/AddUserLog/`,
    data: data,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return [];
    });
};

//更新權限
export const updatePermission = () => {
  const data = new FormData();
  data.append("enTicket", localStorage.getItem("EncTicket").toString());

  return axios({
    method: "post",
    url: `${loginbaseUrl}api/auth/updatePermission/`,
    data: data,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return [];
    });
};

export const GetCountryParkBasicInfo = (area) => {
  return axios({
    proxy: true,
    method: "get",
    url: `${baseUrl}/api/InduParkTotal/GetMainIndex/${encodeURI(area)}`,
    headers: headers,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return [];
    });
};

// 取得稅務行業分類資料
export const getTaxCompanyIndustryInfo = (businessId) => {
  return axios({
    proxy: true,
    method: "get",
    url: `${baseUrl}/api/Company/GetTaxCompanyIndustry/${businessId}`,
    headers: headers,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return [];
    });
};

//公開發行狀況佔比-圓餅圖
export const getAllPercentSharing = (area) => {
  return axios({
    proxy: true,
    method: "get",
    url: `${baseUrl}/api/InduParkTotal/GetPieChartByStock_CanvasJS/${encodeURI(
      area
    )}`,
    headers: headers,
  })
    .then((res) => {
      return res.data.data[0].dataPoints;
    })
    .catch((err) => {
      return [];
    });
};

//取得新聞標籤資料
export const GetAllNewsTag = (area) => {
  return axios({
    proxy: true,
    method: "get",
    url: `${baseUrl}/api/InduParkTotal/GetNewsTags/${encodeURI(area)}`,
    headers: headers,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return [];
    });
};

//TODO 後面一樣直接加參數？
//取得新聞資料
export const GetAllNewsContent = (item, pagination, area) => {
  return axios({
    proxy: true,
    method: "get",
    url: `${baseUrl}/api/InduParkTotal/GetNewsItems/${item}/${pagination}/${area}`,
    headers: headers,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return [];
    });
};

//廠商家數前十大園區排行-長條圖
export const GetTopTenRankingCompanyAll = (area) => {
  return axios({
    proxy: true,
    method: "get",
    url: `${baseUrl}/api/InduParkTotal/GetColumnChartByCompanyNum_CanvasJS/${encodeURI(
      area
    )}`,
    headers: headers,
  })
    .then((res) => {
      return res.data.data[0].dataPoints;
    })
    .catch((err) => {
      return [];
    });
};

//政府計畫數前十大園區排行-長條圖
export const TopTenRankingGovermentPlan = (area) => {
  return axios({
    proxy: true,
    method: "get",
    url: `${baseUrl}/api/InduParkTotal/GetColumnChartByGovPlan_CanvasJS/${encodeURI(
      area
    )}`,
    headers: headers,
  })
    .then((res) => {
      return res.data.data[0].dataPoints;
    })
    .catch((err) => {
      return [];
    });
};

//資本額前十大園區排行-長條圖
export const TopTenCapital = (area) => {
  return axios({
    proxy: true,
    method: "get",
    url: `${baseUrl}/api/InduParkTotal/GetColumnChartByCapital_CanvasJS/${encodeURI(
      area
    )}`,
    headers: headers,
  })
    .then((res) => {
      return res.data.data[0].dataPoints;
    })
    .catch((err) => {
      return [];
    });
};

//汙水量前十大園區排行-長條圖
export const TopTenPollutionWater = (area) => {
  return axios({
    proxy: true,
    method: "get",
    url: `${baseUrl}/api/InduParkTotal/GetColumnChartBySewage_CanvasJS/${encodeURI(
      area
    )}`,
    headers: headers,
  })
    .then((res) => {
      return res.data.data[0].dataPoints;
    })
    .catch((err) => {
      return [];
    });
};

//獲獎數前十大園區排行-長條圖
export const TopTenAward = (area) => {
  return axios({
    proxy: true,
    method: "get",
    url: `${baseUrl}/api/InduParkTotal/GetColumnChartByAward_CanvasJS/${encodeURI(
      area
    )}`,
    headers: headers,
  })
    .then((res) => {
      return res.data.data[0].dataPoints;
    })
    .catch((err) => {
      return [];
    });
};

//專利數前十大園區排行-長條圖
export const TopTenPatent = (area) => {
  return axios({
    proxy: true,
    method: "get",
    url: `${baseUrl}/api/InduParkTotal/GetColumnChartByPatent_CanvasJS/${encodeURI(
      area
    )}`,
    headers: headers,
  })
    .then((res) => {
      return res.data.data[0].dataPoints;
    })
    .catch((err) => {
      return [];
    });
};

//各產業家數佔比 - 圓餅圖;
export const IndustryPercentage = (area) => {
  return axios({
    proxy: true,
    method: "get",
    url: `${baseUrl}/api/InduParkTotal/GetPieChartByInduTypeFour_CanvasJS/${encodeURI(
      area
    )}`,
    headers: headers,
  })
    .then((res) => {
      return res.data.data[0].dataPoints;
    })
    .catch((err) => {
      return [];
    });
};

//取得園區廠商政府計畫類型佔比&&園區廠商獲獎類型佔比資料
export const getParkDetailInfo = (parkId, labelName, IsAward) => {
  const updateLabelName = Base64.encode(labelName)
    .replace(/[+]/g, "-")
    .replace(/[/]/g, "_");

  return axios({
    proxy: true,
    method: "get",
    url:
      proxyUrl +
      `${baseUrl}/api/InduParkSIngle/GetCompanyListByGovPlanOrAward/${parkId}/${updateLabelName}/${IsAward}`,
    headers: headers,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return [];
    });
};

//單一園區 主要指標
export const SectionIndex = (parkId) => {
  return axios({
    proxy: true,
    method: "get",
    url: `${baseUrl}/api/InduParkSingle/GetMainIndex/${parkId}/`,
    headers: headers,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return [];
    });
};

//單一園區 新聞標籤
export const SectionNewsTag = (parkId) => {
  return axios({
    proxy: true,
    method: "get",
    url: proxyUrl + `${baseUrl}/api/InduParkSIngle/GetNewsTagCloud/${parkId}`,
    headers: headers,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return [];
    });
};

//單一園區 新聞內容
export const SectionNewsContant = (parkId, item, pagination) => {
  return axios({
    proxy: true,
    method: "get",
    url:
      proxyUrl +
      `${baseUrl}/api/InduParkSIngle/GetNewsItems/${parkId}/5/${pagination}`,
    headers: headers,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return [];
    });
};

//單一園區 公開發行占比
//
export const SectionPublicSharing = (parkId) => {
  return axios({
    proxy: true,
    method: "get",
    url:
      proxyUrl +
      `${baseUrl}/api/InduParkSIngle/GetPieChartByStockType_CanvasJS/${parkId}`,
    headers: headers,
  })
    .then((res) => {
      return res.data.data[0].dataPoints;
    })
    .catch((err) => {
      return [];
    });
};

//單一園區 公開發行前十大
export const SectionPublicTopTen = (parkId, param) => {
  return axios({
    proxy: true,
    method: "get",
    url:
      proxyUrl +
      `${baseUrl}/api/InduParkSIngle/GetTopCompany_Stock/${parkId}/${param}`,
    headers: headers,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return [];
    });
};

//單一園區 綠色工廠前十大廠商
export const SectionPublicTopTenGreen = (parkId) => {
  return axios({
    proxy: true,
    method: "get",
    url:
      proxyUrl + `${baseUrl}/api/InduParkSIngle/GetTopCompany_Green/${parkId}`,
    headers: headers,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return [];
    });
};

//單一園區 進出口級距佔比-圓餅圖 (透過 true 跟 false 確認進出口)
export const SectionTradeRate = (parkId, tradePort) => {
  let trade;
  if (tradePort === "import") {
    trade = true;
  } else if (tradePort === "export") {
    trade = false;
  }

  return axios({
    proxy: true,
    method: "get",
    url:
      proxyUrl +
      // `http://13.76.6.206/OpenDataServiceTest2/api/InduParkTotal/GetPieChartByForeignTrade_CanvasJS/%E5%8D%97%E9%83%A8/${trade}`
      `${baseUrl}/api/InduParkSIngle/GetPieChartByForeignTrade_CanvasJS/${parkId}/${trade}`,
    headers: headers,
  })
    .then((res) => {
      return res.data.data[0].dataPoints;
    })
    .catch((err) => {
      return [];
    });
};

//
//
//單一園區 進出口級距佔比-清單 (透過 true 跟 false 確認進出口)
export const SectionTradeList = (parkId, tradePort, params) => {
  let trade;
  if (tradePort === "import") {
    trade = true;
  } else if (tradePort === "export") {
    trade = false;
  }

  return axios({
    proxy: true,
    method: "get",
    url:
      proxyUrl +
      `${baseUrl}/api/InduParkSIngle/GetCompanyList_ForeignTrade/${parkId}/${trade}/${params}?`,
    headers: headers,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return [];
    });
};

//單一園區 產業別2碼家數佔比-圓餅圖
export const TwoCodePercentage = (parkId) => {
  return axios({
    proxy: true,
    method: "get",
    url:
      proxyUrl +
      `${baseUrl}/api/InduParkSIngle/GetPieChartByInduTypeTwo_CanvasJS/${parkId}`,
    headers: headers,
  })
    .then((res) => {
      return res.data.data[0].dataPoints;
    })
    .catch((err) => {
      return [];
    });
};

//單一園區 產業別2碼家數佔比-清單
export const TwoCodePercentageList = (parkId, params) => {
  return axios({
    proxy: true,
    method: "get",
    url:
      proxyUrl +
      `${baseUrl}/api/InduParkSIngle/GetCompanyList_InduTypeTwo/${parkId}/${params}`,
    headers: headers,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return [];
    });
};

//單一園區 產業別3碼家數佔比-圓餅圖
export const ThreeCodePercentage = (parkId) => {
  return axios({
    proxy: true,
    method: "get",
    url:
      proxyUrl +
      `${baseUrl}/api/InduParkSIngle/GetPieChartByInduTypeThree_CanvasJS/${parkId}`,
    headers: headers,
  })
    .then((res) => {
      return res.data.data[0].dataPoints;
    })
    .catch((err) => {
      return [];
    });
};

//單一園區 產業別3碼家數佔比-清單
export const ThreeCodePercentageList = (parkId, params) => {
  return axios({
    proxy: true,
    method: "get",
    url:
      proxyUrl +
      `${baseUrl}/api/InduParkSIngle/GetCompanyList_InduTypeThree/${parkId}/${params}`,
    headers: headers,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return [];
    });
};

//單一園區 產業別4碼家數占比
export const FourCodePercentage = (parkId) => {
  return axios({
    proxy: true,
    method: "get",
    url:
      proxyUrl +
      `${baseUrl}/api/InduParkSIngle/GetPieChartByInduTypeFour_CanvasJS/${parkId}`,
    headers: headers,
  })
    .then((res) => {
      return res.data.data[0].dataPoints;
    })
    .catch((err) => {
      return [];
    });
};

//單一園區 產業別4碼家數占比 -- 清單
export const FourCodePercentageList = (parkId, params) => {
  return axios({
    proxy: true,
    method: "get",
    url:
      proxyUrl +
      `${baseUrl}/api/InduParkSIngle/GetCompanyList_InduTypeFour/${parkId}/${params}`,
    headers: headers,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return [];
    });
};

//單一園區 資本額前十大廠商排行-長條圖
export const SectionTopTenCapital = (parkId) => {
  return axios({
    proxy: true,
    method: "get",
    url:
      proxyUrl +
      `${baseUrl}/api/InduParkSIngle/GetColumnChartByCapital_CanvasJS/${parkId}`,
    headers: headers,
  })
    .then((res) => {
      return res.data.data[0].dataPoints;
    })
    .catch((err) => {
      return [];
    });
};

//單一園區 政府計畫前十大廠商
export const SectionTopTenGovermentPlan = (parkId) => {
  return axios({
    proxy: true,
    method: "get",
    url:
      proxyUrl +
      `${baseUrl}/api/InduParkSIngle/GetColumnChartByGovPlan_CanvasJS/${parkId}`,
    headers: headers,
  })
    .then((res) => {
      return res.data.data[0].dataPoints;
    })
    .catch((err) => {
      return [];
    });
};

//單一園區 專利數
export const SectionTopTenPatent = (parkId) => {
  return axios({
    proxy: true,
    method: "get",
    url:
      proxyUrl +
      `${baseUrl}/api/InduParkSIngle/GetColumnChartByPatent_CanvasJS/${parkId}`,
    headers: headers,
  })
    .then((res) => {
      return res.data.data[0].dataPoints;
    })
    .catch((err) => {
      return [];
    });
};

//單一園區 員工數前十大

export const SectionTopTenEmp = (parkId) => {
  return axios({
    proxy: true,
    method: "get",
    url:
      proxyUrl +
      `${baseUrl}/api/InduParkSIngle/GetColumnChartByEmpCount_CanvasJS/${parkId}`,
    headers: headers,
  })
    .then((res) => {
      return res.data.data[0].dataPoints;
    })
    .catch((err) => {
      return [];
    });
};

//單一園區 政府計畫數類型佔比
export const SectionGovPlanPercent = (parkId) => {
  return axios({
    proxy: true,
    method: "get",
    url:
      proxyUrl +
      `${baseUrl}/api/InduParkSIngle/GetPieChartByGovPlan_CanvasJS/${parkId}`,
    headers: headers,
  })
    .then((res) => {
      return res.data.data[0].dataPoints;
    })
    .catch((err) => {
      return [];
    });
};

//單一園區 獲獎數類型佔比
export const SectionAwardPercent = (parkId) => {
  return axios({
    proxy: true,
    method: "get",
    url:
      proxyUrl +
      `${baseUrl}/api/InduParkSIngle/GetPieChartByAward_CanvasJS/${parkId}`,
    headers: headers,
  })
    .then((res) => {
      return res.data.data[0].dataPoints;
    })
    .catch((err) => {
      return [];
    });
};

//單一園區 專利數占比
export const SectionPatentPercent = (parkId) => {
  return axios({
    proxy: true,
    method: "get",
    url:
      proxyUrl +
      `${baseUrl}/api/InduParkSIngle/GetPieChartByPatent_CanvasJS/${parkId}?`,
    headers: headers,
  })
    .then((res) => {
      return res.data.data[0].dataPoints;
    })
    .catch((err) => {
      return [];
    });
};

//依地區取得產業園區
export const getFactoryByArea = (area) => {
  return axios({
    proxy: true,
    method: "get",
    url:
      proxyUrl +
      `${baseUrl}/api/InduPark/GetInduParkEntitiesByCityArea/${area}`,
    headers: headers,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return [];
    });
};

//廠商摘要
export const getCompnaySummary = (data) => {
  return axios({
    proxy: true,
    method: "post",
    url: proxyUrl + `${baseUrl}/api/InduParkAdvanced/GetInduParkCompanySummary`,
    headers: {
      "Content-Type": "multipart/form-data",
      "Access-Control-Allow-Origin": "*",
    },
    data: data,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return [];
    });
};

//廠商新聞標籤
export const getCompnayNewsTag = (data) => {
  return axios({
    proxy: true,
    method: "post",
    url: proxyUrl + `${baseUrl}/api/InduParkAdvanced/GetNewsTagCloud`,
    headers: {
      "Content-Type": "multipart/form-data",
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("acToken"),
    },
    data: data,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return [];
    });
};

//廠商新聞資料
export const getCompnayNewsContent = (item, page, data) => {
  return axios({
    proxy: true,
    method: "post",
    url:
      proxyUrl + `${baseUrl}/api/InduParkAdvanced/GetNewsItems/${item}/${page}`,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: localStorage.getItem("acToken"),
    },
    data: data,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return [];
    });
};

//資本額排行
export const getCapitalRanking = (data) => {
  return axios({
    proxy: true,
    method: "post",
    url:
      proxyUrl +
      `${baseUrl}/api/InduParkAdvanced/GetColumnChartByCapital_CanvasJS`,
    headers: {
      "Content-Type": "multipart/form-data",
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("acToken"),
    },
    data: data,
  })
    .then((res) => {
      if (!res.data) {
        return [];
      } else {
        return res.data.data[0].dataPoints;
      }
    })
    .catch((err) => {
      return [];
    });
};

export const getPublicSahring = (data) => {
  return axios({
    proxy: true,
    method: "post",
    url:
      proxyUrl +
      `${baseUrl}/api/InduParkAdvanced/GetPieChartByStockType_CanvasJS`,
    headers: {
      "Content-Type": "multipart/form-data",
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("acToken"),
    },
    data: data,
  })
    .then((res) => {
      if (!res.data) {
        return [];
      } else {
        return res.data.data[0].dataPoints;
      }
    })
    .catch((err) => {
      return [];
    });
};

//專利排行 --- 從缺
export const getPatentRanking = (data) => {
  return axios({
    proxy: true,
    method: "post",
    url:
      proxyUrl +
      `${baseUrl}/api/InduParkAdvanced/GetColumnChartByPatent_CanvasJS`,
    headers: {
      "Content-Type": "multipart/form-data",
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("acToken"),
    },
    data: data,
  })
    .then((res) => {
      if (!res.data) {
        return [];
      } else {
        return res.data.data[0].dataPoints;
      }
    })
    .catch((err) => {
      return [];
    });
};

//政府計畫數前十大廠商排行
export const getGovermentPlanRanking = (data) => {
  return axios({
    proxy: true,
    method: "post",
    url:
      proxyUrl +
      `${baseUrl}/api/InduParkAdvanced/GetColumnChartByGovPlan_CanvasJS`,
    headers: {
      "Content-Type": "multipart/form-data",
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("acToken"),
    },
    data: data,
  })
    .then((res) => {
      if (!res.data) {
        return [];
      } else {
        return res.data.data[0].dataPoints;
      }
    })
    .catch((err) => {
      return [];
    });
};

//公開發行廠商數佔比
export const publicStockRanking = (data) => {
  return axios({
    proxy: true,
    method: "post",
    url:
      proxyUrl +
      `${baseUrl}/api/InduParkAdvanced/GetPieChartByStockType_CanvasJS`,
    headers: {
      "Content-Type": "multipart/form-data",
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("acToken"),
    },
    data: data,
  })
    .then((res) => {
      if (!res.data) {
        return [];
      } else {
        return res.data.data[0].dataPoints;
      }
    })
    .catch((err) => {
      return [];
    });
};

//產業別4碼廠商數佔比
export const getTypeFoureRanking = (data) => {
  return axios({
    proxy: true,
    method: "post",
    url:
      proxyUrl +
      `${baseUrl}/api/InduParkAdvanced/GetPieChartByInduTypeFour_CanvasJS`,
    headers: {
      "Content-Type": "multipart/form-data",
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("acToken"),
    },
    data: data,
  })
    .then((res) => {
      if (!res.data) {
        return [];
      } else {
        return res.data.data[0].dataPoints;
      }
    })
    .catch((err) => {
      return [];
    });
};

//產業別3碼廠商數佔比
export const getTypeThreeRanking = (data) => {
  return axios({
    proxy: true,
    method: "post",
    url:
      proxyUrl +
      `${baseUrl}/api/InduParkAdvanced/GetPieChartByInduTypeThree_CanvasJS`,
    headers: {
      "Content-Type": "multipart/form-data",
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("acToken"),
    },
    data: data,
  })
    .then((res) => {
      if (!res.data) {
        return [];
      } else {
        return res.data.data[0].dataPoints;
      }
    })
    .catch((err) => {
      return [];
    });
};

//產業別2碼廠商數佔比
export const getTypeTwoRanking = (data) => {
  return axios({
    proxy: true,
    method: "post",
    url:
      proxyUrl +
      `${baseUrl}/api/InduParkAdvanced/GetPieChartByInduTypeTwo_CanvasJS`,
    headers: {
      "Content-Type": "multipart/form-data",
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("acToken"),
    },
    data: data,
  })
    .then((res) => {
      if (!res.data) {
        return [];
      } else {
        return res.data.data[0].dataPoints;
      }
    })
    .catch((err) => {
      return [];
    });
};

//公司基本資料
export const getCompanyBasicInfo = (businessId) => {
  return axios({
    proxy: true,
    method: "get",
    url:
      proxyUrl +
      `${baseUrl}/api/CompanyBasicInfo/GetCompanyBasicInfo/${businessId}`,
    headers: headers,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return [];
    });
};

//工廠登記資料 商工資料
export const getBusinessInfo = (businessId) => {
  return axios({
    proxy: true,
    method: "get",
    url: proxyUrl + `${baseUrl}/api/FactoryInfo/GetFactoryInfos/${businessId}`,
    headers: headers,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return [];
    });
};

//政府計畫
export const getGovPlan = (businessId) => {
  return axios({
    proxy: true,
    method: "get",
    url: proxyUrl + `${baseUrl}/api/GovPlan/GetGovPlans/${businessId}`,
    headers: headers,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return [];
    });
};

//獲獎實績
export const getAwardPlan = (businessId) => {
  return axios({
    proxy: true,
    method: "get",
    url: proxyUrl + `${baseUrl}/api/Award/GetAwards/${businessId}`,
    headers: headers,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return [];
    });
};

//進出口額
export const getTradeAmount = (businessId) => {
  return axios({
    proxy: true,
    method: "get",
    url:
      proxyUrl + `${baseUrl}/api/ForeignTrade/GetForeignTrades/${businessId}`,
    headers: headers,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return [];
    });
};

//股市資訊
export const getStockInfo = (businessId) => {
  return axios({
    proxy: true,
    method: "get",
    url: proxyUrl + `${baseUrl}/api/Stock/GetStockItems/${businessId}`,
    headers: headers,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return [];
    });
};

//董監事資料
export const getBoardMemberInfo = (businessId) => {
  return axios({
    proxy: true,
    method: "get",
    url: proxyUrl + `${baseUrl}/api/BoardMember/GetBoardMembers/${businessId}`,
    headers: headers,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return [];
    });
};

//公司股市基本資料
export const getCompanyStockInfo = (businessId) => {
  return axios({
    proxy: true,
    method: "get",
    url: proxyUrl + `${baseUrl}/api/Stock/GetStockBasicInfo/${businessId}`,
    headers: headers,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return [];
    });
};

//公司股市體質
export const getCompanyStockPhysique = (businessId) => {
  return axios({
    proxy: true,
    method: "get",
    url: proxyUrl + `${baseUrl}/api/Stock/GetStockPhysique/${businessId}`,
    headers: headers,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return [];
    });
};

//股市指標(營業額Revenue,EPS,研發比例Research,毛利率GrossProfitMargin,稅後淨利率NetIncomeMargin,自由現金流量FreeCashFlow,ROE,股利殖利率DividendYield)(chart and List)
export const getStockIndex = (businessId) => {
  return axios({
    proxy: true,
    method: "get",
    url: proxyUrl + `${baseUrl}/api/Stock/GetStockIndex/${businessId}`,
    headers: headers,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return [];
    });
};
//股市指標(每月營收)
export const getStockMonthRevenue = (businessId) => {
  return axios({
    proxy: true,
    method: "get",
    url: proxyUrl + `${baseUrl}/api/Stock/GetStockMonthRevenue/${businessId}`,
    headers: headers,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return [];
    });
};

//股市趨勢(研發比例)
export const getTradeResearch = (businessId) => {
  return axios({
    proxy: true,
    method: "get",
    url: proxyUrl + `${baseUrl}/api/Stock/GetTrend_Research/${businessId}`,
    headers: headers,
  })
    .then((res) => {
      try {
        return res.data.data[0].dataPoints;
      } catch (e) {
        return res.data.length;
      }
    })
    .catch((err) => {
      return [];
    });
};

//股市趨勢(EPS)
export const getTradeEPS = (businessId) => {
  return axios({
    proxy: true,
    method: "get",
    url: proxyUrl + `${baseUrl}/api/Stock/GetTrend_EPS/${businessId}`,
    headers: headers,
  })
    .then((res) => {
      try {
        return res.data.data[0].dataPoints;
      } catch (e) {
        return res.data.length;
      }
    })
    .catch((err) => {
      return [];
    });
};

//股市趨勢(營業額);
export const getTradeRevenue = (businessId) => {
  return axios({
    proxy: true,
    method: "get",
    url: proxyUrl + `${baseUrl}/api/Stock/GetTrend_Revenue/${businessId}`,
    headers: headers,
  })
    .then((res) => {
      try {
        return res.data.data[0].dataPoints;
      } catch (e) {
        return res.data.length;
      }
    })
    .catch((err) => {
      return [];
    });
};

//新聞標籤 -- 動態資訊
export const getNewsTags = (businessId) => {
  return axios({
    proxy: true,
    method: "get",
    url: proxyUrl + `${baseUrl}/api/News/GetNewsTagCloud/${businessId}`,
    headers: headers,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return [];
    });
};

//新聞資料 -- 動態資訊
export const getNewsContents = (businessId) => {
  return axios({
    proxy: true,
    method: "get",
    url: proxyUrl + `${baseUrl}/api/News/GetNewsItems/${businessId}`,
    headers: headers,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return [];
    });
};

//各廠情資-營業登記項目
export const getOperationItems = (businessId) => {
  return axios({
    proxy: true,
    method: "get",
    url: proxyUrl + `${baseUrl}/api/BusiItem/GetBusiItems/${businessId}`,
    headers: headers,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return [];
    });
};

//專利資料
export const getPatentInfo = (businessId, year) => {
  return axios({
    proxy: true,
    method: "get",
    url: proxyUrl + `${baseUrl}/api/Patent/GetPatents/${businessId}/${year}`,
    headers: headers,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return [];
    });
};

//專利資料
export const getBranchInfo = (businessId) => {
  return axios({
    proxy: true,
    method: "get",
    url:
      proxyUrl + `${baseUrl}/api/Company/GetBranchCompany/${businessId}/true`,
    headers: headers,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return [];
    });
};

//產業指標-產業動態 取得四碼
export const getTypeFourCode = () => {
  return axios({
    proxy: true,
    method: "get",
    url: proxyUrl + `${baseUrl}/api/InduType/GetIndustryTypes_Four_Stat/`,
    headers: headers,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return [];
    });
};

//依據新聞標籤取得新聞資料
export const getNewsContentViaKryWords = (keyword) => {
  return axios({
    proxy: true,
    method: "get",
    url:
      proxyUrl +
      `${baseUrl}/api/News/GetNewsByKeywords/${encodeURI(keyword)}/2019-11-09`,
    headers: headers,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return [];
    });
};

//取得資料趨勢資料
export const getTradesInfo = (data) => {
  return axios({
    // proxy: true,
    method: "post",
    url: `${baseUrl}/api/MoeaDos/GetChart_Trend_MoeaDos_Industry`,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: localStorage.getItem("acToken"),
    },
    data: data,
  })
    .then((res) => {
      return res.data.data[0].dataPoints;
    })
    .catch((err) => {
      return [];
    });
};

//取得清單資料
export const getListInfo = (data) => {
  return axios({
    // proxy: true,
    method: "post",
    url: `${baseUrl}/api/MoeaDos/GetTopCompany_Industry`,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: localStorage.getItem("acToken"),
    },
    data: data,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return [];
    });
};

//取得最新數據
export const getNewsInfo = (data) => {
  return axios({
    proxy: true,
    method: "post",
    url: `${baseUrl}/api/MoeaDos/GetLastDatas_MoeaDos_Industry`,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: localStorage.getItem("acToken"),
    },
    data: data,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return [];
    });
};

//取得產業前十大廠商
export const getTopTenBusiness = (data) => {
  return axios({
    proxy: true,
    method: "post",
    url: `${baseUrl}/api/MoeaDos/GetTopCompany_Industry`,
    headers: {
      "Content-Type": "multipart/form-data",
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("acToken"),
    },
    data: data,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return [];
    });
};

//取得產業上市櫃廠商
export const getListedCompany = (data) => {
  return axios({
    proxy: true,
    method: "post",
    url: proxyUrl + `${baseUrl}/api/MoeaDos/GetListedCompany_Industry/`,
    headers: {
      "Content-Type": "multipart/form-data",
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("acToken"),
    },
    data: data,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return [];
    });
};

//取得專業研發申請情形 -- 全國
export const getPatentOnProcess = (data) => {
  return axios({
    proxy: true,
    method: "post",
    url: proxyUrl + `${baseUrl}/api/Patent/GetPatentDatas_Pubinvention/`,
    headers: {
      "Content-Type": "multipart/form-data",
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("acToken"),
    },
    data: data,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return [];
    });
};

//取得專業研發申請情形 -- 園區
export const getPatentOnProcessSection = (data, sectionId) => {
  return axios({
    proxy: true,
    method: "post",
    url: `${baseUrl}/api/InduParkSingle/GetPatentDatas_Pubinvention/${sectionId}`,
    headers: {
      "Content-Type": "multipart/form-data",
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("acToken"),
    },
    data: data,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return [];
    });
};
//取得專利研發情形（獲證實機）
export const getPatentFinished = (data) => {
  return axios({
    proxy: true,
    method: "post",
    url: proxyUrl + `${baseUrl}/api/Patent/GetPatentDatas_Certified`,
    headers: {
      "Content-Type": "multipart/form-data",
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("acToken"),
    },
    data: data,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return [];
    });
};

//取得專利研發情形（獲證實機） -- 全國
export const getPatentFinishedSection = (data, sectionId) => {
  return axios({
    proxy: true,
    method: "post",
    url:
      proxyUrl +
      `${baseUrl}/api/InduParkSIngle/GetPatentDatas_Certified/${sectionId}`,
    headers: {
      "Content-Type": "multipart/form-data",
      "Access-Control-Allow-Origin": "*",
      Authorization: localStorage.getItem("acToken"),
    },
    data: data,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return [];
    });
};

//取得全部園區
export const getAllPark = () => {
  return axios({
    proxy: true,
    method: "get",
    url: proxyUrl + `${baseUrl}/api/InduPark/GetInduParkEntities`,
    headers: headers,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return [];
    });
};

//取得全部產業2碼
export const getAllTypeTwo = () => {
  return axios({
    proxy: true,
    method: "get",
    url: proxyUrl + `${baseUrl}/api/InduType/GetIndustryTypes_Two`,
    headers: headers,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return [];
    });
};

//取得營收產業營運概況
export const getOperationData = (typeTwo) => {
  return axios({
    method: "get",
    url: `${baseUrl}/api/MoeaDos/GetStockCompanyOperations?IndustryCode_Two=${typeTwo}`,
    headers: headers,
  })
    .then((res) => {
      const sortData = [...res.data.data].sort((a, b) => {
        if (a.Variety > b.Variety) return -1;
        if (a.Variety < b.Variety) return 1;
      });
      res.data.data = sortData;
      let calcAbstract = {
        "up": 0,
        "down": 0,
        "balance": 0
      };
      let calcAccumulatedAbstract = {
        "up": 0,
        "down": 0,
        "balance": 0
      };
      for (let i = 0; i < res.data.data.length; i++) {
        if (res.data.data[i].Variety > 0) {
          calcAbstract.up++;
        } else if (res.data.data[i].Variety < 0) {
          calcAbstract.down++;
        } else {
          calcAbstract.balance++;
        }
      }
      for (let i = 0; i < res.data.data.length; i++) {
        if (res.data.data[i].Accumulated_Variety > 0) {
          calcAccumulatedAbstract.up++;
        } else if (res.data.data[i].Accumulated_Variety < 0) {
          calcAccumulatedAbstract.down++;
        } else {
          calcAccumulatedAbstract.balance++;
        }
      }
      return { "data": res.data, "calcAbstract": calcAbstract, "calcAccumulatedAbstract": calcAccumulatedAbstract  };
    })
    .catch((err) => {
      return [];
    });
};

//取得出口級距產業營運概況
export const getOperationExportData = (typeTwo) => {
  return axios({
    proxy: true,
    method: "get",
    url: proxyUrl + `${baseUrl}/api/MoeaDos/GetCompanyOperations_ForeignTrade?IndustryCode_Two=${typeTwo}&IsIncome=false&isStock=false`,
    headers: headers,
  })
    .then((res) => {
      const sortData = [...res.data.data].sort((a, b) => {
        if (a.Accumulated_Variety > b.Accumulated_Variety) return -1;
        if (a.Accumulated_Variety < b.Variety) return 1;
      });
      res.data.data = sortData.filter((item) => item.Accumulated_Variety != 'Infinity');
      let calcAbstract = {
        "up": 0,
        "down": 0,
        "balance": 0
      };
      let calcAccumulatedAbstract = {
        "up": 0,
        "down": 0,
        "balance": 0
      };
      for (let i = 0; i < res.data.data.length; i++) {
        if (res.data.data[i].Accumulated_Variety > 0) {
          calcAccumulatedAbstract.up++;
        } else if (res.data.data[i].Accumulated_Variety < 0) {
          calcAccumulatedAbstract.down++;
        } else {
          calcAccumulatedAbstract.balance++;
        }
      }
      return { "data": res.data, "calcAbstract": calcAbstract, "calcAccumulatedAbstract": calcAccumulatedAbstract  };
    })
    .catch((err) => {
      return [];
    });
};

//取得全部產業3碼
export const getAllTypeThree = (typeTwo) => {
  return axios({
    proxy: true,
    method: "get",
    url: proxyUrl + `${baseUrl}/api/InduType/GetIndustryTypes_Three/${typeTwo}`,
    headers: headers,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return [];
    });
};

//取得全部產業4碼
export const getAllTypeFour = (typeTwo, typeThree) => {
  return axios({
    proxy: true,
    method: "get",
    url:
      proxyUrl +
      `${baseUrl}/api/InduType/GetIndustryTypes_Four_Stat/${typeTwo}/${typeThree}`,
    headers: headers,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return [];
    });
};

//透過關鍵字取得統一編號

export const getFacNo = (keyword) => {
  return axios({
    proxy: true,
    method: "get",
    url:
      proxyUrl +
      `${baseUrl}/api/InduParkCompany/SearchCompanyListByKeyword/${encodeURI(
        keyword
      )}`,
    headers: headers,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return [];
    });
};

// 透過統一編號取得廠商清單
export const getCompanyListByBusinessNo = (BusinessNo) => {
  return axios({
    proxy: true,
    method: "get",
    url:
      proxyUrl +
      `${baseUrl}/api/InduParkCompany/SearchCompanyListByBussinessNo/${BusinessNo}`,
    headers: headers,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return [];
    });
};


//建立政府計畫
export const createGovPlan = (data) => {
  return axios({
    // proxy: true,
    method: "post",
    url: proxyUrl + `https://indupark.smartpark.com.tw/api/govplanschedule/createplan`,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded; charset=utf-8",
    },
    data: data,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return [];
    });
};

//取得所有政府計畫

export const getAllGovPlan = (keyword, category) => {
  return axios({
    proxy: true,
    method: "get",
    url: proxyUrl + `https://indupark.smartpark.com.tw/api/govplanschedule/GetAllPlans/`,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return [];
    });
};

//更新政府計畫
export const updateGovPlan = (data, projectId) => {
  return axios({
    method: "post",
    url:
      proxyUrl + `https://indupark.smartpark.com.tw/api/govplanschedule/UpdatePlan/${projectId}`,
    data: data,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return [];
    });
};

//刪除政府計畫
export const deleteGovPlan = (projectId, user) => {
  return axios({
    method: "get",
    url:
      proxyUrl +
      `https://indupark.smartpark.com.tw/api/govplanschedule/DeletePlan/${projectId}/${user}`,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return [];
    });
};

export const sendGovPlanInterestNotify = (data) => {
  return axios({
    method: "post",
    url:
      proxyUrl +
      `https://indupark.smartpark.com.tw/api/govplanschedule/PlanInterestNotify`,
    data: data,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return [];
    });
};

//取得政府計畫發送群組
export const getInduGroup = (keyword) => {
  return axios({
    method: "get",
    url: proxyUrl + `${loginbaseUrl}/api/govplanschedule/GetInduGroup/`,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return [];
    });
};

//建立政府計畫發發送群組
export const createInduGroup = (data) => {
  return axios({
    method: "post",
    url: proxyUrl + `${loginbaseUrl}api/govplanschedule/CreateInduGroup`,
    data: data,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return [];
    });
};

//更新政府計畫發發送群組
export const updateInduGroup = (groupId, data) => {
  return axios({
    method: "post",
    url:
      proxyUrl +
      `${loginbaseUrl}api/govplanschedule/UpdateInduGroup/${groupId}`,
    data: data,
  });
};

//刪除政府計畫發發送群組
export const deleteInduGroup = (groupId, user) => {
  return axios({
    method: "get",
    url:
      proxyUrl +
      `${loginbaseUrl}api/govplanschedule/DeleteInduGroup/${groupId}/${user}`,
  });
};

//依產業園區編號取得所有廠商名稱
export const getCompanyListByParkId = (parkId) => {
  return axios({
    method: "get",
    url:
      proxyUrl +
      `${loginbaseUrl}/api/govplanschedule/GetCompanyListByParkId/${parkId}`,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return [];
    });
};

//取得日誌檔
export const getDeliveryLog = () => {
  return axios({
    method: "get",
    url:
      proxyUrl +
      `${loginbaseUrl}/api/govplanschedule/GetDeliveryLog//%E6%94%BF%E5%BA%9C%E8%A8%88%E7%95%AB`,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return [];
    });
};

//將統一編號 array 轉換成 廠商編號 + 廠商名稱
export const listByBusinessId = (data) => {
  return axios({
    proxy: true,
    method: "post",
    url:
      proxyUrl +
      `${loginbaseUrl}/api/govplanschedule/GetCompanyListByBusinessId`,
    data: JSON.stringify(data),
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return [];
    });
};

//取得關鍵字 log
export const getPopularKeyWord = () => {
  const form = new FormData();
  form.append("Top", 3);
  form.append("Type", "Product");

  return axios({
    proxy: true,
    method: "post",
    url: proxyUrl + `${loginbaseUrl}}/api/ProductSearch/GetKeywordLog`,
    data: JSON.stringify(form),
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return [];
    });
};

//紀錄關鍵字 log
export const keyWordLogHandler = (data) => {
  return axios({
    proxy: true,
    method: "post",
    url: proxyUrl + `${loginbaseUrl}/api/ProductSearch/InsertKeywordLog`,
    data: data,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return [];
    });
};

//產品搜尋地圖
export const MapSearchHandler = (data) => {
  const headers = {
    Authorization: localStorage.getItem("acToken"),
    "Content-Type": "application/x-www-form-urlencoded",
  };

  return axios({
    proxy: true,
    method: "post",
    url: proxyUrl + `${baseUrl}/api/ProductSearch/Search`,
    data: data,
    headers: headers,
  });
};

//自訂廠商技術快搜
export const CustomizedSearchHandler = (data) => {
  const headers = {
    Authorization: localStorage.getItem("acToken"),
    "Content-Type": "application/x-www-form-urlencoded",
  };

  return axios({
    proxy: true,
    method: "post",
    url: proxyUrl + `${baseUrl}/api/CustomizedProductSearch/Search`,
    data: data,
    headers: headers,
  });
};

//學界搜尋地圖
export const EducationSearchHandler = (data) => {
  const headers = {
    Authorization: localStorage.getItem("acToken"),
    "Content-Type": "application/x-www-form-urlencoded",
  };

  return axios({
    proxy: true,
    method: "post",
    url: proxyUrl + `${baseUrl}/api/EduSearch/Search`,
    data: data,
    headers: headers,
  });
};

//學界搜尋詳細內容

export const EducationDetailHandler = (researchNo) => {
  const headers = {
    Authorization: localStorage.getItem("acToken"),
    "Content-Type": "application/x-www-form-urlencoded",
  };

  return axios({
    proxy: true,
    method: "get",
    url: proxyUrl + `${baseUrl}/api/EduSearch/Detail/${researchNo}`,
    headers: headers,
  });
};

//廠商需求查詢（內部用）
export const AuthQueryHandler = (data) => {
  return axios({
    proxy: true,
    method: "post",
    url: proxyUrl + `${loginbaseUrl}api/CompanyReq/QueryCompanyReq`,
    data: data,
  });
};

//廠商需求查詢（公開）
export const NoAuthQueryHandler = (data) => {
  return axios({
    proxy: true,
    method: "post",
    url: proxyUrl + `${loginbaseUrl}/api/CompanyReq/QueryCompanyReq_Public`,
    data: data,
  });
};

//廠商需求查詢（內部用）
export const ClientRequestHandler = (data) => {
  return axios({
    proxy: true,
    method: "post",
    url: proxyUrl + `${loginbaseUrl}/api/CompanyReq/InsertCompanyReq`,
    data: data,
  });
};

//取得案件列表
export const GetProjectHandleStatus = (caseId) => {
  return axios({
    method: "get",
    url: proxyUrl + `${loginbaseUrl}/api/CompanyReq/ListCaseNotes/${caseId}`,
  });
};

//新增案件列表
export const UpdateProjectHandleStatus = (caseId, data) => {
  return axios({
    proxy: true,
    method: "post",
    url: proxyUrl + `${loginbaseUrl}api/CompanyReq/InsertCaseNote/${caseId}`,
    data: data,
  });
};

//執行結案
export const CaseClosedHandleStatus = (caseId, data) => {
  return axios({
    proxy: true,
    method: "post",
    url: proxyUrl + `${loginbaseUrl}/api/CompanyReq/EndCompanyReq/${caseId}`,
    data: data,
  });
};

//取得所有服務歷程
export const GetServiceHistory = (BusinessNo, UserID) => {
  return axios({
    method: "get",
    url:
      proxyUrl +
      `${loginbaseUrl}api/ServiceHistory/Query/${BusinessNo}/${btoa(UserID)}`,
  });
};

//執行分案
export const ForwardProject = (data, caseId) => {
  return axios({
    proxy: true,
    method: "post",
    url: proxyUrl + `${loginbaseUrl}/api/CompanyReq/ForwardProject/${caseId}`,
    data: data,
  });
};

//取得分案列表
export const getHandleHistory = () => {
  const uid = localStorage.getItem("uid");

  return axios({
    proxy: true,
    method: "get",
    url: proxyUrl + `${loginbaseUrl}/api/CompanyReq/HandleHistory/${btoa(uid)}`,
  });
};

//透過專家ID取得案件列表;
export const getCasesByExpertId = (expertId, data) => {
  const formData = new FormData();
  formData.append("password", expertId);
  if (data) {
    formData.append("KeyWord", data.KeyWord);
    formData.append("StartDate", data.StartDate);
    formData.append("EndDate", data.EndDate);
    formData.append("HandleType", data.HandleType);
  }

  return axios({
    proxy: true,
    method: "POST",
    url: proxyUrl + `${loginbaseUrl}/api/CompanyReq/GetProjectsByTalentsPass`,
    data: formData,
  })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};

export const getReferenceInfo = () => {
  return axios({
    method: "get",
    url: `https://mirdc-system-default-rtdb.firebaseio.com/referenceInfo.json`,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return [];
    });
};

export const getSearchHistoryData = () => {
  return axios({
    proxy: true,
    method: "get",
    url: `${loginbaseUrl}admin/GetLastCompanyInfoLog/${Base64.encode(
      localStorage.getItem("uid")
    )
      .replace(/[+]/g, "-")
      .replace(/[/]/g, "_")}`,
  })
    .then((res) => {
      return res.data.data;
    })
    .catch((err) => {
      return "取得歷史搜尋資料失敗。";
    });
};

//取得廢棄物前5大排行
export const TopFiveWasteDisposalData = (parkId) => {
  return axios({
    proxy: true,
    method: "get",
    url:
      proxyUrl +
      `${baseUrl}/api/InduParkSingle/GetPollutionRank/Waste/${parkId}`,
    headers: headers,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return "error";
    });
};

//取得廢棄物廠商追溯表
export const TopFiveWasteDisposalDataList = (wasteNumber, parkId) => {
  return axios({
    proxy: true,
    method: "get",
    url:
      proxyUrl +
      `${baseUrl}/api/InduParkSingle/GetPollutionRankList/Waste/${wasteNumber}/${parkId}`,
    headers: headers,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return "error";
    });
};

//取得溫室氣體
export const getCompanyGHGInfo = (businessId) => {
  return axiosInstance({
    proxy: true,
    method: "get",
    url: `${baseUrl}/api/Company/GetCompanyGHG_Info/${businessId}`,
    headers: InduParkWebAcTokenheaders
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return [];
    });
};