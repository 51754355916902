import React from "react";
import { useSelector } from "react-redux";
import Box from "@material-ui/core/Box";

import MobileGrid from "../../MobileGridV2";
import * as Style from "../../styles";
import { Card } from "react-bootstrap";
import Swal from "sweetalert2";
import styled from "styled-components";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Trademark = ({ data, referenceType }) => {
  const { referenceInfo } = useSelector((state) => state.app);
  const toggleCard = {};
  const openMarkerInfoAlert = (imgUrl, imgName) => {
    Swal.fire({
      title: imgName,
      imageUrl: imgUrl,
      imageAlt: imgName
    });
  };
  const openReferenceInfoAlert = (type) => {
    let content = "";
    if (referenceInfo[type] === "") {
      console.log("I was called!");
      Swal.fire({
        title: "引用資料來源",
        text: "資料正在建置中 ... ",
      });
    } else {
      try {
        referenceInfo[type]
          .split("。")
          .map(
            (referenceItem) =>
              (content +=
                "<p style='text-align:left'>" + referenceItem + "</p>")
          );
        Swal.fire({
          title: "引用資料來源",
          html: content,
        });
      } catch (err) {
        Swal.fire({
          title: "引用資料來源",
          text: "資料正在建置中 ... ",
        });
      }
    }
  };

  return (
    <div>
      <Card.Header className="card-title">
        <TradeMarker>
            {"註冊商標"}
            {toggleCard && referenceType && ( // 如果有 toggleCard 就顯示（位置差異）
              <button
                title={`開啟${"註冊商標"}引用資料來源彈出視窗`}
                style={{ color: "white", borderRadius: "100%", marginLeft: "4px" }}
                onClick={() => openReferenceInfoAlert(referenceType)}  
              > 
                <FontAwesomeIcon
                  className="icon-reference"
                  icon={faInfoCircle}
                />
              </button>
            )}
        </TradeMarker>
      </Card.Header>
      <MobileGrid>
        {data.map((item, index) => (
          <Style.MobileGridContainerItem
            item
            component={Style.MobileGridItem}
            key={item.PatentNo + index}
          >
            <Style.MobileGridItemList>
              <Style.MobileGridItemListLabel>申請案號 </Style.MobileGridItemListLabel>
              <Style.MobileGridItemListText>
                {item.CaseNo ? item.CaseNo : "無相關資料"}
              </Style.MobileGridItemListText>
            </Style.MobileGridItemList>
            <Style.MobileGridItemList className="patent-name">
              <Style.MobileGridItemListLabel>商標名稱 </Style.MobileGridItemListLabel>
              <Style.MobileGridItemListText onClick={() => openMarkerInfoAlert(`https://opendataservice.smartpark.com.tw/Trademark_icon/${item.FilePath}`, item.Trademark_Name)}>
                {item.Trademark_Name ?
                  <Box style={{ display: "flex", alignItems: "center", color: "#2b91ff" }} >
                    {item.Trademark_Name}
                  </Box>
                  : "無相關資料"}
              </Style.MobileGridItemListText>
            </Style.MobileGridItemList>
            <Style.MobileGridItemList>
              <Style.MobileGridItemListLabel>權利期間(起) </Style.MobileGridItemListLabel>
              <Style.MobileGridItemListText>
                {item.SDate ? item.SDate : "無相關資料"}
              </Style.MobileGridItemListText>
            </Style.MobileGridItemList>
            <Style.MobileGridItemList>
              <Style.MobileGridItemListLabel>權利期間(迄) </Style.MobileGridItemListLabel>
              <Style.MobileGridItemListText>
                {item.EDate ? item.EDate : "無相關資料"}
              </Style.MobileGridItemListText>
            </Style.MobileGridItemList>
          </Style.MobileGridContainerItem>
        ))}
      </MobileGrid>
    </div>
  );
};

const TradeMarker = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
`;

export default React.memo(Trademark);
