import * as actionTypes from '../actions/actionTypes';

const initState = {
    applyFileInfo:"",
    getApplyFileLoading:"",
    applyFileDropdownList:"",
    getApplyFileDropdownListLoading:"",
    error:""
};

export default (state = initState, action) => {
  switch (action.type) {
    case actionTypes.START_GET_APPLY_FILE_INFO:
        return {
            ...state,
            getApplyFileLoading: true,
        }
    case actionTypes.GET_APPLY_FILE_INFO_SUCCESS:
        return {
            ...state,
            applyFileInfo: action.applyFileInfo,
            getApplyFileLoading: false
        }
    case actionTypes.GET_APPLY_FILE_INFO_FAIL:
        return {
            ...state,
            getApplyFileLoading: false,
            err: action.err
        }
    case actionTypes.START_GET_APPLY_FILE_DROPDOWN_LIST:
        return {
            ...state,
            getApplyFileDropdownListLoading: true,
        }
    case actionTypes.GET_APPLY_FILE_DROPDOWN_LIST_SUCCESS:
        return {
            ...state,
            applyFileDropdownList: action.applyFileDropdownList,
            getApplyFileDropdownListLoading: false
        }
    case actionTypes.GET_APPLY_FILE_DROPDOWN_LIST_FAIL:
        return {
            ...state,
            getApplyFileDropdownListLoading: false,
            err: action.err
        }
    default:
      return state;
  }
};
