import React, { useState, useEffect } from "react";
import { useQRCode } from "react-qrcodes";
import { useSelector } from "react-redux";
import * as Style from "./styles";

import "../SharablePage/styles.scss";

export default (props) => {
  const { handleCloseQRcode } = props;
  const { uploadVisitQuestionRelease } = useSelector((state) => state.question);

  const [qrRef] = useQRCode({
    text: `${window.location.origin}/limited-visit/${uploadVisitQuestionRelease}`,
    options: {
      level: "H",
      scale: 8,
      width:
        window.innerWidth < 768
          ? window.innerWidth * 0.6
          : 250,
    },
  });

  var Today = new Date();
  const DueDate =
    Today.getFullYear() +
    " 年 " +
    (Today.getMonth() + 1) +
    " 月 " +
    (Today.getDate() + 3) +
    " 日";

  return (
    <Style.SignaturePadModalContainer
      margin={"30px 25%"}
      width={"50%"}
      height={"80%"}
    >
      <Style.SignaturePadContainer color={"#f0eaea"} borderRadius={"10px"} margin={"0px 10px"}>
        <Style.SignaturePadItem>
          <h4>透過掃描此 QR code 分享問卷</h4>
        </Style.SignaturePadItem>
        <Style.SignaturePadItem height={"60%"}>
          <canvas ref={qrRef} />
        </Style.SignaturePadItem>

        <Style.SignaturePadItem
          justifyContent={"space-evenly"}
          flexDirection={"column"}
        >
          <h4>使用期限至 {`${DueDate}`}</h4>
          <p>若無法正常掃描 QR code 請增加螢幕亮度。</p>
        </Style.SignaturePadItem>
      </Style.SignaturePadContainer>

      <Style.QuestionBasicButtonContainer>
        <Style.QuestionBasicButton
          backgroundColor={"#2a7ac6"}
          onClick={handleCloseQRcode}
        >
          確定
        </Style.QuestionBasicButton>
      </Style.QuestionBasicButtonContainer>
    </Style.SignaturePadModalContainer>
  );
};
