import React from "react";
import styled from "styled-components";
import { useLocation } from "react-router";
import { useSelector } from "react-redux";
import Tooltip from "@mui/material/Tooltip";

import * as Style from "./styles";
import line from "../../assets/images/line.png";
import facebook from "../../assets/images/facebook.png";

export default (props) => {
  const { viewCount } = useSelector((state) => state.app);
  const { version, cachedVersion } = useSelector((state) => state.app);
  const url = useLocation().pathname.split("/")[1];
  const currentYear = new Date().getFullYear();
  const chemicalMaterialUrl = "cmhleialmaricate";

  if (
    url === "map-search" ||
    url === "client-response" ||
    url === "controlled-material" ||
    url === "supply-chain" ||
    url === chemicalMaterialUrl
  ) {
    return <div />;
  } else {
    return (
      <Style.Footer {...props} isFixed={url === "factory_search"}>
        <Style.FooterText>
          經濟部產業園區管理局委辦產業園區價值形塑暨廠商情資加值計畫 ©{" "}
          {currentYear} All Rights Reserved | v.{cachedVersion}{" "}
          <span>
            {version !== cachedVersion && "(您目前使用的是較舊的版本)"}
          </span>{" "}
          | 瀏覽人次 : {viewCount} |
        </Style.FooterText>
        <IconContainer>
          <Tooltip title={"開啟產業情資通臉書專頁"} placement="top">
            <Icon
              src={facebook}
              alt={"Facebook icon"}
              onClick={() => {
                window.open(
                  "https://www.facebook.com/%E7%94%A2%E6%A5%AD%E6%83%85%E8%B3%87%E9%80%9Asmartpark-108158701085956",
                  "_blank"
                );
              }}
            />
          </Tooltip>
          <Tooltip title={"加入產業情資通line@"} placement="top">
            <Icon
              src={line}
              alt={"Line icon"}
              onClick={() => {
                window.open(
                  "https://line.me/R/ti/p/@117uphax?from=page",
                  "_blank"
                );
              }}
            />
          </Tooltip>
        </IconContainer>
      </Style.Footer>
    );
  }
};

const IconContainer = styled.div`
  padding-left: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Icon = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 5px;
  cursor: pointer;
`;
