import * as actionTypes from "./actionTypes";
import * as actionType from "../actionTypes/app";
import {
  getViewerCounts,
  getReferenceInfo,
  getSearchHistoryData,
} from "../../utility/api";
import {
  fetchVerifiedCompanyList,
  fetchCurrentVersion,
} from "../../utility/appApi";

export const innerWidthChanging = (innerWidth) => {
  return { type: actionTypes.INNER_WIDTH_CHANGING, innerWidth: innerWidth };
};

export const startGetViewCount = () => {
  return {
    type: actionTypes.START_GET_VIEW_COUNT,
  };
};

export const getViewerCountSuccess = (data) => {
  return {
    type: actionTypes.GET_VIEW_COUNT_SUCCESS,
    viewCount: data,
  };
};

export const getViewerCountFail = () => {
  return {
    type: actionTypes.GET_VIEW_COUNT_FAIL,
  };
};

export const getViewerCountsHandler = () => async (dispatch) => {
  try {
    dispatch(startGetViewCount());
    const ViewCount = await getViewerCounts();
    if (ViewCount.status === 200) {
      dispatch(getViewerCountSuccess(ViewCount.data));
    } else {
      dispatch(getViewerCountFail());
    }
  } catch (error) {
    console.log(error);
    if (error && error.status === 401) {
      dispatch(getViewerCountFail(error));
    }
  }
};

export const startGetReferenceInfo = () => {
  return {
    type: actionType.START_GET_REFERENCE_INFO,
  };
};

export const getReferenceInfoSuccess = (data) => {
  return {
    type: actionType.GET_REFERENCE_INFO_SUCCESS,
    data: data,
  };
};

export const getReferenceInfoFail = (err) => {
  return {
    type: actionType.GET_REFERENCE_INFO_FAIL,
    err: err,
  };
};

export const getReferenceInfoHandler = () => {
  return (dispatch) => {
    dispatch(startGetReferenceInfo());
    getReferenceInfo()
      .then((res) => {
        if (res) {
          dispatch(getReferenceInfoSuccess(res));
        } else {
          dispatch(getReferenceInfoFail("資料整理中。"));
        }
      })
      .catch((err) => {
        dispatch(getReferenceInfoFail(err));
      });
  };
};

export const startGetSearchHistory = () => {
  return {
    type: actionType.START_GET_SEARCH_HISTORY,
  };
};

export const getSearchHistorySuccess = (data) => {
  return {
    type: actionType.GET_SEARCH_HISTORY_SUCCESS,
    data: data,
  };
};

export const getSearchHistoryFail = (err) => {
  return {
    type: actionType.GET_SEARCH_HISTORY_FAIL,
    err: err,
  };
};

export const getSearchHistoryHandler = () => {
  return (dispatch) => {
    dispatch(startGetReferenceInfo());
    getSearchHistoryData()
      .then((res) => {
        if (res) {
          dispatch(getSearchHistorySuccess(res));
        } else {
          dispatch(getSearchHistoryFail("取得歷史搜尋資料失敗。"));
        }
      })
      .catch((err) => {
        dispatch(getSearchHistoryFail(err));
      });
  };
};

export const startGetCompanyList = () => {
  return {
    type: actionType.START_GET_VERIFIED_COMPANY_LIST,
  };
};

export const getCompanyListSuccess = (data) => {
  return {
    type: actionType.GET_VERIFIED_COMPANY_LIST_SUCCESS,
    data: data,
  };
};

export const getCompanyListFail = (err) => {
  return {
    type: actionType.GET_VERIFIED_COMPANY_LIST_FAIL,
    err: err,
  };
};

export const getCompanyListHandler = () => {
  return (dispatch) => {
    const userUid = localStorage.getItem("uid");
    dispatch(startGetCompanyList());
    fetchVerifiedCompanyList(userUid)
      .then((res) => {
        if (res) {
          try {
            const len = res.data.length;
            dispatch(getCompanyListSuccess(res.data));
          } catch (err) {
            dispatch(getCompanyListSuccess(res.data.length));
          }
        } else {
          dispatch(getCompanyListFail("取得使用者統編失敗失敗。"));
        }
      })
      .catch((err) => {
        dispatch(getCompanyListFail(err));
      });
  };
};

const startFetchCurrentVersion = () => {
  return {
    type: actionType.START_FETCH_CURRENT_VERSION,
  };
};

const fetchCurrentVersionSuccess = (version) => {
  return {
    type: actionType.FETCH_CURRENT_VERSION_SUCCESS,
    version: version,
  };
};

const fetchCurrentVersionFail = () => {
  return {
    type: actionType.FETCH_CURRENT_VERSION_FAIL,
  };
};

export const handleFetchVersion = () => {
  return (dispatch) => {
    dispatch(startFetchCurrentVersion());
    fetchCurrentVersion()
      .then((res) => {
        if (res) {
          try {
            dispatch(fetchCurrentVersionSuccess(res.Version));
          } catch (err) {
            dispatch(fetchCurrentVersionFail(res.data.length));
          }
        } else {
          dispatch(fetchCurrentVersionFail("取得版本失敗。"));
        }
      })
      .catch((err) => {
        dispatch(fetchCurrentVersionFail(err));
      });
  };
};
