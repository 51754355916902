import React from "react";
import styled from "styled-components";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Collapse from "@material-ui/core/Collapse";
import MenuItem from "@material-ui/core/MenuItem";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";

import Spinner from "../../components/Spinner/Spinner";
import { parksWithOutExportZone as parks } from "../../constants/parks";
import { updateUserInfoHandler, getUserInfoHandler } from "../../store/actions/users";
import * as theme from "../../assests/styles/theme";
import { Typography } from "@material-ui/core";

export default ({ isOpen, closeModal }) => {
  const IS_NUMBER = /^\d+$/;
  const dispatch = useDispatch();
  const { userInfo, getUserInfoLoading } = useSelector((state) => state.user);
  const [state, setState] = React.useState({
    isModalOpen: true,
    isClicked: false,
    account: "",
    name: "",
    induPark: "",
    company: "",
    jobTitle: "",
    phone: "",
    department: "",
    email: "",
    companyId: "",
  });


  const handleUpdate = (e) => {
    const { name, value } = e.target;
    setState((state) => ({ ...state, [name]: value }));
  };

  React.useEffect(() => {
    if (isOpen) {
      setState((state) => ({ ...state, isModalOpen: true }));
    } else {
      setState((state) => ({ ...state, isModalOpen: false, isClicked: false }));
    }
  }, [isOpen]);

  React.useEffect(() => {
    //fetchUserInfo
    const uid = localStorage.getItem("uid");
    if (uid) {
      dispatch(getUserInfoHandler(uid));
    }
  }, [isOpen]);

  React.useEffect(() => {
    //fetch user info from redux;
    if (userInfo) {
      const data = {
        account: userInfo.UserId || "",
        name: userInfo.Name || "",
        induPark: userInfo.ParkId || "",
        company: userInfo.CompanyName || "",
        jobTitle: userInfo.JobTitle || "",
        phone: userInfo.Phone || "",
        department: userInfo.Department || "",
        email: userInfo.Email || "",
        companyId: userInfo.BusinessNo || "",
      };

      setState((state) => ({ ...state, ...data }));
    }
  }, [userInfo]);

  const handleUpdateUserInfo = () => {
    setState((state) => ({ ...state, isClicked: true }));

    if(state.name && state.account && (state.company || state.companyId)){
    const params = {};
      params.name = state.name;
      params.account = state.account;
      params.company = state.company;
      params.phone = state.phone;
      params.department = state.department;
      params.jobTitle = state.jobTitle;
      params.email = state.email;
      params.businessNo = state.companyId;
      if(!state.induPark){
        params.parkId = "other"
      }else{
        params.parkId = state.induPark;
      }
      Swal.fire({
          text: `確認更新使用者資訊?`,
          showCancelButton: true,
          confirmButtonText: '確認',
          cancelButtonText: '取消'
      }).then((res) => {
        if(res.value){
          dispatch(updateUserInfoHandler(params));
          closeModalHandler();
        }
      })
    }
 
  };

  const closeModalHandler = () => {
    closeModal();
    setState((state) => ({ ...state, isModalOpen: false, isClicked: false }));
  };

  return (
    <React.Fragment>
        <Backdrops isModalOpen={state.isModalOpen}/>
        <AuthControllerWrapper isModalOpen={state.isModalOpen}>
          <CloseContainer onClick={closeModalHandler}>
            <Icon>close</Icon>
          </CloseContainer>
          <UpdateUserTitleContainer>
            <UpdateUserWrapperTitleHeadText>
              修改使用者資訊
            </UpdateUserWrapperTitleHeadText>
          </UpdateUserTitleContainer>
          <UserAccount>帳號：{state.account}</UserAccount>
          {getUserInfoLoading ? (
            <UpdateUserContainer>
              <Spinner />
            </UpdateUserContainer>
          ) : (
            <UpdateUserContainer>
              <Inputs
                id="select"
                label="所屬工業區"
                variant="outlined"
                size="small"
                value={state.induPark}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Icon>construction</Icon>
                    </InputAdornment>
                  ),
                }}
                onChange={handleUpdate}
                select
                name={"induPark"}
              >
                <MenuItem value="">請選擇</MenuItem>
                {parks.map((park) => (
                  <MenuItem key={park.ParkId} value={park.ParkId}>
                    {park.Name_CH}
                  </MenuItem>
                ))}
                <MenuItem key={"other"} value={"other"}>
                  其他
                </MenuItem>
              </Inputs>
              <Inputs
                label="E-mail"
                variant="outlined"
                size="small"
                value={state.email}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Icon>email</Icon>
                    </InputAdornment>
                  ),
                }}
                onChange={handleUpdate}
                name={"email"}
                disabled
              />
              <Inputs
                label="姓名"
                variant="outlined"
                placeholder="請輸入姓名。"
                size="small"
                value={state.name}
                InputProps={{
                  autoComplete: "off",
                  startAdornment: (
                    <InputAdornment position="start">
                      <Icon>person_pin</Icon>
                    </InputAdornment>
                  ),
                }}
                onChange={handleUpdate}
                name={"name"}
              />
              <Verification
                in={state.isClicked && !state.name}
                text={"請填寫姓名"}
              />
              <Inputs
                label="聯絡電話"
                placeholder="請輸入聯絡電話或手機。"
                variant="outlined"
                size="small"
                value={state.phone}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Icon>call</Icon>
                    </InputAdornment>
                  ),
                }}
                onChange={handleUpdate}
                name={"phone"}
              />
              <Inputs
                label="單位名稱"
                placeholder="請輸入所屬單位名稱，例：中國鋼鐵股份有限公司。"
                variant="outlined"
                size="small"
                value={state.company}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Icon>apartment</Icon>
                    </InputAdornment>
                  ),
                }}
                onChange={handleUpdate}
                name={"company"}
              />
              <Verification
                in={state.isClicked && !state.company}
                text={"請填寫單位名稱"}
              />
              <Inputs
                label="單位統編"
                placeholder="請輸入單位統編，例:00011203"
                variant="outlined"
                size="small"
                value={state.companyId}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Icon>apartment</Icon>
                    </InputAdornment>
                  ),
                }}
                onChange={handleUpdate}
                name={"companyId"}
              />
              <Verification
                in={state.isClicked && !state.company}
                text={"請輸入單位統編"}
              />
              <Verification
                in={
                  state.isClicked &&
                  !IS_NUMBER.test(state.companyId) &&
                  state.companyId
                }
                text={"統一編號只能為數字"}
              />
              <Verification
                in={
                  state.isClicked &&
                  state.companyId.length !== 8 &&
                  state.companyId
                }
                text={"統一編號必需為8位數字"}
              />
              <Inputs
                label="所屬部門"
                placeholder="請輸入所屬部門。"
                variant="outlined"
                size="small"
                value={state.department}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Icon>work</Icon>
                    </InputAdornment>
                  ),
                }}
                onChange={handleUpdate}
                name={"department"}
              />
              <Inputs
                label="職位"
                placeholder="請輸入職位。"
                variant="outlined"
                size="small"
                value={state.jobTitle}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Icon>supervisor_account</Icon>
                    </InputAdornment>
                  ),
                }}
                onChange={handleUpdate}
                name={"jobTitle"}
              />
              <ButtonContainer>
                <Buttons 
                active={state.name && state.account && (state.company || state.companyId)}
                onClick={handleUpdateUserInfo}>確認修改</Buttons>
              </ButtonContainer>
            </UpdateUserContainer>
          )}
        </AuthControllerWrapper>
    </React.Fragment>
  );
};

const Verification = ({ isOpen, text }) => {
  return (
    <Collapse in={isOpen}>
      <VerificationTextContainer>
        <VerificationTex>{text}</VerificationTex>
      </VerificationTextContainer>
    </Collapse>
  );
};

const Backdrops = styled.div`
  width: 100vw;
  height: 100vh;
  position: absolute;
  z-index: 1200;
  background-color: rgba(0, 0, 0, 0.7);
  top: 0px;
  display: ${(p) => (p.isModalOpen ? "block" : "none")};
  transition: 0.2s display ease-in-out;
  
  //手機版沒有修改使用者資料
  @media (max-width: 768px) {
    display: none;
  }
`;

const AuthControllerWrapper = styled.div`
  position: absolute;
  width: 30%;
  margin: 3% 35% 0px 35%;
  min-height: 100px;
  top:${(p) => (p.isModalOpen ? "0px" : "-130vh")};
  opacity:${p => p.isModalOpen ? 1 : 0};
  display:${p => p.isModalOpen ? "block" : "none"};
  z-index: 1201;
  background-color: #fff;
  transition: 0.2s top linear;
  padding: 15px 30px 30px 30px;
  border-radius: 20px;
  transition: 0.5s top linear;

  //手機版沒有修改使用者資料
  @media (max-width: 768px) {
    display: none;
  }
`;

const CloseContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;

  .material-icons {
    cursor: pointer;
  }
`;

const UpdateUserTitleContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

const UpdateUserWrapperTitleHeadText = styled.h2`
  color: ${theme.secondaryBlack};
  font-size: 2.25rem;
  font-family: ${theme.primaryFont} !important;
  font-weight: 600;
`;
const UpdateUserWrapperText = styled.p`
  color: ${theme.secondaryBlack};
  font-family: ${theme.primaryFont} !important;
  > span {
    color: var(--main-color);
    transition: 0.5s;
    &:hover {
      transition: 0.5s;
      cursor: pointer;
      color: #757575;
    }
  }
`;

const UpdateUserputContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin: 10px 0px;
`;

const UpdateUserContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 0px;
`;

const Inputs = styled(TextField)`
  margin: 10px 0px !important;
  .MuiFormLabel-root.Mui-focused {
    color: var(--main-color);
  }
  .MuiInputBase-root.Mui-focused {
    .MuiOutlinedInput-notchedOutline {
      border-color: var(--main-color);
    }
  }
`;

const Buttons = styled(Button)`
  width: 100%;
  height: 40px !important;
  border-radius: 10px !important;
  background-color: ${(p) =>
    p.active ? theme.linkBlue : theme.disabledGray} !important;
  font-size: 1rem !important;
  font-weight: 500 !important;
  color: ${theme.white} !important;
  transition: 0.5s;

  &:hover {
    cursor: ${(p) => (p.active ? "pointer" : "not-allowed")};
    transition: 0.5s;
    background-color: ${(p) => (p.active ? "pointer" : "not-allowed")};
  }
`;
const ButtonContainer = styled.div``;

const VerificationTextContainer = styled.div`
  padding: 5px 0px;
`;

const VerificationTex = styled.p`
  font-size: 0.625rem;
  color: red;
`;

const UserAccount = styled(Typography)`
  margin-top: 8px !important;
`;
