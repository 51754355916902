import React from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

import recycle_mobile from "../assets/images/recycle_mobile.png";
import lightball from "../assets/images/lightball.png";
import wasted_clean_icon from "../assets/images/wasted_clean_icon.png";
import wasted_types_icon from "../assets/images/wasted_types_icon.png";
import trash from "../assets/images/trash.png";

import * as theme from "../assets/theme";

const WastedLandingPage = () => {
  const { innerWidth } = useSelector((state) => state.app);


  if(innerWidth < 550){
    return (
        <WastedLandingPageWrapper>
        <ButtonContainer>
        <Button 
            logo={wasted_types_icon}
            alt={"廢棄物快搜icon"} 
            description={"快速找到工業區R類（可再利用）、D類（事業廢棄物）廢棄物申報狀況。"}
            button_text={"廢棄物申報概況"} 
            url={"controlled-material"}
            width={32}
         />
          <Button 
            logo={wasted_clean_icon}
            alt={"廢棄物清理快搜icon"} 
            description={"快速找到全國廢棄物清運機構資料，包含分佈、處理量能等。"}
            button_text={"廢棄物清運機構"} 
            url={"wasted_clean"}
            width={26}
         />
        </ButtonContainer>
        <LightBallWithTrashContainer>
          <LightBallWithTrashImage src={lightball} />
        </LightBallWithTrashContainer>
        <TrashContainer  width={5} right={5} top={15} rotate={45}>
            <TrashImage src={trash}/> 
        </TrashContainer>
      </WastedLandingPageWrapper>
    )

  }

  return (
    <WastedLandingPageWrapper>
      <CenterArea>
        <CenterAreaHeadContainer>
          <CenterAreaHeadText>請選擇欲查詢的項目</CenterAreaHeadText>
          <CenterAreaHeadSubText>
            本區介接<strong>行政院環境保護署</strong>-廢棄物申報及清除機構資料，提供『 廢棄物申報概況 』、『 廢棄物清運機構 』查詢服務。
          </CenterAreaHeadSubText>
        </CenterAreaHeadContainer>
        <ButtonContainer>
            <Button 
            logo={wasted_types_icon}
            alt={"廢棄物快搜icon"} 
            description={<>
              <p>快速找到工業區R類（可再利用）、D類（事業廢棄物）廢棄物申報狀況。</p>
              資料來源 :  
              <a class="link" href="https://prtr.epa.gov.tw/" target="_blank" rel="noopener noreferrer" onClick={(e) => {
                e.stopPropagation();
              }} >環保署列管汙染源查詢系統</a>
            </>}
            button_text={"廢棄物申報概況"} 
            url={"controlled-material"}
            width={15}
         />
          <Button 
            logo={wasted_clean_icon}
            alt={"廢棄物清理快搜icon"} 
            description={<>
              <p>快速找到全國廢棄物清運機構資料，包含分佈、處理量能等。</p>
              資料來源 : 
              <a class="link" href="https://waste1.epa.gov.tw/Grant/GS-UC60/QryGrantData.aspx" target="_blank" rel="noopener noreferrer" onClick={(e) => {
                e.stopPropagation();
              }} >環保署許可核發證照查詢網站</a>
            </>}
            button_text={"廢棄物清運機構"} 
            url={"wasted_clean"}
            width={11}
         />
        </ButtonContainer>
      </CenterArea>
      <RecycleImageContainer>
        <RecycleImag src={recycle_mobile} />
      </RecycleImageContainer>
      <LightBallWithTrashContainer>
        <LightBallWithTrashImage src={lightball} />
      </LightBallWithTrashContainer>
      <TrashContainer  width={10} right={5.5} top={5}>
          <TrashImage src={trash}/> 
      </TrashContainer>
      <TrashContainer  width={5} right={5} top={15} rotate={45}>
          <TrashImage src={trash}/> 
      </TrashContainer>
    </WastedLandingPageWrapper>
  );
};

const Button = ({logo, alt, description, button_text, url, width}) => {
    const history = useHistory();

    const handleDirectToPage = () => {
        history.push(`/${url}`)
    }

    return (
        <ButtonItem onClick={handleDirectToPage}>
          <ButtonIconContainer>
            <ButtonIcon width={width} src={logo} alt={alt}/>
          </ButtonIconContainer>
          <ButtonMainTextContainer>
          <ButtonMainText>{button_text}</ButtonMainText>
        </ButtonMainTextContainer>
        <ButtonDescriptionText>{description}</ButtonDescriptionText>
      </ButtonItem>
    )

}

const WastedLandingPageWrapper = styled.div`
  max-width: 100vw;
  height: calc(100vh - 114px);
  transform: translateY(114px);
  position: relative;
  display: flex;
  justify-content: center;
  background-color: ${theme.background_color};
  overflow: hidden;


  @media(max-width: 550px){
    height: calc(100vh - 150px);
    transform: translateY(70px);
    overflow: hidden;
    padding-bottom: 20px;
  }
`;

const RecycleImageContainer = styled.div`
  width: 20vw;
  position: absolute;
  left: -10vw;
  top: 12vh;
`;

const RecycleImag = styled.img`
  width: 100%;
  transform: rotate(6deg);
  opacity: 0.8;
`;

const LightBallWithTrashContainer = styled.div`
  width: 20vw;
  position: absolute;
  right: -10vw;
  top: 10vh;

  @media(max-width: 550px){
    width: 80vw;
    right: -40vw;
    top: 40vh;
    z-index: -1;
    opacity: 0.4;
  }

`;

const LightBallWithTrashImage = styled.img`
  width: 100%;
  opacity: 0.8;
`;

const TrashContainer = styled.div`
  width: ${p => `${p.width}vw`};
  position: absolute;
  right: ${p => `${p.right}vw`};
  top: ${p => `${p.top}vw`};
  transform: ${p => `rotate(${p.rotate}deg)`};
  @media(max-width: 550px){
    width: 80vw;
    left: -40vw;
    top: 5vh;
    right: inherit;
    z-index: -1;
    opacity: 0.4
  }
`;

const TrashImage = styled.img`
  width: 100%;
  opacity: 0.8;
`;


const CenterArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 60vw;
  transform: translateY(-5vh);
`;

const CenterAreaHeadContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 5vh;
`;

const CenterAreaHeadText = styled.h1`
  font-size: 2.5rem;
  font-weight: 600;
  padding: 10px 0px;
`;

const CenterAreaHeadSubText = styled.h1`
  font-size: 1.125rem;
  font-weight: 500;
  padding: 10px 0px;
  max-width: 60vw;
  line-height: 25px;
  letter-spacing: 4px;
  >strong{
    font-weight: 600;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;

  @media(max-width: 550px){
      flex-direction: column;
  }
`;

const ButtonItem = styled.div`
  margin: 0px 10px;
  width: 40vw;
  padding: 24px 0px;
  background-color: #fff;
  border-radius: 40px;
  box-shadow: 0px 1px 19px -4px rgba(0, 0, 0, 0.6);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: box-shadow 0.2s ease, color 0.2s ease;
  >img{
      transition: transform 0.2s ease;
  }

  .link {
    color: ${theme.primaryColor};
    &:hover {
      color: ${theme.primaryColor};
      opacity: 0.8;
    }
  }
  @media(max-width: 550px){
    margin: 10px 0px;
    width: 80vw;
    height: 38vh;
  }

  &:hover{
    box-shadow: 0px 1px 25px -4px rgba(0, 0, 0, 0.6);
    background-color: ${theme.disabledGray};
    >div{
      >img{
        transition: transform 0.2s ease;
        transform: scale(1.5) translateY(-30px);
    }
    }
  }
`;

const ButtonIconContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-height: 17vh;
  @media(max-width: 550px){
    min-height: 17vh;
  }
`;

const ButtonIcon = styled.img`
    translate: transform 1s ease;
    height: 15vh;
    width: ${p => `${p.width}vw`};
    @media(max-width: 550px){
        height: 13vh;
    }
`;

const ButtonDescriptionText = styled.p`
  color: ${theme.black};
  padding: 10px 30px;
  line-height: 25px;
  letter-spacing: 4px;

  @media(max-width: 550px){
    max-width: 70vw;
    padding: 10px;
  }
`;

const ButtonMainTextContainer = styled.div`
    border-radius: 20px;
    width: 17vw;
    height: 7vh;
    display: flex;
    justify-content: center;
    align-items: center;
    @media(max-width: 550px){
        width: 70vw;
        height: 6vh;
    }
`;

const ButtonMainText = styled.p`
  color: ${theme.black};
  font-size: 2vw;
  font-weight: 600;
  letter-spacing: 2px;
      @media(max-width: 550px){
        font-size: 5vw;
    }
`

export default WastedLandingPage;
