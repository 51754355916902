//拿取問卷題目
export const START_FETCH_DETAIL_QUESTION = `START_FETCH_DETAIL_QUESTION`;
export const FETCH_DETAIL_QUESTION_SUCCESS = `FETCH_DETAIL_QUESTION_SUCCESS`;
export const FETCH_DETAIL_QUESTION_FAIL = `FETCH_DETAIL_QUESTION_FAIL`;

//讀取廠商資料
export const START_GET_USER_COMPANY_INFO = `START_GET_USER_COMPANY_INFO`;
export const GET_USER_INFO_COMPANY_SUCCESS = `GET_USER_INFO_COMPANY_SUCCESS`;
export const GET_USER_INFO_COMPANY_FAIL = `GET_USER_INFO_COMPANY_FAIL`;

//暫存 & 上傳問卷
export const START_GET_UPLOAD_VISIT_QUESTION_DATA = `START_GET_UPLOAD_VISIT_QUESTION_DATA`;
export const GET_UPLOAD_VISIT_QUESTION_DATA_SUCCESS = `GET_UPLOAD_VISIT_QUESTION_DATA_SUCCESS`;
export const GET_UPLOAD_VISIT_QUESTION_DATA_FAIL = `GET_UPLOAD_VISIT_QUESTION_DATA_FAIL`;

//取得限時訪廠問卷資料
export const START_GET_LIMITED_VISIT_QUESTION_DATA = `START_GET_LIMITED_VISIT_QUESTION_DATA`;
export const GET_LIMITED_VISIT_QUESTION_DATA_SUCCESS = `GET_LIMITED_VISIT_QUESTION_DATA_SUCCESS`;
export const GET_LIMITED_VISIT_QUESTION_DATA_FAIL = `GET_LIMITED_VISIT_QUESTION_DATA_FAIL`;

//取得帳號問卷清單
export const START_GET_VISIT_QUESTION_LIST = `START_GET_VISIT_QUESTION_LIST`;
export const GET_VISIT_QUESTION_LIST_SUCCESS = `GET_VISIT_QUESTION_LIST_SUCCESS`;
export const GET_VISIT_QUESTION_LIST_FAIL = `GET_VISIT_QUESTION_LIST_FAIL`;

//取得問卷詳細資料
export const START_GET_VISIT_QUESTION_DETAIL = `START_GET_VISIT_QUESTION_DETAIL`;
export const GET_VISIT_QUESTION_DETAIL_SUCCESS = `GET_VISIT_QUESTION_DETAIL_SUCCESS`;
export const GET_VISIT_QUESTION_DETAIL_FAIL = `GET_VISIT_QUESTION_DETAIL_FAIL`;

//刪除問卷
export const START_DELETE_VISIT_QUESTION = `START_DELETE_VISIT_QUESTION`;
export const DELETE_VISIT_QUESTION_SUCCESS = `DELETE_VISIT_QUESTION_SUCCESS`;
export const DELETE_VISIT_QUESTION_FAIL = `DELETE_VISIT_QUESTION_FAIL`;

//上傳圖片
export const START_UPLOAD_VISIT_QUESTION_IMAGE = `START_UPLOAD_VISIT_QUESTION_IMAGE`;
export const UPLOAD_VISIT_QUESTION_IMAGE_SUCCESS = `UPLOAD_VISIT_QUESTION_IMAGE_SUCCESS`;
export const UPLOAD_VISIT_QUESTION_IMAGE_FAIL = `UPLOAD_VISIT_QUESTION_IMAGE_FAIL`;

//取得問卷照片
export const START_GET_VISIT_QUESTION_IMAGE = `START_GET_VISIT_QUESTION_IMAGE`;
export const GET_VISIT_QUESTION_IMAGE_SUCCESS = `GET_VISIT_QUESTION_IMAGE_SUCCESS`;
export const GET_VISIT_QUESTION_IMAGE_FAIL = `GET_VISIT_QUESTION_IMAGE_FAIL`;