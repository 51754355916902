import React from "react"
import { Modal, Fade, Backdrop } from "@material-ui/core"

const CustomizedModal = props => {
    const { isOpen, handleClose } = props;
    const focusRef = React.useRef(null);

    React.useEffect(() => {
        if (isOpen && focusRef.current) {
            focusRef.current.focus();
        }
    }, [isOpen]);
    return(
        <Modal
            open={isOpen}
            onClose={handleClose}
            closeAfterTransition
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={isOpen}>
               
                <div ref={focusRef} tabIndex={-1}>
                    {props.children}
                </div>
            </Fade>
        </Modal>
    )
}


export default CustomizedModal