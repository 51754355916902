import React, { useState, useEffect, useRef } from 'react'
import {
  Map,
  TileLayer,
  Marker,
  Popup,
  Viewport,
  LayersControl
} from "react-leaflet";
import styled from "styled-components";
import { useSelector } from 'react-redux'
import MarkerClusterGroup from "react-leaflet-markercluster";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import GeoJson from "../GeoJson/GeoJson";
import L from 'leaflet'
import Button from "@material-ui/core/Button";
import AddIcon from "@mui/icons-material/Add";
import { randomMath } from "../../utility/common.js";

import './styles.scss'
import { CONVERTING_BUSINESS_NO_FAIL } from '../../store/actions/actionTypes';
import current_postion from "../../assets/images/current_position.png";


export const Icons = new L.Icon({
  iconUrl: '../../assests/marker.svg',
  iconRetinaUrl: '../../assests/marker.svg',
  // iconAnchor: [5, 55],
  // popupAnchor: [10, -44],
  // iconSize: [25, 55],
  // shadowUrl: '../assets/marker-shadow.png',
  // shadowSize: [68, 95],
  // shadowAnchor: [20, 92],
})

const CurrentPositionIcon = new L.Icon({
  iconUrl: "https://img.icons8.com/color/96/000000/marker--v1.png",
  iconSize: [32,32],
  iconAnchor: [32, 64],
  popupAnchor: null,
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null
})

const { BaseLayer, Overlay } = LayersControl;


const DEFAULT_VIEWPORT = {
  center: [23.6978, 120.9605],
  zoom: 8,
};

const MySwal = withReactContent(Swal);


const CustomizedMap = props => {
    const { closePopUp, status, openTalentDetailFromPopUp } = props
    const mapRef = useRef(null);
    const markers = useRef(null)
    const { innerWidth } = useSelector((state) => state.app);
    const { data, selectedLocation } = props;    
    const [states, setStates] = useState({
      lat: 23.6978,
      lng: 120.9605,
      zoom: 1,
      markerAdd: false,
    });
    const [MarkerList, setMarkerList] = useState(null);
    const [viewport, setViewport] = useState(DEFAULT_VIEWPORT);
    const [bound, setBound] = useState()
    const [center, setCenter] = useState([23.9037, 121.0794]);
    const [zoom, setZoom] = useState(9);
    const [selectedUid, setSelectedUid] = useState()
    const [localStatus, setLocalStatus] = useState(status)
    const url = window.location.origin

    useEffect(()=>{
      if (MarkerList !== null) {
        MySwal.fire({
          title:"提示",
          text: "切換搜尋模式將會清空地圖上的資料。",
        }).then(() => {
          props.onInitState()
          setBound();
          setMarkerList(null);

        }); 
      }


    }, [status])




    const markerOpen = (lat, lng) => {
      const markers = new L.MarkerClusterGroup();
      // 搜尋 markers 圖層下的子圖層
      markers.eachLayer((layer) => {

        console.log('LAYER!!')
        console.log(layer)
        // 抓取圖層的 經緯度
        // eslint-disable-next-line no-underscore-dangle
        const eachLat = layer._latlng.lat;
        // eslint-disable-next-line no-underscore-dangle
        const eachLng = layer._latlng.lng;
        // 如果與參數的經緯度相同，就抓取那個 layer
        if (eachLat === lat && eachLng === lng) {
          // zoomToShowLayer 這個是 MarkerClusterGroup 給的函式
          // 方法是調用 MarkerClusterGroup 下的子圖層
          // 打開 bindPopup 的 HTML
          markers.zoomToShowLayer((layer), () => layer.openPopup());
        }
      });
    };

    useEffect(() => {
      const { leafletElement } = mapRef.current

      console.log(selectedLocation)


      if (selectedLocation){
        // const markerPoints = markers.current.props.children;



        
        setSelectedUid(selectedLocation[1])
        markerOpen(selectedLocation[0][0], selectedLocation[0][1])

        leafletElement.flyTo(selectedLocation[0], 18)
      }
      
    }, [selectedLocation]);
    const AddToDashboard = (id) => {
      setStates((state) => ({...state, markerAdd: true}))
      props.handleUpdateCustomDashboardData(id);
    }
    useEffect(() => {
      if (states.markerAdd && props.updateCustomDashboardData && props.updateCustomDashboardData.length > 0) {
        props.handleOpenCustomDashboardModalOpen();
        setStates((state) => ({...state, markerAdd: false}))
      }
    }, [states.markerAdd])
    useEffect((viewport: Viewport)=>{

      if(data){
        const markers = data.map((marker) => {
          return(
            <Marker
              position={marker.position}
              alt={marker?.content?.name + "定位點，點擊可查看詳細資訊"}
              key={(
                marker.position[0] +
                marker.position[0] +
                randomMath()
              ).toString()}
              // icon={Icons}
              onClick={() => {
                // setCenter(marker.position)
                // setZoom(2)
              }}
            >
              <Popup isOpen={selectedUid === marker.content.uid} onClose={closePopUp}>
                {status === 'productSearch' &&
                  <div className="pop-up-content">
                  <div className="withLink">
                    <p className="title">
                      廠商名稱：
                    <span className="content" onClick={() => { window.open(url + '/factory_info/basic_info/' + marker.content.businessNo, "_blank") }}>{marker.content.name}</span>
                    </p>
                  </div>
                  <div className="withLink">
                    <p className="title">
                      廠商地址：
                    <span className="content" onClick={() => { window.open(`https://maps.google.com/?q=${marker.position[0]},${marker.position[1]}`, "_blank") }}>{marker.content.address}</span>
                    </p>
                  </div>
                  <div className="withLink">
                    <p className="title">
                      產業四碼：
                    <span className="content" onClick={() => { window.open(url + '/industry_index/' + marker.content.induFourStr.substr(0, 4), "_blank") }}>{marker.content.induFour}</span>
                    </p>
                  </div>
                  <div >
                    <p className="title">
                      主要產品：
                    <span className="content">{marker.content.product}</span>
                    </p>
                  </div>
                  <div>
                    <p className="title">
                      登記資本額(元)：
                    <span className="content">{marker.content.capital.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                    </p>
                  </div>
                  <div>
                    <p className="title">
                      產業園區：
                    <span className="content">{marker.content.induPark ? marker.content.induPark : '區外'}</span>
                    </p>
                  </div>
                  <div>
                    <p className="title">
                      股票狀態：
                    <span className="content">{marker.content.stockType}</span>
                    </p>
                  </div>
                  <div>
                    <p className="title">
                      負責人：
                    <span className="content">{marker.content.owner}</span>
                    </p>
                  </div>
                  {innerWidth > 1023 && localStorage.getItem("uid") && (
                  <div>
                    <StyledButton
                      size="small"
                      onClick={() => {AddToDashboard(marker.content.businessNo)}}
                      startIcon={<AddIcon />}
                    >
                      自訂關注廠商儀表板
                    </StyledButton>
                  </div>)}
                </div>
                }
                {status === 'talentSearch' &&
                  <div className="pop-up-content">
                  <div >
                    <p className="title">
                      姓名：
                    <span className="content">{marker.content.name}</span>
                    </p>
                  </div>
                  <div >
                    <p className="title">
                      服務機關：
                    <span className="content">{marker.content.schoolName + ' ' + marker.content.deptName}</span>
                    </p>
                  </div>
                  <div >
                    <p className="title">
                      命中次數：
                    <span className="content">{marker.content.hits === 0 ? 1 : marker.content.hits}</span>
                    </p>
                  </div>
                  <div >
                    <p className="title">
                      專長：
                    <span className="content">{marker.content.skill}</span>
                    </p>
                  </div>
                  <div className='more-info' onClick={()=> openTalentDetailFromPopUp(marker.content.researcherNo)}>
                    <p>
                      更多資訊
                    </p>
                  </div>
                  </div>
                }
              </Popup>
            </Marker>
          )
        });
        
        const boundArr = [ ]
        const updateBound = data.map(marker => boundArr.push(marker.position))

        if(markers){
          setMarkerList(markers)
        }
        setViewport(updateBound)
        setBound(boundArr)

      }else{
        setMarkerList(null)
      }
      
    }, [data])

    const position = [states.lat, states.lng];

    const onViewportChanged = (viewport: Viewport) => {
      console.log('Changed!')
      setViewport(viewport);
    };

    const centerMapView = (e) => {
      // e.preventDefault()
      let adjustYaxis 
      if (innerWidth>1024){
        adjustYaxis = 200
      } else {
        adjustYaxis = 70;
      }
      const { leafletElement } = mapRef.current;

      console.log(mapRef)

      if (e) {
        leafletElement.setView(e.popup._latlng)
        const point = leafletElement.project(e.target._popup._latlng)
        //把原本預設的 y 軸 - 200 ， 這樣 popup 才會完整出現在畫面
        const updatePoint = {
          ...point,
          y: point.y - adjustYaxis
        };
        leafletElement.panTo(leafletElement.unproject(updatePoint), {
          animate: true,
        });
        
      }
    }

    const openPopUp = event => {
      console.log(event)
      event.openPopup();
    }

    if(props.cluster){
      // return(
      //   <Map
      //     ref={mapRef}
      //     // animate={true}
      //     // duration={10}
      //     bounds={bound ? bound : null}
      //     viewport={!data ? viewport : null}
      //     zoomControl={false}
      //     onPopupopen={centerMapView}
      //     // onClick={event => { event.preventDefault() }}
      //     className='map'
      //     style={{
      //       position:'relative',
      //       outline:'none'
      //     }}
      //     // minZoom={3}
      //     // bounceAtZoomLimits={true}
      //     // maxBoundsViscosity={0.95}
      //     // maxBounds={[
      //     //   [-180, -90],
      //     //   [180, 90],
      //     // ]}
      //   >
      //     <LayersControl position="topright">
      //       <LayersControl.BaseLayer checked name="OpenStreetMap.BlackAndWhite">
      //         <TileLayer
      //         attribution='&amp;copy <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
      //         url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      //         />
      //       </LayersControl.BaseLayer>
      //       <LayersControl.Overlay checked name="Marker with popup">
              
      //         <MarkerClusterGroup ref={markers} >{MarkerList}</MarkerClusterGroup>
      //       </LayersControl.Overlay>
      //     </LayersControl>
      //   </Map>
      // )
      return (
        <Map
          ref={mapRef}
          // animate={true}
          // duration={10}
          tap={false}
          bounds={bound ? bound : [[25.268576, 121.611722], [23.879299, 120.294881], [23.762836, 121.544090], [21.257621, 120.740482], [21.899800, 120.837252]]}
          // viewport={!MarkerList ? viewport : null}
          // viewport={viewport}
          // zoomControl={false}
          zoomControl={false}
          onPopupopen={centerMapView}
          // onClick={event => { event.preventDefault() }}
          className='map'
          style={{
            position:'relative',
            // outline:'none',
          }}
          // minZoom={3}
          // bounceAtZoomLimits={true}
          // maxBoundsViscosity={0.95}
          // maxBounds={[
          //   [-180, -90],
          //   [180, 90],
          // ]}
        >
          <TileLayer
            attribution='&amp;copy <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <MarkerClusterGroup onKeyUp={(e) => {
            if (e.type === "clusterkeyup") {
              e.originalEvent.target.setAttribute(
                "title",
                `包含 ${e.originalEvent.target.innerText} 個定位點，點擊或按下 enter 鍵以放大`
              );
              if (e.originalEvent.keyCode === 13) {
                e.originalEvent.target.click();
              }
              if (e.originalEvent.keyCode === 9) {
                const { leafletElement } = mapRef.current;
                leafletElement.setView(e.layer._cLatLng, e.layer._zoom);
              }
            }
          }}>
            {MarkerList}
          </MarkerClusterGroup>
          <GeoJson/>
          {props.current && <Marker icon={CurrentPositionIcon} position={props.current}/>}          
          {/* <button
            onClick={event=>{
              openPopUp(event)
            }}
           style={{
            zIndex:'1000000',
            top:'10%',
            position:'absolute'
          }}>打開魂結</button> */}
        </Map>
      );
    }else{
      return (
        <Map
          // onViewportChanged={onViewportChanged}
          // useFlyTo={true}
          // viewport={viewport}
          animate={true}
          duration={10}
          bounds={bound ? bound : null}
          viewport={!data ? viewport : null}
          boxZoom={true}
          zoomControl={false}
          tap={false}
        >
          <TileLayer
            attribution='&amp;copy <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <GeoJson/>
          {/* {MarkerList} */}
          {/* <ZoomControl position="topright" /> */}
        </Map>
      );
    }


}

const StyledButton = styled(Button)`
  margin: 12px 0px 8px 0px !important;
  font-size: 0.75rem;
  background-color: var(--main-color) !important;
  color: #fff !important;
`
export default CustomizedMap;