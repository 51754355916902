import axios from "axios";
import dataURLtoBlob from "blueimp-canvas-to-blob";
import { baseUrl, loginbaseUrl } from "../constants/endpoints";
import { encryptAES, decryptAES } from "./common";

const headers = {
  "Access-Control-Allow-Origin": "*",
  "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
  "Access-Control-Allow-Headers":
    "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers",
  "Content-Type": "application/json",
  Authorization: localStorage.getItem("acToken"),
};

export const fetchQuestionItemHandler = (GroupId) => {
  return axios({
    proxy: true,
    method: "get",
    url: `${baseUrl}/api/VisitQuestion/GetQuestionItems/${GroupId}`,
  })
    .then((res) => {
      try {
        return res;
      } catch (e) {
        return [];
      }
    })
    .catch((err) => {
      return "error";
    });
};

//暫存 & 上傳
export const fetchUploadVisitQuestionDataHandler = (result, uid, release) => {
  const tempContnet = [];
  var visitorSignArr = {
    VisitorSign: "",
    VisitorSign_2: "",
    VisitorSign_3: "",
  }
  var respondSignArr = {
    RespondSign: "",
    RespondSign_2: "",
    RespondSign_3: "",
  }
  
  //訪談人員
  const participantsPadArr = ["signParticipantsPad", "signParticipantsPad_2", "signParticipantsPad_3"]
  participantsPadArr.forEach((item, index) => {
    if (
      !result[5].phoneInterview && result[4]?.[item]
    ) {
      if (!result[4]?.[item]?.includes('VisitorSign')) {
        visitorSignArr[`${index === 0 ? "VisitorSign" : `VisitorSign_${index + 1}`}`] = dataURLtoBlob(result[4]?.[item]);
      }
    }
  })
  // if (
  //   !result[5].phoneInterview && result[4]?.signParticipantsPad
  // ) {
  //   if (!result[4]?.signParticipantsPad?.includes("VisitorSign")) {
  //     visitorSign = dataURLtoBlob(result[4]?.signParticipantsPad);
  //   }
  // }

  //受訪人
  const respondSignPadArr = ["signSupervisorPad", "signSupervisorPad_2", "signSupervisorPad_3"]
  respondSignPadArr.forEach((item, index) => {
    if (
      !result[5].phoneInterview && result[4]?.[item]
    ) {
      if (!result[4]?.[item]?.includes('RespondSign')) {
        respondSignArr[`${index === 0 ? 'RespondSign' : `RespondSign_${index + 1}`}`] = dataURLtoBlob(result[4]?.[item]);
      }
    }
  })
  // if (
  //   !result[5].phoneInterview && result[4]?.signSupervisorPad
  // ) {
  //   if (!result[4]?.signSupervisorPad?.includes("RespondSign")) {
  //     respondSign = dataURLtoBlob(result[4]?.signSupervisorPad);
  //   }
  // }

  result[3].stepFourData.map((data) => {
    tempContnet.push({
      TopicId: data.TopicId,
      CheckAns: data.QuestionTypeName === "checkbox" && data.CheckAns !== "" ? data.CheckAns === false ? 0 : 1 : 0,
      TextArea: data.TextArea || "",
    });
  });
  const data = new FormData();
  data.append("Account", uid);
  const encrypt = encryptAES(`Aj9dxd${uid}`);
  data.append("Encrypt", encrypt);
  data.append("Release", release);
  data.append("PhoneInterview", Boolean(result[5].phoneInterview));
  data.append("Qid", result[5].uuid);
  data.append("VisitReason", result[1].stepTwoData);
  data.append("BusinessNo", result[0].companyNumber);
  data.append("IndustryCode", result[0].companyTypeTwo);
  data.append("MainProduct", result[0].companyMainProduct);
  data.append("Park", result[0].industryPark);
  data.append("RespondName", result[0].userName);
  data.append("RespondDept", result[0].userDivision);
  data.append("RespondMail", result[0].userEmail);
  data.append("RespondPhone", result[0].userPhone);
  data.append("VisitTime", result[0].visitTime);
  data.append("Note", result[0].note);
  data.append("Summary", result[4].conclusion);
  data.append("Step3Record", result[2].stepThreeSelected);
  data.append("Step4Record", result[3].stepFourSelected);
  data.append("FollowStep4", result[3].followStep4);
  data.append("Contents", JSON.stringify(tempContnet));

  //訪談人員
  if (
    !result[5].phoneInterview && result[4]?.signParticipantsPad
  ) {
    if (!result[4]?.signParticipantsPad?.includes("VisitorSign")) {
      data.append("VisitorSign", visitorSignArr['VisitorSign'], "VisitorSign.png");
    }
  }
  if (
    !result[5].phoneInterview && result[4]?.signParticipantsPad_2
  ) {
    if (!result[4]?.signParticipantsPad_2?.includes("VisitorSign")) {
      data.append("VisitorSign_2", visitorSignArr['VisitorSign_2'], "VisitorSign_2.png");
    }
  }
  if (
    !result[5].phoneInterview && result[4]?.signParticipantsPad_3
  ) {
    if (!result[4]?.signParticipantsPad_3?.includes("VisitorSign")) {
      data.append("VisitorSign_3", visitorSignArr['VisitorSign_3'], "VisitorSign_3.png");
    }
  }

  //受訪人
  if (
    !result[5].phoneInterview && result[4]?.signSupervisorPad
  ) {
    if (!result[4]?.signSupervisorPad?.includes("RespondSign")) {
      data.append("RespondSign", respondSignArr['RespondSign'], "RespondSign.png");
    }
  }
  if (
    !result[5].phoneInterview && result[4]?.signSupervisorPad_2
  ) {
    if (!result[4]?.signSupervisorPad_2?.includes("RespondSign")) {
      data.append("RespondSign_2", respondSignArr['RespondSign_2'], "RespondSign_2.png");
    }
  }
  if (
    !result[5].phoneInterview && result[4]?.signSupervisorPad_3
  ) {
    if (!result[4]?.signSupervisorPad_3?.includes("RespondSign")) {
      data.append("RespondSign_3", respondSignArr['RespondSign_3'], "RespondSign_3.png");
    }
  }

  return axios({
    proxy: true,
    method: "post",
    url: `${loginbaseUrl}api/VisitQuestion/UploadVisitQuestionData`,
    data: data,
  })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return "error";
    });
};

//取得限時訪廠問卷資料
export const fetchLimitedVisitQuestionDataHandler = (release) => {
  const data = new FormData();
  data.append("Key", release);

  return axios({
    proxy: true,
    method: "post",
    url: `${loginbaseUrl}api/VisitQuestion/GetLimitedVisitQuestionData`,
    data: data,
  })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return "error";
    });
};

//取得帳號問卷清單
export const fetchVisitQuestionListHandler = (
  uid,
  keyword,
  startTime,
  endTime,
  traceBack,
  visitReason,
  group
) => {
  const data = new FormData();
  data.append("Account", uid);
  const encrypt = encryptAES(`Aj9dxd${uid}`);
  data.append("Encrypt", encrypt);
  data.append("Keyword", keyword);
  data.append("StartTime", startTime);
  data.append("EndTime", endTime);
  data.append("TraceBack", traceBack);
  data.append("VisitReason", visitReason);

  if (group !== "") {
    data.append("Group", group);
  }

  return axios({
    proxy: true,
    method: "post",
    url: `${loginbaseUrl}api/VisitQuestion/GetVisitQuestionList`,
    data: data,
  })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return "error";
    });
};

//取得問卷詳細資料
export const fetchVisitQuestionDetailHandler = (uid, Qid) => {
  const data = new FormData();
  data.append("Account", uid);
  const encrypt = encryptAES(`Aj9dxd${uid}`);
  data.append("Encrypt", encrypt);
  data.append("Qid", Qid);

  return axios({
    proxy: true,
    method: "post",
    url: `${loginbaseUrl}api/VisitQuestion/GetVisitQuestionDetail`,
    data: data,
  })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return "error";
    });
};

//刪除問卷
export const fetchDeleteVisitQuestionHandler = (Qid) => {
  const data = new FormData();

  data.append("Qid", Qid);

  return axios({
    proxy: true,
    method: "post",
    url: `${loginbaseUrl}api/VisitQuestion/DeleteVisitQuestion`,
    data: data,
  })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return "error";
    });
};

//上傳照片
export const fetchUploadVisitQuestionImageHandler = (Qid, MultiImage) => {
  const data = new FormData();

  data.append("Qid", Qid);
  
  for (var i = 0; i < MultiImage.length; i++) {
    data.append("MultiImage", MultiImage[i]);
  }

  // console.log(MultiImage.length)

  // for (const value of data.values()) {
  //   console.log(value);
  // }

  return axios({
    proxy: true,
    method: "post",
    url: `${loginbaseUrl}api/VisitQuestion/UploadVisitQuestionImage`,
    data: data,
  })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};

//取得問卷照片
export const fetchGetVisitQuestionImageHandler = (Qid) => {
  return axios({
    proxy: true,
    method: "get",
    url: `${loginbaseUrl}api/VisitQuestion/GetVisitQuestionImage/${Qid}`,
  })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return "error";
    });
};