import * as actionTypes from "../actions/actionTypes";

const initState = {
  sectionMap: null,
  getSectionMapLoading: false,
  updateSectionMapLoading: false,
  err:null,
};

export default (state = initState, action) => {
  switch (action.type) {
    case actionTypes.START_GET_SECTION_MAP:
      return {
        ...state,
        getSectionMapLoading: true,
      };
    case actionTypes.GET_SECTION_MAP_SUCCESS:
      return {
        ...state,
        getSectionMapLoading: false,
        sectionMap: action.data
      };
    case actionTypes.GET_SECTION_MAP_FAIL:
      return {
        ...state,
        getSectionMapLoading: false,
        err:action.err
      };
    case actionTypes.START_UPDATE_SECTION_MAP:
      return {
        ...state,
        updateSectionMapLoading: true,
      };
    case actionTypes.UPDATE_SECTION_MAP_SUCCESS:
      return {
        ...state,
        updateSectionMapLoading: false,
      };
    case actionTypes.UPDATE_SECTION_MAP_FAIL:
      return {
        ...state,
        updateSectionMapLoading: false,
      };
    default:
      return state;
  }
};

