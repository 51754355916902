import React, { useState, useEffect } from 'react'
import { Switch, Route, useRouteMatch, useLocation } from 'react-router-dom'
import { Modal, Slide, Dialog } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import Icon from "@material-ui/core/Icon"



import GovPlanAdjust from '../../components/GovPlanAdjust/GovPlanAdjust'
// import InnerNavBar from '../../components/InnerNavBar/InnerNavBar'
import GovPlanInput from '../../components/GovPlanInput/GovPlanInput'
import GovDetail from '../../components/GovPlanDetail/GovPlanDetail'
import GovPlanCompanyGroup from '../../components/GovPlanCompanyGroup/GovPlanCompanyGroup'
import GovPlanGroupCreate from '../../components/GovPlanGroupCreate/GovPlanGroupCreate'


import * as govPlanActions from '../../store/actions/govplan'
import './styles.scss'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});


const GovPlanManagement = props => {
    const dispatch = useDispatch()
    const { 
      parks, 
      activePlanList, 
      inActivePlanList, 
      loading,
      finished,
      induGroupList
    } = useSelector(state => state.govPlan )

    const { innerWidth } = useSelector(state => state.app )

    let { path } = useRouteMatch()
    const location = useLocation().pathname.split('/')[2]


    const [ isModalOpen, setIsModalOpen ] = useState(false)
    const [ isDialogOpen, setIsDialogOpen] = useState(false)
    const [ modalType, setModalType ] = useState()
    const [ modalContent, setModalContent ] = useState()
    const [ plan, setPlan ] = useState()
    const [ groupAdjust, setGroupAdjust ] = useState('create') 
    const [ group, setGroup ] = useState()


    useEffect(()=>{
      dispatch(govPlanActions.getAllGovPlans())
      dispatch(govPlanActions.getInduGroupHandler())
      dispatch(govPlanActions.getTypeTwo())
      dispatch(govPlanActions.getInduGroupHandler())
      dispatch(govPlanActions.getDeliveryLogsHandler())
    },[ ])


    //假如 url 導到 gov_plan_management 要重新 direct to fov_plan
    useEffect(() => {
      if (!location) {
        props.history.push(`${path}gov_plan`)
      }
    }, [location])


    useEffect(()=>{
      if (modalType === "create") {
        setModalContent(
          <GovPlanAdjust
            loading={loading}
            finished={finished}
            closeModal={handleClose}
          />
        );
      } else if (modalType === "edit") {
        setModalContent(
          <GovPlanAdjust
            loading={loading}
            finished={finished}
            closeModal={handleClose}
            edit
            existedPlan={plan}
          />
        );
      } else if (modalType === "detail") {
        setModalContent(<GovDetail plan={plan} closeModal={handleClose}/>);
      } else if (modalType === "create-gov-group") {
        setGroupAdjust('create')
        setModalContent(<GovPlanGroupCreate parks={parks} type={'create'} closeModal={handleClose}/>);
      } else if (modalType === "edit-gov-group") {
        setGroupAdjust('edit')
        setModalContent(<GovPlanGroupCreate parks={parks} type={'edit'} existedGroup={group} isEdit closeModal={handleClose}/>);
      } else {
        setModalContent(<div>連線出現錯誤，請聯絡系統管理人</div>)
      }
    }, [modalType, plan, group])



    const handleClose = () => {
      setIsModalOpen(false)
      setIsDialogOpen(false)
      // setPlan()
    }

    const modalHandler = (type, projectId)  => {
      if(projectId){
        if (type === 'edit-gov-group') {
          const group = induGroupList.filter(group => group.GroupId === projectId)

          setGroup(group)
        } else {
          const allPlans = activePlanList.concat(inActivePlanList)
          const updatePlan = allPlans.filter(plan => projectId === plan.ProjectId)

          setPlan(updatePlan[0])
        }
      }
      setModalType(type)
      setIsModalOpen(true)
    }

    const dialogHandler = (type, projectId) => {
      const allPlans = activePlanList.concat(inActivePlanList)
      const plan = allPlans.filter(plan => plan.ProjectId === projectId)

      setModalType(type)
      setPlan(plan[0])
      setIsDialogOpen(true)
    }


    return (
      <div className="gov-plan">
        <div>
          <h1>政府資源管理</h1>
          {location === "gov_plan" ? (
            <button onClick={() => modalHandler("create")}>建立政府計畫</button>
          ) : null}
        </div>
        {/* <InnerNavBar listData={listData} getNavValue={getNavValue} /> */}
        <section>
          <Switch>
            <Route
              exact
              path={`${path}gov_plan`}
              render={(props) => (
                <GovPlanInput
                  activePlanList={activePlanList}
                  inActivePlanList={inActivePlanList}
                  modalHandler={
                    innerWidth > 1024
                      ? modalHandler
                      : (type, projectId) => dialogHandler(type, projectId)
                  }
                  loading={loading}
                />
              )}
            />
            <Route
              // exact
              path={`${path}delivery`}
              render={(props) => (
                <GovPlanCompanyGroup
                  modalHandler={modalHandler}
                  handleClose={handleClose}
                />
              )}
            />
          </Switch>
        </section>
        {modalType === "detail" && (
          <Modal open={isModalOpen}>
            <div>
              {/* <div className="modal-container-close-lg" onClick={handleClose}><Icon>close</Icon></div> */}
              <div className="modal-container-lg">{modalContent}</div>
            </div>
          </Modal>
        )}
        {modalType !== "detail" && (
          <Modal open={isModalOpen}>
            <div>
              {/* <div className={modalType === "detail" ? "modal-container-close" : "modal-container-close-sm"} onClick={handleClose}><Icon>close</Icon></div> */}
              <div className="modal-container">{modalContent}</div>
            </div>
          </Modal>
        )}

        <Dialog open={isDialogOpen} TransitionComponent={Transition} className='dialog'>
          <div>
            {/* <div className="modal-container-close-lg" onClick={handleClose}>
              <Icon>close</Icon>
            </div> */}
            <div className="modal-container-gov-plan-display">
              <div>
                {/* <div className="modal-container-close-lg" onClick={handleClose}>
                  <Icon>close</Icon>
                </div> */}
                <div 
                  className="dialog-container"
                >{modalContent}</div>
              </div>
            </div>
          </div>
        </Dialog>
      </div>
    ); 

}

export default GovPlanManagement