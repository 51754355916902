import styled from "styled-components";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";

export const FormControlInherit = styled(FormControl)`
  padding:  1% 1% 0% 0% !important;
`;

export const TableHeader = styled(TableHead)`
  background-color: var(--main-color);
  font-family: $main-font !important;
  > tr th {
    color: #fff;
    font-size: 1rem;
    font-weight: 400;
    font-family: $main-font !important;
  }
`;

export const SmallCell = styled(TableCell)`
  width: 5%;
  font-family: $main-font !important;
`;

export const NormalCell = styled(TableCell)`
  width: 10%;
  font-family: $main-font !important;
  vertical-align: middle !important;
`;

export const LargeCellLink = styled(TableCell)`
  width: 15%;
  font-family: $main-font !important;
  color: #2c83bd;
  text-decoration: underline;
  cursor: pointer;
`;

export const LargeCell = styled(TableCell)`
  width: 15%;
  font-family: $main-font !important;
`;

export const ContainerText = styled.h2`
  color: #ff746f;
  font-weight: 700;
  font-size: 2.18rem;

  @media (max-width: 1024px) {
    font-size: 1.2rem;
  }
`;

export const Card = styled.div`
  display: flex;
  margin: 25px 5%;
  background: #fff;
  min-height: 80vh;
  box-shadow: 0px 0px 15px -5px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  flex-direction: column;

  @media (max-width: 1024px) {
    margin: 20px 5%;
    padding-bottom: 5%;
  }
`;

export const Form = styled.div`
  min-width: 25%;
  display: flex;
  flex-direction: column;
  padding: 1% 1% 0% 0%;

  @media (max-width: 1024px) {
    margin: 3% 0%;
  }

  > label {
    font-size: 90%;
    font-weight: 400;
    margin-bottom: 15px;
    color: #645959;
    min-width: 20%;
  }
`;

export const SearchContainer = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: right;

  @media (max-width: 1024px) {
    flex-direction: column;
    display: inline-block;
    text-align: center;
  }
`;

export const SearchTopContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;

  @media (max-width: 1024px) {
    flex-direction: column;
    width: 100%;
  }
`;

export const SearchBotton = styled.div`
  width: 12%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 3% 0% 1%;

  @media (max-width: 1024px) {
    width: 49%;
    margin: 5% 0%;
    justify-content: center;
    display: inline-block;
  }
`;

export const RestartButton = styled.div`
  width: 12%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 1024px) {
    width: 49%;
    justify-content: center;
    display: inline-block;
  }
`;

export const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0% 2.5%;

  @media (max-width: 1024px) {
    align-items: center;
    width: 100%;
    margin: 0%;
  }
`;

export const TopLeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: start;
  margin: 3% 0% 0%;

  @media (max-width: 1024px) {
    align-items: center;
    width: 80%;
    margin: 5% 0%;
  }
`;

export const TopRightContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (max-width: 1024px) {
    width: 80%;
  }
`;

export const SearchField = styled.div`
  display: flex;
  margin: 2% 0% 0%;
  flex-direction: row;
  
  @media (max-width: 1024px) {    
    flex-direction: column;
  }
`;

export const CapitalContainer = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: 1024px) {
    margin: 2% 0%;
  }
`;

export const SearchTextField = styled(TextField)`
  width: ${(p) => (p.large ? "45%" : "21%")};
  margin: 1% 1% 0% 0% !important;

  @media (max-width: 1024px) {
    width: 100%; 
    margin: 3% 0% !important;
  }
`;

export const ElevatorTextField = styled(TextField)`
  width: 15%;
  height: auto;
  display: flex !important;
  flex-direction: row !important;
  input {
    text-align: center;
    padding: 0px;
  }
`;

export const SearchCapitalField = styled(TextField)`
  min-width: 25% !important;
  padding: 1% 1% 0% 0% !important;

  @media (max-width: 1024px) {
    width: 100%; 
    margin: 3% 0% !important;
  }

  label {
    top: auto !important;
  }
`;

export const BottomContainer = styled.div`
  width: 95%;
  margin: 0% 2.5%;
  height: 80%;

  > h6 {
    text-align: end;
    margin: 2% 0%;
    color: grey;

    @media (max-width: 750px) {
      font-size: 80%;
    }
  }
  .table{
    margin: 0px;
  }
`;

export const MobileContainer = styled.div`
  height: auto;
  padding: 5%;
  background: #94E0DF;
  border-radius: 10px;
  box-shadow: 0px 0px 30px 0px #ececec;
  margin: 5% 0%;
  line-height: 30px;
  display: flex;
  flex-direction: column;
`;

export const MobileItem = styled.div`
  display: flex;
  border-bottom: 1px solid #ececec;
  padding: 2%;
`;

export const MobileItemTitle = styled.div`
  font-weight: 600;
  padding: 2%;
  color: #4e4646;
  width: 30%;
  font-size: 90%;
  text-align: end;

  @media (max-width: 750px) {
    width: 35%;
  }
`;

export const MobileItemContent = styled.div`
  padding: 2%;
  color: #4e4646;
  font-size: 90%;

  @media (max-width: 750px) {
    width: 65%;
  }
`;

export const MobileItemContentLink = styled.div`
  padding: 2%;
  color: #2c83bd;
  text-decoration: underline;
  cursor: pointer;
  font-size: 90%;

  @media (max-width: 750px) {
    width: 65%;
  }
`;
