import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";

import apple from "../../../assests/news_images/apple.jpg";
import china from "../../../assests/news_images/china.jpg";
import cna from "../../../assests/news_images/cna.jpg";
import CteeLogo from "../../../assests/news_images/CteeLogo.jpg";
import ettoday from "../../../assests/news_images/ettoday.jpg";
import free from "../../../assests/news_images/free.png";
import udn from "../../../assests/news_images/udn_logo.gif";
import yahoo from "../../../assests/news_images/yahoo.png";
import white from "../../../assests/news_images/white.jpg";
import pts from "../../../assests/news_images/pts.png";
import ttv from "../../../assests/news_images/ttv.jpg";

import "./styles.scss";

const NewsInfoTable = (props) => {
  const { data, headerList } = props;
  const [state, setState] = React.useState({
    pageCount: 0,
    page: 0,
    rowsPerPage: 5,
  });
  let content;

  // React.useEffect(()=>{
  //  //計算 page count
  //  if(newsInfoTotalLength > 5){
  //    if(newsInfoTotalLength % 5 === 0){
  //     setState(state=>({ ...state, pageCount: newsInfoTotalLength/5 }))
  //    }else{
  //     setState(state=>({ ...state, pageCount: parseInt(newsInfoTotalLength/5) + 1 }))
  //    }
  //  }
  // },[newsInfoTotalLength, newsInfoRowNum, newsInfoPagingNum])

  const handleChangePage = (event, newPage) => {
    // getNewsListByNewPage(newPage)
    setState((state) => ({ ...state, page: newPage }));
  };

  const handleNewsIcon = (logo) => {
    let imageSrc = white;
    switch (logo) {
      case "apple.jpg":
        imageSrc = apple;
        break;
      case "china.jpg":
        imageSrc = china;
        break;
      case "cna.jpg":
        imageSrc = cna;
        break;
      case "CteeLogo.jpg":
        imageSrc = CteeLogo;
        break;
      case "ettoday.jpg":
        imageSrc = ettoday;
        break;
      case "free.png":
        imageSrc = free;
        break;
      case "udn_logo.gif":
        imageSrc = udn;
        break;
      case "yahoo.png":
        imageSrc = yahoo;
        break;
      case "ttv.jpg":
        imageSrc = ttv;
        break;
      case "pts.png":
        imageSrc = pts;
        break;
      default:
        return (imageSrc = white);
    }

    return <img className="news-image" loading={"lazy"} src={imageSrc} alt="news-logo" />;
  };

  const openNewTabs = (link) => window.open(link, "_blank");

  const reOrganizeCompanyHandler = async (list) => {
    const updateArray = [];
    await list.map((company) => {
      console.log(company);
      updateArray.push(company.CompanyName);
    });
    return updateArray;
  };

  // if (data.length === 0) {
  //   content = <div className="no-data">本項目無資料</div>;
  // } else {
  //   content = data.map((row, index) => (
  //     <TableRow
  //       key={encodeURI(row.Title)}
  //       hover
  //       className="table-body-row"
  //       style={index % 2 !== 0 ? { backgroundColor: "#ececec" } : {}}
  //       onClick={()=>openNewTabs(row.Link)}
  //     >
  //       <TableCell align="left" className="table-body-header">
  //         {row.Title}
  //       </TableCell>
  //       <TableCell align="left" className="table-body-date">
  //         {row.PubDate.split(" ")[0]}
  //         {handleNewsIcon(row.Logo)}
  //       </TableCell>
  //       <TableCell align="left" className="table-body-company-name">
  //         {row.Companys.length === 0
  //           ? selectedCity
  //           : row.Companys[0].CompanyName}
  //       </TableCell>
  //     </TableRow>
  //   ));
  // }

  if (data.length > 5) {
    content = data
      .slice(
        state.page * state.rowsPerPage,
        state.page * state.rowsPerPage + state.rowsPerPage
      )
      .map((row, index) => (
        <TableRow
          key={encodeURI(row.Title)}
          hover
          className="table-body-row"
          style={index % 2 !== 0 ? { backgroundColor: "#ececec" } : {}}
          onClick={() => openNewTabs(row.Link)}
        >
          <TableCell
            style={{
              textDecoration: "underline",
              color: "rgb(0, 106, 180)",
              cursor: "pointer",
            }}
            align="left"
          >
            {row.Title}
          </TableCell>
          <TableCell align="left">{row.PubDate.split(" ")[0]}</TableCell>
          <TableCell align="left">{handleNewsIcon(row.Logo)}</TableCell>
        </TableRow>
      ));
  } else {
    content = data.map((row, index) => (
      <TableRow
        key={encodeURI(row.Title)}
        hover
        className="table-body-row"
        style={index % 2 !== 0 ? { backgroundColor: "#ececec" } : {}}
        onClick={() => openNewTabs(row.Link)}
      >
        <TableCell
          style={{
            textDecoration: "underline",
            color: "rgb(0, 106, 180)",
            cursor: "pointer",
          }}
          align="left"
        >
          {row.Title}
        </TableCell>
        <TableCell align="left">{row.PubDate.split(" ")[0]}</TableCell>
        <TableCell align="left">{handleNewsIcon(row.Logo)}</TableCell>
      </TableRow>
    ));
  }

  return (
    <TableContainer className="table-container">
      <Table className="table">
        <TableHead className="table-header">
          {headerList && (
            <TableRow className="table-row">
              {headerList.map((headerItem) => (
                <TableCell
                  style={{
                    fontWeight: 600,
                  }}
                  className="table-cell"
                  align="left"
                >
                  {headerItem}
                </TableCell>
              ))}
            </TableRow>
          )}
        </TableHead>
        <TableBody className="news-table-body">
          {data.length !== 0 && content}
        </TableBody>
      </Table>
      {data.length > 5 && (
        <TablePagination
          className="news-table-pagination"
          rowsPerPageOptions={[]}
          component="div"
          count={data.length}
          rowsPerPage={state.rowsPerPage}
          page={state.page}
          onChangePage={handleChangePage}
          // onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      )}

      {data.length === 0 && (
        <Table>
          <TableBody className="table-body-no-data">
            <TableRow hover className="table-body-row">
              <TableCell align="center" className="no-data">
                本項目無資料
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      )}
    </TableContainer>
  );
};

export default React.memo(NewsInfoTable);
