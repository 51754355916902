import axios from "axios";
import { loginbaseUrl } from "../constants/endpoints";
import Swal from "sweetalert2";

let axiosInstance = axios.create();

const refreshToken = async () => {
  const data = new FormData();
  data.append("RefreshToken", localStorage.getItem("RefreshToken"));
  try {
    const res = await axios({
      proxy: true,
      method: "post",
      url: `${loginbaseUrl}api/auth/RefreshToken`,
      data: data,
    });
    localStorage.setItem("InduParkWebAcToken", res.data.token.AccessToken);
    localStorage.setItem("RefreshToken", res.data.token.RefreshToken);
    return res.data;
  } catch (err) {
    return "error";
  }
};

let isRefreshing = false;
let refreshSubscribers = [];

function subscribeTokenRefresh(cb) {
  refreshSubscribers.push(cb);
}

function onRefreshed(token) {
  refreshSubscribers.map((cb) => cb(token));
}

function logout() {
  window.location.replace("/portal");
  localStorage.removeItem("isLogin");
  localStorage.removeItem("EncTicket");
  localStorage.removeItem("acToken");
  localStorage.removeItem("ParkPermission");
  localStorage.removeItem("uid");
  localStorage.removeItem("name");
  localStorage.removeItem("mir_su");
  localStorage.removeItem("1");
  localStorage.removeItem("2");
  localStorage.removeItem("3");
  localStorage.removeItem("4");
  localStorage.removeItem("5");
  localStorage.removeItem("6");
  localStorage.removeItem("7");
  localStorage.removeItem("8");
  localStorage.removeItem("9");
  localStorage.removeItem("10");
  localStorage.removeItem("11");
  localStorage.removeItem("12");
  localStorage.removeItem("13");
  localStorage.removeItem("14");
  localStorage.removeItem("15");
  localStorage.removeItem("16");
  localStorage.removeItem("17");
  localStorage.removeItem("18");
  localStorage.removeItem("client");
  localStorage.removeItem("ParkId");
  localStorage.removeItem("service_history");
  localStorage.removeItem("indupark_upload");
  localStorage.removeItem("auL");
  localStorage.removeItem("RefreshToken");
  localStorage.removeItem("InduParkWebAcToken");
}

// 加上 axios 的攔截器
axiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      if (!isRefreshing) {
        isRefreshing = true;
        if (localStorage.getItem("RefreshToken")) {
          refreshToken()
            .then(({ token }) => {
              isRefreshing = false;
              onRefreshed(token.AccessToken);
              refreshSubscribers = [];
            })
            .catch((err) => {
              // refreshToken 失敗要做的事情: 自動幫使用者登出，提醒使用者可能在其他地方有登入
              // dispatch(authAction.logoutHandler());
              Swal.fire({
                title: "登入憑證已過期",
                text: "您可能在其他地方方登入帳號，請重新登入。若遇到登入問題，請聯繫客服信箱：meer@mail.mirdc.org.tw",
                confirmButtonText: "確認",
              }).then(() => {
                logout();
              });
            });
        } else {
          if (localStorage.getItem("isLogin") === "true") {
            Swal.fire({
              title: "登入逾時",
              text: "登入已經逾時，請重新由首頁登入。若遇到登入問題，請聯繫客服信箱：meer@mail.mirdc.org.tw",
              confirmButtonText: "確認",
            }).then(() => {
              logout();
            });
          }
        }
      }

      const retryOrigReq = new Promise((resolve, reject) => {
        subscribeTokenRefresh((token) => {
          originalRequest.headers.Authorization = "Bearer " + token;
          originalRequest._retry = true;
          resolve(axiosInstance(originalRequest));
        });
      });
      return retryOrigReq;
    } else {
      return Promise.reject(error);
    }
  }
);

export default axiosInstance;
