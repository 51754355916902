import React from "react";
import Collapse from "@material-ui/core/Collapse";

import { cityCode } from "../../constants/cities";
import IPO from '../../assests/ipo.png'
import InIndustry from '../../assests/fire.png'

import "./styles.scss";


export default (props) => {
  const { data, title, type, allCompany } = props;
  const [state, setState] = React.useState({
    showListLength: 3,
    listData: {},
  });

  const updateShowListLength = () =>
    setState((state) => ({
      ...state,
      showListLength: state.showListLength !== 3 ? 3 : data.length,
    }));
  
  const openCompanyPage = (e, businessNo) => {
    e.stopPropagation();
    const url = window.location.origin;

    window.open(`${url}/factory_info/basic_info/${businessNo}`, "_blank")
  }

  const toggleItem = (e, id) => {
    e.stopPropagation();
    if (state.listData && Object.entries(state.listData).length > 0) {
      const obj = {};
      Object.entries(state.listData).map((i) => {
        if (i[0] === id) {
          obj[i[0]] = {
            isOpen: !i[1].isOpen,
            num: i[1].data.length,
            data: i[1].data,
          };
        } else {
          obj[i[0]] = {
            isOpen: false,
            num: i[1].data.length,
            data: i[1].data,
          };
        }
      });

      setState((state) => ({ ...state, listData: obj }));
    }
  };

  const areaTransform = (area) => {
    switch (area) {
      case "North":
        return "北部";
      case "East":
        return "東部";
      case "Center":
        return "中部";
      case "South":
        return "南部";
      default:
        return "其他";
    }
  };

  React.useEffect(() => {
    if (data && data.length > 0) {
      const obj = {};
      data.map((i) => {
        obj[i[0]] = {
          isOpen: false,
          num: i[1].length,
          data: i[1],
        };
      });

      setState((state) => ({ ...state, listData: obj }));
    }
  }, [data]);

  return (
    <div className="content-info">
      <div className="content-info-title">
        <h4>{title}</h4>
        {title === "所有廠商" &&
          <div className="content-info-title-Label-Detail">
            <img  loading={"lazy"} src={IPO}></img> <a style={{marginRight:"1%"}}>：股票上市櫃</a>
            <img  loading={"lazy"} src={InIndustry}></img> <a style={{marginRight:"1%"}}>：高討論度公司</a>
          </div>
        }
      </div>
      {type === "cities" && (
        <div className="content-info-cities-container">
          {state.listData &&
            Object.entries(state.listData).length > 0 &&
            Object.entries(state.listData)
              .sort((a, b) => b[1].num - a[1].num)
              .map((item) => (
                <div
                  className="content-info-cities-container-items"
                  onClick={(e) => toggleItem(e, item[0])}
                  // key={item[0]}
                >
                  <div className="content-info-cities-container-items-container">
                    <p className="content-info-cities-container-items-header">
                      {
                        cityCode.filter(
                          (city) =>
                            city.CityId.toString() === item[0].toString()
                        )[0].CityName
                      }
                    </p>{" "}
                    :{" "}
                    <p className="content-info-cities-container-items-values">
                      {item[1].data.length}
                    </p>
                  </div>
                  <Collapse in={item[1].isOpen}  style={item[1].isOpen ? { paddingTop: "10px", borderTop: "1px solid #ececec"} : {}}>
                    {item[1].data.map((company) => (
                      <p key={company.FactoryName} onClick={(e)=>{openCompanyPage(e, company.BusinessNo)}}>{company.FactoryName}</p>
                    ))}
                  </Collapse>
                </div>
              ))}
        </div>
      )}
      {type === "parks" && (
        <div className="content-info-parks-container">
          {state.listData &&
            Object.entries(state.listData).length > 0 &&
            Object.entries(state.listData)
              .sort((a, b) => b[1].num - a[1].num)
              .filter(item => item[0] !== "Other")
              .map((item) => (
                <div
                  className="content-info-parks-container-items"
                  onClick={(e) => toggleItem(e, item[0])}
                  key={item[0]}
                >
                  <div className="content-info-parks-container-items-container">
                    <p className="content-info-parks-container-items-header">
                      {item[0] === "Other" ? "其他" : item[0]}
                    </p>{" "}
                    :{" "}
                    <p className="content-info-parks-container-items-values">
                      {item[1].data.length}
                    </p>
                  </div>
                  <Collapse in={item[1].isOpen} style={item[1].isOpen ? { paddingTop: "10px", borderTop: "1px solid #ececec"} : {}}>
                    {item[1].data.map((company) => (
                      <p key={company.FactoryName} onClick={(e)=>{openCompanyPage(e, company.BusinessNo)}}>{company.FactoryName}</p>
                    ))}
                  </Collapse>
                </div>
              ))}
        </div>
      )}
      {type === "area" && (
        <div className="content-info-area-container">
          {state.listData &&
            Object.entries(state.listData).length > 0 &&
            Object.entries(state.listData)
              .sort((a, b) => b[1].num - a[1].num)
              .map((item) => (
                <div
                  style={{flexDirection:"column"}}
                  className="content-info-area-container-items"
                  onClick={(e) => toggleItem(e, item[0])}
                  key={item[0]}
                >
                  <div className="content-info-area-container-items-container">
                    <p className="content-info-area-container-items-header">
                      {areaTransform(item[0])}
                    </p>{" "}
                    :{" "}
                    <p className="content-info-area-container-items-values">
                      {item[1].data.length}
                    </p>
                  </div>
                  <Collapse in={item[1].isOpen}  style={item[1].isOpen ? { paddingTop: "10px", borderTop: "1px solid #ececec"} : {}}>
                    {item[1].data.map((company) => (
                      <p key={company.FactoryName} onClick={(e)=>{openCompanyPage(e, company.BusinessNo)}}>{company.FactoryName}</p>
                    ))}
                  </Collapse>
                </div>
              ))}
        </div>
      )}
      {type === "all" && (
        <div className="content-info-area-container all-companies">
          {allCompany.map((company, index) => (
            <div style={{alignItems:'center'}} className="content-info-area-container-items">
              <p key={company.businessNo + index} onClick={(e)=>{openCompanyPage(e, company.businessNo)}}>{company.name}</p>
              <div className="content-info-area-container-items-Label" style={{right:(company.isIPO === "true" && company.isInIndustry === true) ? "2.5rem" :"0.5rem"}}>
                <img  loading={"lazy"} alt="" src={company.isIPO === "true" ? IPO : "" }></img>
                <img  loading={"lazy"} alt="" src={company.isInIndustry === true ? InIndustry : "" }></img>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
