import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";

import { thousandDivider } from "../../utility/common";

import "./styles.scss";

const DataGenderLawTable = (props) => {
  const { data, headerList } = props;
  const [state, setState] = React.useState({
    endIndex: 0,
    page: 0,
    rowsPerPage: 5,
  });
  const url = window.location.origin;

  let content;

  const handleChangePage = (event, newPage) => {
    setState((state) => ({ ...state, page: newPage }));
  };
  const openNewTabs = (link) => window.open(link, "_blank");
  return (
    <TableContainer className="table-container">
      <Table className="table">
        <TableHead className="table-header">
          {headerList && (
            <TableRow className="table-row">
              {headerList.map((headerItem) => (
                <TableCell className="table-cell" align="left">
                  {headerItem}
                </TableCell>
              ))}
            </TableRow>
          )}
        </TableHead>
        <TableBody className="table-body">
          {data?.slice(
              state.page * state.rowsPerPage,
              state.page * state.rowsPerPage + state.rowsPerPage
            )
            .map((row, index) => (
              <TableRow
                key={index}
                hover
                className="table-body-row"
                style={index % 2 !== 0 ? { backgroundColor: "#ececec" } : {}}
              >
                <TableCell align="left" style={{ width: "10%" }}>
                  {row.announcement_date ? row.announcement_date : "無相關資料"}
                </TableCell>
                <TableCell align="left" style={{ width: "10%" }}>
                  {row.penalty_date ? row.penalty_date : "無相關資料"}
                </TableCell>
                <TableCell align="left" style={{ width: "30%" }}>
                  {row.violation_clause ? row.violation_clause : "無相關資料"}
                </TableCell>
                <TableCell align="left" style={{ width: "50%" }}>
                  {row.law_description ? row.law_description : "無相關資料"}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      {data?.length === 0 && (
        <Table>
          <TableBody className="table-body-no-data">
            <TableRow hover className="table-body-row">
              <TableCell align="center" className="no-data">
                本項目無資料
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      )}
      {data?.length > 5 && (
        <TablePagination
          rowsPerPageOptions={[]}
          component="div"
          count={data?.length}
          rowsPerPage={state.rowsPerPage}
          page={state.page}
          onChangePage={handleChangePage}
          // onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      )}
    </TableContainer>
  );
};

export default React.memo(DataGenderLawTable);