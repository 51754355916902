import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import "../ParkDashboard/styles.scss";
import Button from "@material-ui/core/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";
import { baseUrl, loginbaseUrl } from "../../constants/endpoints";

//components
import CityDashboradModal from "../../components/AreaDashboradModal/CityDashboradModal";
import CityDashboardHeader from "../../components/CityDashboardHeader/CityDashboardHeader";
import CustomizedCardV2 from "../../components/CustomizedCard/CustomizedCardV2";
import CustomizedCardV3 from "../../components/CustomizedCard/CustomizedCardV3";
import CustomizedGraphic from "../../components/CustomizedGraphic/CustomizedGraphicV2";
import CustomizedTable from "../../components/CustomizedTable/CustomizedTableV2";
import CustomizedPublicStock from "../../components/CustomizedPublicStock/CustomizedPublicStock";
import DashboardMap from "../../components/DashboradMap/DashboradMap";
import DetailInfo from "./DetailInfo";
import FactoryAreaRentTable from "../../components/CustomizedTable/ParkFactoryAreaRentTable";
import Introduction from "../../components/Introducrtion/Introducrtion";
import MainIndex from "../../components/MainIndex/MainIndex";
import Wordcloud from "../../components/WordCloud/WordCloud";
import Modal from "../../components/CustomizedModal/CustomizedModal";
import NewsInfoTable from "../../components/CustomizedTable/NewsInfoTable";
import ServiceCenterParkLinkTable from "../../components/CustomizedTable/ServiceCenterParkLinkTable";
import WasteDisposalTable from "../../components/CustomizedTable/WasteDisposalTable";
import {
  parksWithUrl,
  AreaAndCity,
  parkDashboardList,
  northParks,
  centerParks,
  eastParks,
  southParks,
} from "../../constants/parks";
import { ScrollContainer, InnerContainer } from "../../utility/styles";
import BackToTopButton from "../../components/BackToTopButton/BackToTopButtonV2";
import WeatherBox from "../../components/WeatherBox";

import {
  getFactoryAreaRentDataHandler,
  getSectionIndexHandler,
  getSectionNewsTagHandler,
  getSectionNewsContentHandler,
  getSectionPublicSharingHandler, //股票發行
  getSectionPublicHandler,
  getSectionImportPercentageHandler,
  getSectionExportPercentageHandler,
  getTwoCodePercentageHandler,
  getThreeCodePercentageHandler,
  getFourCodePercentageHandler,
  getSectionTopTenCapitalHandler,
  getSectionTopTenGovernmentPlanHandler,
  getSectionTopTenPatentHandler,
  getSectionTopTenEmpHandler,
  getSectionGovPlanPercentHandler,
  getSectionAwardPercentHandler,
  getMapSearchHandler,
  getTopFiveWasteDisposalHandler,
  getServiceCenterParkLinkDataHandler,
  getSectionMapHandler,
  getUpdateSectionMapHandler,
  getBriefAndRankingDataHandler,
  //目前沒用到
  // getSectionPatentPercentHandler,
  // getSectionPublicTopTenGreenHandler,
} from "../../store/actions/parkDashboard_beta";

export default (props) => {
  const containerRef = React.useRef(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [state, setState] = React.useState({
    selectedPark: "",
    parkOptions: [],
    newsListPage: 1,
    perPageCount: 5,
    modalType: "",
    referenceCode: "",
    isModalOpen: false,
    title: "",
    persistModalOpen: false,
    parkId: "",
  });

  const {
    sectionIndex,
    twoCodePercentage,
    threeCodePercentage,
    fourCodePercentage,
    factoryAreaRent,
    sectionPublic,
    sectionNewsTag,
    sectionNewsContent,
    topFiveWasteDisposal,
    sectionTopTenPatent,
    sectionAwardPercent,
    sectionTopTenGovPlan,
    sectionGovPlanPercent,
    sectionTopTenCapital,
    sectionTopTenEmployee,
    sectionImportPercentage,
    sectionExportPercentage,
    sectionPublicSharing,
    serviceCenterParkLink,
    sectionMap,
    briefAndRanking,
    mapSearch,
  } = useSelector((state) => state.parkDashboard_beta);

  const areaCode = props.match.params.park_id;
  const arr = props.match.params;
  const uid = localStorage.getItem("uid");

  const handleRequests = React.useCallback(
    (selectedPark, uid, file) => {
      Promise.all([
        dispatch(getSectionIndexHandler(selectedPark)),
        dispatch(getTwoCodePercentageHandler(selectedPark)),
        dispatch(getThreeCodePercentageHandler(selectedPark)),
        dispatch(getFourCodePercentageHandler(selectedPark)),
        dispatch(getFactoryAreaRentDataHandler(selectedPark)),
        dispatch(getSectionNewsTagHandler(selectedPark)),
        dispatch(getSectionNewsContentHandler(selectedPark)),
        dispatch(getTopFiveWasteDisposalHandler(selectedPark)),
        dispatch(getSectionTopTenPatentHandler(selectedPark)),
        dispatch(getSectionAwardPercentHandler(selectedPark)),
        dispatch(getSectionTopTenGovernmentPlanHandler(selectedPark)),
        dispatch(getSectionGovPlanPercentHandler(selectedPark)),
        dispatch(getSectionTopTenCapitalHandler(selectedPark)),
        dispatch(getSectionTopTenEmpHandler(selectedPark)),
        dispatch(getSectionImportPercentageHandler(selectedPark)),
        dispatch(getSectionExportPercentageHandler(selectedPark)),
        dispatch(getSectionPublicSharingHandler(selectedPark)),
        dispatch(getSectionPublicHandler(selectedPark)),
        dispatch(getServiceCenterParkLinkDataHandler(selectedPark)),
        dispatch(getBriefAndRankingDataHandler(selectedPark)),
        dispatch(getSectionMapHandler(uid)),
        dispatch(getMapSearchHandler(file)),
      ]);
    },
    [dispatch]
  );

  React.useEffect(() => {
    const file = new FormData();

    file.append("KeyWordList", "");
    file.append("CitiesList", "");
    file.append("Area", "All");
    file.append("TypeTwoList", "");
    file.append("TypeFourList", "");
    file.append("MinCapital", "");
    file.append("MaxCapital", "");
    file.append("isIPO", "false");
    file.append("isInduParks", "false");
    file.append("Operator", "or");

    let currentParkName;
    const parksList = [
      ...northParks,
      ...parkDashboardList,
      ...centerParks,
      ...eastParks,
      ...southParks,
    ];

    try {
      currentParkName =
        parksList.filter((park) => park.ParkId === areaCode)[0]["Name_CH"]
          ? parksList.filter((park) => park.ParkId === areaCode)[0]["Name_CH"]
          : parksWithUrl.filter((park) => park.ParkId === areaCode)[0].Name;

      if (currentParkName === "大埔美精密機械園區") {
        currentParkName = "嘉義大埔美精密機械園區";
      }
    } catch (err) {
      currentParkName = "嘉太工業區";
    }

    file.append("InduParksList", [currentParkName]);

    console.log(currentParkName);

    if (Object.keys(arr).length === 0) {
      if (location.pathname.slice(-1) === "/") {
        history.push(`${location.pathname}HIP015`);
      } else {
        history.push(`${location.pathname}/HIP015`);
      }
      handleRequests("HIP015");
      setState((state) => ({ ...state, selectedPark: "HIP015" }));
    } else if (
      !AreaAndCity[areaCode] &&
      parkDashboardList.filter((park) => park.ParkId === areaCode).length === 0
    ) {
      history.push("HIP015");
      handleRequests("HIP015");
    } else {
      handleRequests(areaCode, uid, file);
      setState((state) => ({
        ...state,
        selectedPark: areaCode,
      }));
    }
  }, [areaCode]);

  React.useEffect(() => {
    const parkList = [];
    parksWithUrl.map((item) => {
      const { ParkId } = item;
      parkList.push({
        ...item,
        key: item.ParkId,
        value: item.ParkId,
        name: item.Name,
      });
    });
    setState((state) => ({ ...state, parkOptions: parkList }));
  }, []);

  const onChangeHandler = (value) => {
    setState((state) => ({ ...state, selectedPark: value }));
    history.push(`${value}`);
  };

  const handleNewsPage = (page) => {
    setState((state) => ({ ...state, newsListPage: page }));
  };

  const handleClose = React.useCallback(() => {
    if (!state.persistModalOpen) {
      setState((state) => ({
        ...state,
        isModalOpen: !state.isModalOpen,
        modalType: "",
        referenceCode: "",
      }));
    }
  }, [state.persistModalOpen]);

  const handleModalOpen = (modalType, referenceCode, title, wasteNumber) => {
    if (
      modalType &&
      ((referenceCode && referenceCode !== "其他") ||
        referenceCode === Number(0))
      //&& referenceCode !== 5
    ) {
      setState((state) => ({
        ...state,
        isModalOpen: true,
        modalType,
        referenceCode,
        title,
        wasteNumber,
      }));
    } else {
      return;
    }
  };

  const openNewTab = (businessNo, type) => {
    if (!businessNo) {
      return;
    } else {
      const url = `${window.location.origin}`;
      window.open(`${url}/factory_info/basic_info/${businessNo}`, "_blank");
    }
  };

  const persistModalOpenHandler = (value) => {
    setState((state) => ({ ...state, persistModalOpen: value }));
  };

  const toggleCardHandler = (key) => {
    const form = sectionMap?.sectionMapData?.[key];
    dispatch(getUpdateSectionMapHandler(form, uid));
  };

  const scrollToTop = () => {
    containerRef.current.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <ScrollContainer className="area-dashboard" ref={containerRef}>
      <BackToTopButton scrollToTop={scrollToTop} />
      <Modal isOpen={state.isModalOpen} handleClose={handleClose}>
        <CityDashboradModal
          modalType={state.modalType}
          referenceCode={state.referenceCode}
          handleClose={handleClose}
          parkId={state.selectedPark}
          title={state.title}
          wasteNumber={state.wasteNumber}
          persistModalOpenHandler={persistModalOpenHandler}
        />
      </Modal>
      <InnerContainer>
        <div className="park-dashboard-overview">
          <CityDashboardHeader
            title="總覽"
            subTitle={`園區現況基本資料`}
            onChangeHandler={onChangeHandler}
            selectionOption={state.parkOptions}
            selectedCity={state.selectedPark}
            referenceType={"park_dashboard_overview"}
          />

          {localStorage.getItem("18") && (
            <div className="excel-btn-container">
              <Button
                className="export-excel-btn"
                href={`${baseUrl}/api/InduParkSingle/GetSingleParkFactoryDataExcel/${areaCode}`}
                startIcon={
                  <FontAwesomeIcon
                    className="export-excel-icon"
                    icon={faFileExcel}
                  />
                }>
                匯出園區廠商名錄
              </Button>
            </div>
          )}

          <div className="park-dashboard-overview-container">
            <CustomizedCardV2
              title="簡介"
              isOpen={sectionMap?.sectionMapData?.["introduction"]?.isOpen}
              toggleCard={() => {
                toggleCardHandler("introduction");
              }}
              loading={sectionIndex.sectionIndexLoading}>
              <Introduction
                selectedCity={state.selectedPark}
                sectionData={sectionIndex?.sectionIndexData}
              />
            </CustomizedCardV2>
            {!areaCode.includes("EPZ") &&
              <>
                <CustomizedCardV2
                  title={"詳細資訊"}
                  isOpen={sectionMap?.sectionMapData?.["detail"]?.isOpen}
                  toggleCard={() => {
                    toggleCardHandler("detail");
                  }}
                  loading={sectionIndex.sectionIndexLoading}
                  noSouthArea>
                  <DetailInfo sectionData={sectionIndex.sectionIndexData} />
                </CustomizedCardV2>

                <CustomizedCardV2
                  title="概況"
                  isOpen={sectionMap?.sectionMapData?.["brief"]?.isOpen}
                  toggleCard={() => {
                    toggleCardHandler("brief");
                  }}
                  loading={sectionIndex.sectionIndexLoading}
                  noSouthArea>
                  <MainIndex
                    data={sectionIndex?.sectionIndexData}
                    briefAndRanking={briefAndRanking}
                    parkId={state.parkId}
                  />
                </CustomizedCardV2>
              </>
            }
            {serviceCenterParkLink.serviceCenterParkLinkData.length > 0 && (
              <CustomizedCardV3
                title={`所屬同服務中心園區`}
                shouldCardOpen={true}
                loading={serviceCenterParkLink.serviceCenterParkLinkLoading}>
                <ServiceCenterParkLinkTable
                  headerList={["園區名", "廠商數(家)", "產值(億)", "員工數"]}
                  data={serviceCenterParkLink.serviceCenterParkLinkData}
                />
              </CustomizedCardV3>
            )}

            {mapSearch && localStorage.getItem("isLogin") === "true" && (
              <CustomizedCardV3
                title="園區地圖"
                isOpen={sectionMap?.sectionMapData?.["parkMap"]?.isOpen}
                toggleCard={() => {
                  toggleCardHandler("parkMap");
                }}>
                <DashboardMap
                  type={"park"}
                  registeredCompanyPositionList={mapSearch.mapSearchData}
                />
              </CustomizedCardV3>
            )}
            <CustomizedCardV3
              title="即時空氣品質/天氣資訊"
              isOpen={sectionMap?.sectionMapData?.["weatherInfo"]?.isOpen}
              toggleCard={() => {
                toggleCardHandler("weatherInfo");
              }}
              loading={false}
              noSouthArea
              referenceType={"park_dashboard_weather_card"}>
              <WeatherBox
                park_id={state.selectedPark}
                sectionIndex={sectionIndex}
              />
            </CustomizedCardV3>

            {!areaCode.includes("EPZ") &&
              <>
              <CustomizedCardV3
                title={`土地廠房租售資訊`}
                shouldCardOpen={true}
                isOpen={sectionMap?.sectionMapData?.["rentInfo"]?.isOpen}
                toggleCard={() => {
                  toggleCardHandler("rentInfo");
                }}
                loading={factoryAreaRent.factoryAreaRentLoading}
                referenceType={"park_dashboard_rental_card"}>
                <FactoryAreaRentTable
                  headerList={["刊登時間", "面積", "類別", "單價", "網址"]}
                  data={factoryAreaRent.factoryAreaRentData}
                />
              </CustomizedCardV3>
              </>
            }
            {/* <CustomizedCardV3 title="綠化率(%)">
                  {!greenInfo && <Spinner />}
                  {greenInfo && (
                    <div className="data-info-table">
                      <CustomizedGraphic
                        data={[100 - greenInfo.綠化率[0], greenInfo.綠化率[0]]}
                        chartType="donut"
                        labels={["尚未綠化比率", "綠化率"]}
                      />
                      <CustomizedInfoBar data={greenInfo} />
                    </div>
                  )}
            </CustomizedCardV3> */}
          </div>
        </div>
        <div className="park-dashboard-popular-topics">
          <CityDashboardHeader
            title="熱門議題"
            subTitle="以園區與區內廠商名稱關鍵字分析新聞熱門議題"
            referenceType={"park_dashboard_popular_issues"}
          />
          <div className="park-dashboard-overview-news-container">
            {
              localStorage.getItem("isLogin") === "true" && <CustomizedCardV2
                title="新聞標籤雲"
                isOpen={sectionMap?.sectionMapData?.["tag"]?.isOpen}
                toggleCard={() => {
                  toggleCardHandler("tag");
                }}
                loading={sectionNewsTag.sectionNewsTagLoading}>
                <Wordcloud
                  tag={sectionNewsTag.sectionNewsTagData}
                  eventHandler={(val, title) =>
                    handleModalOpen("sectionNewsTag", val, title)
                  }
                />
              </CustomizedCardV2>
            }
            <CustomizedCardV2
              title="相關新聞"
              isOpen={sectionMap?.sectionMapData?.["news"]?.isOpen}
              toggleCard={() => {
                toggleCardHandler("news");
              }}
              loading={sectionNewsContent.sectionNewsContentLoading}>
              <NewsInfoTable
                headerList={["新聞標題", "日期/來源", "相關廠商"]}
                data={sectionNewsContent.sectionNewsContentData.Datas}
                // newsInfoTotalLength={
                //   sectionNewsContent.sectionNewsContentLength
                // }
                // newsInfoRowNum={sectionNewsContent.sectionNewsContentMaxRowNum}
                // newsInfoPagingNum={
                //   sectionNewsContent.sectionNewsContentPagingNum
                // }
                // getNewsListByNewPage={handleNewsPage}
                // selectedCity=""
              />
            </CustomizedCardV2>
          </div>
        </div>
        <div className="park-dashboard-industry-view">
          <CityDashboardHeader
            title="產業形貌"
            subTitle="以主計總處產業類別2/3/4碼呈現園區廠商產業分佈"
            referenceType={"park_dashboard_categories_view"}
          />
          <div className="park-dashboard-industry-view-container">
            <CustomizedCardV2
              title="園區廠商產業別二碼家數佔比"
              isOpen={sectionMap?.sectionMapData?.["typeTwo"]?.isOpen}
              toggleCard={() => {
                toggleCardHandler("typeTwo");
              }}
              data={twoCodePercentage.twoCodePercentageDataMap}
              loading={twoCodePercentage.twoCodePercentageLoading}
              eventHandler={(val, title) =>
                handleModalOpen("SectionTypeTwo", val, title)
              }>
              <CustomizedGraphic
                title="園區廠商產業別二碼家數佔比"
                thead={["園區廠商產業別二碼家數", "佔比(%)"]}
                chartType={"donut-chart"}
                data={twoCodePercentage.twoCodePercentageData}
                circle={"70"}
                labels={twoCodePercentage.twoCodePercentageLabel}
                mapData={twoCodePercentage.twoCodePercentageDataMap}
                dataLabels={{
                  enabled: true,
                  formatter: (val, { seriesIndex, dataPointIndex, w }) =>
                    w.config.labels[seriesIndex],
                  style: {
                    fontSize: "400px",
                    color: "red",
                    fontFamily: "Helvetica, Arial, sans-serif",
                    fontWeight: "bold",
                  },
                }}
                eventHandler={(val, title) =>
                  handleModalOpen("SectionTypeTwo", val, title)
                }
              />
            </CustomizedCardV2>
            <CustomizedCardV2
              title="園區廠商產業別三碼家數佔比"
              isOpen={sectionMap?.sectionMapData?.["typeThree"]?.isOpen}
              toggleCard={() => {
                toggleCardHandler("typeThree");
              }}
              data={threeCodePercentage.threeCodePercentageDataMap}
              loading={threeCodePercentage.threeCodePercentageLoading}
              eventHandler={(val, title) =>
                handleModalOpen("SectionTypeThree", val, title)
              }>
              <CustomizedGraphic
                title="園區廠商產業別三碼家數佔比"
                thead={["園區廠商產業別三碼家數", "佔比(%)"]}
                chartType={"donut-chart"}
                data={threeCodePercentage.threeCodePercentageData}
                circle={"70"}
                labels={threeCodePercentage.threeCodePercentageLabel}
                mapData={threeCodePercentage.threeCodePercentageDataMap}
                dataLabels={{
                  enabled: true,
                  formatter: (val, { seriesIndex, dataPointIndex, w }) =>
                    w.config.labels[seriesIndex],
                }}
                eventHandler={(val, title) =>
                  handleModalOpen("SectionTypeThree", val, title)
                }
              />
            </CustomizedCardV2>
            <CustomizedCardV2
              title="園區廠商產業別四碼家數佔比"
              isOpen={sectionMap?.sectionMapData?.["typeFour"]?.isOpen}
              toggleCard={() => {
                toggleCardHandler("typeFour");
              }}
              data={fourCodePercentage.fourCodePercentageDataMap}
              loading={fourCodePercentage.fourCodePercentageLoading}
              eventHandler={(val, title) =>
                handleModalOpen("SectionTypeFour", val, title)
              }>
              <CustomizedGraphic
                title="園區廠商產業別四碼家數佔比"
                thead={["園區廠商產業別四碼家數", "佔比(%)"]}
                chartType={"donut-chart"}
                data={fourCodePercentage.fourCodePercentageData}
                circle={"70"}
                labels={fourCodePercentage.fourCodePercentageLabel}
                mapData={fourCodePercentage.fourCodePercentageDataMap}
                dataLabels={{
                  enabled: true,
                  formatter: (val, { seriesIndex, dataPointIndex, w }) =>
                    w.config.labels[seriesIndex],
                }}
                eventHandler={(val, title) =>
                  handleModalOpen("SectionTypeFour", val, title)
                }
              />
            </CustomizedCardV2>
          </div>
        </div>
        <div className="park-dashboard-index-rating">
          <CityDashboardHeader
            title="指標排行"
            subTitle="呈現區內廠商股票公開發行、園區廠商進出口級距佔比與政府計畫排行等圖表"
            referenceType={"park_dashboard_ranking_view"}
          />
          <div className="park-dashboard-index-rating-container">
            <CustomizedCardV2
              title={"股票上市櫃廠商推薦"}
              isOpen={sectionMap?.sectionMapData?.["stockRanking"]?.isOpen}
              toggleCard={() => {
                toggleCardHandler("stockRanking");
              }}
              loading={sectionPublic.sectionPublicLoading}>
              <CustomizedTable
                headerList={["統一編號", "廠商名稱", "設廠園區"]}
                data={sectionPublic.sectionPublicData}
                isArea
              />
            </CustomizedCardV2>
            <CustomizedCardV2
              title={"股票非上市櫃廠商推薦"}
              isOpen={sectionMap?.sectionMapData?.["noneStockRanking"]?.isOpen}
              toggleCard={() => {
                toggleCardHandler("noneStockRanking");
              }}
              loading={sectionPublic.sectionPublicLoading}>
              <CustomizedTable
                headerList={["統一編號", "廠商名稱", "設廠園區"]}
                data={sectionPublic.sectionNonPublicData}
                isArea
              />
            </CustomizedCardV2>
            <CustomizedCardV2
              title={"進口級距廠商家數佔比"}
              isOpen={sectionMap?.sectionMapData?.["importRanking"]?.isOpen}
              toggleCard={() => {
                toggleCardHandler("importRanking");
              }}
              data={sectionImportPercentage.sectionImportPercentageMap}
              loading={sectionImportPercentage.sectionImportPercentageLoading}
              eventHandler={(val, title) =>
                handleModalOpen("SectionImport", val, title)
              }>
              <CustomizedGraphic
                chartType={"donut-chart"}
                title="進口級距廠商家數佔比"
                thead={["進口級距廠商家數", "佔比(%)"]}
                data={sectionImportPercentage.sectionImportPercentageData}
                circle={"70"}
                labels={sectionImportPercentage.sectionImportPercentageLabel}
                mapData={sectionImportPercentage.sectionImportPercentageMap}
                dataLabels={{
                  enabled: true,
                  formatter: (val, { seriesIndex, dataPointIndex, w }) =>
                    w.config.labels[seriesIndex],
                }}
                eventHandler={(val, title) =>
                  handleModalOpen("SectionImport", val, title)
                }
              />
            </CustomizedCardV2>
            <CustomizedCardV2
              title={"出口級距廠商家數佔比"}
              isOpen={sectionMap?.sectionMapData?.["importRanking"]?.isOpen}
              toggleCard={() => {
                toggleCardHandler("importRanking");
              }}
              data={sectionExportPercentage.sectionExportPercentageMap}
              loading={sectionExportPercentage.sectionExportPercentageLoading}
              eventHandler={(val, title) =>
                handleModalOpen("SectionExport", val, title)
              }>
              <CustomizedGraphic
                chartType={"donut-chart"}
                title="出口級距廠商家數佔比"
                thead={["出口級距廠商家數", "佔比(%)"]}
                data={sectionExportPercentage?.sectionExportPercentageData}
                circle={"70"}
                labels={sectionExportPercentage?.sectionExportPercentageLabel}
                mapData={sectionExportPercentage?.sectionExportPercentageMap}
                dataLabels={{
                  enabled: true,
                  formatter: (val, { seriesIndex, dataPointIndex, w }) =>
                    w.config.labels[seriesIndex],
                }}
                eventHandler={(val, title) =>
                  handleModalOpen("SectionExport", val, title)
                }
              />
            </CustomizedCardV2>
            <CustomizedCardV2
              title={"股票公開發行廠商家數佔比"}
              isOpen={sectionMap?.sectionMapData?.["stockPercentage"]?.isOpen}
              toggleCard={() => {
                toggleCardHandler("stockPercentage");
              }}
              loading={sectionPublicSharing.sectionPublicSharingLoading}>
              <CustomizedPublicStock
                data={sectionPublicSharing}
                eventHandler={(val, title) =>
                  handleModalOpen("SectionStock", val, title)
                }
              />
            </CustomizedCardV2>

            {window.innerWidth > 1024 && (
              <CustomizedCardV2
                title={"園區廠商員工數前十大排行"}
                isOpen={sectionMap?.sectionMapData?.["employeeTopTen"]?.isOpen}
                toggleCard={() => {
                  toggleCardHandler("employeeTopTen");
                }}
                referenceType="areaDashboradEmployeeRanking"
                loading={sectionTopTenEmployee.sectionTopTenEmployeeloading}
                eventHandler={(val) => openNewTab(val, "employee")}>
                <CustomizedGraphic
                  title="園區廠商員工數前十大排行"
                  thead={["廠商", "員工數(人)"]}
                  chartType={"horizontal-bar-chart"}
                  data={sectionTopTenEmployee.sectionTopTenEmployeeData}
                  labels={sectionTopTenEmployee.sectionTopTenEmployeeLabels}
                  mapData={sectionTopTenEmployee.sectionTopTenEmployeeMap}
                  unit={"人"}
                  formatter={(val) => val}
                  color={["#6bcf00"]}
                  type={"employee"}
                  eventHandler={(val) => openNewTab(val, "employee")}
                />
              </CustomizedCardV2>
            )}

            <CustomizedCardV2
              title={"園區廠商資本額前十大排行"}
              isOpen={sectionMap?.sectionMapData?.["capitalTopTen"]?.isOpen}
              toggleCard={() => {
                toggleCardHandler("capitalTopTen");
              }}
              data={sectionTopTenCapital.sectionTopTenCapitalDataMap}
              rankingList={sectionTopTenCapital.sectionTopTenCapitalDataMap}
              loading={sectionTopTenCapital.sectionTopTenCapitalLoading}
              eventHandler={(val) => openNewTab(val, "capital")}>
              <CustomizedGraphic
                chartType={"vertical-bar-chart"}
                title="園區廠商資本額前十大排行"
                thead={["廠商", "資本額(元)"]}
                data={sectionTopTenCapital.sectionTopTenCapitalData}
                labels={sectionTopTenCapital.sectionTopTenCapitalDataLabels}
                mapData={sectionTopTenCapital.sectionTopTenCapitalDataMap}
                unit={"元"}
                formatter={(val) => (val === 0 ? 0 : val / 100000000 + "億")}
                eventHandler={(val) => openNewTab(val, "capital")}
              />
            </CustomizedCardV2>

            {window.innerWidth > 1024 && (
              <CustomizedCardV2
                title={"園區廠商政府計畫類型佔比"}
                isOpen={
                  sectionMap?.sectionMapData?.["patentPercentage"]?.isOpen
                }
                toggleCard={() => {
                  toggleCardHandler("patentPercentage");
                }}
                data={sectionGovPlanPercent.sectionGovPlanPercentDataMap}
                loading={sectionGovPlanPercent.sectionGovPlanPercentloading}
                eventHandler={(val, title) =>
                  handleModalOpen("sectionGovPlanPercent", val, title)
                }>
                <CustomizedGraphic
                  chartType={"donut-chart"}
                  title="園區廠商政府計畫類型佔比"
                  thead={["園區廠商政府計畫類型", "佔比(%)"]}
                  data={sectionGovPlanPercent.sectionGovPlanPercentData}
                  circle={"70"}
                  labels={sectionGovPlanPercent.sectionGovPlanPercentDataLabels}
                  mapData={sectionGovPlanPercent.sectionGovPlanPercentDataMap}
                  isGovPlanOrAwardPercentage
                  dataLabels={{
                    enabled: true,
                    formatter: (val, { seriesIndex, dataPointIndex, w }) =>
                      w.config.labels[seriesIndex],
                  }}
                  eventHandler={(val, title) =>
                    handleModalOpen("sectionGovPlanPercent", val, title)
                  }
                />
              </CustomizedCardV2>
            )}

            <CustomizedCardV2
              title={"園區廠商政府計畫數前十大排行"}
              isOpen={sectionMap?.sectionMapData?.["govPlanTopten"]?.isOpen}
              toggleCard={() => {
                toggleCardHandler("govPlanTopten");
              }}
              data={sectionTopTenGovPlan.sectionTopTenGovPlanDataMap}
              loading={sectionTopTenGovPlan.sectionTopTenGovPlanLoading}
              eventHandler={(val, title) =>
                handleModalOpen("SectionGovPlan", val, title)
              }>
              <CustomizedGraphic
                chartType={"horizontal-bar-chart"}
                title="園區廠商政府計畫數前十大排行"
                thead={["廠商", "計畫數(件)"]}
                data={sectionTopTenGovPlan.sectionTopTenGovPlanData}
                labels={sectionTopTenGovPlan.sectionTopTenGovPlanDataLabels}
                mapData={sectionTopTenGovPlan.sectionTopTenGovPlanDataMap}
                unit={"件"}
                formatter={(val) => val}
                eventHandler={(val, title) =>
                  handleModalOpen("SectionGovPlan", val, title)
                }
              />
            </CustomizedCardV2>

            {window.innerWidth > 1024 && (
              <CustomizedCardV2
                title={"園區廠商獲獎類型佔比"}
                isOpen={sectionMap?.sectionMapData?.["awardTopTen"]?.isOpen}
                toggleCard={() => {
                  toggleCardHandler("awardTopTen");
                }}
                data={sectionAwardPercent.sectionAwardPercentDataMap}
                rankingList={sectionAwardPercent.sectionAwardPercentDataMap}
                loading={sectionAwardPercent.sectionAwardPercentloading}
                eventHandler={(val, title) =>
                  handleModalOpen("SectionAward", val, title)
                }>
                <CustomizedGraphic
                  chartType={"donut-chart"}
                  title="園區廠商獲獎類型佔比"
                  thead={["園區廠商獲獎類型", "佔比(%)"]}
                  data={sectionAwardPercent.sectionAwardPercentData}
                  circle={"70"}
                  labels={sectionAwardPercent.sectionAwardPercentDataLabels}
                  mapData={sectionAwardPercent.sectionAwardPercentDataMap}
                  isGovPlanOrAwardPercentage
                  dataLabels={{
                    enabled: true,
                    formatter: (val, { seriesIndex, dataPointIndex, w }) =>
                      w.config.labels[seriesIndex],
                  }}
                  eventHandler={(val, title) =>
                    handleModalOpen("SectionAward", val, title)
                  }
                />
              </CustomizedCardV2>
            )}

            <CustomizedCardV2
              title={"園區廠商近年專利公告數前十大排行"}
              isOpen={sectionMap?.sectionMapData?.["patentTopTen"]?.isOpen}
              toggleCard={() => {
                toggleCardHandler("patentTopTen");
              }}
              data={sectionTopTenPatent.sectionTopTenPatentDataMap}
              loading={sectionTopTenPatent.sectionTopTenPatentLoading}
              eventHandler={(val, title) =>
                handleModalOpen("SectionPatent", val, title)
              }>
              <CustomizedGraphic
                chartType={"horizontal-bar-chart"}
                title="園區廠商近年專利公告數前十大排行"
                thead={["廠商", "專利公告數(件)"]}
                data={sectionTopTenPatent.sectionTopTenPatentData}
                labels={sectionTopTenPatent.sectionTopTenPatentDataLabels}
                mapData={sectionTopTenPatent.sectionTopTenPatentDataMap}
                unit={"件"}
                formatter={(val) => val}
                eventHandler={(val, title) =>
                  handleModalOpen("SectionPatent", val, title)
                }
              />
            </CustomizedCardV2>

            {window.innerWidth < 1024 && (
              <CustomizedCardV2
                title={"園區廠商員工數前十大排行"}
                isOpen={sectionMap?.sectionMapData?.["employeeTopTen"]?.isOpen}
                toggleCard={() => {
                  toggleCardHandler("employeeTopTen");
                }}
                referenceType="areaDashboradEmployeeRanking"
                loading={sectionTopTenEmployee.sectionTopTenEmployeeloading}
                eventHandler={(val) => openNewTab(val, "employee")}>
                <CustomizedGraphic
                  chartType={"horizontal-bar-chart"}
                  title="園區廠商員工數前十大排行"
                  thead={["廠商", "員工數(人)"]}
                  data={sectionTopTenEmployee.sectionTopTenEmployeeData}
                  labels={sectionTopTenEmployee.sectionTopTenEmployeeLabels}
                  mapData={sectionTopTenEmployee.sectionTopTenEmployeeMap}
                  unit={"人"}
                  formatter={(val) => val}
                  color={["#6bcf00"]}
                  type={"employee"}
                  eventHandler={(val) => openNewTab(val, "employee")}
                />
              </CustomizedCardV2>
            )}

            {window.innerWidth < 1024 && (
              <CustomizedCardV2
                title={"園區廠商政府計畫類型佔比"}
                isOpen={
                  sectionMap?.sectionMapData?.["patentPercentage"]?.isOpen
                }
                toggleCard={() => {
                  toggleCardHandler("patentPercentage");
                }}
                data={sectionGovPlanPercent.sectionGovPlanPercentDataMap}
                loading={sectionGovPlanPercent.sectionGovPlanPercentloading}
                eventHandler={(val, title) =>
                  handleModalOpen("sectionGovPlanPercent", val, title)
                }>
                <CustomizedGraphic
                  chartType={"donut-chart"}
                  title="園區廠商政府計畫類型佔比"
                  thead={["園區廠商政府計畫類型", "佔比(%)"]}
                  data={sectionGovPlanPercent.sectionGovPlanPercentData}
                  circle={"70"}
                  labels={sectionGovPlanPercent.sectionGovPlanPercentDataLabels}
                  mapData={sectionGovPlanPercent.sectionGovPlanPercentDataMap}
                  isGovPlanOrAwardPercentage
                  dataLabels={{
                    enabled: true,
                    formatter: (val, { seriesIndex, dataPointIndex, w }) =>
                      w.config.labels[seriesIndex],
                  }}
                  eventHandler={(val, title) =>
                    handleModalOpen("sectionGovPlanPercent", val, title)
                  }
                />
              </CustomizedCardV2>
            )}

            {window.innerWidth < 1024 && (
              <CustomizedCardV2
                title={"園區廠商獲獎類型佔比"}
                isOpen={sectionMap?.sectionMapData?.["awardTopTen"]?.isOpen}
                toggleCard={() => {
                  toggleCardHandler("awardTopTen");
                }}
                data={sectionAwardPercent.sectionAwardPercentDataMap}
                rankingList={sectionAwardPercent.sectionAwardPercentDataMap}
                loading={sectionAwardPercent.sectionAwardPercentloading}
                eventHandler={(val, title) =>
                  handleModalOpen("SectionAward", val, title)
                }>
                <CustomizedGraphic
                  chartType={"donut-chart"}
                  title="園區廠商獲獎類型佔比"
                  thead={["園區廠商獲獎類型", "佔比(%)"]}
                  data={sectionAwardPercent.sectionAwardPercentData}
                  circle={"70"}
                  labels={sectionAwardPercent.sectionAwardPercentDataLabels}
                  mapData={sectionAwardPercent.sectionAwardPercentDataMap}
                  isGovPlanOrAwardPercentage
                  dataLabels={{
                    enabled: true,
                    formatter: (val, { seriesIndex, dataPointIndex, w }) =>
                      w.config.labels[seriesIndex],
                  }}
                  eventHandler={(val, title) =>
                    handleModalOpen("SectionAward", val, title)
                  }
                />
              </CustomizedCardV2>
            )}

            <CustomizedCardV3
              title={`園區循環廢棄物申報量排行`}
              shouldCardOpen={true}
              isOpen={sectionMap?.sectionMapData?.["wasteRank"]?.isOpen}
              toggleCard={() => {
                toggleCardHandler("wasteRank");
              }}
              loading={topFiveWasteDisposal.topFiveWasteDisposalLoading}
              referenceType="wasteDisposal"
              noSouthArea>
              <WasteDisposalTable
                headerList={["廢棄物種類", "工廠家數", "總量(公噸)"]}
                data={topFiveWasteDisposal.topFiveWasteDisposalData}
                eventHandler={(val, title, wasteNumber) =>
                  handleModalOpen(
                    "SectionWasteDisposal",
                    val,
                    title,
                    wasteNumber
                  )
                }
              />
            </CustomizedCardV3>
          </div>
        </div>
      </InnerContainer>
    </ScrollContainer>
  );
};
