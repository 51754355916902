import * as actionTypes from "./actionTypes";
import {
  getFactoryByArea,
  getAllTypeTwo,
  getAllGovPlan,
  updateGovPlan,
  createGovPlan,
  deleteGovPlan,
  sendGovPlanInterestNotify,
  getInduGroup,
  createInduGroup,
  getCompanyListByParkId,
  getDeliveryLog,
  listByBusinessId,
  deleteInduGroup,
  updateInduGroup,
} from "../../utility/api";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
const MySwal = withReactContent(Swal);

export const getTypeTwo = () => async (dispatch) => {
  const typeTwo = await getAllTypeTwo();

  let updateObj = {};
  if (typeTwo !== "error") {
    typeTwo.map((item) => {
      updateObj[item.Key] = item.Value;
    });

    dispatch({
      type: actionTypes.GET_ALL_TYPE_TWO,
      typeTwo: typeTwo,
      typeTwoConvent: updateObj,
    });
  }
};

export const getAllGovPlans = (category, keyword) => async (dispatch) => {
  dispatch({ type: actionTypes.START_LOADING });
  const plans = await getAllGovPlan();

  if (plans.status === 200) {
    await dispatch({
      type: actionTypes.GET_ALL_GOVPLANS_SUCCESS,
      activePlans: plans.data.filter((data) => data.IsExpired === false),
      inActivePlans: plans.data.filter((data) => data.IsExpired === true),
    });

    await dispatch({ type: actionTypes.STOP_LOADING });
  } else {
    await dispatch({ type: actionTypes.GET_ALL_GOVPLANS_FAIL });
    await dispatch({ type: actionTypes.STOP_LOADING });
  }
};

export const updateGovPlanHandler = (data, projectId) => async (dispatch) => {
  await dispatch({ type: actionTypes.START_UPDATE_PLAN });

  const updatePlans = await updateGovPlan(data, projectId);

  if (updatePlans.status === 200) {
    await dispatch({ type: actionTypes.UPDATE_PLAN_SUCCESS });

    const plans = await getAllGovPlan();

    if (plans.status === 200) {
      await dispatch({
        type: actionTypes.GET_ALL_GOVPLANS_SUCCESS,
        activePlans: plans.data.filter((data) => data.IsExpired === false),
        inActivePlans: plans.data.filter((data) => data.IsExpired === true),
      });

      await dispatch({ type: actionTypes.STOP_LOADING });
    } else {
      await dispatch({ type: actionTypes.GET_ALL_GOVPLANS_FAIL });
      await dispatch({ type: actionTypes.STOP_LOADING });
    }
  } else {
    await dispatch({ type: actionTypes.UPDATE_PLAN_FAIL });
    const plans = await getAllGovPlan();

    if (plans.status === 200) {
      await dispatch({
        type: actionTypes.GET_ALL_GOVPLANS_SUCCESS,
        activePlans: plans.data.filter((data) => data.IsExpired === false),
        inActivePlans: plans.data.filter((data) => data.IsExpired === true),
      });

      await dispatch({ type: actionTypes.STOP_LOADING });
    } else {
      await dispatch({ type: actionTypes.GET_ALL_GOVPLANS_FAIL });
      await dispatch({ type: actionTypes.STOP_LOADING });
    }
  }
};

export const createGovPlanHandler = (data) => async (dispatch) => {
  dispatch({ type: actionTypes.START_CREATE_GOVPLAN });

  const planCreated = await createGovPlan(data);

  if (planCreated.status === 200) {
    await dispatch({ type: actionTypes.CREATE_GOVPLAN_SUCCESS });

    const plans = await getAllGovPlan();

    if (plans.status === 200) {
      dispatch({
        type: actionTypes.GET_ALL_GOVPLANS_SUCCESS,
        activePlans: plans.data.filter((data) => data.IsExpired === false),
        inActivePlans: plans.data.filter((data) => data.IsExpired === true),
      });
      dispatch({ type: actionTypes.STOP_LOADING });
    } else {
      dispatch({ type: actionTypes.GET_ALL_GOVPLANS_FAIL });
      dispatch({ type: actionTypes.STOP_LOADING });
    }
  } else {
    await dispatch({ type: actionTypes.CREATE_GOVPLAN_FAIL });

    const plans = await getAllGovPlan();

    if (plans.status === 200) {
      dispatch({
        type: actionTypes.GET_ALL_GOVPLANS_SUCCESS,
        activePlans: plans.data.filter((data) => data.IsExpired === false),
        inActivePlans: plans.data.filter((data) => data.IsExpired === true),
      });

      // dispatch({ type: actionTypes.STOP_LOADING });
    } else {
      dispatch({ type: actionTypes.GET_ALL_GOVPLANS_FAIL });
      dispatch({ type: actionTypes.STOP_LOADING });
    }
  }
};

export const sendGovPlanInterestNotifyHandler = (data) => async (dispatch) => { 
  const notify = await sendGovPlanInterestNotify(data);
  const Toast = MySwal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
  });
  if (notify.status === "success") {
    Toast.fire({
      icon: "success",
      title: `${notify.description}`,
    })
  } else {
    Toast.fire({
      icon: "error",
      title: `${notify.description}`,
    })
  }
};

export const deleteGovPlanHandler = (projectId, user) => async (dispatch) => {
  dispatch({ type: actionTypes.START_LOADING });

  const deletePlan = await deleteGovPlan(projectId, user);

  if (deletePlan.status === 200) {
    dispatch({ type: actionTypes.DELETE_PLAN_SUCCESS });
    dispatch({ type: actionTypes.STOP_LOADING });

    const plans = await getAllGovPlan();

    if (plans.status === 200) {
      dispatch({
        type: actionTypes.GET_ALL_GOVPLANS_SUCCESS,
        activePlans: plans.data.filter((data) => data.IsExpired === false),
        inActivePlans: plans.data.filter((data) => data.IsExpired === true),
      });

      dispatch({ type: actionTypes.STOP_LOADING });
    } else {
      dispatch({ type: actionTypes.GET_ALL_GOVPLANS_FAIL });
      dispatch({ type: actionTypes.STOP_LOADING });
    }
  } else {
    dispatch({ type: actionTypes.DELETE_PLAN_FAIL, error: deletePlan.data });
    dispatch({ type: actionTypes.STOP_LOADING });

    const plans = await getAllGovPlan();

    if (plans.status === 200) {
      dispatch({
        type: actionTypes.GET_ALL_GOVPLANS_SUCCESS,
        activePlans: plans.data.filter((data) => data.IsExpired === false),
        inActivePlans: plans.data.filter((data) => data.IsExpired === true),
      });

      dispatch({ type: actionTypes.STOP_LOADING });
    } else {
      dispatch({ type: actionTypes.GET_ALL_GOVPLANS_FAIL });
      dispatch({ type: actionTypes.STOP_LOADING });
    }
  }
};

export const getInduGroupHandler = () => async (dispatch) => {
  dispatch({ type: actionTypes.START_GET_INDUGROUPS });

  const groups = await getInduGroup();

  if (groups.status === 200) {
    dispatch({
      type: actionTypes.GET_INDUGROUPS_SUCCESS,
      induGroupList: groups.data,
    });
  } else {
    dispatch({
      type: actionTypes.GET_INDUGROUPS_FAIL,
      error: groups.data,
    });
  }
};

export const createInduGroupHandler = (data) => async (dispatch) => {
  dispatch({ type: actionTypes.START_CREATE_INDUGROUP });

  const createGroups = await createInduGroup(data);

  if (createGroups.status === 200) {
    const groups = await getInduGroup();

    if (groups.status === 200) {
      dispatch({
        type: actionTypes.GET_INDUGROUPS_SUCCESS,
        induGroupList: groups.data,
      });
    } else {
      dispatch({
        type: actionTypes.GET_INDUGROUPS_FAIL,
        error: groups.data,
      });
    }
  } else {
    dispatch({
      type: actionTypes.CREATE_INDUGROUP_FAIL,
      error: createGroups.data,
    });
  }
};

export const getCompanyList = (parkId) => async (dispatch) => {
  dispatch({ type: actionTypes.START_GET_COMPANYLIST_BY_PARKID });

  const lists = await getCompanyListByParkId(parkId);

  if (lists.status === 200) {
    dispatch({
      type: actionTypes.COMPANYLIST_BY_PARKID_SUCCESS,
      companyList: lists.data,
    });
  } else {
    dispatch({
      type: actionTypes.COMPANYLIST_BY_PARKID_FAIL,
      error: lists.data,
    });
  }
};

export const getDeliveryLogsHandler = () => async (dispatch) => {
  dispatch({ type: actionTypes.START_GET_DELIVERY_LOG });

  const logs = await getDeliveryLog();

  if (logs.status === 200) {
    dispatch({
      type: actionTypes.GET_DELIVERY_LOG_SUCCESS,
      deliveryLog: logs.data,
    });
  } else {
    dispatch({ type: actionTypes.GET_DELIVERY_LOG_FAIL, error: logs.error });
  }
};

export const convertingBusinessNo = (data) => async (dispatch) => {
  dispatch({ type: actionTypes.START_CONVERTING_BUSINESS_NO });

  const businessInfo = await listByBusinessId(data);

  if (businessInfo.status === 200) {
    dispatch({
      type: actionTypes.CONVERTING_BUSINESS_NO_SUCCESS,
      businessDisplay: businessInfo.data,
    });
  } else {
    dispatch({
      type: actionTypes.CONVERTING_BUSINESS_NO_FAIL,
      error: businessInfo.data,
    });
  }
};

export const deleteGovGroupHandler = (projectId) => async (dispatch) => {
  dispatch({ type: actionTypes.START_DELETE_GROUP });

  const deletion = await deleteInduGroup(projectId, "temp");

  if (deletion.status === 200) {
    const groups = await getInduGroup();

    if (groups.status === 200) {
      dispatch({
        type: actionTypes.GET_INDUGROUPS_SUCCESS,
        induGroupList: groups.data,
      });
    } else {
      dispatch({
        type: actionTypes.GET_INDUGROUPS_FAIL,
        error: groups.data,
      });
    }
  } else {
    dispatch({
      type: actionTypes.GET_INDUGROUPS_FAIL,
      error: deletion.data,
    });
  }
};

export const updateGovGroupHandler = (projectId, data) => async (dispatch) => {
  dispatch({ type: actionTypes.START_UPDATE_GROUP });

  const update = await updateInduGroup(projectId, data);

  if (update.status === 200) {
    const groups = await getInduGroup();

    if (groups.status === 200) {
      dispatch({
        type: actionTypes.GET_INDUGROUPS_SUCCESS,
        induGroupList: groups.data,
      });
    } else {
      dispatch({
        type: actionTypes.GET_INDUGROUPS_FAIL,
        error: groups.data,
      });
    }
  } else {
    dispatch({
      type: actionTypes.GET_INDUGROUPS_FAIL,
      error: update.data,
    });
  }
};

export const clearState = (stateName) => {
  return { type: actionTypes.CLEAR_STATE, stateName: stateName };
};
