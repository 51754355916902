import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled, { keyframes } from "styled-components";
import subscribeBg from "../../assests/images/subscribe-bg.png";
import dashboardIcon from "../../assets/images/dashboard.png";
import popularIcon from "../../assets/images/popular.png";
import windowTitle from "../../assets/images/windowTitle.png";
import { handleSmartWindowtData } from "../../store/actions/smartWindow";
import { thousandDivider } from "../../utility/common.js";

export default (props) => {
  const dispatch = useDispatch();
  const { manageParkStatOverview, manageParkStatOverviewTop, manageParkStatDataLoading } = useSelector((state) => state.smartWindow);

  React.useEffect(() => {
    dispatch(handleSmartWindowtData());
  }, [dispatch]);
  const url = window.location.origin;
  const openNewTabs = (link) => window.open(link, "_blank");

  return (
    <>
      <WindowContent>
        <WindowTitle>園區智慧櫥窗<img width={"50"} height={"50"} src={windowTitle} /></WindowTitle>
        <WindowWrapper>
          <WindowGrid>
            <GirdItem style={{
              display: "flex",
              justifyContent: "flex-end"
            }}>
              <CardContainer>
                <CardHeader>
                  <img width={"50"} height={"50"} src={dashboardIcon} />
                  <TitleText>營運概況</TitleText>
                </CardHeader>
                <CardBody id="ParkStatus">
                  <UlContainer>
                    {manageParkStatOverview.length != 0 &&
                      <>
                        <LiContainer>
                          <ItemContainer>
                            <SubTitle>轄管園區數</SubTitle>
                            <ItemContentContainer>
                              <ItemSummary>總數：80</ItemSummary>
                              <ItemContent>產業園區：67個(含5大園區)</ItemContent>
                              <ItemContent>科技產業園區：13個(含擴區)</ItemContent>
                            </ItemContentContainer>
                          </ItemContainer>
                        </LiContainer>
                        <LiContainer>
                          <ItemContainer>
                            <SubTitle>公司家數<SubTitleUnit>(單位：家)</SubTitleUnit></SubTitle>
                            <ItemContentContainer>
                              <ItemSummary>總數：{thousandDivider(manageParkStatOverview?.CompanyCnts?.Total)}</ItemSummary>
                              <ItemContent>產業園區：{thousandDivider(manageParkStatOverview?.CompanyCnts?.ParkValue[0].Value)}</ItemContent>
                              <ItemContent>科技產業園區：{thousandDivider(manageParkStatOverview?.CompanyCnts?.ParkValue[1].Value)}</ItemContent>
                            </ItemContentContainer>
                          </ItemContainer>
                        </LiContainer>
                        <LiContainer>
                          <ItemContainer>
                            <SubTitle>營業額<SubTitleUnit>(單位：億元)</SubTitleUnit></SubTitle>
                            <ItemContentContainer>
                              <ItemSummary>總數：{thousandDivider(manageParkStatOverview?.ParkVal?.Total.toFixed(2))}</ItemSummary>
                              <ItemContent>產業園區：{thousandDivider(manageParkStatOverview?.ParkVal?.ParkValue[0].Value.toFixed(2))}</ItemContent>
                              <ItemContent>科技產業園區：{thousandDivider(manageParkStatOverview?.ParkVal?.ParkValue[1].Value.toFixed(2))}</ItemContent>
                            </ItemContentContainer>
                          </ItemContainer>
                        </LiContainer>
                        <LiContainer>
                          <ItemContainer>
                            <SubTitle>員工數<SubTitleUnit>(單位：人)</SubTitleUnit></SubTitle>
                            <ItemContentContainer>
                              <ItemSummary>總數：{thousandDivider(manageParkStatOverview?.EmployeeCnts?.Total)}</ItemSummary>
                              <ItemContent>產業園區：{thousandDivider(manageParkStatOverview?.EmployeeCnts?.ParkValue[0].Value)}</ItemContent>
                              <ItemContent>科技產業園區：{thousandDivider(manageParkStatOverview?.EmployeeCnts?.ParkValue[1].Value)}</ItemContent>
                            </ItemContentContainer>
                          </ItemContainer>
                        </LiContainer>
                        <LiContainer>
                          <ItemContainer>
                            <SubTitle>開發面積<SubTitleUnit>(單位：公頃)</SubTitleUnit></SubTitle>
                            <ItemContentContainer>
                              <ItemSummary>總數：{thousandDivider(manageParkStatOverview?.Area?.Total.toFixed(2))}</ItemSummary>
                              <ItemContent>產業園區：{thousandDivider(manageParkStatOverview?.Area?.ParkValue[0].Value.toFixed(2))}</ItemContent>
                              <ItemContent>科技產業園區：{thousandDivider(manageParkStatOverview?.Area?.ParkValue[1].Value.toFixed(2))}</ItemContent>
                            </ItemContentContainer>
                          </ItemContainer>
                        </LiContainer>
                      </>
                    }
                  </UlContainer>
                </CardBody>
              </CardContainer>
            </GirdItem>
          </WindowGrid>
          <WindowGrid>
            <GirdItem>
              <CardContainer>
                <CardHeader>
                  <img width={"50"} height={"50"} src={popularIcon} />
                  <TitleText>轄區之最</TitleText>
                </CardHeader>
                <CardBody id="ParkMax">
                  <UlContainer>
                    {manageParkStatOverviewTop.length != 0 &&
                      <>
                        <LiContainer>
                          <ItemContainer>
                            <SubTitle>面積最大<SubTitleUnit>(單位：公頃)</SubTitleUnit></SubTitle>
                            <ItemContentContainer>
                              <ItemContentText
                                onClick={() =>
                                  openNewTabs(
                                    `${url}/park_dashboard/${manageParkStatOverviewTop.Area.IndustrialParkID}`
                                  )
                                }>
                                {manageParkStatOverviewTop.Area.ParkName}
                              </ItemContentText>
                              <ItemContent>{thousandDivider(manageParkStatOverviewTop.Area.Value.toFixed(2))}</ItemContent>
                            </ItemContentContainer>
                          </ItemContainer>
                        </LiContainer>
                        <LiContainer>
                          <ItemContainer>
                            <SubTitle>公司數最多<SubTitleUnit>(單位：家)</SubTitleUnit></SubTitle>
                            <ItemContentContainer>
                              <ItemContentText
                                onClick={() =>
                                  openNewTabs(
                                    `${url}/park_dashboard/${manageParkStatOverviewTop.Company.IndustrialParkID}`
                                  )
                                }>
                                {manageParkStatOverviewTop.Company.ParkName}
                              </ItemContentText>
                              <ItemContent>{thousandDivider(manageParkStatOverviewTop.Company.Value)}</ItemContent>
                            </ItemContentContainer>
                          </ItemContainer>
                        </LiContainer>
                        <LiContainer>
                          <ItemContainer>
                            <SubTitle>營業額最高<SubTitleUnit>(單位：億元)</SubTitleUnit></SubTitle>
                            <ItemContentContainer>
                              <ItemContentText
                                onClick={() =>
                                  openNewTabs(
                                    `${url}/park_dashboard/${manageParkStatOverviewTop.ParkVal.IndustrialParkID}`
                                  )
                                }>
                                {manageParkStatOverviewTop.ParkVal.ParkName}
                              </ItemContentText>
                              <ItemContent>{thousandDivider(manageParkStatOverviewTop.ParkVal.Value.toFixed(2))}</ItemContent>
                            </ItemContentContainer>
                          </ItemContainer>
                        </LiContainer>
                        <LiContainer>
                          <ItemContainer>
                            <SubTitle>員工數最多<SubTitleUnit>(單位：人)</SubTitleUnit></SubTitle>
                            <ItemContentContainer>
                              <ItemContentText
                                onClick={() =>
                                  openNewTabs(
                                    `${url}/park_dashboard/${manageParkStatOverviewTop.Employee.IndustrialParkID}`
                                  )
                                }>
                                {manageParkStatOverviewTop.Employee.ParkName}
                              </ItemContentText>
                              <ItemContent>{thousandDivider(manageParkStatOverviewTop.Employee.Value)}</ItemContent>
                            </ItemContentContainer>
                          </ItemContainer>
                        </LiContainer>
                        <LiContainer>
                          <ItemContainer>
                            <SubTitle>單位面積產值最高<SubTitleUnit>(單位：億元/公頃)</SubTitleUnit></SubTitle>
                            <ItemContentContainer>
                              <ItemContentText
                                onClick={() =>
                                  openNewTabs(
                                    `${url}/park_dashboard/${manageParkStatOverviewTop.PerAreaVal.IndustrialParkID}`
                                  )
                                }>
                                {manageParkStatOverviewTop.PerAreaVal.ParkName}
                              </ItemContentText>
                              <ItemContent>{thousandDivider(manageParkStatOverviewTop.PerAreaVal.Value.toFixed(2))}</ItemContent>
                            </ItemContentContainer>
                          </ItemContainer>
                        </LiContainer>
                        <LiContainer>
                          <ItemContainer>
                            <SubTitle>員工平均產值最高<SubTitleUnit>(單位：億元/人)</SubTitleUnit></SubTitle>
                            <ItemContentContainer>
                              <ItemContentText
                                onClick={() =>
                                  openNewTabs(
                                    `${url}/park_dashboard/${manageParkStatOverviewTop.PerEmployeeVal.IndustrialParkID}`
                                  )
                                }>
                                {manageParkStatOverviewTop.PerEmployeeVal.ParkName}
                              </ItemContentText>
                              <ItemContent>{thousandDivider(manageParkStatOverviewTop.PerEmployeeVal.Value.toFixed(2))}</ItemContent>
                            </ItemContentContainer>
                          </ItemContainer>
                        </LiContainer>
                      </>
                    }
                  </UlContainer>
                </CardBody>
              </CardContainer>
            </GirdItem>
          </WindowGrid>
        </WindowWrapper>
      </WindowContent>
    </>
  );
};
const WindowContent = styled.div`
height: 100%;
display: flex;
padding-top: 115px;
overflow-y: scroll;
align-items: center;
background-size: cover;
flex-direction: column;
justify-content: center;
background-position: center;
background-repeat: no-repeat;
background-image: url(${subscribeBg});
@media (max-width: 1023px) {
  height: 100vh;
  font-size: x-large;
  padding-top: 500px;
}
`;
const WindowTitle = styled.div`
  margin: auto;
  width: 60vw;
  background: #2c6070;
  font-size: xx-large;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  color: #FFF;
  font-weight: bolder;
  padding: 20px 0px;
  border-radius: 10px;
  @media (max-width: 1023px) {
    width: 80vw;
  }
`;

const WindowWrapper = styled.div`
  width: 100%;
  margin: auto;
  padding: 1.6rem 1.6rem 3.2rem;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  @media (max-width: 1023px) {
    display: flex;
    flex-direction: column;
  }
`;
const WindowGrid = styled.div`
  color: black;
  display: grid;
  margin: 10px;
  grid-column-gap: 1.6rem;
  grid-row-gap: 1.6rem;
  // position: relative;

`;
const GirdItem = styled.div`
  width: 40vw;
  // height: 75vh;
  @media (max-width: 1023px) {
    width: 100vw;
    display: flex;
    justify-content: center !important;
  }
`;
const CardContainer = styled.div`
  border-radius: 0.8rem;
  background: #ffffffd4;
  width: 75%;
  height: 75vh;
  ${(((window.outerWidth + 10) / window.innerWidth) * 100) <= 100 ? (
    "max-height: 75vh;"
  ) : (
    (((window.outerWidth + 10) / window.innerWidth) * 100) <= 150 ? (
      "max-height: 65vh;"
    ) : (
      "max-height: 60vh;"
    )
  )} //計算使用者放大倍率
  ${(((window.outerWidth + 10) / window.innerWidth) * 100) <= 100 ? (
    "min-height: 75vh;"
  ) : (
    (((window.outerWidth + 10) / window.innerWidth) * 100) <= 150 ? (
      "min-height: 65vh;"
    ) : (
      "min-height: 60vh;"
    )
  )} //計算使用者放大倍率
  margin-bottom: 1.6rem;
  box-shadow: 0px 0px 0px 1px rgba(255, 255, 255, 0.12);
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
`;
const CardHeader = styled.div`
  padding: 1.4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 0;
`;
const TitleText = styled.div`
  padding-left: 15px;
  font-size: 30px;
  font-weight: bolder;
  color: #337082;
`;
const CardBody = styled.div`
  padding: 1.4rem;
  width: 90%;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    // display: none; /* for Chrome, Safari, and Opera */
    width: 8px; /*右側捲軸寬度*/
  }
  ::-webkit-scrollbar-thumb {
    background: rgb(158 204 199 / 36%); 
  }
`;
const UlContainer = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;
`;
const LiContainer = styled.li`
`;
const ItemContainer = styled.div`
  display: grid;
  grid-template-columns: 0.9fr 1.1fr;
  grid-column-gap: 1.4rem;
  padding-bottom: 15px;
`;
const SubTitle = styled.div`
  font-size: 20px;
  font-weight: bolder;
  color: #337082;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
`;
const SubTitleUnit = styled.div`
  font-size: 15px;
  color: #000;

`;
const ItemContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: nowrap;
`;
const ItemContent = styled.div`
  margin-top: 0.8rem;
  font-size: 20px;
`;
const ItemContentText = styled.a`
  color: #D16F8D;
  font-weight: bolder;
  text-decoration: none;
  transition: all 120ms ease-out 0s;
  display: inline-block;
  border-radius: 0.4rem;
  font-size: x-large;
  :hover {
    background: rgba(209,111,141,0.52) !important;
    color: #000 !important;
    box-shadow: 0px 0px 0px 0.4rem rgba(209,111,141,0.52) !important;
  }
`;
const ItemSummary = styled.div`
  color: #D16F8D;
  font-size: 22px;
  margin-top: 0.8rem;
  font-weight: bolder;
`;