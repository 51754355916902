import React, { Fragment } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import IconButton from "@material-ui/core/IconButton";
import Link from "@material-ui/core/Link";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Icon from "@material-ui/core/Icon";
import { useSelector } from "react-redux";

import { thousandDivider, stopTypeSwitcher } from "../../utility/common";
import { parksWithUrl } from "../../constants/parks";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Swal from "sweetalert2";
import Tooltip from "@mui/material/Tooltip";

import "./styles.scss";

const FactoryBasicInfoTable = (props) => {
  const { data, UpdateCompany, loading, getCompanyAbstractData, EnterpriseQualifiedData, trademarker } = props;
  const { innerWidth } = useSelector((state) => state.app);
  const [state, setState] = React.useState({
    endIndex: 0,
    page: 0,
    rowsPerPage: 5,
    styleKey: {},
    styleValue: {},
  });
  const tooltipTheme = createTheme({
    components: {
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            color: "#0000",
            backgroundColor: "#0000",
            border: "5px solid #000",
            padding: "0px",
            margin: "0px",
            fontSize: "0px",
          }
        }
      }
    }
  });
  const [enterpriseQualifiedList, setEnterpriseQualifiedList] = React.useState([]);
  const url = window.location.origin;
  const BusinessId = window.location.href.split("/")[window.location.href.split("/").length-1];

  const openNewTabs = (link, method) => window.open(link, method ? method : "_blank");

  const findParksUrl = (industry) => {
    const industryArray = industry.split(",");
    let updatedIndustryArray = [];
    industryArray.forEach((n) => {
      const filtered = parksWithUrl.filter((p) => p.Name=== n.replaceAll("工業區","產業園區") );
      updatedIndustryArray.push({
        link: filtered.length > 0 ? filtered[0].ParkId : "",
        name: n,
      });
    });
    return updatedIndustryArray;
  };

  // const styleKey = innerWidth < 1024 ? {width:"40%", fontWeight: 600} : {width:"30%", fontWeight: 600};
  // const styleValue = innerWidth < 1024 ? {width:"60%", borderBottom: "none"} : {display: "flex"};

  React.useEffect(() => {
    if (innerWidth < 1024) {
      setState((state) => ({
        ...state,
        styleKey: { width: "40%", fontWeight: 600 },
        styleValue: { width: "60%", borderBottom: "none" },
      }));
    } else {
      setState((state) => ({
        ...state,
        styleKey: { width: "30%", fontWeight: 600 },
        styleValue: { display: "flex" },
      }));
    }
  }, [innerWidth]);

  const toggleCard = {};
  const openMarkerInfoAlert = (imgUrl, imgName) => {
    Swal.fire({
      title: imgName,
      imageUrl: imgUrl,
      imageAlt: imgName
    });
  };

  React.useEffect(() => {
    const groupByEnterpriseQualifiedData = EnterpriseQualifiedData.reduce((group, row) => {
      const { Title } = row;
      group[Title] = group[Title] ?? [];
      group[Title].push(row);
      return group;
    }, {});
    const tempEnterpriseQualifiedList = [];
    for(let i=0;i<Object.keys(groupByEnterpriseQualifiedData).length;i++){
      tempEnterpriseQualifiedList.push(Object.keys(groupByEnterpriseQualifiedData)[i]);
    }
    setEnterpriseQualifiedList(tempEnterpriseQualifiedList);
  }, [EnterpriseQualifiedData]);

  if (data.Addr && getCompanyAbstractData) {
    return (
      <TableContainer className="table-container">
        <Table className="table" style={{ tableLayout: "fixed" }}>
          <TableBody className="table-body">
            {!loading && (
              <React.Fragment>
                <TableRow hover className="table-body-row">
                  <TableCell align="left" style={state.styleKey}>
                    <p>公司統編</p>
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{ ...state.styleValue, borderTop: "none" }}
                  >
                    <p>
                      {data["BusinessNo"] ? data["BusinessNo"] : "無相關資料"}
                    </p>
                  </TableCell>
                </TableRow>
                <TableRow hover className="table-body-row">
                  <TableCell align="left" style={state.styleKey}>
                    總部地址
                  </TableCell>
                  <TableCell align="left" style={state.styleValue}>
                    <p>
                      {data.Addr ? data.Addr : "無相關資料"}
                      <IconButton
                        size="small"
                        title={`另開新視窗，前往 Google 地圖`}
                        onClick={() => {
                          openNewTabs(
                            `https://maps.google.com/?q=${data.Addr}`,
                            "_blank"
                          );
                        }}
                      >
                        <Icon>directions</Icon>
                      </IconButton>
                    </p>
                  </TableCell>
                </TableRow>
                <TableRow hover className="table-body-row">
                  <TableCell align="left" style={state.styleKey}>
                    <p>英文公司名稱</p>
                  </TableCell>
                  <TableCell align="left" style={state.styleValue}>
                    <p>
                      {data["CompanyName_En"]
                        ? data["CompanyName_En"]
                        : "無相關資料"}
                    </p>
                  </TableCell>
                </TableRow>
                <TableRow hover className="table-body-row">
                  <TableCell align="left" style={state.styleKey}>
                    <p>總部電話</p>
                  </TableCell>
                  <TableCell align="left" style={state.styleValue}>
                    <p>{data.Phone ? data.Phone : "無相關資料"}</p>
                    {UpdateCompany &&
                      innerWidth > 1024 &&
                      data.Phone === "無相關資料" &&
                      (UpdateCompany.Name === "" ? (
                        <a
                          style={{ color: "#006EB6" }}
                          href={url + "/updated_company"}
                        >
                          (自訂廠商資料)
                        </a>
                      ) : (
                        ""
                      ))}
                  </TableCell>
                </TableRow>
                <TableRow hover className="table-body-row">
                  <TableCell align="left" style={state.styleKey}>
                    <p>傳真</p>
                  </TableCell>
                  <TableCell align="left" style={state.styleValue}>
                    <p>{data.Fax ? data.Fax : "無相關資料"}</p>
                    {UpdateCompany &&
                      innerWidth > 1024 &&
                      data.Fax === "無相關資料" &&
                      (UpdateCompany.Name === "" ? (
                        <a
                          style={{ color: "#006EB6" }}
                          href={url + "/updated_company"}
                        >
                          (自訂廠商資料)
                        </a>
                      ) : (
                        ""
                      ))}
                  </TableCell>
                </TableRow>
                <TableRow hover className="table-body-row">
                  <TableCell align="left" style={state.styleKey}>
                    <p>登記機關</p>
                  </TableCell>
                  <TableCell align="left" style={state.styleValue}>
                    <p>
                      {data["Registration_authority"]
                        ? data["Registration_authority"]
                        : "無相關資料"}
                    </p>
                  </TableCell>
                </TableRow>
                <TableRow hover className="table-body-row">
                  <TableCell align="left" style={state.styleKey}>
                    <p>設廠園區</p>
                  </TableCell>
                  <TableCell align="left" style={state.styleValue}>
                    <p>
                      {data.IndustryArea
                        ? findParksUrl(data.IndustryArea).map((item, index) => {
                            const length = findParksUrl(
                              data.IndustryArea
                            ).length;
                            if (item.link !== "") {
                              return (
                                <Fragment>
                                  <Link
                                    style={{ color: "rgb(0, 106, 180)" }}
                                    underline="always"
                                    target="_blank"
                                    title="另開新視窗，前往園區儀表板"
                                    href={`/park_dashboard/${item.link}`}
                                  >
                                    {item.name}
                                  </Link>{" "}
                                  <Typography component="span">
                                    {index !== length - 1 ? "、" : ""}{" "}
                                  </Typography>
                                </Fragment>
                              );
                            } else {
                              return (
                                <Typography component="span">
                                  {item.name} {index !== length - 1 ? "、" : ""}
                                </Typography>
                              );
                            }
                          })
                        : "無相關資料"}
                    </p>
                  </TableCell>
                </TableRow>
                <TableRow hover className="table-body-row">
                  <TableCell align="left" style={state.styleKey}>
                    <p>設立時間</p>
                  </TableCell>
                  <TableCell align="left" style={state.styleValue}>
                    <p>{data.SetupDate ? data.SetupDate : "無相關資料"}</p>
                  </TableCell>
                </TableRow>
                <TableRow hover className="table-body-row">
                  <TableCell align="left" style={state.styleKey}>
                    <p>登記資本額</p>
                  </TableCell>
                  <TableCell align="left" style={state.styleValue}>
                    {data.CapitalTotal !== 0 ? (
                      <p>
                        {thousandDivider(data.CapitalTotal)}
                        {getCompanyAbstractData?.[1] && (
                          <p style={{ fontSize: "14px" }}>
                            ({getCompanyAbstractData?.[1]?.Index}：
                            {thousandDivider(getCompanyAbstractData?.[1]?.Rank)}
                            )
                          </p>
                        )}
                      </p>
                    ) : (
                      <p>無相關資料</p>
                    )}
                  </TableCell>
                </TableRow>
                <TableRow hover className="table-body-row">
                  <TableCell align="left" style={state.styleKey}>
                    <p>實收資本額</p>
                  </TableCell>
                  <TableCell align="left" style={state.styleValue}>
                    <p>
                      {data.CapitalReality !== 0
                        ? thousandDivider(data.CapitalReality)
                        : "無相關資料"}
                    </p>
                  </TableCell>
                </TableRow>
                <TableRow hover className="table-body-row">
                  <TableCell align="left" style={state.styleKey}>
                    <p>員工人數</p>
                  </TableCell>
                  <TableCell align="left" style={state.styleValue}>
                    <p>
                      {data.EmpCount !== 0
                        ? thousandDivider(data.EmpCount)
                        : "無相關資料"}
                      {UpdateCompany &&
                        data.EmpCount === 0 &&
                        innerWidth > 1024 &&
                        (UpdateCompany.Name === "" ? (
                          <a
                            style={{ color: "#006EB6" }}
                            href={url + "/updated_company"}
                          >
                            (自訂廠商資料)
                          </a>
                        ) : (
                          ""
                        ))}
                    </p>
                  </TableCell>
                </TableRow>
                <TableRow hover className="table-body-row">
                  <TableCell align="left" style={state.styleKey}>
                    <p>負責人</p>
                  </TableCell>
                  <TableCell align="left" style={state.styleValue}>
                    <p>{data.Principal ? data.Principal : "無相關資料"}</p>
                  </TableCell>
                </TableRow>
                <TableRow hover className="table-body-row">
                  <TableCell align="left" style={state.styleKey}>
                    <p>股票發行狀態</p>
                  </TableCell>
                  <TableCell align="left" style={state.styleValue}>
                    <p>{stopTypeSwitcher(data.StockType)}</p>
                  </TableCell>
                </TableRow>
                <TableRow hover className="table-body-row">
                  <TableCell align="left" style={state.styleKey}>
                    <p>設立狀態</p>
                  </TableCell>
                  <TableCell align="left" style={state.styleValue}>
                    <p>{data.Status ? data.Status : "無相關資料"}</p>
                  </TableCell>
                </TableRow>
                <TableRow hover className="table-body-row">
                  <TableCell align="left" style={state.styleKey}>
                    <p>上次更新時間</p>
                  </TableCell>
                  <TableCell align="left" style={state.styleValue}>
                    <p>
                      {data.LastUpdateDate !== "1900/01/01"
                        ? data.LastUpdateDate
                        : "無相關資料"}
                    </p>
                  </TableCell>
                </TableRow>
                <TableRow hover className="table-body-row">
                  <TableCell align="left" style={state.styleKey}>
                    <p>公司網站</p>
                  </TableCell>
                  <TableCell
                    align="left"
                    onClick={
                      data.WebSite === "無相關資料"
                        ? () => {
                            openNewTabs(
                              `https://www.google.com/search?q=${data.CompanyName.substring(
                                0,
                                4
                              )}`
                            );
                          }
                        : () => {
                            openNewTabs(data.WebSite);
                          }
                    }
                    style={{
                      ...state.styleValue,
                      textDecoration: "underline",
                      color: "rgb(0, 106, 180)",
                      cursor: "pointer",
                    }}
                  >
         
                    {data.WebSite === "無相關資料"
                    ? <a href={`https://www.google.com/search?q=${data.CompanyName.substring(
                      0,
                      4
                    )}`} target="_blank" rel="noopener noreferrer" title="另開新視窗">查看Google搜尋結果</a>
                    : <button style={{
                      color: "rgb(0, 106, 180)",
                    }} title="另開新視窗" onClick={
                      data.WebSite === "無相關資料"
                        ? (e) => {
                            e.stopPropagation();
                            openNewTabs(
                              `https://www.google.com/search?q=${data.CompanyName.substring(
                                0,
                                4
                              )}`
                            );
                          }
                        : (e) => {
                          e.stopPropagation();
                            openNewTabs(data.WebSite);
                          }
                    }>{data?.CompanyName || ""}網站連結</button>}
                  </TableCell>
                </TableRow>
                {getCompanyAbstractData && (
                  <TableRow hover className="table-body-row">
                    <TableCell align="left" style={state.styleKey}>
                      <p>是否為陸資</p>
                    </TableCell>
                    <TableCell align="left" style={state.styleValue}>
                      <p>{getCompanyAbstractData.ChinaInvest ? "是" : "否"}</p>
                    </TableCell>
                  </TableRow>
                )}
                {(enterpriseQualifiedList.length != 0) && (
                  <TableRow hover className="table-body-row">
                    <TableCell align="left" style={state.styleKey}>
                      <p>產發署技術服務機構服務能量類別</p>
                    </TableCell>
                    <TableCell align="left" 
                      style={{
                        ...state.styleValue,
                        textDecoration: "underline",
                        color: "rgb(0, 106, 180)",
                        cursor: "pointer",
                      }}
                      onClick={()=>openNewTabs(url+"/factory_info/award/"+BusinessId, "_self")}
                    >
                      {enterpriseQualifiedList && enterpriseQualifiedList.map((row, index) => (
                        <>
                          <a>
                            {row}
                          </a>
                          {index+1 == enterpriseQualifiedList.length ? "" : "、"}
                        </>
                      ))}
                    </TableCell>
                  </TableRow>
                )}
                {trademarker.length>0 &&
                  <TableRow hover className="table-body-row">
                    <TableCell align="left" style={state.styleKey}>
                      <p>註冊商標</p>
                      <p style={{fontSize: "12px"}}>
                        {innerWidth > 1024 ? "文字提醒：游標移至商標名稱上可預覽商標圖案" : "文字提醒：請點擊商標名稱預覽商標圖案"}
                      </p>
                    </TableCell>
                    <TableCell align="left">
                      <p>
                        {trademarker && trademarker.map((row, index) => (
                          <ThemeProvider theme={tooltipTheme}>
                            <Tooltip
                              placement="top-start"
                              id={`tooltip${index}`}
                              title={
                                <img 
                                  src={`https://opendataservice.smartpark.com.tw/Trademark_icon/${row.FilePath}`} 
                                  height={250} 
                                  width={250}
                                />
                              }
                            >
                              <a onClick={() => openMarkerInfoAlert(`https://opendataservice.smartpark.com.tw/Trademark_icon/${row.FilePath}`, row.Trademark_Name)}>
                                {row.Trademark_Name}
                              </a>
                            </Tooltip>
                            {index+1 == trademarker.length ? "" : "、"}
                          </ThemeProvider>
                        ))}
                      </p>
                    </TableCell>
                  </TableRow>
                }
              </React.Fragment>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    );
  } else {
    return <div />;
  }
};


export default React.memo(FactoryBasicInfoTable);
