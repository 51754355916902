const base = `CUSTOM_DASHBOARD_`;

export const START_GET_DASHBOARD_LIST= `${base}START_GET_DASHBOARD_LIST`;
export const GET_DASHBOARD_LIST_SUCCESS = `${base}GET_DASHBOARD_LIST_SUCCESS`;
export const GET_DASHBOARD_LIST_FAIL = `${base}GET_DASHBOARD_LIST_FAIL`;

export const START_CREATE_DASHBOARD_LIST= `${base}START_CREATE_DASHBOARD_LIST`;
export const CREATE_DASHBOARD_LIST_SUCCESS = `${base}CREATE_DASHBOARD_LIST_SUCCESS`;
export const CREATE_DASHBOARD_LIST_FAIL = `${base}CREATE_DASHBOARD_LIST_FAIL`;

export const START_DELETE_DASHBOARD_LIST= `${base}START_DELETE_DASHBOARD_LIST`;
export const DELETE_DASHBOARD_LIST_SUCCESS = `${base}DELETE_DASHBOARD_LIST_SUCCESS`;
export const DELETE_DASHBOARD_LIST_FAIL = `${base}CREATE_DASHBOARD_LIST_FAIL`;

export const STAR_GET_DASHBOARD_NUMBER_LIST= `${base}STAR_GET_DASHBOARD_NUMBER_LIST`;
export const GET_NUMBER_LIST_SUCCESS = `${base}GET_NUMBER_LIST_SUCCESS`;
export const GET_NUMBER_LIST_FAIL = `${base}GET_NUMBER_LIST_FAIL`;


export const START_UPDATE_CUSTOM_BOARD_NAME= `${base}STAR_UPDATE_CUSTOM_BOARD_NAME_LIST`;
export const UPDATE_CUSTOM_BOARD_NAME_SUCCESS = `${base}UPDATE_CUSTOM_BOARD_NAME_SUCCESS`;
export const UPDATE_CUSTOM_BOARD_NAME_FAIL = `${base}UPDATE_CUSTOM_BOARD_NAME_FAIL`;

export const STAR_ADD_DASHBOARD_NUMBER_LIST= `${base}STAR_ADD_DASHBOARD_NUMBER_LIST`;
export const ADD_NUMBER_LIST_SUCCESS = `${base}ADD_NUMBER_LIST_SUCCESS`;
export const ADD_NUMBER_LIST_FAIL = `${base}ADD_NUMBER_LIST_FAIL`;

export const STAR_DELETE_DASHBOARD_NUMBER_LIST= `${base}STAR_DELETE_DASHBOARD_NUMBER_LIST`;
export const DELETE_NUMBER_LIST_SUCCESS = `${base}DELETE_NUMBER_LIST_SUCCESS`;
export const DELETE_NUMBER_LIST_FAIL = `${base}DELETE_NUMBER_LIST_FAIL`;

export const STAR_UPDATE_DASHBOARD_NUMBER_LIST= `${base}STAR_UPDATE_DASHBOARD_NUMBER_LIST`;
export const UPDATE_NUMBER_LIST_SUCCESS = `${base}UPDATE_NUMBER_LIST_SUCCESS`;
export const UPDATE_NUMBER_LIST_FAIL = `${base}UPDATE_NUMBER_LIST_FAIL`;

export const STAR_GET_NO_BY_COMPANY_NAME= `${base}STAR_GET_NO_BY_COMPANY_NAME`;
export const GET_NO_BY_COMPANY_NAME_SUCCESS = `${base}GET_NO_BY_COMPANY_NAME_SUCCESS`;
export const GET_NO_BY_COMPANY_NAME_FAIL = `${base}GET_NO_BY_COMPANY_NAME_FAIL`;

export const START_CHECK_DASHBOARD_NUMBER_LIST= `${base}START_CHECK_DASHBOARD_NUMBER_LIST`;
export const CHECK_NUMBER_LIST_SUCCESS = `${base}CHECK_NUMBER_LIST_SUCCESS`;
export const CHECK_NUMBER_LIST_FAIL = `${base}CHECK_NUMBER_LIST_FAIL`;

export const HANDLE_CLEAR_CUSTOM_DASHBOARD_BUSINESSNUMBER_LIST = `${base}HANDLE_CLEAR_CUSTOM_DASHBOARD_BUSINESSNUMBER_LIST`

export const UPDATE_BUSINESS_NUMBER_BY_PARKINFO = `${base}UPDATE_BUSINESS_NUMBER_BY_PARKINFO`;

export const UPDATE_BUSINESS_NUMBER_BY_COMPANY_SEARCH = `${base}UPDATE_BUSINESS_NUMBER_BY_COMPANY_SEARCH`;

export const CLEAR_BUSINESS_NUMBER_BY_PARKINFO = `${base}CLEAR_BUSINESS_NUMBER_BY_PARKINFO`;

export const CLEAR_BUSINESS_NUMBER_BY_COMPANY_SEARCH = `${base}CLEAR_BUSINESS_NUMBER_BY_COMPANY_SEARCH`;

export const CLEAR_ERROR_MESSAGE = `${base}CLEAR_ERROR_MESSAGE`;

