import React from "react";
import styled from "styled-components";
import { Map, TileLayer } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-markercluster";

import Spinner from "../../components/Spinner/Spinner";
import PopUp from "./CustomPopUp";

//tab切換成地圖顯示
const MapView = ({
    is_show_key,
    effected_events_list_loading,
    mapRef,
    marker_list,
    bond,
  }) => {
    return (
      <IsShow is_show_key={is_show_key} cmp_key={0}>
        <MapContainers>
          {effected_events_list_loading ? (
            <Loading />
          ) : (
            <Maps
              ref={mapRef}
              bounds={
                marker_list.length === 0
                  ? [
                      [25.268576, 121.611722],
                      [23.879299, 120.294881],
                      [23.762836, 121.54409],
                      [21.257621, 120.740482],
                      [21.8998, 120.837252],
                    ]
                  : bond
              }
              zoomControl={false}
              animate={true}
              zoom={8}
            >
              <TileLayer
                attribution='&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              <MarkerClusterGroup>
                {marker_list.map((i) => (
                  <PopUp
                    latitude={i.latitude}
                    longitude={i.longitude}
                    company_name={i.company_name}
                    owner={i.owner}
                    address={i.address}
                    business_no={i.business_no}
                    indu_four_str={i.indu_four_str}
                    product={i.product}
                    stock_type={i.stock_type}
                  />
                ))}
              </MarkerClusterGroup>
            </Maps>
          )}
        </MapContainers>
      </IsShow>
    );
  };
  
  const IsShow = ({ is_show_key, cmp_key, children }) => {
    return <IsShowView is_show={is_show_key === cmp_key}>{children}</IsShowView>;
  };

  const Loading = () => (
    <LoadingWrapper>
      <Spinner />
    </LoadingWrapper>
  );
  
  
  const IsShowView = styled.div`
  display: ${(p) => (p.is_show ? "flex" : "none")};
  flex-direction: column;
  transition: display 0.2s ease-in-out;
  width: 100%;
`;

const MapContainers = styled.div`
  padding: 10px 30px 30px 10px;
  overflow: hidden;
  height: 85vh;

  @media (max-width: 750px) {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: calc(100vh - 70px);
    padding: 0px;
    z-index: 0;
  }
`;

const Maps = styled(Map)`
  width: 100%;
  height: 100%;
  border-radius: 40px;
  /* box-shadow: 0px 0px 20px -5px rgba(0, 0, 0, 0.2); */
  @media (max-width: 750px) {
    width: 100vw;
    height: calc(100vh - 70px);
    padding: 0px;
    border-radius: 0px;
  }
`;

const LoadingWrapper = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 40px;
  @media (max-width: 750px) {
    border-radius: 0px;
  }
`;

export default React.memo(MapView)