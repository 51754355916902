import styled from "styled-components";

export const ModalContainer = styled.div`
  width: 90%;
  margin-top: 10px;
  margin-left: 5%;
  background-color: #f7f3f3;
  border-radius: 5px;
`;

export const ModalContainerHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  .material-icons {
    margin: 10px;
    cursor: pointer;
  }
`;
