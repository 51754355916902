import React from "react";
import styled from "styled-components";
import Select from '@mui/material/Select';
import MenuItem from "@mui/material/MenuItem";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import * as theme from "../../assets/theme"

const UseCurrentLocation = ({ is_use_location, range, handle_check_is_use_location, handle_select_range }) => {
    
    
    return (
        <UseCurrentLocationContainer>
            <FormControlLabel 
                control={
                    <Checkbox 
                        checked={is_use_location} 
                        onClick={handle_check_is_use_location}
                        sx={{
                            '&.Mui-checked': {
                            color: theme.primaryColor,
                            },
                        }}
                        onKeyDown={(e) => {
                            if (e.keyCode === 13) {
                                e.preventDefault();
                                handle_check_is_use_location(e)
                            }
                        }}
                    />
                } 
                label="以你所在位置範圍"

            />
            <StyledSelect value={range} onChange={handle_select_range} disabled={!is_use_location}>
                <MenuItem value={1}>1公里內</MenuItem>
                <MenuItem value={5}>5公里內</MenuItem>
                <MenuItem value={10}>10公里內</MenuItem>
            </StyledSelect>
        </UseCurrentLocationContainer>
    )
}

const UseCurrentLocationContainer = styled.div`
    width: 96%;
    margin: 0px 2%;
    padding-bottom: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    @media(max-width: 750px) {
        width: 100%;
        margin: 0px;
        margin-left: -4px;
    } 
`;

const StyledSelect = styled(Select)`
    color: red;
    border: none !important;
    background-color: #ececec;
    max-height: 40px;
    border-radius: 10px !important;
    >div{
        background-color: rgba(0, 0, 0, 0) !important
    };

    .MuiOutlinedInput-notchedOutline{
        border-color: rgba(0, 0, 0, 0) !important
    }
`;

export default UseCurrentLocation;