import React from 'react'
import {
  GeoJSON
} from "react-leaflet";
import IndustrialAreaJSON from "../../constants/industrialArea.json";

export default (props) => {    
    return(
        <GeoJSON
        data={IndustrialAreaJSON.features}
        onEachFeature={(feature, layer) => {
          layer.bindTooltip((layer) => {
            return "<b style='font-size: 1rem;'>"+layer.feature.properties["名稱"]+"</b>";
          });
        }} />
    )
}