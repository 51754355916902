import React, { useState, useEffect, useReducer } from "react";
import {
  FormControl,
  InputLabel,
  Select,
  Input,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Icon,
  Collapse,
} from "@material-ui/core";
import { Redirect, useHistory, useRouteMatch } from "react-router-dom";
import { useSelector } from "react-redux";
import { parksWithOutExportZone as parks  } from "../../constants/parks";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Helmet } from "react-helmet";

import Spinner from "../../components/Spinner/Spinner";
import CustomizedCard from "../../components/CustomizedCard/CustomizedCard";
import CustomizedTable from "../../components/CustomizedTable/CustomizedTable";
import CustomizedModal from "../../components/CustomizedModal/CustomizedModal";
import CustomizedAutoComplete from "../../components/CustomizedAutoComplete/CustomizedAutoComplete";
import ForwardProjectInput from "../../components/ForwardProjectModal/ForwardProjectModal";

import {
  AuthQueryHandler,
  ClientRequestHandler,
  NoAuthQueryHandler,
  GetProjectHandleStatus,
  UpdateProjectHandleStatus,
  CaseClosedHandleStatus,
  getHandleHistory,
} from "../../utility/api";

import "./styles.scss";

const initState = {
  status: localStorage.getItem("isLogin") ? "query" : "request",
  isAuthQueryModalOpen: false,
  isFormModalOpen: false,
  isAuthTableLoading: false,
  isAuthQueryLoading: false,
  isClientRequestLoading: false,
  authTableContent: [],
  authRawData: "",
  authTableContentError: "",
  isNoAuthTableLoading: false,
  NoAuthContent: [],
  noAuthQueryLoading: false,
  updateHandleContent: "",
  updateEndCaseDesc: "",
  editedProject: {
    handleContent: "",
    closedCaseContent: "",
  },
  authQueryParams: {
    ParkIds: "all",
    Keyword: "",
    HandleType: "all",
    startDate: "",
    endDate: "",
  },
  noAuthQueryParams: {
    ShowCaseId: "",
    Email: "",
  },
  clientRequestParams: {
    CompanyName: "",
    BusinessNo: "",
    DisplayName: "",
    Sex: 2,
    ParkId: "",
    Phone: "",
    Email: "",
    ConfirmedEmail: "",
    IsTech: false,
    IsProduct: false,
    IsTalent: false,
    IsMoney: false,
    IsLand: false,
    IsEnergy: false,
    isOther: false,
    IsOther: "",
    Content: "",
  },
};

const reducer = (state, action) => {
  switch (action.type) {
    case "TOGGLE_STATUS":
      return {
        ...state,
        status: action.status,
      };
    case "TOGGLE_STATUS_SELECTION":
      return {
        ...state,
        status: action.value,
      };
    case "AUTH_SEARCH_HANDLER":
      const updateAuthQueryParams = { ...state.authQueryParams };
      const name = action.name;
      const value = action.value;

      updateAuthQueryParams[name] = value;

      return {
        ...state,
        authQueryParams: updateAuthQueryParams,
      };
    case "NO_AUTH_SEARCH_HANDLER":
      const updateNoAuthQueryParams = { ...state.noAuthQueryParams };
      const noAuthName = action.name;
      const noAuthValue = action.value;

      updateNoAuthQueryParams[noAuthName] = noAuthValue;

      return {
        ...state,
        noAuthQueryParams: updateNoAuthQueryParams,
      };
    case "CLIENT_REQUEST_HANDLER":
      const updatedClientRequestParams = { ...state.clientRequestParams };
      const requestName = action.name;
      const requestValue = action.value;
      let isNumberOny = new RegExp("[0-9]");

      if (requestName === "BusinessNo") {
        if (!isNumberOny.test(requestValue)) {
          MySwal.fire({
            text: "統一編號只能輸入數字！",
          });
          updatedClientRequestParams[requestName] = "";
          return {
            ...state,
            clientRequestParams: updatedClientRequestParams,
          };
        } else {
          // if (typeof (requestValue) === 'string' && requestValue.length === 8) {
          //   console.log("Im Calling!!")
          //   getFacNo(requestValue)
          //     .then(res => {
          //       if (res !== 'error') {
          //         console.log(res[0])

          //         updatedClientRequestParams["CompanyName"] = res[0].Name
          //         updatedClientRequestParams["ParkId"] = res[0].ParkId

          //         return{
          //           ...state,
          //           clientRequestParams: {
          //             ...updatedClientRequestParams,
          //             ...state.clientRequestParams
          //           }
          //         }

          //       } else {
          //         return
          //         // setOption(['網路連線問題'])
          //       }

          //     })
          // }

          if (action.inputType === "check-box") {
            updatedClientRequestParams[requestName] = requestValue;
          } else if (action.inputType === "gender-check-box") {
            if (updatedClientRequestParams.Sex === action.value) {
              updatedClientRequestParams.Sex = 2;
            } else {
              updatedClientRequestParams.Sex = action.value;
            }
          } else {
            updatedClientRequestParams[requestName] = requestValue;
          }

          return {
            ...state,
            clientRequestParams: updatedClientRequestParams,
          };
        }
      } else {
        if (action.inputType === "check-box") {
          updatedClientRequestParams[requestName] = requestValue;
        } else if (action.inputType === "gender-check-box") {
          if (updatedClientRequestParams.Sex === action.value) {
            updatedClientRequestParams.Sex = 2;
          } else {
            updatedClientRequestParams.Sex = action.value;
          }
        } else {
          updatedClientRequestParams[requestName] = requestValue;
        }

        return {
          ...state,
          clientRequestParams: updatedClientRequestParams,
        };
      }

    case "INIT_CLIENT_REQUEST_PARAMS":
      return {
        ...state,
        clientRequestParams: {
          CompanyName: null,
          BusinessNo: null,
          DisplayName: null,
          Sex: 2,
          ParkId: null,
          Phone: null,
          Email: null,
          ConfirmedEmail: null,
          IsTech: false,
          IsProduct: false,
          IsTalent: false,
          IsMoney: false,
          IsLand: false,
          IsEnergy: false,
          isOther: false,
          IsOther: null,
          Content: null,
        },
      };
    case "TOGGLE_AUTH_QUERY_MODAL":
      return {
        ...state,
        isAuthQueryModalOpen: !state.isAuthQueryModalOpen,
      };
    case "TOGGLE_FORM_MODAL":
      return {
        ...state,
        isFormModalOpen: !state.isFormModalOpen,
      };
    case "NO_AUTH":
      return {
        ...state,
        isAuth: false,
      };
    case "AUTH":
      return {
        ...state,
        isAuth: true,
      };
    case "START_AUTH_TABLE":
      return {
        ...state,
        isAuthTableLoading: true,
      };
    case "GET_AUTH_TABLE_SUCCESS":
      return {
        ...state,
        isAuthTableLoading: false,
        authTableContent: action.data,
        authRawData: action.raw,
      };
    case "GET_AUTH_TABLE_FAIL":
      return {
        ...state,
        isAuthTableLoading: false,
        authTableContentError: action.err,
      };
    case "UPDATE_EDITED_PROJECT":
      const updateProject = {
        ...state.editedProject,
        BusinessNo: action.data.Contact.BusinessNo,
        CompanyName: action.data.Contact.CompanyName,
        ParkId: action.data.Contact.ParkId,
        Email: action.data.Contact.Email,
        DisplayName: action.data.Contact.DisplayName,
        jobTitle: "",
        Phone: action.data.Contact.Phone,
        Sex: action.data.Contact.Sex,
        Content: action.data.Case.Content,
        IsTech: action.data.Case.IsTech,
        IsProduct: action.data.Case.IsProduct,
        IsTalent: action.data.Case.IsTalent,
        IsMoney: action.data.Case.IsMoney,
        IsLand: action.data.Case.IsLand,
        IsEnergy: action.data.Case.IsEnergy,
        IsOther: action.data.Case.IsOther,
        HandleType: action.data.Case.HandleType,
        EndCaseDesc: action.data.Case.EndCaseDesc,
        EndTime: action.data.Case.EndTime,
        caseId: action.data.Case.CaseId,
      };

      return {
        ...state,
        editedProject: updateProject,
      };
    case "EDITED_CONTENT_HANDLER":
      const ProjectValue = action.value;
      const ProjectName = action.name;
      const editedProject = { ...state.editedProject };

      editedProject[ProjectName] = ProjectValue;

      return {
        ...state,
        editedProject: editedProject,
      };
    case "START_UPDATE_PROJECT_INFO":
      return {
        ...state,
        isAuthQueryLoading: true,
      };
    case "FINISHED_UPDATE_PROJECT_INFO":
      return {
        ...state,
        isAuthQueryLoading: false,
      };
    case "START_GET_NO_AUTH_DATA":
      return {
        ...state,
        noAuthQueryLoading: true,
      };
    case "GET_NO_AUTH_DATA_SUCCESS":
      return {
        ...state,
        noAuthQueryParams: {
          ...state.noAuthQueryParams,
          ...action.data,
        },
        noAuthQueryLoading: false,
      };
    case "GET_NO_AUTH_DATA_FAIL":
      return {
        ...state,
        noAuthQueryLoading: false,
      };
    case "START_SUBMIT_REQUEST_FORM":
      return {
        ...state,
        isClientRequestLoading: true,
      };
    case "SUBMIT_REQUEST_FORM_SUCCESS":
      return {
        ...state,
        isClientRequestLoading: false,
      };
    case "SUBMIT_REQUEST_FORM_FAIL":
      return {
        ...state,
        isClientRequestLoading: false,
      };
    case "UPDATE_HANDLE_CONTENT":
      const updatedState = { ...state };

      updatedState.updateHandleContent = action.value;

      return {
        ...updatedState,
      };
    case "UPDATE_END_CASE_DESC":
      const updatedEndCaseState = { ...state };

      updatedEndCaseState.updateEndCaseDesc = action.value;

      return {
        ...updatedEndCaseState,
      };
    default:
      return state;
  }
};

const MySwal = withReactContent(Swal);

const ClientResponse = (props) => {
  const { innerWidth } = useSelector((state) => state.app);
  const [state, stateDispatch] = useReducer(reducer, initState);
  const [isAuth, setIsAuth] = useState(true);
  const [selectedCase, setSelectedCase] = useState({
    Contact: "",
    Case: "",
  });
  const [handleStatus, setHandleStatus] = useState([]);
  const [projectStatusHolder, setProjectStatusHolder] = useState("");
  const [clientPermission, setClientPermission] = useState([]);
  //控制填寫表單前的彈出 modal 有四種狀態， form-writing(準備填寫表單), form-delivering(傳送表單中), form-deliver-success(成功傳送表單) 和 form-deliver-fail(失敗傳送表單)
  const [requestStatus, setRequestStatus] = useState("form-writing");
  const [sendHandleStatus, setSendHandleStatus] = useState("complete");
  const [controlledHeight, setControlledHeight] = useState(82);
  const [containerRightHeight, setContainerRightHeight] = useState(100);
  const [clientRequestParamsLocal, setClientRequestParamsLocal] = useState(
    state.clientRequestParams
  );
  const [requestFromContent, setRequestFromContent] = useState();
  const [forwardModalOpen, setForwardModalOpen] = useState(false);
  const [caseId, setCaseId] = useState("");
  const [handleHistory, setHandleHistory] = useState([]);
  const [forwardCaseHeader, setForwardCaseHeader] = useState({
    CompanyName: "",
    ShowCaseId: "",
  });
  const [isReqiresOpen, setIsReqiresOpen] = useState(true);
  const [canView, setCanView] = useState(false);
  const permit = localStorage.getItem("16") === "16";
  const uid = localStorage.getItem("uid")


  useEffect(()=>{
    if(permit || 
      uid === "dcyu"|| 
      uid === "ghyan"|| 
      uid === "gyhwang"|| 
      uid === "idb01"|| 
      uid === "idb02"|| 
      uid === "idb04"|| 
      uid === "mirdc01"|| 
      uid === "rlyun"|| 
      uid === "succ" 
      ){
        setCanView(true)
      }else{
        setCanView(false)

      }
  },[
    permit,
    uid
  ])

  const updateForwardCaseHeader = (name, id) => {
    const updateHeader = { ...forwardCaseHeader };
    updateHeader.CompanyName = name;
    updateHeader.ShowCaseId = id;

    setForwardCaseHeader(updateHeader);
  };

  useEffect(() => {
    if (innerWidth > 1024) {
      setIsReqiresOpen(true);
    }
  }, [innerWidth]);

  useEffect(() => {
    getHandleHistory().then((res) => {
      if(res.data.status === 200){
        setHandleHistory(res.data.data);
      }
    });
  }, []);

  let [noAuthResult, setNoAuthResult] = useState(null);

  let history = useHistory();

  let isNumberOnly = new RegExp("[0-9]");

  const localAuth = localStorage.getItem("isLogin");

  useEffect(() => {
    setClientRequestParamsLocal(state.clientRequestParams);
  }, [state.clientRequestParams]);

  useEffect(() => {
    if (innerWidth > 1024) {
      setControlledHeight(82);
    } else {
      const { requestId } = props.match.params;

      if (!isAuth) {
        if (requestId === "request") {
          setControlledHeight(82);
          setContainerRightHeight(180);
        } else if (requestId === "query") {
          setControlledHeight(82);
          setContainerRightHeight(80);
        }
      } else {
        setControlledHeight(100);
      }
    }
  }, [innerWidth, props.match.params, isAuth]);

  useEffect(() => {
    const { requestId } = props.match.params;
    if (isNumberOnly.test(requestId) && requestId.length === 20) {
      stateDispatch({ type: "TOGGLE_STATUS", status: "query" });
      stateDispatch({
        type: "NO_AUTH_SEARCH_HANDLER",
        name: "ShowCaseId",
        value: requestId,
      });
    } else if (requestId === "request") {
      stateDispatch({ type: "TOGGLE_STATUS", status: "request" });
    } else if (requestId === "query") {
      stateDispatch({ type: "TOGGLE_STATUS", status: "query" });
    }
  }, [innerWidth]);

  const updatedStateBySelection = (value) => {
    stateDispatch({ type: "TOGGLE_STATUS", status: value });
  };

  useEffect(() => {
    const isAuth = localStorage.getItem("isLogin") === "true";
    setIsAuth(isAuth);
  }, [localAuth]);

  useEffect(() => {
    if (localStorage.getItem("isLogin")) {
      setClientPermission(JSON.parse(localStorage.getItem("client")));
    }
  }, []);

  useEffect(() => {
    if (clientPermission.length !== 0) {
      // authQueryHandler("init");
    }
  }, [clientPermission]);

  const authQueryHandler = (init) => {

    if(localStorage.getItem("mir_su") !== "true" && clientPermission.length === 0 && !canView){
      MySwal.fire({
        text: `無查詢產業園區廠商需求權限，請聯絡平台管理員。`,
      });

      return 
    }

    stateDispatch({ type: "START_AUTH_TABLE" });
    const {
      Keyword,
      HandleType,
      startDate,
      endDate,
      ParkIds,
    } = state.authQueryParams;

    const initParkLists = [];
    clientPermission.map((park) => {
      initParkLists.push(park.ParkId);
    });

    const data = new FormData();
    data.append("KeyWord", Keyword);
    data.append("isTech", "");
    data.append("IsProduct", "");
    data.append("IsTalent", "");
    data.append("IsMoney", "");
    data.append("IsLand", "");
    data.append("IsEnergy", "");
    data.append("StartDate", startDate);
    data.append("EndDate", endDate);
    data.append("HandleType", HandleType === "all" ? "" : HandleType);
    data.append("Email", "");
    
    if (init === "init") {
      stateDispatch({
        type: "AUTH_SEARCH_HANDLER",
        value: "all",
        name: "ParkIds",
      });
      data.append("ParkIds", initParkLists);
    } else {
      data.append("ParkIds", ParkIds === "all" ? initParkLists : ParkIds);
    }

    // data.append('ShowCaseId', '')

    AuthQueryHandler(data)
      .then((res) => {
  
        const data = [];
        if(res.data.status === 200){
          res.data.data.map((item) => {
            data.push({
              CompanyName: item.Contact.CompanyName,
              DisplayName: item.Contact.DisplayName,
              Phone: item.Contact.Phone,
              CaseId: item.Case.CaseId,
              HandleType: item.Case.HandleType,
              ShowCaseId: item.Case.ShowCaseId,
              Date: item.Case.ApplyTime.split(" ")[0],
            });
          });
          stateDispatch({
            type: "GET_AUTH_TABLE_SUCCESS",
            data: data,
            raw: res.data.data,
          });
        }else{
          stateDispatch({
            type: "GET_AUTH_TABLE_SUCCESS",
            data: [],
            raw: [],
          });
        }


      })
      .catch((err) => {
        stateDispatch({ type: "GET_AUTH_TABLE_FAIL", err: err });
        MySwal.fire({
          text: `系統發生錯誤，請聯絡管理員。\n ${err}`,
        });

        return;
      });

    //Close the filter in mobile search
    if (innerWidth < 1024) {
      setIsReqiresOpen(false);
    }
  };

  const clientRequestHandler = () => {
    const {
      CompanyName,
      BusinessNo,
      DisplayName,
      Sex,
      ParkId,
      Phone,
      Email,
      IsTech,
      IsProduct,
      IsTalent,
      IsMoney,
      IsLand,
      IsEnergy,
      IsOther,
      Content,
    } = state.clientRequestParams;

    const data = new FormData();

    data.append("CompanyName", CompanyName);
    data.append("BusinessNo", BusinessNo);
    data.append("DisplayName", DisplayName);
    data.append("Sex", Sex);
    data.append("ParkId", ParkId);
    data.append("Phone", Phone);
    data.append("Email", Email);
    data.append("IsTech", IsTech);
    data.append("IsProduct", IsProduct);
    data.append("IsTalent", IsTalent);
    data.append("IsMoney", IsMoney);
    data.append("IsLand", IsLand);
    data.append("IsEnergy", IsEnergy);
    data.append("IsOther", IsOther);
    data.append("Content", Content);

    setRequestStatus("form-delivering");
    ClientRequestHandler(data)
      .then((res) => {
        if (!res.data.data || res.data.status !== 200) {
          MySwal.fire({
            text: "您輸入的資料有誤造成連線錯誤，請重新填寫表單。",
          }).then(() => {
            stateDispatch({ type: "TOGGLE_FORM_MODAL" });
          });
        } else {
          stateDispatch({ type: "INIT_CLIENT_REQUEST_PARAMS" });
          setRequestStatus("form-deliver-success");
        }
      })
      .catch((err) => {
        MySwal.fire({
          text: "連線發生問題，您的表單尚未被送出。\n 請聯絡客服人員。",
        }).then(() => {
          stateDispatch({ type: "TOGGLE_FORM_MODAL" });
          stateDispatch({ type: "SUBMIT_REQUEST_FORM_FAIL" });
        });
      });
  };

  const noAuthQueryHandler = () => {
    const { ShowCaseId, Email } = state.noAuthQueryParams;

    if (!ShowCaseId) {
      MySwal.fire({
        text: "案件編號尚未填寫",
      });

      return;
    }
    if (!Email) {
      MySwal.fire({
        text: "E-mail尚未填寫",
      });

      return;
    }

    stateDispatch({ type: "START_GET_NO_AUTH_DATA" });

    const data = new FormData();
    data.append("ShowCaseId", ShowCaseId);
    data.append("Email", Email);

    NoAuthQueryHandler(data)
      .then((res) => {
        if (res.data.data.length !== 0) {
          const data = {
            ShowCaseId: "",
            ApplyTime: "",
            Content: "",
            HandleType: "",
            EndDate: "",
            EndDesc: "",
            HandleStatus: [],
          };

          data.ShowCaseId = res.data.data[0].Case.ShowCaseId;
          data.ApplyTime = res.data.data[0].Case.ApplyTime;
          data.Content = res.data.data[0].Case.Content;
          data.HandleType = res.data.data[0].Case.HandleType;
          data.EndDate = res.data.data[0].Case.EndDate;
          data.EndDesc = res.data.data[0].Case.EndCaseDesc;

          const { CaseId } = res.data.data[0].Case;
          GetProjectHandleStatus(CaseId)
            .then((res) => {
              data.HandleStatus = res.data.data;

              stateDispatch({ type: "GET_NO_AUTH_DATA_SUCCESS", data: data });
            })
            .catch((err) => {
              MySwal.fire({
                text: `取得案件處理情況發生問題，請聯絡管理員。\n ${err}`,
              }).then(() => {
                stateDispatch({ type: "GET_NO_AUTH_DATA_FAIL" });
              });
            });
        } else {
          MySwal.fire({
            text: "查無此案件。",
          }).then(() => {
            stateDispatch({ type: "GET_NO_AUTH_DATA_FAIL" });
          });
        }
      })
      .catch((err) => {
        MySwal.fire({
          text: `連線發生問題，請聯絡管理員。\n ${err}`,
        }).then(() => {
          stateDispatch({ type: "GET_NO_AUTH_DATA_FAIL" });
        });
      });
  };

  const openFromModalHandler = () => {
    const {
      CompanyName,
      BusinessNo,
      DisplayName,
      Sex,
      ParkId,
      Phone,
      Email,
      ConfirmedEmail,
      IsTech,
      IsProduct,
      IsTalent,
      IsMoney,
      IsLand,
      IsEnergy,
      isOther,
      IsOther,
      Content,
    } = state.clientRequestParams;
    let isEmail = new RegExp("@");

    if (!CompanyName) {
      MySwal.fire({
        text: "廠商名稱尚未填寫",
      });

      return;
    }
    if (!BusinessNo) {
      MySwal.fire({
        text: "統一編號尚未填寫",
      });
      return;
    }
    if (!DisplayName) {
      MySwal.fire({
        text: "聯絡窗口未填寫",
      });
      return;
    }
    if (!ParkId) {
      MySwal.fire({
        text: "所屬工業區尚未填寫",
      });
      return;
    }
    if (!Phone) {
      MySwal.fire({
        text: "聯絡電話尚未填寫",
      });
      return;
    }
    if (!Email) {
      MySwal.fire({
        text: "聯絡信箱尚未填寫",
      });
      return;
    }
    if (Email !== ConfirmedEmail) {
      MySwal.fire({
        text: "聯絡信箱與確認聯絡信箱不符",
      });
      return;
    }
    if (!isEmail.test(Email)) {
      MySwal.fire({
        text: "請輸入正確聯絡信箱格式",
      });
      return;
    }
    if (
      !IsTech &&
      !IsProduct &&
      !IsLand &&
      !IsMoney &&
      !IsEnergy &&
      !IsOther &&
      !IsTalent
    ) {
      MySwal.fire({
        text: "至少勾選一項廠商需求類型",
      });
      return;
    }
    if (!Content) {
      MySwal.fire({
        text: "廠商需求內容未填寫",
      });
      return;
    }

    stateDispatch({ type: "TOGGLE_FORM_MODAL" });
  };

  const LocalUseCheckBox = (props) => {
    const { label, labelName, onChange, value } = props;

    return (
      <div className="check-box">
        <FormControlLabel
          className="check-box-in"
          control={
            <Checkbox checked={value} onChange={onChange} name={labelName} />
          }
          label={label}
        />
      </div>
    );
  };

  const filteredSelectedProjectHandler = async (caseId) => {
    const { authRawData } = state;
    const updatedSelectedCase = authRawData.filter(
      (data) => data.Case.CaseId === caseId
    )[0];

    const projectHandleStatus = await GetProjectHandleStatus(caseId);

    if (projectHandleStatus.status === 200) {
      setHandleStatus(projectHandleStatus.data.data);
      setProjectStatusHolder(updatedSelectedCase.Case.HandleType);
      await setSelectedCase(updatedSelectedCase);
      stateDispatch({
        type: "UPDATE_EDITED_PROJECT",
        data: updatedSelectedCase,
      });
      stateDispatch({ type: "TOGGLE_AUTH_QUERY_MODAL" });
    } else {
      MySwal.fire({
        text: "取得案件狀態發生問題。",
      });
      return;
    }
  };

  const confirmUpdate = (caseId) => {
    if (projectStatusHolder === "辦理中") {
      const { updateHandleContent } = state;
      if (!updateHandleContent) {
        MySwal.fire({
          text: "請填寫辦理情形。",
        });
        return;
      }
    } else {
      const { updateEndCaseDesc } = state;

      if (!updateEndCaseDesc) {
        MySwal.fire({
          text: "請填寫結案說明。",
        });

        return;
      }
    }

    MySwal.fire({
      text:
        projectStatusHolder === "辦理中" ? "確認新增辦理情形？" : "確認結案?",
      showCancelButton: true,
      confirmButtonText: "確認",
      cancelButtonText: "取消",
    }).then((res) => {
      if (res.value) {
        updateStatusHandler(caseId);
      }
    });
  };

  const updateStatusHandler = async (caseId) => {
    const data = new FormData();
    const { EndCaseDesc } = state.editedProject;

    stateDispatch({ type: "START_UPDATE_PROJECT_INFO" });

    if (projectStatusHolder === "辦理中") {
      const user = localStorage.getItem("name");
      const { updateHandleContent } = state;

      setSendHandleStatus("sending");

      data.append("HandleContent", updateHandleContent);
      data.append("Logger", user);
      data.append("Memo", "");

      const updated = await UpdateProjectHandleStatus(caseId, data);
      if (updated.data.data) {
        const reload = await authQueryHandler("init");
        setSendHandleStatus("finished");
      } else {
        MySwal.fire({
          text: "連線發生問題，請重新嘗試。",
        }).then(() => {
          setSendHandleStatus("complete");
          stateDispatch({ type: "TOGGLE_AUTH_QUERY_MODAL" });
        });
      }

      stateDispatch({ type: "FINISHED_UPDATE_PROJECT_INFO" });
    } else {
      const { updateEndCaseDesc } = state;

      data.append("EndCaseDesc", updateEndCaseDesc);
      setSendHandleStatus("sending");

      const closed = await CaseClosedHandleStatus(caseId, data);

      if (closed.data.data) {
        const reload = await authQueryHandler("init");
        setSendHandleStatus("finished");
      } else {
        MySwal.fire({
          text: "連線發生問題，請重新嘗試。",
        }).then(() => {
          setSendHandleStatus("complete");
          stateDispatch({ type: "TOGGLE_AUTH_QUERY_MODAL" });
        });
      }

      stateDispatch({ type: "FINISHED_UPDATE_PROJECT_INFO" });

      // if(closed.)
    }
  };

  const handleForwardModal = (e, name, id) => {
    updateForwardCaseHeader(name, id);
    e.stopPropagation();
    setForwardModalOpen(!forwardModalOpen);
  };

  const handleForwardModalMobile = async (e, caseId, name, id) => {
    setCaseId(caseId);
    handleForwardModal(e, name, id);
  };

  let [content, setContent] = useState();

  useEffect(() => {
    if (state.status === "request") {
      const {
        CompanyName,
        BusinessNo,
        DisplayName,
        Sex,
        ParkId,
        Phone,
        Email,
        ConfirmedEmail,
        IsTech,
        IsProduct,
        IsTalent,
        IsMoney,
        IsLand,
        IsEnergy,
        isOther,
        IsOther,
        Content,
        isFormModalOpen,
      } = clientRequestParamsLocal;
      const { isAuthTableLoading } = state;

      let requestFromContent;

      if (requestStatus === "form-writing") {
        requestFromContent = (
          <div>
            <div className="form-modal-header">
              <div className="form-modal-header-head-center">
                <h3>需求資料確認</h3>
              </div>
              <p>
                為了確保您的權益，請確認下列 <span>資訊無誤</span>
                後再送出需求單。
              </p>
            </div>
            <div className="form-modal-content">
              <div>
                廠商名稱：<span>{CompanyName}</span>
              </div>
              <div>
                統一編號：<span>{BusinessNo}</span>
              </div>
              <div>
                聯絡窗口：<span>{DisplayName}</span>
              </div>
              <div>
                所屬園區：
                <span>
                  {ParkId && ParkId !== "other "
                    ? parks.filter((park) => park.ParkId === ParkId)[0]?.Name_CH
                    : ParkId === "other "? "其他" : null}
                </span>
              </div>
              <div>
                聯絡電話：<span>{Phone}</span>
              </div>
              <div>
                聯絡信箱：<span>{Email}</span>
              </div>
              <div>需求類型：</div>
              <div>
                <span>{IsTech ? "找技術 " : null}</span>
                <span>{IsProduct ? "找產品 " : null}</span>
                <span>{IsMoney ? "找補助 " : null}</span>
                <span>{IsLand ? "進駐/用地 " : null}</span>
                <span>{IsTalent ? "找人才 " : null}</span>
                <span>{IsEnergy ? "綠能需求 " : null}</span>
                <span>{IsOther ? IsOther : null}</span>
              </div>
              <div>需求內容：</div>
              <div>
                <span>{Content}</span>
              </div>
            </div>
            <div className="form-modal-btn-container">
              <button
                className="cancel"
                onClick={() => {
                  stateDispatch({ type: "TOGGLE_FORM_MODAL" });
                }}
              >
                取消
              </button>
              <button className="submit" onClick={clientRequestHandler}>
                確定送出需求
              </button>
            </div>
          </div>
        );
      } else if (requestStatus === "form-deliver-success") {
        requestFromContent = (
          <div>
            <div className="form-modal-header">
              <div className="form-modal-header-head">
                <h3>您的需求已成功送出</h3>
                <Icon
                  className="icon"
                  onClick={() => {
                    setRequestStatus("form-writing");
                    stateDispatch({ type: "TOGGLE_FORM_MODAL" });
                  }}
                >
                  close
                </Icon>
              </div>
            </div>
            <div className="form-modal-content">
              <div>
                <p>
                  可透過下方按鈕至需求查詢確認您的案件處理情況，或是繼續填寫需求。
                </p>
                <p>相關人員會盡快為您服務。</p>
              </div>
            </div>
            <div className="form-modal-btn-container">
              <button
                className="cancel"
                onClick={() => {
                  setRequestStatus("form-writing");
                  stateDispatch({ type: "TOGGLE_FORM_MODAL" });
                  stateDispatch({ type: "INIT_CLIENT_REQUEST_PARAMS" });
                  setClientRequestParamsLocal(state.clientRequestParams);
                }}
              >
                繼續填寫
              </button>
              <button
                className="submit"
                onClick={() => {
                  stateDispatch({ type: "TOGGLE_FORM_MODAL" });
                  setRequestStatus("form-writing");
                  stateDispatch({ type: "TOGGLE_STATUS", status: "query" });
                  history.push("/client-response/query");
                }}
              >
                至需求查詢
              </button>
            </div>
          </div>
        );
      } else if (requestStatus === "form-delivering") {
        requestFromContent = (
          <div>
            <Spinner />
          </div>
        );
      }

      setContent(
        <div className="request-container">
          <div className="request-container-basic-info">
            <div className="mobile-header">
              <h1>廠商查詢</h1>
            </div>
            <div className="request-container-basic-info-header">
              <h2>步驟1 : 填寫廠商資料</h2>
            </div>
            <div className="request-container-basic-info-input">
              <div className="request-container-basic-info-input-container">
                <div className="business-no">
                  <label for="client_response_businessNo">*統一編號</label>
                  <input
                    id="client_response_businessNo"
                    value={BusinessNo}
                    required
                    name="BusinessNo"
                    onChange={(event) => {
                      stateDispatch({
                        type: "CLIENT_REQUEST_HANDLER",
                        name: event.target.name,
                        value: event.target.value,
                      });
                    }}
                  />
                </div>
                <div className="name">
                  <label for="client_response_company_name">*廠商名稱</label>
                  <input
                    required
                    id="client_response_company_name"
                    onChange={(event) => {
                      stateDispatch({
                        type: "CLIENT_REQUEST_HANDLER",
                        name: event.target.name,
                        value: event.target.value,
                      });
                    }}
                    type="text"
                    value={CompanyName}
                    name="CompanyName"
                  />
                </div>
                <div className="contact">
                  <label for="client_response_contact">*聯絡窗口</label>
                  <div className="contact-inner-container">
                    <input
                      id="client_response_contact"
                      required
                      value={DisplayName}
                      name="DisplayName"
                      onChange={(event) => {
                        stateDispatch({
                          type: "CLIENT_REQUEST_HANDLER",
                          name: event.target.name,
                          value: event.target.value,
                        });
                      }}
                    />
                    <div>
                      <LocalUseCheckBox
                        label="先生"
                        labelName="Sex"
                        value={Sex === 1}
                        onChange={(event) => {
                          stateDispatch({
                            type: "CLIENT_REQUEST_HANDLER",
                            name: event.target.name,
                            value: 1,
                            inputType: "gender-check-box",
                          });
                        }}
                      />
                    </div>
                    <div>
                      <LocalUseCheckBox
                        label="小姐"
                        labelName="Sex"
                        value={Sex === 0}
                        onChange={(event) => {
                          stateDispatch({
                            type: "CLIENT_REQUEST_HANDLER",
                            name: event.target.name,
                            value: 0,
                            inputType: "gender-check-box",
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="request-container-basic-info-input-container">
                <div className="indu-park">
                  <FormControl
                    className="query-container-auth-search-left-indupark"
                    variant="outlined"
                  >
                    <InputLabel id="client_response_belong" className="select-label">
                      *所屬產業園區
                    </InputLabel>
                    <Select
                      labelId="client_response_belong"
                      value={ParkId}
                      onChange={(event) => {
                        stateDispatch({
                          type: "CLIENT_REQUEST_HANDLER",
                          value: event.target.value,
                          name: "ParkId",
                        });
                      }}
                      input={<Input type="select" />}
                      name="ParkId"
                    >
                      {parks.map((option) => (
                        <MenuItem value={option.ParkId} key={option.Name_EN}>
                          {option.Name_CH}
                        </MenuItem>
                      ))}
                    <MenuItem key={"other"} value={"other"}>
                      其他
                    </MenuItem>
                    </Select>
                  </FormControl>
                  {/* <label>*所屬園區</label>
                              <FormControl>

                              </FormControl>
                              <input 
                                  value={ParkId}
                                  name="ParkId"
                                  onChange={event => { stateDispatch({ type: "CLIENT_REQUEST_HANDLER", name: event.target.name, value: event.target.value }) }}
                              /> */}
                </div>
                <div className="tel">
                  <label for="client_response_tel">*聯絡電話</label>
                  <input
                    required
                    id="client_response_tel"
                    value={Phone}
                    name="Phone"
                    onChange={(event) => {
                      stateDispatch({
                        type: "CLIENT_REQUEST_HANDLER",
                        name: event.target.name,
                        value: event.target.value,
                      });
                    }}
                  />
                </div>
                <div className="e-mail">
                  <label for="client_response_mail">*聯絡信箱</label>
                  <input
                    required
                    id="client_response_mail"
                    value={Email}
                    name="Email"
                    onChange={(event) => {
                      stateDispatch({
                        type: "CLIENT_REQUEST_HANDLER",
                        name: event.target.name,
                        value: event.target.value,
                      });
                    }}
                  />
                </div>
                <div className="e-mail-confirm">
                  <label for="client_response_mail_confirm">*聯絡信箱（確認）</label>
                  <input
                    required
                    id="client_response_mail_confirm"
                    value={ConfirmedEmail}
                    name="ConfirmedEmail"
                    onChange={(event) => {
                      stateDispatch({
                        type: "CLIENT_REQUEST_HANDLER",
                        name: event.target.name,
                        value: event.target.value,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="request-container-request-form">
            <div className="request-container-request-form-title">
              <h2>步驟2 : 填寫需求表單</h2>
            </div>
            <div className="request-container-request-form-container">
              <div className="request-container-request-form-container-column">
                <div className="request-container-request-form-inner-header">
                  <h3>*廠商需求類型(至少勾選一項)</h3>
                </div>
                <div className="request-container-request-form-form">
                  <div className="request-container-request-form-container">
                    <div>
                      <LocalUseCheckBox
                        label="找技術"
                        labelName="IsTech"
                        value={IsTech}
                        onChange={(event) => {
                          stateDispatch({
                            type: "CLIENT_REQUEST_HANDLER",
                            name: event.target.name,
                            value: !IsTech,
                            inputType: "check-box",
                          });
                        }}
                      />
                    </div>
                    <div>
                      <LocalUseCheckBox
                        label="找產品"
                        labelName="IsProduct"
                        value={IsProduct}
                        onChange={(event) => {
                          stateDispatch({
                            type: "CLIENT_REQUEST_HANDLER",
                            name: event.target.name,
                            value: !IsProduct,
                            inputType: "check-box",
                          });
                        }}
                      />
                    </div>
                    {/* <div>
                        <LocalUseCheckBox
                          label=""
                          labelName="IsProduct"
                          value={IsProduct}
                          onChange={event => { stateDispatch({ type: "CLIENT_REQUEST_HANDLER", name: event.target.name, value: !IsProduct, inputType: "check-box" }) }}
                        />
                      </div> */}
                  </div>
                  <div className="request-container-request-form-container">
                    <div>
                      <LocalUseCheckBox
                        label="找補助"
                        labelName="IsMoney"
                        value={IsMoney}
                        onChange={(event) => {
                          stateDispatch({
                            type: "CLIENT_REQUEST_HANDLER",
                            name: event.target.name,
                            value: !IsMoney,
                            inputType: "check-box",
                          });
                        }}
                      />
                    </div>
                    <div>
                      <LocalUseCheckBox
                        label="進駐/用地"
                        labelName="IsLand"
                        value={IsLand}
                        onChange={(event) => {
                          stateDispatch({
                            type: "CLIENT_REQUEST_HANDLER",
                            name: event.target.name,
                            value: !IsLand,
                            inputType: "check-box",
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="request-container-request-form-container">
                    <div>
                      <LocalUseCheckBox
                        label="找人才"
                        labelName="IsTalent"
                        value={IsTalent}
                        onChange={(event) => {
                          stateDispatch({
                            type: "CLIENT_REQUEST_HANDLER",
                            name: event.target.name,
                            value: !IsTalent,
                            inputType: "check-box",
                          });
                        }}
                      />
                    </div>
                    <div>
                      <LocalUseCheckBox
                        label="綠能需求"
                        labelName="IsEnergy"
                        value={IsEnergy}
                        onChange={(event) => {
                          stateDispatch({
                            type: "CLIENT_REQUEST_HANDLER",
                            name: event.target.name,
                            value: !IsEnergy,
                            inputType: "check-box",
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="request-container-request-form-container">
                    <div>
                      <LocalUseCheckBox
                        label="其他"
                        labelName="isOther"
                        value={isOther}
                        onChange={(event) => {
                          stateDispatch({
                            type: "CLIENT_REQUEST_HANDLER",
                            name: event.target.name,
                            value: !isOther,
                            inputType: "check-box",
                          });
                        }}
                      />
                    </div>
                    <div>
                      {isOther && (
                        <input
                          className="other-input"
                          value={IsOther}
                          name="IsOther"
                          onChange={(event) => {
                            stateDispatch({
                              type: "CLIENT_REQUEST_HANDLER",
                              name: event.target.name,
                              value: event.target.value,
                            });
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="request-container-request-form-container-column">
                <div className="request-container-request-form-inner-header">
                  <h3>*廠商需求內容</h3>
                </div>
                <label style={{
                  opacity: 0,
                  height: "0px"
                }} for="client_response_need">廠商需求內容</label>
                <textarea
                  id="client_response_need"
                  required
                  className="request-container-request-form-container-text-area"
                  value={Content}
                  name="Content"
                  onChange={(event) => {
                    stateDispatch({
                      type: "CLIENT_REQUEST_HANDLER",
                      name: event.target.name,
                      value: event.target.value,
                    });
                  }}
                />
              </div>
            </div>
          </div>
          <div className="request-container-request-btn">
            <button className="btn" style={{transform: "translateY(50px)" }} onClick={openFromModalHandler}>
              送出需求
            </button>
          </div>
          <CustomizedModal isOpen={state.isFormModalOpen}>
            <div className="form-modal">{requestFromContent}</div>
          </CustomizedModal>
        </div>
      );
    } else if (state.status === "query") {
      const {
        Keyword,
        ParkIds,
        HandleType,
        startDate,
        endDate,
      } = state.authQueryParams;
      const { isAuthTableLoading, authTableContent } = state;
      const url = window.location.href.split("/");

      let modalContent;

      if (sendHandleStatus === "complete") {
        modalContent = (
          <div className="auth-query-modal-content">
            <div className="inner-content">
              <div className="auth-query-modal-content-btn-header">
                <div className="auth-query-modal-content-btn-header-box">
                  <div>
                    <h3>{selectedCase.Contact.CompanyName} 的需求案件</h3>
                  </div>
                  <div>
                    <span>[案件編號：{selectedCase.Case.ShowCaseId}]</span>
                  </div>
                </div>

                <Icon
                  onClick={() => {
                    stateDispatch({ type: "TOGGLE_AUTH_QUERY_MODAL" });
                  }}
                >
                  close
                </Icon>
              </div>
              <div className="auth-query-modal-content-container" onScroll={()=>{ alert("test!") }}>
                <div className="auth-query-modal-content-header">
                  <h3>基本資訊</h3>
                </div>
                <div className="auth-query-modal-content-description">
                  <div className="auth-query-modal-content-description-left">
                    <div className="auth-query-modal-content-description-box">
                      <label>*廠商名稱</label>
                      <div>{selectedCase.Contact.CompanyName}</div>
                    </div>
                    <div className="auth-query-modal-content-description-box">
                      <label>*統一編號</label>
                      <div
                        className="businessNo"
                        onClick={() => {
                          window.open(
                            url[0] +
                              "//" +
                              url[2] +
                              "/factory_info/basic_info/" +
                              selectedCase.Contact.BusinessNo,
                            "_blank"
                          );
                        }}
                      >
                        {selectedCase.Contact.BusinessNo}
                      </div>
                    </div>
                    <div className="auth-query-modal-content-description-box">
                      <label>*聯絡窗口</label>
                      <div>
                        {selectedCase.Contact.DisplayName}
                        <span>{selectedCase.Contact.Sex === 0 ? "小姐" : "先生"}</span>
                      </div>
                    </div>
                  </div>
                  <div className="auth-query-modal-content-description-right">
                    <div className="auth-query-modal-content-description-box">
                      <label>*所屬園區</label>
                      <div>
                        {selectedCase.Contact.ParkId && selectedCase.Contact.ParkId !== "other "
                          ? parks.filter(
                              (park) =>
                                park.ParkId === selectedCase.Contact.ParkId
                            )[0]?.Name_CH
                          : selectedCase.Contact.ParkId === "other " ? "其他" : null}
                      </div>
                    </div>
                    <div className="auth-query-modal-content-description-box">
                      <label>*聯絡電話</label>
                      <div>{selectedCase.Contact.Phone}</div>
                    </div>
                    <div className="auth-query-modal-content-description-box">
                      <label>*聯絡信箱</label>
                      <div>{selectedCase.Contact.Email}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="auth-query-modal-content-container">
                <div className="auth-query-modal-content-header">
                  <h3>案件內容</h3>
                </div>
                <div className="auth-query-modal-content-description">
                  <p>{selectedCase.Case.Content}</p>
                </div>
              </div>
              <div className="auth-query-modal-content-container select">
                <div className="auth-query-modal-content-header">
                  <h3>案件狀態</h3>
                  <FormControl
                    className="auth-query-modal-content-header-status"
                    variant="outlined"
                  >
                    <InputLabel className="select-label">案件狀態</InputLabel>
                    <Select
                      value={projectStatusHolder}
                      onChange={(event) => {
                        setProjectStatusHolder(event.target.value);
                      }}
                      input={<Input type="select" />}
                      name="HandleType"
                      disabled={state.editedProject.HandleType === "結案"}
                    >
                      <MenuItem value="辦理中" key="processing">
                        辦理中
                      </MenuItem>
                      <MenuItem value="結案" key="close">
                        結案
                      </MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div className="auth-query-modal-content-container">
                <div className="auth-query-modal-content-header">
                  <h3>辦理情形</h3>
                </div>
                <div className="auth-query-modal-content-description">
                  <div className="auth-query-modal-content-description-container">
                    {handleStatus.length !== 0 ? (
                      handleStatus.map((content) => {
                        return (
                          <div className="auth-query-modal-content-description-box">
                            <div className="auth-query-modal-content-description-box-date">
                              {content.RecordTime +
                                " " +
                                "承辦人員 : " +
                                content.Logger}
                            </div>
                            <div className="auth-query-modal-content-description-box-content">
                              {content.HandleContent}
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <div className="auth-query-modal-content-description-box">
                        <div className="auth-query-modal-content-description-box-content">
                          目前無辦理情況。
                        </div>
                      </div>
                    )}
                    {projectStatusHolder !== "結案" && (
                      <div className="auth-query-modal-content-description-box">
                        <CustomizedAutoComplete
                          // className="status-input"
                          placeholder="請輸入辦理情形"
                          option={[
                            {
                              name:
                                "您的需求已轉介相關窗口，後續將由該窗口為您服務。窗口聯絡資訊：",
                              value:
                                "您的需求已轉介相關窗口，後續將由該窗口為您服務。窗口聯絡資訊：",
                            },
                            {
                              name:
                                "您的案件正在積極聯繫與辦理中，若有任何疑問請致電：",
                              value:
                                "您的案件正在積極聯繫與辦理中，若有任何疑問請致電：",
                            },
                          ]}
                          value={state.updateHandleContent}
                          onChange={(value) => {
                            stateDispatch({
                              type: "UPDATE_HANDLE_CONTENT",
                              value: value,
                            });
                          }}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {state.editedProject.EndCaseDesc && (
                <div className="auth-query-modal-content-container">
                  <div className="auth-query-modal-content-header">
                    <h3>結案說明</h3>
                  </div>
                  <div className="auth-query-modal-content-description">
                    <div className="auth-query-modal-content-description-container">
                      <div className="auth-query-modal-content-description-box">
                        <div className="auth-query-modal-content-description-box-date">
                          {"結案時間 : " + state.editedProject.EndTime}
                        </div>
                        <div className="auth-query-modal-content-description-box-content">
                          {state.editedProject.EndCaseDesc}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {projectStatusHolder === "結案" &&
                state.editedProject.HandleType !== "結案" && (
                  <div className="auth-query-modal-content-container">
                    <div className="auth-query-modal-content-header">
                      <h3>結案說明</h3>
                    </div>
                    <div className="auth-query-modal-content-description">
                      <div className="auth-query-modal-content-description-container">
                        <div className="auth-query-modal-content-description-box">
                          <CustomizedAutoComplete
                            disabled={state.editedProject.HandleType === "結案"}
                            placeholder="*請輸入結案說明"
                            option={[
                              {
                                name:
                                  "您的需求已轉介相關窗口，後續將由該窗口為您服務。窗口聯絡資訊：",
                                value:
                                  "您的需求已轉介相關窗口，後續將由該窗口為您服務。窗口聯絡資訊：",
                              },
                              {
                                name:
                                  "您的案件正在積極聯繫與辦理中，若有任何疑問請致電：",
                                value:
                                  "您的案件正在積極聯繫與辦理中，若有任何疑問請致電：",
                              },
                            ]}
                            value={state.updateEndCaseDesc}
                            onChange={(value) => {
                              stateDispatch({
                                type: "UPDATE_END_CASE_DESC",
                                value: value,
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
            </div>
            {state.editedProject.HandleType !== "結案" && (
              <div className="auth-query-modal-content-btn">
                <button
                  onClick={() => confirmUpdate(state.editedProject.caseId)}
                >
                  更新狀態
                </button>
              </div>
            )}
          </div>
        );
      } else if (sendHandleStatus === "sending") {
        modalContent = (
          <div className="auth-query-modal-content">
            <div className="inner-content">
              <Spinner />
            </div>
          </div>
        );
      } else if (sendHandleStatus === "finished") {
        modalContent = (
          <div className="auth-query-modal-content">
            <div className="inner-content">
              <div className="auth-query-modal-content-btn-header">
                <Icon
                  className="icon-complete"
                  onClick={() => {
                    stateDispatch({ type: "TOGGLE_AUTH_QUERY_MODAL" });
                    setSendHandleStatus("complete");
                  }}
                >
                  close
                </Icon>
              </div>
              <div className="complete-modal">
                案件編號 <span> {selectedCase.Case.ShowCaseId} </span>{" "}
                {projectStatusHolder === "辦理中"
                  ? "已完成新增辦理情形。"
                  : "已完成結案。"}
              </div>
            </div>
            <div className="auth-query-modal-content-btn"></div>
          </div>
        );
      }

      if (canView) {
        setContent(
          <div className="query-container-auth">
            <div className="query-container-auth-search">
              <div className="query-container-auth-search-left">
                <div className="query-container-auth-search-left-head">
                  <h3>需求管理</h3>
                  <Icon
                    onClick={() => {
                      setIsReqiresOpen(!isReqiresOpen);
                    }}
                  >
                    {isReqiresOpen
                      ? "keyboard_arrow_up"
                      : "keyboard_arrow_down"}
                  </Icon>
                </div>

                <Collapse in={isReqiresOpen}>
                  <>
                    <FormControl
                      className="query-container-auth-search-left-indupark"
                      variant="outlined"
                    >
                      <InputLabel className="select-label">
                        所屬產業園區
                      </InputLabel>
                      <Select
                        value={ParkIds}
                        onChange={(event) => {
                          stateDispatch({
                            type: "AUTH_SEARCH_HANDLER",
                            value: event.target.value,
                            name: "ParkIds",
                          });
                        }}
                        input={<Input type="select" />}
                        name="ParkIds"
                      >
                        {(!canView && clientPermission.length === 0) ?
(                       <MenuItem value="all" key="all">
                          無產業園區權限
                        </MenuItem>):
(                        <MenuItem value="all" key="all">
                          所有產業園區
                        </MenuItem>)
                        }

                        {(localStorage.getItem("mir_su") === "true" || canView) ? parks.map((option) => (
                          <MenuItem value={option.ParkId} key={option.ParkId}>
                            {option.Name_CH}
                          </MenuItem>
                        )) : clientPermission.map((option) => (
                          <MenuItem value={option.ParkId} key={option.Name_EN}>
                            {option.ParkName}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <div className="query-container-auth-search-left-keyword">
                      <label>關鍵字</label>
                      <input
                        onChange={(event) => {
                          stateDispatch({
                            type: "AUTH_SEARCH_HANDLER",
                            name: event.target.name,
                            value: event.target.value,
                          });
                        }}
                        value={Keyword}
                        name={"Keyword"}
                        placeholder="請輸入廠商名稱、案件編號或案件內容"
                      />
                    </div>
                    <div className="query-container-auth-search-left-start-date">
                      <label>查詢案件建立時間大於:</label>
                      <input
                        type="date"
                        onChange={(event) => {
                          stateDispatch({
                            type: "AUTH_SEARCH_HANDLER",
                            name: event.target.name,
                            value: event.target.value,
                          });
                        }}
                        value={startDate}
                        name={"startDate"}
                        placeholder="請輸入 2020-09-01"
                      />
                    </div>
                  </>
                </Collapse>
              </div>

              {window.innerWidth < 1024 && (
                <Collapse in={isReqiresOpen}>
                  <div className="query-container-auth-search-md">
                    <div className="query-container-auth-search-md-end-date">
                      <label>結案時間</label>
                      <input
                        type="date"
                        onChange={(event) => {
                          stateDispatch({
                            type: "AUTH_SEARCH_HANDLER",
                            name: event.target.name,
                            value: event.target.value,
                          });
                        }}
                        value={endDate}
                        name={"endDate"}
                      />
                    </div>
                    <FormControl
                      className="query-container-auth-search-md-case-status"
                      variant="outlined"
                    >
                      <InputLabel className="select-label">案件狀態</InputLabel>
                      <Select
                        value={HandleType}
                        onChange={(event) => {
                          stateDispatch({
                            type: "AUTH_SEARCH_HANDLER",
                            name: event.target.name,
                            value: event.target.value,
                          });
                        }}
                        input={<Input type="select" />}
                        name="HandleType"
                      >
                        <MenuItem value="all" key="all">
                          所有案件
                        </MenuItem>
                        <MenuItem value="辦理中" key="processing">
                          辦理中
                        </MenuItem>
                        <MenuItem value="結案" key="close">
                          結案
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </Collapse>
              )}

              {window.innerWidth > 1024 && (
                <div className="query-container-auth-search-md">
                  <FormControl
                    className="query-container-auth-search-md-case-status"
                    variant="outlined"
                  >
                    <InputLabel className="select-label">案件狀態</InputLabel>
                    <Select
                      value={HandleType}
                      onChange={(event) => {
                        stateDispatch({
                          type: "AUTH_SEARCH_HANDLER",
                          name: event.target.name,
                          value: event.target.value,
                        });
                      }}
                      input={<Input type="select" />}
                      name="HandleType"
                    >
                      <MenuItem value="all" key="all">
                        所有案件
                      </MenuItem>
                      <MenuItem value="辦理中" key="processing">
                        辦理中
                      </MenuItem>
                      <MenuItem value="結案" key="close">
                        結案
                      </MenuItem>
                    </Select>
                  </FormControl>
                  <div className="query-container-auth-search-md-end-date">
                    <label>查詢案件建立時間小於:</label>
                    <input
                      type="date"
                      onChange={(event) => {
                        stateDispatch({
                          type: "AUTH_SEARCH_HANDLER",
                          name: event.target.name,
                          value: event.target.value,
                        });
                      }}
                      value={endDate}
                      name={"endDate"}
                      placeholder="請輸入 2020-09-01"
                    />
                  </div>
                </div>
              )}

              <div className="query-container-auth-search-right">
                <button onClick={()=> authQueryHandler()}>查詢</button>
              </div>
            </div>
            <div className="query-container-auth-table">
              <CustomizedTable
                type="client-request-table"
                loading={state.isAuthTableLoading}
                content={authTableContent}
                getProjectId={filteredSelectedProjectHandler}
                openForwardProjectModal={(e, name, id) =>
                  handleForwardModal(e, name, id)
                }
                getCaseId={(value) => {
                  setCaseId(value);
                }}
                hover
              />
            </div>
            <div className="query-container-auth-mobile">
              {isAuthTableLoading && <Spinner />}
              {authTableContent.map((content) => (
                <div
                  className="query-container-auth-mobile-container"
                  onClick={() => filteredSelectedProjectHandler(content.CaseId)}
                >
                  <div className="item">
                    <div className="item-title">案件時間 :</div>
                    <div className="item-content">{content.Date}</div>
                  </div>
                  <div className="item">
                    <div className="item-title">廠商名稱 :</div>
                    <div className="item-content">{content.CompanyName}</div>
                  </div>
                  <div className="item">
                    <div className="item-title">申請窗口 :</div>
                    <div className="item-content">{content.DisplayName}</div>
                  </div>
                  <div className="item">
                    <div className="item-title">聯絡電話 :</div>
                    <div className="item-content">{content.Phone}</div>
                  </div>
                  <div className="item">
                    <div className="item-title">案件狀態 :</div>
                    <div className="item-content">{content.HandleType}</div>
                  </div>
                  {content.HandleType !== "結案" && (
                    <div className="item">
                      <div
                        className="forward-btn"
                        onClick={(e) =>
                          handleForwardModalMobile(
                            e,
                            content.CaseId,
                            content.CompanyName,
                            content.ShowCaseId
                          )
                        }
                      >
                        建立分案
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
            <CustomizedModal isOpen={state.isAuthQueryModalOpen}>
              <div>{modalContent}</div>
            </CustomizedModal>
            <ForwardProjectInput
              isOpen={forwardModalOpen}
              handleClose={handleForwardModal}
              caseId={caseId}
              handleHistroy={handleHistory}
              forwardCaseHeader={forwardCaseHeader}
            />
          </div>
        );
      } else if(!canView){
        const { ShowCaseId, Email, Content } = state.noAuthQueryParams;
        const { noAuthQueryParams } = state;

        setContent(
          <div className="query-container">
            <div className="query-container-content">
              <div className="query-container-inputs">
                <div className="query-container-inputs-header">
                  <h1>案件查詢</h1>
                </div>
                <div className="query-container-input">
                  <div className="query-container-input-content">
                    <label for="ShowCaseId">*案件編號</label>
                    <input
                      id="ShowCaseId"
                      required
                      name="ShowCaseId"
                      value={ShowCaseId}
                      onChange={(event) => {
                        stateDispatch({
                          type: "NO_AUTH_SEARCH_HANDLER",
                          name: event.target.name,
                          value: event.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="query-container-input">
                  <div className="query-container-input-content">
                    <label for="ShowCaseEmail">*E-mail</label>
                    <input
                      id="ShowCaseEmail"
                      required
                      name="Email"
                      value={Email}
                      onChange={(event) => {
                        stateDispatch({
                          type: "NO_AUTH_SEARCH_HANDLER",
                          name: event.target.name,
                          value: event.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className="query-container-input-btn">
                    <button onClick={noAuthQueryHandler}>查詢</button>
                  </div>
                </div>
              </div>
              <div className="query-container-results">
                {!Content && (
                  <div className="result-no-data"> 目前尚無資料</div>
                )}
                {state.noAuthQueryLoading && (
                  <div>
                    <Spinner />
                  </div>
                )}
                {!state.noAuthQueryLoading && Content && (
                  <div className="query-container-results-container">
                    <CustomizedCard isSimple>
                      <div className="query-container-results-box">
                        <div className="query-container-results-box-item">
                          <h5>案件編號：</h5>
                          <p>{noAuthQueryParams.ShowCaseId}</p>
                        </div>
                        <div className="query-container-results-box-item">
                          <h5>申請日期：</h5>
                          <p>{noAuthQueryParams.ApplyTime}</p>
                        </div>
                        {/* <div><h5>需求類別：</h5><p>{NoAuthContent.Case.}</p></div> */}
                        <div className="query-container-results-box-item">
                          <h5>需求內容：</h5>
                          <p>{noAuthQueryParams.Content}</p>
                        </div>
                        {noAuthQueryParams.HandleStatus.length > 0 && (
                          <div className="query-container-results-box-item">
                            <h5>辦理情形：</h5>
                            <div className="query-container-results-box-item-status">
                              {noAuthQueryParams.HandleStatus.map((content) => {
                                return (
                                  <div>
                                    <p className="record-date">
                                      {content.RecordTime}
                                    </p>
                                    <p className="record-content">
                                      {content.HandleContent}
                                    </p>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        )}
                        <div className="query-container-results-box-item">
                          <h5>案件狀態：</h5>
                          <p>{noAuthQueryParams.HandleType}</p>
                        </div>
                        <div
                          className="query-container-results-box-item"
                          style={
                            noAuthQueryParams.EndDate
                              ? { display: "flex" }
                              : { display: "none" }
                          }
                        >
                          <h5>完成日期：</h5>
                          <p>{noAuthQueryParams.EndDate}</p>
                        </div>
                        <div
                          className="query-container-results-box-item"
                          style={
                            noAuthQueryParams.EndDesc
                              ? { display: "flex" }
                              : { display: "none" }
                          }
                        >
                          <h5>結案說明：</h5>
                          <p>{noAuthQueryParams.EndDesc}</p>
                        </div>
                      </div>
                    </CustomizedCard>
                  </div>
                )}
              </div>
            </div>
            <div className="query-container-description">
              <h2 className="query-container-description-header">操作說明 :</h2>
              <ol style={{
                listStyle: "decimal",
                paddingLeft: "1.25rem",
              }}>
                <li>
                  請參考申請案件時所收到之電子郵件或簡訊資料輸入正確案件編號。
                </li>
                <li>
                  並注意您輸入的Email須與申請案件時所提供之資料一致，俾利系統正確判讀。
                </li>
              </ol>
            </div>
          </div>
        );
      }
    }
  }, [
    localStorage.getItem("mir_su"),
    clientRequestParamsLocal,
    state.status,
    requestStatus,
    state.isFormModalOpen,
    state.authTableContent,
    caseId,
  , forwardModalOpen,
    isReqiresOpen,
    isAuth,
    state.isAuthQueryModalOpen,
    state.updateHandleContent,
    state.updateEndCaseDesc,
    projectStatusHolder,
    state.noAuthQueryParams,
    state.authQueryParams,
    state.authTableContent,
    state.isAuthTableLoading,
    clientPermission,
    localStorage.getItem('16'),
    // state.clientRequestParams
  ]);

  return (
    <div className="client-response">
      { state.status === "request" ?
        <Helmet>
            <title>{`廠商需求申請-產業園區廠商情資平台`}</title>
        </Helmet> :
        <Helmet>
            <title>{`廠商需求查詢-產業園區廠商情資平台`}</title>
        </Helmet>
      }
      <div className="client-page-selection">
          <FormControl className="client-page-select" variant="outlined">
            <Select
              value={state.status}
              onChange={(event) => {
                history.push(`/client-response/${event.target.value}`);
                stateDispatch({
                  type: "TOGGLE_STATUS_SELECTION",
                  value: event.target.value,
                });
              }}
              input={<Input type="select" />}
              name="status"
              //   disabled
            >
              <MenuItem value="query" key="query">
                案件查詢
              </MenuItem>
              <MenuItem value="request" key="request">
                需求申請
              </MenuItem>
            </Select>
          </FormControl>
      </div>
      <div
        className="client-response-container"
        style={{ height: `${controlledHeight}vh` }}
      >
        <div className="client-response-container-left">
          <div className="client-response-option-container">
            <button
              className="client-response-option-container-query"
              style={
                state.status === "request"
                  ? { fontSize: "200%" }
                  : {
                      fontSize: "300%",
                      fontWeight: "600",
                      borderBottom: "2px solid #FFFFFF",
                    }
              }
              onClick={() => {
                stateDispatch({ type: "TOGGLE_STATUS", status: "query" });
                history.push("/client-response/query");
              }}
            >
              需求查詢
            </button>
            <button
              className="client-response-option-container-request"
              style={
                state.status === "query"
                  ? { fontSize: "200%" }
                  : {
                      fontSize: "300%",
                      fontWeight: "600",
                      borderBottom: "2px solid #FFFFFF",
                    }
              }
              onClick={() => {
                stateDispatch({ type: "TOGGLE_STATUS", status: "request" });
                history.push("/client-response/request");
              }}
            >
              需求申請
            </button>
          </div>
        </div>
        <div className="client-response-container-middle">
          <div
            className="client-response-container-middle-up"
            style={
              state.status === "request"
                ? { borderWidth: "57vh 0 0 10vw" }
                : { borderWidth: "27vh 0 0 10vw" }
            }
          />
          <div
            className="client-response-container-middle-down"
            style={
              state.status === "request"
                ? { borderWidth: "0 0 25vh 10vw" }
                : { borderWidth: "0 0 55vh 10vw" }
            }
          />
        </div>
        <div
          className="client-response-container-right"
          style={{ height: `${containerRightHeight}vh` }}
        >
          {content}
        </div>
      </div>

      {/* {localStorage.getItem('isLogin') ? JSON.parse(localStorage.getItem('ParkPermission')).length === 0 &&
          <Redirect to="/section/QOP003" /> : null
        } */}
      {/* {localStorage.getItem('isLogin')  ? JSON.parse(localStorage.getItem('ParkPermission')).length === 0 &&
          MySwal.fire({text: "您無此頁權限，請聯絡系統管理員。"}) : null
        } */}

      {/* <CustomizedDialog isOpen={false}>
                
            </CustomizedDialog> */}
    </div>
  );
};

export default ClientResponse;
