import { useHistory } from "react-router-dom";
import React, { useState } from "react";
import styled from "styled-components";
import Drawer from '@material-ui/core/Drawer';
import TextField from "@material-ui/core/TextField";

import ControlledMaterilaAutoComplete from "../ControlledMaterialMapAutoComplete/ControlledMaterialMapAutoComplete";
import ControlledMaterialFilterGroup from "../ControlledMaterialFilterGroup/ControlledMaterialFilterGroup";
import ControlledMaterialResultList from "../ControlledMaterialResultList/ControlledMaterialResultList";
import { getControlledMaterialResult } from "./api";
import "./styles.scss";

import * as theme from "../../assets/theme";

export default props => {
    const history = useHistory();
    const [state, setState] = useState({
        KeyWord: "",
        controlledMaterialType: "",
        location: "",
        city: "",
        parkId: "",
        typeTwo: "",
        typeFour: "",
        data: "",
        loading: false,
        isClicked: false,
        forceOpenFilterGroup:false,
        WasteName:"",
        ToxicName: "",
        totalAmt: 0
    });
    const { open, toggleDrawer, passDataHandler, data, isLoading, onSelectedCompany, updateKeyWord, keyWord, handleClearParamsInMaterialControl, closePopUpHandler, wastedPollutionOptions, toxicPollutionOptions } = props;
    const ref = React.useRef(null);

    const toggleDrawerHandler = () => {
        toggleDrawer();
    }

    const fetchControlledMaterialDataHandler = async () => {
        setState(state=>({ ...state, loading: true, isClicked:true }));

        const { controlledMaterialType, location, city, parkId, typeTwo, typeFour, WasteName, ToxicName } = state;
        const form = new FormData();
        form.append("KeyWord", keyWord);
        form.append("controlledMaterialType", controlledMaterialType);
        form.append("location", location);
        form.append("city", city);
        form.append("parkId", parkId);
        form.append("typeTwo", typeTwo);
        form.append("typeFour", typeFour);
        form.append("WasteName", WasteName);
        form.append("ToxicName", ToxicName);

        const data = await getControlledMaterialResult(form);

        if (data.status === 400) {
            data.data = [];
        }
        //data 的 TotalList 沒有值可能是沒有選可回收項目，給 0 然後不要顯示。
        setState(state=>({ ...state, loading: false, data: data.data, totalAmt: data.TotalList.length > 0 ? data.TotalList[0].Total : 0 ,forceOpenFilterGroup: true }));
    };

    const updateDataFromFilter = (name, value) => {
        //fetch data from filter group
        if(name === "WasteName"){
            setState(state=>({
                ...state,
                ToxicName: "",
                [name]: Array.isArray(value) ? value.slice(1, (value.length)) : value
            }))
        }else if(name === "ToxicName"){
            setState(state=>({
                ...state,
                WasteName: "",
                [name]: Array.isArray(value) ? value.slice(1, (value.length)) : value
            }))
        }else{
            setState(state=>({
                ...state,
                [name]: Array.isArray(value) ? value.slice(1, (value.length)) : value
            })) 
        }
    
    };

    const updateDataFromKeyWord = (value) => {
        setState(state=>({
            ...state,
            KeyWord: value
        }))
    }

    const openForceFilterGroup = isForceOpen => {
        setState(state=>({
            ...state,
            forceOpenFilterGroup: isForceOpen
        }))
    }

    const handleClearParams = () => {
        setState(state=>({
            ...state,
            KeyWord: "",
            controlledMaterialType: "",
            location: "",
            city: "",
            parkId: "",
            typeTwo: "",
            typeFour: "",
            WasteName: "",
        }))
        handleClearParamsInMaterialControl() //清掉 parent component 值
    }

    const handlePushToWastedClean = () => {
        history.push("/wasted_clean")
    }

    React.useEffect(()=>{
        passDataHandler(state.data);
    },[state.data]);
    
    React.useEffect(()=>{
        const { data } = props;
        setState(state=>({ ...state, data: data, isClicked: true }))
    },[props.data]);

    React.useEffect(()=>{
        const { loading } = state;
        isLoading(loading);
    },[state.loading]);

    const [searchOpen, setSearchOpen] = useState(false);
    return (
        <Drawer    
            variant="persistent"
            anchor="left"
            open={open}
        >
            <div className="drawer">
                <div className="drawer-header">
                    <HeadContainer>
                        <h2>廢棄物申報概況</h2>
                        <p onClick={handlePushToWastedClean}>廢棄物清運機構</p>
                    </HeadContainer>
                    <p>透過條件搜尋各廠列管污染源相關資料。</p>                     
                </div>
                <div className="drawer-auto-complete">
                    <ControlledMaterilaAutoComplete 
                        toggleDrawer={toggleDrawerHandler} 
                        controlledSearch={fetchControlledMaterialDataHandler}
                        updateData={(name, value)=> {
                            updateKeyWord(value)
                            updateDataFromKeyWord(name, value)
                        }}
                        style={{position:'initial', width:'90%', margin:'5% 0px 0px 5%'}}
                        passDataHandler={()=>{}}
                        // openForceFilterGroup={openForceFilterGroup}
                        keyWord={keyWord}
                    />
                </div>
                <ControlledMaterialFilterGroup 
                    updateData={(name, value)=> updateDataFromFilter(name, value)}
                    ref={ref}
                    controlledSearch={fetchControlledMaterialDataHandler}
                    KeyWord={state.KeyWord}
                    forceOpen={state.forceOpenFilterGroup}
                    handleClearParams={handleClearParams}
                    isDrawerOpen={open}
                    keyWord={keyWord}
                    wastedPollutionOptions={wastedPollutionOptions}
                    toxicPollutionOptions={toxicPollutionOptions}
                    changeSearchOpen={(status) => { setSearchOpen(status) }}
                 />
                <ControlledMaterialResultList totalAmt={state.totalAmt} closePopUpHandler={closePopUpHandler} loading={state.loading} clicked={state.isClicked} data={state.data} onSelectedCompany={onSelectedCompany} forceOpenFilterGroup={state.forceOpenFilterGroup} searchOpen={searchOpen} />
            </div>
        </Drawer>
    )
}

const HeadContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    > h2 {
        padding: 5px 15px;
        font-size: 1.5rem;
        font-weight: 600;
    }


    >p{
        margin-left: 2.5px;
        text-decoration: underline;
        color: ${theme.linkBlue};
        transition: 0.2s;
        cursor: pointer;

        &:hover{
            transition: 0.2s;
            color: ${theme.linkHoveredBlue}
        }
    }
`;