import React, { useState, lazy, Suspense, useEffect } from "react";
import { Collapse, Icon } from "@material-ui/core";
import { Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCrown, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import Skeleton from '@material-ui/lab/Skeleton';
import Swal from "sweetalert2";

import { southArea } from "../../constants/parks";

import "./CustomizedCard.css";
import "./styles.scss";

export default (props) => {
  const { isSimple, toggleCard, isOpen, data, referenceType, loading, noSouthArea, eventHandler } = props;
  const { referenceInfo } = useSelector((state) => state.app);
  const [shouldCardOpen, setShouldCardOpen] = useState(false);
  const parkId = window.location.pathname.split("/")[window.location.pathname.split("/").length -1];
  const currentPage = window.location.pathname.split("/")[window.location.pathname.split("/").length - 2];

  useEffect(() => {
    if (isOpen) {
      setShouldCardOpen(true);
    } else {
      setShouldCardOpen(false);
    }
  }, [isOpen]);

  const toggleCardHandler = () => {
    toggleCard();
    setShouldCardOpen((prvState) => !prvState);
  };

  const openReferenceInfoAlert = (type) => {
    let content = "";
    if (referenceInfo[type] === "") {
      console.log("I was called!");
      Swal.fire({
        title: "引用資料來源",
        text: "資料正在建置中 ... ",
      });
    } else {
      try {
        referenceInfo[type]
          .split("。")
          .map(
            (referenceItem) =>
              (content +=
                "<p style='text-align:left'>" + referenceItem + "</p>")
          );
        Swal.fire({
          title: "引用資料來源",
          html: content,
        });
      } catch (err) {
        Swal.fire({
          title: "引用資料來源",
          text: "資料正在建置中 ... ",
        });
      }
    }
  };

  if (isSimple) {
    return <div className="simple-card">{props.children}</div>;
  } else if (southArea.filter(park => park.ParkId === parkId).length > 0 && currentPage === "park_dashboard" && noSouthArea ) {
    return ''
  }else {
    return (
      <div className="customized-card">
        <Card>
          <Card.Header className="card-title">
            <div>
              <h3>{props.title}</h3>
              {referenceType && (
                <FontAwesomeIcon
                  className="icon-reference"
                  icon={faInfoCircle}
                  onClick={() => openReferenceInfoAlert(referenceType)}
                />
              )}
            </div>
            <button title={shouldCardOpen? `點擊可折疊${props.title}卡片`: `點擊可展開${props.title}卡片`} aria-expanded={shouldCardOpen} style={{ color: "white" }} onClick={toggleCardHandler}>
              <Icon>
                {shouldCardOpen ? "expand_more" : "expand_less"}
              </Icon>
            </button>
          </Card.Header>
          <Collapse in={shouldCardOpen}>
            <Card.Body className="card-body">
            {loading ? 
                <div className="loading-container">
                    <Skeleton variant="text" height={50}/>
                    <Skeleton variant="text" height={50}/>
                    <Skeleton variant="text" height={50}/>
                    <Skeleton variant="text" height={50}/>
                    <Skeleton variant="text" height={50}/>
                    <Skeleton variant="text" height={50}/>
                </div>
              : props.children}
              {data && data.length > 0 && (
                <div className="ranking">
                  {data
                    .filter(
                      (item) => item.label !== "其他" && item.label !== "0"
                    )
                    .sort((a, b) => b.y - a.y)
                    .slice(0, 3)
                    .map((item, index) => (
                      <div className="ranking-item">
                        <div className="ranking-header">
                          <p className="ranking-number">{index + 1}</p>
                          <FontAwesomeIcon className="icon" icon={faCrown} />
                        </div>
                        {eventHandler ? (<button onClick={() => eventHandler(item.tag || item.label, item.label)}>{item.label}</button>) : ( <p>{item.label}</p> )}
                      </div>
                    ))}
                </div>
              )}
            </Card.Body>
          </Collapse>
        </Card>
      </div>
    );
  }
};
