import * as actionTypes from "../actionTypes/weather";

import { handleFetchWeatherBox, handleFetchAirQuality, handleFetchWeatherInfo } from "../../utility/weather_api";

const startFetchWeatherBoxInfo = () => {
  return {
    type: actionTypes.START_FETCH_WEATHER_BOX_DATA,
  };
};

const fetchWeatherBoxInfoSuccess = (data) => {
  
  return {
    type: actionTypes.FETCH_WEATHER_BOX_DATA_SUCCESS,
    data: data.data,
  };
};

const fetchWeatherBoxInfoFail = (err) => {
  return {
    type: actionTypes.FETCH_WEATHER_BOX_DATA_FAIL,
    err: err,
  };
};

export const handleGetWeatherBoxInfo = (park_id) => {
  return (dispatch) => {
    dispatch(startFetchWeatherBoxInfo());
    handleFetchWeatherBox(park_id)
      .then((res) => {
        if (res && res.status === 200) {
          dispatch(fetchWeatherBoxInfoSuccess(res));
        } else {
          dispatch(
            fetchWeatherBoxInfoFail("依照列管編號取得清理種類時發生問題。")
          );
        }
      })
      .catch((err) => {
        dispatch(fetchWeatherBoxInfoFail(err));
      });
  };
};

const startFetchWeatherInfo = (state) => {
  return {
    type: actionTypes.START_FETCH_WEATHER_INFO,
  };
};

const fetchWeatherInfoSuccess = (data) => {
  return {
    type: actionTypes.FETCH_WEATHER_INFO_SUCCESS,
    data: data.data.records.location[0],    
  };
};

const fetchWeatherInfoFail = (state) => {
  return {
    type: actionTypes.FETCH_WEATHER_INFO_FAIL,
  };
};

export const handleGetWeatherInfo = (location) => {
  return (dispatch) => {
    dispatch(startFetchWeatherInfo());
    handleFetchWeatherInfo(location)
      .then((res) => {
        if (res && res.status === 200) {
          dispatch(fetchWeatherInfoSuccess(res));
        } else {
          dispatch(
            fetchWeatherInfoFail("依照列管編號取得清理種類時發生問題。")
          );
        }
      })
      .catch((err) => {
        dispatch(fetchWeatherInfoFail(err));
      });
  };
};

const startFetchAirQuality = (state) => {
  return {
    type: actionTypes.START_FETCH_AIR_QUALITY,
  };
};

const fetchAirQualitySuccess = (data) => {
  return {
    type: actionTypes.FETCH_AIR_QUALITY_SUCCESS,
    data: data,
  };
};

const fetchAirQualityFail = (state) => {
  return {
    type: actionTypes.FETCH_AIR_QUALITY_FAIL,
  };
};

export const handleGetAirQuality = (weather_box_id) => {
  return (dispatch) => {
    dispatch(startFetchAirQuality());
    handleFetchAirQuality(weather_box_id)
      .then((res) => {
        if (res) {
          dispatch(fetchAirQualitySuccess(res));
        } else {
          dispatch(
            fetchAirQualityFail("依照列管編號取得清理種類時發生問題。")
          );
        }
      })
      .catch((err) => {
        dispatch(fetchAirQualityFail(err));
      });
  };
};