import React, { Component } from "react";
import { Card } from "react-bootstrap";
import Icon from "@material-ui/core/Icon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import SwiperCore, { A11y, Navigation } from "swiper";
import Swal from "sweetalert2";
import "swiper/swiper.scss";
import "../../../assests/styles/_swiper-navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/scrollbar/scrollbar.scss";

import CustomizedCard from "../../../components/CustomizedCard/CustomizedCardV3";
import CustomizedGraphic from "../../../components/CustomizedGraphic/CustomizedGraphic";
import CustomizedGraphicV2 from "../../../components/CustomizedGraphic/StockInfo/CustomizedGraphic";
import CustomizedList from "../../../components/CustomizedList/CustomizedList";
import CustomizedTable from "../../../components/CustomizedTable/CustomizedTableV2";
import Error from "../../../components/Error/Error";
import ShowNoData from "../../../components/showNoData/showNoData";

import { NoAuthCard, NoAuthCardText } from "../FactoryInfo/styles";

import "./styles.scss";

SwiperCore.use([A11y, Navigation]);

const Stock = (props) => {
  const tabRef = React.useRef(null);
  const mainMenu = React.useRef(null);
  const subMenu = React.useRef(null);

  const {
    stock,
    StockPhysique,
    tradeResearch,
    tradeResearchLabels,
    tradeRev,
    tradeRevLabels,
    getTradeEPS,
    getTradeEPSLabels,
    //getStockIndex,
    getCompanyStockInfo,
    stockResearch,
    stockResearchLabels,
    stockResearchList,
    stockRevenue,
    stockRevenueLabels,
    stockRevenueList,
    stockMonthRevenue,
    stockMonthRevenueLabels,
    stockMonthRevenueList,
    stockEPS,
    stockEPSLabels,
    stockEPSList,
    stockGrossProfitMargin,
    stockGrossProfitMarginLabels,
    stockGrossProfitMarginList,
    stockOperatingProfitMargin,
    stockOperatingProfitMarginLabels,
    stockOperatingProfitMarginList,
    stockNetIncomeMargin,
    stockNetIncomeMarginLabels,
    stockNetIncomeMarginList,
    stockFreeCashFlow,
    stockFreeCashFlowLabels,
    stockFreeCashFlowList,
    stockROE,
    stockROELabels,
    stockROEList,
    stockDividendYield,
    stockDividendYieldLabels,
    stockDividendYieldList,
    stockNonOperatingIncomeExpenses,
    stockNonOperatingIncomeExpensesLabels,
    stockNonOperatingIncomeExpensesList,
    GHGData,
    GHGDataLabels,
    GHGDataList,
  } = props;

  const swiperRef = React.useRef(null);
  const [state, setState] = React.useState({
    currentSlide: 0,
  });

  const prevSlide = React.useCallback(() => {
    swiperRef.current.swiper.slidePrev();
  }, [swiperRef]);

  const nextSlide = React.useCallback(() => {
    swiperRef.current.swiper.slideNext();
  }, [swiperRef]);

  const [selectedMenu, setSelectedMenu] = React.useState("基本資料");
  const [dropDownIndex, setDropDownIndex] = React.useState("");

  const [trade, settrade] = React.useState();
  const [tradeLabel, settradeLabel] = React.useState();
  const [tradeunit, settradeunit] = React.useState("");
  const [offSetLeft, setOffSetLeft] = React.useState(0);
  const [isDropDownOpen, setIsDropDownOpen] = React.useState(false);

  const [ListInfo, setListInfo] = React.useState([]);

  const changeTabValueHandler = (e, value) => {
    e.stopPropagation();
    setSelectedMenu(value);

    switch (value) {
      case "營業額":
        settrade([stockRevenue, stockMonthRevenue]);
        settradeLabel([stockRevenueLabels, stockMonthRevenueLabels]);
        settradeunit("金額(單位：仟元)");
        setListInfo(stockMonthRevenueList);
        break;
      case "EPS":
        settrade(stockEPS);
        settradeLabel(stockEPSLabels);
        settradeunit("EPS");
        setListInfo(stockEPSList);
        break;
      case "研發投入比例":
        settrade(stockResearch);
        settradeLabel(stockResearchLabels);
        settradeunit("比例(%)");
        setListInfo(stockResearchList);
        break;
      case "營業毛利率":
        settrade(stockGrossProfitMargin);
        settradeLabel(stockGrossProfitMarginLabels);
        settradeunit("比例(%)");
        setListInfo(stockGrossProfitMarginList);
        break;
      case "營業利益率":
        settrade(stockOperatingProfitMargin);
        settradeLabel(stockOperatingProfitMarginLabels);
        settradeunit("比例(%)");
        setListInfo(stockOperatingProfitMarginList);
        break;
      case "稅後淨利率":
        settrade(stockNetIncomeMargin);
        settradeLabel(stockNetIncomeMarginLabels);
        settradeunit("比例(%)");
        setListInfo(stockNetIncomeMarginList);
        break;
      case "業外收入及支出":
        settrade(stockNonOperatingIncomeExpenses);
        settradeLabel(stockNonOperatingIncomeExpensesLabels);
        settradeunit("金額(單位：仟元)");
        setListInfo(stockNonOperatingIncomeExpensesList);
        break;
      case "自由現金流量":
        settrade(stockFreeCashFlow);
        settradeLabel(stockFreeCashFlowLabels);
        settradeunit("金額(單位：仟元)");
        setListInfo(stockFreeCashFlowList);
        break;
      case "ROE":
        settrade(stockROE);
        settradeLabel(stockROELabels);
        settradeunit("ROE");
        setListInfo(stockROEList);
        break;
      case "股利政策":
        settrade(stockDividendYield);
        settradeLabel(stockDividendYieldLabels);
        settradeunit("比例(%)");
        setListInfo(stockDividendYieldList);
        break;
      case "溫室氣體":
        settrade(GHGData);
        settradeLabel(GHGDataLabels);
        settradeunit("(噸CO2e)");
        setListInfo(GHGDataList);
        break;
      default:
        return "Something when wrong when fetching data";
    }
  };

  var PhysiqueEvalPositive = ["正常", "穩定", "大於", "優秀", "好"];

  const openReferenceInfoAlert = (type) => {
    let content = "";
    let referenceContentList = [];
    switch (type) {
      case "公司營運狀態":
        referenceContentList = [
          "查看公司大於三年以上(營業額、EPS、自由現金流)等指標，判斷營運是否健康。",
          "狀態評估標準：",
          "全部皆達成(優秀)",
          "達成至少任何兩項(正常)",
          "只達成一項或全部皆未達成(提醒)",
        ];
        break;
      case "公司基本面":
        referenceContentList = [
          "營業利益 = 營收 - 銷貨成本 - 營業費用",
          "毛利 = 營收 - 銷貨成本",
          "稅後淨利 = 稅前淨利 * (1-所得稅率)",
          "狀態評估標準：",
          "全部皆達成(優秀)",
          "達成至少任何兩項(正常)",
          "只達成一項或全部皆未達成(提醒)",
        ];
        break;
      case "專注本業":
        referenceContentList = [
          "專注本業判斷公司是否依靠本業賺錢，除了查看公司各項營收指標外，專注本業越高代表公司是否依靠本業穩定獲利，而非利用業外收入(投資)平衡獲利。",
          "公式：專注本業=營業利益/稅前淨利*100",
        ];
        break;
      case "ROE":
        referenceContentList = [
          "股東權益報酬率ROE，ROE表示公司運用自有資本的賺錢效率指標，ROE越高，代表公司為股東賺回的獲利越高。",
          "狀態評估標準：",
          "ROE平均近三年大於10(好)",
          "ROE平均近三年小於等於10(稍低)",
        ];
        break;
      case "殖利率區間":
        referenceContentList = [
          "計算近五年公司殖利率平均是否大於4%。",
          "狀態評估標準：",
          "殖利率平均近五年大於4%(好)",
          "殖利率平均近五年小於等於4%(稍低)",
        ];
        break;
      case "同業平均毛利率比較":
        referenceContentList = ["比較公司於該產業的毛利率高低。"];
        break;
      default:
        return "Something went wrong.";
    }

    try {
      referenceContentList.map(
        (referenceItem) =>
          (content += "<p style='text-align:left'>" + referenceItem + "</p>")
      );

      Swal.fire({
        title: type,
        html: content,
      });
    } catch (err) {
      Swal.fire({
        title: "引用資料來源",
        text: "資料正在建置中 ... ",
      });
    }
  };

  const onScrollHandler = () => {
    let offSetLeft;
    if (dropDownIndex === "主要指標") {
      offSetLeft =
        mainMenu.current.offsetWidth - mainMenu.current.scrollLeft - 18;
    } else if (dropDownIndex === "其他指標") {
      offSetLeft =
        mainMenu.current.offsetWidth -
        mainMenu.current.scrollLeft +
        tabRef.current.offsetWidth -
        18;
    }
    setOffSetLeft(offSetLeft);
  };

  const openMainIndexDropDown = (e, value) => {
    let offSetLeft;
    if (value === "主要指標") {
      setDropDownIndex(value);
      // changeTabValueHandler(e, "")
      if (window.innerWidth > 1023) {
        offSetLeft = tabRef.current.offsetWidth * 2;
      } else {
        offSetLeft =
          mainMenu.current.offsetWidth - mainMenu.current.scrollLeft - 36;
      }
    } else if (value === "其他指標") {
      setDropDownIndex(value);
      // changeTabValueHandler(e, "")
      if (window.innerWidth > 1023) {
        offSetLeft = tabRef.current.offsetWidth * 3;
      } else {
        offSetLeft =
          mainMenu.current.offsetWidth -
          mainMenu.current.scrollLeft +
          tabRef.current.offsetWidth -
          36;
      }
    } else {
      setDropDownIndex("");
      changeTabValueHandler(e, value);
      offSetLeft = 0;
    }
    setIsDropDownOpen(true);
    setOffSetLeft(offSetLeft);
  };

  const toggleDropDownMenu = (e, value) => {
    changeTabValueHandler(e, value);
    setIsDropDownOpen(!isDropDownOpen);
  };

  if (
    getCompanyStockInfo.length === 0 ||
    tradeRev.length === 0 ||
    getTradeEPS.length === 0 ||
    tradeResearch.length === 0
  ) {
    return (
      <div className="nodata">
        <NoAuthCard>
          <NoAuthCardText>資料未公開。</NoAuthCardText>
        </NoAuthCard>
      </div>
    );
  } else {
    return (
      <div className="factory-basic-info">
        <div className="stock-menu">
          <div className="main-menu" onScroll={onScrollHandler} ref={mainMenu}>
            <div
              className="main-menu-item"
              style={
                selectedMenu === "基本資料" &&
                  (dropDownIndex !== "其他指標" || dropDownIndex !== "主要指標")
                  ? { background: "#25758A", color: "#fff" }
                  : {}
              }
              ref={tabRef}
              onClick={(e) => openMainIndexDropDown(e, "基本資料")}
            >
              <p>基本資料</p>
            </div>
            <div
              className="main-menu-item"
              style={
                selectedMenu === "公司體質" &&
                  (dropDownIndex !== "其他指標" || dropDownIndex !== "主要指標")
                  ? { background: "#25758A", color: "#fff" }
                  : {}
              }
              onClick={(e) => openMainIndexDropDown(e, "公司體質")}
            >
              <p>公司體質</p>
            </div>
            <div
              className="main-menu-item"
              style={
                dropDownIndex === "主要指標" ||
                  selectedMenu === "營業額" ||
                  selectedMenu === "EPS" ||
                  selectedMenu === "研發投入比例"
                  ? { background: "#25758A", color: "#fff" }
                  : {}
              }
              onClick={(e) => openMainIndexDropDown(e, "主要指標")}
              onMouseEnter={(e) => openMainIndexDropDown(e, "主要指標")}
            // onMouseLeave={(e)=>{setDropDownIndex("")}}
            >
              <p>主要指標</p>
              <Icon>
                {dropDownIndex === "主要指標" && isDropDownOpen
                  ? "arrow_drop_down"
                  : "arrow_drop_up"}
              </Icon>
            </div>
            <div
              className="main-menu-item"
              style={
                dropDownIndex === "其他指標" ||
                  selectedMenu === "營業毛利率" ||
                  selectedMenu === "營業利益率" ||
                  selectedMenu === "業外收入及支出" ||
                  selectedMenu === "稅後淨利率" ||
                  selectedMenu === "自由現金流量" ||
                  selectedMenu === "ROE"
                  ? { background: "#25758A", color: "#fff" }
                  : {}
              }
              onClick={(e) => openMainIndexDropDown(e, "其他指標")}
              onMouseEnter={(e) => openMainIndexDropDown(e, "其他指標")}
            // onMouseLeave={(e)=>{setDropDownIndex("")}}
            >
              <p>其他指標</p>
              <Icon>
                {dropDownIndex === "其他指標" && isDropDownOpen
                  ? "arrow_drop_down"
                  : "arrow_drop_up"}
              </Icon>
            </div>
            <div
              className="main-menu-item"
              style={
                selectedMenu === "股利政策" &&
                  (dropDownIndex !== "其他指標" || dropDownIndex !== "主要指標")
                  ? { background: "#25758A", color: "#fff" }
                  : {}
              }
              onClick={(e) => openMainIndexDropDown(e, "股利政策")}
            >
              <p>股利政策</p>
            </div>
            <div
              className="main-menu-item"
              style={
                selectedMenu === "溫室氣體" &&
                  (dropDownIndex !== "其他指標" || dropDownIndex !== "主要指標")
                  ? { background: "#25758A", color: "#fff" }
                  : {}
              }
              onClick={(e) => openMainIndexDropDown(e, "溫室氣體")}
            >
              <p>溫室氣體</p>
            </div>
          </div>
          {dropDownIndex === "主要指標" && isDropDownOpen && (
            <div
              className={isDropDownOpen ? "sub-menu" : "sub-menu-mini"}
              ref={subMenu}
              style={{ left: `${offSetLeft}px` }}
              onMouseEnter={(e) => openMainIndexDropDown(e, "主要指標")}
              onMouseLeave={(e) => {
                setDropDownIndex("");
              }}
            >
              <div>
                <p
                  onClick={(e) => toggleDropDownMenu(e, "營業額")}
                  style={
                    selectedMenu === "營業額"
                      ? {
                        background: "#284C48",
                        width: `${tabRef.current.offsetWidth}px`,
                      }
                      : { width: `${tabRef.current.offsetWidth}px` }
                  }
                >
                  營業額
                </p>
                <p
                  onClick={(e) => toggleDropDownMenu(e, "EPS")}
                  style={
                    selectedMenu === "EPS"
                      ? {
                        background: "#284C48",
                        width: `${tabRef.current.offsetWidth}px`,
                      }
                      : { width: `${tabRef.current.offsetWidth}px` }
                  }
                >
                  EPS
                </p>
                <p
                  onClick={(e) => toggleDropDownMenu(e, "研發投入比例")}
                  style={
                    selectedMenu === "研發投入比例"
                      ? {
                        background: "#284C48",
                        width: `${tabRef.current.offsetWidth}px`,
                      }
                      : { width: `${tabRef.current.offsetWidth}px` }
                  }
                >
                  研發投入比例
                </p>
              </div>
            </div>
          )}
          {dropDownIndex === "其他指標" && isDropDownOpen && (
            <div
              className={isDropDownOpen ? "sub-menu" : "sub-menu-mini"}
              ref={subMenu}
              style={{ left: `${offSetLeft}px` }}
              onMouseEnter={(e) => openMainIndexDropDown(e, "其他指標")}
              onMouseLeave={(e) => {
                setDropDownIndex("");
              }}
            >
              <div>
                <p
                  onClick={(e) => toggleDropDownMenu(e, "營業毛利率")}
                  style={
                    selectedMenu === "營業毛利率"
                      ? {
                        background: "#284C48",
                        width: `${tabRef.current.offsetWidth}px`,
                      }
                      : { width: `${tabRef.current.offsetWidth}px` }
                  }
                >
                  營業毛利率
                </p>
                <p
                  onClick={(e) => toggleDropDownMenu(e, "營業利益率")}
                  style={
                    selectedMenu === "營業利益率"
                      ? {
                        background: "#284C48",
                        width: `${tabRef.current.offsetWidth}px`,
                      }
                      : { width: `${tabRef.current.offsetWidth}px` }
                  }
                >
                  營業利益率
                </p>
                <p
                  onClick={(e) => toggleDropDownMenu(e, "稅後淨利率")}
                  style={
                    selectedMenu === "稅後淨利率"
                      ? {
                        background: "#284C48",
                        width: `${tabRef.current.offsetWidth}px`,
                      }
                      : { width: `${tabRef.current.offsetWidth}px` }
                  }
                >
                  稅後淨利率
                </p>
                <p
                  onClick={(e) => toggleDropDownMenu(e, "自由現金流量")}
                  style={
                    selectedMenu === "自由現金流量"
                      ? {
                        background: "#284C48",
                        width: `${tabRef.current.offsetWidth}px`,
                      }
                      : { width: `${tabRef.current.offsetWidth}px` }
                  }
                >
                  自由現金流量
                </p>
                <p
                  onClick={(e) => toggleDropDownMenu(e, "業外收入及支出")}
                  style={
                    selectedMenu === "業外收入及支出"
                      ? {
                        background: "#284C48",
                        width: `${tabRef.current.offsetWidth}px`,
                      }
                      : { width: `${tabRef.current.offsetWidth}px` }
                  }
                >
                  業外收入及支出
                </p>
                <p
                  onClick={(e) => toggleDropDownMenu(e, "ROE")}
                  style={
                    selectedMenu === "ROE"
                      ? {
                        background: "#284C48",
                        width: `${tabRef.current.offsetWidth}px`,
                      }
                      : { width: `${tabRef.current.offsetWidth}px` }
                  }
                >
                  ROE
                </p>
              </div>
            </div>
          )}
        </div>

        {selectedMenu === "基本資料" && (
          <Card style={{ marginBottom: "5%" }}>
            <CustomizedCard title="基本資料" referenceType={"factoryInoBasic"}>
              {getCompanyStockInfo === "error" && <Error />}
              {/* {!basic && <Spinner />} */}
              {getCompanyStockInfo !== "error" && (
                <CustomizedList
                  layout={[30, 70]}
                  listData={getCompanyStockInfo}
                  listTitle
                  vertical
                />
              )}
            </CustomizedCard>
          </Card>
        )}

        {selectedMenu === "公司體質" && (
          <div>
            {StockPhysique[0].text.length >= 3 ? (
              <div className="stock-physique">
                <div
                  className={
                    PhysiqueEvalPositive.includes(StockPhysique[0].Eval)
                      ? "stock-physique-other-positive"
                      : "stock-physique-other-negative"
                  }
                >
                  <div>
                    <div className="physique-title">
                      <h2>公司營運狀態</h2>
                      <FontAwesomeIcon
                        className="icon-reference"
                        icon={faQuestionCircle}
                        onClick={() => openReferenceInfoAlert("公司營運狀態")}
                      />
                    </div>
                    <div className="stock-physique-container">
                      <div className="stock-physique-other-detail">
                        <p>
                          {StockPhysique[0].text[0].split("|")[0]}{" "}
                          {StockPhysique[0].text[0].split("|")[1] === "true" ? (
                            <Icon className="icon-pass">check</Icon>
                          ) : (
                            <Icon className="icon-not-pass">clear</Icon>
                          )}
                        </p>{" "}
                        {/*&#9989;check Mark  &#10060;Cross Mark*/}
                        <p>
                          {StockPhysique[0].text[1].split("|")[0]}{" "}
                          {StockPhysique[0].text[1].split("|")[1] === "true" ? (
                            <Icon className="icon-pass">check</Icon>
                          ) : (
                            <Icon className="icon-not-pass">clear</Icon>
                          )}
                        </p>
                        <p>
                          {StockPhysique[0].text[2].split("|")[0]}{" "}
                          {/*自由現金流近五年平均大於0*/}{" "}
                          {StockPhysique[0].text[2].split("|")[1] === "true" ? (
                            <Icon className="icon-pass">check</Icon>
                          ) : (
                            <Icon className="icon-not-pass">clear</Icon>
                          )}
                        </p>
                      </div>
                      <div
                        className={
                          PhysiqueEvalPositive.includes(StockPhysique[0].Eval)
                            ? "stock-physique-other-eval-pass"
                            : "stock-physique-other-eval-not-pass"
                        }
                      >
                        <p>{StockPhysique[0].Eval}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={
                    PhysiqueEvalPositive.includes(StockPhysique[1].Eval)
                      ? "stock-physique-other-positive"
                      : "stock-physique-other-negative"
                  }
                >
                  <div>
                    <div className="physique-title">
                      <h2>公司基本面</h2>
                      <FontAwesomeIcon
                        className="icon-reference"
                        icon={faQuestionCircle}
                        onClick={() => openReferenceInfoAlert("公司基本面")}
                      />
                    </div>
                    <div className="stock-physique-container">
                      <div className="stock-physique-other-detail">
                        <p>
                          {StockPhysique[1].text[0].split("|")[0]}{" "}
                          {StockPhysique[1].text[0].split("|")[1] === "true" ? (
                            <Icon className="icon-pass">check</Icon>
                          ) : (
                            <Icon className="icon-not-pass">clear</Icon>
                          )}
                        </p>
                        <p>
                          {StockPhysique[1].text[1].split("|")[0]}{" "}
                          {StockPhysique[1].text[1].split("|")[1] === "true" ? (
                            <Icon className="icon-pass">check</Icon>
                          ) : (
                            <Icon className="icon-not-pass">clear</Icon>
                          )}
                        </p>
                        <p>
                          {StockPhysique[1].text[2].split("|")[0]}{" "}
                          {StockPhysique[1].text[2].split("|")[1] === "true" ? (
                            <Icon className="icon-pass">check</Icon>
                          ) : (
                            <Icon className="icon-not-pass">clear</Icon>
                          )}
                        </p>
                      </div>
                      <div
                        className={
                          PhysiqueEvalPositive.includes(StockPhysique[1].Eval)
                            ? "stock-physique-other-eval-pass"
                            : "stock-physique-other-eval-not-pass"
                        }
                      >
                        <p>{StockPhysique[1].Eval}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={
                    PhysiqueEvalPositive.includes(StockPhysique[2].Eval)
                      ? "stock-physique-other-positive"
                      : "stock-physique-other-negative"
                  }
                >
                  <div>
                    <div className="physique-title">
                      <h2>專注本業</h2>
                      <FontAwesomeIcon
                        className="icon-reference"
                        icon={faQuestionCircle}
                        onClick={() => openReferenceInfoAlert("專注本業")}
                      />
                    </div>
                    <div className="stock-physique-container">
                      <div className="stock-physique-other-detail">
                        <p className="FocusIndustry">
                          {StockPhysique[2].text[0].split("|")[0]}
                          {StockPhysique[2].Eval === "正常" ? " > " : " < "}
                          80%(本業收入比基準)
                        </p>
                      </div>
                      <div
                        className={
                          PhysiqueEvalPositive.includes(StockPhysique[2].Eval)
                            ? "stock-physique-other-eval-pass"
                            : "stock-physique-other-eval-not-pass"
                        }
                      >
                        <p>{StockPhysique[2].Eval}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={
                    PhysiqueEvalPositive.includes(StockPhysique[3].Eval)
                      ? "stock-physique-other-positive"
                      : "stock-physique-other-negative"
                  }
                >
                  <div>
                    <div className="physique-title">
                      <h2>ROE</h2>
                      <FontAwesomeIcon
                        className="icon-reference"
                        icon={faQuestionCircle}
                        onClick={() => openReferenceInfoAlert("ROE")}
                      />
                    </div>
                    <div className="stock-physique-container">
                      <div className="stock-physique-other-detail">
                        <p className="ROE">
                          {StockPhysique[3].text[1].split("|")[0]}(
                          {StockPhysique[3].text[1].split("|")[1]})
                        </p>
                      </div>
                      <div
                        className={
                          PhysiqueEvalPositive.includes(StockPhysique[3].Eval)
                            ? "stock-physique-other-eval-pass"
                            : "stock-physique-other-eval-not-pass"
                        }
                      >
                        <p>{StockPhysique[3].Eval}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={
                    PhysiqueEvalPositive.includes(StockPhysique[4].Eval)
                      ? "stock-physique-other-positive"
                      : "stock-physique-other-negative"
                  }
                >
                  <div>
                    <div className="physique-title">
                      <h2>殖利率區間</h2>
                      <FontAwesomeIcon
                        className="icon-reference"
                        icon={faQuestionCircle}
                        onClick={() => openReferenceInfoAlert("殖利率區間")}
                      />
                    </div>
                    <div className="stock-physique-container">
                      <div className="stock-physique-other-detail">
                        <p className="DividendYield">
                          {StockPhysique[4].text[0]}
                        </p>
                      </div>
                      <div
                        className={
                          PhysiqueEvalPositive.includes(StockPhysique[4].Eval)
                            ? "stock-physique-other-eval-pass"
                            : "stock-physique-other-eval-not-pass"
                        }
                      >
                        <p>{StockPhysique[4].Eval}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={
                    PhysiqueEvalPositive.includes(StockPhysique[5].Eval)
                      ? "stock-physique-other-positive"
                      : "stock-physique-other-negative"
                  }
                >
                  <div>
                    <div className="physique-title">
                      <h2>同業平均毛利率比較</h2>
                      <FontAwesomeIcon
                        className="icon-reference"
                        icon={faQuestionCircle}
                        onClick={() =>
                          openReferenceInfoAlert("同業平均毛利率比較")
                        }
                      />
                    </div>
                    <div className="stock-physique-container">
                      <div className="stock-physique-other-detail">
                        <p className="sameIndustryProfit">
                          {StockPhysique[5].text[0].split("|")[0]}%
                          {StockPhysique[5].Eval === "大於" ? " > " : " < "}
                          {StockPhysique[5].text[1].split("|")[0]}%(
                          {getCompanyStockInfo[0].股市產業分類}平均){" "}
                        </p>
                      </div>
                      <div
                        className={
                          PhysiqueEvalPositive.includes(StockPhysique[5].Eval)
                            ? "stock-physique-other-eval-pass"
                            : "stock-physique-other-eval-not-pass"
                        }
                      >
                        <p>{StockPhysique[5].Eval}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <p>說明：指標僅供客觀了解公司營運狀況參考用，不代表任何立場</p>
              </div>
            ) : (
              <div className="nodata">
                <Card style={{ padding: "16px" }}>
                  <p>公司資料蒐集中...(該公司當前屬未滿五年新上市櫃公司)。</p>
                </Card>
              </div>
            )}
          </div>
        )}

        {selectedMenu !== "基本資料" && selectedMenu !== "公司體質" && selectedMenu !== "溫室氣體" && (
          <div>
            <Card style={{ marginBottom: "2%" }}>
              <CustomizedCard title={(selectedMenu == '營業額' ? "年" + selectedMenu : selectedMenu) + "趨勢圖"}>
                <div className="stock-inner-wrapper">
                  <div>
                    {/* <h5 style={{textAlign: "center",  marginTop: "2%" }}>
                      
                    </h5> */}
                    {tradeRev === "error" && <Error />}
                    {tradeRev && tradeRev.length === 0 && <ShowNoData />}
                    {tradeRev !== "error" && tradeRev.length !== 0 && (
                      <CustomizedGraphicV2
                        data={selectedMenu == '營業額' ? trade[0] : trade}
                        unit={tradeunit}
                        chartType="curve-chart"
                        fixedWidth="100%"
                        xaxis={["category", selectedMenu == '營業額' ? tradeLabel[0] : tradeLabel]}
                        height="300"
                      />
                    )}
                  </div>
                </div>
              </CustomizedCard>
            </Card>
            {selectedMenu == '營業額' &&
              <Card style={{ marginBottom: "2%" }}>
                <CustomizedCard title={"月" + selectedMenu + "趨勢圖"}>
                  <div className="stock-inner-wrapper">
                    <div>
                      {/* <h5 style={{textAlign: "center",  marginTop: "2%" }}>
                        
                      </h5> */}
                      {tradeRev === "error" && <Error />}
                      {tradeRev && tradeRev.length === 0 && <ShowNoData />}
                      {tradeRev !== "error" && tradeRev.length !== 0 && (
                        <CustomizedGraphicV2
                          data={trade[1]}
                          unit={tradeunit}
                          chartType="curve-chart"
                          fixedWidth="100%"
                          xaxis={["category", tradeLabel[1]]}
                          height="300"
                          skip="1"
                        />
                      )}
                    </div>
                  </div>
                </CustomizedCard>
              </Card>
            }
            <Card>
              <CustomizedCard
                title={
                  selectedMenu +
                  "詳細資料" +
                  (((selectedMenu !== "股利政策") && selectedMenu !== "營業額") ? "(累積)" : "")
                }
                referenceType={"factoryInfoStockDetail"}
              >
                {stock === "error" && <Error />}
                {stock !== "error" && (
                  <CustomizedTable
                    headerList={["年", "季", tradeunit]}
                    data={ListInfo}
                    isStock
                    selectedMenu={selectedMenu}
                  />
                )}
              </CustomizedCard>
            </Card>
          </div>
        )}
        {selectedMenu === "溫室氣體" && (
          <div>
            <Card>
              <CustomizedCard
                title="範疇一"
                isGHG
                referenceType={"factoryInfoGHGData"}
              >
                {stock === "error" && <Error />}
                {stock !== "error" && (
                  <CustomizedTable
                    headerList={["年份", "資料邊界", "取得驗證", "排放量(噸CO2e)"]}
                    data={ListInfo}
                    isStock
                    isGHG
                    selectedMenu={selectedMenu}
                    GHGNum={1}
                  />
                )}
              </CustomizedCard>
            </Card>
            <Card>
              <CustomizedCard
                title="範疇二"
                referenceType={"factoryInfoGHGData"}
              >
                {stock === "error" && <Error />}
                {stock !== "error" && (
                  <CustomizedTable
                    headerList={["年份", "資料邊界", "取得驗證", "排放量(噸CO2e)"]}
                    data={ListInfo}
                    isStock
                    isGHG
                    selectedMenu={selectedMenu}
                    GHGNum={2}
                  />
                )}
              </CustomizedCard>
            </Card>
            <Card>
              <CustomizedCard
                title="範疇三"
                referenceType={"factoryInfoGHGData"}
              >
                {stock === "error" && <Error />}
                {stock !== "error" && (
                  <CustomizedTable
                    headerList={["年份", "資料邊界", "取得驗證", "排放量(噸CO2e)"]}
                    data={ListInfo}
                    isStock
                    isGHG
                    selectedMenu={selectedMenu}
                    GHGNum={3}
                  />
                )}
              </CustomizedCard>
            </Card>
            <Card>
              <CustomizedCard
                title="溫室氣體排放密集度"
                referenceType={"factoryInfoGHGData"}
              >
                {stock === "error" && <Error />}
                {stock !== "error" && (
                  <CustomizedTable
                    headerList={["年份", "密集度(噸CO2e/單位)", "單位"]}
                    data={ListInfo}
                    isStock
                    isGHG
                    selectedMenu={selectedMenu}
                    GHGNum={4}
                  />
                )}
              </CustomizedCard>
            </Card>
          </div>
        )}
      </div>
    );
  }
};

export default Stock;