import React from "react";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CircularProgress from "@mui/material/CircularProgress";
import * as Style from "./styles";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const TaxSelect = (props) => {
  const { label, name, dataList, loading, handleChange, state } = props;
  
  // const filterOptions = createFilterOptions({
  //   matchFrom: 'any',
  //   // limit: name=="taxCode"?200:2000,
  //   limit: 250,
  // });

  return (
    <Style.Form>
      <Autocomplete
        multiple
        onChange={handleChange}
        id={name}
        options={dataList}
        limitTags={1}
        disableCloseOnSelect
        // filterOptions={filterOptions}
        getOptionLabel={(option) =>
          (
            (option.Code && option.IndustryName)//稅籍代碼判斷
            ?`${option.Code}${option.IndustryName}`
            :(option.Code && option.Name)//工廠四碼判斷
            ?`${option.Code}${option.Name}`
            :(option.Key && option.Value)//出口產品四碼判斷
            ?`${option.Key}${option.Value}`
            :option.CityName//縣市判斷
          )
        }
        // getOptionDisabled={(option) =>
        //   state?.length > 4
        //     ? option !== state[0] || option !== state[1] || option !== state[2]|| option !== state[3]|| option !== state[4]
        //     : null
        // }
        // filterOptions={filterOptions}
        loading={loading}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option.Code || option.Key}{" "}
            {option.Name ||
              option.IndustryName ||
              option.CityName ||
              option.Value}
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={5} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    </Style.Form>
  );
};

export default TaxSelect;
