import axios from 'axios';
import { Base64 } from 'js-base64'
import { baseUrl, loginbaseUrl } from "../constants/endpoints";

const headers =  {
  'Access-Control-Allow-Origin': '*',
  'Access-Control-Allow-Methods': 'GET,HEAD,OPTIONS,POST,PUT',
  'Access-Control-Allow-Headers': 'Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers',
  'Content-Type': 'application/json',
  'Authorization': localStorage.getItem('acToken')
}


export const fetchSupplyChainMapData = (data) => {
    return axios({
      proxy: true,
      method: "post",
      url: `${baseUrl}/api/IndustryStream/Search`,
      data: data
    })
    .then((res) => {
      return res.data.data;
    })
    .catch((err) => {
      return err;
    });
} 


export const fetchSupplyChainItem = () => {
  return axios({
    proxy: true,
    method: "get",
    url: `https://mirdc-system-streamitem-default-rtdb.firebaseio.com/options.json`,
  })
  .then((res) => {
    return res;
  })
  .catch((err) => {
    return err;
  });
}
  