import axios from 'axios';
import axiosInstance from "./axiosWithRefreshToken"
import { Base64 } from 'js-base64'
import { baseUrl, loginbaseUrl } from "../constants/endpoints";


const headers =  {
  'Access-Control-Allow-Origin': '*',
  'Access-Control-Allow-Methods': 'GET,HEAD,OPTIONS,POST,PUT',
  'Access-Control-Allow-Headers': 'Access-Control-Allow-Headers, Origin, Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers',
  'Content-Type': 'application/json',
  'Authorization': localStorage.getItem('acToken')
};
const InduParkWebAcTokenheaders = {
  "Access-Control-Allow-Origin": "*",
  "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
  "Access-Control-Allow-Headers":
    "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers",
  "Content-Type": "application/json",
  Authorization: localStorage.getItem("InduParkWebAcToken"),
};

//取金屬價格
export const getMetalPrice = (metalVal) => {
  metalVal = (typeof metalVal !== 'undefined') ? metalVal : 'ADCS';
  return axiosInstance({
    // proxy: true,
    method: "get",
    url: `${baseUrl}/api/Metal/Get_anue_Metal_Data/${metalVal}`,
    headers: InduParkWebAcTokenheaders,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return [];
    });
};

//取金屬列表
export const getMetalOptionList = () => {
  return axiosInstance({
    // proxy: true,
    method: "get",
    url: `${baseUrl}/api/Metal/Get_anue_Metal_Options`,
    headers: InduParkWebAcTokenheaders,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return [];
    });
};

export const fetchParkMainIndexList = (area) => {
  return axios({
    method: "get",
    url:`${baseUrl}/api/InduParkArea/GetParkMainIndexList/${area}`,
    headers: headers
  })
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return "error";
    });
};  

export const fetchFactoryAreaRentData = (area) => {
    return axios({
      method: "get",
      url:`${baseUrl}/api/InduParkArea/GetFactoryAreaRent/${area}`,
      headers: headers
    })
      .then(res => {
        return res.data;
      })
      .catch(err => {
        return "error";
      });
  };