import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import labor from "../../assests/industrialFactory02.png";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import QuestionContainerCard from "./QuestionContainerCard";

import * as Style from "./styles";

export default (props) => {
  const {
    result,
    UploadQuestionData,
    prevSlide,
    nextSlide,
    updateTypeList,
    selectTwo,
  } = props;
  const { getVisitQuestionDetail } = useSelector((state) => state.question);
  const location = useLocation();

  const [state, setState] = React.useState({
    isFinished: false,
    isPhoneInterview: false,
  });

  const data = ["主動拜訪", "廠商需求", "會同前往", "首長拜訪"];

  //讀取編輯資料
  React.useEffect(async () => {
    if (getVisitQuestionDetail.Qid && location.state) {
      var temp = "";
      [getVisitQuestionDetail].map((item) => {
        temp = item.VisitReason;
        result[5].phoneInterview = item.PhoneInterview;
        setState((state) => ({
          ...state,
          isPhoneInterview: item.PhoneInterview,
        }));
      });
      await data.map((i, index) => {
        if (i === temp) {
          result[1].stepTwoSelected = index;
        }
      });
      result[1].stepTwoData = temp;
    }
  }, [getVisitQuestionDetail]);

  //判斷此step是否填完資料
  React.useEffect(() => {
    if (selectTwo !== undefined && selectTwo !== "") {
      setState((state) => ({
        ...state,
        isFinished: true,
      }));
    } else {
      setState((state) => ({
        ...state,
        isFinished: false,
      }));
    }
  }, [selectTwo]);

  //判斷是否為電話訪問
  const handleToggleIsPhoneInterview = () => {
    result[5].phoneInterview = !state.isPhoneInterview;

    setState((state) => ({
      ...state,
      isPhoneInterview: !state.isPhoneInterview,
    }));
  };

  return (
    <QuestionContainerCard
      title={"訪談事由"}
      src={labor}
      prevSlide={prevSlide}
      nextSlide={nextSlide}
      isFinished={state.isFinished}
      result={result}
      isIndexOne={true}
      UploadQuestionData={UploadQuestionData}
    >
      <Style.BasicQuestionRightContainer>
        <Style.QuestionGridContainer>
          <Style.QuestionGridView isTwoCol>
            {data.map((item, index) => (
              <Style.QuestionGridItem
                key={item + index.toString()}
                onClick={() => updateTypeList(item, index)}
                isActive={selectTwo === index}
              >
                <Style.QuestionGridItemText>{item}</Style.QuestionGridItemText>
              </Style.QuestionGridItem>
            ))}
          </Style.QuestionGridView>
          <Style.PhonInterviewContainer>
            <FormControlLabel
              onClick={handleToggleIsPhoneInterview}
              control={
                <Checkbox checked={state.isPhoneInterview} name="gilad" />
              }
              label={`電話訪談請打勾。`}
            />
          </Style.PhonInterviewContainer>
        </Style.QuestionGridContainer>
      </Style.BasicQuestionRightContainer>
    </QuestionContainerCard>
  );
};