import React from 'react'

import './Error.css'

const ShowNoData = props => {
    

    return(
        <div className='error-container'>本項目無資料</div>
    )
}


export default  ShowNoData