import axios from "axios";
import { Base64 } from "js-base64";
import { baseUrl, loginbaseUrl } from "../../constants/endpoints";

const headers = {
  "Access-Control-Allow-Origin": "*",
  "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
  "Access-Control-Allow-Headers":
    "Access-Control-Allow-Headers, Origin, Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers",
  "Content-Type": "application/json",
  Authorization: localStorage.getItem("acToken"),
};

const type = "InduParkArea";
//總覽

//熱門議題
export const fetchMainIndex = (city) => {
  return axios({
    method: "get",
    url: `${baseUrl}/api/${type}/GetMainIndex/${city}`,
    headers: headers,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

export const fetchNewsInfo = (city, page, perPage) => {
  return axios({
    method: "get",
    url: `${baseUrl}/api/${type}/GetNewsItems/${city}`,
    headers: headers,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

export const fetchNewsTagsInfo = (city) => {
  return axios({
    method: "get",
    url: `${baseUrl}}/api/${type}/GetNewsTagCloud/${city}`,
    headers: headers,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

//產業形貌
export const fetchTypeTwoData = (city) => {
  return axios({
    method: "get",
    url: `${baseUrl}/api/${type}/GetPieChartByInduTypeTwo_CanvasJS/${city}`,
    headers: headers,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

export const fetchTypeThreeData = (city) => {
  return axios({
    method: "get",
    url: `${baseUrl}/api/${type}/GetPieChartByInduTypeThree_CanvasJS/${city}`,
    headers: headers,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

export const fetchTypeFourData = (city) => {
  return axios({
    method: "get",
    url: `${baseUrl}/api/${type}/GetPieChartByInduTypeFour_CanvasJS/${city}`,
    headers: headers,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

//指標排行

export const fetchPublicStockData = (city) => {
  return axios({
    method: "get",
    url: `${baseUrl}/api/${type}/GetPieChartByInduParkStockType_CanvasJS/${city}`,
    headers: headers,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

export const fetchNotPublicStockData = (city) => {
  return axios({
    method: "get",
    url: `${baseUrl}/api/${type}/GetPieChartByStockType_CanvasJS/${city}`,
    headers: headers,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

export const fetchPublicStockRatingData = (city) => {
  return axios({
    method: "get",
    url: `${baseUrl}/api/${type}/GetCompanyList_Stock/1/${city}`,
    headers: headers,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

export const fetchNoPublicStockRatingData = (city) => {
  return axios({
    method: "get",
    url: `${baseUrl}/api/${type}/GetCompanyList_Stock/2/${city}`,
    headers: headers,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

export const fetchTopTenEmployeeNumberData = (city) => {
  return axios({
    method: "get",
    url: `${baseUrl}/api/${type}/GetColumnChartByInduParkEmpCount_CanvasJS/${city}`,
    headers: headers,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

export const fetchTopTenCapitalData = (city) => {
  return axios({
    method: "get",
    url: `${baseUrl}/api/${type}/GetColumnChartByInduParkCapital_CanvasJS/${city}`,
    headers: headers,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

export const fetchTopGovPlanData = (city) => {
  return axios({
    method: "get",
    url: `${baseUrl}/api/${type}/GetColumnChartByInduParkGovPlan_CanvasJS/${city}`,
    headers: headers,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

export const fetchTopPatentData = (city) => {
  return axios({
    method: "get",
    url: `${baseUrl}/api/${type}/GetColumnChartByInduParkPatent_CanvasJS/${city}`,
    headers: headers,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

export const fetchImportPercentageData = (city) => {
  return axios({
    method: "get",
    url: `${baseUrl}/api/${type}/GetPieChartByInParkForeignTrade_CanvasJS/${city}/true`,
    headers: headers,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

export const fetchExportPercentageData = (city) => {
  return axios({
    method: "get",
    url: `${baseUrl}/api/${type}/GetPieChartByInParkForeignTrade_CanvasJS/${city}/false`,
    headers: headers,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

export const fetchPublicStockPercentageData = (city) => {
  return axios({
    method: "get",
    url: `${baseUrl}/api/${type}/GetPieChartByGovPlan_CanvasJS/${city}`,
    headers: headers,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

export const fetchGovPlanListPercentageData = (city) => {
  return axios({
    method: "get",
    url: `${baseUrl}/api/${type}/GetPieChartByInduParkGovPlan_CanvasJS/${city}`,
    headers: headers,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

export const fetchAwardListData = (city) => {
  return axios({
    method: "get",
    url: `${baseUrl}/api/${type}/GetPieChartByAward_CanvasJS/${city}`,
    headers: headers,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

export const fetchAwardPercentageData = (city) => {
  return axios({
    method: "get",
    url: `${baseUrl}/api/${type}/GetPieChartByInduParkAward_CanvasJS/${city}`,
    headers: headers,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

export const fetchEmployeeData = (city) => {
  return axios({
    method: "get",
    url: `${baseUrl}/api/${type}/GetColumnChartByInduParkEmpCount_CanvasJS/${city}`,
    headers: headers,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

export const fetchNewsInfoTagData = (city) => {
  return axios({
    method: "get",
    url: `${baseUrl}/api/${type}/GetNewsTagCloud/${city}`,
    headers: headers,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

export const fetchGreenFactoryData = (city) => {
  return axios({
    method: "get",
    url: `${baseUrl}/api/${type}/GetTopCompany_Green/${city}`,
    headers: headers,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

export const fetchFactoryRanking = (city) => {
  const form = new FormData();
  form.append("ParkIDList", city);
  form.append("MinCapital", 20000000);
  form.append("MaxCapital", 1000000000);
  form.append("MinTradeExport", 4000000);
  form.append("GrowthThisYear", true);

  return axios({
    method: "post",
    url: `${baseUrl}/api/PotentialSearch/SearchParkRange`,
    headers: headers,
    data: form,
  })
    .then((res) => {
      return res.data.data;
    })
    .catch((err) => {
      return err;
    });
};

//追朔相關
export const fetchTypeTwoList = (city, code) => {
  return axios({
    method: "get",
    url: `${baseUrl}/api/${type}/GetCompanyList_InduTypeTwo/${city}/${code}`,
    headers: headers,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

export const fetchTypeThreeList = (city, code) => {
  return axios({
    method: "get",
    url: `${baseUrl}/api/${type}/GetCompanyList_InduTypeThree/${city}/${code}`,
    headers: headers,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

export const fetchTypeFourList = (city, code) => {
  return axios({
    method: "get",
    url: `${baseUrl}/api/${type}/GetCompanyList_InduTypeFour/${city}/${code}`,
    headers: headers,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};
export const fetchImportList = (city, code) => {
  return axios({
    method: "get",
    url: `${baseUrl}/api/${type}/GetCompanyList_ForeignTrade/${city}/true/${code}`,
    headers: headers,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

export const fetchExportList = (city, code) => {
  return axios({
    method: "get",
    url: `${baseUrl}/api/${type}/GetCompanyList_ForeignTrade/${city}/false/${code}`,
    headers: headers,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

export const fetchGovPlanList = (city, code) => {
  return axios({
    method: "get",
    url: `${baseUrl}/api/${type}/GetCompanyListByInduParkGovPlanOrAward/${city}/${Base64.encode(
      code
    )
      .replace(/[+]/g, "-")
      .replace(/[/]/g, "_")}/false`,
    headers: headers,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

export const fetchAwardList = (city, code) => {
  return axios({
    method: "get",
    url: `${baseUrl}/api/${type}/GetCompanyListByInduParkGovPlanOrAward/${city}/${Base64.encode(
      code
    )
      .replace(/[+]/g, "-")
      .replace(/[/]/g, "_")}/true`,
    headers: headers,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

export const fetchPatentList = (businessId, year) => {
  return axios({
    method: "get",
    url: `${baseUrl}/api/Patent/GetPatents/${businessId}/${year}`,
    headers: headers,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return "error";
    });
};

export const fetchPublicDataList = (city, stockType) => {
  return axios({
    method: "get",
    url: `${baseUrl}/api/${type}/GetCompanyList_Stock/${city}/${stockType}`,
    headers: headers,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return "error";
    });
};

export const fetchTopTenGovPlanDataList = (businessNo) => {
  return axios({
    method: "get",
    url: `${baseUrl}/api/GovPlan/GetGovPlans/${businessNo}`,
    headers: headers,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return "error";
    });
};

export const fetchTopFiveWasteDisposalData = (city) => {
  return axios({
    method: "get",
    url: `${baseUrl}/api/InduParkArea/GetPollutionRank/Waste/${city}`,
    headers: headers,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return "error";
    });
};

export const fetchTopFiveWasteDisposalDataList = (wasteNumber, city) => {
  return axios({
    method: "get",
    url: `${baseUrl}/api/InduParkArea/GetPollutionRankList/Waste/${wasteNumber}/${city}`,
    headers: headers,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return "error";
    });
};
