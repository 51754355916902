import axios from "axios";
import { baseUrl } from "../constants/endpoints";

const headers = {
  "Access-Control-Allow-Origin": "*",
  "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
  "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers",
  "Content-Type": "application/json",
  Authorization: localStorage.getItem("acToken"),
};

//取得智慧櫥窗
export const getSmartWindowtData = () => {
  return axios({
    proxy: true,
    method: "get",
    url: `${baseUrl}/api/InduPark/GetManageParkStat`,
    headers: headers,
  })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};
