import React from "react";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import Radio from "@material-ui/core/Radio";
import QuestionContainerCard from "./QuestionContainerCard";
import labor from "../../assests/industrialFactory04.png";
import FormLabel from "@material-ui/core/FormLabel";

import * as Style from "./styles";

export default ({
  result,
  prevSlide,
  nextSlide,
  stepFive,
  goToStepThree,
  UploadQuestionData,
  ECOQuestion,
}) => {
  const [state, setState] = React.useState({
    titleId: [],
    tempResult: [],
    isAnswered: [],
    followStep4: [],
    isFinished: false,
  });

  React.useEffect(() => {
    if (stepFive) {
      stepFive.map((item) => {
        // console.log(item)
        if (ECOQuestion) {
          if(item.CheckAns === undefined){
            item.CheckAns = true;
          }
          if(item.TextArea === undefined){
            item.TextArea = "";
          }
        }
      });
    }
  }, [stepFive]);
  
  React.useEffect(() => {
    const tempTitle = [];
    const tempTitleId = [];
    if (stepFive) {
      stepFive.map((item) => {
        if (tempTitle.indexOf(item.TypeChildName) === -1) {
          tempTitle.push(item.TypeChildName);
          tempTitleId.push(item);
        }
        if (
          item.CheckAns &&
          (item.CheckAns === true || item.TextArea !== "") &&
          state.isAnswered.indexOf(item.TopicId) === -1
        ) {
          state.isAnswered.push(item.TopicId);
        }
      });

      if (result[3].followStep4) {
        tempTitleId.map((i) => {
          result[3].followStep4.map((item) => {
            if (i.TopicId === item) {
              i.followChecked = true;
            }
          });
        });
      }

      setState((state) => ({
        ...state,
        titleId: tempTitleId,
        tempResult: stepFive,
        followStep4: result[3].followStep4,
      }));
    }
  }, [stepFive, stepFive.length, result[3].followStep4.length]);

  const handleChange = (e, index, type, ECO) => {
    const tempData = [...stepFive];

    tempData.map((i, itemIndex) => {
      if (itemIndex === index && type === "textInput") {
        i.TextArea = e.target.value;

        if (ECO && index === 0) {
          result[4].controlNum = e.target.value;
        }
        if (ECO && index === 1) {
          result[4].Inspector = e.target.value;
        }
        if (state.isAnswered.indexOf(i.TopicId) === -1) {
          state.isAnswered.push(i.TopicId);
        } else if (i.TextArea === "") {
          state.isAnswered = state.isAnswered.filter(
            (item) => item !== i.TopicId
          );
        }
      } else if (itemIndex === index && type === "radio") {
        i.CheckAns = e.target.value === "true";
        if (state.isAnswered.indexOf(i.TopicId) === -1) {
          state.isAnswered.push(i.TopicId);
        }
      } else if (itemIndex === index && !type) {
        i.CheckAns = e.target.checked;
        i.TextArea = e.target.value;
        if (state.isAnswered.indexOf(i.TopicId) === -1) {
          state.isAnswered.push(i.TopicId);
        } else if (i.CheckAns === false && i.TextArea === "") {
          state.isAnswered = state.isAnswered.filter(
            (item) => item !== i.TopicId
          );
        }
      }
    });
    setState((state) => ({
      ...state,
      tempResult: tempData,
    }));
  };

  const handleFollowStep4 = (e, index) => {
    var tempFollow = [...result[3].followStep4];

    if (tempFollow.indexOf(index) === -1 && e.target.checked === true) {
      tempFollow.push(index);
    } else if (e.target.checked === false) {
      tempFollow = tempFollow?.filter((i) => i !== index);
    }

    state.titleId.map((i) => {
      if (i.TopicId === index && e.target.checked === true) {
        i.followChecked = true;
      } else if (i.TopicId === index && e.target.checked === false) {
        i.followChecked = false;
      }
    });

    setState((state) => ({
      ...state,
      followStep4: tempFollow,
    }));

    result[3].followStep4 = tempFollow;
  };

  React.useEffect(() => {
    if (state.tempResult?.length > 0) {
      setState((state) => ({
        ...state,
        isFinished: true,
      }));
    } else {
      setState((state) => ({
        ...state,
        isFinished: false,
      }));
    }
  }, [state.tempResult?.length]);

  return (
    <QuestionContainerCard
      title={"洽商事由"}
      src={labor}
      prevSlide={prevSlide}
      nextSlide={nextSlide}
      goToStepThree={goToStepThree}
      isFinished={state.isFinished}
      isIndexFour={true}
      result={result}
      stepFiveChecked={
        result[4].controlNum !== "" && result[4].Inspector !== "" ? true : false
      }
      UploadQuestionData={UploadQuestionData}
    >
      <Style.BasicQuestionRightContainer>
        <Style.QuestionGridContainer>
          <Style.QuestionGridView column={true}>
            {state.titleId.map((title, index) => {
              return (
                <Style.EachQuestionContainer key={"SubItem" + index.toString()}>
                  <Style.FlexRow>
                    <Style.EachQuestionContainerTitle>
                      {title.TypeChildName}
                    </Style.EachQuestionContainerTitle>
                    <FormControlLabel
                      onClick={(e) => handleFollowStep4(e, title.TopicId)}
                      checked={title.followChecked}
                      control={<Checkbox name="gilad" />}
                      label={`持續追蹤。`}
                      style={{
                        margin: "-10px 5px 0px",
                        marginLeft: window.innerWidth <= 425 ? "-10px" : "5px",
                      }}
                    />
                  </Style.FlexRow>
                  <Style.EachQuestionContentContainer>
                    {state.tempResult?.length !== 0 ? (
                      state.tempResult?.map((sub, index) => {
                        let questionItem;
                        if (
                          sub.QuestionTypeName === "checkbox" &&
                          sub.TypeChildName === title.TypeChildName &&
                          !ECOQuestion
                        ) {
                          questionItem = (
                            <Style.ControlItems>
                              <FormControlLabel
                                control={<Checkbox name="gilad" />}
                                label={sub.Content}
                                checked={sub.CheckAns || false}
                                onChange={(e) => handleChange(e, index)}
                              />
                              {sub.CheckAns === true && (
                                <Style.ControlTextArea>
                                  <Style.ControlTextFiled
                                    onChange={(e) =>
                                      handleChange(e, index, "textInput", true)
                                    }
                                    value={sub.TextArea || ""}
                                  />
                                </Style.ControlTextArea>
                              )}
                            </Style.ControlItems>
                          );
                        } else if (
                          sub.QuestionTypeName === "textarea" &&
                          sub.TypeChildName === title.TypeChildName &&
                          !ECOQuestion
                        ) {
                          questionItem = (
                            <React.Fragment>
                              <TextField
                                multiline
                                id="outlined-multiline-static"
                                rows="5"
                                variant="outlined"
                                style={
                                  window.innerWidth <= 1024
                                    ? { width: "100%", lineHeight: "30px" }
                                    : { width: "80%", lineHeight: "30px" }
                                }
                                value={sub.TextArea || ""}
                                onChange={(e) =>
                                  handleChange(e, index, "textInput")
                                }
                              />
                            </React.Fragment>
                          );
                        }
                        //為環保問卷且題型為textarea
                        else if (
                          ECOQuestion &&
                          sub.QuestionTypeName === "textarea"
                        ) {
                          questionItem = (
                            <Style.ControlItems>
                              <Style.FlexRow>
                                <FormLabel
                                  style={{ color: "black", margin: "2% 0%" }}
                                >
                                  (*必填) {sub.Content}：
                                </FormLabel>
                                <Style.ControlTextArea>
                                  <Style.ControlTextFiled
                                    onChange={(e) =>
                                      handleChange(e, index, "textInput", true)
                                    }
                                    value={sub.TextArea || ""}
                                  />
                                </Style.ControlTextArea>
                              </Style.FlexRow>
                            </Style.ControlItems>
                          );
                        }
                        //為環保問卷且題型為checkbox
                        else if (
                          ECOQuestion &&
                          sub.QuestionTypeName === "checkbox"
                        ) {
                          questionItem = (
                            <Style.ControlItems>
                              <FormControl>
                                <FormLabel
                                  style={{
                                    color: "black",
                                    margin: "2% 0%",
                                    lineHeight: "35px",
                                  }}
                                >
                                  {index - 1} . {sub.Content}
                                </FormLabel>
                                <Style.ControlRadioGroup>
                                  <FormControlLabel
                                    value="true"
                                    control={<Radio />}
                                    checked={
                                      sub.CheckAns !== undefined
                                        ? sub.CheckAns === true
                                          ? true
                                          : false
                                        : true
                                    }
                                    label={index === 3 ? "無" : "是"}
                                    onChange={(e) =>
                                      handleChange(e, index, "radio")
                                    }
                                  />

                                  <Style.FlexRow>
                                    <FormControlLabel
                                      value="false"
                                      control={<Radio />}
                                      checked={sub.CheckAns === false && true}
                                      label={
                                        index === 2 || index === 3
                                          ? "異常"
                                          : "否"
                                      }
                                      onChange={(e) =>
                                        handleChange(e, index, "radio")
                                      }
                                    />
                                    {sub.CheckAns === false && index !== 4 && (
                                      <Style.ControlTextArea>
                                        <p>，原因： </p>
                                        <Style.ControlTextFiled
                                          onChange={(e) =>
                                            handleChange(e, index, "textInput")
                                          }
                                          value={sub.TextArea || ""}
                                        />
                                      </Style.ControlTextArea>
                                    )}
                                  </Style.FlexRow>
                                </Style.ControlRadioGroup>
                              </FormControl>
                            </Style.ControlItems>
                          );
                        }
                        return (
                          <FormControl
                            className="question-container-card-right-content-grid-formcontrol"
                            key={"stepFive" + index.toString()}
                          >
                            {questionItem}
                          </FormControl>
                        );
                      })
                    ) : (
                      <Style.NoData>本項目無相關題項。</Style.NoData>
                    )}
                  </Style.EachQuestionContentContainer>
                </Style.EachQuestionContainer>
              );
            })}
          </Style.QuestionGridView>
        </Style.QuestionGridContainer>
      </Style.BasicQuestionRightContainer>
    </QuestionContainerCard>
  );
};
