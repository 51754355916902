import React from "react";
import styled from "styled-components";

export default () => {
  return (
    <TermsWrapper>
      <h2>個人資料蒐集、處理及利用告知暨同意書</h2>
      <p>
        財團法人金屬工業研究發展中心(以下簡稱本中心)為落實個人資料之保護，茲依據個人資料保護法(以下稱個資法)第
        8 條規定告知下列事項:
      </p>
      <p>
        一、本中心因受經濟部工業局委託執行/產業園區價值形塑暨廠商情資加值計畫之事由，蒐集、處理及利用您所提供，或未來將提供的個人資料(下稱個資)，謹先告知下列事項:
      </p>
      <p>(一)蒐集目的:辦理專案計畫活動及執行計畫相關業務之所需。</p>　
      <p>
        (二)個資類別:姓名、公司名稱、金融代碼或帳戶及聯絡方式(包括但不限於電話號碼、E-MAIL、居住或工作地址)等，或其他得以直接或間接識別您個人之資料。
      </p>
      <p>(三)利用期間:至蒐集目的消失為止。</p>
      <p>
        (四)利用地區:中華民國地區及符合蒐集目的之本中心國外合作或委辦
        對象所在地區。
      </p>
      <p>
        (五)利用者:經濟部、本中心及其他與經濟部或本中心有業務往來之公
        務及非公務機關。
      </p>
      <p>
        (六)利用方式:在不違反蒐集目的前提下，以網際網路、電子郵件、書
        面、傳真及其他合法方式利用之。
      </p>
      　
      <p>
        二、此外，對於您所提供給本中心的個人資料，依據個資法第 3 條規定，
        您本人可行使以下權利:
      </p>
      <p>(一)查詢或請求閱覽。</p>
      <p>(二)請求製給複製本。</p>
      <p>(三)請求補充或更正。</p>
      <p>(四)請求停止蒐集、處理及利用。</p>
      <p>(五)請求刪除。</p>
      <p>
        三、如欲行使前項權利，請洽本中心專線(07)351-3121 分機2415或來信至
        meer@mail.mirdc.org.tw。
      </p>
      <p>
        四、本中心基於蒐集目的而需蒐集、處理或利用您的個人資料，您可以自由選擇是否提供。若您選擇不提供或是提供不完全時，將可能導致您無法參加、申請相關活動或計畫，或本中心將無法提供您完整的服務，亦可能無法維護您的權益。
      </p>
    </TermsWrapper>
  );
};

const TermsWrapper = styled.div`
  background-color: #ffffff;
  width: 80%;
  margin: 5% 10% 10% 10%;
  padding: 2%;
  border-radius: 20px;
  max-height: 70vh;
  overflow-y: scroll;
  > h2 {
    font-size: 2.5rem;
    padding: 0px 0px 2% 0px;
    text-align: center;
  }

  > p {
    font-size: 1.25rem;
    line-height: 1.5rem;
    letter-spacing: 1px;
  }
`;
