import React from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import debounce from "lodash.debounce";
import IconButton from "@mui/material/IconButton";
import Close from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import { useSnackbar } from "notistack";
import SlideModal from "../components/SlideModal";

import { message } from "../utility/common";
import {
  handleCheckCustomDashboardBusinessNumberList,
  handleAddCustomDashboardBusinessNumberList,
  handleClearErrMsg,
  handleDeleteCustomDashboardBusinessNumberList
} from "../store/actions/custom_dashboard";
import { createDashboard } from "../utility/custom_dashboard_api";
import Spinner from "./Spinner/Spinner";

import * as theme from "../assets/theme";

const themeColor = createTheme({
  palette: {
    primary: {
      main: "#25758A",
    },
  },
});

const AddNumberToCustomDashboard = ({
  is_open,
  handleClose,
  business_no,
  is_map_search,
  custom_style,
  custom_modal_content_style,
  is_show_default_add,
  type,
}) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const {
    is_number_exiting_list,
    is_number_exiting_list_loading,
    custom_dashboard_business_number_list_loading,
    err
  } = useSelector((state) => state.custom_dashboard);

  const [state, setState] = React.useState({
    ready_to_add_board: [],
    is_default_add: false,
    selected_name: "",
    selected_delete_name: ""
  });
  React.useEffect(() => {
    if (is_open) {
      dispatch(handleCheckCustomDashboardBusinessNumberList(business_no));
    }
  }, [is_open])

  React.useEffect(() => {
    if (is_number_exiting_list.length > 0) {
      const isExistArr = [];
      is_number_exiting_list.forEach(item => {
        if (item.isExists) {
          isExistArr.push(item.bId)
        }
      })
      setState(val => ({ ...val, ready_to_add_board: isExistArr }))
    }
  }, [is_number_exiting_list]);

  React.useEffect(() => {
    dispatch(handleCheckCustomDashboardBusinessNumberList(business_no));
  }, [business_no]);

  React.useEffect(() => {
    setTimeout(() => {
      if (!custom_dashboard_business_number_list_loading && (state.selected_name || state.selected_delete_name)) {
        if (err === "") {
          if (state.selected_name) {
            if (type === "MapSearch") {
              if (state.is_default_add) {
                message(enqueueSnackbar, `${state.selected_name} 成功新增 ${[...new Set(business_no)].length} 間公司! 可以到自訂關注廠商儀表板後台修改名稱。`, "success");
                setState((state) => ({ ...state, is_default_add: false }))
              } else {
                message(enqueueSnackbar, `${state.selected_name} 成功新增 ${[...new Set(business_no)].length} 間公司!`, "success");
              }
            } else {
              message(enqueueSnackbar, `${state.selected_name} 成功新增公司!`, "success");
            }
          }
          if (state.selected_delete_name) {
            message(enqueueSnackbar, `${state.selected_delete_name} 成功刪除公司!`, "success");
          }
        } else {
          const boardName = state.selected_name || state.selected_delete_name;
          message(enqueueSnackbar, boardName + err, "error");
        }

        setState((state) => ({ ...state, ready_to_add_board: [], selected_name: "", selected_delete_name: "" }));
        dispatch(handleCheckCustomDashboardBusinessNumberList(business_no));
        // handleClose();
      }
    }, 1000);
  }, [err, custom_dashboard_business_number_list_loading])


  const handleToggleIsDefaultAdd = () => setState(state => ({ ...state, is_default_add: !state.is_default_add }));

  const handleAddBoardId = (id) => {
    let update_ready_to_add_board = [];

    const is_exist =
      state.ready_to_add_board.filter((i) => i === id).length > 0;

    if (is_exist) {
      update_ready_to_add_board = [
        ...state.ready_to_add_board.filter((i) => i !== id),
      ];
    } else {
      update_ready_to_add_board = [...state.ready_to_add_board];
      update_ready_to_add_board.push(id);
    }

    setState((state) => ({
      ...state,
      ready_to_add_board: update_ready_to_add_board,
    }));
  };


  const handleDeleteBusinessNumberList = (busi_no, business_no_list, name, allName) => {
    dispatch(handleClearErrMsg());
    dispatch(
      handleDeleteCustomDashboardBusinessNumberList(busi_no, business_no_list)
    );
    setState((state) => ({ ...state, add_business_number_list_area: [], selected_delete_name: (allName || name) }));
  }

  const handleAddBusinessNumberList = async (busi_no, business_no_list, name, allName) => {
    dispatch(handleClearErrMsg());
    await dispatch(
      handleAddCustomDashboardBusinessNumberList(busi_no, business_no_list)
    );
    setState((state) => ({ ...state, add_business_number_list_area: [], selected_name: (allName || name) }));
  }


  const handle_submit = () => {
    if (state.is_default_add) {
      createDashboard("新儀表板")
        .then(async (res) => {
          if (res.data.status === "success") {
            const update_business_no = [];
            if (is_map_search) {
              business_no.forEach((com) => {
                update_business_no.push(com);
              });
            } else {
              update_business_no.push(business_no);
            }

            const uniq_update_business_no = [...new Set(update_business_no)];

            handleAddBusinessNumberList(res.data.Info.BoardID, uniq_update_business_no.slice(0, 500), "新儀表板");
          } else {
            message(enqueueSnackbar, `新增儀表板失敗，請聯絡客服人員。`, "error");
          }
        })
        .catch((err) => {
          message(enqueueSnackbar, `新增儀表板失敗，請聯絡客服人員。`, "error");
        });

    }
    let allAddBoardName = "";
    let allDeleteBoardName = "";
    const checkDeleteBoardData = type === "FactoryInfo" ? is_number_exiting_list.filter(item => item.isExists) : [];
    let deleteArray = [];
    if (checkDeleteBoardData.length > 0) {
      checkDeleteBoardData.forEach(data => {
        if (!(state.ready_to_add_board.some(checkItem => checkItem === data.bId))) {
          allDeleteBoardName += `${data.BoardName}, `;
          deleteArray.push({
            bId: data.bId,
            BoardName: data.BoardName
          })
        }
      })
    }
    if (deleteArray.length > 0) {
      const update_business_no = [];
      if (is_map_search) {
        business_no.forEach((com) => {
          update_business_no.push(com);
        });
      } else {
        update_business_no.push(business_no);
      }
      const uniq_update_business_no = [...new Set(update_business_no)];
      deleteArray.forEach(async (data) => {
        handleDeleteBusinessNumberList(data.bId, uniq_update_business_no.slice(0, 500), data.BoardName, allDeleteBoardName);
      })
    }

    state.ready_to_add_board.forEach(async (i) => {

      // 新增：本來是 false 但現在在 ready_to_add_board
      const originBoardData = is_number_exiting_list.filter(item => item.bId === i);
      if (originBoardData.length > 0 && !originBoardData[0].isExists) {
        const update_business_no = [];
        if (is_map_search) {
          business_no.forEach((com) => {
            update_business_no.push(com);
          });
        } else {
          update_business_no.push(business_no);
        }

        const name = is_number_exiting_list.filter((j) => j.bId === i)[0][
          "BoardName"
        ];
        allAddBoardName += `${name}, `;

        const uniq_update_business_no = [...new Set(update_business_no)];

        // 加入
        handleAddBusinessNumberList(i, uniq_update_business_no.slice(0, 500), name, allAddBoardName);
      }
    });


  };

  let area_content;
  if (
    is_number_exiting_list_loading ||
    custom_dashboard_business_number_list_loading
  ) {
    area_content = (
      <ReadyToAddAreaContainer no_data>
        <Spinner />
      </ReadyToAddAreaContainer>
    );
  } else {
    area_content = (
      <ReadyToAddAreaContainer>
        <List sx={{ width: "100%", bgcolor: "background.paper" }}>
          {is_number_exiting_list.map((i, index) => (
            <ListItem key={i.bId} disablePadding>
              <ListItemButton role={undefined} dense>
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={
                      state.ready_to_add_board.filter(
                        (board_id) => board_id === i.bId
                      ).length > 0
                    }
                    disableRipple
                    onClick={() => handleAddBoardId(i.bId)}
                  />
                </ListItemIcon>
                <StyledListText
                  id={i.bId}
                  primary={i.BoardName}
                />
              </ListItemButton>
            </ListItem>
          ))}
          <DefaultListItem is_show={is_number_exiting_list.length <= 2 && is_show_default_add} disablePadding >
            <ListItemButton role={undefined} dense>
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={state.is_default_add}
                  disableRipple
                  onClick={handleToggleIsDefaultAdd}
                />
              </ListItemIcon>
              <StyledListText
                is_system
                primary={"勾選加入新儀表板"}
              />
            </ListItemButton>
          </DefaultListItem>
        </List>
      </ReadyToAddAreaContainer>
    );
  }

  return (
    <SlideModal is_open={is_open} handleClose={handleClose} custom_style={custom_style}>
      <CustomDashboardListDetailModalWrapper style={custom_modal_content_style}>
        <CustomDashboardListDetailModalCloseContainer>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </CustomDashboardListDetailModalCloseContainer>
        <CustomDashboardListDetailModalHeadContainer>
          <CustomDashboardListDetailModalHeadText>
            更新公司到自訂關注廠商儀表板
          </CustomDashboardListDetailModalHeadText>
        </CustomDashboardListDetailModalHeadContainer>
        <CustomDashboardListDetailModalSubHeadContainer>
          <CustomDashboardListDetailModalHeadSubText>
            您可選擇下方欲更新的自訂關注廠商儀表板，加入或移除公司，若公司已在該儀表板中則無法加入。新增後可在<a href="/custom_dashboard" target="_blank">自訂關注廠商儀表板</a>裡查詢已經新增的公司。
          </CustomDashboardListDetailModalHeadSubText>
        </CustomDashboardListDetailModalSubHeadContainer>
        {area_content}
        <AddButtonContainer>
          <StyledButton
            disabled={(type === "MapSearch" && state.ready_to_add_board.length === 0) && !state.is_default_add}
            onClick={handle_submit}
          >
            更新公司至儀表板
          </StyledButton>
        </AddButtonContainer>
      </CustomDashboardListDetailModalWrapper>
    </SlideModal>
  );
};

const StyledListText = styled(ListItemText)`
  /* color: ${(p) => (p.is_disabled ? theme.disabledGray : theme.black)}; */
  cursor: ${(p) => (p.is_disabled ? "not-allowed" : "pointer")};
  > span {
    color: ${(p) => (p.is_system ? theme.primaryColor : p.is_disabled ? theme.grayBorderColor : theme.black)};
    font-size: 1.25rem;
    font-family: Microsoft JhengHei;
  }
`;

const CustomDashboardListDetailModalWrapper = styled.div`
  width: 45%;
  background-color: ${theme.white};
  border-radius: 20px;
  padding: 20px 30px;
  height: fit-content;
  @media (max-width: 750px) {
    width: 100%;
    padding: 20px 10px;
  }
`;

const CustomDashboardListDetailModalCloseContainer = styled.div`
  width: calc(100%-60px);
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const CustomDashboardListDetailModalHeadContainer = styled.div`
  width: calc(100%-60px);
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const CustomDashboardListDetailModalHeadText = styled.h2`
  font-size: 2rem;
  font-weight: 600;
  letter-spacing: 2px;
  transform: translateY(-20px);

  @media (max-width: 750px) {
    transform: translateY(-5px);
    font-size: 1.7rem;
  }
`;

const CustomDashboardListDetailModalSubHeadContainer = styled.div`
  width: calc(100%-60px);
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const CustomDashboardListDetailModalHeadSubText = styled.p`
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 2px;
  transform: translateY(-10px);
  line-height: 20px;

  @media (max-width: 750px) {
    transform: translateY(0px);
  }
`;

const StyledButton = styled(Button)`
  background-color: ${(p) =>
    p.disabled ? theme.grayBorderColor : theme.linkBlue} !important;
  color: ${theme.white} !important;

  &:hover {
    background-color: ${(p) =>
    p.disabled ? theme.grayBorderColor : theme.linkBlue} !important;
  }
`;

const ReadyToAddAreaContainer = styled.div`
  width: 100%;
  height: 20vh;
  border: 0.5px solid ${theme.disabledGray};
  display: flex;
  flex-direction: column;
  justify-content: ${(p) => (p.no_data ? "center" : "flex-start")};
  align-items: ${(p) => (p.no_data ? "center" : "flex-start")};
  padding-bottom: 20px;
  overflow-y: scroll;
  @media (max-width: 750px) {
    margin: 10px 0px;
  }
`;

const ReadyToAddAreaItemNoDataText = styled.p`
  font-size: 1rem;
  font-weight: 400;
  line-height: 25px;
  color: ${theme.grayBorderColor};
`;

const AddButtonContainer = styled.div`
  width: 100%;
  padding: 20px 0px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const DefaultListItem = styled(ListItem)`
  display: ${p => !p.is_show && "none"} !important
`;


const CustomUpdateDashboardListModalWithTheme = (props) => {
  return (
    <ThemeProvider theme={themeColor}>
      <AddNumberToCustomDashboard {...props} />
    </ThemeProvider>
  );
};

export default React.memo(CustomUpdateDashboardListModalWithTheme);