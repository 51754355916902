import * as actionTypes from "../actionTypes/effected_event";

import { handleFetchEffectedEvents, handleSearchEffectedEvents, handleIndustry4CodeStat, handleForeignTradeStat, handleCountryStat, handleNews } from "../../utility/effected_event_api";

const startFetchEffectedEventsList = () => {
  return {
    type: actionTypes.START_FETCH_EFFECTED_EVENTS_LIST_DATA,
  };
};

const fetchEffectedEventsListSuccess = (data) => {
  return {
    type: actionTypes.FETCH_EFFECTED_EVENTS_LIST_DATA_SUCCESS,
    data: data,
  };
};

const fetchEffectedEventsListFail = (err) => {
  return {
    type: actionTypes.FETCH_EFFECTED_EVENTS_LIST_DATA_FAIL,
    err: err,
  };
};

export const handleFetchEffectedEventsData = () => {
  return (dispatch) => {
    dispatch(startFetchEffectedEventsList());
    handleFetchEffectedEvents()
      .then((res) => {
        if (res && res.status === 200) {
          dispatch(fetchEffectedEventsListSuccess(res));
        } else {
          dispatch(
            fetchEffectedEventsListFail(
              "取得廢棄物清理服務種類清單時發生問題。"
            )
          );
        }
      })
      .catch((err) => {
        dispatch(fetchEffectedEventsListFail(err));
      });
  };
};

// 工廠四碼
const startFetchIndustry4Code = () => {
  return {
    type: actionTypes.START_FETCH_INDUSTRY_4_CODE_DATA,
  };
};

const fetchIndustry4CodeSuccess = (data) => {
  return {
    type: actionTypes.FETCH_INDUSTRY_4_CODE_DATA_SUCCESS,
    data: data,
  };
};

const fetchIndustry4CodeFail = (err) => {
  return {
    type: actionTypes.FETCH_INDUSTRY_4_CODE_DATA_FAIL,
    err: err,
  };
};

export const handleFetchIndustry4CodeStatData = (id) => {
  return (dispatch) => {
    dispatch(startFetchIndustry4Code());
    handleIndustry4CodeStat(id)
      .then((res) => {
        if (res && res.status === "success") {
          dispatch(fetchIndustry4CodeSuccess(res.data));
        } else {
          dispatch(
            fetchEffectedEventsListFail(
              "取得廢棄物清理服務種類清單時發生問題。"
            )
          );
        }
      })
      .catch((err) => {
        dispatch(fetchIndustry4CodeFail(err));
      });
  };
};

// end
// 進出口貨品碼 API
const startFetchForeignTrade = () => {
  return {
    type: actionTypes.START_FETCH_FOREIGN_TRADE_DATA,
  };
};
const fetchForeignTradeExportSuccess = (data) => {
  return {
    type: actionTypes.FETCH_FOREIGN_TRADE_EXPORT_DATA_SUCCESS,
    data: data,
  };
};
const fetchForeignTradeImportSuccess = (data) => {
  return {
    type: actionTypes.FETCH_FOREIGN_TRADE_IMPORT_DATA_SUCCESS,
    data: data,
  };
};
const fetchForeignTradeFail = (err) => {
  return {
    type: actionTypes.FETCH_FOREIGN_TRADE_DATA_FAIL,
    err: err,
  };
};
export const handleFetchForeignTradeStatData = (id, type) => {
  return (dispatch) => {
    dispatch(startFetchForeignTrade());
    handleForeignTradeStat(id, type)
      .then((res) => {
        if (res && res.status === "success") {
          if (type === "Export") {
            dispatch(fetchForeignTradeExportSuccess(res.data));
          } else {
            dispatch(fetchForeignTradeImportSuccess(res.data));
          }
        } else {
          dispatch(
            fetchEffectedEventsListFail(
              "取得廢棄物清理服務種類清單時發生問題。"
            )
          );
        }
      })
      .catch((err) => {
        dispatch(fetchForeignTradeFail(err));
      });
  };
};
// end
// 國家別 API
const startFetchCountryStat = () => {
  return {
    type: actionTypes.START_FETCH_COUNTRY_DATA,
  };
};

const fetchCountryStatExportSuccess = (data) => {
  return {
    type: actionTypes.FETCH_COUNTRY_EXPORT_DATA_SUCCESS,
    data: data,
  };
};
const fetchCountryStatImportSuccess = (data) => {
  return {
    type: actionTypes.FETCH_COUNTRY_IMPORT_DATA_SUCCESS,
    data: data,
  };
};

const fetchCountryStatFail = (err) => {
  return {
    type: actionTypes.FETCH_COUNTRY_DATA_FAIL,
    err: err,
  };
};

export const handleFetchCountryStatData = (id, type) => {
  return (dispatch) => {
    dispatch(startFetchCountryStat());
    handleCountryStat(id, type)
      .then((res) => {
        if (res && res.status === "success") {
          if (type === "Export") {
            dispatch(fetchCountryStatExportSuccess(res.data));
          } else {
            dispatch(fetchCountryStatImportSuccess(res.data));
          }
        } else {
          dispatch(
            fetchEffectedEventsListFail(
              "取得廢棄物清理服務種類清單時發生問題。"
            )
          );
        }
      })
      .catch((err) => {
        dispatch(fetchCountryStatFail(err));
      });
  };
};
// end

// 新聞 API
const startFetchNews = () => {
  return {
    type: actionTypes.START_FETCH_NEWS_DATA,
  };
};
const fetchNewsSuccess = (data) => {
  return {
    type: actionTypes.FETCH_NEWS_DATA_SUCCESS,
    data: data,
  };
};
const fetchNewsFail = (err) => {
  return {
    type: actionTypes.FETCH_NEWS_DATA_FAIL,
    err: err,
  };
};

export const handleFetchNewsData = (id) => {
  return (dispatch) => {
    dispatch(startFetchNews());
    handleNews(id)
      .then((res) => {
        if (res && res.status === "success") {
          dispatch(fetchNewsSuccess(res.data));
        } else {
          dispatch(
            fetchEffectedEventsListFail(
              "取得廢棄物清理服務種類清單時發生問題。"
            )
          );
        }
      })
      .catch((err) => {
        dispatch(fetchNewsFail(err));
      });
  };
};
// end

const startSearchEffectedEventsData = () => {
  return {
    type: actionTypes.START_SEARCH_EFFECTED_EVENTS_RESULT,
  };
};

const searchEffectedEventsDataSuccess = (data) => {
  return {
    type: actionTypes.SEARCH_EFFECTED_EVENTS_RESULT_SUCCESS,
    data: data.data,
  };
};

const searchEffectedEventsDataFail = (err) => {
  return {
    type: actionTypes.SEARCH_EFFECTED_EVENTS_RESULT_FAIL,
    err: err,
  };
};

const handleRestErrText = () => {
  return {
    type: actionTypes.HANDLE_RESET_ERR,
  };
}

export const handleSearchEffectedEventsData = (param) => {
  return (dispatch) => {
    dispatch(startSearchEffectedEventsData());
    handleSearchEffectedEvents(param)
      .then((res) => {
        if (res && res.status === "success") {
          dispatch(searchEffectedEventsDataSuccess(res));
        } else {
          dispatch(
            searchEffectedEventsDataFail(res)
          );
          setTimeout(()=>{
            dispatch(handleRestErrText())
          }, 3000)
        }
      })
      .catch((err) => {
        dispatch(searchEffectedEventsDataFail(err));
        setTimeout(()=>{
          dispatch(handleRestErrText())
        }, 3000);
      });
  };
};

export const resetState = () => {
  return {
    type: actionTypes.RESET_STATE,
  };
}

export const handleResetState = () => {
  return (dispatch) => {
    dispatch(resetState());
  };
}