import * as actionTypes from "../actions/actionTypes";
import * as actionType from "../actionTypes/auth";

const initState = {
  isAuth: false,
  permission: [],
  singInLoading: false,
  singUpLoading: false,
  forgetPassWordLoading: false,
  fetch_is_restricted_loading: false,
  restricted_list: [],
  err: "",
};

export default (state = initState, action) => {
  switch (action.type) {
    case actionTypes.START_SIGN_IN:
      return {
        ...state,
        singInLoading: true,
      };
    case actionTypes.SIGN_IN_SUCCESS:
      return {
        ...state,
        isAuth: true,
        permission: action.permission,
        singInLoading: false,
      };
    case actionTypes.SING_IN_FAIL:
      return {
        ...state,
        isAuth: false,
        singInLoading: false,
      };
    case actionTypes.START_GET_AUTH_FROM_LOCAL:
      return {
        ...state,
        singInLoading: true,
      };
    case actionTypes.GET_AUTH_FROM_LOCAL_SUCCESS:
      return {
        ...state,
        isAuth: true,
        permission: action.permission,
        singInLoading: false,
      };
    case actionTypes.GET_AUTH_FROM_LOCAL_FAIL:
      return {
        ...state,
        isAuth: false,
        singInLoading: false,
      };
    case actionTypes.LOG_OUT:
      return {
        ...state,
        isAuth: false,
        permission: [],
      };
    case actionTypes.START_CHANGE_PASS:
      return {
        ...state,
        singInLoading: true,
      };
    case actionTypes.CHANGE_PASS_SUCCESS:
      return {
        ...state,
        singInLoading: false,
      };
    case actionTypes.CHANGE_PASS_FAIL:
      return {
        ...state,
        singInLoading: false,
        err: action.err,
      };
    case actionTypes.START_SIGN_UP:
      return {
        ...state,
        singUpLoading: true,
      };
    case actionTypes.SIGN_UP_SUCCESS:
      return {
        ...state,
        singUpLoading: false,
      };
    case actionTypes.SING_UP_FAIL:
      return {
        ...state,
        singUpLoading: false,
        err: action.err,
      };
    case actionTypes.START_FORGET_PASS:
      return {
        ...state,
        forgetPassWordLoading: true,
      };
    case actionTypes.FORGET_PASS_SUCCESS:
      return {
        ...state,
        forgetPassWordLoading: false,
      };
    case actionTypes.FORGET_PASS_FAIL:
      return {
        ...state,
        forgetPassWordLoading: false,
      };
    case actionType.START_FETCH_RESTRICTED_NAME:
      return {
        ...state,
        fetch_is_restricted_loading: true,
      };
    case actionType.FETCH_RESTRICTED_NAME_SUCCESS:
      return {
        ...state,
        restricted_list: action.data,
        fetch_is_restricted_loading: false,
      };
    case actionType.FETCH_RESTRICTED_NAME_FAIL:
      return {
        ...state,
        fetch_is_restricted_loading: false,
      };
    default:
      return state;
  }
};
