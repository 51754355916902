import React, { useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Map, TileLayer } from "react-leaflet";
import L from "leaflet";
import MarkerClusterGroup from "react-leaflet-markercluster";
import * as mapActions from "../../store/actions/map";
import PopUp from "./PopUp";

// import MaterialSearchPopUp from "./MaterialSearchPopUp";
import Spinner from "../Spinner/Spinner";

import "./styles.scss";

export default (props) => {
  const { registeredCompanyPositionList, type } = props;
  // type: park-園區儀表板-園區地圖 // factory-工廠分佈地圖
  const mapRef = useRef(null);
  const groupRef = useRef(null);
  const [state, setState] = React.useState({
    markerList: [],
    bound: "",
    showedCompany: "",
  });

  const { innerWidth } = useSelector((state) => state.app);
  const { loading } = useSelector((state) => state.map);

  const dispatch = useDispatch();

  const markerOpen = (lat, lng) => {
    const markers = new L.MarkerClusterGroup();
    // 搜尋 markers 圖層下的子圖層
    markers.eachLayer((layer) => {
      // 抓取圖層的 經緯度
      // eslint-disable-next-line no-underscore-dangle
      const eachLat = layer._latlng.lat;
      // eslint-disable-next-line no-underscore-dangle
      const eachLng = layer._latlng.lng;
      // 如果與參數的經緯度相同，就抓取那個 layer
      if (eachLat === lat && eachLng === lng) {
        // zoomToShowLayer 這個是 MarkerClusterGroup 給的函式
        // 方法是調用 MarkerClusterGroup 下的子圖層
        // 打開 bindPopup 的 HTML
        markers.zoomToShowLayer(layer, () => layer.openPopup());
      }
    });
  };

  React.useEffect(() => {
    if (
      registeredCompanyPositionList.length > 0 &&
      registeredCompanyPositionList !== "error"
    ) {
      const updateBond = [];
      const updatedMarkerList = registeredCompanyPositionList.map(
        (marker, index) => {
          updateBond.push(marker.position);

          return (
            <PopUp
              position={marker.position}
              key={marker.key + index}
              data={marker}
              keys={marker.key + index}
            />
          );
        }
      );

      setState((state) => ({
        ...state,
        markerList: updatedMarkerList,
        bond: updateBond,
      }));
    } else {
      setState((state) => ({
        ...state,
        markerList: [],
        bond: [
          [25.268576, 121.611722],
          [23.879299, 120.294881],
          [23.762836, 121.54409],
          [21.257621, 120.740482],
          [21.8998, 120.837252],
        ],
      }));
    }
  }, [registeredCompanyPositionList]);

  React.useEffect(() => {
    dispatch(mapActions.initMapSelection());
  }, [dispatch]);

  const centerMapView = (e) => {
    // e.preventDefault()
    let adjustYaxis;
    if (innerWidth > 1024) {
      adjustYaxis = 115;
    } else {
      adjustYaxis = 115;
    }
    const { leafletElement } = mapRef.current;

    if (e) {
      leafletElement.setView(e.popup._latlng);
      const point = leafletElement.project(e.target._popup._latlng);
      //把原本預設的 y 軸 - 200 ， 這樣 popup 才會完整出現在畫面
      const updatePoint = {
        ...point,
        y: point.y - adjustYaxis,
        // x: point.x - 1000,
      };
      leafletElement.panTo(leafletElement.unproject(updatePoint), {
        animate: true,
      });
    }
  };

  const options =
    type === "park"
      ? {
          bounds: state.bond
            ? state.bond
            : [
                [25.268576, 121.611722],
                [23.879299, 120.294881],
                [23.762836, 121.54409],
                [21.257621, 120.740482],
                [21.8998, 120.837252],
              ],
        }
      : {
          zoom: 16,
          center: state.bond ? state.bond[0] : [23.974, 120.9798],
        };
  if (loading) {
    return (
      <div className="spinner">
        <Spinner />
      </div>
    );
  } else {
    return (
      <div className="dashboard-map-view">
        <Map
          ref={mapRef}
          {...options}
          zoomControl={true}
          onPopupopen={centerMapView}
          className="map-background"
          style={{
            position: "relative",
          }}
          animate={true}
        >
          <TileLayer
            attribution='&amp;copy <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <MarkerClusterGroup
            onKeyUp={(e) => {
              if (e.type === "clusterkeyup") {
                e.originalEvent.target.setAttribute(
                  "title",
                  `包含 ${e.originalEvent.target.innerText} 個定位點，點擊或按下 enter 鍵以放大`
                );
                if (e.originalEvent.keyCode === 13) {
                  e.originalEvent.target.click();
                }
                if (e.originalEvent.keyCode === 9) {
                  const { leafletElement } = mapRef.current;
                  leafletElement.setView(e.layer._cLatLng, e.layer._zoom);
                }
              }
            }}
            ref={groupRef}
          >
            {state.markerList}
          </MarkerClusterGroup>
        </Map>
      </div>
    );
  }
};
