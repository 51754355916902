export const applyFileParameter = {
  "phone": "07-3513121 分機 2415",
  "nickNmae": "陳小姐",
}

export const applyReasonList = [
  { 'id': '1', 'content': '長官交辦' },
  { 'id': '2', 'content': '產業研究' },
  { 'id': '3', 'content': '產業輔導' },
  { 'id': '4', 'content': '訪廠需求' },
  { 'id': '5', 'content': '業務拓展' },
  { 'id': '6', 'content': '其他' },
]


export const keyTranslate = {
  "Area": "地區",
  "CitiesList": "縣市",
  "InduParksList": "園區",
  "KeyWordList": "關鍵字",
  "keyWordList": "關鍵字",
  "MaxCapital": "最高資本額",
  "MinCapital": "最低資本額",
  "maxCapital": "最高資本額",
  "minCapital": "最低資本額",
  "Operator": "運算子",
  "operator": "運算子",
  "TypeFourList": "行業標準分類細類",
  "TypeTwoList": "行業標準分類中類",
  "isAward": "近五年得過獎的廠商",
  "isIPO": "股票上市櫃廠商",
  "isIncome": "上一年度進口級距為C以上的廠商",
  "isInduParks": "產業園區內廠商",
  "isOutcome": "上一年度進口級距為C以上的廠商",
  "isPatent": "近五年有專利公告的廠商",
  "isPlan": "近五年執行過政府計畫的廠商",
  "productFourCode": "出口貨品四碼",
  "taxCode": "稅務行業分類",
  "industryTwoCode": "行業標準分類中類",
  "industryThreeCode": "行業標準分類小類",
  "industryCode": "行業標準分類細類",
  "cityCode": "公司登記縣市代號",
  "Position": "所在地",
  "Distance": "距離",
};

export const areaData = {
  "All": "台灣所有地區",
  "North": "台灣北部地區",
  "Center": "台灣中部地區",
  "South": "台灣南部地區",
  "East": "台灣北部地區",
}

export const checkboxStatus = {
  "false": "未選擇",
  "true": "已選擇",
}

export const operatorStatus = {
  "or": "或",
  "and": "和",
}
