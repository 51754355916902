import React,{ useState, useEffect, useRef} from 'react'
import {
  Card,
  Icon,
  Grid
} from "@material-ui/core";
import { useDispatch } from 'react-redux'

import InnerNavBar from '../../components/InnerNavBar/InnerNavBar'
import CustomizedTable from '../../components/CustomizedTable/CustomizedTable'
import Spinner from '../../components/Spinner/Spinner'
import * as govPlanActions from '../../store/actions/govplan'
import { thousandDivider } from "../../utility/common";

import './styles.scss'

const GovPlanInput = props => {
    const tableRef = useRef(null);
    const { activePlanList, inActivePlanList, loading } = props 
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [value, setValue] = useState(0)
    const [modalInfo, setModalInfo] = useState({
        name:'',
        //other info

    });
    const [data, setData] = useState(activePlanList)
    const [searchKeyword, setSearchKeyword] = useState()
    const [selectedCategory, setSelectedCategory] = useState([])
    const dispatch = useDispatch()


    useEffect(()=>{
      dispatch(govPlanActions.getAllGovPlans())
    },[])

      //function handlers
    const openModalHandler = id => {
      const updateInfo = activePlanList.filter(info => info.id === id);

      setModalInfo({
        name: updateInfo[0].name
      });
      setIsModalOpen(true);
    };

    const modalHandler = (type, projectId) => {
      props.modalHandler(type, projectId)
    }

    const getNavValue = value => { 
      setValue(value)
      if(value === 0){
        setData(activePlanList)
      }else if(value === 1){
        setData(inActivePlanList)
      }
    }



  // const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

    useEffect(()=>{
      if (activePlanList && activePlanList.length > 0) {
        setData(activePlanList.sort((a, b) => ("" + b.Name).localeCompare(a.Name)));
      }
    },[activePlanList, inActivePlanList])

    useEffect(()=>{
      const updateData = []
    
      if(value === 0 && activePlanList) {

        if(activePlanList){
          activePlanList.map(content => {
            if (Object.values(content).toString().indexOf(searchKeyword) !== -1) {
              updateData.push(content)
            }
          })
          
        }
      } else if (value === 1 && inActivePlanList){
        inActivePlanList.map(content => {
          if (Object.values(content).toString().indexOf(searchKeyword) !== -1) {
            updateData.push(content)
          }
        })
      }


      if(searchKeyword){
        setData(updateData)
      } else if (searchKeyword === ''){
        if (value === 0) {
          setData(activePlanList)
        } else if (value === 1) {
          setData(inActivePlanList)
        }
      }




    }, [searchKeyword, activePlanList, inActivePlanList, loading])


    useEffect(()=>{
      if(selectedCategory){
        if (value === 0) {
          const updatePlans = []

          if(activePlanList){
            activePlanList.map(plan => {
              if (plan.Category.toString().indexOf(selectedCategory) !== -1) {
                updatePlans.push(plan)
              }
            })

          }


          setData(updatePlans)
        } else if (value === 1) {
          const updateInactivePlans = inActivePlanList.filter(plan => plan.Category.toString().indexOf(selectedCategory) !== -1)

          setData(updateInactivePlans)
        }
      }
    },[selectedCategory])

    const searchHandler = event => {
      const value = event.target.value
      setSearchKeyword(value)
    }

    const categorySelectHandler = category => {
       if(category === selectedCategory){
         if (value === 0) {
           setSelectedCategory()
           setData(activePlanList)
         } else if (value === 1) {
           setData(inActivePlanList)
         }
       } else {
         setSelectedCategory(category)
       }
    }

  const resetHandler = () => {
    setSelectedCategory()
    setData(activePlanList)
  }

  const handleOrder = (value, type) => {
    switch (type) {
      case 'displayAmount':
        let orderData

        if (value) {
          orderData = data.sort((a, b) => a.Amount - b.Amount)
        } else {
          orderData = data.sort((a, b) => b.Amount - a.Amount)
        }

        setData(orderData)
        break;
      default:
        return null
    }
  }

    return (
      <div className="gov-plan-input">
        <div className="gov-plan-input-title">
          <InnerNavBar
            getNavValue={getNavValue}
            listData={[
              { name: "進行中政府計畫", url: "gov_plan_ongoing" },
              { name: "已過期政府計畫", url: "gov_plan_over_due" }
            ]}
          />
          <div className="category-container">
            <div className='category-item-container'>
                <div
                  className={
                    selectedCategory === "淨零減碳"
                      ? "category-item-selected"
                      : "category-item"
                  }
                  style={{ backgroundColor: "#12736e" }}
                  onClick={() => categorySelectHandler("淨零減碳")}
                >
                  <Icon className="icon">co2</Icon>
                  <p>淨零減碳</p>
                </div>
                <div
                  className={
                    selectedCategory === "數位轉型"
                      ? "category-item-selected"
                      : "category-item"
                  }
                  style={{ backgroundColor: "#103778" }}
                  onClick={() => categorySelectHandler("數位轉型")}
                >
                  <Icon className="icon">electric_bolt</Icon>
                  <p>數位轉型</p>
                </div>
                <div
                  className={selectedCategory === "研究發展相關" ? 'category-item-selected' : 'category-item'}
                  style={{ backgroundColor: '#1a7a3d' }}
                  onClick={() => categorySelectHandler('研究發展相關')}
                >
                  <Icon className='icon'>developer_mode</Icon>
                  <p>研究發展</p>
                </div>
                <div
                  className={selectedCategory === "市場行銷相關" ? 'category-item-selected' : 'category-item'}
                  style={{ backgroundColor: '#1085C9' }}
                  onClick={() => categorySelectHandler('市場行銷相關')}
                >
                  <Icon className='icon'>storefront</Icon>
                  <p>市場行銷</p>
                </div>
                <div
                  className={selectedCategory === "人力資源相關" ? 'category-item-selected' : 'category-item'}
                  style={{ backgroundColor: '#8b6132' }}
                  onClick={() => categorySelectHandler('人力資源相關')}
                >
                  <Icon className='icon'>face</Icon>
                  <p>人力資源</p>
                </div>
                <div
                  className={selectedCategory === "計畫廣宣" ? 'category-item-selected' : 'category-item'}
                  style={{ backgroundColor: '#E34928' }}
                  onClick={() => categorySelectHandler('計畫廣宣')}
                >
                  <Icon className='icon'>featured_video</Icon>
                  <p>課程訊息</p>
                </div>
                <div
                  className={selectedCategory === "企業獎項" ? 'category-item-selected' : 'category-item'}
                  style={{ backgroundColor: '#4F1FB0' }}
                  onClick={() => categorySelectHandler('企業獎項')}
                >
                  <Icon className='icon'>emoji_events</Icon>
                  <p>企業獎項</p>
                </div>
                <div
                  className={
                    selectedCategory === "資金貸款"
                      ? "category-item-selected"
                      : "category-item"
                  }
                  style={{ backgroundColor: "#E3371E" }}
                  onClick={() => categorySelectHandler("資金貸款")}
                >
                  <Icon className="icon">money</Icon>
                  <p>資金貸款</p>
                </div>
              <div>

              </div>

            </div>

            <div className="search-bar-container">
              <div className="search-bar">
                <Icon className="icon">search</Icon>
                <input
                  placeholder="請輸入欲查詢的關鍵字"
                  onChange={event => searchHandler(event)}
                  onClick={resetHandler}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="table">
          <div className="table-height" ref={tableRef}>
          <CustomizedTable
            type="main-table"
            loading={loading}
            content={data}
            modalHandler={modalHandler}
            handleOrder={(value, type) => handleOrder(value, type)}
            tableRef={tableRef}
          />
        </div>
        </div>
        <div className="gov-plan-mobile-table">
          <Grid
            container
            spacing={3}
            direction="column"
            justify="flex-start"
            alignItems="center"
          >
          {!loading && data ? 
            data.map(item => (
              <Grid item component={Card} className='item' onClick={() => props.modalHandler('detail', item.ProjectId)}>
              <div>
                  <label>計畫名稱 :</label> <p>{item.Name}</p>
              </div>
                <div>
                  <label>時間 :</label> <p>{item.StartDate} ~ {item.EndDate}</p>
                </div>
                <div>
                  <label>補助上限 :</label> <p>{item.Amount === 0 ? '依計畫核定' : thousandDivider(item.Amount)}</p>
                </div>
                <div>
                  <label>計畫連結 :</label> <p className='link' onClick={() => window.open(item.Link, "_blank")}>{item.Link}</p>
                </div>
                <div>
                  <label>類型 :</label> {item.Category
                    ? item.Category.map(catItem => {
                      switch (catItem) {
                        case "人力資源相關":
                          return <Icon className='icon'>face</Icon>;
                        case "研究發展相關":
                          return <Icon className='icon'>developer_mode</Icon>;
                        case "市場行銷相關":
                          return <Icon className='icon'>storefront</Icon>;
                        case "資金相關":
                          return <Icon className='icon'>storefront</Icon>;
                        case "計畫廣宣":
                          return <Icon className='icon'>featured_video</Icon>;
                        case "企業獎項":
                          return <Icon className='icon'>emoji_events</Icon>;
                        case "淨零減碳":
                          return <Icon className="icon">co2</Icon>;
                        case "資金貸款":
                          return <Icon className="icon">money</Icon>;
                        case "數位轉型":
                          return <Icon className="icon">electric_bolt</Icon>;
                        default:
                          return <Icon className='icon'>monetization_on</Icon>;
                      }
                    })
                    : null}
                </div>
            </Grid>
            )) 
          : null}
          </Grid>       
        </div>  




        {/* TODO 未來修改手機版要用的 */}
        {/* <div className="gov-plan-mobile-table">
          <SwipeableGrid>
            <div className="gov-plan-mobile-item">
              <h3>愛你九九補計畫1</h3>
              <div>
                <div className="gov-plan-mobile-item-container">
                  <p className="title">補助時間</p>
                  <p className="content">2020/12/15 ～ 2020/12/3</p>
                </div>
                <div className="gov-plan-mobile-item-container">
                  <p className="title">補助金額</p>
                  <p className="content">12,000</p>
                </div>
                <div className="gov-plan-mobile-item-container">
                  <p className="title">聯絡電話</p>
                  <p className="content">0917882311</p>
                </div>
                <div className="gov-plan-mobile-item-container">
                  <p className="title">補助類型</p>
                  <p className="content">人力資源類型</p>
                </div>
              </div>
            </div>
            <div className="gov-plan-mobile-item">
              <h3>愛你九九補計畫2</h3>
              <div>
                <div className="gov-plan-mobile-item-container">
                  <p className="title">補助時間</p>
                  <p className="content">2020/12/15 ～ 2020/12/3</p>
                </div>
                <div className="gov-plan-mobile-item-container">
                  <p className="title">補助金額</p>
                  <p className="content">12,000</p>
                </div>
                <div className="gov-plan-mobile-item-container">
                  <p className="title">聯絡電話</p>
                  <p className="content">0917882311</p>
                </div>
                <div className="gov-plan-mobile-item-container">
                  <p className="title">補助類型</p>
                  <p className="content">人力資源類型</p>
                </div>
              </div>
            </div>
            <div className="gov-plan-mobile-item">
              <h3>愛你九九補計畫3</h3>
              <div>
                <div className="gov-plan-mobile-item-container">
                  <p className="title">補助時間</p>
                  <p className="content">2020/12/15 ～ 2020/12/3</p>
                </div>
                <div className="gov-plan-mobile-item-container">
                  <p className="title">補助金額</p>
                  <p className="content">12,000</p>
                </div>
                <div className="gov-plan-mobile-item-container">
                  <p className="title">聯絡電話</p>
                  <p className="content">0917882311</p>
                </div>
                <div className="gov-plan-mobile-item-container">
                  <p className="title">補助類型</p>
                  <p className="content">人力資源類型</p>
                </div>
              </div>
            </div>
          </SwipeableGrid>
        </div> */}

      </div>
    );
}

export default GovPlanInput