import React from "react";
import { useSelector } from "react-redux";
import MobileGrid from "../../MobileGridV2";
import * as Style from "../../styles";
import Tooltip from "@material-ui/core/Tooltip";
import mittw_logo from "../../../../assets/images/mittw_logo.png";
import { Card } from "react-bootstrap";
import Swal from "sweetalert2";
import styled from "styled-components";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const MITMark = ({ data, referenceType }) => {
  const { referenceInfo } = useSelector((state) => state.app);
  const toggleCard = {};
  const openReferenceInfoAlert = (type) => {
    let content = "";
    if (referenceInfo[type] === "") {
      console.log("I was called!");
      Swal.fire({
        title: "引用資料來源",
        text: "資料正在建置中 ... ",
      });
    } else {
      try {
        referenceInfo[type]
          .split("。")
          .map(
            (referenceItem) =>
              (content +=
                "<p style='text-align:left'>" + referenceItem + "</p>")
          );
        Swal.fire({
          title: "引用資料來源",
          html: content,
        });
      } catch (err) {
        Swal.fire({
          title: "引用資料來源",
          text: "資料正在建置中 ... ",
        });
      }
    }
  };

  return (
    <div>
      <Card.Header className="card-title">
        <TradeMarker>
            {"MIT標章"}
            {toggleCard && referenceType && ( // 如果有 toggleCard 就顯示（位置差異）
              <button
                title={`開啟${"MIT標章"}引用資料來源彈出視窗`}
                style={{ color: "white", borderRadius: "100%", marginLeft: "4px" }}
                onClick={() => openReferenceInfoAlert(referenceType)}  
              > 
                <FontAwesomeIcon
                  className="icon-reference"
                  icon={faInfoCircle}
                />
              </button>
            )}
        </TradeMarker>
      </Card.Header>
      <MobileGrid>
        {data.map((item, index) => (
          <Style.MobileGridContainerItem
            item
            component={Style.MobileGridItem}
            key={item.ProductName + index}
          >
            <Style.MobileGridItemList>
              <Style.MobileGridItemListLabel>產品名稱 </Style.MobileGridItemListLabel>
              <Style.MobileGridItemListText style={{ display: "flex", alignItems: "center" }}>
                {item.ProductName ? item.ProductName : "無相關資料"}
                <Tooltip title="點擊觀看MIT標章詳細頁面">
                  <a href={"https://www.mittw.org.tw/products/" + item.ProductLink} target="_blank" rel="noopener noreferrer">
                    <div style={{ width: "80px", marginLeft: "12px" }}>
                      <img alt="MIT標章" src={mittw_logo} style={{ maxWidth: "100%" }} />
                    </div>
                  </a>
                </Tooltip>
              </Style.MobileGridItemListText>
            </Style.MobileGridItemList>
            <Style.MobileGridItemList>
              <Style.MobileGridItemListLabel>通過日期 </Style.MobileGridItemListLabel>
              <Style.MobileGridItemListText>
                {item.PassDate ? item.PassDate.replaceAll("T00:00:00", "") : "無相關資料"}
              </Style.MobileGridItemListText>
            </Style.MobileGridItemList>
            <Style.MobileGridItemList>
              <Style.MobileGridItemListLabel>有效日期 </Style.MobileGridItemListLabel>
              <Style.MobileGridItemListText>
                {item.EffectDate ? item.EffectDate.replaceAll("T00:00:00", "") : "無相關資料"}
              </Style.MobileGridItemListText>
            </Style.MobileGridItemList>
            <Style.MobileGridItemList>
              <Style.MobileGridItemListLabel>產品狀態 </Style.MobileGridItemListLabel>
              <Style.MobileGridItemListText>
                {item.ProductStatus ? item.ProductStatus : "無相關資料"}
              </Style.MobileGridItemListText>
            </Style.MobileGridItemList>
          </Style.MobileGridContainerItem>
        ))}
      </MobileGrid>
    </div>
  );
};

const TradeMarker = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
`;

export default React.memo(MITMark);
