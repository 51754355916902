import React from "react";
import { Card } from "react-bootstrap";
import { useSelector } from "react-redux";

import CustomizedCard from "../../../components/CustomizedCard/CustomizedCardV3";
import FactoryPatentInfo from "../../../components/CustomizedTable/FactoryInfo/FactoryPatentInfo";
import MobileFactoryPatentInfo from "../../../components/MobileGrid/FactoryInfo/PatentInfo/PatentInfo";
import FactoryTrademark from "../../../components/CustomizedTable/FactoryInfo/FactoryTrademark";
import MobileTrademark from "../../../components/MobileGrid/FactoryInfo/Trademark/Trademark";

const PatentInfo = () => {
  const { innerWidth } = useSelector((state) => state.app);
  const { patentInfo, patentInfoLoading, getTrademarkData, getTrademarkDataLoading} = useSelector(
    (state) => state.factory
  );

  
  return (
    <React.Fragment>
      {innerWidth > 1024 ? (
        <Card>
          <CustomizedCard
            title="專利資料"
            referenceType={"factoryInfoPatents"}
            loading={patentInfoLoading}
          >
            <FactoryPatentInfo
              data={patentInfo}
              headerList={["專利號", "專利名稱", "狀態", "獲證日期"]}
            />
          </CustomizedCard>
        </Card>
      ) : (
        <MobileFactoryPatentInfo data={patentInfo} referenceType={"factoryInfoPatents"} />
      )}
      {innerWidth > 1024 ? (
        <Card>
          <CustomizedCard
            title="註冊商標"
            referenceType={"factoryInfoPatents"}
            loading={getTrademarkDataLoading}
            isMarker
          >
            <FactoryTrademark
              data={getTrademarkData}
              headerList={["申請案號", "商標名稱", "權利期間(起)", "權利期間(迄)"]}
            />
          </CustomizedCard>
        </Card>
      ) : (
        <MobileTrademark data={getTrademarkData}  referenceType={"factoryInfoPatents"} />
      )}
    </React.Fragment>
  );
};

export default React.memo(PatentInfo);
