import React, { useState, useEffect, useReducer } from "react";
import { useRouteMatch, Switch, Route, useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf, faPlus } from "@fortawesome/free-solid-svg-icons";
import UpdatedCompanyInfo from "../UpdatedCompanyInfo/UpdatedCompanyInfo";
import FactoryBasicInfo from "../FactoryBasicInfo/FactoryBasicInfo";
import BusinessInfo from "../BusinessInfo/BusinessInfo";
import Register from "../RegisterInfo/RegisterInfo";
import Patent from "../PatentInfo/PatentInfo";
import ImportExport from "../ImportExportInfo/ImportExportInfo";
import Stock from "../StockInfo/StockInfo";
import FactoryNews from "../FactoryNews/FactoryNews";
import Award from "../AwardInfo/AwardInfo";
import ServiceHistory from "../ServiceHistory/ServiceHistory";
import GovProBidding from "../GovProBidding/GovProBidding";
import Verdict from "../Verdict/Verdict";
import GovernmentPlans from "../GovernmentPlans/GovernmentPlans";
import {
  getCompanyInfoListHandler,
  getEnterpriseQualifiedHandler,
  getCompanyAbstractHandler,
  handleGetForeignTradesCompare,
  getCompanyAssociationHandler,
  handleGetGovProBidding,
  handleGetVerdict,
  handleGetBusinessInfo,
  getFactoryTaxInfoHandler,
  getCompanyInvestBackTWHandler,
  getForeignTrades4CodeProductHandler,
  handleGetBoardMember,
  handleGetRegisteredOperationItems,
  handleGetBranchCompanyInfo,
  handleGetHistoryTradeInfo,
  handleGetPatentInfo,
  handleGetTrademark,
  handleGovernmentPlansInfo,
  handleGetAwardInfo,
  handleGetMITMarkInfo,
  handleGetNewsInfo,
  handleGetNewsTag,
  handleGetServiceHistory,
  handleGetManagerInfo,
  handleGovernmentPlansInfoSubsidy,
  handleGetNegative,
} from "../../../store/actions/factory";
import { handleUpdateBusinessNumberByParkInfo } from "../../../store/actions/custom_dashboard";
import LoginRequired from "../../../components/LoginRequired/LoginRequiredV2";
import AddIcon from "@mui/icons-material/Add";
import NegativeNews from "../NegativeNews/NegativeNews";

// import ExcelGenerator from "../../../utility/excelGenerator";

// import { getCompanyBasicInfo } from "../../../utility/factoryApi";
import {
  getCompanyStockInfo,
  getStockIndex,
  getStockMonthRevenue,
  getCompanyStockPhysique,
  getStockInfo,
  getTradeResearch,
  getTradeEPS,
  getTradeRevenue,
  getCompanyGHGInfo,
} from "../../../utility/api";
import { getPdfHandler } from "./api";
import AddNumberToCustomDashboard from "../../../components/AddNumberToCustomDashboard";


import "./styles.scss";
import * as Style from "./styles";
import Swal from "sweetalert2";

const FactoryInfo = (props) => {
  const history = useHistory();
  const companyNameRef = React.useRef(null);
  const [state, setState] = React.useState({
    elScrollLeft: 0,
    elScrollWidth: 0,
    elClientWidth: 0,
    setIsShowLeftArrow: false,
    setIsShowRightArrow: false,
    is_add_custom_board_modal_open: false,
    business_no: ""
  });
  let businessId;
  let business_name;
  const dispatch = useDispatch();
  const {
    boardMember,
    registeredOperationItems,
    associationCompany,
    getUpdateCompanyInfoData,
    getUpdateCompanyInfoLoading,
    basicInfo,
    basicInfoError,
    factoryTaxInfo,
    ForeignTrades4CodeProduct,
    getForeignTradesCompareData,
    historyTradeInfo,
    patentInfo,
    getTrademarkData,
    governmentPlansInfo,
    awardInfo,
    MITMarkInfo,
    getBusinessInfoData,
    newsInfo,
    serviceHistoryInfo,
    getGovProBiddingData,
    getVerdictData,
    governmentPlansInfoSubsidy,
    getNegativeData,
    getEnterpriseQualifiedData
  } = useSelector((state) => state.factory);
  const { innerWidth } = useSelector((state) => state.app);
  if (props.location.state) {
    businessId = props.location.state.businessId;
  } else {
    if (window.location.pathname.indexOf("-") > -1) {
      const business = window.location.pathname.split("/")[3].split("-");
      businessId = business[0];
      business_name = decodeURI(business[1]);
    } else {
      businessId = window.location.pathname.split("/")[3];
    }
  }

  React.useEffect(() => {
    dispatch(handleUpdateBusinessNumberByParkInfo(businessId))
  }, [businessId])

  React.useEffect(() => {
    if (props.location.state) {
      setState(state => ({ ...state, business_no: props.location.state.businessId }))
    } else {
      if (window.location.pathname.indexOf("-") > -1) {
        const business = window.location.pathname.split("/")[3].split("-");
        setState(state => ({ ...state, business_no: business[0] }))
      } else {
        setState(state => ({ ...state, business_no: window.location.pathname.split("/")[3] }))
      }
    }
  }, [props, window.location])

  const [CompanyStockInfo, setCompanyStockInfo] = useState([]);
  const [CompanyStockPhysique, setCompanyStockPhysique] = useState([]);
  const [stockResearch, setStockResearch] = useState({
    trade: [],
    tradeLabels: [],
    List: [],
  });
  const [stockEPS, setStockEPS] = useState({
    trade: [],
    tradeLabels: [],
    List: [],
  });
  const [stockRevenue, setStockRevenue] = useState({
    trade: [],
    tradeLabels: [],
    List: [],
  });
  const [stockMonthRevenue, setStockMonthRevenue] = useState({
    trade: [],
    tradeLabels: [],
    List: [],
  });
  const [stockNonOperatingIncomeExpenses, setNonOperatingIncomeExpenses] =
    useState({
      trade: [],
      tradeLabels: [],
      List: [],
    });
  const [stockGrossProfitMargin, setStockGrossProfitMargin] = useState({
    trade: [],
    tradeLabels: [],
    List: [],
  });
  const [stockOperatingProfitMargin, setStockOperatingProfitMargin] = useState({
    trade: [],
    tradeLabels: [],
    List: [],
  });
  const [stockNetIncomeMargin, setStockNetIncomeMargin] = useState({
    trade: [],
    tradeLabels: [],
    List: [],
  });
  const [stockFreeCashFlow, setStockFreeCashFlow] = useState({
    trade: [],
    tradeLabels: [],
    List: [],
  });
  const [stockROE, setStockROE] = useState({
    trade: [],
    tradeLabels: [],
    List: [],
  });
  const [stockDividendYield, setStockDividendYield] = useState({
    trade: [],
    tradeLabels: [],
    List: [],
  });
  const [stockInfo, setStockInfo] = useState([]);
  const [tradeResearch, setTradeResearch] = useState({
    tradeResearch: [],
    tradeResearchLabels: "",
  });
  const [tradeEPS, setTradeEPS] = useState({
    getTradeEPS: [],
    getTradeEPSLabels: "",
  });
  const [tradeRevenue, setTradeRevenue] = useState({
    tradeRev: [],
    tradeRevLabels: "",
  });
  let { path, url } = useRouteMatch();

  const auth = localStorage.getItem("auL");

  React.useEffect(() => {
    const el = document.querySelector(".line-it-button");

    if (!el && businessId) {
      const script = document.createElement("script");
      script.async = true;
      script.defer = true;
      script.src =
        "https://www.line-website.com/social-plugins/js/thirdparty/loader.min.js";
      const lineDiv = document.createElement("div");
      lineDiv.classList.add("line-it-button");
      lineDiv.setAttribute("data-lang", "zh_Hant");
      lineDiv.setAttribute("data-type", "share-a");
      lineDiv.setAttribute("data-ver", "3");
      if (business_name) {
        const baseUrl = `${window.location.origin}`;
        lineDiv.setAttribute(
          "data-url",
          `${baseUrl}${url}/basic_info/${businessId}-${business_name}`
        );
      } else {
        const baseUrl = `${window.location.origin}`;
        lineDiv.setAttribute(
          "data-url",
          `${baseUrl}${url}/basic_info/${businessId}`
        );
      }

      lineDiv.setAttribute("data-color", "grey");
      lineDiv.setAttribute("data-size", "large");
      lineDiv.setAttribute("data-count", "false");

      document.body.appendChild(script);
      if (document.getElementsByClassName("line-share") && document.getElementsByClassName("line-share")?.[0]) {
        document.getElementsByClassName("line-share")[0].appendChild(lineDiv);
      }

      return () => {
        document.body.removeChild(script); // This will remove the script on unmount
      };
    }
  }, [businessId, business_name]);

  React.useEffect(() => {
    //個廠情資資料如果有自訂資料的話會被 push 到自訂資料的 tab。
    if (!getUpdateCompanyInfoLoading && getUpdateCompanyInfoData.Name)
      history.push(`/factory_info/updated_companyinfo/${businessId}`);
  }, [getUpdateCompanyInfoData]);

  React.useEffect(() => {
    scrollTop();
  }, [window.location.pathname.split("/")[2]]);

  React.useEffect(() => {
    if (!businessId) {
      history.push("/factory_search");
    }
    if (basicInfoError && basicInfoError !== "") {
      Swal.fire({
        text: `統編錯誤，請重新搜尋`,
        confirmButtonText: "確認",
      }).then(() => {
        history.push("/factory_search");
      });
    }
  }, [businessId, basicInfoError, history]);

  React.useEffect(() => {
    handleRequests(businessId);
    const valueFormatter = (listData) => {
      const tempList = [];
      if (listData.length !== 0 && listData) {
        listData.map((item) => {
          tempList.push(item.y);
        });
      }
      return tempList;
    };

    const labelsFormatter = (listData) => {
      const tempList = [];

      if (listData.length !== 0 && listData) {
        listData.map((item) => {
          tempList.push(item.label);
        });

        return tempList;
      }
    };

    const updateStockIndexLineChart = (
      LineValue,
      LineLabel,
      ListValue,
      IndexKey
    ) => {
      const update = {
        trade: LineValue,
        tradeLabels: LineLabel,
        List: ListValue,
      };
      switch (IndexKey) {
        case "Revenue":
          setStockRevenue(update);
          break;
        case "MonthRevenue":
          setStockMonthRevenue(update);
          break;
        case "EPS":
          setStockEPS(update);
          break;
        case "Research":
          setStockResearch(update);
          break;
        case "GrossProfitMargin":
          setStockGrossProfitMargin(update);
          break;
        case "OperatingProfitMargin":
          setStockOperatingProfitMargin(update);
          break;
        case "NetIncomeMargin":
          setStockNetIncomeMargin(update);
          break;
        case "FreeCashFlow":
          setStockFreeCashFlow(update);
          break;
        case "ROE":
          setStockROE(update);
          break;
        case "DividendYield":
          setStockDividendYield(update);
          break;
        case "NonOperatingIncomeExpenses":
          setNonOperatingIncomeExpenses(update);
          break;
        case "GHG":
          const GHGUpdate = {
            trade: LineValue,
            tradeLabels: LineLabel,
            GHGDataList: ListValue,
          };
          setGHGData(GHGUpdate);
          break;
        default:
          break;
      }
    };

    const ProcessStock = (listData) => {
      const totalList = [];
      var tempList = [];
      let x = 0;
      const stockIndexKey = [
        "MonthRevenue",
        "Revenue",
        "EPS",
        "Research",
        "GrossProfitMargin",
        "OperatingProfitMargin",
        "NetIncomeMargin",
        "FreeCashFlow",
        "ROE",
        "DividendYield",
        "NonOperatingIncomeExpenses",
        "GHG",
      ];
      for (x = 0; x < stockIndexKey.length; x++) {
        if (!listData.LineChart[stockIndexKey[x]]) {
          continue;
        }
        var temp = listData.LineChart[stockIndexKey[x]].ChartObj.data[0].dataPoints;
        var LineLabel = temp.map((item) => Object.values(item)[0]);

        var LineValue = temp.map((item) => Object.values(item)[1]);

        var ListValue = listData.List[stockIndexKey[x]];
        //var List = temp.map(item => Object.values(item)[1]);
        tempList.push(LineValue);
        tempList.push(LineLabel);

        totalList.push(tempList);
        tempList = [];
        updateStockIndexLineChart(
          LineValue,
          LineLabel,
          ListValue,
          stockIndexKey[x]
        );
      }
      return tempList;
    };

    Promise.all([
      getStockInfo(businessId).then((res) => {
        if (res !== "error") {
          const tempList = [];

          res.map((item) => {
            tempList.push({
              年份: item.YY,
              "營業額(千元)": item.Revenue.toString().replace(
                /\B(?=(\d{3})+(?!\d))/g,
                ","
              ),
              EPS: item.EPS.toFixed(2),
              "研發投入比例(%)": item.ResearchRate.toFixed(2) + " %",
            });
          });

          setStockInfo(tempList);
          setState((state) => ({
            ...state,
            data: {
              ...state.data,
              stockInfo: tempList,
            },
          }));
        } else if (res === []) {
          setStockInfo({
            年份: "暫無資料",
            "營業額(千元)": "暫無資料",
            EPS: "暫無資料",
            "研發投入比例(%)": "暫無資料",
          });
        } else {
          setStockInfo("error");
        }
      }),
      getTradeResearch(businessId).then((res) => {
        if (res !== "error") {
          const updateTradeResearch = {
            tradeResearch: valueFormatter(res),
            tradeResearchLabels: labelsFormatter(res),
          };

          setTradeResearch(updateTradeResearch);
        } else if (res === []) {
          setTradeResearch({
            tradeResearch: [],
            tradeResearchLabels: "",
          });
        } else {
          setTradeResearch("error");
        }
      }),
      getTradeEPS(businessId).then((res) => {
        if (res !== "error") {
          const updateTradeEPS = {
            getTradeEPS: valueFormatter(res),
            getTradeEPSLabels: labelsFormatter(res),
          };
          setTradeEPS(updateTradeEPS);
        } else if (res === []) {
          setTradeEPS({
            getTradeEPS: [],
            getTradeEPSLabels: "",
          });
        } else {
          setTradeEPS("error");
        }
      }),
      getTradeRevenue(businessId).then((res) => {
        if (res !== "error") {
          const updateTradeRevenue = {
            tradeRev: valueFormatter(res),
            tradeRevLabels: labelsFormatter(res),
          };

          setTradeRevenue(updateTradeRevenue);
        } else if (res === []) {
          setTradeRevenue({
            tradeRev: [],
            tradeRevLabels: "",
          });
        } else {
          setTradeRevenue("error");
        }
      }),
      getCompanyStockInfo(businessId)
        .then((res) => {
          if (res !== "error") {
            const tempList = [];
            res.map((item) =>
              tempList.push({
                股票代號: item.stockno,
                // 公司統編: item.businessno,
                公司縮寫: item.simplename,
                股票發行狀態: item.type,
                股市產業分類: item.category,
                // "實收資本額(元)": item.CapitalReality.toString().replace(/\B(?=(\d{3})+(?!\d))/g ,","),
                "股本(千元)": item.Equity.toString().replace(
                  /\B(?=(\d{3})+(?!\d))/g,
                  ","
                ),
                員工人數:
                  item.Employee === 0
                    ? "無資料"
                    : item.Employee.toString().replace(
                      /\B(?=(\d{3})+(?!\d))/g,
                      ","
                    ),
                "每人營收(千元)":
                  item.Employee === 0
                    ? "無資料"
                    : item.AverageRevenue.toString().replace(
                      /\B(?=(\d{3})+(?!\d))/g,
                      ","
                    ),
                上次更新時間: item.UpdateTime,
              })
            );
            setCompanyStockInfo(tempList);
          }
        })
        .catch((err) => { }),

      getCompanyGHGInfo(businessId)
        .then((tempRes) => {
          var tempChartDict = {
            "ChartObj": {
              "title": {
                "text": "溫室氣體趨勢圖",
                "fontFamily": "微軟正黑體"
              },
              "axisX": {
                "title": "年",
                "labelFontFamily": "微軟正黑體",
                "titleFontFamily": "微軟正黑體",
                "interval": "1"
              },
              "axisY": {
                "title": "噸CO2e/百萬營收",
                "labelFontFamily": "微軟正黑體",
                "titleFontFamily": "微軟正黑體"
              },
              "data": [
                {
                  "type": "line",
                  "showInLegend": "true",
                  "legendMarkerColor": "grey",
                  "legendText": "",
                  "dataPoints": tempRes.data,
                }
              ]
            }
          };
          getStockMonthRevenue(businessId)
            .then((res) => {
              let stockMonthRevenueData = {
                'LineChart': { 'MonthRevenue': {} },
                'List': { 'MonthRevenue': {} },
              };
              let tempMonthRevenueDataList = [];
              let tempMonthRevenueHistoryDataList = [];
              for (let labelIndex = 0; labelIndex < res.length; labelIndex++) {
                tempMonthRevenueDataList.push({
                  'label': parseInt(res[labelIndex].AnnouncementDate.replace("-01T00:00:00", "").split('-')[0]) - 1911 + "/" + res[labelIndex].AnnouncementDate.replace("-01T00:00:00", "").split('-')[1],
                  'y': res[labelIndex].SaleMonth,
                })
                tempMonthRevenueHistoryDataList.push({
                  'YY': parseInt(res[labelIndex].AnnouncementDate.replace("-01T00:00:00", "").split('-')[0]) - 1911,
                  'Month': res[labelIndex].AnnouncementDate.replace("-01T00:00:00", "").split('-')[1],
                  'TotalVALUE': res[labelIndex].SaleAccumulated,
                  'VALUE': res[labelIndex].SaleMonth,
                })
              }
              let sortedData = tempMonthRevenueDataList.sort((p1, p2) => (p2.label < p1.label) ? 1 : (p2.label > p1.label) ? -1 : 0);
              let monthRevenueChartDict = JSON.parse(JSON.stringify(tempChartDict));
              monthRevenueChartDict.ChartObj.title.text = "股市資料每月營業額趨勢";
              monthRevenueChartDict.ChartObj.data[0].dataPoints = sortedData;
              stockMonthRevenueData['LineChart']['MonthRevenue'] = monthRevenueChartDict;
              stockMonthRevenueData['List']['MonthRevenue'] = tempMonthRevenueHistoryDataList;
              ProcessStock(stockMonthRevenueData);
            })
            .catch((err) => { })
          getStockIndex(businessId)
            .then((res) => {
              res.List.GHG = tempRes.data;
              res.LineChart.GHG = tempChartDict;
              ProcessStock(res);
            })
            .catch((err) => { })
        })
        .catch((err) => { }),
      getCompanyStockPhysique(businessId)
        .then((res) => {
          setCompanyStockPhysique(res);
        })
        .catch((err) => { }),
    ]);
  }, [businessId, dispatch]);

  const handleRequests = React.useCallback(
    (businessId) => {
      Promise.all([
        dispatch(getCompanyInfoListHandler(businessId)),
        dispatch(getCompanyAbstractHandler(businessId)),
        dispatch(getEnterpriseQualifiedHandler(businessId)),
        dispatch(handleGetTrademark(businessId)),
        dispatch(getFactoryTaxInfoHandler(businessId)),
        dispatch(getCompanyInvestBackTWHandler(businessId)),
      ]);
    },
    [dispatch]
  );

  const [otherLoad, setOtherLoad] = useState(false);
  useEffect(() => {
    if (!otherLoad && !window.location.pathname.includes("basic_info")) {
      setOtherLoad(true)
      Promise.all([
        localStorage.getItem("isLogin") && dispatch(handleGetTrademark(businessId)),
        localStorage.getItem("isLogin") && dispatch(handleGetVerdict(businessId)),
        localStorage.getItem("isLogin") && dispatch(handleGetMITMarkInfo(businessId)),
        dispatch(getCompanyAssociationHandler(businessId)),
        dispatch(handleGetForeignTradesCompare(businessId)),
        dispatch(handleGetGovProBidding(businessId)),
        dispatch(handleGetBusinessInfo(businessId)),
        dispatch(handleGetBoardMember(businessId)),
        dispatch(getForeignTrades4CodeProductHandler(businessId)),
        dispatch(handleGetRegisteredOperationItems(businessId)),
        dispatch(handleGetBranchCompanyInfo(businessId)),
        dispatch(handleGetHistoryTradeInfo(businessId)),
        dispatch(handleGetPatentInfo(businessId)),
        dispatch(handleGovernmentPlansInfo(businessId)),
        dispatch(handleGetAwardInfo(businessId)),
        dispatch(handleGetNewsInfo(businessId)),
        dispatch(handleGetNewsTag(businessId)),
        dispatch(
          handleGetServiceHistory(businessId, localStorage.getItem("uid"))
        ),
        dispatch(handleGetManagerInfo(businessId)),
        dispatch(handleGovernmentPlansInfoSubsidy(businessId)),
        dispatch(handleGetNegative(businessId)),
      ]);
    }
  }, [window.location.pathname])
  const getPdf = async () => {
    const pdf = await getPdfHandler(businessId);
  };

  const handleMenuOffsetWidth = (e) => {
    const { clientWidth, scrollLeft, scrollWidth } = e.target;
    setState((state) => ({
      ...state,
      elScrollLeft: scrollLeft,
      elScrollWidth: scrollWidth,
      elClientWidth: clientWidth,
    }));
  };

  const scrollTop = () =>
    companyNameRef.current.scrollTo({ top: 0, behavior: "smooth" });

  const open_modal = () => setState(state => ({ ...state, is_add_custom_board_modal_open: true }));

  const close_modal = () => setState(state => ({ ...state, is_add_custom_board_modal_open: false }));

  const [GHGData, setGHGData] = useState({
    trade: [],
    tradeLabels: [],
    GHGDataList: [],
  });

  return (
    <React.Fragment>
      {business_name ? (
        <Helmet>
          <title>{`${business_name}-產業園區廠商情資平台`}</title>
          <meta name="description" />
          <link rel="canonical" />
        </Helmet>
      ) : basicInfo.CompanyName ? (
        <Helmet>
          <title>{`${basicInfo.CompanyName}-產業園區廠商情資平台`}</title>
          <meta
            name="description"
            content={
              basicInfo.CompanyName +
              basicInfo.Addr +
              basicInfo.Fax +
              basicInfo.Introduction
            }
          />
          <link rel="canonical" href={basicInfo.WebSite} />
        </Helmet>
      ) : (
        <Helmet>
          <title>{`產業園區廠商情資平台`}</title>
          <link rel="canonical" href={""} />
        </Helmet>
      )}
      <Style.FactoryInfoPage>
        <AddNumberToCustomDashboard
          type="FactoryInfo"
          is_show_default_add
          is_open={state.is_add_custom_board_modal_open}
          handleClose={close_modal}
          business_no={state.business_no}
          custom_style={innerWidth < 750 ? { transform: "translate(-10px, -6px)" } : { transform: "translate(-36px, -10px)" }}
        />
        <Style.FactoryPage>
          <div className="factory-info-header">
            <div className="factory-info-header-company-name">
              <Style.FactoryPageHeaderText>
                {business_name || basicInfo.CompanyName}
              </Style.FactoryPageHeaderText>
              {
                localStorage.getItem('uid') && <div className="line-share"></div>
              }
            </div>
            {
              auth && auth <= 3 && (
                <Style.MobileExportPDFContainer>
                  <Style.MobileExportPDFIcon icon={faFilePdf} onClick={getPdf} />
                </Style.MobileExportPDFContainer>
              )
            }
            {/* 先關閉手機自定義關注儀表板 */}
            {/* <Style.MobileExportPDFContainer style={{transform:"translateX(83px)"}}>
              <Style.MobileExportPDFIcon icon={faPlus} onClick={open_modal} />
            </Style.MobileExportPDFContainer> */}

            <div className="export-buttons-container">
              {/* <ExcelGenerator data={state.data}/> */}
              {
                localStorage.getItem('uid') &&
                <Button
                  onClick={open_modal}
                  className="export-pdf-btn"
                  startIcon={<AddIcon />}
                >
                  自訂關注廠商儀表板
                </Button>
              }
              {auth && auth <= 3 && (
                <Button
                  onClick={getPdf}
                  className="export-pdf-btn"
                  startIcon={
                    <FontAwesomeIcon
                      className="export-pdf-icon"
                      icon={faFilePdf}
                    />
                  }
                >
                  匯出 PDF 檔
                </Button>
              )}
            </div>

          </div>
          <Style.MenuContainer
            onScroll={(e) => {
              handleMenuOffsetWidth(e);
            }}
          >
            {getUpdateCompanyInfoData.Name && (
              <Style.CustomLink
                to={`${url}/updated_companyinfo/${businessId}`}
                activeClassName="selected"
                iswithdata={true}
              >
                自訂資料
              </Style.CustomLink>
            )}
            <Style.CustomLink
              to={business_name ? `${url}/basic_info/${businessId}-${business_name}` : `${url}/basic_info/${businessId}`}
              activeClassName="selected"
              iswithdata={
                Object.values(basicInfo).length > 0 || factoryTaxInfo.length > 0 || getEnterpriseQualifiedData.length > 0
              }
            >
              基本資料
            </Style.CustomLink>

            <Style.CustomLink
              to={`${url}/business_info/${businessId}`}
              activeClassName="selected"
              iswithdata={
                otherLoad ? (boardMember.length > 0 ||
                  registeredOperationItems.length > 0 ||
                  associationCompany.subCompanyList.length > 0) : true
              }
            >
              商工資料
            </Style.CustomLink>

            <Style.CustomLink
              to={`${url}/register_info/${businessId}`}
              activeClassName="selected"
              iswithdata={otherLoad ? getBusinessInfoData.length > 0 : true}
            >
              工廠登記
            </Style.CustomLink>
            <Style.CustomLink
              to={`${url}/import&export_info/${businessId}`}
              activeClassName="selected"
              iswithdata={otherLoad ? (historyTradeInfo.length > 0 || getForeignTradesCompareData.length > 0) : true
              }
            >
              進出口
            </Style.CustomLink>

            <Style.CustomLink
              to={`${url}/patent_info/${businessId}`}
              activeClassName="selected"
              iswithdata={(otherLoad ? (patentInfo.length > 0 || getTrademarkData.length > 0) : true)}
            >
              專利資料
            </Style.CustomLink>

            <Style.CustomLink
              to={`${url}/plan_info/${businessId}`}
              activeClassName="selected"
              iswithdata={((otherLoad ? governmentPlansInfo.length > 0 : true) || (otherLoad ? governmentPlansInfoSubsidy.length > 0 : true))}
            >
              政府計畫
            </Style.CustomLink>
            <Style.CustomLink
              to={`${url}/award/${businessId}`}
              activeClassName="selected"
              iswithdata={otherLoad ? (awardInfo.length > 0 || MITMarkInfo.length > 0 || getEnterpriseQualifiedData.length > 0) : true}
            >
              獲獎資料
            </Style.CustomLink>
            <Style.CustomLink
              to={`${url}/news/${businessId}`}
              activeClassName="selected"
              iswithdata={otherLoad ? newsInfo.length > 0 : true}
            >
              相關新聞
            </Style.CustomLink>
            <Style.CustomLink
              to={`${url}/stock_info/${businessId}`}
              activeClassName="selected"
              iswithdata={otherLoad ? CompanyStockInfo.length > 0 : true}
            >
              股市資料
            </Style.CustomLink>
            <Style.CustomLink
              to={`${url}/service_info/${businessId}`}
              activeClassName="selected"
              iswithdata={otherLoad ? serviceHistoryInfo.data.length > 0 : true}
            >
              服務歷程
            </Style.CustomLink>
            <Style.CustomLink
              to={`${url}/gov_pro_bidding/${businessId}`}
              activeClassName="selected"
              iswithdata={otherLoad ? getGovProBiddingData.length > 0 : true}
            >
              政府標案
            </Style.CustomLink>
            <Style.CustomLink
              to={`${url}/verdict/${businessId}`}
              activeClassName="selected"
              iswithdata={otherLoad ? getVerdictData.length > 0 : true}
            >
              智財資料
            </Style.CustomLink>
            {((localStorage.getItem("auL") == 1) || (localStorage.getItem("auL") == 2) || (localStorage.getItem("auL") == 3)) ?
              <Style.CustomLink
                to={`${url}/negative_news/${businessId}`}
                activeClassName="selected"
                iswithdata={((otherLoad ? getNegativeData.data_labour_law?.length > 0 : true) || (otherLoad ? getNegativeData.data_gender_law?.length > 0 : true) || (otherLoad ? getNegativeData.data_retirement?.length > 0 : true))}
              >
                負面訊息
              </Style.CustomLink>
              : null}
          </Style.MenuContainer>
        </Style.FactoryPage>
        <Style.FactoryContainer ref={companyNameRef} id="#head">
          <BackTopTopContainer
            className="back-to-top"
            onClick={scrollTop}
            style={{ right: "inherit" }}
          >
            <Icon className="icon">keyboard_arrow_up</Icon>
          </BackTopTopContainer>
          <Switch>
            <Route
              path={`${path}basic_info/:businessId`}
              component={FactoryBasicInfo}
            />
            <Route
              path={`${path}business_info/:businessId`}
              component={BusinessInfo}
            />
            <LoginRequired
              exact
              path={`${path}register_info/:businessId`}
              component={Register}
            />
            <LoginRequired
              exact
              path={`${path}news/:businessId`}
              render={() => <FactoryNews businessId={businessId} />}
            />
            <LoginRequired
              exact
              path={`${path}plan_info/:businessId`}
              component={GovernmentPlans}
            />
            <LoginRequired
              exact
              path={`${path}award/:businessId`}
              component={Award}
            />
            <LoginRequired
              exact
              path={`${path}import&export_info/:businessId`}
              component={ImportExport}
            />
            <LoginRequired
              exact
              path={`${path}patent_info/:businessId`}
              component={Patent}
            />
            <LoginRequired
              exact
              path={`${path}service_info/:businessId`}
              component={ServiceHistory}
            />
            <LoginRequired
              exact
              path={`${path}gov_pro_bidding/:businessId`}
              component={GovProBidding}
            />
            <LoginRequired
              exact
              path={`${path}verdict/:businessId`}
              component={Verdict}
            />
            <Route
              exact
              path={`${path}updated_companyinfo/:businessId`}
              render={() => <UpdatedCompanyInfo businessId={businessId} />}
            />
            <LoginRequired
              exact
              path={`${path}stock_info/:businessId`}
              render={(props) => (
                <Stock
                  stock={stockInfo}
                  StockPhysique={CompanyStockPhysique}
                  tradeResearch={tradeResearch.tradeResearch}
                  tradeResearchLabels={tradeResearch.tradeResearchLabels}
                  tradeRev={tradeRevenue.tradeRev}
                  tradeRevLabels={tradeRevenue.tradeRevLabels}
                  getTradeEPS={tradeEPS.getTradeEPS}
                  getTradeEPSLabels={tradeEPS.getTradeEPSLabels}
                  //getStockIndex={AllStockIndex}
                  getCompanyStockInfo={CompanyStockInfo}
                  stockResearch={stockResearch.trade}
                  stockResearchLabels={stockResearch.tradeLabels}
                  stockResearchList={stockResearch.List}
                  stockRevenue={stockRevenue.trade}
                  stockRevenueLabels={stockRevenue.tradeLabels}
                  stockRevenueList={stockRevenue.List}
                  stockMonthRevenue={stockMonthRevenue.trade}
                  stockMonthRevenueLabels={stockMonthRevenue.tradeLabels}
                  stockMonthRevenueList={stockMonthRevenue.List}
                  stockEPS={stockEPS.trade}
                  stockEPSLabels={stockEPS.tradeLabels}
                  stockEPSList={stockEPS.List}
                  stockGrossProfitMargin={stockGrossProfitMargin.trade}
                  stockGrossProfitMarginLabels={
                    stockGrossProfitMargin.tradeLabels
                  }
                  stockGrossProfitMarginList={stockGrossProfitMargin.List}
                  stockOperatingProfitMargin={stockOperatingProfitMargin.trade}
                  stockOperatingProfitMarginLabels={
                    stockOperatingProfitMargin.tradeLabels
                  }
                  stockOperatingProfitMarginList={
                    stockOperatingProfitMargin.List
                  }
                  stockNetIncomeMargin={stockNetIncomeMargin.trade}
                  stockNetIncomeMarginLabels={stockNetIncomeMargin.tradeLabels}
                  stockNetIncomeMarginList={stockNetIncomeMargin.List}
                  stockFreeCashFlow={stockFreeCashFlow.trade}
                  stockFreeCashFlowLabels={stockFreeCashFlow.tradeLabels}
                  stockFreeCashFlowList={stockFreeCashFlow.List}
                  stockROE={stockROE.trade}
                  stockROELabels={stockROE.tradeLabels}
                  stockROEList={stockROE.List}
                  stockDividendYield={stockDividendYield.trade}
                  stockDividendYieldLabels={stockDividendYield.tradeLabels}
                  stockDividendYieldList={stockDividendYield.List}
                  stockNonOperatingIncomeExpenses={
                    stockNonOperatingIncomeExpenses.trade
                  }
                  stockNonOperatingIncomeExpensesLabels={
                    stockNonOperatingIncomeExpenses.tradeLabels
                  }
                  stockNonOperatingIncomeExpensesList={
                    stockNonOperatingIncomeExpenses.List
                  }
                  GHGData={GHGData.trade}
                  GHGDataLabels={GHGData.tradeLabels}
                  GHGDataList={GHGData.GHGDataList}
                />
              )}
            />
            <LoginRequired
              exact
              path={`${path}negative_news/:businessId`}
              component={NegativeNews}
            />
          </Switch>
        </Style.FactoryContainer>
      </Style.FactoryInfoPage>
    </React.Fragment>
  );
};

const BackTopTopContainer = styled.div`
  display: none;
  @media (max-width: 1023px) {
    width: 60px;
    height: 60px;
    background-color: rgba(46, 182, 170, 0.5);
    border-radius: 30px;
    position: fixed;
    display: flex !important;
    justify-content: center;
    align-items: center;
    bottom: 50px;
    right: 15px;
    z-index: 1000;

    .icon {
      color: #ffffff;
      font-size: 250%;
      cursor: pointer;
    }
  }
`;

export default FactoryInfo;