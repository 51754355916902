import * as actionTypes from "../actionTypes/parkDashboard_beta";
import * as parkApi from "../../utility/parkDashboradApi";

//取得儀表板資訊
export const startFetchSectionMap = () => {
  return {
    type: actionTypes.START_GET_SECTION_MAP,
  };
};

export const fetchSectionMapSuccess = (data) => {
  return {
    type: actionTypes.GET_SECTION_MAP_SUCCESS,
    data: data,
  };
};

export const fetchSectionMapFail = (err) => {
  return {
    type: actionTypes.GET_SECTION_MAP_FAIL,
    err: err,
  };
};

export const getSectionMapHandler = (uid) => {
  return (dispatch) => {
    dispatch(startFetchSectionMap());
    parkApi
      .getSectionMap(uid)
      .then((res) => {
        if (res) {
          dispatch(fetchSectionMapSuccess(res.data));
        } else {
          dispatch(fetchSectionMapFail("取得儀表板資訊時發生問題。"));
        }
      })
      .catch((err) => {
        dispatch(fetchSectionMapFail(err));
      });
  };
};

//修改儀表板資訊
export const startUpdateSectionMap = () => {
  return {
    type: actionTypes.START_UPDATE_SECTION_MAP,
  };
};

export const updateSectionMapSuccess = (data) => {
  return {
    type: actionTypes.UPDATE_SECTION_MAP_SUCCESS,
    data: data,
  };
};

export const updateSectionMapFail = (err) => {
  return {
    type: actionTypes.UPDATE_SECTION_MAP_FAIL,
    err: err,
  };
};

export const getUpdateSectionMapHandler = (data, uid) => {
  return (dispatch) => {
    dispatch(startUpdateSectionMap());
    parkApi
      .updateSectionMapHandler(data, uid)
      .then((res) => {
        if (res) {
          dispatch(updateSectionMapSuccess(res));
        } else {
          dispatch(updateSectionMapFail("修改儀表板資訊時發生問題。"));
        }
      })
      .catch((err) => {
        dispatch(updateSectionMapFail(err));
      });
  };
};

// 取得同服務中心的園區
export const startServiceCenterParkLinkData = () => {
  return {
    type: actionTypes.START_GET_SERVICE_CENTER_PARK_LINK_DATA,
  };
};

export const getServiceCenterParkLinkDataSuccess = (data) => {
  return {
    type: actionTypes.GET_SERVICE_CENTER_PARK_LINK_DATA_SUCCESS,
    data: data,
  };
};

export const getServiceCenterParkLinkDataFail = (err) => {
  return {
    type: actionTypes.GET_SERVICE_CENTER_PARK_LINK_DATA_FAIL,
    err: err,
  };
};

export const getServiceCenterParkLinkDataHandler = (parkId) => {
  return (dispatch) => {
    dispatch(startServiceCenterParkLinkData());
    parkApi
      .fetchServiceCenterParkLinkData(parkId)
      .then((res) => {
        if (res) {
          dispatch(getServiceCenterParkLinkDataSuccess(res));
        } else {
          dispatch(
            getServiceCenterParkLinkDataFail(
              "取得同服務中心的園區連結資料時發生問題。"
            )
          );
        }
      })
      .catch((err) => {
        dispatch(getServiceCenterParkLinkDataFail(err));
      });
  };
};

// 取得概況全國與地區排名(?)
export const startGetBriefAndRankingData = () => {
  return {
    type: actionTypes.START_GET_BRIEF_AND_RANKING_DATA,
  };
};

export const getBriefAndRankingDataSuccess = (data) => {
  return {
    type: actionTypes.GET_BRIEF_AND_RANKING_DATA_SUCCESS,
    data: data,
  };
};

export const getBriefAndRankingDataFail = (err) => {
  return {
    type: actionTypes.GET_BRIEF_AND_RANKING_DATA_FAIL,
    err: err,
  };
};

export const getBriefAndRankingDataHandler = (city) => {
  return (dispatch) => {
    dispatch(startGetBriefAndRankingData());
    parkApi
      .fetchBriefAndRankingData(city)
      .then((res) => {
        if (res) {
          dispatch(getBriefAndRankingDataSuccess(res));
        } else {
          dispatch(
            getBriefAndRankingDataSuccess("取得概況全國與地區排名時發生問題。")
          );
        }
      })
      .catch((err) => {
        dispatch(getBriefAndRankingDataFail(err));
      });
  };
};

// 取得縣市租用土地資料
export const startFactoryAreaRentData = () => {
  return {
    type: actionTypes.START_GET_FACTORY_AREA_RENT_DATA,
  };
};

export const getFactoryAreaRentDataSuccess = (data) => {
  return {
    type: actionTypes.GET_FACTORY_AREA_RENT_DATA_SUCCESS,
    data: data,
  };
};

export const getFactoryAreaRentDataFail = (err) => {
  return {
    type: actionTypes.GET_FACTORY_AREA_RENT_DATA_FAIL,
    err: err,
  };
};

export const getFactoryAreaRentDataHandler = (area) => {
  return (dispatch) => {
    dispatch(startFactoryAreaRentData());
    parkApi
      .fetchFactoryAreaRentData(area)
      .then((res) => {
        if (res) {
          dispatch(getFactoryAreaRentDataSuccess(res));
        } else {
          dispatch(
            getFactoryAreaRentDataFail("取得縣市租用土地資料時發生問題。")
          );
        }
      })
      .catch((err) => {
        dispatch(getFactoryAreaRentDataFail(err));
      });
  };
};

//取得單一園區 主要指標
export const startGetSectionIndexData = () => {
  return {
    type: actionTypes.START_GET_SECTION_INDEX_DATA,
  };
};

export const getSectionIndexDataSuccess = (data) => {
  return {
    type: actionTypes.GET_SECTION_INDEX_DATA_SUCCESS,
    data: data,
  };
};

export const getSectionIndexDataFail = (err) => {
  return {
    type: actionTypes.GET_SECTION_INDEX_DATA_FAIL,
    err: err,
  };
};

export const getSectionIndexHandler = (parkId) => {
  return (dispatch) => {
    dispatch(startGetSectionIndexData());
    parkApi
      .fetchSectionIndex(parkId)
      .then((res) => {
        if (res) {
          dispatch(getSectionIndexDataSuccess(res));
        } else {
          dispatch(getSectionIndexDataFail("取得單一園區主要指標時發生問題。"));
        }
      })
      .catch((err) => {
        dispatch(getSectionIndexDataFail(err));
      });
  };
};

//取得單一園區 新聞標籤
export const startGetSectionNewsTagData = () => {
  return {
    type: actionTypes.START_GET_SECTION_NEWS_TAG_DATA,
  };
};

export const getSectionNewsTagDataSuccess = (data) => {
  return {
    type: actionTypes.GET_SECTION_NEWS_TAG_DATA_SUCCESS,
    data: data,
  };
};

export const getSectionNewsTagDataFail = (err) => {
  return {
    type: actionTypes.GET_SECTION_NEWS_TAG_DATA_FAIL,
    err: err,
  };
};

export const getSectionNewsTagHandler = (parkId) => {
  return (dispatch) => {
    dispatch(startGetSectionNewsTagData());
    parkApi
      .fetchSectionNewsTag(parkId)
      .then((res) => {
        if (res) {
          dispatch(getSectionNewsTagDataSuccess(res));
        } else {
          dispatch(
            getSectionNewsTagDataFail("取得單一園區新聞標籤時發生問題。")
          );
        }
      })
      .catch((err) => {
        dispatch(getSectionNewsTagDataFail(err));
      });
  };
};

//取得單一園區 新聞內容
export const startGetSectionNewsContentData = () => {
  return {
    type: actionTypes.START_GET_SECTION_NEWS_CONTENT_DATA,
  };
};

export const getSectionNewsContentDataSuccess = (data) => {
  return {
    type: actionTypes.GET_SECTION_NEWS_CONTENT_SUCCESS,
    data: data,
  };
};

export const getSectionNewsContentDataFail = (err) => {
  return {
    type: actionTypes.GET_SECTION_NEWS_CONTENT_FAIL,
    err: err,
  };
};

export const getSectionNewsContentHandler = (parkId, item, pagination) => {
  return (dispatch) => {
    dispatch(startGetSectionNewsContentData());
    parkApi
      .fetchSectionNewsContent(parkId, item, pagination)
      .then((res) => {
        if (res) {
          dispatch(getSectionNewsContentDataSuccess(res));
        } else {
          dispatch(
            getSectionNewsContentDataFail("取得單一園區新聞內容時發生問題。")
          );
        }
      })
      .catch((err) => {
        dispatch(getSectionNewsContentDataFail(err));
      });
  };
};

//取得單一園區 公開發行占比
export const startGetSectionPublicSharingData = () => {
  return {
    type: actionTypes.START_GET_SECTION_PUBLIC_SHARING_DATA,
  };
};

export const getSectioPublicSharingDataSuccess = (data) => {
  return {
    type: actionTypes.GET_SECTION_PUBLIC_SHARING_SUCCESS,
    data: data,
  };
};

export const getSectionPublicSharingDataFail = (err) => {
  return {
    type: actionTypes.GET_SECTION_PUBLIC_SHARING_FAIL,
    err: err,
  };
};

export const getSectionPublicSharingHandler = (parkId) => {
  return (dispatch) => {
    dispatch(startGetSectionPublicSharingData());
    parkApi
      .fetchSectionPublicSharing(parkId)
      .then((res) => {
        if (res) {
          dispatch(getSectioPublicSharingDataSuccess(res));
        } else {
          dispatch(
            getSectionPublicSharingDataFail(
              "取得單一園區公開發行占比時發生問題。"
            )
          );
        }
      })
      .catch((err) => {
        dispatch(getSectionPublicSharingDataFail(err));
      });
  };
};

//取得單一園區 股票公開發行前十大廠商清單
export const startGetSectionPublicData = () => {
  return {
    type: actionTypes.START_GET_SECTION_PUBLIC_DATA,
  };
};

export const getSectionPublicDataSuccess = (data) => {
  return {
    type: actionTypes.GET_SECTION_PUBLIC_SUCCESS,
    data: data,
  };
};

export const getSectionPublicDataFail = (err) => {
  return {
    type: actionTypes.GET_SECTION_PUBLIC_FAIL,
    err: err,
  };
};

export const getSectionPublicHandler = (parkId) => {
  return (dispatch) => {
    dispatch(startGetSectionPublicData());
    parkApi
      .fetchSectionPublic(parkId)
      .then((res) => {
        if (res) {
          dispatch(getSectionPublicDataSuccess(res));
        } else {
          dispatch(
            getSectionPublicDataFail("取得單一園區 潛力廠商時發生問題。")
          );
        }
      })
      .catch((err) => {
        dispatch(getSectionPublicDataFail(err));
      });
  };
};

//取得單一園區 綠色工廠前十大廠商
export const startGetSectionPublicTopTenGreenData = () => {
  return {
    type: actionTypes.START_GET_SECTION_PUBLIC_TOP_TEN_GREEN_DATA,
  };
};

export const getSectionPublicTopTenGreenDataSuccess = (data) => {
  return {
    type: actionTypes.GET_SECTION_PUBLIC_TOP_TEN_GREEN_SUCCESS,
    data: data,
  };
};

export const getSectionPublicTopTenGreenDataFail = (err) => {
  return {
    type: actionTypes.GET_SECTION_PUBLIC_TOP_TEN_GREEN_FAIL,
    err: err,
  };
};

export const getSectionPublicTopTenGreenHandler = (parkId) => {
  return (dispatch) => {
    dispatch(startGetSectionPublicTopTenGreenData());
    parkApi
      .fetchSectionPublicTopTenGreen(parkId)
      .then((res) => {
        if (res) {
          dispatch(getSectionPublicTopTenGreenDataSuccess(res));
        } else {
          dispatch(
            getSectionPublicTopTenGreenDataFail(
              "取得單一園區綠色工廠前十大廠商時發生問題。"
            )
          );
        }
      })
      .catch((err) => {
        dispatch(getSectionPublicTopTenGreenDataFail(err));
      });
  };
};

//取得單一園區 進口級距佔比-圓餅圖
export const startGetSectionImportPercentageData = () => {
  return {
    type: actionTypes.START_GET_SECTION_IMPORT_PERCENTAGE_DATA,
  };
};

export const getSectionImportPercentageDataSuccess = (data) => {
  return {
    type: actionTypes.GET_SECTION_IMPORT_PERCENTAGE_SUCCESS,
    data: data,
  };
};

export const getSectionImportPercentageDataFail = (err) => {
  return {
    type: actionTypes.GET_SECTION_IMPORT_PERCENTAGE_FAIL,
    err: err,
  };
};

export const getSectionImportPercentageHandler = (parkId) => {
  return (dispatch) => {
    dispatch(startGetSectionImportPercentageData());
    parkApi
      .fetchSectionImportPercentage(parkId)
      .then((res) => {
        if (res) {
          dispatch(getSectionImportPercentageDataSuccess(res));
        } else {
          dispatch(
            getSectionImportPercentageDataFail(
              "取得單一園區 進口級距佔比-圓餅圖時發生問題。"
            )
          );
        }
      })
      .catch((err) => {
        dispatch(getSectionImportPercentageDataFail(err));
      });
  };
};

//取得單一園區 出口級距佔比-圓餅圖
export const startGetSectionExportPercentageData = () => {
  return {
    type: actionTypes.START_GET_SECTION_EXPORT_PERCENTAGE_DATA,
  };
};

export const getSectionExportPercentageDataSuccess = (data) => {
  return {
    type: actionTypes.GET_SECTION_EXPORT_PERCENTAGE_SUCCESS,
    data: data,
  };
};

export const getSectionExportPercentageDataFail = (err) => {
  return {
    type: actionTypes.GET_SECTION_EXPORT_PERCENTAGE_FAIL,
    err: err,
  };
};

export const getSectionExportPercentageHandler = (parkId) => {
  return (dispatch) => {
    dispatch(startGetSectionExportPercentageData());
    parkApi
      .fetchSectionExportPercentage(parkId)
      .then((res) => {
        if (res) {
          dispatch(getSectionExportPercentageDataSuccess(res));
        } else {
          dispatch(
            getSectionExportPercentageDataFail(
              "取得單一園區 出口級距佔比-圓餅圖時發生問題。"
            )
          );
        }
      })
      .catch((err) => {
        dispatch(getSectionExportPercentageDataFail(err));
      });
  };
};

//取得單一園區 產業別2碼家數佔比-圓餅圖
export const startGetTwoCodePercentageData = () => {
  return {
    type: actionTypes.START_GET_TWO_CODE_PERCENTAGE_DATA,
  };
};

export const getTwoCodePercentageDataSuccess = (data) => {
  return {
    type: actionTypes.GET_TWO_CODE_PERCENTAGE_SUCCESS,
    data: data,
  };
};

export const getTwoCodePercentageDataFail = (err) => {
  return {
    type: actionTypes.GET_TWO_CODE_PERCENTAGE_FAIL,
    err: err,
  };
};

export const getTwoCodePercentageHandler = (parkId) => {
  return (dispatch) => {
    dispatch(startGetTwoCodePercentageData());
    parkApi
      .fetchTwoCodePercentage(parkId)
      .then((res) => {
        if (res) {
          dispatch(getTwoCodePercentageDataSuccess(res));
        } else {
          dispatch(
            getTwoCodePercentageDataFail(
              "取得單一園區產業別2碼家數佔比-圓餅圖時發生問題。"
            )
          );
        }
      })
      .catch((err) => {
        dispatch(getTwoCodePercentageDataFail(err));
      });
  };
};

//取得單一園區 產業別3碼家數佔比-圓餅圖
export const startGetThreeCodePercentageData = () => {
  return {
    type: actionTypes.START_GET_THREE_CODE_PERCENTAGE_DATA,
  };
};

export const getThreeCodePercentageDataSuccess = (data) => {
  return {
    type: actionTypes.GET_THREE_CODE_PERCENTAGE_SUCCESS,
    data: data,
  };
};

export const getThreeCodePercentageDataFail = (err) => {
  return {
    type: actionTypes.GET_THREE_CODE_PERCENTAGE_FAIL,
    err: err,
  };
};

export const getThreeCodePercentageHandler = (parkId) => {
  return (dispatch) => {
    dispatch(startGetThreeCodePercentageData());
    parkApi
      .fetchThreeCodePercentage(parkId)
      .then((res) => {
        if (res) {
          dispatch(getThreeCodePercentageDataSuccess(res));
        } else {
          dispatch(
            getThreeCodePercentageDataFail(
              "取得單一園區產業別3碼家數佔比-圓餅圖時發生問題。"
            )
          );
        }
      })
      .catch((err) => {
        dispatch(getThreeCodePercentageDataFail(err));
      });
  };
};

//取得單一園區 產業別4碼家數佔比-圓餅圖
export const startGetFourCodePercentageData = () => {
  return {
    type: actionTypes.START_GET_FOUR_CODE_PERCENTAGE_DATA,
  };
};

export const getFourCodePercentageDataSuccess = (data) => {
  return {
    type: actionTypes.GET_FOUR_CODE_PERCENTAGE_SUCCESS,
    data: data,
  };
};

export const getFourCodePercentageDataFail = (err) => {
  return {
    type: actionTypes.GET_FOUR_CODE_PERCENTAGE_FAIL,
    err: err,
  };
};

export const getFourCodePercentageHandler = (parkId) => {
  return (dispatch) => {
    dispatch(startGetFourCodePercentageData());
    parkApi
      .fetchFourCodePercentage(parkId)
      .then((res) => {
        if (res) {
          dispatch(getFourCodePercentageDataSuccess(res));
        } else {
          dispatch(
            getFourCodePercentageDataFail(
              "取得單一園區產業別4碼家數佔比-圓餅圖時發生問題。"
            )
          );
        }
      })
      .catch((err) => {
        dispatch(getFourCodePercentageDataFail(err));
      });
  };
};

//取得單一園區 資本額前十大廠商排行-長條圖
export const startGetSectionTopTenCapitalData = () => {
  return {
    type: actionTypes.START_GET_SECTION_TOP_TEN_CAPITAL_DATA,
  };
};

export const getSectionTopTenCapitalDataSuccess = (data) => {
  return {
    type: actionTypes.GET_SECTION_TOP_TEN_CAPITAL_SUCCESS,
    data: data,
  };
};

export const getSectionTopTenCapitalDataFail = (err) => {
  return {
    type: actionTypes.GET_SECTION_TOP_TEN_CAPITAL_FAIL,
    err: err,
  };
};

export const getSectionTopTenCapitalHandler = (parkId) => {
  return (dispatch) => {
    dispatch(startGetSectionTopTenCapitalData());
    parkApi
      .fetchSectionTopTenCapital(parkId)
      .then((res) => {
        if (res) {
          dispatch(getSectionTopTenCapitalDataSuccess(res));
        } else {
          dispatch(
            getSectionTopTenCapitalDataFail(
              "取得單一園區產業別4碼家數佔比-圓餅圖時發生問題。"
            )
          );
        }
      })
      .catch((err) => {
        dispatch(getSectionTopTenCapitalDataFail(err));
      });
  };
};

//取得單一園區 政府計劃前十大廠商
export const startGetSectionTopTenGovernmentPlanData = () => {
  return {
    type: actionTypes.START_GET_SECTION_TOP_TEN_GOVERNMENT_PLAN_DATA,
  };
};

export const getSectionTopTenGovernmentPlanDataSuccess = (data) => {
  return {
    type: actionTypes.GET_SECTION_TOP_TEN_GOVERNMENT_PLAN_SUCCESS,
    data: data,
  };
};

export const getSectionTopTenGovernmentPlanDataFail = (err) => {
  return {
    type: actionTypes.GET_SECTION_TOP_TEN_GOVERNMENT_PLAN_FAIL,
    err: err,
  };
};

export const getSectionTopTenGovernmentPlanHandler = (parkId) => {
  return (dispatch) => {
    dispatch(startGetSectionTopTenGovernmentPlanData());
    parkApi
      .fetchSectionTopTenGovernmentPlan(parkId)
      .then((res) => {
        if (res) {
          dispatch(getSectionTopTenGovernmentPlanDataSuccess(res));
        } else {
          dispatch(
            getSectionTopTenGovernmentPlanDataFail(
              "取得單一園區政府計劃前十大廠商時發生問題。"
            )
          );
        }
      })
      .catch((err) => {
        dispatch(getSectionTopTenGovernmentPlanDataFail(err));
      });
  };
};

//取得單一園區 專利數前十大-長條圖
export const startGetSectionTopTenPatentData = () => {
  return {
    type: actionTypes.START_GET_SECTION_TOP_TEN_PATENT_DATA,
  };
};

export const getSectionTopTenPatentDataSuccess = (data) => {
  return {
    type: actionTypes.GET_SECTION_TOP_TEN_PATENT_SUCCESS,
    data: data,
  };
};

export const getSectionTopTenPatentDataFail = (err) => {
  return {
    type: actionTypes.GET_SECTION_TOP_TEN_PATENT_FAIL,
    err: err,
  };
};

export const getSectionTopTenPatentHandler = (parkId) => {
  return (dispatch) => {
    dispatch(startGetSectionTopTenPatentData());
    parkApi
      .fetchSectionTopTenPatent(parkId)
      .then((res) => {
        if (res) {
          dispatch(getSectionTopTenPatentDataSuccess(res));
        } else {
          dispatch(
            getSectionTopTenPatentDataFail(
              "取得單一園區專利數前十大-長條圖時發生問題。"
            )
          );
        }
      })
      .catch((err) => {
        dispatch(getSectionTopTenPatentDataFail(err));
      });
  };
};

//取得單一園區 員工數前十大
export const startGetSectionTopTenEmpData = () => {
  return {
    type: actionTypes.START_GET_SECTION_TOP_TEN_EMP_DATA,
  };
};

export const getSectionTopTenEmpDataSuccess = (data) => {
  return {
    type: actionTypes.GET_SECTION_TOP_TEN_EMP_SUCCESS,
    data: data,
  };
};

export const getSectionTopTenEmpDataFail = (err) => {
  return {
    type: actionTypes.GET_SECTION_TOP_TEN_EMP_FAIL,
    err: err,
  };
};

export const getSectionTopTenEmpHandler = (parkId) => {
  return (dispatch) => {
    dispatch(startGetSectionTopTenEmpData());
    parkApi
      .fetchSectionTopTenEmp(parkId)
      .then((res) => {
        if (res) {
          dispatch(getSectionTopTenEmpDataSuccess(res));
        } else {
          dispatch(
            getSectionTopTenEmpDataFail("取得單一園區員工數前十大時發生問題。")
          );
        }
      })
      .catch((err) => {
        dispatch(getSectionTopTenEmpDataFail(err));
      });
  };
};

//取得單一園區 政府計畫數類型佔比
export const startGetSectionGovPlanPercentData = () => {
  return {
    type: actionTypes.START_GET_SECTION_GOV_PLAN_PERCENT_DATA,
  };
};

export const getSectionGovPlanPercentDataSuccess = (data) => {
  return {
    type: actionTypes.GET_SECTION_GOV_PLAN_PERCENT_SUCCESS,
    data: data,
  };
};

export const getSectionGovPlanPercentDataFail = (err) => {
  return {
    type: actionTypes.GET_SECTION_GOV_PLAN_PERCENT_FAIL,
    err: err,
  };
};

export const getSectionGovPlanPercentHandler = (parkId) => {
  return (dispatch) => {
    dispatch(startGetSectionTopTenEmpData());
    parkApi
      .fetchSectionGovPlanPercent(parkId)
      .then((res) => {
        if (res) {
          dispatch(getSectionGovPlanPercentDataSuccess(res));
        } else {
          dispatch(
            getSectionGovPlanPercentDataFail(
              "取得單一園區政府計畫數類型佔比時發生問題。"
            )
          );
        }
      })
      .catch((err) => {
        dispatch(getSectionGovPlanPercentDataFail(err));
      });
  };
};

//取得單一園區 獲獎數類型佔比
export const startGetSectionAwardPercentData = () => {
  return {
    type: actionTypes.START_GET_SECTION_AWARD_PERCENT_DATA,
  };
};

export const getSectionAwardPercentDataSuccess = (data) => {
  return {
    type: actionTypes.GET_SECTION_AWARD_PERCENT_SUCCESS,
    data: data,
  };
};

export const getSectionAwardPercentDataFail = (err) => {
  return {
    type: actionTypes.GET_SECTION_AWARD_PERCENT_FAIL,
    err: err,
  };
};

export const getSectionAwardPercentHandler = (parkId) => {
  return (dispatch) => {
    dispatch(startGetSectionAwardPercentData());
    parkApi
      .fetchSectionAwardPercent(parkId)
      .then((res) => {
        if (res) {
          dispatch(getSectionAwardPercentDataSuccess(res));
        } else {
          dispatch(
            getSectionAwardPercentDataFail(
              "取得單一園區獲獎數類型佔比時發生問題。"
            )
          );
        }
      })
      .catch((err) => {
        dispatch(getSectionAwardPercentDataFail(err));
      });
  };
};

//取得單一園區 專利數占比-圓餅圖 (目前沒用到~~)
// export const startGetSectionPatentPercentData = () => {
//   return {
//     type: actionTypes.START_GET_SECTION_PATENT_PERCENT_DATA,
//   };
// };

// export const getSectionPatentPercentDataSuccess = (data) => {
//   return {
//     type: actionTypes.GET_SECTION_PATENT_PERCENT_SUCCESS,
//     data: data,
//   };
// };

// export const getSectionPatentPercentDataFail = (err) => {
//   return {
//     type: actionTypes.GET_SECTION_PATENT_PERCENT_FAIL,
//     err: err,
//   };
// };

// export const getSectionPatentPercentHandler = (parkId) => {
//   return (dispatch) => {
//     dispatch(startGetSectionPatentPercentData());
//     parkApi.fetchSectionPatentPercent(parkId)
//       .then((res) => {
//         if (res) {
//           dispatch(getSectionPatentPercentDataSuccess(res));
//         } else {
//           dispatch(
//             getSectionPatentPercentDataFail("取得單一園區專利數占比-圓餅圖時發生問題。")
//           );
//         }
//       })
//       .catch((err) => {
//         dispatch(getSectionPatentPercentDataFail(err));
//       });
//   };
// };

//產品搜尋地圖
export const startGetMapSearchData = () => {
  return {
    type: actionTypes.START_GET_MAP_SEARCH_DATA,
  };
};

export const getMapSearchDataSuccess = (data) => {
  return {
    type: actionTypes.GET_MAP_SEARCH_SUCCESS,
    data: data,
  };
};

export const getMapSearchDataFail = (err) => {
  return {
    type: actionTypes.GET_MAP_SEARCH_FAIL,
    err: err,
  };
};

export const getMapSearchHandler = (tag) => {
  return (dispatch) => {
    dispatch(startGetMapSearchData());
    parkApi
      .fetchMapSearch(tag)
      .then((res) => {
        if (res) {
          dispatch(getMapSearchDataSuccess(res));
        } else {
          dispatch(getMapSearchDataFail("取得產品搜尋地圖時發生問題。"));
        }
      })
      .catch((err) => {
        dispatch(getMapSearchDataFail(err));
      });
  };
};

//取得廢棄物前5大排行
export const startTopFiveWasteDisposalData = () => {
  return {
    type: actionTypes.START_GET_TOP_FIVE_WASTE_DISPOSAL_DATA,
  };
};

export const getTopFiveWasteDisposalDataSuccess = (data) => {
  return {
    type: actionTypes.GET_TOP_FIVE_WASTE_DISPOSAL_SUCCESS,
    data: data,
  };
};

export const getTopFiveWasteDisposalDataFail = (err) => {
  return {
    type: actionTypes.GET_TOP_FIVE_WASTE_DISPOSAL_FAIL,
    err: err,
  };
};

export const getTopFiveWasteDisposalHandler = (tag) => {
  return (dispatch) => {
    dispatch(startTopFiveWasteDisposalData());
    parkApi
      .fetchTopFiveWasteDisposal(tag)
      .then((res) => {
        if (res) {
          dispatch(getTopFiveWasteDisposalDataSuccess(res));
        } else {
          dispatch(
            getTopFiveWasteDisposalDataFail("取得廢棄物前5大排行時發生問題。")
          );
        }
      })
      .catch((err) => {
        dispatch(getTopFiveWasteDisposalDataFail(err));
      });
  };
};

// ====================== MODAL ======================

//單一園區 產業別2碼家數佔比-Modal
export const startSectionTwoCodePercentageListData = () => {
  return {
    type: actionTypes.START_GET_SECTION_TWO_CODE_PERCENTAGE_LIST_DATA,
  };
};

export const getSectionTwoCodePercentageListSuccess = (data) => {
  return {
    type: actionTypes.GET_SECTION_TWO_CODE_PERCENTAGE_LIST_SUCCESS,
    data: data,
  };
};

export const getSectionTwoCodePercentageListFail = (err) => {
  return {
    type: actionTypes.GET_SECTION_TWO_CODE_PERCENTAGE_LIST_FAIL,
    err: err,
  };
};

export const getSectionTwoCodePercentageListHandler = (parkId, params) => {
  return (dispatch) => {
    dispatch(startSectionTwoCodePercentageListData());
    parkApi
      .fetchSectionTwoCodePercentageList(parkId, params)
      .then((res) => {
        if (res) {
          dispatch(getSectionTwoCodePercentageListSuccess(res));
        } else {
          dispatch(
            getSectionTwoCodePercentageListFail(
              "取得單一園區 產業別2碼家數佔比-Modal發生問題。"
            )
          );
        }
      })
      .catch((err) => {
        dispatch(getSectionTwoCodePercentageListFail(err));
      });
  };
};

//單一園區 產業別3碼家數佔比-Modal
export const startSectionThreeCodePercentageListData = () => {
  return {
    type: actionTypes.START_GET_SECTION_THREE_CODE_PERCENTAGE_LIST_DATA,
  };
};

export const getSectionThreeCodePercentageListSuccess = (data) => {
  return {
    type: actionTypes.GET_SECTION_THREE_CODE_PERCENTAGE_LIST_SUCCESS,
    data: data,
  };
};

export const getSectionThreeCodePercentageListFail = (err) => {
  return {
    type: actionTypes.GET_SECTION_THREE_CODE_PERCENTAGE_LIST_FAIL,
    err: err,
  };
};

export const getSectionThreeCodePercentageListHandler = (parkId, params) => {
  return (dispatch) => {
    dispatch(startSectionThreeCodePercentageListData());
    parkApi
      .fetchSectionThreeCodePercentageList(parkId, params)
      .then((res) => {
        if (res) {
          dispatch(getSectionThreeCodePercentageListSuccess(res));
        } else {
          dispatch(
            getSectionThreeCodePercentageListFail(
              "取得單一園區 產業別3碼家數佔比-Modal發生問題。"
            )
          );
        }
      })
      .catch((err) => {
        dispatch(getSectionThreeCodePercentageListFail(err));
      });
  };
};

//單一園區 產業別4碼家數佔比-Modal
export const startSectionFourCodePercentageListData = () => {
  return {
    type: actionTypes.START_GET_SECTION_FOUR_CODE_PERCENTAGE_LIST_DATA,
  };
};

export const getSectionFourCodePercentageListSuccess = (data) => {
  return {
    type: actionTypes.GET_SECTION_FOUR_CODE_PERCENTAGE_LIST_SUCCESS,
    data: data,
  };
};

export const getSectionFourCodePercentageListFail = (err) => {
  return {
    type: actionTypes.GET_SECTION_FOUR_CODE_PERCENTAGE_LIST_FAIL,
    err: err,
  };
};

export const getSectionFourCodePercentageListHandler = (parkId, params) => {
  return (dispatch) => {
    dispatch(startSectionFourCodePercentageListData());
    parkApi
      .fetchSectionFourCodePercentageList(parkId, params)
      .then((res) => {
        if (res) {
          dispatch(getSectionFourCodePercentageListSuccess(res));
        } else {
          dispatch(
            getSectionFourCodePercentageListFail(
              "取得單一園區 產業別4碼家數佔比-Modal發生問題。"
            )
          );
        }
      })
      .catch((err) => {
        dispatch(getSectionFourCodePercentageListFail(err));
      });
  };
};

//單一園區 進口級距佔比-清單-Modal
export const startSectionImportListData = () => {
  return {
    type: actionTypes.START_GET_SECTION_IMPORT_LIST_DATA,
  };
};

export const getSectionImportListSuccess = (data) => {
  return {
    type: actionTypes.GET_SECTION_IMPORT_LIST_SUCCESS,
    data: data,
  };
};

export const getSectionImportListFail = (err) => {
  return {
    type: actionTypes.GET_SECTION_IMPORT_LIST_FAIL,
    err: err,
  };
};

export const getSectionImportListHandler = (parkId, params) => {
  return (dispatch) => {
    dispatch(startSectionImportListData());
    parkApi
      .fetchSectionImportList(parkId, params)
      .then((res) => {
        if (res) {
          dispatch(getSectionImportListSuccess(res));
        } else {
          dispatch(
            getSectionImportListFail(
              "取得單一園區 進口級距佔比-清單-Modal發生問題。"
            )
          );
        }
      })
      .catch((err) => {
        dispatch(getSectionImportListFail(err));
      });
  };
};

//單一園區 出口級距佔比-清單-Modal
export const startSectionExportListData = () => {
  return {
    type: actionTypes.START_GET_SECTION_EXPORT_LIST_DATA,
  };
};

export const getSectionExportListSuccess = (data) => {
  return {
    type: actionTypes.GET_SECTION_EXPORT_LIST_SUCCESS,
    data: data,
  };
};

export const getSectionExportListFail = (err) => {
  return {
    type: actionTypes.GET_SECTION_EXPORT_LIST_FAIL,
    err: err,
  };
};

export const getSectionExportListHandler = (parkId, params) => {
  return (dispatch) => {
    dispatch(startSectionExportListData());
    parkApi
      .fetchSectionExportList(parkId, params)
      .then((res) => {
        if (res) {
          dispatch(getSectionExportListSuccess(res));
        } else {
          dispatch(
            getSectionExportListFail(
              "取得單一園區 出口級距佔比-清單-Modal發生問題。"
            )
          );
        }
      })
      .catch((err) => {
        dispatch(getSectionExportListFail(err));
      });
  };
};

//單一園區 專利資料-Modal
export const startSectionPatentInfoListData = () => {
  return {
    type: actionTypes.START_GET_SECTION_PATENT_INFO_LIST_DATA,
  };
};

export const getSectionPatentInfoListSuccess = (data) => {
  return {
    type: actionTypes.GET_SECTION_PATENT_INFO_LIST_SUCCESS,
    data: data,
  };
};

export const getSectionPatentInfoListFail = (err) => {
  return {
    type: actionTypes.GET_SECTION_PATENT_INFO_LIST_FAIL,
    err: err,
  };
};

export const getSectionPatentInfoListHandler = (businessId, year) => {
  return (dispatch) => {
    dispatch(startSectionPatentInfoListData());
    parkApi
      .fetchSectionPatentInfoList(businessId, year)
      .then((res) => {
        if (res) {
          dispatch(getSectionPatentInfoListSuccess(res));
        } else {
          dispatch(
            getSectionPatentInfoListFail(
              "取得單一園區 專利資料-Modal發生問題。"
            )
          );
        }
      })
      .catch((err) => {
        dispatch(getSectionPatentInfoListFail(err));
      });
  };
};

//單一園區 園區廠商政府計畫數前十大排行-Modal
export const startSectionGovPlanListData = () => {
  return {
    type: actionTypes.START_GET_SECTION_GOV_PLAN_LIST_DATA,
  };
};

export const getSectionGovPlanListSuccess = (data) => {
  return {
    type: actionTypes.GET_SECTION_GOV_PLAN_LIST_SUCCESS,
    data: data,
  };
};

export const getSectionGovPlanListFail = (err) => {
  return {
    type: actionTypes.GET_SECTION_GOV_PLAN_LIST_FAIL,
    err: err,
  };
};

export const getSectionGovPlanListHandler = (businessId) => {
  return (dispatch) => {
    dispatch(startSectionGovPlanListData());
    parkApi
      .fetchSectionGovPlanList(businessId)
      .then((res) => {
        if (res) {
          dispatch(getSectionGovPlanListSuccess(res));
        } else {
          dispatch(
            getSectionGovPlanListFail(
              "取得單一園區 園區廠商政府計畫數前十大排行-Modal發生問題。"
            )
          );
        }
      })
      .catch((err) => {
        dispatch(getSectionGovPlanListFail(err));
      });
  };
};

//單一園區 取得園區廠商政府計畫類型佔比-Modal
export const startSectionParkDetailInfoListData = () => {
  return {
    type: actionTypes.START_GET_SECTION_PARK_DETAIL_INFO_LIST_DATA,
  };
};

export const getSectionParkDetailInfoListSuccess = (data) => {
  return {
    type: actionTypes.GET_SECTION_PARK_DETAIL_INFO_LIST_SUCCESS,
    data: data,
  };
};

export const getSectionParkDetailInfoListFail = (err) => {
  return {
    type: actionTypes.GET_SECTION_PARK_DETAIL_INFO_LIST_FAIL,
    err: err,
  };
};

export const getSectionParkDetailInfoListHandler = (
  parkId,
  labelName,
  IsAward
) => {
  return (dispatch) => {
    dispatch(startSectionParkDetailInfoListData());
    parkApi
      .fetchSectionParkDetailInfoList(parkId, labelName, IsAward)
      .then((res) => {
        if (res) {
          dispatch(getSectionParkDetailInfoListSuccess(res));
        } else {
          dispatch(
            getSectionParkDetailInfoListFail(
              "取得單一園區 取得園區廠商政府計畫類型佔比-Modal發生問題。"
            )
          );
        }
      })
      .catch((err) => {
        dispatch(getSectionParkDetailInfoListFail(err));
      });
  };
};

//單一園區 取得園區廠商獲獎類型佔比資料-Modal
export const startSectionAwardListData = () => {
  return {
    type: actionTypes.START_GET_SECTION_AWARD_LIST_DATA,
  };
};

export const getSectioAwardListSuccess = (data) => {
  return {
    type: actionTypes.GET_SECTION_AWARD_LIST_SUCCESS,
    data: data,
  };
};

export const getSectionAwardListFail = (err) => {
  return {
    type: actionTypes.GET_SECTION_AWARD_LIST_FAIL,
    err: err,
  };
};

export const getSectionAwardListHandler = (parkId, labelName) => {
  return (dispatch) => {
    dispatch(startSectionAwardListData());
    parkApi
      .fetchSectionAwardList(parkId, labelName)
      .then((res) => {
        if (res) {
          dispatch(getSectioAwardListSuccess(res));
        } else {
          dispatch(
            getSectionAwardListFail(
              "取得單一園區 取得園區廠商獲獎類型佔比資料-Modal發生問題。"
            )
          );
        }
      })
      .catch((err) => {
        dispatch(getSectionAwardListFail(err));
      });
  };
};

//單一園區 取得廢棄物廠商追溯表-Modal
export const startWasteDisposalListData = () => {
  return {
    type: actionTypes.START_GET_WASTE_DISPOSAL_LIST_DATA,
  };
};

export const getWasteDisposalListSuccess = (data) => {
  return {
    type: actionTypes.GET_SECTION_WASTE_DISPOSAL_LIST_SUCCESS,
    data: data,
  };
};

export const getWasteDisposalListFail = (err) => {
  return {
    type: actionTypes.GET_SECTION_WASTE_DISPOSAL_LIST_FAIL,
    err: err,
  };
};

export const getWasteDisposalListHandler = (wasteNumber, parkId) => {
  return (dispatch) => {
    dispatch(startWasteDisposalListData());
    parkApi
      .fetchWasteDisposalList(wasteNumber, parkId)
      .then((res) => {
        if (res) {
          dispatch(getWasteDisposalListSuccess(res));
        } else {
          dispatch(
            getWasteDisposalListFail(
              "取得單一園區 取得廢棄物廠商追溯表-Modal發生問題。"
            )
          );
        }
      })
      .catch((err) => {
        dispatch(getWasteDisposalListFail(err));
      });
  };
};

//單一園區 公開發行狀況佔比-Modal
export const startSectionStockListData = () => {
  return {
    type: actionTypes.START_GET_SECTION_STOCK_LIST_DATA,
  };
};

export const getSectionStockListSuccess = (data) => {
  return {
    type: actionTypes.GET_SECTION_STOCK_LIST_SUCCESS,
    data: data,
  };
};

export const getSectionStockListFail = (err) => {
  return {
    type: actionTypes.GET_SECTION_STOCK_LIST_FAIL,
    err: err,
  };
};

export const getSectionStockListHandler = (parkId, stockType) => {
  return (dispatch) => {
    dispatch(startSectionStockListData());
    parkApi
      .fetchSectionStockList(parkId, stockType)
      .then((res) => {
        if (res) {
          dispatch(getSectionStockListSuccess(res));
        } else {
          dispatch(
            getSectionStockListFail(
              "取得單一園區 公開發行狀況佔比-Modal發生問題。"
            )
          );
        }
      })
      .catch((err) => {
        dispatch(getSectionStockListFail(err));
      });
  };
};
