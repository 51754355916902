import Slide from "@material-ui/core/Slide";
import CryptoJS, { Base64 } from "crypto-js";

export const thousandDivider = (text) => {
  return text ? text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","):"";
};

export const paginationListHandler = (arr, start, end) => {
  const updateList = arr.slice(start, end);
  return updateList;
};

export const upperFirstCase = (text) => {
  return text.charAt(0).toUpperCase() + text.slice(1);
};

export const directToNewTabHandler = (url, params) => {
  const baseUrl = `${window.location.origin}`;
  window.open(`${baseUrl}/${url}/${params}`, "_blank");
};

export const message = (enqueueSnackbar, msg, type) => {
  enqueueSnackbar(msg, {
    variant: type,
    anchorOrigin: {
      vertical: "top",
      horizontal: "center",
    },
    TransitionComponent: Slide,
  });
};

export const stopTypeSwitcher = (num) => {
  switch (num.toString()) {
    case "0":
      return "未上市";
    case "1":
      return "上市";
    case "2":
      return "上櫃";
    case "3":
      return "興櫃";
    case "4":
      return "公開發行";
    default:
      return "尚未提供資料";
  }
};

export const encryptAES = (message) => {
  const key = CryptoJS.enc.Utf8.parse("@3_*WerTvsj5myu+"); 
  const iv = CryptoJS.enc.Utf8.parse('4serwi8cvsew1@8-');   
  var wordArray = CryptoJS.enc.Utf8.parse(message); 
  var messages = CryptoJS.AES.encrypt(wordArray, key ,{iv:iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 });
  return messages.toString();
}

export const decryptAES = (message = '', key = '') => {
  var code = CryptoJS.AES.decrypt(message, "@3_*WerTvsj5myu+");
  var decryptedMessage = code.toString(CryptoJS.enc.Utf8);
  return decryptedMessage;
}

export const parseUrl = (patentNo) => {
  // 專利號 M 開頭 https://patents.google.com/patent/TW{專利號}U/zh?oq={專利號}
  // 專利號 I 開頭 https://patents.google.com/patent/TW{專利號}B/zh?oq={專利號}
  // 專利號 D 開頭 https://patents.google.com/patent/TW{專利號}S/zh?oq={專利號}
  const headTitle = patentNo.slice(0, 1);
  let link = "" 
  if (headTitle === 'M') {
    link = `TW${patentNo}U`
  } else if (headTitle === 'I') {
    link = `TW${patentNo}B`
  } else if (headTitle === 'D') {
    link = `TW${patentNo}S`
  }
  return `https://patents.google.com/patent/${link}/zh?oq=${patentNo}`;
}

export const randomMath = () => {
  return parseFloat("0." + crypto.getRandomValues(new Uint32Array(1))[0]);
}