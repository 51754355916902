import React from "react";
import { useSelector } from "react-redux";

import Spinner from "../../components/Spinner/Spinner";

import * as Style from "./styles";

const QuestionCompanySuggestionList = ({ isOpen, handleUpdateCompanyInfo }) => {
  const { companyInfoList, companyInfoListLoading } = useSelector(
    (state) => state.question
  );

  if (companyInfoListLoading) {
    return (
      <Style.QuestionCompanySuggestionListContainer isOpen={isOpen}>
        <Spinner />
      </Style.QuestionCompanySuggestionListContainer>
    );
  } else {
    return (
      <Style.QuestionCompanySuggestionListContainer isOpen={isOpen}>
        {companyInfoList.length !== 0 ? (
          companyInfoList.map((company, index) => (
            <Style.QuestionCompanySuggestionListItem
              isOpen={isOpen}
              index={index}
              onClick={() =>
                handleUpdateCompanyInfo(company.BusinessNo, company.Name)
              }
            >
              <Style.QuestionCompanySuggestionListItemText isOpen={isOpen}>
                {company.BusinessNo},
              </Style.QuestionCompanySuggestionListItemText>
              <Style.QuestionCompanySuggestionListItemText isOpen={isOpen}>
                {company.Name}
              </Style.QuestionCompanySuggestionListItemText>
            </Style.QuestionCompanySuggestionListItem>
          ))
        ) : (
          <Style.QuestionCompanySuggestionListItem isOpen={isOpen}>
            <Style.QuestionCompanySuggestionListItemText
              isOpen={isOpen}
              noData={true}
            >
              查無資料。
            </Style.QuestionCompanySuggestionListItemText>
          </Style.QuestionCompanySuggestionListItem>
        )}
      </Style.QuestionCompanySuggestionListContainer>
    );
  }
};

export default QuestionCompanySuggestionList;
