import React from "react";
import styled from "styled-components";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import * as theme from "../../assets/theme";
import hotIndustryIcon from "../../assests/hotIndustry.png";
import { IndystryItem1, IndystryItem2, IndystryItem3, IndystryItem4 } from "./DefaultIndustry";
import { ExportGoodItem1, ExportGoodItem2, ExportGoodItem3, ExportGoodItem4, ImportGoodItem1, ImportGoodItem2, ImportGoodItem3, ImportGoodItem4 } from "./DefaultGood";

const TaxSelect = (props) => {
  const { label, type, countryList, dataList, loading, handleChange, selectedGoods, selectedCountry } = props;

  const filterOptions = createFilterOptions({
    stringify: (option) => option.Code + (option.GoodsName || option.Name)
  });

  return (
    <SelectContainer>
      <SelectRow type={type === "IndustryCode" ? "0px" : "0px 5px"}>
        {/* {type !== "IndustryCode" && (
          <FormControl sx={{ width: "30%" }}>
            <InputLabel id="demo-simple-select-label">{label}國別</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={selectedCountry}
              label={`${label}國別`}
              onChange={(event, value) => {
                handleChange(event, value, `${type}country`)
              }}
            >
              {countryList?.map((data, index) => (
                <MenuItem key={data.Country.toString()} value={data.Country}>{data.Country}</MenuItem>
              ))}
            </Select>
          </FormControl>)} */}

        <FormContainer width={"100%"}>
          <Form>
            <CompleteAuto
              multiple
              autoComplete
              onChange={(event, value) => {
                handleChange(event, value, type === "IndustryCode" ? type : `${type}Selected`)
              }}
              sx={{ height: 30 }}
              id={type}
              name={type}
              value={selectedGoods}
              options={dataList}
              limitTags={1}
              isOptionEqualToValue={(option, value) => option.Code === value.Code}
              disableCloseOnSelect
              getOptionLabel={(option) =>
                `${option.Code}${option.Name}`
              }
              filterOptions={filterOptions}
              loading={loading}
              renderOption={(props, option, { selected }) => dataList.length > 0 && (
                <li {...props}>
                  <Checkbox
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.Code}{" "}
                  {option.GoodsName || option.Name}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={type === "IndustryCode" ? label : `搜尋${label}貨品`}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {loading ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
          </Form>
        </FormContainer>
        {/* 快速選擇產業 */}
        {/* {type === "IndustryCode"&&(
          <ModalInputContainer>
            <img src={hotIndustryIcon} alt={`${hotIndustryIcon}`} height="25px" loading={"lazy"} />
            <span>
              熱門產業：
              <HotIndustryItem
                onClick={(event, value) => {
                  handleChange(event, IndystryItem1, "IndustryCode" )
                  handleChange(event, ImportGoodItem1, "ImportSelected" )
                  handleChange(event, ExportGoodItem1, "ExportSelected" )
                }}
              >
                鋼鐵業
              </HotIndustryItem>
              <HotIndustryItem
                onClick={(event, value) => {
                  handleChange(event, IndystryItem2, "IndustryCode" )
                  handleChange(event, ImportGoodItem2, "ImportSelected" )
                  handleChange(event, ExportGoodItem2, "ExportSelected" )
                }}
              >
                紡織業
              </HotIndustryItem>
              <HotIndustryItem
                onClick={(event, value) => {
                  handleChange(event, IndystryItem3, "IndustryCode" )
                  handleChange(event, ImportGoodItem3, "ImportSelected" )
                  handleChange(event, ExportGoodItem3, "ExportSelected" )
                }}
              >
                製造業
              </HotIndustryItem>
              <HotIndustryItem
                onClick={(event, value) => {
                  handleChange(event, IndystryItem4, "IndustryCode" )
                  handleChange(event, ImportGoodItem4, "ImportSelected" )
                  handleChange(event, ExportGoodItem4, "ExportSelected" )
                }}
              >
                金屬業
              </HotIndustryItem>
            </span>
          </ModalInputContainer>
        )} */}
      </SelectRow>
      <ReadyToAddAreaContainer >
        <List dense={true} >
          {selectedGoods.length > 0 && selectedGoods?.map((data, index) => (
            <ItemList key={index}>
              <ListItemText
                sx={{ fontSize: "12px" }}
                primary={`${data.Code} ${data.GoodsName || data.Name}`}
              />
            </ItemList>
          ))}
        </List>
      </ReadyToAddAreaContainer>
    </SelectContainer>

  );
};

export default TaxSelect;

const SelectContainer = styled.div`
  flex: 1;
`;

const SelectRow = styled.div`
  display: flex;
  height: 55px;
  flex-direction: row;
  padding: ${(p) => p.type};
`;

const ItemList = styled(ListItem)`
  background-color: #FFEE99;
  border-radius: 10px;
  margin: 5px;
  border-style: solid;
  border-width: 1px;
  border-color: grey;
  font-weight: bold;
  font-size: 0.75rem;
`;

const CompleteAuto = styled(Autocomplete)`
  height: 100% !important;
  background: #FFF;
  .MuiChip-label{
    width: 100px;
  }
`;

const FormContainer = styled.div`
  padding-left: 5px;
  width: ${(p) => p.width};
`;

const Form = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: auto;
  z-index: 30001;
  position: relative;
`;

const ReadyToAddAreaContainer = styled.div`
  height: 35vh;
  margin: 10px 5px 0px 5px;
  padding: 0px 5px;
  border: 1px dashed ${theme.grayBorderColor};
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: ${p => p.no_data ? "center" : "flex-start"};
  align-items: ${p => p.no_data ? "center" : "flex-start"};
  overflow-y: scroll;
  background-color: #E4F0F4;
`;

const ModalInputContainer = styled.div`
  width: 55%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0px 10px;
`;

const HotIndustryItem = styled(Button)`
  width: 30px;
  margin: 0px 0px 0px 5px !important;
  background: #FFEE99 !important;
  border: 2px solid #000 !important;
`;
