import * as actionTypes from "../actionTypes/custom_dashboard";

const initState = {
  custom_dashboard_list: [],
  custom_dashboard_list_loading: false,
  custom_create_dashboard_list_loading: false,
  custom_dashboard_business_number_list: [],
  custom_dashboard_business_number_list_loading: false,
  custom_dashboard_business_number_list_result: '',
  is_number_exiting_list: [],
  is_number_exiting_list_loading: false,
  update_board_name_loading: false,
  business_num_by_park_info: "",
  business_num_by_company_search: [],
  business_no_by_company_name: [],
  business_no_by_company_name_loading: false,
  err: "",
};

const startFetchCustomDashboardList = (state) => {
  return {
    ...state,
    custom_dashboard_list_loading: true,
  };
};

const fetchCustomDashboardListSuccess = (state, action) => {
  return {
    ...state,
    custom_dashboard_list: action.data,
    custom_dashboard_list_loading: false,
  };
};

const fetchCustomDashboardListFail = (state) => {
  return {
    ...state,
    custom_dashboard_list_loading: false,
  };
};

const startCreateCustomDashboardList = (state) => {
  return {
    ...state,
    custom_create_dashboard_list_loading: true,
  };
};

const createCustomDashboardListSuccess = (state, action) => {
  return {
    ...state,
    //   custom_dashboard_list: action.data.data,
    custom_create_dashboard_list_loading: false,
  };
};

const createCustomDashboardListFail = (state) => {
  return {
    ...state,
    custom_create_dashboard_list_loading: false,
  };
};

const startDeleteCustomDashboardList = (state) => {
  return {
    ...state,
    custom_create_dashboard_list_loading: true,
  };
};

const deleteCustomDashboardListSuccess = (state, action) => {
  return {
    ...state,
    //   custom_dashboard_list: action.data.data,
    custom_create_dashboard_list_loading: false,
  };
};

const deleteCustomDashboardListFail = (state) => {
  return {
    ...state,
    custom_create_dashboard_list_loading: false,
  };
};

const startFetchCustomDashboardBusinessNumberList = (state) => {
  return {
    ...state,
    custom_dashboard_business_number_list_loading: true,
  };
};

const fetchCustomDashboardBusinessNumberListSuccess = (state, action) => {
  return {
    ...state,
    custom_dashboard_business_number_list: action.data.data,
    custom_dashboard_business_number_list_loading: false,
  };
};

const fetchCustomDashboardBusinessNumberListSuccessFail = (state) => {
  return {
    ...state,
    custom_dashboard_business_number_list_loading: false,
  };
};


const startAddCustomDashboardBusinessNumberList = (state) => {
  return {
    ...state,
    custom_dashboard_business_number_list_loading: true,
    custom_dashboard_business_number_list_result: '',
  };
};

const addCustomDashboardBusinessNumberListSuccess = (state) => {
  return {
    ...state,
    custom_dashboard_business_number_list_loading: false,
    custom_dashboard_business_number_list_result: 'success: 儀表板成功新增!',
  };
};

const addCustomDashboardBusinessNumberListFail = (state, action) => {
  return {
    ...state,
    custom_dashboard_business_number_list_loading: false,
    err: action.err,
    custom_dashboard_business_number_list_result: `error: ${action.err}`
  };
};


const startUpdateCustomDashboardBusinessNumberList = (state) => {
  return {
    ...state,
    custom_dashboard_business_number_list_loading: true,
  };
};

const updateCustomDashboardBusinessNumberListSuccess = (state, action) => {
  return {
    ...state,
    custom_dashboard_business_number_list_loading: false,
  };
};

const updateCustomDashboardBusinessNumberListFail = (state) => {
  return {
    ...state,
    custom_dashboard_business_number_list_loading: false,
  };
};

const startCheckCustomDashboardBusinessNumberList = (state) => {
  return {
    ...state,
    is_number_exiting_list_loading: true,
  };
};

const checkCustomDashboardBusinessNumberListSuccess = (state, action) => {
  return {
    ...state,
    is_number_exiting_list: action.data,
    is_number_exiting_list_loading: false,
  };
};

const checkCustomDashboardBusinessNumberListFail = (state) => {
  return {
    ...state,
    is_number_exiting_list_loading: false,
  };
};


const startUpdateBoardName = (state) => {
  return {
    ...state,
    update_board_name_loading: true,
  };
};

const updateBoardNameSuccess = (state) => {
  return {
    ...state,
    update_board_name_loading: false,
  };
};

const updateBoardNameFail = (state) => {
  return {
    ...state,
    update_board_name_loading: false,
  };
};

const handleClearCustomDashboardBusinessNumberListState = (state) => {
  return {
    ...state,
    custom_dashboard_business_number_list: []
  }
}

const handleUpdateBusinessNumberByParkInfo = (state, action) => {
  return {
    ...state,
    business_num_by_park_info: action.data
  }
}


const handleUpdateBusinessNumberByCompanyInfo = (state, action) => {
  return {
    ...state,
    business_num_by_company_search: action.data,
  };
}

const handleClearBusinessNumberByParkInfo = (state, action) => {
  return {
    ...state,
    business_num_by_park_info:""
  }
}


const handleClearBusinessNumberByCompanyInfo = (state, action) => {
  return {
    ...state,
    business_num_by_company_search: [],
  };
}

const handleClearErrorMessage = (state) => {
  return {
    ...state,
    err: "",
  };
} 

const startGetBusinessNoByCompanyName = (state) => {
  return {
    ...state,
    business_no_by_company_name_loading: true,
  };
};

const getBusinessNoByCompanyNameSuccess = (state, action) => {
  return {
    ...state,
    business_no_by_company_name_loading: false,
    business_no_by_company_name: action.data,
  };
};

const getBusinessNoByCompanyNameFail = (state) => {
  return {
    ...state,
    business_no_by_company_name_loading: false,
  };
};

export default (state = initState, action) => {
    switch (action.type) {
      case actionTypes.CLEAR_ERROR_MESSAGE:
        return handleClearErrorMessage(state);
      case actionTypes.START_GET_DASHBOARD_LIST:
        return startFetchCustomDashboardList(state);
      case actionTypes.GET_DASHBOARD_LIST_SUCCESS:
        return fetchCustomDashboardListSuccess(state, action);
      case actionTypes.GET_DASHBOARD_LIST_FAIL:
        return fetchCustomDashboardListFail(state, action);
      case actionTypes.START_CREATE_DASHBOARD_LIST:
        return startCreateCustomDashboardList(state);
      case actionTypes.CREATE_DASHBOARD_LIST_SUCCESS:
        return createCustomDashboardListSuccess(state, action);
      case actionTypes.CREATE_DASHBOARD_LIST_FAIL:
        return createCustomDashboardListFail(state, action);
      case actionTypes.START_DELETE_DASHBOARD_LIST:
        return startDeleteCustomDashboardList(state, action);
      case actionTypes.DELETE_DASHBOARD_LIST_SUCCESS:
        return deleteCustomDashboardListSuccess(state, action);
      case actionTypes.DELETE_DASHBOARD_LIST_FAIL:
        return deleteCustomDashboardListFail(state, action);    
      case actionTypes.STAR_GET_DASHBOARD_NUMBER_LIST:
        return startFetchCustomDashboardBusinessNumberList(state);
      case actionTypes.GET_NUMBER_LIST_SUCCESS:
        return fetchCustomDashboardBusinessNumberListSuccess(state, action);
      case actionTypes.GET_NUMBER_LIST_FAIL:
        return fetchCustomDashboardBusinessNumberListSuccessFail(state);
      case actionTypes.STAR_ADD_DASHBOARD_NUMBER_LIST:
        return startAddCustomDashboardBusinessNumberList(state);
      case actionTypes.ADD_NUMBER_LIST_SUCCESS:
        return addCustomDashboardBusinessNumberListSuccess(state, action);
      case actionTypes.ADD_NUMBER_LIST_FAIL:
        return addCustomDashboardBusinessNumberListFail(state, action)
      case actionTypes.STAR_DELETE_DASHBOARD_NUMBER_LIST:
        return startAddCustomDashboardBusinessNumberList(state);
      case actionTypes.DELETE_NUMBER_LIST_SUCCESS:
        return addCustomDashboardBusinessNumberListSuccess(state, action);
      case actionTypes.DELETE_NUMBER_LIST_FAIL:
        return addCustomDashboardBusinessNumberListFail(state, action)
      case actionTypes.STAR_UPDATE_DASHBOARD_NUMBER_LIST:
        return startUpdateCustomDashboardBusinessNumberList(state);
      case actionTypes.UPDATE_NUMBER_LIST_SUCCESS:
        return updateCustomDashboardBusinessNumberListSuccess(state, action);
      case actionTypes.UPDATE_NUMBER_LIST_FAIL:
        return updateCustomDashboardBusinessNumberListFail(state);
      case actionTypes.STAR_GET_NO_BY_COMPANY_NAME:
        return startGetBusinessNoByCompanyName(state);
      case actionTypes.GET_NO_BY_COMPANY_NAME_SUCCESS:
        return getBusinessNoByCompanyNameSuccess(state, action);
      case actionTypes.GET_NO_BY_COMPANY_NAME_FAIL:
        return getBusinessNoByCompanyNameFail(state);
      case actionTypes.START_UPDATE_CUSTOM_BOARD_NAME:
        return startUpdateBoardName(state);
      case actionTypes.UPDATE_CUSTOM_BOARD_NAME_SUCCESS:
        return updateBoardNameSuccess(state);
      case actionTypes.UPDATE_CUSTOM_BOARD_NAME_FAIL:
        return updateBoardNameFail(state);
      case actionTypes.START_CHECK_DASHBOARD_NUMBER_LIST:
        return startCheckCustomDashboardBusinessNumberList(state);
      case actionTypes.CHECK_NUMBER_LIST_SUCCESS:
        return checkCustomDashboardBusinessNumberListSuccess(state, action);
      case actionTypes.CHECK_NUMBER_LIST_FAIL:
        return checkCustomDashboardBusinessNumberListFail(state);
      case actionTypes.HANDLE_CLEAR_CUSTOM_DASHBOARD_BUSINESSNUMBER_LIST:
        return handleClearCustomDashboardBusinessNumberListState(state);
      case actionTypes.UPDATE_BUSINESS_NUMBER_BY_PARKINFO:
        return handleUpdateBusinessNumberByParkInfo(state, action);
      case actionTypes.UPDATE_BUSINESS_NUMBER_BY_COMPANY_SEARCH:
        return handleUpdateBusinessNumberByCompanyInfo(state, action);
      case actionTypes.CLEAR_BUSINESS_NUMBER_BY_PARKINFO:
        return handleClearBusinessNumberByParkInfo(state);
      case actionTypes.CLEAR_BUSINESS_NUMBER_BY_COMPANY_SEARCH:
        return handleClearBusinessNumberByCompanyInfo(state);
      default:
        return state;
    }
  };