import React from "react";
import error from "../../assests/error.gif";

import "./styles.scss";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, timeOut: 10 };
    this.redirectHandler = this.redirectHandler.bind();
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.error(error, errorInfo);
  }

  redirectHandler = () => {
    const { timeOut } = this.state;

    setInterval(() => {
      this.setState({ timeOut: timeOut - 1 });
    }, 1000);

    if (timeOut === 0 && process.env.NODE_ENV !== "development") {
      window.location.replace("/portal");
    }
  };

  render() {
    const { timeOut } = this.state;

    if (this.state.hasError) {
      this.redirectHandler();

      // You can render any custom fallback UI
      return (
        <div className="error">
          <h1>網路連線出問題，請聯繫系統管理員。</h1>
          <h1>將於 {timeOut} 秒後重新導致首頁。</h1>
          <img loading={"lazy"} src={error} alt="error" />
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
