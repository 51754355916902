import React, { useState, useEffect } from "react";
import Icon from "@material-ui/core/Icon";

import Spinner from "../Spinner/Spinner";

import "./CustomizedList.css";
import ShowNoData from "../showNoData/showNoData";
import { randomMath } from "../../utility/common";

const CustomizedList = (props) => {
  const [page, setPage] = useState(1);
  let [fixedPaganationList, setFixPaganationList] = useState([1, 2, 3, 4, 5]);
  const styleList = "buttom-item";
  let [adjustWidth, setAdjustWidth] = useState(50);
  const url = window.location.href.split("/");
  let [companyPageControl, setCompanyPageControl] = useState(0);

  const data = props.listData !== "" ? props.listData : [];
  const ListedCompanyDate =
    props.ListedCompanyDate !== "" ? props.ListedCompanyDate : "";

  //Paganation Function

  const pagination = 1000;

  const paginationList = [];

  for (let i = 1; i <= pagination; i++) {
    paginationList.push(i);
  }

  const paginationHandler = (direction, page) => {
    if (direction === "back") {
      setCompanyPageControl((companyPageControl += 1));
    } else if (direction === "forward") {
      setCompanyPageControl((companyPageControl -= 1));
    }
  };

  //TODO this can beafter connecting api
  let listFilter;
  if (props.listFilter) {
    listFilter = (
      <li className="buttom-list" key="filter">
        <div className="list-filter"></div>
      </li>
    );
  }

  // There are three type of list button, one of them is show nothing. To use this type of buttom, simply not passing props of bottomType.
  // The other one with paganition, just pass props of bottomType, paganation, from parent componet.
  // Last one is show more buttom, just pass props of bottomType, more, from parent componet.
  let buttonContent;

  if (props.bottomType === "paganation" && window.innerWidth > 1024) {
    buttonContent = (
      <li className="buttom-list" key="bottom" style={{ padding: "0px" }}>
        <div className="buttom-list-inner-block">
          <div>共 {data.length} 項結果</div>
          <div>
            <div
              className={styleList}
              onClick={() => paginationHandler("forward", page)}
            >
              往前
            </div>
            {fixedPaganationList.map((item) => (
              <div className="buttom-item-2">{item}</div>
            ))}
            <div>...</div>
            <div className="buttom-item-2">{paginationList.length}</div>
            <div
              className={styleList}
              onClick={() => paginationHandler("back", page)}
            >
              往後
            </div>
          </div>
        </div>
      </li>
    );
  } else if (props.bottomType === "normal" && window.innerWidth > 1024) {
    buttonContent = (
      <li className="buttom-list" key="bottom">
        <div className="buttom-item-1">查看全部</div>
      </li>
    );
  } else {
    buttonContent = null;
  }

  let listTitle;

  //Title Rendering
  if (props.listTitle && !props.vertical && props.listData.length !== 0) {
    if (props.ListedCompany) {
      listTitle = (
        <li className="list-content" key="title">
          {Object.keys(data[0]).map((item, index) => {
            const display =
              index >= Object.values(data[0]).length - 2 ? "none" : "block";
            let content;
            if (props.layout) {
              content = (
                <div
                  key={"title" + index}
                  style={{
                    width: `${props.layout[index]}%`,
                    fontWeight: "500",
                    padding: props.adjustTitle ? "3%" : "0.5%",
                    display: props.disableEnd ? `${display}` : "block",
                  }}
                  // onClick={props.redirectHandler ? window.open(url[0]+'//'+url[2]+'/industry_index/'+ item, '_blank') : ()=> { } }
                >
                  {item === "營業額"
                    ? ListedCompanyDate + item + "(千元)"
                    : item}
                </div>
              );
            }

            return content;
          })}
        </li>
      );
    } else {
      listTitle = (
        <li className="list-content" key="title">
          {Object.keys(data[0]).map((item, index) => {
            const display =
              index >= Object.values(data[0]).length - 2 ? "none" : "block";
            let content;
            if (props.layout) {
              content = (
                <div
                  key={"title" + index}
                  style={{
                    width: `${props.layout[index]}%`,
                    fontWeight: "500",
                    padding: props.adjustTitle ? "3%" : "0.5%",
                    display: props.disableEnd ? `${display}` : "block",
                  }}
                  // onClick={props.redirectHandler ? window.open(url[0]+'//'+url[2]+'/industry_index/'+ item, '_blank') : ()=> { } }
                >
                  {item}
                </div>
              );
            } else {
              const fixedWidth = props.disableEnd
                ? 100 / (Object.keys(data[0]).length - 2)
                : 100 / Object.keys(data[0]).length;

              content = (
                <div
                  key={"title" + index}
                  style={{
                    width: `${fixedWidth}%`,
                    fontWeight: "500",
                    padding: props.adjustTitle ? "3%" : "0.5%",
                    display: props.disableEnd ? `${display}` : "block",
                  }}
                  // onClick={props.redirectHandler ? window.open(url[0]+'//'+url[2]+'/industry_index/'+ item, '_blank') : ()=> { } }
                >
                  {item}
                </div>
              );
            }

            return content;
          })}
        </li>
      );
    }
  }

  //Content Redering
  let listContent;

  if (props.vertical) {
    listContent = (
      <ul>
        {Object.values(data).map((item, index) => {
          return Object.entries(item).map((item, index) => {
            if (props.layout) {
              let withLinkstyle = {
                display: "inherit",
                width: `${props.layout[0]}%`,
              };

              if (props.withLink) {
                withLinkstyle = {
                  textDecoration: "underline",
                  color: "#006ab4",
                  width: `${props.layout[0]}%`,
                };
              }
              return (
                <li className="list-content stock" value={index} key={randomMath()}>
                  <div
                    key={randomMath()}
                    style={withLinkstyle}
                    // onClick={index === 0 ? props.closeModal.bind(this, 'I can noe find this Id') : {}}
                    onClick={
                      index === 0 && props.redirectHandler
                        ? () =>
                            window.open(
                              url[0] +
                                "//" +
                                url[2] +
                                "/industry_index/" +
                                item[0],
                              "_blank"
                            )
                        : () => {}
                    }
                  >
                    {item[0]}
                  </div>
                  <div
                    key={randomMath()}
                    style={
                      item[0] === "公司網站" &&
                      props.companyWebSite &&
                      item[1] !== "無相關資料"
                        ? {
                            width: `${props.layout[1]}%`,
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            textDecoration: "underline",
                            color: "#006ab4",
                          }
                        : {
                            width: `${props.layout[1]}%`,
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                          }
                    }
                    onClick={
                      item[0] === "公司網站" &&
                      props.companyWebSite &&
                      item[1] !== "無相關資料"
                        ? () => {
                            window.open(item[1], "_blank");
                          }
                        : () => {}
                    }
                  >
                    {item[1]}
                    {index === 0 && props.toGoogleMap && " "}
                    {index === 0 && props.toGoogleMap && (
                      <Icon
                        onClick={() => {
                          window.open(
                            `https://maps.google.com/?q=${item[1]}`,
                            "_blank"
                          );
                        }}
                        style={{ cursor: "pointer", marginLeft: "20px" }}
                      >
                        directions
                      </Icon>
                    )}
                  </div>
                </li>
              );
            } else {
              const fixedWidth = props.disableEnd
                ? 100 / (Object.keys(data[0]).length - 2)
                : 100 / Object.keys(data[0]).length;

              return (
                <li className="list-content" value={index} key={randomMath()}>
                  <div key={randomMath()} style={{ width: `${fixedWidth}%` }}>
                    {item[0]}
                  </div>
                  <div
                    key={randomMath()}
                    style={{
                      width: `${fixedWidth}%`,
                    }}
                  >
                    {item[1]}
                  </div>
                </li>
              );
            }
          });
        })}
      </ul>
    );
  } else if (props.companyList && data.length !== 0) {
    listContent = (
      <div>
        <div
          className="company-list-container-tite"
          style={{ display: "flex", fontWeight: "500", color: "#4e5155" }}
          key="title"
        >
          <div
            className="company-list-inner-container"
            style={{ width: "30%", fontWeight: "500" }}
          >
            所處產業園區
          </div>
          <div
            className="company-list-inner-container"
            style={{ width: "20%", fontWeight: "500" }}
          >
            廠商數
          </div>
          <div
            className="company-list-inner-container"
            style={{ width: "50%", fontWeight: "500" }}
          >
            廠商名稱
          </div>
        </div>
        {Object.values(data[companyPageControl])[1].map((item, index) => {
          return (
            <div
              className="company-list-container"
              style={{ display: "flex" }}
              key={index}
            >
              <div
                className="company-list-inner-container"
                style={{ width: "30%" }}
              >
                {index === 0
                  ? Object.values(data[companyPageControl])[0].Name
                  : ""}
                {/* {item.InduPark.Name} */}
              </div>
              <div
                className="company-list-inner-container"
                style={{ width: "20%" }}
              >
                {index === 0
                  ? Object.values(data[companyPageControl])[1].length
                  : ""}
                {/* {item.CompanyList.length} */}
              </div>
              <div
                className="company-list-inner-container"
                style={{
                  width: "50%",
                  color: "#006ab4",
                  fontWeight: "500",
                }}
                onClick={() =>
                  window.open(
                    url[0] +
                      "//" +
                      url[2] +
                      "/factory_info/basic_info/" +
                      item.BusinessNo,
                    "_blank"
                  )
                }
              >
                {item.Name}
              </div>
            </div>
          );
        })}
        <li className="buttom-list" key="bottom" style={{ padding: "0px" }}>
          <div className="buttom-list-inner-block">
            <div>共 {data.length} 處工業區</div>
            <div>
              {companyPageControl !== 0 && (
                <div
                  className={styleList}
                  onClick={() => paginationHandler("forward", page)}
                >
                  往前
                </div>
              )}
              <div></div>
              {companyPageControl !== data.length - 1 && (
                <div
                  className={styleList}
                  onClick={() => paginationHandler("back", page)}
                >
                  往後
                </div>
              )}
            </div>
          </div>
        </li>
      </div>
    );
  } else if (props.companyList && data.length === 0) {
    listContent = <ShowNoData />;
  } else {
    listContent = (
      <ul>
        {data.map((item, index) => {
          return (
            <div>
              <li
                className="list-content"
                value={index}
                key={item ? item : randomMath()}
              >
                {Object.values(item).map((innerItem, index) => {
                  // console.log(item)
                  let content;
                  let itemContent;

                  const display =
                    index >= Object.values(item).length - 2 ? "none" : "block";

                  if (
                    Object.prototype.toString.call(itemContent) ===
                    "[object Array]"
                  ) {
                    itemContent = innerItem.map((item) => <p>{item}</p>);
                  } else {
                    itemContent = innerItem;
                  }

                  if (props.layout && !props.isIndustryList) {
                    content = (
                      <div
                        key={innerItem ? innerItem : randomMath()}
                        style={{
                          width: `${props.layout[index]}%`,
                          display: props.disableEnd ? `${display}` : "block",
                          color: `${
                            (index === 0 && props.news) ||
                            ((index === 0 || index === 1) && props.pubinvention)
                              ? "#006ab4"
                              : "#4e5155"
                          }`,
                          textDecoration: `${
                            (index === 0 && props.news) ||
                            ((index === 0 || index === 1) && props.pubinvention)
                              ? "underline"
                              : "none"
                          }`,
                          fontWeight: `${index === 0 ? "500" : "normal"}`,
                        }}
                        onClick={
                          (index === 0 || index === 1) && !props.noLink
                            ? props.news
                              ? () => {
                                  window.open(item["link"], "_blank");
                                }
                              : () => {
                                  window.open(
                                    window.location.origin +
                                      "/factory_info/basic_info/" +
                                      item["統一編號"],
                                    "_blank"
                                  );
                                }
                            : () => {}
                        }
                      >
                        {itemContent}
                      </div>
                    );
                  } else if (
                    props.isIndustryList &&
                    props.layout &&
                    props.pubinvention
                  ) {
                    content = (
                      <div
                        key={innerItem ? innerItem : randomMath()}
                        style={{
                          width: `${props.layout[index]}%`,
                          display: props.disableEnd ? `${display}` : "block",
                          color: `${
                            (index === 0 && props.news) ||
                            ((index === 0 || index === 1) && props.pubinvention)
                              ? "#006ab4"
                              : "#4e5155"
                          }`,
                          textDecoration: `${
                            (index === 0 && props.news) ||
                            ((index === 0 || index === 1) && props.pubinvention)
                              ? "underline"
                              : "none"
                          }`,
                          fontWeight: `${index === 0 ? "500" : "normal"}`,
                        }}
                        onClick={
                          index === 0
                            ? () => {
                                window.open(
                                  window.location.origin +
                                    "/factory_info/basic_info/" +
                                    itemContent[1],
                                  "_blank"
                                );
                              }
                            : () => {}
                        }
                      >
                        {index === 0 ? itemContent[0] : itemContent}
                      </div>
                    );
                  } else if (
                    props.isIndustryList &&
                    props.layout &&
                    props.ListedCompany
                  ) {
                    var fontcolor;
                    if (index === 2 && itemContent[1] === "上升") {
                      fontcolor = "red";
                    } else if (index === 2 && itemContent[1] === "下降") {
                      fontcolor = "green";
                    } else if (index === 0) {
                      fontcolor = "#006ab4";
                    } else {
                      fontcolor = "#4e5155";
                    }

                    content = (
                      <div
                        key={innerItem ? innerItem : randomMath()}
                        style={{
                          width: `${props.layout[index]}%`,
                          display: props.disableEnd ? `${display}` : "block",
                          //color: `${(index === 0 ) || ((index === 0 )&& props.ListedCompany) ? "#006ab4" : "#4e5155"}`,
                          color: fontcolor,
                          textDecoration: `${
                            (index === 0 && props.news) ||
                            (index === 0 && props.ListedCompany)
                              ? "underline"
                              : "none"
                          }`,
                          fontWeight: `${index === 0 ? "500" : "normal"}`,
                        }}
                        onClick={
                          index === 0
                            ? () => {
                                window.open(
                                  window.location.origin +
                                    "/factory_info/basic_info/" +
                                    itemContent[1],
                                  "_blank"
                                );
                              }
                            : () => {}
                        }
                      >
                        {index === 0 || index === 2
                          ? itemContent[0] === ""
                            ? "未公布"
                            : itemContent[0]
                          : itemContent}
                      </div>
                    );
                  } else if (props.pubinvention) {
                    console.log(itemContent);

                    content = (
                      <div
                        key={innerItem ? innerItem : randomMath()}
                        style={{
                          width: `${props.layout[index]}%`,
                          display: props.disableEnd ? `${display}` : "block",
                          color: `${
                            (index === 0 && props.news) ||
                            ((index === 0 || index === 1) && props.pubinvention)
                              ? "#006ab4"
                              : "#4e5155"
                          }`,
                          textDecoration: `${
                            (index === 0 && props.news) ||
                            ((index === 0 || index === 1) && props.pubinvention)
                              ? "underline"
                              : "none"
                          }`,
                          fontWeight: `${index === 0 ? "500" : "normal"}`,
                        }}
                        onClick={
                          index === 0
                            ? () => {
                                window.open(
                                  window.location.origin +
                                    "/factory_info/basic_info/" +
                                    itemContent[1],
                                  "_blank"
                                );
                              }
                            : () => {}
                        }
                      >
                        {itemContent}
                      </div>
                    );
                  } else {
                    const fixedWidth = props.disableEnd
                      ? 100 / (Object.keys(data[0]).length - 2)
                      : 100 / Object.keys(data[0]).length;

                    content = (
                      <div
                        onClick={index === 0 ? props.closeModal : () => {}}
                        key={innerItem ? innerItem : randomMath()}
                        style={{
                          width: `${fixedWidth}%`,
                          display: props.disableEnd ? `${display}` : "block",
                          color: `${
                            index === 0 && props.news ? "#006ab4" : "#4e5155"
                          }`,
                          fontWeight: `${index === 0 ? "500" : "normal"}`,
                        }}
                      >
                        {itemContent}
                      </div>
                    );
                  }

                  return content;
                })}
              </li>
              {/* <li className="list-content" value={index} key={item.id}>
                    {Object.values(item).map((item, index) => {
                      let content;
                      if (props.layout) {
                        content = (
                          <div
                            key={randomMath()}
                            style={{
                              width: `${props.layout[index]}%`
                            }}
                          >
                            {item}
                          </div>
                        );
                      } else {
                        content = (
                          <div
                            key={randomMath()}
                            style={{
                              width: `${adjustWidth}%`
                            }}
                          >
                            {item}
                          </div>
                        );
                      }
  
                      return content
                    })}
                  </li> */}
            </div>
          );
        })}
        {buttonContent}
      </ul>
    );
  }

  //TODO add useRef
  // useEffect(()=>{
  //     if(data.length !== 0){
  //       console.log('I an in customizd list')
  //       console.log(data)
  //       setAdjustWidth( adjustWidth = 100 / data[0].length )
  //     }
  //     // setAdjustWidth( adjustWidth = 33 )

  // },[props.layout])

  return (
    <div className="list">
      {props.listData.length !== 0 && listTitle}
      {props.listData.length !== 0 && listFilter}
      {props.listData.length !== 0 && listContent}
      {props.listData.length === 0 && props.listData && !props.loading && (
        <ShowNoData />
      )}
      {(!props.listData || props.loading) && <Spinner />}
    </div>
  );
};

export default CustomizedList;
