import React, { useState, useEffect, useRef } from "react";
import {
  Card,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Input,
  Icon
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import Spinner from "../Spinner/Spinner";
import * as govPlanActions from "../../store/actions/govplan";

import "./styles.scss";
import { updateGovPlan } from "../../utility/api";

const MySwal = withReactContent(Swal);

const GovPlanAdjust = props => {
  let isEdit = false;
  const dispatch = useDispatch();
  const { typeTwo, createGovPlanLoading, createGovPlanError, updateGovPlanLoading, updateGovPlanError } = useSelector(state => state.govPlan);
  const { existedPlan, finished } = props;
  const [isCloseModal, setIsCloseModal] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [loading, setLoading] = useState(createGovPlanLoading)
  const [error, setError] = useState(createGovPlanError)
  const [plan, setPlan] = useState({
    project_id: "",
    Name: "",
    Description: "",
    Goal: "",
    ImgUrl: "",
    CorpLevel: "",
    Target: "",
    StartDate: "",
    EndDate: "",
    Link: "",
    Amount: "",
    IndustryCode_Two: [],
    Category: [],
    AttacheFile: "",
    Host: "",
    Executive: "",
    Phone: "",
    Suggest: "",
    Conditions: [],
    IsActive: true
  });
  const [img, setImg] = useState();
  const [attachedFile, setAttachedFile] = useState();
  const [edited, setEdited] = useState(false)
  const [created, setCreated] = useState(false)


  if (props.edit) {
    isEdit = true;
  }

  useEffect(() => {
    if (existedPlan) {
      const { Category, IndustryCode_Two, StartDate, EndDate } = existedPlan;

      if (!Category) {
        existedPlan.Category = [];
      }

      if (!IndustryCode_Two) {
        existedPlan.IndustryCode_Two = [];
      }

      if (StartDate) {
        if (existedPlan.StartDate.includes("/")) {
          const updateStartDate = existedPlan.StartDate.split("/");

          existedPlan.StartDate =
            updateStartDate[0] +
            "-" +
            updateStartDate[1] +
            "-" +
            updateStartDate[2];
        } else {
          const updateStartDate = existedPlan.StartDate.split("-");

          existedPlan.StartDate =
            updateStartDate[0] +
            "-" +
            updateStartDate[1] +
            "-" +
            updateStartDate[2];
        }
      }

      if (EndDate) {
        if (existedPlan.EndDate.includes("/")) {
          const updateEndDate = existedPlan.EndDate.split("/");

          existedPlan.EndDate =
            updateEndDate[0] +
            "-" +
            updateEndDate[1] +
            "-" +
            updateEndDate[2];
        } else {
          const updateEndDate = existedPlan.EndDate.split("-");

          existedPlan.EndDate =
            updateEndDate[0] +
            "-" +
            updateEndDate[1] +
            "-" +
            updateEndDate[2];
        }
      }
      setPlan(existedPlan);
    }
  }, [existedPlan]);

  useEffect(() => {
    setIsCloseModal(finished);
  }, [finished]);

  const inputChangeHandler = event => {
    let value = event.target.value;
    const name = event.target.name;
    const type = event.target.type;

    if (type === "date") {
      const formateDate = value.replace(/-/g, "/");

      console.log(formateDate);
    }

    const updatePlan = { ...plan };
    updatePlan[name] = value;

    setPlan(updatePlan);
  };

  const conditionInputChangeHandler = event => {
    let value = event.target.value;
    const name = event.target.name;
    const type = event.target.type;

    if (type !== "checkbox") {
      if (name === "Capital") {
        const updatePlan = { ...plan };

        const updateObject = {
          CondName: "Capital",
          Operator: null,
          Value: value
        };

        const updateConditions = plan.Conditions.filter(
          condition => condition.CondName !== "Capital"
        ).concat(updateObject);

        updatePlan.Conditions = updateConditions;

        console.log(updatePlan);

        setPlan(updatePlan);
      } else if (name === "AwardNum") {
        const updatePlan = { ...plan };

        const updateObject = {
          CondName: "AwardNum",
          Operator: ">=",
          Value: value
        };

        const updateConditions = plan.Conditions.filter(
          condition => condition.CondName !== "AwardNum"
        ).concat(updateObject);

        updatePlan.Conditions = updateConditions;

        setPlan(updatePlan);
      } else if (name === "PatentNum") {
        const updatePlan = { ...plan };

        const updateObject = {
          CondName: "PatentNum",
          Operator: ">=",
          Value: value
        };

        const updateConditions = plan.Conditions.filter(
          condition => condition.CondName !== "PatentNum"
        ).concat(updateObject);

        updatePlan.Conditions = updateConditions;

        setPlan(updatePlan);
      } else {
        MySwal.fire({
          title: <p>政府相關計畫</p>,
          text: "系統錯誤",
        });
      }
    }
  };

  const operatorHandler = event => {
    const name = event.target.name;
    const capital = plan.Conditions.filter(
      con => con.CondName === "Capital"
    )[0];

    if (!capital) {
      MySwal.fire({
        title: <p>政府相關計畫</p>,
        text: "請先填寫資本額",
      })
      return;
    } else {
      if (name === "Greater") {
        const updatePlan = { ...plan };

        const capitalObject = updatePlan.Conditions.filter(
          condition => condition.CondName === "Capital"
        );

        capitalObject[0].Operator = ">=";

        const updated = updatePlan.Conditions.filter(
          con => con.CondName !== "Capital"
        ).concat(capitalObject);

        updatePlan.Conditions = updated;

        // console.log(updatePlan);

        setPlan(updatePlan);


      } else if (name === "Less") {
        const updatePlan = { ...plan };

        const capitalObject = updatePlan.Conditions.filter(
          condition => condition.CondName === "Capital"
        );

        capitalObject[0].Operator = "<=";

        const updatedPlan = updatePlan.Conditions.filter(
          con => con.CondName !== "Capital"
        ).concat(capitalObject);

        updatePlan.Conditions = updatedPlan;

        setPlan(updatePlan);
      }
    }

    // if(!Capital.Value){
    //   alert('請先填寫資本額')

    //   return
    // }else{

    // }
  };

  const submitHandler = async () => {
    if (
      !plan.Name ||
      !plan.Description ||
      !plan.Goal ||
      // !plan.IndustryCode_Two ||
      !plan.Target ||
      !plan.Category ||
      !plan.Host ||
      !plan.Executive
    ) {
      setIsClicked(true);
      return;
    }

    const form = new FormData();
    form.append("Name", plan.Name);
    form.append("Description", plan.Description);
    form.append("Goal", plan.Goal);
    form.append("IndustryCode_Two", plan.IndustryCode_Two);
    form.append("Target", plan.Target);
    form.append("Category", plan.Category);
    form.append("Amount", plan.Amount);
    form.append("CorpLevel", plan.CorpLevel);
    form.append("Link", plan.Link);
    form.append("IndustryMemo", plan.IndustryMemo);
    form.append("Host", plan.Host);
    form.append("Executive", plan.Executive);
    form.append("Phone", plan.Phone);
    form.append("User", "temp");
    form.append("Img", img);
    form.append("AttachedFile", attachedFile);
    form.append("Suggest", plan.Suggest);
    form.append("Conditions", JSON.stringify(plan.Conditions));

    const updateStartDate = plan.StartDate.split("-");
    form.append(
      "StartDate",
      updateStartDate[0] + "/" + updateStartDate[1] + "/" + updateStartDate[2]
    );
    const updateEndDate = plan.EndDate.split("-");
    form.append(
      "EndDate",
      updateEndDate[0] + "/" + updateEndDate[1] + "/" + updateEndDate[2]
    );

    if (isEdit) {
      await dispatch(govPlanActions.updateGovPlanHandler(form, existedPlan.ProjectId))
      setEdited(true)
    } else {
      await dispatch(govPlanActions.createGovPlanHandler(form))
      setCreated(true)
    }
  };

  const onDeleteHandler = () => {
    dispatch(
      govPlanActions.deleteGovPlanHandler(existedPlan.ProjectId, "jdt01")
    );

    props.closeModal();
  };

  const uploadFileHandler = (event, uploadType) => {
    const files = event.target.files;

    const form = new File(files, files[0].name);

    if (uploadType === "img") {
      setImg(form);
    } else if (uploadType === "file") {
      setAttachedFile(form);
    }
  };

  const closeModalHandler = () => {
    setIsClicked(false)
    setLoading(false)
    setError('')
    setPlan({
      project_id: "",
      Name: "",
      Description: "",
      Goal: "",
      ImgUrl: "",
      CorpLevel: "",
      Target: "",
      StartDate: "",
      EndDate: "",
      Link: "",
      Amount: "",
      IndustryCode_Two: [],
      Category: [],
      AttacheFile: "",
      Host: "",
      Executive: "",
      Phone: "",
      Suggest: "",
      Conditions: [],
      IsActive: true
    });
    setImg()
    setAttachedFile();
    setEdited(false)
    setCreated(false)
    dispatch(govPlanActions.clearState('updateGovPlanError'))
    dispatch(govPlanActions.clearState('createGovPlanError'))
    props.closeModal();



  };


  useEffect(() => {
    if (isEdit) {
      setLoading(updateGovPlanLoading)
    } else {
      setLoading(createGovPlanLoading)
      // setError(error)
    }
  }, [createGovPlanLoading, updateGovPlanError])

  useEffect(() => {
    if (isEdit) {
      setError(updateGovPlanError)
    } else {
      setError(createGovPlanError);
    }
  }, [createGovPlanError, updateGovPlanError])


  if (edited) {
    if (!updateGovPlanError) {
      closeModalHandler();
    }
  }

  if (created) {
    if (!createGovPlanError) {
      closeModalHandler();
    }
  }

  let content;

  if (loading) {
    content =
      <div>
        <Spinner />;
      </div>
  } else if (error) {
    content = (
      <div className='error'>{error}</div>
    )
  } else {
    const { Conditions } = plan;
    let Capital;
    let AwardNum;
    let PatentNum;

    if (Conditions.length !== 0) {
      Capital = Conditions.filter(con => con.CondName === "Capital")[0];
      AwardNum = Conditions.filter(con => con.CondName === "AwardNum")[0];
      PatentNum = Conditions.filter(con => con.CondName === "PatentNum")[0];
    }

    content = (
      <div>
        <div>
          <div>
            <p className="gov-plan-input-start-date-title">計畫名稱*</p>
            <input
              // placeholder="計畫名稱*"
              className="gov-plan-input-name"
              onChange={inputChangeHandler}
              value={plan.Name}
              name="Name"
            />
            {isClicked && !plan.Name && (
              <p className="validation-text">此欄位不得空白</p>
            )}
          </div>
          <div>
            <p className="gov-plan-input-end-date-title">補助金額*</p>
            <input
              type='number'
              placeholder='若無上限請填0'
              className="gov-plan-input-amount"
              onChange={inputChangeHandler}
              value={plan.Amount}
              name="Amount"
            />
            {isClicked && plan.Amount === '' && (
              <p className="validation-text">此欄位不得空白</p>
            )}
          </div>

          {/* <input placeholder="計畫開始時間" className="gov-plan-input-date" /> */}
        </div>
        <div>
          {/* <FormControl className="select-container" variant="outlined">
            <InputLabel className="select-label">企業等級</InputLabel>
            <Select
              value={plan.CorpLevel}
              onChange={inputChangeHandler}
              input={<Input type="select" />}
              name="CorpLevel"
            >
              <MenuItem value="A" key="A">
                A
              </MenuItem>
              <MenuItem value="B" key="B">
                B
              </MenuItem>
              <MenuItem value="C" key="C">
                C
              </MenuItem>
              <MenuItem value="D" key="D">
                D
              </MenuItem>
            </Select>
          </FormControl> */}
          {/* <input placeholder="計畫開始時間" className="gov-plan-input-date" /> */}
        </div>
        <div>
          <div>
            <p className="gov-plan-input-start-date-title">計畫開始申請時間*</p>
            <input
              type="date"
              placeholder="計畫開始時間"
              className="gov-plan-input-start-date"
              onChange={inputChangeHandler}
              value={plan.StartDate}
              name="StartDate"
              pattern="\d{4}-\d{2}-\d{2}"
            />
            {isClicked && !plan.StartDate && (
              <p className="validation-text">此欄位不得空白</p>
            )}
          </div>
          <div>
            <p className="gov-plan-input-end-date-title">計畫結束申請時間*</p>
            <input
              type="date"
              placeholder="計畫結束時間"
              className="gov-plan-input-end-date"
              onChange={inputChangeHandler}
              value={plan.EndDate}
              name="EndDate"
              pattern="\d{4}-\d{2}-\d{2}"
            />
            {isClicked && !plan.EndDate && (
              <p className="validation-text">此欄位不得空白</p>
            )}
          </div>
        </div>
        <div>
          <div>
            <p className="gov-plan-input-start-date-title">主辦單位*</p>
            <input
              className="gov-plan-host"
              onChange={inputChangeHandler}
              value={plan.Host}
              name="Host"
            />
            {isClicked && !plan.Host && (
              <p className="validation-text">此欄位不得空白</p>
            )}
          </div>
          <div>
            <p className="gov-plan-input-end-date-title">執行單位*</p>
            <input
              className="gov-plan-executive"
              onChange={inputChangeHandler}
              value={plan.Executive}
              name="Executive"
            />
            {isClicked && !plan.Executive && (
              <p className="validation-text">此欄位不得空白</p>
            )}
          </div>
        </div>
        <div>
          <div>
            <p className="gov-plan-input-start-date-title">計畫連結</p>
            <input
              className="gov-plan-input-link"
              onChange={inputChangeHandler}
              value={plan.Link}
              name="Link"
            />
            {/* {isClicked && !plan.EndDate && <p className='validation-text'>此欄位不得空白</p>} */}
          </div>
        </div>

        <div>
          <div>
            <FormControl className="select-category" variant="outlined">
              <InputLabel className="select-label">計畫類別*</InputLabel>
              <Select
                multiple
                value={plan.Category}
                onChange={inputChangeHandler}
                input={<Input type="select" />}
                name="Category"
              >
                <MenuItem value="淨零減碳" key="finance-resource">
                  淨零減碳相關
                </MenuItem>
                <MenuItem value="數位轉型" key="finance-resource">
                  數位轉型相關
                </MenuItem>
                <MenuItem value="人力資源相關" key="human-resource">
                  人力資源相關
                </MenuItem>
                <MenuItem value="研究發展相關" key="research-development-resource">
                  研究發展相關
                </MenuItem>
                <MenuItem value="市場行銷相關" key="production-resource">
                  市場行銷相關
                </MenuItem>
                <MenuItem value="資金相關" key="finance-resource">
                  資金相關相關
                </MenuItem>
                <MenuItem value="計畫廣宣" key="finance-resource">
                  課程訊息相關
                </MenuItem>
                <MenuItem value="企業獎項" key="finance-resource">
                  企業獎項相關
                </MenuItem>
                <MenuItem value="資金貸款" key="finance-resource">
                  資金貸款相關
                </MenuItem>
              </Select>
            </FormControl>
            {isClicked && plan.Category.length === 0 && (
              <p className="validation-text">此欄位不得空白</p>
            )}
          </div>
          <div>
            <FormControl className="select-indu-two-code" variant="outlined">
              <InputLabel className="select-label">相關產業別二碼</InputLabel>
              <Select
                multiple
                value={plan.IndustryCode_Two}
                onChange={inputChangeHandler}
                input={<Input type="select" />}
                name="IndustryCode_Two"
              >
                {typeTwo.map((typeTwo) => (
                  <MenuItem value={typeTwo.Key} key={typeTwo.Key}>
                    {typeTwo.Value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {/* {isClicked && plan.IndustryCode_Two.length === 0 && (
              <p className="validation-text">此欄位不得空白</p>
            )} */}
          </div>
        </div>
        <div>
          <div>
            <p className="gov-plan-input-start-date-title">相關產業描述</p>
            <input
              className="gov-plan-input-memo"
              onChange={inputChangeHandler}
              value={plan.IndustryMemo}
              name="IndustryMemo"
            />
            {/* {isClicked && !plan.EndDate && <p className='validation-text'>此欄位不得空白</p>} */}
          </div>
        </div>
        <div className="divider" />
        <div>
          <div>
            <p className="gov-plan-input-start-date-title">資本額*</p>
            <input
              type="number"
              placeholder='若無上限請填0'
              className="gov-plan-input-capital"
              onChange={conditionInputChangeHandler}
              value={Capital ? + Capital.Value : null}
              name="Capital"
            />
            {isClicked && !Capital && (
              <p className="validation-text">此欄位不得空白</p>
            )}
          </div>
          <div className="gov-plan-input-operation-block">
            <div>
              <label>大於</label>
              <input
                type="checkbox"
                className="gov-plan-input-projects"
                onChange={operatorHandler}
                checked={Capital ? Capital.Operator === ">=" : false}
                name="Greater"
              />
            </div>
            <div>
              <label>小於</label>
              <input
                type="checkbox"
                className="gov-plan-input-projects"
                onChange={operatorHandler}
                checked={Capital ? Capital.Operator === "<=" : false}
                name="Less"
              />
            </div>
          </div>
        </div>
        <div>
          <div>
            <p className="gov-plan-input-start-date-title">專利數*</p>
            <input
              type="number"
              placeholder='若無上限請填0'
              className="gov-plan-input-patent"
              onChange={conditionInputChangeHandler}
              value={PatentNum ? +PatentNum.Value : null}
              // value={plan.Conditions.filter(con => con.CondName === "PatentNum").Value}
              name="PatentNum"
            />
            {isClicked && !PatentNum && (
              <p className="validation-text">此欄位不得空白</p>
            )}
          </div>
          <div>
            <p className="gov-plan-input-end-date-title">獲獎數*</p>
            <input
              type="number"
              placeholder='若無上限請填0'
              className="gov-plan-input-projects"
              onChange={conditionInputChangeHandler}
              value={AwardNum ? +AwardNum.Value : null}
              // value={plan.Conditions.filter(con => con.CondName === "AwardNum").Value}
              name="AwardNum"
            />
            {isClicked && !AwardNum && (
              <p className="validation-text">此欄位不得空白</p>
            )}
          </div>
        </div>
        <div className="divider" />
        <div className="area-container">
          <p className="gov-plan-input-start-date-title">計畫目標</p>
          <div className="text-area-box">
            <textarea
              className="gov-plan-input-quality"
              onChange={inputChangeHandler}
              value={plan.Goal}
              name="Goal"
            />
            {isClicked && !plan.Goal && (
              <p className="validation-text">此欄位不得空白</p>
            )}
          </div>
        </div>
        <div className="area-container">
          <p className="gov-plan-input-start-date-title">資格限制*</p>
          <div className="text-area-box">
            <textarea
              className="gov-plan-input-quality"
              onChange={inputChangeHandler}
              value={plan.Target}
              name="Target"
            />
            {isClicked && !plan.Target && (
              <p className="validation-text">此欄位不得空白</p>
            )}
          </div>
        </div>
        <div className="area-container">
          <p className="gov-plan-input-start-date-title">計畫詳細內容*</p>
          <div className="text-area-box">
            <textarea
              className="gov-plan-input-description"
              onChange={inputChangeHandler}
              value={plan.Description}
              name="Description"
            />
            {isClicked && !plan.Description && (
              <p className="validation-text">此欄位不得空白</p>
            )}
          </div>
        </div>
        <div className="area-container">
          <p className="gov-plan-input-start-date-title">輔導建議*</p>
          <div className="text-area-box">
            <textarea
              className="gov-plan-input-suggestion"
              onChange={inputChangeHandler}
              value={plan.Suggest}
              name="Suggest"
            />
            {isClicked && !plan.Suggest && (
              <p className="validation-text">此欄位不得空白</p>
            )}
          </div>
        </div>
        <div className="divider" />
        <div className="gov-plan-upload">
          <div className="gov-plan-input-photo">
            <label>請選擇要上傳的照片</label>
            <input
              type="file"
              accept="image/*"
              onChange={(event) => uploadFileHandler(event, "img")}
            />
          </div>
          {/* <div className="gov-plan-input-file">
            <label>請選擇要上傳的檔案</label>
            <input type="file" onChange={event => uploadFileHandler(event, 'file')} />
          </div> */}
        </div>
        <div className="divider" />
      </div>
    );
  }
  return (
    <div>
      <Card>
        <div className="gov-plan-input-group">
          <div className="header-container">
            <h3>{isEdit ? "修改" + plan.Name : "新增政府計畫"}</h3>
            <Icon onClick={closeModalHandler}>close</Icon>
          </div>
          <div className="gov-plan-input-content">{content}</div>
          <div className="btn-container">
            <button onClick={submitHandler}>
              {isEdit ? "修改" : "新增"}計畫項目
            </button>
            {isEdit && (
              <button onClick={onDeleteHandler} className="delete-btn">
                刪除計畫項目
              </button>
            )}
          </div>
        </div>
      </Card>
    </div>
  );
};

export default GovPlanAdjust;
