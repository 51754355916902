import React from "react";
import Box from "@material-ui/core/Box";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TablePagination from "@material-ui/core/TablePagination";
import Tooltip from "@mui/material/Tooltip";

import NoData from "./NoData";
import google_patents_logo from "../../assets/images/google_patents_logo.png";
import {
  parseUrl,
} from "../../utility/common";

export default (props) => {
    const { data } = props;
    const [state, setState] = React.useState({
        page:0,
        rowsPerPage:5
    })
    const url = window.location.origin;

    const openNewTabs = (link) => window.open(link, "_blank");

    const handleChangePage = (event, newPage) => {
      setState(state=>({ ...state, page: newPage }))
    }

    const companyType = (type) => {
        if(type.indexOf("<category>") > -1){
            return type.substring(type.indexOf("<category>")+"<category>".length,type.indexOf("</category>"));
        }else{
            return "無相關資料"
        }
    };


    return(
        <TableContainer className="table-container">
        <Table className="table">
          <TableHead className="table-header">
            <TableRow className="table-row">
                <TableCell className="table-cell" align="left">專利號</TableCell>
                <TableCell className="table-cell" align="left">專利名稱</TableCell>
                <TableCell className="table-cell" align="left">獲證日期</TableCell>
            </TableRow>
          </TableHead>
          <TableBody className="table-body">
            {data.length !==0 ?data.slice(state.page * state.rowsPerPage, state.page * state.rowsPerPage + state.rowsPerPage).map((row, index)=>{
              return(
                <TableRow key={row.BusinessNo} hover  className="table-body-row" style={index%2 !== 0 ? {backgroundColor:"#ececec"}:{}}>
                  <TableCell align="left">{row.PatentNo}</TableCell>
                  <TableCell align="left">
                    <Box style={{ display: "flex", alignItems: "center"}}>
                      {row.ChineseName}
                      <Tooltip title="點擊觀看專利資訊詳細頁面">
                        <a href={parseUrl(row.PatentNo)} target="_blank" rel="noopener noreferrer" className="googlePatentsLogo">
                          <div style={{ width: "80px", padding: "4px" }}>
                            <img alt="專利資料" src={google_patents_logo} style={{ maxWidth: "100%"}} />
                          </div>
                        </a>
                      </Tooltip>
                    </Box>
                  </TableCell>
                  <TableCell align="left">{row.Date}</TableCell>
                </TableRow>
              )
            }): <NoData />}
          </TableBody>
        </Table>
        {data.length > 5 &&        
            <TablePagination
            rowsPerPageOptions={[]}
            component="div"
            count={data.length}
            rowsPerPage={state.rowsPerPage}
            page={state.page}
            onChangePage={handleChangePage}
          />
        }
        </TableContainer>
    )
}