import React from "react";
import Swal from "sweetalert2";
import { useRouteMatch, useHistory } from "react-router-dom";
import PreviewRow from "./PreviewRow";
import noImg from "../../assests/noImg.jpg";
import Spinner from "../../components/Spinner/Spinner";
import { useSelector, useDispatch } from "react-redux";
import { ScrollContainer } from "../../utility/styles";
import { thousandDivider } from "../../utility/common";
import { photoUrl } from "../../constants/endpoints";
import {
  handleLimitedVisitQuestionData,
  handleGetVisitQuestionDetail,
  handleGetVisitQuestionImage,
} from "../../store/actions/question";

import * as Style from "./styles";
import "./styles.scss";

export default (props) => {
  const dispatch = useDispatch();
  const {
    getLimitedVisitQuestionData,
    getVisitQuestionDetail,
    getLimitedVisitQuestionDataMsg,
    getVisitQuestionImage,
  } = useSelector((state) => state.question);
  const { path } = useRouteMatch();

  const [state, setState] = React.useState({
    contents: [],
    title: [],
  });
  const history = useHistory();
  React.useEffect(() => {
    if (
      getLimitedVisitQuestionDataMsg &&
      getLimitedVisitQuestionDataMsg?.status !== 200
    ) {
      Swal.fire({
        icon: "error",
        title: "取得限時訪廠問卷資料失敗",
        text: getLimitedVisitQuestionDataMsg?.description,
        confirmButtonText: "回到首頁",
        confirmButtonColor: "#25758A",
      }).then((res) => {
        history.push("/portal");
      });
    }
  }, [getLimitedVisitQuestionDataMsg]);

  React.useEffect(() => {
    async function fetchData() {
      const uid = localStorage.getItem("uid");
      const getUrlString = window.location.href;
      const url = new URL(getUrlString);

      if (path.split("/")[1] !== "limited-visit") {
        dispatch(
          handleGetVisitQuestionDetail(
            uid,
            url.pathname.replace("/site-visit-record/", "")
          )
        );
      } else {
        dispatch(
          handleLimitedVisitQuestionData(
            url.pathname.replace("/limited-visit/", "")
          )
        );
      }
    }
    fetchData();
  }, []);

  React.useEffect(() => {
    if (getVisitQuestionDetail.length !== 0) {
      setState((state) => ({
        ...state,
        contents: getVisitQuestionDetail,
      }));
      dispatch(handleGetVisitQuestionImage(getVisitQuestionDetail.Qid));
    } else {
      setState((state) => ({
        ...state,
        contents: getLimitedVisitQuestionData,
      }));
      dispatch(handleGetVisitQuestionImage(getLimitedVisitQuestionData.Qid));
    }
  }, [getLimitedVisitQuestionData, getVisitQuestionDetail]);

  React.useEffect(() => {
    const temp = [];
    if (state.contents.Contents) {
      state.contents.Contents.map((item) => {
        if (temp.indexOf(item.VisitTypeName) === -1) {
          temp.push(item.VisitTypeName);
        }
      });

      setState((state) => ({
        ...state,
        title: temp,
      }));
    }
  }, [state.contents]);

  return state.contents.length !== 0 ? (
    <ScrollContainer>
      <Style.QuestionResultContainer>
        <Style.PreviewSectionTitleContainer>
          <Style.PreviewSectionTitle>{`${state.contents.CompanyName}訪廠問卷`}</Style.PreviewSectionTitle>
          <p style={{ textAlign: "end" }}>{state.contents.UpdateTime}</p>
        </Style.PreviewSectionTitleContainer>

        <Style.Preview>
          <Style.PreviewSectionTitleContainer shouldPadding>
            <Style.PreviewSectionSubTitle>
              受訪廠商基本資料
            </Style.PreviewSectionSubTitle>
          </Style.PreviewSectionTitleContainer>

          <Style.PreviewSectionContainer>
            <Style.PreviewSection>
              <PreviewRow
                title={"公司名稱"}
                data={state.contents.CompanyName}
              />
            </Style.PreviewSection>
            <Style.PreviewSection>
              <PreviewRow title={"統一編號"} data={state.contents.BusinessNo} />
              <PreviewRow
                title={"產業園區(工業區)"}
                data={state.contents.Park}
              />
            </Style.PreviewSection>
            <Style.PreviewSection>
              <PreviewRow
                title={"資本額(萬元)"}
                data={thousandDivider(state.contents.CapitalTotal)}
              />
              <PreviewRow title={"訪廠時間"} data={state.contents.VisitTime} />
            </Style.PreviewSection>
            <Style.PreviewSection>
              <PreviewRow
                title={"產業別"}
                data={[state.contents.IndustryCode]}
              />
            </Style.PreviewSection>
            <Style.PreviewSection>
              <PreviewRow
                title={"主要產品"}
                data={state.contents.MainProduct}
              />
            </Style.PreviewSection>
          </Style.PreviewSectionContainer>
        </Style.Preview>

        <Style.Preview>
          <Style.PreviewSectionTitleContainer shouldPadding>
            <Style.PreviewSectionSubTitle>
              受訪人基本資料
            </Style.PreviewSectionSubTitle>
          </Style.PreviewSectionTitleContainer>

          <Style.PreviewSectionContainer>
            <Style.PreviewSection>
              <PreviewRow
                title={"受訪人名稱"}
                data={state.contents.RespondName}
              />
              <PreviewRow
                title={"受訪人部門/職稱"}
                data={state.contents.RespondDept}
              />
            </Style.PreviewSection>
            <Style.PreviewSection>
              <PreviewRow
                title={"受訪人Email"}
                data={state.contents.RespondMail}
              />
              <PreviewRow
                title={"受訪人電話"}
                data={state.contents.RespondPhone}
              />
            </Style.PreviewSection>
            <Style.PreviewSection>
              <PreviewRow title={"備註"} data={state.contents.Note} />
            </Style.PreviewSection>
          </Style.PreviewSectionContainer>
        </Style.Preview>

        <Style.Preview>
          <Style.PreviewSectionTitleContainer shouldPadding>
            <Style.PreviewSectionSubTitle>
              訪廠資料
            </Style.PreviewSectionSubTitle>
          </Style.PreviewSectionTitleContainer>

          <Style.PreviewSectionContainer>
            <Style.PreviewSection>
              <PreviewRow
                title={"拜訪事由"}
                data={state.contents.VisitReason}
              />
            </Style.PreviewSection>
            <Style.PreviewSection>
              <PreviewRow
                title={"拜訪內容"}
                isForm={true}
                VisitTypeName={state.title}
                data={state.contents.Contents}
              />
            </Style.PreviewSection>
          </Style.PreviewSectionContainer>
        </Style.Preview>

        <Style.Preview>
          <Style.PreviewSectionContainer>
            <Style.PreviewSection>
              <PreviewRow title={"訪談總結"} data={state.contents.Summary} />
            </Style.PreviewSection>
            {!state.contents.PhoneInterview && (
              <>
                <Style.PreviewSection>
                  <PreviewRow
                    title={"受訪人簽名"}
                    isPic={[
                      `${photoUrl}/VisitSign/${state.contents.RespondSign}`,
                      `${photoUrl}/VisitSign/${state.contents.RespondSign_2}`,
                      `${photoUrl}/VisitSign/${state.contents.RespondSign_3}`,
                    ]}
                  />
                </Style.PreviewSection>
                <Style.PreviewSection>
                  <PreviewRow
                    title={"訪談人員簽名"}
                    isPic={[
                      `${photoUrl}/VisitSign/${state.contents.VisitorSign}`,
                      `${photoUrl}/VisitSign/${state.contents.VisitorSign_2}`,
                      `${photoUrl}/VisitSign/${state.contents.VisitorSign_3}`,
                    ]}
                  />
                </Style.PreviewSection>
              </>
            )}
          </Style.PreviewSectionContainer>
        </Style.Preview>

        <Style.Preview>
          <Style.PreviewSectionTitleContainer shouldPadding>
            <Style.PreviewSectionSubTitle>
              訪廠照片
            </Style.PreviewSectionSubTitle>
          </Style.PreviewSectionTitleContainer>

          <Style.PreviewSectionContainer>
            <Style.PreviewSection>
              <PreviewRow
                isPic={
                  getVisitQuestionImage?.[0]
                    ? `${photoUrl}/VisitImage/${getVisitQuestionImage?.[0]?.FileName}`
                    : noImg
                }
              />
              <PreviewRow
                isPic={
                  getVisitQuestionImage?.[1]
                    ? `${photoUrl}/VisitImage/${getVisitQuestionImage?.[1]?.FileName}`
                    : noImg
                }
              />
              <PreviewRow
                isPic={
                  getVisitQuestionImage?.[2]
                    ? `${photoUrl}/VisitImage/${getVisitQuestionImage?.[2]?.FileName}`
                    : noImg
                }
              />
            </Style.PreviewSection>
          </Style.PreviewSectionContainer>
        </Style.Preview>
      </Style.QuestionResultContainer>
    </ScrollContainer>
  ) : (
    <ScrollContainer>
      <Spinner />
    </ScrollContainer>
  );
};
