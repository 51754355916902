import React from "react";
import Icon from "@material-ui/core/Icon";
import PreviewRow from "./PreviewRow";
import { photoUrl } from "../../constants/endpoints";
import noImg from "../../assests/noImg.jpg";
import * as Style from "./styles";

const QuestionPreview = (props) => {
  const { handleClose, result } = props;
  return (
    <Style.SignaturePadModalContainer height={"90%"}>
      <Style.SignaturePadModalContainerHeader>
        <Icon onClick={handleClose}>close</Icon>
      </Style.SignaturePadModalContainerHeader>

      <Style.PreviewCard>
        <Style.PreviewSectionTitleContainer>
          <Style.PreviewSectionTitle>
            訪廠問卷{result[5].group && `(${result[5].group})`}
          </Style.PreviewSectionTitle>
        </Style.PreviewSectionTitleContainer>

        <Style.Preview>
          <Style.PreviewSectionTitleContainer shouldPadding>
            <Style.PreviewSectionSubTitle>
              受訪廠商基本資料
            </Style.PreviewSectionSubTitle>
          </Style.PreviewSectionTitleContainer>

          <Style.PreviewSectionContainer>
            <Style.PreviewSection>
              <PreviewRow title={"公司名稱"} data={result[0]?.companyName} />
            </Style.PreviewSection>
            <Style.PreviewSection>
              <PreviewRow title={"統一編號"} data={result[0]?.companyNumber} />
              <PreviewRow
                title={"產業園區(工業區)"}
                data={result[0]?.industryPark}
              />
            </Style.PreviewSection>
            <Style.PreviewSection>
              <PreviewRow
                title={"資本額(萬元)"}
                data={result[0]?.companyCapital}
              />
              <PreviewRow title={"訪廠時間"} data={result[0]?.visitTime} />
            </Style.PreviewSection>
            <Style.PreviewSection>
              <PreviewRow title={"產業別"} data={result[0]?.companyTypeTwo} />
            </Style.PreviewSection>
            <Style.PreviewSection>
              <PreviewRow
                title={"主要產品"}
                data={result[0]?.companyMainProduct}
              />
            </Style.PreviewSection>
          </Style.PreviewSectionContainer>
        </Style.Preview>

        <Style.Preview>
          <Style.PreviewSectionTitleContainer shouldPadding>
            <Style.PreviewSectionSubTitle>
              受訪人基本資料
            </Style.PreviewSectionSubTitle>
          </Style.PreviewSectionTitleContainer>

          <Style.PreviewSectionContainer>
            <Style.PreviewSection>
              <PreviewRow title={"受訪人名稱"} data={result[0]?.userName} />
              <PreviewRow
                title={"受訪人部門/職稱"}
                data={result[0]?.userDivision}
              />
            </Style.PreviewSection>
            <Style.PreviewSection>
              <PreviewRow title={"受訪人Email"} data={result[0]?.userEmail} />
              <PreviewRow title={"受訪人電話"} data={result[0]?.userPhone} />
            </Style.PreviewSection>
            <Style.PreviewSection>
              <PreviewRow title={"備註"} data={result[0]?.note} />
            </Style.PreviewSection>
          </Style.PreviewSectionContainer>
        </Style.Preview>

        <Style.Preview>
          <Style.PreviewSectionTitleContainer shouldPadding>
            <Style.PreviewSectionSubTitle>
              訪廠資料
            </Style.PreviewSectionSubTitle>
          </Style.PreviewSectionTitleContainer>

          <Style.PreviewSectionContainer>
            <Style.PreviewSection>
              <PreviewRow title={"拜訪事由"} data={result[1]?.stepTwoData} />
            </Style.PreviewSection>
            <Style.PreviewSection>
              <PreviewRow
                title={"拜訪內容"}
                isForm={true}
                VisitTypeName={result[2]?.stepThreeResult}
                TypeChildName={result[3]?.stepFourResult}
                data={result[3]?.stepFourData}
              />
            </Style.PreviewSection>
          </Style.PreviewSectionContainer>
        </Style.Preview>

        <Style.Preview>
          <Style.PreviewSectionContainer>
            <Style.PreviewSection>
              <PreviewRow
                title={"訪談總結"}
                data={result[4]?.conclusion}
                isColumn={true}
              />
            </Style.PreviewSection>

            {!result[5].phoneInterview && (
              <>
                <Style.PreviewSection>
                  <PreviewRow
                    title={"受訪人簽名"}
                    isPic={[!result[4]?.signSupervisorPad?.includes("RespondSign") ? result[4]?.previewSignSupervisorPad : `${photoUrl}/VisitSign/${result[4]?.signSupervisorPad}`, !result[4]?.signSupervisorPad_2?.includes("RespondSign_2") ? result[4]?.previewSignSupervisorPad_2 : `${photoUrl}/VisitSign/${result[4]?.signSupervisorPad_2}`, !result[4]?.signSupervisorPad_3?.includes("RespondSign_3") ? result[4]?.previewSignSupervisorPad_3 : `${photoUrl}/VisitSign/${result[4]?.signSupervisorPad_3}`]}
                  />
                </Style.PreviewSection>
                <Style.PreviewSection>
                  <PreviewRow
                    title={"訪談人簽名"}
                    isPic={[!result[4]?.signParticipantsPad?.includes("VisitorSign") ? result[4]?.previewSignParticipantsPad : `${photoUrl}/VisitSign/${result[4]?.signParticipantsPad}`, !result[4]?.signParticipantsPad_2?.includes("VisitorSign") ? result[4]?.previewSignParticipantsPad_2 : `${photoUrl}/VisitSign/${result[4]?.signParticipantsPad_2}`, !result[4]?.signParticipantsPad_3?.includes("VisitorSign") ? result[4]?.previewSignParticipantsPad_3 : `${photoUrl}/VisitSign/${result[4]?.signParticipantsPad_3}`]}
                  />
                </Style.PreviewSection>
              </>
            )}
          </Style.PreviewSectionContainer>
        </Style.Preview>

        <Style.Preview>
          <Style.PreviewSectionTitleContainer shouldPadding>
            <Style.PreviewSectionSubTitle>
              訪廠照片
            </Style.PreviewSectionSubTitle>
          </Style.PreviewSectionTitleContainer>

          <Style.PreviewSectionContainer>
            <Style.PreviewSection>
              <PreviewRow isPic={result[4]?.questionVisitImg1 || noImg} />
              <PreviewRow isPic={result[4]?.questionVisitImg2 || noImg} />
              <PreviewRow isPic={result[4]?.questionVisitImg3 || noImg} />
            </Style.PreviewSection>
          </Style.PreviewSectionContainer>
        </Style.Preview>
      </Style.PreviewCard>

      <Style.QuestionBasicButtonContainer preview>
        <Style.QuestionBasicButton
          backgroundColor={"purple"}
          onClick={handleClose}
        >
          確定
        </Style.QuestionBasicButton>
      </Style.QuestionBasicButtonContainer>
    </Style.SignaturePadModalContainer>
  );
};

export default QuestionPreview;
