import * as actionTypes from '../actions/actionTypes'

const initState = {
  activePlanList:'',
  inActivePlanList: '',
  induGroupList:'',
  parks:'',
  typeTwo:'',
  typeTwoConvent:{},
  induGroupList:[],
  companyList:[],
  loading:false,
  finished:false,
  deliveryLog:[],
  businessDisplay:[],
  businessLoading:false,
  deliveryLogsLoading:false,
  induGroupLoading:false,
  createGovPlanLoading:false,
  createGovPlanError:null,
  updateGovPlanLoading: false,
  updateGovPlanError: null,
  deleteGovPlanLoading: false,
  deleteGovPlanError: null,
}



export default (state = initState, action) => {
  switch (action.type) {
    case actionTypes.START_CREATE_GOVPLAN:
      return {
        ...state,
        createGovPlanLoading:true
      }
    case actionTypes.CREATE_GOVPLAN_SUCCESS:
      return {
        ...state,
        createGovPlanLoading: false
      }
    case actionTypes.CREATE_GOVPLAN_FAIL:
      return {
        ...state,
        createGovPlanLoading: false,
        createGovPlanError:'系統發生問題，請聯絡管理人員'
      }   
    case actionTypes.GET_ALL_INDUPARK:
      return {
        ...state,
        parks: action.parks
      };
    case actionTypes.GET_ALL_TYPE_TWO:
      return {
        ...state,
        typeTwo: action.typeTwo,
        typeTwoConvent: action.typeTwoConvent
      };
    case actionTypes.GET_ALL_GOVPLANS_SUCCESS:
      return {
        ...state,
        activePlanList: action.activePlans.sort((a, b) => new Date(`${a.EndDate} 00:00:00`).getTime() - new Date(`${b.EndDate} 00:00:00`).getTime()),
        inActivePlanList: action.inActivePlans.sort((a, b) => new Date(`${a.EndDate} 00:00:00`).getTime() - new Date(`${b.EndDate} 00:00:00`).getTime()),
        finished:true
      };
    case actionTypes.GET_ALL_GOVPLANS_FAIL:
      return {
        ...state,
        error: action.error
      }
    case actionTypes.START_CREATE_INDUGROUP:
      return {
        ...state,
        loading:true
      }  
    case actionTypes.CREATE_INDUGROUP_SUCCESS:
      return {
        ...state,
        induGroupList: action.induGroupList,
        loading:false
      }  
    case actionTypes.CREATE_INDUGROUP_FAIL:
      return {
        ...state,
        error:action.error,
        loading:false
      }  
    case actionTypes.START_UPDATE_PLAN:
      return {
        ...state,
        updateGovPlanLoading:true
      }
    case actionTypes.UPDATE_PLAN_SUCCESS:
      return{
        ...state,
        updateGovPlanLoading: false
      }
    case actionTypes.UPDATE_PLAN_FAIL:
      return {
        ...state,
        updateGovPlanLoading: false,
        updateGovPlanError: '系統發生問題，請聯絡管理人員'
      }  
    case actionTypes.DELETE_PLAN_SUCCESS:
      return{
        ...state,
        finished:true
      }    
    case actionTypes.START_LOADING:
      return {
        ...state,
        finished:false,
        loading: true
      };
    case actionTypes.STOP_LOADING:
      return {
        ...state,
        loading: false
      };
    case actionTypes.UPDATE_PLAN_FAIL:
      return {
        ...state,
        error: action.error
      }  
    case actionTypes.START_GET_COMPANYLIST_BY_PARKID:
      return {
        ...state,
        loading:true
      }  
    case actionTypes.COMPANYLIST_BY_PARKID_SUCCESS:
      return {
        ...state,
        companyList: action.companyList,
        loading:false
      };  
    case actionTypes.COMPANYLIST_BY_PARKID_FAIL:
      return {
        ...state,
        error: action.error,
        loading:false
      } 
    case actionTypes.START_GET_DELIVERY_LOG:
      return {
        ...state,
        deliveryLogsLoading: true
      } 
    case actionTypes.GET_DELIVERY_LOG_SUCCESS:
      return {
        ...state,
        deliveryLog: action.deliveryLog,
        deliveryLogsLoading: false
      }   
    case actionTypes.GET_DELIVERY_LOG_FAIL:
      return {
        ...state,
        error: action.error,
        deliveryLogsLoading:false
      }  
    case actionTypes.START_GET_INDUGROUPS:
      return {
        ...state,
        induGroupLoading:true
      }  
    case actionTypes.GET_INDUGROUPS_SUCCESS:
      return {
        ...state,
        induGroupList: action.induGroupList,
        induGroupLoading: false
      }  
    case actionTypes.GET_INDUGROUPS_FAIL:
      return {
        ...state,
        error: action.error,
        induGroupLoading: false
      }  
    case actionTypes.START_CONVERTING_BUSINESS_NO:
      return {
        ...state,
        businessLoading:true,
      }  
    case actionTypes.CONVERTING_BUSINESS_NO_SUCCESS:
      return {
        ...state,
        businessDisplay: action.businessDisplay,
        businessLoading:false
      }   
    case actionTypes.CONVERTING_BUSINESS_NO_FAIL:
      return {
        ...state,
        error: action.error,
        businessLoading: false
      }   
    case actionTypes.START_DELETE_GROUP:
      return state
    case actionTypes.START_UPDATE_GROUP:
      return state  
    case actionTypes.CLEAR_STATE:
      return {
        ...state,
        [action.stateName]:''
      }  

    default:
      return state;
  }
};
