import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 0px 10px;
`;

export const Title = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  > h2 {
    font-size: 1.875rem;
    font-weight: 700;
    letter-spacing: 2px;
    margin-right: 10px;
  }
`;

export const SubTitle = styled.div`
  margin-top: 10px;
`;

export const Icon = styled.div`
  cursor: pointer;
`;

export const Search = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  align-items: flex-end;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const Selector = styled.div`
  display: flex;
  flex-direction: row;
`;