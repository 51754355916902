import * as actionTypes from "../actionTypes/wasted_clean";

const initState = {
  wasted_pollution_list_loading: false,
  wasted_pollution_list: [],
  wasted_pollution_data_loading: false,
  wasted_pollution_data: [],
  wasted_pollution_detail_data_loading: false,
  wasted_pollution_detail_data: [],
  err:""
};

const startFetchWastedPollutionList = (state) => {
  return {
    ...state,
    wasted_pollution_list_loading: true,
  };
};

const fetchWastedPollutionListSuccess = (state, action) => {
  return {
    ...state,
    wasted_pollution_list: action.data.data,
    wasted_pollution_list_loading: false,
  };
};

const fetchWastedPollutionListFail = (state) => {
  return {
    ...state,
    wasted_pollution_list_loading: false,
  };
};

const startFetchWastedPollutionData = (state) => {
  return {
    ...state,
    wasted_pollution_data_loading: true,
  };
};

const fetchWastedPollutionDataSuccess = (state, action) => {
  return {
    ...state,
    wasted_pollution_data: action.data,
    wasted_pollution_data_loading: false,
  };
};

const fetchWastedPollutionDataFail = (state, action) => {
  return {
    ...state,
    wasted_pollution_data_loading: false,
    err: action.err
  };
};


const startFetchWastedDetailList = (state, action) => {
  return {
    ...state,
    wasted_pollution_detail_data_loading : true,
  };
};

const fetchWastedDetailListSuccess = (state, action) => {
  return {
    ...state,
    wasted_pollution_detail_data : action.data.data,
    wasted_pollution_detail_data_loading : false,
  };
};

const fetchWastedDetailListFail = (state, action) => {
  return {
    ...state,
    wasted_pollution_detail_data_loading : false,
  };
};

const handleResetErrText = (state) => {
  return {
    ...state,
    err:""
  };
} 


export default (state = initState, action) => {
  switch (action.type) {
    case actionTypes.START_FETCH_CLEAN_POLLUTION_LIST_DATA:
      return startFetchWastedPollutionList(state);
    case actionTypes.FETCH_CLEAN_POLLUTION_LIST_DATA_SUCCESS:
      return fetchWastedPollutionListSuccess(state, action);
    case actionTypes.FETCH_CLEAN_POLLUTION_LIST_DATA_FAIL:
      return fetchWastedPollutionListFail(state, action);
    case actionTypes.START_FETCH_CLEAN_POLLUTION_DATA:
      return startFetchWastedPollutionData(state);
    case actionTypes.FETCH_CLEAN_POLLUTION_DATA_SUCCESS:
      return fetchWastedPollutionDataSuccess(state, action);
    case actionTypes.FETCH_CLEAN_POLLUTION_DATA_FAIL:
      return fetchWastedPollutionDataFail(state, action);
    case actionTypes.START_FETCH_CLEAN_DETAIL_LIST:
      return startFetchWastedDetailList(state, action);
    case actionTypes.FETCH_CLEAN_DETAIL_LIST_SUCCESS:
      return fetchWastedDetailListSuccess(state, action);
    case actionTypes.FETCH_CLEAN_DETAIL_LIST_FAIL:
      return fetchWastedDetailListFail(state, action);  
    case actionTypes.HANDLE_RESET_ERR:
      return handleResetErrText(state)      
    default:
      return state;
  }
};
