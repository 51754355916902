import styled from "styled-components";
import { Icon } from "@material-ui/core";
import React from "react";

const BackToTopButton = ({scrollToTop}) => {

    return(
        <React.Fragment>
            <BackToTopButtonContainer onClick={scrollToTop}>
                <Icon className="icon" >keyboard_arrow_up</Icon>
            </BackToTopButtonContainer>
        </React.Fragment>

    )
};

const BackToTopButtonContainer = styled.div`
    width: 60px;
    height: 60px;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 30px;
    position: fixed;
    display: flex !important;
    justify-content: center;
    align-items: center;
    bottom: 250px;
    right: 15px;
    left: inherit;
    z-index: 1000;

    .icon{
            color:#FFFFFF;
            font-size: 250%;
            cursor: pointer;
    }

    @media(max-width: 750px) {
        right: inherit;
        left: 15px;
    } 
`

export default BackToTopButton