import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { SectionsContainer, Section } from "react-fullpage";
import Particles from "react-tsparticles";
import Button from "@mui/material/Button";
import anime from "animejs/lib/anime.es.js";
import YouTube from "react-youtube";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";

import { params } from "../constants/particles_configs";

import mobile1 from "../assets/images/about_mobile_1.png";
import mobile2 from "../assets/images/about_mobile_2.png";
import check from "../assets/images/check.png";
import about1 from "../assets/images/about1.png";
import about2 from "../assets/images/about2.png";
import about3 from "../assets/images/about3.png";
import about4 from "../assets/images/about4.png";
import doll from "../assets/images/ug_doll.png";
import contact from "../assets/images/about_contact_us.png";
import facebook from "../assets/images/facebook_black.png";
import line from "../assets/images/line_black.png";
import mirdcLogo from "../assests/images/mirdc.png";
import mugaLogo from "../assests/images/muga.png";
import MIRDC_Logo from "../assests/images/MIRDC_Logo.png";
import moeaLogo from "../assests/images/moeaLogo.png";
import IDBLogo from "../assests/images/IDB.png";

import * as theme from "../assets/theme";

const About = () => {
  const history = useHistory();
  const { innerWidth } = useSelector((state) => state.app);

  React.useEffect(() => {
    if (
      window.location.pathname.split("/")[1] !== "about" &&
      window.location.pathname.split("/")[1] !== "forget_password_redirection"
    ) {
      history.push("/portal");
    }
  }, [window.location.pathname]);

  let options = {
    sectionClassName: "section",
    anchors:
      innerWidth < 1195
        ? ["section_1", "section_2", "section_3", "section_4"]
        : [
          "section_1",
          "section_2",
          "section_3",
          "section_4",
          "section_5",
          "section_6",
          "section_7",
        ],
    scrollBar: false,
    navigation: window.innerWidth < 1195 ? false : true,
    verticalAlign: false,
    arrowNavigation: true,
  };

  if (innerWidth < 1195) {
    return (
      <Container>
        <StyleSection>
          <FirstSectionModal />
        </StyleSection>
        <MobileContentContainer>
          <MobileTextContainer align={"center"}>
            <HeaderText>
              企業愛用 <span>4大功能</span>
            </HeaderText>
            {/* <HeaderSubText>
              從<span>產業</span>到<span>園區</span>，再到<span>個廠</span>
              您想找的產業數據，都幫您整合在這！
            </HeaderSubText> */}
          </MobileTextContainer>
          <MobileView
            img_src={about1}
            img_alt={"快搜服務展示圖"}
            title={"快搜服務"}
            func={() => {
              history.push("/map-search");
            }}>
            <MobileTextContainer align={"flex-start"}>
              <MobileText>
                您只要<span>輸入產品關鍵字</span>，即找到全國相關供應商資訊。
              </MobileText>
            </MobileTextContainer>
          </MobileView>
          <MobileView
            img_src={about2}
            img_alt={"個廠情資展示圖"}
            title={"個廠情資"}
            func={() => {
              history.push("/factory_search");
            }}>
            <MobileTextContainer align={"flex-start"}>
              <MobileText>
                您只要<span>輸入公司關鍵字</span>，即可一次瀏覽個廠9類情資。
              </MobileText>
            </MobileTextContainer>
          </MobileView>
          <MobileView
            img_src={about3}
            img_alt={"園區儀表板展示圖"}
            title={"園區儀表板"}
            func={() => {
              history.push("/park_dashboard/HIP003");
            }}>
            <MobileTextContainer align={"flex-start"}>
              <MobileText>
                您只要<span>點選園區別</span>
                ，即可一次瀏覽該園區的產業數據及廠商形貌等。
              </MobileText>
            </MobileTextContainer>
          </MobileView>
          <MobileView
            img_src={about4}
            img_alt={"產業鏈快搜展示圖"}
            title={"產業鏈快搜"}
            func={() => {
              history.push("/supply-chain");
            }}>
            <MobileTextContainer align={"flex-start"}>
              <MobileText>
                您只需<span>點選產業別</span>
                。該產業的產品關聯性及相關工廠分佈，即可一目瞭然。
              </MobileText>
            </MobileTextContainer>
          </MobileView>
          <MobileTextContainer align={"center"}>
            <HeaderText>
              企業實際操作，<span>看這裡！</span>
            </HeaderText>
          </MobileTextContainer>
          <MobileVideoContainer>
            <VideoContainer videoId={"TGuPX7BCZHw"} />
          </MobileVideoContainer>
          <MobileTextContainer align={"center"}>
            <HeaderText>聯絡我們</HeaderText>
          </MobileTextContainer>
          <MobileView
            img_src={contact}
            img_alt={""}
            title={"財團法人金屬工業研究發展中心"}
            text={""}>
            <MobileTextContainer>
              <MobileText>
                <span>客服窗口：</span>陳小姐 07-3513121 分機 2415
              </MobileText>
              <MobileText>
                <span>服務時間：</span>週一至週五(非國定假日) 09:00～17:00
              </MobileText>
              <MobileText>
                <span>客服信箱：</span>meer@mail.mirdc.org.tw
              </MobileText>
              <FifthIconContainer>
                <Tooltip title={"開啟產業情資通臉書專頁"} placement="top">
                  <FifthIcon
                    src={facebook}
                    alt={"Facebook icon"}
                    onClick={() => {
                      window.open(
                        "https://www.facebook.com/%E7%94%A2%E6%A5%AD%E6%83%85%E8%B3%87%E9%80%9Asmartpark-108158701085956",
                        "_blank"
                      );
                    }}
                  />
                </Tooltip>
                <Tooltip title={"加入產業情資通line@"} placement="top">
                  <FifthIcon
                    src={line}
                    alt={"Line icon"}
                    onClick={() => {
                      window.open(
                        "https://line.me/R/ti/p/@117uphax?from=page",
                        "_blank"
                      );
                    }}
                  />
                </Tooltip>
              </FifthIconContainer>
            </MobileTextContainer>
          </MobileView>
          <MobileTextContainer align={"center"}>
            <HeaderText>友站連結</HeaderText>
          </MobileTextContainer>
          <LinkBoxContainer display="flex" flexWrap="wrap">
            <LinkBox
              href="https://www.moea.gov.tw/Mns/populace/home/Home.aspx"
              target="_blank">
              <div className="content">
                <img src={moeaLogo} alt="" />
                <p>經濟部</p>
              </div>
            </LinkBox>
            <LinkBox
              href="https://www.moeaidb.gov.tw/ctlr?PRO=idx2015"
              target="_blank">
              <div className="content">
                <img src={IDBLogo} alt="" style={{ padding: "25px" }} />
                <p>經濟部工業局</p>
              </div>
            </LinkBox>
            <LinkBox
              href="https://www.moeaidb.gov.tw/iphw/monip/"
              target="_blank">
              <div className="content">
                <img src={IDBLogo} alt="" style={{ padding: "25px" }} />
                <p>北區產業園區管理處</p>
              </div>
            </LinkBox>
            <LinkBox
              href="https://www.moeaidb.gov.tw/iphw/mocip/"
              target="_blank">
              <div className="content">
                <img src={IDBLogo} alt="" style={{ padding: "25px" }} />
                <p>中區產業園區管理處</p>
              </div>
            </LinkBox>
            <LinkBox
              href="https://www.moeaidb.gov.tw/iphw/mosip/"
              target="_blank">
              <div className="content">
                <img src={IDBLogo} alt="" style={{ padding: "25px" }} />
                <p>南區產業園區管理處</p>
              </div>
            </LinkBox>
            <LinkBox href="http://www.muga.org.tw/" target="_blank">
              <div className="content">
                <img src={mugaLogo} alt="" />
                <p>中華民國產業園區廠商聯合總會</p>
              </div>
            </LinkBox>
            <LinkBox href="https://www.mirdc.org.tw/index.aspx" target="_blank">
              <div className="content">
                <img src={MIRDC_Logo} alt="" style={{ padding: "10px" }} />
                <p>金屬工業研究發展中心</p>
              </div>
            </LinkBox>
            <LinkBox href="https://mii.mirdc.org.tw/" target="_blank">
              <div className="content">
                <img src={mirdcLogo} alt="" style={{ padding: "10px" }} />
                <p>MII金屬情報網</p>
              </div>
            </LinkBox>
          </LinkBoxContainer>
        </MobileContentContainer>
        <MobileParticlesContainer>
          <Particles params={params} />
        </MobileParticlesContainer>
      </Container>
    );
  }

  return (
    <Container>
      <SectionsContainer className="container" {...options}>
        <StyleSection>
          <Particles params={params} />
          <FirstSectionModal />
        </StyleSection>
      </SectionsContainer>
      <SectionsContainer className="container" {...options}>
        <StyleSection>
          <SecondSectionModal />
        </StyleSection>
      </SectionsContainer>
      <SectionsContainer className="container" {...options}>
        <StyleSection>
          <ThirdSectionModal />
        </StyleSection>
      </SectionsContainer>
      <SectionsContainer className="container" {...options}>
        <StyleSection>
          <ForthSectionModal />
        </StyleSection>
      </SectionsContainer>
      <SectionsContainer className="container" {...options}>
        <StyleSection>
          <FifthSectionModal />
        </StyleSection>
      </SectionsContainer>
      <SectionsContainer className="container" {...options}>
        <StyleSection>
          <SevenSectionModal />
        </StyleSection>
      </SectionsContainer>
      <SectionsContainer className="container" {...options}>
        <StyleSection>
          <SixthSectionModal />
        </StyleSection>
      </SectionsContainer>
    </Container>
  );
};

const Container = styled.div`
  background-color: gray;
  width: 100vw;
  height: calc(100vh - 114px);
  overflow: hidden;
  transform: translateY(114px);
  .container {
    max-width: 100vw !important;
    padding: 0px 0px;
  }

  /* @media screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:portrait) {
    height: calc(100vh - 70px);
    transform: translateY(70px);
    overflow-y: scroll;
    background: rgb(247, 243, 243);
    background: linear-gradient(
      177deg,
      rgba(247, 243, 243, 0.5) 16%,
      rgba(211, 153, 172, 0.5032387955182073) 37%,
      rgba(151, 0, 50, 0.5) 100%
    );
  }

  @media screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:landscape) {
    height: calc(100vh - 70px);
    transform: translateY(70px);
    overflow-y: scroll;
    background: rgb(247, 243, 243);
    background: linear-gradient(
      177deg,
      rgba(247, 243, 243, 0.5) 16%,
      rgba(211, 153, 172, 0.5032387955182073) 37%,
      rgba(151, 0, 50, 0.5) 100%
    );
  } */

  @media (max-width: 1195px) {
    overflow-y: scroll;
    background: rgb(247, 243, 243);
    background: linear-gradient(
      177deg,
      rgba(247, 243, 243, 0.5) 16%,
      rgba(211, 153, 172, 0.5032387955182073) 37%,
      rgba(151, 0, 50, 0.5) 100%
    );
  }

  @media (max-width: 850px) {
    transform: translateY(0px);
    height: 100%;
    position: fixed;
    top: 70px;
    bottom: 0;
  }
`;

const StyleSection = styled(Section)`
  /* background-color: ${(p) => p.color}; */
  overflow: hidden !important;
  position: relative;
  height: calc(100vh - 114px);
  width: 100vw;
  background: rgb(247, 243, 243);
  background: linear-gradient(
    137deg,
    rgba(247, 243, 243, 0.5) 0%,
    rgba(211, 153, 172, 0.5032387955182073) 39%,
    rgba(151, 0, 50, 0.5) 100%
  );

  @media (max-width: 750px) {
    background: rgb(247, 243, 243, 0);
  }
`;

const Modal = styled.div`
  width: ${(p) => (p.is_small ? "60%" : "80%")};
  height: ${(p) => (p.is_small ? "50vh" : "70vh")};
  position: absolute;
  left: ${(p) => (p.is_small ? "20%" : "10%")};
  top: ${(p) =>
    p.is_small ? "calc((50vh - 114px) * 0.5)" : "calc((30vh - 114px) * 0.5)"};
  background-color: #f7f3f3;
  border-radius: 10px;
  z-index: 1000;
  @media (max-width: 750px) {
    background: rgb(247, 243, 243, 0);
  }
`;

const FirstSectionModal = () => {
  const [state, setState] = React.useState({
    doll_class: "",
  });

  // anime({
  //     targets: '.ug_doll',
  //     translateX: 10,
  //     rotate: 40,
  //     duration: 10000,
  //     loop: true,
  // })

  return (
    <FirstSectionModalWrapper>
      <UgDollImage
        src={doll}
        className={state.doll_class}
        alt={"doll_icon"}
        onMouseEnter={() => {
          setState((state) => ({ ...state, doll_class: "ug_doll" }));
        }}
        onMouseLeave={() => {
          setState((state) => ({ ...state, doll_class: "" }));
        }}
      />
      <Mobile1Image src={mobile1} />
      <Mobile2Image src={mobile2} />
      <FirstSectionModalWrapperRight>
        <FirstSectionModalHeaderTextContainer>
          <FirstSectionModalHeaderText>產業情資通</FirstSectionModalHeaderText>
          <FirstSectionModalHeaderSubText>
            一手掌握 您最需要的產業資訊
          </FirstSectionModalHeaderSubText>
        </FirstSectionModalHeaderTextContainer>
        <FirstSectionModalContentTextContainer>
          <FirstSectionModalContentTextRows>
            <FirstSectionModalContentImage src={check} alt="checked_icon" />
            <FirstSectionModalContentText>
              最<span>即時</span>的產業資訊
            </FirstSectionModalContentText>
          </FirstSectionModalContentTextRows>
          <FirstSectionModalContentTextRows>
            <FirstSectionModalContentImage src={check} alt="checked_icon" />
            <FirstSectionModalContentText>
              最<span>齊全</span>的產業數據
            </FirstSectionModalContentText>
          </FirstSectionModalContentTextRows>
          <FirstSectionModalContentTextRows>
            <FirstSectionModalContentImage src={check} alt="checked_icon" />
            <FirstSectionModalContentText>
              最<span>快速</span>的產品曝光
            </FirstSectionModalContentText>
          </FirstSectionModalContentTextRows>
        </FirstSectionModalContentTextContainer>
      </FirstSectionModalWrapperRight>
      <FirstSectionModalBottomTextContainer>
        <FirstSectionModalHeadBottomText>
          建置目的
        </FirstSectionModalHeadBottomText>
        <FirstSectionModalBottomText>
          為協助活化我國產業與廠商資訊之應用
          ，故工業局自民國108年起委託財團法人金屬工業研究發展中心建置「產業園區廠商情資平台」又名產業情資通，並開放給大眾使用。
        </FirstSectionModalBottomText>
      </FirstSectionModalBottomTextContainer>
    </FirstSectionModalWrapper>
  );
};

const UgDollImage = styled.img`
  position: absolute;
  width: 100px;
  right: 200px;
  transform: rotate(-8deg);
  top: 30px;

  @media (max-width: 1195px) {
    right: 5px;
    transform: rotate(10deg);
    top: 120px;
  }

  @media (max-width: 750px) {
    right: 20px;
    transform: rotate(10deg);
    top: 120px;
  }
`;

const FirstSectionModalWrapper = styled(Modal)`
  @media (max-width: 750px) {
    width: 95%;
    margin: 0px 2.5%;
    left: 0%;
    top: calc((10vh - 70px) * 0.5);
  }
`;

const Mobile1Image = styled.img`
  position: absolute;
  width: 400px;
  height: 400px;
  left: -133px;
  transform: rotate(-8deg);
  top: 44px;
  z-index: 10;

  @media (max-width: 840px) {
    left: -165px;
    top: 15vh;
  }

  @media (max-width: 750px) {
    width: 300px;
    height: 300px;
    transform: rotate(-8deg);
    top: 60vh;
    z-index: 10;
  }
`;

const Mobile2Image = styled.img`
  position: absolute;
  width: 400px;
  height: 400px;
  left: 25px;
  top: 86px;
  z-index: 5;

  @media (max-width: 840px) {
    left: -130px;
    top: 15vh;
  }

  @media (max-width: 750px) {
    width: 400px;
    height: 400px;
    left: 26vw;
    top: 50vh;
    z-index: 5;
    transform: rotate(-10deg);
  }
`;

const FirstSectionModalWrapperLeft = styled.div``;

const FirstSectionModalWrapperRight = styled.div`
  width: 60%;
  margin-left: 40%;
  padding: 20px 0px;

  @media (max-width: 840px) {
    width: 70%;
    margin: 0px 15%;
  }

  @media (max-width: 750px) {
    width: 90%;
    margin: 0px 5%;
  }
`;

const FirstSectionModalHeaderTextContainer = styled.div`
  padding: 10px 0px;
  @media (max-width: 840px) {
    width: 70%;
    margin: 0px 15%;
  }
  @media (max-width: 750px) {
    width: 100%;
    margin: 0px;
  }
`;

const FirstSectionModalHeaderText = styled.h1`
  font-size: 5rem;
  font-weight: 600;
  padding-bottom: 10px;

  @media screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
    font-size: 3rem;
    text-align: center;
  }

  @media screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: landscape) {
    font-size: 3rem;
    text-align: center;
  }

  @media (max-width: 750px) {
    font-size: 3rem;
    text-align: center;
  }
`;

const FirstSectionModalHeaderSubText = styled.h3`
  font-size: 2rem;
  font-weight: 400;
  @media (max-width: 750px) {
    font-size: 1.5rem;
    text-align: center;
  }
`;

const FirstSectionModalContentTextContainer = styled.div`
  height: 40vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const FirstSectionModalContentTextRows = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 0px;

  @media (max-width: 840px) {
    justify-content: center;
  }

  @media (max-width: 750px) {
    padding: 5px 0px;
  }
`;

const FirstSectionModalContentText = styled.p`
  font-size: 1.5rem;
  padding-left: 20px;
  > span {
    color: #ff5f5f;
    font-weight: 600;
    padding: 0px 2px;
  }
`;

const FirstSectionModalContentImage = styled.img`
  width: 40px;
`;

const FirstSectionModalBottomTextContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: absolute;
  bottom: 20px;
  left: 0px;
  width: 90%;
  margin: 0px 5%;

  @media (max-width: 750px) {
    display: none;
  }
`;

const FirstSectionModalHeadBottomText = styled.p`
  padding: 20px;
  font-size: 1.75rem;
  background-color: #ececec;
  border-radius: 30px;
  font-weight: 600;
  width: 30%;
  text-align: center;
`;

const FirstSectionModalBottomText = styled.p`
  font-size: 1.15rem;
  padding-left: 20px;
  line-height: 30px;
`;

const SecondSectionModal = () => {
  const { hash } = window.location;
  const history = useHistory();
  React.useEffect(() => {
    if (hash === "#section_2") {
      setTimeout(() => {
        anime({
          targets: ".title",
          translateX: 0,
          duration: 750,
        });
        anime({
          targets: ".upper_block2",
          translateX: -50,
          duration: 800,
        });
        anime({
          targets: ".downer_block2",
          translateX: 70,
          duration: 800,
        });
      }, 500);
    } else {
      setTimeout(() => {
        anime({
          targets: ".title",
          translateX: -400,
          duration: 750,
        });
        anime({
          targets: ".upper_block2",
          translateX: -400,
          duration: 800,
        });
        anime({
          targets: ".downer_block2",
          translateX: 900,
          duration: 800,
        });
      }, 500);
    }
  }, [hash]);

  return (
    <FirstSectionModalWrapper>
      <HeaderContainer className="title">
        <HeaderText>
          企業愛用 <span>4大功能</span>
        </HeaderText>
        {/* <HeaderSubText>
          從<span>產業</span>到<span>園區</span>，再到<span>個廠</span>{" "}
          您想找的產業數據，都幫您整合在這！
        </HeaderSubText> */}
      </HeaderContainer>
      <SecondSectionUpperContainer className="upper_block2">
        <SectionImageBlock>
          <SectionImage src={about1} alt={"快搜服務展示圖"} />
        </SectionImageBlock>
        <SectionTextBlock is_left>
          <SectionText>快搜服務</SectionText>
          <SectionSubText>
            您只要輸入<span>產品關鍵字</span>，即找到全國相關供應商資訊。
            <StyledButton
              onClick={() => {
                history.push("/map-search");
              }}>
              查看更多
            </StyledButton>
          </SectionSubText>
        </SectionTextBlock>
      </SecondSectionUpperContainer>
      <SecondSectionDownerContainer className="downer_block2">
        <SectionTextBlock is_right>
          <SectionText>個廠情資</SectionText>
          <SectionSubText>
            您只要輸入<span>公司關鍵字</span>，即可一次瀏覽個廠9類情資。{" "}
            <StyledButton
              onClick={() => {
                history.push("/factory_search");
              }}>
              查看更多
            </StyledButton>
          </SectionSubText>
        </SectionTextBlock>
        <SectionImageBlock>
          <SectionImage src={about2} alt={"個廠情資展示圖"} />
        </SectionImageBlock>
      </SecondSectionDownerContainer>
    </FirstSectionModalWrapper>
  );
};

const ThirdSectionModal = () => {
  const { hash } = window.location;
  const history = useHistory();

  React.useEffect(() => {
    if (hash === "#section_3") {
      setTimeout(() => {
        anime({
          targets: ".upper_block3",
          translateX: -50,
          duration: 800,
        });
        anime({
          targets: ".downer_block3",
          translateX: 70,
          duration: 800,
        });
      }, 500);
    } else {
      setTimeout(() => {
        anime({
          targets: ".upper_block3",
          translateX: -400,
          duration: 750,
        });
        anime({
          targets: ".downer_block3",
          translateX: 200,
          duration: 850,
        });
      }, 500);
    }
  }, [hash]);

  return (
    <FirstSectionModalWrapper>
      <ThirdSectionUpperContainer className="upper_block3">
        <SectionImageBlock>
          <SectionImage src={about3} alt={"園區儀表板展示圖"} />
        </SectionImageBlock>
        <SectionTextBlock is_left>
          <SectionText>園區儀表板</SectionText>
          <SectionSubText>
            您只要<span>點選園區別</span>
            ，即可一次瀏覽該園區的產業數據及廠商形貌等。
            <StyledButton
              onClick={() => {
                history.push("/park_dashboard/HIP003");
              }}>
              查看更多
            </StyledButton>
          </SectionSubText>
        </SectionTextBlock>
      </ThirdSectionUpperContainer>
      <ThirdSectionDownerContainer className="downer_block3">
        <SectionTextBlock is_right>
          <SectionText>產業鏈快搜</SectionText>
          <SectionSubText>
            您只需<span>點選產業別</span>
            。該產業的產品關聯性及相關工廠分佈，即可一目瞭然。
            <StyledButton
              onClick={() => {
                history.push("/supply-chain");
              }}>
              查看更多
            </StyledButton>
          </SectionSubText>
        </SectionTextBlock>
        <SectionImageBlock>
          <SectionImage src={about4} alt={"產業鏈快搜展示圖"} />
        </SectionImageBlock>
      </ThirdSectionDownerContainer>
    </FirstSectionModalWrapper>
  );
};

const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* transform: translateY(-20px) !important; */

  > span {
    color: #ff5f5f;
  }
`;

const HeaderText = styled.h1`
  font-size: 2.5rem;
  font-weight: 600;
  padding: 0px 0px 15px 0px;

  > span {
    color: #ff5f5f;
  }

  @media (max-width: 750px) {
    font-size: 2rem;
    text-align: center;
  }
`;

const HeaderSubText = styled.h1`
  font-size: 1rem;
  font-weight: 600;
  padding: 5px 0px;
  line-height: 30px;

  > span {
    color: #ff5f5f;
    font-weight: 400;
  }

  @media (max-width: 750px) {
    text-align: center;
  }
`;

const SecondSectionUpperContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: absolute;
  left: 0px;
  top: 30px;
  width: 100%;
  padding: 10px 20px;
  z-index: 10;
  /* transform: translateX(-400px); */
`;

const SecondSectionDownerContainer = styled(SecondSectionUpperContainer)`
  /* transform: translateX(900px); */
  right: 0px;
  bottom: -260px;
  z-index: 5;
`;

const ThirdSectionUpperContainer = styled(SecondSectionUpperContainer)`
  top: 10px;
`;

const ThirdSectionDownerContainer = styled(SecondSectionDownerContainer)`
  bottom: -260px;
`;

const SectionImageBlock = styled.div`
  width: 40vw;
  height: 30vh;
  background-color: #d9aab9;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SectionImage = styled.img`
  width: 80%;
  object-fit: contain;
  image-resolution: 1500dpi;
  /* height: 40vh; */
`;

const SectionTextBlock = styled.div`
  padding-left: ${(p) => p.is_left && "50px"};
  padding-right: ${(p) => p.is_right && "50px"};
`;

const SectionText = styled.h1`
  font-size: 3rem;
  font-weight: 600;
  padding: 20px 0px 10px 0px;
`;

const SectionSubText = styled(SectionText)`
  font-size: 2rem;
  font-weight: 400;
  line-height: 50px;

  > span {
    font-weight: 600;
  }
`;

const SectionUpperButtonBlock = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 10px 0px;
  transform: translateX(-5px);
`;

const StyledButton = styled(Button)`
  background-color: ${theme.linkBlue} !important;
  color: ${theme.white} !important;
`;

const ForthSectionModal = () => {
  const { hash } = window.location;
  const [player, setPlayer] = React.useState(null);

  const onReady = (event) => {
    setPlayer(event.target);
  };

  React.useEffect(() => {
    if (player) {
      if (hash === "#section_4") {
        player.playVideo();
      } else {
        player.pauseVideo();
      }
    }
  }, [hash]);

  return (
    <ForthSectionModalContainer>
      <HeaderContainer>
        <HeaderText>
          企業實際操作，<span>看這裡！</span>
        </HeaderText>
      </HeaderContainer>
      <VideoContainer
        videoId={"TGuPX7BCZHw"}
        opts={{
          playerVars: {
            autoplay: hash === "#section_4" ? 1 : 0,
          },
        }}
        onReady={onReady}
      />
    </ForthSectionModalContainer>
  );
};

const ForthSectionModalContainer = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const VideoContainer = styled(YouTube)`
  width: 45vw;
  height: 54vh;

  @media (max-width: 840px) {
    width: 100vw;
    height: 34vh;
  }
`;

const FifthSectionModal = () => {
  return (
    <FifthSectionModalContainer>
      <FifthSectionContent>
        <FifthSectionTextContent>
          <FifthSectionHeadText>聯絡我們</FifthSectionHeadText>
          <FifthSectionSubHeadText>
            財團法人金屬工業研究發展中心
          </FifthSectionSubHeadText>
          <FifthSectionTextContainer>
            <FifthSectionText>
              客服窗口：陳小姐 07-3513121 分機 2415
            </FifthSectionText>
            <FifthSectionText>
              服務時間：週一至週五(非國定假日) 09:00～17:00
            </FifthSectionText>
            <FifthSectionText>
              客服信箱：meer@mail.mirdc.org.tw
            </FifthSectionText>
            <FifthIconContainer>
              <Tooltip title={"開啟產業情資通臉書專頁"} placement="top">
                <FifthIcon
                  src={facebook}
                  alt={"Facebook icon"}
                  onClick={() => {
                    window.open(
                      "https://www.facebook.com/%E7%94%A2%E6%A5%AD%E6%83%85%E8%B3%87%E9%80%9Asmartpark-108158701085956",
                      "_blank"
                    );
                  }}
                />
              </Tooltip>
              <Tooltip title={"加入產業情資通line@"} placement="top">
                <FifthIcon
                  src={line}
                  alt={"Line icon"}
                  onClick={() => {
                    window.open(
                      "https://line.me/R/ti/p/@117uphax?from=page",
                      "_blank"
                    );
                  }}
                />
              </Tooltip>
            </FifthIconContainer>
          </FifthSectionTextContainer>
        </FifthSectionTextContent>
        <FifthSectionImageContainer>
          <FifthSectionImage src={contact} />
        </FifthSectionImageContainer>
      </FifthSectionContent>
    </FifthSectionModalContainer>
  );
};

const FifthSectionModalContainer = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FifthSectionContent = styled.div`
  width: 90%;
  margin: 0px 5%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const FifthSectionTextContent = styled.div`
  padding-left: 20px;
`;

const FifthSectionHeadText = styled.h1`
  font-size: 3rem;
  font-weight: 600;
`;

const FifthSectionSubHeadText = styled.h2`
  font-size: 1.5rem;
  padding: 10px 0px 40px 0px;
  border-bottom: 1px solid ${theme.black};
`;

const FifthSectionTextContainer = styled.div`
  padding: 10px 0px;
`;

const FifthSectionText = styled.p`
  font-size: 1rem;
  padding: 10px 0px;
`;

const FifthIconContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const FifthIcon = styled.img`
  width: 40px;
  padding-right: 15px;
  cursor: pointer;
`;

const FifthSectionImageContainer = styled.div`
  padding-left: 10px;
`;

const FifthSectionImage = styled.img`
  height: 30vh;
`;

const SevenSectionModal = () => {
  return (
    <SevenSectionModalContainer>
      <SevenSectionContent>
        <SevenSectionHeadText>友站連結</SevenSectionHeadText>
        <LinkBoxContainer display="flex" flexWrap="wrap">
          <LinkBox
            href="https://www.moea.gov.tw/Mns/populace/home/Home.aspx"
            target="_blank">
            <div className="content">
              <img src={moeaLogo} alt="" />
              <p>經濟部</p>
            </div>
          </LinkBox>
          <LinkBox
            href="https://www.moeaidb.gov.tw/ctlr?PRO=idx2015"
            target="_blank">
            <div className="content">
              <img src={IDBLogo} alt="" style={{ padding: "25px" }} />
              <p>經濟部工業局</p>
            </div>
          </LinkBox>
          <LinkBox
            href="https://www.moeaidb.gov.tw/iphw/monip/"
            target="_blank">
            <div className="content">
              <img src={IDBLogo} alt="" style={{ padding: "25px" }} />
              <p>北區產業園區管理處</p>
            </div>
          </LinkBox>
          <LinkBox
            href="https://www.moeaidb.gov.tw/iphw/mocip/"
            target="_blank">
            <div className="content">
              <img src={IDBLogo} alt="" style={{ padding: "25px" }} />
              <p>中區產業園區管理處</p>
            </div>
          </LinkBox>
          <LinkBox
            href="https://www.moeaidb.gov.tw/iphw/mosip/"
            target="_blank">
            <div className="content">
              <img src={IDBLogo} alt="" style={{ padding: "25px" }} />
              <p>南區產業園區管理處</p>
            </div>
          </LinkBox>
          <LinkBox href="http://www.muga.org.tw/" target="_blank">
            <div className="content">
              <img src={mugaLogo} alt="" />
              <p>中華民國產業園區廠商聯合總會</p>
            </div>
          </LinkBox>
          <LinkBox href="https://www.mirdc.org.tw/index.aspx" target="_blank">
            <div className="content">
              <img src={MIRDC_Logo} alt="" style={{ padding: "10px" }} />
              <p>金屬工業研究發展中心</p>
            </div>
          </LinkBox>
          <LinkBox href="https://mii.mirdc.org.tw/" target="_blank">
            <div className="content">
              <img src={mirdcLogo} alt="" style={{ padding: "10px" }} />
              <p>MII金屬情報網</p>
            </div>
          </LinkBox>
        </LinkBoxContainer>
      </SevenSectionContent>
    </SevenSectionModalContainer>
  );
};
const SevenSectionModalContainer = styled(Modal)`
  display: flex;
  justify-content: center;
`;

const SevenSectionContent = styled.div`
  width: 90%;
  margin: 0px 5%;
  padding: 48px 0px;
`;

const SevenSectionHeadText = styled.h1`
  font-size: 3rem;
  font-weight: 600;
  margin-bottom: 2rem;
`;
const LinkBoxContainer = styled(Box)`
  margin-left: -12px;
  margin-right: -12px;
  @media (max-width: 1195px) {
    margin-left: auto;
    margin-right: auto;
    max-width: 95%;
  }
`;
const LinkBox = styled.a`
  display: block;
  width: 25%;
  padding: 0px 12px;
  margin-top: 20px;
  text-align: center;
  color: #212529;
  @media (max-width: 996px) {
    width: 33%;
  }
  @media (max-width: 768px) {
    width: 49%;
    padding: 0px 8px;
  }
  @media (max-width: 576px) {
    padding: 0px 4px;
  }
  &:hover {
    color: #25758A !important;
    text-decoration: underline;
    .content {
      box-shadow: 5px 5px 5px #d0cccc;
    }
  }
  .content {
    border: 3px solid #ffffff;
  }
  img {
    display: block;
    width: 100%;
    height: 150px;
    object-fit: contain;
    background: white;
    @media (max-height: 805px) {
      height: 12vh;
    }
  }
  p {
    font-size: 1.125rem;
    margin-top: 8px;
    margin-bottom: 8px;
    @media (max-width: 576px) {
      font-size: 1rem;
    }
  }
`;

const SixthSectionModal = () => {
  const currentYear = new Date().getFullYear();
  const { viewCount } = useSelector((state) => state.app);
  const { version, cachedVersion } = useSelector((state) => state.app);
  const [state, setState] = React.useState({
    is_turn: true,
  });
  React.useEffect(() => {
    setInterval(() => {
      setState((state) => ({ ...state, is_turn: !state.is_turn }));
    }, 1000);
  }, []);

  return (
    <SixthSectionModalContainer is_small>
      <SixthSectionContent>
        <SixthSectionImageContainer>
          <SixthSectionImage is_turn={state.is_turn} src={doll} />
        </SixthSectionImageContainer>
        <FifthSectionTextContent>
          <FifthSectionTextContainer>
            <SixthSectionText>
              經濟部產業園區管理局委辦產業園區價值形塑暨廠商情資加值計畫 ©
            </SixthSectionText>
            <SixthSectionText>
              {currentYear} All Rights Reserved
            </SixthSectionText>
            <SixthSectionText>
              v.{cachedVersion}{" "}
              {version !== cachedVersion && "(您目前使用的是較舊的版本)"}
            </SixthSectionText>
            <SixthSectionText>
              瀏覽人次 : {viewCount ? viewCount : "資料載入中 ..."}
            </SixthSectionText>
          </FifthSectionTextContainer>
        </FifthSectionTextContent>
      </SixthSectionContent>
    </SixthSectionModalContainer>
  );
};

const SixthSectionModalContainer = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

const SixthSectionContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SixthSectionImageContainer = styled.div``;

const SixthSectionImage = styled.img`
  transform: ${(p) => (p.is_turn ? "rotate(-45deg)" : "rotate(45deg)")};
  transition: 1000ms ease all;
  padding-right: 20px;
`;

const SixthSectionText = styled(FifthSectionText)`
  font-size: 1.25rem;
`;

const MobileView = ({ img_src, img_alt, children, title, text, func }) => {
  const { innerWidth } = useSelector((state) => state.app);

  return (
    <Card sx={{ maxWidth: "95%", margin: "20px auto" }}>
      <CardMedia
        component="img"
        height={`${innerWidth / 2.5}`}
        image={img_src}
        alt={img_alt}
      />
      <CardContent>
        <MobileHeadText gutterBottom variant="h5" component="div">
          {title}
        </MobileHeadText>
        {children}
      </CardContent>
      {func && (
        <CardActions>
          <Button size="small" onClick={func}>
            查看更多
          </Button>
        </CardActions>
      )}
    </Card>
  );
};

const MobileContentContainer = styled.div`
  position: absolute;
  width: 100vw;
  top: 100vh;
  left: 0px;
  z-index: 10;
  padding-bottom: 150px;
`;

const MobileParticlesContainer = styled.div`
  position: absolute;
  width: 100vw;
  top: 80vh;
  left: 0px;
  height: 340vh;
  z-index: 1;
`;

const MobileTextContainer = styled.div`
  width: 100%;
  margin: 40px auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: ${(p) => p.align};
`;

const MobileHeadText = styled.h1`
  font-size: 1.75rem;
  font-weight: 600;
  padding: 10px 0px 0px 0px;
`;

const MobileSubText = styled.p`
  font-size: 1.15rem;
  line-height: 25px;
  padding: 2.5px 40px;
`;

const MobileText = styled.p`
  font-size: 1.5rem;
  line-height: 35px;
  padding: 5px 0px;
  > span {
    font-weight: 600;
  }

  @media (max-width: 830px) {
    font-size: 1rem;
    line-height: 25px;
  }
`;

const MobileVideoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default About;
