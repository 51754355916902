import * as actionTypes from '../actions/actionTypes';

const initState = {
    userInfo:"",
    getUserInfoLoading:false,
    updateUserInfoLoading:false,
    error:""
};

export default (state = initState, action) => {
  switch (action.type) {
    case actionTypes.START_GET_USER_INFO:
        return {
            ...state,
            getUserInfoLoading: true,
        }      
    case actionTypes.GET_USER_INFO_SUCCESS:
        return {
            ...state,
            userInfo: action.userInfo,
            getUserInfoLoading: false
        }    
    case actionTypes.GET_USER_INFO_FAIL:
        return {
            ...state,
            getUserInfoLoading: false,
            err: action.err
        }    
    case actionTypes.START_UPDATE_USER_INFO:
        return {
            ...state,
            updateUserInfoLoading: false,
        }    
    case actionTypes.UPDATE_GROUP_SUCCESS:
        return {
            ...state,
            updateUserInfoLoading: true,
        }        
    case actionTypes.UPDATE_GROUP_FAIL:
        return {
            ...state,
            updateUserInfoLoading: true,
        }    
    default:
      return state;
  }
};
