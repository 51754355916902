export const INNER_WIDTH_CHANGING = 'INNER_WIDTH_CHANGING'

export const START_LOADING = 'START_LOADING'
export const STOP_LOADING = 'STOP_LOADING'
export const START_REQUEST = 'START_REQUEST'
export const STOP_REQUEST = 'STOP_REQUEST'

export const START_CREATE_GOVPLAN = 'START_CREATE_GOV_PLAN'
export const CREATE_GOVPLAN_SUCCESS = 'CREATE_GOV_PLAN_SUCCESS'
export const CREATE_GOVPLAN_FAIL = 'CREATE_GOV_PLAN_FAIL'
export const GET_ALL_GOVPLANS_SUCCESS = 'GET_ALL_GOVPLANS_SUCCESS'
export const GET_ALL_GOVPLANS_FAIL = "GET_ALL_GOVPLANS_FAIL";
export const GET_PLAN = 'GET_PLAN'
export const START_UPDATE_PLAN = "START_UPDATE_PLAN"
export const UPDATE_PLAN_SUCCESS = "UPDATE_PLAN_SUCCESS";
export const UPDATE_PLAN_FAIL = "UPDATE_PLAN_FAIL";
export const DELETE_PLAN_SUCCESS = 'DELETE_PLAN_SUCCESS'
export const DELETE_PLAN_FAIL = 'DELETE_PLAN_FAIL'

export const START_GET_INDUGROUPS = 'START_GET_INDUGROUPS'
export const GET_INDUGROUPS_SUCCESS = 'GET_INDUGROUPS_SUCCESS'
export const GET_INDUGROUPS_FAIL = 'GET_INDUGROUPS_FAIL'
export const START_CREATE_INDUGROUP = 'START_CREATE_INDUGROUP'
export const CREATE_INDUGROUP_SUCCESS = 'CREATE_INDUGROUP_SUCCESS'
export const CREATE_INDUGROUP_FAIL = 'CREATE_INDUGROUP_FAIL'

export const UPDATE_INDUGROUP = 'UPDATE_INDUGROUP'
export const GET_DELIVERY_LOG = 'GET_DELIVERY_LOG'

export const GET_ALL_INDUPARK = 'GET_ALL_INDUPARK'
export const GET_ALL_TYPE_TWO = 'GET_ALL_TYPE_TWO'

export const START_GET_COMPANYLIST_BY_PARKID = 'START_GET_COMPANYLIST_BY_PARKID'
export const COMPANYLIST_BY_PARKID_SUCCESS = 'COMPANYLIST_BY_PARKID_SUCCESS'
export const COMPANYLIST_BY_PARKID_FAIL = 'SCOMPANYLIST_BY_PARKID_FAIL'

export const START_GET_DELIVERY_LOG = 'START_GET_DELIVERY_LOG'
export const GET_DELIVERY_LOG_SUCCESS = 'GET_DELIVERY_LOG_SUCCESS'
export const GET_DELIVERY_LOG_FAIL = 'GET_DELIVERY_LOG_FAIL'


export const START_CONVERTING_BUSINESS_NO = 'START_CONVERTING_BUSINESS_NO'
export const CONVERTING_BUSINESS_NO_SUCCESS = 'CONVERTING_BUSINESS_NO_SUCCESS'
export const CONVERTING_BUSINESS_NO_FAIL = 'CONVERTING_BUSINESS_NO_FAIL'

export const START_DELETE_GROUP = 'START_DELETE_GROUP'
export const DELETE_GROUP_SUCCESS = 'DELETE_GROUP__SUCCESS'
export const DELETE_GROUP_FAIL = 'DELETE_GROUP_FAIL'

export const START_UPDATE_GROUP = 'START_UPDATE_GROUP'
export const UPDATE_GROUP_SUCCESS = 'UPDATE_GROUP_SUCCESS'
export const UPDATE_GROUP_FAIL = 'UPDATE_GROUP_FAIL'

export const INIT_MAP_SELECTION = 'INIT_MAP_SELECTION'
export const INIT_MAP_SELECTION_SUCCESS = 'INIT_MAP_SELECTION_SUCCESS'
export const INIT_MAP_SELECTION_FAIL = 'INIT_MAP_SELECTION_FAIL'

export const CLEAR_STATE = 'CLEAR_STATE'

export const START_SIGN_IN = 'SIGN_IN'
export const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS'
export const SING_IN_FAIL = 'SING_IN_FAIL'


export const START_GET_AUTH_FROM_LOCAL = 'START_GET_AUTH_FROM_LOCAL'
export const GET_AUTH_FROM_LOCAL_SUCCESS = 'GET_AUTH_FROM_LOCAL_SUCCESS'
export const GET_AUTH_FROM_LOCAL_FAIL = ' GET_AUTH_FROM_LOCAL_FAIL'

export const LOG_OUT = 'LOG_OUT'

export const START_CHANGE_PASS = 'START_CHANGE_PASS'
export const CHANGE_PASS_SUCCESS = 'CHANGE_PASS_SUCCESS'
export const CHANGE_PASS_FAIL = 'CHANGE_PASS_FAIL'

export const START_SIGN_UP = 'START_SIGN_UP'
export const SIGN_UP_SUCCESS = 'SIGN_UP_SUCCES'
export const SING_UP_FAIL = 'SIGN_UP_FAIL'

export const START_FORGET_PASS = 'START_FORGET_PASS'
export const FORGET_PASS_SUCCESS = 'FORGET_PASS_SUCCESS'
export const FORGET_PASS_FAIL = 'FORGET_PASS_FAIL'

export const START_GET_FORGET_PASS_EMAIL = 'START_GET_FORGET_PASS_EMAIL'
export const GET_FORGET_PASS_EMAIL_SUCCESS = 'GET_FORGET_PASS_EMAIL_SUCCESS'
export const GET_FORGET_PASS_EMAIL_FAIL = 'GET_FORGET_PASS_EMAIL_FAIL'

export const START_GET_USER_INFO = 'START_GET_USER_INFO'
export const GET_USER_INFO_SUCCESS = 'GET_USER_INFO_SUCCESS'
export const GET_USER_INFO_FAIL = 'GET_USER_INFO_FAIL'

export const START_UPDATE_USER_INFO = 'START_UPDATE_USER_INFO'
export const UPDATE_USER_INFO_SUCCESS = 'UPDATE_USER_INFO_SUCCESS'
export const UPDATE_USER_INFO_FAIL = 'UPDATE_USER_INFO_FAIL'

// 申請EXCEL
export const START_APPLY_FILE = 'START_APPLY_FILE'
export const APPLY_FILE_SUCCESS = 'APPLY_FILE_SUCCESS'
export const APPLY_FILE_FAIL = 'UPDATE_USER_INFO_FAIL'

// 查詢申請EXCEL次數
export const START_GET_APPLY_FILE_INFO = 'START_GET_APPLY_FILE_INFO'
export const GET_APPLY_FILE_INFO_SUCCESS = 'GET_APPLY_FILE_INFO_SUCCESS'
export const GET_APPLY_FILE_INFO_FAIL = 'GET_APPLY_FILE_INFO_FAIL'

// 取得申請檔案下拉選單
export const START_GET_APPLY_FILE_DROPDOWN_LIST = 'START_GET_APPLY_FILE_DROPDOWN_LIST'
export const GET_APPLY_FILE_DROPDOWN_LIST_SUCCESS = 'GET_APPLY_FILE_DROPDOWN_LIST_SUCCESS'
export const GET_APPLY_FILE_DROPDOWN_LIST_FAIL = 'GET_APPLY_FILE_DROPDOWN_LIST_FAIL'

export const START_GET_CARDCONTROL = "START_GET_CARDCONTROL";
export const GET_CARDCONTROL_SUCCESS = "GET_CARDCONTROL_SUCCESS";
export const GET_CARDCONTROL_FAIL = "GET_CARDCONTROL_FAIL";

export const START_UPDATE_CARDCONTROL = "START_UPDATE_CARDCONTROL";
export const UPDATE_CARDCONTROL_SUCCESS = "UPDATE_CARDCONTROL_SUCCESS";
export const UPDATE_CARDCONTROL_FAIL = "UPDATE_CARDCONTROL_FAIL";

export const START_GET_SECTION_MAP = "START_GET_SECTION_MAP";
export const GET_SECTION_MAP_SUCCESS = "GET_SECTION_MAP_SUCCESS";
export const GET_SECTION_MAP_FAIL = "GET_SECTION_MAP_FAIL";

export const START_UPDATE_SECTION_MAP = "START_UPDATE_SECTION_MAP";
export const UPDATE_SECTION_MAP_SUCCESS = "UPDATE_SECTION_MAP_SUCCESS";
export const UPDATE_SECTION_MAP_FAIL = "UPDATE_SECTION_MAP_FAIL";

export const START_GET_VIEW_COUNT = "START_GET_VIEW_COUNT";
export const GET_VIEW_COUNT_SUCCESS = "GET_VIEW_COUNT_SUCCESS";
export const GET_VIEW_COUNT_FAIL = "GET_VIEW_COUNT_FAIL";

export const START_GET_TYPE_TWO_CHART_DATA = "START_GET_TYPE_TWO_CHART_DATA";
export const GET_TYPE_TWO_CHART_DATA_SUCCESS = "GET_TYPE_TWO_CHART_DATA_SUCCESS";
export const GET_TYPE_TWO_CHART_DATA_FAIL = "GET_TYPE_TWO_CHART_DATA_FAIL";

export const START_GET_TYPE_THREE_CHART_DATA = "START_GET_TYPE_THREE_CHART_DATA";
export const GET_TYPE_THREE_CHART_DATA_SUCCESS = "GET_TYPE_THREE_CHART_DATA_SUCCESS";
export const GET_TYPE_THREE_CHART_DATA_FAIL = "GET_TYPE_THREE_CHART_DATA_FAIL";

export const START_GET_TYPE_FOUR_CHART_DATA = "START_GET_TYPE_FOUR_CHART_DATA";
export const GET_TYPE_FOUR_CHART_DATA_SUCCESS = "GET_TYPE_FOUR_CHART_DATA_SUCCESS";
export const GET_TYPE_FOUR_CHART_DATA_FAIL = "GET_TYPE_FOUR_CHART_DATA_FAIL";

export const START_GET_PUBLIC_STOCK_CHART_DATA = "START_GET_PUBLIC_STOCK_CHART_DATA";
export const GET_PUBLIC_STOCK_CHART_DATA_SUCCESS = "GET_PUBLIC_STOCK_CHART_DATA_SUCCESS";
export const GET_PUBLIC_STOCK_CHART_DATA_FAIL = "GET_PUBLIC_STOCK_CHART_DATA_FAIL";

export const START_GET_NOT_PUBLIC_STOCK_CHART_DATA = "START_GET_NOT_PUBLIC_STOCK_CHART_DATA";
export const GET_NOT_PUBLIC_STOCK_CHART_DATA_SUCCESS = "GET_NOT_PUBLIC_STOCK_CHART_DATA_SUCCESS";
export const GET_NOT_PUBLIC_STOCK_CHART_DATA_FAIL = "GET_NOT_PUBLIC_STOCK_CHART_DATA_FAIL";

export const START_GET_STOCK_RANKING_DATA = "START_GET_PUBLIC_STOCK_RANKING_DATA";
export const GET_STOCK_RANKING_DATA_SUCCESS = "GET_PUBLIC_STOCK_RANKING_DATA_SUCCESS";
export const GET_STOCK_RANKING_DATA_FAIL = "GET_PUBLIC_STOCK_RANKING_DATA_FAIL";

// export const START_GET_NOT_PUBLIC_STOCK_RANKING_DATA = "START_GET_NOT_PUBLIC_STOCK_RANKING_DATA";
// export const GET_NOT_PUBLIC_STOCK_RANKING_DATA_SUCCESS = "GET_NOT_PUBLIC_STOCK_RANKING_DATA_SUCCESS";
// export const GET_NOT_PUBLIC_STOCK_RANKING_DATA_FAIL = "GET_NOT_PUBLIC_STOCK_RANKING_DATA_FAIL";

export const START_GET_TOP_TEN_CAPITAL_DATA = "START_GET_TOP_TEN_CAPITAL_DATA";
export const GET_TOP_TEN_CAPITAL_DATA_SUCCESS = "GET_TOP_TEN_CAPITAL_DATA_SUCCESS";
export const GET_TOP_TEN_CAPITAL_DATA_FAIL = "GET_TOP_TEN_CAPITAL_DATA_FAIL";

export const START_GET_TOP_TEN_GOVPLAN_DATA = "START_GET_TOP_TEN_GOVPLAN_DATA";
export const GET_TOP_TEN_GOVPLAN_DATA_SUCCESS = "GET_TOP_TEN_GOVPLAN_DATA_SUCCESS";
export const GET_TOP_TEN_GOVPLAN_DATA_FAIL = "GET_TOP_TEN_GOVPLAN_DATA_FAIL";

export const START_GET_TOP_TEN_PATENT_DATA = "START_GET_TOP_TEN_PATENT_DATA";
export const GET_TOP_TEN_PATENT_DATA_SUCCESS = "GET_TOP_TEN_PATENT_DATA_SUCCESS";
export const GET_TOP_TEN_PATENT_DATA_FAIL = "GET_TOP_TEN_PATENT_DATA_FAIL";

export const START_GET_IMPORT_PERCENTAGE_DATA = "START_GET_IMPORT_PERCENTAGE_DATA";
export const GET_IMPORT_PERCENTAGE_DATA_SUCCESS = "GET_IMPORT_PERCENTAGE_DATA_SUCCESS";
export const GET_IMPORT_PERCENTAGE_DATA_FAIL = "GET_IMPORT_PERCENTAGE_DATA_FAIL";

export const START_GET_EXPORT_PERCENTAGE_DATA = "START_GET_EXPORT_PERCENTAGE_DATA";
export const GET_EXPORT_PERCENTAGE_DATA_SUCCESS = "GET_EXPORT_PERCENTAGE_DATA_SUCCESS";
export const GET_EXPORT_PERCENTAGE_DATA_FAIL = "GET_EXPORT_PERCENTAGE_DATA_FAIL";

export const START_GET_PUBLIC_STOCK_PERCENTAGE_DATA = "START_GET_PUBLIC_STOCK_PERCENTAGE_DATA";
export const GET_PUBLIC_STOCK_PERCENTAGE_DATA_SUCCESS = "GET_PUBLIC_STOCK_PERCENTAGE_DATA_SUCCESS";
export const GET_PUBLIC_STOCK_PERCENTAGE_DATA_FAIL = "GET_PUBLIC_STOCK_PERCENTAGE_DATA_FAIL";

export const START_GET_GOV_PLAN_PERCENTAGE_DATA = "START_GET_GOV_PLAN_PERCENTAGE_DATA";
export const GET_GOV_PLAN_PERCENTAGE_DATA_SUCCESS = "GET_GOV_PLAN_PERCENTAGE_DATA_SUCCESS";
export const GET_GOV_PLAN_PERCENTAGE_DATA_FAIL = "GET_GOV_PLAN_PERCENTAGE_DATA_FAIL";

export const START_GET_AWARD_PERCENTAGE_DATA = "START_GET_AWARD_PERCENTAGE_DATA";
export const GET_AWARD_PERCENTAGE_DATA_SUCCESS = "GET_AWARD_PERCENTAGE_DATA_SUCCESS";
export const GET_AWARD_PERCENTAGE_DATA_FAIL = "GET_AWARD_PERCENTAGE_DATA_FAIL";

export const START_GET_NEWS_INFO_DATA = "START_GET_NEWS_INFO_DATA";
export const GET_NEWS_INFO_DATA_SUCCESS = "GET_NEWS_INFO_DATA_SUCCESS";
export const GET_NEWS_INFO_DATA_FAIL = "GET_NEWS_INFO_DATA_FAIL";

export const START_GET_NEWS_TAGS_DATA = "START_GET_NEWS_TAGS_DATA";
export const GET_NEWS_TAGS_DATA_SUCCESS = "GET_NEWS_TAGS_DATA_SUCCESS";
export const GET_NEWS_TAGS_DATA_FAIL = "GET_NEWS_TAGS_DATA_FAIL";

export const START_GET_EMPLOYEE_DATA = "START_GET_EMPLOYEE_DATA";
export const GET_EMPLOYEE_DATA_SUCCESS = "GET_EMPLOYEE_DATA_SUCCESS";
export const GET_EMPLOYEE_DATA_FAIL = "GET_EMPLOYEE_DATA_FAIL";

export const START_GET_NEWS_INFO_TAG_DATA = "START_GET_NEWS_INFO_TAG_DATA";
export const GET_NEWS_INFO_TAG_DATA_SUCCESS = "GET_NEWS_INFO_TAG_DATA_SUCCESS";
export const GET_NEWS_INFO_TAG_DATA_FAIL = "GET_NEWS_INFO_TAG_DATA_FAIL";

export const START_GET_GREEN_FACTORY_DATA = "START_GET_GREEN_FACTORY_DATA";
export const GET_GREEN_FACTORY_DATA_SUCCESS = "GET_GREEN_FACTORY_DATA_SUCCESS";
export const GET_GREEN_FACTORY_DATA_FAIL = "GET_GREEN_FACTORY_DATA_FAIL";

export const START_GET_COMPANY_ASSOCIATION_DATA = "START_GET_COMPANY_ASSOCIATION_DATA";
export const GET_COMPANY_ASSOCIATION_DATA_SUCCESS = "GET_COMPANY_ASSOCIATION_DATA_SUCCESS";
export const GET_COMPANY_ASSOCIATION_DATA_FAIL = "GET_COMPANY_ASSOCIATION_DATA_FAIL";

export const START_GET_BRIEF_AND_RANKING_DATA = "START_GET_BRIEF_AND_RANKING_DATA";
export const GET_BRIEF_AND_RANKING_DATA_SUCCESS = "GET_BRIEF_AND_RANKING_DATA_SUCCESS";
export const GET_BRIEF_AND_RANKING_DATA_FAIL = "GET_BRIEF_AND_RANKING_DATA_FAIL";

export const START_GET_TYPE_TWO_LIST_DATA = "START_GET_TYPE_TWO_LIST_DATA";
export const GET_TYPE_TWO_LIST_DATA_SUCCESS = "GET_TYPE_TWO_LIST_DATA_SUCCESS";
export const GET_TYPE_TWO_LIST_DATA_FAIL = "GET_TYPE_TWO_LIST_DATA_FAIL";

export const START_GET_TYPE_THREE_LIST_DATA = "START_GET_TYPE_THREE_LIST_DATA";
export const GET_TYPE_THREE_LIST_DATA_SUCCESS = "GET_TYPE_THREE_LIST_DATA_SUCCESS";
export const GET_TYPE_THREE_LIST_DATA_FAIL = "GET_TYPE_THREE_LIST_DATA_FAIL";

export const START_GET_TYPE_FOUR_LIST_DATA = "START_GET_TYPE_FOUR_LIST_DATA";
export const GET_TYPE_FOUR_LIST_DATA_SUCCESS = "GET_TYPE_FOUR_LIST_DATA_SUCCESS";
export const GET_TYPE_FOUR_LIST_DATA_FAIL = "GET_TYPE_FOUR_LIST_DATA_FAIL";

export const START_GET_IMPORT_LIST_DATA = "START_GET_IMPORT_LIST_DATA";
export const GET_IMPORT_LIST_DATA_SUCCESS = "GET_IMPORT_LIST_DATA_SUCCESS";
export const GET_IMPORT_LIST_DATA_FAIL = "GET_IMPORT_LIST_DATA_FAIL";

export const START_GET_EXPORT_LIST_DATA = "START_GET_EXPORT_LIST_DATA";
export const GET_EXPORT_LIST_DATA_SUCCESS = "GET_EXPORT_LIST_DATA_SUCCESS";
export const GET_EXPORT_LIST_DATA_FAIL = "GET_EXPORT_LIST_DATA_FAIL";

export const START_GET_GOV_PLAN_LIST_DATA = "START_GET_GOV_PLAN_LIST_DATA";
export const GET_GOV_PLAN_LIST_DATA_SUCCESS = "GET_GOV_PLAN_LIST_DATA_SUCCESS";
export const GET_GOV_PLAN_LIST_DATA_FAIL = "GET_GOV_PLAN_LIST_DATA_FAIL";

export const START_GET_AWARD_LIST_DATA = "START_GET_AWARD_LIST_DATA";
export const GET_AWARD_LIST_DATA_SUCCESS = "GET_AWARD_LIST_DATA_SUCCESS";
export const GET_AWARD_LIST_DATA_FAIL = "GET_AWARD_LIST_DATA_FAIL";

export const START_GET_PATENT_LIST_DATA = "START_GET_PATENT_LIST_DATA";
export const GET_PATENT_LIST_DATA_SUCCESS = "GET_PATENT_LIST_DATA_SUCCESS";
export const GET_PATENT_LIST_DATA_FAIL = "GET_PATENT_LIST_DATA_FAIL";

export const START_GET_PUBLIC_STOCK_LIST_DATA = "START_GET_PUBLIC_STOCK_LIST_DATA";
export const GET_PUBLIC_STOCK_LIST_DATA_SUCCESS = "GET_PUBLIC_STOCK_LIST_DATA_SUCCESS";
export const GET_PUBLIC_STOCK_LIST_DATA_FAIL = "GET_PUBLIC_STOCK_LIST_DATA_FAIL";

export const START_GET_TOP_TEN_GOVPLAN_LIST_DATA = "START_GET_TOP_TEN_GOVPLAN_LIST_DATA";
export const GET_TOP_TEN_GOVPLAN_LIST_DATA_SUCCESS = "GET_TOP_TEN_GOVPLAN_LIST_DATA_SUCCESS";
export const GET_TOP_TEN_GOVPLAN_LIST_DATA_FAIL = "GET_TOP_TEN_GOVPLAN_LIST_DATA_FAIL";