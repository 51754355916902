import React from "react";
import styled from "styled-components";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Collapse from "@material-ui/core/Collapse";
import { getForgetPassEmail } from "../../store/actions/auth";

import * as theme from "../../assests/styles/theme";

const ForgetPass = ({isHidden, isModalOpen, directToSignIn, handleCloseModal }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const IS_EMAIL = /^\S+@\S+\.\S+$/;
    const [state, setState] = React.useState({
        email: "",
        confirmedEmail: "",
        remember: false,
        clicked: false
    });

    const handleForgetPass = () => {
        setState(state=>({ ...state, clicked: true }))
        if (state.email !== state.confirmedEmail) {
            return;
        }
        if (!IS_EMAIL.test(state.email)) {
            return;
        }

        dispatch(getForgetPassEmail(state.email));
        handleCloseModal()
    };



    const handleChange = (e) => {
        const { name, value, type } = e.target;

        if(type === "checkbox"){
            setState(state => ({ ...state, [name]: !state[name] }))
        }else{
            setState(state => ({ ...state, [name]: value }))
        }
    }

    const handleDirectToHelper = () => {
        window.open("https://smartpark-guideline.gitbook.io/smartpark_guideline/shi-yong-zhe-guan-li/ping-tai-hui-yuan/wang-ji-mi-ma", "_blank")
    }

    const resetState = () => {
        setState(state=>({
            ...state,
            email: "",
            confirmedEmail: "",
            remember: false,
            clicked: false
        }))
    }

    React.useEffect(()=>{
        if(!isModalOpen) resetState();
    }, [isModalOpen])

    if(isHidden){
        return <div />
    }


    return (
        <ForgetPassWrapper>
            <ForgetPassTitleContainer>
            <ForgetPassTitleInnerContainer>
                <ForgetPassWrapperTitleHeadTextContainer>
                    <ForgetPassWrapperTitleHeadText>忘記密碼</ForgetPassWrapperTitleHeadText>
                    <HelperIcon title="另開新視窗至情資平台忘記密碼操作指南" onClick={handleDirectToHelper}>
                        <Icon>help</Icon>
                    </HelperIcon>
                </ForgetPassWrapperTitleHeadTextContainer>
                <ForgetPassWrapperText onClick={directToSignIn}>
                    登入
                </ForgetPassWrapperText>
            </ForgetPassTitleInnerContainer>
            <ForgetPassTitleInnerContainer>
                <ForgetPassWrapperTitleText>請輸入您的 E-mail，系統將會發送重設密碼連結給您。</ForgetPassWrapperTitleText>
            </ForgetPassTitleInnerContainer>
            </ForgetPassTitleContainer>

            <ForgetPassputContainer>
                <Inputs
                   label="E-mail"
                    variant="outlined"
                    size="small"
                    placeholder="請輸入您註冊時填寫的Email。"
                    value={state.email}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Icon>email</Icon>
                        </InputAdornment>
                      ),
                    }}
                    name="email"
                    onChange={handleChange}
                />
                <Verification isOpen={state.clicked && !IS_EMAIL.test(state.email) && state.email} text="E-mail格式錯誤" />
                <Verification isOpen={state.clicked && !state.email} text="請輸入E-mail" />
                <Inputs
                    value={state.password}
                    label="E-mail確認"
                    placeholder="確認Email。"
                    variant="outlined"
                    size="small"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Icon>email</Icon>
                        </InputAdornment>
                      ),
                    }}
                    name="confirmedEmail"
                    onChange={handleChange}
                />
                <Verification isOpen={state.clicked && state.email !== state.confirmedEmail && state.confirmedEmail} text="E-mail 與 E-mail 確認不符" />
                <Verification isOpen={state.clicked && !state.confirmedEmail} text="請輸入E-mail(帳號)確認" />
            </ForgetPassputContainer>
            <ButtonContainer>
                <Buttons
                    active={state.email && state.confirmedEmail}
                    onClick={(state.email && state.confirmedEmail) && handleForgetPass}
                >
                    送出
                </Buttons>
            </ButtonContainer>
        </ForgetPassWrapper>
    )
};

const ForgetPassWrapper = styled.div`
`
const ForgetPassTitleContainer = styled.div`

`
const Verification = ({isOpen, text}) => {
    return (
        <Collapse in={isOpen}>
            <VerificationTextContainer>
                <VerificationTex>{text}</VerificationTex>
            </VerificationTextContainer>
        </Collapse>
    )
}

const ForgetPassTitleInnerContainer = styled.div`
    width : 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
`

const ForgetPassWrapperTitleHeadTextContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
`

const ForgetPassWrapperTitleHeadText = styled.h2`
    color: ${theme.secondaryBlack};
    font-size: 2.25rem;
    font-family: ${theme.primaryFont} !important;
    font-weight: 600;
    padding-right: 10px;
`

const HelperIcon = styled(IconButton)`
    transition: 0.5s;
    padding: 0px !important;
    &:hover {
        color:var(--main-color);
    }
`
const ForgetPassWrapperTitleText = styled.p`
    color: ${theme.secondaryBlack};
    font-family: ${theme.primaryFont} !important;
    font-size: 1rem;
    padding-top: 15px;
`

const ForgetPassWrapperText = styled.button`
    color: ${theme.secondaryBlack};
    font-family: ${theme.primaryFont} !important;
    cursor: pointer;

    &:hover{
            transition: 0.5s;
            color:var(--main-color);
            transition: 0.5s;
        }
`

const ForgetPassputContainer = styled.div`
    width : 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin: 10px 0px;
`

const Inputs = styled(TextField)`
    margin: 10px 0px !important;
    .MuiFormLabel-root.Mui-focused{
        color: var(--main-color);
    }
    .MuiInputBase-root.Mui-focused{
        .MuiOutlinedInput-notchedOutline{
            border-color: var(--main-color);
        }
    }
`

const Checkboxs = styled(Checkbox)`
    .MuiIconButton-label{
        color:var(--main-color);
    }
`

const Buttons = styled(Button)`
    width: 100%;
    height: 40px !important;
    border-radius: 10px !important;
    background-color: ${p => p.active ? theme.linkBlue : theme.disabledGray} !important;
    font-size: 1rem !important;
    font-weight: 500 !important;
    color: ${theme.white} !important;
    transition: 0.5s;

    &:hover{
        cursor: ${p => p.active ? "pointer" : "not-allowed"};
        transition: 0.5s;
        background-color: ${p => p.active ? "pointer" : "not-allowed"};
    }
`

const ButtonContainer = styled.div`

`

const DirectToForgetPassContainer = styled.div`
    text-align: center;
    padding: 3% 0px 1% 0px;
`

const DirectToForgetPassText = styled(ForgetPassWrapperText)`
    font-size: 1rem;
    transition: 0.5s;
    cursor: pointer;

    &:hover{
        transition: 0.5s;
        color:#757575;
        cursor: pointer;
    }
`
const VerificationTextContainer = styled.div`
    padding: 5px 0px;
`;

const VerificationTex = styled.p`
    font-size: 0.625rem;
    color:red;
`;


export default ForgetPass