import React from "react";
import styled from "styled-components";
import Icon from "@material-ui/core/Icon";

import SignIn from "./SignIn";
import ForgetPass from "./ForgetPass";
import SignUp from "./SignUp";
import { IconButton } from "@material-ui/core";

const AuthController = ({ isSignUpInit, setAuthStatus, isOpen, closeModal }) => {
    const [state, setState] = React.useState({
        status: "",
        isModalOpen: false,
    })

    React.useEffect(()=>{
        setState(state=>({ ...state, status: setAuthStatus }))
    }, [setAuthStatus])

    React.useEffect(()=>{
        if (isSignUpInit) {
            setState(state=>({ ...state, status: "sign-up" }));
          } else {
            setState(state=>({ ...state, status: "sign-in" }));
          }
    }, [isSignUpInit]);

    React.useEffect(() => {
        setState(state=>({ ...state, isModalOpen: isOpen }));
      }, [isOpen]);

    React.useEffect(()=>{
        if(!state.isModalOpen){
            closeModal();
            setState(state=>({ ...state, status: "sign-in" }))
        };
    }, [state.isModalOpen]);


    const handleCloseModal = () => {
        setState(state => ({ ...state,  isModalOpen: false }))
    }
    
    return(
        <React.Fragment>
            <Backdrop isModalOpen={state.isModalOpen} onClick={closeModal}/>
            <div style={{
                display: state.isModalOpen ? "block" : "none",
                top: 0,
                width: "100%",
                height: `${window.innerHeight - 80}px`,
                /* margin: 20px 0px; */
                overflowY: "auto",
                position: "absolute",
            }}>
                <AuthControllerWrapper isModalOpen={state.isModalOpen}>
                    <CloseContainer>
                        <IconButton title="關閉視窗" size="small" onClick={closeModal}>
                            <Icon>close</Icon>
                        </IconButton>
                    </CloseContainer>
                    <SignUp
                        isModalOpen={state.isModalOpen}
                        directToSignIn={()=>{setState(state=>({ ...state, status: "sign-in" }))}} 
                        isHidden={state.status !== "sign-up"}
                        status={state.status}
                        />
                    <SignIn 
                        isModalOpen={state.isModalOpen}
                        directToSignUp={()=>{setState(state=>({ ...state, status: "sign-up" }))}} 
                        directToForgetPass={()=>{setState(state=>({ ...state, status: "forget-pass" }))}} 
                        isHidden={state.status !== "sign-in"}
                        status={state.status}
                    />
                    <ForgetPass  
                        isModalOpen={state.isModalOpen}
                        directToSignIn={()=>{setState(state=>({ ...state, status: "sign-in" }))}} 
                        isHidden={state.status !== "forget-pass"}
                        handleCloseModal={handleCloseModal}
                        />
                </AuthControllerWrapper>
            </div>
        </React.Fragment>
    );
};

const Backdrop = styled.div`
    width: 100vw;
    height: 100vh;
    position: absolute;
    z-index: 1200;
    background-color: rgba(0, 0, 0, 0.7);
    top: 0px;
    display:${p => p.isModalOpen ? "block" : "none"};
    transition: 0.2s display ease-in-out;
`

const AuthControllerWrapper = styled.div`
    position: absolute;
    width: 30%; 
    margin:3% 35% 0px 35%;
    height: auto;
    top:${p => p.isModalOpen ? "0px" : "-300vh"};
    opacity:${p => p.isModalOpen ? 1 : 0};
    z-index: 1201;
    background-color: #fff;
    transition: 0.4s top linear;
    padding: 15px 30px 30px 30px;
    border-radius: 20px;

    @media (max-width: 768px) {
        width: 95%;
        margin:3% 0px 0px 2.5%;
    }
`

const CloseContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;

    .material-icons{
        cursor: pointer;
    }
`

export default AuthController;