export const cityCode = [{"CityId": "North","CityName":"臺北分局"},
            {"CityId": "Center","CityName":"臺中分局"},
            {"CityId": "South","CityName":"臺南分局"},
            {"CityId": "KP","CityName":"高屏分局"},
            // {"CityId": "East","CityName":"東部"},
]

export const cityCodeMap = {
  "All": "全區",
  "North":"臺北分局",
  "Center":"臺中分局",
  "South": "臺南分局",
  "KP":"高屏分局",
  // "East":"東部",
}