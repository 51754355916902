import React from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableBody from "@material-ui/core/TableBody";
import Table from "@material-ui/core/Table"

import "./styles.scss";

export default (props) => {
  return (
    <TableContainer className="table-container">
      <Table>
        <TableBody className="table-body-no-data">
          <TableRow hover className="table-body-row">
            <TableCell align="center" className="no-data">
              本項目無資料
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};
