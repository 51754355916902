import React from "react";

import Chart from "../CustomizedGraphic/CustomizedGraphicV2"

import "./styles.scss";

const CustomizedPublicStock = (props) => {
  const { data, eventHandler } = props;
  const [state, setState] = React.useState({
    totalCompany:0,
    listData: {
      public: 0,
      newPublic: 0,
      prePublic: 0,
      onStock: 0,
      noPublic: 0,
    },
    graphicData: {
      tag: [],
      data: [],
    },
  });

  React.useEffect(() => {
    const { publicStockDataMap } = data;
    const updateListData = {
        public: 0,
        newPublic: 0,
        prePublic: 0,
        onStock: 0,
        noPublic: 0,
    }
    const updateGraphicData = {
        ...state.graphicData,
    }
    let total = 0;
    let PublicTotal = 0;
    let NotPublicTotal = 0;

    publicStockDataMap.map(item=>{
        total += item.y
        switch (item.label) {
            case "上市":
                updateListData.public = item.y;
                PublicTotal += item.y;
              break;
            case "上櫃":
                updateListData.newPublic = item.y;
                PublicTotal += item.y;
              break;
            case "興櫃":
                updateListData.prePublic = item.y;
                PublicTotal += item.y;
              break;
            case "公開發行":
                updateListData.onStock = item.y;
                PublicTotal += item.y;
              break;
            case "未上市":
                updateListData.noPublic = item.y;
                NotPublicTotal += item.y
              break;
            default:
              return "";
          }
    })

    updateGraphicData.tag = ["上市", "未上市"];
    updateGraphicData.data = [PublicTotal, NotPublicTotal];

    setState(state=>({ ...state, listData: updateListData, graphicData: updateGraphicData , totalCompany: total }))

  }, [data]);

  return (
    <div className="customized-public-stock">
      <div className="customized-public-stock-graph">
          <Chart 
                chartType={"pie-chart-with-legend"} 
                data={state.graphicData.data} 
                labels={state.graphicData.tag}
                // eventHandler={val => this.companyAbstractModalHandler('threeCode', val)} 
          />
      </div>
      <div className="customized-public-stock-list">
        <div className="customized-public-stock-list-item">
          <p>
            <strong className="customized-public-stock-list-item-click" onClick={()=> eventHandler(1,"上市")}>上市:</strong> {state.listData.public} <span>({parseInt((state.listData.public/state.totalCompany)*100)}%)</span>
          </p>
        </div>
        <div className="customized-public-stock-list-item">
          <p>
            <strong className="customized-public-stock-list-item-click" onClick={()=> eventHandler(2,"上櫃")}>上櫃:</strong> {state.listData.newPublic} <span>({parseInt((state.listData.newPublic/state.totalCompany)*100)}%)</span>
          </p>
        </div>
        <div className="customized-public-stock-list-item">
          <p>
            <strong className="customized-public-stock-list-item-click" onClick={()=> eventHandler(3, "興櫃")}>興櫃:</strong> {state.listData.prePublic} <span>({parseInt((state.listData.prePublic/state.totalCompany)*100)}%)</span>
          </p>
        </div>
        <div className="customized-public-stock-list-item">
          <p>
            <strong className="customized-public-stock-list-item-click" onClick={()=> eventHandler(4, "公開發行")}>公開發行:</strong> {state.listData.onStock} <span>({parseInt((state.listData.onStock/state.totalCompany)*100)}%)</span>
          </p>
        </div>
        <div className="customized-public-stock-list-item">
          <p>
            <strong className="customized-public-stock-list-item-click" onClick={()=> eventHandler(0, "未上市")}>未上市:</strong> {state.listData.noPublic} <span>({parseInt((state.listData.noPublic/state.totalCompany)*100)}%)</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default React.memo(CustomizedPublicStock)
