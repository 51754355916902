import axios from "axios";
import { baseUrl, loginbaseUrl } from "../constants/endpoints";
import { encryptAES, decryptAES } from "./common";

const headers = {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
    "Access-Control-Allow-Headers":
      "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers",
    "Content-Type": "application/json",
    Authorization: localStorage.getItem("acToken"),
  };

  export const handleGoogleSignInToServer = (account, name) => {
    const data = new FormData(); 
    data.append("Account", account);
    data.append("Name", name);
    data.append("OAuthSource", "google");
    const encrypt = encryptAES(`Aj9dxd${account}`);
    data.append("Encrypt", encrypt);
    return axios({
      proxy: true,
      method: "post",
      url: `${loginbaseUrl}/api/auth/OAuthSignUpAndSignIn/`,
      data: data,
    })
      .then((res) => {
        try {
          return res.data;
        } catch (e) {
          return [];
        }
      })
      .catch((err) => {
        return "error";
      });
};  
  
  export const handleGoogleSignInCheckRegistered = (account) => {
    const data = new FormData(); 
    data.append("Account", account);
    data.append("OAuthSource", "google");

    return axios({
      proxy: true,
      method: "post",
      url: `${loginbaseUrl}/api/auth/OAuthCheckRegistered/`,
      data: data,
    })
      .then((res) => {
        try {
          return res.data;
        } catch (e) {
          return [];
        }
      })
      .catch((err) => {
        return "error";
      });
};
  

export const handleGetIsRestricted = () => {
  return axios({
    method: "get",
    url: `https://mirdc-system-default-rtdb.firebaseio.com/restricted_list.json`,
  })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
}