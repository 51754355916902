import React from "react";

import Footer from "../../components/Footer/Footer";

import * as Style from "./styles";

const FollowCompany = () => {
  const uid = localStorage.getItem("uid");

  return (
    <Style.NotificationContainer>
      <Style.NotificationHeaderContainer>
        <Style.NotificationHeader>資料上傳</Style.NotificationHeader>
        <Style.NotificationSubHeader>
          上傳產業園區興辦工業人建廠使用統計表
        </Style.NotificationSubHeader>
      </Style.NotificationHeaderContainer>
      <Style.NotificationContentContainer>
        <Style.NotificationIframe
          url={`https://indupark.smartpark.com.tw/InduParkUpload/?user_id=${btoa(
            uid
          )}`}
        />
        {/* <Footer /> */}
      </Style.NotificationContentContainer>
    </Style.NotificationContainer>
  );
};

export default FollowCompany;
