import React, { useState } from "react";
import styled from "styled-components";
import Collapse from "@material-ui/core/Collapse";
import Icon from "@material-ui/core/Icon";
import { VariableSizeList as List } from 'react-window';

import TabPanel from "./TabPanel";
import Spinner from "../Spinner/Spinner";

import "./styles.scss";


export default (props) => {
  const rowHeights = React.useRef({});
  const listRef = React.useRef({});

  const { totalAmt, loading, clicked, data, onSelectedCompany, forceOpenFilterGroup, closePopUpHandler } = props;
  const [state, setState] = useState({
    isFilterGroupOpen: false,
    keyArrow: "keyboard_arrow_down",
    value: "",
    index: "",
    content: "",
  });

  React.useEffect(()=>{
    if(forceOpenFilterGroup){
      setState(state=>({ ...state, isFilterGroupOpen: true, keyArrow: "keyboard_arrow_up", }));
    }
  },[forceOpenFilterGroup]);

  const setRowHeight = (index, size) => {
    listRef.current.resetAfterIndex(0);
    rowHeights.current = { ...rowHeights.current, [index]: size };
  };

  const getRowHeight = (index) => {
    return rowHeights.current[index];
  }

  const toggleResultListHandler = () => {
    setState((state) => ({
      ...state,
      isFilterGroupOpen: !state.isFilterGroupOpen,
      keyArrow:
        state.keyArrow === "keyboard_arrow_up"
          ? "keyboard_arrow_down"
          : "keyboard_arrow_up",
    }));
  };

  const flyToHandler = (uid, position) => {
    closePopUpHandler(uid)
    onSelectedCompany(position)
  };

  const Rows = ({index, style}) => {
    const rowRef = React.useRef({});
    const [state, setState] = React.useState({
      isOpen: false,
    })

    React.useEffect(() => {
      const { isOpen } = state;
      if (rowRef.current) {
        setRowHeight(index, isOpen ? 365 : 180);
      }
    }, [rowRef, state.isOpen]);

    return(
      <TabPanel 
      ref={rowRef}
      style={style}
      isFlyTo 
      key={data[index].Uid} 
      IsToxicPoll={data[index].IsToxicPoll} 
      IsWastePoll={data[index].IsWastePoll} 
      CtrlId={data[index].CtrlId} 
      uid={data[index].Uid} 
      busineesNo={data[index].BusineesNo} 
      name={data[index].FactoryName} 
      address={data[index].FactoryAddr.toString()} 
      parkId={data[index].ParkId} 
      MonitoringType={data[index].MonitoringType} 
      onClick={()=>flyToHandler(data[index].Uid ,[data[index].Latitude, data[index].Longitude])}
      passIsContentOpenHandler={(isOpen)=>{setState(state=>({ ...state, isOpen: isOpen }))}}
      />
    )
  }

  if(clicked){
      return (
        <div className="material-result-list" style={loading ? {minHeight: "300px"}: null}>
          <div className="material-result-list-header">
          <div className="material-result-list-header-left"><h4>結果列表</h4>
            {data.length !== 0 && 
            <InfoContainer>
                <p>總共 {data.length} 筆資料</p>
                {totalAmt > 0 && <p>總共 {totalAmt.toFixed(2)} 公噸</p>}
            </InfoContainer>
            }
          </div>
            <Icon onClick={toggleResultListHandler} className="icon">
              {state.keyArrow}
            </Icon>
          </div>
          {loading ? <Spinner /> : 
          <Collapse in={state.isFilterGroupOpen}>
            <div className={props.searchOpen? "result-group search-open" : "result-group"} style={data.length !== 0 ? { paddingBottom : "100px" } : {}}>
              {data.length === 0 && <div className="no-result"><p>無搜尋結果</p></div> }
              {/* {data.length > 0 &&
              <List
                className="List"
                height={2000}
                itemCount={data.length}
                itemSize={getRowHeight}
                width={350}
                ref={listRef}
              >
                {Rows}
                </List>
              } */}
              {data.length > 0 &&
                  data.map((i, index) =>{
                    // const rowRef = React.useRef({});
                    // const [state, setState] = React.useState({
                    //   isOpen: false,
                    // })
                
                    // React.useEffect(() => {
                    //   const { isOpen } = state;
                    //   if (rowRef.current) {
                    //     setRowHeight(index, isOpen ? 365 : 180);
                    //   }
                    // }, [rowRef, state.isOpen]);
                
                    return(
                      <TabPanel 
                      // ref={rowRef}
                      isFlyTo 
                      key={i.Uid} 
                      IsToxicPoll={i.IsToxicPoll} 
                      IsWastePoll={i.IsWastePoll} 
                      CtrlId={i.CtrlId} 
                      uid={i.Uid} 
                      busineesNo={i.BusineesNo} 
                      name={i.FactoryName} 
                      address={i.FactoryAddr.toString()} 
                      parkId={i.ParkId} 
                      MonitoringType={i.MonitoringType} 
                      onClick={()=>flyToHandler(i.Uid ,[data[index].Latitude, data[index].Longitude])}
                      passIsContentOpenHandler={(isOpen)=>{setState(state=>({ ...state, isOpen: isOpen }))}}
                      />
                    )

                  })
              }
            </div>
          </Collapse>
          }
        </div>
      );
  }else{
    return <></>
  }

};

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px 0px;
  >p{
    margin-left: 10px;
    font-size: 0.75rem;
    color: #777777;
    padding: 0px;
  }
`