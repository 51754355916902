import * as actionTypes from "../actionTypes/custom_industry_dashboard";

const initState = {
  createDashboard: [],
  createDashboard_status: "",
  createDashboard_loading: false,
  getDashboardList: [],
  getDashboardList_loading: false,
  getDashboardCond: [],
  getDashboardCond_loading: false,
  updateUserDashboardName: [],
  updateUserDashboardName_loading: false,
  uploadDashboardCond: [],
  uploadDashboardCond_status: "",
  uploadDashboardCond_loading: false,
  copyDashboard: [],
  copyDashboard_loading: false,
  deleteDashboard: [],
  deleteDashboard_loading: false,
  getALLFactoryIndustry: [],
  getALLFactoryIndustry_loading: false,
  getCountryList: [],
  getCountryList_loading: false,
  getImportCountryTradeProduct: [],
  getImportCountryTradeProduct_loading: false,
  getExportCountryTradeProduct: [],
  getExportCountryTradeProduct_loading: false,
  err: "",
};

//新增儀表板
const startCreateDashboard = (state) => {
  return {
    ...state,
    createDashboard_loading: true,
  };
};

const createDashboardSuccess = (state, action) => {
  return {
    ...state,
    createDashboard: action.data,
    createDashboard_loading: false,
  };
};

const createDashboardFail = (state, action) => {
  return {
    ...state,
    createDashboard_status: action.err,
    createDashboard_loading: false,
  };
};

//取得儀表板清單
const startGetDashboardList = (state) => {
  return {
    ...state,
    getDashboardList_loading: true,
  };
};

const getDashboardListSuccess = (state, action) => {
  return {
    ...state,
    getDashboardList: action.data,
    getDashboardList_loading: false,
  };
};

const getDashboardListFail = (state) => {
  return {
    ...state,
    getDashboardList_loading: false,
  };
};

//取得儀表板當前條件
const startGetDashboardCond = (state) => {
  return {
    ...state,
    getDashboardCond_loading: true,
  };
};

const getDashboardCondSuccess = (state, action) => {
  return {
    ...state,
    getDashboardCond: action.data,
    getDashboardCond_loading: false,
  };
};

const GetDashboardCondFail = (state) => {
  return {
    ...state,
    getDashboardCond_loading: false,
  };
};

//更新儀表板名稱
const startUpdateUserDashboardName = (state) => {
  return {
    ...state,
    updateUserDashboardName_loading: true,
  };
};

const updateUserDashboardNameSuccess = (state, action) => {
  return {
    ...state,
    updateUserDashboardName: action.data,
    updateUserDashboardName_loading: false,
  };
};

const UpdateUserDashboardNameFail = (state) => {
  return {
    ...state,
    updateUserDashboardName_loading: false,
  };
};

//更新儀表板條件
const startUploadDashboardCond = (state) => {
  return {
    ...state,
    uploadDashboardCond_loading: true,
  };
};

const UploadDashboardCondSuccess = (state, action) => {
  return {
    ...state,
    uploadDashboardCond: action.data,
    uploadDashboardCond_loading: false,
  };
};

const UploadDashboardCondFail = (state, action) => {
  return {
    ...state,
    uploadDashboardCond_status: action.err,
    uploadDashboardCond_loading: false,
  };
};

//複製儀表板
const startCopyDashboard = (state) => {
  return {
    ...state,
    copyDashboard_loading: true,
  };
};

const copyDashboardSuccess = (state, action) => {
  return {
    ...state,
    copyDashboard: action.data,
    copyDashboard_loading: false,
  };
};

const copyDashboardFail = (state) => {
  return {
    ...state,
    copyDashboard_loading: false,
  };
};

//刪除儀表板
const startDeleteDashboard = (state) => {
  return {
    ...state,
    deleteDashboard_loading: true,
  };
};

const deleteDashboardSuccess = (state, action) => {
  return {
    ...state,
    deleteDashboard: action.data,
    deleteDashboard_loading: false,
  };
};

const deleteDashboardFail = (state) => {
  return {
    ...state,
    deleteDashboard_loading: false,
  };
};

//取得工廠四碼
const startGetALLFactoryIndustry = (state) => {
  return {
    ...state,
    getALLFactoryIndustry_loading: true,
  };
};

const getALLFactoryIndustrySuccess = (state, action) => {
  return {
    ...state,
    getALLFactoryIndustry: action.data,
    getALLFactoryIndustry_loading: false,
  };
};

const GetALLFactoryIndustryFail = (state) => {
  return {
    ...state,
    getALLFactoryIndustry_loading: false,
  };
};

//國別清單
const startGetCountryList = (state) => {
  return {
    ...state,
    getCountryList_loading: true,
  };
};

const getCountryListSuccess = (state, action) => {
  return {
    ...state,
    getCountryList: action.data,
    getCountryList_loading: false,
  };
};

const getCountryListFail = (state) => {
  return {
    ...state,
    getCountryList_loading: false,
  };
};

//進口清單排名 (參數：國別、年分)
const startGetImportCountryTradeProduct = (state) => {
  return {
    ...state,
    getImportCountryTradeProduct_loading: true,
  };
};

const getImportCountryTradeProductSuccess = (state, action) => {
  return {
    ...state,
    getImportCountryTradeProduct: action.data,
    getImportCountryTradeProduct_loading: false,
  };
};

const getImportCountryTradeProductFail = (state) => {
  return {
    ...state,
    getImportCountryTradeProduct_loading: false,
  };
};

//出口清單排名 (參數：國別、年分)
const startGetExportCountryTradeProduct = (state) => {
  return {
    ...state,
    getExportCountryTradeProduct_loading: true,
  };
};

const getExportCountryTradeProductSuccess = (state, action) => {
  return {
    ...state,
    getExportCountryTradeProduct: action.data,
    getExportCountryTradeProduct_loading: false,
  };
};

const getExportCountryTradeProductFail = (state) => {
  return {
    ...state,
    getExportCountryTradeProduct_loading: false,
  };
};

export default (state = initState, action) => {
  switch (action.type) {
    //新增儀表板
    case actionTypes.START_CREATE_DASHBOARD:
      return startCreateDashboard(state);
    case actionTypes.CREATE_DASHBOARD_SUCCESS:
      return createDashboardSuccess(state, action);
    case actionTypes.CREATE_DASHBOARD_FAIL:
      return createDashboardFail(state, action);

    //取得儀表板清單
    case actionTypes.START_GET_DASHBOARD_LIST:
      return startGetDashboardList(state);
    case actionTypes.GET_DASHBOARD_LIST_SUCCESS:
      return getDashboardListSuccess(state, action);
    case actionTypes.GET_DASHBOARD_LIST_FAIL:
      return getDashboardListFail(state, action);

    //取得儀表板當前條件
    case actionTypes.START_GET_DASHBOARD_COND:
      return startGetDashboardCond(state);
    case actionTypes.GET_DASHBOARD_COND_SUCCESS:
      return getDashboardCondSuccess(state, action);
    case actionTypes.GET_DASHBOARD_COND_FAIL:
      return GetDashboardCondFail(state, action);

    //更新儀表板名稱
    case actionTypes.START_UPDATE_USER_DASHBOARD_NAME:
      return startUpdateUserDashboardName(state);
    case actionTypes.UPDATE_USER_DASHBOARD_NAME_SUCCESS:
      return updateUserDashboardNameSuccess(state, action);
    case actionTypes.UPDATE_USER_DASHBOARD_NAME_FAIL:
      return UpdateUserDashboardNameFail(state, action);

    //更新儀表板條件
    case actionTypes.START_UPLOAD_DASHBOARD_COND:
      return startUploadDashboardCond(state);
    case actionTypes.UPLOAD_DASHBOARD_COND_SUCCESS:
      return UploadDashboardCondSuccess(state, action);
    case actionTypes.UPLOAD_DASHBOARD_COND_FAIL:
      return UploadDashboardCondFail(state, action);

    //複製儀表板
    case actionTypes.START_COPY_DASHBOARD:
      return startCopyDashboard(state);
    case actionTypes.COPY_DASHBOARD_SUCCESS:
      return copyDashboardSuccess(state, action);
    case actionTypes.COPY_DASHBOARD_FAIL:
      return copyDashboardFail(state, action);

    //刪除儀表板
    case actionTypes.START_DELETE_DASHBOARD:
      return startDeleteDashboard(state);
    case actionTypes.DELETE_DASHBOARD_SUCCESS:
      return deleteDashboardSuccess(state, action);
    case actionTypes.DELETE_DASHBOARD_FAIL:
      return deleteDashboardFail(state, action);

    //取得工廠四碼
    case actionTypes.START_GET_ALL_FACTORY_INDUSTRY:
      return startGetALLFactoryIndustry(state);
    case actionTypes.GET_ALL_FACTORY_INDUSTRY_SUCCESS:
      return getALLFactoryIndustrySuccess(state, action);
    case actionTypes.GET_ALL_FACTORY_INDUSTRY_FAIL:
      return GetALLFactoryIndustryFail(state, action);

    //國別清單
    case actionTypes.START_GET_COUNTRY_LIST:
      return startGetCountryList(state);
    case actionTypes.GET_COUNTRY_LIST_SUCCESS:
      return getCountryListSuccess(state, action);
    case actionTypes.GET_COUNTRY_LIST_FAIL:
      return getCountryListFail(state, action);

    //進口清單排名 (參數：國別、年分)
    case actionTypes.START_GET_IMPORT_COUNTRY_TRADE_PRODUCT:
      return startGetImportCountryTradeProduct(state);
    case actionTypes.GET_IMPORT_COUNTRY_TRADE_PRODUCT_SUCCESS:
      return getImportCountryTradeProductSuccess(state, action);
    case actionTypes.GET_IMPORT_COUNTRY_TRADE_PRODUCT_FAIL:
      return getImportCountryTradeProductFail(state, action);

    //出口清單排名 (參數：國別、年分)
    case actionTypes.START_GET_EXPORT_COUNTRY_TRADE_PRODUCT:
      return startGetExportCountryTradeProduct(state);
    case actionTypes.GET_EXPORT_COUNTRY_TRADE_PRODUCT_SUCCESS:
      return getExportCountryTradeProductSuccess(state, action);
    case actionTypes.GET_EXPORT_COUNTRY_TRADE_PRODUCT_FAIL:
      return getExportCountryTradeProductFail(state, action);
    default:
      return state;
  }
};