import React from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import debounce from "lodash.debounce";
import IconButton from "@mui/material/IconButton";
import Close from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import Checkbox from "@mui/material/Checkbox";
import { useSnackbar } from "notistack";
import Icon from "@material-ui/core/Icon";
import ReactExport from "react-data-export";

import { message } from "../utility/common";
import {
  handleFetchCustomDashboardList,
  handleUpdateCustomDashboardBusinessNumberList,
  handleFetchCustomDashboardBusinessNumberList,
  handleClearCustomDashboardBusinessNumberListState
} from "../store/actions/custom_dashboard";
import Spinner from "./Spinner/Spinner";

import { getFacNo } from "../utility/api";

import * as theme from "../assets/theme";

const CustomUpdateDashboardListModal = ({ id, should_api_call, handleClose }) => {

  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const {
    custom_dashboard_list,
    custom_dashboard_business_number_list,
    custom_dashboard_business_number_list_loading,
  } = useSelector((state) => state.custom_dashboard);
  const [state, setState] = React.useState({
    add_business_number_list: "",
    add_business_number_list_area: [],
    search_keyword: [],
    search_keyword_loading: false,
    company_name: "",
    is_company_name_edit: false,
    is_force_button_click: false,
    is_fetched_res: false,
    is_order_by_ts : true
  });

  const handleToggleORderByTime = () => setState(state =>({ ...state, is_order_by_ts: !state.is_order_by_ts }))

  const handle_is_company_name_edit = () => {
    setState((state) => ({
      ...state,
      is_company_name_edit: !state.is_company_name_edit,
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((state) => ({
      ...state,
      [name]: value,
      is_force_button_click: true,
    }));
  };

  const handleAddBusinessNumberList = () => {
    const business_no_list = [];
    state.add_business_number_list_area.forEach((i) =>
      business_no_list.push(i.business_no)
    );

    dispatch(
      handleUpdateCustomDashboardBusinessNumberList(
        id,
        business_no_list,
        state.company_name
      )
    );

    setState(state=>({ ...state, is_company_name_edit: false }))

    setTimeout(()=>{
      if(!custom_dashboard_business_number_list_loading){
        message(enqueueSnackbar, "更新自訂關注廠商儀表板成功!", "success");
      }
    }, 1000)
  };

  const handleUpdateBusinessNumberList = (val, business_no) => {
    if(state.add_business_number_list_area.filter(i => i.business_no === business_no).length > 0){
      setState((state) => ({
        ...state,
        search_keyword: [],
        is_fetched_res: false,
        is_show_duplicated: true,
        add_business_number_list: ""
      }));
    }else{
      setState((state) => ({
        ...state,
        add_business_number_list: val,
        search_keyword: [],
        is_fetched_res: false,
        is_show_duplicated: false
      }));
    }
  };

  const handleFetchCompanyByKeyword = (value) => {
    if(value){
      setState((state) => ({ ...state, search_keyword_loading: true }));
      getFacNo(value).then((res) => {
        setState((state) => ({
          ...state,
          search_keyword_loading: false,
          search_keyword: res,
          is_fetched_res: true
        }));
      });
    }else{
      setState((state) => ({
        ...state,
        is_fetched_res: false
      }));
    }
  };

  const debouncedChangeHandler = React.useCallback(
    debounce((val) => handleFetchCompanyByKeyword(val), 1000),
    []
  );

  const handleUpdateVal = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
    debouncedChangeHandler(value);
  };

  const handle_move_number_to_ready_area = () => {
    const { add_business_number_list, add_business_number_list_area } = state;
    const business_no = add_business_number_list.split(",")[0];
    const name = add_business_number_list.split(",")[1];

    const update_add_business_number_list_area = [
      ...add_business_number_list_area,
    ];

    update_add_business_number_list_area.unshift({
      business_no: business_no,
      name: name,
      id: "",
      ts: 9999999999
    });

    setState((state) => ({
      ...state,
      add_business_number_list_area: update_add_business_number_list_area,
      add_business_number_list: "",
      is_force_button_click: true,
    }));
  };

  const handle_pop_item = (id, name) => {
    const update_add_business_number_list_area = [];

    if (id) {
      state.add_business_number_list_area.forEach((i) => {
        if (i.id !== id) {
          update_add_business_number_list_area.push({
            business_no: i.business_no,
            name: i.name,
            id: i.id,
          });
        }
      });

      setState((state) => ({
        ...state,
        add_business_number_list_area: update_add_business_number_list_area,
        is_force_button_click: true

      }));
    } else {
      state.add_business_number_list_area.forEach((i) => {
        if (i.name !== name) {
          update_add_business_number_list_area.push({
            business_no: i.business_no,
            name: i.name,
            id: i.id,
          });
        }
      });

      setState((state) => ({
        ...state,
        add_business_number_list_area: update_add_business_number_list_area,
        is_force_button_click: true
      }));
    }
  };

  const handle_close_modal = () => {
    setState(state=>({ 
      ...state,
      add_business_number_list: "",
      add_business_number_list_area: [],
      search_keyword: [],
      search_keyword_loading: false,
      company_name: "",
      is_company_name_edit: false,
      is_force_button_click: false,
    }))
    dispatch(handleClearCustomDashboardBusinessNumberListState())
    dispatch(handleFetchCustomDashboardList())
    handleClose()
  };

  React.useEffect(() => {
    if (id && should_api_call) {
      setState((state) => ({
        ...state,
        company_name: custom_dashboard_list.filter((i) => i.BoardID === id)[0]
          .BoardName,
      }));
      dispatch(handleFetchCustomDashboardBusinessNumberList(id));
    }
  }, [id, should_api_call]);


  React.useEffect(() => {
    if (custom_dashboard_business_number_list.length > 0) {
      const update_add_business_number_list_area = [
        ...state.add_business_number_list_area,
      ];

      custom_dashboard_business_number_list.forEach((i) =>
        update_add_business_number_list_area.push({
          business_no: i.BusinessNo,
          name: i.CompanyName,
          ts: new Date(i.InsertTime).getTime() / 1000,
          id: i.id,
        })
      );

      setState((state) => ({
        ...state,
        add_business_number_list_area: update_add_business_number_list_area,
        add_business_number_list: "",
      }));
    }
  }, [custom_dashboard_business_number_list]);

  let area_content;
  if (custom_dashboard_business_number_list_loading) {
    area_content = (
      <ReadyToAddAreaContainer no_data>
        <Spinner />
      </ReadyToAddAreaContainer>
    );
  } else {
    if (state.add_business_number_list_area.length === 0) {
      area_content = (
        <ReadyToAddAreaContainer no_data>
          <ReadyToAddAreaItemNoDataText>
            目前無欲加入之公司，您可以點選右上角 + 來新增公司。
          </ReadyToAddAreaItemNoDataText>
        </ReadyToAddAreaContainer>
      );
    } else {
      area_content = (
        <ReadyToAddAreaContainer>
          {state.add_business_number_list_area.sort((a,b) => {
            if(state.is_order_by_ts){
              return +b.ts - +a.ts
            }else{
              return +a.business_no - +b.business_no
            }
          }).map((i, index) => (
            <ReadyToAddAreaItem
              key={i.id ? i.id : index + i.name + i.business_no}
              is_odd={index % 2 === 0}
            >
              <TextContainer>
                <ReadyToAddAreaItemText>
                  <ReadyToAddAreaItemLink to={`factory_info/basic_info/${i.business_no}`} target="_blank" color="inherit">
                    {i.business_no} {i.name && `(${i.name.trim()})`}
                  </ReadyToAddAreaItemLink>
                </ReadyToAddAreaItemText>
              </TextContainer>
              <IconContainer>
                <StyledCloseIcon
                  onClick={() => handle_pop_item(i.id, i.name)}
                />
              </IconContainer>
            </ReadyToAddAreaItem>
          ))}
        </ReadyToAddAreaContainer>
      );
    }
  }


  let head_text;
  if (state.is_company_name_edit) {
    head_text = (
      <TextField
        variant="standard"
        onChange={handleChange}
        value={state.company_name}
        name={"company_name"}
        sx={{fontSize:"1.25rem"}}
      />
    ); 
  } else {
    head_text = (
      <CustomDashboardListDetailModalHeadText>
        {state.company_name}
      </CustomDashboardListDetailModalHeadText>
    );
  }

  return (
    <CustomDashboardListDetailModalWrapper>
      <CustomDashboardListDetailModalCloseContainer>
        <IconButton onClick={handle_close_modal}>
          <Close />
        </IconButton>
      </CustomDashboardListDetailModalCloseContainer>
      <CustomDashboardListDetailModalHeadContainer>
        {head_text}
        <IconButton
          sx={{ transform: "translateY(8px)" }}
          onClick={handle_is_company_name_edit}
        >
          <EditIcon sx={{ width: "20px", height: "20px" }} />
        </IconButton>
        <CustomDashboardNumberText>目前已加入廠商數量：{state.add_business_number_list_area.length}</CustomDashboardNumberText>
      </CustomDashboardListDetailModalHeadContainer>
      <CustomDashboardListDetailModalSubHeadContainer>
        <CustomDashboardListDetailModalHeadSubText>
          您可以點選在儀表板名稱旁的編輯按鈕來編輯儀表板名稱，透過 +
          新增欲增加的公司，或是 x 來刪除公司。
        </CustomDashboardListDetailModalHeadSubText>
      </CustomDashboardListDetailModalSubHeadContainer>
      <CustomDashboardListDetailModalContent>
        <CustomDashboardListDetailModalInputContainer>
          <TextField
            label="新增公司"
            variant="outlined"
            size="small"
            value={state.add_business_number_list}
            name={"add_business_number_list"}
            onChange={handleUpdateVal}
            sx={{ width: "80%" }}
            helperText={state.is_show_duplicated && "您選擇的公司已經在列表。"}
          />
          <IconButton
            disabled={!state.add_business_number_list || state.add_business_number_list.length < 12}
            onClick={handle_move_number_to_ready_area}
          >
            <AddIcon />
          </IconButton>
          <OptionContainer is_with_data={state.search_keyword_loading}>
            {state.search_keyword_loading && <Spinner />}
            {!state.search_keyword_loading &&
              state.search_keyword.length > 0 &&
              state.search_keyword.map((i, index) => (
                <OptionItem is_odd={index % 2 === 0} key={i.BusinessNo + index}>
                  <OptionItemText
                    onClick={() =>
                      handleUpdateBusinessNumberList(`${i.BusinessNo}, ${i.Name}`, i.BusinessNo)
                    }
                  >
                    {i.BusinessNo}, {i.Name}
                  </OptionItemText>
                </OptionItem>
              ))}
            {state.is_fetched_res && 
            !state.search_keyword_loading &&
              state.search_keyword.length === 0 &&
              state.add_business_number_list && (
                <OptionItem is_odd={false}>查無資料</OptionItem>
              )}
          </OptionContainer>

        </CustomDashboardListDetailModalInputContainer>
        <OrderByTimeContainer>
          <Checkbox 
            checked={state.is_order_by_ts}
            onClick={handleToggleORderByTime}
          /><p>依最新加入時間排序</p>
        </OrderByTimeContainer>
      </CustomDashboardListDetailModalContent>
      {area_content}
      <AddButtonContainer>
        <StyledButton
          disabled={
            state.add_business_number_list_area.filter((i) => !i.id).length ===
              0 && !state.is_force_button_click
          }
          onClick={handleAddBusinessNumberList}
        >
          更新自訂關注廠商儀表板
        </StyledButton>
        <ExcelFile
          element={
            <Tooltip title={`匯出在 ${state.company_name} 儀表板中的公司資料`}>
              <Button
                variant="outlined"
                color="inherit"
                size="small"
                startIcon={<Icon>download</Icon>}
              >
                匯出 Excel
              </Button>
            </Tooltip>
          }
          filename={`自訂關注儀表板_${state.company_name}_公司資料`}
        >
          <ExcelSheet
            data={custom_dashboard_business_number_list}
            name="自訂關注儀表板公司資料"
          >
            <ExcelColumn label="統一編號" value="BusinessNo" />
            <ExcelColumn label="公司名稱" value="CompanyName" />
            <ExcelColumn label="資本額" value="CapitalTotal" />
          </ExcelSheet>
        </ExcelFile>
      </AddButtonContainer>
    </CustomDashboardListDetailModalWrapper>
  );
};

const CustomDashboardListDetailModalWrapper = styled.div`
  width: 45%;
  height: 77vh;
  background-color: ${theme.white};
  border-radius: 20px;
  padding: 20px 30px;

  @media (max-width: 750px) {
    width: 100%;
    padding: 20px 20px;
  }
`;

const CustomDashboardListDetailModalCloseContainer = styled.div`
  width: calc(100%-60px);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  z-index: 10;
`;

const CustomDashboardListDetailModalHeadContainer = styled.div`
  width: calc(100%-60px);
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  flex-wrap: wrap;
  transform: translateY(-20px);
`;

const CustomDashboardNumberText = styled.p`
  padding-left: 15px;
  @media (max-width: 768px) {
    padding-left: 0px;
    margin-top: 4px;
  }
`;

const CustomDashboardListDetailModalHeadText = styled.h2`
  font-size: 2rem;
  font-weight: 600;
  letter-spacing: 2px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const CustomDashboardListDetailModalSubHeadContainer = styled.div`
  width: calc(100%-60px);
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const CustomDashboardListDetailModalHeadSubText = styled.h2`
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 2px;
  transform: translateY(-10px);
  line-height: 20px;
`;

const CustomDashboardListDetailModalContent = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 10px 0px;
  position: relative;
  @media (max-width: 768px) {
    padding: 4px 0px;
  }
`
const CustomDashboardListDetailModalInputContainer = styled.div`
  width: calc(100% - 186px);
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 10px 0px;
  position: relative;

  @media (max-width: 768px) {
    width: 100%;
    padding: 0px 0px 0px 0px;
  }

  .MuiFormControl-root{
    .MuiFormHelperText-root{
      margin: 4px 0px 0px 0px !important;
      color: red !important;
    }
  }

`;

const OptionContainer = styled.div`
  position: absolute;
  /* max-height: ${(p) => (p.is_with_data ? "200px" : "0px")}; */
  max-height: 200px;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: ${theme.white};
  width: 400px;
  top: 50px;
  box-shadow: 0px 0px 20px -5px rgba(0, 0, 0, 0.2);
  transition: 0.1s max-height ease-in;
  z-index: 10;
  @media (max-width: 750px) {
    width: 100%;
    max-height: 150px;
  }
`;

const OptionItem = styled.div`
  height: 50px;
  background-color: ${(p) => (p.is_odd ? theme.grayBorderColor : theme.white)};
  width: 400px;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0px 20px;
  transition: 0.3s;
  @media (max-width: 750px) {
    width: 100%;
  }

  &:hover {
    opacity: 0.9;
    transition: 0.3s;
  }
`;

const OptionItemText = styled.p`
  font-size: 1rem;
  letter-spacing: 2px;
  @media (max-width: 750px) {
    font-size: 0.875rem;
    line-height: 1.2;
  }
`;

const StyledButton = styled(Button)`
  background-color: ${(p) =>
    p.disabled ? theme.grayBorderColor : theme.linkBlue} !important;
  color: ${theme.white} !important;
  margin-bottom: 8px !important;

  &:hover {
    background-color: ${(p) =>
      p.disabled ? theme.grayBorderColor : theme.linkBlue} !important;
  }
`;

const ReadyToAddAreaContainer = styled.div`
  width: 100%;
  height: calc(100% - 300px);
  border: 0.5px solid ${theme.disabledGray};
  display: flex;
  flex-direction: column;
  justify-content: ${(p) => (p.no_data ? "center" : "flex-start")};
  align-items: ${(p) => (p.no_data ? "center" : "flex-start")};
  padding-bottom: 20px;
  overflow-y: scroll;
  @media (max-width: 768px) {
    height: calc(100% - 300px - 42px);
  }
`;

const ReadyToAddAreaItem = styled.div`
  width: 100%;
  padding: 10px;
  background-color: ${(p) => (p.is_odd ? theme.lightGrayBorderColor : theme.white)};
  transition: 0.2s;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:hover {
    transition: 0.2s;
    opacity: 0.6;
  }
`;

const ReadyToAddAreaItemText = styled.p`
  font-size: 1rem;
  font-weight: 500;
  text-decoration: underline;
  cursor: pointer;
  @media (max-width: 750px) {
    font-size: 0.875rem;
    line-height: 1.2;
  }
`;
const ReadyToAddAreaItemLink = styled(Link)`
  color: black;
  &:hover {
    color: black;
  }
`;

const TextContainer = styled.div``;

const IconContainer = styled.div``;

const StyledCloseIcon = styled(Close)`
  width: 20px !important;
  height: 20px !important;
`;

const ReadyToAddAreaItemNoDataText = styled.p`
  font-size: 1rem;
  font-weight: 400;
  line-height: 25px;
  color: ${theme.grayBorderColor};
`;

const AddButtonContainer = styled.div`
  width: 100%;
  padding: 20px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
`;


const OrderByTimeContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;


export default CustomUpdateDashboardListModal;
