import React from "react";
import styled from "styled-components";
import "./styles.scss";
import * as Style from "./styles";
import Modals from "../../components/CustomizedModal/CustomizedModal";
import SignaturePad from "./SignaturePad";
import QRcodeModal from "./QRcodeModal";
import { useHistory } from "react-router-dom";
import { directToNewTabHandler } from "../../utility/common";

export default (props) => {
  const {
    title,
    src,
    prevSlide,
    nextSlide,
    children,
    goToStepThree,
    openPreview,
    isFinished,
    isIndexZero,
    isIndexOne,
    isIndexTwo,
    isIndexFour,
    result,
    stepFiveChecked,
    UploadQuestionData,
    isCompanyExist,
    companyNumber,
    isECO
  } = props;

  const [state, setState] = React.useState({
    isSignaturePadOpen: false,
    isQRcodeOpen: false,
    isTempStorage: false
  });

  let history = useHistory();

  const handleSignaturePad = (tempStorage) => {
    setState((state) => ({
      ...state, isSignaturePadOpen: !state.isSignaturePadOpen,
      isTempStorage: tempStorage || false
    }));
  }

  const handleOpenQRcode = () => {
    setState((state) => ({ ...state, isQRcodeOpen: true }));
  };

  const handleCloseQRcode = () => {
    setState((state) => ({ ...state, isQRcodeOpen: false }));
    history.push("/questionnaire-management");
  };

  return (
    <div className="question-container-card">
      <div className="question-container-card-left">
        <Style.QuestionContainer>
          <Style.QuestionContainerText>{title}</Style.QuestionContainerText>
        </Style.QuestionContainer>

        {!isCompanyExist ? (
          <img src={src} alt={`${src}`} loading={"lazy"} />
        ) : (
          <button
            onClick={() =>
              directToNewTabHandler("factory_info/basic_info", companyNumber)
            }
          >
            <img src={src} alt={`${src}`} />
          </button>
        )}
      </div>
      <div className="question-container-card-right">
        {children}
        <Style.QuestionBasicButtonContainer>
          {!isIndexZero ? (
            <Style.QuestionBasicButton
              onClick={prevSlide}
              backgroundColor={"orange"}
            >
              上一步
            </Style.QuestionBasicButton>
          ) : (
            <Style.QuestionBasicButton
              onClick={() => {
                window.history.back();
              }}
              backgroundColor={"orange"}
            >
              回管理頁
            </Style.QuestionBasicButton>
          )}
          <Style.QuestionBasicButton
            backgroundColor={"#2e9830"}
            onClick={async () => {
              UploadQuestionData(false);
              await alert("暫存成功!!!");
            }}
          >
            暫存
          </Style.QuestionBasicButton>

          {!isIndexFour && (
            <>
              {!isFinished ? (
                <Style.DisabledQuestionBasicButton>
                  下一步
                </Style.DisabledQuestionBasicButton>
              ) : (
                <Style.ButtonRow>
                  {(isIndexOne || isECO) && (
                    <Style.QuestionBasicButton
                      backgroundColor={"#66B3FF"}
                      onClick={() => handleSignaturePad(true)}
                    >
                      紀錄
                    </Style.QuestionBasicButton>)}
                  <Style.QuestionBasicButton onClick={nextSlide}>
                    下一步
                  </Style.QuestionBasicButton>
                </Style.ButtonRow>
              )}
            </>
          )}

          {isIndexFour && (
            <>
              {!stepFiveChecked ? (
                <Style.DisabledQuestionBasicButton>
                  確認
                </Style.DisabledQuestionBasicButton>
              ) : (
                <Style.QuestionBasicButton
                  backgroundColor={"#2a7ac6"}
                  onClick={() => handleSignaturePad(false)}
                >
                  確認
                </Style.QuestionBasicButton>
              )}
            </>
          )}
        </Style.QuestionBasicButtonContainer>

        <Modals
          isOpen={state.isSignaturePadOpen}
          handleClose={handleSignaturePad}
        >
          <SignaturePad
            openPreview={openPreview}
            UploadQuestionData={UploadQuestionData}
            handleClose={handleSignaturePad}
            handleOpenQRcode={handleOpenQRcode}
            result={result}
            tempStorage={state.isTempStorage}
          />
        </Modals>
        <Modals isOpen={state.isQRcodeOpen} handleClose={handleCloseQRcode}>
          <QRcodeModal handleCloseQRcode={handleCloseQRcode} />
        </Modals>
      </div>
    </div>
  );
};
