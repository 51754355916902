import React from "react";
import { useSelector } from "react-redux";
import { Card } from "react-bootstrap";

import FactoryBoardMember from "../../../components/CustomizedTable/FactoryInfo/FactoryBoardMember";
import FactoryManager from "../../../components/CustomizedTable/FactoryInfo/FactoryManager";
import FactoryRegisteredOperationItems from "../../../components/CustomizedTable/FactoryInfo/FactoryRegisteredOperationItems";
import FactoryBranchCompanyInfo from "../../../components/CustomizedTable/FactoryInfo/FactoryBranchCompanyInfo";
import FactoryAssociatedCompany from "../../../components/CustomizedTable/FactoryInfo/FactoryAssociatedCompany";
import CustomizedCard from "../../../components/CustomizedCard/CustomizedCardV3";

const BusinessInfo = () => {
  const { innerWidth } = useSelector((state) => state.app);
  const {
    boardMember,
    boardMemberLoading,
    registeredOperationItems,
    registeredOperationItemsLoading,
    branchCompanyInfo,
    branchCompanyInfoLoading,
    associationCompany,
    manager,
    managerLoading
  } = useSelector((state) => state.factory);

  return (
    <React.Fragment>
      <Card >
        <CustomizedCard
          title="董事會資料"
          referenceType={"factoryInfoBoardMembers"}
          loading={boardMemberLoading}
        >
          <FactoryBoardMember
            data={boardMember}
            headerList={
              innerWidth > 1024
                ? ["職稱", "姓名", "持有股份數", "代表法人"]
                : ["姓名(職稱)", "持有股份數", "代表法人"]
            }
          />
        </CustomizedCard>
      </Card>
      <Card >
        <CustomizedCard
          title="經理人資料"
          referenceType={"factoryInfoManager"}
          loading={managerLoading}
        >
          <FactoryManager
            data={manager}
            headerList={["經理人姓名", "到職日期"]}
          />
        </CustomizedCard>
      </Card>
      <Card >
        <CustomizedCard
          title="營業登記項目"
          referenceType={"factoryInfoOperation"}
          loading={registeredOperationItemsLoading}
        >
          <FactoryRegisteredOperationItems
            data={registeredOperationItems}
            headerList={["代碼", "營業項目描述"]}
          />
        </CustomizedCard>
      </Card>
      <Card >
        <CustomizedCard
          title="關係企業"
          referenceType={"factoryInfoSubsidiary"}
          loading={associationCompany.associationCompanyLoading}
        >
          <FactoryAssociatedCompany
            data={associationCompany.subCompanyList}
            headerList={
              innerWidth > 1024
                ? ["統一編號", "公司名稱", "資本額"]
                : ["統一編號", "公司名稱"]
            }
          />
        </CustomizedCard>
      </Card>
      <Card >
        <CustomizedCard
          title="分公司"
          referenceType={"factoryInfoBranches"}
          loading={branchCompanyInfoLoading}
        >
          <FactoryBranchCompanyInfo
            data={branchCompanyInfo}
            headerList={["分公司名稱", "地址", "狀態"]}
          />
        </CustomizedCard>
      </Card>
    </React.Fragment>
  );
};

export default React.memo(BusinessInfo);
