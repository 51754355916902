import styled from "styled-components";

export const ScrollContainer = styled.div`
  height: calc(100vh - 115px);
  min-height: 50vh;
  overflow-y: scroll;
  overflow-x: hidden;
  width: 100%;
  margin: 50px 0px;

  @media (max-width: 1024px) {
    height: 100%;
    overflow-y: auto;
    margin: 0px;
  }
`;

export const InnerContainer = styled.div`
  padding: 0px 2.5% 2.5%;
`;

export const Header = styled.h1`
  margin: 2% 0px 2% 0px;
  font-size: 2.18rem;
  font-weight: 700;
  letter-spacing: 2px;
`;
