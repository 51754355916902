//搜尋稅籍廠商基本資料
export const START_TAX_INFO_SEARCH = `START_TAX_INFO_SEARCH`;
export const FETCH_TAX_INFO_SEARCH_SUCCESS = `FETCH_TAX_INFO_SEARCH_SUCCESS`;
export const FETCH_TAX_INFO_SEARCH_FAIL = `FETCH_TAX_INFO_SEARCH_FAIL`;

//出口產品四碼清單
export const START_EXPORT_PRODUCT_FOUR_CODE_LIST = `START_EXPORT_PRODUCT_FOUR_CODE_LIST`;
export const FETCH_EXPORT_PRODUCT_FOUR_CODE_LIST_SUCCESS = `FETCH_EXPORT_PRODUCT_FOUR_CODE_LIST_SUCCESS`;
export const FETCH_EXPORT_PRODUCT_FOUR_CODE_LIST_FAIL = `FETCH_EXPORT_PRODUCT_FOUR_CODE_LIST_FAIL`;

//稅籍產業選單
export const START_TAX_INDUSTRY_LIST = `START_TAX_INDUSTRY_LIST`;
export const FETCH_TAX_INDUSTRY_LIST_SUCCESS = `FETCH_TAX_INDUSTRY_LIST_SUCCESS`;
export const FETCH_TAX_INDUSTRY_LIST_FAIL = `FETCH_TAX_INDUSTRY_LIST_FAIL`;

//工廠二碼選單
export const START_TYPE_TWO_LIST = `START_TYPE_TWO_LIST`;
export const FETCH_TYPE_TWO_LIST_SUCCESS = `FETCH_TYPE_TWO_LIST_SUCCESS`;
export const FETCH_TYPE_TWO_LIST_FAIL = `FETCH_TYPE_TWO_LIST_FAIL`;

//工廠三碼選單
export const START_TYPE_THREE_LIST = `START_TYPE_THREE_LIST`;
export const FETCH_TYPE_THREE_LIST_SUCCESS = `FETCH_TYPE_THREE_LIST_SUCCESS`;
export const FETCH_TYPE_THREE_LIST_FAIL = `FETCH_TYPE_THREE_LIST_FAIL`;