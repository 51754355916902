export const talents = [
    {
        name:'吳佩芬',
        en_name:'Pei Fen Wu',
        gender:'女',
        school:'國立彰化師範大學資訊管理學系暨研究所',
        position:'副教授',
        address:'(500) 彰化縣彰化市師大路2號',
        phone:'04-7232105 #7517',
        email:'pfwu@cc.ncue.edu.tw',
        fax:'[不公開]',	
        skill:'電腦繪圖與動畫, 遊戲式學習, 數位學習平台與人機介面設計, 媒體與互動設計',
        publication:[
            {
                years: '2019 - 05',
                category: '研討會論文',
                title: '動畫中反派角色造型與對應之性格分析- 以《迪士尼公主系列電影》為例',
                authors: '鄭榆潔、吳佩芬',
                reference: ''
            },
            {
                years: '2018 - 11',
                category: '期刊論文',
                title: '動畫短片對高教生之批判性思考影響：以皮克斯之三部動畫短片為例',
                authors: '吳佩芬, 鄭筑云',
                reference: '藝術研究學報'
            },
            {
                years: '2018 - 06',
                category: '研討會論文',
                title: '探討動畫中老人角色造型與性格之文化性差異',
                authors: '許雅筑、吳佩芬',
                reference: ''
            },
            {
                years: '2017 - 06',
                category: '研討會論文',
                title: '探討動畫短片啟發式思考之影響─以Ormie the pig動畫短片為例',
                authors: '鄭筑云、吳佩芬',
                reference: ''
            },
            {
                years: '2015 - 12',
                category: '期刊論文',
                title: '動畫電影中女性英雄之角色性格與造型探討',
                authors: '周衡儀、吳佩芬',
                reference: '藝術論文集刊(THCI)'
            },

        ],
        projects:[
            {
                years: '105',
                category: '專題研究計畫(新進人員研究計畫)',
                acdemic_category:'藝術教育',
                name: '數位化說故事系統應用於兒童說故事能力之培養',
                budget: '571, 000',
            },
            {
                years: '104',
                category: '專題研究計畫(新進人員研究計畫)',
                acdemic_category: '機械與電子影／音像',
                name: '立體繪本應用擴增實境對兒童創造力之影響',
                budget: '459, 000',
            },
            {
                years: '103',
                category: '專題研究計畫(會議補助計畫)',
                acdemic_category: '其他',
                name: '專利視覺化推廣',
                budget: '393, 000',
            },
            {
                years: '103',
                category: '專題研究計畫(新進人員研究計畫)',
                acdemic_category: '機械與電子影／音像',
                name: '地方文化特色街區之數位互動感知研究',
                budget: '437, 000',
            },
            {
                years: '102',
                category: '專題研究計畫(新進人員研究計畫)',
                acdemic_category: '機械與電子影／音像',
                name: '應用互動科技結合水墨畫於兒童的創造力經驗探討',
                budget: '444, 000',
            },
        ]
    },
    {
        name: '鐘世凱',
        en_name: 'Shih - kai Chung',
        gender: '男',
        school: '國立臺灣藝術大學多媒體動畫藝術學系（所）',
        position: '教授',
        address: '[不公開]',
        phone: '02 - 22722181 * 2152',
        email: '[不公開]',
        fax: '[不公開]',
        skill: '[不公開]',
        publication: [
            {
                years: '2015 - 10',
                category: '研討會論文',
                title: '動畫的諷刺意涵分析研究',
                authors: '歐芸瑄，鐘世凱',
                reference: ''
            },
            {
                years: '2014 - 10',
                category: '期刊論文',
                title: '動畫短片故事劇情編排與鏡頭語言之關聯研究',
                authors: '鐘世凱，張維忠',
                reference: ''
            },
            {
                years: '2011 - 12',
                category: '研討會論文',
                title: '動畫短片故事發想研究－PIXAR動畫短片結構分析',
                authors: '陳弘睿，鐘世凱',
                reference: ''
            },
            {
                years: '2011 - 12',
                category: '研討會論文',
                title: '動畫色彩計畫研究─美、日成功商業動畫長片色彩計畫比較分析',
                authors: '張凱翔，鐘世凱',
                reference: ''
            },
            {
                years: '2011 - 12',
                category: '期刊論文',
                title: '隊形變換在群體動畫之分析研究',
                authors: '鄭君彥，鐘世凱',
                reference: ''
            },

        ],
        projects: [
            {
                years: '097',
                category: '專題研究計畫(一般研究計畫)',
                acdemic_category: '機械與電子影／音像',
                name: '3D動畫影片分鏡－-好萊塢成功商業3D動畫長片研究',
                budget: '212, 000',
            },
            {
                years: '104',
                category: '專題研究計畫(國家型科技計畫)',
                acdemic_category: '數典國家型計畫(創意加值計畫)',
                name: '加值型多媒體整合及商業發展計畫--(子計畫二)多媒體整合的商業運用以科博館人類學典藏為例',
                budget: '1, 111, 000',
            },
        ]
    },



] 


