import React from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

export default (props) => {
  const {
    data,
    selectedItems,
    updateSelectedItemsHandler,
    type,
    updateTagHandler,
    updateProducthandler,
    style,
    handleChange,
    stream,
    value,
    referenceValue
  } = props;
  const [state, setState] = React.useState({
    listData: [],
  });

  React.useEffect(() => {
    if (type) {
      if (data.length > 0) {
        const updateDataList = [];
        data
          .filter((item) => item.stream === type)
          .map((item) =>
            updateDataList.push({
              ...item,
              isProductOpen: false,
            })
          );
        setState((state) => ({ ...state, listData: updateDataList }));
      }
    } else {
      if (data.length > 0) {
        const updateDataList = [];
        data.map((item) =>
          updateDataList.push({
            ...item,
            isProductOpen: false,
          })
        );
        setState((state) => ({ ...state, listData: updateDataList }));
      }
    }
  }, [data]);

  const toggleProduct = (id) => {
    const updateDataList = [];
    state.listData.map((item) => {
      if (id === item.tag) {
        updateDataList.push({
          ...item,
          isProductOpen: !item.isProductOpen,
        });
      } else {
        updateDataList.push({
          ...item,
        });
      }
    });
    setState((state) => ({ ...state, listData: updateDataList }));
    // if(selectedItems.filter(i => i === id).length > 0){
    //     return
    // }else{
    //     const updateSelectedItems = selectedItems;
    //     updateSelectedItems.push(id);
    //     updateSelectedItemsHandler(updateSelectedItems);
    // }
    updateTagHandler(id);
    updateSelectedItemsHandler(id);
  };

  const updateItemHandler = (item) => {
    updateSelectedItemsHandler(item);
    updateProducthandler(item);

    // if(selectedItems.filter(i => i === item).length > 0){
    //     return
    // }else{
    //     const updateSelectedItems = selectedItems;
    //     updateSelectedItems.push(item);
    //     updateSelectedItemsHandler(updateSelectedItems);
    //     updateSelectedItemsHandler(item);
    //     updateProducthandler(item);
    // }
  };

  if(window.innerWidth < 1023 && (referenceValue !== value) && value !== 3){
      return null
  }else{
    return (
        <div className="item-content" style={style}>
          <div className="item-all" key={`${type}-all`}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={type === stream}
                  onChange={handleChange}
                  // indeterminate
                />
              }
              label={`${type}全部`}
            />
          </div>
          <div className="item-content-wrapper">
            {state.listData.map((item) => {
              return (
                <div className="item-content-content" key={item.tag}>
                  <h4 className="code" onClick={() => toggleProduct(item.tag)}>
                    {item.code}
                  </h4>
                  <h4 className="tag" onClick={() => toggleProduct(item.tag)}>
                    {item.tag}
                  </h4>
                  <div className="product-container">
                    {item.products &&
                      item.products
                        .split(",")
                        .map((prod) => (
                          <h4 onClick={() => updateItemHandler(prod)}>{prod}</h4>
                        ))}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      );
  }
};
