import React, { useState, useEffect } from "react";
import styled from "styled-components";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import Box from "@material-ui/core/Box";
import Switch from "@material-ui/core/Switch";
import TextField from "@material-ui/core/TextField";
import {
  parks,
  northParks,
  centerParks,
  southParks,
  eastParks,
} from "../../constants/parks";
import {
  cities,
  northCities,
  centerCities,
  southCities,
  eastCities,
} from "../../constants/cities";

const MapSearch = ({ search }) => {
  const url = window.location.href.split("/");
  const [citiesOptions, setCitiesOptions] = useState([]);
  const [parksOptions, setParksOptions] = useState([]);
  const valueMap = [
    {
      name: "isAward",
      label: "近五年得過獎的廠商",
    },
    {
      name: "isPlan",
      label: "近五年執行過政府計畫的廠商",
    },
    {
      name: "isPatent",
      label: "近五年有專利公告的廠商",
    },
    {
      name: "isIncome",
      label: "上一年度進口級距為 C 以上的廠商",
    },
    {
      name: "isOutcome",
      label: "上一年度出口級距為 C 以上的廠商",
    },
  ];
  const [state, setState] = React.useState({
    showMore: false,
    searchType: "CompanyName",
    searchContent: "",
    filterCondition: {
      keywordType: "or",
      filterKeyWord: [],
      Area: "All",
      AreaOrCity: "Area",
      Cities: [],
      ParksList: [],
      TypeList: [],
      MinCapital: "",
      MaxCapital: "",
      isIPO: false,
      isInduParks: false,
      isAward: false,
      isPlan: false,
      isPatent: false,
      isIncome: false,
      isOutcome: false,
      isUseLocation: false,
      isUseLocationFar: 1,
      SpecialConditions: [],
      numbers: "",
    },
  });

  useEffect(() => {
    if (search === "talentSearch") {
      setState((prev) => ({
        ...prev,
        showMore: false,
      }));
    }
  }, [search]);
  useEffect(() => {
    if (state.filterCondition.Area === "All") {
      setCitiesOptions(cities);
      setParksOptions(parks);
    } else if (state.filterCondition.Area === "North") {
      setCitiesOptions(northCities);
      setParksOptions(northParks);
    } else if (state.filterCondition.Area === "Center") {
      setCitiesOptions(centerCities);
      setParksOptions(centerParks);
    } else if (state.filterCondition.Area === "South") {
      setCitiesOptions(southCities);
      setParksOptions(southParks);
    } else if (state.filterCondition.Area === "East") {
      setCitiesOptions(eastCities);
      setParksOptions(eastParks);
    }
  }, [state.filterCondition.Area]);
  const searchNext = () => {
    let param = `keyword=${state.searchContent}&operator=${state.filterCondition.keywordType}&type=${search}`;
    console.log("filterCondition", state.filterCondition);
    if (state.filterCondition.MinCapital !== "") {
      param += `&min=${state.filterCondition.MinCapital}`;
    }
    if (state.filterCondition.MaxCapital !== "") {
      param += `&max=${state.filterCondition.MaxCapital}`;
    }
    if (state.filterCondition.Area !== "All") {
      param += `&Area=${state.filterCondition.Area}`;
    }
    if (state.filterCondition.Cities.length > 0) {
      param += `&Cities=${JSON.stringify(state.filterCondition.Cities)}`;
    }
    if (state.filterCondition.ParksList.length > 0) {
      param += `&ParksList=${JSON.stringify(state.filterCondition.ParksList)}`;
    }
    if (state.filterCondition.AreaOrCity !== "Area") {
      param += `&isArea=false`;
    }
    [
      "isIPO",
      "isInduParks",
      "isAward",
      "isPlan",
      "isPatent",
      "isIncome",
      "isOutcome",
    ].forEach((item) => {
      if (state.filterCondition[item] && state.filterCondition[item] === true) {
        param += `&${item}=true`;
      }
    });
    window.open(url[0] + "//" + url[2] + `/map-search?${param}`, "_blank");
  };
  const handleChange = (event) => {
    if (event.target?.name) {
      const name = event.target.name;
      if (name === "SpecialConditions") {
        // const value = event.target.value;
        // setState((prev) => ({
        //   ...prev,
        //   filterCondition: {
        //     ...state.filterCondition,
        //     SpecialConditions: value,
        //   },
        // }));
      } else if (name === "Cites") {
        console.log("Cites", event.target.value);
      } else if (name === "ParksList") {
        setState((prev) => ({
          ...prev,
          filterCondition: {
            ...state.filterCondition,
            ParksList: event.target.value,
          },
        }));
      } else if (event.target.name.includes("is")) {
        setState((prev) => ({
          ...prev,
          filterCondition: {
            ...state.filterCondition,
            [event.target.name]: event.target.checked,
          },
        }));
      } else {
        setState((prev) => ({
          ...prev,
          filterCondition: {
            ...state.filterCondition,
            [event.target.name]: event.target.value,
          },
        }));
      }
    }
  };
  return (
    <div style={{ width: "100%" }}>
      <div className="search-content">
        <Box display="flex" flexWrap={"wrap"} justifyContent={"center"} mt={1}>
          <FormControl style={{ marginBottom: "8px" }}>
            <RadioGroup
              row
              style={{
                flexWrap: "nowrap",
              }}
              name="row-radio-buttons-group"
              className="button-group"
              value={state.filterCondition.keywordType}
              onChange={(e) => {
                setState((state) => ({
                  ...state,
                  filterCondition: {
                    ...state.filterCondition,
                    keywordType: e.target.value,
                  },
                }));
              }}>
              <FormControlLabel
                tabIndex={"0"}
                className={
                  state.filterCondition.keywordType === "or" && "active"
                }
                value="or"
                control={<Radio size="small" />}
                label="OR"
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    setState((prev) => ({
                      ...prev,
                      filterCondition: {
                        ...state.filterCondition,
                        keywordType: "or",
                      },
                    }));
                  }
                }}
              />
              <FormControlLabel
                tabIndex={"0"}
                className={
                  state.filterCondition.keywordType === "and" && "active"
                }
                value="and"
                control={<Radio size="small" />}
                label="AND"
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    setState((prev) => ({
                      ...prev,
                      filterCondition: {
                        ...state.filterCondition,
                        keywordType: "and",
                      },
                    }));
                  }
                }}
              />
            </RadioGroup>
          </FormControl>
          <SearchWrapper style={{ marginBottom: "8px" }}>
            {/* <Autocomplete
                  className="search-input"
                  freeSolo
                  disableClearable
                  // defaultValue={option[0]}
                  // onKey={event => {
                  //   if (event.charCode === 13) {
                  //     event.preventDefault()
                  //     return false
                  //   }
                  // }}
                  // ref={(input) => { setBusinessId(input) }}
                  disableOpenOnFocus
                  renderOption={(option) => {
                    return (
                      <React.Fragment>
                        <span>
                          {option.BusinessNo}, {option.Name}, {option.ParkName}
                        </span>
                      </React.Fragment>
                    );
                  }}
                  // getOptionLabel={option => {
                  //   if (!option.BusinessNo) {
                  //     return
                  //   } else {
                  //     setBusinessId(option.BusinessNo)
                  //     return option.BusinessNo + ', ' + option.Name + ', ' + option.ParkName
                  //   }
                  // }}
                  // onChange={() => { return  }}
                  renderInput={(params) => (
                    <input
                      placeholder={
                        search === "productSearch"
                          ? "產品或廠商關鍵字"
                          : "技術關鍵字"
                      }
                      className="map-filter-key-word"
                      onChange={(event) => {
                        setState(state => ({ ...state, searchContent: event.target.value}))
                      }}
                      value={state.searchContent}
                      name="KeyWord"
                      {...params}
                    />
                  )}
                /> */}
            <TextField
              autoComplete="one-time-code"
              fullWidth
              size="small"
              style={{
                marginRight: "12px",
                marginLeft: "12px",
              }}
              id="outlined-basic"
              color="secondary"
              label="輸入關鍵字，請用 , 隔開"
              variant="outlined"
              onChange={(event) => {
                setState((state) => ({
                  ...state,
                  searchContent: event.target.value,
                }));
              }}
            />
          </SearchWrapper>
          <Box display="flex" style={{ marginBottom: "8px" }}>
            <button
              className={"button-contained"}
              style={{
                marginLeft: "24px",
              }}
              onClick={searchNext}>
              搜尋
            </button>
            {search === "productSearch" && (
              <button
                className={"button-contained-dark"}
                style={{
                  marginLeft: "12px",
                }}
                onClick={() => {
                  setState((state) => ({
                    ...state,
                    showMore: !state.showMore,
                  }));
                }}>
                進階設定
              </button>
            )}
          </Box>
        </Box>
        {/* <SearchHistoryContainer /> */}

        {state.showMore && (
          <Box
            py={2}
            style={{
              maxWidth: "100%",
            }}>
            <Box display={"flex"} my={2}>
              <FormControl
                color="secondary"
                fullWidth
                style={{ marginRight: "12px" }}
                variant="outlined"
                size="small">
                <InputLabel id="demo-simple-select-label">地區</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={state.filterCondition.Area}
                  label="地區"
                  name="Area"
                  onChange={handleChange}>
                  {[
                    {
                      value: "All",
                      label: "台灣所有地區",
                    },
                    {
                      value: "North",
                      label: "台灣北部地區",
                    },
                    {
                      value: "Center",
                      label: "台灣中部地區",
                    },
                    {
                      value: "East",
                      label: "台灣東部地區",
                    },
                    {
                      value: "South",
                      label: "台灣南部地區",
                    },
                  ].map((item) => {
                    return (
                      <MenuItem key={item.value} value={item.value}>
                        {item.label}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <FormControl
                color="secondary"
                style={{ flexShrink: 0, marginRight: "12px" }}>
                <RadioGroup
                  row
                  name="row-radio-buttons-group"
                  className="button-group"
                  value={state.searchType}
                  onChange={(e) => {
                    setState((state) => ({
                      ...state,
                      filterCondition: {
                        ...state.filterCondition,
                        AreaOrCity: e.target.value,
                      },
                    }));
                  }}>
                  <FormControlLabel
                    tabIndex="0"
                    className={
                      state.filterCondition.AreaOrCity === "Area" && "active"
                    }
                    value="Area"
                    onKeyDown={(e) => {
                      if (e.keyCode === 13) {
                        setState((prev) => ({
                          ...prev,
                          filterCondition: {
                            ...state.filterCondition,
                            AreaOrCity: "Area",
                          },
                        }));
                      }
                    }}
                    control={<Radio size="small" />}
                    label="縣市"
                  />
                  <FormControlLabel
                    tabIndex="0"
                    className={
                      state.filterCondition.AreaOrCity === "Park" && "active"
                    }
                    value="Park"
                    onKeyDown={(e) => {
                      if (e.keyCode === 13) {
                        setState((prev) => ({
                          ...prev,
                          filterCondition: {
                            ...state.filterCondition,
                            AreaOrCity: "Park",
                          },
                        }));
                      }
                    }}
                    control={<Radio size="small" />}
                    label="園區"
                  />
                </RadioGroup>
              </FormControl>
              {state.filterCondition.AreaOrCity === "Area" && (
                <FormControl
                  color="secondary"
                  variant="outlined"
                  size="small"
                  fullWidth>
                  <InputLabel id="demo-simple-select-label">縣市</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={state.filterCondition.Cities}
                    label="縣市"
                    multiple
                    name="Cities"
                    onChange={handleChange}>
                    {citiesOptions.map((city, index) => (
                      <MenuItem key={`${city}_${index}`} value={city}>
                        {city}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
              {state.filterCondition.AreaOrCity === "Park" && (
                <FormControl
                  color="secondary"
                  variant="outlined"
                  size="small"
                  fullWidth>
                  <InputLabel id="park_list_label">園區</InputLabel>
                  <Select
                    multiple
                    labelId="park_list_label"
                    id="park_list"
                    value={state.filterCondition.ParksList}
                    label="園區"
                    name="ParksList"
                    onChange={handleChange}>
                    {parksOptions.map((indu) => (
                      <MenuItem key={indu.ParkId} value={indu.Name_CH}>
                        {indu.Name_CH}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </Box>
            <Box display={"flex"} my={2} justifyContent={"center"}>
              <FormControlLabel
                color="secondary"
                control={
                  <Switch
                    inputProps={{ role: 'switch' }}
                    checked={state.filterCondition.isIPO}
                    onChange={handleChange}
                    name="isIPO"
                    onKeyDown={(e) => {
                      if (e.keyCode === 13) {
                        setState((prev) => ({
                          ...prev,
                          filterCondition: {
                            ...state.filterCondition,
                            isIPO: !state.filterCondition.isIPO,
                          },
                        }));
                      }
                    }}
                  />
                }
                label="股票上市櫃"
              />
              <FormControlLabel
                color="secondary"
                control={
                  <Switch
                    checked={state.filterCondition.isInduParks}
                    onChange={handleChange}
                    name="isInduParks"
                    onKeyDown={(e) => {
                      if (e.keyCode === 13) {
                        setState((prev) => ({
                          ...prev,
                          filterCondition: {
                            ...state.filterCondition,
                            isInduParks: !state.filterCondition.isInduParks,
                          },
                        }));
                      }
                    }}
                  />
                }
                label="產業園區"
              />
              {/* <FormControlLabel color="secondary"
                    control={<Switch checked={state.filterCondition.isUseLocation} onChange={handleChange} name="isUseLocation" />}
                    label="以你所在區域"
                  />
                  <FormControl color="secondary" variant="outlined" size="small">
                    <InputLabel id="demo-simple-select-label">距離</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={state.filterCondition.isUseLocationFar}
                      label="距離"
                      onChange={handleChange}
                    >
                      <MenuItem value={1}>1公里內</MenuItem>
                      <MenuItem value={5}>5公里內</MenuItem>
                      <MenuItem value={10}>10公里內</MenuItem>
                    </Select>
                  </FormControl> */}
            </Box>
            <Box display={"flex"}>
              <TextField
                fullWidth
                size="small"
                style={{
                  marginRight: "12px",
                }}
                id="outlined-basic"
                color="secondary"
                label="最低資本額"
                variant="outlined"
                name="MinCapital"
                onChange={handleChange}
              />
              <TextField
                fullWidth
                size="small"
                style={{
                  marginRight: "12px",
                }}
                color="secondary"
                id="outlined-basic"
                label="最高資本額"
                variant="outlined"
                name="MaxCapital"
                onChange={handleChange}
              />
              {/* <FormControl  color="secondary" fullWidth style={{ marginRight: "12px" }} variant="outlined" size="small">
                    <InputLabel id="demo-mutiple-checkbox-label">特殊條件快搜(你已勾選{state.filterCondition.SpecialConditions.length}項)</InputLabel>
                    <Select
                      labelId="demo-mutiple-checkbox-label"
                      id="demo-mutiple-checkbox"
                      multiple
                      value={state.filterCondition.SpecialConditions}
                      name="SpecialConditions"
                      onChange={handleChange}
                      input={<OutlinedInput />}
                      renderValue={(selected) => valueMap.filter(item => selected.indexOf(item.name) > -1).map(item => item.label).join(', ')}
                    >
                      {valueMap.map((item) => (
                        <MenuItem key={item.name} value={item.name}>
                          <Checkbox checked={state.filterCondition.SpecialConditions.indexOf(item.name) > -1} />
                          <ListItemText primary={item.label} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl> */}
              {/* <FormControl color="secondary" fullWidth style={{ marginRight: "12px" }} variant="outlined" size="small">
                    <InputLabel id="demo-simple-select-label">特殊條件快搜(你已勾選{state.filterCondition.SpecialConditions.length}項)</InputLabel>
                    <Select
                      multiple
                      label="特殊條件快搜"
                      labelId="demo-mutiple-checkbox-label"
                      id="demo-mutiple-checkbox"
                      value={state.filterCondition.SpecialConditions}
                      className="select"
                    >
                      <MenuItem>
                        <FormControlLabel color="secondary"
                          control={
                            <Checkbox name="isAward" onChange={handleChange} checked={state.filterCondition.isAward} />
                          }
                          label="近五年得過獎的廠商"
                        />
                      </MenuItem>
                      <MenuItem>
                        <FormControlLabel color="secondary"
                          control={
                            <Checkbox name="isPlan" onChange={handleChange} checked={state.filterCondition.isPlan} />
                          }
                          label="近五年執行過政府計畫的廠商"
                        />
                      </MenuItem>
                      <MenuItem>
                        <FormControlLabel color="secondary"
                          control={
                            <Checkbox name="isPatent" onChange={handleChange} checked={state.filterCondition.isPatent} />
                          }
                          label="近五年有專利公告的廠商"
                        />
                      </MenuItem>
                      <MenuItem>
                        <FormControlLabel color="secondary"
                          control={
                            <Checkbox name="isIncome" onChange={handleChange} checked={state.filterCondition.isIncome}  />
                          }
                          label="上一年度進口級距為C以上的廠商"
                        />
                      </MenuItem>
                      <MenuItem>
                        <FormControlLabel color="secondary"
                          control={
                            <Checkbox name="isOutcome" onChange={handleChange} checked={state.filterCondition.isOutcome} />
                          }
                          label="上一年度出口級距為C以上的廠商"
                        />
                      </MenuItem>
                    </Select>
                  </FormControl> */}
            </Box>
          </Box>
        )}
      </div>
    </div>
  );
};

const SearchWrapper = styled.div`
  position: relative;
  width: 350px;
  @media (max-width: 768px) {
    width: 200px;
  }
`;
const SelcetionContent = styled.div`
  position: absolute;
  background: white;
  box-shadow: 2px 2px 5px #f2f2f2;
  border: 1px solid #f2f2f2;
  border-radius: 16px;
  width: 350px;
  max-height: 200px;
  margin-left: 12px;
  @media (max-width: 768px) {
    width: 300px;
    margin-left: 0px;
  }
  top: 104%;
  overflow: auto;
`;

export default MapSearch;
