import React from "react";
import ReactExport from "react-data-export";
import Card from "@material-ui/core/Card";
import Icon from "@material-ui/core/Icon";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Checkbox from "@material-ui/core/Checkbox";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import { useHistory } from "react-router-dom";
import { ScrollContainer } from "../../utility/styles";
import { directToNewTabHandler } from "../../utility/common";
import Swal from "sweetalert2";
import survey from "../../assests/survey.png";
import {
  handleGetVisitQuestionList,
  handleDeleteVisitQuestion,
} from "../../store/actions/question";
import { useSnackbar } from "notistack";
import { useSelector, useDispatch } from "react-redux";
import BackToTopButton from "../../components/BackToTopButton/BackToTopButtonV2";
import { message } from "../../utility/common";
import "./styles.scss";
import * as Style from "./styles";

export default (props) => {
  const containerRef = React.useRef(null);
  let history = useHistory();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { getVisitQuestionList, getVisitQuestionListLoading } = useSelector((state) => state.question);

  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

  const [state, setState] = React.useState({
    dataList:[],
    KeyWord: "",
    StartDate: new Date(new Date().setMonth(new Date().getMonth() - 3)).toISOString().slice(0, 10),
    EndDate: new Date().toISOString().slice(0, 10),
    HandleType: "",
    traceBack: false,
    visitReason: "",
    group: "",
    page: 0,
    rowsPerPage: 5,
  });

  React.useEffect(() => {
    const uid = localStorage.getItem("uid");
    dispatch(
      handleGetVisitQuestionList(
        uid,
        state.KeyWord,
        state.StartDate,
        state.EndDate,
        state.traceBack,
        state.visitReason,
        state.group
      )
    );
  }, []);

  React.useEffect(() => {
    setState((state) => ({
      ...state,
      dataList: getVisitQuestionList,
    }));
  }, [getVisitQuestionList]);

  const updateSearchParams = (event, newPage) => {
    const { value, name, checked } = event.target;
    const updateParams = { ...state };

    if ((name === "visitReason" || name === "group") && value === 0) {
      updateParams[name] = "";
    } else {
      updateParams[name] = value || checked;
    }

    if (newPage !== undefined) {
      updateParams["page"] = newPage;
    }

    setState(updateParams);
  };

  const NoDataTableRow = (props) => {
    return (
      <TableRow className="table-row-no-data">
        <TableCell>{props.children}</TableCell>
      </TableRow>
    );
  };

  const directToNewQuestionnaire = (Qid, Group) => {
    if (Group === "環保組") {
      if (Qid) {
        history.push({
          pathname: `/ECO-questionnaire/edit/${Qid}`,
          state: `${Qid}`,
        });
      } else {
        history.push("/ECO-questionnaire/");
      }
    } else {
      if (Qid) {
        history.push({
          pathname: `/site-visit-record/edit/${Qid}`,
          state: `${Qid}`,
        });
      } else {
        history.push("/site-visit-record/");
      }
    }
  };

  const directToECOQuestionnaire = (Qid) => {
    if (Qid) {
      history.push({
        pathname: `/ECO-questionnaire/edit/${Qid}`,
        state: `${Qid}`,
      });
    } else {
      history.push("/ECO-questionnaire/");
    }
  };

  const searchQuestionList = () => {
    const uid = localStorage.getItem("uid");
    dispatch(
      handleGetVisitQuestionList(
        uid,
        state.KeyWord,
        state.StartDate,
        state.EndDate,
        state.traceBack,
        state.visitReason,
        state.group
      )
    );

    setState((state) => ({
      ...state,
      page: 0,
    }));
  };

  const openQuestionnaire = (Qid) => {
    directToNewTabHandler("site-visit-record", Qid);
  };

  {/* 刪除 */ }
  const deleteQuestionnaire = (Qid) => {
    Swal.fire({
      title: `確定要刪除？`,
      text: "專案刪除後將無法復原。",
      showCancelButton: true,
      confirmButtonColor: "#25758A",
      cancelButtonColor: "#bcb9b9",
      confirmButtonText: "確定刪除",
      cancelButtonText: "取消",
    }).then(async (result) => {
      if (result.isConfirmed) {
        dispatch(handleDeleteVisitQuestion(Qid));
        message(enqueueSnackbar, `刪除成功!`, "success");
        searchQuestionList()
      }
    }).catch(err => {
      message(enqueueSnackbar, "刪除失敗", "error");
    });
  };

  const scrollToTop = () => {
    containerRef.current.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <ScrollContainer ref={containerRef}>
      {window.innerWidth < 1024 && (
        <BackToTopButton scrollToTop={scrollToTop} />
      )}
      <Style.QuestionCard>
        <Style.QuestionRecordCardTop>
          <Style.QuestionRecordCardTopLeft>
            <Style.QuestionContainerText>
              訪廠問卷管理
            </Style.QuestionContainerText>
            <img src={survey} alt={`${survey}`} loading={"lazy"} />

            {window.innerWidth <= 1024 && (
              <Style.ButtonContainer>
                <Style.SearchBotton>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{
                      height: "45px",
                      fontSize: "16px",
                      width: "100%",
                      backgroundColor: "rosybrown",
                    }}
                    onClick={() => directToNewQuestionnaire()}
                    startIcon={<Icon>add</Icon>}
                  >
                    新增問卷(行政組)
                  </Button>
                </Style.SearchBotton>
                <Style.SearchBotton>
                  <Button
                    variant="contained"
                    color="success"
                    style={{
                      height: "45px",
                      fontSize: "16px",
                      width: "100%",
                      backgroundColor: "transparent",
                      color: "green",
                      border: "1px solid green",
                    }}
                    onClick={() => directToECOQuestionnaire()}
                    startIcon={<Icon>add</Icon>}
                  >
                    新增問卷(環保組)
                  </Button>
                </Style.SearchBotton>
              </Style.ButtonContainer>
            )}
          </Style.QuestionRecordCardTopLeft>

          <Style.QuestionRecordCardTopRight>
            <Style.SearchContainer>
              <Style.QuestionForm>
                <label>關鍵字</label>
                <input
                  onChange={(event) => updateSearchParams(event)}
                  value={state.KeyWord}
                  name={"KeyWord"}
                  placeholder="請輸入廠商名稱或受訪人"
                />
              </Style.QuestionForm>
              <Style.QuestionForm>
                <label>組別</label>
                <FormControl variant="filled" style={{ width: "98%" }}>
                  <Select
                    defaultValue={0}
                    name={"group"}
                    onChange={(event) => updateSearchParams(event)}
                  >
                    <MenuItem value={0}>全部</MenuItem>
                    <MenuItem value={"行政組"}>行政組</MenuItem>
                    <MenuItem value={"環保組"}>環保組</MenuItem>
                  </Select>
                </FormControl>
              </Style.QuestionForm>
            </Style.SearchContainer>

            <Style.SearchContainer>
              <Style.QuestionForm>
                <label>查詢訪廠時間大於</label>
                <input
                  type="date"
                  onChange={(event) => updateSearchParams(event)}
                  value={state.StartDate}
                  name={"StartDate"}
                />
              </Style.QuestionForm>
              <Style.QuestionForm>
                <label>查詢訪廠時間小於</label>
                <input
                  type="date"
                  onChange={(event) => updateSearchParams(event)}
                  value={state.EndDate}
                  name={"EndDate"}
                />
              </Style.QuestionForm>
            </Style.SearchContainer>

            <Style.SearchContainer>
              <Style.QuestionForm>
                <label>訪廠事由</label>
                <FormControl variant="filled" style={{ width: "98%" }}>
                  <Select
                    defaultValue={0}
                    name={"visitReason"}
                    onChange={(event) => updateSearchParams(event)}
                  >
                    <MenuItem value={0}>全部事由</MenuItem>
                    <MenuItem value={"主動拜訪"}>主動拜訪</MenuItem>
                    <MenuItem value={"廠商需求"}>廠商需求</MenuItem>
                    <MenuItem value={"會同前往"}>會同前往</MenuItem>
                    <MenuItem value={"首長拜訪"}>首長拜訪</MenuItem>
                  </Select>
                </FormControl>
              </Style.QuestionForm>
              <Style.QuestionForm>
                <div
                  style={{
                    display: "flex",
                    flexDirection: window.innerWidth < 1024 ? "column" : "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    margin: "2%",
                  }}
                >
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox />}
                      label="顯示需追蹤的廠商"
                      name={"traceBack"}
                      onChange={(event) => updateSearchParams(event)}
                    />
                  </FormGroup>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{
                      height: "35px",
                      width: "90px",
                    }}
                    startIcon={<Icon>search</Icon>}
                    onClick={searchQuestionList}
                  >
                    查詢
                  </Button>
                </div>
              </Style.QuestionForm>
            </Style.SearchContainer>
          </Style.QuestionRecordCardTopRight>
          {window.innerWidth > 1024 && (
            <Style.ButtonContainer>
              <Style.SearchBotton>
                <Button
                  variant="outlined"
                  color="success"
                  style={{
                    width: "100%",
                    height: "60px",
                    fontSize: "16px",
                    backgroundColor: "transparent",
                    border: "2px solid green",
                    color: "green",
                  }}
                  onClick={() => directToECOQuestionnaire()}
                  startIcon={<Icon>add</Icon>}
                >
                  新增問卷(環保組)
                </Button>
              </Style.SearchBotton>
              <Style.SearchBotton>
                <Button
                  variant="contained"
                  color="primary"
                  style={{
                    width: "100%",
                    height: "60px",
                    fontSize: "16px",
                    marginTop: "16px",
                    backgroundColor: "rosybrown",
                  }}
                  onClick={() => directToNewQuestionnaire()}
                  startIcon={<Icon>add</Icon>}
                >
                  新增問卷(行政組)
                </Button>
              </Style.SearchBotton>
            </Style.ButtonContainer>
          )}
        </Style.QuestionRecordCardTop>

            <Style.QuestionRecordCardBottom>
              {window.innerWidth <= 1024 ? (
                <div className="query-container-auth-mobile">
                  {state.dataList.map((content) => {
                    // var date = content.UpdateTime?.split("T");
                    var tempfollow = content.FollowStep4?.split(",");

                    return (
                      <div className="query-container-auth-mobile-container">
                        <div className="item">
                          <div className="item-title">訪廠時間 </div>
                          <div className="item-content">
                            {/* {date.map((item) => {
                          return <p>{item}</p>;
                        })} */}
                            {content.VisitTime}
                          </div>
                        </div>
                        <div className="item">
                          <div className="item-title">廠商名稱 </div>

                          <div
                            className="item-content-link"
                            onClick={() => openQuestionnaire(content.Qid)}
                          >
                            {content.CompanyName}
                          </div>
                        </div>
                        <div className="item">
                          <div className="item-title">組別 </div>
                          <div className="item-content">
                            {content.Group || "未填寫"}
                          </div>
                        </div>
                        <div className="item">
                          <div className="item-title">受訪人 </div>
                          <div className="item-content">
                            {content.RespondName || "未填寫"}
                          </div>
                        </div>
                        <div className="item">
                          <div className="item-title">聯絡電話 </div>
                          <div className="item-content">
                            {content.RespondPhone || "未填寫"}
                          </div>
                        </div>
                        <div className="item">
                          <div className="item-title">訪廠事由 </div>
                          <div className="item-content">
                            {content.VisitReason || "未填寫"}
                          </div>
                        </div>
                        <div className="item">
                          <div className="item-title">問卷狀態 </div>
                          <div className="item-content">
                            {content.Release ? "完成" : "未完成"}
                          </div>
                        </div>
                        <div className="item">
                          <div className="item-title">需追蹤 </div>
                          <div className="item-content">
                            {tempfollow.map((item) => {
                              return <p>{item}</p> || "未填寫";
                            })}
                          </div>
                        </div>
                        <div className="item">
                          <div
                            className={content.Release? "forward-btn disabled" : "forward-btn"}
                            onClick={() => {
                              if (content.Release) {
                                return;
                              }
                              directToNewQuestionnaire(content.Qid, content.Group)
                            }}
                          >
                            <Icon>edit</Icon>
                          </div>
                          <div
                            className="forward-btn"
                            onClick={() => {
                              if (window.confirm("確定要刪除問卷嗎?")) {
                                deleteQuestionnaire(content.Qid);
                              }
                            }}
                          >
                            <Icon>delete</Icon>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <Table
                  component={Card}
                  className="table"
                  style={
                    state.dataList.length === 0
                      ? { marginBottom: "0px" }
                      : null
                  }
                >
                  <TableHead className="questionnaire-table-head" align="center">
                    <TableRow>
                      <TableCell>訪廠時間</TableCell>
                      <TableCell>廠商名稱</TableCell>
                      <TableCell>組別</TableCell>
                      <TableCell>受訪人</TableCell>
                      <TableCell>聯絡電話</TableCell>
                      <TableCell>訪廠事由</TableCell>
                      <TableCell>問卷狀態</TableCell>
                      <TableCell>需追蹤</TableCell>
                      <TableCell>編輯</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody className="questionnaire-table-body">
                    {state.dataList.length !== 0 &&
                      // !loading &&
                      state.dataList
                        .slice(
                          state.page * state.rowsPerPage,
                          state.page * state.rowsPerPage + state.rowsPerPage
                        )
                        .map((item, index) => {
                          // var date = item.VisitTime?.split("T");
                          var tempfollow = item.FollowStep4?.split(",");
                          return (
                            <TableRow
                              index={index}
                              hover
                              className="table-body-row"
                            >
                              <TableCell className="normal-cell">
                                {/* {date.map((item) => {
                              return <p>{item}</p>;
                            })} */}
                                {item.VisitTime}
                              </TableCell>

                              <TableCell
                                className="large-cell-link"
                                onClick={() => openQuestionnaire(item.Qid)}
                              >
                                {item.CompanyName}
                              </TableCell>

                              <TableCell className="normal-cell">
                                {item.Group || "未填寫"}
                              </TableCell>
                              <TableCell className="normal-cell">
                                {item.RespondName || "未填寫"}
                              </TableCell>
                              <TableCell className="normal-cell">
                                {item.RespondPhone || "未填寫"}
                              </TableCell>
                              <TableCell className="normal-cell">
                                {item.VisitReason || "未填寫"}
                              </TableCell>
                              <TableCell className="normal-cell">
                                {item.Release ? "完成" : "未完成"}
                              </TableCell>
                              <TableCell className="large-cell">
                                {tempfollow.map((item) => {
                                  if (item) {
                                    return <p>。{item}</p>;
                                  }
                                }) || "無項目"}
                              </TableCell>

                              <TableCell className="normal-cell">
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    style={{
                                      height: "30px",
                                      width: "10px",
                                      backgroundColor: !item.Release && "#007bff",
                                    }}
                                    onClick={() =>
                                      directToNewQuestionnaire(item.Qid, item.Group)
                                    }
                                    disabled={item.Release && true}
                                  >
                                    <Icon>edit</Icon>
                                  </Button>
                                  <Button
                                    variant="contained"
                                    color="secondary"
                                    style={{
                                      height: "30px",
                                      width: "10px",
                                    }}
                                    onClick={() => deleteQuestionnaire(item.Qid)}
                                  >
                                    <Icon>delete</Icon>
                                  </Button>
                                </div>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                  </TableBody>

                  {state.dataList.length > 0 && (
                    <TableFooter>
                      <TableRow>
                        <caption>
                          <ExcelFile
                            filename="訪廠問卷條件查詢"
                            element={
                              <Button
                                variant="contained"
                                color="primary"
                                style={{
                                  height: "35px",
                                  width: "90px",
                                }}
                                startIcon={<Icon>download</Icon>}
                                onClick={searchQuestionList}
                              >
                                匯出
                              </Button>
                            }
                          >
                            <ExcelSheet
                              data={state.dataList}
                              name="訪廠問卷條件查詢"
                            >
                              <ExcelColumn label="廠商名稱" value="CompanyName" />
                              <ExcelColumn label="組別" value="Group" />
                              <ExcelColumn label="受訪人" value="RespondName" />
                              <ExcelColumn label="訪廠事由" value="VisitReason" />
                              <ExcelColumn
                                label="需追蹤的內容"
                                value="FollowStep4"
                              />
                            </ExcelSheet>
                          </ExcelFile>
                        </caption>
                        <TablePagination
                          rowsPerPageOptions={[]}
                          count={state.dataList.length}
                          rowsPerPage={state.rowsPerPage}
                          page={state.page}
                          labelRowsPerPage=""
                          onChangePage={updateSearchParams}
                        />
                      </TableRow>
                    </TableFooter>
                  )}
                </Table>
              )}

              {state.dataList.length === 0 && (
                <Table component={Card} className="no-data-table">
                  <TableBody>
                    <NoDataTableRow>
                      <TableCell align="center">目前沒有資料</TableCell>
                    </NoDataTableRow>
                  </TableBody>
                </Table>
              )}
            </Style.QuestionRecordCardBottom>

      </Style.QuestionCard>
      <div style={{ height: "30%" }} />
    </ScrollContainer >
  );
};
