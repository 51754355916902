import * as actionTypes from "../actionTypes/weather";

const initState = {
    get_weather_box_loading: false,
    get_weather_box: [],
    get_weather_info_loading: false,
    get_weather_info: "",
    get_air_quality_loading: false,
    get_air_quality: ""
};

const startFetchWeatherBoxInfo = (state) => {
  return {
    ...state,
    get_weather_box_loading: true,
  };
};

const fetchWeatherBoxInfoSuccess = (state, action) => {
  console.log(action)
  return {
    ...state,
    get_weather_box: action.data,
    get_weather_box_loading: false,
  };
};

const fetchWeatherBoxInfoFail = (state) => {
  return {
    ...state,
    get_weather_box_loading: false,
  };
};

const startFetchWeatherInfo = (state) => {
    return {
      ...state,
      get_weather_info_loading: true,
    };
  };
  
  const fetchWeatherInfoSuccess = (state, action) => {
    return {
      ...state,
      get_weather_info: action.data,
      get_weather_info_loading: false,
    };
  };
  
  const fetchWeatherInfoFail = (state) => {
    return {
      ...state,
      get_weather_info_loading: false,
    };
  };
  

  const startFetchAirQuality = (state) => {
    return {
      ...state,
      get_air_quality_loading: true,
    };
  };
  
  const fetchAirQualitySuccess = (state, action) => {
    return {
      ...state,
      get_air_quality: action.data,
      get_air_quality_loading: false,
    };
  };
  
  const fetchAirQualityFail = (state) => {
    return {
      ...state,
      get_air_quality_loading: false,
    };
  };
  

export default (state = initState, action) => {
  switch (action.type) {
    case actionTypes.START_FETCH_WEATHER_BOX_DATA:
      return startFetchWeatherBoxInfo(state);
    case actionTypes.FETCH_WEATHER_BOX_DATA_SUCCESS:
      return fetchWeatherBoxInfoSuccess(state, action);
    case actionTypes.FETCH_WEATHER_BOX_DATA_FAIL:
      return fetchWeatherBoxInfoFail(state, action);
    case actionTypes.START_FETCH_WEATHER_INFO:
      return startFetchWeatherInfo(state);
    case actionTypes.FETCH_WEATHER_INFO_SUCCESS:
      return fetchWeatherInfoSuccess(state, action);
    case actionTypes.FETCH_WEATHER_INFO_FAIL:
      return fetchWeatherInfoFail(state, action);
    case actionTypes.START_FETCH_AIR_QUALITY:
      return startFetchAirQuality(state, action);
    case actionTypes.FETCH_AIR_QUALITY_SUCCESS:
      return fetchAirQualitySuccess(state, action);
    case actionTypes.FETCH_AIR_QUALITY_FAIL:
      return fetchAirQualityFail(state, action);   
    default:
      return state;
  }
};
