import React, { useEffect } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import debounce from "lodash.debounce";
import IconButton from "@mui/material/IconButton";
import Close from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import AddIcon from '@mui/icons-material/Add';
import { useSnackbar } from "notistack";

import { message } from "../utility/common";
import {
  handleFetchCustomDashboardList,
  handleAddCustomDashboardBusinessNumberList,
  handleGetBusinessNoByCompanyName,
} from "../store/actions/custom_dashboard";
import Spinner from "./Spinner/Spinner";

import { getFacNo } from "../utility/api";

import * as theme from "../assets/theme";

const CustomUpdateDashboardListModal = ({ id, should_api_call, handleClose, handle_open_update_board }) => {
    const dispatch = useDispatch();
    const { custom_dashboard_list, custom_dashboard_business_number_list, custom_dashboard_business_number_list_loading, custom_dashboard_business_number_list_result, business_no_by_company_name, business_no_by_company_name_loading } = useSelector(
      (state) => state.custom_dashboard
    );
    const { enqueueSnackbar } = useSnackbar();
    const [state, setState] = React.useState({
      search_type: "keyword",
      add_business_number_list: "",
      add_business_number_list_area: [],
      search_keyword: [],
      search_keyword_loading: false,
      is_fetched_res: false
    });
  
    useEffect(() => {
      if (!business_no_by_company_name_loading && business_no_by_company_name.length > 0) {
        const list = [...state.add_business_number_list_area];
        business_no_by_company_name.forEach(b => {
          list.push({
            business_no: b.BusinessNo,
            name: b.CompanyName,
            id: ""
          });
        })
        setState(state=>({ ...state, add_business_number_list_area: list, add_business_number_list: "" }))
      }
    }, [business_no_by_company_name_loading, business_no_by_company_name])

    useEffect(() => {
      if (custom_dashboard_business_number_list_result && custom_dashboard_business_number_list_result !== "") {
        const status = custom_dashboard_business_number_list_result?.split(":")[0]
        const msg = custom_dashboard_business_number_list_result?.split(":")[1].trim();
        message(enqueueSnackbar, msg, status);
      }
    }, [custom_dashboard_business_number_list_result])
    const handleAddBusinessNumberList = () => {
      const business_no_list = [];
      state.add_business_number_list_area.forEach((i) =>
        business_no_list.push(i.business_no)
      );

      dispatch(handleAddCustomDashboardBusinessNumberList(id, business_no_list))
      setState((state) => ({ ...state, add_business_number_list_area :[] }));
      handle_open_update_board(id)

      // setTimeout(()=>{
      //   if(!custom_dashboard_business_number_list_loading){
      //     message(enqueueSnackbar, "儀表板成功新增統編!", "success");
      //   }
      // }, 1000)
    };
  
    const handleUpdateBusinessNumberList = (val) => {
      setState((state) => ({ ...state, add_business_number_list: val, search_keyword: [] }));
    }
  
    const handleFetchCompanyByKeyword = (value) => {
      if(value){
        setState((state) => ({ ...state, search_keyword_loading: true }));
        getFacNo(value).then((res) => {
          setState((state) => ({
            ...state,
            search_keyword_loading: false,
            search_keyword: res,
            is_fetched_res: true
          }));
        });
      }else{
        setState((state) => ({
          ...state,
          is_fetched_res: false
        }));
      }
    };
  
    const debouncedChangeHandler = React.useCallback(
      debounce((val) => handleFetchCompanyByKeyword(val), 1000),
      []
    );
  
    const handleUpdateVal = (e) => {
      const { name, value } = e.target;
      if(["companyName", "multiple"].includes(state.search_type) || value.match(/\r?[\n,","]/)){
        setState({ ...state, [name]: value.split(/\r?[\n,","]/) }); 
      } else {
        setState({ ...state, [name]: value });
        debouncedChangeHandler(value);
      }
    };

    const handleRadioChange = (e) => { // 切換 新增公司模式 | keyword: 關鍵字, multiple: 批次統編｜companyName: 批次公司名
      setState({ ...state, search_type: e.target.value, add_business_number_list: "" });
    }
  
    const handle_move_number_to_ready_area = () => {
      const { add_business_number_list, add_business_number_list_area } = state;
      const update_add_business_number_list_area = [ ...add_business_number_list_area ];
      if (state.search_type === "companyName") {
        dispatch(handleGetBusinessNoByCompanyName(add_business_number_list))
      } else if (state.search_type === "multiple" && Array.isArray(add_business_number_list)){
        add_business_number_list.forEach(i => {
          if(update_add_business_number_list_area.filter(item => item.business_no === i).length === 0 && i.length === 8 && i.match(/^\d+$/) && i){
            update_add_business_number_list_area.push({
              business_no: i,
              name: "",
              id: ""
            });
          }
        })  
      }else{
        if (!!add_business_number_list.match(/[\uff00-\uffff]/g)) {
          setState(state=>({ ...state,  add_business_number_list: "" }))
          return;
        }
        const business_no = add_business_number_list.split(",")[0];
        const name = add_business_number_list.split(",")[1];

        update_add_business_number_list_area.push({
          business_no: business_no,
          name: name,
          id: ""
        });
  
      }

      setState(state=>({ ...state, add_business_number_list_area: update_add_business_number_list_area, add_business_number_list: "" }))
    };

    const handle_close_modal = () => {
      dispatch(handleFetchCustomDashboardList())
      handleClose()
    }


    // React.useEffect(()=>{
    //   if(id && should_api_call){
    //     dispatch(handleFetchCustomDashboardBusinessNumberList(id))
    //   }
    // }, [id]);

    // React.useEffect(()=>{
    //   if(custom_dashboard_business_number_list.length > 0){
    //     const update_add_business_number_list_area = [ ...state.add_business_number_list_area ];

    //     custom_dashboard_business_number_list.forEach(i => (
    //       update_add_business_number_list_area.push({
    //         business_no: i.BusinessNo,
    //         name: i.CompanyName,
    //         id: i.id
    //       })
    //     ))

    //     setState(state=>({ ...state, add_business_number_list_area: update_add_business_number_list_area, add_business_number_list: "" }))
    //   }
    // }, [custom_dashboard_business_number_list])
    const deleteAddItem = (i) => {
      const update_add_business_number_list_area = [...state.add_business_number_list_area];
      const index = state.add_business_number_list_area.findIndex(list => list.business_no === i.business_no);
      update_add_business_number_list_area.splice(index, 1)
      setState(state=>({ ...state, add_business_number_list_area: update_add_business_number_list_area, add_business_number_list: "" }))
    }

    let area_content;
    if(custom_dashboard_business_number_list_loading){
      area_content = (
        <ReadyToAddAreaContainer no_data>
            <Spinner />
        </ReadyToAddAreaContainer>      
      )
    }else{
      if(state.add_business_number_list_area.length === 0){
        area_content = (
          <ReadyToAddAreaContainer no_data>
              <ReadyToAddAreaItemNoDataText>目前無欲加入之統編，您可以點選右上角 + 來新增公司。</ReadyToAddAreaItemNoDataText>
          </ReadyToAddAreaContainer>      
        )
      }else{
        area_content = (
          <ReadyToAddAreaContainer>
          {state.add_business_number_list_area.map((i, index) => <ReadyToAddAreaItem key={i.id ? i.id : index + i.name + i.business_no} is_odd={index%2===0}><ReadyToAddAreaItemText>{i.business_no} {i.name && ` (${i.name.trim()})`}</ReadyToAddAreaItemText><StyledCloseIcon onClick={() => deleteAddItem(i)} /></ReadyToAddAreaItem>)}
          </ReadyToAddAreaContainer>
        )
      }
    }

    return (
      <CustomDashboardListDetailModalWrapper>
        <CustomDashboardListDetailModalCloseContainer>
          <IconButton onClick={handle_close_modal}>
            <Close />
          </IconButton>
        </CustomDashboardListDetailModalCloseContainer>
        <CustomDashboardListDetailModalHeadContainer>
          <CustomDashboardListDetailModalHeadText>
            {(custom_dashboard_list.length > 0 && id) && custom_dashboard_list.filter(i => i.BoardID === id)[0].BoardName}
          </CustomDashboardListDetailModalHeadText>
        </CustomDashboardListDetailModalHeadContainer>
        <CustomDashboardListDetailModalSubHeadContainer>
          <CustomDashboardListDetailModalHeadSubText>
          提供輸入公司名稱關鍵字，平台將輔助您選擇公司，確認公司後可以點選 + 新增公司進儀表板。 或是透過逗號（,）將統編或公司名分隔(注意 : 公司名稱需完整全名，例 : 中國鋼鐵股份有限公司)，可批次新增多筆統編(公司) (注意：統編需為半形數字)。
          </CustomDashboardListDetailModalHeadSubText>
        </CustomDashboardListDetailModalSubHeadContainer>
        <FormControl>
          <RadioGroup
            row
            name="row-radio-buttons-group"
            value={state.search_type}
            onChange={handleRadioChange}
          >
            <FormControlLabel value="keyword" control={<Radio size="small" />} label="關鍵字查詢" />
            <FormControlLabel value="multiple" control={<Radio size="small" />} label="批次統編加入" />
            <FormControlLabel value="companyName" control={<Radio size="small" />} label="批次公司名加入" />
          </RadioGroup>
        </FormControl>
        <CustomDashboardListDetailModalInputContainer>
          <TextField
            label="新增公司"
            variant="outlined"
            size="small"
            value={state.add_business_number_list}
            name={"add_business_number_list"}
            onChange={handleUpdateVal}
            sx={{width: "70%"}}
            multiline={state.search_type === "multiple" || state.search_type === "companyName"}
            rows={4}
          />
          <IconButton
            disabled={!state.add_business_number_list}
            onClick={handle_move_number_to_ready_area}
          >
            <AddIcon />
          </IconButton>
          {state.search_type === "keyword" &&
            <OptionContainer is_with_data={state.search_keyword_loading}>
              {state.search_keyword_loading && <Spinner />}
              {!state.search_keyword_loading &&
                state.search_keyword.length > 0 &&
                state.search_keyword.map((i, index) => (
                  <OptionItem is_odd={index % 2 === 0} key={i.BusinessNo + index}>
                    <OptionItemText onClick={() => handleUpdateBusinessNumberList(`${i.BusinessNo}, ${i.Name}`)}>
                      {i.BusinessNo}, {i.Name}
                    </OptionItemText>
                  </OptionItem>
                ))}
              { state.is_fetched_res && 
               !state.search_keyword_loading &&
                state.search_keyword.length === 0 && (state.add_business_number_list && state.add_business_number_list.length < 10)
                 
                && (
                  <OptionItem is_odd={false}>查無資料</OptionItem>
                )}
            </OptionContainer>
          
          }
        </CustomDashboardListDetailModalInputContainer>
        {area_content}
        <AddButtonContainer>
          <StyledButton
            disabled={state.add_business_number_list_area.filter(i => !i.id).length === 0}
            onClick={handleAddBusinessNumberList}
          >
            新增公司至儀表板
          </StyledButton>
        </AddButtonContainer>
      </CustomDashboardListDetailModalWrapper>
    );
  };
  
  const CustomDashboardListDetailModalWrapper = styled.div`
    width: 45%;
    height: 77vh;
    background-color: ${theme.white};
    border-radius: 20px;
    padding: 20px 30px;
    @media (max-width: 750px) {
      width: 100%;
    }
  `;
  
  const CustomDashboardListDetailModalCloseContainer = styled.div`
    width: calc(100%-60px);
    display: flex;
    justify-content: flex-end;
    align-items: center;
  `;
  
  const CustomDashboardListDetailModalHeadContainer = styled.div`
    width: calc(100%-60px);
    display: flex;
    justify-content: flex-start;
    align-items: center;
  `;
  
  const CustomDashboardListDetailModalHeadText = styled.h2`
    font-size: 2rem;
    font-weight: 600;
    letter-spacing: 2px;
    transform: translateY(-20px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    @media (max-width: 750px) {
      width: 100%;
    }
  `;
  
  const CustomDashboardListDetailModalSubHeadContainer = styled.div`
    width: calc(100%-60px);
    display: flex;
    justify-content: flex-start;
    align-items: center;
  `;
  
  const CustomDashboardListDetailModalHeadSubText = styled.h2`
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 2px;
  transform: translateY(-10px);
  line-height: 20px;
`;

  const CustomDashboardListDetailModalInputContainer = styled.div`
    width: calc(100%-60px);
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    padding: 10px 0px;
    position: relative;
  `;
  
  const OptionContainer = styled.div`
    position: absolute;
    /* max-height: ${(p) => (p.is_with_data ? "200px" : "0px")}; */
    max-height: 200px;
  
    overflow-y: scroll;
    overflow-x: hidden;
    background-color: ${theme.white};
    width: 400px;
    top: 52px;
    box-shadow: 0px 0px 20px -5px rgba(0, 0, 0, 0.2);
    transition: 0.1s max-height ease-in;
    @media (max-width: 750px) {
      width: 100%;
      max-height: 150px;
    }
  `;
  
  const OptionItem = styled.div`
    height: 50px;
    background-color: ${(p) => (p.is_odd ? theme.grayBorderColor : theme.white)};
    width: 400px;
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0px 20px;
    transition: 0.3s;
    @media (max-width: 750px) {
      width: 100%;
    }
    &:hover {
      opacity: 0.9;
      transition: 0.3s;
    }
  `;
  
  const OptionItemText = styled.p`
    font-size: 1rem;
    letter-spacing: 2px;
    @media (max-width: 750px) {
      font-size: 0.875rem;
      line-height: 1.2;
    }
  `;

const StyledButton = styled(Button)`
background-color: ${(p) =>
  p.disabled ? theme.grayBorderColor : theme.linkBlue} !important;
color: ${theme.white} !important;

&:hover {
  background-color: ${(p) =>
    p.disabled ? theme.grayBorderColor : theme.linkBlue} !important;
}
`;

const ReadyToAddAreaContainer = styled.div`
  width: 100%;
  height: calc(100% - 420px);
  border: 0.5px solid ${theme.disabledGray};
  display: flex;
  flex-direction: column;
  justify-content: ${ p => p.no_data ? "center" : "flex-start"};
  align-items: ${ p => p.no_data ? "center" : "flex-start"};
  padding-bottom: 20px;
  overflow-y: scroll;
`;

const ReadyToAddAreaItem = styled.div`
  width: 100%;
  padding: 10px;
  background-color: ${p => p.is_odd ? theme.lightGrayBorderColor : theme.white};
  transition: 0.2s;
  display: flex;
  justify-content: space-between;


  &:hover{
    transition: 0.2s;
    opacity: 0.6;
  }
`;

const ReadyToAddAreaItemText = styled.p`
  font-size: 1rem;
  font-weight: 500;
  text-decoration: underline;
  cursor: pointer;
  @media (max-width: 750px) {
    font-size: 0.875rem;
    line-height: 1.2;
  }
`;

const ReadyToAddAreaItemNoDataText = styled.p`
  font-size: 1rem;
  font-weight: 400;
  line-height: 25px;
  color: ${theme.grayBorderColor};
`;

const AddButtonContainer = styled.div`
  width: 100%;
  padding: 20px 0px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  @media (max-width: 750px) {
    padding: 16px 0px 16px 0px;
  }
`;

const StyledCloseIcon = styled(Close)`
  width: 20px !important;
  height: 20px !important;
  cursor: pointer;
`;

export default CustomUpdateDashboardListModal;