import React from "react";
import { useSelector } from "react-redux";
import Spinner from "../Spinner/Spinner";
import factory from "../../assests/factory.svg";
import employee from "../../assests/empoyee.svg";
import power from "../../assests/power.svg";
import water from "../../assests/water.svg";
import research from "../../assests/research.svg";
import income from "../../assests/income.svg";
import pay from "../../assests/pay.svg";
import money from "../../assets/images/value-chain.png";
import ipo from "../../assets/images/ipo.png";
import cityImg from "../../assets/images/city.png";
import factoryImg from "../../assets/images/factory.png";
import first from "../../assets/images/first.png";
import second from "../../assets/images/second.png";
import third from "../../assets/images/third.png";
import Tooltip from "@material-ui/core/Tooltip";
import {
  createTheme,
  MuiThemeProvider,
} from "@material-ui/core/styles";

import { thousandDivider } from "../../utility/common";
import { AreaAndCity } from "../../constants/parks";
import "./styles.scss";

export default (props) => {
  const rankImgList = [first, second, third]
  const { innerWidth } = useSelector((state) => state.app);
  const tooltipTheme = createTheme({
    overrides: {
      MuiTooltip: {
        tooltip: {
          color: "#000",
          backgroundColor: "#FFF",
          border: "2px solid #000",
          padding: "0px",
          margin: "0px",
          fontSize: "20px",
        }
      }
    }
  });
  React.useEffect(() => {
    const counters = document.querySelectorAll('.valueAnimation');
    const speed = 200;

    counters.forEach(counter => {
      const animate = () => {
        const value = + counter.getAttribute('akhi');
        const data = + counter.innerText.replaceAll(",", "");
        if(value){
          const time = value / speed;
          if (data < value) {
            counter.innerText = thousandDivider(Math.ceil(data + time));
            // counter.innerText = Math.ceil(data + time);
            setTimeout(animate, 1);
          } else {
            counter.innerText = thousandDivider(value);
            // counter.innerText = value;
          }
        }
      }

      animate();
    });
  }, []);
  const { data, briefAndRanking, parkId, isArea, isIndustry, isTech, isTaxInfo } = props;
  if (isIndustry && !isTech && data) {
    return (
      <div className="main-industry-container" isIndustry>
        <div className="main-industry-container-middle">
          <div
            className="main-industry-container-inner"
            style={{ borderRight: "1px solid #b6aaaa" }}
          >
            <img src={pay} alt="" loading={"lazy"} />
            <div>
              <p className="head">產值(千)元</p>
              <p className="content">{thousandDivider(data.product_val)}</p>
            </div>
          </div>
          <div className="main-industry-container-inner">
            <img src={factory} alt="" loading={"lazy"} />
            <div>
              <p className="head">工廠家數(家)</p>
              <p className="content">{thousandDivider(data.factory_count)}</p>
            </div>
          </div>
        </div>
        <div className="main-industry-container-bottom">
          <div
            className="main-industry-container-inner"
            style={{ borderRight: "1px solid #b6aaaa" }}
          >
            <img src={employee} alt="" loading={"lazy"} />
            <div>
              <p className="head">員工數(人)</p>
              <p className="content">{thousandDivider(data.emp_count)}</p>
            </div>
          </div>
          <div className="main-industry-container-inner">
            <img src={pay} alt="" loading={"lazy"} />
            <div>
              <p className="head">投資金額(千元)</p>
              <p className="content">{thousandDivider(data.investment_amt)}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (isTaxInfo) {
    if (Object.keys(data).length) {
      const { CompanyCnts, CompanyCntsByCity, StockCompanyCnts, CompanyCntsByIndustryFour } = data;
      if (innerWidth >= 1024) {
        return (
          <div className="taxinfo-container">
            {((CompanyCnts > 0) || (CompanyCntsByCity.length > 0)) &&
              <div className="taxinfo-container-top">
                <div
                  className="taxinfo-container-inner"
                  style={{ borderRight: "1px solid #b6aaaa" }}
                >
                  <img src={factory} alt="factory" loading={"lazy"} />
                  <div>
                    <p className="head">公司數(家)</p>
                    <div class="contentAnimation">
                      <div class="valueAnimation" akhi={CompanyCnts}>0</div>
                    </div>
                  </div>
                </div>
                {(CompanyCntsByCity.length > 0) &&
                  <div style={{display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr"}} className="taxinfo-container-inner-list">
                    <div className="map-content-item" style={{ flexDirection: 'column', background: 'none' }}>
                      <img src={cityImg} alt="cityImg" loading={"lazy"} />
                      <div>
                        <p className="head">產業聚落</p>
                      </div>
                    </div>
                    {CompanyCntsByCity.slice(0, 3).map((item, index) => {
                      return(
                      <div className="map-content-item" key={item.CityName}>
                        <img src={rankImgList[index]} alt="first" loading={"lazy"} />
                        <div className="map-content-item-header">
                          <div style={{ flexDirection: 'column' }}>
                            <div>{item.CityName}</div>
                            <div class="contentAnimation">
                              <div class="valueAnimation" akhi={item.Cnts}>0</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      )
                    })}
                  </div>
                }
              </div>
            }
            {((StockCompanyCnts > 0) || (CompanyCntsByIndustryFour.length > 0)) &&
              <div className="taxinfo-container-bottom">
                <div
                  className="taxinfo-container-inner"
                  style={{ borderRight: "1px solid #b6aaaa" }}
                >
                  <img src={ipo} alt="ipo" loading={"lazy"} />
                  <div>
                    <p className="head">上市櫃公司家數</p>
                    <p className="content">
                      <div class="contentAnimation">
                        <div class="valueAnimation" akhi={StockCompanyCnts}>0</div>
                      </div>
                    </p>
                  </div>
                </div>
                {(CompanyCntsByIndustryFour.length > 0) &&
                  <div style={{display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr"}} className="taxinfo-container-inner-list">
                    <div className="map-content-item" style={{ flexDirection: 'column', background: 'none' }}>
                      <img src={factoryImg} alt="factoryImg" loading={"lazy"} />
                      <div>
                        <p className="head">產業家數</p>
                      </div>
                    </div>
                      {CompanyCntsByIndustryFour.slice(0, 3).map((item, index) => {
                        return(
                          <MuiThemeProvider theme={tooltipTheme}>
                            <Tooltip
                              placement="top-start"
                              title={item.IndustryCode + " " + item.IndustryName}
                            >
                              <div className="map-content-item" key={item.IndustryName}>
                                <img src={rankImgList[index]} alt="first" loading={"lazy"} />
                                <div className="map-content-item-header">
                                  <div style={{ flexDirection: 'column' }}>
                                    <div>{item.IndustryCode + " " + item.IndustryName.substr(0, 3) + "..."}</div>
                                    <div class="contentAnimation">
                                      <div class="valueAnimation" akhi={item.Cnts}>0</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Tooltip>
                          </MuiThemeProvider>
                        )
                      })}
                  </div>
                }
              </div>
            }
          </div>
        );
      }
      else {
        return (
          <>
            {(CompanyCnts > 0) &&
              <div className="mobileRowContainer">
                <div className="mobileHeadItem">
                  <img src={factory} alt="factory" loading={"lazy"} />
                  <p>公司數(家)</p>
                </div>
                <div>
                  <div class="valueAnimation" akhi={CompanyCnts}>0</div>
                </div>
              </div>
            }
            {(StockCompanyCnts > 0) &&
              <div className="mobileRowContainer">
                <div className="mobileHeadItem">
                  <img src={ipo} alt="ipo" loading={"lazy"} />
                  <p>上市櫃公司家數</p>
                </div>
                <div>
                  <div class="valueAnimation" akhi={StockCompanyCnts}>0</div>
                </div>
              </div>
            }
            {(CompanyCntsByCity.length > 0) &&
              <div style={{display: "grid", gridTemplateColumns: window.innerWidth >= 1024 ? "1fr 1fr 1fr 1fr" : "", gridTemplateRow: window.innerWidth < 1024 ? "1fr 1fr 1fr 1fr" : ""}} className="mobileRowContainer">
                <div className="mobileHeadItem">
                  <img src={cityImg} alt="cityImg" loading={"lazy"} />
                  <div>
                    <p>產業聚落</p>
                  </div>
                </div>
                {CompanyCntsByCity.slice(0, 3).map((item, index) => {
                  return(
                    <div key={item.CityName} className="mobileListItem">
                      <img src={rankImgList[index]} alt="first" loading={"lazy"} />
                      <div>
                        <div style={{ flexDirection: 'column' }}>
                          <div>{item.CityName}</div>
                          <div>
                            <div class="valueAnimation" akhi={item.Cnts}>0</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
            }
            {(CompanyCntsByIndustryFour.length > 0) &&
              <div style={{display: "grid", gridTemplateColumns: window.innerWidth >= 1024 ? "1fr 1fr 1fr 1fr" : "", gridTemplateRow: window.innerWidth < 1024 ? "1fr 1fr 1fr 1fr" : ""}}  className="mobileRowContainer">
                <div className="mobileHeadItem">
                  <img src={factoryImg} alt="factoryImg" loading={"lazy"} />
                  <div>
                    <p>產業家數</p>
                  </div>
                </div>
                {CompanyCntsByIndustryFour.slice(0, 3).map((item, index) => {
                  return(
                    <MuiThemeProvider theme={tooltipTheme}>
                      <Tooltip
                        placement="top-start"
                        title={item.IndustryCode + " " + item.IndustryName}
                      >
                        <div key={item.IndustryName} className="mobileListItem">
                          <img src={rankImgList[index]} alt="first" loading={"lazy"}/>
                          <div>
                            <div style={{ flexDirection: 'column' }}>
                              <div>{item.IndustryCode + " " + item.IndustryName.substr(0, 3) + "..."}</div>
                              <div>
                                <div class="valueAnimation" akhi={item.Cnts}>0</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Tooltip>
                    </MuiThemeProvider>
                  )
                })}
              </div>
            }
          </>
        )
      }
    } else {
      return <Spinner />;
    }
  }

  if (isTech && data) {
    return (
      <div className="main-industry-container" isIndustry>
        <div className="main-industry-container-middle">
          <div
            className="main-industry-container-inner"
            style={{ borderRight: "1px solid #b6aaaa" }}
          >
            <img src={factory} alt="" loading={"lazy"} />
            <div>
              <p className="head">研究發展工廠家數(家)</p>
              <p className="content">
                {thousandDivider(data.research_factory_count)}
              </p>
            </div>
          </div>
          <div className="main-industry-container-inner">
            <img src={research} alt="" loading={"lazy"} />
            <div>
              <p className="head">研究發展經費(千元)</p>
              <p className="content">{thousandDivider(data.research_amt)}</p>
            </div>
          </div>
        </div>
        <div className="main-industry-container-bottom">
          <div
            className="main-industry-container-inner"
            style={{ borderRight: "1px solid #b6aaaa" }}
          >
            <img src={pay} alt="" loading={"lazy"} />
            <div>
              <p className="head">營業收入(千元)</p>
              <p className="content">{thousandDivider(data.revenue_income)}</p>
            </div>
          </div>
          <div className="main-industry-container-inner">
            <img src={income} alt="" loading={"lazy"} />
            <div>
              <p className="head">營業支出(千元)</p>
              <p className="content">{thousandDivider(data.revenue_pay)}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (isArea) {
    if (data.Facility && data) {
      return (
        <div className="main-index-container">
          <div className="main-index-container-top">
            <img src={pay} alt="" loading={"lazy"} />
            <p className="head">產值(億)元</p>
            <p className="content">{thousandDivider(data.ProductVal)}</p>
            {!props.factory &&
              !props.research &&
              briefAndRanking &&
              briefAndRanking.briefAndRankingList &&
              window.innerWidth > 1024 && (
                <p
                  style={{
                    fontSize: "0.9rem",
                    color: "#757575",
                    paddingTop: "5px",
                  }}
                >
                  {AreaAndCity[parkId]}排名:
                  {briefAndRanking.briefAndRankingList.ProductValRankArea} /
                  全區:
                  {briefAndRanking.briefAndRankingList.ProductValRank}
                </p>
              )}
            {!props.factory &&
              !props.research &&
              briefAndRanking &&
              window.innerWidth < 1024 && (
                <>
                  <p
                    style={{
                      fontSize: "0.9rem",
                      color: "#757575",
                      paddingTop: "5px",
                    }}
                  >
                    {AreaAndCity[parkId]}排名:
                    {briefAndRanking.briefAndRankingList.ProductValRankArea}
                  </p>
                  <p
                    style={{
                      fontSize: "0.9rem",
                      color: "#757575",
                      paddingTop: "5px",
                    }}
                  >
                    全區:{briefAndRanking.briefAndRankingList.ProductValRank}
                  </p>
                </>
              )}
          </div>
          <div className="main-index-container-middle">
            <div
              className="main-index-container-inner"
              style={{ borderRight: "1px solid #b6aaaa" }}
            >
              <img src={factory} alt="" loading={"lazy"} />
              <div>
                <p className="head">廠商數(家)</p>
                <p className="content">{thousandDivider(data.FactoryNum)}</p>
                {!props.factory &&
                  !props.research &&
                  briefAndRanking &&
                  window.innerWidth > 1024 && (
                    <p
                      style={{
                        fontSize: "0.9rem",
                        color: "#757575",
                        paddingTop: "5px",
                      }}
                    >
                      {AreaAndCity[parkId]}排名:
                      {briefAndRanking.briefAndRankingList.FactoryNumRankArea} /
                      全區:{briefAndRanking.briefAndRankingList.FactoryNumRank}
                    </p>
                  )}
                {!props.factory &&
                  !props.research &&
                  briefAndRanking &&
                  window.innerWidth < 1024 && (
                    <>
                      <p
                        style={{
                          fontSize: "0.9rem",
                          color: "#757575",
                          paddingTop: "5px",
                        }}
                      >
                        {AreaAndCity[parkId]}排名:
                        {briefAndRanking.briefAndRankingList.FactoryNumRankArea}
                      </p>
                      <p
                        style={{
                          fontSize: "0.9rem",
                          color: "#757575",
                          paddingTop: "5px",
                        }}
                      >
                        全區:
                        {briefAndRanking.briefAndRankingList.FactoryNumRank}
                      </p>
                    </>
                  )}
              </div>
            </div>
            <div className="main-index-container-inner">
              <img src={employee} alt="" loading={"lazy"} />
              <div>
                <p className="head">員工數(人)</p>
                <p className="content">{thousandDivider(data.EmpCount)}</p>
                {!props.factory &&
                  !props.research &&
                  briefAndRanking &&
                  window.innerWidth > 1024 && (
                    <p
                      style={{
                        fontSize: "0.9rem",
                        color: "#757575",
                        paddingTop: "5px",
                      }}
                    >
                      {AreaAndCity[parkId]}排名:
                      {briefAndRanking.briefAndRankingList.EmployeNumRankArea} /
                      全區:{briefAndRanking.briefAndRankingList.EmployeNumRank}
                    </p>
                  )}
                {!props.factory &&
                  !props.research &&
                  briefAndRanking &&
                  window.innerWidth < 1024 && (
                    <>
                      <p
                        style={{
                          fontSize: "0.9rem",
                          color: "#757575",
                          paddingTop: "5px",
                        }}
                      >
                        {AreaAndCity[parkId]}排名:
                        {briefAndRanking.briefAndRankingList.EmployeNumRankArea}
                      </p>
                      <p
                        style={{
                          fontSize: "0.9rem",
                          color: "#757575",
                          paddingTop: "5px",
                        }}
                      >
                        全區:
                        {briefAndRanking.briefAndRankingList.EmployeNumRank}
                      </p>
                    </>
                  )}
              </div>
            </div>
          </div>
          <div className="main-index-container-bottom">
            <div
              className="main-index-container-inner"
              style={{ borderRight: "1px solid #b6aaaa" }}
            >
              <img src={water} alt="" loading={"lazy"} />
              <div>
                <p className="head">自來水供應量</p>
                <p className="head">(公噸/日)</p>
                <p className="content">
                  {thousandDivider(data.Facility.WaterSupply)}
                </p>
                {!props.factory &&
                  !props.research &&
                  briefAndRanking &&
                  window.innerWidth > 1024 && (
                    <p
                      style={{
                        fontSize: "0.9rem",
                        color: "#757575",
                        paddingTop: "5px",
                      }}
                    >
                      {AreaAndCity[parkId]}排名:
                      {briefAndRanking.briefAndRankingList.WaterSupplyRankArea}/
                      全區:{briefAndRanking.briefAndRankingList.WaterSupplyRank}
                    </p>
                  )}
                {!props.factory &&
                  !props.research &&
                  briefAndRanking &&
                  window.innerWidth < 1024 && (
                    <>
                      <p
                        style={{
                          fontSize: "0.9rem",
                          color: "#757575",
                          paddingTop: "5px",
                        }}
                      >
                        {AreaAndCity[parkId]}排名:
                        {
                          briefAndRanking.briefAndRankingList
                            .WaterSupplyRankArea
                        }
                      </p>
                      <p
                        style={{
                          fontSize: "0.9rem",
                          color: "#757575",
                          paddingTop: "5px",
                        }}
                      >
                        全區:
                        {briefAndRanking.briefAndRankingList.WaterSupplyRank}
                      </p>
                    </>
                  )}
              </div>
            </div>
            <div className="main-index-container-inner">
              <img src={power} alt="" loading={"lazy"} />
              <div>
                <p className="head">電力供應量</p>
                <p className="head">(千瓦/日)</p>
                <p className="content">
                  {thousandDivider(data.Facility.PowerSupply)}
                </p>
                {!props.factory &&
                  !props.research &&
                  briefAndRanking &&
                  window.innerWidth > 1024 && (
                    <p
                      style={{
                        fontSize: "0.9rem",
                        color: "#757575",
                        paddingTop: "5px",
                      }}
                    >
                      {AreaAndCity[parkId]}排名:
                      {
                        briefAndRanking.briefAndRankingList.PowerSupplyRankArea
                      }{" "}
                      / 全區:
                      {briefAndRanking.briefAndRankingList.PowerSupplyRank}
                    </p>
                  )}
                {!props.factory &&
                  !props.research &&
                  briefAndRanking &&
                  window.innerWidth < 1024 && (
                    <>
                      <p
                        style={{
                          fontSize: "0.9rem",
                          color: "#757575",
                          paddingTop: "5px",
                        }}
                      >
                        {AreaAndCity[parkId]}排名:
                        {
                          briefAndRanking.briefAndRankingList
                            .PowerSupplyRankArea
                        }
                      </p>
                      <p
                        style={{
                          fontSize: "0.9rem",
                          color: "#757575",
                          paddingTop: "5px",
                        }}
                      >
                        全區:
                        {briefAndRanking.briefAndRankingList.PowerSupplyRank}
                      </p>
                    </>
                  )}
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return <Spinner />;
    }
  } else {
    if (
      data.Facility &&
      data &&
      briefAndRanking &&
      briefAndRanking.briefAndRankingList
    ) {
      return (
        <div className="main-index-container">
          <div className="main-index-container-top">
            <img src={pay} alt="" loading={"lazy"} />
            <p className="head">產值(億)元</p>
            <p className="content">{thousandDivider(data.ProductVal)}</p>
            {!props.factory &&
              !props.research &&
              briefAndRanking &&
              briefAndRanking.briefAndRankingList &&
              window.innerWidth > 1024 && (
                <p
                  style={{
                    fontSize: "0.9rem",
                    color: "#757575",
                    paddingTop: "5px",
                  }}
                >
                  {AreaAndCity[parkId]}排名:
                  {briefAndRanking.briefAndRankingList.ProductValRankArea} /
                  全區:
                  {briefAndRanking.briefAndRankingList.ProductValRank}
                </p>
              )}
            {!props.factory &&
              !props.research &&
              briefAndRanking &&
              window.innerWidth < 1024 && (
                <>
                  <p
                    style={{
                      fontSize: "0.9rem",
                      color: "#757575",
                      paddingTop: "5px",
                    }}
                  >
                    {AreaAndCity[parkId]}排名:
                    {briefAndRanking.briefAndRankingList.ProductValRankArea}
                  </p>
                  <p
                    style={{
                      fontSize: "0.9rem",
                      color: "#757575",
                      paddingTop: "5px",
                    }}
                  >
                    全區:{briefAndRanking.briefAndRankingList.ProductValRank}
                  </p>
                </>
              )}
          </div>
          <div className="main-index-container-middle">
            <div
              className="main-index-container-inner"
              style={{ borderRight: "1px solid #b6aaaa" }}
            >
              <img src={factory} alt="" loading={"lazy"} />
              <div>
                <p className="head">廠商數(家)</p>
                <p className="content">{thousandDivider(data.FactoryNum)}</p>
                {!props.factory &&
                  !props.research &&
                  briefAndRanking &&
                  window.innerWidth > 1024 && (
                    <p
                      style={{
                        fontSize: "0.9rem",
                        color: "#757575",
                        paddingTop: "5px",
                      }}
                    >
                      {AreaAndCity[parkId]}排名:
                      {briefAndRanking.briefAndRankingList.FactoryNumRankArea} /
                      全區:{briefAndRanking.briefAndRankingList.FactoryNumRank}
                    </p>
                  )}
                {!props.factory &&
                  !props.research &&
                  briefAndRanking &&
                  window.innerWidth < 1024 && (
                    <>
                      <p
                        style={{
                          fontSize: "0.9rem",
                          color: "#757575",
                          paddingTop: "5px",
                        }}
                      >
                        {AreaAndCity[parkId]}排名:
                        {briefAndRanking.briefAndRankingList.FactoryNumRankArea}
                      </p>
                      <p
                        style={{
                          fontSize: "0.9rem",
                          color: "#757575",
                          paddingTop: "5px",
                        }}
                      >
                        全區:
                        {briefAndRanking.briefAndRankingList.FactoryNumRank}
                      </p>
                    </>
                  )}
              </div>
            </div>
            <div className="main-index-container-inner">
              <img src={employee} alt="" loading={"lazy"} />
              <div>
                <p className="head">員工數(人)</p>
                <p className="content">{thousandDivider(data.EmpCount)}</p>
                {!props.factory &&
                  !props.research &&
                  briefAndRanking &&
                  window.innerWidth > 1024 && (
                    <p
                      style={{
                        fontSize: "0.9rem",
                        color: "#757575",
                        paddingTop: "5px",
                      }}
                    >
                      {AreaAndCity[parkId]}排名:
                      {briefAndRanking.briefAndRankingList.EmployeNumRankArea} /
                      全區:{briefAndRanking.briefAndRankingList.EmployeNumRank}
                    </p>
                  )}
                {!props.factory &&
                  !props.research &&
                  briefAndRanking &&
                  window.innerWidth < 1024 && (
                    <>
                      <p
                        style={{
                          fontSize: "0.9rem",
                          color: "#757575",
                          paddingTop: "5px",
                        }}
                      >
                        {AreaAndCity[parkId]}排名:
                        {briefAndRanking.briefAndRankingList.EmployeNumRankArea}
                      </p>
                      <p
                        style={{
                          fontSize: "0.9rem",
                          color: "#757575",
                          paddingTop: "5px",
                        }}
                      >
                        全區:
                        {briefAndRanking.briefAndRankingList.EmployeNumRank}
                      </p>
                    </>
                  )}
              </div>
            </div>
          </div>
          <div className="main-index-container-bottom">
            <div
              className="main-index-container-inner"
              style={{ borderRight: "1px solid #b6aaaa" }}
            >
              <img src={water} alt="" loading={"lazy"} />
              <div>
                <p className="head">自來水供應量</p>
                <p className="head">(公噸/日)</p>
                <p className="content">
                  {thousandDivider(data.Facility.WaterSupply)}
                </p>
                {!props.factory &&
                  !props.research &&
                  briefAndRanking &&
                  window.innerWidth > 1024 && (
                    <p
                      style={{
                        fontSize: "0.9rem",
                        color: "#757575",
                        paddingTop: "5px",
                      }}
                    >
                      {AreaAndCity[parkId]}排名:
                      {briefAndRanking.briefAndRankingList.WaterSupplyRankArea}/
                      全區:{briefAndRanking.briefAndRankingList.WaterSupplyRank}
                    </p>
                  )}
                {!props.factory &&
                  !props.research &&
                  briefAndRanking &&
                  window.innerWidth < 1024 && (
                    <>
                      <p
                        style={{
                          fontSize: "0.9rem",
                          color: "#757575",
                          paddingTop: "5px",
                        }}
                      >
                        {AreaAndCity[parkId]}排名:
                        {
                          briefAndRanking.briefAndRankingList
                            .WaterSupplyRankArea
                        }
                      </p>
                      <p
                        style={{
                          fontSize: "0.9rem",
                          color: "#757575",
                          paddingTop: "5px",
                        }}
                      >
                        全區:
                        {briefAndRanking.briefAndRankingList.WaterSupplyRank}
                      </p>
                    </>
                  )}
              </div>
            </div>
            <div className="main-index-container-inner">
              <img src={power} alt="" loading={"lazy"} />
              <div>
                <p className="head">電力供應量</p>
                <p className="head">(千瓦/日)</p>
                <p className="content">
                  {thousandDivider(data.Facility.PowerSupply)}
                </p>
                {!props.factory &&
                  !props.research &&
                  briefAndRanking &&
                  window.innerWidth > 1024 && (
                    <p
                      style={{
                        fontSize: "0.9rem",
                        color: "#757575",
                        paddingTop: "5px",
                      }}
                    >
                      {AreaAndCity[parkId]}排名:
                      {
                        briefAndRanking.briefAndRankingList.PowerSupplyRankArea
                      }{" "}
                      / 全區:
                      {briefAndRanking.briefAndRankingList.PowerSupplyRank}
                    </p>
                  )}
                {!props.factory &&
                  !props.research &&
                  briefAndRanking &&
                  window.innerWidth < 1024 && (
                    <>
                      <p
                        style={{
                          fontSize: "0.9rem",
                          color: "#757575",
                          paddingTop: "5px",
                        }}
                      >
                        {AreaAndCity[parkId]}排名:
                        {
                          briefAndRanking.briefAndRankingList
                            .PowerSupplyRankArea
                        }
                      </p>
                      <p
                        style={{
                          fontSize: "0.9rem",
                          color: "#757575",
                          paddingTop: "5px",
                        }}
                      >
                        全區:
                        {briefAndRanking.briefAndRankingList.PowerSupplyRank}
                      </p>
                    </>
                  )}
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return <Spinner />;
    }
  }
};
