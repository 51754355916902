const base = `CITY_DASHBOARD_`;

export const START_GET_TAIWAN_INVESTMENT_DATA = `${base}START_GET_TAIWAN_INVESTMENT_DATA`;
export const GET_TAIWAN_INVESTMENT_DATA_SUCCESS = `${base}GET_TAIWAN_INVESTMENT_DATA_SUCCESS`;
export const GET_TAIWAN_INVESTMENT_DATA_FAIL = `${base}GET_TAIWAN_INVESTMENT_DATA_FAIL`;

export const START_GET_FACTORY_AREA_RENT_DATA = `${base}START_GET_FACTORY_AREA_RENT_DATA`;
export const GET_FACTORY_AREA_RENT_DATA_SUCCESS = `${base}GET_FACTORY_AREA_RENT_DATA_SUCCESS`;
export const GET_FACTORY_AREA_RENT_DATA_FAIL = `${base}GET_FACTORY_AREA_RENT_DATA_FAIL`;

export const START_GET_START_UP_DATA = `${base}START_GET_START_UP_DATA`;
export const GET_START_UP_DATA_SUCCESS = `${base}GET_START_UP_DATA_SUCCESS`;
export const GET_START_UP_DATA_FAIL = `${base}GET_START_UP_DATA_FAIL`;

export const START_GET_TAX_RANK = `${base}START_GET_TAX_RANK`;
export const GET_TAX_RANK_SUCCESS = `${base}GET_TAX_RANK_SUCCESS`;
export const GET_TAX_RANK_FAIL = `${base}GET_TAX_RANK_FAIL`;

export const START_GET_TAX_RANK_LIST = `${base}START_GET_TAX_RANK_LIST`;
export const GET_TAX_RANK_LIST_SUCCESS = `${base}GET_TAX_RANK_LIST_SUCCESS`;
export const GET_TAX_RANK_LIST_FAIL = `${base}GET_TAX_RANK_LIST_FAIL`;