export const white = "#fff";
export const black = "#000";
export const primaryColor = "#25758A";
export const linkBlue = "#006ab4";
export const linkHoveredBlue = "#1c21ca";
export const disabledGray = "#ececec";
export const secondaryBlack = "#444444";
export const primaryFont = "Microsoft JhengHei";
export const grayBorderColor = "rgb(182, 170, 170)";
export const lightGrayBorderColor = "rgb(153 191 194 / 40%)";
// export const lightGrayBorderColor = "rgb(194 153 153 / 40%)";
export const background_color = "#f7f3f3";
export const lightPrimaryColor = "#25758A";
export const BlueV2 = "#11304A";
export const darkBlueV2 = "#3E5A75";
