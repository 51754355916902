import React from "react";
import { Card } from "react-bootstrap";
import { useSelector } from "react-redux";
import CustomizedCard from "../../../components/CustomizedCard/CustomizedCardV3";
import FactoryNewsInfo from "../../../components/CustomizedTable/FactoryInfo/FactoryNewsInfo";
import Modal from "../../../components/CustomizedModal/CustomizedModal";
import Wordcloud from "../../../components/WordCloud/WordCloud";
import CityDashboradModal from "../../../components/AreaDashboradModal/CityDashboradModal";

const FactoryNews = () => {
  const { newsInfo, newsInfoLoading, newsTags, newsTagsLoading } = useSelector(
    (state) => state.factory
  );

  const [state, setState] = React.useState({
    title: "",
    modalType: "",
    isModalOpen: false,
    persistModalOpen: false,
  });

  const handleModalOpen = (modalType, referenceCode, title) => {
    if (modalType) {
      setState((state) => ({
        ...state,
        title,
        modalType,
        isModalOpen: true,
      }));
    } else {
      return;
    }
  };

  const handleClose = React.useCallback(() => {
    if (!state.persistModalOpen) {
      setState((state) => ({
        ...state,
        isModalOpen: !state.isModalOpen,
        modalType: "",
      }));
    }
  }, [state.persistModalOpen]);

  const persistModalOpenHandler = (value) => {
    setState((state) => ({ ...state, persistModalOpen: value }));
  };

  return (
    <React.Fragment>
      <Modal isOpen={state.isModalOpen} handleClose={handleClose}>
        <CityDashboradModal
          modalType={state.modalType}
          handleClose={handleClose}
          title={state.title}
          persistModalOpenHandler={persistModalOpenHandler}
        />
      </Modal>
      <Card>
        <CustomizedCard
          title="近兩年相關新聞"
          referenceType={"factoryInfoNewsItems"}
          loading={newsInfoLoading}
        >
          <FactoryNewsInfo
            data={newsInfo}
            headerList={["新聞標題", "日期", "來源"]}
          />
        </CustomizedCard>
      </Card>
      <Card>
        <CustomizedCard
          title="新聞標籤雲"
          referenceType={"factoryInfoNewsTags"}
          loading={newsTagsLoading}
        >
          <Wordcloud
            tag={newsTags}
            eventHandler={(val, title) =>
              handleModalOpen("sectionNewsTag", val, title)
            }
          />
        </CustomizedCard>
      </Card>
    </React.Fragment>
  );
};

export default React.memo(FactoryNews);
