import * as actionTypes from "../actionTypes/custom_dashboard";

import {
  createDashboard,
  copyDashboard,
  fetchDashboardList,
  deleteDashboardBusinessNumberList,
  fetchDashboardBusinessNumberList,
  addDashboardBusinessNumberList,
  checkDashboardBusinessNumberList,
  updateDashboardName,
  updateDashboardBusinessNumberList,
  getBusinessNoByCompanyName,
  deleteCompanyDashboardBusinessNumberList,
} from "../../utility/custom_dashboard_api";


export const handleClearErrMsg = () => {
  return {
    type: actionTypes.CLEAR_ERROR_MESSAGE,
  };
}

const startGetCustomDashboardList = () => {
  return {
    type: actionTypes.START_GET_DASHBOARD_LIST,
  };
};

const getCustomDashboardListSuccess = (data) => {
  return {
    type: actionTypes.GET_DASHBOARD_LIST_SUCCESS,
    data: data,
  };
};

const getCustomDashboardListFail = (err) => {
  return {
    type: actionTypes.GET_DASHBOARD_LIST_FAIL,
    err: err,
  };
};

export const handleFetchCustomDashboardList = (data) => {
  return (dispatch) => {
    dispatch(startGetCustomDashboardList());
    fetchDashboardList(data)
      .then((res) => {
        if (res.data.status === "success") {
          dispatch(getCustomDashboardListSuccess(res.data.data));
        } else {
          dispatch(getCustomDashboardListFail("取得自訂關注廠商儀表板列表發生問題。"));
        }
      })
      .catch((err) => {
        dispatch(getCustomDashboardListFail(err));
      });
  };
};

const startCreateCustomDashboardList = () => {
  return {
    type: actionTypes.START_CREATE_DASHBOARD_LIST,
  };
};

const createCustomDashboardListSuccess = (data) => {
  return {
    type: actionTypes.CREATE_DASHBOARD_LIST_SUCCESS,
    data: data,
  };
};

const createCustomDashboardListFail = (err) => {
  return {
    type: actionTypes.CREATE_DASHBOARD_LIST_FAIL,
    err: err,
  };
};

export const handleCreateCustomDashboardList = (data) => {
  return (dispatch) => {
    dispatch(startCreateCustomDashboardList());
    createDashboard(data)
      .then(async (res) => {
        if (res.data.status === "success") {
          dispatch(createCustomDashboardListSuccess());
          await handleFetchCustomDashboardList();
        } else {
          dispatch(
            createCustomDashboardListFail(
              "取得縣市廠商產業別二碼家數佔比時發生問題。"
            )
          );
        }
      })
      .catch((err) => {
        dispatch(createCustomDashboardListFail(err));
      });
  };
};

const startDeleteCustomDashboardList = () => {
  return {
    type: actionTypes.START_DELETE_DASHBOARD_LIST,
  };
};

const deleteCustomDashboardListSuccess = (data) => {
  return {
    type: actionTypes.DELETE_DASHBOARD_LIST_SUCCESS,
    data: data,
  };
};

const deleteCustomDashboardListFail = (err) => {
  return {
    type: actionTypes.DELETE_DASHBOARD_LIST_FAIL,
    err: err,
  };
};

export const handleDeleteCustomDashboardList = (board_id) => {
  return (dispatch) => {
    dispatch(startDeleteCustomDashboardList());
    deleteDashboardBusinessNumberList(board_id)
      .then(async (res) => {
        if (res.data.status === "success") {
          dispatch(deleteCustomDashboardListSuccess());
        } else {
          dispatch(
            deleteCustomDashboardListFail(
              "取得縣市廠商產業別二碼家數佔比時發生問題。"
            )
          );
        }
      })
      .catch((err) => {
        dispatch(deleteCustomDashboardListFail(err));
      });
  };
};

const startFetchCustomDashboardBusinessNumberList = () => {
  return {
    type: actionTypes.STAR_GET_DASHBOARD_NUMBER_LIST,
  };
};

const fetchCustomDashboardBusinessNumberListSuccess = (data) => {
  return {
    type: actionTypes.GET_NUMBER_LIST_SUCCESS,
    data: data,
  };
};

const fetchCustomDashboardBusinessNumberListFail = (err) => {
  return {
    type: actionTypes.GET_NUMBER_LIST_FAIL,
    err: err,
  };
};

export const handleFetchCustomDashboardBusinessNumberList = (board_id) => {
  return (dispatch) => {
    dispatch(startFetchCustomDashboardBusinessNumberList());
    fetchDashboardBusinessNumberList(board_id)
      .then((res) => {
        if (res.data.status === "success") {
          dispatch(fetchCustomDashboardBusinessNumberListSuccess(res.data));
        } else {
          dispatch(
            fetchCustomDashboardBusinessNumberListFail(
              "取得儀表板統編公司資料時發生問題。"
            )
          );
        }
      })
      .catch((err) => {
        dispatch(fetchCustomDashboardBusinessNumberListFail(err));
      });
  };
};

const startAddCustomDashboardBusinessNumberList = () => {
  return {
    type: actionTypes.STAR_ADD_DASHBOARD_NUMBER_LIST,
  };
};

const addCustomDashboardBusinessNumberListSuccess = (data) => {
  return {
    type: actionTypes.ADD_NUMBER_LIST_SUCCESS,
    data: data,
  };
};

const addCustomDashboardBusinessNumberListFail = (err) => {
  return {
    type: actionTypes.ADD_NUMBER_LIST_FAIL,
    err: err,
  };
};

const startDeleteCustomDashboardBusinessNumberList = () => {
  return {
    type: actionTypes.STAR_DELETE_DASHBOARD_NUMBER_LIST,
  };
};

const deleteCustomDashboardBusinessNumberListSuccess = (data) => {
  return {
    type: actionTypes.DELETE_NUMBER_LIST_SUCCESS,
    data: data,
  };
};

const deleteCustomDashboardBusinessNumberListFail = (err) => {
  return {
    type: actionTypes.DELETE_NUMBER_LIST_FAIL,
    err: err,
  };
};

export const handleAddCustomDashboardBusinessNumberList = (
  board_id,
  business_no_list
) => {
  return (dispatch) => {
    dispatch(startAddCustomDashboardBusinessNumberList());
    addDashboardBusinessNumberList(board_id, business_no_list)
      .then((res) => {
        if (res.data.status === "success") {
          dispatch(addCustomDashboardBusinessNumberListSuccess(res.data));
        } else {
          dispatch(
            addCustomDashboardBusinessNumberListFail(res.data.description)
          );
        }
      })
      .catch((err) => {
        dispatch(addCustomDashboardBusinessNumberListFail(err));
      });
  };
};

export const handleDeleteCustomDashboardBusinessNumberList = (
  board_id,
  business_no_list
) => {
  return (dispatch) => {
    dispatch(startDeleteCustomDashboardBusinessNumberList());
    deleteCompanyDashboardBusinessNumberList(board_id, business_no_list)
      .then((res) => {
        if (res.data.status === "success") {
          dispatch(deleteCustomDashboardBusinessNumberListSuccess(res.data));
        } else {
          dispatch(
            deleteCustomDashboardBusinessNumberListFail(res.data.description)
          );
        }
      })
      .catch((err) => {
        dispatch(deleteCustomDashboardBusinessNumberListFail(err));
      });
  };
};

const startUpdateCustomDashboardBusinessNumberList = () => {
  return {
    type: actionTypes.STAR_UPDATE_DASHBOARD_NUMBER_LIST,
  };
};

const updateCustomDashboardBusinessNumberListSuccess = (data) => {
  return {
    type: actionTypes.UPDATE_NUMBER_LIST_SUCCESS,
    data: data,
  };
};

const updateCustomDashboardBusinessNumberListFail = (err) => {
  return {
    type: actionTypes.UPDATE_NUMBER_LIST_FAIL,
    err: err,
  };
};
export const handleUpdateCustomDashboardName = (board_id,
  board_name) => {
    return (dispatch) => {
      dispatch(startUpdateCustomDashboardName());
      return new Promise((resolve, reject) => {
        updateDashboardName(board_id, board_name)
        .then(async (res) => {
          if (res.data.status === "success") {
            dispatch(updateCustomDashboardNameSuccess());
            resolve(res.data)
          } else {
            reject(res.data)
            dispatch(
              updateCustomDashboardNameFail(
                "更新儀表板統編時發生問題。"
              )
            );
          }
        })
        .catch((err) => {
          reject(err)
          dispatch(updateCustomDashboardNameFail(err));
        });
      })
    };
}
const startUpdateCustomDashboardName = () => {
  return {
    type: actionTypes.START_UPDATE_CUSTOM_BOARD_NAME,
  };
};

const updateCustomDashboardNameSuccess = (data) => {
  return {
    type: actionTypes.UPDATE_CUSTOM_BOARD_NAME_SUCCESS,
    data: data,
  };
};

const updateCustomDashboardNameFail = (err) => {
  return {
    type: actionTypes.UPDATE_CUSTOM_BOARD_NAME_FAIL,
    err: err,
  };
};

export const handleUpdateCustomDashboardBusinessNumberList = (
  board_id,
  business_no_list,
  board_name
) => {
  return (dispatch) => {
    dispatch(startUpdateCustomDashboardBusinessNumberList());
    updateDashboardBusinessNumberList(board_id, business_no_list, board_name)
      .then((res) => {
        if (res.data.status === "success") {
          dispatch(updateCustomDashboardBusinessNumberListSuccess());
        } else {
          dispatch(
            updateCustomDashboardBusinessNumberListFail(
              "更新儀表板統編時發生問題。"
            )
          );
        }
      })
      .catch((err) => {
        dispatch(updateCustomDashboardBusinessNumberListFail(err));
      });
  };
};

/* 用公司名稱找統編 */
const startGetBusinessNoByCompanyName = () => {
  return {
    type: actionTypes.STAR_GET_NO_BY_COMPANY_NAME,
  };
};

const getBusinessNoByCompanyNameSuccess = (data) => {
  return {
    type: actionTypes.GET_NO_BY_COMPANY_NAME_SUCCESS,
    data: data,
  };
};

const getBusinessNoByCompanyNameFail = (err) => {
  return {
    type: actionTypes.GET_NO_BY_COMPANY_NAME_FAIL,
    err: err,
  };
};

export const handleGetBusinessNoByCompanyName = (
  company_list,
) => {
  return (dispatch) => {
    dispatch(startGetBusinessNoByCompanyName());
    getBusinessNoByCompanyName(company_list)
      .then((res) => {
        if (res.data.status === "success") {
          dispatch(getBusinessNoByCompanyNameSuccess(res.data.data));
        } else {
          dispatch(
            getBusinessNoByCompanyNameFail(
              "取得公司統編時發生問題。"
            )
          );
        }
      })
      .catch((err) => {
        dispatch(getBusinessNoByCompanyNameFail(err));
      });
  };
};
/* end */

const startCheckCustomDashboardBusinessNumberList = () => {
  return {
    type: actionTypes.START_CHECK_DASHBOARD_NUMBER_LIST,
  };
};

const checkCustomDashboardBusinessNumberListSuccess = (data) => {
  return {
    type: actionTypes.CHECK_NUMBER_LIST_SUCCESS,
    data: data,
  };
};

const checkCustomDashboardBusinessNumberListFail = (err) => {
  return {
    type: actionTypes.CHECK_NUMBER_LIST_FAIL,
    err: err,
  };
};

export const handleCheckCustomDashboardBusinessNumberList = (business_no) => {
  return (dispatch) => {
    dispatch(startCheckCustomDashboardBusinessNumberList());
    checkDashboardBusinessNumberList(business_no)
      .then((res) => {
        if (res.data.status === "success") { 
          dispatch(checkCustomDashboardBusinessNumberListSuccess(res.data.data));
        } else {
          dispatch(
            checkCustomDashboardBusinessNumberListFail(
              "檢查統編已加入儀表板時發生問題。"
            )
          );
        }
      })
      .catch((err) => {
        dispatch(checkCustomDashboardBusinessNumberListFail(err));
      });
  };
};


export const handleClearCustomDashboardBusinessNumberListState = () => {
  return {
    type: actionTypes.HANDLE_CLEAR_CUSTOM_DASHBOARD_BUSINESSNUMBER_LIST,
  };
};

export const handleUpdateBusinessNumberByParkInfo = (business_number) => {
  return {
    type: actionTypes.UPDATE_BUSINESS_NUMBER_BY_PARKINFO,
    data: business_number
  };
};

export const handleUpdateBusinessNumberByCompanyInfo = (business_number_list) => {
  return {
    type: actionTypes.UPDATE_BUSINESS_NUMBER_BY_COMPANY_SEARCH,
    data: business_number_list
  };
};

export const handleClearBusinessNumberByParkInfo = () => {
  return {
    type: actionTypes.CLEAR_BUSINESS_NUMBER_BY_PARKINFO,
  };
};

export const handleClearBusinessNumberByCompanyInfo = () => {
  return {
    type: actionTypes.CLEAR_BUSINESS_NUMBER_BY_COMPANY_SEARCH,
  };
};

const startCopyDashboardList = () => {
  return {
    type: actionTypes.START_CREATE_DASHBOARD_LIST,
  };
};

const copyDashboardListSuccess = (data) => {
  return {
    type: actionTypes.CREATE_DASHBOARD_LIST_SUCCESS,
    data: data,
  };
};

const copyDashboardListFail = (err) => {
  return {
    type: actionTypes.CREATE_DASHBOARD_LIST_FAIL,
    err: err,
  };
};

export const handleCopyDashboardList = (data) => {
  return (dispatch) => {
    dispatch(startCopyDashboardList());
    return new Promise((resolve, reject) => {
      copyDashboard(data)
        .then(async (res) => {
          if (res.data.status === "success") {
            dispatch(copyDashboardListSuccess());
            await handleFetchCustomDashboardList();
            resolve(res.data)
          } else {
            dispatch(
              copyDashboardListFail(
                "複製儀表板時發生問題。"
              )
            );
            reject(res.data)
          }
        })
        .catch((err) => {
          dispatch(copyDashboardListFail(err));
          reject(err)
        });
    })
    
  };
};