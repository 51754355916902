import axios from "axios";
import { baseUrl } from "../constants/endpoints";

const headers = {
  "Access-Control-Allow-Origin": "*",
  "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
  "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers",
  "Content-Type": "application/json",
  Authorization: localStorage.getItem("acToken"),
};


//取得出口級距產業營運概況
export const getOperationExportData = (typeTwo) => {
  return axios({
    proxy: true,
    method: "get",
    url: `${baseUrl}/api/MoeaDos/GetCompanyOperations_ForeignTrade?IndustryCode_Two=${typeTwo}&IsIncome=false&isStock=false`,
    headers: headers,
  })
    .then((res) => {
      const sortData = [...res.data.data].sort((a, b) => {
        if (a.Accumulated_Variety > b.Accumulated_Variety) return -1;
        if (a.Accumulated_Variety < b.Variety) return 1;
      });
      res.data.data = sortData.filter((item) => item.Accumulated_Variety != 'Infinity');
      let calcAbstract = {
        "up": 0,
        "down": 0,
        "balance": 0
      };
      let calcAccumulatedAbstract = {
        "up": 0,
        "down": 0,
        "balance": 0
      };
      for (let i = 0; i < res.data.data.length; i++) {
        if (res.data.data[i].Accumulated_Variety > 0) {
          calcAccumulatedAbstract.up++;
        } else if (res.data.data[i].Accumulated_Variety < 0) {
          calcAccumulatedAbstract.down++;
        } else {
          calcAccumulatedAbstract.balance++;
        }
      }
      return { "data": res.data, "calcAbstract": calcAbstract, "calcAccumulatedAbstract": calcAccumulatedAbstract  };
    })
    .catch((err) => {
      return [];
    });
};

//取得營收產業營運概況
export const getOperationData = (typeTwo) => {
  return axios({
    proxy: true,
    method: "get",
    url: `${baseUrl}/api/MoeaDos/GetStockCompanyOperations?IndustryCode_Two=${typeTwo}`,
    headers: headers,
  })
    .then((res) => {
      const sortData = [...res.data.data].sort((a, b) => {
        if (a.Variety > b.Variety) return -1;
        if (a.Variety < b.Variety) return 1;
      });
      res.data.data = sortData;
      let calcAbstract = {
        "up": 0,
        "down": 0,
        "balance": 0
      };
      let calcAccumulatedAbstract = {
        "up": 0,
        "down": 0,
        "balance": 0
      };
      for (let i = 0; i < res.data.data.length; i++) {
        if (res.data.data[i].Variety > 0) {
          calcAbstract.up++;
        } else if (res.data.data[i].Variety < 0) {
          calcAbstract.down++;
        } else {
          calcAbstract.balance++;
        }
      }
      for (let i = 0; i < res.data.data.length; i++) {
        if (res.data.data[i].Accumulated_Variety > 0) {
          calcAccumulatedAbstract.up++;
        } else if (res.data.data[i].Accumulated_Variety < 0) {
          calcAccumulatedAbstract.down++;
        } else {
          calcAccumulatedAbstract.balance++;
        }
      }
      return { "data": res.data, "calcAbstract": calcAbstract, "calcAccumulatedAbstract": calcAccumulatedAbstract  };
    })
    .catch((err) => {
      return [];
    });
};