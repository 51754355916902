import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Collapse from "@material-ui/core/Collapse";
import Icon from "@material-ui/core/Icon";
import Button from "@material-ui/core/Button";

import {
  cities,
  northCities,
  centerCities,
  southCities,
  eastCities,
} from "../../constants/cities";
import {
  parks,
  northParks,
  centerParks,
  southParks,
} from "../../constants/parks";
import { getTypeTwoCode, getTypeFourCode } from "./api";
import Message from "../../utility/customizedAlert";
import "./styles.scss";

export default (props) => {
  const {
    controlledSearch,
    updateData,
    KeyWord,
    forceOpen,
    handleClearParams,
    isDrawerOpen,
    keyWord,
    wastedPollutionOptions,
    toxicPollutionOptions,
  } = props;
  const [state, setState] = useState({
    isFilterGroupOpen: true,
    keyArrow: "keyboard_arrow_up",
    KeyWord: "",
    controlledMaterialType: "毒性化學物質",
    location: "All",
    city: [""],
    parkId: [""],
    typeTwo: [""],
    typeFour: [""],
    citiesAndPark: 0,
    citiesAndParkSelection: [],
    type: 0,
    typeSelection: [],
    typeTwoOptions: [""],
    typeFourOptions: [""],
    isAccessSearch: false,
    isClicked: false,
    WasteName: "",
    ToxicName: "",
  });

  const activeTabs = { backgroundColor: "#7D7D7D", color: "#EBEBEB" };
  const inActiveTabs = { backgroundColor: "#EBEBEB", color: "#7D7D7D" };

  React.useEffect(() => {
    const {
      controlledMaterialType,
      location,
      city,
      parkId,
      typeTwo,
      typeFour,
    } = state;
    if (
      !controlledMaterialType &&
      location === "All" &&
      city.length === 1 &&
      parkId.length === 1 &&
      typeTwo.length === 1 &&
      typeFour.length === 1 &&
      !keyWord
    ) {
      setState((state) => ({ ...state, isAccessSearch: false }));
    } else {
      setState((state) => ({ ...state, isAccessSearch: true }));
    }
  }, [
    state.controlledMaterialType,
    state.location,
    state.city,
    state.parkId,
    state.typeTwo,
    state.typeFour,
    keyWord,
  ]);

  React.useEffect(() => {
    fetData();
  }, []);

  React.useEffect(() => {
    const { citiesAndPark, location, type } = state;
    if (citiesAndPark === 1) {
      if (location === "All") {
        setState((state) => ({
          ...state,
          citiesAndPark: 1,
          citiesAndParkSelection: parks.map((park) => (
            <MenuItem key={park.ParkId} value={park.ParkId}>
              {park.Name_CH}
            </MenuItem>
          )),
        }));
      } else if (location === "North") {
        setState((state) => ({
          ...state,
          citiesAndPark: 1,
          citiesAndParkSelection: northParks.map((park) => (
            <MenuItem key={park.ParkId} value={park.ParkId}>
              {park.Name_CH}
            </MenuItem>
          )),
        }));
      } else if (location === "Center") {
        setState((state) => ({
          ...state,
          citiesAndPark: 1,
          citiesAndParkSelection: centerParks.map((park) => (
            <MenuItem key={park.ParkId} value={park.ParkId}>
              {park.Name_CH}
            </MenuItem>
          )),
        }));
      } else if (location === "South") {
        setState((state) => ({
          ...state,
          citiesAndPark: 1,
          citiesAndParkSelection: southParks.map((park) => (
            <MenuItem key={park.ParkId} value={park.ParkId}>
              {park.Name_CH}
            </MenuItem>
          )),
        }));
      }
    } else if (citiesAndPark === 0) {
      if (location === "All") {
        setState((state) => ({
          ...state,
          citiesAndPark: 0,
          citiesAndParkSelection: cities.map((city) => (
            <MenuItem key={city} value={city}>
              {city}
            </MenuItem>
          )),
        }));
      } else if (location === "North") {
        setState((state) => ({
          ...state,
          citiesAndPark: 0,
          citiesAndParkSelection: northCities.map((city) => (
            <MenuItem key={city} value={city}>
              {city}
            </MenuItem>
          )),
        }));
      } else if (location === "Center") {
        setState((state) => ({
          ...state,
          citiesAndPark: 0,
          citiesAndParkSelection: centerCities.map((city) => (
            <MenuItem key={city} value={city}>
              {city}
            </MenuItem>
          )),
        }));
      } else if (location === "South") {
        setState((state) => ({
          ...state,
          citiesAndPark: 0,
          citiesAndParkSelection: southCities.map((city) => (
            <MenuItem key={city} value={city}>
              {city}
            </MenuItem>
          )),
        }));
      } else if (location === "East") {
        setState((state) => ({
          ...state,
          citiesAndPark: 0,
          citiesAndParkSelection: eastCities.map((city) => (
            <MenuItem key={city} value={city}>
              {city}
            </MenuItem>
          )),
        }));
      }
    }

    // if(type === 0){
    //     setState(state => ({
    //         ...state,
    //         type:0,
    //         typeSelection: state.typeTwoOptions.map(type=> <MenuItem key={type.Key} value={type.Key}>{type.Value}</MenuItem>),
    //     }))
    // }else if(type === 1){
    //     setState(state => ({
    //         ...state,
    //         type:0,
    //         typeSelection: state.typeTwoOptions.map(type=> <MenuItem key={type.Key} value={type.Key}>{type.Value}</MenuItem>),
    //     }))
    // }
  }, [state.location, state.citiesAndPark, state.type]);

  React.useEffect(() => {
    if (forceOpen) {
      setState((state) => ({ ...state, isFilterGroupOpen: true }));
    }
  }, [forceOpen]);

  React.useEffect(() => {
    if (!state.isClicked) {
      setState((state) => ({ ...state, isFilterGroupOpen: true }));
    } else if (!isDrawerOpen) {
      setState((state) => ({ ...state, isFilterGroupOpen: false }));
    }
  }, [isDrawerOpen]);

  const fetData = async () => {
    const [typeTwoOptions, typeFourOptions] = await Promise.all([
      getTypeTwoCode(),
      getTypeFourCode(),
    ]);

    setState((state) => ({
      ...state,
      typeTwoOptions,
      typeFourOptions,
      typeSelection: typeTwoOptions.map((type) => (
        <MenuItem key={type.Key} value={type.Key}>
          {type.Value}
        </MenuItem>
      )),
      citiesAndParkSelection: cities.map((city) => (
        <MenuItem key={city} value={city}>
          {city}
        </MenuItem>
      )),
    }));
  };

  const toggleFilterGroupHandler = () => {
    setState((state) => ({
      ...state,
      isFilterGroupOpen: !state.isFilterGroupOpen,
      keyArrow:
        state.keyArrow === "keyboard_arrow_up"
          ? "keyboard_arrow_down"
          : "keyboard_arrow_up",
    }));
  };

  const toggleTabHandler = (params) => {
    switch (params) {
      case "parks":
        setState((state) => ({
          ...state,
          citiesAndPark: 1,
          city: [""],
        }));
        updateData("city", [""]); //判斷有選園區的時候縣市要空
        break;
      case "cities":
        setState((state) => ({
          ...state,
          citiesAndPark: 0,
          parkId: [""],
        }));
        updateData("parkId", [""]); //判斷有選縣市的時候園區要空
        break;
      case "typeTwo":
        setState((state) => ({
          ...state,
          type: 0,
          typeSelection: state.typeTwoOptions.map((type) => (
            <MenuItem key={type.Key} value={type.Key}>
              {type.Value}
            </MenuItem>
          )),
          typeFour: [""],
        }));
        updateData("typeFour", [""]); //判斷有兩碼時候四碼要空
        break;
      case "typeFour":
        setState((state) => ({
          ...state,
          type: 1,
          typeSelection: state.typeFourOptions.map((type) => (
            <MenuItem key={type.Key} value={type.Key}>
              {type.Value}
            </MenuItem>
          )),
          typeTwo: [""],
        }));
        updateData("typeTwo", [""]); //判斷有四碼時候兩碼要空
        break;
      default:
        return;
    }
  };

  const handleUpdateField = (event) => {
    const { name, value } = event.target;
    //縣市, 工業區, 產業別2碼, 產業別3碼不得超過 3 個。
    if (Array.isArray(value)) {
      if (state[name].length === 4) {
        let updateArray;
        if (value > state[name]) {
          updateArray = value.pop();
        }
        setState((state) => ({
          ...state,
          [name]: value,
        }));
        updateData(name, value);
        Message({
          html: "<p>選項不得大於3個</p>",
          confirmButtonText: "好",
        });
      } else {
        setState((state) => ({
          ...state,
          [name]: value,
        }));
        updateData(name, value);
      }
    } else {
      if (name === "controlledMaterialType" && value === "all") {
        setState((state) => ({
          ...state,
          [name]: value,
        }));
        updateData(name, "");
      } else {
        if (name === "WasteName") {
          setState((state) => ({
            ...state,
            ToxicName: "",
            [name]: value,
          }));
        } else if (name === "ToxicName") {
          setState((state) => ({
            ...state,
            WasteName: "",
            [name]: value,
          }));
        } else {
          setState((state) => ({
            ...state,
            [name]: value,
          }));
        }
        updateData(name, value);
      }
    }
  };

  const handleSubmit = () => {
    controlledSearch("", "others");
    setState((state) => ({
      ...state,
      isFilterGroupOpen: false,
      isClicked: true,
    }));
  };

  const clearSearchCondition = () => {
    //處理 ControlledMaterialFilterGroup 的 local state
    setState((state) => ({
      ...state,
      controlledMaterialType: "毒性化學物質",
      location: "All",
      city: [""],
      parkId: [""],
      typeTwo: [""],
      typeFour: [""],
      WasteName: "",
      ToxicName: "",
    }));
    handleClearParams();
  };

  console.log(wastedPollutionOptions);

  return (
    <div className="material-filter-group">
      <div className="material-filter-group-header">
        <h4>搜尋條件</h4>
        <Icon onClick={toggleFilterGroupHandler} className="icon">
          {state.keyArrow}
        </Icon>
      </div>
      <Collapse in={state.isFilterGroupOpen}>
        <div className="input-group">
          <TextField
            className="material-input-select"
            label="列管物分類"
            variant="outlined"
            size="small"
            // value={state.controlledMaterialType}
            value={"毒性化學物質"}
            onChange={(e) => handleUpdateField(e)}
            name="controlledMaterialType"
            select
          >
            {/* <MenuItem key="all" value="all">全部</MenuItem> */}
            {/* <MenuItem key={"廢棄物"} value={"廢棄物"}>
              廢棄物
            </MenuItem> */}
            <MenuItem key={"毒性化學物質"} value={"毒性化學物質"}>
              毒性化學物質
            </MenuItem>
            {/* <MenuItem disabled key={"水污染"} value={"水污染"}>水污染</MenuItem>
                        <MenuItem disabled key={"空氣污染"} value={"空氣污染"}>空氣污染</MenuItem> */}
          </TextField>
          {state.controlledMaterialType === "廢棄物" && (
            <TextField
              className="material-input-select"
              label="可回收項目"
              variant="outlined"
              size="small"
              value={state.WasteName}
              onChange={(e) => handleUpdateField(e)}
              name="WasteName"
              select
            >
              <MenuItem value="">請選擇</MenuItem>
              {wastedPollutionOptions
                .filter(
                  (option) =>
                    option.Name.charAt(0).toString() === "R" ||
                    option.Name.charAt(0).toString() === "D"
                )
                .map((option) => (
                  <MenuItem key={option.Name} value={option.Name}>
                    {option.Name}
                  </MenuItem>
                ))}
            </TextField>
          )}
          {state.controlledMaterialType === "毒性化學物質" && (
            <TextField
              className="material-input-select"
              label="毒化物"
              variant="outlined"
              size="small"
              value={state.ToxicName}
              onChange={(e) => handleUpdateField(e)}
              name="ToxicName"
              select
            >
              <MenuItem value="">請選擇</MenuItem>
              {toxicPollutionOptions.map((option) => (
                <MenuItem key={option.Name} value={option.Name}>
                  {option.Name}
                </MenuItem>
              ))}
            </TextField>
          )}
          <TextField
            className="material-input-select"
            label="地區"
            variant="outlined"
            size="small"
            value={state.location}
            onChange={(e) => handleUpdateField(e)}
            name="location"
            select
          >
            <MenuItem key={"All"} value={"All"}>
              台灣所有地區
            </MenuItem>
            <MenuItem key={"North"} value={"North"}>
              台灣北部地區
            </MenuItem>
            <MenuItem key={"Center"} value={"Center"}>
              台灣中部地區
            </MenuItem>
            <MenuItem key={"South"} value={"South"}>
              台灣南部地區
            </MenuItem>
            <MenuItem key={"East"} value={"East"}>
              台灣東部地區
            </MenuItem>
          </TextField>
          <div className="inline-inputs">
            <div className="tabs">
              <div
                className="lefts"
                style={state.citiesAndPark === 0 ? activeTabs : inActiveTabs}
                onClick={() => {
                  toggleTabHandler("cities");
                }}
              >
                <p>縣市</p>
              </div>
              <div
                className="rights"
                style={state.citiesAndPark === 1 ? activeTabs : inActiveTabs}
                onClick={() => {
                  toggleTabHandler("parks");
                }}
              >
                <p>園區</p>
              </div>
            </div>
            <Select
              size="small"
              value={state.citiesAndPark === 0 ? state.city : state.parkId}
              onChange={(e) => handleUpdateField(e)}
              name={state.citiesAndPark === 0 ? "city" : "parkId"}
              input={
                <TextField
                  className="material-input-select"
                  variant="outlined"
                  select
                />
              }
              multiple
              defaultValue={[""]}
            >
              <MenuItem value={""} disabled>
                {state.citiesAndPark === 0 ? "縣市" : "園區"}
              </MenuItem>
              {state.citiesAndParkSelection}
            </Select>
          </div>
          {/* <div className="inline-inputs">
                        <div className="tabs">
                            <div className="lefts" style={state.type === 0 ? activeTabs : inActiveTabs } onClick={()=>{toggleTabHandler("typeTwo")}}><p>2碼</p></div>
                            <div className="rights" style={state.type === 1 ? activeTabs : inActiveTabs } onClick={()=>{toggleTabHandler("typeFour")}}><p>4碼</p></div>
                        </div>
                        <Select
                            size="small"
                            value={state.type === 0 ? state.typeTwo: state.typeFour}
                            onChange={(e) => handleUpdateField(e)}
                            name={state.type === 0 ? "typeTwo":"typeFour"}
                            input={<TextField select className="material-input-select" variant="outlined"/>}
                            multiple
                            defaultValue={[""]}
                        >
                            <MenuItem value={""} disabled >{state.type === 0 ? "2碼":"4碼"}</MenuItem>
                            {state.typeSelection}
                        </Select>
                    </div> */}
        </div>
        <div className="button-container">
          <Button
            className="button"
            variant="contained"
            onClick={handleSubmit}
            disabled={!state.isAccessSearch}
            style={
              !state.isAccessSearch
                ? { background: "#757575", cursor: "not-allowed" }
                : {}
            }
          >
            查詢
          </Button>
          <p className="clear-filter-condition" onClick={clearSearchCondition}>
            清除搜尋條件
          </p>
        </div>
      </Collapse>
    </div>
  );
};
