import React from "react";
import { useSelector } from "react-redux";
import { CircularProgress } from "@material-ui/core";
import { useLocation } from "react-router-dom";

import labor from "../../assests/industrialFactory03.png";
import QuestionContainerCard from "./QuestionContainerCard";

import * as Style from "./styles";

export default (props) => {
  const {
    result,
    prevSlide,
    nextSlide,
    stepFour,
    selected,
    updateTypeList,
    UploadQuestionData,
  } = props;

  const { getVisitQuestionDetail } = useSelector((state) => state.question);
  const location = useLocation();

  const [state, setState] = React.useState({
    title: [],
    isFinished: false,
  });

  //讀取編輯資料
  React.useEffect(() => {
    const fetchData = async () => {
      if (getVisitQuestionDetail.Qid && location.state) {
        var temp = [];
        var tempSelected = [];
        var tempResult = [];
        var tempData = [];
        var tempStep4 = [];

        [getVisitQuestionDetail].map((item) => {
          tempData = item.Contents;
          result[4].conclusion = item.Summary;
          result[4].signParticipantsPad = item.VisitorSign;
          result[4].signParticipantsPad_2 = item.VisitorSign_2;
          result[4].signParticipantsPad_3 = item.VisitorSign_3;
          result[4].signSupervisorPad = item.RespondSign;
          result[4].signSupervisorPad_2 = item.RespondSign_2;
          result[4].signSupervisorPad_3 = item.RespondSign_3;
          tempStep4 = item.FollowStep4?.split(",")?.filter((i) => i !== "");
          temp = item.Step4Record?.split(",")?.filter((i) => i !== "");
        });
        if (temp) {
          tempSelected = temp?.map((i) => parseInt(i));
        }
        if (tempStep4) {
          tempStep4 = tempStep4?.map((i) => parseInt(i));
        }
        await tempData?.map((title) => {
          if (tempResult.indexOf(title.TypeChildName) === -1) {
            tempResult.push(title.TypeChildName);
          }
        });

        result[3].stepFourData = tempData;
        result[3].stepFourResult = tempResult;
        result[3].stepFourSelected = tempSelected;
        result[3].followStep4 = tempStep4;
      }
    };
    fetchData();
  }, [getVisitQuestionDetail]);

  React.useEffect(() => {
    const temp = [];

    result[2].stepThreeData
      .sort((x, y) => x.TopicId - y.TopicId)
      .forEach((item) => {
        if (temp.indexOf(item.VisitTypeName) === -1) {
          temp.push(item.VisitTypeName);
        }
      });

    setState((state) => ({
      ...state,
      title: temp,
    }));
  }, [result]);

  React.useEffect(() => {
    if (selected?.length > 0) {
      setState((state) => ({
        ...state,
        isFinished: true,
      }));
    } else {
      setState((state) => ({
        ...state,
        isFinished: false,
      }));
    }
  }, [selected?.length]);

  return !state.title ? (
    <CircularProgress />
  ) : (
    <QuestionContainerCard
      title={"類型子項"}
      src={labor}
      prevSlide={prevSlide}
      nextSlide={nextSlide}
      isFinished={state.isFinished}
      UploadQuestionData={UploadQuestionData}>
      <Style.QuestionGridView column>
        {state.title.map((title, index) => (
          <Style.BasicQuestionRightContainer
            key={"VisitSubType" + index.toString()}>
            <Style.EachQuestionContainerTitle>
              {title}
            </Style.EachQuestionContainerTitle>
            <Style.QuestionGridContainer isFourCol={true}>
              <Style.QuestionGridView isFourCol={true}>
                {stepFour.map((item, index) => {
                  if (item.VisitTypeName === title) {
                    return (
                      <Style.QuestionGridItem
                        key={"stepFour" + index.toString()}
                        onClick={() =>
                          updateTypeList(item.TypeChildName, item.TopicId)
                        }
                        isActive={
                          selected?.filter((i) => i === item.TopicId).length > 0
                        }
                        isFourCol={true}>
                        <Style.QuestionGridItemText
                          isFourCol={true}
                          fontSize={"1.3rem"}>
                          {item.TypeChildName}
                        </Style.QuestionGridItemText>
                      </Style.QuestionGridItem>
                    );
                  }
                })}
              </Style.QuestionGridView>
            </Style.QuestionGridContainer>
          </Style.BasicQuestionRightContainer>
        ))}
      </Style.QuestionGridView>
    </QuestionContainerCard>
  );
};
