import React from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import IconButton from "@mui/material/IconButton";
import Close from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import TaxSelect from "../CustomIndustryDashboard/TaxSelect";

import {
  handleGetCountryList,
  handleGetImportCountryTradeProduct,
  handleGetExportCountryTradeProduct,
} from "../../store/actions/custom_industry_dashboard";

import * as theme from "../../assets/theme";

const SetGoodsModal = (props) => {
  const { handle_close_modal, handleModalChange, state, handleOpenExportModal, handleUpload, modalName } = props;

  const dispatch = useDispatch();
  const { getImportCountryTradeProduct, getExportCountryTradeProduct, getCountryTradeProduct_loading, getCountryList } = useSelector(
    (state) => state.custom_industry_dashboard
  );

  React.useEffect(async () => {
    dispatch(handleGetCountryList());
  }, []);

  React.useEffect(async () => {
    let currentYear = new Date().getFullYear();
    dispatch(handleGetImportCountryTradeProduct(state.Importcountry, currentYear - 2));
  }, [state.Importcountry]);

  React.useEffect(async () => {
    let currentYear = new Date().getFullYear();
    dispatch(handleGetExportCountryTradeProduct(state.Exportcountry, currentYear - 2));
  }, [state.Exportcountry]);

  return (
    <>
      {window.innerWidth>=1024&&(
        <ModalWrapper>
          <ModalCloseContainer>
            <IconButton onClick={handle_close_modal}>
              <Close />
            </IconButton>
          </ModalCloseContainer>
          <ModalHeadContainer>
            <ModalHeadText>
              設定進/出口貨品
            </ModalHeadText>
          </ModalHeadContainer>
          <ModalSubHeadContainer>
            <ModalHeadSubText>
              [ 輸入貨品名稱關鍵字，平台將輔助您選擇進/出口貨品 ]
            </ModalHeadSubText>
          </ModalSubHeadContainer>
          <hr />
          <SelectContainer>
            <TaxSelect
              label={"進口"}
              type={"Import"}
              dataList={!getImportCountryTradeProduct ? [{ GoodsName: "Loading...", Code: 0 }] : getImportCountryTradeProduct}
              loading={getCountryTradeProduct_loading}
              countryList={getCountryList}
              handleChange={handleModalChange}
              selectedCountry={state.Importcountry}
              selectedGoods={state.ImportSelected}
            />
            <TaxSelect
              label={"出口"}
              type={"Export"}
              dataList={getExportCountryTradeProduct}
              loading={getCountryTradeProduct_loading}
              countryList={getCountryList}
              handleChange={handleModalChange}
              selectedCountry={state.Exportcountry}
              selectedGoods={state.ExportSelected}
            />
          </SelectContainer>
          <AddButtonContainer>
            <StyledButton
              onClick={handleUpload}
            >
              完成
            </StyledButton>
          </AddButtonContainer>
        </ModalWrapper>
      )}
      {window.innerWidth<1024&&(
        <>
          {modalName=="import"&&(
            <ModalWrapper>
              <ModalCloseContainer>
                <IconButton onClick={handle_close_modal}>
                  <Close />
                </IconButton>
              </ModalCloseContainer>
              <ModalHeadContainer>
                <ModalHeadText>
                  設定進口貨品
                </ModalHeadText>
              </ModalHeadContainer>
              <ModalSubHeadContainer>
                <ModalHeadSubText>
                  [ 輸入貨品名稱關鍵字，平台將輔助您選擇進口貨品 ]
                </ModalHeadSubText>
              </ModalSubHeadContainer>
              <hr />
              <SelectContainer>
                <TaxSelect
                  label={"進口"}
                  type={"Import"}
                  dataList={!getImportCountryTradeProduct ? [{ GoodsName: "Loading...", Code: 0 }] : getImportCountryTradeProduct}
                  loading={getCountryTradeProduct_loading}
                  countryList={getCountryList}
                  handleChange={handleModalChange}
                  selectedCountry={state.Importcountry}
                  selectedGoods={state.ImportSelected}
                />
              </SelectContainer>
              <AddButtonContainer>
                <StyledButton
                  onClick={handleOpenExportModal}
                >
                  下一步
                </StyledButton>
              </AddButtonContainer>
            </ModalWrapper>
          )}
          {modalName=="export"&&(
            <ModalWrapper>
              <ModalCloseContainer>
                <IconButton onClick={handle_close_modal}>
                  <Close />
                </IconButton>
              </ModalCloseContainer>
              <ModalHeadContainer>
                <ModalHeadText>
                  設定出口貨品
                </ModalHeadText>
              </ModalHeadContainer>
              <ModalSubHeadContainer>
                <ModalHeadSubText>
                  [ 輸入貨品名稱關鍵字，平台將輔助您選擇出口貨品 ]
                </ModalHeadSubText>
              </ModalSubHeadContainer>
              <hr />
              <SelectContainer>
                <TaxSelect
                  label={"出口"}
                  type={"Export"}
                  dataList={!getExportCountryTradeProduct ? [{ GoodsName: "Loading...", Code: 0 }] : getExportCountryTradeProduct}
                  loading={getCountryTradeProduct_loading}
                  countryList={getCountryList}
                  handleChange={handleModalChange}
                  selectedCountry={state.Exportcountry}
                  selectedGoods={state.ExportSelected}
                />
              </SelectContainer>
              <AddButtonContainer>
                <StyledButton
                  onClick={handleUpload}
                >
                  完成
                </StyledButton>
              </AddButtonContainer>
            </ModalWrapper>
          )}
        </>
      )}
    </>
  );
};

const ModalWrapper = styled.div`
    width: 70%;
    background-color: ${theme.white};
    border-radius: 20px;
    padding: 20px 30px;
    @media (max-width: 750px) {
      width: 100%;
    }
    @media screen and (max-width: 1280px),
            screen and (max-height: 675px)
    {
      width: 78%;
      height: fit-content;
      overflow-y: auto;
    }
  `;

const ModalCloseContainer = styled.div`
    width: calc(100%-60px);
    display: flex;
    justify-content: flex-end;
    align-items: center;
  `;

const ModalHeadContainer = styled.div`
    width: calc(100%-60px);
    display: flex;
    justify-content: flex-start;
    align-items: center;
  `;

const SelectContainer = styled.div`
    display: flex;
    flex-direction: row
  `;

const ModalHeadText = styled.h2`
    font-size: 1.5rem;
    font-weight: 600;
    letter-spacing: 2px;
    transform: translateY(-20px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    @media (max-width: 750px) {
      width: 100%;
    }
  `;

const ModalSubHeadContainer = styled.div`
    width: calc(100%-60px);
    display: flex;
    justify-content: flex-start;
    align-items: center;
  `;

const ModalHeadSubText = styled.h2`
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 2px;
  transform: translateY(-5px);
  line-height: 20px;
`;

const StyledButton = styled(Button)`
background-color: ${(p) =>
    p.disabled ? theme.grayBorderColor : theme.linkBlue} !important;
color: ${theme.white} !important;

&:hover {
  background-color: ${(p) =>
    p.disabled ? theme.grayBorderColor : theme.linkBlue} !important;
}
`;

const AddButtonContainer = styled.div`
  width: 100%;
  padding: 20px 0px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  @media (max-width: 750px) {
    padding: 16px 0px 16px 0px;
  }
`;

export default SetGoodsModal;
