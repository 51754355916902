import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Swal from "sweetalert2";

import QRcode from "../../assests/QRcode.svg";

import "./styles.scss";

export default () => {
    const [sharableText, setSharableText] = useState("");

    useEffect(()=>{
        setSharableText("https://smartpark.tw/sign-up?openExternalBrowser=1")
    },[])

    const handleUpdateField = (e, callback) => {
        callback(e.target.value);
      };

    const isCopied = () => {
        const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 1000,
            timerProgressBar: true,
          });
    
          Toast.fire({
            icon: "success",
            title: "複製成功",
          })
    }

    return(
        <div className="sharable-page">
            <h3>透過掃描此 QR code 分享平台</h3>
            <div className="qr-code">
                <img src={QRcode}  loading={"lazy"}/>
            </div>
            <p>若無法正常掃描 QR code 請增加螢幕亮度。</p>
            <div className="text-container">
                {/* <TextField
                className="sharable-input"
                variant="outlined"
                size="small"
                value={sharableText}
                onChange={(e) => handleUpdateField(e, setSharableText)}
                /> */}
                <Button variant="contained" color="primary">
                    <CopyToClipboard
                        text={sharableText}
                        onCopy={isCopied}
                    >
                        <a>點我複製平台網址</a>
                    </CopyToClipboard>
                </Button>
            </div>
        </div>
    )
}

