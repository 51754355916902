import * as actionType from "../actionTypes/question";

const initState = {
  detailQuestions: [],
  detailQuestionsLoading: false,

  //讀取廠商資料
  companyInfoList: [],
  companyInfoListLoading: false,

  //暫存 & 上傳
  uploadVisitQuestionDescription: "",
  uploadVisitQuestionRelease: "",
  uploadVisitQuestionStatus: "",
  uploadVisitQuestionDataLoading: false,

  //取得限時訪廠問卷資料
  getLimitedVisitQuestionData: [],
  getLimitedVisitQuestionDataMsg: "",
  getLimitedVisitQuestionDataLoading: false,

  //取得帳號問卷清單
  getVisitQuestionList: [],
  getVisitQuestionListLoading: false,

  //取得問卷詳細資料
  getVisitQuestionDetail: [],
  geVisitQuestionDetailLoading: false,

  //刪除問卷
  deleteVisitQuestion: [],
  deleteVisitQuestionLoading: false,

  //上傳照片
  uploadVisitQuestionImage: [],
  uploadVisitQuestionImageMsg: "",
  uploadVisitQuestionImageLoading: false,

  //取得問卷照片
  getVisitQuestionImage: [],
  getVisitQuestionImageLoading: false,
};

const startFetchDetailQuestion = (state) => {
  return {
    ...state,
    detailQuestionsLoading: true,
  };
};

const fetchDetailQuestionSuccess = (state, action) => {
  return {
    ...state,
    detailQuestions: action.data,
    detailQuestionsLoading: false,
  };
};

const fetchDetailQuestionFail = (state) => {
  return {
    ...state,
    detailQuestionsLoading: false,
  };
};

//讀取廠商資料
const startGetUserCompanyInfo = (state) => {
  return {
    ...state,
    companyInfoListLoading: true,
  };
};

const getUserCompanyInfoSuccess = (state, action) => {
  return {
    ...state,
    companyInfoList: action.companyInfoList,
    companyInfoListLoading: false,
  };
};

const getUserCompanyInfoFail = (state) => {
  return {
    ...state,
    companyInfoListLoading: false,
  };
};

//暫存 & 上傳
export const startUploadVisitQuestionData = (state) => {
  return {
    ...state,
    uploadVisitQuestionDataLoading: true,
  };
};

export const getUploadVisitQuestionDataSuccess = (state, data) => {
  return {
    ...state,
    uploadVisitQuestionDescription: data.description,
    uploadVisitQuestionRelease: data.Release,
    uploadVisitQuestionStatus: data.status,
    uploadVisitQuestionDataLoading: false,
  };
};

export const getUploadVisitQuestionDataFail = (state, data) => {
  return {
    ...state,
    err: data,
    uploadVisitQuestionDataLoading: false,
  };
};

//取得限時訪廠問卷資料
export const startGetLimitedVisitQuestionData = (state) => {
  return {
    ...state,
    getLimitedVisitQuestionDataLoading: true,
  };
};

export const getLimitedVisitQuestionDataSuccess = (state, data) => {
  return {
    ...state,
    getLimitedVisitQuestionData: data.data,
  };
};

export const getLimitedVisitQuestionDataFail = (state, data) => {
  return {
    ...state,
    getLimitedVisitQuestionDataMsg: data,
    getLimitedVisitQuestionDataLoading: false,
  };
};

//取得帳號問卷清單
export const startGetVisitQuestionList = (state) => {
  return {
    ...state,
    getVisitQuestionListLoading: true,
  };
};

export const getVisitQuestionListSuccess = (state, data) => {
  return {
    ...state,
    getVisitQuestionList: data.data,
  };
};

export const getVisitQuestionListFail = (state, data) => {
  return {
    ...state,
    err: data,
    getVisitQuestionListLoading: false,
  };
};

//取得問卷詳細資料
export const startGetVisitQuestionDetail = (state) => {
  return {
    ...state,
    getVisitQuestionDetailLoading: true,
  };
};

export const getVisitQuestionDetailSuccess = (state, data) => {
  return {
    ...state,
    getVisitQuestionDetail: data.data,
  };
};

export const getVisitQuestionDetailFail = (state, data) => {
  return {
    ...state,
    err: data,
    geVisitQuestionDetailLoading: false,
  };
};

//刪除問卷
export const startDeleteVisitQuestion = (state) => {
  return {
    ...state,
    deleteVisitQuestionLoading: true,
  };
};

export const deleteVisitQuestionSuccess = (state, data) => {
  return {
    ...state,
    deleteVisitQuestion: data.data,
  };
};

export const deleteVisitQuestionFail = (state, data) => {
  return {
    ...state,
    err: data,
    deleteVisitQuestionLoading: false,
  };
};

//上傳照片
export const startUploadVisitQuestionImage = (state) => {
  return {
    ...state,
    uploadVisitQuestionImageLoading: true,
  };
};

export const uploadVisitQuestionImageSuccess = (state, data) => {
  console.log(data.description);
  return {
    ...state,
    uploadVisitQuestionImage: data.data,
  };
};

export const uploadVisitQuestionImageFail = (state, data) => {
  return {
    ...state,
    uploadVisitQuestionImageMsg: data,
    uploadVisitQuestionImageLoading: false,
  };
};

//取得問卷照片
export const startGetVisitQuestionImage = (state) => {
  return {
    ...state,
    getVisitQuestionImageLoading: true,
  };
};

export const uploadGetVisitQuestionImageSuccess = (state, data) => {
  return {
    ...state,
    getVisitQuestionImage: data.Data,
  };
};

export const uploadGetVisitQuestionImageFail = (state, data) => {
  return {
    ...state,
    err: data,
    getVisitQuestionImageLoading: false,
  };
};

export default (state = initState, action) => {
  switch (action.type) {
    case actionType.START_FETCH_DETAIL_QUESTION:
      return startFetchDetailQuestion(state);
    case actionType.FETCH_DETAIL_QUESTION_SUCCESS:
      return fetchDetailQuestionSuccess(state, action);
    case actionType.FETCH_DETAIL_QUESTION_FAIL:
      return fetchDetailQuestionFail(state, action);

    case actionType.START_GET_USER_COMPANY_INFO:
      return startGetUserCompanyInfo(state);
    case actionType.GET_USER_INFO_COMPANY_SUCCESS:
      return getUserCompanyInfoSuccess(state, action);
    case actionType.GET_USER_INFO_COMPANY_FAIL:
      return getUserCompanyInfoFail(state, action);

    case actionType.START_GET_UPLOAD_VISIT_QUESTION_DATA:
      return startUploadVisitQuestionData(state, action);
    case actionType.GET_UPLOAD_VISIT_QUESTION_DATA_SUCCESS:
      return getUploadVisitQuestionDataSuccess(state, action.data);
    case actionType.GET_UPLOAD_VISIT_QUESTION_DATA_FAIL:
      return getUploadVisitQuestionDataFail(state, action.err);

    case actionType.START_GET_LIMITED_VISIT_QUESTION_DATA:
      return startGetLimitedVisitQuestionData(state, action);
    case actionType.GET_LIMITED_VISIT_QUESTION_DATA_SUCCESS:
      return getLimitedVisitQuestionDataSuccess(state, action.data);
    case actionType.GET_LIMITED_VISIT_QUESTION_DATA_FAIL:
      return getLimitedVisitQuestionDataFail(state, action.err);

    case actionType.START_GET_VISIT_QUESTION_LIST:
      return startGetVisitQuestionList(state, action);
    case actionType.GET_VISIT_QUESTION_LIST_SUCCESS:
      return getVisitQuestionListSuccess(state, action.data);
    case actionType.GET_VISIT_QUESTION_LIST_FAIL:
      return getVisitQuestionListFail(state, action.err);

    case actionType.START_GET_VISIT_QUESTION_DETAIL:
      return startGetVisitQuestionDetail(state, action);
    case actionType.GET_VISIT_QUESTION_DETAIL_SUCCESS:
      return getVisitQuestionDetailSuccess(state, action.data);
    case actionType.GET_VISIT_QUESTION_DETAIL_FAIL:
      return getVisitQuestionDetailFail(state, action.err);

    case actionType.START_DELETE_VISIT_QUESTION:
      return startDeleteVisitQuestion(state, action);
    case actionType.DELETE_VISIT_QUESTION_SUCCESS:
      return deleteVisitQuestionSuccess(state, action.data);
    case actionType.DELETE_VISIT_QUESTION_FAIL:
      return deleteVisitQuestionFail(state, action.err);

    case actionType.START_UPLOAD_VISIT_QUESTION_IMAGE:
      return startUploadVisitQuestionImage(state, action);
    case actionType.UPLOAD_VISIT_QUESTION_IMAGE_SUCCESS:
      return uploadVisitQuestionImageSuccess(state, action.data);
    case actionType.UPLOAD_VISIT_QUESTION_IMAGE_FAIL:
      return uploadVisitQuestionImageFail(state, action.err);

    case actionType.START_GET_VISIT_QUESTION_IMAGE:
      return startGetVisitQuestionImage(state, action);
    case actionType.GET_VISIT_QUESTION_IMAGE_SUCCESS:
      return uploadGetVisitQuestionImageSuccess(state, action.data);
    case actionType.GET_VISIT_QUESTION_IMAGE_FAIL:
      return uploadGetVisitQuestionImageFail(state, action.err);
    default:
      return state;
  }
};
