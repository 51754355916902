import React, { useMemo } from 'react';
import styled from "styled-components";
import { ResponsiveBar } from '@nivo/bar';
import { thousandDivider } from "../../utility/common";
const HorizontalBarChart = ({data, keyTarget, indexBy}) => {
  const colorArray = ["#013a63", "#014f86", "#2c7da0", "#61a5c2"];
  const parseIndex = {
    Name: "產業別",
    Code: "貨品碼",
    Country: "國家",
    CityName: "縣市",
    IndustryArea: "園區",
    CompanyName: "公司名稱"
  }
  const parseUnit = {
    Cnts: "家",
    TotalValue: "千元",
    Value: "千元",
    CapitalTotal: "千元",
  }
  const min = useMemo(() => {
    return Math.min(...data.map(item => item[keyTarget]))
  }, [keyTarget, data]);
  const maxString = useMemo(() => {
    return Math.max(...data.map(item => item[indexBy] && item[indexBy].replace("股份有限公司", "").length))
  }, [indexBy, data]);
  return (
    <div style={{ height: 350 }}>
      <ResponsiveBar
        data={data}
        groupMode="grouped"
        keys={[keyTarget]}
        indexBy={indexBy}
        padding={0.4}
        margin={{
          bottom: 42,
          left: 65 + (6 * maxString),
          right: 10,
          top: 10
        }}
        tooltip={(point) => {
          return (
            <DataTooltip>
              {`${point.data[indexBy]} - ${thousandDivider(keyTarget === "Cnts" ? point.data[keyTarget] : (point.data[keyTarget] / 100000).toFixed(3))} ${keyTarget === "Cnts" ? "家" : (min / 100000) > 1 ? '億元' : '千元'}`}
            </DataTooltip>
          )
        }}
        valueScale={{ type: "linear" }}
        colors={colorArray}
        colorBy="index"
        layout="horizontal"
        animate={true}
        enableLabel={false}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          tickValues: 5,
          legend: keyTarget === "Cnts" ? "家" : (min / 100000) > 1 ? '億元' : '千元',
          legendPosition: "middle",
          legendOffset: 30,
          format: function(value){ 
            return (min / 100000) > 1 ? thousandDivider(value / 100000) : thousandDivider(value);
          }
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: parseIndex[indexBy],
          legendPosition: "middle",
          legendOffset: -60 + -(4 * maxString),
          format: function(value){ 
            if (indexBy === "CompanyName") {
              return value.replace("股份有限公司", "");
            }
            return value
          }
        }}
      />
    </div>
  )
};

const ColumnBox = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  box-shadow: 2px 2px 6px 2px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  height: 100%;
  margin: ${props => props.marginCustom || "0px"};
  background: ${props => props.bgColor || "none"};
  color: ${props => props.colorCustom || "none"};
  .subTitle {
    font-weight: bold !important;
  }
  p {
    line-height: 1.5;
  }
  .unit {
    margin-left: 8px;
  }
`;
const DataTooltip = styled.div`
  background: white;
  padding: 8px 12px;
  border: 1px solid #ccc;
`;

export default HorizontalBarChart;
