import * as actionTypes from "./actionTypes";
import * as actionType from "../actionTypes/subscribe";

import {
  fetchAccountSubscribeGetNews,
  fetchAccountSubscribeGetCompanyForeignTrade,
  fetchAccountSubscribeGetCompanyStockRevenue,
  fetchAccountSubscribeGetCompanyPatent,
  fetchAccountSubscribeGetCompanyGovProject,
  fetchAccountSubscribeIndustryList,
  fetchAccountSubscribeNews,
  fetchAccountStockRevenue,
  fetchAccountSubscribeForeignTrade,
  fetchAccountSubscribePatent,
  fetchAccountSubscribeGovProject
} from "../../utility/subscribeApi";

export const startGetCompany = () => {
  return {
    type: actionType.START_GET_COMPANY_INFO,
  };
};

export const getCompanySuccess = (data) => {
  return {
    type: actionType.GET_COMPANY_INFO_SUCCESS,
    data: data,
  };
};

export const getCompanyFail = (err) => {
  return {
    type: actionType.GET_COMPANY_INFO_FAIL,
    error: err,
  };
};

let promisesMap = { 
  News: Promise.resolve('News'), 
  ForeignTrade: Promise.resolve('ForeignTrade'), 
  StockRevenue: Promise.resolve('StockRevenue'),
  Patent: Promise.resolve('Patent'),
  GovProject: Promise.resolve('GovProject')
};
export const handleGetCompany = (userId) => {
  return (dispatch) => {
    dispatch(startGetCompany());
    Promise.all([
      fetchAccountSubscribeGetNews(userId),
      fetchAccountSubscribeGetCompanyForeignTrade(userId),
      fetchAccountSubscribeGetCompanyStockRevenue(userId),
      fetchAccountSubscribeGetCompanyPatent(userId),
      fetchAccountSubscribeGetCompanyGovProject(userId),
    ])
      .then((res) => {
        let obj = {};
        let keys = Object.keys(promisesMap);
        
        for(let i = 0; i < res.length; i++) {
          obj[keys[i]] = res[i];
        }
        if (obj) {
          dispatch(getCompanySuccess(obj));
        } else {
          dispatch(getCompanyFail("取得資料時發生問題。"));
        }
      })
      .catch((err) => {
        dispatch(getCompanyFail(err));
      });
  };
};

export const startGetIndustry = () => {
  return {
    type: actionType.START_GET_INDUSTRY_INFO,
  };
};

export const getIndustrySuccess = (data) => {
  return {
    type: actionType.GET_INDUSTRY_INFO_SUCCESS,
    data: data,
  };
};

export const getIndustryFail = (err) => {
  return {
    type: actionType.GET_INDUSTRY_INFO_FAIL,
    error: err,
  };
};

let promisesIndustryMap = { 
  IndustryList: Promise.resolve('IndustryList'),
  News: Promise.resolve('News'), 
  ForeignTrade: Promise.resolve('ForeignTrade'), 
  StockRevenue: Promise.resolve('StockRevenue'),
  Patent: Promise.resolve('Patent'),
  GovProject: Promise.resolve('GovProject')
};
export const handleGetIndustry = (userId) => {
  return (dispatch) => {
    dispatch(startGetIndustry());
    Promise.all([
      fetchAccountSubscribeIndustryList(userId),
      fetchAccountSubscribeNews(userId),
      fetchAccountSubscribeForeignTrade(userId),
      fetchAccountStockRevenue(userId),
      fetchAccountSubscribePatent(userId),
      fetchAccountSubscribeGovProject(userId)
    ])
      .then((res) => {
        let obj = {};
        let keys = Object.keys(promisesIndustryMap);
        
        for(let i = 0; i < res.length; i++) {
          obj[keys[i]] = res[i];
        }
        if (obj) {
          dispatch(getIndustrySuccess(obj));
        } else {
          dispatch(getIndustryFail("取得資料時發生問題。"));
        }
      })
      .catch((err) => {
        dispatch(getIndustryFail(err));
      });
  };
};
