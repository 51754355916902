import styled from "styled-components";

export const UpdateCompanyContainer = styled.div`
  min-height: 90vh;
  padding-top: 20px;
`;

export const UpdateCompanyContainerHeader = styled.div`
  position: relative;
`;

export const UpdateCompanyContainerHeaderContain = styled.div`
  position: absolute;
  left: 20px;
  top: 10px;
  z-index: 20;
`;

export const UpdateCompanyContainerHeaderText = styled.h2`
  font-size: 0.625rem;
  font-weight: 600;
  background: var(--main-color);
  color: #f7f3f3;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px -5px rgba(0, 0, 0, 0.8);
  cursor: pointer;
`;

export const UpdateCompanyContainerHeaderImage = styled.img`
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 40vh;
  background-size: cover;
  border: 0;
`;

export const UpdateCompanyContainerBody = styled.div`
  position: relative;
  top: 120px;
  z-index: 50px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 1024px) {
    flex-direction: column;
    top: 80px;
  }
`;

export const UpdateCompanyContainerInnerCard = styled.div`
  background: #fff;
  width: 350px;
  height: 350px;
  box-shadow: 0px 0px 10px -4px #757575;
  margin: 0px 2px;
  padding: 10px;
  border-radius: 2px;
  transition: 0.5s;
  cursor: pointer;

  &:hover {
    background: #ff746f;
    width: 360px;
    height: 360px;
    transition: 0.5s;
    > div {
      .svg-inline--fa {
        font-size: 2.18 rem;
        color: #fff;
      }
      > h2 {
        color: #fff;
      }
      > p {
        color: #fff;
      }
    }
  }

  @media (max-width: 1024px) {
    width: 80%;
    margin-bottom: 20px;
  }
`;

export const UpdateCompanyContainerInnerCardIcons = styled.div`
  display: flex;
  justify-content: center;
  padding: 10px 0px;
  .svg-inline--fa {
    font-size: 2.125rem;
    color: #ff746f;
  }
`;

export const UpdateCompanyContainerInnerCardHeader = styled.div`
  padding: 10px 0px;
`;

export const UpdateCompanyContainerInnerCardHeaderText = styled.h2`
  font-size: 1.25rem;
  font-weight: 600;
`;

export const UpdateCompanyContainerInnerCardContent = styled.div`
  padding: 0px 10px 0px 0px;
  height: 230px;
  line-height: 20px;
  overflow-y: scroll;
`;

export const UpdateCompanyContainerInnerCardContentText = styled.p`
  padding: 1.5px 0px;
  font-weight: 400;
  white-space: pre-wrap;
`;

export const UpdateCompanyContainerInnerCardContentTextSpan = styled.span`
  font-weight: 600;
  text-decoration: ${p => p.isLink ? "underline" : ""};
  color: ${p => p.isLink ? "#006ab4" : ""};
`;
