import React from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";

import "./styles.scss";

export default props => {
    return (
        <TableRow key={"no-data"} hover className="table-body-row">
            <TableCell align="right" style={{ fontSize: "1.25rem", fontWeight: "600", color: "#757575" }}>本項目無資料。</TableCell>
            <TableCell align="left"></TableCell>
        </TableRow>
    )
}