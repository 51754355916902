import React, { useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import { activeAccount } from "../../utility/api";

const MySwal = withReactContent(Swal);

export default (props) => {
  const history = useHistory();
  const account = props.match.params.params;

  if (account) {
    activeAccount(account)
      .then((res) => {
        if (res.data) {
          MySwal.fire({
            text: "您的帳號已啟用成功，請點選登入進入本站。",
          }).then(() => {
            history.push("/portal");
          });
        } else {
          MySwal.fire({
            text: "帳號啟用發生錯誤，請重新註冊或聯繫窗口陳小姐 07-3513121 分機 2415。",
          }).then(() => {
            history.push("/portal");
          });
        }
      })
      .catch((err) => {
        MySwal.fire({
          text: "帳號啟用發生錯誤，請重新註冊或聯繫窗口陳小姐 07-3513121 分機 2415。",
        }).then(() => {
          history.push("/portal");
        });
      });
  } else {
    history.push("/portal");
  }

  return <div></div>;
};
