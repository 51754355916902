import React from "react";
import Chart from "react-apexcharts";
import NoData from "../../Nodata/NoData";
import { thousandDivider } from "../../../utility/common";


const CustomizedGraphic = (props) => {
  const { chartType, labels, data, unit, formatter, mapData, isGovPlanOrAwardPercentage, skip } = props;
  const defualtFontSize = {
    fontSize: '18px',
    fontFamily: 'Noto Sans TC'
  };

  if (data && data.length === 0) {
    return <NoData />
  } else {
    switch (chartType) {
      case "donut-chart":
        const options = {
          id: "donut",
          labels: labels ? labels : false,
          series: data,
          chart: {
            type: "pie",
            events: props.eventHandler
              ? {
                click: (event, chartContext, config) => {
                  try {
                    if (isGovPlanOrAwardPercentage) {
                      props.eventHandler(config.globals.labels[event.target.attributes["j"].value], config.globals.labels[event.target.attributes["j"].value])
                    } else {
                      const updateValue = mapData.filter(data => data.label === config.globals.labels[event.target.attributes["j"].value])
                      props.eventHandler(updateValue[0].tag, config.globals.labels[event.target.attributes["j"].value]);
                    }
                  } catch (err) {
                    props.eventHandler("");
                  }
                },
              }
              : {
                click: () => {
                  return;
                },
              },
          },
          dataLabels: props.dataLabels ? props.dataLabels : { enabled: false },
          plotOptions: {
            pie: {
              expandOnClick: true,
              offsetY: props.offSetY ? props.offSetY : 0,
              customScale: 0.9,
              donut: {
                size: props.circle ? `${props.circle}%` : "0%",
                labels: {
                  total: {
                    showAlways: false,
                    label: "Total",
                  },
                },
              },
            },
          },
          xaxis: {
            labels: {
              style: {
                fontSize: "18px",
                fontFamily: "Noto Sans TC",
              },
            },
          },
          yaxis: {
            labels: {
              style: {
                fontSize: "18px",
                fontFamily: "Noto Sans TC",
              },
            },
          },
          legend: {
            show: props.isLegend ? true : false,
          },
        };

        return (
          <Chart
            options={options}
            series={data}
            labels={labels ? labels : false}
            type="donut"
          />
        );

      case "vertical-bar-chart":
        const verticalBarOptions = {
          dataLabels: {
            enabled: false,
          },
          chart: {
            toolbar: {
              show: true,
            },
            events: props.eventHandler
              ? {
                click: (event, chartContext, config) => {
                  try {
                    const updateValue = mapData.filter(data => data.label.slice(0, 4) === config.globals.labels[event.target.attributes["j"].value])
                    props.eventHandler(updateValue[0].tag);
                  } catch (err) {
                    props.eventHandler("");
                  }
                },
              }
              : {
                click: () => {
                  return;
                },
              },
          },
          tooltip: {
            enabled: true,
          },
          xaxis: {
            categories: labels.map(item => item.slice(0, 4)),
          },
          yaxis: {
            labels: {
              style: {
                ///
              },
              formatter: formatter,
            },
          },
          fill: { colors: props.color },
          plotOptions: {
            bar: {
              endingShape: "flat",
              columnWidth: props.width ? props.width : "70%",
              distributed: false,
            },
          },
        };

        return (
          <Chart
            options={verticalBarOptions}
            series={[{ data: data, name: unit }]}
            type="bar"
          />
        );

      case "horizontal-bar-chart":
        const horizontalBarOptions = {
          dataLabels: {
            enabled: false,
          },
          chart: {
            toolbar: {
              show: true,
            },
            events: props.eventHandler
              ? {
                click: (event, chartContext, config) => {
                  try {
                    const updateValue = mapData.filter(data => data.label === config.globals.labels[event.target.attributes["j"].value])

                    props.eventHandler(updateValue, config.globals.labels[event.target.attributes["j"].value]);
                  } catch (err) {
                    props.eventHandler("");
                  }
                },
              }
              : {
                click: () => {
                  return;
                },
              },
          },
          tooltip: {
            enabled: true,
          },
          xaxis: {
            categories: labels,
          },
          yaxis: {
            labels: {
              align: "left",
              // maxWidth: 50,
              style: {
                cssClass: "horizontal-bar-chart-yaxis"

              },
              formatter: formatter,
            },
          },
          fill: { colors: props.color },
          plotOptions: {
            bar: {
              horizontal: true,
              endingShape: "flat",
              columnWidth: props.width ? props.width : "70%",
              distributed: false,
            },
          },
        };

        return (
          <Chart
            options={horizontalBarOptions}
            series={[{ data: data, name: unit }]}
            type="bar"
          />
        );

      case "pie-chart-with-legend":
        const pieChartWithLegendOptions = {
          id: "donut",
          labels: labels ? labels : false,
          series: data,
          chart: {
            type: "pie",
            events: props.eventHandler
              ? {
                click: (event, chartContext, config) => {
                  try {
                    props.eventHandler(
                      config.globals.labels[event.target.attributes["j"].value]
                    );
                  } catch (err) {
                    props.eventHandler("");
                  }
                },
              }
              : {
                click: () => {
                  return;
                },
              },
          },
          dataLabels: props.dataLabels ? props.dataLabels : { enabled: false },
          plotOptions: {
            pie: {
              expandOnClick: true,
              offsetY: props.offSetY ? props.offSetY : 0,
              customScale: 0.9,
              donut: {
                size: props.circle ? `${props.circle}%` : "0%",
                labels: {
                  total: {
                    showAlways: false,
                    label: "Total",
                  },
                },
              },
            },
          },
          xaxis: {
            labels: {
              style: {
                fontSize: "18px",
                fontFamily: "Noto Sans TC",
              },
            },
          },
          yaxis: {
            labels: {
              style: {
                fontSize: "18px",
                fontFamily: "Noto Sans TC",
              },
            },
          },
          legend: {
            show: true,
            position: 'bottom'
          },
        };

        return (
          <Chart
            options={pieChartWithLegendOptions}
            series={data}
            labels={labels ? labels : false}
            type="pie"
          />
        );

      case "curve-chart":
        const curveChartOptoins = {
          title: { text: unit, offsetY: 10, },
          chart: {
            toolbar: {
              show: true
            },
            zoom: false,
            width: `${props.fixedWidth ? props.fixedWidth : "100%"
              }`
          },
          dataLabels: {
            enabled: skip !== "1",
            formatter: (value) => thousandDivider(value),
          },
          tooltip: {
            enabled: true,
            style: defualtFontSize
          },
          // stroke: {
          //   curve: 'smooth'
          // },
          xaxis: props.xaxis ? {
            tickAmount: skip == "1" ? Math.ceil(props.xaxis[0].length / 2) : props.xaxis[0].length,
            type: props.xaxis[0],
            categories: props.xaxis[1],
            labels: {
              style: defualtFontSize
            }
          } : {
            type: "datetime",
            categories: [
              "2018-09-19T00:00:00",
              "2018-09-19T01:30:00",
              "2018-09-19T02:30:00",
              "2018-09-19T03:30:00",
              "2018-09-19T04:30:00",
            ],
            labels: {
              style: defualtFontSize
            }
          },
          yaxis: {
            labels: {
              style: defualtFontSize,
              formatter: (value) => { return thousandDivider(value.toFixed(0)) }
            },
            // title: {
            //   text: unit,
            //   style: {
            //     fontSize: "15px"
            //   }
            // }
          }
        };

        return (
          <Chart
            options={curveChartOptoins}
            series={[{ name: "", data: data }]}
            type="area"
            height={props.height}
          />
        );


      default:
        return "Something went wrong when generating the charts";
    }

  }
};

export default React.memo(CustomizedGraphic)
