import React, { useState } from 'react'
import { Icon } from '@material-ui/core'
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import Iframe from 'react-iframe'


import './styles.scss'

const EnMonitor = props => {
    const urlEnMonitor = "https://indupark.smartpark.com.tw/evimonitor/EnMonitor.aspx"
    const urlToxic = "https://indupark.smartpark.com.tw/evimonitor/ToxicSubstances.aspx"
 
    const [toggleIframe, setToggleIframe] = useState(true)


    return (
      <div className="en-monitor">
        <div className="en-monitor-title">
          <h1>台南科技工業區環境監測儀表板</h1>
          <div>
            <ToggleButtonGroup
              //   value={tabValue}
              onChange={() => {
                setToggleIframe(!toggleIframe);
              }}
              exclusive
            >
              <ToggleButton disabled={toggleIframe}>
                <Icon>toys</Icon>
              </ToggleButton>
              <ToggleButton disabled={!toggleIframe}>
                <Icon>report</Icon>
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
        </div>
        <div className="en-monitor-content">
          <Iframe url={toggleIframe ? urlEnMonitor : urlToxic} />
        </div>
        {/* <CustomizedMap mapSize={mapStlye}/> */}
      </div>
    );
}

export default EnMonitor