import axios from "axios";
import axiosInstance from "./axiosWithRefreshToken"
import dataURLtoBlob from "blueimp-canvas-to-blob";
import { baseUrl, loginbaseUrl } from "../constants/endpoints";
import { encryptAES, decryptAES } from "./common";

const headers = {
  "Access-Control-Allow-Origin": "*",
  "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
  "Access-Control-Allow-Headers":
    "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers",
  "Content-Type": "application/json",
  Authorization: localStorage.getItem("acToken"),
};
const InduParkWebAcTokenheaders = {
  "Access-Control-Allow-Origin": "*",
  "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
  "Access-Control-Allow-Headers":
    "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers",
  "Content-Type": "application/json",
  Authorization: localStorage.getItem("InduParkWebAcToken"),
};

//搜尋稅籍廠商基本資料
export const taxInfoSearchHandler = (KeyWordList, MinCapital, MaxCapital, Operator, ProductFourCode, TexCode, IndustryTwoCode, IndustryThreeCode, IndustryCode, CityCode) => {
  const data = new FormData();
  data.append("KeyWordList", KeyWordList);
  data.append("MinCapital", parseFloat(MinCapital));
  data.append("MaxCapital", parseFloat(MaxCapital));
  data.append("Operator", Operator);
  data.append("ProductFourCode", ProductFourCode);
  data.append("TexCode", TexCode);
  data.append("IndustryTwoCodeList", IndustryTwoCode);
  data.append("IndustryThreeCodeList", IndustryThreeCode);
  data.append("IndustryCode", IndustryCode);
  data.append("CityCode", CityCode);

  return axios({
    proxy: true,
    method: "post",
    url: `${baseUrl}/api/CompanySearch/Search`,
    data: data,
  })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return "error";
    });
};

//出口產品四碼清單
export const exportProductFourCodeListHandler = () => {
  return axios({
    proxy: true,
    method: "get",
    url: `${baseUrl}/api/CompanySearch/ExportProductFourCodeList`,
  })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return "error";
    });
};

//稅籍產業選單
export const TaxIndustryListHandler = () => {
  return axios({
    proxy: true,
    method: "get",
    url: `${baseUrl}/api/Company/GetTaxIndustryList`,
  })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return "error";
    });
};

//工廠二碼選單
export const TypeTwoListHandler = () => {
  return axiosInstance({
    proxy: true,
    method: "get",
    url: `${baseUrl}/api/CompanySearch/GetIndustryTypes_Two`,
    headers: InduParkWebAcTokenheaders
  })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return "error";
    });
};

//工廠三碼選單
export const TypeThreeListHandler = () => {
  return axiosInstance({
    proxy: true,
    method: "get",
    url: `${baseUrl}/api/CompanySearch/GetIndustryTypes_Three`,
    headers: InduParkWebAcTokenheaders
  })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return "error";
    });
};