import * as actionTypes from "../actionTypes/IndustryDashboard";
import {
  getIndustryInfo,
  getTradesInfo,
  getStockCompanyRevenueTrendApi,
  getExportCompanyRevenueTrendApi,
  getTopCompanyIndustry,
  getListedIndustryCompany,
  getPubinvention,
  getPatentDatas,
  getTypeFourCode,
  fetchStockCompanyRevenue,
  fetchExportCompanyRevenue,
  fetchOperationMainIndexListData,
} from "../../utility/IndustryDashboardApi";

//產業上市櫃公司營收漲跌家數
export const startGetStockCompanyRevenue = () => {
  return {
    type: actionTypes.START_GET_STOCK_COMPANY_REVENUE
  }
};

export const getStockCompanyRevenueSuccess = data => {
  return {
    type: actionTypes.GET_STOCK_COMPANY_REVENUE_SUCCESS,
    data: data
  }
};

export const getStockCompanyRevenueFail = err => {
  return {
    type: actionTypes.GET_STOCK_COMPANY_REVENUE_FAIL,
    err: err
  }
};

export const getStockCompanyRevenueHandler = (IndustryCode) => {
  return dispatch => {
    dispatch(startGetStockCompanyRevenue());
    fetchStockCompanyRevenue(IndustryCode)
      .then(res => {
        if (res) {
          dispatch(getStockCompanyRevenueSuccess(res))
        } else {
          dispatch(getStockCompanyRevenueFail("取得縣市廠商產四碼家數佔比時發生問題。"))
        }
      })
      .catch(err => {
        dispatch(getStockCompanyRevenueFail(err))
      })
  }
}

//產業出口級距漲跌家數
export const startGetExportCompanyRevenue = () => {
  return {
    type: actionTypes.START_GET_EXPORT_COMPANY_REVENUE
  }
};

export const getExportCompanyRevenueSuccess = data => {
  return {
    type: actionTypes.GET_EXPORT_COMPANY_REVENUE_SUCCESS,
    data: data
  }
};

export const getExportCompanyRevenueFail = err => {
  return {
    type: actionTypes.GET_EXPORT_COMPANY_REVENUE_FAIL,
    err: err
  }
};

export const getExportCompanyRevenueHandler = (IndustryCode) => {
  return dispatch => {
    dispatch(startGetExportCompanyRevenue());
    fetchExportCompanyRevenue(IndustryCode)
      .then(res => {
        if (res) {
          dispatch(getExportCompanyRevenueSuccess(res))
        } else {
          dispatch(getExportCompanyRevenueFail("取得縣市廠商產四碼家數佔比時發生問題。"))
        }
      })
      .catch(err => {
        dispatch(getExportCompanyRevenueFail(err))
      })
  }
}

//產業儀錶板營運動態列表
export const startOperationMainIndexListData = () => {
  return {
    type: actionTypes.START_GET_OPERATION_MAIN_INDEX_LIST_DATA,
  };
};

export const getOperationMainIndexListDataSuccess = (data) => {
  return {
    type: actionTypes.GET_OPERATION_MAIN_INDEX_LIST_DATA_SUCCESS,
    data: data,
  };
};

export const getOperationMainIndexListDataFail = (err) => {
  return {
    type: actionTypes.GET_OPERATION_MAIN_INDEX_LIST_DATA_FAIL,
    err: err,
  };
};

export const getOperationMainIndexListDataHandler = (Area) => {
  return (dispatch) => {
    dispatch(startOperationMainIndexListData());
    fetchOperationMainIndexListData()
      .then((res) => {
        if (res) {
          dispatch(getOperationMainIndexListDataSuccess(res));
        } else {
          dispatch(
            getOperationMainIndexListDataFail("取得地區轄下園區列表資料時發生問題。")
          );
        }
      })
      .catch((err) => {
        dispatch(getOperationMainIndexListDataFail(err));
      });
  };
};

//取得最新數據
export const startGetInfo = () => {
  return {
    type: actionTypes.START_GET_INFO,
  };
};

export const getInfoSuccess = (data) => {
  return {
    type: actionTypes.GET_INFO_SUCCESS,
    data: data,
  };
};

export const getInfoFail = (err) => {
  return {
    type: actionTypes.GET_INFO_FAIL,
    err: err,
  };
};

export const getInfo = (industryCodes) => {
  return (dispatch) => {
    dispatch(startGetInfo());
    getIndustryInfo(industryCodes)
      .then((res) => {
        if (res) {
          dispatch(getInfoSuccess(res));
        } else {
          dispatch(getInfoFail("取得最新數據時發生問題。"));
        }
      })
      .catch((err) => {
        dispatch(getInfoFail(err));
      });
  };
};

//產業上市櫃公司營收趨勢圖
export const startGetStockCompanyRevenueTrend = () => {
  return {
    type: actionTypes.START_GET_STOCK_COMPANY_REVENUE_TREND,
  };
};

export const getStockCompanyRevenueTrendSuccess = (data) => {
  return {
    type: actionTypes.GET_STOCK_COMPANY_REVENUE_TREND_SUCCESS,
    data: data,
  };
};

export const getStockCompanyRevenueTrendFail = (err) => {
  return {
    type: actionTypes.GET_STOCK_COMPANY_REVENUE_TREND_FAIL,
    err: err,
  };
};

export const getStockCompanyRevenueTrend = (industryCodes) => {
  return (dispatch) => {
    dispatch(startGetStockCompanyRevenueTrend());
    getStockCompanyRevenueTrendApi(industryCodes)
      .then((res) => {
        if (res) {
          dispatch(getStockCompanyRevenueTrendSuccess(res));
        } else {
          dispatch(getStockCompanyRevenueTrendFail("取得產業產值趨勢(千元)時發生問題。"));
        }
      })
      .catch((err) => {
        dispatch(getStockCompanyRevenueTrendFail(err));
      });
  };
};

//產業出口級距趨勢圖
export const startGetExportCompanyRevenueTrend = () => {
  return {
    type: actionTypes.START_GET_EXPORT_COMPANY_REVENUE_TREND,
  };
};

export const getExportCompanyRevenueTrendSuccess = (data) => {
  return {
    type: actionTypes.GET_EXPORT_COMPANY_REVENUE_TREND_SUCCESS,
    data: data,
  };
};

export const getExportCompanyRevenueTrendFail = (err) => {
  return {
    type: actionTypes.GET_EXPORT_COMPANY_REVENUE_TREND_FAIL,
    err: err,
  };
};

export const getExportCompanyRevenueTrend = (industryCodes) => {
  return (dispatch) => {
    dispatch(startGetExportCompanyRevenueTrend());
    getExportCompanyRevenueTrendApi(industryCodes)
      .then((res) => {
        if (res) {
          dispatch(getExportCompanyRevenueTrendSuccess(res));
        } else {
          dispatch(getExportCompanyRevenueTrendFail("取得產業產值趨勢(千元)時發生問題。"));
        }
      })
      .catch((err) => {
        dispatch(getExportCompanyRevenueTrendFail(err));
      });
  };
};

//產業產值趨勢(千元)
export const startGetProductVal = () => {
  return {
    type: actionTypes.START_GET_PRODUCT_VAL,
  };
};

export const getProductValSuccess = (data) => {
  return {
    type: actionTypes.GET_PRODUCT_VAL_SUCCESS,
    data: data,
  };
};

export const getProductValFail = (err) => {
  return {
    type: actionTypes.GET_PRODUCT_VAL_FAIL,
    err: err,
  };
};

export const getProductVal = (industryCodes) => {
  return (dispatch) => {
    dispatch(startGetProductVal());
    getTradesInfo("ProductVal", industryCodes)
      .then((res) => {
        if (res) {
          dispatch(getProductValSuccess(res));
        } else {
          dispatch(getProductValFail("取得產業產值趨勢(千元)時發生問題。"));
        }
      })
      .catch((err) => {
        dispatch(getProductValFail(err));
      });
  };
};

//全年固定資產投資金額趨勢(千元)
export const startGetInvestmentAmt = () => {
  return {
    type: actionTypes.START_GET_INVESTMENT_AMT,
  };
};

export const getInvestmentAmtSuccess = (data) => {
  return {
    type: actionTypes.GET_INVESTMENT_AMT_SUCCESS,
    data: data,
  };
};

export const getInvestmentAmtFail = (err) => {
  return {
    type: actionTypes.GET_INVESTMENT_AMT_FAIL,
    err: err,
  };
};

export const getInvestmentAmt = (industryCodes) => {
  return (dispatch) => {
    dispatch(startGetInvestmentAmt());
    getTradesInfo("InvestmentAmt", industryCodes)
      .then((res) => {
        if (res) {
          dispatch(getInvestmentAmtSuccess(res));
        } else {
          dispatch(
            getInvestmentAmtFail(
              "取得全年固定資產投資金額趨勢(千元)時發生問題。"
            )
          );
        }
      })
      .catch((err) => {
        dispatch(getInvestmentAmtFail(err));
      });
  };
};

//營運中工廠家數趨勢
export const startGetFactoryCount = () => {
  return {
    type: actionTypes.START_GET_FACTORY_COUNT,
  };
};

export const getFactoryCountSuccess = (data) => {
  return {
    type: actionTypes.GET_FACTORY_COUNT_SUCCESS,
    data: data,
  };
};

export const getFactoryCountFail = (err) => {
  return {
    type: actionTypes.GET_FACTORY_COUNT_FAIL,
    err: err,
  };
};

export const getFactoryCount = (industryCodes) => {
  return (dispatch) => {
    dispatch(startGetFactoryCount());
    getTradesInfo("FactoryCount", industryCodes)
      .then((res) => {
        if (res) {
          dispatch(getFactoryCountSuccess(res));
        } else {
          dispatch(getFactoryCountFail("取得營運中工廠家數趨勢時發生問題。"));
        }
      })
      .catch((err) => {
        dispatch(getFactoryCountFail(err));
      });
  };
};

//年度從業員工人數趨勢
export const startGetEmpCount = () => {
  return {
    type: actionTypes.START_GET_EMP_COUNT,
  };
};

export const getEmpCountSuccess = (data) => {
  return {
    type: actionTypes.GET_EMP_COUNT_SUCCESS,
    data: data,
  };
};

export const getEmpCountFail = (err) => {
  return {
    type: actionTypes.GET_EMP_COUNT_FAIL,
    err: err,
  };
};

export const getEmpCount = (industryCodes) => {
  return (dispatch) => {
    dispatch(startGetEmpCount());
    getTradesInfo("EmpCount", industryCodes)
      .then((res) => {
        if (res) {
          dispatch(getEmpCountSuccess(res));
        } else {
          dispatch(getEmpCountFail("取得年度從業員工人數趨勢時發生問題。"));
        }
      })
      .catch((err) => {
        dispatch(getEmpCountFail(err));
      });
  };
};

//研究發展工廠家數
export const startGetResearchFactoryCount = () => {
  return {
    type: actionTypes.START_GET_RESEARCH_FACTORY_COUNT,
  };
};

export const getResearchFactoryCountSuccess = (data) => {
  return {
    type: actionTypes.GET_RESEARCH_FACTORY_COUNT_SUCCESS,
    data: data,
  };
};

export const getResearchFactoryCountFail = (err) => {
  return {
    type: actionTypes.GET_RESEARCH_FACTORY_COUNT_FAIL,
    err: err,
  };
};

export const getResearchFactoryCount = (industryCodes) => {
  return (dispatch) => {
    dispatch(startGetResearchFactoryCount());
    getTradesInfo("ResearchFactoryCount", industryCodes)
      .then((res) => {
        if (res) {
          dispatch(getResearchFactoryCountSuccess(res));
        } else {
          dispatch(
            getResearchFactoryCountFail("取得研究發展工廠家數時發生問題。")
          );
        }
      })
      .catch((err) => {
        dispatch(getResearchFactoryCountFail(err));
      });
  };
};

//技術購買金額趨勢(千元)
export const startGetTechBuyAmt = () => {
  return {
    type: actionTypes.START_GET_TECH_BUY_AMT,
  };
};

export const getTechBuyAmtSuccess = (data) => {
  return {
    type: actionTypes.GET_TECH_BUY_AMT_SUCCESS,
    data: data,
  };
};

export const getTechBuyAmtFail = (err) => {
  return {
    type: actionTypes.GET_TECH_BUY_AMT_FAIL,
    err: err,
  };
};

export const getTechBuyAmt = (industryCodes) => {
  return (dispatch) => {
    dispatch(startGetTechBuyAmt());
    getTradesInfo("TechBuyAmt", industryCodes)
      .then((res) => {
        if (res) {
          dispatch(getTechBuyAmtSuccess(res));
        } else {
          dispatch(getTechBuyAmtFail("取得技術購買金額趨勢(千元)時發生問題。"));
        }
      })
      .catch((err) => {
        dispatch(getTechBuyAmtFail(err));
      });
  };
};

//研究發展經費(千元)
export const startGetResearchAmt = () => {
  return {
    type: actionTypes.START_GET_RESEARCH_AMT,
  };
};

export const getResearchAmtSuccess = (data) => {
  return {
    type: actionTypes.GET_RESEARCH_AMT_SUCCESS,
    data: data,
  };
};

export const getResearchAmtFail = (err) => {
  return {
    type: actionTypes.GET_RESEARCH_AMT_FAIL,
    err: err,
  };
};

export const getResearchAmt = (industryCodes) => {
  return (dispatch) => {
    dispatch(startGetResearchAmt());
    getTradesInfo("ResearchAmt", industryCodes)
      .then((res) => {
        if (res) {
          dispatch(getResearchAmtSuccess(res));
        } else {
          dispatch(getResearchAmtFail("取得研究發展經費(千元)時發生問題。"));
        }
      })
      .catch((err) => {
        dispatch(getResearchAmtFail(err));
      });
  };
};

//技術銷售金額趨勢(千元)
export const startGetTechSaleAmt = () => {
  return {
    type: actionTypes.START_GET_TECH_SALE_AMT,
  };
};

export const getTechSaleAmtSuccess = (data) => {
  return {
    type: actionTypes.GET_TECH_SALE_AMT_SUCCESS,
    data: data,
  };
};

export const getTechSaleAmtFail = (err) => {
  return {
    type: actionTypes.GET_TECH_SALE_AMT_FAIL,
    err: err,
  };
};

export const getTechSaleAmt = (industryCodes) => {
  return (dispatch) => {
    dispatch(startGetTechSaleAmt());
    getTradesInfo("TechSaleAmt", industryCodes)
      .then((res) => {
        if (res) {
          dispatch(getTechSaleAmtSuccess(res));
        } else {
          dispatch(
            getTechSaleAmtFail("取得技術銷售金額趨勢(千元)時發生問題。")
          );
        }
      })
      .catch((err) => {
        dispatch(getTechSaleAmtFail(err));
      });
  };
};

//營業收入(千元)
export const startGetRevenueIncome = () => {
  return {
    type: actionTypes.START_GET_REVENUE_INCOME,
  };
};

export const getRevenueIncomeSuccess = (data) => {
  return {
    type: actionTypes.GET_REVENUE_INCOME_SUCCESS,
    data: data,
  };
};

export const getRevenueIncomeFail = (err) => {
  return {
    type: actionTypes.GET_REVENUE_INCOME_FAIL,
    err: err,
  };
};

export const getRevenueIncome = (industryCodes) => {
  return (dispatch) => {
    dispatch(startGetRevenueIncome());
    getTradesInfo("RevenueIncome", industryCodes)
      .then((res) => {
        if (res) {
          dispatch(getRevenueIncomeSuccess(res));
        } else {
          dispatch(getRevenueIncomeFail("取得研究發展經費(千元)時發生問題。"));
        }
      })
      .catch((err) => {
        dispatch(getRevenueIncomeFail(err));
      });
  };
};

//營業支出(千元)
export const startGetRevenuePay = () => {
  return {
    type: actionTypes.START_GET_REVENUE_PAY,
  };
};

export const getRevenuePaySuccess = (data) => {
  return {
    type: actionTypes.GET_REVENUE_PAY_SUCCESS,
    data: data,
  };
};

export const getRevenuePayFail = (err) => {
  return {
    type: actionTypes.GET_REVENUE_PAY_FAIL,
    err: err,
  };
};

export const getRevenuePay = (industryCodes) => {
  return (dispatch) => {
    dispatch(startGetRevenuePay());
    getTradesInfo("RevenuePay", industryCodes)
      .then((res) => {
        if (res) {
          dispatch(getRevenuePaySuccess(res));
        } else {
          dispatch(getRevenuePayFail("取得營業支出(千元)時發生問題。"));
        }
      })
      .catch((err) => {
        dispatch(getRevenuePayFail(err));
      });
  };
};

//產業前三十大資本額廠商清單
export const startGetTop30Company = () => {
  return {
    type: actionTypes.START_GET_TOP30_COMPANY,
  };
};

export const getTop30CompanySuccess = (data) => {
  return {
    type: actionTypes.GET_TOP30_COMPANY_SUCCESS,
    data: data,
  };
};

export const getTop30CompanyFail = (err) => {
  return {
    type: actionTypes.GET_TOP30_COMPANY_FAIL,
    err: err,
  };
};

export const getTop30Company = (industryCodes) => {
  return (dispatch) => {
    dispatch(startGetTop30Company());
    getTopCompanyIndustry(industryCodes)
      .then((res) => {
        if (res) {
          dispatch(getTop30CompanySuccess(res));
        } else {
          dispatch(
            getTop30CompanyFail("取得產業前三十大資本額廠商清單時發生問題。")
          );
        }
      })
      .catch((err) => {
        dispatch(getTop30CompanyFail(err));
      });
  };
};

//產業上市櫃廠商
export const startGetListedCompany = () => {
  return {
    type: actionTypes.START_GET_LISTED_COMPANY,
  };
};

export const getListedCompanySuccess = (data) => {
  return {
    type: actionTypes.GET_LISTED_COMPANY_SUCCESS,
    data: data,
  };
};

export const getListedCompanyFail = (err) => {
  return {
    type: actionTypes.GET_LISTED_COMPANY_FAIL,
    err: err,
  };
};

export const getListedCompany = (industryCodes) => {
  return (dispatch) => {
    dispatch(startGetListedCompany());
    getListedIndustryCompany(industryCodes)
      .then((res) => {
        if (res) {
          dispatch(getListedCompanySuccess(res));
        } else {
          dispatch(getListedCompanyFail("取得產業上市櫃廠商時發生問題。"));
        }
      })
      .catch((err) => {
        dispatch(getListedCompanyFail(err));
      });
  };
};

//發明公開(5年內)-全國
export const startGetPubinvention = () => {
  return {
    type: actionTypes.START_GET_PUBINVENTION,
  };
};

export const getPubinventionSuccess = (data) => {
  return {
    type: actionTypes.GET_PUBINVENTION_SUCCESS,
    data: data,
  };
};

export const getPubinventionFail = (err) => {
  return {
    type: actionTypes.GET_PUBINVENTION_FAIL,
    err: err,
  };
};

export const getPubinventionALL = (industryCodes, parkId) => {
  return (dispatch) => {
    dispatch(startGetPubinvention());
    getPubinvention(industryCodes, parkId)
      .then((res) => {
        if (res) {
          dispatch(getPubinventionSuccess(res));
        } else {
          dispatch(getPubinventionFail("取得發明公開(5年內)-全國時發生問題。"));
        }
      })
      .catch((err) => {
        dispatch(getPubinventionFail(err));
      });
  };
};

//專利公告(5年內)
export const startGetPatentFinished = () => {
  return {
    type: actionTypes.START_GET_PATENT_FINISHED,
  };
};

export const getPatentFinishedSuccess = (data) => {
  return {
    type: actionTypes.GET_PATENT_FINISHED_SUCCESS,
    data: data,
  };
};

export const getPatentFinishedFail = (err) => {
  return {
    type: actionTypes.GET_PATENT_FINISHED_FAIL,
    err: err,
  };
};

export const getPatentFinished = (industryCodes, parkId) => {
  return (dispatch) => {
    dispatch(startGetPatentFinished());
    getPatentDatas(industryCodes, parkId)
      .then((res) => {
        if (res) {
          dispatch(getPatentFinishedSuccess(res));
        } else {
          dispatch(getPatentFinishedFail("取得專利公告(5年內)時發生問題。"));
        }
      })
      .catch((err) => {
        dispatch(getPatentFinishedFail(err));
      });
  };
};

//產業四碼
export const startGetFourCode = () => {
  return {
    type: actionTypes.START_GET_FOUR_CODE,
  };
};

export const getFourCodeSuccess = (data) => {
  return {
    type: actionTypes.GET_FOUR_CODE_SUCCESS,
    data: data,
  };
};

export const getFourCodeFail = (err) => {
  return {
    type: actionTypes.GET_FOUR_CODE_FAIL,
    err: err,
  };
};

export const getFourCode = () => {
  return (dispatch) => {
    dispatch(startGetFourCode());
    getTypeFourCode()
      .then((res) => {
        if (res) {
          dispatch(getFourCodeSuccess(res));
        } else {
          dispatch(getFourCodeFail("取得專利公告(5年內)時發生問題。"));
        }
      })
      .catch((err) => {
        dispatch(getFourCodeFail(err));
      });
  };
};