import React from "react";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import Swal from "sweetalert2";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import {
  northParks as north,
  centerParks as center,
  southParks as south,
  eastParks as southArea,
} from "../../constants/parks";
import { handleGetReferenceInfoData } from "../../utility/reference_info";

import * as Style from "./styles";

export default (props) => {
  const {
    title,
    subTitle,
    selectionOption,
    onChangeHandler,
    selectedCity,
    isIndustry,
    referenceType,
    isPatent,
    handleKeyWord,
    keyWord,
    remark,
    patenSelection,
    loading,
    patentLoading,
  } = props;

  const [state, setState] = React.useState({
    city: "",
    sampleArea: [],
    northArea: [],
    westArea: [],
    southArea: [],
    eastArea: [],
    isModalOpen: false,
  });

  const openReferenceInfoAlert = async () => {
    const data = await handleGetReferenceInfoData(referenceType);

    let content = "";
    if (!data) {
      Swal.fire({
        title: "引用資料來源",
        html: remark,
        text: remark ? "" : "資料正在建置中 ... ",
      });
    } else {
      try {
        data
          .split("。")
          .map(
            (referenceItem) =>
              (content +=
                "<p style='text-align:left'>" + referenceItem + "</p>")
          );
        Swal.fire({
          title: "引用資料來源",
          html: content,
        });
      } catch (err) {
        Swal.fire({
          title: "引用資料來源",
          text: "資料正在建置中 ... ",
        });
      }
    }
  };

  React.useEffect(() => {
    setState((state) => ({ ...state, city: selectedCity }));
  }, [selectedCity]);

  const handleChangeCity = (e) => {
    const { value, name } = e.target;
    setState((state) => ({ ...state, [name]: value }));
    onChangeHandler(value, name);
  };

  React.useEffect(() => {
    const sampleArea = selectionOption?.filter((data) => data.isAccess === 1);
    const northArea = selectionOption?.filter(
      (data) => data.location === "臺北分局" && data.isAccess !== 1
    );
    const westArea = selectionOption?.filter(
      (data) => data.location === "臺中分局" && data.isAccess !== 1
    );
    const southArea = selectionOption?.filter(
      (data) => data.location === "臺南分局" && data.isAccess !== 1
    );
    const eastArea = selectionOption?.filter(
      (data) => data.location === "高屏分局" && data.isAccess !== 1
    );

    setState((state) => ({
      ...state,
      sampleArea,
      northArea,
      westArea,
      southArea,
      eastArea,
    }));
  }, [selectionOption]);

  return (
    <Style.Container>
      <Style.Header>
        <Style.Title>
          <h2>{title}</h2>
          <Style.Icon>
            <FontAwesomeIcon
              className="icon-reference"
              icon={faInfoCircle}
              onClick={() => openReferenceInfoAlert(referenceType)}
            />
          </Style.Icon>
        </Style.Title>
        <Style.SubTitle>
          <h4>{subTitle}</h4>
        </Style.SubTitle>
      </Style.Header>

      <Style.Search>
        {isPatent && (
          <TextField
            id="standard-search"
            label="專利研發關鍵字搜尋"
            type="search"
            value={keyWord}
            variant="standard"
            style={{ width: "300px", margin: "16px 0px" }}
            className="dashboard-header-selection"
            onChange={(e) => handleKeyWord(e)}
            disabled={loading || patentLoading}
          />
        )}
        <Style.Selector>
          {isPatent && (
            <Select
              native
              value={patenSelection}
              onChange={(e) => handleChangeCity(e)}
              name="patenSelection"
              style={{ width: "100px", margin: "16px 0px 16px 30px" }}
              className="dashboard-header-selection"
              disabled={loading || patentLoading}>
              <option value={"all"}>全國</option>
              <option value={"section"}>園區</option>
            </Select>
          )}
          {((selectionOption && !isPatent) ||
            (selectionOption && isPatent && patenSelection === "section")) && (
            <Select
              native
              value={state.city}
              onChange={(e) => handleChangeCity(e)}
              name="city"
              style={{ width: "200px", margin: "16px 0px 16px 30px" }}
              className="dashboard-header-selection"
              disabled={loading || patentLoading}>
              {state.sampleArea.map((option) => {
                return (
                  <option key={option.key} value={option.value}>
                    {`(示範園區) ${option.name}`}
                  </option>
                );
              })}
              {selectionOption.map((option) => {
                if (
                  selectionOption.length === 3 ||
                  selectionOption.length === 21 ||
                  (selectionOption.length === 22 && !isIndustry)
                ) {
                  return (
                    <option key={option.key} value={option.value}>
                      {option.name}
                    </option>
                  );
                } else if (isIndustry) {
                  return (
                    <option key={option.Key} value={option.Key}>
                      {option.Key} {option.Value}
                    </option>
                  );
                }
              })}
              {selectionOption.length !== 3 &&
                selectionOption.length !== 21 &&
                selectionOption.length !== 22 &&
                !isIndustry && (
                  <>
                    <optgroup
                      label="臺北分局"
                      style={{ backgroundColor: "pink" }}>
                      {state.northArea.map((option) => {
                        return (
                          <option key={option.key} value={option.value}>
                            {option.name}
                          </option>
                        );
                      })}
                    </optgroup>
                    <optgroup
                      label="臺中分局"
                      style={{ backgroundColor: "pink" }}>
                      {state.westArea.map((option) => {
                        return (
                          <option key={option.key} value={option.value}>
                            {option.name}
                          </option>
                        );
                      })}
                    </optgroup>
                    <optgroup
                      label="臺南分局"
                      style={{ backgroundColor: "pink" }}>
                      {state.southArea.map((option) => {
                        return (
                          <option key={option.key} value={option.value}>
                            {option.name}
                          </option>
                        );
                      })}
                    </optgroup>
                    <optgroup
                      label="高屏分局"
                      style={{ backgroundColor: "pink" }}>
                      {state.eastArea.map((option) => {
                        return (
                          <option key={option.key} value={option.value}>
                            {option.name}
                          </option>
                        );
                      })}
                    </optgroup>
                    <optgroup
                      label="地方型園區"
                      style={{ backgroundColor: "pink" }}>
                      {southArea.map((option) => {
                        return (
                          <option
                            key={option["ParkId"]}
                            value={option["ParkId"]}>
                            {option["Name_CH"]}
                          </option>
                        );
                      })}
                    </optgroup>
                  </>
                )}
            </Select>
          )}
        </Style.Selector>
      </Style.Search>
    </Style.Container>
  );
};
