import React from "react";
import styled, { keyframes } from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { useSnackbar } from "notistack";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUp from "@mui/icons-material/KeyboardArrowUp";
import Collapse from "@mui/material/Collapse";
import Close from "@mui/icons-material/Close";
import Menu from "@mui/icons-material/Menu";

import KeywordInput from "./KeywordInput";
import SearchInput from "./SearchInput";
import TabItem from "./TabsItem"; 
import { handleFetchWastedPollutionList, handleFetchWastedPollutionData } from "../../store/actions/wasted_clean";
import * as theme from "../../assets/theme";
import Spinner from "../Spinner/Spinner";
import WastedCleanDetailTable from "../CustomizedTable/WastedCleanDetailTable";
import ExportButton from "../../utility/export_wasted_clean_to_excel";
import { message } from "../../utility/common";

const MapSideMenu = ({marker_list, handle_update_org_id}) => {
    const { innerWidth } = useSelector(state => state.app);
    const { enqueueSnackbar } = useSnackbar();
    const { err } = useSelector(state => state.wasted_clean);
    const history = useHistory();
    const dispatch = useDispatch();
    const [state, setState] = React.useState({
        isSearched: false,
        isSideBarOpen: true,
        isResultListOpen: false,
        isSearchInputOpen: true,
        isMobileMenuOpen: true,
        isWastedCleanDetailOpen: false,
        company_name: "",
        key_word: "",
        waste_name: "",
        location: "All",
        city: "",
        org_kind: "清除機構"
    });

    React.useEffect(()=>{
        if(err){
            message(enqueueSnackbar, err, "error");
        }
    }, [err])

    React.useEffect(()=>{
        dispatch(handleFetchWastedPollutionList())
    }, []);

    const handleResetState = () => {
        setState(state=>({ 
            ...state,         
            key_word: "",
            waste_name: "",
            location: "All",
            city: ""
         }));
    }

    const toggleDetailMenu = () => {
        setState(state=>({ ...state, isSideBarOpen: !state.isSideBarOpen }));
    };

    const handleUpdateProps = (name, value) => {
        setState(state => ({ ...state, [name]: value }));
    };

    const handleToggleResultList = () => {
        setState(state=>({ ...state, isResultListOpen: !state.isResultListOpen }));
    }

    const handleToggleSearchInput = () => {
        setState(state=>({ ...state, isSearchInputOpen: !state.isSearchInputOpen }));
    }

    const handleToggleDialog = () => {
        setState(state=>({ ...state, isMobileMenuOpen: !state.isMobileMenuOpen }));
    }

    const handleToggleDetailModal = () => {
        setState(state=>({ ...state, isWastedCleanDetailOpen: !state.isWastedCleanDetailOpen }));
    }

    const handleUpdateModalTitle = (company_name) => {
        setState(state=>({ ...state, company_name: company_name }))
    }

    const handleFetchData = () => {
        setState(state => ({ ...state, isSearched: true }))
        const data = new FormData();
        if(state.key_word) data.append("Keyword", state.key_word);
        data.append("city", state.city);
        if(state.location !== "All") data.append("location", state.location);
        data.append("WasteName", state.waste_name);
        data.append("OrgKind", state.org_kind);

        dispatch(handleFetchWastedPollutionData(data));
        setState(state=>({ ...state, isResultListOpen: true, isSearchInputOpen: false }));
    }

    const handlePushToControlledMaterial = () => {
        history.push("/controlled-material")
    }

    //手機版 layout
    if(innerWidth < 790){
        return <React.Fragment>
            <WastedCleanDetailModals open={state.isWastedCleanDetailOpen} handleToggleDetailModal={handleToggleDetailModal} company_name={state.company_name} org_id={state.org_kind}/>
            <MapMobileSideMenuInputWrapper>
                {/* <KeywordInput 
                    noHelperText 
                    toggleDetailMenu={handleToggleDialog}
                    handleFetchData={handleFetchData}
                    handleUpdateProps={handleUpdateProps}
                    propsState={state}
                /> */}
                <MapSideMenuHead>
                    <MenuIcon onClick={handleToggleDialog}/>
                    <HeadContainer>
                        <h2>廢棄物清運機構</h2>
                        <p onClick={handlePushToControlledMaterial}>廢棄物申報概況</p>
                    </HeadContainer>
                </MapSideMenuHead>
            </MapMobileSideMenuInputWrapper>
            <MobileDialog open={state.isMobileMenuOpen}>
                <SearchInput 
                    handleUpdateProps={handleUpdateProps}
                    propsState={state}
                    handleFetchData={handleFetchData}
                    handleToggleSearchInput={handleToggleSearchInput}
                    handleResetState={handleResetState}
                />
                <MapMobileSideMenuResult>
                    <MapResultHead>
                        <MapResultHeadContainer>
                            <h4>結果列表</h4>
                            {state.isSearched && <p>共{" "} {marker_list.length} {" "} 筆搜尋結果</p>}
                        </MapResultHeadContainer>
                        {state.isResultListOpen ? <ArrowDown onClick={handleToggleResultList}/> : <ArrowUp  onClick={handleToggleResultList}/>}
                    </MapResultHead>
                    <ExportButtonContainer is_show={marker_list.length !== 0}><ExportButton  waste_name={state.waste_name} /></ExportButtonContainer>
                    <MapResultContent isSearchInputOpen={state.isSearchInputOpen} in={state.isResultListOpen}>
                        {marker_list.length === 0 ? 
                            <MapResultNoDataText>無搜尋結果</MapResultNoDataText> : 
                            marker_list.map(m => 
                                <TabItem 
                                    latitude={m.latitude} 
                                    longitude={m.longitude}
                                    address={m.address}
                                    amount={m.amount}
                                    business_no={m.business_no}
                                    company_name={m.company_name}
                                    county={m.county}
                                    key={m.ctrl_id}
                                    ctrl_id={m.ctrl_id}
                                    phone={m.phone}
                                    handleToggleDetailModal={handleToggleDetailModal}
                                    handleUpdateModalTitle={handleUpdateModalTitle}
                                    org_id={state.org_kind}
                                />
                            )}
                    </MapResultContent>
                </MapMobileSideMenuResult>
            </MobileDialog>
        </React.Fragment>
    }


    return (
        <React.Fragment>
            <WastedCleanDetailModals open={state.isWastedCleanDetailOpen} handleToggleDetailModal={handleToggleDetailModal}  company_name={state.company_name} org_id={state.org_kind}/>
            <MapSideMenuClosedWrapper open={state.isSideBarOpen}>
                <KeywordInput 
                    noHelperText 
                    toggleDetailMenu={toggleDetailMenu}
                    handleFetchData={handleFetchData}
                    handleUpdateProps={handleUpdateProps}
                    propsState={state}
                />
            </MapSideMenuClosedWrapper>
            <MapSideMenuWrapper open={state.isSideBarOpen}>
                <MapSideMenuHead is_col>
                    <HeadContainer>
                        <h2>廢棄物清運機構</h2>
                        <p onClick={handlePushToControlledMaterial}>廢棄物申報概況</p>
                    </HeadContainer>
                    <p>透過條件搜尋廢棄物清運業者相關資料。</p>
                </MapSideMenuHead>
                {/* <MapSideMenuKeyWordSearchContainer>
                    <KeywordInput 
                        toggleDetailMenu={toggleDetailMenu}
                        handleFetchData={handleFetchData}
                        handleUpdateProps={handleUpdateProps}
                        propsState={state}
                    />
                </MapSideMenuKeyWordSearchContainer> */}
                <SearchInput 
                    handleUpdateProps={handleUpdateProps}
                    propsState={state}
                    handleFetchData={handleFetchData}
                    handleToggleSearchInput={handleToggleSearchInput}
                    handleResetState={handleResetState}
                />
                <MapMobileSideMenuResult>
                    <MapResultHead>
                        <MapResultHeadContainer>
                            <h4>結果列表</h4>
                            {state.isSearched && <p>共{" "} {marker_list.length} {" "} 筆搜尋結果</p>}
                        </MapResultHeadContainer>
                        {state.isResultListOpen ? <ArrowDown onClick={handleToggleResultList}/> : <ArrowUp  onClick={handleToggleResultList}/>}
                    </MapResultHead>
                    <ExportButtonContainer is_show={marker_list.length !== 0}><ExportButton waste_name={state.waste_name}/></ExportButtonContainer>
                    <MapResultContent isSearchInputOpen={state.isSearchInputOpen} in={state.isResultListOpen}>
                        {marker_list.length === 0 ? 
                            <MapResultNoDataText>無搜尋結果</MapResultNoDataText> : 
                            marker_list.map(m => 
                                <TabItem 
                                    latitude={m.latitude} 
                                    longitude={m.longitude}
                                    address={m.address}
                                    amount={m.amount}
                                    business_no={m.business_no}
                                    company_name={m.company_name}
                                    county={m.county}
                                    key={m.ctrl_id}
                                    ctrl_id={m.ctrl_id}
                                    phone={m.phone}
                                    handleToggleDetailModal={handleToggleDetailModal}
                                    handleUpdateModalTitle={handleUpdateModalTitle}
                                    org_id={state.org_kind}
                                />
                            )}
                    </MapResultContent>
                </MapMobileSideMenuResult>
            </MapSideMenuWrapper>
        </React.Fragment>

    )
};

const WastedCleanDetailModals = ({open, handleToggleDetailModal, company_name, org_id}) => {
    const {  wasted_pollution_detail_data_loading, wasted_pollution_detail_data } = useSelector(state => state.wasted_clean);
    const { innerWidth } = useSelector((state) => state.app);

    let header_list;
    if (innerWidth < 750) {
        if(org_id === "清除機構"){
            header_list = ["編號", "名稱"]
        }else{
            header_list = ["編號", "名稱(處理過程)"]
        }
    }else{
        if(org_id === "清除機構"){
            header_list = ["編號", "名稱"]
        }else{
            header_list =["編號", "名稱", "處理過程"]
        }
    }

    return(
        <React.Fragment>
            <WastedCleanDetailBackdropModal open={open} onClick={handleToggleDetailModal}/>
            <WastedCleanDetailModal open={open} >
                <WastedCleanDetailCloseContainer open={open}>
                    <h2>{company_name}<span>可處理廢棄物項目</span></h2>
                    <CloseIcon  onClick={handleToggleDetailModal} />
                </WastedCleanDetailCloseContainer>
                {wasted_pollution_detail_data_loading ? 
                <WastedCleanDetailLoadingContainer><Spinner /></WastedCleanDetailLoadingContainer> :
                <WastedCleanDetailContainer>
                    <WastedCleanDetailTable 
                        data={wasted_pollution_detail_data}
                        headerList={header_list}
                    />
                </WastedCleanDetailContainer>
                }
            </WastedCleanDetailModal>
        </React.Fragment>
    )
}

const MenuIcon = styled(Menu)`
    cursor: pointer;
    transition: 0.2s;

    &:hover{
        color: ${theme.black};
        transition: 0.2s;
    }
`

const ExportButtonContainer = styled.div`
    display: ${p => (localStorage.getItem('auL') <= 3 && p.is_show) ? "flex" : "none"};
    padding: 0px 10px 5px 10px;
    justify-content: flex-start;
    align-items: center;
`;

const CloseIcon = styled(Close)`

`

const WastedCleanDetailCloseContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0px 25px 25px 0px;
    height: 40px;
    > h2 {
        padding: 5px 15px;
        font-size: 1.5rem;
        font-weight: 600;
    }
    /* position: fixed;
    top: ${p => p.open ? "160px" : "-140vh"};
    right: calc(10% + 15px);
    transition: 0.5s top ease-in-out;

    @media(max-width:750px){    
        top: ${p => p.open ? "100px" : "-140vh"};
        right: 25px;
    } */
`


const WastedCleanDetailContainer = styled.div`
    width: 100%;
    max-height:  50vh;
    overflow-y: scroll;
    padding: 0px 20px 20px 20px;
`

const WastedCleanDetailLoadingContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height:  30vh;
`

const WastedCleanDetailBackdropModal = styled.div`
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.4);
    position: absolute;
    z-index: 2004;
    top: 0px;
    left: 0px;
    display: ${p => p.open ? "block" : "none"};
    transition: 0.2s display ease-in-out;
`

const WastedCleanDetailModal = styled.div`
    width: 80%;
    min-height: 40vh;
    height: auto;
    background-color: ${theme.white};
    border-radius: 20px;
    position: absolute;
    z-index: 2004;
    margin: 150px 10%;
    padding: 20px 0px;
    left: 0px;
    top: ${p => p.open ? "0px" : "-140vh"};
    transition: 0.5s top ease-in-out;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    @media(max-width:750px){    
        width: 90%;
        margin: 100px 5%;
    }
`;

const MapSideMenuWrapper = styled.div`
    position: absolute;
    left: ${p => p.open ? "0px" : "-100vw"};
    top: 114px;
    width: 350px;
    z-index: 1000;
    height: calc(100vh - 114px);
    background-color: #ececec;
    transition: 0.2s left ease-in-out;
`

const MapSideMenuClosedWrapper = styled.div`
    position: absolute;
    left: ${p => !p.open ? "0px" : "-100vw"};
    top: 114px;
    width: 350px;
    z-index: 1001;
    transition: 0.2s left ease-in-out;
`

const MapSideMenuHead = styled.div`
    width: 100%;
    background-color: #fff;
    padding: 5px 15px 2px 0px;
    display: flex;
    flex-direction: ${p => p.is_col ? "column" : "row"};
    justify-content: flex-start;
    align-items: ${p => p.is_col ? "flex-start" : "center"};;
    > h2 {
        padding: 5px 15px;
        font-size: 1.5rem;
        font-weight: 600;
    }
    > p {
        padding: 5px 15px;
        font-size: 0.75rem;
        >span{
            text-decoration: underline;
            padding: 0px 5px;
            cursor: pointer
        }
    }
`

const HeadContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    > h2 {
        padding: 5px 15px;
        font-size: 1.5rem;
        font-weight: 600;
    }


    >p{
        margin-left: 2.5px;
        text-decoration: underline;
        color: ${theme.linkBlue};
        transition: 0.2s;
        cursor: pointer;

        &:hover{
            transition: 0.2s;
            color: ${theme.linkHoveredBlue}
        }
    }
`;

const MapSideMenuKeyWordSearchContainer = styled.div`
    background-color: #fff;
    padding: 10px 0px 2px 0px;
    margin-bottom: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
`

const MapMobileSideMenuInputWrapper = styled.div`
    position: absolute;
    left: 0px;
    /* top: 70px; */
    width: 100vw;
    z-index: 1000;
`

const MapMobileSideMenuResult = styled.div`
    width: 100%;
    background-color: #fff;
`

const MapResultHead = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`
const MapResultHeadContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;

    >h4{
        margin-left: 10px;
        font-size: 1.25rem;
        font-weight: 600;
        padding: 15px 0px;
    }

    >p{
        font-size: 0.8rem;
        font-weight: 400;
        padding: 15px 0px;
        margin-left: 5px;
    }
`

const ArrowDown = styled(KeyboardArrowDown)`
    transform: translateX(-10px);
    cursor: pointer;
`;

const ArrowUp = styled(KeyboardArrowUp)`
    transform: translateX(-10px);
    cursor: pointer;
`;

const MapResultContent = styled(Collapse)`
    /* transform: translateY(50px); */
    .MuiCollapse-wrapper{
        .MuiCollapse-wrapperInner{
            /* padding-top: ${p => p.isSearchInputOpen ? "270px" : "300px"} !important; */
            background-color: ${theme.white};
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-direction: column;
            padding: 0px 0px 20px 0px;
            max-height: ${p => p.isSearchInputOpen ? "calc(100vh - 580px)" : "calc(100vh - 322.5px)"};
            overflow-y: scroll;

            @media(max-width:750px){    
                /* padding-top: ${p => p.isSearchInputOpen ? "10px" : "10px"} !important; */
                max-height: ${p => p.isSearchInputOpen ? "45vh" : "62vh"};
            }
        }
    }
`

const MapResultNoDataText = styled.p`
    font-size: 1.25rem;
    color: #888888;
    font-weight: 600;
    text-align: center;
`

const MobileDialog = styled.div`
    position: absolute;
    left: 0px;
    top: ${p => p.open ? "45px" : "calc(130vh - 45px)" };
    width: 100vw;
    z-index: 1000;
    height: 90vh;
    background-color: #ececec;
    transition: 0.5s top linear;
`

const slideUp = keyframes`
  from {
    transform: translateY(0px);
  }

  to {
    transform: translateY(100vh - 114px);
  }
`

const slideDown = keyframes`
  from {
    transform: translateY(100vh - 114px);
  }

  to {
    transform: translateY(0px);
  }
`

export default MapSideMenu;