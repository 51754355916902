import React from "react";
import { Popup, Marker } from "react-leaflet";

export default (props) => {
  const markerRef = React.useRef(null);
  let owner = props.data.marker ? props.data.marker.owner : props.data.owner;
  let name = props.data.marker ? props.data.marker.name : props.data.name;
  let address = props.data.marker ? props.data.marker.address : props.data.address;
  let product = props.data.marker ? props.data.marker.product : props.data.product;
  let typeFour = props.data.marker ? props.data.marker.typeFour : props.data.typeFour;
 

  // , name, address, parks, product } = props.data.marker ? ;
  const { keys, position } = props;
  const [state, setState] = React.useState({})
  const url = window.location.origin;

  const generateTitle = (name, address, owner, product) => {
    let title = "";
    if (name) {
      title += `工廠名稱${name}`;
    }
    if (owner) {
      title += ` 負責人${owner}`;
    }
    if (address) {
      title += `工廠地址${address}`;
    }
    
    if (product) {
      title += `產品${product}`;
    }
    return title;
  }
  return (
    <Marker 
      position={position} 
      key={keys} 
      ref={markerRef} 
      title={generateTitle(name, address, owner, product)}
      > 
      <Popup >
        <div className="material-search-popup">
          { name &&
          <h4>
          工廠名稱:
          <span
          >
            {" " + name}
          </span>
        </h4>
          }
          { owner &&
          <p>
          <span>負責人:</span> {" " + owner}
        </p>
          }
          { address &&
          <p> 
          <span>工廠地址:</span> {" " + address}
        </p>
          }
  { product &&
          <p>
          <span>產品:</span> {" " + product}
        </p>
  }
  { typeFour &&
          <p>
          <span>產業別:</span> {" " + typeFour}
        </p>
  }
        </div>
      </Popup>
    </Marker>
  );
};
