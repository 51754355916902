import axios from "axios";
import { baseUrl, loginbaseUrl } from "../constants/endpoints";
import { encryptAES, decryptAES } from "./common";

//新增儀表板
export const createDashboard = (board_name, year, description) => {
  const account = localStorage.getItem("uid");
  const data = new FormData();
  data.append("Account", account);
  const encrypt = encryptAES(`Aj9dxd${account}`);
  data.append("Encrypt", encrypt);
  data.append("BoardName", board_name);
  data.append("Indu_Description", description);
  data.append("Year", year);

  return axios({
    method: "post",
    url: `${loginbaseUrl}/api/UserCustIndustry/CreateDashboard`,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    data: data,
  })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};

//取得儀表板清單
export const getDashboardList = () => {
  const account = localStorage.getItem("uid");
  const data = new FormData();
  data.append("Account", account);
  const encrypt = encryptAES(`Aj9dxd${account}`);
  data.append("Encrypt", encrypt);

  return axios({
    method: "post",
    url: `${loginbaseUrl}/api/UserCustIndustry/GetDashboardList`,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    data: data,
  })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};

//取得儀表板當前條件
export const getDashboardCond = (board_id) => {
  const account = localStorage.getItem("uid");
  const data = new FormData();
  data.append("Account", account);
  const encrypt = encryptAES(`Aj9dxd${account}`);
  data.append("Encrypt", encrypt);
  data.append("BoardID", board_id);
  return axios({
    method: "post",
    url: `${loginbaseUrl}/api/UserCustIndustry/GetDashboardCond`,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    data: data,
  })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};

//更新儀表板名稱
export const updateUserDashboardName = (
  board_id,
  board_name
) => {
  const account = localStorage.getItem("uid");
  const data = new FormData();
  data.append("Account", account);
  const encrypt = encryptAES(`Aj9dxd${account}`);
  data.append("Encrypt", encrypt);
  data.append("BoardID", board_id);
  data.append("BoardName", board_name);

  return axios({
    method: "post",
    url: `${loginbaseUrl}/api/UserCustIndustry/UpdateUserDashboardName`,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    data: data,
  })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};

//更新儀表板條件
export const uploadDashboardCond = (
  board_id,
  board_name,
  description,
  industry_list,
  export_4_code,
  import_4_code
) => {
  const account = localStorage.getItem("uid");
  const data = new FormData();
  data.append("Account", account);
  const encrypt = encryptAES(`Aj9dxd${account}`);
  data.append("Encrypt", encrypt);
  data.append("BoardID", board_id);
  data.append("BoardName", board_name);
  data.append("Indu_Description", description);

  const industryList = [];
  const exportList = [];
  const importList = [];

  industry_list.map((data) => {
    industryList.push(data.Code);
  });

  export_4_code.map((data) => {
    exportList.push(data.Code);
  });

  import_4_code.map((data) => {
    importList.push(data.Code);
  });

  data.append("IndustryList", industryList.toString());
  data.append("Export4Code", exportList.toString());
  data.append("Import4Code", importList.toString());

  return axios({
    method: "post",
    url: `${loginbaseUrl}/api/UserCustIndustry/UploadDashboardCond`,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    data: data,
  })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};

//複製儀表板
export const copyDashboard = (board_id) => {
  const account = localStorage.getItem("uid");
  const data = new FormData();
  data.append("Account", account);
  const encrypt = encryptAES(`Aj9dxd${account}`);
  data.append("Encrypt", encrypt);
  data.append("BoardID", board_id);

  return axios({
    method: "post",
    url: `${loginbaseUrl}/api/UserCustIndustry/CopyDashboard`,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    data: data,
  })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};

//刪除儀表板
export const deleteDashboard = (board_id) => {
  const account = localStorage.getItem("uid");
  const data = new FormData();
  data.append("Account", account);
  const encrypt = encryptAES(`Aj9dxd${account}`);
  data.append("Encrypt", encrypt);
  data.append("BoardID", board_id);

  return axios({
    method: "post",
    url: `${loginbaseUrl}/api/UserCustIndustry/DeleteDashboard`,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    data: data,
  })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};

//取得工廠四碼
export const getALLFactoryIndustry = () => {
  return axios({
    method: "get",
    url: `${baseUrl}/api/EventSearch/GetALLFactoryIndustry`,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};

//取得篩選過的工廠四碼
export const getFilterFactoryIndustry = () => {
  return axios({
    method: "get",
    url: `${baseUrl}/api/InduType/GetIndustryTypes_Four_Stat/`,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};

//國別清單
export const getCountryList = () => {
  return axios({
    method: "get",
    url: `${baseUrl}/api/EventSearch/GetCountryList`,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};

//進出口清單排名 (參數：國別、年分)
export const getCountryTradeProduct = (country, year, type) => {
  const data = new FormData();
  data.append("Country", country);
  data.append("Year", year);
  data.append("Type", type);

  return axios({
    method: "post",
    url: `${baseUrl}/api/EventSearch/GetCountryTradeProduct`,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    data: data,
  })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};

//出口清單排名 (沒有參數)
export const getTradeProduct = () => {
  return axios({
    method: "get",
    url: `${baseUrl}/api/CompanySearch/ExportProductFourCodeList`,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};