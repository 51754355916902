import React from "react";
import Select from "@material-ui/core/Select";
import Modal from "../CustomizedModal/CustomizedModal";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";

import NestModalSelection from "./NestModalSelection";

import "./styles.scss";

export default props => {
    const { title, subTitle, selectionOption, onChangeHandler, selectedOrg, isNesting, referenceType } = props;
    const [state, setState] = React.useState({
        Org: "",
        isModalOpen: false
    });
    const { referenceInfo } = useSelector(state => state.app);

    const openReferenceInfoAlert = (type) => {
        let content = "";
        if (referenceInfo[type] === "") {
            console.log("I was called!")
            Swal.fire({
                title: "引用資料來源",
                text: "資料正在建置中 ... ",
            })
        } else {
            try {
                referenceInfo[type].split("。").map(referenceItem => content += "<p style='text-align:left'>" + referenceItem + "</p>")
                Swal.fire({
                    title: "引用資料來源",
                    html: content,
                })
            } catch (err) {
                Swal.fire({
                    title: "引用資料來源",
                    text: "資料正在建置中 ... ",
                })
            }
        }
    }

    React.useEffect(() => {
        // history.push()
        setState(state => ({ ...state, Org: selectedOrg }))
    }, [selectedOrg])


    const handleChangeOrg = (e) => {
        const { value, name } = e.target;
        setState(state => ({ ...state, [name]: value }))
        onChangeHandler(value)
    }

    const handleModalOpen = () => {
        setState(state => ({ ...state, isModalOpen: true }))
    }

    const handleClose = () => {
        setState(state => ({ ...state, isModalOpen: false }))
    }

    const handlePassValue = data => {
        const { name, value } = data;
        setState(state => ({ ...state, [name]: value }))
    }

    return (
        <div className="dashboard-header-container">
            <Modal isOpen={state.isModalOpen} handleClose={handleClose}><NestModalSelection handleClose={handleClose} handlePassValue={handlePassValue} /></Modal>
            <div className="dashboard-header">
                <div className="dashboard-header-title">
                    <h2>{title}</h2>
                    <FontAwesomeIcon
                        className="icon-reference"
                        icon={faInfoCircle}
                        onClick={() => openReferenceInfoAlert(referenceType)}
                    />
                </div>
                <div>
                    {selectionOption && !isNesting &&
                        <Select
                            native
                            value={state.Org}
                            onChange={(e) => handleChangeOrg(e)}
                            name="Org"
                            style={{ width: "200px" }}
                            className="dashboard-header-selection"
                        >
                            {selectionOption.map(option => <option key={option.BoardID} value={option.BoardID}>{option.BoardName}</option>)}
                        </Select>
                    }
                    {selectionOption && isNesting &&
                        <Select
                            native
                            value={state.Org}
                            // onChange={(e)=>handleChangeOrg(e)}
                            onClick={handleModalOpen}
                            name="Org"
                            style={{ width: "200px" }}
                            className="dashboard-header-selection"
                        >
                            {/* {selectionOption.map(option => <option key={option.key} value={option.value}>{option.name}</option>)} */}
                        </Select>
                    }
                </div>
            </div>
            <div className="dashboard-sub-header">
                <h4>{subTitle}</h4>
            </div>
        </div>
    )
}