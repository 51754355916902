import React from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import Icon from "@material-ui/core/Icon";
import { useSelector } from "react-redux";
import LockIcon from "@mui/icons-material/Lock";

import { thousandDivider, stopTypeSwitcher } from "../../../utility/common";
import * as theme from "../../../assets/theme";
import blurred from "../../../assets/images/blurred_mobile.png";

import "./styles.scss";

const FactoryBasicInfoTable = (props) => {
  const { data, loading } = props;
  const { innerWidth } = useSelector((state) => state.app);
  const [state, setState] = React.useState({
    endIndex: 0,
    page: 0,
    rowsPerPage: 5,
    styleKey: {},
    styleValue: {},
  });
  const url = window.location.origin;
  const history = useHistory();


  const openNewTabs = (link) => window.open(link, "_blank");

  const handleOpenFactoryPage = () => {
    history.push("/sign-up");
  };

  // const styleKey = innerWidth < 1024 ? {width:"40%", fontWeight: 600} : {width:"30%", fontWeight: 600};
  // const styleValue = innerWidth < 1024 ? {width:"60%", borderBottom: "none"} : {display: "flex"};

  React.useEffect(() => {
    if (innerWidth < 1024) {
      setState((state) => ({
        ...state,
        styleKey: { width: "40%", fontWeight: 600 },
        styleValue: { width: "60%", borderBottom: "none" },
      }));
    } else {
      setState((state) => ({
        ...state,
        styleKey: { width: "30%", fontWeight: 600 },
        styleValue: { display: "flex" },
      }));
    }
  }, [innerWidth]);

  return (
    <TableContainers className="table-container">
            <NoAuthCover login={localStorage.getItem("isLogin") === "true"}>
        <TextContainer>
          <LockIcon />
          <Text>
            請 <button onClick={handleOpenFactoryPage}>註冊</button> 解鎖此功能。
          </Text>
        </TextContainer>
      </NoAuthCover>
      <Blurred
        login={localStorage.getItem("isLogin") === "true"}
        src={blurred}
      />
        {data.MainProductDesc ? 
          <Table className="table" style={{ tableLayout: "fixed" }}>
            <TableBody className="table-body">
              <React.Fragment>
                <TableRow hover className="table-body-row">
                  <TableCell align="left" style={state.styleValue}>
                    <p>{data.MainProductDesc}</p>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            </TableBody>
          </Table>
        :
        <Table>
        <TableBody className="table-body-no-data">
          <TableRow hover className="table-body-row">
            <TableCell align="center" className="no-data">
              本項目無資料
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
        }
      </TableContainers>
  );
};

const TableContainers = styled(TableContainer)`
  position: relative;
`;

const NoAuthCover = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
  /* background-color: ${theme.background_color}; */
  display: ${(p) => (p.login ? "none" : "flex")};
  justify-content: center;
  align-items: center;
  z-index: 20;
`;

const Blurred = styled.img`
  width: 100%;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
  filter: blur(10px);
  object-fit: cover;
  z-index: 10;
  display: ${(p) => (p.login ? "none" : "block")};
`;

const TextContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Text = styled.p`
  padding-left: 5px;
  > button {
    color: ${theme.linkBlue};
    text-decoration: underline;
    cursor: pointer;
    transition: 0.2s;
    &:hover {
      transition: 0.2s;
      color: ${theme.linkHoveredBlue};
    }
  }
`;

export default React.memo(FactoryBasicInfoTable);
