import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { Modal } from 'react-bootstrap'
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";


import Spinner from '../../components/Spinner/Spinner'
import FullPage from '../../components/FullPage/FullPage'
import { 
    getFactoryByArea,
    getAllTypeTwo,
    getAllTypeThree,
    getAllTypeFour
} from '../../utility/api'

import './AdvancedSearch.css'
import searchBgPhoto from '../../assests/bill-oxford--fGqsewtsJY-unsplash.jpg'

const MySwal = withReactContent(Swal);


class AdvancedSearch extends Component{
    constructor (props) {
        super(props)
    
        this.state = {
          showModal: false,
          redirect: false,
          error: false,
          area: "1",
          north: [""],
          south: [""],
          midd: [""],
          induType: "",
          typeData: "",
          typeTwo: "",
          typeThree: "",
          typeFour: "",
          listedStock: false,
          otcStock: false,
          newOTCStock: false,
          publicStock: false,
          params: {
            CityAreaName:'北部',
            ParkId:[],
            onInduTypeTwo: [],
            onInduTypeThree: [],
            onInduTypeFour: [],
            StockType: [],
            MinCapital: "",
            MaxCapital: "",
            ProductKeyword: ""
          },
          displayParams:{
            ParkId:[],
            onInduTypeTwo: [],
            onInduTypeThree: [],
            onInduTypeFour: [], 
          }
        };
    
        this.onAreaChangeHandler = this.onAreaChangeHandler.bind(this)
        this.onInduTypeHandler = this.onInduTypeHandler.bind(this)
        this.onStockTyppHandler = this.onStockTyppHandler.bind(this)
        this.onInputHandler = this.onInputHandler.bind(this)
        this.submitHandler = this.submitHandler.bind(this)
        this.openModalHandler = this.openModalHandler.bind(this)
        this.onParkIdHandler = this.onParkIdHandler.bind(this)
      }
    

    async componentDidMount(){
      getFactoryByArea('南部')
        .then(res=>{
        if(res !== 'error'){
            this.setState({
                south:res
            })
        }else{
            this.setState({
                south:'error'
            })
        }
        })


      getFactoryByArea('北部')
        .then(res=>{
            if(res !== 'error'){
                this.setState({
                    north:res
                })
            }else{
                this.setState({
                    north:'error'
                })
            }
          })
     
      getFactoryByArea('中部')
        .then(res=>{
            if(res !== 'error'){
                this.setState({
                    midd:res
                })
            }else{
                this.setState({
                  midd: "error"
                });
            }
          })    
          

      await getAllTypeTwo()
        .then(res=>{
          if(res !== 'error'){
            const tempObj = {}

            res.map(item=>{
              tempObj[item.Key] = item.Value
            })
            
            this.setState({
                typeTwo:tempObj
            })
          }else{
            this.setState({
                typeData:'error'
            })
          }    
        })

      await getAllTypeThree('')
      .then(res=>{
        if(res !== 'error'){
          const tempObj = {}

          res.map(item=>{
            tempObj[item.Key] = item.Value
          })

          this.setState({
              typeThree:tempObj
          })
        }else{
          this.setState({
              typeThree:'error'
          })
        }    
      })    
        
      await getAllTypeFour('','')
      .then(res=>{
        if(res !== 'error'){
          const tempObj = {}

          res.map(item=>{
            tempObj[item.Key] = item.Value
          })


          this.setState({
              typeFour:tempObj
          })
        }else{
          this.setState({
              typeFour:'error'
          })
        }    
      })    
    }

    componentWillReceiveProps(){

    }


    submitHandler(){
        const { 
            listedStock,
            otcStock,
            newOTCStock,
            publicStock,
            params,
            typeTwo,
            typeThree,
            typeFour 
        } =  this.state

        const fixdeParam = params
        const fixdeDisplayParam = this.state.displayParams
        
        // const tempList = []

        const tempList = [listedStock, otcStock, newOTCStock, publicStock]
        const temptemList = []
        


        for(let i=0; i < tempList.length; i++){
            if(tempList[i] === true){
                temptemList.push((i+1).toString())
            }
        }


        let temptemptempList = []

        if(fixdeParam.onInduTypeTwo.length > 0){

          fixdeParam.onInduTypeTwo.map(item=>{
            temptemptempList.push(typeTwo[item])
          })

          fixdeDisplayParam.onInduTypeTwo = temptemptempList

          temptemptempList = []

        }

        if(fixdeParam.onInduTypeThree.length > 0){
          fixdeParam.onInduTypeThree.map(item=>{
            temptemptempList.push(typeThree[item])
          })

          fixdeDisplayParam.onInduTypeThree = temptemptempList

          temptemptempList = []

        }

        if(fixdeParam.onInduTypeFour.length > 0){

          fixdeParam.onInduTypeFour.map(item=>{
            temptemptempList.push(typeFour[item])
          })

          fixdeDisplayParam.onInduTypeFour = temptemptempList

          temptemptempList = []
        }
        

        let stockList = []
        let fixedStockList = []

        if(listedStock){
          stockList.push(1)
          fixedStockList.push('上市')
        }
        
        if(otcStock){
          stockList.push(2)
          fixedStockList.push('上櫃')
        }
        
        if(newOTCStock){
          stockList.push(3)
          fixedStockList.push('興櫃')
        }
        
        if(publicStock){
          stockList.push(4)
          fixedStockList.push('公開發行')
        }

 
        if(!listedStock && !otcStock && !newOTCStock && !publicStock){
          stockList = []
          fixedStockList = []
        } 


        if(params.MinCapital < 0){
          MySwal.fire({
            text: "最低資本額不得低於0",
          }).then(()=>{}) 
          // this.setState({params:fixdeParam})
        }


        if(params.MaxCapital < 0){
          MySwal.fire({
            text: "最高資本額不得低於0",
          }).then(() => {}); 

          // const fixdeParam = { ...this.state.params }
          // fixdeParam.MinCapital = ''

          // this.setState({ params: fixdeParam })
        }

        if(params.MinCapital > params.MaxCapital){
          MySwal.fire({
            text: "最低資本額數字大於最高資本額",
          }).then(() => {}); 
        }

        fixdeParam.StockType = stockList
        fixdeDisplayParam.StockType = fixedStockList
        

        this.setState({
            params:fixdeParam,
            displayParams:fixdeDisplayParam
        })

        this.setState({
            redirect:true
        })


        // console.log(this.state.redirect)
        // return <Redirect to='/search_result' />

    }

    onAreaChangeHandler(event){
        const fixdeParam = this.state.params
        
        if(event.target.value === '1'){
          fixdeParam.CityAreaName = '北部'
          this.setState({params:fixdeParam})
        }else if(event.target.value === '3'){
          fixdeParam.CityAreaName = '中部'
          this.setState({params:fixdeParam})
        }else if(event.target.value === '2'){
          fixdeParam.CityAreaName = '南部'
          this.setState({params:fixdeParam})
        }


        this.setState({
            area:event.target.value
        })
    }


    onInduTypeHandler(event, type){
        const fixdeParam = { ...this.state.params }
        const value = event.target.value

        if(type === 'typeTwo'){
          // fixdeParam.onInduTypeTwo.push(value)
          if(5 > fixdeParam.onInduTypeTwo.length > 0){
            const newTypeList = fixdeParam.onInduTypeTwo.filter(id => id === value)


            if(newTypeList.length === 0){
              fixdeParam.onInduTypeTwo.push(value)
            }else if(newTypeList.length !== 0){
              const fixedNewTypeList = fixdeParam.onInduTypeTwo.filter(id => id !== value)
              fixdeParam.onInduTypeTwo = fixedNewTypeList

            }

          }else if(fixdeParam.onInduTypeTwo.length === 0){
            fixdeParam.onInduTypeTwo.push(value)
          }else if(fixdeParam.onInduTypeTwo.length >= 5){
            MySwal.fire({
              text: "已達選擇上限",
            }).then(() => {}); 
          }
         
        }else if(type === 'typeThree'){
          if(5 > fixdeParam.onInduTypeThree.length > 0){
            const newTypeList = fixdeParam.onInduTypeThree.filter(id => id === value)


            if(newTypeList.length === 0){
              fixdeParam.onInduTypeThree.push(value)
            }else if(newTypeList.length !== 0){
              const fixedNewTypeList = fixdeParam.onInduTypeThree.filter(id => id !== value)
              fixdeParam.onInduTypeThree = fixedNewTypeList

            }

          }else if(fixdeParam.onInduTypeThree.length === 0){
            fixdeParam.onInduTypeThree.push(value)
          }else if(fixdeParam.onInduTypeThree.length >= 5){
            MySwal.fire({
              text: "已達選擇上限",
            }).then(() => {}); 
          }
        }else if(type === 'typeFour'){
          if(5 > fixdeParam.onInduTypeFour.length > 0){
            const newTypeList = fixdeParam.onInduTypeFour.filter(id => id === value)


            if(newTypeList.length === 0){
              fixdeParam.onInduTypeFour.push(value)
            }else if(newTypeList.length !== 0){
              const fixedNewTypeList = fixdeParam.onInduTypeFour.filter(id => id !== value)
              fixdeParam.onInduTypeFour = fixedNewTypeList

            }

          }else if(fixdeParam.onInduTypeFour.length === 0){
            fixdeParam.onInduTypeFour.push(value)
          }else if(fixdeParam.onInduTypeFour.length >= 5){
            MySwal.fire({
              text: "已達選擇上限",
            }).then(() => {}); 
          }
                   
        }


        this.setState({
            params:fixdeParam
        })    
    }



    setPostiveNumber(){
      const { MinCapital,MaxCapital} = this.state.params
      if( MinCapital < 0 ){
        const fixdeParam = this.state.params
        fixdeParam.MinCapital = 0

        this.setState({params:fixdeParam})
      } 

      if (MaxCapital < 0) {
        const fixdeParam = this.state.params;
        fixdeParam.MaxCapital = 0;

        this.setState({ params: fixdeParam });
      } 
    }



    onStockTyppHandler(event){
        const target = event.target
        const name = target.name
        const value = target.checked
        this.setState({[name]:value})
    }


    onInputHandler(event){
        const name = event.target.name
        const value = event.target.value

        const fixdeParam = { ...this.state.params }

      if (name === 'MaxCapital' && value < 0){
          // alert('高於')
          fixdeParam.MaxCapital = ''
          this.setState({
            params: fixdeParam
          }) 
        }

        if (name === 'MinCapital' && value < 0) {
          // alert('低於')

          fixdeParam.MinCapital = ''
          this.setState({
            params: fixdeParam
          })
        }


        fixdeParam[name] = value
        this.setState({
            params:fixdeParam
        }) 

    }

    onParkIdHandler(event){
      const value = event.target.value

      const fixdeParam = { ...this.state.params }
      const fixdeDisplayParam = { ...this.state.displayParams }


      fixdeParam.ParkId.push(value.split(',')[0])
      fixdeDisplayParam.ParkId.push(value.split(',')[1])

      this.setState({
          params:fixdeParam,
          displayParams:fixdeDisplayParam
      }) 
    }

    async openModalHandler(type){
      const typeTwo = this.state.params.onInduTypeTwo
      const typeThree = this.state.params.onInduTypeThree
      const typeFour = this.state.params.onInduTypeFour

      this.setState({induType:type})

      if(type === 'typeTwo'){
        if(typeThree.length !== 0){
          MySwal.fire({
            text: "您已選3碼產業別，目前無法重複選擇2碼產業別",
          }).then(() => {}); 
        }else if(typeFour.length !== 0){
          MySwal.fire({
            text: "您已選4碼產業別，目前無法重複選擇2碼產業別",
          }).then(() => {}); 
        }else{
          await getAllTypeTwo()
          .then(res=>{
            if(res !== 'error'){
              this.setState({
                  typeData:res
              })
          }else{
              this.setState({
                  typeData:'error'
              })
          }
          })

          this.setState({
            showModal:true
          })
        }

      }else if(type === 'typeThree'){
        if(typeTwo.length !== 0){
          MySwal.fire({
            text: "您已選2碼產業別，目前無法重複選擇3碼產業別",
          }).then(() => {}); 
        }else if(typeFour.length !== 0){
          MySwal.fire({
            text: "您已選4碼產業別，目前無法重複選擇3碼產業別",
          }).then(() => {}); 
        }else{
          await getAllTypeThree(typeTwo)
          .then(res=>{
            if(res !== 'error'){
              this.setState({
                  typeData:res
              })
          }else{
              this.setState({
                  typeData:'error'
              })
          }
          })

          this.setState({
            showModal:true
          })
        }

        
      }else if(type === 'typeFour'){
        if(typeTwo.length !== 0){
          MySwal.fire({
            text: "您已選2碼產業別，目前無法重複選擇4碼產業別",
          }).then(() => {}); 
        }else if(typeThree.length !== 0){
          MySwal.fire({
            text: "您已選3碼產業別，目前無法重複選擇4碼產業別",
          }).then(() => {}); 
        }else{
        await getAllTypeFour(typeTwo, typeThree)
        .then(res=>{
          if(res !== 'error'){
            this.setState({
                typeData:res
            })
        }else{
            this.setState({
                typeData:'error'
            })
        }
        })

        this.setState({
          showModal:true
        })
      }

    }

  }

    render(){
        const {
            showModal,
            redirect, 
            area,
            north,
            midd,
            south,
            listedStock,
            otcStock,
            newOTCStock,
            publicStock,
            induType,
            typeData,
            typeTwo,
            typeThree,
            typeFour,
            params,
            displayParams
        } = this.state

        const { 
          onInduTypeTwo,
          onInduTypeThree,
          onInduTypeFour,
          MaxCapital,
          MinCapital,
          ParkId
         } = this.state.params

 

        if (MinCapital < 0) {
          const fixdeParam = this.state.params;
          fixdeParam.MinCapital = 0;

          this.setState({ params: fixdeParam });
        }

        if (MaxCapital < 0) {
          const fixdeParam = this.state.params;
          fixdeParam.MaxCapital = 0;

          this.setState({ params: fixdeParam });
        } 


        return (
          <div className="adv-search">
            {redirect ? <Redirect to={{
              pathname: "/search_result",
              state: {
                params: params,
                displayParams: displayParams
              }
              }} /> : null}
            <FullPage
              bgPhoto={searchBgPhoto}
              title="請輸入搜尋條件"
              buttonContent="搜尋"
              buttonFunc={this.submitHandler}
            >
              <div className="search-box">
                <select
                  className="no-radius"
                  onChange={this.onAreaChangeHandler}
                >
                  <option value={"1"}>北部</option>
                  <option value={"3"}>中部</option>
                  <option value={"2"}>南部</option>
                </select>
                {area === "1" && (
                  <select className="no-radius" onChange={this.onParkIdHandler}>
                    <option value={[]} selected>北部所有園區</option>
                    {north !== "error" && north ? (
                      Object.values(north).map(item => {

                        if (item) {
                        return (
                          <option
                            key={item.InduPark.ParkId}
                            value={[item.InduPark.ParkId, item.InduPark.Name]}
                          >
                            {item.InduPark.Name}
                          </option>
                        );
                        }
                      })
                    ) : (
                      <option>網路連線出現問題</option>
                    )}
                  </select>
                )}
                {area === "2" && (
                  <select className="no-radius" onChange={this.onParkIdHandler}>
                    <option value={[]} selected>南部所有園區</option>
                    {south !== "error" && south ? (
                      Object.values(south).map(item => {
                        if (item) {
                          return (
                            <option
                              key={item.InduPark.ParkId}
                              value={[item.InduPark.ParkId, item.InduPark.Name]}
                            >
                              {item.InduPark.Name}
                            </option>
                          );
                        }
                      })
                    ) : (
                      <option>網路連線出現問題</option>
                    )}
                  </select>
                )}
                {area === "3" && (
                  <select className="no-radius" onChange={this.onParkIdHandler}>
                   <option value={[]} selected>中部所有園區</option>
                    {midd !== "error" && midd ? (
                      Object.values(midd).map(item => {
                        if (item) {
                          return (
                            <option
                              key={item.InduPark.ParkId}
                              value={[item.InduPark.ParkId, item.InduPark.Name]}
                            >
                              {item.InduPark.Name}
                            </option>
                          );
                        }
                      })
                    ) : (
                      <option>網路連線出現問題</option>
                    )}
                  </select>
                )}
              </div>
              <div className="search-box">
                <button
                  className="no-radius-button"
                  onClick={() => this.openModalHandler("typeTwo")}
                >
                  {onInduTypeTwo.length === 0
                    ? "2碼產業別"
                    : onInduTypeTwo.map(item => {
                        return <p>{typeTwo[item]}</p>;
                      })}
                </button>
                <button
                  className="no-radius-button"
                  onClick={() => this.openModalHandler("typeThree")}
                >
                  {onInduTypeThree.length === 0
                    ? "3碼產業別"
                    : onInduTypeThree.map(item => {
                        return <p>{typeThree[item]}</p>;
                      })}
                </button>
                <button
                  className="no-radius-button"
                  onClick={() => this.openModalHandler("typeFour")}
                >
                  {onInduTypeFour.length === 0
                    ? "4碼產業別"
                    : onInduTypeFour.map(item => {
                        return <p>{typeFour[item]}</p>;
                      })}
                </button>
              </div>
              <div className="search-box-group">
                <input
                  className="no-radius"
                  type="number"
                  name="MinCapital"
                  placeholder="最低資本額"
                  onChange={(event) => this.onInputHandler(event)}
                />
                <input
                  className="no-radius"
                  type="number"
                  name="MaxCapital"
                  onChange={(event) => this.onInputHandler(event)}
                  placeholder="最高資本額"
                />
              </div>
              <div className="search-box">
                <input
                  className="no-radius"
                  type="text"
                  name="ProductKeyword"
                  placeholder="關鍵字"
                  onChange={event => this.onInputHandler(event)}
                />
              </div>
              <div className="check-box">
                <div className="checkbox-group">
                  <input
                    type="checkbox"
                    name="listedStock"
                    checked={listedStock}
                    onChange={this.onStockTyppHandler}
                  />
                  <p>上市</p>
                </div>
                <div className="checkbox-group">
                  <input
                    type="checkbox"
                    name="otcStock"
                    checked={otcStock}
                    onChange={this.onStockTyppHandler}
                  />
                  <p>上櫃</p>
                </div>
                <div className="checkbox-group">
                  <input
                    type="checkbox"
                    name="newOTCStock"
                    checked={newOTCStock}
                    onChange={this.onStockTyppHandler}
                  />
                  <p>興櫃</p>
                </div>
                <div className="checkbox-group">
                  <input
                    type="checkbox"
                    name="publicStock"
                    checked={publicStock}
                    onChange={this.onStockTyppHandler}
                  />
                  <p>公開發行</p>
                </div>
              </div>
            </FullPage>
            <Modal
              show={showModal}
              size="lg"
              onHide={() => this.setState({ showNewsModal: false })}
            >
              <Modal.Header
                style={{
                  border: "none",
                  fontSize: "18px",
                  fontWeight: "500",
                  color: "#006ab4"
                }}
                closeButton
                onHide={() => this.setState({ showModal: false, typeData: "" })}
              >
              <h4 class="h4">產業別選項</h4>
              </Modal.Header>
              <Modal.Body>
                <div>
                  <div className="modal-checkbox-group">
                    {!typeData && <Spinner />}
                    {typeData.length === 0 && (
                      <div className="show-no-data">目前尚無資料 ..</div>
                    )}
                    {typeData &&
                      typeData.length !== 0 && typeData !== 'error' &&
                      typeData.map((item, index) => {
                        let isChecked = false;

                        if (induType === "typeTwo") {
                          onInduTypeTwo.map(id => {
                            if (id === item.Key) {
                              isChecked = true;
                            }
                          });
                        } else if (induType === "typeThree") {
                          onInduTypeThree.map(id => {
                            if (id === item.Key) {
                              isChecked = true;
                            }
                          });
                        } else if (induType === "typeFour") {
                          onInduTypeFour.map(id => {
                            if (id === item.Key) {
                              isChecked = true;
                            }
                          });
                        }

                        return (
                          <div className="modal-checkbox">
                            <input
                              type="checkbox"
                              name="publicStock"
                              checked={isChecked}
                              onChange={(event, type) =>
                                this.onInduTypeHandler(event, induType)
                              }
                              value={item.Key}
                            />
                            <p className="modal-check-box-text">
                              {item.Key} {item.Value}
                            </p>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </Modal.Body>
            </Modal>
          </div>
        );
    }
}


export default AdvancedSearch
 