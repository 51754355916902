import React from "react";
import { useSelector } from "react-redux";
import { Card } from "react-bootstrap";
import CustomizedCard from "../../../components/CustomizedCard/CustomizedCardV3";
import FactoryAwardInfo from "../../../components/CustomizedTable/FactoryInfo/FactoryAwardInfo";
import MITMarkInfoTable from "../../../components/CustomizedTable/FactoryInfo/FactoryMITMarkInfo";
import MobileAwardInfo from "../../../components/MobileGrid/FactoryInfo/AwardInfo/AwardInfo";
import MobileMITMarkInfo from "../../../components/MobileGrid/FactoryInfo/MITMarkInfo/MITMarkInfo";
import MobileEnterpriseQualified from "../../../components/MobileGrid/FactoryInfo/EnterpriseQualified/EnterpriseQualified";
import FactoryEnterpriseQualified from "../../../components/CustomizedTable/FactoryInfo/FactoryEnterpriseQualified";

const Award = (props) => {
  const { awardInfo, awardInfoLoading, MITMarkInfo, MITMarkInfoLoading, getEnterpriseQualifiedData, getEnterpriseQualifiedLoading } = useSelector((state) => state.factory);

  if (window.innerWidth > 1023) {
    return (
      <React.Fragment>
        <Card>
          <CustomizedCard
            title="獲獎資料"
            referenceType={"factoryInfoAwardInfo"}
            loading={awardInfoLoading}
          >
            <FactoryAwardInfo
              headerList={["獎項別", "描述", "年份"]}
              data={awardInfo}
            />
          </CustomizedCard>
        </Card>
        <Card>
          <CustomizedCard
            title="MIT標章"
            referenceType={"factoryInfoMITmarker"}
            loading={MITMarkInfoLoading}
          >
            <MITMarkInfoTable
              headerList={["產品名稱", "通過日期", "有效日期", "產品狀態"]}
              data={MITMarkInfo}
            />
          </CustomizedCard>
        </Card>
        <Card >
          <CustomizedCard
            title="產發署技術服務機構服務能量"
            referenceType={"factoryInfoEnterpriseQualified"}
            loading={getEnterpriseQualifiedLoading}
          >
            <FactoryEnterpriseQualified
              data={getEnterpriseQualifiedData}
              headerList={["服務項目", "服務分項", "有效日期"]}
            />
          </CustomizedCard>
        </Card>
      </React.Fragment>
    );
  } else {
    return (
      <>
        <MobileAwardInfo data={awardInfo} referenceType={"factoryInfoAwardInfo"}/>
        <MobileMITMarkInfo data={MITMarkInfo} referenceType={"factoryInfoMITmarker"}/>
        <MobileEnterpriseQualified data={getEnterpriseQualifiedData} referenceType={"factoryInfoEnterpriseQualified"}/>
      </>
    )
  }
};

export default React.memo(Award);
