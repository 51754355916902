import React, { useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import TextField from "@mui/material/TextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Collapse from "@material-ui/core/Collapse";
import Icon from "@material-ui/core/Icon";
import Button from "@material-ui/core/Button";
import CircularProgress from '@mui/material/CircularProgress';
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUp from "@mui/icons-material/KeyboardArrowUp";
import Autocomplete from '@mui/material/Autocomplete';

import {
  cities,
  northCities,
  centerCities,
  southCities,
  eastCities,
} from "../../constants/cities";

import Message from "../../utility/customizedAlert";
import * as theme from "../../assets/theme"

const SearchInput = ({handleUpdateProps, propsState, handleFetchData, handleToggleSearchInput, handleResetState}) => {
  const { wasted_pollution_list } = useSelector(state => state.wasted_clean);
  const [state, setState] = useState({
    list_data: [],
    isFilterGroupOpen: true,
    location: "All",
    cityOptions : [ ...cities ],
    citiesAndPark: 0,
  });

  React.useEffect(()=>{
    const updateCityOption = handleSwitchLocation(state.location)
    setState(state => ({ ...state, cityOptions: [ ...updateCityOption ] }))
  }, [state.location])

  const handleSwitchLocation = (location) => {
    switch(location){
      case "All":
        return cities
      case "North":
        return northCities
      case "Center":
        return centerCities
      case "South":
        return southCities
      case "East":
        return eastCities
      default:
        return cities
    }
  }

  const handleUpdatePropsState = (event) => {
    const { name, value } = event.target;
    handleUpdateProps(name, value);
  }

  const handleUpdatePropsStateUseAutoComplete = (value, name) => {
    if(value){
      handleUpdateProps(name, value.value);
    }else{
      return
    }
  }

  const handleUpdateField = (event) => {
    const { name, value } = event.target;
    setState(state =>({ ...state, [name]: value }))


    //縣市, 工業區, 產業別2碼, 產業別3碼不得超過 3 個。
    // if (Array.isArray(value)) {
    //   if (state[name].length === 4) {
    //     let updateArray;
    //     if (value > state[name]) {
    //       updateArray = value.pop();
    //     }
    //     setState((state) => ({
    //       ...state,
    //       [name]: value,
    //     }));
    //     updateData(name, value);
    //     Message({
    //       html: "<p>選項不得大於3個</p>",
    //       confirmButtonText: "好",
    //     });
    //   } else {
    //     setState((state) => ({
    //       ...state,
    //       [name]: value,
    //     }));
    //     updateData(name, value);
    //   }
    // } else {
    //   if (name === "controlledMaterialType" && value === "all") {
    //     setState((state) => ({
    //       ...state,
    //       [name]: value,
    //     }));
    //     updateData(name, "");
    //   } else {
    //     if (name === "WasteName") {
    //       setState((state) => ({
    //         ...state,
    //         ToxicName: "",
    //         [name]: value,
    //       }));
    //     } else if (name === "ToxicName") {
    //       setState((state) => ({
    //         ...state,
    //         WasteName: "",
    //         [name]: value,
    //       }));
    //     } else {
    //       setState((state) => ({
    //         ...state,
    //         [name]: value,
    //       }));
    //     }
    //     updateData(name, value);
    //   }
    // }
  };

  const handleResetSearchParams = () => {
    handleResetState()
    setState(state =>({ ...state, location: "All" }))
  }

//   const handleSubmit = () => {
//     controlledSearch("", "others");
//     setState((state) => ({
//       ...state,
//       isFilterGroupOpen: false,
//       isClicked: true,
//     }));
//   };

//   const clearSearchCondition = () => {
//     //處理 ControlledMaterialFilterGroup 的 local state
//     setState((state) => ({
//       ...state,
//       controlledMaterialType: "毒性化學物質",
//       location: "All",
//       city: [""],
//       parkId: [""],
//       typeTwo: [""],
//       typeFour: [""],
//       WasteName: "",
//       ToxicName: "",
//     }));
//     handleClearParams();
//   };
  React.useEffect(()=>{
    const update_list_data = [];
    wasted_pollution_list.forEach(i=>{
      update_list_data.push({
        label: i.Name,
        value: i.Name
      })
    })

    setState(state => ({ ...state, list_data: update_list_data }))
  },[wasted_pollution_list])

  return (
    <SearchInputWrapper>
      <SearchInputHead>
        <h4>搜尋條件</h4>
        {propsState.isSearchInputOpen ? <ArrowDown onClick={handleToggleSearchInput}/> : <ArrowUp  onClick={handleToggleSearchInput}/>}
      </SearchInputHead>
      <Collapse in={propsState.isSearchInputOpen}>
        <InputGroup>
          <InputsContainer>
            <Autocomplete 
              options={state.list_data}
              variant="outlined"
              size="small"
              value={propsState["waste_name"]}
              onChange={(event, newValue) =>{ 

                console.log(event)
                console.log(newValue)


                handleUpdatePropsStateUseAutoComplete(newValue, "waste_name")
              }}
              name="waste_name"
              renderInput={(params) => 
                <Inputs 
                  {...params} 
                  label="欲清理廢棄物項目"
                  value={propsState["waste_name"]}
                  />}
            />
            {/* <Inputs
                label="欲清理廢棄物項目"
                variant="outlined"
                size="small"
                value={propsState["waste_name"]}
                onChange={(e) => handleUpdatePropsState(e)}
                name="waste_name"
                select
            >
              <MenuItem value={""} key={""}>未選擇</MenuItem>
              {wasted_pollution_list.map(pol => <MenuItem value={pol.Name} key={pol.Name}>{pol.Name}</MenuItem>)}
            </Inputs> */}
          </InputsContainer>
          <InputsContainer>
            <Inputs
              label="地區"
              variant="outlined"
              size="small"
              value={state.location}
              onChange={(e) => {
                handleUpdateField(e)
                handleUpdatePropsState(e, "location")
              }}
              name="location"
              select
            >
              <MenuItem key={"All"} value={"All"}>
                台灣所有地區
              </MenuItem>
              <MenuItem key={"North"} value={"North"}>
                台灣北部地區
              </MenuItem>
              <MenuItem key={"Center"} value={"Center"}>
                台灣中部地區
              </MenuItem>
              <MenuItem key={"South"} value={"South"}>
                台灣南部地區
              </MenuItem>
              <MenuItem key={"East"} value={"East"}>
                台灣東部地區
              </MenuItem>
            </Inputs>
          </InputsContainer>
          <InputsContainer>
            <Inputs
              label="縣市"
              variant="outlined"
              size="small"
              value={propsState["city"]}
              onChange={(e) => handleUpdatePropsState(e)}
              name="city"
              select
            >
               <MenuItem value={""} key={"city"}>縣市</MenuItem>
              {state.cityOptions.map(city => <MenuItem value={city} key={city}>{city}</MenuItem>)}
            </Inputs>
          </InputsContainer>
          <InputsContainer>
            <Inputs
              label="機構種類"
              variant="outlined"
              size="small"
              value={propsState["org_kind"]}
              onChange={(e) => handleUpdatePropsState(e)}
              name="org_kind"
              select
            >
               <MenuItem value={"清除機構"} >清除機構</MenuItem>
               <MenuItem value={"處理同意設置"} >處理同意設置</MenuItem>
               <MenuItem value={"處理機構"} >處理機構</MenuItem>
            </Inputs>
          </InputsContainer>
          {/* <InLineTabContainer>
            <Tabs>
              <Left
                active={state.citiesAndPark === 0}
                onClick={handleToggleCitiesOrParks}
              >
                <p>縣市</p>
              </Left>
              <Rights
                active={state.citiesAndPark === 1}
                onClick={handleToggleCitiesOrParks}
              >
                <p>園區</p>
              </Rights>
            </Tabs>
            <Select
              size="small"
              value={state.citiesAndPark === 0 ? propsState["city"] : state.parkId}
              onChange={(e) => handleUpdateField(e)}
              name={state.citiesAndPark === 0 ? "city" : "parkId"}
              input={
                <Inputs
                  variant="outlined"
                  select
                />
              }
              multiple
              defaultValue={[""]}
            >
              <MenuItem value={""} disabled>
                {state.citiesAndPark === 0 ? "縣市" : "園區"}
              </MenuItem>
              {state.citiesAndParkSelection}
            </Select>
          </InLineTabContainer> */}
        </InputGroup>
        <div className="button-container">
          <Button
            className="button"
            variant="contained"
            onClick={handleFetchData}
            // disabled={!state.isAccessSearch}
            // style={
            //   !state.isAccessSearch
            //     ? { background: "#757575", cursor: "not-allowed" }
            //     : {}
            // }
          >
            查詢
          </Button>
          <p className="clear-filter-condition" onClick={handleResetSearchParams}>
            清除搜尋條件
          </p>
        </div>
      </Collapse>
    </SearchInputWrapper>
  );
};


const SearchInputWrapper = styled.div`
    width: 100%;
    background-color: #fff;
    margin-bottom: 2px;


        .MuiCollapse-wrapper{
            .MuiCollapse-wrapperInner{
                .input-group{
                    display: flex;
                    flex-direction: column;
                    width: 90%;
                    margin-left: 5%;
                    border-top: 1px solid #ECECEC;

                    .material-input-select{
                        width: 100%;
                        padding: 5px 0px;
                        >label{
                            line-height: 30px;
                        }

                        .Mui-focused{
                            color:var(--main-color);
                        }
                        .MuiInputBase-root.Mui-focused{
                            .MuiOutlinedInput-notchedOutline{
                                border-color: var(--main-color);
                            }
                        }
                    }
                }   

                .button-container{
                    display: flex;
                    width: 100%;
                    padding: 10px 0px;
                    justify-content: flex-start;
                    align-items: flex-end;

                    .button{
                        width: 100px;
                        margin-left: 15px;
                        background-color: #2877B2;
                        color:#fff
                    }

                    .clear-filter-condition{
                      font-size: 0.75rem;
                      color: #777777;
                      cursor: pointer;
                      transition: 0.2s;
                      margin-left: 20px;
                      padding-bottom: 2px;

                      &:hover{
                          color: #222222;
                          transition: 0.2s;
                      }
                    }
                }
            }
        }
`
const SearchInputHead = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    >h4{
        margin-left: 10px;
        font-size: 1.25rem;
        font-weight: 600;
        padding: 15px 0px;
    }
`
const ArrowDown = styled(KeyboardArrowDown)`
    transform: translateX(-10px);
    cursor: pointer;
`;

const ArrowUp = styled(KeyboardArrowUp)`
    transform: translateX(-10px);
    cursor: pointer;
`;

const InputGroup = styled.div`
    display: flex;
    flex-direction: column;
    width: 90%;
    margin-left: 5%;
    border-top: 1px solid #ececec;
`

const InputsContainer = styled.div`
    margin: 5px 0px;
`

const Inputs = styled(TextField)`
    width: 100%;
    background-color: ${theme.white};
    .MuiOutlinedInput-root{
        .MuiOutlinedInput-notchedOutline{
            >legend{
                display: none;
            }
        }
    }

    .MuiOutlinedInput-root.Mui-focused{
        .MuiOutlinedInput-notchedOutline{
            border-color: ${theme.primaryColor};
        }
    }

    .MuiInputLabel-root.Mui-focused{
      color: ${theme.primaryColor};
    }
`

const InLineTabContainer = styled.div`
  margin: 5px 0px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  .tabs{
      @include tabs-style;
  }
  .material-input-select{
      padding: 5px 0px;
      width: 60%;

      >label{
          line-height: 30px;
      }
      .Mui-focused{
          color:var(--main-color);
      }
      .MuiInputBase-root.Mui-focused{
          .MuiOutlinedInput-notchedOutline{
              border-color: var(--main-color);
          }
      }
  }
`
const Tabs = styled.div`
    width: 40%;
    display: flex;
`

const Left = styled.div`
  background-color: ${p=>p.active ? "#7d7d7d" : "#ebebeb"};
  color: ${p=> !p.active ? "#7d7d7d" : "#ebebeb"};
  text-align: center;
  width: 45%;
  font-size: 0.9rem;
  padding: 10px 2px 10px 5px;
  border-radius: 5px 0px 0px 5px;
  cursor: pointer;
`;

const Rights = styled(Left)`
  border-radius: 0px 5px 5px 0px;
`;



export default SearchInput;