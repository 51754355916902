import React from "react";
import { useSelector } from "react-redux";
import Icon from "@material-ui/core/Icon";

import MobileGrid from "../../MobileGridV2";
import * as Style from "../../styles";
import { Card } from "react-bootstrap";
import Swal from "sweetalert2";
import styled from "styled-components";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const RegisteredFactories = ({ data, referenceType }) => {
  const { referenceInfo } = useSelector((state) => state.app);
  const toggleCard = {};
  const openReferenceInfoAlert = (type) => {
    let content = "";
    if (referenceInfo[type] === "") {
      console.log("I was called!");
      Swal.fire({
        title: "引用資料來源",
        text: "資料正在建置中 ... ",
      });
    } else {
      try {
        referenceInfo[type]
          .split("。")
          .map(
            (referenceItem) =>
              (content +=
                "<p style='text-align:left'>" + referenceItem + "</p>")
          );
        Swal.fire({
          title: "引用資料來源",
          html: content,
        });
      } catch (err) {
        Swal.fire({
          title: "引用資料來源",
          text: "資料正在建置中 ... ",
        });
      }
    }
  };

  return (
    <div>
      <Card.Header className="card-title">
        <TradeMarker>
            {"工廠登記"}
            {toggleCard && referenceType && ( // 如果有 toggleCard 就顯示（位置差異）
              <button
                title={`開啟${"工廠登記"}引用資料來源彈出視窗`}
                style={{ color: "white", borderRadius: "100%", marginLeft: "4px" }}
                onClick={() => openReferenceInfoAlert(referenceType)}  
              > 
                <FontAwesomeIcon
                  className="icon-reference"
                  icon={faInfoCircle}
                />
              </button>
            )}
        </TradeMarker>
      </Card.Header>
      <MobileGrid>
        {data.map((item, index) => (
          <Style.MobileGridContainerItem
            item
            component={Style.MobileGridItem}
            key={item.FactoryName + index}
          >
            <Style.MobileGridItemList>
              <Style.MobileGridItemListLabel>名稱 </Style.MobileGridItemListLabel>
              <Style.MobileGridItemListText>
                {item.FactoryName ? item.FactoryName : "無相關資料"}
              </Style.MobileGridItemListText>
            </Style.MobileGridItemList>
            <Style.MobileGridItemList>
              <Style.MobileGridItemListLabel>地址 </Style.MobileGridItemListLabel>
              <Style.MobileGridItemListText>
                {item.FactoryAddress ? item.FactoryAddress : "無相關資料"}
                {item.InduParkStr ? "(" + item.InduParkStr + ")" : ""}
                <Icon
                  onClick={() => {
                    window.open(
                      `https://maps.google.com/?q=${item.FactoryAddress}`,
                      "_blank"
                    );
                  }}
                  style={{ fontSize: "1.15rem", marginLeft: "5px" }}
                >
                  directions
                </Icon>
              </Style.MobileGridItemListText>
            </Style.MobileGridItemList>
            <Style.MobileGridItemList>
              <Style.MobileGridItemListLabel>
                工廠登記號{" "}
              </Style.MobileGridItemListLabel>
              <Style.MobileGridItemListText>
                {item.FactoryNo ? item.FactoryNo : "無相關資料"}
              </Style.MobileGridItemListText>
            </Style.MobileGridItemList>
            <Style.MobileGridItemList>
              <Style.MobileGridItemListLabel>
                產業二碼{" "}
              </Style.MobileGridItemListLabel>
              <Style.MobileGridItemListText>
                {item.InduTypeTwo ? item.InduTypeTwo : "無產業別二碼登記"}
              </Style.MobileGridItemListText>
            </Style.MobileGridItemList>
            <Style.MobileGridItemList>
              <Style.MobileGridItemListLabel>
                產業四碼{" "}
              </Style.MobileGridItemListLabel>
              <Style.MobileGridItemListText>
                {item.InduTypeFour ? item.InduTypeFour : "無產業別四碼登記"}
              </Style.MobileGridItemListText>
            </Style.MobileGridItemList>
            <Style.MobileGridItemList>
              <Style.MobileGridItemListLabel>
                負責人{" "}
              </Style.MobileGridItemListLabel>
              <Style.MobileGridItemListText>
                {item.FactoryBoss ? item.FactoryBoss : "無相關資料"}
              </Style.MobileGridItemListText>
            </Style.MobileGridItemList>
            <Style.MobileGridItemList>
              <Style.MobileGridItemListLabel>
                主要產品{" "}
              </Style.MobileGridItemListLabel>
              <Style.MobileGridItemListText>
                {item.FactoryMainProduct ? item.FactoryMainProduct : "無相關資料"}
              </Style.MobileGridItemListText>
            </Style.MobileGridItemList>
          </Style.MobileGridContainerItem>
        ))}
      </MobileGrid>
    </div>
  );
};

const TradeMarker = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
`;

export default React.memo(RegisteredFactories);
