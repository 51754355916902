import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import "./styles.scss";
import Tooltip from "@material-ui/core/Tooltip";
import {
  createMuiTheme,
  MuiThemeProvider,
} from "@material-ui/core/styles";
import InfoTwoToneIcon from '@mui/icons-material/InfoTwoTone';

const FactoryTrademark = (props) => {
  const { data, headerList } = props;
  const [state, setState] = React.useState({
    endIndex: 0,
    page: 0,
    rowsPerPage: 5,
  });

  let content;

  React.useEffect(() => {
    if (Math.ceil(data.length) === state.page) {
      if (data.length % 5 === 0) {
        setState((state) => ({
          ...state,
          endIndex: state.page * state.rowsPerPage + state.rowsPerPage,
        }));
      } else {
        setState((state) => ({
          ...state,
          endIndex: state.page * state.rowsPerPage + (data.length % 5),
        }));
      }
    } else {
      setState((state) => ({
        ...state,
        endIndex: state.page * state.rowsPerPage + state.rowsPerPage,
      }));
    }
  }, [data.length, state.page]);

  const tooltipTheme = createMuiTheme({
    overrides: {
      MuiTooltip: {
        tooltip: {
          color: "#0000",
          backgroundColor: "#0000",
          border: "5px solid #000",
          padding: "0px",
          margin: "0px",
          fontSize: "0px",
        }
      }
    }
  });

  if (data.length > 5) {
    content = data
      .slice(state.page * 5, state.page * 5 + 5)
      .map((row, index) => (
        <TableRow
          key={row.PatentNo}
          hover
          className="table-body-row"
          style={index % 2 !== 0 ? { backgroundColor: "#ececec" } : {}}
        >
          <TableCell align="left">{row.CaseNo}</TableCell>
          <TableCell align="left">
            <MuiThemeProvider theme={tooltipTheme}>
              <Tooltip
                placement="top-start"
                id={`tooltip${index+1}`}
                title={
                  <img 
                    src={`https://opendataservice.smartpark.com.tw/Trademark_icon/${row.FilePath}`} 
                    height={250} 
                    width={250}
                  />
                }
              >
                <a>
                  {row.Trademark_Name}
                </a>
              </Tooltip>
            </MuiThemeProvider>
          </TableCell>
          <TableCell align="left">{row.SDate.replaceAll(".", "/")}</TableCell>
          <TableCell align="left">{row.EDate.replaceAll(".", "/")}</TableCell>
        </TableRow>
      ));
  } else {
    content = data.map((row, index) => (
      <TableRow
        key={row.PatentNo}
        hover
        className="table-body-row"
        style={index % 2 !== 0 ? { backgroundColor: "#ececec" } : {}}
      >
        <TableCell align="left">{row.CaseNo}</TableCell>
        <TableCell align="left">
          <MuiThemeProvider theme={tooltipTheme}>
            <Tooltip
              placement="top-start"
              id={`tooltip${index+1}`}
              title={
                <img 
                  src={`https://opendataservice.smartpark.com.tw/Trademark_icon/${row.FilePath}`} 
                  height={250} 
                  width={250}
                />
              }
            >
              <a>
                {row.Trademark_Name}
              </a>
            </Tooltip>
          </MuiThemeProvider>
        </TableCell>
        <TableCell align="left">{row.SDate.replaceAll(".", "/")}</TableCell>
        <TableCell align="left">{row.EDate.replaceAll(".", "/")}</TableCell>
      </TableRow>
    ));
  }

  const handleChangePage = (event, newPage) => {
    setState((state) => ({ ...state, page: newPage }));
  };

  const openNewTabs = (link) => window.open(link, "_blank");

  return (
    <TableContainer className="table-container">
      <Table className="table">
        <TableHead className="table-header">
          {headerList && (
            <TableRow className="table-row">
              {headerList.map((headerItem) => (
                <TableCell
                  key={headerItem}
                  style={{
                    fontWeight: 600,
                  }}
                  className="table-cell"
                  align="left"
                >
                  {headerItem}
                </TableCell>
              ))}
            </TableRow>
          )}
        </TableHead>
        <TableBody className="table-body">
          {data.length !== 0 && content}
        </TableBody>
      </Table>
      {data.length === 0 && (
        <Table>
          <TableBody className="table-body-no-data">
            <TableRow hover className="table-body-row">
              <TableCell align="center" className="no-data">
                本項目無資料
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      )}
      {data.length > 5 && (
        <TablePagination
          rowsPerPageOptions={[]}
          component="div"
          count={data.length}
          rowsPerPage={state.rowsPerPage}
          page={state.page}
          onChangePage={handleChangePage}
          // onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      )}
    </TableContainer>
  );
};

export default React.memo(FactoryTrademark);
