import axios from 'axios';
import { Base64 } from 'js-base64'
import { baseUrl, loginbaseUrl } from "../constants/endpoints";


export const fetchWastedPollutionList = () => {
    return axios({
      proxy: true,
      method: "get",
      url: `${baseUrl}/api/Waste/GetWasteCleanServicePollutionsList`,
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};

export const fetchWastedPollutionData = (data) => {
  return axios({
    method: "post",
    url: `${baseUrl}/api/Waste/WasteCleanServiceResult`,
    headers: { 
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    data: data
  })
  .then((res) => {
    return res.data;
  })
  .catch((err) => {
    return err;
  });
} 

export const fetchWastedPollutionDetailData = (ctrl_id, org_id) => {
  return axios({
    proxy: true,
    method: "get",
    url: `${baseUrl}/api/Waste/GetCleanPollutions/${ctrl_id}/${org_id}`,
  })
  .then((res) => {
    return res;
  })
  .catch((err) => {
    return err;
  });
} 
    